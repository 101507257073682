import { PhoneIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";

type Props = ButtonProps & {
  text?: string;
  children?: React.ReactNode;
};

export default function CallButton(props: Props) {
  return (
    <Popover closeOnBlur={true} placement="bottom">
      <PopoverTrigger>
        <Button
          color="blue.500"
          fontSize="12px"
          isDisabled={props.isDisabled ?? false}
          leftIcon={<PhoneIcon />}
          variant="ghost"
          {...props}
        >
          {props.text ?? "Call"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody p={0}>{props.children}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
