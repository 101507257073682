import { Button, Flex, Skeleton, Text } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import { UseQueryResult } from "@tanstack/react-query";
import { useCurrentStateAndParams } from "@uirouter/react";
import Link from "../../../../../shared/components/Link";
import ProtectedField from "../../../../../shared/components/ProtectedField";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { AssignmentRecord } from "../assignments.utils";
import { VisitAssignmentSidebarSection } from "./VisitAssignmentSidebarSection";

export function VisitAssignmentRelatedVisitsCard(props: {
  query: UseQueryResult<AssignmentRecord>;
}) {
  const { state } = useCurrentStateAndParams();

  return (
    <VisitAssignmentSidebarSection title="RELATED VISITS">
      {(() => {
        switch (props.query.status) {
          case "pending":
            return (
              <Flex direction="column" gap={2}>
                <Skeleton height={5} rounded="full" width="66%" />
                <Skeleton height={5} rounded="full" width="66%" />
              </Flex>
            );
          case "error":
            return <Flex>Something went wrong</Flex>;
          case "success":
            return (
              <Flex direction="column" gap={2}>
                {props.query.data.visitInstances.nodes.length === 0 && (
                  <Text>There are no related visits</Text>
                )}
                {props.query.data.visitInstances.nodes.map(({ visitInstance }) => (
                  <Button
                    key={visitInstance.id}
                    as={Link}
                    color="black"
                    display="block"
                    params={{ visitInstance: visitInstance.id }}
                    textAlign="start"
                    to={state.name ?? "app.visitAssignments.assignment"}
                    variant="link"
                  >
                    <ProtectedField field={visitInstance.startTimeLocal}>
                      {(x) => dateFormatter.toDateTime(x)}
                    </ProtectedField>
                    {" - "}
                    <ProtectedField field={visitInstance.endTimeLocal}>
                      {(x) => dateFormatter.toTime(LocalDateTime.parse(String(x)))}
                    </ProtectedField>
                  </Button>
                ))}
              </Flex>
            );
        }
      })()}
    </VisitAssignmentSidebarSection>
  );
}
