import React from "react";
import { ElkNodeData, IntakeFlowGraphId } from "../flow-settings-visualizer.utils";
import { useReactFlow } from "reactflow";

export default function useSelectedNode() {
  const { getNode } = useReactFlow<ElkNodeData>();
  const [selectedNodeId, setSelectedNodeId] = React.useState<IntakeFlowGraphId | null>(null);

  const select = (nodeId: string) => {
    const node = getNode(nodeId);
    if (node === undefined) {
      return;
    }
    setSelectedNodeId(node.data.id);
  };

  const unselect = (nodeId: string) => {
    const node = getNode(nodeId);
    if (node === undefined) {
      return;
    }
    if (selectedNodeId === node.data.id) {
      setSelectedNodeId(null);
    }
  };

  return { selectedNodeId, select, unselect };
}
