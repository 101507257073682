import { EditIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Link } from "@chakra-ui/react";
import { useRouter } from "@uirouter/react";
import EntityCard, { IntakePatientEntity } from "../../../shared/components/EntityCard";
import SaveIcon from "../../../shared/icons/SaveIcon";
import { getFullName } from "../../../shared/utils/get-full-name";
import IntakeNextCallBadge from "./IntakeNextCallBadge";
import IntakePatientSendEmailButton from "./IntakePatientSendEmailButton";
import IntakePatientSendSMSButton from "./IntakePatientSendSMSButton";
import { PatientIntakeProfileDraft } from "../patient-intake.types";
import IntakeProfileCallButton from "./IntakeProfileCallButton";
import PatientIntakeRescheduleNextCallButton from "./PatientIntakeRescheduleNextCallButton";
import { usePatientProfileLink } from "../../../shared/hooks/usePatientProfileLink";
import TransferFromIntakePatientButton from "./TransferFromIntakePatientButton";

interface Props {
  patientInfo: PatientIntakeProfileDraft;
  isEditMode: boolean;
  onCloseIntakeProfile: () => void;
  onClickEdit: () => void;
  onClickSave: () => void;
}

export const IntakeProfileHeader = (props: Props) => {
  const { displayId, id, gender, nextCallDate, intakeStatus, intakeFlowType, wasSelfServe } =
    props.patientInfo;
  const { stateService } = useRouter();
  const openPatientProfile = usePatientProfileLink();
  const patientIntakeEntity: IntakePatientEntity = {
    type: "IntakePatient",
    id: id,
    displayId: displayId,
    gender: gender,
    fullName: getFullName(props.patientInfo),
    intakeStatus: intakeStatus.status,
    contactDetails: null,
    selfServe: intakeFlowType === "Self Serve",
    wasSelfServe,
  };

  return (
    <>
      <Flex direction="column" marginTop="15px">
        <Flex gap={3}>
          <EntityCard
            boxProps={{
              fontSize: "xl",
              marginBottom: "5px",
              as: Link,
              p: 3,
              borderRadius: "lg",
              _hover: { bg: "gray.100", color: "black", textDecoration: "none" },
              onClick: () => {
                props.onCloseIntakeProfile();
                setTimeout(() => {
                  openPatientProfile({
                    id: props.patientInfo.id,
                  });
                }, 0);
              },
            }}
            entity={patientIntakeEntity}
          />
          <Box marginTop={3}>
            <IntakeNextCallBadge nextCallDate={nextCallDate} />
          </Box>
        </Flex>
        <Flex direction="row-reverse">
          {props.isEditMode ? (
            <Button
              colorScheme="blue"
              fontSize="12px"
              leftIcon={<SaveIcon />}
              onClick={props.onClickSave}
            >
              Save
            </Button>
          ) : (
            <Button
              color="blue.500"
              fontSize="12px"
              leftIcon={<EditIcon />}
              variant="ghost"
              onClick={props.onClickEdit}
            >
              Edit
            </Button>
          )}
          <PatientIntakeRescheduleNextCallButton
            fontSize="12px"
            patientId={props.patientInfo.id}
            variant="ghost"
          />
          <Button
            color="blue.500"
            fontSize="12px"
            variant="ghost"
            onClick={() => stateService.go("app.patients.intake-flow", { patientId: id })}
          >
            Intake Flow Modal
          </Button>
          <IntakeProfileCallButton patientId={props.patientInfo.id} />
          <IntakePatientSendSMSButton
            patientId={props.patientInfo.id}
            predefinedPhoneNumber={props.patientInfo.mainPhoneNumber ?? undefined}
          />
          <IntakePatientSendEmailButton patientId={id} />
          <TransferFromIntakePatientButton
            color="blue.500"
            fontSize="12px"
            patientId={props.patientInfo.id}
            track={props.patientInfo.track}
            variant="ghost"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default IntakeProfileHeader;
