import { Instant } from "@js-joda/core";
import jwtDecode from "jwt-decode";
import React from "react";
import { reactBridge } from "../../core";
import { Messages, ResponseOf } from "../../core/api";
import { auth } from "../../core/auth";
import { env } from "../../env";
import { UserId } from "../schema/schema";
import { useReactBridgePayloadEvent } from "./useReactBridgeEvent";
export type AuthTokens = { authToken: string; refreshToken: string };

interface JWTResponse {
  userLoginDetails: {
    agencyMember: Messages["AgencyMember"];
    agency: Messages["Agency"];
  };
  authData: {
    userId: UserId;
    userCreatedAt: Instant;
    superuser: boolean;
    email: string | null;
  };
}

export interface AuthData {
  userId: UserId;
  agencyMember: Messages["AgencyMember"];
  agency: Messages["Agency"];
}

const useAuth = () => {
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const permissions = auth.getPermissions();

  useReactBridgePayloadEvent({
    eventName: "AUTH_SET",
    runInitial: true,
    onEvent: () => {
      setIsInitialized(true);

      const info = reactBridge.getPayload("AUTH_SET") ?? null;

      if (info === null) {
        return setIsUnauthorized(true);
      }

      auth.set(info);
    },
  });

  const logout = (onLoggedOut: () => void) => {
    const clearExcept = [
      "stealthMode",
      "code",
      "showVideoNewVisitForm",
      "caregiversTableSettings",
      "trainingTableSettings",
      "pendingApplicationTableSettings",
      "coronaReportsTableSettings",
      "medflytPassportTableSettings",
      "billingAccountsReceivableTableSettings",
      "savedAssignedCoordinators",
      "faxComment",
      "localDistHash",
      "hide-sweep-vegas",
      "hide-reset-attempts-video",
      "hide-corona-banner",
      "trainingCenterStatisticsTableColumn",
      "trainingCenterOverallStatisticsTableColumn",
      "searchFiltersSettings",
      "visitsDashboardTableSettings",
      "stateOfHomeCareSurvey",
      "patientCalendarShowDeletedVisits",
      "caregiverCalendarShowDeletedVisits",
      "ClientSurveyNextDate",
    ];

    const localStorageKeys = Object.keys(localStorage);
    for (const key of localStorageKeys) {
      if (!clearExcept.includes(key)) {
        localStorage.removeItem(key);
      }
    }

    return onLoggedOut();
  };

  function hasPermissions(permission: Messages["AgencyMemberPermission"]) {
    return auth.isAllowedTo(permission);
  }

  const authData = getAuthData({ tokens: auth.getTokens(), isUnauthorized });

  const isMedflytAgencyMember = () => {
    return authData.type === "authenticated" && authData.isMedflytAgencyMember;
  };

  function handleTokenRefresh(data: ResponseOf<"post", "/auth/token">) {
    auth.set({
      authToken: data.accessJWT,
      refreshToken: data.refreshJWT,
      permissions: permissions,
    });
  }

  return {
    isInitialized,
    isUnauthorized,
    isMedflytAgencyMember,
    logout,
    authData,
    handleTokenRefresh,
    hasPermissions,
    can: hasPermissions,
  };
};

function getAuthData(params: { tokens: AuthTokens | null; isUnauthorized: boolean }) {
  if (params.tokens == null) {
    return { type: "guest" as const };
  }

  if (params.isUnauthorized) {
    return { type: "unauthorized" as const };
  }

  const { authData, userLoginDetails } = jwtDecode<JWTResponse>(params.tokens.authToken);

  const data: AuthData = {
    userId: authData.userId,
    agency: userLoginDetails.agency,
    agencyMember: userLoginDetails.agencyMember,
  };

  return {
    type: "authenticated" as const,
    isMedflytAgencyMember: data.agency.id === env.MEDFLYT_AGENCY_ID,
    data: data,
    tokens: params.tokens,
  };
}

export default useAuth;
