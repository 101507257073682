import { WorkflowTaskInstanceId } from "../../shared/schema/schema";
import { createQueryKeys } from "../../shared/utils/create-query-keys";

export const workflowKeys = createQueryKeys("workflow", {});

export const workflowTaskKeys = createQueryKeys("workflow_task", {
  search: (params: Record<string, unknown>) => params,
  item: (id: WorkflowTaskInstanceId) => id,
  getSignedFiles: (id: WorkflowTaskInstanceId) => id,
  skills: null,
  agencyMembersSkills: null,
});

export const workflowDefinitionKeys = createQueryKeys("workflow_definition", {
  list: null,
});

export const workflowHumanTaskNamesKeys = createQueryKeys("workflow_human_task_names", {
  list: null,
});

export const workflowTaskViewerKeys = createQueryKeys("workflow_task_viewer", {
  view: null,
});
