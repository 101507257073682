import { useToast } from "@chakra-ui/react";
import React from "react";
import { IntakeFlowGraphId, parseIntakeFlowGraphId } from "../flow-settings-visualizer.utils";

export default function useNodeClipboard({
  selectedNodeId,
}: {
  selectedNodeId: IntakeFlowGraphId | null;
}) {
  const toast = useToast();

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
        const selection = window.getSelection();

        if (
          (selection?.type !== "None" && selection?.type !== "Caret") ||
          selectedNodeId === null ||
          !isActiveElementNode()
        ) {
          return;
        }

        e.preventDefault();

        const nodeId = parseIntakeFlowGraphId(selectedNodeId);
        navigator.clipboard.writeText(`${nodeId.id}`);

        toast({
          title: `Node #${nodeId.id} copied`,
          status: "info",
          duration: 2000,
          position: "top-right",
        });
      }
    };

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  });
}

function isActiveElementNode() {
  return document.activeElement?.classList.contains("react-flow__node") === true;
}
