import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { ListedWorkflow } from "../workflow.types";
import { WorkflowDefinitionSerializer } from "../workflow.utils";

export default function useWorkflowDefinitions() {
  const { queries } = useApi();

  return useQuery({
    ...queries.workflow.definitions(),
    select: ({ workflows }) =>
      workflows.map((workflow): ListedWorkflow => {
        return {
          ...workflow,
          input: WorkflowDefinitionSerializer.fromJSON(workflow.input),
        };
      }),
  });
}
