import { CertificationPeriod } from "../messages/certification_period";
import { PatientTaskTypesForWebapp } from "@medflyt/messages/patient_task";
import { LocalDate } from "@js-joda/core";
import { DatabaseApiService } from "./db";
import { PatientId } from "@medflyt/messages/ids";
import { PatientService } from "./patientService";
import { PatientExtendedData } from "@medflyt/messages/patient";

interface BaseNewPatientTaskModalParameters {
    task?: PatientTaskTypesForWebapp;
    date?: LocalDate;
    certificationPeriod?: CertificationPeriod;
    certificationPeriodLocked?: boolean;
}

type NewPatientTaskModalParameters = ({
    patientId: PatientId;
} | {
    patient: PatientExtendedData;
}) & BaseNewPatientTaskModalParameters;

//! @ngInject
export class CreateTaskModalService {
    constructor(
        private $rootScope: ng.IRootScopeService,
        private DatabaseApi: DatabaseApiService,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private patientService: PatientService,
    ) {
    }

    async openNewTaskModalByCertificationPeriod(certificationPeriod) {
        let date = LocalDate.parse(certificationPeriod.startDate);
        const isStartOfCare = certificationPeriod.type === "START_OF_CARE";
        if (!isStartOfCare) {
            date = date.minusMonths(1);
        }

        return this.openNewPatientTaskModal({
            date,
            certificationPeriod,
            patientId: certificationPeriod.patientId,
            certificationPeriodLocked: true
        });
    }

    async openNewPatientTaskModal(params: NewPatientTaskModalParameters) {
        const {
            task,
            date,
            certificationPeriod,
            certificationPeriodLocked
        } = params;

        let patient;

        const availableForms = this.DatabaseApi.patientDocumentTypes();
        const planOfCareTypes = this.DatabaseApi.plansOfCare();
        const planOfCare = planOfCareTypes ? planOfCareTypes[0] : null;

        if ("patient" in params) {
            patient = params.patient;
        } else {
            patient = await this.patientService.getById(params.patientId);
        }

        const patientTaskTemplates = this.DatabaseApi.patientTaskTemplates();

        const newScope = this.$rootScope.$new() as any;

        newScope.task = null;
        newScope.type = null;
        newScope.patient = patient;
        newScope.date = date;
        newScope.certificationPeriod = certificationPeriod;
        newScope.certificationPeriodLocked = certificationPeriodLocked ?? false;

        newScope.patientTaskTemplates = patientTaskTemplates;
        newScope.planOfCare = planOfCare;

        if (task) {
            newScope.task = task;
            newScope.task.documents = newScope.task.documents.filter(doc => doc !== undefined);
            newScope.type = task.type;
        }

        const docs = availableForms
            .filter(doc => doc.title !== "")
            .map(doc => ({ id: doc.id, label: doc.title }));

        newScope.documentsMultiSelectOptions = docs.filter(doc => {
            const document = availableForms.find(d => d.id === doc.id);
            return document && document.versions && document.versions.find(v => v.isPublished) !== undefined;
        });

        const patientOfficePOC = planOfCareTypes.find(poc => poc.officeId === patient.currentOfficeId);

        // add plan of care to array with unique id '0'
        if (patientOfficePOC) {
            newScope.patientOfficePOC = patientOfficePOC;
            newScope.documentsMultiSelectOptions.push({
                id: 0,
                label: planOfCare.name,
                isPlanOfCare: true
            });
        }

        return this.$uibModal.open({
            templateUrl: 'admin/views/new-patient-task-modal.html',
            size: 'lg',
            controller: 'newPatientTaskModalCtrl',
            scope: newScope,
            backdrop: 'static',
            windowClass: "modal-slide-in-right uib-side-modal uib-side-modal-wide"
        }).result;
    }
}
