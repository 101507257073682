import { Flex, IconButton, Textarea } from "@chakra-ui/react";
import { EmojiClickData } from "emoji-picker-react";
import React from "react";
import { useAutosizeTextarea } from "../../../shared/hooks/useAutoresize";
import useStableCallback from "../../../shared/hooks/useStableCallback";
import SendIcon from "../../../shared/icons/SendIcon";
import { EmojiPickerPopover } from "../../../shared/components/EmojiPickerPopover";
import FileAttachmentButton from "../../../shared/components/FileAttachmentButton";

type Props = {
  onSubmit: (message: string) => void;
  onSelectFile: (newFile: File) => void;
};

const ChatMessageInput = (props: Props) => {
  const ref = React.useRef<HTMLTextAreaElement>(null);
  const stableOnSubmit = useStableCallback(props.onSubmit);

  useAutosizeTextarea(ref, { lineHeight: 42 });

  React.useEffect(() => {
    const textarea = ref.current;

    if (textarea === null) {
      return;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();

        if (textarea.value.trim().length > 0) {
          stableOnSubmit(textarea.value.trim());
        }

        textarea.value = "";
        textarea.style.height = "42px";
      }
    };

    textarea.addEventListener("keydown", handleKeyDown);

    return () => textarea.removeEventListener("keydown", handleKeyDown);
  }, [ref, stableOnSubmit]);

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    const textarea = ref.current;

    if (textarea !== null && textarea.value.trim().length > 0) {
      props.onSubmit(textarea.value.trim());
      textarea.value = "";
    }
  };

  const handleEmojiClick = (emoji: EmojiClickData) => {
    const textarea = ref.current;

    if (textarea !== null) {
      textarea.value += emoji.emoji;
    }
  };

  return (
    <Flex
      alignItems="flex-end"
      as="form"
      bg="white"
      borderBottomEndRadius="2xl"
      gap={2}
      p={4}
      onSubmit={handleSubmit}
    >
      <Textarea
        ref={ref}
        borderRadius="2xl"
        lineHeight="1.6"
        minHeight="42px"
        placeholder="Type your message..."
        resize="none"
        rows={1}
        size="lg"
      />

      <EmojiPickerPopover onEmojiClick={handleEmojiClick} />
      <FileAttachmentButton onSelectFile={props.onSelectFile} />

      <IconButton
        aria-label="send-icon"
        borderRadius="2xl"
        colorScheme="blue"
        icon={<SendIcon />}
        size="lg"
        type="submit"
      />
    </Flex>
  );
};

export default ChatMessageInput;
