import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import EditNoteModal from "../../../modules/note/components/EditNoteModal";
import useApi from "../../hooks/useApi";
import { useReactBridgeDispatchEvent } from "../../hooks/useReactBridgeEvent";
import { NoteId } from "../../schema/schema";
import { useQueryClient } from "@tanstack/react-query";

export default function EditNoteModalListener() {
  const disclosure = useDisclosure();
  const [selectedNoteId, setSelectedNoteId] = React.useState<NoteId | null>(null);
  const { queries } = useApi();
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    if (selectedNoteId !== null) {
      queryClient.invalidateQueries(queries.note.get(selectedNoteId));
    }
  };

  useReactBridgeDispatchEvent({
    eventName: "EDIT_NOTE_MODAL:OPEN",
    onEvent: ({ id }) => {
      setSelectedNoteId(id);
      disclosure.onOpen();
    },
  });

  if (selectedNoteId === null) {
    return <></>;
  }

  return (
    <EditNoteModal disclosure={disclosure} noteId={selectedNoteId} onSuccess={handleSuccess} />
  );
}
