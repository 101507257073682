import { CalendarIcon } from "@chakra-ui/icons";
import { Box, InputGroup, InputRightElement } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useRouter } from "@uirouter/react";
import DataTable from "../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../shared/components/DataTable/useGraphQLDataTable";
import RangeDatePicker from "../../shared/components/DatePicker/RangeDatePicker";
import EntityCard from "../../shared/components/EntityCard";
import { createFilters } from "../../shared/hooks/useFilters";
import {
  GetAuthorizedPatientsWithoutVisitsQuery,
  GetAuthorizedPatientsWithoutVisitsQueryVariables,
} from "../../shared/schema/gql/graphql";
import { dateFormatter } from "../../shared/utils/date-formatter";
import { getFullName } from "../../shared/utils/get-full-name";
import { GET_AUTHORIZED_PATIENTS_WITHOUT_VISITS_QUERY } from "./authorized-patients-without-visits.graphql";

type TableRow =
  GetAuthorizedPatientsWithoutVisitsQuery["authorizedPatientsWithoutVisits"]["nodes"][number];

const { createRangeDatePickerFilter } =
  createFilters<GetAuthorizedPatientsWithoutVisitsQueryVariables>();

export default function AuthorizedPatientsWithoutVisitsTable() {
  const { dataTableProps, globalFilters, setFilter } = useGraphQLDataTable({
    document: GET_AUTHORIZED_PATIENTS_WITHOUT_VISITS_QUERY,
    connection: "authorizedPatientsWithoutVisits",
    columns: columns,
    enableColumnFilters: false,
    columnVisiblity: {},
    initialSorting: [{ id: "startDate", desc: true }],
    globalFilters: {
      initialState: {},
    },
  });

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date from to",
    startDate: { name: "from", value: globalFilters.from ?? null },
    endDate: { name: "to", value: globalFilters.to ?? null },
    onChange: setFilter,
  });

  const filtersNode = (
    <>
      <InputGroup width="xs">
        <RangeDatePicker {...fromToFilter} inputProps={{ width: "full" }} />
        <InputRightElement>
          <CalendarIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
    </>
  );

  const { stateService } = useRouter();

  return (
    <div>
      <DataTable
        {...dataTableProps}
        filterNode={filtersNode}
        onClickRow={(e, row) => {
          e.metaKey || e.ctrlKey
            ? window.open(
                `/app/patients/authorized-without-visits?patient=${row.original.patient.id}`
              )
            : stateService.go("app.patients.authorizedWithoutVisits.patient", {
                patient: row.original.patient.id,
              });
        }}
      />
    </div>
  );
}

const { accessor } = createColumnHelper<TableRow>();

const columns = [
  accessor("patient", {
    header: "Patient",
    meta: { gqlSortKey: "patientId" },
    cell: ({ getValue }) => {
      const { id, status, displayId, gender } = getValue();
      return (
        <Box w="fit-content">
          <EntityCard
            boxProps={{ maxWidth: "fit-content" }}
            entity={{
              type: "Patient",
              fullName: getFullName(getValue()),
              status: status,
              id: id,
              displayId: displayId ?? null,
              gender: gender ?? null,
              contactDetails: null,
            }}
          />
        </Box>
      );
    },
  }),
  accessor("patientAuthorization.startDate", {
    header: "Authorization Start Date",
    meta: { gqlSortKey: "startDate" },
    cell: ({ getValue }) => dateFormatter.toDate(getValue()),
  }),
  accessor("patientAuthorization.endDate", {
    header: "Authorization End Date",
    meta: { gqlSortKey: "endDate" },
    cell: ({ getValue }) => dateFormatter.toDate(getValue()),
  }),
];
