import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../../../../shared/hooks/useApi";
import { GetVisitAssignmentQuery } from "../../../../../shared/schema/gql/graphql";
import { VisitAssignmentId } from "../../../../../shared/schema/schema";
import { CommCenterTicket } from "../../../../communication/communication.types";

export function VisitAssignmentTicketRelationIndication(props: {
  assignmentId: VisitAssignmentId;
  ticket: CommCenterTicket;
}) {
  const { queries } = useApi();
  const query = useQuery(queries.visit.assignments.get(props.assignmentId));

  return (
    <Flex align="center" flex={1}>
      <Popover openDelay={0} placement="end" trigger="hover">
        <PopoverTrigger>
          <IconButton
            aria-label="relation"
            icon={<InfoOutlineIcon color="gray.500" />}
            variant="ghost"
          />
        </PopoverTrigger>
        <PopoverContent w="fit-content">
          {query.data !== undefined && (
            <PopoverBody>
              {getVisitAssignmentRelationIndications({
                ticket: props.ticket,
                assignment: query.data,
              }).map((indication) => (
                <Box key={indication}>{indication}.</Box>
              ))}
            </PopoverBody>
          )}
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

function getVisitAssignmentRelationIndications(params: {
  ticket: CommCenterTicket;
  assignment: GetVisitAssignmentQuery["visitAssignments"]["nodes"][0];
}) {
  const relations: string[] = [];

  if (params.assignment.patient.id === params.ticket.relatedPatient?.id) {
    relations.push("The ticket is a recent ticket of the patient");
  }

  if (params.assignment.caregiver?.id === params.ticket.relatedCaregiver?.id) {
    relations.push("The ticket is a recent ticket of the caregiver");
  }

  if (
    params.assignment.visitInstances.nodes.some(
      (x) => x.visitInstance.id === params.ticket.relatedVisitInstance?.id
    )
  ) {
    relations.push("The ticket is related to one of the assigned visits");
  }

  if (
    params.assignment.visitBroadcasts.nodes.some(
      (x) => x.visitBroadcast.id === params.ticket.relatedVisitBroadcast?.id
    )
  ) {
    relations.push("The ticket is related to a visit broadcast");
  }

  return relations;
}
