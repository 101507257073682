import { HStack, Text, VStack } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { Messages } from "../../../../core/api";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { getTelephonyCallParticipantTypeAndName } from "../../call-center.utils";

export default function MissedCallCard(props: {
  caller: Messages["TelephonyInboundCallInfo"]["caller"];
  calledAt: Instant;
}) {
  console.log("props.calledAt", props.calledAt);
  const { type, name } = getTelephonyCallParticipantTypeAndName(props.caller);
  return (
    <HStack gap={4} justifyContent="center">
      <VStack alignItems="flex-start" gap={0}>
        <Text fontSize="xl">{name}</Text>
        <Text color="gray.500" fontSize="sm">
          {type}
        </Text>
        <Text color="gray.500" fontSize="sm">
          Called at: {dateFormatter.toDateOrDateTime(props.calledAt)}
        </Text>
      </VStack>
    </HStack>
  );
}
