import { SystemStyleObject } from "@chakra-ui/styled-system";
import { z } from "zod";
import { AgencyMemberId } from "../../schema/schema";

export const editorTheme: SystemStyleObject = {
  h1: {
    fontSize: "3xl",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "2xl",
    fontWeight: "bold",
  },
  ul: {
    paddingStart: 5,
  },
  ol: {
    paddingStart: 5,
  },
  ".mention": {
    bg: "blue.100",
    color: "blue.900",
    px: 1,
    rounded: "md",
    fontWeight: "semibold",
  },
};

export type MentionItem = z.infer<typeof zMentionItem>;
export type RichText = z.infer<typeof zRichText>;

export const zMentionItem = z.object({
  id: AgencyMemberId,
  name: z.string(),
  picture: z.string().nullable(),
});

export const zRichText = z.object({
  output: z.string(),
  mentions: zMentionItem.array(),
});

export function getTextContentOfRichText(richText: RichText) {
  const { textContent } = new DOMParser().parseFromString(richText.output ?? "", "text/html").body;
  return textContent ?? "";
}
