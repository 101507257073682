import {
  Center,
  FlexProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { noop } from "@chakra-ui/utils";
import React from "react";
import useCallCenter from "../../modules/call-center/hooks/useCallCenter";
import DialerTabs, { DialerTabsContext } from "../../modules/communication/components/DialerTabs";

interface Props {
  isOpen: boolean;
  predefinedPhoneNumber?: string;
  onClose: () => void;
}

export default function DialerModal({ isOpen, predefinedPhoneNumber, onClose }: Props) {
  const callCenter = useCallCenter();

  React.useEffect(() => {
    if (callCenter.state.status === "Active") {
      onClose();
    }
  }, [callCenter.state.status, onClose]);

  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <DialerTabs
              context={dialerTabsContext}
              flexProps={dialerFlexProps}
              isCalling={callCenter.state.status === "Dialing"}
              predefinedPhoneNumber={predefinedPhoneNumber}
              onClickAgencyMember={noop}
              onClickDial={callCenter.dial}
            />
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const dialerTabsContext: DialerTabsContext = {
  hideAgencyMembers: true,
  hideCaregivers: false,
  hidePatients: false,
  hidePhoneNumber: false,
  hidePhonebook: false,
};

const dialerFlexProps: FlexProps = {
  flex: "1",
  alignItems: "center",
  mt: 10,
};
