import { Box, Flex, Heading, List, Skeleton } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BodyOf, Messages } from "../../../../core/api";
import ErrorPage from "../../../../shared/components/ErrorPage";
import useApi from "../../../../shared/hooks/useApi";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import { IntakeChecklistItemId, NoteId, PatientId } from "../../../../shared/schema/schema";
import NewNoteModal from "../../../note/components/NewNoteModal";
import useIntakeChecklist from "../../hooks/useIntakeChecklist";
import { createBlockingDependenciesForChecklistItems } from "../../shared/utils/intakeChecklist.utils";
import ConfirmBulkUncheckModal from "./ConfirmBulkUncheckModal";
import IntakeChecklistItem from "./IntakeChecklistItem";

type UpdateChecklistItemParams = BodyOf<
  "post",
  "./patient_intake/:patientId/checklist/:checklistItemId"
> & {
  checklistItemId: IntakeChecklistItemId;
};
export type UpdateChecklistItemParamsWithoutNoteId = Omit<UpdateChecklistItemParams, "noteId">;

interface Props {
  intakeTrack: Messages["IntakePatientProfile"]["track"];
  patientId: PatientId;
}

function IntakeChecklist(props: Props) {
  const queryClient = useQueryClient();
  const { queries } = useApi();
  const {
    clickChecklistItem,
    confirmBulkUncheck,
    onSaveNote,
    confirmBulkUncheckDisclosure,
    newNoteDisclosure,
    clickedChecklistItem,
  } = useIntakeChecklist({
    patientId: props.patientId,
  });

  const checklist = useQuery({
    ...queries.intake.checklist(props.patientId),
    select: (data) => {
      const checklistItemsWithBlockingDependencies = createBlockingDependenciesForChecklistItems(
        data.items
      );
      return checklistItemsWithBlockingDependencies;
    },
  });

  useSocketEvent({
    key: "PatientIntackeChecklistChanged",
    onEvent: (data) => {
      if (data.patientIds.includes(props.patientId)) {
        queryClient.invalidateQueries(queries.intake.checklist(props.patientId));
      }
    },
  });

  return (
    <>
      {(() => {
        switch (checklist.status) {
          case "pending":
            return (
              <Box minW={370} pl={5} pt={10} w={370}>
                <IntakeChecklistShimmer />
              </Box>
            );
          case "error":
            return <ErrorPage error={checklist.error} resetErrorBoundary={checklist.refetch} />;
          case "success": {
            if (checklist.data.length === 0) {
              return <></>;
            }

            return (
              <Flex flexDirection="column" gap={3} mb={45} minW={370} pl={5} w={370}>
                <Heading as="h2" cursor="help" pt={8} size="md">
                  Checklist - {props.intakeTrack.name}
                </Heading>
                <List overflowY="auto" spacing={4}>
                  {checklist.data.map((item) => (
                    <IntakeChecklistItem
                      key={item.code}
                      item={item}
                      onClickUpdateItem={(params) => clickChecklistItem(item, params)}
                    />
                  ))}
                </List>
              </Flex>
            );
          }
        }
      })()}

      <ConfirmBulkUncheckModal
        disclosure={confirmBulkUncheckDisclosure}
        item={clickedChecklistItem}
        onConfirm={confirmBulkUncheck}
      />

      <NewNoteModal
        disclosure={newNoteDisclosure}
        initialValues={{ patientId: props.patientId }}
        onSuccess={(noteId: NoteId) => onSaveNote(noteId)}
      />
    </>
  );
}

function IntakeChecklistShimmer() {
  return (
    <Flex flexDirection="column" gap={5}>
      <Skeleton height={5} mb={3} width={300} />
      {Array.from({ length: 10 }).map((_, index) => (
        <IntakeChecklistItemShimmer key={index} />
      ))}
    </Flex>
  );
}

function IntakeChecklistItemShimmer() {
  return <Skeleton height={5} width={72} />;
}

export default IntakeChecklist;
