import { Flex } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import Select from "../../../../shared/components/Select";
import { validateOneOf } from "../../../../shared/utils";
import EntityFormControl from "../../../workflow/components/EntityFormControl";
import { TicketEntityModalSchema } from "./utils/ticket-entity-select-modal-form-validation";

const VisitTypeOptions = ["VisitInstance", "VisitBroadcast"] as const;
const visitTypeSelectOptions = [
  {
    label: "Visit Instance",
    value: "VisitInstance",
  },
  {
    label: "Visit Broadcast",
    value: "VisitBroadcast",
  },
];

function TicketEntitySelectModalVisitsSelect() {
  const { setValue, control, trigger } = useFormContext<TicketEntityModalSchema>();

  const visitType = useController({ name: "visitType", control });
  const visitInstanceId = useController({ name: "visitInstanceId", control });
  const visitBroadcastId = useController({ name: "visitBroadcastId", control });
  const patientId = useController({ name: "patientId", control });
  const caregiverId = useController({ name: "caregiverId", control });

  const handleChangeVisitType = (val: string | undefined) => {
    const value = val !== undefined ? validateOneOf(val, VisitTypeOptions) : null;
    setValue("visitType", value);
    trigger("visitType");
  };

  return (
    <Flex flexDirection="column" gap={5}>
      <Select
        allowUnselect={true}
        label="Visit Type"
        multiple={false}
        options={visitTypeSelectOptions}
        searchable={false}
        value={visitType.field.value}
        onChange={(val) => handleChangeVisitType(val)}
      />
      {visitType.field.value === "VisitInstance" && (
        <EntityFormControl
          errors={[]}
          hardFilters={{
            caregiverId: caregiverId.field.value,
            patientId: patientId.field.value,
          }}
          input={{ type: "entity", entity: "Visit Instance" }}
          isRequired={false}
          label="Visit Instance"
          multiple={false}
          showLabel={false}
          value={visitInstanceId.field.value}
          onChange={(value) => {
            setValue("visitInstanceId", value);
            trigger("visitType");
          }}
        />
      )}
      {visitType.field.value === "VisitBroadcast" && (
        <EntityFormControl
          errors={[]}
          hardFilters={{
            caregiverId: caregiverId.field.value,
            patientId: patientId.field.value,
          }}
          input={{ type: "entity", entity: "Visit Broadcast" }}
          isRequired={false}
          label="Visit Broadcast"
          multiple={false}
          showLabel={false}
          value={visitBroadcastId.field.value}
          onChange={(value) => {
            setValue("visitBroadcastId", value);
            trigger("visitType");
          }}
        />
      )}
    </Flex>
  );
}

export default TicketEntitySelectModalVisitsSelect;
