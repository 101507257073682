import React from "react";
import { Messages } from "../../core/api";
import { useCallbackRef } from "@chakra-ui/react";
import { tabId } from "../consts/tab-id";

const broadcastChannel = new BroadcastChannel("react-broadcast-channel");

type ReactBroadcastChannelMessage = {
  "CALL_CENTER:DECLINE": { callId: Messages["TelephonyCallId"] };
  "CALL_CENTER:ANSWER_INBOUND": { call: Messages["TelephonyCallInfo"] };
  "PHONE_CLIENT:LOGOUT": { tabId: string };
};

export default function useReactBroadcastChannel() {
  const broadcastEvent = React.useCallback(
    <T extends keyof ReactBroadcastChannelMessage>(
      eventName: T,
      payload: ReactBroadcastChannelMessage[T]
    ) => {
      console.debug(`[RBC:${tabId}] post message`, payload);
      broadcastChannel.postMessage({ type: eventName, payload, tabId });
    },
    []
  );

  const onBroadcastEvent = React.useCallback(
    <T extends keyof ReactBroadcastChannelMessage>(
      eventName: T,
      callback: (payload: ReactBroadcastChannelMessage[T]) => void
    ) => {
      const listener = (event: MessageEvent) => {
        if (event.data.type === eventName) {
          console.debug(`[RBC:${tabId}] received`, event.data);
          callback(event.data.payload);
        }
      };

      broadcastChannel.addEventListener("message", listener);

      return () => {
        broadcastChannel.removeEventListener("message", listener);
      };
    },
    []
  );

  return { broadcastEvent, onBroadcastEvent };
}

export function useReactBroadcastChannelEvent<
  $EventName extends keyof ReactBroadcastChannelMessage
>(params: {
  eventName: $EventName;
  onEvent: (payload: ReactBroadcastChannelMessage[$EventName]) => void;
}) {
  const { onBroadcastEvent } = useReactBroadcastChannel();
  const onEventCallback = useCallbackRef(params.onEvent);

  React.useEffect(() => {
    return onBroadcastEvent(params.eventName, onEventCallback);
  }, [onBroadcastEvent, onEventCallback, params.eventName]);
}
