import { graphql } from "../../shared/schema/gql";

export const GET_PATIENTS_QUERY = graphql(`
  query GetPatients(
    $order: [Patient__Order]!
    $limit: Int!
    $id: PatientId_Filter
    $offset: Int!
    $statuses: [String!]
    $offices: OfficeId_Filter
    $contracts: [ContractTypeId!]
    $teams: AgencyTeamId_Filter
    $assigned: AgencyMemberId_Filter
    $displayIdSearch: String
    $firstNameSearch: String
    $lastNameSearch: String
    $addressSearch: String
    $mobilePhoneSearch: String
    $homePhoneSearch: String
    $search: String
  ) {
    patients(
      order: $order
      limit: $limit
      id: $id
      displayIdSearch: $displayIdSearch
      offset: $offset
      status: $statuses
      currentOfficeId: $offices
      contractTypeId: $contracts
      agencyTeamId: $teams
      assignedCoordinatorId: $assigned
      addressSearch: $addressSearch
      firstNameSearch: $firstNameSearch
      lastNameSearch: $lastNameSearch
      mobilePhoneSearch: $mobilePhoneSearch
      homePhoneSearch: $homePhoneSearch
      search: $search
    ) {
      totalCount
      nodes {
        id
        displayId
        firstName
        lastName
        address
        gender
        homePhoneNumber
        mobilePhoneNumber
        dateOfBirth
        startOfCare
        customFields
        agencyTeam {
          name
        }
        assignedCoordinator {
          firstName
          lastName
        }
        status
        effectiveDate
        currentOffice {
          name
        }
        activeContracts {
          nodes {
            patientContract {
              memberId
              medicaidNumber
            }
            contractType {
              name
            }
          }
        }
        createdAt
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`);
