import { useMutation } from "@tanstack/react-query";
import { BodyOf } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";

export function useCreateIntakeTrackNote() {
  const { api } = useApi();
  return useMutation({
    mutationFn: async (params: BodyOf<"post", "./intake_flow/notes">) => {
      return await api.post("./intake_flow/notes", {
        body: params,
      });
    },
  });
}
