import { createIcon } from "@chakra-ui/react";

const FormatH2Icon = createIcon({
  displayName: "FormatH2Icon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M3 17V7h2v4h4V7h2v10H9v-4H5v4H3Zm10 0v-4q0-.825.588-1.413T15 11h4V9h-6V7h6q.825 0 1.413.588T21 9v2q0 .825-.588 1.413T19 13h-4v2h6v2h-8Z"
      fill="currentColor"
    />
  ),
});

export default FormatH2Icon;
