import { Avatar, Flex, Skeleton, Text } from "@chakra-ui/react";
import { capitalize } from "lodash";
import QueryResolver from "../../../shared/components/QueryResolver";
import useApi from "../../../shared/hooks/useApi";
import { getFullName } from "../../../shared/utils/get-full-name";
import { NoteEntity } from "./EntityNotes";

export default function EntityNoteEntityCard(props: { entity: NoteEntity }) {
  const { queries } = useApi();

  switch (props.entity.type) {
    case "Patient":
    case "PatientCalendar":
      return (
        <QueryResolver
          options={queries.patient.get(props.entity.id)}
          onError={<EntityError />}
          onLoading={<EntitySkeleton />}
          onSuccess={({ data }) => <EntityResult type="Patient" {...data} />}
        />
      );
    case "Caregiver":
    case "CaregiverCalendar":
      return (
        <QueryResolver
          options={queries.caregiver.get(props.entity.id)}
          onError={<EntityError />}
          onLoading={<EntitySkeleton />}
          onSuccess={({ data }) => <EntityResult type="Caregiver" {...data} />}
        />
      );
  }
}

function EntitySkeleton() {
  return (
    <Flex align="center" gap={2}>
      <Skeleton h={10} rounded="full" w={10} />
      <Flex direction="column" gap={1.5}>
        <Skeleton h={4} rounded="full" w={40} />
        <Skeleton h={3} rounded="full" w={24} />
      </Flex>
    </Flex>
  );
}

function EntityError() {
  return (
    <Flex align="center" gap={2}>
      <Flex align="center" fontSize="4xl" h={10} justify="center" w={10}>
        😬
      </Flex>
      <Flex direction="column" gap={1}>
        <Text fontWeight="bold" lineHeight="5">
          Something went wrong
        </Text>
        <Text color="gray" fontSize="sm" lineHeight="3">
          Failed to fetch entity information
        </Text>
      </Flex>
    </Flex>
  );
}

function EntityResult(props: {
  firstName: string;
  middleName: string | null;
  lastName: string;
  photoUrl?: string | null;
  status: string;
  type: "Caregiver" | "Patient";
}) {
  return (
    <Flex align="center" gap={2}>
      <Avatar
        fontSize="3xl"
        h={10}
        name={getFullName(props)}
        src={props.photoUrl ?? undefined}
        w={10}
      />
      <Flex direction="column" gap={1}>
        <Text fontWeight="bold" lineHeight="5">
          {getFullName(props)}
        </Text>
        <Text color="gray" fontSize="sm" lineHeight="3">
          <span>{props.type}</span>
          <span>{" • "}</span>
          <span>{capitalize(props.status)}</span>
        </Text>
      </Flex>
    </Flex>
  );
}
