import { PhoneIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import { Duration, Instant } from "@js-joda/core";
import { FaHourglassHalf } from "react-icons/fa";
import { Messages } from "../../../../core/api";
import ClockIcon from "../../../../shared/icons/ClockIcon";
import { TelephonyCallId } from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../../shared/utils/duration-formatter";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import useCommCenterCallRecording from "../../hooks/useCommCenterCallRecording";
import { formatDirection, getIconBySatisfactionRank } from "../../utils/communication-utils";
import CallTicketParticipantCard from "../CallTicketParticipantCard";
import PhoneCallStatus from "../PhoneCallStatus";
import CallNoteCard from "./CallNoteCard";
import CallNotesSection from "./CallNotesSection";
import CallTicketColumn from "./helpers/CallTicketColumn";
import CallTicketEventCard from "./helpers/CallTicketEventCard/CallTicketEventCard";
import CallTicketRow from "./helpers/CallTicketRow";
import CallTicketText from "./helpers/CallTicketText";
import { CommCenterCallTicket } from "./helpers/call-ticket-utils";
import useCallTicketNotes from "./hooks/useCallTicketNotes";

interface Props {
  callId: TelephonyCallId;
  activeTicket: CommCenterCallTicket;
  direction: "Inbound" | "Outbound";
  createdAt: Instant;
  durationSeconds: number;
  externalPhoneNumber: string | null;
  status: Messages["TelephonyCallStatus"];
  failDescription: string | null;
  participants: Messages["TelephonyCallParticipantInfo"][];
  participantEvents: Messages["TelephonyCallParticipantEvent"][];
  notes: Messages["Note"][];
  hasRecording: boolean;
}

const CompletedCallTicket = (props: Props) => {
  const notesSectionState = useCallTicketNotes({
    ticket: props.activeTicket,
    callId: props.callId,
  });

  const recordingState = useCommCenterCallRecording({
    callId: props.callId,
    hasRecording: props.hasRecording,
  });

  return (
    <Flex
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      data-component="CompletedCallTicket"
      gap={2}
      height="100%"
      justifyContent="space-between"
      padding={2}
    >
      <CallTicketColumn>
        <CallTicketRow flex={1}>
          <Flex gap={4}>
            <Flex direction="column">
              <CallTicketRow.Header>Call Details</CallTicketRow.Header>
              <CallTicketRow.Content>
                <CallTicketRow.ContentRow>
                  <PhoneIcon color="green.400" />
                  <CallTicketText>{formatDirection(props.direction)}</CallTicketText>
                </CallTicketRow.ContentRow>
                <CallTicketRow.ContentRow>
                  <ClockIcon />
                  <CallTicketText>
                    {fmap(props.createdAt, (v) => dateFormatter.toDateTime(v))}
                  </CallTicketText>
                </CallTicketRow.ContentRow>
                <CallTicketRow.ContentRow>
                  <FaHourglassHalf size="14px" />
                  <CallTicketText>
                    {durationFormatter.hours(Duration.ofSeconds(props.durationSeconds))}
                  </CallTicketText>
                </CallTicketRow.ContentRow>

                <CallTicketRow.ContentRow>
                  <PhoneIcon />
                  <CallTicketText>
                    {fmap(props.externalPhoneNumber, phoneFormatter.formatNationalIfValid) ??
                      "No Phone Number"}
                  </CallTicketText>
                </CallTicketRow.ContentRow>

                <CallTicketRow.ContentRow>
                  <PhoneCallStatus
                    callStatus={props.status}
                    failDescription={props.failDescription}
                    recordingState={recordingState}
                  />
                </CallTicketRow.ContentRow>
              </CallTicketRow.Content>
            </Flex>
            <Flex direction="column">
              <CallTicketRow.Header>Call Parties</CallTicketRow.Header>
              <CallTicketRow.Content>
                {props.participants.map((participant, i) => (
                  <CallTicketRow.ContentRow key={participant.id}>
                    <CallTicketParticipantCard index={i} participant={participant} />
                  </CallTicketRow.ContentRow>
                ))}
              </CallTicketRow.Content>
            </Flex>
          </Flex>
        </CallTicketRow>
        <CallTicketRow flex={1}>
          <CallTicketRow.Header>Call Notes</CallTicketRow.Header>
          <CallTicketRow.Content gap={4}>
            <CallNotesSection {...notesSectionState} ticketId={props.activeTicket.id} />
            {props.notes.map((note) => (
              <CallNoteCard key={note.id} note={note} />
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow flex={1}>
          <CallTicketRow.Header>Call Events</CallTicketRow.Header>
          <CallTicketRow.Content>
            {props.participantEvents.map((event) => (
              <CallTicketRow.ContentRow key={JSON.stringify(event)}>
                <CallTicketEventCard event={event} />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
      <CallTicketColumn>
        <CallTicketRow flex={1}>
          <CallTicketRow.Header>Call Summary</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallTicketText>
              {props.activeTicket.callInfo?.summary?.text ?? "No Summary"}
            </CallTicketText>
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow flex={1}>
          <Flex direction="row" justifyContent="space-between">
            <CallTicketRow.Header>Call Satisfaction</CallTicketRow.Header>
            {props.activeTicket.callInfo?.summary?.satisfaction.rankNumber &&
              getIconBySatisfactionRank(
                props.activeTicket.callInfo.summary.satisfaction.rankNumber
              )}
          </Flex>
          <CallTicketRow.Content>
            <CallTicketText>
              {props.activeTicket.callInfo?.summary?.satisfaction.rankDescription ??
                "No Description"}
            </CallTicketText>
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow flex={1}>
          <CallTicketRow.Header>Call Transcription</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallTicketText>
              {props.activeTicket.callInfo?.transcription ?? "No Transcription"}
            </CallTicketText>
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
    </Flex>
  );
};

export default CompletedCallTicket;
