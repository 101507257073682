import { FlexProps, Flex } from "@chakra-ui/react";

const CallTicketColumn = (props: FlexProps) => {
  return (
    <Flex direction="column" gap={2} width="full" {...props}>
      {props.children}
    </Flex>
  );
};

export default CallTicketColumn;
