import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import CaregiverAddressPicker from "../../../modules/caregiver/components/CaregiverAddressPicker";
import PatientAddressPicker from "../../../modules/patient/components/PatientAddressPicker";
import { useReactBridgeDispatchEvent } from "../../hooks/useReactBridgeEvent";
import { CaregiverId, PatientId } from "../../schema/schema";

type Entity =
  | {
      patientId: PatientId;
      type: "Patient";
    }
  | {
      caregiverId: CaregiverId;
      type: "Caregiver";
    };

export default function CustomizeAddressModalListener() {
  const disclosure = useDisclosure();
  const [entity, setEntity] = React.useState<Entity | undefined>(undefined);

  useReactBridgeDispatchEvent({
    eventName: "CUSTOM_ADDRESS:OPEN_MODAL",
    onEvent: ({ entity, id }) => {
      if (entity === "Patient") {
        setEntity({ patientId: id, type: "Patient" });
        disclosure.onOpen();
      } else if (entity === "Caregiver") {
        setEntity({ caregiverId: id, type: "Caregiver" });
        disclosure.onOpen();
      }
    },
  });

  if (entity === undefined) {
    return <></>;
  }

  switch (entity.type) {
    case "Patient":
      return <PatientAddressPicker disclosure={disclosure} patientId={entity.patientId} />;
    case "Caregiver":
      return <CaregiverAddressPicker caregiverId={entity.caregiverId} disclosure={disclosure} />;
  }
}
