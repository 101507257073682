import React from "react";
import { Text } from "@chakra-ui/react";

type Props = {
  currentValue: number;
  percentageChange?: number;
  showComparison?: boolean;
  description?: string;
  formatter?: (amount: number) => React.ReactNode;
};

function Identity<T>(x: T) {
  return x;
}

const percentageFormatter = Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

const PayrollVerificationTableNumberValue = (props: Props) => {
  const formatter = props.formatter ?? Identity;

  return (
    <Text as="span">
      {formatter(props.currentValue)} {props.description}{" "}
      {props.percentageChange !== undefined && props.showComparison ? (
        <Text as="span" color={props.percentageChange < 0 ? "red" : "green"} display="inline">
          {props.percentageChange > 0 ? "▲" : props.percentageChange < 0 ? "▼" : "~"}{" "}
          {percentageFormatter.format(props.percentageChange)}%
        </Text>
      ) : (
        <></>
      )}
    </Text>
  );
};

export default PayrollVerificationTableNumberValue;
