import moment from "moment";

//! @ngInject
export function medflytPassportCtrl($scope, $rootScope, itemSearchPageManager, NgTableParams, DatabaseApi, toaster, Storage, $filter, offices, $uibModal) {
    $scope.tableParams = null;
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.today = moment().set("hours", 12).set("minutes", 0).set("seconds", 0);
    $scope.currentDate = angular.copy($scope.today);
    $scope.gotData = false;

    $scope.filterBy = {
        caregiverCertification: [],
        caregiverLanguage: [],
        caregiverOffices: [],
        documentStatus: [{ id: 3 }], // The default filter is set to "Pending Review"
        caregiverStatus: [{ id: 1 }]
    };

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
        .map((certificationItem, index) => ({
            id: index,
            label: certificationItem.certification
        }));

    $scope.languages = [
        { id: 1, label: "English" },
        { id: 2, label: "Spanish" },
        { id: 3, label: "Chinese" },
        { id: 4, label: "Portuguese" },
        { id: 5, label: "French" },
        { id: 6, label: "Russian" },
        { id: 7, label: "Arabic" },
    ];

    $scope.documentStatuses = [
        { id: 1, value: "APPROVED", label: "Approved" },
        { id: 2, value: "DECLINED", label: "Rejected" },
        { id: 3, value: "PENDING", label: "Pending Review" }
    ];

    $scope.caregiverStatuses = [
        { id: 1, value: "ACTIVE", label: "Active", statusClass: "green" },
        { id: 2, value: "ON_HOLD", label: "On Hold", statusClass: "yellow" },
        { id: 3, value: "ON_LEAVE", label: "On Leave", statusClass: "orange" },
        { id: 4, value: "PENDING", label: "Pending Application", statusClass: "lightblue" },
        { id: 5, value: "SUSPENDED", label: "Inactive", statusClass: "azur" },
        { id: 6, value: "TERMINATED", label: "Terminated", statusClass: "red" },
        { id: 7, value: "QUIT", label: "Quit", statusClass: "azur" },
        { id: 8, value: "REJECTED", label: "Rejected", statusClass: "red" }
    ];
    
    $scope.offices = offices ? offices
      .filter(office => office.active)
      .map(office => ({
        id: office.id,
        label: office.name
      })) : '';

    $scope.officesComponentOptions = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true
    };

    $scope.documentTypes = [];

    $scope.caregiverStatusesMap = {};
    $scope.caregiverStatuses.forEach(status => {
        $scope.caregiverStatusesMap[status.value] = status;
    });

    $scope.globalFilter = { val: "" };
    $scope.applyGlobalSearch = function (term) {
        var filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
        } else {
            console.log('no table');
        }
    };

    function initialize() {
        if (Object.keys($scope.caregiversMap).length === 0) {
            return;
        }
        $scope.gotData = true;
        initPageManager();
        initTableColumns();
        $scope.loadItems();
    }

    var getCaregiverOffices = function(officeIds) {
        let offices = officeIds.map(function ( officeId) {
            const officeFound = $scope.offices.find(office => office.id === officeId);
            return officeFound ? officeFound.label : '';
        });
        return offices.join(", ");
    }

    function initTableColumns() {
        var columns = Storage.getObject("medflytPassportTableSettings");
        var offices = false;
        let isCertificationExists = false;
        
        if (columns && Object.keys(columns).length) {
            Object.keys(columns).forEach(function (t) {
                if (t === 'Caregiver Offices') offices = true;
                if (t === "Caregiver Certification") isCertificationExists = true;
                if (columns[t]) $scope.tableColumnsLength++;
            });

            if (!offices) columns['Caregiver Offices'] = false;

            if (isCertificationExists) {
                delete columns["Caregiver Certification"];
                columns["Caregiver Certifications"] = true;
            }

            $scope.tableColumns = columns;
        } else {
            $scope.tableColumns = {
                "Upload Date": true,
                "Document Name": true,
                "Caregiver Name": true,
                "Expiration Date": true,
                "Caregiver Offices": true,
                "View Upload": true,
                "Review Status": true,
                "Actions": true,
                "Caregiver Code": false,
                "Caregiver Branch": false,
                "Caregiver Gender": false,
                "Caregiver Birthdate": false,
                "Caregiver Languages": false,
                "Caregiver Certifications": false,
                "Caregiver Installation": false,
                "Caregiver Installed At": false,
                "Caregiver Uninstalled At": false,
                "Caregiver Last Seen": false,
                "Caregiver Address": false,
                "Caregiver Phone": false,
                "Caregiver Status": false,
                "Caregiver Hire Date": false,
                "Caregiver Area Preference": false,
                "Caregiver State Registry Number": false
            };
        }
        $scope.$watch(
            "tableColumns",
            function () {
                if ($scope.tableColumns) {
                    Storage.setObject("medflytPassportTableSettings", $scope.tableColumns);
                    $scope.tableColumnsLength = 0;
                    Object.keys($scope.tableColumns).forEach(function (t) {
                        if ($scope.tableColumns[t]) $scope.tableColumnsLength++;
                    });
                    filterTable();
                }
            },
            true
        );
    }

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/passport_documents");
        $scope.pageManager.initFromToDateParams();
    };

    var mapPassportDocuments = function (items) {
        $scope.documents = [];
        var caregiver;
        items.forEach(function (item) {
            caregiver = DatabaseApi.getCaregiverById(item.caregiver);
            if (!caregiver) {
                return;
            }
            item.offices = getCaregiverOffices(caregiver.officeIds);
            item.caregiver = caregiver;
            $scope.documents.push(item);
        });
    };

    var filterByMethods = {
        documentHasCaregiverCertification: (document, selectedCertifications) => {
            return document.caregiver.certifications.some(c => selectedCertifications.includes(c))
        },
        documentHasCaregiverLanguage: (document, selectedLanguages) => document.caregiver.languages.find(function (lang) { return selectedLanguages.indexOf(lang) !== -1; }) !== undefined,
        documentHasCaregiverOffices: (document, selectedOffices) => document.caregiver.officeIds.find(function (office) { return selectedOffices.indexOf(office) !== -1; }) !== undefined,
        documentHasStatus: (document, selectedStatuses) => selectedStatuses.indexOf(document.status) !== -1,
        documentHasCaregiverStatus: (document, selectedStatuses) => selectedStatuses.indexOf(document.caregiver.status) !== -1
    };

    function filterTable() {
        if (!$scope.documents) return;
        var filters = [];

        var selectedCaregiverCertifications = $scope.filterBy.caregiverCertification.map(function (obj) {
            return $scope.certifications.find(function (cert) { return cert.id === obj.id; }).label;
        });
        if (selectedCaregiverCertifications.length > 0) {
            filters.push(function (document) { return filterByMethods.documentHasCaregiverCertification(document, selectedCaregiverCertifications); });
        }

        var selectedCaregiverLanguages = $scope.filterBy.caregiverLanguage.map(function (obj) {
            return $scope.languages.find(function (lang) { return lang.id === obj.id; }).label;
        });
        if (selectedCaregiverLanguages.length > 0) {
            filters.push(function (document) { return filterByMethods.documentHasCaregiverLanguage(document, selectedCaregiverLanguages); });
        }

        var selectedCaregiverOffices = $scope.filterBy.caregiverOffices.map((office) => office.id);
        if (selectedCaregiverOffices.length > 0) {
            filters.push(function (document) { return filterByMethods.documentHasCaregiverOffices(document, selectedCaregiverOffices); });
        }

        var selectedDocumentStatuses = $scope.filterBy.documentStatus.map(function (obj) {
            return $scope.documentStatuses.find(function (stat) { return stat.id === obj.id; }).value;
        });
        if (selectedDocumentStatuses.length > 0) {
            filters.push(function (document) { return filterByMethods.documentHasStatus(document, selectedDocumentStatuses); });
        }

        var selectedCaregiverStatuses = $scope.filterBy.caregiverStatus.map(function (obj) {
            return $scope.caregiverStatuses.find(function (stat) { return stat.id === obj.id; }).value;
        });
        if (selectedCaregiverStatuses.length > 0) {
            filters.push(function (document) { return filterByMethods.documentHasCaregiverStatus(document, selectedCaregiverStatuses); });
        }

        var filteredDocuments = $scope.documents;
        if (filters.length > 0) {
            filteredDocuments = filteredDocuments.filter(function (document) {
                var isFiltered = true;
                for (var idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](document);
                }
                return isFiltered;
            });
        }

        initTable(filteredDocuments);
    }

    var initTable = function (documents) {
        $scope.tableData = documents;
        var options = {
            count: 25
        };
        $scope.tableParams = new NgTableParams(options, {
            counts: [],
            dataset: $scope.tableData
        });

        if ($scope.globalFilter.val) {
            $scope.applyGlobalSearch($scope.globalFilter.val);
        }
    };

    $scope.$watch('filterBy', function () {
        filterTable();
    }, true);

    $scope.getDocumentTypeById = (documentTypeId) => {
        return $scope.documentTypes.find((documentType) => documentType.id === documentTypeId);
    }

    $scope.updateDocumentTypeId = (row) => {
        const updateDocTypeIdUrl = 'agencies/' + $rootScope.agencyId + 
            '/agency_members/' + $rootScope.agencyMemberId + 
            '/passport_documents/' + row.id + 
            '/update_doc_type';
        const body = {
            documentTypeId: row.docType.id
        };
        DatabaseApi.put(updateDocTypeIdUrl, body).then((res) => {
            row.docType.name = $scope.getDocumentTypeById(row.docType.id).documentName;
            toaster.pop('success', 'Document type updated successfully');
        }, (err) => {
            toaster.pop('error', 'Oops! Something went wrong', 'Failed updating document type');
        });
    }

    $scope.viewPassportDocument = function (document) {
        var newScope = $scope.$new();
        newScope.document = document;
        newScope.url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
        + '/passport_documents/' + document.id + '/file_url';

        $uibModal.open({
            templateUrl: 'admin/views/image-viewer-modal.html',
            size: 'lg',
            controller: 'imageViewerModalCtrl',
            scope: newScope
        });
    }

    // This is the same as "addNewComplianceItem" from caregiverCompliance.js
    $scope.addNewComplianceItem = (itemId, itemName, type, caregiverId, caregiverDocumentUploadId) => {
        console.log(itemId, type, caregiverId, caregiverDocumentUploadId);
        const url = `agencies/:agencyId/agency_members/:agencyMemberId/document_types`
                        .replace(':agencyId', $rootScope.agencyId)
                        .replace(':agencyMemberId', $rootScope.agencyMemberId);

        DatabaseApi.get(url).then((res) => {
            var documentTypes = res.data.documentTypes;
            var documentType = documentTypes.find(documentType => documentType.id === itemId);

            if (documentType === undefined) {
                toaster.pop(
                    "error",
                    "Something went wrong",
                    `DocumentTypeId ${itemId} not found`
                    );
            } else {
                var fields = documentType.complianceData.fields;

                const newScope = $scope.$new();

                newScope.item = documentType;
                newScope.caregiverId = caregiverId;
                newScope.itemId = itemId;
                newScope.type = type;
                newScope.documentName = itemName;
        
                if (caregiverDocumentUploadId) {
                    newScope.caregiverDocumentUploadId = caregiverDocumentUploadId;
                }
        
                newScope.fields = fields;
        
                const modalInstance = $uibModal.open({
                    templateUrl: 'admin/modules/compliance/views/caregiver-compliance-instance-modal.html',
                    controller: 'caregiverComplianceInstanceModalCtrl',
                    size: 'lg',
                    scope: newScope,
                    windowClass: "compliance-instance-modal"
                });
        
                modalInstance.result.then(async function (res) {
                    $scope.loadItems();
                }, function () {
                });        
            }
        }, () => {
            toaster.pop(
                "error",
                "Something went wrong",
                `Error getting document_types`
                );
        });
    }

    // This is the same as "rejectComplianceItemPendingUpload" from caregiverCompliance.js
    $scope.rejectComplianceItemPendingUpload = (row) => {
        const newScope = $scope.$new();
        newScope.pendingUpload = {
            caregiverId: row.caregiver.id,
            caregiverDocumentUploadId: row.id
        };
        newScope.complianceItemName = row.category;
        newScope.caregiverDocumentTypeId = row.docType.id;

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/modules/compliance/views/reject-compliance-item-pending-upload-modal.html',
            size: 'lg',
            controller: 'rejectComplianceItemPendingUploadModalCtrl',
            scope: newScope
        });

        modalInstance.result.then(async function (res) {
            if (res === 'success') {
                $scope.loadItems();
            }
        }, function () {
        });
    }

    $scope.loadItems = function () {
        $scope.pageManager.executeSearch().then(function (response) {
            mapPassportDocuments(response.data.documents);
            filterTable();

        }, function (error) {
            toaster.pop("error", "Failed to fetch passport documents");
        });

        const getDocumentTypesUrl = 'agencies/' + $rootScope.agencyId +
            '/agency_members/' + $rootScope.agencyMemberId +
            '/passport_documents/types';
        DatabaseApi.get(getDocumentTypesUrl).then(res => {
            $scope.documentTypes = res.data.documentTypes;
        });
    };

    $scope.preOpenCaregiverModal = function (caregiverId) {
        if (!caregiverId) return;
        var caregiver = $scope.caregiversMap[caregiverId];
        $rootScope.openCaregiverModal(caregiverId, caregiver);
    };

    $scope.exportTable = function () {
        // Filter table data by global filter
        const tableData = $filter('filter')($scope.tableData, $scope.globalFilter.val);
        if (tableData.length <= 0) {
            toaster.pop("warning", "Nothing to export", "The table is empty");
            return;
        }

        const rows = [];
        const titles = [];
        for (const key in $scope.tableColumns) {
            if ($scope.tableColumns[key]) {
                titles.push(key);
            }
        }
        const idx = titles.indexOf("View Upload");
        titles.splice(idx, 1);

        rows.push(titles);
        tableData.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        tableData.forEach(function (document) {
            if (!document.caregiver) {
                return;
            }

            const row = [];
            titles.forEach(function (title) {

                let toPush = "";
                if (title === "Upload Date") toPush = document.createdAt || "";
                else if (title === "Document Name") toPush = document.docType.name || "";
                else if (title === "Caregiver Name") toPush = document.caregiver.displayName || "";
                else if (title === "Offices") toPush = document.caregiver.officeIds || "";
                else if (title === "Expiration Date") toPush = $filter("mfShortDate")(document.expiryDate) || '';
                else if (title === "Review Status") toPush = document.status || "";
                else if (title === "Caregiver Code") toPush = document.caregiver.caregiverCode || "";
                else if (title === "Caregiver Branch") toPush = document.caregiver.caregiverBranch || "";
                else if (title === "Caregiver Gender") toPush = document.caregiver.gender || "";
                else if (title === "Caregiver Birthdate") toPush = $filter("mfShortDate")(document.caregiver.birthDate) || "";
                else if (title === "Caregiver Languages") toPush = document.caregiver.languages.join(" | ") || "";
                else if (title === "Caregiver Certifications") toPush = document.caregiver.certifications.join(" | ") || "";
                else if (title === "Caregiver Installation") {
                    if (document.caregiver.confirmed === "installed") toPush = "Installed";
                    else if (document.caregiver.confirmed === "uninstalled") toPush = "Un-Installed";
                    else toPush = "Not Installed";
                }
                else if (title === "Caregiver Installed At") {
                    toPush = " ";
                    document.caregiver.appInstalledAt.forEach(function (d) {
                        if (toPush !== " ") toPush += " | ";
                        toPush += $filter("mfShortDate")(d);
                    });
                }
                else if (title === "Caregiver Uninstalled At") {
                    toPush = " ";
                    document.caregiver.appUninstalledAt.forEach(function (d) {
                        if (toPush !== " ") toPush += " | ";
                        toPush += $filter("mfShortDate")(d);
                    });
                }
                else if (title === "Caregiver Last Seen") {
                    if (document.caregiver.lastSeen === -1) toPush = "";
                    else toPush = $filter("mfShortDate")(document.caregiver.lastSeen) || "";
                }
                else if (title === "Caregiver Address") toPush = '"' + document.caregiver.address + '"' || "";
                else if (title === "Caregiver Phone") {
                    toPush = " ";
                    document.caregiver.phoneNumbers.forEach(function (p) {
                        if (toPush !== " ") toPush += " | ";
                        toPush += $filter("americanphone")(p.phoneNumber) + " (" + p.type + ")";
                    });
                }
                else if (title === "Caregiver Status") toPush = document.caregiver.status || "";
                else if (title === "Caregiver Hire Date") {
                    if (document.caregiver.hireDate === -1) toPush = "";
                    else toPush = $filter("mfShortDate")(document.caregiver.hireDate) || "";
                }
                else if (title === "Caregiver Area Preference") toPush = document.caregiver.workAreaPreference || "";
                else if (title === "Caregiver State Registry Number") toPush = document.caregiver.stateRegistryNum || "";

                row.push(toPush.toString().replace('\n', ' '));
            });
            rows.push(row);
        });

        let csvContent = "";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const blob = new Blob([csvContent], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "medflyt-passport-documents-" + $scope.getLocalDateByMoment($scope.currentDate) + ".csv");
        document.body.appendChild(link);

        link.click();
    };

    $scope.getLocalDateByMoment = function (m) {
        return m.toJSON().slice(0, 10);
    }

    $rootScope.$on("got_data", function (event) {
        if ($scope.gotData) return;
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
        initialize();
    });

    initialize();
};
