import { Center, Skeleton } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../../shared/components/Select";
import useApi from "../../../../../shared/hooks/useApi";
import { CommCenterTicket } from "../../../../communication/communication.types";
import useEditTicket from "../../../../communication/pages/CommunicationCenterTicket/mutateEditTicket";

export function VisitAssignmentTicketTeamSelect(props: { ticket: CommCenterTicket }) {
  const { queries } = useApi();

  const editTicket = useEditTicket();

  const query = useQuery({
    ...queries.communication.teams(),
    select: (data) => data.map((team) => ({ label: team.name, value: team.id })),
  });

  switch (query.status) {
    case "pending":
      return <Skeleton h={8} rounded="md" w={20} />;
    case "error":
      return <Center h={8}>Failed to load teams</Center>;
    case "success":
      return (
        <Select
          label="Team"
          multiple={false}
          options={query.data}
          size="sm"
          value={props.ticket.relatedTeam.id}
          width="fit-content"
          onChange={(x) => editTicket.mutate({ id: props.ticket.id, body: { teamId: x } })}
        />
      );
  }
}
