import { createIcon } from "@chakra-ui/react";

const PhotoFillIcon = createIcon({
  displayName: "PhotoFillIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M21 3H3C2 3 1 4 1 5v14c0 1.1.9 2 2 2h18c1 0 2-1 2-2V5c0-1-1-2-2-2zM5 17l3.5-4.5l2.5 3.01L14.5 11l4.5 6H5z"
      fill="currentColor"
    />
  ),
});

export default PhotoFillIcon;
