import { EditIcon, SearchIcon } from "@chakra-ui/icons";
import { Button, IconButton, InputGroup, InputRightElement, Tag, Wrap } from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import DataTable from "../../../../../shared/components/DataTable/DataTable";
import { DataTableEndActionFlex } from "../../../../../shared/components/DataTable/DataTableUI";
import useQueryDataTable from "../../../../../shared/components/DataTable/useQueryDataTable";
import DebouncedInput from "../../../../../shared/components/DebouncedInput";
import Link from "../../../../../shared/components/Link";
import useApi from "../../../../../shared/hooks/useApi";
import { usePageFetchingObserver } from "../../../../../shared/hooks/usePageIndication";
import { AgencyMemberId, WorkflowSkillId } from "../../../../../shared/schema/schema";

type TableRow = {
  id: AgencyMemberId;
  name: string;
  skills: WorkflowSkillId[];
};

export default function WorkflowAgencyMembersSkillsDataTable() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const { queries } = useApi();

  const queryOptions = queries.workflow.allMembersWithSkills({ search: searchQuery });

  const { dataTableProps } = useQueryDataTable({
    query: useQuery({ ...queryOptions, select: (x) => x.rows, placeholderData: keepPreviousData }),
    columns: columns,
    columnVisiblity: {},
    initialSorting: [{ id: "id", desc: true }],
  });

  usePageFetchingObserver(queryOptions);

  const filterNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by agency member name"
          value={searchQuery}
          onChange={setSearchQuery}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
    </>
  );

  return <DataTable {...dataTableProps} filterNode={filterNode} spacing="tight" />;
}

const { accessor, display } = createColumnHelper<TableRow>();

const columns = [
  accessor("id", { header: "ID" }),
  accessor("name", { header: "Name" }),
  accessor("skills", {
    header: "Skills",
    cell: (x) => <WorkflowSkillBadges skills={x.getValue()} />,
  }),

  display({
    id: "_actions",
    cell: (x) => (
      <DataTableEndActionFlex>
        <IconButton
          aria-label="Edit"
          as={Link}
          icon={<EditIcon />}
          params={{ id: x.row.original.id }}
          to="app.workflow_skills.assign.to"
          variant="outline"
        />
      </DataTableEndActionFlex>
    ),
  }),
];

function WorkflowSkillBadges(props: { skills: WorkflowSkillId[] }) {
  const skills = props.skills.slice(0, 5);
  const remaining = props.skills.slice(5);

  const [showAll, setShowAll] = React.useState(false);

  return (
    <Wrap direction="row">
      {(showAll ? props.skills : skills).map((skill) => (
        <Tag key={skill}>{skill}</Tag>
      ))}
      {showAll ? (
        <Button size="xs" variant="outline" onClick={() => setShowAll(false)}>
          Show less
        </Button>
      ) : (
        remaining.length > 0 && (
          <Button
            size="xs"
            variant="outline"
            onClick={() => setShowAll(true)}
          >{`+${remaining.length}`}</Button>
        )
      )}
    </Wrap>
  );
}
