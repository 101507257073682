import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { BodyOf, ResponseOf } from "../../../../core/api";
import ButtonWithMenu, { ButtonMenuItem } from "../../../../shared/components/ButtonWithMenu";
import useApi from "../../../../shared/hooks/useApi";
import SaveIcon from "../../../../shared/icons/SaveIcon";
import { queryKeys } from "../../../../shared/query-keys";
import { CommCenterTicketId } from "../../../../shared/schema/schema";
import { createDisclosureTriggerComponent } from "../../../../shared/utils/create-disclosure-trigger-component";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import { getSecondaryEntityFromTicket } from "../../utils/communication-utils";
import TicketEntitySelectModalBody from "./TicketEntitySelectModalBody";
import {
  TicketEntityModalSchema,
  TicketEntitySelectModalSubmitType,
  ticketEntitySelectModalSchema,
} from "./utils/ticket-entity-select-modal-form-validation";

type SubmitDataType = BodyOf<"patch", "./comm_center/tickets/:ticketId/edit">["params"];

const commonSaveIcon = <SaveIcon h={6} w={5} />;
const formSubmitMenuItems: ButtonMenuItem<TicketEntitySelectModalSubmitType>[] = [
  {
    id: "Save",
    label: "Save",
    type: "submit",
    leftIcon: commonSaveIcon,
  },
  {
    id: "SaveAndResolve",
    label: "Save & Resolve",
    type: "submit",
    leftIcon: commonSaveIcon,
  },
];

interface Props {
  ticketId: CommCenterTicketId;
  disclosure: ReturnType<typeof useDisclosure>;
}

function TicketEntitySelectModal(props: Props) {
  const { api } = useApi();
  const disclosure = props.disclosure;

  const ticketQuery = useQuery({
    queryKey: queryKeys.commCenter.get(props.ticketId),
    select: (data) => data.ticket,
    queryFn: () => {
      return api.get("./comm_center/tickets/:ticketId", {
        path: {
          ticketId: props.ticketId,
        },
      });
    },
  });

  return (
    <Modal size="3xl" {...disclosure} blockScrollOnMount={false}>
      <ModalOverlay zIndex="afterCallModal" />
      {(() => {
        switch (ticketQuery.status) {
          case "pending":
            return <LoadingContent />;
          case "error":
            return <ErrorContent error={ticketQuery.error} />;
          case "success":
            return (
              <TicketEntitySelectModalContent
                ticket={ticketQuery.data}
                onClose={disclosure.onClose}
              />
            );
        }
      })()}
    </Modal>
  );
}

const LoadingContent = () => {
  return (
    <BaseModalContent isDisabled={false} isLoading={true}>
      <Center>
        <Spinner margin={200} size="xl" />
      </Center>
    </BaseModalContent>
  );
};

function ErrorContent(props: { error: unknown }) {
  return (
    <BaseModalContent isDisabled={true} isLoading={false}>
      <Center>
        <Text>{`Error while loading ticket entities data: ${formatErrorResponse(
          props.error
        )}`}</Text>
      </Center>
    </BaseModalContent>
  );
}

function BaseModalContent(props: {
  children: React.ReactNode;
  isLoading: boolean;
  isDisabled: boolean;
  footer?: React.ReactNode;
}) {
  return (
    <ModalContent containerProps={{ zIndex: "afterCallModal" }} margin="auto">
      <ModalHeader>Who is related to this ticket?</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>{props.footer ? props.footer : null}</ModalFooter>
    </ModalContent>
  );
}

function TicketEntitySelectModalContent(props: {
  ticket: ResponseOf<"get", "./comm_center/tickets/:ticketId">["ticket"];
  onClose: () => void;
}) {
  const { api } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const useFormMethods = useForm<TicketEntityModalSchema>({
    mode: "onChange",
    resolver: zodResolver(ticketEntitySelectModalSchema),
    defaultValues: {
      patientId: props.ticket.relatedPatient?.id ?? null,
      caregiverId: props.ticket.relatedCaregiver?.id ?? null,
      phonebookContactId: props.ticket.relatedPhonebookContact?.id ?? null,
      visitInstanceId: props.ticket.relatedVisitInstance?.id ?? null,
      visitBroadcastId: props.ticket.relatedVisitBroadcast?.id ?? null,
      primaryEntityType:
        props.ticket.topic === "NotIdentifiedPhoneNumber" ||
        props.ticket.topic === "NotIdentifiedEmailEntity"
          ? null
          : props.ticket.topic,
      secondaryEntityType: getSecondaryEntityFromTicket(props.ticket)?.type ?? null,
      visitType:
        props.ticket.relatedVisitInstance !== null
          ? "VisitInstance"
          : props.ticket.relatedVisitBroadcast !== null
          ? "VisitBroadcast"
          : null,
      submitType: "Save",
    },
  });

  const editTicket = useMutation({
    mutationFn: (params: SubmitDataType) => {
      return api.patch("./comm_center/tickets/:ticketId/edit", {
        path: {
          ticketId: props.ticket.id,
        },
        body: {
          params,
        },
      });
    },
    onSuccess: () => {
      toast({
        title: `Successfuly updated ticket ${props.ticket.id}`,
        status: "success",
        position: "top-right",
      });
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.get(props.ticket.id) });
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.search.K });
      props.onClose();
    },
    onError: (error) => {
      toast({
        title: `Could not update ticket ${props.ticket.id}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const onSubmit: SubmitHandler<TicketEntityModalSchema> = (data: TicketEntityModalSchema) => {
    const {
      primaryEntityType,
      secondaryEntityType,
      visitType,
      patientId,
      caregiverId,
      phonebookContactId,
      visitBroadcastId,
      visitInstanceId,
      submitType,
    } = data;

    if (primaryEntityType === null) return;

    const caregiver =
      primaryEntityType === "Caregiver" || secondaryEntityType === "Caregiver" ? caregiverId : null;
    const patient =
      primaryEntityType === "Patient" || secondaryEntityType === "Patient" ? patientId : null;
    const phonebookContact =
      primaryEntityType === "PhonebookContact" || secondaryEntityType === "PhonebookContact"
        ? phonebookContactId
        : null;

    const transformedData: SubmitDataType = {
      topic: primaryEntityType,
      caregiverId: caregiver,
      patientId: patient,
      phonebookContactId: phonebookContact,
      relatedVisitInstanceId: visitType === "VisitInstance" ? visitInstanceId : null,
      relatedVisitBroadcastId: visitType === "VisitBroadcast" ? visitBroadcastId : null,
      status: submitType === "SaveAndResolve" ? "RESOLVED" : undefined,
    };

    editTicket.mutate(transformedData);
  };

  const { handleSubmit, getValues, setValue, trigger } = useFormMethods;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseModalContent
        footer={
          <>
            <Button variant="ghost" onClick={props.onClose}>
              Cancel
            </Button>
            <ButtonWithMenu
              activeButtonId={getValues("submitType")}
              buttonGroupProps={{
                colorScheme: "blue",
                variant: "solid",
              }}
              menuItems={formSubmitMenuItems}
              onChangeActiveButton={(item) => {
                setValue("submitType", item.id);
                trigger("submitType");
              }}
            />
          </>
        }
        isDisabled={false}
        isLoading={false}
      >
        <FormProvider {...useFormMethods}>
          <TicketEntitySelectModalBody />
        </FormProvider>
      </BaseModalContent>
    </form>
  );
}

export const TicketEntitySelectModalTrigger = createDisclosureTriggerComponent<{
  ticketId: CommCenterTicketId;
}>({
  displayName: "TicketEntitySelectModalTrigger",
  render: (props) => (
    <TicketEntitySelectModal disclosure={props.disclosure} ticketId={props.ticketId} />
  ),
});

export default TicketEntitySelectModal;
