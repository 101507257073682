import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BodyOf } from "../../core/api";
import { queryKeys } from "../query-keys";
import { PatientId } from "../schema/schema";
import { loadable } from "../utils/loadable";
import useApi from "./useApi";

const usePatientStaffingPreferences = (patientId: PatientId) => {
  const { api } = useApi();
  const queryClient = useQueryClient();
  const patientStaffingPreferences = useQuery({
    queryKey: queryKeys.patient.patientStaffingPreferences(patientId),
    queryFn: () =>
      api.get("./patients/:patientId/staffing_preferences", {
        path: {
          patientId: patientId,
        },
      }),
  });

  const updateStaffingPreferences = useMutation({
    mutationFn: (params: BodyOf<"put", "./patients/:patientId/staffing_preferences">) =>
      api.put("./patients/:patientId/staffing_preferences", {
        path: {
          patientId: patientId,
        },
        body: params,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.patient.patientStaffingPreferences(patientId),
      });
    },
  });

  return {
    patientStaffingPreferences: loadable.fromQuery(patientStaffingPreferences),
    onUpdateStaffingPreferences: updateStaffingPreferences.mutate,
  };
};

export default usePatientStaffingPreferences;
