import { Button, ButtonProps } from "@chakra-ui/react";
import { useSmsSender } from "../hooks/useSmsSender";
import { ChatIcon } from "@chakra-ui/icons";
import { SmsSenderProps } from "./SmsSender/sms-sender.types";

export type SendSMSButtonProps = SmsSenderProps & {
  buttonProps?: ButtonProps;
  text?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export default function SendSMSButton(props: SendSMSButtonProps) {
  const { onOpen } = useSmsSender();

  return (
    <Button
      color="blue.500"
      fontSize="12px"
      isDisabled={props.isDisabled ?? false}
      isLoading={props.isLoading ?? false}
      leftIcon={<ChatIcon />}
      variant="ghost"
      onClick={() => onOpen(props)}
      {...props.buttonProps}
    >
      {props.text ?? "Send SMS"}
    </Button>
  );
}
