import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  UseDisclosureReturn,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { BodyOf, Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";
import { CaregiverId, CommCenterTicketId, PatientId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import useCallState from "../../call-center/hooks/useCallState";
import EntityFormControl from "../../workflow/components/EntityFormControl";

interface Props {
  patientId: PatientId;
  track: Messages["PatientIntakeTrack"];
  disclosure: UseDisclosureReturn;
}

type ActionType = "phone-number-and-all-tickets" | "just-current-call";
type SelectedEntity =
  | {
      type: "Patient";
      id: PatientId | null;
    }
  | {
      type: "Caregiver";
      id: CaregiverId | null;
    };
type NonNullableSelectedEntity = SelectedEntity & { id: NonNullable<SelectedEntity["id"]> };
type EditPatientEntityOnTicketParams = {
  entity: NonNullableSelectedEntity;
  currentCallTicketId: CommCenterTicketId;
};

function isEntitySelected(entity: SelectedEntity): entity is NonNullableSelectedEntity {
  return entity.id !== null;
}

function isPatientInTrackUnknown(track: Messages["PatientIntakeTrack"]) {
  return track.code === "UNKNOWN" || track.code === "UNKNOWN_SELF_SERVE";
}

export default function TransferFromIntakePatientModal(props: Props) {
  const { api, queries } = useApi();
  const toast = useToast();
  const { stateService } = useRouter();
  const [selectedEntity, setSelectedEntity] = React.useState<SelectedEntity>({
    type: "Patient",
    id: null,
  });
  const [actionType, setActionType] = React.useState<ActionType>("phone-number-and-all-tickets");
  const [state] = useCallState();
  const isActiveCallExists = state.status === "Active";
  const queryClient = useQueryClient();

  const handleChangeRadio = (val: ActionType) => {
    setActionType(val);
  };

  const editTicket = useMutation({
    mutationFn: (params: EditPatientEntityOnTicketParams) => {
      return api.patch("./comm_center/tickets/:ticketId/edit", {
        path: {
          ticketId: params.currentCallTicketId,
        },
        body: {
          params: {
            caregiverId: params.entity.type === "Caregiver" ? params.entity.id : null,
            patientId: params.entity.type === "Patient" ? params.entity.id : null,
            topic: params.entity.type === "Caregiver" ? "Caregiver" : "Patient",
          },
        },
      });
    },
    onSuccess: (_, variables) => {
      toast({
        title: `Successfuly updated ticket`,
        status: "success",
        position: "top-right",
      });

      if (variables.entity.type === "Patient") {
        queryClient.invalidateQueries(queries.intake.profile(variables.entity.id));
        stateService.go("app.patients.intake-flow", {
          patientId: variables.entity.id,
        });
      } else {
        queryClient.invalidateQueries(queries.caregiver.get(variables.entity.id));
        props.disclosure.onClose();
      }

      queryClient.invalidateQueries({
        queryKey: queryKeys.commCenter.get(variables.currentCallTicketId),
      });
      queryClient.invalidateQueries(queries.intake.profile(props.patientId));
    },
    onError: (error) => {
      toast({
        title: `Could not update ticket`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const transferFromPatient = useMutation({
    mutationFn: (body: BodyOf<"post", "./intake/transfer">) => {
      return api.post("./intake/transfer", {
        body,
      });
    },
    onSuccess: (_, variables) => {
      toast({
        title: `Successfuly transfered phone numbers and tickets`,
        status: "success",
        position: "top-right",
      });

      if (variables.to.type === "Patient") {
        queryClient.invalidateQueries(queries.intake.profile(variables.to.id));
        stateService.go("app.patients.intake-flow", {
          patientId: variables.to.id,
        });
      } else {
        queryClient.invalidateQueries(queries.caregiver.get(variables.to.id));
        props.disclosure.onClose();
      }

      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.search.K });
      queryClient.invalidateQueries(queries.intake.profile(variables.fromPatientId));
    },
    onError: (error) => {
      toast({
        title: `Could not transfer phone numbers and tickets`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleClickDone = () => {
    if (isEntitySelected(selectedEntity)) {
      if (selectedEntity.type === "Patient" && props.patientId === selectedEntity.id) {
        toast({
          title: `Can't connect a patient with itself. Please select two different patients.`,
          status: "warning",
          position: "top-right",
        });
        return;
      }

      if (actionType === "phone-number-and-all-tickets") {
        transferFromPatient.mutate({
          fromPatientId: props.patientId,
          to: selectedEntity,
        });
      } else if (actionType === "just-current-call" && isActiveCallExists) {
        editTicket.mutate({
          currentCallTicketId: state.currentCall.ticket.id,
          entity: selectedEntity,
        });
      }
    }
  };

  const selectPatient = (patientId: PatientId | null) => {
    setSelectedEntity({ type: "Patient", id: patientId });
  };

  const selectCaregiver = (caregiverId: CaregiverId | null) => {
    setSelectedEntity({ type: "Caregiver", id: caregiverId });
  };

  const isDoneButtonDisabled = selectedEntity.id === null;

  return (
    <Modal size="xl" {...props.disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transfer From Intake Patient</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex flexDirection="column" gap={5} justifyContent="space-between">
            <Flex
              alignItems="flex-start"
              flexDirection="column"
              gap={3}
              justifyContent="space-between"
            >
              <EntityFormControl
                errors={[]}
                input={{ type: "entity", entity: "Patient" }}
                label="Patient"
                multiple={false}
                renderHint={false}
                showLabel={false}
                value={selectedEntity.type === "Patient" ? selectedEntity.id : null}
                onChange={selectPatient}
              />

              <Text as="b" ml={1}>
                OR
              </Text>

              <EntityFormControl
                errors={[]}
                input={{ type: "entity", entity: "Caregiver" }}
                label="Patient"
                multiple={false}
                renderHint={false}
                showLabel={false}
                value={selectedEntity.type === "Caregiver" ? selectedEntity.id : null}
                onChange={selectCaregiver}
              />
            </Flex>
            <RadioGroup value={actionType} onChange={handleChangeRadio}>
              <VStack alignItems="flex-start">
                <Radio value="phone-number-and-all-tickets">
                  <Text fontWeight="normal">
                    Connect all numbers from this patient to the selected entity, alongside all
                    existing tickets
                  </Text>
                </Radio>
                <Radio isDisabled={!isActiveCallExists} value="just-current-call">
                  <Text fontWeight="normal">
                    Connect just the current call to the selected entity
                  </Text>
                </Radio>
              </VStack>
            </RadioGroup>
            {!isPatientInTrackUnknown(props.track) && (
              <Alert status="warning">
                <AlertIcon />
                This patient is in an advance intake status. Transfering from this patient will move
                them to LOST. Please be certain that this action is necessary.
              </Alert>
            )}
            {isActiveCallExists && (
              <Alert status="warning">
                <AlertIcon />
                The information presented regarding the active call will update once the call is
                completed and the page is refreshed
              </Alert>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={props.disclosure.onClose}>
            Close
          </Button>
          <Button colorScheme="blue" isDisabled={isDoneButtonDisabled} onClick={handleClickDone}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
