import { FaPause, FaPlay } from "react-icons/fa";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import useAudio from "./useAudio";
import Backward15SecondsIcon from "../../icons/Backward15SecondsIcon";
import Forward15SecondsIcon from "../../icons/Forward15SecondsIcon";
import CallTicketText from "../../../modules/communication/components/CallTicket/helpers/CallTicketText";

interface Props {
  src: string;
  ratesOptions?: number[];
}

const defaultRates = [0.25, 0.5, 1, 1.5, 2];

const AudioPlayer = ({ src, ratesOptions = defaultRates }: Props) => {
  const {
    isPlaying,
    isLoaded,
    currentTimeSeconds,
    durationSeconds,
    playbackRate,
    pause,
    play,
    setPlaybackRate,
    setPosition,
  } = useAudio(src);

  const handleClickForward15Secs = () => {
    setPosition(Math.min(currentTimeSeconds + 15, durationSeconds));
  };

  const handleClickBackward15Secs = () => {
    setPosition(Math.max(currentTimeSeconds - 15, 0));
  };

  const updateRate = () => {
    const newRate =
      ratesOptions[(ratesOptions.findIndex((x) => x === playbackRate) + 1) % ratesOptions.length];
    setPlaybackRate(newRate);
  };

  const handleSliderChange = (n: number) => {
    pause();
    setPosition(n);
  };

  const handleSliderChangeEnd = (n: number) => {
    setPosition(n);
    play();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <Flex align="center" gap={{ "2xl": 4, base: 2 }}>
      <Backward15SecondsIcon
        boxSize={{ "2xl": 5, base: 4 }}
        cursor="pointer"
        onClick={handleClickBackward15Secs}
      />
      {isPlaying ? (
        <FaPause cursor="pointer" onClick={pause} />
      ) : (
        <FaPlay cursor="pointer" onClick={play} />
      )}
      <Slider
        focusThumbOnChange={false}
        max={durationSeconds}
        min={0}
        step={0.1}
        value={currentTimeSeconds}
        width={{ "2xl": "150px", base: "80px" }}
        onChange={handleSliderChange}
        onChangeEnd={handleSliderChangeEnd}
      >
        <SliderTrack>
          <SliderFilledTrack bg="blue.500" cursor="pointer" />
        </SliderTrack>
        <SliderThumb bg="black" cursor="pointer" />
      </Slider>
      <CallTicketText cursor="pointer" onClick={updateRate}>
        <b>x{playbackRate}</b>
      </CallTicketText>
      <Forward15SecondsIcon
        boxSize={{ "2xl": 5, base: 4 }}
        cursor="pointer"
        onClick={handleClickForward15Secs}
      />
    </Flex>
  );
};

export default AudioPlayer;
