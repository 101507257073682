import { Box, Text } from "@chakra-ui/react";
import Page from "./Page";

export default function UnauthorizedPage() {
  return (
    <Page>
      <Page.Header>
        <Page.Title>Unauthorized</Page.Title>
      </Page.Header>
      <Page.Content>
        <Box bg="red.50" color="red" fontWeight="medium" p={6} rounded="lg">
          <Text>You are not allowed to view this page</Text>
        </Box>
      </Page.Content>
    </Page>
  );
}
