import { Button, ButtonGroup, IconButton, VisuallyHidden } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { MdClose, MdUpload } from "react-icons/md";
import invariant from "tiny-invariant";
import { humanFileSize } from "../utils/common";
import { toBase64 } from "../utils/to-base64";
import LoadingButton from "./LoadingButton";

export default function Base64FileSelectionButton(props: { onChange: (base64: string) => void }) {
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const processFile = useMutation({
    mutationFn: async (e: React.ChangeEvent<HTMLInputElement>) => {
      invariant(e.target.files?.length === 1, "Expected exactly one file to be selected");

      const file = e.target.files[0];
      const base64 = await toBase64(file);

      props.onChange?.(base64);

      return {
        file: file,
        base64: base64,
        name: `${file.name} (${humanFileSize(file.size)})`,
      };
    },
  });

  switch (processFile.status) {
    case "idle":
      return (
        <>
          <Button
            leftIcon={<MdUpload />}
            variant="ghost"
            onClick={() => inputFileRef.current?.click()}
          >
            Upload a file
          </Button>

          <VisuallyHidden>
            <input ref={inputFileRef} type="file" onChange={processFile.mutate} />
          </VisuallyHidden>
        </>
      );
    case "pending":
      return <LoadingButton />;

    case "error":
      return (
        <Button colorScheme="red" variant="link" onClick={processFile.reset}>
          Error processing file. Try again
        </Button>
      );

    case "success":
      return (
        <ButtonGroup isAttached>
          <Button color="gray" leftIcon={<MdUpload />}>
            {processFile.data.name}
          </Button>
          <IconButton aria-label="Remove file" icon={<MdClose />} onClick={processFile.reset} />
        </ButtonGroup>
      );
  }
}
