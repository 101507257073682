import { Messages } from "../../../core/api";
import { createLocalFilters } from "../../../shared/hooks/useLocalFilters";
import { getTicketUnreadCount, getTopicEntityFromTicket } from "./communication-utils";

export type CommCenterLocalFilters = {
  entity: string;
  awaitingResponse: "Caregiver" | "Agency" | "All";
  unread: boolean;
};

export const commCenterTableLocalFilters = createLocalFilters<
  Messages["CommCenterTicket"],
  CommCenterLocalFilters
>({
  entity: {
    filter: (ticket, current) => {
      const relatedEntity = getTopicEntityFromTicket(ticket);
      const lowered: string = current.toLocaleLowerCase();

      if (relatedEntity === null) {
        return false;
      }

      switch (relatedEntity.type) {
        case "Caregiver":
        case "Patient": {
          const term =
            `${relatedEntity.fullName} ${relatedEntity.displayId} ${relatedEntity.id}`.toLowerCase();
          return term.includes(lowered);
        }
        case "Agency Member": {
          const term = `${relatedEntity.fullName} ${relatedEntity.id}`.toLowerCase();
          return term.includes(lowered);
        }
        case "NotIdentifiedPhoneNumber": {
          const term = `${relatedEntity.phoneNumber}`.toLowerCase();
          return term.includes(lowered);
        }
        case "PhonebookContact": {
          const term = `${relatedEntity.name}`.toLowerCase();
          return term.includes(lowered);
        }
        case "NotIdentifiedEmailEntity": {
          const term = `${relatedEntity.email}`.toLowerCase();
          return term.includes(lowered);
        }
        case "FaxNotAssignedEntity":
        case "CommCenterTicket":
        case "IntakePatient":
        case "VisitInstance":
        case "VisitBroadcast":
          return false;
      }
    },
  },
  awaitingResponse: {
    filter: (ticket, currentFilter) =>
      (currentFilter === "Agency" && ticket.messages.at(-1)?.createdBy.type === "Caregiver") ||
      (currentFilter === "Caregiver" &&
        ["Agency Member", "System"].includes(ticket.messages.at(-1)?.createdBy.type ?? "")) ||
      currentFilter === "All",
  },
  unread: {
    filter: (ticket, current) => {
      if (!current) {
        return true;
      }

      return (
        getTicketUnreadCount(ticket) > 0 ||
        (ticket.source === "PHONE_CALL" && ticket.callInfo?.status === "Missed")
      );
    },
  },
});
