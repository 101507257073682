import { AddressComponents } from "../../../../../public/admin/scripts/messages/address_component";
import { Messages } from "../../../core/api";
import { CustomAddress } from "./AddressPickerModal";

export const buildAddressComponentsFromCustomAddress = (
  address: CustomAddress
): AddressComponents => {
  return {
    country: "US",
    location: {
      lat: address.lat,
      lng: address.lng,
    },
    administrativeAreaLevel1: address.state,
    administrativeAreaLevel2: address.county,
    sublocalityLevel1: address.city,
    locality: address.city,
    formatedAddressDetails: {
      city: address.city,
      state: address.state,
      county: address.county,
      street: address.street,
      streetNumber: address.streetNumber,
      address1: `${address.streetNumber} ${address.street}`,
      zip5: address.zip,
      country: "US",
      location: {
        lat: address.lat,
        lng: address.lng,
      },
      fullAddress: buildCustomAddressTextString(address),
      zip4: null,
      address2: null,
    },
    neighborhood: null,
  };
};

export const buildCustomAddressTextString = (address: CustomAddress): string => {
  return `${address.streetNumber} ${address.street}, ${address.city}, ${address.state} ${address.zip}, USA`;
};

export const parseAddress = (
  address: string,
  addressComponents?: AddressComponents | null
): CustomAddress => {
  const addressDetails = parseAddressText(address);
  return {
    lat: addressComponents?.location.lat ?? 0,
    lng: addressComponents?.location.lng ?? 0,
    street: addressComponents?.formatedAddressDetails?.street ?? addressDetails.street,
    streetNumber:
      addressComponents?.formatedAddressDetails?.streetNumber ?? addressDetails.streetNumber,
    state: addressComponents?.administrativeAreaLevel1 ?? addressDetails.state,
    county: addressComponents?.administrativeAreaLevel2 ?? addressDetails.county,
    city:
      addressComponents?.sublocalityLevel1 ??
      addressComponents?.locality ??
      addressComponents?.formatedAddressDetails?.city ??
      addressDetails.city,
    zip: addressComponents?.formatedAddressDetails?.zip5 ?? addressDetails.zip,
  };
};

export const parseCaregiverAddress = (
  address: string,
  addressComponents?: AddressComponents | null,
  addressGeoLocation?: Messages["Caregiver"]["addressGeoLocation"]
): CustomAddress => {
  const parsedAddress = parseAddress(address, addressComponents);
  return {
    ...parsedAddress,
    lat: addressComponents?.location.lat ?? addressGeoLocation?.lat ?? 0,
    lng: addressComponents?.location.lng ?? addressGeoLocation?.lng ?? 0,
  };
};

export const parsePatientAddress = (
  address: string,
  timezone: string,
  addressComponents?: AddressComponents | null
): CustomAddress => {
  const parsedAddress = parseAddress(address, addressComponents);
  return {
    ...parsedAddress,
    timezone,
  };
};

// address example 1337 M L King Pedestrian Way, Chester, PA 19013, USA
const parseAddressText = (address: string) => {
  const components = address.split(", ");

  const streetComponents = components[0].split(" ");
  const streetNumber = streetComponents[0];
  const street = streetComponents.slice(1).join(" ");

  const city = components[1];

  const stateZipComponents = components[2].split(" ");
  const state = stateZipComponents[0];
  const zip = stateZipComponents[1];

  const county = "";

  return {
    street,
    streetNumber,
    city,
    state,
    county,
    zip,
  };
};

export const getDefaultCustomAddress = (): CustomAddress => {
  return {
    lat: 0,
    lng: 0,
    street: "",
    streetNumber: "",
    state: "NY",
    county: "",
    city: "",
    zip: "",
  };
};
