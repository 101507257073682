import { createIcon } from "@chakra-ui/react";

const LastPageIcon = createIcon({
  displayName: "LastPageIcon",
  viewBox: "0 0 24 24",
  path: (
    <path d="m7 18l-1.4-1.4l4.6-4.6l-4.6-4.6L7 6l6 6l-6 6Zm9 0V6h2v12h-2Z" fill="currentColor" />
  ),
});

export default LastPageIcon;
