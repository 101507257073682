import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";
import { CaregiverId, NoteFileId, NoteId, PatientId } from "../../shared/schema/schema";

export default createQueryGroup(({ api }) => ({
  get: (noteId: NoteId) =>
    queryOptions({
      queryKey: ["notes", "get", noteId],
      queryFn: () => api.get("./notes/:noteId", { path: { noteId } }),
    }),
  file: (noteFileId: NoteFileId) =>
    queryOptions({
      queryKey: ["notes", "file", noteFileId],
      queryFn: () => api.get("./notes/files/:noteFileId", { path: { noteFileId } }),
    }),
  settings: () =>
    queryOptions({
      queryKey: ["notes", "settings"],
      queryFn: () => api.get("./notes_settings", {}),
    }),
  patientNotes: (patientId: PatientId) =>
    queryOptions({
      queryKey: ["notes", "patient", patientId],
      queryFn: () => api.get("./patient/:patientId/notes", { path: { patientId } }),
    }),
  caregiverNotes: (caregiverId: CaregiverId) =>
    queryOptions({
      queryKey: ["notes", "caregiver", caregiverId],
      queryFn: () => api.get("./caregiver/:caregiverId/notes", { path: { caregiverId } }),
    }),
}));
