import { Flex } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import Select from "../../../../shared/components/Select";
import EntityFormControl from "../../../workflow/components/EntityFormControl";
import { TicketEntityModalSchema } from "./utils/ticket-entity-select-modal-form-validation";

interface Props {
  entityKind: "Primary" | "Secondary";
  handleChangeEntityType: (val: string | undefined) => void;
  hidePatientSelect?: boolean;
  hideCaregiverSelect?: boolean;
  hidePhonebookContactSelect?: boolean;
}

const TicketEntitySelectInput = (props: Props) => {
  const { trigger, setValue, control } = useFormContext<TicketEntityModalSchema>();

  const entityKind = props.entityKind === "Primary" ? "primaryEntityType" : "secondaryEntityType";
  const entityType = useController({ name: entityKind, control });
  const patientId = useController({ name: "patientId", control });
  const caregiverId = useController({ name: "caregiverId", control });
  const phonebookContactId = useController({ name: "phonebookContactId", control });

  const entitySelectOptions = [
    ...(props.hidePatientSelect ? [] : [{ label: "Patient", value: "Patient" }]),
    ...(props.hideCaregiverSelect ? [] : [{ label: "Caregiver", value: "Caregiver" }]),
    ...(props.hidePhonebookContactSelect
      ? []
      : [{ label: "Phonebook Contact", value: "PhonebookContact" }]),
  ];
  return (
    <Flex flexDirection="column" gap={5}>
      <Select
        allowUnselect={props.entityKind === "Secondary"}
        label={`${props.entityKind} Entity Type`}
        multiple={false}
        options={entitySelectOptions}
        searchable={false}
        value={entityType.field.value}
        onChange={props.handleChangeEntityType}
      />
      {!props.hidePatientSelect && entityType.field.value === "Patient" && (
        <EntityFormControl
          errors={[]}
          input={{ type: "entity", entity: "Patient" }}
          isRequired={false}
          label="Patient"
          multiple={false}
          showLabel={false}
          value={patientId.field.value}
          onChange={(value) => {
            setValue("patientId", value);
            trigger(entityKind);
          }}
        />
      )}
      {!props.hideCaregiverSelect && entityType.field.value === "Caregiver" && (
        <EntityFormControl
          errors={[]}
          input={{ type: "entity", entity: "Caregiver" }}
          isRequired={false}
          label="Caregiver"
          multiple={false}
          showLabel={false}
          value={caregiverId.field.value}
          onChange={(value) => {
            setValue("caregiverId", value);
            trigger(entityKind);
          }}
        />
      )}
      {!props.hidePhonebookContactSelect && entityType.field.value === "PhonebookContact" && (
        <EntityFormControl
          errors={[]}
          input={{ type: "entity", entity: "Phonebook Contact" }}
          isRequired={false}
          label="Phonebook Contact"
          multiple={false}
          showLabel={false}
          value={phonebookContactId.field.value}
          onChange={(value) => {
            setValue("phonebookContactId", value);
            trigger(entityKind);
          }}
        />
      )}
    </Flex>
  );
};

export default TicketEntitySelectInput;
