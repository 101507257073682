import { Box, chakra, Flex } from "@chakra-ui/react";
import { LocalDate } from "@js-joda/core";
import React from "react";
import BaseDatePicker, { BaseDatePickerProps } from "./BaseDatePicker";
import RangeDatePickerRanges from "./RangeDatePickerRanges";

const CalendarsBox = chakra(Box, {
  baseStyle: {
    display: "flex",
    width: "fit-content",
    gap: 3,
  },
});

export interface RangeDatePickerProps extends Omit<BaseDatePickerProps, "onChange"> {
  onChange: (
    date: [LocalDate, LocalDate | null] | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => void;
}

const RangeDatePicker = (props: RangeDatePickerProps) => {
  return (
    <BaseDatePicker
      {...props}
      calendarContainer={({ children }) => {
        return (
          <Flex gap={8}>
            <RangeDatePickerRanges
              endDate={props.endDate}
              startDate={props.startDate}
              onClear={(e) => props.onChange(null, e)}
              onClick={props.onChange}
            />
            <CalendarsBox>
              <>{children}</>
            </CalendarsBox>
          </Flex>
        );
      }}
      monthsShown={props.monthsShown ?? 2}
      selectsRange={true}
    />
  );
};

export default RangeDatePicker;
