/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Ajv, { ValidateFunction } from "ajv";
import { revivers } from "./schema-revivers";

const validators = new Map<string, ValidateFunction>();

const ajv = new Ajv({ strict: false });

ajv.addKeyword({
  keyword: "xreviver",
  post: true,
  modifying: true,
  validate: (keyword, value, _declaration, ctx) => {
    if (ctx === undefined) {
      throw new Error("ctx is undefined");
    }

    if (!(keyword in revivers)) {
      throw new Error(`Unknown reviver: ${keyword}`);
    }

    ctx.parentData[ctx.parentDataProperty] = revivers[keyword](value);
    return true;
  },
});

async function getMessages() {
  const { messages, pathsToMessage } = (await import("./messages.json")) as any;
  return { messages, pathsToMessage };
}

async function getOrSetValidator(method: string, path: Path) {
  const fullPath = `${method} ${path}` as string;

  if (validators.has(fullPath)) {
    return validators.get(fullPath)!;
  }

  const { messages, pathsToMessage } = await getMessages();

  const messageName = pathsToMessage[fullPath];
  const messageSchema = (messages as any)[messageName];

  if (messageSchema) {
    const validator = ajv.compile(messageSchema);
    validators.set(fullPath, validator);
    return validator;
  }

  return;
}

type Path = string;

class InvalidResponseError extends Error {
  readonly detail: unknown;
  readonly responseJSON: unknown;
  readonly errorJSON: unknown;

  constructor(params: {
    message: string;
    detail: string;
    responseJSON: unknown;
    errorJSON: unknown;
  }) {
    super(params.message);
    this.detail = params.detail;
    this.responseJSON = params.responseJSON;
    this.errorJSON = params.errorJSON;
  }

  toJSON() {
    return {
      detail: this.detail,
      responseJSON: this.responseJSON,
      errorJSON: this.errorJSON,
    };
  }
}

async function getSocketMessageValidator(socketName: string) {
  const validatorKey = socketName;

  let validator = validators.get(validatorKey);
  if (validator) return validator;

  const { messages } = await getMessages();

  const message = (() => {
    const socketEvents = (messages as any)?.SocketEvents;

    if (!socketEvents) {
      return;
    }

    const { properties, definitions } = socketEvents;

    const reference = properties?.[socketName];

    if (reference === undefined) {
      return;
    }

    const messageName = reference.$ref.split("/").pop();

    const definition = definitions?.[messageName];

    if (definition === undefined) {
      return;
    }

    return {
      ...definition,
      definitions: definitions,
    };
  })();

  if (message) {
    validator = ajv.compile(message);
    validators.set(validatorKey, validator); // Cache the compiled validator
  }

  return validator;
}

export async function withValidator<T>(
  params:
    | {
        method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
        url: string;
        data: T;
      }
    | { socketName: string; data: T }
): Promise<T> {
  const validate =
    "method" in params
      ? await getOrSetValidator(params.method, params.url)
      : await getSocketMessageValidator(params.socketName);

  if (validate !== undefined && validate(params.data) !== true) {
    throw new InvalidResponseError({
      message: `JSON schema validation error ${JSON.stringify(validate.errors)}`,
      detail: "",
      responseJSON: params.data,
      errorJSON: validate.errors,
    });
  }

  return params.data;
}

export async function withSocketEventValidator<T extends object>(params: {
  validate: boolean;
  socketName: string;
  data: T;
}) {
  if (params.validate) {
    return withValidator({
      socketName: params.socketName,
      data: JSON.parse(JSON.stringify(params.data)),
    });
  }

  return params.data;
}
