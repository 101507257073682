import { ArrowBackIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Grid,
  GridProps,
  Highlight,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Portal,
  Skeleton,
  Text,
  TextProps,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useInput } from "rooks";
import { WorkflowInputForm } from "../../../modules/workflow/components/WorkflowForm";
import useWorkflowDefinitions from "../../../modules/workflow/hooks/useWorkflowDefinitions";
import { ListedWorkflow } from "../../../modules/workflow/workflow.types";
import useApi from "../../hooks/useApi";
import { useGlobalWorkflowRunner } from "../../hooks/useGlobalWorkflowRunner";
import { useReactBridgeDispatchEvent } from "../../hooks/useReactBridgeEvent";
import PlayCircleIcon from "../../icons/PlayCircleIcon";
import WorkflowsPlayIcon from "../../icons/WorkflowsPlayIcon";
import ErrorPage from "../ErrorPage";

export default function GlobalWorkflowRunner() {
  const [selectedWorkflow, setSelectedWorkflow] = React.useState<ListedWorkflow | null>(null);
  const runner = useGlobalWorkflowRunner();
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      runner.close();
      setSelectedWorkflow(null);
    },
    onOpen: runner.open,
    isOpen: runner.isOpen,
  });

  const handleComplete = () => {
    setSelectedWorkflow(null);
    onClose();
  };

  useReactBridgeDispatchEvent({
    eventName: "GLOBAL_WORKFLOW_RUNNER:OPEN",
    onEvent: () => onOpen(),
  });

  useReactBridgeDispatchEvent({
    eventName: "GLOBAL_WORKFLOW_RUNNER:SET_HINTS",
    onEvent: ({ hints }) => runner.setHints(hints),
  });

  const modalContainerRef = React.useRef(null);

  return (
    <Portal>
      <IconButton
        aria-label="Add"
        icon={<WorkflowsPlayIcon fontSize="2xl" />}
        left={4}
        position="absolute"
        top={4}
        zIndex="popover"
        onClick={onOpen}
      />

      <Box ref={modalContainerRef} position="relative" zIndex="workflowRunnerModal" />

      <Modal
        isOpen={isOpen}
        portalProps={{ containerRef: modalContainerRef }}
        size="xl"
        onClose={onClose}
      >
        <ModalOverlay />
        {selectedWorkflow === null ? (
          <WorkflowSelectionModalContent onClick={setSelectedWorkflow} />
        ) : (
          <WorkflowFormModalContent
            workflow={selectedWorkflow}
            onClickGoBack={() => setSelectedWorkflow(null)}
            onComplete={handleComplete}
          />
        )}
      </Modal>
    </Portal>
  );
}

function WorkflowSelectionModalContent(props: { onClick: (workflow: ListedWorkflow) => void }) {
  const search = useInput();
  const workflowsQuery = useWorkflowDefinitions();

  const filterWorkflows = (workflows: ListedWorkflow[]) => {
    const searchValue = search.value.toLowerCase();

    return workflows.filter((workflow) => {
      return workflow.meta.published && workflow.name.toLowerCase().includes(searchValue);
    });
  };

  return (
    <ModalContent>
      <ModalHeader>
        <Text>Run Workflow</Text>
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.500" />
          </InputLeftElement>
          <Input placeholder="Search workflow" {...search} />
        </InputGroup>

        {(() => {
          switch (workflowsQuery.status) {
            case "pending":
              return (
                <WorkflowDefinitionCards>
                  <Skeleton h="63.5px" rounded="md" />
                  <Skeleton h="63.5px" rounded="md" />
                  <Skeleton h="63.5px" rounded="md" />
                  <Skeleton h="63.5px" rounded="md" />
                </WorkflowDefinitionCards>
              );
            case "error":
              return (
                <ErrorPage
                  error={workflowsQuery.error}
                  resetErrorBoundary={workflowsQuery.refetch}
                />
              );
            case "success":
              return (
                <WorkflowDefinitionCards>
                  {filterWorkflows(workflowsQuery.data).map((workflow) => (
                    <WorkflowDefinitionCard
                      key={workflow.id}
                      onClick={() => props.onClick(workflow)}
                    >
                      <WorkflowDefinitionTitle>
                        <Highlight query={search.value} styles={{ p: 0, bg: "blue.100" }}>
                          {workflow.name}
                        </Highlight>
                      </WorkflowDefinitionTitle>

                      <WorkflowDefinitionDescription>
                        {workflow.description}
                      </WorkflowDefinitionDescription>
                    </WorkflowDefinitionCard>
                  ))}
                </WorkflowDefinitionCards>
              );
          }
        })()}
      </ModalBody>
    </ModalContent>
  );
}

function WorkflowDefinitionTitle(props: TextProps) {
  return (
    <Text color="black" fontSize="md" fontWeight="bold" textTransform="uppercase" {...props} />
  );
}

function WorkflowDefinitionDescription(props: TextProps) {
  return <Text color="gray.500" fontSize="md" {...props} />;
}

function WorkflowDefinitionCards(props: GridProps) {
  return <Grid gap={2} my={2} templateColumns="repeat(1, 1fr)" {...props} />;
}

function WorkflowDefinitionCard(props: FlexProps) {
  return (
    <Flex
      _focusVisible={{ bg: "blue.50", borderColor: "blue.500", transition: "none" }}
      _hover={{ bg: "blue.50", borderColor: "blue.500", transition: "none" }}
      as="button"
      borderColor="gray.200"
      borderWidth="1px"
      cursor="pointer"
      direction="column"
      gap={1}
      outlineColor="blue.500"
      p={5}
      rounded="md"
      textAlign="start"
      transition="all 0.2s"
      {...props}
    />
  );
}

function WorkflowFormModalContent(props: {
  workflow: ListedWorkflow;
  onClickGoBack: () => void;
  onComplete: () => void;
}) {
  const { mutations } = useApi();
  const toast = useToast();
  const workflowRunner = useGlobalWorkflowRunner();

  const runWorkflow = useMutation({
    ...mutations.runWorkflow(props.workflow.id),
    onSuccess: () => {
      toast({ position: "top", description: "Workflow started", status: "success" });
      props.onComplete();
    },
    onError: (error) => toast({ position: "top", description: `${error}`, status: "error" }),
  });

  return (
    <ModalContent>
      <ModalHeader>
        <IconButton
          aria-label="Go back"
          icon={<ArrowBackIcon fontSize="xl" />}
          mb={2}
          rounded="full"
          onClick={props.onClickGoBack}
        />
        <Text>
          Run Workflow
          <Text as="span" color="gray.400" px={2}>
            &raquo;
          </Text>
          <Text as="span" color="blue.500">
            {props.workflow.name}
          </Text>
        </Text>

        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <WorkflowInputForm
          fields={props.workflow.input}
          fileUploadDestination={{ by: "workflow-definition", id: props.workflow.id }}
          hints={workflowRunner.hints}
          renderButton={() => (
            <Button
              colorScheme="blue"
              leftIcon={<PlayCircleIcon />}
              ms="auto"
              type="submit"
              variant="solid"
            >
              Run
            </Button>
          )}
          onSubmit={runWorkflow.mutate}
        />
      </ModalBody>
    </ModalContent>
  );
}
