import { Box, Flex, SlideFade, Text } from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
};

const ClosedEmailThreadMessage = (props: Props) => {
  return (
    <SlideFade in={props.isOpen} style={{ zIndex: 10 }}>
      <Box bg="gray.100" color="black" opacity="80%" p="30px" rounded="md" shadow="md">
        <Flex alignItems="center" direction="column" justifyContent="center">
          <Text fontSize="xl">This ticket is resolved.</Text>
          <Text fontSize="lg">
            If you want to continue the email thread, please create a new ticket.
          </Text>
        </Flex>
      </Box>
    </SlideFade>
  );
};

export default ClosedEmailThreadMessage;
