import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { BodyOf, Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import useControlledForm from "../../../shared/hooks/useControlledForm";
import { CaregiverId, PatientId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import AttachedNotes from "./AttachedNotes";

const zEmailForm = z.object({
  to: z.string().nonempty().email(),
  recipientName: z.string().nonempty(),
  subject: z.string().nonempty(),
  message: z.string().nonempty(),
});

type Props = {
  disclosure: ReturnType<typeof useDisclosure>;
  entity:
    | { type: "Patient" | "PatientCalendar"; id: PatientId; name: string }
    | { type: "Caregiver" | "CaregiverCalendar"; id: CaregiverId; name: string };
  notes: Messages["EntityNote"][];
};

export default function EmailNoteModal({ disclosure, entity, notes }: Props) {
  const { api } = useApi();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: (
      body:
        | BodyOf<"post", "./caregivers/:caregiverId/send_notes_by_email">
        | BodyOf<"post", "./patients/:patientId/send_notes_by_email">
    ) => {
      switch (entity.type) {
        case "Caregiver":
        case "CaregiverCalendar":
          return api.post("./caregivers/:caregiverId/send_notes_by_email", {
            path: { caregiverId: entity.id },
            body: body,
          });

        case "Patient":
        case "PatientCalendar":
          return api.post("./patients/:patientId/send_notes_by_email", {
            path: { patientId: entity.id },
            body: body,
          });
      }
    },
    onSuccess: () => {
      disclosure.onClose();

      toast({
        title: "Email sent",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error sending email",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const getInitialValues = (): Partial<z.infer<typeof zEmailForm>> => {
    const subject = (() => {
      const type = `${entity.type}-${notes.length > 1 ? "multiple" : "single"}-note` as const;

      switch (type) {
        case "Caregiver-multiple-note":
        case "CaregiverCalendar-multiple-note":
          return `You have received ${notes.length} notes regarding ${entity.name}, please review`;
        case "Caregiver-single-note":
        case "CaregiverCalendar-single-note":
          return `You have received a note regarding ${entity.name}, please review`;
        case "Patient-multiple-note":
        case "PatientCalendar-multiple-note":
          return `You have received ${notes.length} notes regarding ${entity.name}, please review`;
        case "Patient-single-note":
        case "PatientCalendar-single-note":
          return `You have received a note regarding ${entity.name}, please review`;
      }
    })();

    return {
      to: "",
      recipientName: "",
      subject: subject,
      message: "",
    };
  };

  const form = useControlledForm({
    schema: zEmailForm,
    initialValues: getInitialValues(),
    onSuccess: (data) => {
      mutation.mutate({
        body: data.message,
        subject: data.subject,
        email: data.to,
        name: data.recipientName,
        noteIds: notes.map((note) => note.id),
      });
    },
  });

  return (
    <Modal {...disclosure} isCentered={true} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={form.onSubmit}>
          <ModalHeader>
            {notes.length === 1 && <Text>Send a note</Text>}
            {notes.length > 1 && <Text>Send {notes.length} notes</Text>}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={4} w="full">
              <FormControl isInvalid={form.isInvalid("to")}>
                <FormLabel>To:</FormLabel>
                <Input
                  placeholder="Email"
                  type="email"
                  value={form.state.to}
                  onChange={(e) => form.setValue("to", e.target.value)}
                />
                <FormErrorMessage>{form.getError("to")}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={form.isInvalid("recipientName")}>
                <FormLabel>Recipient name:</FormLabel>
                <Input
                  placeholder="Name"
                  value={form.state.recipientName}
                  onChange={(e) => form.setValue("recipientName", e.target.value)}
                />
                <FormErrorMessage>{form.getError("recipientName")}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={form.isInvalid("subject")}>
                <FormLabel>Subject:</FormLabel>
                <Input
                  placeholder="Subject"
                  value={form.state.subject}
                  onChange={(e) => form.setValue("subject", e.target.value)}
                />
                <FormErrorMessage>{form.getError("subject")}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={form.isInvalid("message")}>
                <FormLabel>Message:</FormLabel>
                <Textarea
                  value={form.state.message}
                  onChange={(e) => form.setValue("message", e.target.value)}
                />
                <FormErrorMessage>{form.getError("message")}</FormErrorMessage>
              </FormControl>
            </Flex>

            <Box mt={4}>
              <AttachedNotes notes={notes} />
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={disclosure.onClose}>Cancel</Button>
              <Button colorScheme="blue" isLoading={mutation.isPending} type="submit">
                Send
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
