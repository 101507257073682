import { UseQueryResult } from "@tanstack/react-query";
import ErrorBox from "../../../../shared/components/ErrorBox";
import { CommunicationLogSkeleton } from "./CommunicationLogSkeleton";
import { Flex } from "@chakra-ui/react";
import CommunicationLog, { CommunicationLogDivider } from "./CommunicationLog";
import { Messages } from "../../../../core/api";
import { CommCenterTicketId } from "../../../../shared/schema/schema";
import React from "react";

interface Props {
  query: UseQueryResult<
    {
      tickets: Messages["CommCenterTicket"][];
      roboCalls: Messages["RobocallLogData"][];
    },
    unknown
  >;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
  emptyCommunicationsComponent?: React.ReactNode;
}

function CommunicationLogWrapper({ query, onClickTicket, emptyCommunicationsComponent }: Props) {
  switch (query.status) {
    case "error":
      return <ErrorBox error={query.error} resetErrorBoundary={query.refetch} />;
    case "pending":
      return (
        <>
          <Flex direction="column" gap={6}>
            {Array.from({ length: 9 }).map((_, index) => (
              <React.Fragment key={`CommunicationLogSkeleton-${index}`}>
                <CommunicationLogSkeleton />
                <CommunicationLogDivider />
              </React.Fragment>
            ))}
            <CommunicationLogSkeleton />
          </Flex>
        </>
      );
    case "success":
      return (
        <CommunicationLog
          communicationLog={query.data.tickets}
          emptyCommunicationsComponent={emptyCommunicationsComponent}
          roboCallsLog={query.data.roboCalls}
          onClickTicket={onClickTicket}
        />
      );
  }
}

export default CommunicationLogWrapper;
