import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Shimmer } from "react-shimmer";
import useCacheSignedUrlQuery from "../hooks/useCacheSignedUrlQuery";
import ArrowRightOnRectangle20SolidIcon from "../icons/ArrowRightOnRectangle20SolidIcon";
import ExpandIcon from "../icons/ExpandIcon";

type Props = React.IframeHTMLAttributes<HTMLIFrameElement> & {
  src: string;
  width: number;
  height: number;
  fallback?: React.ReactNode;
};

const CachedSignedDocument = (props: Props) => {
  const { width, height, src, fallback, ...rest } = props;
  const query = useCacheSignedUrlQuery(src);

  if (query.isPending) {
    return <>{fallback}</>;
  }

  if (query.data !== undefined) {
    return (
      <Flex borderRadius="lg" display="inline-flex">
        <Flex direction="column" gap="2">
          <Iframe height={height} src={query.data} width={width} {...rest} border="none" />

          <ButtonGroup spacing={1}>
            <IconButton
              aria-label="Open in new tab"
              as="a"
              href={query.data}
              icon={<ArrowRightOnRectangle20SolidIcon />}
            />
            <DocumentModal src={query.data} />
          </ButtonGroup>
        </Flex>
      </Flex>
    );
  }

  return null;
};

export const CachedSignedDocumentWithShimmer = (
  props: Omit<Props, "w" | "h"> & { width: number; height: number }
) => {
  return (
    <CachedSignedDocument
      {...props}
      fallback={<Shimmer height={props.height} width={props.width} />}
    />
  );
};

function DocumentModal(props: { src: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton aria-label="Expand" icon={<ExpandIcon />} onClick={onOpen} />

      <Modal isOpen={isOpen} size="full" onClose={onClose}>
        <ModalOverlay />
        <ModalContent h="full">
          <ModalBody h="full">
            <Flex
              alignItems="center"
              direction="column"
              gap={4}
              h="full"
              justifyContent="center"
              pb={12}
            >
              <Iframe h="full" src={props.src} w="full" />
              <Button colorScheme="blue" mr={3} size="lg" onClick={onClose}>
                Close
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const Iframe = chakra("iframe");

export default CachedSignedDocument;
