import { useToast } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { z } from "zod";
import { BodyOf, Messages, ResponseOf } from "../../../../core/api";
import { Entity, EntityWithStatus } from "../../../../shared/components/EntityCard";
import { withPathParams } from "../../../../shared/consts/hoc";
import useApi from "../../../../shared/hooks/useApi";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import usePreSignUpload from "../../../../shared/hooks/usePreSignUpload";
import usePreSignedUrl from "../../../../shared/hooks/usePreSignedUrl";
import { queryKeys } from "../../../../shared/query-keys";
import {
  AgencyMemberId,
  CommCenterEmailThreadId,
  CommCenterMessageId,
  CommCenterTeamId,
  CommCenterTeamMemberId,
  CommCenterTicketId,
} from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import { getFullName } from "../../../../shared/utils/get-full-name";
import { Loadable, loadable } from "../../../../shared/utils/loadable";
import { optimisticUpdate } from "../../../../shared/utils/optimistic-update";
import useCommCenterTicket from "../../hooks/useTicket";
import {
  AttachmentMimeType,
  CommCenterTicketStatus,
  MessagePayload,
  NewTicketRequestBody,
  buildAttachmentsPayload,
  getCommCenterTeamMemberIdByAgencyMemberId,
  getPayloadTypeByMimeType,
  getSecondaryEntityFromTicket,
  getTopicEntityFromTicket,
  transformCommCenterTickets,
} from "../../utils/communication-utils";
import CommunicationCenterTicketPage from "./CommunicationCenterTicketPage";

export type TransferCallType =
  | {
      type: "AgencyMember";
      agencyMemberId: AgencyMemberId;
    }
  | {
      type: "PhoneNumber";
      phoneNumber: string;
    };

export type AddToCallType =
  | {
      type: "AgencyMember";
      agencyMemberId: AgencyMemberId;
    }
  | {
      type: "PhoneNumber";
      phoneNumber: string;
    };

const pathParamsSchema = z.object({
  ticketId: z.string().transform((x) => CommCenterTicketId.parse(parseInt(x))),
});

export type EditTicketRequest = {
  ticketId: CommCenterTicketId;
  editParams: EditTicketFields;
};

export type CreateMessageRequest = {
  ticketId: CommCenterTicketId;
  message: string;
  attachments: MessagePayload[];
};

export type SubmitEmailMessage = Omit<
  BodyOf<"post", "./comm_center/email/threads/:threadId/messages">,
  "attachments"
> & {
  threadId: CommCenterEmailThreadId;
  ticketId: CommCenterTicketId;
};

export type CreateEmailMessageRequest = SubmitEmailMessage & {
  attachments: BodyOf<"post", "./comm_center/email/threads/:threadId/messages">["attachments"];
};

export type EditTicketFields = Partial<{
  assignedToId: CommCenterTeamMemberId | null;
  teamId: CommCenterTeamId;
  status: CommCenterTicketStatus;
}>;

type TicketRouteProps = {
  pathParams: z.infer<typeof pathParamsSchema>;
};

const CommunicationCenterTicketRoute = (props: TicketRouteProps) => {
  const { api } = useApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { stateService } = useRouter();
  const { agencyMember } = useAuthData();
  const [attachments, setAttachments] = React.useState<File[]>([]);
  const preSignedFiles = usePreSignedUrl(attachments);
  const uploadAttachments = usePreSignUpload();

  const handleRemoveAttachment = (attachment: File) => {
    setAttachments(attachments.filter((i) => i !== attachment));
  };

  const handleRemoveAllAttachments = () => {
    setAttachments([]);
  };

  const handleAddFile = (newFile: File) => {
    setAttachments([...attachments, newFile]);
  };

  const ticket = useCommCenterTicket(props.pathParams.ticketId, { markAsRead: true });

  const topicQueryParam = fmap(ticket.data?.ticket, getTopicQueryParam) ?? {};

  const relatedOpenTickets = useQuery({
    enabled: ticket.data !== undefined ? undefined : false,
    queryKey: queryKeys.commCenter.search(topicQueryParam),
    queryFn: () => {
      return api.get("./comm_center/tickets", {
        query: {
          ...topicQueryParam,
          isAgencyResponseEnabled: false,
        },
      });
    },
    placeholderData: keepPreviousData,
    select: (response) => transformCommCenterTickets(response.tickets),
  });

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => {
      return api.get("./comm_center/teams", {});
    },
    placeholderData: keepPreviousData,
  });

  const submitMessage = useMutation({
    mutationFn: async (createParams: CreateMessageRequest) => {
      return api.post("./comm_center/tickets/:commCenterTicketId/message", {
        path: {
          commCenterTicketId: createParams.ticketId,
        },
        body: {
          message: [
            {
              type: "TEXT",
              message: createParams.message,
            },
            ...createParams.attachments,
          ],
        },
      });
    },
    onMutate: async (createParams) => {
      const tickets = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          const ticket = draft.tickets.find((ticket) => ticket.id === createParams.ticketId);

          if (ticket !== undefined) {
            mutateSubmitMessageTicket({ ticket, body: createParams });
          }
        },
      });

      const ticket = optimisticUpdate<{ ticket: Messages["CommCenterTicket"] }>({
        queryClient,
        queryKey: queryKeys.commCenter.get(createParams.ticketId),
        update: (draft) => mutateSubmitMessageTicket({ ticket: draft.ticket, body: createParams }),
      });

      return { tickets, ticket };
    },
    onError: (error, _newChatMessage, context) => {
      queryClient.setQueriesData({ queryKey: queryKeys.commCenter.search.K }, context?.tickets);
      queryClient.setQueriesData(
        { queryKey: queryKeys.commCenter.get(props.pathParams.ticketId) },
        context?.ticket
      );

      toast({
        title: "Error submitting message",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => {
      return api.get("./comm_center/labels", {});
    },
    placeholderData: keepPreviousData,
    select: (response) => response.labels,
  });

  const markAsUnread = useMutation({
    mutationFn: (ticketId: CommCenterTicketId) => {
      return api.post("./comm_center/tickets/:ticketId/unread", {
        path: {
          ticketId: ticketId,
        },
      });
    },
    onMutate: () => {
      return optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets
            .find((ticket) => ticket.id === props.pathParams.ticketId)
            ?.messages.forEach((message) => (message.readAt = null));
        },
      });
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKeys.commCenter.search.K, context?.previousValue);
      toast({
        title: "Error marking ticket as unread",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: () => {
      stateService.go("app.commcenter");

      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.commCenter.get(props.pathParams.ticketId),
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.search.K });
      }, 500);
    },
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: NewTicketRequestBody) => {
      return api.post(
        "/agencies/:agencyId/comm_center_team_members/:commCenterTeamMemberId/comm_center/tickets",
        {
          path: {
            commCenterTeamMemberId:
              getCommCenterTeamMemberIdByAgencyMemberId(teams.data?.teams ?? [], agencyMember.id) ??
              CommCenterTeamMemberId.parse(0),
          },
          body: newTicketRequest,
        }
      );
    },
    onSuccess: (response) => {
      stateService.go("app.commcenter_ticket", { ticketId: response.ticketId });
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleClickTicket = (newTicketId: CommCenterTicketId) => {
    stateService.go("app.commcenter_ticket", { ticketId: newTicketId });
  };

  const handleSuccessCreateEmailTicket = (
    response: ResponseOf<"post", "./comm_center/email/threads">
  ) => {
    stateService.go("app.commcenter_ticket", { ticketId: response.ticket.id });
  };

  const handleSubmitNewMessage = async (ticketId: CommCenterTicketId, message: string) => {
    handleRemoveAllAttachments();
    const attachments = buildAttachmentsPayload(preSignedFiles);
    await uploadAttachments.mutateAsync(preSignedFiles.flatMap((a) => (a.data ? a.data : [])));
    submitMessage.mutate({ ticketId, message, attachments });
  };

  const handleCreateNewTicket = (newTicketRequest: NewTicketRequestBody) => {
    createTicket.mutate(newTicketRequest);
  };

  const primaryEntity = ((): Loadable<EntityWithStatus<Entity> | null> => {
    return ticket.data === undefined
      ? loadable.loading()
      : loadable.resolve(getTopicEntityFromTicket(ticket.data.ticket));
  })();

  const secondaryEntity = ((): Loadable<EntityWithStatus<Entity> | undefined> => {
    return ticket.data === undefined
      ? loadable.loading()
      : loadable.resolve(getSecondaryEntityFromTicket(ticket.data.ticket));
  })();

  function mutateSubmitMessageTicket(params: {
    ticket: Messages["CommCenterTicket"];
    body: CreateMessageRequest;
  }) {
    const { ticket, body } = params;

    ticket.messages.push({
      id: CommCenterMessageId.parse(Instant.now().toEpochMilli() * -1),
      createdBy: {
        type: "Agency Member",
        id: agencyMember.id,
        name: getFullName(agencyMember),
        photoUrl: agencyMember.photoUrl,
      },
      payload: [
        {
          type: "TEXT",
          message: body.message,
        },
        ...attachments.map((attachment) => ({
          type: getPayloadTypeByMimeType(attachment.type as AttachmentMimeType),
          url: URL.createObjectURL(attachment),
        })),
      ],
      labelId: null,
      readAt: null,
      messageActionId: null,
      ticketId: body.ticketId,
      createdAt: Instant.now(),
    });
  }

  return (
    <CommunicationCenterTicketPage
      attachments={attachments}
      initialLabelId={
        labels.data?.find((label) => label.parent === null && !label.active)?.id ?? null
      }
      labels={labels.data?.filter((label) => label.active) ?? []}
      primaryEntity={primaryEntity}
      relatedTickets={loadable.fromUndefined(relatedOpenTickets.data)}
      secondaryEntity={secondaryEntity}
      teams={teams.data?.teams ?? []}
      ticket={ticket.data?.ticket ?? null}
      onClickMarkAsUnread={markAsUnread.mutate}
      onClickRemoveAttachment={handleRemoveAttachment}
      onClickTicket={handleClickTicket}
      onCreateNewTicket={handleCreateNewTicket}
      onSelectFile={handleAddFile}
      onSubmitNewMessage={handleSubmitNewMessage}
      onSuccessCreateEmailTicket={handleSuccessCreateEmailTicket}
    />
  );
};

function getTopicQueryParam(ticket: Messages["CommCenterTicket"]) {
  switch (true) {
    case ticket.topic === "Caregiver" && ticket.relatedCaregiver !== null:
      return { caregiverId: [ticket.relatedCaregiver.id] };

    case ticket.topic === "Patient" && ticket.relatedPatient !== null:
      return { patientId: [ticket.relatedPatient.id] };

    case ticket.topic === "NotIdentifiedPhoneNumber" &&
      ticket.relatedNotIdentifiedPhoneNumber !== null:
      return { relatedNotIdentifiedPhoneNumber: [ticket.relatedNotIdentifiedPhoneNumber] };

    case ticket.topic === "PhonebookContact" && ticket.relatedPhonebookContact !== null:
      return { phonebookContactId: [ticket.relatedPhonebookContact.id] };

    case ticket.topic === "NotIdentifiedEmailEntity" &&
      ticket.relatedNotIdentifiedEmailEntity !== null:
      return { relatedNotIdentifiedEmailEntity: [ticket.relatedNotIdentifiedEmailEntity] };

    default: {
      console.error(`Invalid ticket topic: ${ticket.id}`);
      return null;
    }
  }
}

export default withPathParams(CommunicationCenterTicketRoute, pathParamsSchema);
