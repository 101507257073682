import { createIcon } from "@chakra-ui/react";

const ReplyRoundedIcon = createIcon({
  displayName: "ReplyRoundedIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m6.825 12l2.9 2.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-4.6-4.6q-.3-.3-.3-.7t.3-.7l4.6-4.6q.275-.275.688-.275T9.7 5.7q.3.3.3.713t-.3.712L6.825 10H16q2.075 0 3.538 1.463T21 15v3q0 .425-.288.713T20 19q-.425 0-.713-.288T19 18v-3q0-1.25-.875-2.125T16 12H6.825Z"
      fill="currentColor"
    />
  ),
});

export default ReplyRoundedIcon;
