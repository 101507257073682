import { createIcon } from "@chakra-ui/react";

const Backward15SecondsIcon = createIcon({
  displayName: "Backward15SecondsIcon",
  viewBox: "0 0 24 24",
  path: (
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path d="M3 13a9 9 0 1 0 9-9M9 9v7" />
      <path d="M15 9h-2a1 1 0 0 0-1 1v1.5a1 1 0 0 0 1 1h1a1 1 0 0 1 1 1V15a1 1 0 0 1-1 1h-2m0-12H4.5m0 0l2-2m-2 2l2 2" />
    </g>
  ),
});

export default Backward15SecondsIcon;
