import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

export interface TicketSummaryDisclosureState {
  summaryText: string;
  satisfactionText: string;
  satisfactionRank: number;
}

const defualtState: TicketSummaryDisclosureState = {
  satisfactionRank: 0,
  satisfactionText: "",
  summaryText: "",
};

const useTicketSummaryDisclosure = () => {
  const disclosure = useDisclosure();
  const [state, setState] = useState<TicketSummaryDisclosureState>(defualtState);

  const handleOpen = (state: TicketSummaryDisclosureState) => {
    setState(state);
    disclosure.onOpen();
  };

  return {
    disclosureProps: {
      ...disclosure,
      onOpen: handleOpen,
    },
    disclosureState: state,
  };
};

export default useTicketSummaryDisclosure;
