import Page from "../../../../../shared/components/Page";
import EditSkillsRoute from "../EditSkillsRoute";
import WorkflowAgencyMembersSkillsDataTable from "./WorkflowAgencyMembersSkillsDataTable";

export default function WorkflowAgencyMembersSkillsTab() {
  return (
    <>
      <Page.Header>
        <Page.Title>Assignments</Page.Title>
      </Page.Header>

      <Page.Content p={0}>
        <WorkflowAgencyMembersSkillsDataTable />
      </Page.Content>
      <EditSkillsRoute />
    </>
  );
}
