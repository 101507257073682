import { DownloadIcon } from "@chakra-ui/icons";
import {
  ComponentWithAs,
  Flex,
  IconButton,
  Image,
  ImageProps,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useDownloadImage } from "../hooks/useDownloadImage";

const ImageModal = (props: {
  src: string;
  width: number;
  height: number;
  element?: React.ElementType;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ImageComponent = (props.element ?? Image) as ComponentWithAs<"img", ImageProps>;
  const { download } = useDownloadImage();

  return (
    <Flex alignItems="center" justifyContent="center">
      <ImageComponent
        cursor="pointer"
        height={props.height}
        src={props.src}
        width={props.width}
        onClick={onOpen}
      />
      <Modal isCentered={true} isOpen={isOpen} size="5xl" onClose={onClose}>
        <ModalOverlay bgColor="whiteAlpha.800" />
        <ModalContent bg="transparent" shadow="none" w="fit-content">
          <Flex align="center" direction="column" gap={8}>
            <ImageComponent h="100%" maxH="80vh" src={props.src} />
            <IconButton
              aria-label="download"
              colorScheme="blackAlpha"
              icon={<DownloadIcon fontSize="xl" />}
              rounded="full"
              size="lg"
              variant="outline"
              onClick={() => download(props.src)}
            />
          </Flex>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ImageModal;
