import useApi from "../../../shared/hooks/useApi";
import { PatientId } from "../../../shared/schema/schema";
import { patientRegularMessageTemplateOptions } from "../../../shared/components/SmsSender/sms-sender.consts";
import { isIntakePatientSmsable } from "../shared/utils/intake.utils";
import { useQuery } from "@tanstack/react-query";
import SendSMSButton from "../../../shared/components/SendSMSButton";
import { MessageTemplate } from "../../../shared/components/SmsSender/sms-sender.types";
import { ButtonProps } from "@chakra-ui/react";

const defaultPredefinedSmsMessageTemplate = patientRegularMessageTemplateOptions.ScheduleCall;

export default function IntakePatientSendSMSButton(props: {
  patientId: PatientId;
  predefinedPhoneNumber?: string;
  predefinedSmsMessageTemplate?: MessageTemplate;
  buttonProps?: ButtonProps;
  text?: string;
}) {
  const { queries } = useApi();

  const patient = useQuery({
    ...queries.intake.profile(props.patientId),
    select: ({ patient }) => patient,
  });

  if (patient.isError) {
    return (
      <SendSMSButton
        buttonProps={props.buttonProps}
        entity="Patient"
        isDisabled={true}
        isLoading={false}
        patientId={props.patientId}
        predefinedMessageTemplate={props.predefinedSmsMessageTemplate}
        predefinedPhoneNumber={props.predefinedPhoneNumber}
        text="Error while loading SMS button"
      />
    );
  }

  const isSmsButtonDisabled = !patient.isSuccess || !isIntakePatientSmsable(patient.data);

  return (
    <SendSMSButton
      buttonProps={props.buttonProps}
      entity="Patient"
      isDisabled={isSmsButtonDisabled}
      isLoading={patient.isLoading}
      patientId={props.patientId}
      predefinedMessageTemplate={
        props.predefinedSmsMessageTemplate ?? defaultPredefinedSmsMessageTemplate
      }
      predefinedPhoneNumber={props.predefinedPhoneNumber}
      text={props.text}
    />
  );
}
