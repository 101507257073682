import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { IntakeTrackId } from "../../../../../shared/schema/schema";
import { Messages } from "../../../../../core/api";
import React from "react";
import Select from "../../../../../shared/components/Select";
import invariant from "tiny-invariant";

interface Props {
  disclosure: UseDisclosureReturn;
  tracks: Messages["IntakeTrackForFullFlow"][];
  onClickConfirm: (trackId: IntakeTrackId) => void;
  onClickCancel: () => void;
}

const CopyStepModal = (props: Props) => {
  const [selectedTrack, setSelectedTrack] = React.useState<IntakeTrackId | undefined>(undefined);

  return (
    <Modal {...props.disclosure} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Copy step to another track</ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={4} w="full">
            <Text>Please choose what track to move this step to</Text>
            <Select
              label="Select track"
              multiple={false}
              options={props.tracks.map((t) => ({
                label: t.label,
                value: t.id,
              }))}
              value={selectedTrack ?? null}
              onChange={(value) => setSelectedTrack(value)}
            />
          </Flex>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            colorScheme="blue"
            isDisabled={selectedTrack === undefined}
            onClick={() => {
              invariant(selectedTrack !== undefined, "selectedTrack is undefined");
              props.onClickConfirm(selectedTrack);
            }}
          >
            Confirm
          </Button>
          <Button variant="ghost" onClick={props.onClickCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CopyStepModal;
