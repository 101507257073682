import { Box } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import ErrorBox from "../../../shared/components/ErrorBox";
import useControlledForm from "../../../shared/hooks/useControlledForm";
import { EntityNoteFormData, zEntityNoteForm } from "../utils/note-form";
import EntityNoteFormControls from "./EntityNoteFormControls";
import useNotesEntitySettingsQuery from "../hooks/useNotesEntitySettingsQuery";

type Props = {
  noteTypes: Messages["NoteTypes"][];
  isAnswerInvalid: boolean;
  isMessageInvalid: boolean;
  onChange?: (form: Partial<EntityNoteFormData>) => void;
  onSuccess?: (form: EntityNoteFormData) => void;
};

export default function UncontrolledEntityNotesFormControls(props: Props) {
  const query = useNotesEntitySettingsQuery({ noteTypes: props.noteTypes });

  return (
    <Box borderColor="gray.200" borderWidth="1px" p={4} rounded="md">
      {(() => {
        switch (query.status) {
          case "pending":
            return <EntityNoteFormControls.Skeleton />;
          case "error":
            return <ErrorBox error={query.error} resetErrorBoundary={query.refetch} />;
          case "success":
            return <BaseUncontrolledEntityNoteFormControls {...props} settings={query.data} />;
          default:
            return null;
        }
      })()}
    </Box>
  );
}

function BaseUncontrolledEntityNoteFormControls(
  props: Props & { settings: Messages["EntityNoteSetting"] }
) {
  const form = useControlledForm({
    schema: zEntityNoteForm(props.settings),
    onChange: props.onChange,
    onSuccess: props.onSuccess,
  });

  React.useEffect(() => {
    form.setError.call(
      null,
      "notePredefinedAnswerId",
      props.isAnswerInvalid ? "Please select a reason" : undefined
    );
  }, [form.setError, props.isAnswerInvalid]);

  React.useEffect(() => {
    form.setError.call(
      null,
      "richText",
      props.isMessageInvalid ? "Please enter a message" : undefined
    );
  }, [form.setError, props.isMessageInvalid]);

  return (
    <EntityNoteFormControls
      form={form}
      isReasonDisabled={false}
      noteTypes={props.noteTypes}
      settings={props.settings}
    />
  );
}
