import { AuthPermissionGuard } from "../../../../shared/components/AuthPermissionGuard";
import Page, { PageTab } from "../../../../shared/components/Page";
import IntakeDashboardRoute from "./IntakeDashboardRoute";
import IntakePlanReferralsRoute from "./IntakePlanReferralsRoute";

const tabs: PageTab[] = [
  {
    header: "Intake Patients",
    route: "app.patients.intake.dashboard",
    component: <IntakeDashboardRoute />,
  },
  {
    header: "Plan Referrals",
    route: "app.patients.intake.plans",
    component: (
      <AuthPermissionGuard permission="view_patient_page_intake">
        <IntakePlanReferralsRoute />
      </AuthPermissionGuard>
    ),
  },
];

export default function IntakeRoute() {
  return <Page.Tabbed tabs={tabs} />;
}
