import { CardHeader, Skeleton, SkeletonProps, VStack } from "@chakra-ui/react";

export default function CardHeaderSkeleton(props: { descriptionW?: SkeletonProps["width"] }) {
  return (
    <CardHeader>
      <VStack align="flex-start" spacing={2}>
        <Skeleton h={4} w={16} />
        <Skeleton h={5} w={props.descriptionW ?? "md"} />
      </VStack>
    </CardHeader>
  );
}
