import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import React from "react";
import { Messages } from "../../../../../core/api";
import ErrorBox from "../../../../../shared/components/ErrorBox";
import useApi from "../../../../../shared/hooks/useApi";
import useSocketEvent from "../../../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../../../shared/query-keys";
import { NoteId, PatientId, WorkflowTaskInstanceId } from "../../../../../shared/schema/schema";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";
import { isDefined } from "../../../../../utils";
import useCallCenter from "../../../../call-center/hooks/useCallCenter";
import { usePhoneNumberDialerPopup } from "../../../../call-center/hooks/usePhoneNumberDialerPopup";
import NewNoteModal from "../../../../note/components/NewNoteModal";
import IntakePatientProfileNotesSection from "../../../../patientIntake/components/IntakePatientProfileNotesSection";
import PatientIntakeFlowContent from "../../../../patientIntake/pages/flow/components/PatientIntakeFlowContent";
import usePatientIntakeFlow from "../../../../patientIntake/pages/flow/hooks/usePatientIntakeFlow";
import HumanTaskDefaultForm from "../HumanTaskDefaultForm";
import { HumanTaskSubmitParams } from "../HumanTaskForm";
import { IntakePatientLinkCard } from "./components/IntakePatientLinkCard";
import { PatientLinkCard } from "./components/PatientLinkCard";
import PatientCommunicationLog from "../../../../communication/components/communication-log/PatientCommunicationLog";

interface Props {
  workflowTaskInstanceId: WorkflowTaskInstanceId;
  patientId: PatientId;
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  onSubmit: (params: HumanTaskSubmitParams) => void;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}

export default function HumanTaskCallIntakeLayout(props: Props) {
  const queryClient = useQueryClient();
  const { api, queries } = useApi();
  const toast = useToast();

  const intakeQuery = useQuery({
    ...queries.intake.profile(props.patientId),
    placeholderData: keepPreviousData,
    select: (data) => ({
      patient: {
        ...data.patient,
        contacts: orderBy(data.patient.contacts, (contact) => contact.id, "asc"),
      },
    }),
  });

  const ticketsQuery = useQuery({
    queryKey: queryKeys.patientIntake.communicationLog(props.patientId),
    placeholderData: keepPreviousData,
    queryFn: () =>
      api.get("./comm_center/tickets/patients/:patientId", {
        path: {
          patientId: props.patientId!,
        },
      }),
  });

  const markNoteAsDone = useMutation({
    mutationFn: (noteId: NoteId) => {
      return api.put("./notes/:noteId/status", {
        path: { noteId },
        body: { status: "DONE" },
      });
    },
    onSuccess: () => {
      toast({
        title: "Successfuly marked note as done",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries(queries.intake.profile(props.patientId));
    },
    onError: (error) => {
      toast({
        title: `Error in marking note as done.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  if (intakeQuery.isError || ticketsQuery.isError) {
    return (
      <ErrorBox
        error={intakeQuery.error ?? ticketsQuery.error}
        resetErrorBoundary={() => {
          intakeQuery.refetch();
          ticketsQuery.refetch();
        }}
      />
    );
  }

  if (intakeQuery.isPending || ticketsQuery.isPending) {
    return <LoadingState />;
  }

  return (
    <LoadedState
      patient={intakeQuery.data.patient}
      robocalls={ticketsQuery.data.roboCalls}
      tickets={ticketsQuery.data.tickets}
      onClickMarkNoteAsDone={markNoteAsDone.mutate}
      {...props}
    />
  );
}

function LoadingState() {
  return (
    <Flex direction="row" gap={6}>
      <Flex direction="column" flex={1} gap={6}>
        <Flex direction="column" gap={2}>
          <Skeleton height={8} rounded="md" width="40%" />
          <Skeleton height={6} rounded="md" width="20%" />
        </Flex>
        <Flex direction="column" gap={2}>
          <Skeleton height={8} rounded="md" width="40%" />
          <Skeleton height={6} rounded="md" width="20%" />
        </Flex>
        <Skeleton height={64} rounded="md" width="100%" />
        <Skeleton height={24} rounded="md" width="100%" />
      </Flex>
      <Flex direction="column" gap={4} width="sm">
        <Skeleton height={20} rounded="md" width="100%" />
        <Skeleton height={20} rounded="md" width="100%" />
        <Flex direction="column" gap={3} width="sm">
          <Skeleton height={12} rounded="md" width="100%" />
          <Skeleton height={12} rounded="md" width="100%" />
        </Flex>
      </Flex>
    </Flex>
  );
}

function LoadedState(props: {
  patient: Messages["IntakePatientProfile"];
  tickets: Messages["CommCenterTicket"][];
  robocalls: Messages["RobocallLogData"][];
  workflowTaskInstanceId: WorkflowTaskInstanceId;
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  onSubmit: (params: HumanTaskSubmitParams) => void;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
  onClickMarkNoteAsDone: (noteId: NoteId) => void;
}) {
  const phoneNumbers = getPatientPhoneNumbers(props.patient);
  const dialerPopup = usePhoneNumberDialerPopup();
  const callCenter = useCallCenter();

  useSocketEvent({
    key: "TelephonyOutboundCallInitiated",
    onEvent: () => {
      setShowIntakeFlow(true);
      dialerPopup.open({ predefinedPhoneNumber: selectedPhoneNumber });
    },
  });

  const [showIntakeFlow, setShowIntakeFlow] = React.useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = React.useState<string | undefined>(
    phoneNumbers.size === 1 ? phoneNumbers.keys().next().value : undefined
  );
  const autoDialAt = phoneNumbers.size === 1 ? Instant.now().plusSeconds(30) : null;

  return (
    <>
      <Flex direction="row" gap={2} maxH="full">
        {showIntakeFlow ? (
          <PatientIntakeFlow patientId={props.patient.id} />
        ) : (
          <PatientInfo
            autoDialAt={autoDialAt}
            isCalling={callCenter.state.status === "Dialing"}
            patient={props.patient}
            phoneNumbers={phoneNumbers}
            robocalls={props.robocalls}
            selectedPhoneNumber={selectedPhoneNumber}
            tickets={props.tickets}
            onClickMarkNoteAsDone={props.onClickMarkNoteAsDone}
            onClickStartCall={() =>
              dialerPopup.open({ predefinedPhoneNumber: selectedPhoneNumber })
            }
            onDialNumber={callCenter.dial}
            onSetSelectedPhoneNumber={setSelectedPhoneNumber}
          />
        )}
        <SideActions
          output={props.output}
          patient={props.patient}
          workflowTaskInstanceId={props.workflowTaskInstanceId}
          onCloseAngularModal={props.onCloseAngularModal}
          onOpenAngularModal={props.onOpenAngularModal}
          onSubmit={props.onSubmit}
        />
      </Flex>
    </>
  );
}

function PatientIntakeFlow(props: { patientId: PatientId }) {
  const {
    intakeProfileDisclosure,
    activeStepId,
    activeStepForm,
    setActiveStepId,
    setField,
    onSubmitStep,
    isStepSubmitting,
    patientQuery,
    stepsQuery,
    stepQuery,
    externalFlowsQuery,
    setDidFinishFlow,
  } = usePatientIntakeFlow({ patientId: props.patientId });
  const dialerPopup = usePhoneNumberDialerPopup();

  return (
    <PatientIntakeFlowContent
      activeStepForm={activeStepForm}
      activeStepId={activeStepId}
      externalFlowsQuery={externalFlowsQuery}
      intakeProfileDisclosure={intakeProfileDisclosure}
      isStepSubmitting={isStepSubmitting}
      patientId={props.patientId}
      patientQuery={patientQuery}
      phoneDialerPopup={dialerPopup}
      stepQuery={stepQuery}
      stepsQuery={stepsQuery}
      onChangeField={setField}
      onChangeStep={setActiveStepId}
      onFinishFlow={() => setDidFinishFlow(true)}
      onSubmitStep={onSubmitStep}
    />
  );
}

function SideActions(props: {
  patient: Messages["IntakePatientProfile"];
  workflowTaskInstanceId: WorkflowTaskInstanceId;
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  onSubmit: (params: HumanTaskSubmitParams) => void;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}) {
  return (
    <Flex direction="column" gap={3} maxW="md" minW="sm">
      <Flex direction="column" gap={2}>
        <PatientLinkCard
          patientId={props.patient.id}
          onCloseAngularModal={props.onCloseAngularModal}
          onOpenAngularModal={props.onOpenAngularModal}
        />
        <IntakePatientLinkCard patientId={props.patient.id} />
      </Flex>
      <HumanTaskDefaultForm
        output={props.output}
        workflowTaskInstanceId={props.workflowTaskInstanceId}
        onSubmit={props.onSubmit}
      />
    </Flex>
  );
}

function PatientInfo(props: {
  patient: Messages["IntakePatientProfile"];
  tickets: Messages["CommCenterTicket"][];
  robocalls: Messages["RobocallLogData"][];
  phoneNumbers: Map<string, PhoneNumberInfo>;
  selectedPhoneNumber: string | undefined;
  autoDialAt: Instant | null;
  isCalling: boolean;
  onSetSelectedPhoneNumber: (phoneNumber: string | undefined) => void;
  onClickMarkNoteAsDone: (noteId: NoteId) => void;
  onClickStartCall: () => void;
  onDialNumber: (phoneNumber: string) => void;
}) {
  const { queries } = useApi();
  const queryClient = useQueryClient();
  const newNoteDisclosure = useDisclosure();

  return (
    <>
      <Flex direction="column" flex={1} justify="space-between" maxH="full">
        <Flex direction="column" flex={1} gap={8} overflowY="auto" pr={4}>
          <Flex direction="column" gap={4} mt={2}>
            <Heading as="h2" size="md">
              Patient: {props.patient.firstName} {props.patient.lastName} ({props.patient.id})
            </Heading>
            <Wrap fontSize="md">
              <Text>
                <Text as="span" fontWeight="medium">
                  Intake status:
                </Text>
                <Text as="span"> {props.patient.intakeStatus.status}</Text>
              </Text>
              <Text fontWeight="bold"> • </Text>
              <Text>
                <Text as="span" fontWeight="medium">
                  Track:
                </Text>
                <Text as="span"> {props.patient.track.name}</Text>
              </Text>
            </Wrap>
          </Flex>
          <PatientCommunicationLog patientId={props.patient.id} />
          <IntakePatientProfileNotesSection
            notes={props.patient.notes}
            onClickMarkAsDone={props.onClickMarkNoteAsDone}
            onClickNewNote={newNoteDisclosure.onOpen}
          />
        </Flex>
        <Box padding={6}>
          <Heading as="h2" mb={4} size="md">
            Contacts
          </Heading>
          <Flex justify="space-between">
            <RadioGroup
              flex={1}
              value={props.selectedPhoneNumber}
              onChange={props.onSetSelectedPhoneNumber}
            >
              <Wrap spacing={4}>
                {[...props.phoneNumbers].map(([number, info]) => (
                  <WrapItem key={number}>
                    <Radio value={number}>
                      <Flex>
                        <Avatar key={info.contact.fullName} name={info.contact.fullName} />
                        <Box ml="3">
                          <Text fontWeight="bold">
                            {info.contact.fullName}
                            <Badge
                              colorScheme={info.contact.type === "patient" ? "green" : "red"}
                              ml="1"
                            >
                              {info.contact.type === "patient"
                                ? "Patient"
                                : info.contact.relationship}
                            </Badge>
                          </Text>
                          <Text fontSize="sm" fontWeight="normal">
                            {info.type}
                          </Text>
                        </Box>
                      </Flex>
                    </Radio>
                  </WrapItem>
                ))}
              </Wrap>
            </RadioGroup>
            <Button
              colorScheme="blue"
              isDisabled={props.selectedPhoneNumber === undefined}
              isLoading={props.isCalling}
              onClick={props.onClickStartCall}
            >
              Start call
            </Button>
          </Flex>
        </Box>
      </Flex>
      <NewNoteModal
        disclosure={newNoteDisclosure}
        initialValues={{ patientId: props.patient.id }}
        onSuccess={() => queryClient.invalidateQueries(queries.intake.profile(props.patient.id))}
      />
    </>
  );
}

export interface PhoneNumberInfo {
  type: "MOBILE" | "TEL" | "OTHER";
  contact:
    | {
        type: "patient";
        fullName: string;
        firstName: string;
        lastName: string;
        avatar?: string;
      }
    | {
        type: "relative";
        fullName: string;
        firstName: string;
        lastName: string;
        relationship: string;
      };
}

function getPatientPhoneNumbers(patient: Messages["IntakePatientProfile"]) {
  const map = new Map<string, PhoneNumberInfo>();

  for (const phoneNumber of patient.phoneNumbers) {
    map.set(phoneNumber.phonenumber, {
      type: phoneNumber.type,
      contact: {
        type: "patient",
        fullName: `${patient.firstName} ${patient.lastName}`,
        firstName: patient.firstName,
        lastName: patient.lastName,
      },
    });
  }

  for (const contact of patient.contacts ?? []) {
    const data = [
      {
        type: "MOBILE" as const,
        number: contact.mobilePhoneNumber,
        contact,
      },
      {
        type: "TEL" as const,
        number: contact.homePhoneNumber,
        contact,
      },
    ].filter(isDefined);

    for (const { contact, type, number } of data) {
      if (number === null || number === "") continue;

      map.set(number, {
        type,
        contact: {
          type: "relative",
          fullName: `${contact.firstName} ${contact.lastName}`,
          relationship: contact.relationship,
          firstName: contact.firstName,
          lastName: contact.lastName,
        },
      });
    }
  }

  return map;
}
