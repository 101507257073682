import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";
import { Badge, Box, Button, Flex, Heading, IconButton, Text } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Messages } from "../../../core/api";
import DeleteForeverIcon from "../../../shared/icons/DeleteForeverIcon";
import SaveIcon from "../../../shared/icons/SaveIcon";
import { PatientId } from "../../../shared/schema/schema";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { PATIENT_INTAKE_CONTACT_KEY_PREFIX, phoneNumberSchema } from "../shared/utils/intake.utils";
import {
  getIntakeContactRelationshipType,
  relationshipsOptions,
  validatePatientAdditionalContact,
} from "../shared/utils/intakePatientContacts.utils";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";
import IntakePatientProfileInput from "./IntakePatientProfileInput";
import IntakeProfileAddressInput from "./IntakeProfileAddressInput";
import { PatientIntakeContact } from "../patient-intake.types";
import IntakeMobileRightAddon from "./IntakeMobileRightAddon";
import IntakeLandlineRightAddon from "./IntakeLandlineRightAddon";
import { isEmpty } from "lodash";
import IntakePatientProfileSwitch from "./IntakePatientProfileSwitch";

export type PatientContact = Omit<Messages["PatientContact"], "patientId" | "middleName">;
export type PatientContactForm = Omit<PatientContact, "id" | "mobilePhoneNumberSmsable"> & {
  mobilePhoneNumberSmsable?: boolean;
};

const PatientContactForm = (props: {
  contact: PatientIntakeContact;
  patientId: PatientId;
  isEditable: boolean;
  mainPhoneNumber: string | null;
  onToggleMainNumber: (value: string) => void;
  onChangeField: (field: keyof PatientIntakeContact, value: string | null) => void;
  onChangeAddressField: (
    address: string | null,
    addressComponents: Messages["PatientContactAddressComponents"] | null
  ) => void;
}) => {
  return (
    <Flex direction="column" gap={4}>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={true}
          label="First name"
          value={props.contact.firstName}
          onChange={(value) => {
            props.onChangeField("firstName", value);
          }}
        />
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={false}
          label="Last name"
          value={props.contact.lastName}
          onChange={(value) => {
            props.onChangeField("lastName", value);
          }}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={true}
          label="Mobile number"
          rightElement={
            !props.isEditable &&
            (props.contact.mobilePhoneNumberSmsable ? (
              <IntakeMobileRightAddon
                patientId={props.patientId}
                phoneNumber={props.contact.mobilePhoneNumber}
              />
            ) : (
              <IntakeLandlineRightAddon />
            ))
          }
          validateSchema={phoneNumberSchema}
          value={phoneFormatter.formatNational(props.contact.mobilePhoneNumber)}
          onChange={(value) => {
            props.onChangeField("mobilePhoneNumber", value);
          }}
        />
        <IntakePatientProfileSwitch
          isEditable={props.isEditable && props.contact.mobilePhoneNumber !== ""}
          isRequired={false}
          label="Main number"
          value={
            props.contact.mobilePhoneNumber === props.mainPhoneNumber &&
            props.mainPhoneNumber !== null
          }
          onChange={() => props.onToggleMainNumber(props.contact.mobilePhoneNumber)}
        />
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={false}
          label="Home number"
          rightElement={
            !isEmpty(props.contact.homePhoneNumber) &&
            !props.isEditable && <IntakeLandlineRightAddon />
          }
          validateSchema={phoneNumberSchema}
          value={phoneFormatter.formatNational(props.contact.homePhoneNumber ?? "")}
          onChange={(value) => {
            props.onChangeField("homePhoneNumber", value);
          }}
        />
        {props.contact.homePhoneNumber !== null && (
          <IntakePatientProfileSwitch
            isEditable={props.isEditable && props.contact.homePhoneNumber !== ""}
            isRequired={false}
            label="Main number"
            value={
              props.contact.homePhoneNumber === props.mainPhoneNumber &&
              props.mainPhoneNumber !== null
            }
            onChange={() => props.onToggleMainNumber(props.contact.homePhoneNumber ?? "")}
          />
        )}
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakeProfileAddressInput
          isEdit={props.isEditable}
          label="Address"
          value={props.contact.address}
          onChange={(address, addressComponents) => {
            props.onChangeAddressField(address, addressComponents);
          }}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={false}
          label="Address Line 2"
          value={props.contact.address2 ?? ""}
          onChange={(value) => props.onChangeField("address2", value)}
        />
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={false}
          label="Email"
          value={props.contact.email ?? ""}
          onChange={(value) => {
            props.onChangeField("email", value);
          }}
        />
      </Flex>
      <IntakePatientProfileDropdown
        currValue={{
          label: props.contact.relationship ?? "Unknown",
          value: props.contact.relationship,
        }}
        isEditable={props.isEditable}
        isRequired={true}
        label="Relationship with the patient"
        options={relationshipsOptions.map((val) => ({
          label: val ?? "Unknown",
          value: val,
        }))}
        onChange={(value) => {
          props.onChangeField("relationship", value === "Unknown" ? null : value);
        }}
      />
    </Flex>
  );
};

export const NewPatientContact = (props: {
  index: number;
  isEditable: boolean;
  patientId: PatientId;
  onChangeForm: (isEdited: boolean) => void;
  onToggleMainNumber: (value: string) => void;
  onClickCloseForm: () => void;
  onClickSaveContact: (contact: PatientIntakeContact) => void;
  onValidateNewContactPhoneNumber: (value: PatientIntakeContact) => boolean;
}) => {
  const [contact, setContact] = React.useState<PatientIntakeContact>({
    key: "",
    firstName: "",
    lastName: "",
    relationship: "",
    mobilePhoneNumber: "",
    homePhoneNumber: "",
    address: null,
    addressComponents: null,
    address2: null,
    email: null,
    mobilePhoneNumberSmsable: false,
    isForPortalLogin: false,
  });
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleCloseForm = () => {
    setContact({
      key: "",
      firstName: "",
      lastName: "",
      relationship: "",
      mobilePhoneNumber: "",
      homePhoneNumber: "",
      mobilePhoneNumberSmsable: false,
      address: null,
      addressComponents: null,
      address2: null,
      email: null,
      isForPortalLogin: false,
    });

    setErrorMessage("");

    props.onChangeForm(false);
    props.onClickCloseForm();
  };

  const handleChangeContact = (
    field: keyof PatientIntakeContact,
    value: string | Messages["PatientContactAddressComponents"] | null
  ) => {
    setContact((prev) => ({
      ...prev,
      [field]: value,
      key: `${PATIENT_INTAKE_CONTACT_KEY_PREFIX}-new-${
        field === "mobilePhoneNumber" ? value : prev.mobilePhoneNumber
      }`,
    }));
    props.onChangeForm(true);
  };

  const handleChangeAddressForContact = (
    address: string | null,
    addressComponents: Messages["PatientContactAddressComponents"] | null
  ) => {
    setContact((prev) => ({
      ...prev,
      address,
      addressComponents,
      key: `${PATIENT_INTAKE_CONTACT_KEY_PREFIX}-new-${prev.mobilePhoneNumber}`,
    }));
    props.onChangeForm(true);
  };

  const handleClickSaveChanges = () => {
    const isValid = validatePatientAdditionalContact(contact);

    if (!isValid) {
      setErrorMessage(
        "Please fill out all required fields - First name, Mobile number, Relationship"
      );
      return;
    }

    const isPhonenumberValid = props.onValidateNewContactPhoneNumber(contact);
    if (!isPhonenumberValid) {
      setErrorMessage(
        "Phone number already exists on another contact. Please use a different phone number."
      );
      return;
    }

    props.onChangeForm(false);
    props.onClickSaveContact(contact);
  };

  return (
    <Flex
      border="1px solid black"
      borderColor="gray.300"
      borderRadius={16}
      direction="column"
      gap={4}
      padding={5}
    >
      <Flex alignItems="center" direction="row" justifyContent="space-between">
        <Heading as="h4" size="sm">
          Contact #{props.index + 1}
        </Heading>
        <CloseIcon aria-label="close" cursor="pointer" onClick={handleCloseForm} />
      </Flex>
      <PatientContactForm
        contact={contact}
        isEditable={props.isEditable}
        mainPhoneNumber={null}
        patientId={props.patientId}
        onChangeAddressField={handleChangeAddressForContact}
        onChangeField={handleChangeContact}
        onToggleMainNumber={props.onToggleMainNumber}
      />
      <Flex justifyContent="flex-start">
        <Text color="red.400">{errorMessage}</Text>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button
          aria-label="save changes"
          colorScheme="blue"
          leftIcon={<SaveIcon />}
          onClick={handleClickSaveChanges}
        >
          {" "}
          Save Changes{" "}
        </Button>
      </Flex>
    </Flex>
  );
};

interface IntakePatientContactProps {
  index: number;
  openIndex: number;
  contact: PatientIntakeContact;
  isEditable: boolean;
  patientId: PatientId;
  mainPhoneNumber: string | null;
  onToggleMainNumber: (value: string) => void;
  onClickRemoveContact: (key: string) => void;
  onEditPatientContact: (key: string, contact: PatientIntakeContact) => void;
}

export const IntakePatientContact = (props: IntakePatientContactProps) => {
  const [isOpen, setIsOpen] = React.useState(props.openIndex === props.index);
  const relationshipType = getIntakeContactRelationshipType(props.contact.relationship);

  const handleEditContact = (
    field: keyof PatientIntakeContact,
    value: string | Messages["PatientContactAddressComponents"] | null
  ) => {
    if (props.contact.id !== undefined) {
      props.onEditPatientContact(props.contact.key, {
        ...props.contact,
        [field]: value,
        key: `${PATIENT_INTAKE_CONTACT_KEY_PREFIX}-${
          field === "mobilePhoneNumber" ? (value as string) : props.contact.mobilePhoneNumber
        }`,
      });
    }
  };

  const handleEditAddressFieldsForContact = (
    address: string | null,
    addressComponents: Messages["PatientContactAddressComponents"] | null
  ) => {
    if (props.contact.id !== undefined) {
      props.onEditPatientContact(props.contact.key, {
        ...props.contact,
        address,
        addressComponents,
        key: `${PATIENT_INTAKE_CONTACT_KEY_PREFIX}-${props.contact.mobilePhoneNumber}`,
      });
    }
  };

  return (
    <Flex direction="column" gap={5}>
      <Flex alignItems="center" direction="row" justifyContent="space-between">
        <Flex alignItems="center" direction="row" gap={5}>
          <Heading as="h4" size="sm" w={100}>
            {props.contact.firstName} {props.contact.lastName}
          </Heading>
          <Box w={120}>
            <Badge
              colorScheme={
                relationshipType === "Family Member"
                  ? "green"
                  : relationshipType === "Unknown"
                  ? "gray"
                  : "blue"
              }
            >
              {relationshipType}
            </Badge>
          </Box>
          {props.isEditable ? (
            <Button
              aria-label="remove contact"
              leftIcon={<DeleteForeverIcon />}
              variant="link"
              onClick={() => props.onClickRemoveContact(props.contact.key)}
            ></Button>
          ) : null}
        </Flex>
        {isOpen ? (
          <IconButton
            aria-label="collapse up"
            color="black"
            icon={<ChevronUpIcon boxSize={6} />}
            size="md"
            variant="link"
            onClick={() => setIsOpen(!isOpen)}
          />
        ) : (
          <IconButton
            aria-label="collapse down"
            color="black"
            icon={<ChevronDownIcon boxSize={6} />}
            variant="link"
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </Flex>
      <AnimatePresence>
        {isOpen && (
          <motion.section
            animate="open"
            exit="collapsed"
            initial="collapsed"
            transition={{ duration: 0.5, ease: "easeInOut" }}
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
          >
            <PatientContactForm
              contact={props.contact}
              isEditable={props.isEditable}
              mainPhoneNumber={props.mainPhoneNumber}
              patientId={props.patientId}
              onChangeAddressField={handleEditAddressFieldsForContact}
              onChangeField={handleEditContact}
              onToggleMainNumber={props.onToggleMainNumber}
            />
          </motion.section>
        )}
      </AnimatePresence>
    </Flex>
  );
};
