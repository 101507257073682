export const STATES = [
  {
    label: "New York",
    value: "NY",
    location: {
      lat: 40.7128,
      lng: -74.006,
    },
    timezone: "America/New_York",
  },
  {
    label: "Alabama",
    value: "AL",
    location: {
      lat: 32.377,
      lng: -86.3,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Alaska",
    value: "AK",
    location: {
      lat: 58.3019,
      lng: -134.4197,
    },
    timezone: "America/Anchorage",
  },
  {
    label: "Arizona",
    value: "AZ",
    location: {
      lat: 33.4484,
      lng: -112.074,
    },
    timezone: "America/Denver",
  },
  {
    label: "Arkansas",
    value: "AR",
    location: {
      lat: 34.749,
      lng: -92.2865,
    },
    timezone: "America/Chicago",
  },
  {
    label: "California",
    value: "CA",
    location: {
      lat: 38.5767,
      lng: -121.4944,
    },
    timezone: "America/Los_Angeles",
  },
  {
    label: "Colorado",
    value: "CO",
    location: {
      lat: 39.7392,
      lng: -104.9903,
    },
    timezone: "America/Denver",
  },
  {
    label: "Connecticut",
    value: "CT",
    location: {
      lat: 41.7658,
      lng: -72.6734,
    },
    timezone: "America/New_York",
  },
  {
    label: "Delaware",
    value: "DE",
    location: {
      lat: 39.1582,
      lng: -75.5244,
    },
    timezone: "America/New_York",
  },
  {
    label: "Florida",
    value: "FL",
    location: {
      lat: 28.5383,
      lng: -81.3792,
    },
    timezone: "America/New_York",
  },
  {
    label: "Georgia",
    value: "GA",
    location: {
      lat: 33.749,
      lng: -84.388,
    },
    timezone: "America/New_York",
  },
  {
    label: "Hawaii",
    value: "HI",
    location: {
      lat: 21.3075,
      lng: -157.8574,
    },
    timezone: "Pacific/Honolulu",
  },
  {
    label: "Idaho",
    value: "ID",
    location: {
      lat: 43.615,
      lng: -116.2023,
    },
    timezone: "America/Denver",
  },
  {
    label: "Illinois",
    value: "IL",
    location: {
      lat: 39.798,
      lng: -89.6562,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Indiana",
    value: "IN",
    location: {
      lat: 39.7686,
      lng: -86.1626,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Iowa",
    value: "IA",
    location: {
      lat: 41.5922,
      lng: -93.6037,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Kansas",
    value: "KS",
    location: {
      lat: 39.0558,
      lng: -95.689,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Kentucky",
    value: "KY",
    location: {
      lat: 38.1867,
      lng: -84.8753,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Louisiana",
    value: "LA",
    location: {
      lat: 30.4422,
      lng: -91.1309,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Maine",
    value: "ME",
    location: {
      lat: 44.3106,
      lng: -69.7795,
    },
    timezone: "America/New_York",
  },
  {
    label: "Maryland",
    value: "MD",
    location: {
      lat: 39.2904,
      lng: -76.6122,
    },
    timezone: "America/New_York",
  },
  {
    label: "Massachusetts",
    value: "MA",
    location: {
      lat: 42.3601,
      lng: -71.0589,
    },
    timezone: "America/New_York",
  },
  {
    label: "Michigan",
    value: "MI",
    location: {
      lat: 42.7336,
      lng: -84.5556,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Minnesota",
    value: "MN",
    location: {
      lat: 44.9544,
      lng: -93.0902,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Mississippi",
    value: "MS",
    location: {
      lat: 32.3032,
      lng: -90.1821,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Missouri",
    value: "MO",
    location: {
      lat: 38.5791,
      lng: -92.1676,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Montana",
    value: "MT",
    location: {
      lat: 46.5891,
      lng: -112.0391,
    },
    timezone: "America/Denver",
  },
  {
    label: "Nebraska",
    value: "NE",
    location: {
      lat: 41.2565,
      lng: -95.9345,
    },
    timezone: "America/Denver",
  },
  {
    label: "Nevada",
    value: "NV",
    location: {
      lat: 39.1638,
      lng: -119.7674,
    },
    timezone: "America/Denver",
  },
  {
    label: "New Hampshire",
    value: "NH",
    location: {
      lat: 43.2081,
      lng: -71.5376,
    },
    timezone: "America/New_York",
  },
  {
    label: "New Jersey",
    value: "NJ",
    location: {
      lat: 40.2206,
      lng: -74.7597,
    },
    timezone: "America/New_York",
  },
  {
    label: "New Mexico",
    value: "NM",
    location: {
      lat: 35.0844,
      lng: -106.6504,
    },
    timezone: "America/Denver",
  },
  {
    label: "North Carolina",
    value: "NC",
    location: {
      lat: 35.7804,
      lng: -78.6391,
    },
    timezone: "America/New_York",
  },
  {
    label: "North Dakota",
    value: "ND",
    location: {
      lat: 46.8083,
      lng: -100.7837,
    },
    timezone: "America/Denver",
  },
  {
    label: "Ohio",
    value: "OH",
    location: {
      lat: 39.9612,
      lng: -82.9988,
    },
    timezone: "America/New_York",
  },
  {
    label: "Oklahoma",
    value: "OK",
    location: {
      lat: 35.4931,
      lng: -97.4617,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Oregon",
    value: "OR",
    location: {
      lat: 44.9385,
      lng: -123.0302,
    },
    timezone: "America/Los_Angeles",
  },
  {
    label: "Pennsylvania",
    value: "PA",
    location: {
      lat: 40.2732,
      lng: -76.8867,
    },
    timezone: "America/New_York",
  },
  {
    label: "Rhode Island",
    value: "RI",
    location: {
      lat: 41.824,
      lng: -71.4128,
    },
    timezone: "America/New_York",
  },
  {
    label: "South Carolina",
    value: "SC",
    location: {
      lat: 34.0007,
      lng: -81.0348,
    },
    timezone: "America/New_York",
  },
  {
    label: "South Dakota",
    value: "SD",
    location: {
      lat: 44.3683,
      lng: -100.3364,
    },
    timezone: "America/Denver",
  },
  {
    label: "Tennessee",
    value: "TN",
    location: {
      lat: 36.1627,
      lng: -86.7816,
    },
    timezone: "America/New_York",
  },
  {
    label: "Texas",
    value: "TX",
    location: {
      lat: 31.5493,
      lng: -97.1467,
    },
    timezone: "America/Denver",
  },
  {
    label: "Utah",
    value: "UT",
    location: {
      lat: 40.7608,
      lng: -111.891,
    },
    timezone: "America/Denver",
  },
  {
    label: "Vermont",
    value: "VT",
    location: {
      lat: 44.2601,
      lng: -72.5754,
    },
    timezone: "America/New_York",
  },
  {
    label: "Virginia",
    value: "VA",
    location: {
      lat: 37.5407,
      lng: -77.436,
    },
    timezone: "America/New_York",
  },
  {
    label: "Washington",
    value: "WA",
    location: {
      lat: 47.0303,
      lng: -122.899,
    },
    timezone: "America/Los_Angeles",
  },
  {
    label: "West Virginia",
    value: "WV",
    location: {
      lat: 38.3498,
      lng: -81.6326,
    },
    timezone: "America/New_York",
  },
  {
    label: "Wisconsin",
    value: "WI",
    location: {
      lat: 43.0731,
      lng: -89.4012,
    },
    timezone: "America/Chicago",
  },
  {
    label: "Wyoming",
    value: "WY",
    location: {
      lat: 41.14,
      lng: -104.8202,
    },
    timezone: "America/Denver",
  },
];
