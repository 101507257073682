import React from "react";
import { reactBridge } from "../../core";
import { AngularJSDispatch, AngularJSPayload } from "../../core/react-bridge";
import useStableCallback from "./useStableCallback";

export function useReactBridgePayloadEvent<TEventName extends keyof AngularJSPayload>(params: {
  eventName: TEventName;
  runInitial: boolean;
  onEvent: () => void;
}) {
  const stableCallback = useStableCallback(params.onEvent);

  React.useEffect(() => {
    if (params.runInitial) {
      stableCallback();
    }
  }, [params.runInitial, stableCallback]);

  React.useEffect(() => {
    return reactBridge.onPayloadSet(params.eventName, stableCallback);
  }, [params.eventName, stableCallback]);
}

export function useReactBridgeDispatchEvent<TEventName extends keyof AngularJSDispatch>(params: {
  eventName: TEventName;
  onEvent: (payload: AngularJSDispatch[TEventName]) => void;
}) {
  const stableCallback = useStableCallback(params.onEvent);

  React.useEffect(() => {
    return reactBridge.onDispatch(params.eventName, stableCallback);
  }, [params.eventName, stableCallback]);
}
