import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { BodyOf } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import useControlledForm from "../../../shared/hooks/useControlledForm";
import { NoteId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { NoteFormData, zNoteForm } from "../utils/note-form";
import NoteModalBody from "./NoteModalBody";

type Props = {
  initialValues?: Partial<NoteFormData>;
  disclosure: ReturnType<typeof useDisclosure>;
  onSuccess?: (noteId: NoteId) => void;
};

export default function NewNoteModal(props: Props) {
  const { api } = useApi();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: (body: BodyOf<"post", "./notes">) => {
      return api.post("./notes", { body });
    },
    onSuccess: (data) => {
      props.disclosure.onClose();
      props.onSuccess?.(data.noteId);

      toast({
        title: "Note created",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error creating note",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const form = useControlledForm({
    schema: zNoteForm,
    initialValues: props.initialValues,
    onSuccess: (data) => {
      mutation.mutate({
        caregiverId: data.caregiverId,
        patientId: data.patientId,
        commCenterTicketId: data.commCenterTicketId,
        noteRichText: data.content,
        noteType: "profile_note",
        status: data.status,
        base64File: data.base64File,
        followUp: data.followUpDate,
        subject: data.subject,
        agencyMemberIds: [
          ...(data.mentionedAgencyMemberIds ?? []),
          ...(data.agencyMemberIds ?? []),
        ],
      });
    },
  });

  return (
    <Modal {...props.disclosure} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a new note</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={form.onSubmit}>
          <NoteModalBody form={form} />

          <ModalFooter>
            <ButtonGroup>
              <Button variant="ghost" onClick={props.disclosure.onClose}>
                Close
              </Button>
              <Button colorScheme="blue" isLoading={mutation.isPending} type="submit">
                Post Note
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
