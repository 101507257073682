import { Button, ButtonProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import LoadingButton from "../../../shared/components/LoadingButton";
import Select from "../../../shared/components/Select";
import SelectNoResults from "../../../shared/components/SelectNoResults";
import useApi from "../../../shared/hooks/useApi";
import { NotePredefinedAnswerId } from "../../../shared/schema/schema";

export default function NotePredefinedAnswerSelect(props: {
  buttonProps?: ButtonProps;
  noteTypes: Messages["NoteTypes"][];
  value: NotePredefinedAnswerId | null;
  onChange: (value: NotePredefinedAnswerId | undefined) => void;
}) {
  const { queries } = useApi();

  const query = useQuery({
    ...queries.note.settings(),
    select: ({ agencyEntityNotes }) => {
      return (
        agencyEntityNotes
          .filter((x) => props.noteTypes.includes(x.noteType))
          .flatMap((a) => a.predefinedAnswers)
          ?.filter((x) => x.active) ?? []
      );
    },
  });

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          buttonProps={props.buttonProps}
          label="Select reason"
          multiple={false}
          options={query.data.map((x) => ({ value: x.id, label: x.text }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          value={props.value}
          onChange={props.onChange}
        />
      );
  }
}
