import { Box, Button, Code, CodeProps, Flex, Heading, Link } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import React from "react";

type Props = {
  error: Error | unknown;
  width?: CodeProps["width"];
  resetErrorBoundary: () => void;
};

export default function ErrorBox(props: Props) {
  const error = props.error instanceof Error ? props.error : new Error(String(props.error));

  const [showErrorCount, setShowErrorCount] = React.useState(0);
  const [exceptionId] = React.useState<string>(Sentry.captureException(error));

  return (
    <Flex align="center" direction="column" gap={8} m="auto" p={8}>
      <Heading size="2xl" onClick={() => setShowErrorCount((prev) => prev + 1)}>
        😬
      </Heading>
      <Heading size="md">Something went wrong</Heading>

      {showErrorCount > 3 && (
        <Box maxW="5xl">
          <Code
            bg="red.50"
            color="red.600"
            display="block"
            fontSize="md"
            overflow="auto"
            p={4}
            rounded="md"
            w={props.width}
            whiteSpace="pre"
          >
            {error.message + "\n\n"}
            {error.stack}
          </Code>

          <Box mt={2}>
            <Link
              color="red.500"
              href={`https://medflyt.sentry.io/discover/webapp:${exceptionId}`}
              target="_blank"
            >
              Sentry exception: {exceptionId}
            </Link>
          </Box>
        </Box>
      )}

      <Box>
        <Button onClick={() => props.resetErrorBoundary()}>Try again</Button>
      </Box>
    </Flex>
  );
}
