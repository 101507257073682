import { Button, Flex, Heading } from "@chakra-ui/react";
import { Instant, LocalDate, LocalDateTime, LocalTime, ZoneId } from "@js-joda/core";
import React from "react";
import { Messages } from "../../../core/api";
import { AddIcon } from "@chakra-ui/icons";
import { DisplayPatientPlans, EditablePatientPlans } from "./IntakePatientPlansDisplay";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";
import { toLocalDateTime } from "../../../shared/utils/date-utils";
import IntakePatientProfileDatepicker from "./IntakePatientProfileDatepicker";
import { partition } from "lodash";
import { PatientIntakePlan } from "../patient-intake.types";

const NEW_YORK_TZ = "America/New_York";

interface Props {
  isEditMode: boolean;
  plans: Messages["IntegrationPayer"][];
  patientPlans: PatientIntakePlan[] | null;
  UASStatus: boolean;
  essenAssessmentDate: Instant | null;
  essenAssessmentStatus: boolean;
  onClickRemovePlan: (key: string) => void;
  onEditPatientPlan: (key: string, newPlanInfo: PatientIntakePlan) => void;
  onSaveNewPlan: (newPlanInfo: PatientIntakePlan) => void;
  onChangeField: (
    field: "UASCompleted" | "essenAssessmentDate" | "essenAssessmentCompleted",
    value: undefined | null | boolean | Instant
  ) => void;
}

const IntakeProfilePlansInfo = (props: Props) => {
  const [isNewPlanOpen, setIsNewPlanOpen] = React.useState(false);
  const hasCurrentPlan = props.patientPlans?.some((plan) => plan.current) ?? false;
  const plansLength = props.patientPlans?.length ?? 0;
  const essenAssessmentDate =
    props.essenAssessmentDate !== null
      ? toLocalDateTime(props.essenAssessmentDate, { timezone: NEW_YORK_TZ })
      : null;

  const handleEssenAssessmentDateChange = (value: LocalDate | LocalDateTime | null) => {
    if (value === null) {
      props.onChangeField("essenAssessmentDate", null);
    } else {
      const newValue = value instanceof LocalDate ? LocalDateTime.of(value, LocalTime.MIN) : value;
      const instant = newValue.atZone(ZoneId.of(NEW_YORK_TZ)).toInstant();
      props.onChangeField("essenAssessmentDate", instant);
    }
  };

  const [currentPlan, otherPlans] = partition(props.patientPlans, (plan) => plan.current === true);

  return (
    <Flex direction="column" gap={4}>
      <Flex direction="row" justifyContent="space-between">
        <Heading as="h2" size="md">
          {" "}
          Plan Information
        </Heading>
        {!isNewPlanOpen && props.isEditMode ? (
          <Button
            color="blue.500"
            justifyContent="flex-end"
            leftIcon={<AddIcon />}
            variant="link"
            onClick={() => setIsNewPlanOpen(true)}
          >
            Add new plan
          </Button>
        ) : null}
      </Flex>
      {props.isEditMode ? (
        <>
          <EditablePatientPlans
            availablePlans={props.plans}
            isCurrentPlan={true}
            isNewPlanOpen={isNewPlanOpen}
            plans={currentPlan}
            onClickCloseNewPlanForm={() => setIsNewPlanOpen(false)}
            onClickRemovePlan={props.onClickRemovePlan}
            onClickSaveChanges={props.onSaveNewPlan}
            onEditPatientPlan={props.onEditPatientPlan}
          />
          <EditablePatientPlans
            availablePlans={props.plans}
            isCurrentPlan={false}
            isNewPlanOpen={isNewPlanOpen}
            plans={otherPlans}
            onClickCloseNewPlanForm={() => setIsNewPlanOpen(false)}
            onClickRemovePlan={props.onClickRemovePlan}
            onClickSaveChanges={props.onSaveNewPlan}
            onEditPatientPlan={props.onEditPatientPlan}
          />
        </>
      ) : (
        <>
          <DisplayPatientPlans
            availablePlans={props.plans}
            isCurrentPlan={true}
            plans={currentPlan}
            onClickRemovePlan={props.onClickRemovePlan}
            onEditPatientPlan={props.onEditPatientPlan}
          />
          <DisplayPatientPlans
            availablePlans={props.plans}
            isCurrentPlan={false}
            plans={otherPlans}
            onClickRemovePlan={props.onClickRemovePlan}
            onEditPatientPlan={props.onEditPatientPlan}
          />
        </>
      )}
      {((hasCurrentPlan && plansLength > 1) || plansLength > 0) && (
        <Flex direction="row" gap={4}>
          <IntakePatientProfileDatepicker
            isEditable={props.isEditMode}
            isRequired={false}
            label="Essen Assessment Date"
            value={essenAssessmentDate}
            withTime={true}
            onChange={(value) => handleEssenAssessmentDateChange(value)}
          />
          <IntakePatientProfileDropdown
            currValue={{
              value: props.essenAssessmentStatus ? "Completed" : "Not completed",
              label: props.essenAssessmentStatus ? "Completed" : "Not completed",
            }}
            isEditable={props.isEditMode}
            isRequired={false}
            label="Essen Assessment Status"
            options={[
              {
                value: "Completed",
                label: "Completed",
              },
              {
                value: "Not completed",
                label: "Not completed",
              },
            ]}
            onChange={(value) =>
              props.onChangeField("essenAssessmentCompleted", value === "Completed" ? true : false)
            }
          />
        </Flex>
      )}
    </Flex>
  );
};

export default IntakeProfilePlansInfo;
