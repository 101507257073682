import React from "react";

import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Messages } from "../../../core/api";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { sortingFns } from "../../../shared/utils/tanstack-table";
import { getRelatedEntityFromFax, getViewFaxButton } from "../utils/fax-utils";
import { Instant } from "@js-joda/core";
import { TableCellBadge } from "../../communication/components/TableCellBadge";
import ViewFaxButton, { ViewFax } from "./ViewFaxButton";
import UpdateActionFaxButton from "./UpdateActionFaxButton";
import { FaxDetailsId } from "../../../shared/schema/schema";
import FaxEntityBox from "./FaxEntityBox";
import { Entity } from "../../../shared/components/EntityCard";
import { SelectEntityParams } from "./EditFaxEntitySection";

interface FaxDataRow {
  faxId: FaxDetailsId;
  type: Messages["FaxType"];
  createdAt: Instant;
  createdBy: string | null;
  sentFrom: string;
  sentTo: string;
  entity: Entity;
  status: Messages["FaxStatus"];
  viewFaxButton: ViewFax;
  updateActionButton: Messages["DashboardFax"];
}

function toDataRow(fax: Messages["DashboardFax"]): FaxDataRow {
  const entity = getRelatedEntityFromFax(fax);
  const viewFaxButton = getViewFaxButton(fax);

  return {
    faxId: fax.id,
    type: fax.type,
    createdAt: fax.createdAt,
    createdBy: fax.createdByUserName,
    sentFrom: fax.sender,
    sentTo: fax.recipient,
    entity: entity,
    status: fax.status,
    viewFaxButton: viewFaxButton,
    updateActionButton: fax,
  };
}

const faxStatusTextToColor = {
  failed: "red" as const,
  processing: "yellow" as const,
  success: "green" as const,
  transmitting: "yellow" as const,
};

const paginationPageSizes = [10, 20, 30, 40, 50];

export function FaxesTable(props: {
  faxes: Messages["DashboardFax"][];
  onUpdateStatus: (faxId: FaxDetailsId) => void;
  onSelectEntity: (faxId: FaxDetailsId, entity: SelectEntityParams | null) => void;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([{ id: "createdAt", desc: true }]);

  const data = React.useMemo(() => props.faxes.map(toDataRow), [props.faxes]);

  const columnHelper = createColumnHelper<FaxDataRow>();
  const columns = [
    columnHelper.accessor("entity", {
      cell: (info) => (
        <FaxEntityBox
          entity={info.getValue()}
          faxId={info.row.original.faxId}
          onSelectEntity={props.onSelectEntity}
        />
      ),
      header: () => <span>Entity</span>,
    }),
    columnHelper.accessor("type", {
      cell: (info) => info.getValue(),
      header: () => <span>Type</span>,
    }),
    columnHelper.accessor("createdAt", {
      sortingFn: "instant",
      cell: (info) => <Text>{dateFormatter.toDateOrDateTime(info.getValue())}</Text>,
      header: () => <span>Created at</span>,
    }),
    columnHelper.accessor("createdBy", {
      cell: (info) => info.getValue(),
      header: () => <span>Created by</span>,
    }),
    columnHelper.accessor("sentFrom", {
      cell: (info) => phoneFormatter.formatNational(info.getValue()),
      header: () => <span>Sent from</span>,
    }),
    columnHelper.accessor("sentTo", {
      cell: (info) => phoneFormatter.formatNational(info.getValue()),
      header: () => <span>Sent to</span>,
    }),
    columnHelper.accessor("status", {
      cell: (info) => (
        <TableCellBadge
          color={faxStatusTextToColor[info.getValue()]}
          text={info.getValue().toLocaleUpperCase()}
        />
      ),
      header: () => <span>Fax Status</span>,
    }),
    columnHelper.accessor("updateActionButton", {
      cell: (info) => (
        <UpdateActionFaxButton
          fax={info.getValue()}
          onUpdateAction={props.onUpdateStatus}
        ></UpdateActionFaxButton>
      ),
      header: () => "",
    }),
    columnHelper.accessor("viewFaxButton", {
      cell: (info) => <ViewFaxButton viewFax={info.getValue()}></ViewFaxButton>,
      header: () => "",
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    sortingFns: sortingFns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const createHeaderFromColumn = (header: Header<FaxDataRow, any>) => {
    return (
      <Th
        key={header.id}
        _hover={{ bg: "gray.100" }}
        borderTopRadius="md"
        colSpan={header.colSpan}
        cursor="pointer"
        transition="100ms ease-in-out"
        onClick={header.column.getToggleSortingHandler()}
      >
        {header.isPlaceholder ? null : (
          <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
            <Flex alignItems="center" justifyContent="space-between">
              {flexRender(header.column.columnDef.header, header.getContext())}
              {{
                asc: <ArrowUpIcon />,
                desc: <ArrowDownIcon />,
              }[header.column.getIsSorted() as string] ?? null}
            </Flex>
          </div>
        )}
      </Th>
    );
  };

  const createTableRow = (row: Row<FaxDataRow>) => {
    return (
      <Tr key={row.id} _hover={{ bg: "gray.50" }} borderBottomColor="blue.100" fontWeight={600}>
        {row.getVisibleCells().map((cell) => {
          return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
        })}
      </Tr>
    );
  };

  return (
    <Stack spacing={1}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return createHeaderFromColumn(header);
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>{table.getRowModel().rows.map((row) => createTableRow(row))}</Tbody>
        </Table>
      </TableContainer>
      <Flex alignItems="center" dir="ltr" direction="row" gap={8}>
        <Stack alignItems="center" dir="ltr" direction="row" spacing={1}>
          <Button disabled={!table.getCanPreviousPage()} onClick={() => table.setPageIndex(0)}>
            «
          </Button>
          <Button disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}>
            ‹
          </Button>
          <Button disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>
            ›
          </Button>
          <Button
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          >
            »
          </Button>
        </Stack>
        <Stack alignItems="center" direction="row">
          <Text fontSize="md">Page</Text>
          <Stack alignItems="center" direction="row" w="150px">
            <Text as="b" fontSize="md">
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </Text>
            <Text>({table.getRowModel().rows.length} Rows)</Text>
          </Stack>
        </Stack>
        <Stack alignItems="center" direction="row">
          <Text fontSize="md" w="100px">
            Go to page:
          </Text>
          <Input
            defaultValue={table.getState().pagination.pageIndex + 1}
            type="number"
            w="60px"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Stack>
        <Menu>
          <MenuButton as={Button}>Show {table.getState().pagination.pageSize}</MenuButton>
          <MenuList>
            {paginationPageSizes.map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize} onClick={() => table.setPageSize(pageSize)}>
                Show {pageSize}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  );
}

export default FaxesTable;
