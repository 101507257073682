import { Button } from "@chakra-ui/react";
import { Messages } from "../../../core/api";

export type ViewFax = {
  fax: Messages["DashboardFax"];
  onSelectView: () => void;
};

interface Props {
  viewFax: ViewFax;
}

const ViewFaxButton = (props: Props) => {
  return (
    <Button
      colorScheme="blue"
      size="sm"
      variant="solid"
      w="full"
      onClick={props.viewFax.onSelectView}
    >
      View
    </Button>
  );
};

export default ViewFaxButton;
