import { ButtonProps, Wrap } from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { usePhoneNumberDialerPopup } from "../../call-center/hooks/usePhoneNumberDialerPopup";
import { getFullName } from "../../../shared/utils/get-full-name";
import CallButton from "../../../shared/components/CallButton";
import { getPatientPhoneNumbersToCall } from "../../patient/patient-utils";
import PatientCallOption from "../../patient/components/PatientCallOption";

interface Props {
  patientId: Messages["PatientId"];
  buttonProps?: ButtonProps;
  text?: string;
}

export default function IntakeProfileCallButton(props: Props) {
  const { queries } = useApi();
  const dialerPopup = usePhoneNumberDialerPopup();

  const intakeProfile = useQuery({
    ...queries.intake.profile(props.patientId),
    placeholderData: keepPreviousData,
    select: (data) => ({
      patient: {
        ...data.patient,
        contacts: orderBy(data.patient.contacts, (contact) => contact.id, "asc"),
      },
    }),
  });

  const handleClickDial = (phoneNumber?: string) => {
    dialerPopup.open({ predefinedPhoneNumber: phoneNumber });
  };

  if (intakeProfile.isLoading || intakeProfile.data === undefined) {
    return (
      <CallButton isDisabled={true} isLoading={true}>
        <></>
      </CallButton>
    );
  }

  const patient = intakeProfile.data.patient;
  const contactsArray = getPatientPhoneNumbersToCall({
    patientName: getFullName(patient),
    phoneNumbers: patient.phoneNumbers,
    contacts: patient.contacts,
    mainPhoneNumber: patient.mainPhoneNumber,
  });

  return (
    <CallButton
      isDisabled={patient.contacts.length === 0 && patient.phoneNumbers.length === 0}
      isLoading={intakeProfile.isLoading || intakeProfile.data === undefined}
      text={props.text}
      {...props.buttonProps}
    >
      <Wrap direction="column" padding={2} spacing={5}>
        {contactsArray.map((contact, index) => (
          <PatientCallOption
            key={`contact-${index}`}
            contactName={contact.contactName}
            contactPhoneNumber={contact.contactPhoneNumber}
            contactRelationship={contact.contactRelationship}
            isMainPhoneNumber={contact.isMain}
            phoneNumberType={contact.phoneNumberType}
            onClickCall={handleClickDial}
          />
        ))}
      </Wrap>
    </CallButton>
  );
}
