import _ from "lodash";

//! @ngInject
export function payRateSetupModalCtrl($scope, $rootScope, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService, NgTableParams) {
    $scope.hideSaveFormButton = true;
    $scope.fields = FormService.getFields(fields, data.payRate);
    $scope.data = data.payRate;
    $scope.calendarPopups = {};
    $scope.form = {};
    $scope.agencyMembers = DatabaseApi.getAgencyMembers();
    $scope.payRateHistory = data.payRateHistory.map(payRate => ({...payRate, createdByName: $scope.agencyMembers[payRate.createdBy]}));
    $scope.showWeekendRates = true;

    $scope.dateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    if (!$scope.viewModeOnly) {
        prepareAssignedLocationGroups();
    } else {
        setOfficesWithRates();
    }

    $scope.submitForm = () => {
        $scope.form.data.$submitted = true

        if ($scope.form.data.$invalid) {
            return false;
        }

        let errorMessage = validatePayRateInputs();
        if (errorMessage !== '') {
            toaster.pop('error', "Something went wrong", errorMessage);
            return;
        }
        errorMessage = validateSupplementsInputs();
        if (errorMessage !== '') {
            toaster.pop('error', "Something went wrong", errorMessage);
            return;
        }

        var newData = FormService.prepareFormData($scope.fields);
        preparePayRateRequest(newData);
        if (newData.payRateCounties.length === 0) {
            return toaster.pop('error', "Something went wrong", "Please add rates for at least one location group");
        }

        DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/pay_rates', newData).then(function (res) {
            DatabaseApi.getLocationGroupsAndCounties();
            toaster.pop('success', "Pay rate saved");
            $scope.closeModal();
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not add pay rate");
        });
    };

    $scope.toggleCalendarPopups = (prop) => {
        $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
    }

    $scope.closeModal = () => {
        $uibModalInstance.close();
    };

    $scope.exit = () => {
        $uibModalInstance.dismiss();
    };

    function isFalsy(val) {
        return val === null || val === undefined || val === "" || val === 0;
    }

    function validatePayRateInputs() {
        let errorMessage = '';
        $scope.locationGroups.forEach(locationGroup => {
            if (errorMessage !== '') return;

            const groupRates = [
                locationGroup.hourlyRate,
                locationGroup.dailyRate,
                locationGroup.visitRate
            ];
            const falsyWorkShifts = [
                isFalsy(locationGroup.workShiftLine1Rate),
                isFalsy(locationGroup.workShiftLine1Label),
                isFalsy(locationGroup.workShiftLine2Rate),
                isFalsy(locationGroup.workShiftLine2Label)
            ];
            const groupPayRange = locationGroup.payRange;
            if (
                isFalsy(groupRates[0]) &&
                isFalsy(groupRates[1]) &&
                isFalsy(groupRates[2]) &&
                isFalsy(groupPayRange) &&
                isFalsy(locationGroup.baseWage) &&
                isFalsy(locationGroup.additionalWage) &&
                isFalsy(locationGroup.supplementalWage) &&
                falsyWorkShifts[0] &&
                falsyWorkShifts[1] &&
                falsyWorkShifts[2] &&
                falsyWorkShifts[3]
            ) {
                // Means that the user did not enter payrates for this location group and its ok
                return;
            }
            const falseRates = groupRates.some(function (rate) {
                return (!rate && rate !== 0);
            });
            if (falseRates) {
                errorMessage = "Please fill in rate for each location group";
            } else if (!groupPayRange) {
                errorMessage = "Please fill in pay range for each location group"
            } else if (
                falsyWorkShifts[0] && !falsyWorkShifts[1] ||
                !falsyWorkShifts[0] && falsyWorkShifts[1] ||
                falsyWorkShifts[2] && !falsyWorkShifts[3] ||
                !falsyWorkShifts[2] && falsyWorkShifts[3]
            ) {
                errorMessage = "Work shift rate must be entered with labels";
            } else if (falsyWorkShifts[0] && falsyWorkShifts[1] && !falsyWorkShifts[2] && !falsyWorkShifts[3]) {
                locationGroup.workShiftLine1Rate = locationGroup.workShiftLine2Rate;
                locationGroup.workShiftLine1Label = locationGroup.workShiftLine2Label;
                locationGroup.workShiftLine2Rate = null;
                locationGroup.workShiftLine2Label = null;
            }
        });

        return errorMessage;
    }

    function validateSupplementsInputs() {
        let errorMessage = '';
        $scope.locationGroups.forEach(group => {
            if (errorMessage !== '') return;

            group.supplementalRates.forEach(supplemental => {
                if (errorMessage !== '') return;

                let validFieldsCounter = 0;
                if (!isFalsy(supplemental.hourlyRate)) validFieldsCounter++;
                if (!isFalsy(supplemental.type)) validFieldsCounter++;
                if (!isFalsy(supplemental.agencyName)) validFieldsCounter++;
                if (isFalsy(supplemental.agencyDescription)) supplemental.agencyDescription = '';
                if (validFieldsCounter === 0) {
                    return;
                }
                if (validFieldsCounter < 3) {
                    errorMessage = "Please fill in supplement fields";
                    return
                }
            });
        });
        return errorMessage;
    }

    function preparePayRateRequest(formData) {
        formData.payRateCounties = [];
        formData.supplementalRates = [];
        $scope.locationGroups.forEach(group => {
            $scope.counties.forEach(county => {
                if (county.locationGroupId === group.id &&
                        typeof group.hourlyRate === 'number' && 
                        typeof group.dailyRate === 'number' &&
                        typeof group.visitRate === 'number' &&
                        group.payRange) {
                    formData.payRateCounties.push({
                        payRateId: $scope.payRateId,
                        countyName: county.countyName,
                        state: county.state,
                        groupName: group.name,
                        hourlyRate: Number((group.hourlyRate * 100).toFixed()),
                        dailyRate: Number((group.dailyRate * 100).toFixed()),
                        visitRate: Number((group.visitRate * 100).toFixed()),
                        weekendHourlyRate: typeof group.weekendHourlyRate === 'number' ? Number((group.weekendHourlyRate * 100).toFixed()) : null,
                        weekendDailyRate: typeof group.weekendDailyRate === 'number' ? Number((group.weekendDailyRate * 100).toFixed()) : null,
                        weekendVisitRate: typeof group.weekendVisitRate === 'number' ? Number((group.weekendVisitRate * 100).toFixed()) : null,
                        payRange: group.payRange,
                        baseWage: group.baseWage ? Number((group.baseWage * 100).toFixed()) : null,
                        additionalWage: group.additionalWage ? Number((group.additionalWage * 100).toFixed()) : null,
                        supplementalWage: group.supplementalWage ? Number((group.supplementalWage * 100).toFixed()) : null,
                        workShiftLine1Rate: group.workShiftLine1Rate ? Number((group.workShiftLine1Rate * 100).toFixed()) : null,
                        workShiftLine1Label: group.workShiftLine1Label ? group.workShiftLine1Label : null,
                        workShiftLine2Rate: group.workShiftLine2Rate ? Number((group.workShiftLine2Rate * 100).toFixed()) : null,
                        workShiftLine2Label: group.workShiftLine2Label ? group.workShiftLine2Label : null,
                    });
                }
            });

            group.supplementalRates.forEach(supplement => {
                if (supplement.hourlyRate)
                formData.supplementalRates.push({
                    state: "NY",
                    groupName: group.name,
                    hourlyRate: supplement.hourlyRate ? Number((supplement.hourlyRate * 100).toFixed()) : null,
                    type: supplement.type,
                    agencyName: supplement.agencyName,
                    agencyDescription: supplement.agencyDescription,
                });
            });
        });

    }

    function prepareAssignedLocationGroups() {
        var locationsIdsMap = $scope.locationGroups.reduce((map, obj) => {
            map[obj.id] = obj;
            return map;
        }, {});

        var assignedGroups = {};
        $scope.counties.forEach(county => {
            if (_.has(locationsIdsMap, county.locationGroupId) && !_.has(assignedGroups, county.locationGroupId)) {
                assignedGroups[county.locationGroupId] = locationsIdsMap[county.locationGroupId];
                assignedGroups[county.locationGroupId].supplementalRates = [{}, {}, {}];
                assignedGroups[county.locationGroupId] = {
                    ...assignedGroups[county.locationGroupId],
                    hourlyRate: undefined,
                    dailyRate: undefined,
                    visitRate: undefined,
                    weekendHourlyRate: undefined,
                    weekendDailyRate: undefined,
                    weekendVisitRate: undefined,
                }

                if (Object.keys($scope.data).length > 0) {
                    const locationGroupPayRate = $scope.data.payRateCounties.find(payRateCounty => payRateCounty.groupName === assignedGroups[county.locationGroupId].name);
                    if (locationGroupPayRate !== undefined) {
                        assignedGroups[county.locationGroupId].hourlyRate = Number((locationGroupPayRate.hourlyRate / 100).toFixed(2));
                        assignedGroups[county.locationGroupId].dailyRate = Number((locationGroupPayRate.dailyRate / 100).toFixed(2));
                        assignedGroups[county.locationGroupId].visitRate = Number((locationGroupPayRate.visitRate / 100).toFixed(2));
                        assignedGroups[county.locationGroupId].weekendHourlyRate = typeof locationGroupPayRate.weekendHourlyRate === 'number' ? Number((locationGroupPayRate.weekendHourlyRate / 100).toFixed(2)) : null;
                        assignedGroups[county.locationGroupId].weekendDailyRate = typeof locationGroupPayRate.weekendDailyRate === 'number' ? Number((locationGroupPayRate.weekendDailyRate / 100).toFixed(2)) : null;
                        assignedGroups[county.locationGroupId].weekendVisitRate = typeof locationGroupPayRate.weekendVisitRate === 'number' ? Number((locationGroupPayRate.weekendVisitRate / 100).toFixed(2)) : null;
                    }
                }
            }
        });
        $scope.locationGroups = angular.copy(Object.values(assignedGroups));
    }

    function setOfficesWithRates() {
        let officesIdsMap = {};
        // prepare office by ids object
        $scope.offices.forEach(office => {
            officesIdsMap[office.id] = {
                id: office.id,
                officeName: office.name,
                locationGroupNames: [],
                payRateCounties: []
            }
        });

        $scope.locationGroups.forEach(locationGroup => {
            const locationOfficeIds = locationGroup.officeIds;
            locationOfficeIds.forEach(officeId => {
                if (officesIdsMap[officeId].locationGroupNames.includes(locationGroup.name) === false) {
                    officesIdsMap[officeId].locationGroupNames.push(locationGroup.name);
                }
            })
        });

        $scope.officesRatesTable = angular.copy(Object.values(officesIdsMap));
        $scope.officesRatesTable.forEach(officeRate => {
            $scope.data.payRateCounties.forEach(function (payRateCounty) {
                if (officeRate.locationGroupNames.includes(payRateCounty.groupName)) {
                    payRateCounty.supplementalRates = $scope.data.supplementalRates.filter(supplement => {
                        return supplement.state === payRateCounty.state && supplement.groupName === payRateCounty.groupName;
                    });
                    officeRate.payRateCounties.push(payRateCounty);
                }
            });
        })
    }

    function setHistoryTableData() {
        var options = {
            count: 5,
            sorting: { createdAt: "desc" }
        };

        $scope.historyTableData = new NgTableParams(options, {
            counts: [],
            dataset: $scope.payRateHistory
        });
    }

    setHistoryTableData();
};
