import { Flex, Skeleton } from "@chakra-ui/react";
import EntityNoteBox from "./EntityNoteBox";

export default function EntityNoteBoxSkeleton() {
  return (
    <EntityNoteBox.CardRoot>
      <EntityNoteBox.CardHeaderFlex>
        <Skeleton h={4} rounded="md" w="50%" />
        <Flex flexShrink={0} gap={4} m={2}>
          <Skeleton h={4} rounded="full" w={4} />
          <Skeleton h={4} rounded="full" w={4} />
        </Flex>
      </EntityNoteBox.CardHeaderFlex>

      <EntityNoteBox.CardBodyFlex>
        <Skeleton h={4} my={1} rounded="md" w="40%" />
        <Skeleton h={4} my={1} rounded="md" w="30%" />
        <Skeleton h={4} my={1} rounded="md" w="50%" />
        <Skeleton h={4} my={1} rounded="md" w="25%" />
        <Skeleton h={4} my={1} rounded="md" w="15%" />
        <Skeleton h={14} my={1} rounded="md" w="full" />
        <Skeleton h={4} my={1} rounded="md" w="10%" />
      </EntityNoteBox.CardBodyFlex>

      <EntityNoteBox.CardFooterFlex>
        <Flex align="center" flex={1} gap={2}>
          <Skeleton h={6} rounded="full" w={6} />
          <Skeleton h={4} rounded="md" w="40%" />
        </Flex>
        <Skeleton h={4} rounded="md" w="20%" />
      </EntityNoteBox.CardFooterFlex>
    </EntityNoteBox.CardRoot>
  );
}
