import React from "react";
import { z } from "zod";

export type ControlledForm<ZSchema extends z.AnyZodObject> = ReturnType<
  typeof useControlledForm<ZSchema>
>;

export default function useControlledForm<ZSchema extends z.AnyZodObject>(params: {
  schema: ZSchema;
  initialValues?: Partial<z.infer<ZSchema>>;
  onSuccess?: (data: z.infer<ZSchema>) => void;
  onChange?: (data: Partial<z.infer<ZSchema>>) => void;
  onError?: (error: any) => void;
}) {
  type TData = z.infer<ZSchema>;
  const initialValuesRef = React.useRef(params?.initialValues ?? {});
  const [isDirty, setIsDirty] = React.useState(false);
  const [state, setState] = React.useState<Partial<TData>>(initialValuesRef.current);
  const [errors, setErrors] = React.useState<{ [k in keyof TData]?: string[] }>({});
  const onChangeRef = React.useRef(params.onChange);

  React.useEffect(() => {
    if (isDirty) onChangeRef.current?.(state);
  }, [isDirty, state]);

  const reset = React.useCallback(() => {
    setErrors({});
    setIsDirty(false);
    setState(initialValuesRef.current);
  }, []);

  const setValue = React.useCallback(
    <TKey extends keyof TData>(key: TKey, value: TData[TKey] | null) => {
      setIsDirty(true);
      setErrors((prev) => ({ ...prev, [key]: undefined }));
      setState((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const getError = React.useCallback(
    <TKey extends keyof TData>(key: TKey) => {
      return errors[key]?.[0];
    },
    [errors]
  );

  const setError = React.useCallback(
    <TKey extends keyof TData>(key: TKey, value: string | undefined) => {
      setErrors((prev) => ({ ...prev, [key]: value === undefined ? undefined : [value] }));
    },
    []
  );

  const isInvalid = React.useCallback(
    <TKey extends keyof TData>(key: TKey) => {
      return Boolean(errors[key]);
    },
    [errors]
  );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return submit(state);
  };

  const submit = (data: z.infer<ZSchema>): z.SafeParseSuccess<TData> | z.SafeParseError<TData> => {
    const result = params.schema.safeParse(data);

    if (result.success) {
      params.onSuccess?.(result.data);
      return result;
    }

    setErrors(result.error.flatten().fieldErrors as typeof errors);
    params.onError?.(result.error);

    return result;
  };

  return {
    isDirty,
    state,
    errors,
    reset,
    setValue,
    setError,
    getError,
    isInvalid,
    onSubmit,
    submit,
  };
}
