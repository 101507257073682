import { useQuery } from "@tanstack/react-query";
import EntityCard from "../../../../../../shared/components/EntityCard";
import useApi from "../../../../../../shared/hooks/useApi";
import { useEntityLink } from "../../../../../../shared/hooks/useEntityLink";
import { queryKeys } from "../../../../../../shared/query-keys";
import { VisitInstanceId } from "../../../../../../shared/schema/schema";
import { CardButton } from "./CardButton";
import { LoadingEntityCard } from "./LoadingEntityCard";

export function VisitInstanceLinkCard(props: {
  visitInstanceId: VisitInstanceId;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}) {
  const { api } = useApi();
  const visitInstanceQuery = useQuery({
    queryKey: queryKeys.visitInstance.get(props.visitInstanceId),
    queryFn: () =>
      api.get("./visit_instance_info/:visitInstanceId", {
        path: { visitInstanceId: props.visitInstanceId },
      }),
  });

  const { open } = useEntityLink({
    onOpen: props.onOpenAngularModal,
    onClose: props.onCloseAngularModal,
    redirectModal: false,
  });

  if (visitInstanceQuery.isPending) {
    return <LoadingEntityCard />;
  }

  if (visitInstanceQuery.isError) {
    return null;
  }

  const visitInstance = visitInstanceQuery.data.visitInfo;

  const entity = {
    type: "VisitInstance" as const,
    id: props.visitInstanceId,
    startTime: visitInstance.startTime,
    endTime: visitInstance.endTime,
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => open({ entity, event });

  return (
    <CardButton onClick={handleClick}>
      <EntityCard entity={entity} />
    </CardButton>
  );
}
