import { createIcon } from "@chakra-ui/react";

const UserCircleFillIcon = createIcon({
  displayName: "UserCircleFillIcon",
  viewBox: "0 0 256 256",
  path: (
    <path
      d="M172 120a44 44 0 1 1-44-44a44 44 0 0 1 44 44Zm60 8A104 104 0 1 1 128 24a104.2 104.2 0 0 1 104 104Zm-16 0a88 88 0 1 0-153.8 58.4a81.3 81.3 0 0 1 24.5-23a59.7 59.7 0 0 0 82.6 0a81.3 81.3 0 0 1 24.5 23A87.6 87.6 0 0 0 216 128Z"
      fill="currentColor"
    />
  ),
});

export default UserCircleFillIcon;
