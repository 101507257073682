import { ChatIcon } from "@chakra-ui/icons";
import { Flex, Text, TextProps } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import { sortByInstant } from "../../../shared/utils/date-utils";
import { isIncomingMessage, isOutgoingMessage } from "../utils/communication-utils";

interface Props {
  messages: Messages["CommCenterTicket"]["messages"];
  status: Messages["CommCenterTicket"]["status"];
}

const getLastMessage = (messages: Messages["CommCenterTicket"]["messages"]) => {
  const orderedMessages = sortByInstant(messages, "createdAt", "asc");
  return orderedMessages.at(-1);
};

const isNoAnswer = (messages: Messages["CommCenterTicket"]["messages"]) => {
  return messages.every(isOutgoingMessage);
};

const isPendingAgencyResponse = (messages: Messages["CommCenterTicket"]["messages"]) => {
  const lastMessage = getLastMessage(messages);
  return lastMessage !== undefined && isIncomingMessage(lastMessage);
};

const isPendingReply = (messages: Messages["CommCenterTicket"]["messages"]) => {
  const lastMessage = getLastMessage(messages);
  return lastMessage !== undefined && isOutgoingMessage(lastMessage);
};

const renderStatus = ({ messages, status }: Props) => {
  if (isNoAnswer(messages)) {
    return <SmsStatusText color="red">No answer</SmsStatusText>;
  }

  if (isPendingAgencyResponse(messages)) {
    return status === "RESOLVED" ? (
      <SmsStatusText color="green">Resolved</SmsStatusText>
    ) : (
      <SmsStatusText color="red">Pending agency response</SmsStatusText>
    );
  }

  if (isPendingReply(messages)) {
    return <SmsStatusText color="orange">Pending reply</SmsStatusText>;
  }

  return undefined;
};

function SmsStatusText(props: TextProps) {
  return <Text fontSize={{ base: "xs", "2xl": "md" }} {...props} />;
}

function SmsTicketStatus(props: Props) {
  return (
    <Flex alignItems="center" gap={2}>
      <ChatIcon />
      {renderStatus(props)}
    </Flex>
  );
}

export default SmsTicketStatus;
