import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import { Button, IconButton, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import DataTable from "../../../../../shared/components/DataTable/DataTable";
import { DataTableEndActionFlex } from "../../../../../shared/components/DataTable/DataTableUI";
import useQueryDataTable from "../../../../../shared/components/DataTable/useQueryDataTable";
import DebouncedInput from "../../../../../shared/components/DebouncedInput";
import useApi from "../../../../../shared/hooks/useApi";
import { usePageFetchingObserver } from "../../../../../shared/hooks/usePageIndication";
import AddRoundedIcon from "../../../../../shared/icons/AddRoundedIcon";
import { WorkflowSkillId } from "../../../../../shared/schema/schema";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { DeleteSkillAlertDialogTrigger } from "./DeleteSkillAlertDialog";

interface Props {
  onClickAdd: () => void;
}

type Skill = {
  id: WorkflowSkillId;
  createdAt: Instant;
};

export default function WorkflowSkillsDataTable(props: Props) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const { queries } = useApi();
  const queryOptions = queries.workflow.skills({ search: searchQuery });

  const { dataTableProps } = useQueryDataTable({
    query: useQuery({ ...queryOptions, select: (x) => x.skills }),
    columns: columns,
    columnVisiblity: {
      forceVisibleColumns: ["_actions"],
    },
    initialSorting: [{ id: "createdAt", desc: true }],
  });

  usePageFetchingObserver(queryOptions);

  const actionNode = (
    <>
      <Button colorScheme="blue" leftIcon={<AddRoundedIcon />} onClick={props.onClickAdd}>
        Add Skill
      </Button>
    </>
  );

  const filterNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search skill by name"
          value={searchQuery}
          onChange={setSearchQuery}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
    </>
  );

  return (
    <DataTable
      {...dataTableProps}
      actionNode={actionNode}
      filterNode={filterNode}
      spacing="tight"
    />
  );
}

const { accessor, display } = createColumnHelper<Skill>();

const columns = [
  accessor("id", { header: "ID" }),
  accessor("createdAt", {
    header: "Created At",
    sortingFn: "instant",
    cell: (x) => dateFormatter.toDateTime(x.getValue()),
  }),
  display({
    id: "_actions",
    cell: (x) => (
      <DataTableEndActionFlex>
        <DeleteSkillAlertDialogTrigger id={x.row.original.id}>
          <IconButton
            _hover={{ bg: "red.50", color: "red.600", borderColor: "red.600" }}
            aria-label="Remove"
            icon={<DeleteIcon />}
            tabIndex={-1}
            variant="outline"
            onClick={(e) => e.stopPropagation()}
          />
        </DeleteSkillAlertDialogTrigger>
      </DataTableEndActionFlex>
    ),
  }),
];
