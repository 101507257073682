import { useReactBridgeDispatchEvent } from "../../../shared/hooks/useReactBridgeEvent";
import CommCenterChatWrapper from "./CommCenterChatWrapper";
import { useCommCenterTicketPopup } from "../hooks/useCommCenterTicketPopup";

export default function CommCenterTicketPopupListener() {
  const activeTicket = useCommCenterTicketPopup();

  useReactBridgeDispatchEvent({
    eventName: "COMM_CENTER:OPEN_TICKET_POPUP",
    onEvent: (event) => activeTicket.open(event),
  });

  if (activeTicket.state === null) {
    return <></>;
  }

  return <CommCenterChatWrapper {...activeTicket.state} onClose={activeTicket.close} />;
}
