import { Input } from "@chakra-ui/react";
import React from "react";

type DatePickerInputProps = Record<string, unknown> & {
  placeholder?: string;
};

const DatePickerInput = React.forwardRef(
  (props: DatePickerInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <Input
        color="gray.600"
        fontWeight="semibold"
        {...props}
        ref={ref}
        placeholder={props.placeholder ?? "Select date"}
      />
    );
  }
);
DatePickerInput.displayName = "DatePickerInput";

export default DatePickerInput;
