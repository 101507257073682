import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { useGlobalWorkflowRunner } from "../../../shared/hooks/useGlobalWorkflowRunner";
import { queryKeys } from "../../../shared/query-keys";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import {
  getLatestCommCenterEmailMessageByRecipient,
  isEmailTicket,
} from "../utils/comm-center-email-utils";
import {
  getLatestCommCenterMessageByRecipient,
  getWorkflowHintsFromTicket,
  toCommCenterTicket,
} from "../utils/communication-utils";
import useEmailTicketViewMutation from "./useEmailTicketViewMutation";
import useTicketViewMutation from "./useTicketViewMutation";

export default function useCommCenterTicket(
  ticketId: CommCenterTicketId,
  options?: {
    queryOptions?: { enabled?: boolean };
    markAsRead?: boolean;
  }
) {
  const { api } = useApi();
  const workflowRunner = useGlobalWorkflowRunner();
  const markAsRead = useTicketViewMutation();
  const markAsReadEmails = useEmailTicketViewMutation();

  return useQuery({
    enabled: options?.queryOptions?.enabled,
    queryKey: queryKeys.commCenter.get(ticketId),
    queryFn: async () => {
      const response = await api.get("./comm_center/tickets/:ticketId", { path: { ticketId } });

      let latestReceivedMessage;
      if (isEmailTicket(response.ticket)) {
        latestReceivedMessage = getLatestCommCenterEmailMessageByRecipient(
          response.ticket.emailThread.messages
        );
        if (options?.markAsRead === true && latestReceivedMessage?.readAt === null) {
          markAsReadEmails.mutate({
            ticketId: response.ticket.id,
          });
        }
      } else {
        latestReceivedMessage = getLatestCommCenterMessageByRecipient(response.ticket.messages);
        if (options?.markAsRead === true && latestReceivedMessage?.readAt === null) {
          markAsRead.mutate({
            ticketId: response.ticket.id,
            latestReceivedMessageId: latestReceivedMessage.id,
          });
        }
      }

      workflowRunner.setHints(getWorkflowHintsFromTicket(response.ticket));

      return {
        ticket: toCommCenterTicket(response.ticket),
      };
    },
    placeholderData: keepPreviousData,
  });
}
