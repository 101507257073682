import { Text } from "@chakra-ui/react";
import { Messages, ResponseOf } from "../../../core/api";
import { isEmailTicket } from "../utils/comm-center-email-utils";
import { isCallTicket, isMobileChatTicket, isSmsTicket } from "../utils/communication-utils";
import ChatMessages from "./ChatMessages";
import EmailTicket from "./Emails/EmailTicket";
import TelephonyTicket from "./Telephony/TelephonyTicket";

interface Props {
  ticket: Messages["CommCenterTicket"];
  attachments: File[];
  onSubmitNewMessage: (message: string) => void;
  onSelectFile: (newFile: File) => void;
  onClickRemoveAttachment: (attachment: File) => void;
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
}

const ActiveTicket = (props: Props): JSX.Element => {
  if (isMobileChatTicket(props.ticket) || isSmsTicket(props.ticket)) {
    return (
      <ChatMessages
        activeTicket={props.ticket}
        attachments={props.attachments}
        onClickRemoveAttachment={props.onClickRemoveAttachment}
        onSelectFile={props.onSelectFile}
        onSubmitNewMessage={props.onSubmitNewMessage}
      />
    );
  }
  if (isCallTicket(props.ticket)) {
    return <TelephonyTicket ticket={props.ticket} />;
  }
  if (isEmailTicket(props.ticket)) {
    return (
      <EmailTicket
        activeTicket={props.ticket}
        onSuccessCreateEmailTicket={props.onSuccessCreateEmailTicket}
      />
    );
  }
  return <Text>Unhandled Ticket</Text>;
};

export default ActiveTicket;
