import { PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Portal,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import PhoneHangupIcon from "../../../shared/icons/PhoneHangupIcon";
import InboundCallMetaActions from "./InboundCallMetaActions";

type Props = {
  title: string;
  subtitle: string;
  isOpen: boolean;
  isLoading: boolean;
  isRingtoneMuted: boolean;
  onToggleMuteRingtone: () => void;
  onToggleMinimize: () => void;
  onClose: () => void;
  onAccept: () => void;
  onDecline: () => void;
};

export default function InboundCallBanner(props: Props) {
  const bodyEl = React.useRef(document.querySelector("body"));

  return (
    <AnimatePresence>
      {props.isOpen && (
        <Portal>
          <MotionBox
            animate={{ opacity: 1, scale: 1 }}
            bottom={0}
            drag={true}
            dragConstraints={bodyEl}
            dragElastic={1}
            dragTransition={{ power: 0.1, timeConstant: 100 }}
            exit={{ opacity: 0, scale: 0.7 }}
            initial={{ opacity: 0, scale: 0.7 }}
            left={0}
            m="auto"
            mb={20}
            mx={8}
            position="fixed"
            transition={{ duration: 0.2 }}
            whileDrag={{ scale: 0.8 }}
            zIndex="modal"
          >
            <Flex
              align="center"
              bg="white"
              gap={8}
              justify="space-between"
              p={4}
              rounded="128px"
              shadow="0 0 16px -2px rgba(0,0,0,0.25)"
            >
              <Flex align="center" gap={4}>
                <ButtonGroup>
                  <IconButton
                    aria-label="decline"
                    colorScheme="red"
                    icon={<PhoneHangupIcon />}
                    rounded="full"
                    size="lg"
                    variant="outline"
                    onClick={props.onDecline}
                  />

                  <IconButton
                    aria-label="answer"
                    colorScheme="green"
                    icon={<PhoneIcon h={4} />}
                    isLoading={props.isLoading}
                    rounded="full"
                    size="lg"
                    onClick={props.onAccept}
                  />
                </ButtonGroup>
                <VStack gap={8}>
                  <Flex direction="column">
                    <Heading size="md">{props.title}</Heading>
                    <Heading color="gray.600" fontWeight="semibold" size="sm">
                      {props.subtitle}
                    </Heading>
                  </Flex>
                </VStack>
              </Flex>

              <Divider borderColor="gray.400" h="6" orientation="vertical" />

              <Box paddingEnd={4}>
                <InboundCallMetaActions
                  isMinimized={true}
                  isRingtoneMuted={props.isRingtoneMuted}
                  onClose={props.onClose}
                  onToggleMinimize={props.onToggleMinimize}
                  onToggleMuteRingtone={props.onToggleMuteRingtone}
                />
              </Box>
            </Flex>
          </MotionBox>
        </Portal>
      )}
    </AnimatePresence>
  );
}

const MotionBox = motion(Box);
