//! @ngInject
export function ProcessorModalProcessGenerator() {

    // // example for processObject: 
    // var processObject = {
    //     action: function () {
    //         //DO SOMETHING and return a promise 
    //         return $q(function (resolve, reject) { /*...*/ });
    //     },
    //     isProcessing: false,
    //     isSuccess: false,
    //     processingText: "Processing X",
    //     processFailedText: "Failed to process X",
    //     processCompletedText: "Process X completed"
    // };

    const createNewProcessObject = function (processAction, processingText, processFailedText, processCompletedText, processPartiallyCompletedText, processTimeoutText) {
        const processObject = {
            action: processAction,
            isProcessing: false,
            isSuccess: false,
            isTimeout: false,
            processingText,
            processingErrors: [],
            processFailedText,
            processCompletedText,
            processPartiallyCompletedText,
            processTimeoutText
        };
        return processObject;
    };

    const composeProcessObjectAction = function (fn, processObject) {
        const previous = processObject.action;
        processObject.action = (...args) => previous(fn(...args));

        return processObject;
    }

    this.createNewProcess = createNewProcessObject;
    this.composeProcessObjectAction = composeProcessObjectAction;
};

//! @ngInject
export function processorModalCtrl($scope, modalData, $uibModalInstance) {

    $scope.title = modalData.title;
    $scope.getTitle = function () {
        return $scope.title || "Processing";
    };

    // // 'modalData.processes' is an array of process objects in the order they should be executed 
    // // each process object should look like the following: 
    // var processObject = {
    //     action: function () {
    //         //DO SOMETHING and return a promise 
    //         return $q(function (resolve, reject) { /*...*/ });
    //     },
    //     isProcessing: false,
    //     isSuccess: false,
    //     processingText: "Processing X",
    //     processFailedText: "Failed to process X",
    //     processCompletedText: "Process X completed"
    // };
    const allProcesses = modalData.processes || [];
    $scope.processes = [];
    $scope.minContentHeight = allProcesses.length * 30 + 120;
    $scope.showButtons = false;
    $scope.areAllProcessesCompleted = false;

    // 'modalData.onSuccessAction' is the action of the button that will appear only if all the processes completed successfully 
    // 'modalData.isSuccessActionAllowed' is the method which determines whether the action of that button is allowed 
    // and 'modalData.successActionText' is the text of that button
    $scope.onSuccessAction = modalData.onSuccessAction && function () {
        modalData.onSuccessAction();
        $scope.closeModal('success');
    };
    $scope.isSuccessActionAllowed = modalData.isSuccessActionAllowed || Boolean(modalData.onSuccessAction);
    $scope.successActionText = modalData.successActionText;

    // 'modalData.onCloseModal' is the action which is executed when closing the modal
    $scope.closeModal = function(result) {
        if (modalData.onCloseModal) modalData.onCloseModal();
        $uibModalInstance.close(result);
    };

    const results = allProcesses.map(_ => undefined);
    
    function executeProcess(processIndex) {
        const processObject = allProcesses[processIndex];
        processObject.isProcessing = true;
        $scope.processes.push(processObject);

        const onSuccess = function (response) {
            processObject.isProcessing = false;
            processObject.isSuccess = true;
            processObject.processingErrors = response && response.errors ? response.errors : [];
            results[processIndex] = response;
            if (processIndex < allProcesses.length - 1) {
                executeProcess(processIndex + 1);
            }
            else {
                $scope.showButtons = true;
                $scope.areAllProcessesCompleted = true;
            }
        };

        const onFailure = function (error) {
            if (error.status === 504) {
                processObject.isTimeout = true;
            }

            processObject.isProcessing = false;
            processObject.isSuccess = false;
            processObject.processingErrors = error && error.length ? error : [];
            for (let index = processIndex + 1; index < allProcesses.length; index++) {
                const otherProcessObject = allProcesses[index];
                $scope.processes.push(otherProcessObject);
                if (error.status === 504) {
                    otherProcessObject.isTimeout = true;
                }
                otherProcessObject.isProcessing = false;
                otherProcessObject.isSuccess = false;
            }
            $scope.showButtons = true;
        };

        processObject.action(results).then(onSuccess, onFailure);
    }

    function onLoaded() {
        executeProcess(0);
    }

    onLoaded();
};