import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  StyleProps,
} from "@chakra-ui/react";
import React from "react";

interface IntakeProfileInputProps {
  isEditable: boolean;
  isRequired: boolean;
  label: string;
  value: string;
  type?: InputProps["type"];
  minLength?: number;
  maxLength?: number;
  validateSchema?: Zod.Schema;
  styleProps?: StyleProps;
  rightElement?: React.ReactNode;
  onChange: (value: string) => void;
}

const IntakePatientProfileInput = (props: IntakeProfileInputProps) => {
  const [error, setError] = React.useState<string | null>(null);

  const validate = (value: string) => {
    if (props.validateSchema === undefined) {
      setError(null);
    } else {
      const result = props.validateSchema.safeParse(value);
      setError(!result.success ? result.error.flatten().formErrors.at(0) ?? null : null);
    }
  };

  return (
    <FormControl
      isInvalid={error !== null}
      isRequired={props.isEditable ? props.isRequired : false}
      {...props.styleProps}
    >
      <FormLabel>{props.label}</FormLabel>
      <InputGroup>
        <Input
          isDisabled={!props.isEditable}
          maxLength={props.maxLength}
          minLength={props.minLength}
          type={props.type ?? "text"}
          value={props.isEditable ? props.value : props.value === "" ? "Unknown" : props.value}
          onBlur={() => validate(props.value)}
          onChange={(event) => props.onChange(event.target.value)}
        />
        {props.rightElement}
      </InputGroup>
      <FormErrorMessage>{error ?? ""}</FormErrorMessage>
    </FormControl>
  );
};

export default IntakePatientProfileInput;
