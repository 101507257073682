import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";
import { CommCenterTicketId, VisitAssignmentId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import { CommCenterTicket } from "../communication.types";

export function useMarkCommCenterTicketAsUnread(options: {
  ticketId: CommCenterTicketId;
  assignmentId?: VisitAssignmentId;
  onMutate?: () => void;
  onSuccess?: () => void;
}) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { api, queries } = useApi();

  return useMutation({
    mutationFn: (ticketId: CommCenterTicketId) => {
      return api.post("./comm_center/tickets/:ticketId/unread", {
        path: {
          ticketId: ticketId,
        },
      });
    },
    onMutate: () => {
      const search = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets
            .find((ticket) => ticket.id === options.ticketId)
            ?.messages.forEach((message) => (message.readAt = null));
        },
      });

      const assignmentTickets =
        options.assignmentId === undefined
          ? undefined
          : optimisticUpdate<CommCenterTicket[]>({
              queryClient,
              queryKey: queries.visit.assignments.tickets(options.assignmentId).queryKey,
              update: (draft) => {
                draft
                  .find((ticket) => ticket.id === options.ticketId)
                  ?.messages.forEach((message) => (message.readAt = null));
              },
            });

      return { search, assignmentTickets };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKeys.commCenter.search.K, context?.search.previousValue);
      if (context?.assignmentTickets !== undefined) {
        queryClient.setQueryData(
          queries.visit.assignments.tickets(options.assignmentId!).queryKey,
          context.assignmentTickets.previousValue
        );
      }

      toast({
        title: "Error marking ticket as unread",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: () => {
      options.onSuccess?.();

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.get(options.ticketId) });
        queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.search.K });

        if (options.assignmentId !== undefined) {
          queryClient.invalidateQueries(queries.visit.assignments.tickets(options.assignmentId));
        }
      }, 500);
    },
  });
}
