import { createIcon } from "@chakra-ui/react";

const FirstPageIcon = createIcon({
  displayName: "FirstPageIcon",
  viewBox: "0 0 24 24",
  path: (
    <path d="M6 18V6h2v12H6Zm11 0l-6-6l6-6l1.4 1.4l-4.6 4.6l4.6 4.6L17 18Z" fill="currentColor" />
  ),
});

export default FirstPageIcon;
