import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";

interface Props {
  searchTerm: string;
}

function useSearchPhonebookContactsQuery(props: Props) {
  const { api } = useApi();

  return useQuery({
    queryKey: queryKeys.phonebook.search(props.searchTerm),
    staleTime: Infinity,
    queryFn: () => api.get("./phonebook", {}),
    select: (data) => {
      const { searchTerm } = props;
      if (searchTerm !== undefined && searchTerm !== "") {
        return data.contacts.filter((x) =>
          x.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        );
      }
      return data.contacts;
    },
  });
}

export default useSearchPhonebookContactsQuery;
