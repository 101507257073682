import { Box, Button, Flex, Skeleton } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import { ResponseOf } from "../../../../../core/api";
import ErrorPage from "../../../../../shared/components/ErrorPage";
import UserCircleFillIcon from "../../../../../shared/icons/UserCircleFillIcon";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";
import CurrentlyTalkingToIndicator from "./CurrentlyTalkingToIndicator";
import TransferFromIntakePatientButton from "../../../components/TransferFromIntakePatientButton";

interface Props {
  patientQuery: UseQueryResult<ResponseOf<"get", "./patients/:patientId/intake">>;
  onClickIntakeProfile: () => void;
}

export default function PatientIntakeFlowActions(props: Props) {
  if (props.patientQuery.isPending) {
    return (
      <Flex alignItems="center" gap={4} justifyContent="flex-end" p={6}>
        <Skeleton height={33} width={97} />
        <Skeleton height={33} width={252} />
        <Skeleton height={33} width={178} />
      </Flex>
    );
  }

  if (props.patientQuery.isError) {
    return (
      <ErrorPage
        error={formatErrorResponse(props.patientQuery.error ?? "Unknown error")}
        resetErrorBoundary={() => {
          props.patientQuery.refetch();
        }}
      />
    );
  }

  if (props.patientQuery.data === undefined) {
    <ErrorPage
      error={formatErrorResponse("Error: data is undefined")}
      resetErrorBoundary={() => {
        props.patientQuery.refetch();
      }}
    />;
  }

  const patient = props.patientQuery.data.patient;

  return (
    <Flex alignItems="center" justify="space-between" p={6}>
      <Box>
        <CurrentlyTalkingToIndicator />
      </Box>
      <Flex gap={4}>
        <TransferFromIntakePatientButton patientId={patient.id} track={patient.track} />
        <Button
          leftIcon={<UserCircleFillIcon />}
          variant="outline"
          onClick={props.onClickIntakeProfile}
        >
          Open intake modal
        </Button>
      </Flex>
    </Flex>
  );
}
