import Page, { PageTab } from "../../../../shared/components/Page";
import WorkflowAgencyMembersSkillsTab from "./WorkflowAgencyMembersSkills/WorkflowAgencyMembersSkillsTab";
import WorkflowSkillsListTab from "./WorkflowSkillsList/WorkflowSkillsListTab";

const tabs: PageTab[] = [
  {
    header: "Skills",
    route: "app.workflow_skills",
    component: <WorkflowSkillsListTab />,
  },
  {
    header: "Assignment",
    route: "app.workflow_skills.assign",
    component: <WorkflowAgencyMembersSkillsTab />,
  },
];

export default function WorkflowSkillsRoute() {
  return <Page.Tabbed tabs={tabs} />;
}
