import { CaregiverId, OfficeCaregiverHoursRestrictionOverrideId, OfficeId } from "../../scripts/messages/ids";
import { Api } from "../../scripts/services/Api";
import { Endpoint } from "../../scripts/services/endpoint.service";
import { CreateOfficeCaregiverHoursOverrideRequest, GetOverridesResponse, VisitsHoursRestrictionsValidationResult } from "./caregiver-hours-override.types";

//! @ngInject
export class CaregiverHoursOverrideService {
  constructor(
    private api: Api,
    private endpoint: Endpoint,
    private $rootScope: ng.IRootScopeService
  ) { }

  getOverrides = (
    caregiverId: CaregiverId,
    offices?: OfficeId[]
  ) => {

    let path = "agencies/:agencyId/agency_member/:agencyMemberId/caregiver/:caregiverId/hours_overrides?";
    const queryParams: string[] = [];

    if (offices !== undefined) {
      for (const s of offices) {
        queryParams.push(`officeIds[]=${s}`);
      }
    }

    path += queryParams.join("&").toString();

    const url = this.endpoint({
      path,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        caregiverId: caregiverId,
      },
    });

    return this.api.get<GetOverridesResponse>(url);
  };

  removeOverride = (caregiverId: CaregiverId, overrideId: OfficeCaregiverHoursRestrictionOverrideId) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_member/:agencyMemberId/caregiver/:caregiverId/hours_overrides/:overrideId",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        caregiverId: caregiverId,
        overrideId: overrideId,
      },
    });

    return this.api.post<VisitsHoursRestrictionsValidationResult>(url);
  };

  createOverride = (caregiverId: CaregiverId, override: CreateOfficeCaregiverHoursOverrideRequest) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_member/:agencyMemberId/caregiver/:caregiverId/hours_overrides",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        caregiverId: caregiverId
      },
    });

    return this.api.post<VisitsHoursRestrictionsValidationResult>(url, override);
  };

  editOverride = (caregiverId: CaregiverId, overrideId: OfficeCaregiverHoursRestrictionOverrideId, override: CreateOfficeCaregiverHoursOverrideRequest) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_member/:agencyMemberId/caregiver/:caregiverId/hours_overrides/:overrideId",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        caregiverId: caregiverId,
        overrideId: overrideId
      },
    });

    return this.api.put<VisitsHoursRestrictionsValidationResult>(url, { id: overrideId, ...override });
  };
}
