import { createIcon } from "@chakra-ui/react";

const MaximizeIcon = createIcon({
  displayName: "MaximizeIcon",
  viewBox: "0 0 32 32",
  path: (
    <path
      d="M20 2v2h6.586L18 12.582L19.414 14L28 5.414V12h2V2H20zm-6 17.416L12.592 18L4 26.586V20H2v10h10v-2H5.414L14 19.416z"
      fill="currentColor"
    />
  ),
});

export default MaximizeIcon;
