import { ChronoUnit, Instant, LocalDate, LocalDateTime, ZoneId } from "@js-joda/core";
import { cloneDeep } from "lodash";
import { fmap } from ".";

export const NEW_YORK_TZ = "America/New_York";
export const NY_ZONE_ID = ZoneId.of("America/New_York");
export type AvailableTimeZones = "America/New_York";

export function toLocalDateTime(
  date: LocalDateTime | Instant | LocalDate,
  options?: { timezone?: AvailableTimeZones }
) {
  if (date instanceof Instant) {
    const zoneId = fmap(options?.timezone, (tz) => ZoneId.of(tz)) ?? ZoneId.systemDefault();
    return LocalDateTime.ofInstant(date, zoneId);
  }
  return date instanceof LocalDateTime ? date : date.atStartOfDay();
}

export function isXDaysAfter(
  dateToCheckAgainst: LocalDateTime | LocalDate,
  dateDayAfter: LocalDateTime | LocalDate,
  days: number
) {
  const firstDateToCheck =
    dateToCheckAgainst instanceof LocalDateTime
      ? dateToCheckAgainst.toLocalDate()
      : dateToCheckAgainst;
  const secondDateToCheck =
    dateDayAfter instanceof LocalDateTime ? dateDayAfter.toLocalDate() : dateDayAfter;
  const durationInDays = firstDateToCheck.until(secondDateToCheck, ChronoUnit.DAYS);
  return durationInDays === days;
}

export function instantComparatorByKey<T extends { [K in P]: Instant }, P extends keyof T>(
  key: P,
  order: "asc" | "desc"
): (a: T, b: T) => number {
  return (a: T, b: T) => {
    if (a[key].isBefore(b[key])) {
      return order === "asc" ? -1 : 1;
    } else if (a[key].isAfter(b[key])) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  };
}

export function sortByInstant<T extends { [K in P]: Instant }, P extends keyof T>(
  array: T[],
  key: P,
  order: "asc" | "desc"
): T[] {
  return cloneDeep(array).sort(instantComparatorByKey(key, order));
}

export function formatSeconds(seconds: number) {
  const m = Math.floor(seconds / 60);
  const s = seconds % 60;

  return `${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
}
