import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { CaregiverId, FaxDetailsId, PatientId } from "../../../shared/schema/schema";
import CaregiverSelect from "../../caregiver/components/CaregiverSelect";
import PatientSelect from "../../patient/components/PatientSelect";

type Props = {
  caregiverId: CaregiverId | null;
  patientId: PatientId | null;
  faxId: FaxDetailsId;
  onSelectEntity: (faxId: FaxDetailsId, entity: SelectEntityParams | null) => void;
};

export type SelectEntityParams =
  | {
      relatedEntityType: "Caregiver";
      caregiverId: CaregiverId;
    }
  | {
      relatedEntityType: "Patient";
      patientId: PatientId;
    };

const EditFaxEntitySection = (props: Props) => {
  return (
    <Tabs>
      <TabList>
        <Tab flex={1} p={3}>
          {" "}
          Caregiver
        </Tab>
        <Tab flex={1} p={3}>
          {" "}
          Patient
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <CaregiverSelect
            value={props.caregiverId}
            onChange={(value) =>
              props.onSelectEntity(
                props.faxId,
                value === null ? null : { relatedEntityType: "Caregiver", caregiverId: value.id }
              )
            }
          />
        </TabPanel>
        <TabPanel>
          <PatientSelect
            value={props.patientId}
            onChange={(value) =>
              props.onSelectEntity(
                props.faxId,
                value === null ? null : { relatedEntityType: "Patient", patientId: value.id }
              )
            }
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default EditFaxEntitySection;
