import React from "react";
import { Messages } from "../../../core/api";
import EntitySelect from "../../../shared/components/EntitySelect";
import { useDebounce } from "../../../shared/hooks/useDebounce";
import CaregiverIcon from "../../../shared/icons/CaregiverIcon";
import { CaregiverId } from "../../../shared/schema/schema";
import { useCaregiversQuery } from "../hooks/useCaregiversQuery";
import { useSearchCaregiversQuery } from "../hooks/useSearchCaregiversQuery";

type CaregiverResult = Pick<
  Messages["Caregiver"],
  "id" | "firstName" | "middleName" | "lastName" | "displayId" | "gender" | "photoUrl"
>;

type Props = {
  value: CaregiverId | null;
  isDisabled?: boolean;
  onChange: (value: CaregiverResult | null) => void;
};

const CaregiverSelect = (props: Props) => {
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchText = useDebounce(searchText, 200);
  const searchQuery = useSearchCaregiversQuery({ term: debouncedSearchText });
  const caregiversQuery = useCaregiversQuery();

  const getPhotoUrlByCaregiver = (caregiver: CaregiverResult) => {
    return caregiver.photoUrl ?? "/admin/images/blank-profile.jpg";
  };

  function getFullName(
    entity: Pick<CaregiverResult, "id" | "displayId" | "firstName" | "middleName" | "lastName">
  ): string {
    let name = entity.firstName;

    if (entity.middleName ?? null !== null) {
      name += ` ${entity.middleName?.charAt(0)}.`;
    }

    name += ` ${entity.lastName}`;

    return name;
  }

  return (
    <EntitySelect
      buttonPlaceholder="Select caregiver"
      entitiesQuery={caregiversQuery}
      getEntityDisplayText={getFullName}
      inputPlaceholder="Search caregiver..."
      isDisabled={props.isDisabled}
      leftIcon={<CaregiverIcon fontSize={18} />}
      notFoundPlaceholder="No results"
      photoUrlPredicate={getPhotoUrlByCaregiver}
      searchByPlaceholder="Search by name or ID"
      searchQuery={searchQuery}
      searchText={searchText}
      value={props.value}
      onChange={props.onChange}
      onChangeSearchText={setSearchText}
    />
  );
};

export default CaregiverSelect;
