import { InputProps } from "@chakra-ui/react";
import { css } from "@emotion/react";
// eslint-disable-next-line no-restricted-imports
import styled from "@emotion/styled";
import "@js-joda/timezone";
import DatePickerHeader from "./DatePickerHeader";
import DatePickerInput from "./DatePickerInput";
import DatePickerJsJodaAdapter, { DatePickerJsJodaAdapterProps } from "./DatePickerJsJodaAdapter";

const daysStyle = css`
  .react-datepicker__day-names {
    display: flex;
  }

  .react-datepicker__day-name {
    font-size: 12px;
    height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .react-datepicker__day {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 14px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    flex: 1;

    &:hover:not(.react-datepicker__day--in-range):not(.react-datepicker__day--selected) {
      background-color: var(--chakra-colors-blue-100);
    }
  }

  .react-datepicker__day--outside-month {
    color: var(--chakra-colors-gray-300);
  }

  .react-datepicker__day--today:not(.react-datepicker__day--outside-month) {
    border: 1px solid var(--chakra-colors-blue-500);
  }

  .react-datepicker__day--selected {
    background-color: var(--chakra-colors-blue-500);
    color: var(--chakra-colors-white);
    &:hover {
      background-color: var(--chakra-colors-blue-600);
    }
  }
`;

const daysRangeStyle = css`
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 0;
  }

  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start
    .react-datepicker__day--in-range.react-datepicker__day--selecting-range-start {
    /* border-start- */
  }

  .react-datepicker__day--in-selecting-range:first-of-type,
  .react-datepicker__day--in-range:first-of-type {
    border-start-start-radius: 8px;
    border-end-start-radius: 8px;
  }

  .react-datepicker__day--in-selecting-range:last-child,
  .react-datepicker__day--in-range:last-child {
    border-start-end-radius: 8px;
    border-end-end-radius: 8px;
  }

  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--range-start {
    border-start-start-radius: 8px;
    border-end-start-radius: 8px;
  }

  .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--range-end {
    border-start-end-radius: 8px;
    border-end-end-radius: 8px;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--outside-month) {
    background-color: var(--chakra-colors-blue-100);
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--outside-month) {
    background-color: var(--chakra-colors-blue-500);
    color: var(--chakra-colors-white);
  }
`;

const weekStyle = css`
  .react-datepicker__week {
    display: flex;
  }
`;

const monthStyle = css`
  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const headerStyle = css`
  .react-datepicker__header {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }
`;

const timeHeaderStyle = css`
  .react-datepicker-time__header {
    font-weight: var(--chakra-fontWeights-semibold);
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
  }
`;

const timePickerListStyle = css`
  .react-datepicker__time-box {
    height: 250px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 80px;
    align-items: center;
  }
`;

const timePickerListItemStyle = css`
  .react-datepicker__time-list-item {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 14px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    flex: 1;
    padding: 10px;
  }

  .react-datepicker__time-list-item--selected {
    background-color: var(--chakra-colors-blue-500);
    color: var(--chakra-colors-white);
    &:hover {
      background-color: var(--chakra-colors-blue-600);
    }
  }
`;

export interface BaseDatePickerProps extends DatePickerJsJodaAdapterProps {
  inputProps?: InputProps;
}

const StyledBaseDatePicker = styled.div`
  ${daysStyle}
  ${daysRangeStyle}
  ${weekStyle}
  ${monthStyle}
  ${headerStyle}
  ${timeHeaderStyle}
  ${timePickerListItemStyle}
  ${timePickerListStyle}

  width: inherit;

  .react-datepicker__month-container {
    width: 250px;
  }

  .react-datepicker-popper {
    background-color: var(--chakra-colors-white);
    border: 1px solid var(--chakra-colors-gray-300);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), var(--extra-box-shadow, 0 0 0);
    display: flex;
    gap: 12px;
    padding: 8px;
    border-radius: 8px;
    z-index: 1;
    max-height: 380px;
    min-height: 300px;
  }
`;

const BaseDatePicker = (props: BaseDatePickerProps) => {
  return (
    <StyledBaseDatePicker>
      <DatePickerJsJodaAdapter
        {...props}
        customInput={<DatePickerInput {...props.inputProps} />}
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [5, 10],
            },
          },
        ]}
        popperPlacement={props.popperPlacement ?? "bottom"}
        renderCustomHeader={(headerProps) => (
          <DatePickerHeader
            {...headerProps}
            pickerYears={props.pickerYears}
            totalMonthsShown={props.monthsShown}
          />
        )}
        showPopperArrow={false}
      />
    </StyledBaseDatePicker>
  );
};

export default BaseDatePicker;
