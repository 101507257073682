import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { TelephonyCallId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";

export default function useInviteToCallMutation(props: { onInvite?: () => void }) {
  const { api } = useApi();
  const toast = useToast();

  return useMutation({
    mutationFn: (params: {
      callId: TelephonyCallId;
      invitee: Messages["TelephonyInviteToCallInvitee"];
    }) => {
      return api.post("./telephony/calls/:callId/invite", {
        path: { callId: params.callId },
        body: { invitee: params.invitee },
      });
    },

    onSuccess: ({ result }) => {
      props.onInvite?.();

      switch (result.status) {
        case "ALREADY_IN_CALL":
        case "ALREADY_INVITED":
        case "CONTACT_ALREADY_IN_THE_CALL":
        case "NO_SELF_INVITE":
        case "INVALID_PHONE_NUMBER":
        case "AGENCY_MEMBER_NOT_CONNECTED":
        case "CALL_NOT_CONNECTED":
        case "NO_SIP_ENDPOINT":
          return toast({
            title: "Could not send invitation",
            description: {
              ALREADY_IN_CALL: "Agency member is already in a call",
              CONTACT_ALREADY_IN_THE_CALL: "Contact is already in the call",
              INVALID_PHONE_NUMBER: "Invalid phone number",
              AGENCY_MEMBER_NOT_CONNECTED: "Agency member is not connected",
              CALL_NOT_CONNECTED: "Can't invite to a call that hasn't started yet",
              NO_SIP_ENDPOINT: "Agency member is not connected",
              ALREADY_INVITED: "The participant is already invited",
              NO_SELF_INVITE: "You cannot invite yourself to a call",
            }[result.status],
            status: "warning",
            position: "top-right",
          });
        case "OK":
          return toast({
            title: "Invitation sent",
            status: "success",
            description: "Invitation sent",
            position: "top-right",
          });
      }
    },
    onError: (error) => {
      toast({
        title: "Could not send invitation",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });
}
