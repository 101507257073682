import { Box, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { Messages } from "../../../../core/api";
import useApi from "../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../shared/query-keys";
import { HumanTaskLayoutWrapper } from "./components/HumanTaskLayoutWrapper";
import HumanTaskCallIntakeLayout from "./layouts/HumanTaskCallIntakeLayout";
import HumanTaskChatLayout from "./layouts/HumanTaskChatLayout";
import HumanTaskDocumentViewerLayout from "./layouts/HumanTaskDocumentViewerLayout";
import HumanTaskEntityLayout from "./layouts/HumanTaskEntityLayout";
import HumanTaskManualLayout from "./layouts/HumanTaskManualLayout";
import HumanTaskVisitAssignmentLayout from "./layouts/HumanTaskVisitAssignmentLayout";

interface Props {
  task: Messages["WorkflowHumanTaskInstance"];
  onSubmit: () => void;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}

export interface HumanTaskSubmitParams {
  outputKey: string;
  data: Record<string, unknown>;
}

export default function HumanTaskForm(props: Props) {
  const { api } = useApi();
  const queryClient = useQueryClient();
  const toast = useToast();

  const submit = useMutation({
    mutationFn: (body: HumanTaskSubmitParams) =>
      api.post("./workflow_tasks/:workflowTaskInstanceId/submit", {
        path: {
          workflowTaskInstanceId: props.task.id,
        },
        body: {
          outputKey: body.outputKey,
          data: body.data,
        },
      }),
    onSuccess: () => {
      toast({ title: "Task submitted!", position: "top-right" });
      queryClient.invalidateQueries({ queryKey: queryKeys.workflowTask.search.K });
      props.onSubmit();
    },
  });

  return (
    <AnimatePresence>
      <Box height="full">
        <motion.div
          key={props.task.id}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -900, opacity: 0 }}
          initial={{ x: 900, opacity: 0 }}
          style={{ height: "100%" }}
          transition={{
            bounce: false,
            ease: "easeInOut",
          }}
        >
          <Layout
            task={props.task}
            onCloseAngularModal={props.onCloseAngularModal}
            onOpenAngularModal={props.onOpenAngularModal}
            onSubmit={submit.mutate}
          />
        </motion.div>
      </Box>
    </AnimatePresence>
  );
}

function Layout(props: {
  task: Messages["WorkflowHumanTaskInstance"];
  onSubmit: (params: HumanTaskSubmitParams) => void;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}) {
  const { task } = props;

  switch (task.template.layout) {
    case "manual":
      return (
        <HumanTaskManualLayout
          instructions={task.template.data.instructions}
          output={task.output}
          workflowTaskInstanceId={props.task.id}
          onSubmit={props.onSubmit}
        />
      );
    case "entity":
      return (
        <HumanTaskEntityLayout
          caregiver={task.template.data.caregiver}
          files={task.template.data.files}
          instructions={task.template.data.instructions}
          intakePatient={task.template.data.intakePatient}
          output={task.output}
          patient={task.template.data.patient}
          taskInstanceId={task.id}
          ticket={task.template.data.ticket}
          visit={task.template.data.visit}
          visitBroadcast={task.template.data.visitBroadcast}
          onCloseAngularModal={props.onCloseAngularModal}
          onOpenAngularModal={props.onOpenAngularModal}
          onSubmit={props.onSubmit}
        />
      );
    case "document-viewer":
      return (
        <HumanTaskDocumentViewerLayout
          files={task.template.data.documents}
          id={task.id}
          instructions={task.template.data.instructions}
          output={task.output}
          onSubmit={props.onSubmit}
        />
      );
    case "chat":
      return (
        <HumanTaskChatLayout
          instructions={task.template.data.instructions}
          output={task.output}
          showTicketLink={task.template.data.showTicketLink}
          taskInstanceId={task.id}
          ticketId={task.template.data.ticket}
          onCloseAngularModal={props.onCloseAngularModal}
          onOpenAngularModal={props.onOpenAngularModal}
          onSubmit={props.onSubmit}
        />
      );
    case "intake-call":
      return (
        <HumanTaskLayoutWrapper>
          <HumanTaskCallIntakeLayout
            output={task.output}
            patientId={task.template.data.patient}
            workflowTaskInstanceId={task.id}
            onCloseAngularModal={props.onCloseAngularModal}
            onOpenAngularModal={props.onOpenAngularModal}
            onSubmit={props.onSubmit}
          />
        </HumanTaskLayoutWrapper>
      );
    case "visit-assignment":
      return (
        <HumanTaskVisitAssignmentLayout
          output={task.output}
          visitAssignmentId={task.template.data.visitAssignment}
          workflowTaskInstanceId={task.id}
          onSubmit={props.onSubmit}
        />
      );
  }
}
