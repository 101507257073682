import { z } from "zod";
import {
  CaregiverId,
  PatientId,
  AgencyPhonebookContactId,
  VisitInstanceId,
  VisitBroadcastId,
} from "../../../../../shared/schema/schema";

export const TicketEntityModalSubmitTypeSave = "Save";
export const TicketEntityModalSubmitTypeSaveAndResolve = "SaveAndResolve";
export type TicketEntitySelectModalSubmitType = "Save" | "SaveAndResolve";

export const ticketEntitySelectModalSchema = z
  .object({
    primaryEntityType: z
      .union([z.literal("Caregiver"), z.literal("Patient"), z.literal("PhonebookContact")])
      .nullable(),
    secondaryEntityType: z
      .union([z.literal("Caregiver"), z.literal("Patient"), z.literal("PhonebookContact")])
      .nullable(),
    visitType: z.union([z.literal("VisitInstance"), z.literal("VisitBroadcast")]).nullable(),
    caregiverId: z.number().transform(CaregiverId.parse).nullable(),
    patientId: z.number().transform(PatientId.parse).nullable(),
    phonebookContactId: z.number().transform(AgencyPhonebookContactId.parse).nullable(),
    visitInstanceId: z.number().transform(VisitInstanceId.parse).nullable(),
    visitBroadcastId: z.number().transform(VisitBroadcastId.parse).nullable(),
    submitType: z.union([
      z.literal(TicketEntityModalSubmitTypeSave),
      z.literal(TicketEntityModalSubmitTypeSaveAndResolve),
    ]),
  })
  .refine(
    (data) => {
      // Primary entity condition
      if (
        data.primaryEntityType === null ||
        (data.primaryEntityType === "Caregiver" && data.caregiverId === null) ||
        (data.primaryEntityType === "Patient" && data.patientId === null) ||
        (data.primaryEntityType === "PhonebookContact" && data.phonebookContactId === null)
      ) {
        return false;
      }
      return true;
    },
    { message: `Primary entity is required`, path: ["primaryEntityType"] }
  )
  .refine(
    (data) => {
      // Secondary entity condition
      if (
        (data.secondaryEntityType === "Caregiver" && data.caregiverId === null) ||
        (data.secondaryEntityType === "Patient" && data.patientId === null) ||
        (data.secondaryEntityType === "PhonebookContact" && data.phonebookContactId === null)
      ) {
        return false;
      }
      return true;
    },
    { message: `Secondary entity is required if selected`, path: ["secondaryEntityType"] }
  )
  .refine(
    (data) => {
      // Visit type condition
      if (
        (data.visitType === "VisitInstance" && data.visitInstanceId === null) ||
        (data.visitType === "VisitBroadcast" && data.visitBroadcastId === null)
      ) {
        return false;
      }

      return true;
    },
    { message: `Visit is required if selected`, path: ["visitType"] }
  );

export type TicketEntityModalSchema = z.infer<typeof ticketEntitySelectModalSchema>;
