//! @ngInject
export function patientCaregiverRelationshipModalCtrl(
  $scope,
  $rootScope,
  $uibModalInstance,
  DatabaseApi,
  dateUtils,
  toaster
) {
  $scope.original = $scope.$resolve.originalRelationship;
  $scope.relationshipTypes = $scope.$resolve.relationshipTypes.list;
  $scope.loading = false;
  $scope.calendarPopups = {};
  $scope.dateOptions = {
    startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek,
  };

  $scope.searchOptions = {
    ...$rootScope.dropdownSettings.singleSelect,
    ...$rootScope.dropdownSettings.searchable,
    displayProp: "name",
  };

  $scope.caregiversMap = DatabaseApi.caregivers() ?? {};
  $scope.patientsMap = DatabaseApi.patients() ?? {};

  $scope.selectedCaregiver = $scope.original?.caregiverId
    ? $scope.caregiversMap[$scope.original.caregiverId]
    : $scope.$resolve.selectedCaregiverId
    ? $scope.caregiversMap[$scope.$resolve.selectedCaregiverId.unwrap]
    : null;
  $scope.selectedPatient = $scope.original?.patientId
    ? $scope.patientsMap[$scope.original.patientId]
    : $scope.$resolve.selectedPatientId
    ? $scope.patientsMap[$scope.$resolve.selectedPatientId.unwrap]
    : null;

  $scope.mode = $scope.original
    ? {
        original: $scope.original,
        isEdit: true,
      }
    : {
        isEdit: false,
      };

  $scope.form = {
    fields: {
      startDate: $scope.original?.startDate
        ? dateUtils.ymdStringToDate($scope.original.startDate)
        : null,
      endDate: $scope.original?.endDate ? dateUtils.ymdStringToDate($scope.original.endDate) : null,
      patientId: $scope.selectedPatient?.id,
      caregiverId: $scope.selectedCaregiver?.id,
      relationship: {
        id: $scope.original?.relationshipTypeId ?? null,
      },
    },
    valid: false,
    loading: false,
  };

  $scope.title = $scope.mode.isEdit
    ? "Edit Patient-Caregiver Relationship"
    : "Add Patient-Caregiver Relationship";

  $scope.submit = () => {
    $scope.form.loading = true;

    const data = {
      startDate: dateUtils.dateToLocalDate($scope.form.fields.startDate),
      endDate: dateUtils.dateToLocalDate($scope.form.fields.endDate),
      patientId: $scope.form.fields.patientId,
      caregiverId: $scope.form.fields.caregiverId,
      relationshipTypeId: $scope.form.fields.relationship.id,
    };

    if ($scope.mode.isEdit) {
      for (const key in Object.keys(data)) {
        if (data[key] === $scope.mode.original[key]) {
          delete data[key];
        }
      }
    }

    const url = $scope.mode.isEdit
      ? `agencies/:agencyId/agency_members/:agencyMemberId/patient_caregiver_relationship/:patientCaregiverRelationshipId`
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientCaregiverRelationshipId", $scope.mode.original.id)
      : `agencies/:agencyId/agency_members/:agencyMemberId/patient_caregiver_relationship`
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId);

    const promise = $scope.mode.isEdit ? DatabaseApi.put(url, data) : DatabaseApi.post(url, data);

    return promise
      .then(() => $uibModalInstance.close())
      .catch(() =>
        toaster.pop(
          "error",
          "Something went wrong",
          `Failed to ${$scope.mode.isEdit ? "update" : "create"} patient-caregiver relationship`
        )
      )
      .finally(() => ($scope.form.loading = false));
  };

  $scope.$watchGroup(
    [
      "form.fields.startDate",
      "form.fields.endDate",
      "form.fields.patientId",
      "form.fields.caregiverId",
      "form.fields.relationship.id",
    ],
    () => {
      validateForm();
    }
  );

  function validateForm() {
    $scope.form.valid =
      $scope.form.fields.startDate &&
      $scope.form.fields.endDate &&
      $scope.form.fields.patientId &&
      $scope.form.fields.caregiverId &&
      $scope.form.fields.relationship?.id;
  }

  $scope.toggleCalendarPopups = function (prop) {
    $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
  };

  $scope.handleSelect = function (type, obj) {
    const id = obj?.id ?? null;
    if (type === "patient") {
      $scope.selectedPatient = id === null ? null : $scope.patientsMap[id];
      $scope.form.fields.patientId = id;
    } else if (type === "caregiver") {
      $scope.selectedCaregiver = id === null ? null : $scope.caregiversMap[id];
      $scope.form.fields.caregiverId = id;
    }
  };
}
