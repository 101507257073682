import { Flex, Text } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { capitalize } from "lodash";
import React from "react";
import { Messages } from "../../../core/api";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import PatientClinicalSeverityLevelTag from "../../patient/components/PatientClinicalSeverityLevelTag";
import { PatientClinicalSeverityLevel } from "../../patient/patient-utils";
import {
  CommCenterRelatedVisit,
  getRelatedVisitFromTicket,
  getTextFormatForRelatedVisit,
  isCommCenterMessageAuthorTheSame,
} from "../utils/communication-utils";
import ChatAttachmentsBar from "./ChatAttachmentsBar";
import ChatMessage from "./ChatMessage";
import ChatMessageInput from "./ChatMessageInput";
import ClosedChatMessage from "./ClosedChatMessage";

interface Props {
  activeTicket: Messages["CommCenterTicket"];
  attachments: File[];
  onSubmitNewMessage: (message: string) => void;
  onSelectFile: (newFile: File) => void;
  onClickRemoveAttachment: (attachment: File) => void;
}

// Weird shit but we have to live with it
function getRelatedVisitMessageTime(messages: Messages["CommCenterMessage"][]): Instant | null {
  return (
    messages.find(
      (message) =>
        message.createdBy.type === "Caregiver" &&
        message.payload[0].type === "TEXT" &&
        (message.payload[0].message.includes("Visit from ") ||
          message.payload[0].message.includes("Broadcast of "))
    )?.createdAt ?? null
  );
}

const ChatMessages = (props: Props) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const relatedVisit = getRelatedVisitFromTicket(props.activeTicket);

  const messages = [
    ...props.activeTicket.messages.map((payload, i, messages) => {
      return { type: "Message" as const, payload, previousMessage: messages[i - 1] };
    }),
    ...props.activeTicket.statusChanges.map((payload) => {
      return { type: "StatusChange" as const, payload };
    }),
    ...props.activeTicket.assigneeChanges.map((payload) => {
      return { type: "AssigneeChange" as const, payload };
    }),
    ...(relatedVisit === null
      ? []
      : [
          {
            type: "RelatedVisit",
            payload: {
              ...relatedVisit,
              createdAt:
                getRelatedVisitMessageTime(props.activeTicket.messages) ??
                props.activeTicket.createdAt,
            },
          } as const,
        ]),
  ].sort((a, b) => a.payload.createdAt.compareTo(b.payload.createdAt));

  React.useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, [props.activeTicket.messages]);

  return (
    <Flex
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      direction="column"
      height="100%"
      justifyContent="space-between"
      overflowY="auto"
    >
      <Flex ref={wrapperRef} direction="column" gap={2} height="100%" overflowY="auto" p={4}>
        {props.activeTicket.phoneNumber !== null && (
          <PhoneNumberHeaderMessage phoneNumber={props.activeTicket.phoneNumber} />
        )}
        {messages.map((message) => {
          switch (message.type) {
            case "RelatedVisit": {
              return (
                <ChatRelatedVisitEvent
                  key={`related-visit-${message.payload.id}`}
                  relatedVisit={message.payload}
                />
              );
            }
            case "Message": {
              const isTheSameAuthor = isCommCenterMessageAuthorTheSame({
                previous: message.previousMessage,
                next: message.payload,
              });

              return (
                <ChatMessage
                  key={`message-${message.payload.id}`}
                  displayEntityName={!isTheSameAuthor}
                  message={message.payload}
                />
              );
            }
            case "StatusChange": {
              return (
                <ChatStatusChangeEvent
                  key={`status-change-${message.payload.id}`}
                  event={message.payload}
                />
              );
            }
            case "AssigneeChange": {
              return (
                <ChatAssigneeChangeEvent
                  key={`assignee-change-${message.payload.id}`}
                  event={message.payload}
                />
              );
            }
          }
        })}
      </Flex>
      <ChatAttachmentsBar
        attachments={props.attachments}
        onClickRemoveAttachment={props.onClickRemoveAttachment}
      />
      {props.activeTicket.status !== "RESOLVED" ? (
        <ChatMessageInput onSelectFile={props.onSelectFile} onSubmit={props.onSubmitNewMessage} />
      ) : (
        <ClosedChatMessage isOpen={props.activeTicket.status === "RESOLVED"} />
      )}
    </Flex>
  );
};

const EventRoot = (props: { children: React.ReactNode }) => {
  return (
    <Flex
      align="center"
      bg="orange.100"
      borderRadius="lg"
      gap={1}
      justifyContent="center"
      mx="auto"
      my={2}
      p={3}
      w="fit-content"
    >
      {props.children}
    </Flex>
  );
};

function getCreatorName(creator: Messages["CommCenterTicketStatusChange"]["createdBy"]) {
  switch (creator.type) {
    case "System":
      return "System";
    case "Caregiver":
    case "TeamMember":
      return creator.name;
  }
}

export const ChatStatusChangeEvent = (props: {
  event: Messages["CommCenterTicketStatusChange"];
}) => {
  return (
    <EventRoot>
      <Text color="black.500">{dateFormatter.toDateOrDateTime(props.event.createdAt)}</Text>
      <Text fontWeight="bold">{getCreatorName(props.event.createdBy)}</Text>
      <Text>has changed the ticket status to</Text>
      <Text fontWeight="bold">{capitalize(props.event.status)}</Text>
    </EventRoot>
  );
};

export const ChatAssigneeChangeEvent = (props: {
  event: Messages["CommCenterTicketAssigneeChange"];
}) => {
  return (
    <EventRoot>
      <Text color="black.500">{dateFormatter.toDateOrDateTime(props.event.createdAt)}</Text>
      {props.event.assignedTo !== null ? (
        <>
          <Text fontWeight="bold">{props.event.assignedTo.name}</Text>
          <Text>was assigned to this ticket by</Text>
          <Text>{props.event.createdBy.name}</Text>
        </>
      ) : (
        <>
          <Text>{props.event.createdBy.name}</Text>
          <Text>has unassigned this case</Text>
        </>
      )}
    </EventRoot>
  );
};

const ChatRelatedVisitEvent = (props: { relatedVisit: CommCenterRelatedVisit }) => {
  const handleClick = () => {
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "navigate",
          payload: { id: props.relatedVisit.id, entity: props.relatedVisit.type },
        },
      })
    );
  };
  return (
    <EventRoot>
      <Text cursor="pointer" fontWeight="bold" onClick={handleClick}>
        {getTextFormatForRelatedVisit(props.relatedVisit)}
      </Text>
    </EventRoot>
  );
};

const PhoneNumberHeaderMessage = (props: { phoneNumber: string }) => {
  return (
    <EventRoot>
      <Text>You are talking to number </Text>
      <Text fontWeight="bold">{phoneFormatter.formatNational(props.phoneNumber)}</Text>
      <Text> via SMS</Text>
    </EventRoot>
  );
};

export const PatientClinicalSeverityLevelHeaderMessage = (props: {
  clinicalSeverityLevel: PatientClinicalSeverityLevel | null;
}) => {
  return (
    <Flex
      align="center"
      justifyContent="center"
      mx="auto"
      position="sticky"
      top={0}
      w="fit-content"
      zIndex={1}
    >
      <PatientClinicalSeverityLevelTag clinicalSeverityLevel={props.clinicalSeverityLevel} />
    </Flex>
  );
};

export default ChatMessages;
