import { createIcon } from "@chakra-ui/react";

const SaveIcon = createIcon({
  displayName: "SaveIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M15 9H5V5h10m-3 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3a3 3 0 0 1-3 3m5-16H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7l-4-4Z"
      fill="currentColor"
    />
  ),
});

export default SaveIcon;
