import { Box, Flex, Skeleton } from "@chakra-ui/react";

export function VisitAssignmentListedTicketsSkeleton() {
  return (
    <Box>
      <Box borderBottomWidth={1} borderColor="gray.200" p={4}>
        <Skeleton height="19.5px" rounded="full" w={32} />
      </Box>

      <VisitAssignmentListedTicketSkeleton />
      <VisitAssignmentListedTicketSkeleton />
      <VisitAssignmentListedTicketSkeleton />

      <Box borderBottomWidth={1} borderColor="gray.200" p={5}>
        <Skeleton height="19.5px" rounded="full" w={32} />
      </Box>

      <VisitAssignmentListedTicketSkeleton />
      <VisitAssignmentListedTicketSkeleton />
    </Box>
  );
}

function VisitAssignmentListedTicketSkeleton() {
  return (
    <Flex borderBottomWidth={1} borderColor="gray.200" direction="column" gap={2} p={4}>
      <Flex gap={3}>
        <Skeleton flexShrink={0} h={8} rounded="full" w={8} />
        <Skeleton h="37.88px" rounded="md" w="full" />
      </Flex>
      <Flex justify="space-between">
        <Skeleton h="19.5px" rounded="md" w="full" />
      </Flex>
    </Flex>
  );
}
