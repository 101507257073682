import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import { fmap } from "./common";

export function createDisclosureTriggerComponent<T>(params: {
  render: (props: T & { disclosure: ReturnType<typeof useDisclosure> }) => React.ReactElement;
  displayName?: string;
}) {
  const displayName =
    fmap(params.displayName, (x) => `DisclosureTrigger(${x})`) ?? "DisclosureTrigger";

  const Component = React.forwardRef<HTMLButtonElement, T & { children: React.ReactElement }>(
    (props, ref) => {
      const disclosure = useDisclosure();

      if (React.Children.count(props.children) !== 1) {
        throw new Error(`${displayName} must have exactly one child`);
      }

      return (
        <>
          {params.render({ ...props, disclosure })}
          {React.cloneElement(props.children, {
            ref: ref,
            onClick: (e: React.MouseEvent) => {
              disclosure.onOpen();
              props.children.props.onClick?.(e);
            },
          })}
        </>
      );
    }
  );

  Component.displayName = displayName;

  return Component;
}
