import { chakra } from "@chakra-ui/react";
import React from "react";
import { Shimmer } from "react-shimmer";
import useCacheSignedUrlQuery from "../hooks/useCacheSignedUrlQuery";

type Props = { width: number; height: number; src: string; fallback?: React.ReactNode };

const CachedSignedVideo = (props: Props) => {
  const { width, height, src, fallback } = props;
  const query = useCacheSignedUrlQuery(src);

  if (query.isPending) {
    return <>{fallback}</>;
  }

  if (query.data !== undefined) {
    return (
      <chakra.video controls h="200" height={height} src={query.data} w="250px" width={width} />
    );
  }

  return null;
};

export const CachedSignedVideoWithShimmer = (
  props: Omit<Props, "w" | "h"> & { width: number; height: number }
) => {
  return (
    <CachedSignedVideo
      {...props}
      fallback={<Shimmer height={props.height} width={props.width} />}
    />
  );
};

export default CachedSignedVideo;
