import { CheckIcon, ChevronDownIcon, CloseIcon, NotAllowedIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Flex,
  FlexProps,
  Input,
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Progress,
  Text,
  useDisclosure,
  useFormControlProps,
} from "@chakra-ui/react";
import React from "react";
import { TNullableMultiSelect } from "../hooks/useFilters";

const DEFUALT_SELECT_NONE_STRING = "Select None";

type SelectOptions<Data extends TNullableMultiSelect<unknown>> =
  | ReadonlyArray<{
      value: NonNullable<Data["values"]>[number];
      label: string;
      description?: string;
    }>
  | { value: NonNullable<Data["values"]>[number]; label: string; description?: string }[];

type BaseProps<TValue extends TNullableMultiSelect<unknown>> = {
  buttonProps?: ButtonProps;
  width?: PopoverContentProps["width"] | undefined;
  maxH?: PopoverContentProps["maxH"] | undefined;
  size?: ButtonProps["size"] | undefined;
  allowUnselect?: boolean;
  closeOnUnselect?: boolean;
  closeOnSelectAll?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isTruncated?: boolean;
  searchable?: boolean;
  "aria-invalid"?: boolean;
  popoverProps?: PopoverProps;
  controlledSearchTerm?: [string, React.Dispatch<React.SetStateAction<string>>];
  selectNoneString?: string;
  renderAfter?: (p: {
    searchTerm: string;
    filteredOptions: SelectOptions<TValue>;
  }) => React.ReactNode;
  value: TValue | undefined;
  onChange: (value: TValue | undefined) => void;
};

export type CustomSelectProps<Selection extends TNullableMultiSelect<unknown>> =
  BaseProps<Selection> & {
    label?: string;
  };

export type SelectProps<Selection extends TNullableMultiSelect<unknown>> = BaseProps<Selection> & {
  label: string;
  options: SelectOptions<Selection>;
};

export default function NullableMultiSelect<Selection extends TNullableMultiSelect<unknown>>(
  props: SelectProps<Selection>
) {
  const formControlProps = useFormControlProps(props);

  const searchTermState = React.useState("");

  const [searchTerm, setSearchTerm] = props.controlledSearchTerm ?? searchTermState;

  const disclosure = useDisclosure({
    onClose: () => setTimeout(() => setSearchTerm(""), 200),
  });

  const isSearchable = props.searchable ?? props.options.length > 5;

  const isChecked = (value: NonNullable<Selection["values"]>[number]) => {
    return (props.value?.values ?? []).includes(value);
  };

  const filteredOptions = props.options.filter((option) => {
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSelect = (value: NonNullable<Selection["values"]>[number]) => {
    const selected = props.value?.values ?? [];
    const newValue = selected.includes(value)
      ? selected.filter((v) => v !== value)
      : [...selected, value];

    return props.onChange(
      newValue.length > 0
        ? ({
            values: newValue,
          } as any)
        : undefined
    );
  };

  const handleSelectAll = () => {
    if (props.closeOnSelectAll) {
      disclosure.onClose();
    }
    return props.onChange({
      values: props.options.map((option) => option.value),
    } as any);
  };

  const handleUnselectAll = () => {
    if (props.closeOnUnselect) {
      disclosure.onClose();
    }
    return props.onChange(undefined);
  };

  const handleSelectNone = () => {
    props.onChange({
      values: null,
    } as any);
  };

  const getButtonLabel = () => {
    if (props.value !== undefined) {
      if (props.value.values === null) {
        return props.selectNoneString ?? DEFUALT_SELECT_NONE_STRING;
      }
      return `${props.label} (${props.value.values?.length ?? 0})`;
    }

    return String(props.label);
  };

  return (
    <Popover placement="bottom-start" {...disclosure} isLazy {...props.popoverProps}>
      <PopoverTrigger>
        <Button
          _hover={{
            bg: disclosure.isOpen ? "transparent" : "gray.50",
          }}
          _invalid={
            disclosure.isOpen
              ? undefined
              : {
                  borderColor: "red.500",
                  boxShadow: "0 0 0 1px var(--chakra-colors-red-500)",
                }
          }
          aria-invalid={props["aria-invalid"] ?? formControlProps.isInvalid}
          bg={props.value !== undefined ? "blue.50" : undefined}
          borderColor={disclosure.isOpen ? "blue.500" : undefined}
          boxShadow={disclosure.isOpen ? "0 0 0 1px var(--chakra-colors-blue-500)" : undefined}
          colorScheme={props.value !== undefined ? "blue" : undefined}
          isDisabled={props.isDisabled}
          rightIcon={<ChevronDownIcon />}
          size={props.size}
          textOverflow="ellipsis"
          type="button"
          variant="outline"
          whiteSpace="nowrap"
          {...props.buttonProps}
        >
          <Text isTruncated={props.isTruncated ?? true} textAlign="start" w="full">
            {getButtonLabel()}
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent fontSize={props.size} maxH={props.maxH} width={props.width}>
        <MenuGroup>
          <MenuItem onClick={handleSelectAll}>
            <Center h={5} w={4}>
              <CheckIcon h={3} />
            </Center>

            <Text>Check all</Text>
          </MenuItem>
          <MenuItem onClick={handleUnselectAll}>
            <Center h={5} w={4}>
              <CloseIcon h={2.5} />
            </Center>
            <Text>Uncheck all</Text>
          </MenuItem>
          <MenuItem onClick={handleSelectNone}>
            <Center h={5} w={4}>
              <NotAllowedIcon h={4} />
            </Center>
            <Text>{props.selectNoneString ?? DEFUALT_SELECT_NONE_STRING}</Text>
          </MenuItem>
        </MenuGroup>
        <Divider />

        {isSearchable && (
          <>
            <Input
              p={props.size === "sm" ? 3 : 4}
              placeholder="Search..."
              size={props.size}
              value={searchTerm}
              variant="unstyled"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Divider />
          </>
        )}

        {props.isLoading === true && <Progress isIndeterminate size="xs" />}
        {props.isLoading === false && <Box h="3.5px" />}

        <MenuGroup>
          {filteredOptions.map((option) => (
            <MenuItem
              key={JSON.stringify(option.value)}
              alignItems={option.description !== undefined ? "flex-start" : "center"}
              onClick={() => handleSelect(option.value)}
            >
              <Center h={5} w={4}>
                <CheckIcon h={3} opacity={isChecked(option.value) ? 1 : 0} />
              </Center>
              <Flex direction="column">
                <Text>{option.label}</Text>
                {option.description && <Text color="gray.500">{option.description}</Text>}
              </Flex>
            </MenuItem>
          ))}
          {props.renderAfter?.({ searchTerm, filteredOptions })}
        </MenuGroup>
      </PopoverContent>
    </Popover>
  );
}

function MenuGroup(props: FlexProps) {
  return <Flex direction="column" maxH="40vh" overflow="auto" py={2} {...props} />;
}

function MenuItem(props: FlexProps) {
  return (
    <Flex
      _hover={{ bg: "gray.100" }}
      alignItems="center"
      as="button"
      cursor="pointer"
      gap={2}
      px={4}
      py={2}
      textAlign="start"
      type="button"
      {...props}
    />
  );
}

NullableMultiSelect.MenuGroup = MenuGroup;
NullableMultiSelect.MenuItem = MenuItem;
