import React from "react";
import { Shimmer } from "react-shimmer";
import useCacheSignedUrlQuery from "../hooks/useCacheSignedUrlQuery";

type Props = { width: number; height: number; data: string; fallback?: React.ReactNode };

const CachedSignedFile = (props: Props) => {
  const { width, height, data, fallback } = props;
  const query = useCacheSignedUrlQuery(data);

  if (query.isPending) {
    return <>{fallback}</>;
  }

  if (query.data !== undefined) {
    return <object data={query.data} height={height} type="application/pdf" width={width} />;
  }

  return null;
};

export const CachedSignedFileWithShimmer = (
  props: Omit<Props, "w" | "h"> & { width: number; height: number }
) => {
  return (
    <CachedSignedFile {...props} fallback={<Shimmer height={props.height} width={props.width} />} />
  );
};

export default CachedSignedFile;
