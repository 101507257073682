import { Flex, Tag } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import { fmap } from "../../../shared/utils";
import { getMessagePreview } from "../utils/communication-utils";

interface Props {
  unreadCount: number;
  lastMessage: Messages["CommCenterMessage"] | null;
  chatSummaryDetails: Messages["CommCenterTicket"]["chatSummaryDetails"];
}

const ChatPreviewCell = (props: Props) => {
  const textToShow =
    props.chatSummaryDetails?.summary ?? fmap(props.lastMessage, getMessagePreview);
  return (
    <Flex gap={1}>
      <Tag bg="blue.500" color="white" hidden={props.unreadCount === 0} mr={2} rounded="full">
        {props.unreadCount}
      </Tag>
      {textToShow}
    </Flex>
  );
};

export default ChatPreviewCell;
