import { createIcon } from "@chakra-ui/react";

const DotsVerticalIcon = createIcon({
  displayName: "DotsVerticalIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="currentColor">
      <rect height="4" rx="2" width="4" x="10" y="3" />
      <rect height="4" rx="2" width="4" x="10" y="10" />
      <rect height="4" rx="2" width="4" x="10" y="17" />
    </g>
  ),
});

export default DotsVerticalIcon;
