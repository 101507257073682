import { CloseIcon } from "@chakra-ui/icons";
import { ButtonGroup, IconButton } from "@chakra-ui/react";
import MaximizeIcon from "../../../shared/icons/MaximizeIcon";
import MinimizeIcon from "../../../shared/icons/MinimizeIcon";
import VolumeOffIcon from "../../../shared/icons/VolumeOffIcon";
import VolumeUpIcon from "../../../shared/icons/VolumeUpIcon";

type Props = {
  isRingtoneMuted: boolean;
  isMinimized: boolean;
  onToggleMuteRingtone: () => void;
  onToggleMinimize: () => void;
  onClose: () => void;
};

export default function InboundCallMetaActions(props: Props) {
  return (
    <ButtonGroup>
      <IconButton
        aria-label="deafen"
        colorScheme={props.isRingtoneMuted ? "red" : undefined}
        fontSize={18}
        icon={props.isRingtoneMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        variant="ghost"
        onClick={props.onToggleMuteRingtone}
      />
      <IconButton
        aria-label="minimize"
        fontSize={18}
        icon={props.isMinimized ? <MaximizeIcon /> : <MinimizeIcon />}
        variant="ghost"
        onClick={props.onToggleMinimize}
      />
      <IconButton
        aria-label="close"
        icon={<CloseIcon fontSize={12} />}
        variant="ghost"
        onClick={props.onClose}
      />
    </ButtonGroup>
  );
}
