import { Editable, EditablePreview, EditableInput } from "@chakra-ui/react";
import { IntakeFlowGraphId, parseIntakeFlowGraphId } from "../flow-settings-visualizer.utils";
import { useMutation } from "@tanstack/react-query";
import useApi from "../../../../../shared/hooks/useApi";
import { IntakeTrackNoteId } from "../../../../../shared/schema/schema";

interface Props {
  nodeId: IntakeFlowGraphId;
  content: string;
  refreshGraphNodes: () => void;
}

export function EditableNoteDisplayInput(props: Props) {
  const { api } = useApi();
  const editNoteMut = useMutation({
    mutationFn: async (params: { noteId: IntakeTrackNoteId; content: string }) =>
      api.patch("./intake_flow/notes/:noteId", {
        body: {
          content: params.content,
        },
        path: {
          noteId: params.noteId,
        },
      }),
  });
  const handleEditNote = (content: string) => {
    if (content !== props.content) {
      const nodeId = parseIntakeFlowGraphId(props.nodeId);
      if (nodeId.type !== "note") {
        return;
      }
      editNoteMut.mutate(
        { noteId: nodeId.id, content },
        {
          onSuccess: () => {
            props.refreshGraphNodes();
          },
        }
      );
    }
  };
  return (
    <Editable defaultValue={props.content} w="full" onBlur={handleEditNote}>
      <EditablePreview />
      <EditableInput />
    </Editable>
  );
}
