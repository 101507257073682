import { map } from "lodash";
export * from "./common";

export const WEBAPP_FOOTER_HEIGHT = "40.5px";

export function validateOneOf<T>(value: any, possibilies: readonly T[]): T {
  if (!possibilies.includes(value)) {
    throw new Error(
      `validation of validateOneOf failed. Expected "${value}" to be one of "${possibilies.join(
        ", "
      )}"`
    );
  }

  return value as T;
}

export function assertObjectPropsNonNulls<A extends Record<string, unknown>>(
  vals: A
): { [T in keyof A]-?: NonNullable<A[T]> } | null {
  for (const key of Object.keys(vals)) {
    if (vals[key] === null || vals[key] === undefined) {
      return null;
    }
  }
  return vals as { [T in keyof A]-?: NonNullable<A[T]> };
}

export function range(num: number): number[] {
  if (num < 0) {
    throw new Error(`${num} should be bigger than or equal to 0`);
  }

  return Array(num)
    .fill(0)
    .map((_, i) => i);
}

export const sortWith = <T>(collection: T[], comparator: (a: T, b: T) => number): T[] => {
  return map(collection).sort(comparator);
};
