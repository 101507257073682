import { createIcon } from "@chakra-ui/react";

const PatientIcon = createIcon({
  displayName: "PatientIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12a6 6 0 0 1 0 12z"
      fill="currentColor"
    />
  ),
});

export default PatientIcon;
