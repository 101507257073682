import { Box, Flex, chakra } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../../../../shared/hooks/useApi";
import { VisitAssignmentId } from "../../../../../shared/schema/schema";
import { VisitAssignmentCaregiverCard } from "./VisitAssignmentCaregiverCard";
import { VisitAssignmentHeader } from "./VisitAssignmentHeader";
import { VisitAssignmentPatientCard } from "./VisitAssignmentPatientCard";
import { VisitAssignmentRelatedAssignmentsCard } from "./VisitAssignmentRelatedAssignmentsCard";
import { VisitAssignmentRelatedAutomationsCard } from "./VisitAssignmentRelatedAutomationsCard";
import { VisitAssignmentRelatedBroadcastsCard } from "./VisitAssignmentRelatedBroadcastsCard";
import { VisitAssignmentRelatedVisitsCard } from "./VisitAssignmentRelatedVisitsCard";
import { VisitAssignmentTickets } from "./VisitAssignmentTickets";

export function VisitAssignmentDrawerBody(props: { id: VisitAssignmentId }) {
  const { queries } = useApi();

  const query = useQuery(queries.visit.assignments.get(props.id));

  return (
    <Flex h="full">
      <VisitAssignmentSidebar>
        <VisitAssignmentPatientCard query={query} />
        <VisitAssignmentCaregiverCard query={query} />
        <VisitAssignmentSidebarScrollable>
          <VisitAssignmentRelatedAutomationsCard query={query} />
          <VisitAssignmentRelatedVisitsCard query={query} />
          <VisitAssignmentRelatedBroadcastsCard query={query} />
          <VisitAssignmentRelatedAssignmentsCard query={query} />
        </VisitAssignmentSidebarScrollable>
      </VisitAssignmentSidebar>

      <VisitAssignmentContent>
        <VisitAssignmentHeader query={query} />
        <VisitAssignmentTickets visitAssignmentId={props.id} />
      </VisitAssignmentContent>
    </Flex>
  );
}

const VisitAssignmentSidebarScrollable = chakra(Box, {
  baseStyle: {
    overflow: "auto",
  },
});

const VisitAssignmentSidebar = chakra(Flex, {
  baseStyle: {
    backgroundColor: "gray.50",
    borderColor: "gray.200",
    borderRightWidth: 1,
    flexDirection: "column",
    flexShrink: 0,
    width: 96,
    overflow: "auto",
  },
});

const VisitAssignmentContent = chakra(Flex, {
  baseStyle: {
    flexDirection: "column",
    flex: 1,
    minW: 0,
  },
});
