import { Flex } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import produce from "immer";
import React from "react";
import useApi from "../../../../../shared/hooks/useApi";
import useMultipleSocketEvent from "../../../../../shared/hooks/useMultipleSocketEvent";
import { queryKeys } from "../../../../../shared/query-keys";
import { CommCenterTicketId, VisitAssignmentId } from "../../../../../shared/schema/schema";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { AssignmentTicketsContent } from "./VisitAssignmentTicketsContent";
import { VisitAssignmentTicketsSidebar } from "./VisitAssignmentTicketsSidebar";

export function VisitAssignmentTickets(props: { visitAssignmentId: VisitAssignmentId }) {
  const queryClient = useQueryClient();
  const { queries } = useApi();
  const ticketsQueryOption = queries.visit.assignments.tickets(props.visitAssignmentId);
  const query = useQuery(ticketsQueryOption);
  const [selectedTicketId, setSelectedTicketId] = React.useState<CommCenterTicketId | null>(null);

  const selectTicket = (ticketId: CommCenterTicketId) => {
    const ticketData = query.data?.find((x) => x.id === ticketId);

    queryClient.setQueryData(ticketsQueryOption.queryKey, (prev) => {
      if (prev === undefined) return undefined;
      return markTicketAsReadInCollection(prev, ticketId);
    });

    if (ticketData !== undefined) {
      queryClient.setQueryData(queryKeys.commCenter.get(ticketId), { ticket: ticketData });
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.get(ticketId) });
    }

    setSelectedTicketId(ticketId);
  };

  useMultipleSocketEvent({
    keys: ["CommCenterTicketUpdated", "CommCenterTicketViewed"],
    onEvent: ({ ticketId }) => {
      if (query.data?.some((ticket) => ticket.id === ticketId)) {
        queryClient.invalidateQueries(ticketsQueryOption);
      }
    },
  });

  return (
    <Flex direction={{ base: "column", xl: "row" }} flex={1} overflow="auto">
      <VisitAssignmentTicketsSidebar
        query={query}
        selectedTicketId={selectedTicketId}
        onClickTicket={selectTicket}
      />
      <AssignmentTicketsContent
        assignmentId={props.visitAssignmentId}
        ticketId={selectedTicketId}
        onCloseTicket={() => setSelectedTicketId(null)}
      />
    </Flex>
  );
}

function markTicketAsReadInCollection(
  collection: CommCenterTicket[],
  ticketId: CommCenterTicketId
) {
  return produce(collection, (draft) => {
    const ticket = draft.find((x) => x.id === ticketId);

    if (ticket === undefined) return;

    const unreadMessages = ticket.messages.filter(
      (message) =>
        message.readAt === null &&
        message.createdBy.type !== "System" &&
        message.createdBy.type !== "Agency Member"
    );

    unreadMessages.forEach((message) => {
      message.readAt = Instant.now();
    });
  });
}
