import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../core/api";
import ErrorBox from "../../../shared/components/ErrorBox";
import useApi from "../../../shared/hooks/useApi";
import useControlledForm, { ControlledForm } from "../../../shared/hooks/useControlledForm";
import { PatientId, VisitInstanceId } from "../../../shared/schema/schema";
import assert from "../../../shared/utils/assert";
import { mapToAgencyNoteCreateParams, zEntityNoteForm } from "../utils/note-form";
import EntityNoteFormControls from "./EntityNoteFormControls";

type Props = {
  entity: { type: "Patient"; id: PatientId };
  visitInstanceId?: VisitInstanceId;
  isApprovalNoteRequired: boolean;
  disclosure: ReturnType<typeof useDisclosure>;
  onSubmit: (forms: { approvalNote?: Messages["AgencyNoteCreateParams"] }) => void;
};

export default function EditVisitEntityNoteFormModal(props: Props) {
  const { queries } = useApi();
  const query = useQuery({
    ...queries.note.settings(),
    select: ({ agencyEntityNotes }) => {
      const adjustmentApproval = agencyEntityNotes.find(
        (x) => x.noteType === "adjustment_approval"
      );

      const visitInstance = agencyEntityNotes.find((x) => x.noteType === "visit_instance_edit");

      assert(adjustmentApproval, "Missing adjustment approval note settings");
      assert(visitInstance, "Missing visit instance note settings");

      return { settings: { adjustmentApproval, visitInstance } };
    },
  });

  return (
    <Modal isCentered={true} size="2xl" {...props.disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
          <Text>Add note</Text>
        </ModalHeader>
        {(() => {
          switch (query.status) {
            case "pending":
              return (
                <>
                  <ModalBody>
                    {props.isApprovalNoteRequired && <EntityNoteFormBoxSkeleton />}
                    <EntityNoteFormBoxSkeleton />
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Skeleton h={9} rounded="md" w={20} />
                      <Skeleton h={9} rounded="md" w={16} />
                    </ButtonGroup>
                  </ModalFooter>
                </>
              );
            case "error":
              return (
                <ModalBody>
                  <ErrorBox error={query.error} resetErrorBoundary={query.refetch} />
                </ModalBody>
              );
            case "success":
              return (
                <EditVisitEntityNoteForm
                  entity={props.entity}
                  isApprovalNoteRequired={props.isApprovalNoteRequired}
                  settings={{
                    adjustmentApproval: query.data.settings.adjustmentApproval,
                    visitInstance: query.data.settings.visitInstance,
                  }}
                  visitInstanceId={props.visitInstanceId}
                  onClose={props.disclosure.onClose}
                  onSubmit={props.onSubmit}
                />
              );
          }
        })()}
      </ModalContent>
    </Modal>
  );
}

function EditVisitEntityNoteForm(props: {
  entity: { type: "Patient"; id: PatientId };
  visitInstanceId: VisitInstanceId | undefined;
  isApprovalNoteRequired: boolean;
  settings: {
    adjustmentApproval: Messages["EntityNoteSetting"];
    visitInstance: Messages["EntityNoteSetting"];
  };
  onSubmit: (forms: { approvalNote?: Messages["AgencyNoteCreateParams"] }) => void;
  onClose: () => void;
}) {
  const approvalNoteForm = useControlledForm({
    schema: zEntityNoteForm(props.settings.adjustmentApproval),
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const approvalNote = (() => {
      if (!props.isApprovalNoteRequired) {
        return;
      }

      const parsed = approvalNoteForm.onSubmit(e);

      if (!parsed.success) {
        return;
      }

      return mapToAgencyNoteCreateParams({
        ...parsed.data,
        visitInstanceId: props.visitInstanceId,
        patientId: props.entity.id,
        noteType: "adjustment_approval",
      });
    })();

    if (props.isApprovalNoteRequired && !approvalNote) {
      return;
    }

    props.onSubmit({ approvalNote });
  };

  return (
    <chakra.form onSubmit={onSubmit}>
      <ModalBody>
        <Flex direction="column" gap={2}>
          {props.isApprovalNoteRequired && (
            <EntityNoteFormBox
              form={approvalNoteForm}
              noteType="adjustment_approval"
              primary="Adjustment Approval"
              secondary="Some of the selected visit instances are billed, paid or on payroll draft. Please post a note for the change."
              settings={props.settings.adjustmentApproval}
            />
          )}
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button colorScheme="blue" type="submit">
            Post
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </chakra.form>
  );
}

const EntityNoteFormBoxRoot = chakra(Box, {
  baseStyle: {
    borderColor: "gray.200",
    borderWidth: "1px",
    mb: 6,
    rounded: "md",
  },
});

const EntityNoteFormBoxHeader = chakra(Flex, {
  baseStyle: {
    bg: "gray.50",
    borderBottomWidth: "1px",
    borderColor: "gray.200",
    flexDirection: "column",
    gap: 2,
    mb: 2,
    p: 4,
    roundedTop: "md",
  },
});

function EntityNoteFormBoxSkeleton() {
  return (
    <EntityNoteFormBoxRoot>
      <EntityNoteFormBoxHeader>
        <Skeleton h={6} rounded="full" w="50%" />
        <Skeleton h={4} rounded="full" w="100%" />
        <Skeleton h={4} rounded="full" w="20%" />
      </EntityNoteFormBoxHeader>
      <Box p={4}>
        <EntityNoteFormControls.Skeleton />
      </Box>
    </EntityNoteFormBoxRoot>
  );
}

function EntityNoteFormBox(props: {
  primary: string;
  secondary: string;
  noteType: Messages["NoteTypes"];
  settings: Messages["EntityNoteSetting"];
  form: ControlledForm<ReturnType<typeof zEntityNoteForm>>;
}) {
  return (
    <EntityNoteFormBoxRoot>
      <EntityNoteFormBoxHeader>
        <Text fontSize="md" fontWeight="semibold">
          {props.primary}
        </Text>
        <Text color="gray">{props.secondary}</Text>
      </EntityNoteFormBoxHeader>

      <Box p={4}>
        <EntityNoteFormControls
          form={props.form}
          isReasonDisabled={false}
          noteTypes={[props.noteType]}
          settings={props.settings}
        />
      </Box>
    </EntityNoteFormBoxRoot>
  );
}
