import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../../../shared/utils/duration-formatter";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { VisitAssignmentListedTicketAvatar } from "./VisitAssignmentListedTicketAvatar";
import { VisitAssignmentListedTicketLabel } from "./VisitAssignmentListedTicketLabel";
import { VisitAssignmentListedTicketMessagePreview } from "./VisitAssignmentListedTicketMessagePreview";
import { VisitAssignmentListedTicketTopic } from "./VisitAssignmentListedTicketTopic";
import { getTicketUnreadCount } from "../../../../communication/utils/communication-utils";

export function VisitAssignmentListedTicket(props: {
  isSelected: boolean;
  ticket: CommCenterTicket;
  onClick: () => void;
}) {
  const unreadCount = React.useMemo(() => getTicketUnreadCount(props.ticket), [props.ticket]);

  return (
    <Flex
      data-group
      _hover={{ bg: "gray.50" }}
      borderBottomWidth={1}
      borderColor="gray.200"
      cursor="pointer"
      data-selected={props.isSelected ? true : undefined}
      data-unread={unreadCount > 0 ? true : undefined}
      direction="column"
      gap={2}
      p={4}
      sx={{
        "&[data-selected]": {
          bg: "gray.50",
          boxShadow: "inset 2px 0px 0px var(--chakra-colors-blue-500)",
        },
        "&[data-unread]": {
          bg: "blue.50",
        },
      }}
      width="full"
      onClick={props.onClick}
    >
      <Flex w="full">
        <Flex gap={3} width="full">
          <VisitAssignmentListedTicketAvatar ticket={props.ticket} unreadCount={unreadCount} />
          <Box minW={0} width="full">
            <VisitAssignmentListedTicketTopic ticket={props.ticket} />
            <VisitAssignmentListedTicketMessagePreview
              ticket={props.ticket}
              totalUnread={unreadCount}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex gap={4} justify="space-between" w="full">
        <VisitAssignmentListedTicketLabel ticket={props.ticket} />
        <Tooltip label={dateFormatter.toDateTime(props.ticket.createdAt)}>
          <Text color="gray.500" fontSize="sm" whiteSpace="nowrap">
            {durationFormatter.relativeFrom(props.ticket.lastActivity).formatted}
          </Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
}
