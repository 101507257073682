import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";

export default function PatientProfileRemovePortalPhoneAlertDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleSubmit = () => {
    props.onSubmit();
    props.onClose();
  };

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      size="xl"
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remove Portal Phone
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? This phone number is used for patient portal login.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme="red" mr={2} onClick={handleSubmit}>
              I understand the consequences, remove anyway
            </Button>
            <Button ref={cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
