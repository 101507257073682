import {
  Button,
  Center,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import SaveIcon from "../../icons/SaveIcon";
import { formatErrorResponse } from "../../utils/format-response-error";
import AddressPickerFields from "./AddressPickerFields";
import AddressPickerMap from "./AddressPickerMap";

const zCustomAddress = z.object({
  state: z.string(),
  city: z.string(),
  county: z.string(),
  zip: z.string(),
  street: z.string(),
  streetNumber: z.string(),
  lat: z.number(),
  lng: z.number(),
  timezone: z.string().optional(),
});
export type CustomAddress = z.infer<typeof zCustomAddress>;

interface Props {
  addressQuery: UseQueryResult<CustomAddress, unknown>;
  updateAddressMutation: UseMutationResult<never, unknown, CustomAddress, unknown>;
  disclosure: ReturnType<typeof useDisclosure>;
}

function AddressPickerModal(props: Props) {
  return (
    <Modal size="3xl" {...props.disclosure}>
      <ModalOverlay />
      {(() => {
        switch (props.addressQuery.status) {
          case "pending":
            return <LoadingContent />;
          case "error":
            return <ErrorContent error={props.addressQuery.error} />;
          case "success":
            return (
              <SuccessContent
                addressQueryData={props.addressQuery.data}
                updateAddressMutation={props.updateAddressMutation}
              />
            );
        }
      })()}
    </Modal>
  );
}

interface ModalBodySuccessProps {
  addressQueryData: CustomAddress;
  updateAddressMutation: UseMutationResult<never, unknown, CustomAddress, unknown>;
}

const SuccessContent = (props: ModalBodySuccessProps) => {
  const formMethods = useForm<CustomAddress>({
    resolver: zodResolver(zCustomAddress),
    defaultValues: props.addressQueryData,
  });
  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<CustomAddress> = (data: CustomAddress) => {
    props.updateAddressMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseModalContent
        isDisabled={props.updateAddressMutation.isError}
        isLoading={props.updateAddressMutation.isPending}
      >
        <FormProvider {...formMethods}>
          <AddressPickerFields />
          <FormLabel mb={3} mt={6}>
            Please also pinpoint the exact location on the map
          </FormLabel>
          <AddressPickerMap />
        </FormProvider>
      </BaseModalContent>
    </form>
  );
};

const LoadingContent = () => {
  return (
    <BaseModalContent isDisabled={false} isLoading={true}>
      <Center>
        <Spinner margin={200} size="xl" />
      </Center>
    </BaseModalContent>
  );
};

const ErrorContent = (props: { error: unknown }) => {
  return (
    <BaseModalContent isDisabled={true} isLoading={false}>
      <Center>
        <Text>{`Error while loading the entity's address: ${formatErrorResponse(
          props.error
        )}`}</Text>
      </Center>
    </BaseModalContent>
  );
};

const BaseModalContent = (props: {
  children: React.ReactNode;
  isLoading: boolean;
  isDisabled: boolean;
}) => {
  return (
    <ModalContent margin="auto">
      <ModalHeader>Enter a custom address</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>
        <Button
          colorScheme="blue"
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          leftIcon={<SaveIcon />}
          type="submit"
        >
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default AddressPickerModal;
