import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";

export default createQueryGroup(({ api }) => ({
  teams: () =>
    queryOptions({
      queryKey: ["comm_center", "teams", "list"],
      queryFn: async () => {
        const { teams } = await api.get("./comm_center/teams", {});
        return teams;
      },
    }),
}));
