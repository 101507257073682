import EntityCard from "../../../../../../shared/components/EntityCard";
import { useEntityLink } from "../../../../../../shared/hooks/useEntityLink";
import { VisitBroadcastId } from "../../../../../../shared/schema/schema";
import { CardButton } from "./CardButton";

export function VisitBroadcastLinkCard(props: {
  visitBroadcastId: VisitBroadcastId;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}) {
  const { open } = useEntityLink({
    onOpen: props.onOpenAngularModal,
    onClose: props.onCloseAngularModal,
    redirectModal: false,
  });

  const entity = {
    type: "VisitBroadcast" as const,
    id: props.visitBroadcastId,
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => open({ entity, event });

  return (
    <CardButton onClick={handleClick}>
      <EntityCard entity={entity} />
    </CardButton>
  );
}
