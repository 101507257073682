import { LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment";

//! @ngInject
export function agencyCompleteFormModalCtrl($scope, $rootScope, $uibModalInstance, modalData, subsection, DatabaseApi, $window, toaster) {

    $scope.subsection = subsection;
    $scope.questionsToComplete = {};

    async function initialize() {
        if (!$scope.subsection.document || $scope.subsection.document.length === 0) {
            $scope.noQuestionsToComplete = true;
        } else {
            switch ($scope.subsection.document[0].documentType) {
                case 75: {
                    $scope.questionsToComplete["CPAEffectiveDate"] = {};
                    break;
                }
                case 84: {
                    $scope.noQuestionsToComplete = true;
                    break;
                }
                case 86: {
                    $scope.questionsToComplete["F88502EmploymentStartDate"] = {};
                    $scope.questionsToComplete["F88502StartingWage"] = {};

                    const getCaregiverRatesUrl = `hr/agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${modalData.caregiverId}/caregiver_rates`;
                    const caregiverRates = await DatabaseApi.get(getCaregiverRatesUrl);
                    if (caregiverRates && caregiverRates.data && caregiverRates.data.rates && caregiverRates.data.rates.dollarsPerHourForHourly) {
                        $scope.hourlyRate = caregiverRates.data.rates.dollarsPerHourForHourly;
                    }
                    break;
                }
                default: {
                    $scope.noQuestionsToComplete = true;
                    break;
                }
            }
        }
     
        $scope.subsection.questionGroups.forEach(questionGroup => {
            questionGroup.questions.forEach(question => {
                if ($scope.questionsToComplete[question.textId] !== undefined) {
                    const qst = angular.copy(question);
                    if (qst.answer) {
                        if (qst.type === 'BirthDate' || qst.type === 'FullDate') {
                            qst.answer = new Date(moment(qst.answer).format('MM/DD/YYYY'));
                        }
                    }
                    if (qst.textId === 'F88502StartingWage') {
                        if (qst.answer) {
                            qst.answer = parseFloat(qst.answer);
                        } else if ($scope.hourlyRate) {
                            qst.answer = $scope.hourlyRate;
                        }
                    }
                    $scope.questionsToComplete[question.textId] = qst;
                }
            });
        });
    }
    
    $scope.updateQuestion = (question) => {
        const questionUrl = `hr/agencies/${$rootScope.agencyId}/caregivers/${modalData.caregiverId}/caregiver_application_answer_webapp`;

        const body = angular.copy(question);
        if (question.type === 'BirthDate' || question.type === 'FullDate') {
            body.answer = LocalDate.from(nativeJs(moment(body.answer))).toString();
        } else {
            if (typeof(body.answer) === 'number') {
                body.answer = body.answer.toString();
            }
        }

        DatabaseApi.put(questionUrl, body).then((res) => {
            if (res.data.isSuccessful) {
                toaster.pop('success', 'Question was updated successfully');
            }
        }, (err) => {
            toaster.pop('warning', 'something went wrong', '');
        });
    }

    $scope.submitCompletedDoc = () => {
        $scope.sendingCompletedForm = true;
        const postUrl = `hr/agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${modalData.caregiverId}/agency_confirm_subsection`;

        const questions = [];
        Object.keys($scope.questionsToComplete).forEach((questionId) => {
            const question = $scope.questionsToComplete[questionId];
            if (question.type === 'BirthDate' && question.answer && question.answer.toString().length > 0) {
                const questionBody = angular.copy(question);
                questionBody.answer = LocalDate.from(nativeJs(moment(questionBody.answer))).toString();
                questions.push(questionBody);
            }
            if (question.type === 'FullDate' && question.answer && question.answer.toString().length > 0) {
                const questionBody = angular.copy(question);
                questionBody.answer = LocalDate.from(nativeJs(moment(questionBody.answer))).toString();
                questions.push(questionBody);
            }
            if (question.type === 'Dollar' && typeof(question.answer) === 'number' && question.answer >= 0) {
                const questionBody = angular.copy(question);
                questionBody.answer = questionBody.answer.toString();
                questions.push(questionBody);
            }
        });

        if (questions.length !== Object.keys($scope.questionsToComplete).length) {
            toaster.pop('warning', 'Please answer all questions and submit again');
            $scope.sendingCompletedForm = false;
        } else {
            const data = {
                questions: questions,
                subsectionId: $scope.subsection.data.id
            };
            DatabaseApi.post(postUrl, data).then((res) => {
                $window.open(res.data.url);
                $scope.sendingCompletedForm = false;
                $scope.closeModal('Completed');
            }, (err) => {
                toaster.pop('warning', 'something went wrong', '');
                $scope.sendingCompletedForm = false;
            });
        }
        
    };

    $scope.closeModal = (res) => {
        if (res === undefined) {
            res = 'cancel';
        }
        $uibModalInstance.close(res);
    };

    initialize();
};
