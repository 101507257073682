import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Textarea,
} from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import Select from "../../../../../shared/components/Select";
import {
  IntakeTrackStepFieldId,
  IntakeTrackStepFieldValueId,
  IntakeTrackStepId,
} from "../../../../../shared/schema/schema";
import usePatientIntakeStepForm from "../hooks/usePatientIntakeStepForm";
import StepFieldItem from "./StepFieldItem";
import { IntakeStepForm } from "../flow-settings.types";

export default function IntakeFlowStepForm(props: {
  tracks: Messages["IntakeTrackForFullFlow"][];
  statuses: Messages["IntakeStatusForFullFlow"][];
  steps: Messages["IntakeTrackStepForFullFlow"][];
  sortedSteps: Messages["IntakeTrackStepForFullFlow"][];
  externalFlows: Messages["IntakeExternalFlow"][];
  value: ReturnType<typeof usePatientIntakeStepForm>["state"];
  allFieldsIds: Set<IntakeTrackStepFieldId>;
  allStepsIds: Set<IntakeTrackStepId>;
  allStepFieldValuesIds: Set<IntakeTrackStepFieldValueId>;
  allStepActions: NonNullable<Messages["IntakeTrackStepForFullFlow"]["action"]>[];
  allStepCodes: NonNullable<Messages["IntakeTrackStepForFullFlow"]["code"]>[];
  onClickCopyStep: (step: IntakeStepForm) => void;
  onChangeStepField: ReturnType<typeof usePatientIntakeStepForm>["changeStepField"];
  onChangeStepProperty: ReturnType<typeof usePatientIntakeStepForm>["changeStepProperty"];
  onChangeStepFieldOption: ReturnType<typeof usePatientIntakeStepForm>["changeStepFieldOption"];
  onChangeStepFieldOptionProperty: ReturnType<
    typeof usePatientIntakeStepForm
  >["changeStepFieldOptionProperty"];
  onChangeStepFieldProperty: ReturnType<typeof usePatientIntakeStepForm>["changeStepFieldProperty"];
  onChangeStepFieldOfOptionsProperty: ReturnType<
    typeof usePatientIntakeStepForm
  >["changeStepFieldOfOptionsProperty"];
  onClickNewField: ReturnType<typeof usePatientIntakeStepForm>["createStepField"];
  onClickRemoveField: ReturnType<typeof usePatientIntakeStepForm>["removeStepField"];
  onClickNewFieldOption: ReturnType<typeof usePatientIntakeStepForm>["createStepFieldOption"];
  onClickRemoveFieldOption: ReturnType<typeof usePatientIntakeStepForm>["removeStepFieldOption"];
  onSwitchMainStepOnTrackAndStatus: (checked: boolean) => void;
  onSubmit: React.FormEventHandler<HTMLFormElement | HTMLDivElement>;
}) {
  const isStepExists = props.value.id !== null && props.allStepsIds.has(props.value.id);
  return (
    <Flex as="form" direction="column" flex={1} gap={4} h="full" onSubmit={props.onSubmit}>
      <Button
        colorScheme="blue"
        hidden={!isStepExists}
        onClick={() => props.onClickCopyStep(props.value)}
      >
        COPY TO OTHER TRACK
      </Button>
      <Badge colorScheme={isStepExists ? "red" : "green"} p={4}>
        {isStepExists ? "edit" : "new"}
      </Badge>
      <FormControl>
        <FormLabel>ID</FormLabel>
        <Input
          value={props.value.id}
          onChange={(e) =>
            props.onChangeStepProperty("id", IntakeTrackStepId.parse(parseInt(e.target.value)))
          }
        />
      </FormControl>

      <FormControl>
        <FormLabel>Track & Next step</FormLabel>
        <ButtonGroup>
          <Select
            label="Select track"
            multiple={false}
            options={props.tracks.map((track) => ({ value: track.id, label: track.label }))}
            value={props.value.trackId}
            onChange={(value) => props.onChangeStepProperty("trackId", value ?? null)}
          />
          <Select
            allowUnselect
            searchable
            label="Select next step"
            multiple={false}
            options={props.steps.map((step) => ({
              value: step.id,
              label: `${step.title} (${step.track.label}) (${step.id})`,
            }))}
            value={props.value.nextStepId}
            onChange={(value) => props.onChangeStepProperty("nextStepId", value ?? null)}
          />
          <Select
            allowUnselect
            searchable
            label="Select external flow"
            multiple={false}
            options={props.externalFlows
              .filter((x) => x.removedAt === null)
              .map((flow) => ({
                label: flow.title,
                value: flow.id,
              }))}
            value={props.value.externalFlowId}
            onChange={(value) => props.onChangeStepProperty("externalFlowId", value ?? null)}
          />
        </ButtonGroup>
      </FormControl>

      <FormControl>
        <ButtonGroup>
          <FormLabel>Is main on track and status</FormLabel>
          <Switch
            isChecked={props.value.isMainOnTrackAndStatus}
            size="lg"
            onChange={(e) => {
              props.onSwitchMainStepOnTrackAndStatus(e.target.checked);
            }}
          />
          <FormLabel>Main track flow type</FormLabel>
          <Select
            allowUnselect={false}
            isDisabled={!props.value.isMainOnTrackAndStatus}
            label="Select track"
            multiple={false}
            options={
              [
                {
                  label: "Regular",
                  value: "Regular",
                },
                {
                  label: "Self Serve",
                  value: "Self Serve",
                },
              ] as const
            }
            value={props.value.flowType}
            onChange={(value) => props.onChangeStepProperty("flowType", value ?? "Regular")}
          />
        </ButtonGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Status</FormLabel>
        <Select
          label="Select status"
          multiple={false}
          options={props.statuses.map((status) => ({ value: status.id, label: status.label }))}
          value={props.value.statusId}
          onChange={(value) => props.onChangeStepProperty("statusId", value ?? null)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Is stop</FormLabel>
        <Switch
          isChecked={props.value.isStop}
          size="lg"
          onChange={(e) => props.onChangeStepProperty("isStop", e.target.checked)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Show title</FormLabel>
        <Switch
          isChecked={props.value.showTitle}
          size="lg"
          onChange={(e) => props.onChangeStepProperty("showTitle", e.target.checked)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Show assistant button</FormLabel>
        <Switch
          isChecked={props.value.showAssistantButton}
          size="lg"
          onChange={(e) => props.onChangeStepProperty("showAssistantButton", e.target.checked)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Action</FormLabel>
        <Select
          label="Select Action"
          multiple={false}
          options={props.allStepActions.map((action) => ({ value: action, label: action }))}
          value={props.value.action}
          width="auto"
          onChange={(value) => props.onChangeStepProperty("action", value ?? null)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Code</FormLabel>
        <Select
          label="Select Code"
          multiple={false}
          options={props.allStepCodes.map((code) => ({ value: code, label: code }))}
          value={props.value.code}
          width="auto"
          onChange={(value) => props.onChangeStepProperty("code", value ?? null)}
        />
      </FormControl>

      <Divider my={4} />

      <FormControl>
        <FormLabel>Title</FormLabel>
        <Input
          value={props.value.title}
          onChange={(e) => props.onChangeStepProperty("title", e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Script</FormLabel>
        <Textarea
          rows={7}
          value={props.value.script}
          onChange={(e) => props.onChangeStepProperty("script", e.target.value)}
        />
      </FormControl>

      <Divider my={4} />

      <Box p={4}>
        {props.value.fields.map((field, fieldIdx) => (
          <StepFieldItem
            key={field.__key}
            allFieldsIds={props.allFieldsIds}
            allStepFieldValuesIds={props.allStepFieldValuesIds}
            steps={props.sortedSteps}
            value={field}
            onChangeOptionProperty={(optionIdx, field, value) => {
              return props.onChangeStepFieldOptionProperty(fieldIdx, optionIdx, field, value);
            }}
            onChangeOptions={(options) =>
              props.onChangeStepFieldOfOptionsProperty(fieldIdx, "options", options)
            }
            onChangeProperty={(field, value) =>
              props.onChangeStepFieldProperty(fieldIdx, field, value)
            }
            onClickNewOption={() => props.onClickNewFieldOption(fieldIdx)}
            onClickRemove={() => props.onClickRemoveField(fieldIdx)}
            onClickRemoveOption={(optionIdx) => props.onClickRemoveFieldOption(fieldIdx, optionIdx)}
          />
        ))}
      </Box>

      <Button borderStyle="dashed" size="lg" variant="outline" onClick={props.onClickNewField}>
        Add field
      </Button>

      <Divider my={4} />

      <ButtonGroup alignSelf="flex-end">
        <Button variant="outline">Cancel</Button>
        <Button colorScheme="blue" type="submit">
          Save
        </Button>
      </ButtonGroup>
    </Flex>
  );
}
