import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { BodyOf, Messages } from "../../../core/api";
import PhoneNumberInput from "../../../shared/components/PhoneNumberInput";
import useApi from "../../../shared/hooks/useApi";
import useControlledForm from "../../../shared/hooks/useControlledForm";
import { CaregiverId, PatientId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { toBase64 } from "../../../shared/utils/to-base64";
import AttachedNotes from "./AttachedNotes";

const zFaxForm = z.object({
  phoneNumber: z.string().nonempty(),
  name: z.string().nonempty(),
  body: z.string().nonempty(),
});

type Props = {
  disclosure: ReturnType<typeof useDisclosure>;
  entity:
    | { type: "Patient" | "PatientCalendar"; id: PatientId; name: string }
    | { type: "Caregiver" | "CaregiverCalendar"; id: CaregiverId; name: string };
  notes: Messages["EntityNote"][];
};

export default function FaxNoteModal({ disclosure, entity, notes }: Props) {
  const { api } = useApi();
  const toast = useToast();

  const entityNotesPdfMutation = useMutation({
    mutationFn: async (
      body:
        | BodyOf<"post", "./notes/caregivers/:caregiverId/generate_url">
        | BodyOf<"post", "./notes/patients/:patientId/generate_url">
    ) => {
      const response = await (() => {
        switch (entity.type) {
          case "Caregiver":
          case "CaregiverCalendar":
            return api.post("./notes/caregivers/:caregiverId/generate_url", {
              path: { caregiverId: entity.id },
              body: body,
            });
          case "Patient":
          case "PatientCalendar":
            return api.post("./notes/patients/:patientId/generate_url", {
              path: { patientId: entity.id },
              body: body,
            });
        }
      })();

      const fileName = `${entity.name} - ${notes.length} notes.pdf`;
      const blob = await fetch(response.fileUrl).then((res) => res.blob());
      const file = new File([blob], fileName, { type: "application/pdf" });
      const base64 = await toBase64(file);

      return {
        base64: base64,
        fileName: fileName,
      };
    },
  });

  const sendMutation = useMutation({
    mutationFn: async (body: BodyOf<"post", "./faxes/send_document">) => {
      return api.post("./faxes/send_document", { body });
    },
    onSuccess: () => {
      disclosure.onClose();

      toast({
        title: "Fax sent",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error sending fax",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const isLoading = sendMutation.isPending || entityNotesPdfMutation.isPending;

  const form = useControlledForm({
    schema: zFaxForm,
    onSuccess: async (data) => {
      const { base64, fileName } = await entityNotesPdfMutation.mutateAsync({
        noteIds: notes.map((note) => note.id),
      });

      sendMutation.mutate({
        comment: data.body,
        faxNumber: data.phoneNumber,
        file: base64,
        fileName: fileName,
        recipient: data.name,
        subject: "Note",
        relatedEntity: (() => {
          switch (entity.type) {
            case "Caregiver":
            case "CaregiverCalendar":
              return { type: "Caregiver", caregiverId: entity.id };
            case "Patient":
            case "PatientCalendar":
              return { type: "Patient", patientId: entity.id };
          }
        })(),
      });
    },
  });

  return (
    <Modal {...disclosure} isCentered={true} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={form.onSubmit}>
          <ModalHeader>
            {notes.length === 1 && <Text>Send a note</Text>}
            {notes.length > 1 && <Text>Send {notes.length} notes</Text>}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={4} w="full">
              <FormControl isInvalid={form.isInvalid("phoneNumber")}>
                <FormLabel>Fax number:</FormLabel>
                <PhoneNumberInput
                  placeholder="(123) 456-7890"
                  value={form.state.phoneNumber ?? ""}
                  onChange={(x) => form.setValue("phoneNumber", x)}
                />
                <FormErrorMessage>{form.getError("phoneNumber")}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={form.isInvalid("name")}>
                <FormLabel>Recipient name:</FormLabel>
                <Input
                  placeholder="Name"
                  value={form.state.name ?? ""}
                  onChange={(e) => form.setValue("name", e.target.value)}
                />
                <FormErrorMessage>{form.getError("name")}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={form.isInvalid("body")}>
                <FormLabel>Message:</FormLabel>
                <Textarea
                  value={form.state.body ?? ""}
                  onChange={(e) => form.setValue("body", e.target.value)}
                />
                <FormErrorMessage>{form.getError("body")}</FormErrorMessage>
              </FormControl>
            </Flex>

            <Box mt={4}>
              <AttachedNotes notes={notes} />
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={disclosure.onClose}>Cancel</Button>
              <Button colorScheme="blue" isLoading={isLoading} type="submit">
                Send
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
