const get_tsk_utils_log_info = () => (window as any).tsk_utils_log_info;
const getSIPml = () => (window as any).SIPml;

export class CloudonixClient {
  /**
   * @description Initialise the Web SDK and the relevant SIP Stack elements.
   * @param domain {string} The cloudonix domain to init
   * @param username {string} The cloudonix domain subscriber to init
   * @param token {string} Either a password or a RegFree Token
   * @param regfree {boolean} Enable RegFree operation - default: false
   * @returns {boolean} Returns false on failure to initialise the SDK, due to improper token handling
   */
  constructor(domain: string, username: string, password: string);
  constructor(domain: string, username: string, token: string, isRegFree: boolean);
  constructor(
    domain: string,
    username: string,
    passwordOrToken: string,
    isRegFree: boolean = false
  ) {
    this.#setAgentIdent();

    if (!isRegFree) {
      if (!this.#bCredentialsSet) {
        const password = passwordOrToken;
        this.setCredentials(domain, username, password);
      }
    } else {
      const token = passwordOrToken;
      this.setRegFreeToken(domain, username, token);
    }

    try {
      /* Stack Pre-Init */
      // set default webrtc type (before initialization)
      const s_webrtc_type = this.#getPVal("wt");
      const s_fps = this.#getPVal("fps");
      const s_mvs = this.#getPVal("mvs"); // maxVideoSize
      const s_mbwu = this.#getPVal("mbwu"); // maxBandwidthUp (kbps)
      const s_mbwd = this.#getPVal("mbwd"); // maxBandwidthUp (kbps)
      const s_za = this.#getPVal("za"); // ZeroArtifacts
      const s_ndb = this.#getPVal("ndb"); // NativeDebug
      const b_ec = this.#bEchoCancel;
      const b_ns = this.#bNoiseSuppression;
      const b_agc = this.#bAutoGainControl;

      if (s_webrtc_type !== null) {
        getSIPml().setWebRtcType(s_webrtc_type);
      }

      // initialize SIPML5
      const tWaitForSip = setInterval(() => {
        if ((window as any).tmedia_session_state_e !== undefined) {
          const SIPml = getSIPml();
          clearInterval(tWaitForSip);
          SIPml.init(
            () => {
              /* SIP Stack Successfull Init */
            },
            () => {
              /* SIP Stack Init failed */
            }
          );

          // set other options after initialization
          if (s_fps) SIPml.setFps(parseFloat(s_fps));
          if (s_mvs) SIPml.setMaxVideoSize(s_mvs);
          if (s_mbwu) SIPml.setMaxBandwidthUp(parseFloat(s_mbwu));
          if (s_mbwd) SIPml.setMaxBandwidthDown(parseFloat(s_mbwd));
          if (s_za) SIPml.setZeroArtifacts(s_za === "true");
          if (s_ndb == "true") SIPml.startNativeDebug();
          if (b_ec) SIPml.setAudioConstraintEchoCancel(b_ec);
          if (b_ns) SIPml.setAudioConstraintNoiseSuppression(b_ns);
          if (b_agc) SIPml.setAudioConstraintAutogainControl(b_agc);

          SIPml.setDebugLevel(this.#bStackDebug ? "info" : "fatal");

          this.#postInitSipStack();
        }
      }, 100);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @namespace Cloudonix.sessionEvents
   * @description Cloudonix.sessionEvents namesapce.
   */
  public stackEvents = {
    /**
     * @description Stack Event Callback - Stack is now fully started
     * @param callback {function} Function to handle this callback
     */
    onStarting: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onStarting", callback);
    },

    /**
     * @description Stack Event Callback - Stack is now fully started
     * @param callback {function} Function to handle this callback
     */
    onStarted: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onStarted", callback);
    },

    /**
     * @description Stack Event Callback - Stack is shutting down
     * @param callback {function} Function to handle this callback
     */
    onStopping: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onStopping", callback);
    },

    /**
     * @description Stack Event Callback - Stack is stopped
     * @param callback {function} Function to handle this callback
     */
    onStopped: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onStopped", callback);
    },

    /**
     * @description Stack Event Callback - Stack failed to start
     * @param callback {function} Function to handle this callback
     */
    onFailedToStart: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onFailedToStart", callback);
    },

    /**
     * @description Stack Event Callback - Stack failed to stop
     * @param callback {function} Function to handle this callback
     */
    onFailedToStop: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onFailedToStop", callback);
    },

    /**
     * @description Stack Event Callback - A new session is starting (normally, a new call)
     * @param callback {function} Function to handle this callback
     */
    onNewSessionStarting: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onNewSessionStarting", callback);
    },

    /**
     * @description Stack Event Callback - The stack had initiated a browser permissions request
     * @param callback {function} Function to handle this callback
     */
    onBrowserPermissionsRequested: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onBrowserPermissionsRequested", callback);
    },

    /**
     * @description Stack Event Callback - The stack had been approved access to the microphone and camera
     * @param callback {function} Function to handle this callback
     */
    onBrowserPermissionsAccepted: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onBrowserPermissionsAccepted", callback);
    },

    /**
     * @description Stack Event Callback - The stack had been refused access to the microphone and camera and will be terminated
     * @param callback {function} Function to handle this callback
     */
    onBrowserPermissionsRefused: (callback: (e: unknown) => void) => {
      return this.#stackEventCallback("onBrowserPermissionsRefused", callback);
    },
  };

  #stackCallbacks: Partial<Record<StackEventName, ((e: unknown) => void)[]>> = {};

  /**
   * @namespace Cloudonix.sessionEvents
   * @description Cloudonix.sessionEvents namesapce.
   */
  public sessionEvents = {
    /**
     * @description Session Event Callback - A session is currently connecting (SIP 180 or 183)
     * @param callback {function} Function to handle this callback
     */
    onSessionConnecting: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionConnecting", callback);
    },

    /**
     * @description Session Event Callback - A session is now connected (SIP 200)
     * @param callback {function} Function to handle this callback
     */
    onSessionConnected: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionConnected", callback);
    },

    /**
     * @description Session Event Callback - A session is terminating (SIP BYE or SIP CANCEL)
     * @param callback {function} Function to handle this callback
     */
    onSessionTerminating: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionTerminating", callback);
    },

    /**
     * @description Session Event Callback - A session had been terminated
     * @param callback {function} Function to handle this callback
     */
    onSessionTerminated: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionTerminated", callback);
    },

    /**
     * @description Session Event Callback - Local browser audio had been added to the session
     * @param callback {function} Function to handle this callback
     */
    onLocalAudioAdded: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalAudioAdded", callback);
    },

    /**
     * @description Session Event Callback - Local browser audio had been removed from the session
     * @param callback {function} Function to handle this callback
     */
    onLocalAudioRemoved: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalAudioRemoved", callback);
    },

    /**
     * @description Session Event Callback - Remote browser audio had been added to the session
     * @param callback {function} Function to handle this callback
     */
    onRemoteAudioAdded: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onRemoteAudioAdded", callback);
    },

    /**
     * @description Session Event Callback - Remove browser audio had been removed from the session
     * @param callback {function} Function to handle this callback
     */
    onRemoteAudioRemoved: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onRemoteAudioRemoved", callback);
    },

    /**
     *  @description Session Event Callback - A new session is starting
     * @param callback {function} Function to handle this callback
     */
    onSessionNewSession: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionNewSession", callback);
    },

    /**
     * @description Session Event Callback - A response from a remote server had been received for the session
     * @param callback {function} Function to handle this callback
     */
    onSessionResponseReceived: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionResponseReceived", callback);
    },

    /**
     * @description Session Event Callback - Early media had been detected (normally, following a SIP 183)
     * @param callback {function} Function to handle this callback
     */
    onSessionEarlyMedia: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionEarlyMedia", callback);
    },

    /**
     * @description Session Event Callback - Local HOLD had completed successfully
     * @param callback {function} Function to handle this callback
     */
    onLocalHoldSuccess: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalHoldSuccess", callback);
    },

    /**
     * @description Session Event Callback - Local HOLD had failed
     * @param callback {function} Function to handle this callback
     */
    onLocalHoldFailed: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalHoldFailed", callback);
    },

    /**
     * @description Session Event Callback - Local Un-HOLD had completed successfully
     * @param callback {function} Function to handle this callback
     */
    onLocalUnholdSuccess: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalUnholdSuccess", callback);
    },

    /**
     * @description Session Event Callback - Local Un-HOLD had failed
     * @param callback {function} Function to handle this callback
     */
    onLocalUnholdFailed: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalUnholdFailed", callback);
    },

    /**
     * @description Session Event Callback - Remote HOLD started
     * @param callback {function} Function to handle this callback
     */
    onRemoteHoldStarted: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onRemoteHoldStarted", callback);
    },

    /**
     * @description Session Event Callback - Remote HOLD stopped
     * @param callback {function} Function to handle this callback
     */
    onRemoteHoldStopped: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onRemoteHoldStopped", callback);
    },

    /**
     * @description Session Event Callback - SIP Call Transfer had started
     * @param callback {function} Function to handle this callback
     */
    onSessionTransferStarted: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionTransferStarted", callback);
    },

    /**
     * @description Session Event Callback - SIP Call Transfer had been accepted
     * @param callback {function} Function to handle this callback
     */
    onSessionTransferAccepted: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionTransferAccepted", callback);
    },

    /**
     * @description Session Event Callback - SIP Call Transfer had failed
     * @param callback {function} Function to handle this callback
     */
    onSessionTransferFailed: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionTransferFailed", callback);
    },

    /**
     * @description Session Event Callback - SIP Call Transfer Notification
     * @param callback {function} Function to handle this callback
     */
    onSessionTransferNotification: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionTransferNotification", callback);
    },

    /**
     * @description Session Event Callback - SIP Call Transfer had been requested
     * @param callback {function} Function to handle this callback
     */
    onSessionTransferRequested: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionTransferRequested", callback);
    },

    /**
     * @description Session Event Callback - SIP REGISTER had been sent
     * @param callback {function} Function to handle this callback
     */
    onSessionRegister: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionRegister", callback);
    },

    /**
     * @description Session Event Callback - SIP REGISTER had completed successfully
     * @param callback {function} Function to handle this callback
     */
    onSessionRegistered: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionRegistered", callback);
    },

    /**
     * @description Session Event Callback - SIP Un-REGISTER had been sent
     * @param callback {function} Function to handle this callback
     */
    onSessionUnregister: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionUnregister", callback);
    },

    /**
     * @description Session Event Callback - SIP Un-REGISTER completed successfully
     * @param callback {function} Function to handle this callback
     */
    onSessionUnregistered: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onSessionUnregistered", callback);
    },

    /**
     * @description Session Event Callback - Local Audio Mute/Unmute Failed
     * @param callback {function} Function to handle this callback
     */
    onLocalMuteFailed: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalMuteFailed", callback);
    },

    /**
     * @description Session Event Callback - Local Audio Mute/Unmute is request occurred
     * @param callback {function} Function to handle this callback
     */
    onLocalMuteToggle: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalMuteToggle", callback);
    },

    /**
     * @description Session Event Callback - Local Audio Silent is request occurred
     * @param callback {function} Function to handle this callback
     */
    onLocalSilent: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalSilent", callback);
    },

    /**
     * @description Session Event Callback - Local Audio Unsilent is request occurred
     * @param callback {function} Function to handle this callback
     */
    onLocalUnSilent: (callback: (e: unknown) => void) => {
      return this.#sessionEventCallback("onLocalUnSilent", callback);
    },
  };

  #sessionCallbacks: Partial<Record<string, ((e: unknown) => void)[]>> = {};

  #oSipStack: any | null = null;
  #oSipStackConfig: SipStackConfig | null = null;
  #oSipSessionRegister: any | null = null;
  #oSipSessionCall: any | null = null;
  #oSipSessionTransferCall: object | null = null;
  // #oVideoRemote: object | null = null;
  // #oVideoLocal: object | null = null;
  #oAudioRemote: object | null = null;
  #oNotifICall: any | null = null;
  // #oViewVideoLocal: object | null = null;
  // #oViewVideoRemote: object | null = null;
  #oConfigCall: any | null = null;
  // #oReadyStateTimer: object | null = null;
  #bRingtonePlaying: boolean = false;
  #bRingbacktonePlaying: boolean = false;

  #bRegfreeDialing: boolean = false;
  #bCredentialsSet: boolean = false;
  #sRegfreeToken: string | null = null;
  // #bFullScreen: boolean = false;
  #bSipStackReady: boolean = false;
  #sDestination: string | null = null;
  #sApikey: string | null = null;
  #sUsername: string | null = null;
  #sPassword: string | null = null;
  #sDomain: string | null = null;
  // #aMetadata = null;
  // #bDisableVideo: boolean = false;
  #bInboundCalls: boolean = false;
  // #bVideoEnable: boolean = false;
  #bMultiline: boolean = false;
  // #cxConfig = null;
  #aBaseSipHeader: { name: string; value: string; session: boolean }[] | null = null;
  #bCallConnected: boolean = false;
  // #sVerification: string | null = false;
  // #oVerification = false;
  #bSipStatus183: boolean = false;
  // #bSipStatus200: boolean = false;

  #tSipStatus183: NodeJS.Timeout | undefined;

  #bEchoCancel: boolean = true;
  #bNoiseSuppression: boolean = true;
  #bAutoGainControl: boolean = true;

  // #sMyPublicIpNumber: string | null = null;

  #bStackDebug = false;

  #cxUserAgentStun = [{ url: "stun:stun.google.com:19302" }];
  #cxUserAgentWssEndpoint = "wss://webrtc.cloudonix.io:443";
  #cxApiCoreEndpoint = "https://api.cloudonix.io";
  #myRemoteRegFreeToken: string | boolean | null = null;

  #cxUserAgentVersion = "1";
  #cxUserAgentIdent = `CxSmrtWebClient ${this.#cxUserAgentVersion}[${navigator.userAgent}]`;
  #cxUserAgentOrganization = "Cloudonix.io";

  #btnHoldResume: { value: "Hold" | "Resume"; disabled: boolean } | null = null;

  #audioElements = createAudioElements(`cloudonix-${crypto.randomUUID()}_`);

  get btnHoldResume() {
    return this.#btnHoldResume;
  }

  static load() {
    injectScriptElementOnce("https://webinc.cloudonix.io/sdk/v1.3.2.1/js/SIPml-api.js");
  }

  /**
   * @description Set SDK to Debug mode
   */
  setDebug() {
    this.#bStackDebug = true;
  }

  /**
   * @description Enable the WebRTC Echo Cancelling module (Default: Enabled)
   */
  setEchoCancel() {
    this.#bEchoCancel = true;
  }

  /**
   * @description Disable the WebRTC Echo Cancelling module
   */
  unsetEchoCancel() {
    this.#bEchoCancel = false;
  }

  /**
   * @description Enable the WebRTC Noise Suppression module (Default: Enabled)
   */
  setNoiseSuppression() {
    this.#bNoiseSuppression = true;
  }

  /**
   * @description Disable the WebRTC Noise Suppression module
   */
  unsetNoiseSuppression() {
    this.#bNoiseSuppression = false;
  }

  /**
   * @description Enable the WebRTC Auto-Gain Control module (Default: Enabled)
   */
  setAutoGainControl() {
    this.#bAutoGainControl = true;
  }

  /**
   * @description Disable the WebRTC Auto-Gain Control module
   */
  unsetAutoGainControl() {
    this.#bAutoGainControl = false;
  }

  #setAgentIdent() {
    this.#cxUserAgentVersion = "1";
    this.#cxUserAgentIdent =
      "CxSmrtWebClient " + this.#cxUserAgentVersion + "[" + navigator.userAgent + "]";
    this.#cxUserAgentOrganization = "Cloudonix.io";
  }

  /**
   * @description Add a new STUN/TURN server configuration or use the default one.
   *
   * @param stunServerObject {object} A JSON object representing a STUN server, eg. { url: 'stun:stun.google.com:19302' }
   * @returns {array} The current STUN/TURN servers list
   */
  setStunServers(stunServerObject: { url: string }[]) {
    this.#cxUserAgentStun = stunServerObject;

    return this.#cxUserAgentStun;
  }

  /**
   * @description Set the Cloudonix SIP over WebSocket Endpoint
   *
   * @param websocketEndpoint {string} SIP over WebSocket URI
   */
  setCloudonixWssEndpoint(websocketEndpoint: string) {
    this.#cxUserAgentWssEndpoint = websocketEndpoint;
  }

  /**
   * @description Set the Cloudonix API.Core Endpoint
   *
   * @param cloudonixApiEndpoint {string} Cloudonix HTTP/HTTPS API Endpoint URL
   */
  setCloudonixApiEndpoint(cloudonixApiEndpoint: string) {
    this.#cxApiCoreEndpoint = cloudonixApiEndpoint;
  }

  /**
   * @description Set the SDK for credentials based operations (SIP username/password usage)
   * @param domain {string} The Cloudonix Domain of the subscriber
   * @param username {string} The assigned subscriber username
   * @param password {string} The assigned subscriber password
   * @returns {boolean} Returns false on missing or invalid input
   */
  setCredentials(domain: string, username: string, password: string) {
    this.#bRegfreeDialing = false;
    this.#sUsername = username;
    this.#sPassword = password;
    this.#sDomain = domain;

    if (!this.#bRegfreeDialing && (!this.#sUsername || !this.#sPassword || !this.#sDomain)) {
      console.error("=== Init error, Domain/Username/Password credentials not provided properly");
    }

    this.#bCredentialsSet = true;
  }

  /**
   * @description Request a RegfreeDialing Token from Cloudonix backend
   *
   * @deprecated - DO NOT USE THIS FUNCTION IN PRODUCTION!!! IT IS PROVIDED AS A LEARNING TOOL ONLY!!!
   */
  async requestRegFreeToken(destination: string) {
    if (!this.#sDomain || !this.#sUsername || !this.#sApikey) {
      console.error("=== Init error, Domain/Username/Apikey credentials not provided properly");
    }

    this.#myRemoteRegFreeToken = false;
    this.#sDestination = destination;

    const response = await fetch(
      `${this.#cxApiCoreEndpoint}/calls/${this.#sDomain}/outgoing/${this.#sUsername}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${this.#sApikey}`,
        }),
        body: JSON.stringify({ destination: this.#sDestination }),
      }
    );

    if (response.status !== 200) {
      console.error(response.statusText);
      return false;
    }

    this.#myRemoteRegFreeToken = true;
    const myXhrResponse = await response.json();
    const mySingleTimeToken = myXhrResponse["token"];
    this.#sRegfreeToken = mySingleTimeToken;

    this.#oSipStackConfig?.sip_headers.push({
      name: "X-Cloudonix-Session",
      value: mySingleTimeToken,
      session: false,
    });

    return true;
  }

  /**
   * @description Set the SDK for RegFreeDialing based operations (Single Time Token).
   * more information can be obtained at [https://cloudonix.io/wp-content/uploads/2018/12/Cloudonix-Registration-Free-Dialing.pdf]
   *
   * @param domain {string} The Cloudonix Domain of the subscriber
   * @param username {string} The assigned subscriber username
   * @param regfreeToken {string} The Cloudonix provided single time token for RegfreeDialing
   */

  setRegFreeToken(domain: string, username: string, regfreeToken: string) {
    this.#bRegfreeDialing = true;
    this.#bMultiline = false;
    this.#bInboundCalls = false;
    // this.#bVideoEnable = false;

    this.#sDomain = domain;
    this.#sUsername = username;
    this.#sRegfreeToken = regfreeToken;

    if (this.#bRegfreeDialing && (!this.#sUsername || !this.#sRegfreeToken)) {
      console.error(
        "=== Init error, Domain/Username/regfreeToken credentials not provided properly"
      );
    }

    this.#oSipStackConfig?.sip_headers.push({
      name: "X-Cloudonix-Session",
      value: this.#sRegfreeToken,
      session: false,
    });
  }

  /**
   * @description Stop the Web SDK and destroy any active connection
   */
  destroySipStack() {
    this.#oSipStack?.stop();
  }

  #postInitSipStack() {
    if (this.#sDomain === null || this.#sUsername === null || this.#sPassword === null) {
      console.error("=== Init error, Domain/Username/Password credentials not provided properly");
      return;
    }

    /* Populate SIP Stack Identifying information */
    const my_sip_headers = [
      { name: "User-Agent", value: this.#cxUserAgentIdent, session: true },
      { name: "Organization", value: this.#cxUserAgentOrganization, session: true },
      { name: "X-Cloudonix-Domain", value: this.#sDomain, session: true },
    ];

    this.#aBaseSipHeader = my_sip_headers.slice();

    /* Activate the SIP Stack */
    this.#oSipStackConfig = {
      realm: this.#sDomain,
      impi: this.#sUsername,
      impu: "sip:" + this.#sUsername + "@" + this.#sDomain,
      password: this.#sPassword,
      display_name: this.#sUsername,
      websocket_proxy_url: this.#cxUserAgentWssEndpoint,
      enable_rtcweb_breaker: true,
      enable_click2call: false,
      events_listener: {
        events: "*",
        listener: this.#onCloudonixSipStackEvent.bind(this),
      }, // optional: '*' means all events
      ice_servers: this.#cxUserAgentStun,
      sip_headers: my_sip_headers,
    };

    const SIPml = getSIPml();
    this.#oSipStack = new SIPml.Stack(this.#oSipStackConfig);
    this.#oSipStack.start();
    return;
  }

  #getPVal(name: string) {
    const params = new URLSearchParams(window.location.search);
    const value = params.get(name);
    return value ? decodeURIComponent(value) : null;
  }

  /**
   * @description SIP REGISTER using the credentials, provided by the Cloudonix Token.
   */
  sipRegister() {
    const tSipStack = setInterval(() => {
      if (this.#bSipStackReady) {
        clearInterval(tSipStack);

        try {
          this.#oSipSessionRegister = this.#oSipStack.newSession("register", {
            expires: 200,
            events_listener: {
              events: "*",
              listener: this.#onCloudonixSipSessionEvent.bind(this),
            },
            sip_caps: [{ name: "+g.oma.sip-im" }, { name: "language", value: '"en,fr"' }],
          });

          this.#oSipSessionRegister?.register();
        } catch (e) {
          console.error(e);
        }
      }
    });
  }

  /**
   * @description SIP UNREGISTER
   */
  sipUnRegister() {
    if (this.#oSipStack) {
      this.#oSipStack?.stop(); // shutdown all sessions
      this.#bSipStackReady = false;
      this.#bCredentialsSet = false;
      this.#bSipStatus183 = false;
      this.#sessionCallbacks = {};
      // this.#bSipStatus200 = false;
    }
  }

  async waitTillSipStackReady() {
    return new Promise((resolve) => {
      const tSipStack = setInterval(() => {
        if (this.#bSipStackReady) {
          clearInterval(tSipStack);
          resolve(true);
        }
      }, 100);
    });
  }

  sipStartCall(destination: string, metadata: Record<PropertyKey, any>[] = []) {
    try {
      if (destination.match(/[ /.:<>?!@$%^&(){}'";-=_]/)) {
        console.log("=== sipStartCall Error: Destination contains invalid characters");
        throw new Error();
      }

      if (this.#oSipStackConfig === null || this.#aBaseSipHeader === null) {
        console.log("=== sipStartCall Error: SIP Stack not initialised");
        throw new Error();
      }

      this.#oSipStackConfig.sip_headers.length = 0;
      this.#oSipStackConfig.sip_headers = this.#aBaseSipHeader.slice();

      // Reset silent mode id needed
      this.#audioElements.remote.volume = 1;

      const myUuid = crypto.randomUUID();
      this.#myRemoteRegFreeToken = null;
      this.#oConfigCall = {
        audio_remote: this.#oAudioRemote,
        events_listener: {
          events: "*",
          listener: this.#onCloudonixSipSessionEvent.bind(this),
        },
        sip_caps: [
          //{name: '+g.oma.sip-im'},
          //{name: 'language', value: '\"en,fr\"'}
        ],
        //video_local: viewVideoLocal,
        //video_remote: viewVideoRemote,
        //screencast_window_id: 0x00000000, // entire desktop
        //bandwidth: {audio: undefined, video: undefined},
        //video_size: {minWidth: undefined, minHeight: undefined, maxWidth: undefined, maxHeight: undefined},
      };

      /* Enable or Disable RegFree Dialing */
      if (this.#bRegfreeDialing) {
        if (!this.#sRegfreeToken) {
          console.log("=== sipStartCall Error: RegFree Enabled without RegfreeToken");
          console.log(
            "=== You must provide the RegfreeToken by either issuing `Cloudonix.requestRegFreeToken` or `Cloudonix.setRegFreeToken`"
          );
          throw new Error();
        }

        this.#myRemoteRegFreeToken = this.#sRegfreeToken;
        this.#oSipStackConfig.sip_headers.push({
          name: "X-Cloudonix-Session",
          value: this.#sRegfreeToken,
          session: false,
        });
      }

      const tSipStack = setInterval(() => {
        this.#oAudioRemote = this.#audioElements.remote;

        if (this.#oConfigCall !== null) {
          this.#oConfigCall.audio_remote = this.#oAudioRemote;
        }

        const cond1 = this.#bSipStackReady && this.#oAudioRemote != null && !this.#bRegfreeDialing;

        const cond2 =
          this.#bSipStackReady &&
          this.#oAudioRemote != null &&
          this.#bRegfreeDialing &&
          Boolean(this.#myRemoteRegFreeToken);

        if (cond1 || cond2) {
          clearInterval(tSipStack);

          if (metadata != null && metadata) {
            this.#oSipStack?.stop();
            metadata.forEach((sipHeaderObject) => {
              if (typeof sipHeaderObject === "object") {
                const entry = Object.entries(sipHeaderObject);
                const sipHeaderName = entry[0][0];
                const sipHeaderValue = entry[0][1];

                this.#oSipStackConfig?.sip_headers.push({
                  name:
                    sipHeaderName == "X-Cloudonix-Session" ? sipHeaderName : "CV-" + sipHeaderName,
                  value: sipHeaderValue,
                  session: false,
                });
              }
            });
            this.#bSipStackReady = false;
            this.#oSipStackConfig?.sip_headers.push({
              name: "X-Cloudonix-ID",
              value: myUuid,
              session: false,
            });
            const SIPml = getSIPml();
            this.#oSipStack = new SIPml.Stack(this.#oSipStackConfig);
            this.#oSipStack?.start();
          }

          const tSipStartCall = setInterval(() => {
            if (this.#bSipStackReady && this.#oAudioRemote != null) {
              clearInterval(tSipStartCall);
              get_tsk_utils_log_info()(this.#oConfigCall);
              this.#oSipSessionCall = this.#oSipStack?.newSession("call-audio", this.#oConfigCall);
              get_tsk_utils_log_info()(
                "== [Cloudonix.sipCall] newSession invoked " + this.#oSipSessionCall
              );

              // make call
              if (this.#oSipSessionCall?.call(destination) != 0) {
                get_tsk_utils_log_info()("== [Cloudonix.sipCall] Failed to make call...");
                this.#oSipSessionCall = null;
              } else {
                get_tsk_utils_log_info()("== [Cloudonix.sipCall] Call started successfully...");
              }
            }
          }, 100);
        }
      }, 100);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @description Stop and active call and hangup
   */
  sipStopCall() {
    if (this.#oSipSessionCall) {
      get_tsk_utils_log_info()("== [Cloudonix.sipHangUp] hungup");
      this.#oSipSessionCall.hangup({
        events_listener: { events: "*", listener: this.#onCloudonixSipSessionEvent.bind(this) },
      });
    }
  }

  #onCloudonixSipSessionEvent(e: {
    type:
      | "connecting"
      | "connected"
      | "terminating"
      | "terminated"
      | "m_stream_audio_local_added"
      | "m_stream_audio_local_removed"
      | "m_stream_audio_remote_added"
      | "m_stream_audio_remote_removed"
      | "i_ect_new_call"
      | "i_ao_request"
      | "m_early_media"
      | "m_local_hold_ok"
      | "m_local_hold_nok"
      | "m_local_resume_ok"
      | "m_local_resume_nok"
      | "m_remote_hold"
      | "m_remote_resume"
      | "m_bfcp_info"
      | "o_ect_trying"
      | "o_ect_accepted"
      | "o_ect_completed"
      | "i_ect_completed"
      | "o_ect_failed"
      | "i_ect_failed"
      | "o_ect_notify"
      | "i_ect_notify"
      | "i_ect_requested";
    description: string;
    session: object;
    getSipResponseCode: () => number;
    getTransferDestinationFriendlyName: () => string;
  }) {
    get_tsk_utils_log_info()("== [Cloudonix.onCloudonixSipSessionEvent] event = " + e.type);
    switch (e.type) {
      case "connecting":
      case "connected": {
        const bConnected = e.type == "connected";
        if (e.session == this.#oSipSessionRegister) {
          if (e.type == "connecting") this.#sessionEventDispatch("onSessionRegister", e);
          else this.#sessionEventDispatch("onSessionRegistered", e);
          get_tsk_utils_log_info()(
            "== [Cloudonix.onCloudonixSipSessionEvent (oSipSessionRegister)] " + e.description
          );
        } else if (e.session == this.#oSipSessionCall) {
          if (e.type == "connecting") this.#sessionEventDispatch("onSessionConnecting", e);
          else this.#sessionEventDispatch("onSessionConnected", e);
          get_tsk_utils_log_info()(
            "== [Cloudonix.onCloudonixSipSessionEvent (oSipSessionCall)] " + e.description
          );

          if (bConnected) {
            clearInterval(this.#tSipStatus183 ?? undefined);
            this.#sessionEventDispatch("onSessionStarted", e);
            this.#bCallConnected = true;
            // this.#bSipStatus200 = true;
            if (this.#bRingtonePlaying) this.stopRingTone();
            if (this.#bRingbacktonePlaying) this.stopRingbackTone();

            if (this.#oNotifICall) {
              this.#oNotifICall.cancel();
              this.#oNotifICall = null;
            }
          }

          get_tsk_utils_log_info()("== [Cloudonix.onCloudonixSipSessionEvent] " + e.description);
          if (getSIPml().isWebRtc4AllSupported()) {
            // IE don't provide stream callback
          }
        }
        break;
      } // 'connecting' | 'connected'
      case "terminating":
      case "terminated": {
        clearTimeout(this.#tSipStatus183);
        if (e.session == this.#oSipSessionRegister) {
          if (e.type == "terminating") this.#sessionEventDispatch("onSessionUnregister", e);
          else this.#sessionEventDispatch("onSessionUnregistered", e);
          this.#oSipSessionCall = null;
          this.#oSipSessionRegister = null;
          get_tsk_utils_log_info()("== [Cloudonix.onCloudonixSipSessionEvent] " + e.description);
        } else if (e.session == this.#oSipSessionCall) {
          if (e.type == "terminating") this.#sessionEventDispatch("onSessionTerminating", e);
          else this.#sessionEventDispatch("onSessionTerminated", e);
          if (this.#bRingbacktonePlaying) this.stopRingbackTone();
          this.#bCallConnected = false;
        }
        this.stopRingbackTone();
        // this.#bSipStatus200 = true;
        break;
      } // 'terminating' | 'terminated'

      case "m_stream_audio_local_added": {
        this.#sessionEventDispatch("onLocalAudioAdded", e);
        break;
      }
      case "m_stream_audio_local_removed": {
        this.#sessionEventDispatch("onLocalAudioRemoved", e);
        break;
      }
      case "m_stream_audio_remote_added": {
        this.#sessionEventDispatch("onRemoteAudioAdded", e);
        break;
      }
      case "m_stream_audio_remote_removed": {
        this.#sessionEventDispatch("onRemoteAudioRemoved", e);
        break;
      }

      case "i_ect_new_call": {
        this.#sessionEventDispatch("onSessionNewSession", e);
        this.#oSipSessionTransferCall = e.session;
        break;
      }

      case "i_ao_request": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onSessionResponseReceived", {
            description: e.description,
          });
          const iSipResponseCode = e.getSipResponseCode();
          if (iSipResponseCode == 180) {
            get_tsk_utils_log_info()("== [Cloudonix.onCloudonixSipSessionEvent] Local Ringing...");
            this.#tSipStatus183 = setTimeout(() => {
              if (!this.#bSipStatus183 && !this.#bCallConnected) {
                this.startRingbackTone();
              }
            }, 2000);
          } else if (iSipResponseCode == 183) {
            get_tsk_utils_log_info()("== [Cloudonix.onCloudonixSipSessionEvent] Remote Ringing...");
            clearTimeout(this.#tSipStatus183);
            this.#bSipStatus183 = true;
            this.stopRingbackTone();
          }
          /*
                if (iSipResponseCode == 180) {
                    Cloudonix.tSipStatus183 = setTimeout(function () {
                        if ((!Cloudonix.bSipStatus183) && (!Cloudonix.bCallConnected))
                            Cloudonix.startRingbackTone();
                    }, 5000);
                } else if (iSipResponseCode == 183) {
                    Cloudonix.bSipStatus183 = true;
                    clearInterval(Cloudonix.tSipStatus183);
                    if (Cloudonix.bRingbacktonePlaying)
                        Cloudonix.stopRingbackTone();
                    get_tsk_utils_log_info()('== [Cloudonix.onCloudonixSipSessionEvent] Remote Ringing...');
                }
                */
        }
        break;
      }

      case "m_early_media": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onSessionEarlyMedia", e);
          if (this.#bRingtonePlaying) this.stopRingTone();
          if (this.#bRingbacktonePlaying) this.stopRingbackTone();
          get_tsk_utils_log_info()("== [onSipEventSession] Early media started...");
        }
        break;
      }

      case "m_local_hold_ok": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onLocalHoldSuccess", e);
          if (this.#oSipSessionCall.bTransfering) {
            this.#oSipSessionCall.bTransfering = false;
            // this.AVSession.TransferCall(this.transferUri);
          }
          this.#btnHoldResume = { value: "Resume", disabled: false };
          get_tsk_utils_log_info()("== [onSipEventSession] Local hold started...");
          this.#oSipSessionCall.bHeld = true;
        }
        break;
      }
      case "m_local_hold_nok": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onLocalHoldFailed", e);
          this.#oSipSessionCall.bTransfering = false;
          this.#btnHoldResume = { value: "Hold", disabled: false };
          get_tsk_utils_log_info()("== [onSipEventSession] Local hold failed...");
        }
        break;
      }
      case "m_local_resume_ok": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onLocalUnholdSuccess", e);
          this.#oSipSessionCall.bTransfering = false;
          this.#btnHoldResume = { value: "Hold", disabled: false };
          get_tsk_utils_log_info()("== [onSipEventSession] Local hold canceled, call resumed");
          this.#oSipSessionCall.bHeld = false;

          if (getSIPml().isWebRtc4AllSupported()) {
            // IE don't provide stream callback yet
            // uiVideoDisplayEvent(false, true);
            // uiVideoDisplayEvent(true, true);
          }
        }
        break;
      }
      case "m_local_resume_nok": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onLocalUnholdFailed", e);
          this.#oSipSessionCall.bTransfering = false;
          this.#btnHoldResume = { value: "Resume", disabled: false };
          get_tsk_utils_log_info()("== [onSipEventSession] Local resumed had failed");
        }
        break;
      }
      case "m_remote_hold": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onRemoteHoldStarted", e);
          get_tsk_utils_log_info()("== [onSipEventSession] Call is on Remote-Hold");
        }
        break;
      }
      case "m_remote_resume": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onRemoteHoldStopped", e);
          get_tsk_utils_log_info()("== [onSipEventSession] Call is Remote-Resumed");
        }
        break;
      }
      case "m_bfcp_info": {
        if (e.session == this.#oSipSessionCall) {
          get_tsk_utils_log_info()("== [onSipEventSession] BFCP Information: " + e.description);
        }
        break;
      }

      case "o_ect_trying": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onSessionTransferStarted", {
            description: e.description,
          });
          get_tsk_utils_log_info()("== [onSipEventSession] Call transfer started...");
        }
        break;
      }
      case "o_ect_accepted": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onSessionTransferAccepted", {
            description: e.description,
          });
          get_tsk_utils_log_info()("== [onSipEventSession] Call transfer accepted");
        }
        break;
      }
      case "o_ect_completed":
      case "i_ect_completed": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onSessionTransferNotification", {
            description: e.description,
          });
          get_tsk_utils_log_info()("== [onSipEventSession] Call transfer completed");
          // btnTransfer.disabled = false;
          if (this.#oSipSessionTransferCall === null) {
            this.#oSipSessionCall = this.#oSipSessionTransferCall;
          }
          this.#oSipSessionTransferCall = null;
        }
        break;
      }
      case "o_ect_failed":
      case "i_ect_failed": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onSessionTransferNotification", {
            description: e.description,
          });
          get_tsk_utils_log_info()("== [onSipEventSession] Call transfer failed");
          // btnTransfer.disabled = false;
        }
        break;
      }
      case "o_ect_notify":
      case "i_ect_notify": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onSessionTransferNotification", {
            description: e.description,
          });
          get_tsk_utils_log_info()(
            "== [onSipEventSession] Call Transfer: " + e.getSipResponseCode() + " " + e.description
          );
          if (e.getSipResponseCode() >= 300) {
            if (this.#oSipSessionCall.bHeld) {
              this.#oSipSessionCall.resume();
            }
            // btnTransfer.disabled = false;
          }
        }
        break;
      }
      case "i_ect_requested": {
        if (e.session == this.#oSipSessionCall) {
          this.#sessionEventDispatch("onSessionTransferNotification", {
            description: e.description,
          });
          const s_message =
            "Do you accept call transfer to [" + e.getTransferDestinationFriendlyName() + "]?"; //FIXME
          if (confirm(s_message)) {
            get_tsk_utils_log_info()("== [onSipEventSession] Call transfer in progress");
            this.#oSipSessionCall.acceptTransfer();
            break;
          }
          this.#oSipSessionCall.rejectTransfer();
        }
        break;
      }
    }
  }

  #onCloudonixSipStackEvent(e: any) {
    get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] event = " + e.type);
    switch (e.type) {
      case "started":
        this.#stackEventDispatch("onStarted", e);
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {started}");
        this.#bSipStackReady = true;
        break;
      case "stopping":
        this.#stackEventDispatch("onStopping", e);
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {stopping}");
        break;
      case "stopped":
        this.#stackEventDispatch("onStopped", e);
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {stopped}");
        break;
      case "failed_to_start":
      case "failed_to_stop":
        if (e.type == "failed_to_start") this.#stackEventDispatch("onFailedToStart", e);
        else this.#stackEventDispatch("onFailedToStop", e);

        get_tsk_utils_log_info()(
          "== [onCloudonixSipStackEvent] {failed_to_start/failed_to_stop} " + e.type
        );
        this.#oSipStack = null;
        this.#oSipSessionRegister = null;
        this.#oSipSessionCall = null;
        get_tsk_utils_log_info()(
          "== [onCloudonixSipStackEvent] {failed_to_start/failed_to_stop} " + e.description
        );
        break;
      case "i_new_call":
        this.#stackEventDispatch("onNewSessionStarting", e);
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {i_new_call}");
        if (!this.#bInboundCalls && !this.#bMultiline && this.#oSipSessionCall) {
          e.newSession.hangup();
          return;
        }
        /*
           startRingTone();
           var sRemoteNumber = (Cloudonix.oSipSessionCall.getRemoteFriendlyName() || 'unknown');
           get_tsk_utils_log_info()('== [onCloudonixSipStackEvent] {i_new_call} Incoming from ' + sRemoteNumber);
           showNotifICall(sRemoteNumber);
           */
        break;
      case "m_permission_requested":
        this.#stackEventDispatch("onBrowserPermissionsRequested", e);
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {m_permission_requested}");
        break;
      case "m_permission_accepted":
        this.#stackEventDispatch("onBrowserPermissionsAccepted", e);
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {m_permission_accepted}");
        break;
      case "m_permission_refused": {
        this.#stackEventDispatch("onBrowserPermissionsRefused", e);
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {m_permission_refused}");
        break;
      }
      case "starting":
        this.#stackEventDispatch("onStarting", e);
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {m_permission_refused}");
        break;
      default:
        get_tsk_utils_log_info()("== [onCloudonixSipStackEvent] {" + e.type + "}");
        break;
    }
  }

  /**
   * @description Starting playing the local ringing tone (inbound call). Normally, associated with the onSessionConnecting callback.
   */
  startRingTone() {
    try {
      this.#bRingtonePlaying = true;
      this.#audioElements.ringtone.play();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @description Stop playing the local ringing tone (inbound call). Normally, associated with the onSessionConnected or onSessionTerminated callback.
   */
  stopRingTone() {
    try {
      this.#bRingtonePlaying = false;
      this.#audioElements.ringtone.pause();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @description Starting playing the local ring-back tone. Normally, associated with the onSessionConnecting callback.
   */
  startRingbackTone() {
    try {
      this.#bRingbacktonePlaying = true;
      this.#audioElements.ringbacktone.play();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @description Starting playing the local ring-back tone. Normally, associated with the onSessionConnected or onSessionTerminated callback.
   */
  stopRingbackTone() {
    try {
      this.#bRingbacktonePlaying = false;
      this.#audioElements.ringbacktone.pause();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @description Send a DTMF tone to the remote server
   * @param c {char} A character, representing the DTMF tone to send (0-9#*)
   */
  sipSendDtmfTone(c: string) {
    if (this.#oSipSessionCall && c) {
      if (this.#oSipSessionCall.dtmf(c) == 0) {
        try {
          get_tsk_utils_log_info()("== [sipSendDtmfTone] Playing tone: " + c);
          this.#audioElements.dtmfTone.play();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  // #sipToggleHold(e: unknown) {
  //   if (this.#oSipSessionCall) {
  //     const i_ret = this.#oSipSessionCall.bHeld
  //       ? this.#oSipSessionCall.resume()
  //       : this.#oSipSessionCall.hold();

  //     this.#oSipSessionCall.bHeld
  //       ? this.#sessionEventDispatch("onLocalUnholdSuccess", { description: e })
  //       : this.#sessionEventDispatch("onLocalHoldSuccess", { description: e });

  //     if (i_ret != 0) {
  //       if (this.#oSipSessionCall.bHeld)
  //         this.#sessionEventDispatch("onLocalUnholdFailed", { description: e });
  //       else this.#sessionEventDispatch("onLocalHoldFailed", { description: e });
  //     }
  //   }
  // }

  /**
   * @description Mute/Unmute my audio stream
   */
  sipToggleMute(description?: unknown) {
    if (this.#oSipSessionCall) {
      const bMute = !this.#oSipSessionCall.bMute;
      const i_ret = this.#oSipSessionCall.mute("audio" /*could be 'video'*/, bMute);
      if (i_ret != 0) {
        this.#sessionEventDispatch("onLocalMuteFailed", { description });
        return;
      }
      this.#sessionEventDispatch("onLocalMuteToggle", { description });
      this.#oSipSessionCall.bMute = bMute;

      if (!bMute && this.#audioElements.remote.volume === 0) {
        this.sipUnSilent();
      }
    }
  }

  sipSilent(description?: unknown) {
    try {
      this.#audioElements.remote.volume = 0;
      if (!this.#oSipSessionCall.bMute) {
        this.sipToggleMute(description);
      }
      this.#sessionEventDispatch("onLocalSilent", { description });
    } catch (e) {
      this.#sessionEventDispatch("onLocalSilentFailed", { description });
    }
  }

  sipUnSilent(description?: unknown) {
    try {
      this.#audioElements.remote.volume = 1;
      this.#sessionEventDispatch("onLocalUnSilent", { description });
      if (this.#oSipSessionCall.bMute) {
        this.sipToggleMute(description);
      }
    } catch (e) {
      this.#sessionEventDispatch("onLocalSilentFailed", { description });
    }
  }

  /* Stack Event Dispatchers */
  #stackEventCallback(event: StackEventName, callback: (e: unknown) => void) {
    if (!this.#stackCallbacks[event]) {
      this.#stackCallbacks[event] = [];
    }

    this.#stackCallbacks[event]?.push(callback);

    return () => {
      this.#stackCallbacks[event] = this.#stackCallbacks[event]?.filter((cb) => cb !== callback);
    };
  }

  #stackEventDispatch(event: StackEventName, data: unknown) {
    if (!this.#stackCallbacks[event]) return;

    this.#stackCallbacks[event]?.forEach((callback) => {
      callback(data);
    });
  }

  /* Session Event Dispatchers */
  #sessionEventCallback(event: SessionEventName, callback: (e: unknown) => unknown) {
    if (!this.#sessionCallbacks[event]) {
      this.#sessionCallbacks[event] = [];
    }
    this.#sessionCallbacks[event]?.push(callback);

    return () => {
      this.#sessionCallbacks[event] = this.#sessionCallbacks[event]?.filter(
        (cb) => cb !== callback
      );
    };
  }

  #sessionEventDispatch(event: SessionEventName, data: unknown) {
    if (!this.#sessionCallbacks[event]) return;
    this.#sessionCallbacks[event]?.forEach((callback) => {
      callback(data);
    });
  }
}

const injectedScripts: string[] = [];

function injectScriptElementOnce(src: string) {
  if (injectedScripts.includes(src)) return;

  const tagScript = document.createElement("script");
  tagScript.setAttribute("type", "text/javascript");
  tagScript.setAttribute("src", src);
  tagScript.setAttribute("defer", "defer");
  document.head.appendChild(tagScript);

  injectedScripts.push(src);
}

function createAudioElements(prefix: string) {
  const audioRemote = document.createElement("audio");
  audioRemote.id = prefix + "audio_remote";
  audioRemote.autoplay = true;

  const audioRingtone = document.createElement("audio");
  audioRingtone.id = prefix + "audio_ringtone";
  audioRingtone.loop = true;

  const audioRingbacktone = document.createElement("audio");
  audioRingbacktone.id = prefix + "audio_ringbacktone";
  audioRingbacktone.loop = true;

  const audioDtmf = document.createElement("audio");
  audioDtmf.id = prefix + "audio_dtmf";
  audioDtmf.autoplay = true;

  document.body.appendChild(audioRemote);
  document.body.appendChild(audioRingtone);
  document.body.appendChild(audioRingbacktone);
  document.body.appendChild(audioDtmf);

  return {
    remote: audioRemote,
    ringtone: audioRingtone,
    ringbacktone: audioRingbacktone,
    dtmfTone: audioDtmf,
  };
}

type SipStackConfig = {
  realm: string;
  impi: string;
  impu: string;
  password: string;
  display_name: string;
  websocket_proxy_url: string;
  enable_rtcweb_breaker: boolean;
  enable_click2call: boolean;
  events_listener: {
    events: string;
    listener: (e: unknown) => void;
  };
  ice_servers: {
    url: string;
  }[];
  sip_headers: {
    name: string;
    value: string;
    session: boolean;
  }[];
};

type StackEventName =
  | "onStarting"
  | "onStarted"
  | "onStopping"
  | "onStopped"
  | "onFailedToStart"
  | "onFailedToStop"
  | "onNewSessionStarting"
  | "onBrowserPermissionsRequested"
  | "onBrowserPermissionsAccepted"
  | "onBrowserPermissionsRefused";

type SessionEventName =
  | "onSessionRegister"
  | "onSessionRegistered"
  | "onSessionConnecting"
  | "onSessionConnected"
  | "onSessionStarted"
  | "onSessionUnregister"
  | "onSessionUnregistered"
  | "onSessionTerminating"
  | "onSessionTerminated"
  | "onLocalAudioAdded"
  | "onLocalAudioRemoved"
  | "onRemoteAudioAdded"
  | "onRemoteAudioRemoved"
  | "onSessionNewSession"
  | "onSessionResponseReceived"
  | "onSessionEarlyMedia"
  | "onLocalHoldSuccess"
  | "onLocalHoldFailed"
  | "onLocalUnholdSuccess"
  | "onLocalUnholdFailed"
  | "onRemoteHoldStarted"
  | "onRemoteHoldStopped"
  | "onSessionTransferStarted"
  | "onSessionTransferAccepted"
  | "onSessionTransferRequested"
  | "onSessionTransferFailed"
  | "onSessionTransferNotification"
  | "onLocalMuteFailed"
  | "onLocalMuteToggle"
  | "onLocalSilentFailed"
  | "onLocalSilent"
  | "onLocalUnSilent";
