import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { Messages, ResponseOf } from "../../../../core/api";
import ReplyAllRoundedIcon from "../../../../shared/icons/ReplyAllRoundedIcon";
import ReplyRoundedIcon from "../../../../shared/icons/ReplyRoundedIcon";
import { isDefined } from "../../../../utils";
import {
  EmailTicket,
  getQuotedReplyParams,
  isInboundEmailMessage,
} from "../../utils/comm-center-email-utils";
import EmailTicketReply, { EmailReplyDefaultValues, EmailReplyType } from "./EmailTicketReply";
import { fmapu } from "../../../../shared/utils";

interface Props {
  ticket: EmailTicket;
  onSubmitNewEmailMessage?: () => void;
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
}

function EmailActions(props: Props) {
  const [replyType, setReplyType] = React.useState<EmailReplyType | undefined>(undefined);
  const [replyDefaultValues, setReplyDefaultValues] = React.useState<
    Partial<EmailReplyDefaultValues>
  >({});

  const handleClickReply = () => {
    setReplyType("reply");
    const lastMessage = props.ticket.emailThread.messages.at(-1);
    const sender = lastMessage?.sender;
    const cc = lastMessage?.cc;
    setReplyDefaultValues({
      recipients: sender !== undefined ? [sender] : [],
      cc: isDefined(cc) ? cc : [],
      quotedReplyParams: fmapu(lastMessage, getQuotedReplyParams),
    });
  };

  const handleClickReplyAll = () => {
    setReplyType("reply-all");
    const allInboundMessages = props.ticket.emailThread.messages.filter((message) =>
      isInboundEmailMessage(message)
    );
    const allRecipients = [
      ...new Map(
        allInboundMessages.map((message) => [message.sender.email, message.sender])
      ).values(),
    ];
    const allCCRecipients = allInboundMessages.reduce<Messages["CommCenterEmailAddressObject"][]>(
      (acc, message) => {
        if (isDefined(message.cc)) {
          acc.push(...message.cc);
        }
        return acc;
      },
      []
    );
    const allCCRecipientsFromMap = [
      ...new Map(allCCRecipients.map((recipient) => [recipient.email, recipient])).values(),
    ];

    const lastMessage = props.ticket.emailThread.messages.at(-1);
    setReplyDefaultValues({
      recipients: allRecipients,
      cc: allCCRecipientsFromMap,
      quotedReplyParams: fmapu(lastMessage, getQuotedReplyParams),
    });
  };

  return !isDefined(replyType) ? (
    <Flex alignItems="center" gap={5}>
      <Button
        borderColor="gray.500"
        leftIcon={<ReplyRoundedIcon h={6} w={6} />}
        rounded="full"
        size="lg"
        variant="outline"
        onClick={handleClickReply}
      >
        Reply
      </Button>
      <Button
        borderColor="gray.500"
        leftIcon={<ReplyAllRoundedIcon h={6} w={6} />}
        rounded="full"
        size="lg"
        variant="outline"
        onClick={handleClickReplyAll}
      >
        Reply All
      </Button>
    </Flex>
  ) : (
    <>
      <EmailTicketReply
        defaultValues={replyDefaultValues}
        threadId={props.ticket.emailThread.id}
        ticketId={props.ticket.id}
        ticketRelatedTeamId={props.ticket.relatedTeam.id}
        type={replyType}
        onDiscardDraftReply={() => {
          setReplyType(undefined);
          setReplyDefaultValues({});
        }}
        onSubmitNewEmailMessage={() => {
          setReplyType(undefined);
          props.onSubmitNewEmailMessage?.();
        }}
        onSuccessCreateEmailTicket={props.onSuccessCreateEmailTicket}
      />
    </>
  );
}

export default EmailActions;
