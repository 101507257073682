import { LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment";

//! @ngInject
export function agencyProductModalCtrl($scope, $rootScope, $uibModalInstance, toaster, DatabaseApi, SweetAlert) {
    $scope.title = $scope.agency.agencyName + " - " + $scope.product.name;
    $scope.agencyMembersOptions = $scope.agencyMembers.map(am => ({id: am.id, label: am.firstName + " " + am.lastName}));
    $scope.productModel = { ...$scope.product };

    function initAgencyProduct() {
        if ($scope.isNewProduct) {
            $scope.productModel.contacts = [];
        } else {
            // set fields
            if ($scope.product.subscription_start !== null) {
                const d = LocalDate.parse($scope.product.subscription_start);
                $scope.productModel.subscription_start = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
            }

            if ($scope.product.subscription_end !== null) {
                const d = LocalDate.parse($scope.product.subscription_end);
                $scope.productModel.subscription_end = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
            }
        }
    }

    $scope.addAgencyProductContact = () => {
        $scope.productModel.contacts.push({
            isMain: false,
            send_engagement_emails: false
        })
    }

    $scope.updateAgencyProduct = (fieldName, isDateField) => {
        const url = "admin/customer-engagement/agencies/" + $scope.agency.agencyId + "/products/" + $scope.productModel.id;

        let body = {};
        if (isDateField) {
            body[fieldName] = LocalDate.from(nativeJs(moment($scope.productModel[fieldName])))
        } else {
            if(fieldName === 'repId') {
                let repId = null;

                if ($scope.productModel.repName) {
                    repId = $scope.reps.find( (item) =>  item.name === $scope.productModel.repName).id;
                    body[fieldName] = repId;
                } else {
                    body[fieldName] = null;
                }
            } else {
                body[fieldName] = $scope.productModel[fieldName];
            }
        }

        DatabaseApi.patch(url, body).then(function (res) {
            toaster.pop('success', "Agency product updated successfully");
            $scope.product[fieldName] = $scope.productModel[fieldName];
            $scope.closeModal($scope.product);
            // $uibModalInstance.close(success.data);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not updated agency product");
        })
    }

    $scope.createAgencyProductContact = (contact, contactIndex) => {
        if (!contact.agencyMemberId || !contact.role) {
            toaster.pop('warning', 'Please Fill Contact Details');
            return;
        }

        const url = "admin/customer-engagement/agencies/" + $scope.agency.agencyId + "/contacts";

        let body = {
            productId: $scope.productModel.productId,
            agencyMemberId: Number(contact.agencyMemberId),
            role: contact.role,
            isMain: contact.isMain,
            send_engagement_emails: contact.send_engagement_emails
        };

        DatabaseApi.post(url, body).then(function (res) {
            toaster.pop('success', "Agency product contact created successfully");
            $scope.productModel.contacts[contactIndex] = res.data;

        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not create agency product contact");
        })
    }

    $scope.removeAgencyProductContact = (contactId) => {
        const url = "admin/customer-engagement/agencies/" + $scope.agency.agencyId + "/contacts/" + contactId;

        SweetAlert.swal({
            title: "Remove contact",
            text: "Are you sure you want to remove agency product contact? ",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                DatabaseApi.delete(url).then(function (res) {
                    toaster.pop('success', "Agency product contact removed successfully");
                    const contactIndex = $scope.productModel.contacts.findIndex(c => c.id = contactId);
                    if (contactIndex > -1) {
                        $scope.productModel.contacts.splice(contactIndex, 1);
                    }
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not remove agency product contact");
                })
            }
        });
    }

    $scope.submitForm = function () {
        const url = "admin/customer-engagement/agencies/" + $scope.agency.agencyId + "/products";
        if ($scope.form.$invalid) {
            return;
        }

        const subscriptionStartDate = LocalDate.from(nativeJs(moment($scope.productModel.subscription_start)));
        const subscriptionEndDate = $scope.productModel.subscription_end ?
            LocalDate.from(nativeJs(moment($scope.productModel.subscription_end))) : null


        let repId = null;

        if ($scope.repName.val) {
            repId = $scope.reps.find( (item) =>  item.name === $scope.repName.val).id;
        }
        const body = {
            productId: $scope.productModel.id,
            subscription_start: subscriptionStartDate,
            subscription_end: subscriptionEndDate,
            repId: repId || null
        }

        DatabaseApi.post(url, body).then(function (res) {
            toaster.pop('success', "Agency product created successfully");
            $scope.closeModal(res.data);
            // $uibModalInstance.close(success.data);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not create agency product");
        })
    }

    $scope.closeModal = function (data) {
        $uibModalInstance.close(data);
    };

    initAgencyProduct();
};

