import { ResponseOf } from "./../../../core/api/api-utils";
import { Messages } from "../../../core/api";
import { DefaultSmsMessageFields } from "./sms-sender.types";
import { smsCommCenterLabelCodesByTeam } from "./sms-sender.consts";

export const replaceMessageTemplatePlaceholders = (
  messageTemplate: string,
  values: { firstName: string; agencyMember: Messages["AgencyMember"] | undefined }
) => {
  return messageTemplate
    .replace(DefaultSmsMessageFields.FIRST_NAME, values.firstName)
    .replace(DefaultSmsMessageFields.AGENCY_MEMBER, values.agencyMember?.firstName ?? "");
};

export type ReduceLabelsForSmsResultType = {
  [K in keyof typeof smsCommCenterLabelCodesByTeam]?: ResponseOf<
    "get",
    "./comm_center/labels"
  >["labels"][0];
};
export const reduceCommCenterLabelsByTeamForSms = (
  labels: ResponseOf<"get", "./comm_center/labels">["labels"]
) => {
  return labels.reduce<ReduceLabelsForSmsResultType>((acc, obj) => {
    for (const [key, value] of Object.entries(smsCommCenterLabelCodesByTeam)) {
      if (obj.code === value) {
        acc[key as keyof typeof smsCommCenterLabelCodesByTeam] = obj;
      }
    }
    return acc;
  }, {});
};
