import { Badge, Center } from "@chakra-ui/react";
import { ReactNode } from "react";

const CallTicketIndexBadge = ({ children }: { children?: ReactNode }) => {
  return (
    <Badge
      alignItems="center"
      as="div"
      borderColor="black"
      borderWidth={1}
      boxSize={{ "2xl": 10, base: 9 }}
      display="flex"
      fontSize={{ "2xl": "lg", base: "sm" }}
      fontWeight="medium"
      justifyContent="center"
      rounded="full"
    >
      <Center>{children}</Center>
    </Badge>
  );
};

export default CallTicketIndexBadge;
