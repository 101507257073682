import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import RefreshRoundedIcon from "../../../../../shared/icons/RefreshRoundedIcon";
import { VisitAssignmentId } from "../../../../../shared/schema/schema";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { VisitAssignmentTicketAddNoteButton } from "./VisitAssignmentTicketAddNoteButton";
import { VisitAssignmentTicketAssigneeSelect } from "./VisitAssignmentTicketAssigneeSelect";
import { VisitAssignmentTicketMarkAsUnreadButton } from "./VisitAssignmentTicketMarkAsUnreadButton";
import { VisitAssignmentTicketRelationIndication } from "./VisitAssignmentTicketRelationIndication";
import { VisitAssignmentTicketStatusSelect } from "./VisitAssignmentTicketStatusSelect";
import { VisitAssignmentTicketTeamSelect } from "./VisitAssignmentTicketTeamSelect";

export function VisitAssignmentTicketActions(props: {
  ticket: CommCenterTicket;
  isFetching: boolean;
  assignmentId: VisitAssignmentId;
  onRefresh: () => void;
  onCloseTicket: () => void;
}) {
  return (
    <Flex
      align="center"
      borderBottomColor="gray.200"
      borderBottomWidth={1}
      className="thin-scrollbar"
      flexShrink={0}
      h={14}
      justify="between"
      overflowX="auto"
      overflowY="hidden"
      px={3}
      w="full"
    >
      <Flex align="center" flex={1}>
        <VisitAssignmentTicketTeamSelect ticket={props.ticket} />

        <Box bg="gray.200" h={5} mx={3} transform="skew(-15deg)" w="1px" />

        <VisitAssignmentTicketAssigneeSelect ticket={props.ticket} />

        <Box bg="gray.200" h={5} mx={2} w="1px" />

        <VisitAssignmentTicketStatusSelect ticket={props.ticket} />
      </Flex>

      <Flex align="center">
        <VisitAssignmentTicketRelationIndication
          assignmentId={props.assignmentId}
          ticket={props.ticket}
        />

        <Box bg="gray.200" h={5} mx={2} w="1px" />

        <VisitAssignmentTicketMarkAsUnreadButton
          assignmentId={props.assignmentId}
          ticket={props.ticket}
          onSuccess={props.onCloseTicket}
        />

        <VisitAssignmentTicketAddNoteButton ticket={props.ticket} />

        <Box bg="gray.200" h={5} mx={2} w="1px" />

        <Tooltip label="Open in new tab">
          <IconButton
            aria-label="open external"
            as="a"
            href={`/app/commcenter/${props.ticket.id}`}
            icon={<ExternalLinkIcon fontSize={18} />}
            target="blank"
            variant="ghost"
          />
        </Tooltip>

        <Tooltip label="Refresh">
          <IconButton
            aria-label="refresh"
            icon={<RefreshRoundedIcon fontSize={18} />}
            isLoading={props.isFetching}
            variant="ghost"
            onClick={props.onRefresh}
          />
        </Tooltip>

        <Box bg="gray.200" h={5} mx={2} w="1px" />

        <Tooltip label="Close">
          <IconButton
            aria-label="close"
            icon={<CloseIcon fontSize="sm" />}
            variant="ghost"
            onClick={props.onCloseTicket}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
}
