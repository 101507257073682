import { AddIcon, CloseIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { useQueryParams } from "../../../shared/hooks/useQueryParams";
import { useSelection } from "../../../shared/hooks/useSelection";
import DotsVerticalIcon from "../../../shared/icons/DotsVerticalIcon";
import ExportNotesIcon from "../../../shared/icons/ExportNotesIcon";
import FaxIcon from "../../../shared/icons/FaxIcon";
import { CaregiverId, NoteSubjectId, PatientId } from "../../../shared/schema/schema";
import { useExportEntityNotesMutation } from "../hooks/useExportEntityNotesMutation";
import CaregiverNotesBox from "./CaregiverNotesBox";
import EmailNoteModal from "./EmailNoteModal";
import EntityNoteEntityCard from "./EntityNoteEntityCard";
import FaxNoteModal from "./FaxNoteModal";
import NewNoteModal from "./NewNoteModal";
import NoteSubjectSelect from "./NoteSubjectSelect";
import NoteTypeSelect from "./NoteTypeSelect";
import PatientNotesBox from "./PatientNotesBox";
import { useQueryClient } from "@tanstack/react-query";

export type EntityNoteFilters = {
  subject?: NoteSubjectId;
  type?: Messages["NoteTypes"];
};

export type WithName<T> = T & { name: string };

export type NoteEntity =
  | {
      type: "Patient" | "PatientCalendar";
      id: PatientId;
    }
  | {
      type: "Caregiver" | "CaregiverCalendar";
      id: CaregiverId;
    };

type Props = {
  entity: WithName<NoteEntity>;
  onClose?: () => void;
};

function useBasicNoteModalProps() {
  const [notes, setNotes] = React.useState<Messages["EntityNote"][]>([]);
  const disclosure = useDisclosure();

  const open = (note: Messages["EntityNote"] | Messages["EntityNote"][]) => {
    setNotes(Array.isArray(note) ? note : [note]);
    disclosure.onOpen();
  };

  return { disclosure, open, notes };
}

export default function EntityNotes({ entity, onClose }: Props) {
  const queryClient = useQueryClient();
  const filters = useQueryParams<EntityNoteFilters>();
  const selection = useSelection<Messages["EntityNote"]>();
  const newNoteModalDisclosure = useDisclosure();
  const emailModal = useBasicNoteModalProps();
  const faxModal = useBasicNoteModalProps();
  const exportNotesMutation = useExportEntityNotesMutation({ entity });

  const { queries } = useApi();

  const handleSuccessNewNote = () => {
    switch (entity.type) {
      case "Patient":
      case "PatientCalendar":
        return queryClient.invalidateQueries(queries.note.patientNotes(entity.id));
      case "CaregiverCalendar":
      case "Caregiver":
        return queryClient.invalidateQueries(queries.note.caregiverNotes(entity.id));
    }
  };

  const newNoteInitialValues = (() => {
    switch (entity.type) {
      case "Patient":
      case "PatientCalendar":
        return { patientId: entity.id };
      case "Caregiver":
      case "CaregiverCalendar":
        return { caregiverId: entity.id };
    }
  })();

  return (
    <Flex direction="column">
      <Flex
        bg="white"
        borderBottom="1px"
        borderColor="gray.200"
        direction="column"
        position="sticky"
        top={0}
        zIndex={2}
      >
        <Flex align="center" bg="gray.50" justify="space-between" p={5}>
          <Heading fontWeight="semibold" size="md">
            {entity.type} Notes
          </Heading>
          <IconButton aria-label="Close" icon={<CloseIcon />} onClick={onClose} />
        </Flex>

        <Box borderBottom="1px" borderColor="gray.200" p={4}>
          <EntityNoteEntityCard entity={entity} />
        </Box>

        <Flex align="center" gap={4} justify="space-between" p={4}>
          <Flex align="center" gap={2} hidden={selection.state.length > 0}>
            <NoteSubjectSelect
              value={filters.getValue("subject") ?? null}
              onChange={(x) => filters.setValue("subject", x)}
            />

            <NoteTypeSelect
              value={filters.getValue("type") ?? null}
              onChange={(x) => filters.setValue("type", x)}
            />
          </Flex>

          <Flex gap={2} justify="flex-end" ms="auto">
            <Menu>
              {selection.state.length > 0 && (
                <MenuButton
                  aria-label="Options"
                  as={Button}
                  isLoading={exportNotesMutation.isPending}
                  leftIcon={<DotsVerticalIcon />}
                  variant="outline"
                >
                  Actions ({selection.state.length})
                </MenuButton>
              )}
              <MenuList>
                <MenuItem
                  icon={<EmailIcon fontSize="lg" />}
                  onClick={() => emailModal.open(selection.state)}
                >
                  Email selected
                </MenuItem>
                <MenuItem
                  icon={<FaxIcon fontSize="lg" />}
                  onClick={() => faxModal.open(selection.state)}
                >
                  Fax selected
                </MenuItem>
                <MenuItem
                  icon={<ExportNotesIcon fontSize="lg" />}
                  onClick={() =>
                    exportNotesMutation.mutate({ noteIds: selection.state.map((x) => x.id) })
                  }
                >
                  Export selected
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={selection.clear}>Clear selection</MenuItem>
              </MenuList>
            </Menu>

            <Button
              colorScheme="blue"
              leftIcon={<AddIcon />}
              onClick={newNoteModalDisclosure.onOpen}
            >
              New note
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Box m={4}>
        {(() => {
          switch (entity.type) {
            case "Patient":
            case "PatientCalendar":
              return (
                <PatientNotesBox
                  filters={filters}
                  patientId={entity.id}
                  selection={selection}
                  onClickEmail={emailModal.open}
                  onClickFax={faxModal.open}
                />
              );
            case "Caregiver":
            case "CaregiverCalendar":
              return (
                <CaregiverNotesBox
                  caregiverId={entity.id}
                  filters={filters}
                  selection={selection}
                  onClickEmail={emailModal.open}
                  onClickFax={faxModal.open}
                />
              );
          }
        })()}
      </Box>

      <NewNoteModal
        disclosure={newNoteModalDisclosure}
        initialValues={newNoteInitialValues}
        onSuccess={handleSuccessNewNote}
      />
      <EmailNoteModal {...emailModal} entity={entity} />
      <FaxNoteModal {...faxModal} entity={entity} />
    </Flex>
  );
}
