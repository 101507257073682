import { Divider, Flex, Heading } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { Messages, ResponseOf } from "../../../core/api";
import { NoteId, PatientId } from "../../../shared/schema/schema";
import {
  EditPatientIntake,
  PatientIntakeContact,
  PatientIntakePlan,
  PatientIntakeProfileDraft,
} from "../patient-intake.types";
import IntakePatientPortalDocuments from "./IntakePatientPortalDocuments";
import IntakePatientProfileNotesSection from "./IntakePatientProfileNotesSection";
import IntakeProcessSummarySection from "./IntakeProcessSummarySection";
import IntakeProfileContactInfoSection from "./IntakeProfileContactInfoSection";
import IntakeProfileMedInfoSection from "./IntakeProfileMedInfoSection";
import IntakeProfileNYIAInfo from "./IntakeProfileNYIAInfo";
import IntakeProfilePersonalInfoSection from "./IntakeProfilePersonalInfoSection";
import IntakeProfilePlansInfo from "./IntakeProfilePlansInfo";
import PatientCommunicationLog from "../../communication/components/communication-log/PatientCommunicationLog";

interface Props {
  intakePlans: Messages["IntegrationPayer"][];
  patientIntakeProfile: PatientIntakeProfileDraft;
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  communicationLog: ResponseOf<"get", "./comm_center/tickets/patients/:patientId">;
  portalDocuments: ResponseOf<"get", "./patients/:patientId/documents">["documents"];
  intakeTracks: ResponseOf<"get", "./patient_intake/intake_tracks">["tracks"];
  isEditMode: boolean;
  onChangeNewContactDetails: (isEdited: boolean) => void;
  onClickMarkNoteAsDone: (noteId: NoteId) => void;
  onClickAddNewNote: (patientId: PatientId) => void;
  onEditPatientIntakeProfileField: (field: keyof EditPatientIntake, value: any) => void;
  onEditPatientContact: (key: string, newContact: PatientIntakeContact) => void;
  onClickRemovePatientContact: (key: string) => void;
  onClickAddPatientContact: (newContact: PatientIntakeContact) => void;
  onClickRemovePlan: (key: string) => void;
  onEditPatientPlan: (key: string, newPlanInfo: PatientIntakePlan) => void;
  onSaveNewPlan: (newPlanInfo: PatientIntakePlan) => void;
  onValidateNewContactPhoneNumber: (value: PatientIntakeContact) => boolean;
  onEditPatientIntakeAddress: (
    address: string | null,
    addressComponents: Messages["PatientAddressComponents"] | null
  ) => void;
}

const IntakePatientProfileBody = (props: Props) => {
  const handlePatientPhoneNumberChange = (value: string) => {
    if (!isEmpty(value)) {
      props.onEditPatientIntakeProfileField("phoneNumbers", [
        {
          ...props.patientIntakeProfile.phoneNumbers[0],
          phonenumber: value,
          type: "MOBILE",
          label: null,
          description: null,
          patientId: props.patientIntakeProfile.id,
        },
      ]);
    } else {
      props.onEditPatientIntakeProfileField("phoneNumbers", []);
    }
  };

  const handleChangeMainPhoneNumber = (value: string) => {
    if (value === props.patientIntakeProfile.mainPhoneNumber) {
      props.onEditPatientIntakeProfileField("mainPhoneNumber", null);
    } else {
      props.onEditPatientIntakeProfileField("mainPhoneNumber", value);
    }
  };

  const patientPhoneNumber =
    props.patientIntakeProfile.phoneNumbers.length > 0
      ? props.patientIntakeProfile.phoneNumbers[0].phonenumber
      : "";
  const phonenumberSmsable = props.patientIntakeProfile.phoneNumbers.at(0)?.isSmsable ?? false;

  return (
    <Flex direction="column" gap={4} marginBottom="50px">
      <IntakeProfilePersonalInfoSection
        intakeStatuses={props.intakeStatuses}
        intakeTracks={props.intakeTracks}
        isEditMode={props.isEditMode}
        patientInfo={props.patientIntakeProfile}
        onChangeAddress={props.onEditPatientIntakeAddress}
        onChangePatientInfoField={props.onEditPatientIntakeProfileField}
      />
      <Divider my={8} variant="solid"></Divider>
      <IntakeProcessSummarySection
        patientId={props.patientIntakeProfile.id}
        status={props.patientIntakeProfile.patientStatus}
      />

      <Divider my={8} variant="solid"></Divider>
      <IntakeProfileMedInfoSection
        isEditMode={props.isEditMode}
        medicaidId={props.patientIntakeProfile.medicaidId}
        medicaidStatus={props.patientIntakeProfile.medicaidStatus}
        medicareId={props.patientIntakeProfile.medicareId}
        patientId={props.patientIntakeProfile.id}
        onChangeIds={props.onEditPatientIntakeProfileField}
      />

      <Divider my={8} variant="solid"></Divider>
      <IntakeProfileContactInfoSection
        isEditMode={props.isEditMode}
        mainPhoneNumber={props.patientIntakeProfile.mainPhoneNumber}
        patientContacts={props.patientIntakeProfile.contacts}
        patientEmail={props.patientIntakeProfile.email ?? ""}
        patientId={props.patientIntakeProfile.id}
        patientPhoneNumber={patientPhoneNumber}
        phonenumberSmsable={phonenumberSmsable}
        onChangeNewContactDetails={props.onChangeNewContactDetails}
        onChangePatientContact={props.onEditPatientContact}
        onChangePatientEmail={(value) => props.onEditPatientIntakeProfileField("email", value)}
        onChangePatientPhoneNumber={handlePatientPhoneNumberChange}
        onClickRemovePatientContact={props.onClickRemovePatientContact}
        onClickSaveChanges={props.onClickAddPatientContact}
        onToggleMainNumber={handleChangeMainPhoneNumber}
        onValidateNewContactPhoneNumber={props.onValidateNewContactPhoneNumber}
      />

      <Divider my={8} variant="solid"></Divider>
      <IntakePatientProfileNotesSection
        notes={props.patientIntakeProfile.notes}
        onClickMarkAsDone={props.onClickMarkNoteAsDone}
        onClickNewNote={() => props.onClickAddNewNote(props.patientIntakeProfile.id)}
      />

      <Divider my={8} variant="solid"></Divider>
      <IntakeProfilePlansInfo
        essenAssessmentDate={props.patientIntakeProfile.essenAssessmentDate}
        essenAssessmentStatus={props.patientIntakeProfile.essenAssessmentCompleted}
        isEditMode={props.isEditMode}
        patientPlans={props.patientIntakeProfile.plans}
        plans={props.intakePlans}
        UASStatus={props.patientIntakeProfile.UASCompleted}
        onChangeField={props.onEditPatientIntakeProfileField}
        onClickRemovePlan={props.onClickRemovePlan}
        onEditPatientPlan={props.onEditPatientPlan}
        onSaveNewPlan={props.onSaveNewPlan}
      />
      <Divider my={8} variant="solid"></Divider>
      <IntakeProfileNYIAInfo
        isEditMode={props.isEditMode}
        NYIA1Clinician={props.patientIntakeProfile.NYIA1Clinician}
        NYIA1Date={props.patientIntakeProfile.NYIA1AppoitmentDate}
        NYIA1Location={props.patientIntakeProfile.NYIA1Location}
        NYIA1Status={props.patientIntakeProfile.NYIA1Completed}
        NYIA2Clinician={props.patientIntakeProfile.NYIA2Clinician}
        NYIA2Date={props.patientIntakeProfile.NYIA2AppoitmentDate}
        NYIA2Location={props.patientIntakeProfile.NYIA2Location}
        NYIA2Status={props.patientIntakeProfile.NYIA2Completed}
        NYIAResults={props.patientIntakeProfile.NYIAResults}
        onChangeField={props.onEditPatientIntakeProfileField}
      />

      <Divider my={8} variant="solid"></Divider>
      <IntakePatientPortalDocuments portalDocuments={props.portalDocuments} />

      <Divider my={8} variant="solid"></Divider>
      <Flex direction="column" gap={4}>
        <Heading as="h2" size="md">
          Communication Log
        </Heading>
        <PatientCommunicationLog patientId={props.patientIntakeProfile.id} />
      </Flex>
    </Flex>
  );
};

export default IntakePatientProfileBody;
