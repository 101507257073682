import { Badge, Flex, FormControl, FormLabel, Input, Tooltip } from "@chakra-ui/react";
import { Instant, LocalDate, LocalDateTime } from "@js-joda/core";
import { Messages, ResponseOf } from "../../../core/api";
import IosInformationCircleIcon from "../../../shared/icons/IosInformationCircleIcon";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { isDefined } from "../../../utils";
import { EditPatientIntake, PatientIntakeProfileDraft } from "../patient-intake.types";
import { referralSourceOptions } from "../shared/intake.consts";
import { AssignedAgencyMember } from "./AssignedAgencyMember";
import IntakePatientProfileDatepicker from "./IntakePatientProfileDatepicker";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";
import IntakePatientProfileInput from "./IntakePatientProfileInput";
import IntakePatientProfileStatusAndTrackPicker from "./IntakePatientProfileStatusAndTrackPicker";
import IntakeProfileAddressInput from "./IntakeProfileAddressInput";

const patientGenderOptions = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  },
];

interface Props {
  isEditMode: boolean;
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  intakeTracks: ResponseOf<"get", "./patient_intake/intake_tracks">["tracks"];
  patientInfo: PatientIntakeProfileDraft;
  onChangePatientInfoField: (
    field: keyof EditPatientIntake,
    value:
      | string
      | LocalDate
      | LocalDateTime
      | null
      | Messages["IntakeStatus"]
      | Messages["PatientIntakeTrack"]
      | Messages["IntakePatientProfile"]["referralSource"]
      | Messages["IntakePatientProfile"]["assignedAgencyMember"]
  ) => void;
  onChangeAddress: (
    address: string | null,
    addressComponents:
      | Messages["PatientAddressComponents"]
      | Messages["PatientContactAddressComponents"]
      | null
  ) => void;
  addressValidationSchema?: Zod.Schema;
}

const IntakeProfilePersonalInfoSection = (props: Props) => {
  const shouldPresentBurstBadge =
    isDefined(props.patientInfo.nextCallDetails) &&
    isDefined(props.patientInfo.nextCallDetails.burstNum) &&
    isDefined(props.patientInfo.nextCallDetails.burstPrevCall);

  return (
    <Flex direction="column" gap={4}>
      <Flex direction="row" gap={4} hidden={!props.isEditMode}>
        <FormControl isRequired>
          <FormLabel>First Name</FormLabel>
          <Input
            autoComplete="new-password"
            value={props.patientInfo.firstName}
            onChange={(val) => props.onChangePatientInfoField("firstName", val.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Middle Name</FormLabel>
          <Input
            autoComplete="new-password"
            value={props.patientInfo.middleName ?? ""}
            onChange={(val) => props.onChangePatientInfoField("middleName", val.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Last Name</FormLabel>
          <Input
            autoComplete="new-password"
            value={props.patientInfo.lastName}
            onChange={(val) => props.onChangePatientInfoField("lastName", val.target.value)}
          />
        </FormControl>
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          label="DOB"
          pickerYearsRange={[1910, LocalDate.now().year()]}
          value={props.patientInfo.dateOfBirth}
          onChange={(value) => props.onChangePatientInfoField("dateOfBirth", value)}
        />
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          label="Last Call"
          value={props.patientInfo.lastCallDate}
          withTime={true}
          onChange={(value) => props.onChangePatientInfoField("lastCallDate", value)}
        />
        <IntakePatientProfileDatepicker
          headerInfoTooltip={
            props.patientInfo.nextCallDetails === null ? null : (
              <NextCallMissedCallTooltip
                boost={props.patientInfo.nextCallDetails.boost}
                createdAt={props.patientInfo.nextCallDetails.createdAt}
                createdBy={props.patientInfo.nextCallDetails.createdByName}
              />
            )
          }
          inputRightIndication={
            shouldPresentBurstBadge ? (
              <Badge
                borderRadius={5}
                colorScheme="gray"
                h={10}
                paddingTop={3}
                textAlign="center"
                w={10}
              >{`${props.patientInfo.nextCallDetails?.burstNum}/3`}</Badge>
            ) : null
          }
          isEditable={props.isEditMode}
          isRequired={true}
          label="Next Call"
          value={props.patientInfo.nextCallDate}
          withTime={true}
          onChange={(value) => props.onChangePatientInfoField("nextCallDate", value)}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDropdown
          currValue={{
            value: props.patientInfo.gender,
            label: props.patientInfo.gender === "F" ? "Female" : "Male" ?? "Not chosen",
          }}
          isEditable={props.isEditMode}
          isRequired={true}
          label="Gender"
          options={patientGenderOptions}
          onChange={(value) => props.onChangePatientInfoField("gender", value)}
        />
        <FormControl>
          <FormLabel> Created Date </FormLabel>
          <Input
            isDisabled={true}
            value={dateFormatter.toDateTime(props.patientInfo.createdAt, {
              timezone: "America/New_York",
            })}
          />
        </FormControl>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          label="Discharge Date"
          value={props.patientInfo.dischargeDate}
          withTime={false}
          onChange={(value) => props.onChangePatientInfoField("dischargeDate", value)}
        />
      </Flex>
      <Flex direction="row">
        <IntakeProfileAddressInput
          isEdit={props.isEditMode}
          label="Address"
          value={props.patientInfo.address}
          onChange={props.onChangeAddress}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          label="Address Line 2"
          value={props.patientInfo.address2 ?? ""}
          onChange={(value) => props.onChangePatientInfoField("address2", value)}
        />
        <AssignedAgencyMember
          assignedAgencyMember={props.patientInfo.assignedAgencyMember}
          isEdit={props.isEditMode}
          onChange={(value) => props.onChangePatientInfoField("assignedAgencyMember", value)}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          label="Address Instructions"
          value={props.patientInfo.addressInstructions ?? ""}
          onChange={(value) => props.onChangePatientInfoField("addressInstructions", value)}
        />
        <IntakePatientProfileDropdown
          currValue={{
            value: props.patientInfo.referralSource,
            label: props.patientInfo.referralSource ?? "Unknown",
          }}
          isEditable={props.isEditMode}
          isRequired={false}
          label="Referral Source"
          options={referralSourceOptions}
          onChange={(value) => props.onChangePatientInfoField("referralSource", value)}
        />
      </Flex>
      <IntakePatientProfileStatusAndTrackPicker
        currentTrack={props.patientInfo.track}
        intakeTracks={props.intakeTracks}
        statusSelectProps={{
          currValue: props.patientInfo.intakeStatus,
          isEditable: props.isEditMode,
          isRequired: props.isEditMode,
          label: "Intake Status",
          options: props.intakeStatuses,
          onChange: (value) => props.onChangePatientInfoField("intakeStatus", value),
        }}
        trackSelectProps={{
          currValue: props.patientInfo.track,
          isEditable: props.isEditMode,
          isRequired: props.isEditMode,
          label: "Intake Track",
          options: props.intakeTracks,
          onChange: (value) => props.onChangePatientInfoField("track", value),
        }}
      />
    </Flex>
  );
};

export default IntakeProfilePersonalInfoSection;

const NextCallMissedCallTooltip = (props: {
  createdBy: string;
  createdAt: Instant;
  boost: Messages["IntakeNextCall"]["boost"];
}) => {
  const generateTooltipScheduleByText = () => {
    const createdAt = dateFormatter.toDateTime(props.createdAt, {});
    return `Scheduled by ${props.createdBy} at ${createdAt}`;
  };

  const generateTooltipText = () => {
    const scheduleSnippet = generateTooltipScheduleByText();
    const boostedDescription = props.boost?.description ?? null;

    return (
      <>
        {scheduleSnippet}
        {boostedDescription && (
          <>
            <br />
            {boostedDescription}
          </>
        )}
      </>
    );
  };

  const isScheduledDueToTrigger = props.boost !== null;

  return (
    <Tooltip
      hasArrow
      bgColor="white"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      color="black"
      label={generateTooltipText()}
      padding={5}
      placement="top"
    >
      <IosInformationCircleIcon
        boxSize={5}
        color={isScheduledDueToTrigger ? "red.500" : "gray.500"}
      />
    </Tooltip>
  );
};
