import { CloseIcon } from "@chakra-ui/icons";
import { Badge, Flex, IconButton, Input } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import Select from "../../../../../shared/components/Select";
import { IntakeTrackStepFieldValueId } from "../../../../../shared/schema/schema";
import { WithKey, IntakeStepFormOptionsOption } from "../flow-settings.types";

export default function FieldItemOption(props: {
  steps: Messages["IntakeTrackStepForFullFlow"][];
  value: WithKey<IntakeStepFormOptionsOption>;
  allStepFieldValuesIds: Set<IntakeTrackStepFieldValueId>;
  onChangeField: <K extends keyof WithKey<IntakeStepFormOptionsOption>>(
    field: K,
    value: WithKey<IntakeStepFormOptionsOption>[K]
  ) => void;
  onClickRemove: () => void;
}) {
  const fieldValueIdExists =
    props.value.id !== null && props.allStepFieldValuesIds.has(props.value.id);
  return (
    <Flex gap={2}>
      <IconButton
        aria-label="remove"
        icon={<CloseIcon h={3} />}
        size="md"
        variant="outline"
        onClick={props.onClickRemove}
      />
      <Badge colorScheme={fieldValueIdExists ? "red" : "green"} p={4}>
        {fieldValueIdExists ? "edit" : "new"}
      </Badge>
      <Input
        bg="white"
        placeholder="ID"
        value={props.value.id ?? ""}
        w={48}
        onChange={(e) =>
          props.onChangeField("id", IntakeTrackStepFieldValueId.parse(parseInt(e.target.value)))
        }
      />
      <Input
        bg="white"
        placeholder="Icon"
        value={props.value.icon ?? ""}
        w={48}
        onChange={(e) => props.onChangeField("icon", e.target.value)}
      />
      <Input
        bg="white"
        placeholder="order"
        value={props.value.orderInField ?? ""}
        w={48}
        onChange={(e) =>
          props.onChangeField(
            "orderInField",
            e.target.value === "" ? null : parseInt(e.target.value)
          )
        }
      />
      <Input
        bg="white"
        placeholder="Value"
        value={props.value.value ?? ""}
        onChange={(e) => props.onChangeField("value", e.target.value)}
      />
      <Select
        buttonProps={{ flexShrink: 0 }}
        label="Select next step"
        multiple={false}
        options={props.steps.map((step) => ({
          value: step.id,
          label: `${step.title} (${step.track.label}) (${step.id})`,
        }))}
        value={props.value.nextIntakeTrackStepId}
        width="fit-content"
        onChange={(value) => {
          if (value !== undefined) {
            return props.onChangeField("nextIntakeTrackStepId", value);
          }
        }}
      />
    </Flex>
  );
}
