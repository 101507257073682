import { IconButton, Tooltip } from "@chakra-ui/react";
import MarkEmailUnreadOutlineIcon from "../../../../../shared/icons/MarkEmailUnreadOutlineIcon";
import { VisitAssignmentId } from "../../../../../shared/schema/schema";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { useMarkCommCenterTicketAsUnread } from "../../../../communication/hooks/useMarkCommCenterTicketAsUnread";

export function VisitAssignmentTicketMarkAsUnreadButton(props: {
  ticket: CommCenterTicket;
  assignmentId: VisitAssignmentId;
  onSuccess: () => void;
}) {
  const { mutate: markAsUnread } = useMarkCommCenterTicketAsUnread({
    ticketId: props.ticket.id,
    assignmentId: props.assignmentId,
  });

  switch (props.ticket.source) {
    case "EMAIL":
    case "PHONE_CALL":
    case "SMS_CONVERSATION":
    case "SYSTEM_TRIGGER":
      return null;
    case "MANUAL":
    case "MOBILE_CHAT":
      return (
        <Tooltip label="Mark as unread">
          <IconButton
            aria-label="mark as unread"
            icon={<MarkEmailUnreadOutlineIcon fontSize={18} />}
            variant="ghost"
            onClick={() => {
              markAsUnread(props.ticket.id);
              props.onSuccess();
            }}
          />
        </Tooltip>
      );
  }
}
