import { FlexProps, useToast } from "@chakra-ui/react";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import {
  patientRegularMessageTemplateOptions,
  portalLinkMessageTemplateOptions,
} from "../../../shared/components/SmsSender/sms-sender.consts";
import {
  PatientSmsSenderProps,
  PhoneNumber,
  PortalLink,
  RegularMessage,
  SendPortalLinkSmsBody,
  SendSmsBody,
} from "../../../shared/components/SmsSender/sms-sender.types";
import {
  reduceCommCenterLabelsByTeamForSms,
  ReduceLabelsForSmsResultType,
} from "../../../shared/components/SmsSender/sms-sender.utils";
import SmsSender from "../../../shared/components/SmsSender/SmsSender";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { NewTicketRequestBody } from "../../communication/utils/communication-utils";

const patientMessageTemplateOptions: (RegularMessage | PortalLink)[] = [
  ...Object.values(portalLinkMessageTemplateOptions),
  ...Object.values(patientRegularMessageTemplateOptions),
].sort((a, b) => a.label.localeCompare(b.label));

const getSmsLabelByPatientStatus = (
  patientStatus: Messages["PatientStatus"],
  smsLabels: ReduceLabelsForSmsResultType
) => {
  switch (patientStatus) {
    case "REFERRAL":
    case "PENDING_FILES":
    case "ELIGIBLE":
    case "ACCEPTED":
    case "ACTIVE":
    case "HOSPITALIZED":
      return smsLabels.Staffing;
    default:
      return smsLabels.Intake;
  }
};

type Props = Omit<PatientSmsSenderProps, "entity"> & {
  flexProps?: FlexProps;
  onClose: () => void;
};

function PatientSmsSender(props: Props) {
  const { api, queries } = useApi();
  const toast = useToast();

  const patientQuery = useQuery({
    ...queries.patient.get(props.patientId),
    select: (patient) => {
      const patientName = `${patient.firstName} ${patient.lastName}`;
      const patientPhoneNumbers: PhoneNumber[] = patient.phoneNumbers
        .filter((x) => x.isSmsable)
        .map((x) => ({
          label: `${patientName} [ ${phoneFormatter.formatNationalIfValid(x.phonenumber)} ]`,
          value: x.phonenumber,
        }));
      const contactPhonenumber: PhoneNumber[] =
        patient.contacts !== null
          ? patient.contacts
              .filter((x) => x.mobilePhoneNumberSmsable)
              .map((x) => {
                const contactName = `${x.firstName} ${x.lastName}`;
                return {
                  label: `${contactName} [ ${phoneFormatter.formatNationalIfValid(
                    x.mobilePhoneNumber
                  )} ]`,
                  value: x.mobilePhoneNumber,
                };
              })
          : [];
      return {
        firstName: patient.firstName,
        phoneNumbers: [...patientPhoneNumbers, ...contactPhonenumber],
        status: patient.status,
      };
    },
  });

  const smsTicketLabels = useQuery({
    queryKey: [queryKeys.commCenter.labels(), "SMS"],
    queryFn: () => api.get("./comm_center/labels", {}),
    placeholderData: keepPreviousData,
    select: (response) => reduceCommCenterLabelsByTeamForSms(response.labels),
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: Pick<NewTicketRequestBody, "phoneNumber" | "message">) => {
      if (smsTicketLabels.data === undefined || patientQuery.data === undefined) {
        toast({
          title: "Could not create new ticket",
          description: "SMS labels not found OR patient not found",
          status: "error",
          position: "top-right",
        });
        throw new Error("SMS label not found");
      }
      const relevantSmsLabel = getSmsLabelByPatientStatus(
        patientQuery.data.status,
        smsTicketLabels.data
      );
      if (relevantSmsLabel === undefined) {
        toast({
          title: "Could not create new ticket",
          description: "SMS labels not found",
          status: "error",
          position: "top-right",
        });
        throw new Error("SMS label not found");
      }

      return api.post("./comm_center/tickets", {
        body: {
          ...newTicketRequest,
          message: newTicketRequest.message,
          phoneNumber: newTicketRequest.phoneNumber,
          patientId: props.patientId,
          caregiverId: null,
          labelId: relevantSmsLabel.id,
          topic: "Patient",
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "SMS sent successfully",
        status: "success",
        position: "top-right",
      });
      props.onClose();
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const sendPortalLinkSms = useMutation({
    mutationFn: (sendPortalLinkSmsBody: SendPortalLinkSmsBody) => {
      return api.post(
        "/portal/agencies/:agencyId/agency_members/:agencyMemberId/redirect_url/send",
        {
          body: {
            ...sendPortalLinkSmsBody,
            patientId: props.patientId,
          },
        }
      );
    },
    onSuccess: () => {
      toast({
        title: "Portal link SMS sent successfully",
        status: "success",
        position: "top-right",
      });
      props.onClose();
    },
    onError: (error) => {
      toast({
        title: "Could not send portal link SMS",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleSend = (body: SendSmsBody | SendPortalLinkSmsBody) => {
    return "redirectUrl" in body ? sendPortalLinkSms.mutate(body) : createTicket.mutate(body);
  };

  return (
    <SmsSender
      entityQuery={patientQuery}
      flexProps={props.flexProps}
      isSending={createTicket.isPending || sendPortalLinkSms.isPending}
      messageTemplates={patientMessageTemplateOptions}
      predefinedMessageTemplate={props.predefinedMessageTemplate}
      predefinedPhoneNumber={props.predefinedPhoneNumber}
      onClose={props.onClose}
      onSend={handleSend}
    />
  );
}

export default PatientSmsSender;
