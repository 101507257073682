import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import React from "react";
import { env } from "../../../../../env";
import useGraphQLQuery from "../../../../../shared/hooks/useGraphQLQuery";
import { graphql } from "../../../../../shared/schema/gql";
import {
  PatientId,
  VisitAssignmentId,
  WorkflowDefinitionId,
  WorkflowInstanceId,
} from "../../../../../shared/schema/schema";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";
import { AssignmentRecord } from "../assignments.utils";
import { VisitAssignmentSidebarSection } from "./VisitAssignmentSidebarSection";

export function VisitAssignmentRelatedAutomationsCard(props: {
  query: UseQueryResult<AssignmentRecord>;
}) {
  return (
    <VisitAssignmentSidebarSection panelProps={{ py: 0 }} title="RELATED AUTOMATIONS">
      {(() => {
        switch (props.query.status) {
          case "pending":
            return <RelatedAssignmentsSkeleton />;
          case "error":
            return <Flex>Something went wrong</Flex>;
          case "success":
            return (
              <VisitAssignmentRelatedAutomationsCardInner
                assignmentId={props.query.data.id}
                patientId={props.query.data.patient.id}
              />
            );
        }
      })()}
    </VisitAssignmentSidebarSection>
  );
}

function VisitAssignmentRelatedAutomationsCardInner(props: {
  assignmentId: VisitAssignmentId;
  patientId: PatientId;
}) {
  const [onlyRelated, setOnlyRelated] = React.useState(false);

  const query = useGraphQLQuery({
    document: GET_PATIENT_VISIT_ASSIGNMENT_AUTOMATIONS,
    variables: {
      tagValue: onlyRelated
        ? `visit-assignment-${props.assignmentId}`
        : `visit-assignment-patient-${props.patientId}`,
    },
    select: (x) => x.workflowTaskInstanceTags.nodes.map((node) => node.workflowTaskInstance),
  });

  const automationLink = (automation: {
    workflowDefinitionId: WorkflowDefinitionId | undefined;
    workflowInstanceId: WorkflowInstanceId;
  }) => {
    return `${env.WORKFLOWS_API_URL}/workflows/${automation.workflowDefinitionId}/instances/${automation.workflowInstanceId}`;
  };

  return (
    <Flex direction="column">
      <FormControl data-group alignItems="center" display="flex" gap={2} m={0} py={2}>
        <Switch
          checked={onlyRelated}
          m={0}
          size="sm"
          onChange={() => setOnlyRelated(!onlyRelated)}
        />
        <FormLabel color="gray.600" flex={1} fontSize="sm" m={0}>
          only related to this assignment
        </FormLabel>

        <Tooltip label="By default, we show all assignment automations related to the patient. If you enable this option, we will only show automations related to this assignment.">
          <InfoOutlineIcon
            _groupHover={{ opacity: 1 }}
            color="gray.500"
            opacity={0}
            transition="ease"
            transitionDuration="normal"
          />
        </Tooltip>
      </FormControl>
      {(() => {
        switch (query.status) {
          case "pending":
            return <RelatedAssignmentsSkeleton />;
          case "error":
            return <Flex>{formatErrorResponse(query.error)}</Flex>;
          case "success":
            return (
              <>
                {query.data.length === 0 && (
                  <Box pb={5} pt={3}>
                    <Text>There are no related automations</Text>
                  </Box>
                )}
                {query.data.map((automation) => (
                  <a
                    key={`${automation.id}`}
                    href={automationLink({
                      workflowDefinitionId:
                        automation.workflowTaskDefinition?.workflowDefinition.id,
                      workflowInstanceId: automation.workflowInstance.id,
                    })}
                  >
                    <Flex
                      _hover={{ bg: "gray.100" }}
                      as="span"
                      direction="column"
                      gap={1}
                      mx={-5}
                      px={5}
                      py={3}
                    >
                      <Text as="span" color="gray.600" fontSize="sm">
                        {dateFormatter.toDateTime(automation.createdAt)}
                      </Text>
                      <Text as="span" color="black" fontWeight="semibold">
                        {automation.workflowTaskDefinition?.name ?? "Unknown"}
                      </Text>
                    </Flex>
                  </a>
                ))}
              </>
            );
        }
      })()}
    </Flex>
  );
}

function RelatedAssignmentsSkeleton() {
  return (
    <Flex direction="column" gap={3} py={2}>
      <Skeleton height={12} rounded="md" />
      <Skeleton height={12} rounded="md" />
    </Flex>
  );
}

const GET_PATIENT_VISIT_ASSIGNMENT_AUTOMATIONS = graphql(`
  query GetVisitAssignmentRelatedAutomations($tagValue: String!) {
    workflowTaskInstanceTags(
      tagValue: { eq: $tagValue }
      order: [{ field: createdAt, sort: desc }]
      workflowTaskInstance: { completedAt: { ne: null }, success: { eq: true } }
    ) {
      nodes {
        workflowTaskInstance {
          id
          createdAt
          workflowInstance {
            id
          }
          workflowTaskDefinition {
            name
            workflowDefinition {
              id
              name
            }
          }
        }
      }
    }
  }
`);
