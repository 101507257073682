import { ChatIcon, PhoneIcon } from "@chakra-ui/icons";
import { Avatar, Flex, IconButton, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import React from "react";
import { isDescendantOfButton } from "../../../../../shared/utils/is-descendant-of-button";

export function VisitAssignmentEntityCard(props: {
  name: string;
  description: string;
  children: React.ReactNode;
  src?: string;
  onClickChat: () => void;
  onClick: () => void;
}) {
  const onClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if (!isDescendantOfButton(e.target as HTMLElement)) {
      props.onClick();
    }
  };

  return (
    <Flex
      _hover={{ bg: "gray.100" }}
      align="center"
      cursor="pointer"
      gap={4}
      p={4}
      onClick={onClick}
    >
      <Avatar h={9} src={props.src} w={9} />
      <Flex direction="column" flex={1}>
        <Text color="black" fontSize="md" fontWeight="semibold" lineHeight="5">
          {props.name}
        </Text>
        <Text color="gray.500" fontSize="sm" fontWeight="semibold">
          {props.description}
        </Text>
      </Flex>
      <Flex gap={1}>
        <Menu isLazy={true} lazyBehavior="unmount">
          <MenuButton
            _hover={{ bg: "gray.200" }}
            as={IconButton}
            icon={<PhoneIcon fontSize="sm" />}
            variant="ghost"
          />
          <MenuList>{props.children}</MenuList>
        </Menu>
        <IconButton
          _hover={{ bg: "gray.200" }}
          aria-label="Chat"
          icon={<ChatIcon fontSize="sm" />}
          variant="ghost"
          onClick={props.onClickChat}
        />
      </Flex>
    </Flex>
  );
}
