export const getErrorFromAssignResponse = (error) =>  {
    let errorMessage = "Failed to assign caregivers";

    if (error.status && error.status === 403 && error.type === "AssignWithOvertimeError") {
        errorMessage = "Not permitted to increase caregiver overtime.";
    }

    if(error.type !== undefined){
        switch(error.type){
            case "AssignWithOvertimeError":{
                errorMessage = "Not permitted to increase caregiver overtime.";
                break;
            }
            case "OfficeHoursRestrictionsConflict": {
                errorMessage = "Office hours restrictions conflict";
                break;
            }
            case "CargeiverVisitOverlap": 
            case "VisitsOverlapError": {
                errorMessage = "This assignment overlaps with patient or caregiver existing visits";
                break;
            }
            case "InactiveCaregiverAssignement": {
                errorMessage = "Can't assign inactive caregiver";
                break;
            }
            default: {
                errorMessage = "Failed to assign caregivers";
                break;
            }
        }
    }

    return errorMessage;
}