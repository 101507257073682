import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  Text,
  useCallbackRef,
  useDisclosure,
} from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import React from "react";
import { useLocalstorageState } from "rooks";
import { Messages } from "../../../../../core/api";

export default function StartTaskDialog(props: {
  task: {
    instance: Messages["WorkflowHumanTaskInstance"];
    endsAt: Instant | null;
  };
  disclosure: ReturnType<typeof useDisclosure>;
  onConfirm: (instance: Messages["WorkflowHumanTaskInstance"]) => void;
}) {
  const [hasPreviouslyConfirmed, setHasPreviouslyConfirmed] = useLocalstorageState(
    "workflow-task-viewer-has-confirmed-start-task",
    false
  );

  const initialHasPreviouslyConfirmed = React.useRef(hasPreviouslyConfirmed);
  const leastDestructiveRef = React.useRef(null);
  const onConfirmRef = useCallbackRef(props.onConfirm);

  const handleConfirm = React.useCallback(() => {
    onConfirmRef(props.task.instance);
    props.disclosure.onClose.call(null);
  }, [onConfirmRef, props.disclosure.onClose, props.task.instance]);

  React.useEffect(() => {
    if (props.disclosure.isOpen && initialHasPreviouslyConfirmed.current) {
      handleConfirm();
    }
  }, [handleConfirm, props.disclosure.isOpen]);

  React.useEffect(() => {
    if (props.task.endsAt !== null) {
      props.disclosure.onClose.call(null);
    }
  }, [props.task.endsAt, props.disclosure.onClose]);

  const shouldShowAlert =
    props.disclosure.isOpen && props.task.endsAt === null && !initialHasPreviouslyConfirmed.current;

  if (!shouldShowAlert) {
    return <></>;
  }

  return (
    <AlertDialog
      {...props.disclosure}
      isCentered
      leastDestructiveRef={leastDestructiveRef}
      size="lg"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader>Start Task</AlertDialogHeader>
          <AlertDialogBody>
            <Text fontSize="md" mb={4}>
              You are already assigned to a task. Are you sure you want to start another one?
            </Text>

            <Checkbox
              fontWeight="normal"
              onChange={(e) => setHasPreviouslyConfirmed(e.target.checked)}
            >
              Don&apos;t show this dialog again
            </Checkbox>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={leastDestructiveRef} onClick={props.disclosure.onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" ml={3} onClick={handleConfirm}>
              Start anyway
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
