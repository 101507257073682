import { CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Badge,
  Checkbox,
} from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import Select from "../../../../../shared/components/Select";
import {
  IntakeTrackStepFieldId,
  IntakeTrackStepFieldValueId,
} from "../../../../../shared/schema/schema";
import { typedObjectEntries } from "../../../../../shared/utils/ts-utils";
import { shouldShowOptionsForQuestion } from "../../../shared/utils/intakeQuestionUtils";
import { WithKey, IntakeStepFormField, IntakeStepFormOptionsOption } from "../flow-settings.types";
import { questionTypes } from "../flow-settings.utils";
import FieldItemOption from "./FieldItemOption";

export default function StepFieldItem(props: {
  value: WithKey<IntakeStepFormField>;
  allFieldsIds: Set<IntakeTrackStepFieldId>;
  allStepFieldValuesIds: Set<IntakeTrackStepFieldValueId>;
  steps: Messages["IntakeTrackStepForFullFlow"][];
  onChangeProperty: <K extends keyof WithKey<IntakeStepFormField>>(
    field: K,
    value: WithKey<IntakeStepFormField>[K]
  ) => void;
  onChangeOptionProperty: <K extends keyof WithKey<IntakeStepFormOptionsOption>>(
    idx: number,
    field: K,
    value: WithKey<IntakeStepFormOptionsOption>[K]
  ) => void;
  onChangeOptions: (options: WithKey<IntakeStepFormOptionsOption>[]) => void;
  onClickNewOption: () => void;
  onClickRemove: () => void;
  onClickRemoveOption: (optionIdx: number) => void;
}) {
  const isFieldIdExists = props.value.id !== null && props.allFieldsIds.has(props.value.id);
  return (
    <Flex
      bg="white"
      border="1px"
      borderColor="gray.200"
      direction="column"
      gap={4}
      mb={4}
      p={4}
      rounded="md"
    >
      <Flex alignItems="center" gap={2} justify="space-between">
        <IconButton
          aria-label="remove question"
          colorScheme="red"
          icon={<CloseIcon fontSize="xs" />}
          size="sm"
          variant="outline"
          onClick={props.onClickRemove}
        />
      </Flex>
      <FormControl>
        <Badge colorScheme={isFieldIdExists ? "red" : "green"} p={4} w="full">
          {isFieldIdExists ? "edit" : "new"}
        </Badge>
        <FormLabel>ID</FormLabel>
        <Input
          value={props.value.id ?? ""}
          onChange={(e) =>
            props.onChangeProperty("id", IntakeTrackStepFieldId.parse(parseInt(e.target.value)))
          }
        />
      </FormControl>

      <FormControl>
        <FormLabel>Order</FormLabel>
        <Input
          type="number"
          value={props.value.orderInStep ?? ""}
          onChange={(e) => props.onChangeProperty("orderInStep", parseInt(e.target.value))}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Code</FormLabel>
        <Input
          value={props.value.code ?? ""}
          onChange={(e) =>
            props.onChangeProperty(
              "code",
              e.target.value as NonNullable<Messages["IntakeTrackStepField"]["code"]>
            )
          }
        />
      </FormControl>

      <FormControl>
        <FormLabel>Title</FormLabel>
        <Input
          value={props.value.title}
          onChange={(e) => props.onChangeProperty("title", e.target.value)}
        />
      </FormControl>

      <FormControl display="flex" flexDirection="row" gap={4}>
        <FormLabel>Optional</FormLabel>
        <Checkbox
          isChecked={props.value.isOptional}
          size="lg"
          onChange={(event) => props.onChangeProperty("isOptional", event.target.checked)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Type</FormLabel>
        <Select
          label="Select type"
          multiple={false}
          options={typedObjectEntries(questionTypes).map(([value, label]) => ({
            value,
            label,
          }))}
          value={props.value.type}
          onChange={(value) => value && props.onChangeProperty("type", value)}
        />
      </FormControl>

      <Flex flexDirection="column" gap={2}>
        {shouldShowOptionsForQuestion(props.value) && (
          <Flex bg="gray.50" flexDirection="column" gap={2} p={4} rounded="md">
            {props.value.options.map((option, index) => (
              <FieldItemOption
                key={option.__key}
                allStepFieldValuesIds={props.allStepFieldValuesIds}
                steps={props.steps}
                value={option}
                onChangeField={(field, value) => props.onChangeOptionProperty(index, field, value)}
                onClickRemove={() => props.onClickRemoveOption(index)}
              />
            ))}

            <Button
              bg="white"
              borderStyle="dashed"
              variant="outline"
              w="full"
              onClick={props.onClickNewOption}
            >
              Add option
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
