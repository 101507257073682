import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  chakra,
  Flex,
  Grid,
  Heading,
  IconButton,
  keyframes,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Duration, Instant } from "@js-joda/core";
import React from "react";
import { Messages } from "../../../../core/api";
import SafeHTML from "../../../../shared/components/SafeHTML";
import { useDownloadFile } from "../../../../shared/hooks/useDownloadFile";
import CheckDoubleFillIcon from "../../../../shared/icons/CheckDoubleFillIcon";
import ClockIcon from "../../../../shared/icons/ClockIcon";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../../shared/utils/duration-formatter";
import {
  getMailboxString,
  getMailboxStrings,
  isInboundEmailMessage,
} from "../../utils/comm-center-email-utils";

const formatDateForEmailMessage = (date: Instant) => {
  const { formatted: timeAgoH, duration } = durationFormatter.relative(
    Duration.between(Instant.now(), date)
  );

  return `${dateFormatter.toDateOrDateTime(date)} ${
    duration.abs().toDays() > 0 ? `(${timeAgoH})` : ""
  }`;
};

interface Props {
  message: Messages["CommCenterEmailMessage"];
}

const EmailMessage = (props: Props) => {
  const sentOrReceived = isInboundEmailMessage(props.message) ? "received" : "sent";

  return (
    <EmailMessageRoot isNew={props.message.id < 0} type={sentOrReceived}>
      <EmailMessageBubble type={sentOrReceived}>
        <EmailMessageHeader message={props.message} sentOrReceived={sentOrReceived} />
        <SafeHTML html={props.message.content} />
        <EmailMessageAttachments attachments={props.message.attachments} />
      </EmailMessageBubble>
    </EmailMessageRoot>
  );
};

function EmailMessageHeader(props: {
  message: Messages["CommCenterEmailMessage"];
  sentOrReceived: "sent" | "received";
}) {
  const relevantIcon = (() => {
    if (props.message.id < 0) {
      return (
        <Tooltip label="Sending...">
          <ClockIcon color="gray.500" />
        </Tooltip>
      );
    }

    return (
      <Tooltip label="Sent">
        <CheckDoubleFillIcon color="gray.500" />
      </Tooltip>
    );
  })();

  return (
    <Flex direction="column" mb={5}>
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <Heading size="xs">{getMailboxString(props.message.sender)}</Heading>
        <Flex alignItems="center">
          <Text color="gray.700" fontSize="xs">
            {formatDateForEmailMessage(props.message.createdAt)}
          </Text>
          {props.sentOrReceived === "sent" && relevantIcon}
        </Flex>
      </Flex>
      <Flex alignItems="center" gap={1}>
        <EmailMessage.SubText>
          to: {getMailboxStrings(props.message.recipients)}
        </EmailMessage.SubText>
        <EmailMessageExpandDetailsButton message={props.message} />
      </Flex>
    </Flex>
  );
}

function EmailMessageAttachments(props: {
  attachments: Messages["CommCenterEmailMessageAttachment"][];
}) {
  return (
    <Flex flexWrap="wrap">
      {props.attachments.map((attachment, i) => (
        <AttachmentBox key={`message-attachment-${i}`} attachment={attachment} />
      ))}
    </Flex>
  );
}

const AttachmentBox = (props: { attachment: Messages["CommCenterEmailMessageAttachment"] }) => {
  const { download, isDownloading } = useDownloadFile();
  const downloadAttachment = () => {
    download({
      fileName: props.attachment.name,
      url: props.attachment.s3Url,
    });
  };
  return (
    <Box
      data-group
      _hover={{ borderColor: "gray.600" }}
      backgroundColor="white"
      border="1px"
      borderColor="gray.300"
      borderRadius={5}
      m={1}
      p={2}
      pos="relative"
    >
      <Text>{props.attachment.name}</Text>
      <Box
        _groupHover={{ opacity: 1 }}
        alignItems="center"
        backgroundColor="rgba(0,0,0,0.5)"
        bottom="0"
        color="white"
        display="flex"
        justifyContent="center"
        left="0"
        opacity={isDownloading ? 1 : 0}
        position="absolute"
        right="0"
        top="0"
        transition="opacity 0.2s ease-in-out"
      >
        <IconButton
          _hover={{ backgroundColor: "transparent" }}
          aria-label="Download Attachment"
          color="white"
          h="100%"
          icon={<DownloadIcon height={7} width={7} />}
          isLoading={isDownloading}
          size="xl"
          variant="ghost"
          w="100%"
          onClick={downloadAttachment}
        />
      </Box>
    </Box>
  );
};

function EmailMessageBubble(props: { children: React.ReactNode; type: "sent" | "received" }) {
  return (
    <EmailMessageBubble.Box backgroundColor={props.type === "received" ? "gray.100" : "blue.100"}>
      {props.children}
    </EmailMessageBubble.Box>
  );
}
EmailMessageBubble.Box = chakra(Box, {
  baseStyle: {
    borderRadius: 12,
    padding: 3,
    transition: "all 250ms ease",
    width: "min(700px, 80%)",
    "*": {
      maxWidth: "100%",
    },
    table: {
      width: "fit-content !important",
    },
  },
});

const chatMessageIn = keyframes`
  from {
      opacity: 0;
      transform: scale(0.95);
  }
`;

function EmailMessageRoot(props: {
  children: React.ReactNode;
  type: "sent" | "received";
  isNew: boolean;
}) {
  return (
    <Flex
      alignItems={props.type === "received" ? "flex-start" : "flex-end"}
      animation={props.isNew ? `${chatMessageIn} 0.3s ease-in-out backwards` : undefined}
      data-is-new={props.isNew}
      flexDirection="column"
      gap={4}
      marginInlineEnd={props.type === "received" ? "auto" : undefined}
      marginInlineStart={props.type === "sent" ? "auto" : undefined}
      opacity={props.isNew ? 0.75 : undefined}
      width="100%"
    >
      {props.children}
    </Flex>
  );
}

EmailMessage.ContentBubble = chakra(EmailMessageBubble, {
  baseStyle: {
    border: "1px solid",
    borderColor: "gray.300",
    margin: 5,
  },
});

function EmailMessageExpandDetailsButton(props: { message: Messages["CommCenterEmailMessage"] }) {
  return (
    <Popover closeOnBlur={true} placement="bottom-start">
      <PopoverTrigger>
        <IconButton
          _hover={{ bg: "gray.300" }}
          aria-label="Expand details"
          bg="transparent"
          icon={<ChevronDownIcon />}
          size="xs"
        />
      </PopoverTrigger>
      <PopoverContent border="1px solid" borderColor="gray.400" fontSize="12px">
        <PopoverBody>
          <Grid gap={2} templateColumns="max-content auto">
            <>
              <EmailMessage.SubText>from:</EmailMessage.SubText>
              <Text>{getMailboxString(props.message.sender)}</Text>
            </>
            <>
              <EmailMessage.SubText>to:</EmailMessage.SubText>
              <Text>{getMailboxStrings(props.message.recipients)}</Text>
            </>
            {props.message.cc !== null && props.message.cc?.length > 0 && (
              <>
                <EmailMessage.SubText>cc:</EmailMessage.SubText>
                <Text>{getMailboxStrings(props.message.cc)}</Text>
              </>
            )}
            <>
              <EmailMessage.SubText>date:</EmailMessage.SubText>
              <Text>{formatDateForEmailMessage(props.message.createdAt)}</Text>
            </>
          </Grid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

EmailMessage.SubText = chakra(Text, {
  baseStyle: {
    color: "gray.500",
    fontSize: "12px",
  },
});

export default EmailMessage;
