import { Box } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import { IntakeTrackStepId } from "../../../../../shared/schema/schema";
import usePatientIntakeFlow from "../hooks/usePatientIntakeFlow";
import PatientIntakeFlowError from "./PatientIntakeFlowError";
import PatientIntakeFlowSidebarItem from "./PatientIntakeFlowSidebarItem";

export default function PatientIntakeFlowSidebar(props: {
  query: ReturnType<typeof usePatientIntakeFlow>["stepsQuery"];
  externalFlows: Messages["IntakeExternalFlow"][];
  activeStepId: ReturnType<typeof usePatientIntakeFlow>["activeStepId"];
  onClickStep: ReturnType<typeof usePatientIntakeFlow>["setActiveStepId"];
}) {
  return (
    <Box
      bg="gray.50"
      borderColor="gray.200"
      borderEndWidth={1}
      flexShrink={0}
      h="full"
      overflow="auto"
      p={4}
      w="sm"
    >
      {(() => {
        switch (props.query.status) {
          case "pending":
            return <PatientIntakeFlowSidebarShimmer />;
          case "error":
            return <PatientIntakeFlowError error={props.query.error} />;
          case "success": {
            return (
              <Box>
                {props.query.data.map((step) => (
                  <PatientIntakeFlowSidebarItem
                    key={step.id}
                    answer={
                      step.answers.length === 1 ? step.answers[0] : `${step.answers.length} answers`
                    }
                    externalFlowName={
                      props.externalFlows.find((f) => f.id === step.externalFlowId)?.title ?? null
                    }
                    isCompleted={step.completed}
                    isFuture={step.future}
                    question={step.title}
                    status={getPatientIntakeFlowStepStatus(step, props.activeStepId)}
                    onClick={() => props.onClickStep(step.id)}
                  />
                ))}
              </Box>
            );
          }
        }
      })()}
    </Box>
  );
}

const PatientIntakeFlowSidebarShimmer = () => {
  return (
    <>
      <PatientIntakeFlowSidebarItem.Shimmer />
      <PatientIntakeFlowSidebarItem.Shimmer />
      <PatientIntakeFlowSidebarItem.Shimmer />
      <PatientIntakeFlowSidebarItem.Shimmer />
      <PatientIntakeFlowSidebarItem.Shimmer />
      <PatientIntakeFlowSidebarItem.Shimmer />
      <PatientIntakeFlowSidebarItem.Shimmer />
    </>
  );
};

PatientIntakeFlowSidebar.Shimmer = PatientIntakeFlowSidebarShimmer;

function getPatientIntakeFlowStepStatus(
  step: Messages["IntakeTrackStepPreview"],
  activeStepId: IntakeTrackStepId | null
): React.ComponentProps<typeof PatientIntakeFlowSidebarItem>["status"] {
  if (step.id === activeStepId) {
    return "ACTIVE";
  }

  return step.completed ? "COMPLETED" : "FUTURE";
}
