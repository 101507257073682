import { Messages } from "../../../../../core/api";
import Select from "../../../../../shared/components/Select";
import { CommCenterTicket } from "../../../../communication/communication.types";
import useEditTicket from "../../../../communication/pages/CommunicationCenterTicket/mutateEditTicket";

const options: { label: string; value: Messages["CommCenterTicketStatus"] }[] = [
  { label: "New", value: "NEW" },
  { label: "In Progress", value: "IN PROGRESS" },
  { label: "Resolved", value: "RESOLVED" },
];

export function VisitAssignmentTicketStatusSelect(props: { ticket: CommCenterTicket }) {
  const editTicket = useEditTicket();

  return (
    <Select
      allowUnselect={false}
      isDisabled={props.ticket.status === "RESOLVED"}
      label="Assignee"
      multiple={false}
      options={options}
      size="sm"
      value={props.ticket.status}
      width="fit-content"
      onChange={(status) => editTicket.mutate({ id: props.ticket.id, body: { status } })}
    />
  );
}
