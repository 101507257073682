import { chakra, Flex, forwardRef, TableCellProps, Td, Text, Tr } from "@chakra-ui/react";
import { Column } from "@tanstack/react-table";
import { getStickyProps } from "./data-table.utils";

export type DataTableSpacing = "tight" | "loose";

export const DataTableTheadText = chakra(Text, {
  baseStyle: {
    color: "gray.800",
    fontSize: "sm",
    fontWeight: "semibold",
    letterSpacing: "normal",
    padding: 2,
    textTransform: "none",
  },
});

export const DataTableColumnFiltersRow = chakra(Tr, {
  baseStyle: {
    bg: "gray.50",
  },
});

export const DataTableTbodyTd = forwardRef<
  TableCellProps & { column: Column<any>; spacing: DataTableSpacing },
  "td"
>((props, ref) => {
  const { column, spacing, ...rest } = props;

  return (
    <Td
      ref={ref}
      px={spacing === "tight" ? 4 : undefined}
      py={spacing === "tight" ? 2 : undefined}
      {...getStickyProps(column)}
      {...rest}
      h="50px"
    />
  );
});

export const DataTableEndActionFlex = chakra(Flex, {
  baseStyle: {
    opacity: 0,
    pointerEvents: "none",
    transform: "translateX(-24px)",
    transition: "all 0.2s ease",
    justifyContent: "flex-end",
    _groupHover: {
      pointerEvents: "auto",
      opacity: 1,
      transform: "translateX(0)",
    },
  },
});
