import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import { Duration, Instant } from "@js-joda/core";
import { CommCenterTicketId } from "../../../../shared/schema/schema";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../../shared/utils/duration-formatter";
import { EmailTicket, isInboundEmailMessage } from "../../utils/comm-center-email-utils";
import TicketSourceCell from "../TicketSourceCell";

interface Props {
  ticket: EmailTicket;
  isActive: boolean;
  onClick: (ticketId: CommCenterTicketId) => void;
}

const EmailTicketItem = (props: Props) => {
  const totalUnreadMessages = props.ticket.emailThread.messages.filter(
    (message) => isInboundEmailMessage(message) && message.readAt === null
  ).length;

  const firstMessage = props.ticket.emailThread.messages[0];
  const senderText =
    firstMessage.direction === "OUTBOUND"
      ? firstMessage.sender.email
      : firstMessage.sender.name === ""
      ? firstMessage.sender.email
      : firstMessage.sender.name;
  const lastMessage = props.ticket.emailThread.messages.at(-1);
  const lastMessageSentTime = lastMessage?.createdAt ?? props.ticket.createdAt;
  const { formatted: timeAgoH, duration } = durationFormatter.relative(
    Duration.between(Instant.now(), lastMessageSentTime)
  );

  const sentDate = dateFormatter.toDateTime(lastMessageSentTime);

  const lastMessageSentDate = duration.abs().toDays() > 5 ? sentDate : timeAgoH;

  return (
    <Box
      _hover={{ bg: "gray.100" }}
      backgroundColor={props.isActive ? "gray.300" : undefined}
      cursor="pointer"
      p={2}
      onClick={() => props.onClick(props.ticket.id)}
    >
      <Flex alignItems="center" direction="row" gap={2}>
        <Tag mb={1}>
          <Text noOfLines={1}>{props.ticket.emailThread.subject}</Text>
        </Tag>
        <TicketSourceCell
          callInfo={null}
          emailThread={props.ticket.emailThread}
          messages={props.ticket.messages}
          source="EMAIL"
          status={props.ticket.status}
        />
      </Flex>
      <Flex justifyContent="space-between">
        <Text>{senderText}</Text>
        <Text color="gray" fontSize="sm">
          {lastMessageSentDate}
        </Text>
      </Flex>
      <Flex ml={2} mt={2}>
        <Tag bg="blue.500" color="white" hidden={totalUnreadMessages === 0} mr={2} rounded="full">
          {totalUnreadMessages}
        </Tag>
        <Text color="gray" noOfLines={1}>
          {lastMessage?.contentPlainText ?? ""}
        </Text>
      </Flex>
    </Box>
  );
};

export default EmailTicketItem;
