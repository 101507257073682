import { Messages } from "../../core/api";
import Select, { CustomSelectProps } from "./Select";
import SelectNoResults from "./SelectNoResults";

const statusOptions = [
  { value: "ACCEPTED", label: "Accepted" },
  { value: "ACTIVE", label: "Active" },
  { value: "DECEASED", label: "Deceased" },
  { value: "DISCHARGED", label: "Discharged" },
  { value: "DRAFT", label: "Draft" },
  { value: "ELIGIBLE", label: "Eligible" },
  { value: "HOSPITALIZED", label: "Hospitalized" },
  { value: "LOST", label: "Lost" },
  { value: "ON_HOLD", label: "On Hold" },
  { value: "PENDING_FILES", label: "Pending Files" },
  { value: "REFERRAL", label: "Referral" },
  { value: "VACATION", label: "Vacation" },
] as const;

export default function PatientStatusSelect(props: CustomSelectProps<Messages["PatientStatus"]>) {
  return (
    <Select
      label={props.multiple ? "Statuses" : "Status"}
      options={statusOptions}
      renderAfter={({ searchTerm, filteredOptions }) =>
        filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
      }
      {...props}
    />
  );
}
