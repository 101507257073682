import { QueryParamsOf } from "../../../../../core/api";
import { useQueryParams } from "../../../../../shared/hooks/useQueryParams";

export type IntakeDashboardFilters = ReturnType<typeof useIntakeDashboardFilters>;

export default function useIntakeDashboardFilters() {
  const serverSideFilters = useQueryParams<QueryParamsOf<"get", "./patient_intake/dashboard">>({
    storageKey: ["patient-intake", "dashboard-table"],
  });

  return {
    serverSide: serverSideFilters,
  };
}
