import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import useApi from "../../../../../shared/hooks/useApi";
import { WorkflowTaskInstanceId } from "../../../../../shared/schema/schema";

export function useAssigneTasks() {
  const queryClient = useQueryClient();
  const { api, queries } = useApi();
  const [activeTaskInstanceId, setActiveTaskInstanceId] =
    React.useState<WorkflowTaskInstanceId | null>(null);

  const assignmentsQueryOptions = queries.workflow.assignments();

  const query = useQuery({
    ...assignmentsQueryOptions,
    staleTime: Infinity,
  });

  const activeTask = React.useMemo(() => {
    if (query.isSuccess) {
      return (
        query.data.tasks.find((task) => task.instance.id === activeTaskInstanceId) ??
        query.data.tasks.find((x) => x.endsAt !== null) ??
        query.data.tasks.at(0)
      );
    }

    return undefined;
  }, [activeTaskInstanceId, query.data, query.isSuccess]);

  const assign = useMutation({
    mutationFn: (params: { id: WorkflowTaskInstanceId }) => {
      return api.post("./workflow_task_viewer/assign/:workflowTaskInstanceId", {
        path: { workflowTaskInstanceId: params.id },
      });
    },
    onSuccess: (data, { id }) => {
      queryClient.setQueryData(assignmentsQueryOptions.queryKey, data);
      setActiveTaskInstanceId(id);
    },
  });

  const prolong = useMutation({
    mutationFn: (params: { id: WorkflowTaskInstanceId }) => {
      return api.post("./workflow_task_viewer/prolong/:workflowTaskInstanceId", {
        path: { workflowTaskInstanceId: params.id },
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(assignmentsQueryOptions.queryKey, data);
    },
  });

  const skipTask = useMutation({
    mutationFn: (params: { id: WorkflowTaskInstanceId }) => {
      return api.post("./workflow_task_viewer/skip/:workflowTaskInstanceId", {
        path: { workflowTaskInstanceId: params.id },
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(assignmentsQueryOptions.queryKey, data);
      setActiveTaskInstanceId(null);
    },
  });

  const skipCluster = useMutation({
    mutationFn: () => {
      return api.post("./workflow_task_viewer/skip_cluster", {});
    },
    onSuccess: (data) => {
      queryClient.setQueryData(assignmentsQueryOptions.queryKey, data);
      setActiveTaskInstanceId(null);
    },
  });

  return {
    query,
    prolong,
    assign,
    skipTask,
    setActiveTaskInstanceId,
    skipCluster,
    activeTask,
  };
}
