import { IconButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import AddNotesOutlineIcon from "../../../../../shared/icons/AddNotesOutlineIcon";
import { CommCenterTicket } from "../../../../communication/communication.types";
import NewNoteModal from "../../../../note/components/NewNoteModal";
import { NoteFormData } from "../../../../note/utils/note-form";

export function VisitAssignmentTicketAddNoteButton(props: { ticket: CommCenterTicket }) {
  const disclosure = useDisclosure();

  const noteInitialValues: Partial<NoteFormData> = {
    caregiverId: props.ticket.relatedCaregiver?.id ?? undefined,
    patientId: props.ticket.relatedPatient?.id ?? undefined,
    commCenterTicketId: props.ticket.id,
  };

  return (
    <>
      <Tooltip label="Add note">
        <IconButton
          aria-label="add note"
          icon={<AddNotesOutlineIcon fontSize={18} />}
          variant="ghost"
          onClick={disclosure.onOpen}
        />
      </Tooltip>

      <NewNoteModal disclosure={disclosure} initialValues={noteInitialValues} />
    </>
  );
}
