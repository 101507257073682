import ErrorBox from "./ErrorBox";
import Page from "./Page";

type Props = {
  error: Error | unknown;
  resetErrorBoundary: () => void;
};

const ErrorPage = (props: Props) => {
  return (
    <Page>
      <ErrorBox {...props} />
    </Page>
  );
};

export default ErrorPage;
