export function jsonToCSV<T extends Record<string, string>>(rows: T[]): string {
  if (rows.length === 0) {
    return "";
  }
  const keys = Object.keys(rows[0]) as unknown as (keyof T)[];
  const csvHeader = keys.map((key) => `"${String(key).replace(/"/g, `""`)}"`).join(",");
  const csvRows = rows.map((row) =>
    keys.map((key) => `"${String(row[key]).replace(/"/g, `""`)}"`).join(",")
  );
  return `${csvHeader}\n${csvRows.join("\n")}`;
}

export function downloadCsvFileData(filename: string, csvData: string): void {
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
