import { Box, Button, Flex, Spinner, keyframes } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../../../core/api";
import { PatientId } from "../../../../../shared/schema/schema";
import { PatientIntakeFlowStepForm } from "../../../patient-intake.types";
import PatientIntakeFlowStaffingPreferences from "./PatientIntakeFlowStaffingPreferences";
import PatientIntakeFlowStepField from "./PatientIntakeFlowStepField";
import PatientIntakeFlowStepHeadline from "./PatientIntakeFlowStepHeadline";
import PatientIntakeFlowPatientAvilability from "./PatientIntakeFlowStepPatientAvailability";

export default function PatientIntakeFlowStepFields(props: {
  fields: Messages["IntakeTrackStepField"][];
  isFuture: boolean;
  isStop: boolean;
  form: PatientIntakeFlowStepForm;
  isSubmitting: boolean;
  patientId: PatientId;
  onFinishFlow: () => void;
  onChange: (field: Messages["IntakeTrackStepFieldWithNewAnswer"]) => void;
  onSubmit: () => void;
}) {
  const buttonText = () => {
    if (!props.isStop) {
      return "Next";
    }
    return "End of script for this call";
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit();

    if (props.isStop) {
      props.onFinishFlow();
    }
  };

  if (props.isFuture) {
    return (
      <Box bg="yellow.100" color="yellow.600" fontSize="lg" p={6} textAlign="center">
        You can&rsquo;t fill out this step yet.
      </Box>
    );
  }

  const renderField = (field: Messages["IntakeTrackStepField"]) => {
    switch (field.type) {
      case "patient_availability":
        return <PatientIntakeFlowPatientAvilability patientId={props.patientId} />;
      case "patient_staffing_preferences":
        return <PatientIntakeFlowStaffingPreferences patientId={props.patientId} />;
      case "patient_special_requests":
        return <div>TEMP UNIMPLEMENTED!!!</div>;
      default:
        return (
          <PatientIntakeFlowStepField
            field={field}
            value={props.form[field.id]}
            onChange={props.onChange}
          />
        );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        {props.fields.map((field) => (
          <Box key={field.id}>
            {field.type !== "checkbox" && (
              <PatientIntakeFlowStepHeadline>{`${field.title} ${
                field.isOptional ? "(Optional)" : ""
              }`}</PatientIntakeFlowStepHeadline>
            )}
            {renderField(field)}
          </Box>
        ))}
        <Button
          animation={props.isStop ? `${buttonPulse} 2s 5 5s` : ""}
          colorScheme={props.isStop ? "red" : "blue"}
          isDisabled={props.isSubmitting}
          p={8}
          size="lg"
          type="submit"
        >
          {props.isSubmitting ? <Spinner /> : buttonText()}
        </Button>
      </Flex>
    </form>
  );
}

const buttonPulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;
