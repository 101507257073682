import { PhoneIcon } from "@chakra-ui/icons";
import { Divider, Flex, HStack, IconButton } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import React from "react";
import { Messages } from "../../../../core/api";
import { TelephonyCallId } from "../../../../shared/schema/schema";
import { isOneOfUnion } from "../../../../shared/utils";
import useCallCenter from "../../hooks/useCallCenter";
import MissedCallCard from "./MissedCallCard";

export default function MissedCallsContent(props: {
  calls: Messages["TelephonyInboundCallInfo"][];
}) {
  const { dial, state } = useCallCenter();
  const [dialedCallId, setDialedCall] = React.useState<TelephonyCallId | null>(null);

  const disableDial = isOneOfUnion(state.status, ["Active", "Ringing", "Calling", "Unauthorized"]);

  const handleDial = (call: Messages["TelephonyInboundCallInfo"]) => {
    setDialedCall(call.id);
    dial(call.caller.source);
  };

  return (
    <Flex direction="column" overflowY="auto">
      {props.calls.map((call, index) => {
        return (
          <React.Fragment key={call.id}>
            <HStack
              _hover={{ bg: "gray.100" }}
              justifyContent="space-between"
              p={5}
              role="group"
              w="full"
            >
              <MissedCallCard calledAt={call.rangAt ?? Instant.now()} caller={call.caller} />

              <IconButton
                _groupHover={{ opacity: 1 }}
                aria-label="dial-missed-call"
                colorScheme="green"
                disabled={disableDial}
                icon={<PhoneIcon />}
                isLoading={dialedCallId === call.id && state.status === "Dialing"}
                opacity={0}
                rounded="full"
                onClick={() => handleDial(call)}
              />
            </HStack>
            {index !== props.calls.length - 1 && <Divider ml={5} width="90%" />}
          </React.Fragment>
        );
      })}
    </Flex>
  );
}
