import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";
import { Entity, EntityCardLink } from "../../../shared/components/EntityCard";
import { FaxDetailsId } from "../../../shared/schema/schema";
import EditFaxEntitySection, { SelectEntityParams } from "./EditFaxEntitySection";

type Props = {
  entity: Entity;
  faxId: FaxDetailsId;
  onSelectEntity: (faxId: FaxDetailsId, entity: SelectEntityParams | null) => void;
};

const FaxEntityBox = (props: Props) => {
  const [isEditable, setIsEditable] = React.useState<boolean>(false);

  return (
    <Flex alignItems="center" direction="row" gap={6} height="70px" width="250px">
      <Popover placement="right">
        <PopoverTrigger>
          <Box height="40px" width="40px">
            <IconButton
              _hover={{ background: isEditable ? "blue.100" : "white" }}
              aria-label="assign entity"
              background={isEditable ? "blue.100" : "white"}
              icon={<EditIcon />}
              variant="ghost"
              onClick={() => setIsEditable(!isEditable)}
            ></IconButton>
          </Box>
        </PopoverTrigger>
        <PopoverContent minW="fit-content">
          <PopoverBody p={0}>
            <EditFaxEntitySection
              caregiverId={props.entity.type === "Caregiver" ? props.entity.id : null}
              faxId={props.faxId}
              patientId={props.entity.type === "Patient" ? props.entity.id : null}
              onSelectEntity={props.onSelectEntity}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <EntityCardLink entity={props.entity} />
    </Flex>
  );
};

export default FaxEntityBox;
