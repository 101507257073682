import { z } from "zod";
import { Messages } from "../../../core/api";
import {
  getTextContentOfRichText,
  zRichText,
} from "../../../shared/components/Lexical/editor-utils";
import {
  AgencyMemberId,
  CaregiverId,
  CommCenterTicketId,
  NotePredefinedAnswerId,
  NoteSubjectId,
  PatientId,
  VisitInstanceId,
} from "../../../shared/schema/schema";
import { zj } from "../../../shared/utils/zj";
import { noteTypeKeys } from "./note.utils";

export const zNoteStatus = z.enum(["NONE", "VIEWED", "IN_PROGRESS", "DONE"]);
export const zNoteType = z.enum(noteTypeKeys);

export const zNoteForm = z.object({
  caregiverId: CaregiverId.optional(),
  patientId: PatientId.optional(),
  agencyMemberIds: AgencyMemberId.array().optional(),
  mentionedAgencyMemberIds: AgencyMemberId.array().optional(),
  commCenterTicketId: CommCenterTicketId.optional(),
  subject: NoteSubjectId,
  content: z
    .string()
    .refine((x) => x.replace(/<[^>]*>/g, "").length > 0, { message: "Content cannot be empty" }),
  followUpDate: zj.localDate().optional(),
  status: zNoteStatus.default("NONE"),
  base64File: z.string().optional(),
});

export type NoteFormData = z.infer<typeof zNoteForm>;

export function zEntityNoteForm(
  settings: Pick<Messages["EntityNoteSetting"], "isMessageMandatory" | "isPredefinedMandatory">
) {
  return z.object({
    notePredefinedAnswerId:
      settings.isPredefinedMandatory === true
        ? NotePredefinedAnswerId
        : NotePredefinedAnswerId.optional(),
    richText:
      settings.isMessageMandatory === true
        ? zRichText.refine(getTextContentOfRichText, { message: "Message cannot be empty" })
        : zRichText.optional(),
    agencyMemberIds: AgencyMemberId.array().optional(),
    commCenterTicketId: CommCenterTicketId.optional(),
    followUpDate: zj.localDate().optional(),
    status: zNoteStatus.default("NONE"),
    base64File: z.string().optional(),
  });
}

export type EntityNoteFormData = z.infer<ReturnType<typeof zEntityNoteForm>>;

export function mapToAgencyNoteCreateParams(
  form: EntityNoteFormData & {
    noteType: Messages["NoteTypes"];
    visitInstanceId?: VisitInstanceId;
  } & (
      | { patientId: PatientId; caregiverId?: CaregiverId }
      | { patientId?: PatientId; caregiverId: CaregiverId }
    )
): Messages["AgencyNoteCreateParams"] {
  return {
    notePredefinedAnswerId: form.notePredefinedAnswerId,
    noteRichText: form.richText?.output ?? null,
    noteType: form.noteType,
    status: form.status,
    agencyMemberIds: [
      ...new Set([
        ...(form.agencyMemberIds ?? []),
        ...(form.richText?.mentions.map((x) => x.id) ?? []),
      ]),
    ],
    visitInstanceId: form.visitInstanceId,
    base64File: form.base64File,
    patientId: form.patientId,
    caregiverId: form.caregiverId,
  };
}
