import { useState } from "react";
import { SingleDigitDialPadState } from "../components/DialPad";

interface Actions {
  onClickDigit?: (digit: string, acc: string) => void;
}

const useSingleDigitDialPad = (
  state?: Actions
): { state: SingleDigitDialPadState; accInput: string } => {
  const [accInput, setAccInput] = useState("");

  const handleClickDigit = (digit: string | number) => {
    const newAccInput = `${accInput}${digit}`;
    setAccInput(newAccInput);
    state?.onClickDigit?.(digit.toString(), newAccInput);
  };

  const handleClickMultipleDigits = (digits: string, delayMs = 0) => {
    const acc = accInput;
    setAccInput((prev) => `${prev}${digits}`);
    sendMultipleDigitsWithDelay(digits, acc, delayMs);
  };

  /**
   * This is useful when sending dtmf tones to a phone system that requires a little delay between digits
   */
  const sendMultipleDigitsWithDelay = (digits: string, acc: string, delayMs: number) => {
    if (digits.length === 0) {
      return;
    }
    const [digit, ...rest] = digits;
    state?.onClickDigit?.(digit, `${acc}${digit}`);
    setTimeout(() => {
      sendMultipleDigitsWithDelay(rest.join(""), `${acc}${digit}`, delayMs);
    }, delayMs);
  };
  return {
    state: {
      type: "SingleDigitDialer",
      currentState: accInput,
      onClickDigit: handleClickDigit,
      onClickMultipleDigits: handleClickMultipleDigits,
    },
    accInput,
  };
};

export default useSingleDigitDialPad;
