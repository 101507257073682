import { EmailIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { fmap } from "../../../../shared/utils";
import { sortByInstant } from "../../../../shared/utils/date-utils";
import { EmailTicket } from "../../utils/comm-center-email-utils";
import { formatDirection } from "../../utils/communication-utils";
import { CommunicationLogVerticalDivider } from "./CommunicationLog";

interface Props {
  ticket: EmailTicket;
}

const MAX_EMAILS_TO_SHOW = 3;
function getFirstMessageSenderAndRecipients(emailThread: EmailTicket["emailThread"]) {
  const orderedMessages = sortByInstant(emailThread.messages, "createdAt", "asc");
  const firstMessage = orderedMessages[0];

  const recipientEmails = new Set([
    ...firstMessage.recipients.map((r) => r.email),
    ...(firstMessage.cc !== null ? firstMessage.cc.map((x) => x.email) : []),
    ...(firstMessage.bcc !== null ? firstMessage.bcc.map((x) => x.email) : []),
  ]);

  let recipients = "";
  const recipientEmailArray = Array.from(recipientEmails);
  if (recipientEmailArray.length > MAX_EMAILS_TO_SHOW) {
    const firstRecipients = recipientEmailArray.slice(0, MAX_EMAILS_TO_SHOW).join(", ");
    recipients = `${firstRecipients} and ${recipientEmailArray.length - MAX_EMAILS_TO_SHOW} more`;
  } else {
    recipients = recipientEmailArray.join(", ");
  }
  return {
    sender: firstMessage.sender.email,
    recipients,
  };
}

const CommunicationLogEmailDetails = (props: Props) => {
  const { chatSummaryDetails } = props.ticket;
  const { sender, recipients } = getFirstMessageSenderAndRecipients(props.ticket.emailThread);

  const sentBy = `Sent by: ${sender}`;
  const sentTo = `Sent to: ${recipients}`;

  const summaryText =
    chatSummaryDetails !== null && chatSummaryDetails.summary !== null
      ? chatSummaryDetails.summary
      : "No summary available";

  return (
    <>
      <Flex alignItems="center" gap={2}>
        <EmailIcon />
        {fmap(props.ticket.emailThread, (thread) => (
          <Text>{formatDirection(thread.direction)}</Text>
        ))}
        <CommunicationLogVerticalDivider />
        <Text>{sentBy}</Text>
        <CommunicationLogVerticalDivider />
        <Text>{sentTo}</Text>
      </Flex>
      <Text>{summaryText}</Text>
    </>
  );
};

export default CommunicationLogEmailDetails;
