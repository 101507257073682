import { graphql } from "../../shared/schema/gql";

export const GET_CAREGIVERS_FULL_NAMES_QUERY = graphql(`
  query GetCaregiversFullNames($ids: CaregiverId_Filter!) {
    caregivers(id: $ids) {
      totalCount
      nodes {
        id
        displayId
        firstName
        lastName
      }
    }
  }
`);
