import angular from "angular";
import { PatientId } from "@medflyt/messages/ids";
import { LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment";
import { DateRangeDefaultOptions } from "public/admin/scripts/consts/dateRangeDefaultOptions";
import { Api } from "../../../../scripts/services/Api";
import { DatabaseApiService } from "../../../../scripts/services/db";
import { Endpoint } from "../../../../scripts/services/endpoint.service";
import { PatientBpComponentBindings, PatientBpDeviceAssignmentResponse, PatientBpDeviceModal, PatientBpHistoryResponse, PatientBpHistoryDatesFilter, PatientBpTableDataType } from "./patientbp.component.types";

const DefaultPatientBpDeviceModal: PatientBpDeviceModal = {
    currentImei: null,
    newImei: null,
    isNewImeiValid: true
}

//! @ngInject
class Controller implements angular.IComponentController, PatientBpComponentBindings {
    patientId!: PatientId;

    dateRange: PatientBpHistoryDatesFilter;
    patientBpHistoryData: PatientBpTableDataType[] = [];
    isLoadingPatientBpData = true;
    patientBpDevice: PatientBpDeviceModal = DefaultPatientBpDeviceModal;
    patientBpHistoryTable!: NgTableParams<PatientBpTableDataType>;
    showEditPatientBpDevice = false;

    constructor(
        private $rootScope: angular.IRootScopeService,
        private $scope: ng.IScope,
        private toaster: toaster.IToasterService,
        private api: Api,
        private endpoint: Endpoint,
        private DatabaseApi: DatabaseApiService,
        private NgTableParams: NgTable.ITableParamsConstructor<PatientBpTableDataType>,
        private dateRangeDefaultOptions: DateRangeDefaultOptions
      ) {
        this.dateRange = {
            from: LocalDate.now().minusMonths(1),
            to: LocalDate.now()
        }
    }
    
    $onInit(): void {
        this.fetchPatientBp();
    }
    
    fetchPatientBp = async () => {
        const datesFilterQuery = {
            from: this.dateRange.from.toString(),
            to: this.dateRange.to.toString()
        }

        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/patient/:patientId/patient_blood_pressure/history",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                patientId: this.patientId
            },
            queries: datesFilterQuery,
        });
      
        this.api.get<PatientBpHistoryResponse>(url)
            .then(({ data: { imei, history } }) => {
                this.patientBpHistoryData = this.formatBpHistoryData(history);
                this.isLoadingPatientBpData = false;
                this.patientBpDevice.currentImei = this.patientBpDevice.newImei = imei
                this.initPatientBpHistoryTable();
            }).catch((e) => {
                console.log(e)
                this.toaster.pop('error', 'Error while loading patient BP history: ' + e.data?.error);
            });
    }

    formatBpHistoryData = (history) => {
        return history.map((row) => {
            return {
                ...row,
                done_at: moment(new Date(row.done_at)).format("MM/DD/yyyy HH:mm:ss")
            }
        })
    }

    initPatientBpHistoryTable = () => {
        const options = { 
            count: 10,
            sorting: { done_at: "desc" } 
        }

        this.patientBpHistoryTable = new this.NgTableParams(options, {
            counts: [],
            dataset: this.patientBpHistoryData
        });
    }

    onPatientBpHistoryDateRangeChanged = (startDate, endDate) => {
        this.dateRange.from = this.momentToLocalDate(startDate);
        this.dateRange.to = this.momentToLocalDate(endDate);
        this.fetchPatientBp()
    }

    assignBpDeviceToPatient = async () => {
        this.patientBpDevice.isNewImeiValid = this.validateBpDeviceImei()
        if (!this.patientBpDevice.newImei || !this.patientBpDevice.isNewImeiValid) {
            this.patientBpDevice.newImei = this.patientBpDevice.currentImei
            this.toaster.pop('error', "Wrong Device IMEI format")
            return;
        }

        if (this.patientBpDevice.newImei !== null && this.patientBpDevice.currentImei !== this.patientBpDevice.newImei) {
            const url = this.endpoint({
                path: "agencies/:agencyId/agency_members/:agencyMemberId/patient/:patientId/patient_blood_pressure_device/assign/:imei",
                params: {
                    agencyId: this.$rootScope.agencyId,
                    agencyMemberId: this.$rootScope.agencyMemberId,
                    patientId: this.patientId,
                    imei: this.patientBpDevice.newImei
                }
            });
      
            try {
                const { data: { success } } = await this.api.put<PatientBpDeviceAssignmentResponse>(url)
                if (success) {
                    this.patientBpDevice.currentImei = this.patientBpDevice.newImei
                    this.toaster.pop('success', `Successfuly assigned blood pressure device ${this.patientBpDevice.newImei} to patient ${this.patientId}`)
                } else {
                    this.toaster.pop('error', "Something went wrong", "Could not assign bp device to patient");
                }
            } catch(e: any) {
                console.log(e)
                this.toaster.pop('error', 'Error while loading patient BP history: ' + e.data?.error);
            }
        }
    }

    unassignBpDeviceFromPatient = async () => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/patient/:patientId/patient_blood_pressure_device/unassign",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
                patientId: this.patientId,
            }
        });
        
        try {
            const { data: { success } } = await this.api.put<PatientBpDeviceAssignmentResponse>(url)
            if (success) {
                this.toaster.pop('success', `Successfuly unassigned blood pressure device ${this.patientBpDevice.currentImei} to patient ${this.patientId}`)
                this.patientBpDevice.currentImei = this.patientBpDevice.newImei = null
            } else {
                this.toaster.pop('error', "Something went wrong", "Could not unassign bp device to patient");                
            }
        } catch (err: any) {
            this.toaster.pop('error', 'Could not unassign blood pressure device!', err.data?.error)
        }
    }

    validateBpDeviceImei = () => {
        const imeiRegexp = new RegExp(/^[0-9]{15}$/)
        return this.patientBpDevice.newImei !== null && imeiRegexp.test(this.patientBpDevice.newImei)
    }

    momentToLocalDate = (date) => {
        return LocalDate.from(nativeJs(date))
    }
}

interface ComponentOptions extends angular.IComponentOptions {
    $name: string;
}

export const patientBpComponent: ComponentOptions = {
    $name: "patientBpComponent",
    templateUrl: "admin/modules/patient-bp/components/patient-bp/patient-bp.component.html",
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
        patientId: "<"
    },
  };