import { ThemeTypings } from "@chakra-ui/styled-system";
import { Messages } from "../../core/api";

export function getEntityStatusColorScheme(
  status: Messages["CaregiverStatus"] | Messages["PatientStatus"]
): ThemeTypings["colorSchemes"] {
  switch (status) {
    case "ACTIVE":
      return "green";
    case "JOIN_REQUEST":
      return "purple";
    case "ON_HOLD":
      return "yellow";
    case "ON_LEAVE":
      return "yellow";
    case "PENDING":
      return "gray";
    case "QUIT":
      return "gray";
    case "REJECTED":
      return "gray";
    case "SUSPENDED":
      return "gray";
    case "TERMINATED":
      return "gray";
    case "ACCEPTED":
      return "gray";
    case "DECEASED":
      return "gray";
    case "DISCHARGED":
      return "gray";
    case "DRAFT":
      return "gray";
    case "ELIGIBLE":
      return "gray";
    case "HOSPITALIZED":
      return "gray";
    case "PENDING_FILES":
      return "gray";
    case "REFERRAL":
      return "gray";
    case "VACATION":
      return "gray";
    case "LOST":
      return "gray";
  }
}
