import useImportantCalls from "../../hooks/useImportantCalls";
import MissedCallsContent from "./MissedCallsContent";
import RingingCallsContent from "./RingingCallsContent";
import RingingCallsContentError from "./RingingCallsContentError";
import RingingCallsContentLoading from "./RingingCallsContentLoading";

export default function ImportantCallsPopoverSwitch({
  ringingCallsQuery,
  missedCallsQuery,
}: ReturnType<typeof useImportantCalls>) {
  switch (ringingCallsQuery.status) {
    case "error":
      return (
        <RingingCallsContentError
          error={ringingCallsQuery.error}
          onRefresh={ringingCallsQuery.refetch}
        />
      );

    case "pending":
      return <RingingCallsContentLoading />;

    case "success": {
      if (ringingCallsQuery.data.calls.length === 0) {
        return <MissedCallsContent calls={missedCallsQuery.data?.calls ?? []} />;
      }

      return <RingingCallsContent calls={ringingCallsQuery.data.calls} />;
    }
  }
}
