import React from "react";

export function useMapSelection<K, V>(initialState?: Map<K, V> | (() => Map<K, V>)) {
  const [state, setStates] = React.useState<Map<K, V>>(
    typeof initialState === "function" ? new Map(initialState()) : new Map(initialState)
  );

  const add = React.useCallback((key: K, item: V) => {
    setStates((prev) => new Map(prev).set(key, item));
  }, []);

  const remove = React.useCallback((key: K) => {
    setStates((prev) => {
      const newMap = new Map(prev);
      newMap.delete(key);
      return newMap;
    });
  }, []);

  const toggle = React.useCallback((key: K, item: V) => {
    setStates((prev) => {
      const newMap = new Map(prev);
      if (newMap.has(key)) {
        newMap.delete(key);
      } else {
        newMap.set(key, item);
      }
      return newMap;
    });
  }, []);

  const clear = React.useCallback(() => {
    setStates(new Map());
  }, []);

  const isSelected = React.useCallback((key: K) => state.has(key), [state]);

  const selection = React.useMemo(() => Array.from(state.values()), [state]);

  const keys = React.useMemo(() => new Set(state.keys()), [state]);

  return { state: selection, add, remove, toggle, clear, isSelected, keys };
}
