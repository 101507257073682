import { PhoneIcon } from "@chakra-ui/icons";
import { Badge, keyframes } from "@chakra-ui/react";

const shake = keyframes`
  5%,
  45% {
    transform: rotate3d(0, 0, 1, -7deg);
  }
  10%,
  40% {
    transform: rotate3d(0, 0, 1, 7deg);
  }
  15%,
  25%,
  35% {
    transform: rotate3d(0, 0, 1, -7deg);
  }
  20%,
  30% {
    transform: rotate3d(0, 0, 1, 7deg);
  }
  51% {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 0, 0deg);
  }
`;

const RingingBadge = () => {
  return (
    <Badge
      alignItems="center"
      as="div"
      bg="green"
      color="white"
      display="flex"
      gap={1}
      paddingX={2}
      rounded="md"
      w="75px"
    >
      ringing
      <PhoneIcon animation={`${shake} 2s infinite cubic-bezier(.36, .07, .19, .97) both`} />
    </Badge>
  );
};

export default RingingBadge;
