import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { create, windowScheduler } from "@yornaath/batshit";
import { API, QueryParamsOf } from "../../core/api";
import { createQueryGroup } from "../../core/api/queries";
import { WorkflowDefinitionId, WorkflowTaskClusterId } from "../../shared/schema/schema";
import { getFullName } from "../../shared/utils/get-full-name";
import { memoize } from "../../shared/utils/memoize";

export default createQueryGroup(({ api }) => ({
  definitions: () => {
    return queryOptions({
      queryKey: ["workflow", "defintion", "list"],
      queryFn: () => api.get("./listed_workflows", {}),
      placeholderData: keepPreviousData,
    });
  },
  latestDefinitions: () => {
    return queryOptions({
      queryKey: ["workflow", "defintion", "latest"],
      queryFn: () => api.get("./latest_workflow_definitions", {}),
      placeholderData: keepPreviousData,
    });
  },
  definition: (id: WorkflowDefinitionId) => {
    return queryOptions({
      queryKey: ["workflow", "definition", "get", id],
      queryFn: () =>
        api.get("./workflow_definitions/:workflowDefinition", {
          path: { workflowDefinition: id },
        }),
    });
  },
  entityLayouts: () => {
    return queryOptions({
      queryKey: ["workflow", "entity_layout"],
      queryFn: () => api.get("./entity_layouts", {}),
      staleTime: Infinity,
    });
  },
  entitySearch: (query: QueryParamsOf<"get", "./entity_search">) => {
    return queryOptions({
      queryKey: ["workflow", "entity", "list", query],
      queryFn: () => api.get("./entity_search", { query }),
      placeholderData: keepPreviousData,
    });
  },
  entityName: (params: { entity: string; id: any }) => {
    return queryOptions({
      queryKey: ["workflow", "entity", "name", params],
      queryFn: () => entityBatcher(api).fetch({ entity: params.entity, id: params.id }),
    });
  },
  skills: (query?: { search?: string }) => {
    return queryOptions({
      queryKey: ["workflow", "skill", "list", query].filter(Boolean),
      queryFn: async () => {
        const { skills } = await api.get("./workflow_tasks/skills", {});

        if (typeof query?.search === "string") {
          const search = query.search.toLowerCase();

          return { skills: skills.filter((skill) => skill.id.toLowerCase().includes(search)) };
        }

        return { skills };
      },
    });
  },
  tasks: (query?: QueryParamsOf<"get", "./workflow_tasks/tasks">) => {
    return queryOptions({
      queryKey: ["workflow", "task", "list", query].filter(Boolean),
      queryFn: () => api.get("./workflow_tasks/tasks", { query: query ?? {} }),
    });
  },
  memberSkills: () => {
    return queryOptions({
      queryKey: ["workflow", "member_skill", "list"],
      queryFn: () => api.get("./workflow_tasks/agency_members_skills", {}),
    });
  },
  allMembersWithSkills: (query?: { search?: string }) => {
    return queryOptions({
      queryKey: ["workflow", "all_member_skill_assignment", "list", query].filter(Boolean),
      queryFn: async () => {
        const [{ agencyMembers }, { data: memberSkillEntries }] = await Promise.all([
          api.get("./agency_members", {}),
          api.get("./workflow_tasks/agency_members_skills", {}),
        ]);

        const memberSkills = new Map(memberSkillEntries);

        const rows = agencyMembers.map((member) => ({
          id: member.id,
          name: getFullName(member),
          skills: memberSkills.get(member.id) ?? [],
        }));

        if (typeof query?.search === "string") {
          const search = query.search;
          return { rows: rows.filter((x) => x.name.toLowerCase().includes(search.toLowerCase())) };
        }

        return { rows };
      },
    });
  },
  humanTaskNames: () => {
    return queryOptions({
      queryKey: ["workflow", "human_task", "list"],
      queryFn: () => api.get("./workflow_tasks/human_task_names", {}),
    });
  },
  assignments: () => {
    return queryOptions({
      queryKey: ["workflow", "assigned", "list"],
      queryFn: () => api.post("./workflow_task_viewer/assignments", {}),
    });
  },
  clusters: () => {
    return queryOptions({
      queryKey: ["workflow", "cluster", "list"],
      queryFn: () => api.get("./workflow_task_clusters", {}),
    });
  },
  clusteredTasks: ({
    cluster,
    limit,
  }: {
    cluster: { id: WorkflowTaskClusterId; value: string };
    limit?: number;
  }) => {
    return queryOptions({
      queryKey: ["workflow", "cluster", "get", cluster.id, "get", cluster.value, "tasks", "list"],
      queryFn: () =>
        api.get(
          "./workflow_task_clusters/:workflowTaskClusterId/get/:workflowTaskClusterValue/tasks",
          {
            query: {
              limit,
            },
            path: {
              workflowTaskClusterId: cluster.id,
              workflowTaskClusterValue: cluster.value,
            },
          }
        ),
    });
  },
}));

const entityBatcher = memoize((api: API) => {
  return create({
    fetcher: (entities: { entity: string; id: any }[]) => {
      return api.post("./workflow_entities/display_values", { body: { entities } });
    },
    resolver: (data, { entity, id }) => data.entities[entity][id] ?? null,
    scheduler: windowScheduler(10),
  });
});
