import { LocalDate } from "@js-joda/core";

//! @ngInject
export function approveCaregiverModalCtrl(
  $scope,
  $rootScope,
  $uibModalInstance,
  statuses,
  certifications,
  caregiver,
  DatabaseApi,
  toaster,
  SweetAlert,
  Analytics
) {
  window.scrollTo(0, 0);

  console.log("modal");

  $scope.phoneNumber = {
    correct: false,
    val: ""
  };

  $scope.statuses = statuses.map(status => ({ id: status.value, label: status.text }));
  $scope.certifications = certifications;
  $scope.offices = $scope.offices.map(office => ({ id: office.id, label: office.name }));
  $scope.caregiver = caregiver || {};
  $scope.selectedStatus = {};

  $scope.statuses.unshift({
    id: "JOIN_REQUEST",
    label: "Waiting membership request"
  });

  $scope.selectedStatus = {
    id: "JOIN_REQUEST",
    label: "Waiting membership request"
  };

  $scope.selectedOffices = [$scope.offices[0]];
  $scope.selectedCertifications = [];

  $scope.statusesExtraSettings = {
    selectionLimit: 1,
    showUncheckAll: false,
    showCheckAll: false,
    smartButtonMaxItems: 1,
    smartButtonTextConverter: function(itemText, originalItem) { return itemText; } 
  }

  $scope.officesExtraSettings = {
    smartButtonMaxItems: 3,
    smartButtonTextConverter: function(itemText, originalItem) { return itemText; } 
  };

  $scope.certificationsExtraSettings = {
    externalIdProp: '',
    smartButtonMaxItems: 3,
    smartButtonTextConverter: function(itemText, originalItem) { return itemText; } 
  };

  $scope.statusSelectEvents = {
    onItemSelect: function(selectedStatus) {
      let obj = $scope.statuses.find(status => status.id === selectedStatus.id);
      if (obj) {
        $scope.caregiver.status = obj.id;
      }
    }
  }

  $scope.closeModal = function() {
    $uibModalInstance.dismiss("cancel");
  };

  $scope.phoneNumberChange = function() {
    caregiver.phoneNumbers.forEach(function(p) {
      if (p.phoneNumber && p.phoneNumber.length > 10) {
        var last4 = p.phoneNumber.substr(p.phoneNumber.length - 4);
        if (last4 === $scope.phoneNumber.val) $scope.phoneNumber.correct = true;
      }
    });
  };

  $scope.submitStatus = function(visit) {
    if (!$scope.caregiver || !$scope.caregiver.id) return;

    SweetAlert.swal(
      {
        title: "Accept Caregiver?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, Accept",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if (isConfirm) {
          //console.log(caregiver.status);
          ///return;

          if ($scope.caregiver.status === "JOIN_REQUEST") return;
          //console.log(caregiver.status);
          const officeIds = $scope.selectedOffices.map(office => office.id);
          const certifications = $scope.selectedCertifications.map(certification => certification.label);
          const body = {
            effectiveDate: LocalDate.now(),
            note: null,
            status: $scope.caregiver.status,
            offices: officeIds,
            certifications: certifications,
            setEffectiveDateAsHireDate: false
          }

          var url =
            "agencies/" +
            $rootScope.agencyId +
            "/caregivers/" +
            $scope.caregiver.id +
            "/status";
          DatabaseApi.put(url, body).then(
            function(res) {
              Analytics.event("caregiver-status-edit", {
                cargiverId: $scope.caregiver.id,
                status: $scope.caregiver.status
              });

              toaster.pop("success", "Success", "Caregiver status changed!");
              DatabaseApi.updateCaregiversDict(() => {
                toaster.pop("error", "Error updating caregiver list, please refresh the page");
              });
              $uibModalInstance.close("changed");
            },
            function(err) {
              toaster.pop("error", "Something went wrong");
            }
          );

          /*
         var url = 'agencies/' + $rootScope.agencyId + '/visits/' + visit.id  + '/cancel';
         DatabaseApi.put(url).then(function(res){
         visit.assignedCaregiver = undefined;
         toaster.pop('success', "Success", 'Visit was canceled');
         $uibModalInstance.dismiss('cancel');

         }, function(err){
         toaster.pop('error', "Something went wrong", err.data);

         });
         */
        }
      }
    );
  };
};
