import { Avatar, Box, Flex } from "@chakra-ui/react";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { getGenderPhotoURL } from "../../../../../shared/utils/get-gender-photo-url";

export function VisitAssignmentListedTicketAvatar(props: {
  ticket: CommCenterTicket;
  unreadCount: number;
}) {
  return (
    <Flex
      align="center"
      borderColor={props.unreadCount > 0 ? "blue.600" : "transparent"}
      borderWidth={props.unreadCount > 0 ? "2px" : undefined}
      flexShrink={0}
      h={9}
      justify="center"
      position="relative"
      rounded="full"
      w={9}
    >
      <Box
        borderColor="transparent"
        borderWidth="2px"
        h="full"
        rounded="full"
        sx={{ ">*": { width: "full", height: "full" } }}
        w="full"
      >
        <VisitAssignmentListedTicketAvatarInner ticket={props.ticket} />
      </Box>
      <Box bottom={-2} position="absolute" right={-2}>
        <VisitAssignmentListedTicketAvatarUnreadBadge value={props.unreadCount} />
      </Box>
    </Flex>
  );
}

function VisitAssignmentListedTicketAvatarInner(props: { ticket: CommCenterTicket }) {
  switch (true) {
    case props.ticket.topic === "Caregiver" && props.ticket.relatedCaregiver !== null:
      return <Avatar h="full" src={props.ticket.relatedCaregiver.photoUrl ?? undefined} w="full" />;

    case props.ticket.topic === "Patient" && props.ticket.relatedPatient !== null:
      return (
        <Avatar h="full" src={getGenderPhotoURL(props.ticket.relatedPatient.gender)} w="full" />
      );

    case props.ticket.topic === "PhonebookContact" && props.ticket.relatedPhonebookContact !== null:
      return <Avatar h="full" name={props.ticket.relatedPhonebookContact.name} w="full" />;

    default:
      return <Avatar h="full" w="full" />;
  }
}

function VisitAssignmentListedTicketAvatarUnreadBadge(props: { value: number }) {
  if (props.value === 0) {
    return null;
  }

  return (
    <Flex
      _groupActive={{ outlineColor: "blue.50" }}
      _groupHover={{ outlineColor: "blue.50" }}
      align="center"
      bg="blue.600"
      color="white"
      fontSize="xs"
      fontWeight="semibold"
      h={5}
      justify="center"
      outline="2px solid white"
      rounded="full"
      shrink={0}
      w={5}
    >
      {props.value}
    </Flex>
  );
}
