import { Checkbox, Stack, Text, Tooltip } from "@chakra-ui/react";
import { ChecklistWithBlocking } from "../../shared/utils/intakeChecklist.utils";
import { UpdateChecklistItemParamsWithoutNoteId } from "./IntakeChecklist";

interface Props {
  item: ChecklistWithBlocking[number];
  onClickUpdateItem: (params: UpdateChecklistItemParamsWithoutNoteId) => void;
}

const getBlockersText = (blockers: Props["item"]["blockers"]): string[] => {
  return blockers.map((blocker) => blocker.name);
};

function IntakeChecklistItem(props: Props) {
  const handleCheckboxChange = (isChecked: boolean) => {
    props.onClickUpdateItem({
      checklistItemId: props.item.id,
      isCompleted: isChecked,
      createdByType: "agency_member",
    });
  };

  let tooltipLabel;
  const relevantBlockers = props.item.blockers.filter((blocker) => !blocker.isCompleted);
  const isBlocked = relevantBlockers.length > 0;
  if (isBlocked) {
    tooltipLabel = (
      <Stack>
        <Text>Blocked by: </Text>
        {getBlockersText(relevantBlockers).map((blockerText) => (
          <Text key={blockerText}>{blockerText}</Text>
        ))}
      </Stack>
    );
  } else if (props.item.hint !== undefined) {
    tooltipLabel = <Text>{props.item.hint}</Text>;
  }

  return (
    <Tooltip bg={isBlocked ? "red.500" : "blue.500"} label={tooltipLabel} placement="bottom-start">
      <div>
        <Checkbox
          alignItems="center"
          colorScheme="blue"
          fontWeight="normal"
          isChecked={props.item.isCompleted}
          isDisabled={!props.item.isCompleted && isBlocked}
          onChange={(e) => handleCheckboxChange(e.target.checked)}
        >
          {props.item.name}
        </Checkbox>
      </div>
    </Tooltip>
  );
}

export default IntakeChecklistItem;
