import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

export default function RemoveNoteCommentModal(props: {
  disclosure: ReturnType<typeof useDisclosure>;
  isLoading: boolean;
  onClickRemove: () => void;
}) {
  return (
    <Modal {...props.disclosure} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Remove comment</Text>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Text>Are you sure you want remove this comment?</Text>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="gray" onClick={props.disclosure.onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" isLoading={props.isLoading} onClick={props.onClickRemove}>
              Remove
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
