import { z } from "zod";
import { CommCenterTicketId, NoteSubjectId } from "../../../shared/schema/schema";

const LOCAL_STORAGE_KEY = "call-center-cote-cache";

const schema = z.array(
  z.tuple([
    z.number().transform(CommCenterTicketId.parse),
    z.object({
      text: z.string(),
      noteSubjectId: z.number().transform(NoteSubjectId.parse).nullable(),
    }),
  ])
);

const useCallCenterNoteCache = () => {
  const getStorageData = (): Map<
    CommCenterTicketId,
    { text: string; noteSubjectId: NoteSubjectId | null }
  > => {
    const emptyMap = new Map<
      CommCenterTicketId,
      { text: string; noteSubjectId: NoteSubjectId | null }
    >();
    const rawData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (rawData === null) {
      return emptyMap;
    }
    try {
      const parsedData = schema.parse(JSON.parse(rawData));
      return new Map<CommCenterTicketId, { text: string; noteSubjectId: NoteSubjectId | null }>(
        parsedData
      );
    } catch {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return emptyMap;
    }
  };

  const saveStorageData = (
    data: Map<CommCenterTicketId, { text: string; noteSubjectId: NoteSubjectId | null }>
  ) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify([...data.entries()]));
  };

  const getTicketNoteCacheText = (ticketId: CommCenterTicketId): string => {
    const data = getStorageData();
    return data.get(ticketId)?.text ?? "";
  };

  const getTicketNoteCacheSubjectId = (ticketId: CommCenterTicketId): NoteSubjectId | null => {
    const data = getStorageData();
    return data.get(ticketId)?.noteSubjectId ?? null;
  };

  const setTicketNoteCache = (
    ticketId: CommCenterTicketId,
    data: Partial<{ text: string; noteSubjectId: NoteSubjectId | null }>
  ): void => {
    const storageData = getStorageData();
    const currentCache = storageData.get(ticketId) ?? {
      noteSubjectId: null,
      text: "",
    };
    storageData.set(ticketId, {
      ...currentCache,
      ...data,
    });
    saveStorageData(storageData);
  };

  const clearTicketNoteCache = (ticketId: CommCenterTicketId) => {
    const storageData = getStorageData();
    storageData.delete(ticketId);
    saveStorageData(storageData);
  };

  return {
    getTicketNoteCacheText,
    setTicketNoteCache,
    getTicketNoteCacheSubjectId,
    clearTicketNoteCache,
  };
};

export default useCallCenterNoteCache;
