import { Center, Flex, Spinner } from "@chakra-ui/react";
import { noop } from "@chakra-ui/utils";
import useApi from "../../../../../shared/hooks/useApi";
import { CommCenterTicketId, VisitAssignmentId } from "../../../../../shared/schema/schema";
import ActiveTicket from "../../../../communication/components/ActiveTicket";
import useCommCenterTicket from "../../../../communication/hooks/useTicket";
import useTicketMessages from "../../../../communication/hooks/useTicketMessages";
import { VisitAssignmentErrorBox } from "./VisitAssignmentErrorBox";
import { VisitAssignmentTicketActions } from "./VisitAssignmentTicketActions";
import { VisitAssignmentTicketVisitInstanceRelation } from "./VisitAssignmentTicketVisitInstanceRelation";
import { VisitAssignmentTicketVisitBroadcastRelation } from "./VisitAssignmentTicketVisitBroadcastRelation";
import { useQueryClient } from "@tanstack/react-query";

export function VisitAssignmentActiveTicket(props: {
  ticketId: CommCenterTicketId;
  assignmentId: VisitAssignmentId;
  onCloseTicket: () => void;
}) {
  const queryClient = useQueryClient();
  const { queries } = useApi();
  const query = useCommCenterTicket(props.ticketId, { markAsRead: true });
  const { attachments, submitMessage } = useTicketMessages({});

  switch (query.status) {
    case "pending":
      return (
        <Center h="full" w="full">
          <Spinner />
        </Center>
      );
    case "error":
      return <VisitAssignmentErrorBox error={query.error} />;
    case "success":
      return (
        <Flex
          direction="column"
          sx={{
            containerType: "inline-size",
            "@container (min-width: 0)": {
              '[data-component="CompletedCallTicket"]': {
                flexDirection: "column",
                overflow: "auto",
              },
            },
            "@container (min-width: 640px)": {
              '[data-component="CompletedCallTicket"]': { flexDirection: "row" },
            },
          }}
          w="full"
        >
          <VisitAssignmentTicketActions
            assignmentId={props.assignmentId}
            isFetching={query.isFetching}
            ticket={query.data.ticket}
            onCloseTicket={props.onCloseTicket}
            onRefresh={() => {
              query.refetch();

              if (query.data.ticket.callInfo !== null) {
                queryClient.invalidateQueries(
                  queries.telephony.call(query.data.ticket.callInfo.id)
                );
              }
            }}
          />

          <VisitAssignmentTicketVisitInstanceRelation ticket={query.data.ticket} />

          <VisitAssignmentTicketVisitBroadcastRelation ticket={query.data.ticket} />

          <ActiveTicket
            attachments={attachments.state}
            ticket={query.data.ticket}
            onClickRemoveAttachment={attachments.remove}
            onSelectFile={attachments.add}
            onSubmitNewMessage={(message) => submitMessage(props.ticketId, message)}
            onSuccessCreateEmailTicket={noop}
          />
        </Flex>
      );
  }
}
