//! @ngInject
export function billingExportsCtrl($scope, NgTableParams, $state, itemSearchPageManager, toaster, $filter, SweetAlert, DatabaseApi, $rootScope, generalUtils, FilterUtils, selectionLogic, wildcard) {

    $scope.tableParams = null;
    $scope.globalFilter = { val: '' };
    $scope.eBillingProviderOptions = DatabaseApi.eBillingProviders().map(item => ({
        id: item, label: item
    }));
    $scope.adjustmentOptions = [{
        id: "true", label: "Adjustment"
    },
    { 
        id: "false", label: "Original" 
    }];
    $scope.selectedEBillingProviders = [];
    $scope.selectedIsAdjustment = [];


    function initialize() {
        initPageManager();
        $scope.pageManager.resetSearchParamsByUrl();
        
        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingExportsCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }
    }

    $scope.$on("$destroy", function() {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingExportsCtrl = angular.copy(filters);
    });

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/export_batches");

        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();

        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initSearchParam("exportBatchId", "", { placeholderValue: "", isEntity: true });
        $scope.pageManager.initSearchParamDeprecated("eBillingProvider", "", "e-billing-provider", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("isAdjustment", "", "isAdjustment", undefined, undefined, "");
        $scope.pageManager.initContractTypesParam();
    };

    var mapItems = function (items) {
        items.forEach(function (item) {
            item.isSelected = false;
            $scope.selectionLogic.initItem(item);
        });
    };

    var initSelectionLogic = function(items) {
        $scope.selectionLogic = selectionLogic.createNewLogic(function (item) {
            $scope.selectionLogic.addItemToCollection(item);
        });

        if (items) {
            items.forEach((item) => {
                $scope.selectionLogic.initItem(item);
            });
        }
    }

    var initTable = function (items) {
        initSelectionLogic();

        var hasItems = items && items.length;
        if (hasItems) {
            mapItems(items);
            // toaster.pop("success", items.length + " Export batches were loaded successfully");
        }

        var options = {
            count: 25
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    $scope.downloadAllSelectedFilesAsZip = function(){
        const now = new Date();

        const requestBody = {
            exportsIds: $scope.allExports.filter((exportFile) => exportFile.isSelected).map((selectedRow) => selectedRow.id)
        }

        const endPointUrl = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/export_batches/create_exports_zip_file",
            $rootScope.agencyId,
            $rootScope.agencyMemberId
          );

        DatabaseApi.post(endPointUrl, requestBody).then(res => {
            const formattedDateTime = $filter("mfShortTime")(now, ["withSeconds", "withDate"])
            const fileName = `exports_${$rootScope.user.agency.name.replaceAll(' ', '_').toLowerCase()}_${formattedDateTime}.zip`;

            generalUtils.downloadURI(res.data.zipFileUrl, fileName);
        }, function (error) {
            toaster.pop("error", "Failed to download files from cloud");
        });
    }

    $scope.toggleSelectionForAll = function(){
        $scope.selectionLogic.toggleAllItems();
    }

    $scope.toggleFileSelection = function(event, exportFileRow){
        exportFileRow.isSelected ? selectFileForDownload(event, exportFileRow) : unselectFileForDownload(event, exportFileRow);
    }

    const selectFileForDownload = function(event, exportFile){
        $scope.selectionLogic.onItemSelectionChanged(exportFile)
    }

    const unselectFileForDownload = function(event, exportFile){
        $scope.selectionLogic.onItemSelectionChanged(exportFile);
    }

    $scope.applyGlobalSearch = function (term) {
        var filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
            const allExports = $filter('filter')($scope.allExports, $scope.globalFilter.val);
            initSelectionLogic(allExports);
        }
    };

    $scope.getSignedUrl = function (row) {
        DatabaseApi.get("agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/export_batches/signed_url/" + row.id).then(res => {
            generalUtils.downloadURI(res.data.url, `medflyt-export-${row.contractName}-${row.displayId}`)
        }, function (error) {
            toaster.pop("error", "Failed to download file from cloud");
        });
    }

    $scope.loadItems = function () {
        $scope.globalFilter.val = '';
        $scope.pageManager.searchParams.eBillingProvider.value = $scope.selectedEBillingProviders.map(eBillingProvider => eBillingProvider.id).join('|');
        $scope.pageManager.searchParams.isAdjustment.value = $scope.selectedIsAdjustment.length === 1 ? $scope.selectedIsAdjustment[0].id : "";
        $scope.pageManager.executeSearch().then(function (response) {
            $scope.allExports = [...response.data.exportBatches];
            initTable(response.data.exportBatches);
        }, function (error) {
            toaster.pop("error", "Failed to load export batches");
        });
    };

    $scope.clickTableRow = function (item) {
        if (!item) return;
        $state.go('app.billing.export', { id: item.id, export: item })
    };

    $scope.openUploadedDocumentNewTab = function (url) {
        window.open(url).focus();
    }

    $scope.exportTable = function (selected) {
        $scope.loadingCSV = true;
        var rows = [];
        var titles = ['Export Batch ID', 'Export Date', 'Export Type', 'Number of Visits', 'Number of Claims', 'Export Errors', 'Contract Name', 'Total Billed Amount', 'Total payment', 'Office', 'Is Adjustment', 'File URL'];
        rows.push(titles);
        const items = selected ? $scope.selectionLogic.getSelectedItems() : $scope.selectionLogic.data.items;
        // Filter table data by global filter
        const allExports = $filter('filter')(items, $scope.globalFilter.val);
        allExports.forEach(dataRow => {
            var csvRow = [];
            titles.forEach(title => {
                let toPush = "";
                switch (title) {
                    case "Export Batch ID":
                        toPush = dataRow.displayId || '';
                        break;
                    case "Export Date":
                        toPush = $filter("mfShortTime")(dataRow.createdAt, ['withDate']) || '';
                        break;
                    case "Export Type":
                        toPush = dataRow.eBillingProvider || '';
                        break;
                    case "Number of Visits":
                        toPush = dataRow.visitsCount || 0;
                        break;
                    case "Number of Claims":
                        toPush = dataRow.claimsCount || 0;
                        break;
                    case "Export Errors":
                        toPush = dataRow.rejectedVisitsCount.length || 0;
                        break;
                    case "Contract Name":
                        toPush = dataRow.contractName || '';
                        break;
                    case "Total Billed Amount":
                        toPush = $filter("centsToDollars")(dataRow.billingSum || 0);
                        break;
                    case "Total payment":
                        toPush = $filter("centsToDollars")(dataRow.paymentSum || 0);
                        break;
                    case "Office":
                        toPush = dataRow.officeName || '';
                        break;
                    case "Is Adjustment":
                        toPush = dataRow.isAdjustment ? "Yes" : "No";
                        break;
                    case "File URL":
                        toPush = dataRow.fileUrl || '';
                        break;
                    default:
                        break;
                }
                csvRow.push("\"" + toPush + "\"");
            });

            rows.push(csvRow);
        });

        var csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    var getExportedFileName = function () {
        var filename = "medflyt-export-batches"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    $scope.deleteExport = function (id) {
        SweetAlert.swal({
            title: "Delete export",
            text: "Are you sure you want to delete this export?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                DatabaseApi.delete('agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId + '/export_batches/' + id).then(function (res) {
                        toaster.pop('success', "Success", "Deleted export successfully");
                        $scope.loadItems();
                    }, function (err) {
                        toaster.pop('error', "Something went wrong", "Failed to delete export");
                    })
            }
        });
    }

    $scope.adjustmentOptionExtraSetting = {
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: false,
        enableSearch: true,
    };

    initialize();

};