import { Box, Flex, Skeleton, Table, TableContainer, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { flexRender, Table as ReactTable } from "@tanstack/react-table";
import React from "react";
import { range } from "../../utils";
import { DataTableColumnFiltersRow, DataTableTheadText } from "./DataTableUI";

export function DataTableSkeleton<TData>(props: { table: ReactTable<TData> }) {
  return (
    <Box>
      <TableContainer>
        <Table>
          <DataTableHeadSkeleton table={props.table} />
          <DataTableBodySkeleton table={props.table} />
        </Table>
      </TableContainer>

      <DataTablePaginationSkeleton />
    </Box>
  );
}

function DataTablePaginationSkeleton() {
  return (
    <Flex align="center" gap={4} px={8} py={6}>
      <Skeleton height={10} rounded="md" width="137px" />
      <Skeleton height={10} rounded="md" width="107px" />
      <Skeleton height={6} rounded="md" width={24} />
    </Flex>
  );
}

function DataTableHeadSkeleton<TData>(props: { table: ReactTable<TData> }) {
  return (
    <Thead>
      {props.table.getHeaderGroups().map((headerGroup) => (
        <React.Fragment key={headerGroup.id}>
          <Tr>
            {headerGroup.headers.map((header) => (
              <Th key={header.id} p={2}>
                <DataTableTheadText>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </DataTableTheadText>
              </Th>
            ))}
          </Tr>
          {props.table.options.enableColumnFilters && (
            <DataTableColumnFiltersRow>
              {headerGroup.headers.map((header) => (
                <Td key={header.id} p={2}>
                  {header.column.columnDef.meta?.gqlFilterKey !== undefined && (
                    <Skeleton height={10} rounded="md" />
                  )}
                </Td>
              ))}
            </DataTableColumnFiltersRow>
          )}
        </React.Fragment>
      ))}
    </Thead>
  );
}

function DataTableBodySkeleton<TData>(props: { table: ReactTable<TData> }) {
  return (
    <Thead>
      {props.table.getHeaderGroups().map((headerGroup) =>
        range(props.table.getState().pagination.pageSize).map((i) => (
          <Tr key={`${headerGroup.id}_${i}`}>
            {headerGroup.headers.map((header) => (
              <Td key={header.id} py={4}>
                <Skeleton height={6} minW={6} rounded="full" />
              </Td>
            ))}
          </Tr>
        ))
      )}
    </Thead>
  );
}
