import { LocalDateTime } from "@js-joda/core";
import React from "react";
import { isFunction } from "../utils";
import { parseFromStorage } from "../utils/storage";
import { useDebounce } from "./useDebounce";

type CacheKey = unknown[];

type State<T> = {
  value: T;
  cachedAt: LocalDateTime | null;
};

export function useStorageState<T>(params: {
  key: CacheKey;
  initialState: () => T;
  debounce: number;
  storage: Storage;
}) {
  const serializedKey = React.useMemo(() => JSON.stringify(params.key), [params.key]);
  const isDirty = React.useRef(false);

  const initialStorageState = React.useMemo(() => {
    return parseFromStorage<State<T>>({ key: serializedKey, storage: params.storage });
  }, [params.storage, serializedKey]);

  const [value, setValue] = React.useState<T>(() => {
    return initialStorageState?.value ?? params.initialState();
  });

  const [cachedAt, setCachedAt] = React.useState<LocalDateTime | null>(
    initialStorageState?.cachedAt ?? null
  );

  const debouncedState = useDebounce(value, params.debounce);

  const onChange = (updater: React.SetStateAction<T>) => {
    isDirty.current = true;
    setValue((prevState) => (isFunction(updater) ? updater(prevState) : updater));
  };

  const removeCache = () => {
    params.storage.removeItem(JSON.stringify(params.key));
    setCachedAt(null);
  };

  const resetState = () => {
    setValue(params.initialState());
  };

  const reset = () => {
    removeCache();
    resetState();
  };

  React.useEffect(() => {
    if (!isDirty.current) {
      return;
    }

    const newState: State<T> = {
      value: debouncedState,
      cachedAt: LocalDateTime.now(),
    };

    params.storage.setItem(serializedKey, JSON.stringify(newState));
    setCachedAt(newState.cachedAt);
  }, [debouncedState, params.storage, serializedKey]);

  return [
    value,
    onChange,
    { initialState: initialStorageState, cachedAt, removeCache, resetState, reset },
  ] as const;
}
