import { Tag } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import { isOneOf } from "../../../shared/utils/common";
import { zNoteStatus } from "../utils/note-form";

export default function NoteStatusTag(props: { status: Messages["EntityNote"]["status"] }) {
  if (!isOneOf(props.status, zNoteStatus.options)) {
    return <Tag colorScheme="gray">Unknown</Tag>;
  }

  switch (props.status) {
    case "DONE":
      return <Tag colorScheme="green">Done</Tag>;
    case "IN_PROGRESS":
      return <Tag colorScheme="yellow">In progress</Tag>;
    case "NONE":
      return <Tag colorScheme="gray">No status</Tag>;
    case "VIEWED":
      return <Tag colorScheme="blue">Viewed</Tag>;
  }
}
