export function isDescendantOfButton(element: HTMLElement | null): boolean {
  if (element == null) {
    return false;
  }

  if (element.tagName.toLowerCase() === "button") {
    return true;
  }

  return isDescendantOfButton(element.parentElement);
}
