import useApi from "../../../shared/hooks/useApi";
import { PatientId } from "../../../shared/schema/schema";
import { useQuery } from "@tanstack/react-query";
import SendSMSButton from "../../../shared/components/SendSMSButton";
import { ResponseOf } from "../../../core/api";
import { MessageTemplate } from "../../../shared/components/SmsSender/sms-sender.types";
import { ButtonProps } from "@chakra-ui/react";

function isPatientSmsable(patient: ResponseOf<"get", "/agencies/:agencyId/patients/:patientId">) {
  return (
    patient.phoneNumbers.some((phoneNumber) => phoneNumber.isSmsable) ||
    patient.contacts?.some((contact) => contact.mobilePhoneNumberSmsable)
  );
}

/** Prefer using IntakePatientSendSMSButton over this when you know it's an intake patient */
export default function PatientSendSMSButton(props: {
  patientId: PatientId;
  predefinedPhoneNumber?: string;
  predefinedSmsMessageTemplate?: MessageTemplate;
  buttonProps?: ButtonProps;
  text?: string;
}) {
  const { queries } = useApi();

  const patient = useQuery(queries.patient.get(props.patientId));

  if (patient.isError) {
    return (
      <SendSMSButton
        buttonProps={props.buttonProps}
        entity="Patient"
        isDisabled={true}
        isLoading={false}
        patientId={props.patientId}
        predefinedMessageTemplate={props.predefinedSmsMessageTemplate}
        predefinedPhoneNumber={props.predefinedPhoneNumber}
        text="Error while loading SMS button"
      />
    );
  }

  const isSmsButtonDisabled = !patient.isSuccess || !isPatientSmsable(patient.data);

  return (
    <SendSMSButton
      buttonProps={props.buttonProps}
      entity="Patient"
      isDisabled={isSmsButtonDisabled}
      isLoading={patient.isLoading}
      patientId={props.patientId}
      predefinedMessageTemplate={props.predefinedSmsMessageTemplate}
      predefinedPhoneNumber={props.predefinedPhoneNumber}
      text={props.text}
    />
  );
}
