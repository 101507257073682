import { IntakeStepFormField } from "../../pages/flow-settings/flow-settings.types";

export function shouldShowOptionsForQuestion<T extends Pick<IntakeStepFormField, "type">>(
  value: T
): value is Extract<T, { type: "options" | "multiselect" }> {
  return isOptionsQuestion(value) || isMultiselectQuestion(value);
}

export function isOptionsQuestion<T extends Pick<IntakeStepFormField, "type">>(
  value: T
): value is Extract<T, { type: "options" }> {
  return value.type === "options";
}

export function isMultiselectQuestion<T extends Pick<IntakeStepFormField, "type">>(
  value: T
): value is Extract<T, { type: "multiselect" }> {
  return value.type === "multiselect";
}
