import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import useAuth from "../../shared/hooks/useAuth";
import { createApi } from "./api-utils";
import { createMutations } from "./mutations";
import { createQueries } from "./queries";

export const ApiContext = React.createContext<{
  api: ReturnType<typeof createApi>;
  mutations: ReturnType<typeof createMutations>;
  queries: ReturnType<typeof createQueries>;
}>(null as any);

export const ApiProvider = (props: { children: React.ReactNode }) => {
  const { authData, logout, handleTokenRefresh } = useAuth();
  const [api] = React.useState(() => {
    if (authData.type !== "authenticated") {
      throw new Error(`Can't create API without auth data (type: ${authData.type})`);
    }

    return createApi({
      baseUrl: import.meta.env.API_URL,
      auth: {
        data: {
          agencyId: authData.data.agency.id,
          agencyMemberId: authData.data.agencyMember.id,
        },
      },
      onTokenRefreshed: handleTokenRefresh,
      onInvalidRefreshToken: () => {
        console.log("[withApi]: invalid refresh token. logging out");
        logout(() => window.location.reload());
      },
    });
  });

  const queryClient = useQueryClient();
  const queries = React.useMemo(() => createQueries({ api, queryClient }), [api, queryClient]);
  const mutations = createMutations({ api });

  return (
    <ApiContext.Provider value={{ api, queries, mutations }}>{props.children}</ApiContext.Provider>
  );
};
