import { SearchIcon } from "@chakra-ui/icons";
import { Button, Center, Flex, Text } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../core/api";
import ErrorBox from "../../../shared/components/ErrorBox";
import useApi from "../../../shared/hooks/useApi";
import { QueryParams } from "../../../shared/hooks/useQueryParams";
import { useSelection } from "../../../shared/hooks/useSelection";
import { NoteCommentId, NoteId, PatientId } from "../../../shared/schema/schema";
import { useExportEntityNotesMutation } from "../hooks/useExportEntityNotesMutation";
import { filterEntityNotes } from "../utils/entity-note.utils";
import EntityNoteBox from "./EntityNoteBox";
import EntityNoteBoxSkeleton from "./EntityNoteBoxSkeleton";
import { EntityNoteFilters } from "./EntityNotes";

type Props = {
  patientId: PatientId;
  selection: ReturnType<typeof useSelection<Messages["EntityNote"]>>;
  filters: QueryParams<EntityNoteFilters>;
  onClickEmail: (noteId: Messages["EntityNote"]) => void;
  onClickFax: (noteId: Messages["EntityNote"]) => void;
};

export default function PatientNotesBox(props: Props) {
  const queryClient = useQueryClient();
  const { queries } = useApi();
  const [maxNotes, setMaxNotes] = React.useState(5);

  const query = useQuery({
    ...queries.note.patientNotes(props.patientId),
    enabled: props.selection.state.length === 0,
  });

  const handleRemoveComment = (comment: { id: NoteCommentId; noteId: NoteId }) => {
    queryClient.setQueryData(queries.note.patientNotes(props.patientId).queryKey, (prev) => {
      if (prev === undefined) return undefined;

      return {
        list: prev.list.map((note) => {
          return note.id === comment.noteId
            ? { ...note, comments: note.comments.filter((c) => c.id !== comment.id) }
            : note;
        }),
      };
    });
  };

  const exportNotesMutation = useExportEntityNotesMutation({
    entity: { type: "Patient", id: props.patientId },
  });

  switch (query.status) {
    case "pending":
      return (
        <Flex direction="column" gap={6}>
          <EntityNoteBoxSkeleton />
          <EntityNoteBoxSkeleton />
          <EntityNoteBoxSkeleton />
          <EntityNoteBoxSkeleton />
          <EntityNoteBoxSkeleton />
        </Flex>
      );
    case "error":
      return <ErrorBox error={query.error} resetErrorBoundary={query.refetch} />;
    case "success": {
      const filtered = filterEntityNotes({
        items: query.data.list,
        filters: props.filters,
        maxNotes,
      });

      if (filtered.length === 0) {
        return (
          <Center bg="gray.50" color="gray.600" gap={2} m={4} p={8} rounded="md">
            <SearchIcon />
            <Text fontSize="md" fontWeight="semibold" textTransform="uppercase">
              No notes found
            </Text>
          </Center>
        );
      }

      return (
        <Flex direction="column" gap={6}>
          {filtered.map((note) => (
            <EntityNoteBox
              key={note.id}
              isActionLoading={exportNotesMutation.isPending}
              isSelected={props.selection.isSelected(note)}
              note={note}
              onClickEmail={props.onClickEmail}
              onClickExport={(noteId) => exportNotesMutation.mutate({ noteIds: [noteId] })}
              onClickFax={props.onClickFax}
              onRemoveComment={handleRemoveComment}
              onSuccessComment={query.refetch}
              onSuccessEdit={query.refetch}
              onSuccessRemove={query.refetch}
              onToggleSelect={props.selection.toggle}
            />
          ))}

          <Center display={filtered.length >= maxNotes ? undefined : "none"} p={2}>
            <Button variant="outline" onClick={() => setMaxNotes((x) => x + 5)}>
              Show more
            </Button>
          </Center>
        </Flex>
      );
    }
  }
}
