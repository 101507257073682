import { DayOfWeek, LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment"

//! @ngInject
export function officeRestrictionSetupModalCtrl(
  $scope,
  $rootScope,
  $uibModalInstance,
  data,
  toaster,
  mfModal,
  DatabaseApi,
  wildcard,
) {

  $scope.existingId = data.id;

  $scope.offices = [];
  getOffices();
  
  $scope.form = {
    startTime: {
      data: data.startTime ? LocalDate.from(nativeJs(moment(data.startTime))) :  LocalDate.now(),
      error: false
    },
    endTime: {
      data: data.endTime ? LocalDate.from(nativeJs(moment(data.endTime))) : LocalDate.now().plusDays(7),
      error: false
    },
    officeId: {
      data: data.officeId || $scope.offices[0].id,
      error: false
    },
    hoursPerWeek: {
      data: data.hoursPerWeek || 0,
      error: false
    }
  };

  function getOffices() {
    $scope.offices = DatabaseApi.activeOffices();
    if (!$scope.offices || $scope.offices.length === 0) {
        const url = wildcard(`agencies/:agencyId/agency_members/:agencyMemberId/offices`, $rootScope.agencyId, $rootScope.agencyMemberId)
        DatabaseApi.get(url).then((res) => {
            $scope.offices = res.data.offices.filter(x => x.active);
            DatabaseApi.storeOffices(res.data.offices);
        }, (err) => {
            toaster.pop('error', "Something went wrong", "could not get offices");
        });
    }
  }

  function validateForm () {
    const errors = [];
    
    if (!$scope.form.startTime.data) {
      errors.push({
        field: "startTime",
        message: "Please enter a start date"
      });
    } else if ($scope.form.startTime.data.dayOfWeek() !== DayOfWeek.SUNDAY) {
      errors.push({
        field: "startTime",
        message: "Start date should be a Sunday"
      });
    }

    if (!$scope.form.endTime.data) {
      errors.push({
        field: "endTime",
        message: "Please enter a end time"
      });
    } else if ($scope.form.endTime.data.dayOfWeek() !== DayOfWeek.SATURDAY) {
      errors.push({
        field: "endTime",
        message: "End date should be a Saturday"
      });
    }

    if (!$scope.form.officeId.data || $scope.form.officeId.data === 0) {
      errors.push({
        field: "officeId",
        message: "Please enter an office id"
      })
    }

    if (!$scope.form.hoursPerWeek.data || $scope.form.hoursPerWeek.data <= 0) {
      errors.push({
        field: "hoursPerWeek",
        message: "Hours per week should be more than 0"
      })
    }
    return errors;
  }

  $scope.submitForm = function () {
    console.log($scope.form);
    const formFields = Object.keys($scope.form); 
    formFields.forEach((field) => $scope.form[field].error = false);

    const errors = validateForm();
    errors.forEach((error) => $scope.form[error.field].error = true);
    const errorMessage = errors.map(error => error.message).join("\n");

    if (errors.length !== 0) {
      return mfModal.createSimple({
        subject: "Error",
        message: errorMessage,
        variant: "danger",
      });
    }

    if (data.id) {
      // Edit
      const url = wildcard(
        "agencies/:agencyId/agency_member/:agencyMemberId/offices/:officeId/caregiver_hours_restrictions/:restrictionId",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        data.officeId,
        data.id
      );
      DatabaseApi.put(
        url,
        {
          ...data,
          startTime: $scope.form.startTime.data,
          endTime: $scope.form.endTime.data,
          hoursPerWeek: $scope.form.hoursPerWeek.data
        }
      ).then(() => {
        toaster.pop("success", "Office restriction saved");
          $scope.closeModal();
      }).catch((error) => {
        if (error.data) {
          toaster.pop("error", "Something went wrong", error.data.details ?? error.data.error)
        } else {
          toaster.pop(
            "error",
            "Something went wrong",
            "Could not update office restriction settings"
          );
        }
      })
    } else {
      // Create
      const url = wildcard(
        "agencies/:agencyId/agency_member/:agencyMemberId/offices/:officeId/caregiver_hours_restrictions",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        $scope.form.officeId.data,
      );
        DatabaseApi.post(
          url,
          {
            hoursPerWeek: $scope.form.hoursPerWeek.data,
            startTime: $scope.form.startTime.data,
            endTime: $scope.form.endTime.data,
          }
        ).then(() => {
          toaster.pop("success", "Office hours restriction saved");
          $scope.closeModal();
        })
        .catch((error) => {
          if (error.data) {
            toaster.pop("error", "Something went wrong", error.data.details ?? error.data.error)
          } else {
            toaster.pop("error", "Something went wrong", "Could not add office hours restriction");
          }
        });
    }
  };

  $scope.onClickRemoveOfficeHoursRestriction = function (officeId, restrictionId) {
    const modal = mfModal.create({
      subject: "Delete Office Hours Restricion",
      message: `Are you sure you want to delete this record?`,
      layoutOrder: ["message", "options"],
      cancelLabel: "Cancel",
      confirmLabel: "Delete",
      variant: "danger",
      onConfirm: () => {
          modal.setLoading(true);

          const url = wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/offices/:officeId/caregiver_hours_restrictions/:restrictionId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            officeId,
            restrictionId
          )

          DatabaseApi.delete(url).then(async () => {
              toaster.pop("success", "Office hours restrictions deleted");
              modal.close();
              $scope.closeModal();
          }, (error) => {
              if (error.data) {
                toaster.pop("error", "Something went wrong", error.data.details ?? error.data.error)
              } else {
                toaster.pop("error", "Something went wrong", "Can't delete office hours restriction");
              }
          }).finally(() => {
              modal.setLoading(false);
          });
      },
  });
  }

  $scope.resetError = function (field) {
    $scope.form[field].error = false;
  };

  $scope.closeModal = function () {
    $uibModalInstance.close("ok");
  };

  $scope.exit = function () {
    $uibModalInstance.dismiss();
  };
};
