import { Input, InputProps } from "@chakra-ui/react";
import { ComponentProps } from "react";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";

const maskGenerator = createDefaultMaskGenerator("(999) 999-9999");

const PhoneNumberInput = (
  props: Omit<InputProps, "value" | "onChange"> & ComponentProps<typeof MaskedInput>
) => {
  return (
    <Input
      {...props}
      as={MaskedInput}
      maskGenerator={maskGenerator}
      type="text"
      value={props.value}
      onChange={(e) => props.onChange?.(e as unknown as string)}
    />
  );
};

export default PhoneNumberInput;
