import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import PatientIntakeFlowStepHeadline from "./PatientIntakeFlowStepHeadline";

export default function PatientIntakeFlowStepSection(props: {
  title: string;
  script: string;
  externalFlowName: string | null;
}) {
  return (
    <Box>
      <Flex alignItems="baseline" justifyContent="space-between">
        <PatientIntakeFlowStepHeadline>{props.title}</PatientIntakeFlowStepHeadline>
        {props.externalFlowName !== null && (
          <Tag colorScheme="blue">External - {props.externalFlowName}</Tag>
        )}
      </Flex>
      <Box bg="gray.50" fontSize="xl" lineHeight="taller" p={4}>
        <Text whiteSpace="break-spaces">{props.script}</Text>
      </Box>
    </Box>
  );
}
