import { UseDisclosureReturn } from "@chakra-ui/react";
import React from "react";

export default function useAskForConfirmation(disclosure: UseDisclosureReturn) {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [onClose, setOnClose] = React.useState<(val: boolean) => void>();

  const handleOpen = (title: string, description: string, onClose: (value: boolean) => void) => {
    setTitle(title);
    setDescription(description);
    setOnClose(() => onClose);
    disclosure.onOpen();
  };

  const handleClose = (confirm: boolean) => {
    onClose?.(confirm);
    disclosure.onClose();
  };

  return {
    onOpen: handleOpen,
    onClose: handleClose,
    description,
    title,
    isOpen: disclosure.isOpen,
  };
}
