import { LocalDate, DayOfWeek,convert } from "@js-joda/core";
import _ from "lodash";

//! @ngInject
export function authorizationSetupModalCtrl($scope, $timeout, $rootScope, $uibModalInstance, data, DatabaseApi, toaster, serviceCodes, patientId, $http, Consts, entityNoteService, noteConsts, wildcard) {
    $scope.visitHoursRestrictionError = {
        error: false,
        conflicts: []
    }
    $scope.serviceCodes = serviceCodes;
    $scope.currContractServiceCode = [];
    $scope.dailyHours = {
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: ''
    };
    $scope.weeklyRestrictions = [];
    if (data && data.restrictions) {
        data.restrictions.forEach((restriction, i) => {
            $scope.weeklyRestrictions[i] = {
                numDays: restriction.numDays,
                maxHours: restriction.maxMinutesPerDay / 60
            };
        });
    }

    $scope.showInactivePatientContracts = false;
    $scope.patientContracts.forEach(pc => {
        pc.isActive = (pc.endDate === null || pc.endDate === undefined || LocalDate.parse(pc.endDate).isAfter(LocalDate.now()))
            && !pc.isOnHold;
    });
    
    const patientContractOptions = $scope.patientContracts;
    $scope.patientContracts = $scope.patientContracts.filter(c => c.isActive === true);

    $scope.calendarPopups = {};
    $scope.editMode = _.isEmpty(data) ? false : true;
    $scope.authModel = {};
    $scope.isDraft = false;
    if(data && data.isDraft){
        $scope.isDraft = data.isDraft;
        
        
    }
    $scope.isLoading = true;
    $scope.isNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.PATIENT_AUTHORIZATION);

    $scope.dateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    }

    $scope.onCloseHoursRestrictionErrorModal = () => {
        $timeout(() => {
            $scope.visitHoursRestrictionError = {
                error: false,
                conflicts: []
            };
        });
    };

    $scope.openHoursRestrictionsErrorModal = (conflicts) => {
        $timeout(() => {
            $scope.visitHoursRestrictionError = {
                error: true,
                conflicts
            };
        })
    };

    function init() {
      
        loadContractServiceCodes().then(res => {
          
            
            if ($scope.editMode) {
                initAuthModel(data);
                prepareAuthModelData();
            } else {
                populateAuthModelWithDays();
            }

            if (data.isFromHHAIntegration) {
                $scope.isFromHHAIntegration = true;
                initIcdCodes(data.dxCodes);
            }

            $scope.isLoading = false;
        });
    }

    function loadContractServiceCodes() {
        

        return DatabaseApi.get('agencies/' + $rootScope.agencyId + '/contract_service_codes/' + (patientId === null ? -1 : patientId)).then(function (res) {
            $scope.allContractsServiceCodesIds = res.data.contractServiceCodes;
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not load contracts and service codes");
        });
    
    }

    function initAuthModel(data) {
        $scope.authModel = angular.copy(data);
        $scope.authModel.monday = $scope.authModel.monday || $scope.authModel.mondayMinutes / 60;
        $scope.authModel.tuesday = $scope.authModel.tuesday || $scope.authModel.tuesdayMinutes / 60;
        $scope.authModel.wednesday = $scope.authModel.wednesday || $scope.authModel.wednesdayMinutes / 60;
        $scope.authModel.thursday = $scope.authModel.thursday || $scope.authModel.thursdayMinutes / 60;
        $scope.authModel.friday = $scope.authModel.friday || $scope.authModel.fridayMinutes / 60;
        $scope.authModel.saturday = $scope.authModel.saturday || $scope.authModel.saturdayMinutes / 60;
        $scope.authModel.sunday = $scope.authModel.sunday || $scope.authModel.sundayMinutes / 60;
        $scope.authModel.maxHours = $scope.authModel.maxHours || $scope.authModel.maxMinutes / 60;
        $scope.authModel.hours = $scope.authModel.hours || $scope.authModel.minutes / 60;
        $scope.authModel.firstDayOfWeek = DayOfWeek.MONDAY;        
        if (!$scope.patientContracts.find(contract => contract.contractId === data.patientContractId)) {
            $scope.showInactivePatientContracts = true;
            $scope.handleClickShowInactiveContracts();
        }
        if($scope.isDraft){
            
            $scope.authModel.authCode = "TEMP";
            $scope.authCode = "TEMP";
            $scope.authModel.periodType = "WEEKLY";
            $scope.authModel.maxHours = 99999;
            $scope.authModel.startDate = new Date();                        
            $scope.authModel.endDate = convert(LocalDate.of($scope.authModel.startDate.getFullYear(), $scope.authModel.startDate.getMonth(), $scope.authModel.startDate.getDate()).plusMonths(3)).toDate();
            $scope.authModel.patientContractId = $scope.patientContracts[0].id;
            $scope.onPatientContractChange($scope.authModel.patientContractId);
            return;
        }
        
        $scope.onPatientContractChange(data.patientContractId);
    }

    $scope.onPatientContractChange = function (patientContractId) {
        
        const currContractServiceCodeIds = $scope.allContractsServiceCodesIds
            .filter((item) => item.contractId === patientContractId)
            .map((item) => item.serviceCodeId);
        $scope.currContractServiceCodes = $scope.serviceCodes.filter(
            (serviceCode) => currContractServiceCodeIds.includes(serviceCode.id)
        );

        const contractTypeId = $scope.patientContracts.find(
            ({ id }) => id === patientContractId
        )?.contractTypeId;
        if (contractTypeId === undefined) return;

        const contractTypeStartOfWeek = DatabaseApi.contractTypes().find(
            ({ id }) => id === contractTypeId
        )?.startOfAuthWeek;
        if (contractTypeStartOfWeek === undefined) return;

        $scope.authModel.firstDayOfWeek = contractTypeStartOfWeek;
    };

    $scope.submitForm = function () {
        if ($scope.authModel.periodType === 'WEEKLY' && $scope.weeklyRestrictions.length > 0) {
            $scope.validateWeeklyRestrictions();
        }

        if ($scope.disabled) return;

        $scope.disabled = true;
        const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(data.note, noteConsts.NoteTypes.PATIENT_AUTHORIZATION);
        if ($scope.form.$invalid || !isNoteValid) {
            if (!isNoteValid) {
                $scope.noteValidations = { isPredefinedValid, isMessageValid };
            }

            $scope.disabled = false;
            return;
        }

        var authRequest = enrichAuthorizationForm();
     
        if (!$scope.isDraft && $scope.editMode && !$scope.integrationAuth) {
            $http.put(Consts.api +
                "agencies/" + $rootScope.agencyId + "/authorization/" + data.id,
                authRequest
            ).then(
                function () {
                    $scope.disabled = false;
                    toaster.pop("success", "Authorization saved");
                    $scope.getAuthorizations && $scope.getAuthorizations();
                    $scope.closeModal();
                    $scope.$resolve.onEditAuthorization && $scope.$resolve.onEditAuthorization();
                    $rootScope.$broadcast('refresh_visits');
                },
                function (res) {
                    $scope.disabled = false;
                    const { data: { error, conflicts } } = res;
                    const hasPaid = error === "AUTH_HAS_PAID_VISITS";
                    const hasBilled = error === "AUTH_HAS_BILLED_VISITS";
                    const hasVisitHoursError = error === "VISIT_HOURS_RESTRICTION_CONFLICT";

                    if (hasVisitHoursError) {
                        return $scope.openHoursRestrictionsErrorModal(conflicts)
                    }

                    if (hasPaid || hasBilled) {
                        const word = hasPaid ? "paid" : "billed";
                        toaster.pop(
                            "Permission error",
                            "Couldn't edit authorization",
                            `There are ${word} visits in this authorization. You are not permitted to edit it.`,
                        );
                        return;
                    }

                    toaster.pop(
                        "error",
                        "Something went wrong",
                        "Could not edit authorization",
                    );
                }
            );
        } else {
            const url = wildcard(
				"agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/weekly_template_shifts",
				$rootScope.agencyId,
				$rootScope.agencyMemberId,
				patientId
			);
            DatabaseApi.get(url).then(weeklyTemplateRes => {
                let successMessage = "Authorization saved.";
                let popupVariant = "success";
                if (weeklyTemplateRes.data.shifts.length === 0) {
                    successMessage += " you are missing a weekly template, no visits will be added on the calendar"
                    popupVariant = "warning";
                }
                DatabaseApi.post(
                'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/authorizations', authRequest).then(function (res) {
                    $scope.disabled = false;
                    toaster.pop(popupVariant, successMessage);
                    $scope.getAuthorizations && $scope.getAuthorizations();
                    $scope.closeModal();
                    $scope.$resolve.onCreatedAuthorization && $scope.$resolve.onCreatedAuthorization();
                }, function (err) {
                    const {data: {error, conflicts}} = err;
                    const hasVisitHoursError = error === "VISIT_HOURS_RESTRICTION_CONFLICT";

                    $scope.disabled = false;
                    if (hasVisitHoursError) {
                        return $scope.openHoursRestrictionsErrorModal(conflicts)
                    } else {
                        toaster.pop('error', "Something went wrong", "could not add authorization");
                    }
                });
            });
        }
    };

    $scope.toggleCalendarPopups = function (prop) {
        $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
    }

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };

    $scope.addRestriction = function() {
        $scope.weeklyRestrictions.push({
            numDays: '',
            maxHours: ''
        });
    };

    $scope.removeRestriction = function(index) {
        $scope.weeklyRestrictions.splice(index, 1);
        $scope.validateWeeklyRestrictions();
    };

    $scope.validateWeeklyRestrictions = function() {

        const daysSum = $scope.weeklyRestrictions.reduce((acc, curr, i) => {
            return acc + $scope.form[`numDays${i}`].$modelValue;
        }, 0);

        if (daysSum > 7) {
            $scope.form.numDays0.$setValidity("maxDays7", false);
        } else {
            $scope.form.numDays0.$setValidity("maxDays7", true);
        }
    };

    function prepareAuthModelData() {
        for (var field in $scope.authModel) {
            switch (field) {
                case 'startDate':
                case 'endDate': {
                    var d = LocalDate.parse($scope.authModel[field]);
                    var year = d.year();
                    var month = d.month().value() - 1;
                    var day = d.dayOfMonth();
                    $scope.authModel[field] = new Date(year, month, day);
                    break;
                }
                default: {
                    break;
                }
            }
        };
    }

    function parseHour(rawHours) {
        const decimalHours = parseFloat(rawHours, 10);
        const decimalPart = (decimalHours - Math.floor(decimalHours)).toFixed(2); // Get only the .xx
        let closestDecimal = 0;
        if (decimalPart > 0) {
            const allowedDecimals = [0, 0.25, 0.5, 0.75, 1];
            closestDecimal = allowedDecimals.reduce((prev, curr) =>
                Math.abs(curr - decimalPart) < Math.abs(prev - decimalPart)
                    ? curr
                    : prev
            );
        }
        const hours = Math.floor(decimalHours);
        return (hours + closestDecimal) * 60;
    }

    function enrichAuthorizationForm() {
        var authRequest = {};

        authRequest.authCode = $scope.isDraft ? "TEMP": "" + $scope.authModel.code;
        authRequest.externalId = null;
        authRequest.patientContractId = $scope.authModel.patientContractId;
        authRequest.serviceCodeId = $scope.authModel.serviceCode;

        authRequest.notes = $scope.authModel.notes || '';

        var d = new Date($scope.authModel.startDate);
        authRequest.startDate = LocalDate.of(d.getFullYear(), d.getMonth() + 1, d.getDate()).toString();

        var d = new Date($scope.authModel.endDate);
        authRequest.endDate = LocalDate.of(d.getFullYear(), d.getMonth() + 1, d.getDate()).toString();

        switch ($scope.authModel.periodType) {
            case 'DAILY': {
                authRequest.timeConfig = {
                    type: 'Daily',
                    dailyMinutes: []
                };
                if ($scope.authModel.monday) {
                    authRequest.timeConfig.dailyMinutes.push([DayOfWeek.MONDAY, parseHour($scope.authModel.monday)]);
                }
                if ($scope.authModel.tuesday) {
                    authRequest.timeConfig.dailyMinutes.push([DayOfWeek.TUESDAY, parseHour($scope.authModel.tuesday)]);
                }
                if ($scope.authModel.wednesday) {
                    authRequest.timeConfig.dailyMinutes.push([DayOfWeek.WEDNESDAY, parseHour($scope.authModel.wednesday)]);
                }
                if ($scope.authModel.thursday) {
                    authRequest.timeConfig.dailyMinutes.push([DayOfWeek.THURSDAY, parseHour($scope.authModel.thursday)]);
                }
                if ($scope.authModel.friday) {
                    authRequest.timeConfig.dailyMinutes.push([DayOfWeek.FRIDAY, parseHour($scope.authModel.friday)]);
                }
                if ($scope.authModel.saturday) {
                    authRequest.timeConfig.dailyMinutes.push([DayOfWeek.SATURDAY, parseHour($scope.authModel.saturday)]);
                }
                if ($scope.authModel.sunday) {
                    authRequest.timeConfig.dailyMinutes.push([DayOfWeek.SUNDAY, parseHour($scope.authModel.sunday)]);
                }

                break;
            }
            case 'WEEKLY': {
                authRequest.timeConfig = {
                    type: 'Weekly',
                    firstDayOfWeek: $scope.authModel.firstDayOfWeek,
                    weeklyMinutes: parseHour($scope.authModel.hours),
                    dayRestrictions: null
                };

                var r = $scope.weeklyRestrictions.filter(function(w) {
                    return !Number.isNaN(parseInt(w.numDays, 10)) && !Number.isNaN(parseFloat(w.maxHours, 10))
                });

                if (r.length > 0) {
                    authRequest.timeConfig.dayRestrictions = [];
                    for (var i = 0; i < r.length; ++i) {
                        var d = r[i];
                        authRequest.timeConfig.dayRestrictions.push({
                            numDays: parseInt(d.numDays, 10),
                            maxMinutesPerDay: parseHour(d.maxHours),
                        });
                    }
                }

                break;
            }
            case 'MONTHLY': {
                authRequest.timeConfig = {
                    type: 'Monthly',
                    monthlyMinutes: parseHour($scope.authModel.hours),
                };
                break;
            }
            case 'ENTIRE_PERIOD': {
                authRequest.timeConfig = {
                    type: 'EntirePeriod',
                };
                break;
            }
        }

        authRequest.maxMinutes = parseHour($scope.authModel.maxHours);

        const noteActors = {
            patientId: patientId
        };

        authRequest.notes = entityNoteService.buildEntityNoteRequest(
            data.note,
            noteConsts.NoteTypes.PATIENT_AUTHORIZATION,
            noteActors
        );

        if ($scope.isFromHHAIntegration) {
            authRequest.icdCodesForDX = Object.keys($scope.authModel.icdCodesForDX).filter(key => $scope.authModel.icdCodesForDX[key] === true).map(id => parseInt(id));
        }

        return authRequest;
    }

    function populateAuthModelWithDays() {
        for (const day in $scope.dailyHours) {
            $scope.authModel[day] = null;
        }
    }

    $scope.populateFields = () => {
            initAuthModel($scope.lastAuthorization);
            prepareAuthModelData();
    }

    $scope.handleClickShowInactiveContracts = () => {
        if ($scope.showInactivePatientContracts) {
            $scope.patientContracts = patientContractOptions;
        } else {
            $scope.patientContracts = patientContractOptions.filter(c => c.isActive === true);
        }
    }

    $scope.handleNoteChanges = (updatedNote) => {
        if (!data.note) {
            data.note = {};
        }

        data.note = updatedNote;
    };

    const initIcdCodes = (dxCodes) => {
        $scope.authModel.icdCodesForDX = {};
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/icd_10_codes?dx_codes=${dxCodes.join('|')}`;

        DatabaseApi.get(url).then(res => {
            $scope.icdCodes = res.data.codes;
            res.data.codes.forEach(item => {
                $scope.authModel.icdCodesForDX = { ...$scope.authModel.icdCodesForDX, [item.id]: true };
            });
        });
    }

    init();
};