import Page, { PageTab } from "../../../../shared/components/Page";
import PatientIntakeFlowSettingsPage from "./PatientIntakeFlowSettingsPage";
import PatientIntakeFlowSettingsVisualizerPage from "./PatientIntakeFlowSettingsVisualizerPage";

const tabs: PageTab[] = [
  {
    header: "List View",
    route: "app.intakeFlowSettings",
    component: <PatientIntakeFlowSettingsPage />,
  },
  {
    header: "Visualizer (new)",
    route: "app.intakeFlowSettings.visualizer",
    component: <PatientIntakeFlowSettingsVisualizerPage />,
  },
];

export default function IntakeRoute() {
  return <Page.Tabbed tabs={tabs} />;
}
