import {
  Badge,
  Button,
  Flex,
  Heading,
  Text,
  ListItem,
  OrderedList,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import useApi from "../../../shared/hooks/useApi";
import { PatientId } from "../../../shared/schema/schema";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { Messages } from "../../../core/api";
import React from "react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import MdRefreshIcon from "@medflyt/webapp-react/src/shared/icons/MdRefreshIcon";

export default function IntakeProcessSummarySection(props: {
  patientId: PatientId;
  status: Messages["Patient"]["status"];
}) {
  const { api } = useApi();
  const client = useQueryClient();

  const summaryQuery = useQuery({
    queryKey: ["intake", "summary", props.patientId],
    queryFn: () => {
      return api.post("./patient_intake/:patientId/intake_process_summary", {
        path: { patientId: props.patientId },
      });
    },
    select: (data) => data.summary,
  });

  const updateSummary = useMutation({
    mutationFn: () => {
      return api.post("./patient_intake/:patientId/update_intake_process_summary", {
        path: { patientId: props.patientId },
      });
    },
    onSuccess: (data) => {
      client.setQueryData(["intake", "summary", props.patientId], data);
    },
  });

  const isPatientActive = ["ACTIVE", "DRAFT"].includes(props.status);

  return (
    <Flex direction="column" gap={4}>
      <Flex justifyContent="space-between">
        <Heading as="h1" size="md">
          Intake Summary
        </Heading>
        {summaryQuery?.data?.lastUpdatedAt && (
          <Flex alignItems="center">
            {isPatientActive && (
              <IconButton
                aria-label="refetch-summary-btn"
                icon={<MdRefreshIcon />}
                variant="link"
                onClick={() => updateSummary.mutate()}
              />
            )}
            <div>Last updated at {dateFormatter.toDateTime(summaryQuery.data?.lastUpdatedAt)}</div>
          </Flex>
        )}
      </Flex>
      <Flex>
        {summaryQuery.isPending || updateSummary.isPending ? (
          <SummaryLoading />
        ) : summaryQuery.isError ? (
          <SummaryError />
        ) : summaryQuery.data === null || summaryQuery.data === undefined ? (
          <NoSummary />
        ) : (
          <SummaryBody {...summaryQuery.data} />
        )}
      </Flex>
    </Flex>
  );
}

const SummaryBody = (props: Messages["PatientIntakeProcessSummary"]) => {
  const [readMoreOpen, setReadMoreOpen] = React.useState(false);

  return (
    <Flex direction="column" gap={4}>
      <p>{props.summary}</p>

      <Flex direction="column">
        <u>Next Actions To Take: </u>
        <OrderedList>
          {props.nextActionItems.map((item, index) => (
            <ListItem key={`next-action-item-${index}`}>{item}</ListItem>
          ))}
        </OrderedList>
      </Flex>
      {!readMoreOpen && (
        <Button
          color="blue.600"
          textAlign="left"
          variant="link"
          w={100}
          onClick={() => setReadMoreOpen(true)}
        >
          Read More...
        </Button>
      )}
      {readMoreOpen && (
        <Flex direction="column" gap={5}>
          <Flex direction="column">
            <Text>
              <u>Is patient in the correct status?: </u>
              <Badge
                colorScheme={props.isPatientInCorrectStatus ? "green" : "orange"}
                marginLeft={2}
              >
                {props.isPatientInCorrectStatus ? "Yes" : "No"}
              </Badge>
            </Text>
            <p>{props.isPatientInCorrectStatusExplanation}</p>
          </Flex>
          <Button
            aria-label="read-less"
            color="blue.600"
            leftIcon={<ArrowUpIcon />}
            variant="link"
            w={100}
            onClick={() => setReadMoreOpen(false)}
          >
            Read Less
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

const SummaryError = () => {
  return <div>Sorry.. There was an error while trying to fetch the intake summary.</div>;
};

const NoSummary = () => {
  return <div>There&apos;s no summary for this patient&apos;s intake process.</div>;
};

const SummaryLoading = () => {
  return (
    <Flex color="gray.500" flex={1} flexDirection="column" gap={8}>
      <Spinner size="sm" />
    </Flex>
  );
};
