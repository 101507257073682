import { Skeleton, SkeletonProps, VStack } from "@chakra-ui/react";

export default function FormControlSkeleton(props: { inputW?: SkeletonProps["width"] }) {
  return (
    <VStack align="flex-start" w={props.inputW ?? "full"}>
      <Skeleton h={5} mt={1} rounded="full" w={16} />
      <Skeleton h={10} rounded="md" w="full" />
    </VStack>
  );
}
