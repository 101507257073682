import { Button, ButtonProps } from "@chakra-ui/react";
import Link, { LinkProps } from "../../../../../shared/components/Link";

export function VisitAssignmentTicketRelationButton(
  props: LinkProps & { colorScheme: ButtonProps["colorScheme"] }
) {
  return (
    <Button
      as={Link}
      color={`${props.colorScheme}.700`}
      size="sm"
      textAlign="start"
      variant="link"
      {...props}
    />
  );
}
