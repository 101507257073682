import { Button, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Messages } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import EntityNoteFormModal from "../../../../note/components/EntityNoteFormModal";
import { EntityNoteFormData, mapToAgencyNoteCreateParams } from "../../../../note/utils/note-form";

export function CaregiverExclusionRowSwitchFlagButton(props: {
  row: Messages["CaregiverExlusionDataNew"];
}) {
  const queryClient = useQueryClient();
  const disclosure = useDisclosure();
  const { api, queries } = useApi();

  const operationName = props.row.flagged ? "Un-flag" : "Flag";
  const flag = !props.row.flagged;

  const flagsReasonQuery = useQuery({
    ...queries.note.settings(),
    select: (x) => {
      const reasons = x.agencyEntityNotes.find(
        (x) => x.noteType === "caregiver_exclusion"
      )?.predefinedAnswers;

      return {
        flag: reasons?.find((x) => x.text === "Flag")?.id,
        unflag: reasons?.find((x) => x.text.toLowerCase().replace("-", "") === "unflag")?.id,
      };
    },
  });

  const flagSwitch = useMutation({
    mutationFn: (note: EntityNoteFormData) => {
      return api.put("./exclusion_list/:exclusionId/flag", {
        body: {
          flag: flag,
          note: mapToAgencyNoteCreateParams({
            noteType: "caregiver_exclusion",
            caregiverId: props.row.id,
            ...note,
          }),
        },
        path: { exclusionId: props.row.exclusionId },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queries.caregiver.exclusions.list());
      disclosure.onClose();
    },
  });

  const predefinedAnswerId = flag ? flagsReasonQuery.data?.flag : flagsReasonQuery.data?.unflag;

  return (
    <>
      <Button colorScheme="cyan" variant="outline" onClick={disclosure.onOpen}>
        {operationName}
      </Button>

      <EntityNoteFormModal
        confirmButtonProps={{ children: operationName }}
        disclosure={disclosure}
        hardcodedAnswerId={predefinedAnswerId}
        isLoading={flagSwitch.isPending}
        noteType="caregiver_exclusion"
        titleProps={{ children: `${operationName} ${props.row.fullName}?` }}
        onSuccess={flagSwitch.mutate}
      />
    </>
  );
}
