import React from "react";
import CallCenterListeners from "../../../modules/call-center/components/CallCenterListeners";
import CommCenterSocketsListener from "../../../modules/communication/components/CommCenterSocketsListener";
import CommCenterTicketPopupListener from "../../../modules/communication/components/CommCenterTicketPopupListener";
import VisitAssignmentRoute from "../../../modules/visit/pages/assignments/VisitAssignmentRoute";
import useAuth from "../../hooks/useAuth";
import EditNoteModalListener from "./EditNoteModalListener";
import EntityCommunicationsListener from "./EntityCommunicationsListener";
import GlobalWorkflowRunner from "./GlobalWorkflowModal";
import LastRouteStateDispatcher from "./LastRouteStateDispatcher";
import NewTicketPopupListener from "./NewTicketPopupListener";
import SmsSenderListener from "./SmsSenderListener";

/**
 * This component is global in the webapp and is being imported from content.html
 */
export default function ReactHost() {
  const { isMedflytAgencyMember } = useAuth();

  React.useEffect(() => {
    console.log("[react-host] init");
  }, []);

  if (!isMedflytAgencyMember()) {
    return <></>;
  }

  return (
    <>
      <LastRouteStateDispatcher />
      <GlobalWorkflowRunner />
      <CommCenterSocketsListener />
      <CommCenterTicketPopupListener />
      <SmsSenderListener />
      <EditNoteModalListener />
      <NewTicketPopupListener />
      <CallCenterListeners />
      <EntityCommunicationsListener />

      {/* absolute routes */}
      <VisitAssignmentRoute />
    </>
  );
}
