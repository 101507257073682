import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";

export default function useNoteEntitySettingsQuery(params: { noteType: Messages["NoteTypes"] }) {
  const { queries } = useApi();

  return useQuery({
    ...queries.note.settings(),
    select: ({ agencyEntityNotes }) => {
      const settings = agencyEntityNotes.find((x) => x.noteType === params.noteType);

      if (!settings) {
        throw new Error(`No settings found for note type ${params.noteType}`);
      }

      return settings;
    },
  });
}
