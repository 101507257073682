import { PhoneIcon } from "@chakra-ui/icons";
import { Divider, Flex, HStack, IconButton } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../../core/api";
import { TelephonyCallId } from "../../../../shared/schema/schema";
import { isOneOfUnion } from "../../../../shared/utils";
import { sortByInstant } from "../../../../shared/utils/date-utils";
import useCallCenter from "../../hooks/useCallCenter";
import RingingCallCard from "./RingingCallCard";

export default function RingingCallsContent(props: {
  calls: Messages["TelephonyInboundRingingCallInfo"][];
}) {
  const { answer, state } = useCallCenter();
  const [answeredCall, setAnsweredCall] = React.useState<TelephonyCallId | null>(null);

  const calls = React.useMemo(() => sortByInstant(props.calls, "rangAt", "asc"), [props.calls]);

  const disableAnswer =
    isOneOfUnion(state.status, ["Active", "Ringing", "Calling", "Unauthorized"]) ||
    answer.isPending;

  const handleAnswer = (call: Messages["TelephonyInboundRingingCallInfo"]) => {
    setAnsweredCall(call.id);
    answer.mutate({ call, intent: "AnswerInbound" });
  };

  return (
    <Flex direction="column" overflowY="auto">
      {calls.map((call, index) => {
        return (
          <React.Fragment key={call.id}>
            <HStack
              _hover={{ bg: "gray.100" }}
              justifyContent="space-between"
              p={5}
              role="group"
              w="full"
            >
              <RingingCallCard caller={call.caller} startedRingingAt={call.rangAt} />

              <IconButton
                _groupHover={{ opacity: 1 }}
                aria-label="answer-ringing"
                colorScheme="green"
                disabled={disableAnswer}
                icon={<PhoneIcon />}
                isLoading={answer.isPending && answeredCall === call.id}
                opacity={0}
                rounded="full"
                onClick={() => handleAnswer(call)}
              />
            </HStack>
            {index !== props.calls.length - 1 && <Divider ml={5} width="90%" />}
          </React.Fragment>
        );
      })}
    </Flex>
  );
}
