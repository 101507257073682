import { PhoneIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Skeleton, Text } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import { Messages, ResponseOf } from "../../../../../core/api";
import EntityCard from "../../../../../shared/components/EntityCard";
import { fmap } from "../../../../../shared/utils";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { getFullName } from "../../../../../shared/utils/get-full-name";
import { phoneFormatter } from "../../../../../shared/utils/phone-formatter";
import { usePhoneNumberDialerPopup } from "../../../../call-center/hooks/usePhoneNumberDialerPopup";
import IntakePatientSendEmailButton from "../../../components/IntakePatientSendEmailButton";
import IntakePatientSendSMSButton from "../../../components/IntakePatientSendSMSButton";
import PatientIntakeRescheduleNextCallButton from "../../../components/PatientIntakeRescheduleNextCallButton";
import PatientIntakeFlowError from "./PatientIntakeFlowError";

export default function PatientIntakeFlowHeader(props: {
  query: UseQueryResult<ResponseOf<"get", "./patients/:patientId/intake">>;
  phoneDialerPopup: ReturnType<typeof usePhoneNumberDialerPopup>;
  onClickEntity: () => void;
}) {
  return (
    <Box fontSize="xl" fontWeight="semibold" px={6} py={4}>
      {(() => {
        switch (props.query.status) {
          case "pending":
            return <PatientIntakeFlowHeaderBodyShimmer />;
          case "error":
            return <PatientIntakeFlowError error={props.query.error} />;
          case "success":
            return (
              <PatientIntakeFlowHeaderBody
                patient={props.query.data.patient}
                phoneDialerPopup={props.phoneDialerPopup}
                onClickEntity={props.onClickEntity}
              />
            );
        }
      })()}
    </Box>
  );
}

function PatientIntakeFlowHeaderBody(props: {
  patient: Messages["IntakePatientProfile"];
  phoneDialerPopup: ReturnType<typeof usePhoneNumberDialerPopup>;
  onClickEntity: () => void;
}) {
  const mainPatientPlan = props.patient.plans?.find((plan) => plan.main);
  const handleClickDial = () => {
    props.phoneDialerPopup.open({
      predefinedPhoneNumber: props.patient.phoneNumbers[0].phonenumber,
    });
  };
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Box
        _hover={{ bg: "gray.100", color: "black", textDecoration: "none" }}
        cursor="pointer"
        p={3}
        rounded="lg"
        transition="all 200ms ease"
        onClick={props.onClickEntity}
      >
        <EntityCard
          entity={{
            id: props.patient.id,
            type: "Patient",
            displayId: props.patient.displayId,
            fullName: getFullName(props.patient),
            status: props.patient.patientStatus,
            gender: props.patient.gender,
            contactDetails: null,
          }}
        />
      </Box>
      <Flex alignItems="center" gap={10}>
        <Flex alignItems="flex-start" flexDirection="column">
          <Flex>
            <IntakePatientSendEmailButton patientId={props.patient.id} />
            <Button
              color="blue.500"
              fontSize="12px"
              isDisabled={props.patient.phoneNumbers.length === 0}
              leftIcon={<PhoneIcon />}
              variant="ghost"
              onClick={handleClickDial}
            >
              Call
            </Button>
            <IntakePatientSendSMSButton
              patientId={props.patient.id}
              predefinedPhoneNumber={props.patient.mainPhoneNumber ?? undefined}
            />
          </Flex>
          <PatientIntakeRescheduleNextCallButton
            border="transparent"
            color="blue.500"
            fontSize="12px"
            patientId={props.patient.id}
          />
        </Flex>
        <Flex direction="column" fontSize="md" fontWeight="normal">
          <Text>
            Next call:{" "}
            <b>
              {props.patient.nextCallDate
                ? dateFormatter.toDateTime(props.patient.nextCallDate)
                : "N/A"}
            </b>
          </Text>
          <Text>
            Status: <b>{props.patient.intakeStatus.status}</b>
          </Text>
          <Text>
            Track: <b>{props.patient.track?.name}</b>
          </Text>
          <Text>
            Plan:{" "}
            <b>
              {mainPatientPlan
                ? `${mainPatientPlan.plan.payerCode},${mainPatientPlan.plan.payerName},${mainPatientPlan.plan.payerType}`
                : "N/A"}
            </b>
          </Text>
        </Flex>
        <Flex alignItems="flex-end" direction="column">
          <Text fontWeight="bold">
            {props.patient.phoneNumbers
              .map((x) => phoneFormatter.formatNational(x.phonenumber))
              .join(", ")}
          </Text>
          <Text fontSize="md" fontWeight="normal">
            Last phone call - {fmap(props.patient.lastCallDate, dateFormatter.toDateTime) ?? "N/A"}
          </Text>
        </Flex>

        <Flex direction="column" gap={1} textAlign="end">
          <Text color="black" fontSize="md" fontWeight="normal">
            Created at {dateFormatter.toDateTime(props.patient.createdAt)}
          </Text>
          <Text color="black" fontSize="md" fontWeight="normal">
            Updated at {fmap(props.patient.updatedAt, dateFormatter.toDateTime) ?? "N/A"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

const PatientIntakeFlowHeaderBodyShimmer = () => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <EntityShimmer />
      <Flex gap={32}>
        <Flex alignItems="flex-end" direction="column" gap={1}>
          <Skeleton height="26px" width="122px" />
          <Skeleton height="21px" width="168px" />
        </Flex>

        <Flex direction="column" gap={1} textAlign="end">
          <Skeleton height="21px" width="200px" />
          <Skeleton height="21px" width="200px" />
        </Flex>
      </Flex>
    </Flex>
  );
};

const EntityShimmer = () => {
  return (
    <Flex gap={3} p={3}>
      <Skeleton height="35px" rounded="full" width="35px" />
      <Flex direction="column" gap={2}>
        <Skeleton height="25px" width="180px" />
        <Flex gap={2}>
          <Skeleton height="16px" width="40px" />
          <Skeleton height="16px" width="40px" />
        </Flex>
      </Flex>
    </Flex>
  );
};
