export const newVisitFooter = {
    templateUrl: 'admin/views/new-visit/new-visit-footer.html',
    bindings: {
		type: '@',
		form: '=',
		relevantCaregivers: '<',
        isSaving: '<',
		isEditMode: '<',
        isBroadcasting: '<',
        emptyCaregiversMessage: '<',
        handleSave: '&',
        updateEnableCaregivers: '&',
		isStartDateBeforeToday: '<',
		handleBroadcast: '&',
		patientName: '<',
		disableSave: '<',
		forceDisableBroadcast: '<',
		isCurrentlyBroadcasted: '<',
		patientId: '<'
    },
	transclude: true,
    //! @ngInject
    controller: function ($scope, $uibModal, $rootScope) {
		$scope.openBroadcastModal = () => {
			$uibModal.open({
				templateUrl: "admin/views/visit-broadcast-options-modal.html",
				size: "md",
				controller: "visitBroadcastOptionsModalCtrl",
				windowClass: "visit-broadcast-options-modal center-center",
				resolve: {
					onSubmit: () => (state) => {
						$scope.$ctrl.handleBroadcast()({
							radius: state.miles,
							isBroadcast: true,
							allowMultipleCaregiversToSelectShifts: state.allowMultipleCaregiversToSelectShifts
						});
					},
					patientName: () => $scope.$ctrl.patientName,
				}
			});
		};

		$scope.handleCaregiverSelect = (target, caregiver) => {
			target.caregiver = caregiver;
			$scope.$ctrl.updateEnableCaregivers();
		};

		$scope.handleCaregiverDeselect = (target) => {
			if ($rootScope.isOnboardingAgency && target.caregiver) {
				const modalInstance = $uibModal.open({
					templateUrl: "admin/views/approve-visit-instance-unassign-modal.html",
					size: "md",
					controller: "approveVisitInstanceUnassignModalCtrl",
					resolve: {
						visitInstanceId: () => null,
						requestNoteText: () => null,
						caregiverId: () => target.caregiver.id,
						patientId: () => $scope.$ctrl.patientId
					}
				});
	
				modalInstance.result.then(function (res) {
					if (res.notePredefinedAnswerId !== null) {
						target.caregiver = null;
						$scope.$ctrl.updateEnableCaregivers();
						$scope.caregiverUnassignedNote = res;
					}
				});
			} else {
				target.caregiver = null;
				$scope.$ctrl.updateEnableCaregivers();
			}
		};

		$scope.handleSeeAllSuggestions = (target) => {
			$uibModal.open({
				templateUrl: "admin/views/new-visit/new-visit-sugggested-caregivers-modal.html",
				controller: 'newVisitSuggestedCaregiversModalCtrl',
				size: "lg",
				resolve: {
					caregivers: () => $scope.$ctrl.relevantCaregivers,
					handleCaregiverSelect: () => (selectedCaregiver) => $scope.handleCaregiverSelect(target, selectedCaregiver)
				}
			});
		};
    }
};