import { CloseIcon } from "@chakra-ui/icons";
import { Box, Portal, Flex, IconButton, Divider, useToast } from "@chakra-ui/react";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { ResponseOf } from "../../../core/api";
import ErrorPage from "../../../shared/components/ErrorPage";
import LoadingPage from "../../../shared/components/LoadingPage";
import useApi from "../../../shared/hooks/useApi";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import { useNewTicket } from "../../../shared/hooks/useNewTicket";
import MaximizeIcon from "../../../shared/icons/MaximizeIcon";
import MinimizeIcon from "../../../shared/icons/MinimizeIcon";
import { queryKeys } from "../../../shared/query-keys";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { getCommCenterTeamMemberIdByAgencyMemberId } from "../utils/communication-utils";
import NewCommCenterTicket, { NewCommCenterTicketProps } from "./NewCommCenterTicket";
import { useCommCenterTicketPopup } from "../hooks/useCommCenterTicketPopup";
import { getTicketPopupEntityParamsFromTicketCreation } from "../utils/comm-center-email-utils";

export type NewTicketPopupProps = Pick<
  NewCommCenterTicketProps,
  "initialLabelId" | "defaultValues" | "defaultEmailValues" | "defaultTicketType"
>;

function NewTicketPopup(props: NewTicketPopupProps) {
  const { onClose } = useNewTicket();
  const [isMinimized, setIsMinimized] = React.useState(false);

  return (
    <Portal>
      <Box
        bg="white"
        borderTopRadius="2xl"
        bottom={39}
        boxShadow="0 0 16px -2px rgba(0,0,0,0.25)"
        id="comm-center-new-ticket-popup"
        position="fixed"
        right={55}
        sx={{
          "--max-chat-height": "60vh",
        }}
        transform={isMinimized ? `translateY(calc(100% - 49px)) ` : undefined}
        transition="all 250ms ease"
        w={isMinimized ? 300 : 1000}
        zIndex="popover"
      >
        <Flex p={2}>
          <IconButton
            aria-label="close"
            borderRadius="xl"
            icon={<CloseIcon fontSize={12} />}
            variant="ghost"
            onClick={onClose}
          />
          <IconButton
            aria-label="close"
            borderRadius="xl"
            hidden={isMinimized}
            icon={<MinimizeIcon fontSize={18} />}
            variant="ghost"
            onClick={() => setIsMinimized(true)}
          />
          <IconButton
            aria-label="close"
            borderRadius="xl"
            hidden={!isMinimized}
            icon={<MaximizeIcon fontSize={18} />}
            variant="ghost"
            onClick={() => setIsMinimized(false)}
          />
        </Flex>

        <Divider />

        <Box maxH={700} overflowY="auto">
          <NewTicketPopUpBody onClose={onClose} {...props} />
        </Box>
      </Box>
    </Portal>
  );
}

function NewTicketPopUpBody(props: NewTicketPopupProps & { onClose: () => void }) {
  const { api, mutations } = useApi();
  const { agencyMember } = useAuthData();
  const toast = useToast();
  const { open: openCommCenterTicketPopup } = useCommCenterTicketPopup();

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => api.get("./comm_center/teams", {}),
    select: (response) => response.teams,
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => api.get("./comm_center/labels", {}),
    placeholderData: keepPreviousData,
    select: (response) => response.labels,
  });

  const createTicket = useMutation({
    ...mutations.createTicket(
      getCommCenterTeamMemberIdByAgencyMemberId(teams.data ?? [], agencyMember.id)
    ),
    onSuccess: (response) => {
      props.onClose();

      const popupEntityParams = getTicketPopupEntityParamsFromTicketCreation(response.ticket);
      openCommCenterTicketPopup({
        defaultTicketId: response.ticket.id,
        ...popupEntityParams,
      });
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleSuccessCreateEmailTicket = (
    response: ResponseOf<"post", "./comm_center/email/threads">
  ) => {
    props.onClose();

    const popupEntityParams = getTicketPopupEntityParamsFromTicketCreation(response.ticket);
    openCommCenterTicketPopup({
      defaultTicketId: response.ticket.id,
      ...popupEntityParams,
    });
  };

  if (teams.isPending || labels.isPending) {
    return <LoadingPage />;
  }

  if (teams.isError) {
    return <ErrorPage error={teams.error} resetErrorBoundary={teams.refetch} />;
  }

  if (labels.isError) {
    return <ErrorPage error={labels.error} resetErrorBoundary={labels.refetch} />;
  }

  const initialLabelId =
    props.initialLabelId ??
    labels.data?.find((label) => label.parent === null && !label.active)?.id ??
    null;

  return (
    <NewCommCenterTicket
      defaultEmailValues={props.defaultEmailValues}
      defaultTicketType={props.defaultTicketType}
      defaultValues={props.defaultValues}
      initialLabelId={initialLabelId}
      labels={labels.data}
      teams={teams.data}
      onCreateTicket={createTicket.mutate}
      onSuccessCreateEmailTicket={handleSuccessCreateEmailTicket}
    />
  );
}

export default NewTicketPopup;
