import angular from "angular";
import { caregiverDetails } from "./components/caregiver-details/caregiver-details.component";
import { caregiverVisitRequestsTable } from "./components/caregivers-visits-requests-table/caregivers-visits-requests-table.component";
import { flexibleCaregiverRequestsRow } from "./components/flexible-caregiver-requests-row/flexible-caregiver-requests-row.component";
import { flexibleVisitRequestsContainer } from "./components/flexible-visit-requests-container/flexible-visit-requests-container.component";
import { flexibleVisitAssignmentRequestsContainer } from "./components/flexible-visit-assignment-requests-container/flexible-visit-assignment-requests-container.component";
import { visitInfoSection } from "./components/visit-info-section/visit-info-section.component";
import { VisitBroadcastService } from "./visitBroadcast.service";
import {
  caregiversVisitsAssignmentRequestsTable
} from "./components/caregivers-visits-assignment-requests-table/caregivers-visits-assignment-requests-table.component";
import { caregiverVisitRequestsTableNonMultiple } from "./components/caregivers-visits-requests-table-non-multiple/caregivers-visits-requests-table-non-multiple.component";
import { caregiversVisitsAssignmentRequestsTableNonMultiple } from "./components/caregivers-visits-assignment-requests-table-non-multiple/caregivers-visits-assignment-requests-table-non-multiple.component";
import { visitModalCaregiversTable } from "./components/visit-modal-caregivers-table/visit-modal-caregivers-table.component";

export function registerVisitBroadcastModule() {
    return (
      angular
        .module("dashboard")
  
        //  Services
        .service("visitBroadcastService", VisitBroadcastService)
  
        // Components
        .component("caregiversVisitsAssignmentRequestsTableNonMultiple", caregiversVisitsAssignmentRequestsTableNonMultiple)
        .component("caregiversVisitsAssignmentRequestsTable", caregiversVisitsAssignmentRequestsTable)
        .component("flexibleVisitAssignmentRequestsContainer", flexibleVisitAssignmentRequestsContainer)
        .component("caregiversVisitsRequestsTable", caregiverVisitRequestsTable)
        .component("caregiverVisitRequestsTableNonMultiple", caregiverVisitRequestsTableNonMultiple)
        .component("visitInfoSection", visitInfoSection)
        .component("flexibleCaregiverRequestsRow", flexibleCaregiverRequestsRow)
        .component("flexibleVisitRequestsContainer", flexibleVisitRequestsContainer)
        .component("caregiverDetails", caregiverDetails)
        .component("visitModalCaregiversTable", visitModalCaregiversTable)
    );
  }
  