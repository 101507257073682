import { Center, FormLabel, Heading, Switch } from "@chakra-ui/react";
import Page from "../../../../shared/components/Page";
import PayrollVerificationTable from "../../components/PayrollVerificationTable";
import { PayrollBatchId } from "../../../../shared/schema/schema";
import React from "react";

type Props = {
  payrollBatchId: PayrollBatchId;
};
const PayrollVerificationPage = (props: Props) => {
  const [showComparison, setShowComparisonName] = React.useState(false);
  const labelId = React.useId();

  return (
    <Page>
      <Page.Header>
        <Heading size="lg">Payroll Verification for batch {String(props.payrollBatchId)}</Heading>
      </Page.Header>
      <Page.Filters gap={3}>
        <Center>
          <FormLabel htmlFor={labelId}> Show Comparison </FormLabel>
          <Switch
            id={labelId}
            isChecked={showComparison}
            onChange={(e) => {
              setShowComparisonName(e.currentTarget.checked);
            }}
          />
        </Center>
      </Page.Filters>
      <Page.Content>
        <PayrollVerificationTable
          batchCount={4}
          payrollBatchId={props.payrollBatchId}
          showComparison={showComparison}
        />
      </Page.Content>
    </Page>
  );
};

export default PayrollVerificationPage;
