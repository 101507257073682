import { chakra } from "@chakra-ui/react";
import { useSrefActive } from "@uirouter/react";
import React from "react";

export interface LinkProps {
  to: string;
  params?: object;
  children: React.ReactNode;
  activeClassName?: string;
  className?: string;
}

function Link(props: LinkProps, ref: React.Ref<HTMLAnchorElement>) {
  const { to, children, params = null, activeClassName = "active", ...rest } = props;
  const sref = useSrefActive(to, params ?? {}, activeClassName);
  const debugString = `${to}${params ? " " + JSON.stringify(params) : ""}`;

  return (
    <A
      {...sref}
      {...rest}
      ref={ref}
      className={classNames(sref.className, rest.className)}
      data-sref-debug={debugString}
    >
      {children}
    </A>
  );
}

function classNames(...classes: unknown[]) {
  return classes.filter(Boolean).join(" ");
}

const A = chakra("a", {
  baseStyle: {
    _hover: {
      outline: "none",
    },
    _focus: {
      outline: "none",
    },
  },
});

export default React.forwardRef(Link);
