import { Messages } from "../../../core/api";
import { typedObjectEntries } from "../../../shared/utils/ts-utils";

export const noteTypes: Record<Messages["NoteTypes"], string> = {
  adjustment_approval: "Adjustment Approval",
  bulk: "Bulk",
  calendar: "Calendar",
  caregiver_absence: "Caregiver Absence",
  caregiver_exclusion: "Caregiver Exclusion",
  caregiver_pto: "Caregiver PTO",
  caregiver_status_change: "Caregiver Status Change",
  missed_visit_instance: "Missed Visit Instance",
  patient_authorization: "Patient Authorization",
  patient_status: "Patient Status",
  phone_call: "Phone Call",
  profile_note: "Profile Note",
  visit_distance_approval: "Visit Distance Approval",
  visit_instance_edit: "Visit Edit",
  visit_instance: "Visit Instance",
  visit_manual_clock_time: "Visit Manual Clock Time",
  visit_manual_remove: "Visit Manual Remove",
  visit_instance_caregiver_unassign: "Visit Instance Caregiver Unassign",
  visit_assignment_note: "Visit Assignment Note",
};

export const noteTypeEntries = typedObjectEntries(noteTypes);

export const noteTypeKeys = Object.keys(noteTypes) as [
  Messages["NoteTypes"],
  ...Messages["NoteTypes"][]
];
