import angular from "angular";
import { Chart, registerables } from "chart.js";
import { AppModule } from "./app.module";
import { CloudonixClient } from "@medflyt/webapp-react/src/shared/utils/cloudonix.sdk";

CloudonixClient.load();

Chart.register(...registerables);

angular.element(() => angular.bootstrap(document, [AppModule], { strictDi: true }));
