import { Box, Spinner } from "@chakra-ui/react";
import PatientStaffingPreferences from "../../../../../shared/components/PatientStaffingPreferences";
import usePatientStaffingPreferences from "../../../../../shared/hooks/usePatientStaffingPreferences";
import { PatientId } from "../../../../../shared/schema/schema";
import { assertNever } from "../../../../../utils";

const PatientIntakeFlowStaffingPreferences = (props: { patientId: PatientId }) => {
  const { patientStaffingPreferences, onUpdateStaffingPreferences } = usePatientStaffingPreferences(
    props.patientId
  );

  switch (patientStaffingPreferences.type) {
    case "Loading":
      return <Spinner />;
    case "Rejected":
      return <Box>Failed to load data</Box>;
    case "Resolved":
      return (
        <PatientStaffingPreferences
          staffingPreferences={patientStaffingPreferences.value}
          onUpdateStaffingPreferences={onUpdateStaffingPreferences}
        />
      );
    default:
      assertNever(patientStaffingPreferences);
  }
};

export default PatientIntakeFlowStaffingPreferences;
