import React, { useState } from "react";
import { fmap } from "../utils";
import { parseFromStorage } from "../utils/storage";
import { typedObjectEntries, typedObjectKeys } from "../utils/ts-utils";

export type LocalFilters<$Object, $Filters> = {
  [P in keyof $Filters]: {
    initialValue?: $Filters[P];
    cache?: boolean;
    filter: (row: $Object, currentFilter: $Filters[P]) => boolean;
  };
};

export function createLocalFilters<$Object, $Filters>(filters: LocalFilters<$Object, $Filters>) {
  return filters;
}

const useLocalFilters = <$Object, $Filters>(params: {
  data: $Object[];
  filters: LocalFilters<$Object, $Filters>;
  storageKey?: string[];
}) => {
  const [initialStorageState] = useState<Record<string, unknown>>(
    fmap(params.storageKey, (key) => parseFromStorage({ key: key, storage: sessionStorage })) ?? {}
  );
  const [state, setState] = useState<{
    [key in keyof $Filters]?: $Filters[key];
  }>(() => {
    return typedObjectKeys(params.filters).reduce((acc, key) => {
      return { ...acc, [key]: getInitialFilterValue(key) };
    }, {});
  });

  function shouldCacheFilter<K extends keyof $Filters>(key: K) {
    const DEFAULT_CACHE = true;
    return params.filters[key]?.cache ?? DEFAULT_CACHE;
  }

  function getInitialFilterValue<K extends keyof $Filters>(key: K) {
    const shouldTakeFromStorage = params.storageKey !== undefined && shouldCacheFilter(key);
    const initialValue = params.filters[key]?.initialValue;

    return shouldTakeFromStorage ? initialStorageState[String(key)] ?? initialValue : initialValue;
  }

  const changeFilter = <$Key extends keyof $Filters>(
    key: $Key,
    value: $Filters[$Key] | undefined
  ) => {
    return setState((prev) => {
      const newState = { ...prev, [key]: value };

      if (params.storageKey !== undefined && shouldCacheFilter(key)) {
        sessionStorage.setItem(JSON.stringify(params.storageKey), JSON.stringify(newState));
      }

      return newState;
    });
  };

  const filtered = React.useMemo(() => {
    let data = params.data;
    for (const [key, { filter }] of typedObjectEntries(params.filters)) {
      const value: $Filters[typeof key] | undefined = state[key];
      if (value !== undefined && value !== null) {
        data = data.filter((x) => filter(x, value));
      }
    }
    return data;
  }, [params.data, params.filters, state]);

  return {
    filtered: filtered,
    changeFilter: changeFilter,
    state: state,
  };
};

export default useLocalFilters;
