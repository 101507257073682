import { useQuery } from "@tanstack/react-query";
import useApi from "./useApi";

export default function useAgencyMembers() {
  const { api } = useApi();

  return useQuery({
    queryKey: ["agency_members", "list"],
    staleTime: Infinity,
    queryFn: () => api.get("./agency_members", {}),
    select: (data) => data.agencyMembers,
  });
}
