import { Box, Td, Text } from "@chakra-ui/react";
import PayrollVerificationTableNumberValue from "./PayrollVerificationTableNumberValue";
import { PayrollVerificationCellItemFullValues } from "../payroll.types";

type Props = {
  itemA: PayrollVerificationCellItemFullValues;
  itemB: PayrollVerificationCellItemFullValues;
  showComparison?: boolean;
  instanceAName?: string;
  instanceBname?: string;
  secondsFormatter?: (seconds: number) => React.ReactNode;
  amountFormatter?: (amount: number) => React.ReactNode;
};

export default function PayrollVerificationTableDoubleCell(props: Props) {
  return (
    <Td>
      <Box>
        <Text>
          <PayrollVerificationTableNumberValue
            currentValue={props.itemA.count}
            description={props.instanceAName}
            percentageChange={props.itemA.countPctChange}
            showComparison={props.showComparison}
          />
          {" / "}
          <PayrollVerificationTableNumberValue
            currentValue={props.itemB.count}
            description={props.instanceBname}
            percentageChange={props.itemB.countPctChange}
            showComparison={props.showComparison}
          />
        </Text>
      </Box>
      <Box>
        <Text>
          <PayrollVerificationTableNumberValue
            currentValue={props.itemA.amount}
            formatter={props.amountFormatter}
            percentageChange={props.itemA.amountPctChange}
            showComparison={props.showComparison}
          />
          {" / "}
          <PayrollVerificationTableNumberValue
            currentValue={props.itemB.amount}
            formatter={props.amountFormatter}
            percentageChange={props.itemB.amountPctChange}
            showComparison={props.showComparison}
          />
        </Text>
      </Box>
      <Box>
        <Text>
          <PayrollVerificationTableNumberValue
            currentValue={props.itemA.seconds}
            formatter={props.secondsFormatter}
            percentageChange={props.itemA.secondsPctChange}
            showComparison={props.showComparison}
          />
          {" / "}
          <PayrollVerificationTableNumberValue
            currentValue={props.itemB.seconds}
            formatter={props.secondsFormatter}
            percentageChange={props.itemB.secondsPctChange}
            showComparison={props.showComparison}
          />
        </Text>
      </Box>
    </Td>
  );
}
