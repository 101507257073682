import { FormControl, FormLabel, StyleProps, Switch } from "@chakra-ui/react";

interface IntakeProfileSwitchProps {
  isEditable: boolean;
  isRequired: boolean;
  label: string;
  value: boolean;
  validateSchema?: Zod.Schema;
  styleProps?: StyleProps;
  onChange: (value: boolean) => void;
}

const IntakePatientProfileSwitch = (props: IntakeProfileSwitchProps) => {
  return (
    <FormControl isRequired={props.isEditable ? props.isRequired : false} {...props.styleProps}>
      <FormLabel>{props.label}</FormLabel>
      <Switch
        isChecked={props.value}
        isDisabled={!props.isEditable}
        onChange={(event) => props.onChange(event.target.checked)}
      />
    </FormControl>
  );
};

export default IntakePatientProfileSwitch;
