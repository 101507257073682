import { createIcon } from "@chakra-ui/react";

const ReplyAllRoundedIcon = createIcon({
  displayName: "ReplyAllRoundedIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m4.825 11l3.9 3.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-4.6-4.6q-.15-.15-.213-.325T2.426 11q0-.2.063-.375T2.7 10.3l4.6-4.6q.275-.275.688-.275T8.7 5.7q.3.3.3.713t-.3.712L4.825 11Zm6 1l2.9 2.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-4.6-4.6q-.15-.15-.213-.325T7.425 11q0-.2.063-.375T7.7 10.3l4.6-4.6q.275-.275.688-.275t.712.275q.3.3.3.713t-.3.712L10.825 10H17q2.075 0 3.538 1.463T22 15v3q0 .425-.288.713T21 19q-.425 0-.713-.288T20 18v-3q0-1.25-.875-2.125T17 12h-6.175Z"
      fill="currentColor"
    />
  ),
});

export default ReplyAllRoundedIcon;
