import {
  DateTimeFormatter,
  Instant,
  LocalDate,
  LocalDateTime,
  LocalTime,
  ZonedDateTime,
} from "@js-joda/core";
import { z } from "zod";

interface Parameters {
  dateTimeFormatter?: DateTimeFormatter;
}

const localDate = (parameters?: Parameters) =>
  z.union([
    z.custom<LocalDate>((value) => value instanceof LocalDate),
    z
      .string()
      .refine((value) => {
        try {
          LocalDate.parse(value, parameters?.dateTimeFormatter);
          return true;
        } catch {
          return false;
        }
      }, "Invalid local date")
      .transform((value) => LocalDate.parse(value, parameters?.dateTimeFormatter)),
  ]);

const localDateTime = (parameters?: Parameters) =>
  z.union([
    z.custom<LocalDateTime>((value) => value instanceof LocalDateTime),
    z
      .string()
      .refine((value) => {
        try {
          LocalDateTime.parse(value, parameters?.dateTimeFormatter);
          return true;
        } catch {
          return false;
        }
      }, "Invalid local date time")
      .transform((value) => LocalDateTime.parse(value, parameters?.dateTimeFormatter)),
  ]);

const localTime = (parameters?: Parameters) =>
  z.union([
    z.custom<LocalTime>((value) => value instanceof LocalTime),
    z
      .string()
      .refine((value) => {
        try {
          LocalTime.parse(value, parameters?.dateTimeFormatter);
          return true;
        } catch {
          return false;
        }
      }, "Invalid local time")
      .transform((value) => LocalTime.parse(value, parameters?.dateTimeFormatter)),
  ]);

const zonedDateTime = (parameters?: Parameters) =>
  z.union([
    z.custom<ZonedDateTime>((value) => value instanceof ZonedDateTime),
    z
      .string()
      .refine((value) => {
        try {
          ZonedDateTime.parse(value, parameters?.dateTimeFormatter);
          return true;
        } catch {
          return false;
        }
      }, "Invalid zoned date time")
      .transform((value) => ZonedDateTime.parse(value, parameters?.dateTimeFormatter)),
  ]);

const instant = () =>
  z.union([
    z.custom<Instant>((value) => value instanceof Instant),
    z
      .string()
      .refine((value) => {
        try {
          Instant.parse(value);
          return true;
        } catch {
          return false;
        }
      }, "Invalid instant")
      .transform((value) => Instant.parse(value)),
  ]);

export type ZodInstant = ReturnType<typeof instant>;

export const zj = {
  localDate: localDate,
  localDateTime: localDateTime,
  localTime: localTime,
  zonedDateTime: zonedDateTime,
  instant: instant,
};
