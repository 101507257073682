import React from "react";
import { Messages } from "../../../core/api";
import { getTicketLastUpdatedAt } from "../utils/communication-utils";
import { PatientId } from "../../../shared/schema/schema";

interface Props {
  tickets: Messages["CommCenterTicket"][];
}

const mapTicketRowsToGroupByPatient = (tickets: Messages["CommCenterTicket"][]) => {
  const groupedTickets = {} as Record<PatientId, Messages["CommCenterTicket"]>;

  for (const ticket of tickets) {
    if (
      ticket.relatedPatient === null ||
      ticket.relatedVisitBroadcast?.patient === null ||
      ticket.relatedVisitInstance?.patient === null
    ) {
      continue;
    }

    // Get the ticket's related patient (related in any way possible)
    let relatedPatient;
    switch (true) {
      case ticket.relatedPatient !== null:
        relatedPatient = ticket.relatedPatient;
        break;
      case ticket.relatedVisitBroadcast !== null:
        relatedPatient = ticket.relatedVisitBroadcast.patient;
        break;
      case ticket.relatedVisitInstance !== null:
        relatedPatient = ticket.relatedVisitInstance.patient;
        break;
      default:
        throw new Error(`Ticket ${ticket.id} has no related patient`);
    }

    // group by patient (most recent ticket per patient)
    if (groupedTickets[relatedPatient.id] === undefined) {
      groupedTickets[relatedPatient.id] = ticket;
    } else {
      const currentTicketUpdatedAt = getTicketLastUpdatedAt(ticket);
      const existingTicketUpdatedAt = getTicketLastUpdatedAt(groupedTickets[relatedPatient.id]);

      if (currentTicketUpdatedAt.isAfter(existingTicketUpdatedAt)) {
        groupedTickets[relatedPatient.id] = ticket;
      } else {
        continue;
      }
    }

    // Make the related patient the primary entity for open comms table
    groupedTickets[relatedPatient.id].relatedPatient = relatedPatient;
    groupedTickets[relatedPatient.id].topic = "Patient";
  }

  return Object.values(groupedTickets);
};

export default function useGroupTicketsByPatient(props: Props) {
  const [groupByPatient, setGroupByPatient] = React.useState<boolean>(false);
  const [groupedTickets, setGroupedTickets] = React.useState<Messages["CommCenterTicket"][]>(
    props.tickets
  );

  const handleChangeGroupByPatient = (value: boolean) => {
    setGroupByPatient(value);
    if (value) {
      setGroupedTickets(mapTicketRowsToGroupByPatient(props.tickets));
    }
  };

  return {
    groupByPatient,
    groupedTickets,
    onChangeGroupByPatient: handleChangeGroupByPatient,
  };
}
