import { CheckIcon, ChevronDownIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Editable,
  EditableInput,
  HStack,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { $createCodeNode } from "@lexical/code";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import { $isAtNodeEnd, $wrapNodes } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  RangeSelection,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import React, { useCallback, useEffect, useRef } from "react";
import {
  FaAlignCenter,
  FaAlignJustify,
  FaAlignLeft,
  FaAlignRight,
  FaBold,
  FaItalic,
  FaLink,
  FaRedo,
  FaStrikethrough,
  FaUnderline,
  FaUndo,
  FaUnlink,
} from "react-icons/fa";
import {
  MdCode,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdLooks3,
  MdLooks4,
  MdLooks5,
} from "react-icons/md";
import FormatH1Icon from "../../../icons/FormatH1Icon";
import FormatH2Icon from "../../../icons/FormatH2Icon";
import FormatParagraphIcon from "../../../icons/FormatParagraphIcon";

const LowPriority = 1;

const supportedBlockTypes = new Set(["paragraph", "quote", "code", "h1", "h2", "ul", "ol"]);

const blockOptions = {
  code: { icon: <MdCode fontSize="16" />, children: "Code" },
  h1: { icon: <FormatH1Icon fontSize="16" />, children: "Large Heading" },
  h2: { icon: <FormatH2Icon fontSize="16" />, children: "Small Heading" },
  h3: { icon: <MdLooks3 fontSize="16" />, children: "Heading" },
  h4: { icon: <MdLooks4 fontSize="16" />, children: "Heading" },
  h5: { icon: <MdLooks5 fontSize="16" />, children: "Heading" },
  ol: { icon: <MdFormatListNumbered fontSize="16" />, children: "Numbered List" },
  paragraph: { icon: <FormatParagraphIcon fontSize="16" />, children: "Normal" },
  quote: { icon: <MdFormatQuote fontSize="16" />, children: "Quote" },
  ul: { icon: <MdFormatListBulleted fontSize="16" />, children: "Bulleted List" },
};

type LinkPopoverProps = {
  editor: LexicalEditor;
  disclosure: ReturnType<typeof useDisclosure>;
};

function LinkPopover({ editor, disclosure }: LinkPopoverProps) {
  const [linkUrl, setLinkUrl] = React.useState("");

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if (!$isRangeSelection(selection)) {
      return;
    }

    const node = getSelectedNode(selection);
    const parent = node.getParent();

    if ($isLinkNode(parent)) {
      return setLinkUrl(parent.getURL());
    }

    if ($isLinkNode(node)) {
      return setLinkUrl(node.getURL());
    }

    return setLinkUrl("");
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => updateLinkEditor());
  }, [editor, updateLinkEditor]);

  return (
    <Popover isLazy={true} lazyBehavior="unmount" {...disclosure} autoFocus={false}>
      <PopoverTrigger>
        <IconButton
          aria-label="Link"
          icon={<FaLink />}
          variant={disclosure.isOpen ? "solid" : "ghost"}
        />
      </PopoverTrigger>
      <PopoverContent w="sm">
        <PopoverBody>
          <Editable
            startWithEditView={true}
            value={linkUrl}
            onSubmit={(linkUrl) => {
              editor.dispatchCommand(
                TOGGLE_LINK_COMMAND,
                linkUrl.startsWith("http") ? linkUrl : `https://${linkUrl}`
              );
              disclosure.onClose();
            }}
          >
            {(controls) =>
              controls.isEditing ? (
                <HStack>
                  <Input
                    as={EditableInput}
                    value={linkUrl}
                    onChange={(e) => setLinkUrl(e.target.value)}
                  />
                  <IconButton
                    aria-label="Reset"
                    icon={<CloseIcon />}
                    onClick={disclosure.onClose}
                  />
                  <IconButton aria-label="Edit" icon={<CheckIcon />} onClick={controls.onSubmit} />
                </HStack>
              ) : (
                <HStack>
                  <Link
                    bg="gray.50"
                    h={10}
                    href={linkUrl}
                    p={2}
                    rel="noopener noreferrer"
                    rounded="md"
                    target="_blank"
                    w="full"
                  >
                    {linkUrl}
                  </Link>
                  <IconButton aria-label="Edit" icon={<EditIcon />} onClick={controls.onEdit} />
                </HStack>
              )
            }
          </Editable>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();

  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }

  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
}

type BlockOptionsMenuProps = {
  editor: LexicalEditor;
  blockType: string;
};

function BlockOptionsMenu({ editor, blockType }: BlockOptionsMenuProps) {
  const dropDownRef = useRef(null);

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, void 0);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, void 0);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0);
    }
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
  };

  return (
    <Menu>
      <MenuButton
        aria-label="Formatting Options"
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant="ghost"
      >
        {blockOptions[blockType as keyof typeof blockOptions].children}
      </MenuButton>
      <MenuList ref={dropDownRef}>
        <MenuItem {...blockOptions.paragraph} onClick={formatParagraph} />
        <MenuItem {...blockOptions.h1} onClick={formatLargeHeading} />
        <MenuItem {...blockOptions.h2} onClick={formatSmallHeading} />
        <MenuItem {...blockOptions.ul} onClick={formatBulletList} />
        <MenuItem {...blockOptions.ol} onClick={formatNumberedList} />
        <MenuItem {...blockOptions.quote} onClick={formatQuote} />
        <MenuItem {...blockOptions.code} onClick={formatCode} />
      </MenuList>
    </Menu>
  );
}

export default function ToolbarPlugin() {
  const linkDisclosure = useDisclosure();
  const [editor] = useLexicalComposerContext();
  const toolbarRef = React.useRef(null);
  const [canUndo, setCanUndo] = React.useState(false);
  const [canRedo, setCanRedo] = React.useState(false);
  const [blockType, setBlockType] = React.useState("paragraph");
  const [isLink, setIsLink] = React.useState(false);
  const [isBold, setIsBold] = React.useState(false);
  const [isItalic, setIsItalic] = React.useState(false);
  const [isUnderline, setIsUnderline] = React.useState(false);
  const [isStrikethrough, setIsStrikethrough] = React.useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        linkDisclosure.onOpen.call(null);
        setIsLink(true);
      } else {
        linkDisclosure.onClose.call(null);
        setIsLink(false);
      }
    }
  }, [editor, linkDisclosure.onClose, linkDisclosure.onOpen]);

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <HStack
      ref={toolbarRef}
      bg="gray.50"
      borderBottomWidth="1px"
      p={1}
      roundedTop="md"
      spacing={1}
      wrap="wrap"
    >
      <IconButton
        aria-label="Undo"
        disabled={!canUndo}
        icon={<FaUndo />}
        variant="ghost"
        onClick={() => editor.dispatchCommand(UNDO_COMMAND, void 0)}
      >
        <i className="format undo" />
      </IconButton>
      <IconButton
        aria-label="Redo"
        disabled={!canRedo}
        icon={<FaRedo />}
        variant="ghost"
        onClick={() => editor.dispatchCommand(REDO_COMMAND, void 0)}
      >
        <i className="format redo" />
      </IconButton>
      <Divider h={8} orientation="vertical" />
      {supportedBlockTypes.has(blockType) && (
        <>
          <BlockOptionsMenu blockType={blockType} editor={editor} />
          <Divider h={8} orientation="vertical" />
        </>
      )}
      <IconButton
        aria-label="Format Bold"
        icon={<FaBold />}
        variant={isBold ? "solid" : "ghost"}
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
      />
      <IconButton
        aria-label="Format Italics"
        icon={<FaItalic />}
        variant={isItalic ? "solid" : "ghost"}
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
      />
      <IconButton
        aria-label="Format Underline"
        icon={<FaUnderline />}
        variant={isUnderline ? "solid" : "ghost"}
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
      />
      <IconButton
        aria-label="Format Strikethrough"
        icon={<FaStrikethrough />}
        variant={isStrikethrough ? "solid" : "ghost"}
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
      />
      <LinkPopover disclosure={linkDisclosure} editor={editor} />
      {isLink && (
        <IconButton
          aria-label="Unlink"
          icon={<FaUnlink />}
          variant="solid"
          onClick={() => editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)}
        />
      )}
      <Divider h={8} orientation="vertical" />
      <IconButton
        aria-label="Left Align"
        icon={<FaAlignLeft />}
        variant={isUnderline ? "solid" : "ghost"}
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")}
      />
      <IconButton
        aria-label="Center Align"
        icon={<FaAlignCenter />}
        variant={isUnderline ? "solid" : "ghost"}
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")}
      />
      <IconButton
        aria-label="Right Align"
        icon={<FaAlignRight />}
        variant={isUnderline ? "solid" : "ghost"}
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")}
      />
      <IconButton
        aria-label="Justify Align"
        icon={<FaAlignJustify />}
        variant={isUnderline ? "solid" : "ghost"}
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")}
      />
    </HStack>
  );
}
