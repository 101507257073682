import { useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Messages } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { createFilters } from "../../../../../shared/hooks/useFilters";
import { CaregiverId, NoteSubjectId, PatientId } from "../../../../../shared/schema/schema";
import { fmap } from "../../../../../shared/utils";
import useCallCenterNoteCache from "../../../hooks/useCallCenterNoteCache";
import { CommCenterCallTicket } from "../helpers/call-ticket-utils";

const createNoteSchema = z.object({
  text: z.string().min(4),
  subjectId: z
    .number()
    .nullable()
    .transform((x) => fmap(x, NoteSubjectId.parse)),
});

export type CreateNoteForm = z.infer<typeof createNoteSchema>;

const useCallTicketNotes = ({
  ticket,
  callId,
}: {
  ticket: CommCenterCallTicket;
  callId: Messages["TelephonyCallId"];
}) => {
  const queryClient = useQueryClient();
  const { getTicketNoteCacheText, getTicketNoteCacheSubjectId, clearTicketNoteCache } =
    useCallCenterNoteCache();
  const toast = useToast();
  const { api, queries } = useApi();
  const { control, handleSubmit, setValue, watch, reset, formState } = useForm<CreateNoteForm>({
    resolver: zodResolver(createNoteSchema),
    defaultValues: async () => ({
      subjectId: getTicketNoteCacheSubjectId(ticket.id),
      text: getTicketNoteCacheText(ticket.id),
    }),
  });

  const createNoteMutation = useMutation({
    mutationKey: ["create-call-ticket-note"],
    mutationFn: (data: {
      text: string;
      subjectId: NoteSubjectId | null;
      patientId?: PatientId;
      caregiverId?: CaregiverId;
    }) =>
      api.post("./telephony/calls/:callId/notes", {
        path: {
          callId: callId,
        },
        body: {
          body: data.text,
          subjectId: data.subjectId,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(queries.telephony.call(ticket.callInfo.id));
      clearTicketNoteCache(ticket.id);
      reset();
      toast({
        position: "top-right",
        status: "success",
        title: "Note added successfully",
      });
    },
  });

  const { data: noteSubjects = [] } = useQuery({
    ...queries.note.settings(),
    select: (x): { label: string; value: NoteSubjectId }[] => {
      return (x.agencyNoteSubjects ?? []).map((x) => ({ label: x.text, value: x.id }));
    },
  });

  const { createSelectFilter } = createFilters<CreateNoteForm>();
  const subjectIdSelectFilter = createSelectFilter({
    name: "subjectId",
    disabled: false,
    label: "Note subject",
    options: noteSubjects,
    onChange: (name, selected) => setValue(name, selected ?? null),
    value: watch("subjectId"),
  });

  const handleValidSubmit = (data: CreateNoteForm) => {
    createNoteMutation.mutate({
      subjectId: data.subjectId,
      text: data.text,
    });
  };

  return {
    formState,
    control,
    subjectIdSelectFilter,
    onSubmit: handleSubmit(handleValidSubmit),
    clearForm: () => {
      reset();
      clearTicketNoteCache(ticket.id);
    },
  };
};

export default useCallTicketNotes;
