// eslint-disable-next-line no-restricted-imports
import { match } from "ts-pattern";
import { Messages } from "../../../../../../core/api";
import CallTicketEventCardAnswer from "./components/CallTicketEventCardAnswer";
import CallTicketEventCardBarge from "./components/CallTicketEventCardBarge";
import CallTicketEventCardEnter from "./components/CallTicketEventCardEnter";
import CallTicketEventCardHangup from "./components/CallTicketEventCardHangup";
import CallTicketEventCardInvited from "./components/CallTicketEventCardInvited";
import CallTicketEventCardLeave from "./components/CallTicketEventCardLeave";
import CallTicketEventCardRemoved from "./components/CallTicketEventCardRemoved";
import CallTicketEventCardRingingAt from "./components/CallTicketEventCardRingingAt";
import CallTicketEventCardRingingFrom from "./components/CallTicketEventCardRingingFrom";
import CallTicketEventCardIVR from "./components/CallTicketEventIVR";

interface Props {
  event: Messages["TelephonyCallParticipantEvent"];
}

const CallTicketEventCard = ({ event }: Props) => {
  return match(event)
    .with({ data: { name: "Initiated" } }, (event) => (
      <CallTicketEventCardRingingFrom event={event} />
    ))
    .with({ data: { name: "RangTo" } }, (event) => <CallTicketEventCardRingingAt event={event} />)
    .with({ data: { name: "Answered" } }, (event) => <CallTicketEventCardAnswer event={event} />)
    .with({ data: { name: "BargedIn" } }, (event) => <CallTicketEventCardBarge event={event} />)
    .with({ data: { name: "InputGathered" } }, (event) => <CallTicketEventCardIVR event={event} />)
    .with({ data: { name: "Hungup" } }, (event) => <CallTicketEventCardHangup event={event} />)
    .with({ data: { name: "Left" } }, (event) => <CallTicketEventCardLeave event={event} />)
    .with({ data: { name: "Invited" } }, (event) => <CallTicketEventCardInvited event={event} />)
    .with({ data: { name: "Joined" } }, (event) => <CallTicketEventCardEnter event={event} />)
    .with({ data: { name: "Removed" } }, (event) => <CallTicketEventCardRemoved event={event} />)
    .with({ data: { name: "EavesDropped" } }, () => <></>)
    .with({ data: { name: "Declined" } }, () => <></>)
    .exhaustive();
};

export default CallTicketEventCard;
