import { LocalDate } from "@js-joda/core";

//! @ngInject
export function dueDateForCaregiverModalCtrl($scope, $rootScope, DatabaseApi, toaster) {

    $scope.publishNewDueDate = function (bundle) {
        if(!bundle.dueDate) {
            toaster.pop("error", "Please enter a valid date");
        }

        var d = new Date(bundle.dueDate);
        d = LocalDate.of(d.getFullYear(), d.getMonth() + 1, d.getDate()).toString();

        var dueDateRequest = {
            bundleId: bundle.id,
            dueDate: d
        }
        DatabaseApi.post("agencies/" + $rootScope.agencyId + "/agency_members/" +
            $rootScope.agencyMemberId + "/caregivers/" + $scope.caregiverId +
            "/due_dates", dueDateRequest).then(function (response) {
                bundle.published = true;
                $scope.getVideos();
                toaster.pop("success", "Due date added succefully");
            }, function (err) {
                toaster.pop("error", "Failed to add a new due date");
            })
    };
};
