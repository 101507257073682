import { Flex, Text } from "@chakra-ui/react";
import { CommCenterTicketId, VisitAssignmentId } from "../../../../../shared/schema/schema";
import { VisitAssignmentActiveTicket } from "./VisitAssignmentActiveTicket";

export function AssignmentTicketsContent(props: {
  assignmentId: VisitAssignmentId;
  ticketId: CommCenterTicketId | null;
  onCloseTicket: () => void;
}) {
  if (props.ticketId === null) {
    return (
      <Flex align="center" h="full" justify="center" w="full">
        <Text color="gray.400" fontSize="2xl">
          No chat selected
        </Text>
      </Flex>
    );
  }

  return (
    <Flex h="full" sx={{ ">*": { width: "full" } }} w="full">
      <VisitAssignmentActiveTicket
        assignmentId={props.assignmentId}
        ticketId={props.ticketId}
        onCloseTicket={props.onCloseTicket}
      />
    </Flex>
  );
}
