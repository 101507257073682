import { CheckIcon } from "@chakra-ui/icons";
import { Button, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../../../shared/hooks/useApi";
import { VisitAssignmentStatus } from "../../../../../shared/schema/gql/graphql";
import { VisitAssignmentId } from "../../../../../shared/schema/schema";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";
import { GET_VISIT_ASSIGNMENTS_QUERY } from "../visit-assignment.graphql";

export function VisitAssignmentButton(props: {
  id: VisitAssignmentId;
  status: VisitAssignmentStatus;
}) {
  const { api, queries } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const resolve = useMutation({
    mutationFn: () => {
      return api.post("./assignment/:visitAssignmentId", {
        path: { visitAssignmentId: props.id },
        body: { status: "Resolved" },
      });
    },
    onMutate: () => {
      queryClient.setQueryData(queries.visit.assignments.get(props.id).queryKey, (prev) => {
        if (prev === undefined) return undefined;
        return { ...prev, status: VisitAssignmentStatus.Resolved };
      });
    },
    onError: (error) => {
      toast({
        title: "Failed to resolve assignment",
        description: formatErrorResponse(error),
        status: "error",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(queries.visit.assignments.get(props.id));
      queryClient.invalidateQueries({ queryKey: [GET_VISIT_ASSIGNMENTS_QUERY] });
    },
  });

  switch (props.status) {
    case VisitAssignmentStatus.Resolved:
      return (
        <Button colorScheme="green" rightIcon={<CheckIcon />}>
          Resolved
        </Button>
      );
    case VisitAssignmentStatus.Unresolved:
      return (
        <Button colorScheme="blue" isLoading={resolve.isPending} onClick={() => resolve.mutate()}>
          Resolve
        </Button>
      );
  }
}
