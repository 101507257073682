import { ButtonProps } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import Select from "../../../shared/components/Select";
import SelectNoResults from "../../../shared/components/SelectNoResults";
import { noteTypeEntries } from "../utils/note.utils";

export default function NoteTypeSelect(props: {
  buttonProps?: ButtonProps;
  value: Messages["NoteTypes"] | null;
  onChange: (value: Messages["NoteTypes"] | undefined) => void;
}) {
  return (
    <Select
      buttonProps={props.buttonProps}
      label="Select type"
      multiple={false}
      options={noteTypeEntries.map(([value, label]) => ({ value, label }))}
      renderAfter={({ searchTerm, filteredOptions }) =>
        filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
      }
      value={props.value}
      onChange={props.onChange}
    />
  );
}
