import { useCallbackRef } from "@chakra-ui/react";
import React from "react";
import plivoClient from "../../../shared/utils/plivo-client";
import { typedObjectEntries } from "../../../shared/utils/ts-utils";
import { CallInfo } from "plivo-browser-sdk/managers/callSession";
import { ConnectionInfo } from "plivo-browser-sdk/client";
import { AudioEvent } from "@aws-sdk/client-transcribe-streaming";

export const customClientEvents = {
  muteToggle: "plivo-mute-toggle",
  silent: "plivo-silent",
  unsilent: "plivo-unsilent",
};

const clientEventNames = [
  "onCallAnswered",
  "onCallTerminated",
  "onMediaConnected",
  "onConnectionChange",
  "onMediaPermission",
  "onLoginFailed",
  "remoteAudioStatus",
] as const;

export default function usePhoneProviderListeners(props?: {
  onCallAnswered?: (callInfo: CallInfo) => void;
  onCallTerminated?: (
    details: { originator: string; reason?: string | null },
    callInfo: CallInfo
  ) => void;
  onConnectionChange?: (connectionInfo: ConnectionInfo) => void;
  onMediaConnected?: (callInfo: CallInfo) => void;
  onChangeMediaPermission?: (event: AudioEvent | { status: "failure"; error: string }) => void;
  onLoginFailed?: (cause: string) => void;
  onChangeRemoteAudioStatus?: (status: boolean) => void;
  onToggleMute?: () => void;
  onSilent?: () => void;
  onUnsilent?: () => void;
}) {
  const log = React.useCallback((eventName: string, ...args: any[]) => {
    console.debug(`[plivo client] ${eventName}`, ...args);
  }, []);
  const onCallAnswered = useCallbackRef(props?.onCallAnswered);
  const onCallTerminated = useCallbackRef(props?.onCallTerminated);
  const onConnectionChange = useCallbackRef(props?.onConnectionChange);
  const onMediaConnected = useCallbackRef(props?.onMediaConnected);
  const onChangeMediaPermission = useCallbackRef(props?.onChangeMediaPermission);
  const onLoginFailed = useCallbackRef(props?.onLoginFailed);
  const onChangeRemoteAudioStatus = useCallbackRef(props?.onChangeRemoteAudioStatus);
  const onToggleMute = useCallbackRef(props?.onToggleMute);
  const onSilent = useCallbackRef(props?.onSilent);
  const onUnsilent = useCallbackRef(props?.onUnsilent);

  React.useEffect(() => {
    const customEvents: Record<keyof typeof customClientEvents, () => void> = {
      muteToggle: () => {
        log("Mute toggle");
        onToggleMute();
      },
      silent: () => {
        log("Silent");
        onSilent();
      },
      unsilent: () => {
        log("Unsilent");
        onUnsilent();
      },
    };

    const clientEvents: Record<typeof clientEventNames[number], (...args: any[]) => void> = {
      onCallAnswered: (...args) => {
        log("Call answered", ...args);
        onCallAnswered(...(args as Parameters<typeof onCallAnswered>));
      },
      onCallTerminated: (...args) => {
        log("Call terminated", ...args);
        onCallTerminated(...(args as Parameters<typeof onCallTerminated>));
      },
      onConnectionChange: (...args) => {
        log("Connection change", ...args);
        onConnectionChange(...(args as Parameters<typeof onConnectionChange>));
      },
      onMediaConnected: (...args) => {
        log("Media connected", ...args);
        onMediaConnected(...(args as Parameters<typeof onMediaConnected>));
      },
      onMediaPermission: (...args) => {
        log("Media permission", ...args);
        onChangeMediaPermission(...(args as Parameters<typeof onChangeMediaPermission>));
      },
      onLoginFailed: (...args) => {
        log("Login failed", ...args);
        onLoginFailed(...(args as Parameters<typeof onLoginFailed>));
      },
      remoteAudioStatus: (...args) => {
        log("Remote audio status", ...args);
        onChangeRemoteAudioStatus(...(args as Parameters<typeof onChangeRemoteAudioStatus>));
      },
    };

    for (const [eventName, callback] of typedObjectEntries(customEvents)) {
      window.addEventListener(customClientEvents[eventName], callback);
    }

    for (const [eventName, callback] of typedObjectEntries(clientEvents)) {
      plivoClient.on(eventName, callback);
    }

    return () => {
      for (const [eventName, callback] of typedObjectEntries(customEvents)) {
        window.removeEventListener(customClientEvents[eventName], callback);
      }

      for (const [eventName, callback] of typedObjectEntries(clientEvents)) {
        plivoClient.off(eventName, callback);
      }
    };
  }, [
    log,
    onCallAnswered,
    onCallTerminated,
    onChangeMediaPermission,
    onChangeRemoteAudioStatus,
    onConnectionChange,
    onLoginFailed,
    onMediaConnected,
    onSilent,
    onToggleMute,
    onUnsilent,
  ]);
}
