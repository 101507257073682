import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { VisitAssignmentTicketRelationLayout } from "./VisitAssignmentTicketRelationLayout";
import { VisitAssignmentTicketRelationButton } from "./VisitAssignmentTicketRelationButton";

export function VisitAssignmentTicketVisitInstanceRelation(props: { ticket: CommCenterTicket }) {
  if (props.ticket.relatedVisitInstance === null) return null;

  const { id, startTime, endTime } = props.ticket.relatedVisitInstance;

  return (
    <VisitAssignmentTicketRelationLayout colorScheme="purple">
      This ticket is related to the visit that starts at{" "}
      <VisitAssignmentTicketRelationButton
        colorScheme="purple"
        params={{ visitInstance: id }}
        to="app.visitAssignments.assignment"
      >
        {dateFormatter.toDateTimeRange(startTime, endTime, { overnight: true })}
      </VisitAssignmentTicketRelationButton>
    </VisitAssignmentTicketRelationLayout>
  );
}
