import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { queryKeys } from "../query-keys";

interface DownloadParams {
  fileName: string;
  url: string;
}

export function useDownloadFile() {
  const queryClient = useQueryClient();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const download = async ({ fileName, url }: DownloadParams) => {
    const trimmedUrl = url.includes("s3.amazonaws.com") ? url.split("?")[0] : url;

    const blobUrl: { blobUrl: string; fileName: string } =
      queryClient.getQueryData(queryKeys.shared.image(trimmedUrl)) ??
      (await queryClient.fetchQuery({
        queryKey: queryKeys.shared.image(trimmedUrl),
        staleTime: Infinity,
        queryFn: async () => {
          setIsDownloading(true);
          const res = await fetch(url);
          const blob = await res.blob();
          return {
            blobUrl: URL.createObjectURL(blob),
            fileName,
          };
        },
      }));

    setIsDownloading(false);
    if (blobUrl.blobUrl) {
      const a = document.createElement("a");
      a.href = blobUrl.blobUrl;
      a.download = blobUrl.fileName;
      document.body.appendChild(a);
      a.click();
    }
  };

  return { download, isDownloading };
}
