import { Flex } from "@chakra-ui/react";
import { Messages } from "../../../../../../../core/api";
import DialpadIcon from "../../../../../../../shared/icons/DialpadIcon";
import { dateFormatter } from "../../../../../../../shared/utils/date-formatter";
import CallTicketIndexBadge from "../../../../CallTicketIndexBadge";
import CallTicketText from "../../CallTicketText";
import { getParticipantDescription } from "../call-ticket-event-utils";

interface Props {
  event: Messages["TelephonyCallParticipantEvent"] & { data: { name: "InputGathered" } };
}

const gatherInputTypeToTextMap: Record<Messages["GatherActionType"], string> = {
  CaregiverRequestsApplicationSMS: "requested application SMS.",
  CaregiverRequestsToLeaveVoiceMail: "requested to leave voice mail.",
  HandleInvalidGatherInput: "invalid digit.",
  IdentifyParticipantAsCaregiver: "identified as caregiver.",
  IdentifyParticipantAsInsuranceProvider: "identified as insurance provider.",
  IdentifyParticipantAsIntakePatient: "identified as intake patient.",
  IdentifyParticipantAsPatient: "identified as patient.",
  InsuranceProviderRequestsIntakeTeam: "requested intake team.",
  InsuranceProviderRequestsStaffingTeam: "requested staffing team.",
  PatientRequestsMoveToSms: "requested move to SMS.",
  PatientSelectsIntakeTeam: "selected intake team.",
  PatientSelectsStaffingTeam: "selected staffing team.",
  PlanSelectsAuthorization: "selected authorization.",
  PlanSelectsNewPatient: "selected new patient.",
  PlanSelectsStaffingTeam: "selected staffing team.",
};

const CallTicketEventCardIVR = ({ event }: Props) => {
  return (
    <Flex alignItems="center" gap={2}>
      <CallTicketIndexBadge>
        <DialpadIcon />
      </CallTicketIndexBadge>
      <Flex direction="column">
        <CallTicketText color="gray.400">
          {dateFormatter.toDateTime(event.timestamp)}
        </CallTicketText>
        <Flex gap={1}>
          <CallTicketText fontWeight="bold">
            {getParticipantDescription(event.data.participant)}
          </CallTicketText>
          <CallTicketText>{formatIVREventData(event.data)}</CallTicketText>
        </Flex>
      </Flex>
    </Flex>
  );
};

const formatIVREventData = (data: Messages["TelephonyCallParticipantInputGatheredEventData"]) => {
  const gatherInputType = gatherInputTypeToTextMap[data.gatherInputType];
  const gatherInputTypeText =
    gatherInputType !== undefined ? `: ${gatherInputTypeToTextMap[data.gatherInputType]}` : "";

  return `pressed digit "${data.digitPressed}"` + gatherInputTypeText;
};

export default CallTicketEventCardIVR;
