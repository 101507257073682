import { Center, Text, useColorModeValue } from "@chakra-ui/react";
import { DropzoneOptions, DropzoneState } from "react-dropzone";
import OutlineInsertDriveFileIcon from "../icons/OutlineInsertDriveFileIcon";

interface Props {
  options: DropzoneOptions;
  state: DropzoneState;
}

export default function Dropzone(props: Props) {
  const { options, state } = props;

  const dropText = state.isDragActive
    ? "Drop the files here ..."
    : `Drag and drop ${options.multiple ? "some files" : "a file"} here, or click to select`;

  const activeBg = useColorModeValue("gray.100", "gray.600");
  const borderColor = useColorModeValue(
    state.isDragActive ? "blue.300" : "gray.300",
    state.isDragActive ? "blue.500" : "gray.500"
  );
  const textColor = useColorModeValue(
    state.isDragActive ? "blue.500" : "gray.500",
    state.isDragActive ? "blue.300" : "gray.300"
  );

  return (
    <Center
      _hover={{ bg: activeBg }}
      bg={state.isDragActive ? activeBg : "transparent"}
      border="3px dashed"
      borderColor={borderColor}
      borderRadius={4}
      cursor="pointer"
      gap={4}
      p={10}
      transition="background-color 0.2s ease"
      {...state.getRootProps()}
    >
      <input {...state.getInputProps()} />
      <OutlineInsertDriveFileIcon color={textColor} h={12} w={12} />
      <Text color={textColor}>{dropText}</Text>
    </Center>
  );
}
