import { graphql } from "../../shared/schema/gql";

export const GET_AUTHORIZED_PATIENTS_WITHOUT_VISITS_QUERY = graphql(`
  query GetAuthorizedPatientsWithoutVisits(
    $order: [AuthorizedPatientWithoutVisitsView__Order]!
    $limit: Int!
    $offset: Int!
    $from: LocalDate
    $to: LocalDate
  ) {
    authorizedPatientsWithoutVisits(
      order: $order
      limit: $limit
      offset: $offset
      from: $from
      to: $to
    ) {
      totalCount
      nodes {
        patient {
          id
          firstName
          middleName
          lastName
          status
          displayId
          gender
        }
        patientAuthorization {
          id
          startDate
          endDate
        }
      }
    }
  }
`);
