import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
} from "@chakra-ui/react";
import useInviteToCallModal from "../../../call-center/hooks/useInviteToCallModal";
import DialerTabs, { DialerTabsContext } from "../DialerTabs";

const inviteToCallDialerTabsContext: DialerTabsContext = {
  hideAgencyMembers: false,
  hideCaregivers: false,
  hidePatients: false,
  hidePhoneNumber: false,
  hidePhonebook: false,
};

export default function InviteToCallModal() {
  const modal = useInviteToCallModal();

  return (
    <Modal isOpen={modal.state.isOpen} size="2xl" onClose={modal.close}>
      <ModalOverlay zIndex={10001} />
      <ModalContent containerProps={{ zIndex: 10001 }}>
        <ModalCloseButton />
        <ModalBody minH="500px">
          <Progress
            isIndeterminate
            hidden={!modal.state.isOpen || !modal.state.isCalling}
            size="xs"
          />

          <ModalHeader textAlign="center">
            <Heading>Add To Call</Heading>
          </ModalHeader>

          {modal.state.isOpen && (
            <DialerTabs
              context={inviteToCallDialerTabsContext}
              isCalling={modal.state.isCalling}
              onClickAgencyMember={modal.addAgencyMember}
              onClickDial={modal.dial}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
