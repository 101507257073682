import { Box, Flex, Text } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import { WorkflowTaskInstanceId } from "../../../../../shared/schema/schema";
import HumanTaskDefaultForm from "../HumanTaskDefaultForm";
import { HumanTaskSubmitParams } from "../HumanTaskForm";
import { HumanTaskLayoutWrapper } from "../components/HumanTaskLayoutWrapper";

interface Props {
  workflowTaskInstanceId: WorkflowTaskInstanceId;
  instructions: string;
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  onSubmit: (params: HumanTaskSubmitParams) => void;
}

export default function HumanTaskManualLayout(props: Props) {
  return (
    <HumanTaskLayoutWrapper>
      <Flex direction="column" gap={3}>
        <Box bg="gray.50" mt={4} p={8} rounded="lg">
          <Text fontSize="lg" fontWeight="semibold">
            Instructions:
          </Text>
          <Text fontSize="lg" whiteSpace="pre-wrap">
            {props.instructions}
          </Text>
        </Box>

        <HumanTaskDefaultForm
          output={props.output}
          workflowTaskInstanceId={props.workflowTaskInstanceId}
          onSubmit={props.onSubmit}
        />
      </Flex>
    </HumanTaskLayoutWrapper>
  );
}
