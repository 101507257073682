import { useEntityLink } from "../hooks/useEntityLink";
import { CaregiverEntity, PatientEntity } from "./EntityCard";

export default function EntityLink(
  props: Pick<CaregiverEntity | PatientEntity, "id" | "type" | "fullName">
) {
  const entityLink = useEntityLink();

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    entityLink.open({ event, entity: props });
  };

  return <a onClick={handleClick}>{props.fullName}</a>;
}
