import { keepPreviousData, useQuery } from "@tanstack/react-query";
import EntityCard, { IntakePatientEntity } from "../../../../../../shared/components/EntityCard";
import useApi from "../../../../../../shared/hooks/useApi";
import { useEntityLink } from "../../../../../../shared/hooks/useEntityLink";
import { PatientId } from "../../../../../../shared/schema/schema";
import { getFullName } from "../../../../../../shared/utils/get-full-name";
import { CardButton } from "./CardButton";
import { LoadingEntityCard } from "./LoadingEntityCard";

export function IntakePatientLinkCard(props: { patientId: PatientId }) {
  const { queries } = useApi();
  const patientQuery = useQuery({
    ...queries.intake.profile(props.patientId),
    placeholderData: keepPreviousData,
    select: (data) => data.patient,
  });
  const { open } = useEntityLink();

  if (patientQuery.isPending) {
    return <LoadingEntityCard />;
  }

  if (patientQuery.isError) {
    return null;
  }

  const patient = patientQuery.data;

  const entity: IntakePatientEntity = {
    type: "IntakePatient",
    id: patient.id,
    displayId: patient.displayId,
    gender: patient.gender,
    fullName: getFullName(patient),
    intakeStatus: patient.intakeStatus.status,
    contactDetails: null,
    selfServe: patient.intakeFlowType === "Self Serve",
    wasSelfServe: patient.wasSelfServe,
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => open({ entity, event });

  return (
    <CardButton onClick={handleClick}>
      <EntityCard entity={entity} />
    </CardButton>
  );
}
