import { atom, useAtom } from "jotai";
import { EntityCommunicationsFilters } from "../components/entity-communications/entity-communications.types";

const filtersAtom = atom<EntityCommunicationsFilters>({ type: [], status: [] });

export default function useEntityCommunicationsFilters() {
  const [state, setState] = useAtom(filtersAtom);

  const handleChangeFilter = (
    key: keyof EntityCommunicationsFilters,
    value: EntityCommunicationsFilters[keyof EntityCommunicationsFilters]
  ) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const resetFilters = () => {
    setState({ status: [], type: [] });
  };

  return {
    filters: state,
    setFilter: handleChangeFilter,
    resetFilters,
  };
}
