import { createIcon } from "@chakra-ui/react";

const IosInformationCircleIcon = createIcon({
  displayName: "IosInformationCircleIcon",
  viewBox: "0 0 512 512",
  path: (
    <path
      d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm19 304h-38.2V207.9H275V352zm-19.1-159.8c-11.3 0-20.5-8.6-20.5-20s9.3-19.9 20.5-19.9c11.4 0 20.7 8.5 20.7 19.9s-9.3 20-20.7 20z"
      fill="currentColor"
    />
  ),
});

export default IosInformationCircleIcon;
