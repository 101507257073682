import { Instant } from "@js-joda/core";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { QueryParamsOf } from "../../../../core/api";
import LoadingPage from "../../../../shared/components/LoadingPage";
import useApi from "../../../../shared/hooks/useApi";
import { useQueryParams } from "../../../../shared/hooks/useQueryParams";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../../shared/query-keys";
import { PatientId } from "../../../../shared/schema/schema";
import IntakePlanReferralsTab from "./IntakePlanReferralsTab";
import { usePageFetchingObserver } from "../../../../shared/hooks/usePageIndication";

export interface EligibilityCheckResult {
  isEligible: boolean | null;
  isEligibleOnOtherPayer: boolean;
  eligibilityPayer: string | null;
  createdAt: Instant;
  message: string | null;
}

const IntakePlanReferralsRoute = () => {
  const { api, queries } = useApi();
  const queryClient = useQueryClient();
  const { stateService } = useRouter();

  const queryParams = useQueryParams<QueryParamsOf<"get", "./patient_intake/dashboard">>({
    storageKey: ["patient-intake", "dashboard-table"],
  });
  const plansQueryParams = useQueryParams<QueryParamsOf<"get", "./patient_intake/patient_plans">>({
    storageKey: ["patient-intake", "plans-table"],
  });

  const intakeStatuses = useQuery({
    queryKey: queryKeys.patientIntake.intakeStatus(),
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const data = await api.get("./patient_intake/intake_status", {});

      const defaultMainStatuses = data.intakeStatuses
        .filter((x) => ["LEAD", "ACTIVE_INTAKE"].includes(x.code))
        .map((x) => x.id);
      const defaultStatuses = data.intakeStatuses
        .filter((x) => x.parentId !== null && defaultMainStatuses.includes(x.parentId))
        .map((x) => x.id);
      queryParams.setValue("mainIntakeStatusId", defaultMainStatuses);
      queryParams.setValue("intakeStatusId", defaultStatuses);

      return data;
    },
    staleTime: Infinity,
  });

  const initiatedPreselect =
    intakeStatuses.isSuccess &&
    queryParams.getValue("mainIntakeStatusId") !== undefined &&
    queryParams.getValue("intakeStatusId") !== undefined;

  const intakePlans = useQuery({
    queryKey: queryKeys.patientIntake.intakePlan(),
    placeholderData: keepPreviousData,
    queryFn: () => api.get("./patient_intake/intake_plan", {}),
  });

  useSocketEvent({
    key: "NewIntakePatientCreated",
    onEvent: () => queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.search.K }),
  });

  const plansQueryOptions = queries.intake.plans(plansQueryParams.params.toJSON());

  const plans = useQuery({
    ...plansQueryOptions,
    placeholderData: keepPreviousData,
    enabled: initiatedPreselect,
  });

  const goToPatientIntakeProfile = (
    event: React.MouseEvent<HTMLTableRowElement>,
    patientId: PatientId
  ) => {
    const isNewTab = event.ctrlKey || event.metaKey;

    isNewTab
      ? window.open(`/app/patients/patient-intake/${patientId}`)
      : stateService.go("app.patients.intake.dashboard.profile", {
          patientId,
        });
  };

  usePageFetchingObserver(plansQueryOptions);

  if (plans.isLoading || intakeStatuses.isLoading || intakePlans.isLoading) {
    return <LoadingPage />;
  }

  if (plans.isError || intakeStatuses.isError || intakePlans.isError) {
    return <div>Error</div>;
  }

  if (!plans.data || !intakeStatuses.data || !intakePlans.data) {
    return <div>Something went wrong</div>;
  }

  const isFetching = plans.isFetching || intakeStatuses.isFetching;

  return (
    <IntakePlanReferralsTab
      filters={plansQueryParams}
      intakePatientsPlans={plans.data.plans}
      intakePlans={intakePlans.data.intakePlans}
      intakeStatuses={intakeStatuses.data.intakeStatuses}
      isFetching={isFetching}
      onChangeFilter={plansQueryParams.setValue}
      onChangeFilters={plansQueryParams.setValues}
      onClickDashboardRow={goToPatientIntakeProfile}
      onClickRefresh={() => queryClient.invalidateQueries(queries.intake.plans())}
    />
  );
};

export default IntakePlanReferralsRoute;
