import {
  Box,
  Button,
  Flex,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useRouter } from "@uirouter/react";
import React from "react";
import DataTable from "../../../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../../../shared/components/DataTable/useGraphQLDataTable";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import Page from "../../../../shared/components/Page";
import useApi from "../../../../shared/hooks/useApi";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import { createFilters } from "../../../../shared/hooks/useFilters";
import CalendarIcon from "../../../../shared/icons/CalendarIcon";
import {
  GetAvailableWorkflowTasksQuery,
  GetAvailableWorkflowTasksQueryVariables,
} from "../../../../shared/schema/gql/graphql";
import { WorkflowSkillId } from "../../../../shared/schema/schema";
import { fmap, fmapIn, toArrayOrNull } from "../../../../shared/utils";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { getFullName } from "../../../../shared/utils/get-full-name";
import { EntitySelect } from "../../components/EntityFormControl";
import WorkflowHumanTaskSelect from "../../components/WorkflowHumanTaskSelect";
import WorkflowLatestDefinitionSelect from "../../components/WorkflowLatestDefinitionSelect";
import WorkflowSkillSelect from "../../components/WorkflowSkillSelect";
import WorkflowTaskClusterSelect from "../../components/WorkflowTaskClusterSelect";
import { GET_AVAILABLE_WORKFLOW_TASKS } from "../../workflow.graphql";

const { createRangeDatePickerFilter } = createFilters<GetAvailableWorkflowTasksQueryVariables>();

export default function WorkflowTasksPage() {
  const { stateService } = useRouter();

  const { query, dataTableProps, setFilter, globalFilters } = useGraphQLDataTable({
    document: GET_AVAILABLE_WORKFLOW_TASKS,
    connection: "workflowAvailableTaskInstances",
    columns: columns,
    columnVisiblity: {},
    enableColumnFilters: false,
    globalFilters: {
      storage: { key: "workflow/tasks-dashboard", version: 1 },
      initialState: {
        ownerId: { in: [useAuthData().agencyMember.id] },
      },
    },
  });

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date from to",
    startDate: {
      name: "createdAtFrom",
      value: globalFilters.createdAtFrom ?? null,
    },
    endDate: {
      name: "createdAtTo",
      value: globalFilters.createdAtTo ?? null,
    },
    onChange: setFilter,
  });

  const filterNode = (
    <>
      <EntitySelect
        input={{ type: "entity", entity: "Agency Member" }}
        label="Owners"
        multiple={true}
        renderUnselected="Owners"
        value={globalFilters.ownerId?.in ?? null}
        onChange={(x) => setFilter("ownerId", fmapIn(x))}
      />

      <EntitySelect
        input={{ type: "entity", entity: "Agency Member" }}
        label="Assignees"
        multiple={true}
        renderUnselected="Assignees"
        value={globalFilters.assigneeId?.in ?? null}
        onChange={(x) => setFilter("assigneeId", fmapIn(x))}
      />

      <WorkflowHumanTaskSelect
        multiple={true}
        value={globalFilters.taskName?.in ?? null}
        onChange={(x) => setFilter("taskName", fmapIn(x))}
      />

      <WorkflowLatestDefinitionSelect
        multiple={true}
        value={globalFilters.correlationKey?.in ?? null}
        onChange={(x) => setFilter("correlationKey", fmapIn(x))}
      />

      <WorkflowSkillSelect
        multiple={true}
        value={toArrayOrNull(globalFilters.skillIds)}
        onChange={(x) => setFilter("skillIds", x ?? null)}
      />

      <WorkflowTaskClusterSelect
        multiple={true}
        value={globalFilters.clusterId?.in ?? null}
        onChange={(x) => setFilter("clusterId", fmapIn(x))}
      />

      <EntitySelect
        input={{ type: "entity", entity: "Patient" }}
        label="Patients"
        multiple={true}
        renderUnselected="Patients"
        value={toArrayOrNull(globalFilters.patientIds)}
        onChange={(x) => setFilter("patientIds", x)}
      />

      <EntitySelect
        input={{ type: "entity", entity: "Caregiver" }}
        label="Caregivers"
        multiple={true}
        renderUnselected="Caregivers"
        value={toArrayOrNull(globalFilters.caregiverIds)}
        onChange={(x) => setFilter("caregiverIds", x)}
      />

      <InputGroup width="xs">
        <RangeDatePicker {...fromToFilter} inputProps={{ width: "full" }} />
        <InputRightElement>
          <CalendarIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
    </>
  );

  return (
    <Page isLoading={query.isFetching}>
      <Page.Header>
        <Page.Title>
          Tasks ({query.data?.workflowAvailableTaskInstances.totalCount ?? "..."})
        </Page.Title>
      </Page.Header>

      <Page.Content p={0}>
        <DataTable
          {...dataTableProps}
          filterNode={filterNode}
          spacing="tight"
          onClickRow={(e, { original: { id } }) => {
            const isNewTab = e.ctrlKey || e.metaKey;

            isNewTab
              ? window.open(`/app/workflow-tasks/${id}`)
              : stateService.go("app.workflow_tasks.task", { id });
          }}
        />
      </Page.Content>
    </Page>
  );
}

function Skills(props: { skills: WorkflowSkillId[] }) {
  return (
    <Flex gap={2}>
      {props.skills.map((s) => (
        <Tag key={s}>{s}</Tag>
      ))}
    </Flex>
  );
}

const { accessor, display } =
  createColumnHelper<
    GetAvailableWorkflowTasksQuery["workflowAvailableTaskInstances"]["nodes"][number]
  >();

const columns = [
  accessor("id", {
    header: "ID",
    meta: { gqlFilterKey: "id", gqlFilterMap: "eq", gqlFilterType: "number", gqlSortKey: "id" },
  }),
  accessor("name", {
    header: "Name",
    meta: { gqlFilterKey: "nameSearch", gqlSortKey: "name", sticky: "left" },
    cell: (props) => (
      <Flex direction="column" gap={1}>
        <Text fontWeight="semibold">{props.getValue()}</Text>
        <Text color="gray" fontSize="sm">
          {props.row.original.description}
        </Text>
      </Flex>
    ),
  }),
  accessor("workflowDefinition.name", {
    id: "workflowDefinitionName",
    header: "Workflow",
    meta: { gqlSortKey: "workflowDefinitionName" },
  }),
  accessor("createdAt", {
    header: "Created At",
    meta: { gqlSortKey: "createdAt" },
    cell: (props) => dateFormatter.toDateOrDateTime(props.getValue()),
  }),
  display({
    id: "patient",
    header: "Patient",
    cell: (props) => (
      <EntitiesResolver
        entities={props.row.original.instanceEntities.nodes.filter((x) => x.entity === "Patient")}
      />
    ),
  }),
  display({
    id: "caregiver",
    header: "Caregiver",
    cell: (props) => (
      <EntitiesResolver
        entities={props.row.original.instanceEntities.nodes.filter((x) => x.entity === "Caregiver")}
      />
    ),
  }),
  accessor("instanceEntities.nodes", {
    header: "Entities",
    cell: (props) => <OtherEntitiesPopover entities={props.getValue()} />,
  }),
  accessor("assignee", {
    header: "Assignee",
    meta: { gqlSortKey: "assigneeName", gqlFilterKey: "assigneeSearch" },
    cell: (props) => fmap(props.getValue(), getFullName),
  }),
  accessor("clusterValue", {
    header: "Cluster",
    meta: { gqlSortKey: "clusterValue" },
    cell: (props) =>
      fmap(props.getValue(), (value) => (
        <Tooltip label={props.row.original.clusterId}>{value}</Tooltip>
      )),
  }),
  accessor("priority", {
    header: "Priority",
    meta: { gqlSortKey: "priority" },
  }),
  accessor("severity", {
    header: "Severity",
    meta: { gqlSortKey: "severity" },
    cell: (props) => <Tag>{props.getValue()}</Tag>,
  }),
  accessor("mandatory", {
    header: "Mandatory",
    meta: { gqlSortKey: "mandatory" },
  }),
  accessor("skills.nodes", {
    header: "Skills",
    cell: (props) => <Skills skills={props.getValue().map((x) => x.id)} />,
  }),
];

function EntitiesResolver(props: { entities: { entity: string; value: string }[] }) {
  return (
    <>
      {props.entities.map((entity) => (
        <EntityResolver key={JSON.stringify(entity)} entity={entity.entity} id={entity.value}>
          {(patient) => <Text>{patient}</Text>}
        </EntityResolver>
      ))}
    </>
  );
}

function EntityResolver(props: {
  entity: string;
  id: string;
  onLoading?: () => React.ReactNode;
  onError?: (error: unknown, retry: () => void) => React.ReactNode;
  children?: (data: string) => React.ReactNode;
}) {
  const { queries } = useApi();
  const query = useQuery({
    ...queries.workflow.entityName({ entity: props.entity, id: props.id }),
    staleTime: Infinity,
  });

  switch (query.status) {
    case "pending":
      return <>{props.onLoading?.() ?? null}</>;
    case "error":
      return <>{props.onError?.(query.error, query.refetch) ?? null}</>;
    case "success":
      return <>{props.children?.(String(query.data)) ?? query.data}</>;
  }
}

function OtherEntitiesPopover(props: { entities: { entity: string; value: string }[] }) {
  const filteredEntities = props.entities.filter(
    (x) => !["Patient", "Caregiver"].includes(x.entity)
  );

  if (filteredEntities.length === 0) {
    return null;
  }

  return (
    <Popover isLazy={true}>
      <PopoverTrigger>
        <Button aria-label="more" size="sm" variant="outline">
          {filteredEntities.length}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent p={0} width="fit-content">
          <Box p={5}>
            <Table>
              <Thead>
                <Th>Entity</Th>
                <Th>ID</Th>
                <Th>Value</Th>
              </Thead>
              <Tbody>
                {filteredEntities.map((entity) => (
                  <Tr key={JSON.stringify(entity)}>
                    <Td>{entity.entity}</Td>
                    <Td>{entity.value}</Td>
                    <Td>
                      <EntityResolver entity={entity.entity} id={entity.value} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
