import {
  UseDisclosureReturn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";

export default function AskForConfirmation(props: {
  disclosure: Omit<UseDisclosureReturn, "onClose">;
  title: string;
  description: string;
  onClose: (value: boolean) => void;
}) {
  return (
    <Modal onClose={() => props.onClose(false)} {...props.disclosure} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>{props.description}</ModalBody>
        <ModalFooter gap={2}>
          <Button
            colorScheme="blue"
            onClick={() => {
              props.onClose(true);
            }}
          >
            Confirm
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              props.onClose(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
