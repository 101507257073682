import { Flex, IconButton, Tooltip } from "@chakra-ui/react";
import Select from "../Select";
import RefreshRoundedIcon from "../../icons/RefreshRoundedIcon";
import TableExportIcon from "../../icons/TableExportIcon";
import ViewColumnOutlineRoundedIcon from "../../icons/ViewColumnOutlineRoundedIcon";

export type DataTableMetaButtonsProps = {
  isRefreshing: boolean;
  isExporting: boolean;
  columnOptions: { label: string; value: string }[];
  visibleColumns: string[];
  onClickRefresh: () => void;
  onClickExport: () => void;
  onChangeVisibleColumns: (x: string[] | undefined) => void;
};

export default function DataTableMetaButtons(props: DataTableMetaButtonsProps) {
  return (
    <Flex gap={2}>
      <Tooltip label="Export to CSV">
        <IconButton
          aria-label="Export to CSV"
          icon={<TableExportIcon h={5} w={5} />}
          isLoading={props.isExporting}
          variant="outline"
          onClick={props.onClickExport}
        />
      </Tooltip>

      <Tooltip label="Refresh">
        <IconButton
          aria-label="Refresh"
          icon={<RefreshRoundedIcon h={5} w={5} />}
          isLoading={props.isRefreshing}
          variant="outline"
          onClick={props.onClickRefresh}
        />
      </Tooltip>

      <Select
        label="Columns"
        multiple={true}
        options={props.columnOptions}
        popoverProps={{ placement: "bottom-end" }}
        value={props.visibleColumns}
        onChange={props.onChangeVisibleColumns}
      >
        <IconButton
          aria-label="Columns"
          icon={<ViewColumnOutlineRoundedIcon h={5} w={5} />}
          variant="outline"
        />
      </Select>
    </Flex>
  );
}
