import { useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { PatientId } from "../../../shared/schema/schema";
import { getFullName } from "../../../shared/utils/get-full-name";
import { isDefined } from "../../../utils";
import SendEmailButton from "../../../shared/components/SendEmailButton";
import { ButtonProps } from "@chakra-ui/react";

interface Props {
  patientId: PatientId;
  buttonProps?: ButtonProps;
  text?: string;
}

export default function IntakePatientSendEmailButton(props: Props) {
  const { queries } = useApi();

  const emailsQuery = useQuery({
    ...queries.intake.profile(props.patientId),
    select: (data) => {
      const patient = {
        ...data.patient,
        contacts: orderBy(data.patient.contacts, (contact) => contact.id, "asc"),
      };
      const emails: Messages["CommCenterEmailAddressObject"][] = [];
      if (isDefined(patient.email) && patient.email !== "") {
        emails.push({
          name: getFullName(patient),
          email: patient.email,
        });
      }

      patient.contacts.forEach((contact) => {
        if (isDefined(contact.email) && patient.email !== "") {
          emails.push({
            name: getFullName(contact),
            email: contact.email,
          });
        }
      });

      return emails;
    },
  });

  if (emailsQuery.isError) {
    return (
      <SendEmailButton
        buttonProps={props.buttonProps}
        emails={[]}
        isDisabled={true}
        isLoading={false}
        newTicketDefaultValues={{
          topic: "Patient",
          caregiverId: null,
          patientId: props.patientId,
        }}
        text="Error while loading email button"
      />
    );
  }

  return (
    <SendEmailButton
      buttonProps={props.buttonProps}
      defaultAssignedTeam="Intake"
      emails={emailsQuery.data ?? []}
      isLoading={emailsQuery.isLoading}
      newTicketDefaultValues={{
        topic: "Patient",
        caregiverId: null,
        patientId: props.patientId,
      }}
      text={props.text}
    />
  );
}
