import { Flex, Text } from "@chakra-ui/react";
import { CommCenterTicket } from "../../../../communication/communication.types";

export function VisitAssignmentListedTicketMessagePreview(props: {
  totalUnread: number;
  ticket: CommCenterTicket;
}) {
  return (
    <Flex isTruncated align="center" color="gray.500" fontSize="sm" gap={1}>
      <VisitAssignmentListedTicketMessagePreviewText ticket={props.ticket} />
    </Flex>
  );
}

function VisitAssignmentListedTicketMessagePreviewText(props: { ticket: CommCenterTicket }) {
  const lastMessage = props.ticket.messages.at(-1)?.payload.at(0);

  if (lastMessage === undefined) {
    return <Text color="gray.500">No messages</Text>;
  }

  switch (lastMessage.type) {
    case "TEXT":
      return (
        <Text isTruncated color="black">
          {lastMessage.message}
        </Text>
      );
    case "IMAGE":
      return <Text color="gray.500">[Image]</Text>;
    case "DOCUMENT":
      return <Text color="gray.500">[Document]</Text>;
    default:
      return <Text color="gray.500">...</Text>;
  }
}
