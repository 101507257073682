type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function typedObjectEntries<T extends object>(o: T): Entries<T> {
  return Object.entries(o) as Entries<T>;
}

export function typedObjectKeys<T extends object>(o: T): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}

export function isKeyOf<T extends Record<PropertyKey, unknown>>(
  key: PropertyKey,
  obj: T
): key is keyof T {
  return key in obj;
}
