import { Card, CardBody, Flex, ModalBody, Skeleton, VStack } from "@chakra-ui/react";
import CardHeaderSkeleton from "../../../shared/components/CardHeaderSkeleton";
import FormControlSkeleton from "../../../shared/components/FormControlSkeleton";

export default function NoteModalSkeleton() {
  return (
    <ModalBody>
      <VStack align="stretch" spacing={4}>
        <Card>
          <CardHeaderSkeleton />
          <CardBody>
            <Flex gap={4}>
              <FormControlSkeleton />
              <FormControlSkeleton />
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeaderSkeleton />
          <CardBody>
            <FormControlSkeleton />
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <VStack align="start" spacing={4}>
              <FormControlSkeleton inputW={36} />
              <FormControlSkeleton />

              <VStack align="start" w="full">
                <Skeleton h={5} mt={1} w={16} />
                <Skeleton h={48} w="full" />
              </VStack>

              <Flex gap={4} w="full">
                <FormControlSkeleton />
                <FormControlSkeleton />
              </Flex>

              <Skeleton h={8} w={32} />
            </VStack>
          </CardBody>
        </Card>

        <Flex gap={2} justify="end" w="full">
          <Skeleton h={9} rounded="md" w={24} />
          <Skeleton h={9} rounded="md" w={24} />
        </Flex>
      </VStack>
    </ModalBody>
  );
}
