import { Drawer, DrawerContent, DrawerOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import React from "react";
import { BodyOf } from "../../../core/api";
import LoadingPage from "../../../shared/components/LoadingPage";
import useApi from "../../../shared/hooks/useApi";
import { useGlobalWorkflowRunner } from "../../../shared/hooks/useGlobalWorkflowRunner";
import useSocketEvent from "../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../shared/query-keys";
import { NoteId, PatientId } from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils/common";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { getFullName } from "../../../shared/utils/get-full-name";
import { isEmailTicket } from "../../communication/utils/comm-center-email-utils";
import { isCallTicket, isSmsTicket } from "../../communication/utils/communication-utils";
import NewNoteModal from "../../note/components/NewNoteModal";
import { EditPatientIntake, PatientIntakeProfileDraft } from "../patient-intake.types";
import {
  getIsRemovingPhoneNumberAssociatedWithPortal,
  mergeIntakePatientProfileWithDraft,
} from "../shared/utils/intake.utils";
import IntakePatientProfileDrawerContent from "./IntakePatientProfileDrawerContent";
import PatientProfileRemovePortalPhoneAlertDialog from "./PatientProfileRemovePortalPhoneAlertDialog";

const IntakePatientProfileRoute = (props: {
  patientId: PatientId | undefined;
  onClose: () => void;
}) => {
  const { queries } = useApi();
  const { patientId } = props;

  const checklist = useQuery({
    enabled: patientId !== undefined,
    ...queries.intake.checklist(patientId as PatientId),
  });

  return (
    <Drawer
      blockScrollOnMount={false}
      isOpen={patientId !== undefined}
      placement="right"
      size={checklist.data !== undefined && checklist.data.items.length > 0 ? "2xl" : "xl"}
      trapFocus={false}
      onClose={props.onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        {patientId !== undefined && (
          <IntakePatientProfile patientId={patientId} onClose={props.onClose} />
        )}
      </DrawerContent>
    </Drawer>
  );
};

interface Props {
  patientId: PatientId;
  onClose: () => void;
}

const IntakePatientProfile = (props: Props) => {
  const { api, queries } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();
  const newNoteDisclosure = useDisclosure();

  useSocketEvent({
    key: "PortalPatientDocumentUpload",
    onEvent: (event) => {
      if (event.patientId === props.patientId) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.patientIntake.portalDocuments(props.patientId),
        });
      }
    },
  });

  const intakeProfile = useQuery({
    ...queries.intake.profile(props.patientId),
    placeholderData: keepPreviousData,
    select: (data) => ({
      patient: {
        ...data.patient,
        contacts: orderBy(data.patient.contacts, (contact) => contact.id, "asc"),
      },
    }),
  });

  const { mutate: handleEditPatientIntakeProfileMutation } = useMutation({
    mutationKey: ["edit-patient-intake-profile"],
    mutationFn: (body: BodyOf<"put", "./patients/:patientId/intake">) =>
      api.put("./patients/:patientId/intake", {
        body,
        path: {
          patientId: props.patientId!,
        },
      }),
    onSuccess: (data) => {
      toast({
        title: "Success! patient's details were updated",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.setQueryData(queries.intake.profile(props.patientId).queryKey, data);
      queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.intakeCallsOrder() });
      queryClient.invalidateQueries(queries.intake.checklist(props.patientId));
    },
    onError: (error) => {
      toast({
        title: `Error in updating the patient's details`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const workflowRunner = useGlobalWorkflowRunner();

  React.useEffect(() => {
    if (!intakeProfile.isSuccess) {
      return;
    }

    workflowRunner.setHints.call(null, [
      {
        type: "prefill-field",
        fieldType: { type: "entity", entity: "Patient" },
        display: getFullName(intakeProfile.data.patient),
        value: intakeProfile.data.patient.id,
      },
    ]);
  }, [intakeProfile.data, intakeProfile.isSuccess, workflowRunner.setHints]);

  const markNoteAsDone = useMutation({
    mutationFn: (noteId: NoteId) => {
      return api.put("./notes/:noteId/status", {
        path: { noteId },
        body: { status: "DONE" },
      });
    },
    onSuccess: () => {
      toast({
        title: "Successfuly marked note as done",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries(queries.intake.profile(props.patientId));
    },
    onError: (error) => {
      toast({
        title: `Error in marking note as done.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const intakeStatuses = useQuery({
    queryKey: queryKeys.patientIntake.intakeStatus(),
    placeholderData: keepPreviousData,
    queryFn: () => api.get("./patient_intake/intake_status", {}),
  });

  const intakePlans = useQuery({
    queryKey: queryKeys.patientIntake.intakePlan(),
    placeholderData: keepPreviousData,
    queryFn: () => api.get("./patient_intake/intake_plan", {}),
  });

  const communicationLog = useQuery({
    queryKey: queryKeys.patientIntake.communicationLog(props.patientId),
    placeholderData: keepPreviousData,
    queryFn: () =>
      api.get("./comm_center/tickets/patients/:patientId", {
        path: {
          patientId: props.patientId!,
        },
      }),
    select: (data) => {
      const filteredData = data.tickets.filter(
        (ticket) => isEmailTicket(ticket) || isCallTicket(ticket) || isSmsTicket(ticket)
      );
      return { tickets: filteredData, roboCalls: data.roboCalls };
    },
  });

  const portalDocuments = useQuery({
    queryKey: queryKeys.patientIntake.portalDocuments(props.patientId),
    placeholderData: keepPreviousData,
    queryFn: () =>
      api.get("./patients/:patientId/documents", {
        path: {
          patientId: props.patientId!,
        },
      }),
  });

  const intakeTracks = useQuery({
    queryKey: queryKeys.patientIntake.intakeTracks(),
    queryFn: () => api.get("./patient_intake/intake_tracks", {}),
    select: (data) => data.tracks,
  });

  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [patientIntakeProfileDraft, setPatientIntakeProfileDraft] =
    React.useState<EditPatientIntake | null>(null);
  // Only shows what changed in the current session of changes.
  const [didContractsChange, setDidContractsChange] = React.useState<boolean>(false);
  const [didPlansChange, setDidPlansChange] = React.useState<boolean>(false);
  const [didPhoneNumberChange, setDidPhoneNumberChange] = React.useState<boolean>(false);
  const portalPhoneAlertDialogDisclosure = useDisclosure();

  const handleEditDraft = (field: keyof EditPatientIntake, value: any) => {
    setDidContractsChange((prev) => (prev === false ? field === "contacts" : prev));
    setDidPlansChange((prev) => (prev === false ? field === "plans" : prev));
    setDidPhoneNumberChange((prev) => (prev === false ? field === "phoneNumbers" : prev));

    setPatientIntakeProfileDraft((prev) => ({
      ...prev,
      id: prev?.id ?? props.patientId,
      [field]: value,
    }));
  };

  const handleClickEdit = () => {
    setIsEditMode(true);
  };

  if (
    intakeProfile.isLoading ||
    intakePlans.isLoading ||
    intakeStatuses.isLoading ||
    communicationLog.isLoading ||
    portalDocuments.isLoading ||
    intakeProfile.isLoading
  ) {
    return <LoadingPage />;
  }

  if (
    intakeProfile.isError ||
    intakePlans.isError ||
    intakeStatuses.isError ||
    communicationLog.isError ||
    portalDocuments.isError ||
    intakeTracks.isError ||
    intakeProfile.isError
  ) {
    return <div>Error</div>;
  }

  if (
    !intakeProfile.data ||
    !intakePlans.data ||
    !intakeStatuses.data ||
    !communicationLog.data ||
    !portalDocuments.data ||
    !intakeTracks.data ||
    !intakeProfile.data
  ) {
    return <div>Something went wrong</div>;
  }

  const isFetching =
    intakeProfile.isFetching ||
    intakePlans.isFetching ||
    intakeStatuses.isFetching ||
    communicationLog.isFetching ||
    portalDocuments.isFetching ||
    intakeTracks.isFetching;

  const $intakeProfile: PatientIntakeProfileDraft = mergeIntakePatientProfileWithDraft(
    intakeProfile.data.patient,
    patientIntakeProfileDraft,
    didContractsChange,
    didPlansChange,
    didPhoneNumberChange
  );

  const handleClickSave = () => {
    intakeProfile.data.patient.mainPhoneNumber;
    if (
      isEditMode &&
      getIsRemovingPhoneNumberAssociatedWithPortal({
        original: {
          contacts: intakeProfile.data.patient.contacts,
          patientPhone: fmap(
            intakeProfile.data.patient.phoneNumbers.at(0),
            (patientPhoneNumber) => ({
              number: patientPhoneNumber.phonenumber,
              isForPortalLogin: patientPhoneNumber.isForPortalLogin,
            })
          ),
        },
        changes: {
          contacts: patientIntakeProfileDraft?.contacts,
          patientPhoneNumber: patientIntakeProfileDraft?.phoneNumbers?.[0]?.phonenumber ?? null,
        },
      })
    ) {
      portalPhoneAlertDialogDisclosure.onOpen();
      return;
    }

    handleSave();
  };

  const handleSave = () => {
    if (patientIntakeProfileDraft !== null) {
      const lostStatus = intakeStatuses.data?.intakeStatuses.find((s) => s.code === "LOST");
      const didChangetoLostStatus =
        patientIntakeProfileDraft.intakeStatus !== null &&
        lostStatus !== undefined &&
        patientIntakeProfileDraft.intakeStatus?.parentId === lostStatus.id;

      // If the next call date changed to null, and the patient's status is not changed to lost, don't update.
      if (
        patientIntakeProfileDraft.nextCallDate === null &&
        patientIntakeProfileDraft.nextCallDate !== intakeProfile.data?.patient.nextCallDate &&
        !didChangetoLostStatus
      ) {
        toast({
          title: "Next call date is required",
          status: "error",
          description: "Please select a date for the next call",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
      } else {
        handleEditPatientIntakeProfileMutation(patientIntakeProfileDraft, {
          onSuccess: () => {
            setIsEditMode(false);
            setDidContractsChange(false);
            setDidPlansChange(false);
            setDidPhoneNumberChange(false);
            setPatientIntakeProfileDraft(null);
          },
        });
      }
    } else {
      setIsEditMode(false);
      toast({
        title: "No changes were made",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <IntakePatientProfileDrawerContent
        communicationLog={communicationLog.data}
        intakePlans={intakePlans.data.intakePlans}
        intakeProfile={$intakeProfile}
        intakeStatuses={intakeStatuses.data.intakeStatuses}
        intakeTracks={intakeTracks.data}
        isEditMode={isEditMode}
        isFetching={isFetching}
        portalDocuments={portalDocuments.data.documents}
        setPatientIntakeProfile={handleEditDraft}
        onClickAddNewNote={newNoteDisclosure.onOpen}
        onClickEdit={handleClickEdit}
        onClickSave={handleClickSave}
        onCloseIntakeProfile={props.onClose}
        onMarkNoteAsDone={markNoteAsDone.mutate}
      >
        <NewNoteModal
          disclosure={newNoteDisclosure}
          initialValues={{ patientId: props.patientId }}
          onSuccess={() => queryClient.invalidateQueries(queries.intake.profile(props.patientId))}
        />
      </IntakePatientProfileDrawerContent>
      <PatientProfileRemovePortalPhoneAlertDialog
        isOpen={portalPhoneAlertDialogDisclosure.isOpen}
        onClose={portalPhoneAlertDialogDisclosure.onClose}
        onSubmit={handleSave}
      />
    </>
  );
};

export default IntakePatientProfileRoute;
