import {
  Button,
  Flex,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IntakeTrackId } from "../../../../../shared/schema/schema";
import { useCreateIntakeTrackNote } from "../hooks/useCreateIntakeTrackNote";
import React from "react";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";

interface Props {
  trackId: IntakeTrackId | null;
}

export function CreateNewNoteButton(props: Props) {
  const createNewNoteMutation = useCreateIntakeTrackNote();
  const [noteContent, setNoteContent] = React.useState("");
  const toast = useToast();
  const disclosure = useDisclosure();
  const handleClickSaveNote = () => {
    if (props.trackId === null) {
      return;
    }

    createNewNoteMutation.mutate(
      {
        trackId: props.trackId,
        content: noteContent,
        graphPoint: {
          x: 0,
          y: 0,
        },
      },
      {
        onSuccess: () => {
          setNoteContent("");
          toast({
            position: "top",
            title: "Note Created",
            description: "Note has been created successfully",
            status: "success",
          });
          disclosure.onClose();
        },
        onError: (error) => {
          toast({
            position: "top",
            title: "Failed Creating Note",
            description: formatErrorResponse(error),
            status: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Popover {...disclosure}>
        <PopoverTrigger>
          <Button colorScheme="blue" isDisabled={props.trackId === null}>
            New Note
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Add New Note</PopoverHeader>
          <PopoverBody as={Flex} flexDir="column" gap={4}>
            <Input
              placeholder="Note Content"
              type="text"
              value={noteContent}
              onChange={(e) => setNoteContent(e.currentTarget.value)}
            />
            <Button isDisabled={noteContent.trim() === ""} onClick={handleClickSaveNote}>
              Save
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
