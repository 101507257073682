import { PhoneIcon } from "@chakra-ui/icons";
import {
  Avatar,
  AvatarGroup,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  keyframes,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Messages } from "../../../core/api";
import PhoneHangupIcon from "../../../shared/icons/PhoneHangupIcon";
import UserCircleFillIcon from "../../../shared/icons/UserCircleFillIcon";
import {
  formatPhoneNumber,
  getLabelForNotIdentifiedPhoneNumber,
} from "../../../shared/utils/call-center";
import { capitalize } from "../../../shared/utils/common";
import { getFullName } from "../../../shared/utils/get-full-name";
import PatientClinicalSeverityLevelTag from "../../patient/components/PatientClinicalSeverityLevelTag";
import InboundCallConferenceButton from "./InboundCallConferenceButton";
import InboundCallMetaActions from "./InboundCallMetaActions";
import { getTelephonyInboundCallType, isConferenceCall } from "../call-center.utils";
import IntakePatientAgencyMemberAssignmentTag from "../../patientIntake/components/IntakePatientAgencyMemberAssignmentTag";

type Props = {
  subtitle: string;
  isOpen: boolean;
  isLoading: boolean;
  isRingtoneMuted: boolean;
  onToggleMuteRingtone: () => void;
  onToggleMinimize: () => void;
  onClose: () => void;
  onAccept: () => void;
  onDecline: () => void;
  call: Messages["TelephonyPortalRequestCallInfo"] | Messages["TelephonyInboundCallInfo"];
};

function getIntakeQueueName(callerEntity: Messages["TelephonyCallParticipantEntityInfo"]) {
  switch (callerEntity.type) {
    case "Patient":
      return callerEntity.intakeQueue;
    case "PatientContact":
      return callerEntity.patient.intakeQueue;
    case "Caregiver":
    case "AgencyMember":
    case "PhonebookContact":
    case "NotIdentified":
      return null;
  }
}

function getAgencyMemberAssignment(callerEntity: Messages["TelephonyCallParticipantEntityInfo"]) {
  switch (callerEntity.type) {
    case "Patient":
      return callerEntity.assignedAgencyMember;
    case "PatientContact":
      return callerEntity.patient.assignedAgencyMember;
    case "Caregiver":
    case "AgencyMember":
    case "PhonebookContact":
    case "NotIdentified":
      return null;
  }
}

function getClinicalSeverityLevel(callerEntity: Messages["TelephonyCallParticipantEntityInfo"]) {
  switch (callerEntity.type) {
    case "Patient":
      return callerEntity.clinicalSeverityLevel;
    case "PatientContact":
      return callerEntity.patient.clinicalSeverityLevel;
    case "Caregiver":
    case "AgencyMember":
    case "PhonebookContact":
    case "NotIdentified":
      return null;
  }
}

function getTitleText(
  call: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
) {
  switch (call.direction) {
    case "Inbound":
      return call.caller.entity.type === "AgencyMember"
        ? "You are invited to a call"
        : "Incoming call";
    case "PortalCallRequest":
      return "Incoming call request from patient portal";
  }
}

function getCaller(
  call: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
) {
  switch (call.direction) {
    case "Inbound":
      return call.caller.entity;
    case "PortalCallRequest":
      return call.requester.entity;
  }
}

export default function InboundCallModal(props: Props) {
  const clinicalSeverityLevel = (() => {
    switch (props.call.direction) {
      case "Inbound":
        return getClinicalSeverityLevel(props.call.caller.entity);
      case "PortalCallRequest":
        return getClinicalSeverityLevel(props.call.requester.entity);
    }
  })();

  const assignedAgencyMember = (() => {
    switch (props.call.direction) {
      case "Inbound":
        return getAgencyMemberAssignment(props.call.caller.entity);
      case "PortalCallRequest":
        return getAgencyMemberAssignment(props.call.requester.entity);
    }
  })();

  const intakeQueueName = (() => {
    switch (props.call.direction) {
      case "Inbound":
        return getIntakeQueueName(props.call.caller.entity);
      case "PortalCallRequest":
        return getIntakeQueueName(props.call.requester.entity);
    }
  })();

  return (
    <Modal
      autoFocus={false}
      closeOnOverlayClick={false}
      isCentered={true}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" justifyContent="flex-end" p={4}>
          <InboundCallMetaActions
            isMinimized={false}
            isRingtoneMuted={props.isRingtoneMuted}
            onClose={props.onClose}
            onToggleMinimize={props.onToggleMinimize}
            onToggleMuteRingtone={props.onToggleMuteRingtone}
          />
        </ModalHeader>
        <ModalBody>
          <VStack gap={6} p={8}>
            <InboundCallSymbol inboundCallType={getTelephonyInboundCallType(props.call)} />
            <VStack gap={8}>
              <Flex align="center" direction="column" gap={2}>
                {isConferenceCall(props.call) && (
                  <Flex my={2}>
                    <InboundCallConferenceButton participants={props.call.participants} />
                  </Flex>
                )}
                <Heading size="md" textAlign="center">
                  {getTitleText(props.call)}
                </Heading>
                <Heading color="gray.600" fontWeight="semibold" size="sm">
                  {`${props.subtitle} ${intakeQueueName ? `(${intakeQueueName})` : ""}`}
                </Heading>

                {assignedAgencyMember !== null && (
                  <IntakePatientAgencyMemberAssignmentTag agencyMember={assignedAgencyMember} />
                )}
              </Flex>
              <CallerBox caller={getCaller(props.call)} />
            </VStack>

            {clinicalSeverityLevel !== null && (
              <PatientClinicalSeverityLevelTag
                clinicalSeverityLevel={clinicalSeverityLevel}
                fontSize="md"
                textAlign="center"
              />
            )}

            <HStack w="full">
              <Button
                colorScheme="red"
                leftIcon={<PhoneHangupIcon />}
                size="lg"
                variant="outline"
                w="full"
                onClick={props.onDecline}
              >
                Decline
              </Button>

              <Button
                colorScheme="green"
                isLoading={props.isLoading}
                leftIcon={<PhoneIcon h={4} />}
                size="lg"
                w="full"
                onClick={props.onAccept}
              >
                Accept
              </Button>
            </HStack>
            <Divider />
            <RingingToAgencyMembers agencyMembers={props.call.ringingTo} />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function RingingToAgencyMembers(props: {
  agencyMembers: Messages["TelephonyAgencyMemberRingingTo"][];
}) {
  if (props.agencyMembers.length === 1) {
    return (
      <Center>
        <Text color="gray.500" fontSize="sm" textAlign="center">
          This call is only ringing to you
        </Text>
      </Center>
    );
  }

  return (
    <VStack gap={5} w="full">
      <Text color="gray.500" fontSize="sm" textAlign="center">
        Ringing to {props.agencyMembers.length} members
      </Text>
      <AvatarGroup max={10} size="md">
        {props.agencyMembers.map((x) => (
          <Avatar
            key={x.id}
            name={getFullName(x)}
            src={x.photoUrl ?? undefined}
            title={getFullName(x)}
          />
        ))}
      </AvatarGroup>
    </VStack>
  );
}

const pulse = keyframes`
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 var(--shadow-color);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
`;

function InboundCallSymbol(props: {
  inboundCallType: "Invitation" | "Incoming" | "PortalCallRequest";
}) {
  const options = (() => {
    switch (props.inboundCallType) {
      case "Invitation":
        return {
          bg: "green.600",
          shadowColor: "var(--chakra-colors-green-600)",
          icon: <UserCircleFillIcon color="green.100" h={8} w={8} />,
        };
      case "Incoming":
        return {
          bg: "blue.600",
          shadowColor: "var(--chakra-colors-blue-600)",
          icon: <PhoneIcon color="blue.100" h={5} w={5} />,
        };
      case "PortalCallRequest":
        return {
          bg: "red.500",
          shadowColor: "var(--chakra-colors-red-500)",
          icon: <PhoneIcon color="red.100" h={5} w={5} />,
        };
    }
  })();

  return (
    <Flex
      alignItems="center"
      animation={`${pulse} 2s infinite`}
      bg={options.bg}
      h={16}
      justify="center"
      rounded="full"
      sx={{
        "--shadow-color": options.shadowColor,
      }}
      w={16}
    >
      <motion.div
        animate={{
          rotate: [0, 20, -15, 0],
          transition: { duration: 1, repeat: Infinity, repeatDelay: 1 },
        }}
      >
        {options.icon}
      </motion.div>
    </Flex>
  );
}

function CallerBoxLayout(props: { name: string; tag: string | null; label: string }) {
  return (
    <VStack>
      <Text color="gray" fontSize="md">
        {props.label}
      </Text>
      <Flex align="center" gap={2}>
        <Text fontSize="lg">{props.name}</Text>
        {props.tag !== null && <Tag>{props.tag}</Tag>}
      </Flex>
    </VStack>
  );
}

function CallerBox(props: { caller: Messages["TelephonyCallParticipantEntityInfo"] }) {
  switch (props.caller.type) {
    case "AgencyMember":
      return (
        <CallerBoxLayout
          label="Agency Member"
          name={`${getFullName(props.caller)} #${props.caller.id}`}
          tag={null}
        />
      );

    case "Patient": {
      return (
        <CallerBoxLayout
          label="Patient"
          name={`${getFullName(props.caller)} #${props.caller.id}`}
          tag={capitalize(props.caller.status)}
        />
      );
    }

    case "PatientContact": {
      const { patient } = props.caller;

      return (
        <CallerBoxLayout
          label={`Patient Contact | ${props.caller.relationship}`}
          name={`${getFullName(props.caller)} (${getFullName(patient)} #${patient.id})`}
          tag={capitalize(patient.status)}
        />
      );
    }

    case "NotIdentified":
      return (
        <CallerBoxLayout
          label={getLabelForNotIdentifiedPhoneNumber(props.caller.kind)}
          name={formatPhoneNumber(props.caller.source)}
          tag={null}
        />
      );

    case "PhonebookContact":
      return (
        <CallerBoxLayout
          label={props.caller.name}
          name={formatPhoneNumber(props.caller.contactType)}
          tag={null}
        />
      );

    // Product-wise caregivers should not be able to make calls, but technically it is possible
    case "Caregiver":
      return <CallerBoxLayout label="Caregiver" name={getFullName(props.caller)} tag={null} />;
  }
}
