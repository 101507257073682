import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import { z } from "zod";
import ErrorPage from "../../../../shared/components/ErrorPage";
import useApi from "../../../../shared/hooks/useApi";
import usePathParams from "../../../../shared/hooks/usePathParams";
import { AgencyMemberId } from "../../../../shared/schema/schema";
import { zPathParamId } from "../../../../shared/utils/zod";
import EditSkillsModal from "./WorkflowAgencyMembersSkills/EditSkillsModal";

const zPathParams = z.object({
  id: zPathParamId(AgencyMemberId).optional(),
});

export default function EditSkillsRoute() {
  const { queries } = useApi();
  const { id } = usePathParams(zPathParams);
  const { stateService } = useRouter();

  const skillsQuery = useQuery({
    ...queries.workflow.skills(),
    staleTime: Infinity,
    select: (data) => data.skills.map((x) => x.id),
  });

  const agencyMembersSkillsQuery = useQuery({
    ...queries.workflow.memberSkills(),
    select: (data) => new Map(data.data),
  });

  const handleClose = () => {
    stateService.go("app.workflow_skills.assign");
  };

  if (id === undefined) {
    return null;
  }

  if (skillsQuery.isPending || agencyMembersSkillsQuery.isPending) {
    return (
      <Modal isOpen={true} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Skills</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center color="gray.500" flex={1} flexDirection="column" gap={8}>
              <Spinner size="xl" />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  if (skillsQuery.isError) {
    return (
      <Modal isOpen={true} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Skills</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ErrorPage
              error={new Error(`${skillsQuery.error}`)}
              resetErrorBoundary={skillsQuery.refetch}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  if (agencyMembersSkillsQuery.isError) {
    return (
      <Modal isOpen={true} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Skills</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ErrorPage
              error={new Error(`${agencyMembersSkillsQuery.error}`)}
              resetErrorBoundary={agencyMembersSkillsQuery.refetch}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  const currentSkills = agencyMembersSkillsQuery.data.get(id) ?? [];

  return (
    <EditSkillsModal
      agencyMemberId={id}
      currentSkills={currentSkills}
      isOpen={true}
      skills={skillsQuery.data}
      onClose={handleClose}
    />
  );
}
