import { CaregiverId } from "../../../shared/schema/schema";
import { useNewTicket } from "../../../shared/hooks/useNewTicket";
import { Button, ButtonProps } from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";

interface Props {
  caregiverId: CaregiverId;
  text?: string;
  buttonProps?: ButtonProps;
}

export default function CaregiverChatButton(props: Props) {
  const { onOpen } = useNewTicket();

  const openNewChat = () => {
    onOpen({
      defaultTicketType: "Chat",
      initialLabelId: null,
      defaultValues: {
        topic: "Caregiver",
        caregiverId: props.caregiverId,
        patientId: null,
      },
    });
  };

  return (
    <Button
      color="blue.500"
      fontSize="12px"
      leftIcon={<ChatIcon />}
      variant="ghost"
      onClick={openNewChat}
      {...props.buttonProps}
    >
      {props.text ?? "New Chat"}
    </Button>
  );
}
