import angular from "angular";
import { caregiverHoursOverride } from "./components/caregiver-hours-override.component";
import { CaregiverHoursOverrideService } from "./caregiver-hours-override.service";

export function registerHoursOverrideModule() {
    return (
      angular
        .module("dashboard")
  
        //  Services
        .service("caregiverHoursOverrideService", CaregiverHoursOverrideService)
  
        // Components
        .component("caregiverHoursOverride", caregiverHoursOverride)
    );
  }
  