import { useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import {
  IntakeTrackId,
  IntakeTrackStepFieldId,
  IntakeTrackStepFieldValueId,
  IntakeTrackStepId,
} from "../../../../../shared/schema/schema";
import { isOptionsQuestion } from "../../../shared/utils/intakeQuestionUtils";
import { IntakeStepForm } from "../flow-settings.types";
import {
  getNewIdForStep,
  getNewIdForStepField,
  getNewIdForStepFieldValue,
} from "../flow-settings.utils";
import usePatientIntakeStepForm from "./usePatientIntakeStepForm";

interface Params {
  allStepsIds: Set<IntakeTrackStepId>;
  allFieldsIds: Set<IntakeTrackStepFieldId>;
  allFieldValuesIds: Set<IntakeTrackStepFieldValueId>;
  form: ReturnType<typeof usePatientIntakeStepForm>;
}

const useCopyStep = (params: Params) => {
  const toast = useToast();
  const [stepToCopy, setStepToCopy] = React.useState<IntakeStepForm | null>(null);
  const disclosure = useDisclosure();

  const handleConfirmCopyStep = (trackId: IntakeTrackId) => {
    if (stepToCopy === null) {
      return;
    }

    disclosure.onClose();
    const newIdForField = getNewIdForStepField(params.allFieldsIds);
    const newIdForFieldValue = getNewIdForStepFieldValue(params.allFieldValuesIds);

    const newState = {
      ...stepToCopy,
      trackId,
      id: getNewIdForStep(params.allStepsIds),
      fields: stepToCopy.fields.map((field, fieldIndex) => ({
        ...field,
        id: IntakeTrackStepFieldId.parse(newIdForField + fieldIndex),
        ...(() =>
          isOptionsQuestion(field)
            ? {
                options: field.options.map((value, optionIndex) => ({
                  ...value,
                  id: IntakeTrackStepFieldValueId.parse(newIdForFieldValue + optionIndex),
                })),
              }
            : {})(),
      })),
    };
    params.form.setState(newState);
    params.form.submit.mutate(undefined, {
      onSuccess: () => {
        toast({
          duration: 7000,
          status: "info",
          position: "top-right",
          title: "Attention",
          description: "Don't forget to update next step and fields options",
        });
      },
    });
  };

  const handleClickCopyStep = (step: IntakeStepForm) => {
    setStepToCopy(step);
    disclosure.onOpen();
  };

  const handleClickCancelStepCopy = () => {
    setStepToCopy(null);
    disclosure.onClose();
  };

  return {
    disclosure,
    onClickCopyStep: handleClickCopyStep,
    onClickConfirmCopyStep: handleConfirmCopyStep,
    onClickCancelStepCopy: handleClickCancelStepCopy,
  };
};

export default useCopyStep;
