import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Messages, ResponseOf } from "../../../core/api";
import {
  CaregiverId,
  CommCenterLabelId,
  PatientId,
  VisitBroadcastId,
} from "../../../shared/schema/schema";
import { NewTicketRequestBody } from "../utils/communication-utils";
import NewEmailTicket from "./Emails/NewEmailTicket";
import NewTicket from "./NewTicket";

export interface NewCommCenterTicketProps {
  initialLabelId: CommCenterLabelId | null;
  labels: Messages["CommCenterLabel"][];
  defaultValues: {
    caregiverId: CaregiverId | null;
    patientId: PatientId | null;
    topic: "Caregiver" | "Patient";
    message?: string;
    label?: Messages["CommCenterLabel"];
    visitBroadcastId?: VisitBroadcastId;
  };
  defaultEmailValues?: {
    recipients?: Messages["CommCenterEmailAddressObject"][];
    assignedTeamName?: string;
  };
  defaultTicketType: "Chat" | "Email";
  teams: Messages["CommCenterTeamWithMembers"][];
  onCreateTicket: (details: NewTicketRequestBody) => void;
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
}

const NewCommCenterTicket = (props: NewCommCenterTicketProps) => {
  return (
    <Tabs
      isFitted
      defaultIndex={props.defaultTicketType === "Chat" ? 0 : 1}
      size="lg"
      variant="line"
    >
      <TabList>
        <Tab>Chat</Tab>
        <Tab>Email</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <NewTicket {...props} />
        </TabPanel>
        <TabPanel>
          <NewEmailTicket
            defaultValues={{
              caregiverId: props.defaultValues.caregiverId,
              patientId: props.defaultValues.patientId,
              topic: props.defaultValues.topic,
              recipients: props.defaultEmailValues?.recipients,
              assignedTeamName: props.defaultEmailValues?.assignedTeamName,
            }}
            teams={props.teams}
            onSuccessCreateEmailTicket={props.onSuccessCreateEmailTicket}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default NewCommCenterTicket;
