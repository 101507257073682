import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";

export function useSearchCaregiversQuery(params: { term: string }) {
  const { api } = useApi();

  return useQuery({
    queryKey: queryKeys.caregiver.search(params.term),
    queryFn: () =>
      api.get("/agencies/:agencyId/search", {
        query: {
          caregiver_id: true,
          caregiver_first_name: true,
          caregiver_last_name: true,
          text: params.term,
        },
      }),
    enabled: params.term !== "",
    select: (data) => data.caregivers.slice(0, 10),
    placeholderData: params.term !== "" ? keepPreviousData : undefined,
  });
}
