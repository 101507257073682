
export const entityNotes = {
    templateUrl: "admin/views/entity-note/entity-notes.html",
    bindings: {
        note: '<',
        patientId: '<?',
        noteValidations: '<',
        isEditor: '<?',
        noteTypes: '=',
        handleChanges: '&',
        shouldBeExpanded: '<'
    },
    //! @ngInject
    controller: function (
        $rootScope,
        $scope,
        entityNoteService,
        noteConsts,
        toaster,
        DatabaseApi,
        generalUtils,
        $timeout
    ) {
        const patientsMap = DatabaseApi.patients();
        const agencyMembers = DatabaseApi.getAgencyMembersArr();
        this.followUpPickerOpen = false;
        this.altInputFormats = ['MM/dd/yyyy'];

        this.datepickerOptions = {
            minDate: new Date(),
            startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
        };

        this.predefinedExtraSettings = {
            styleActive: true,
            singleSelection: false,
            selectionLimit: 1,
            smartButtonMaxItems: 1,
            closeOnSelect: true,
            showCheckAll: false,
            showUncheckAll: false,
            enableSearch: true
        };

        this.agencyMembersSelectionExtraSettings = {
            closeOnBlur: true,
            displayProp: "displayName",
            enableSearch: true,
            scrollable: true,
            scrollableHeight: "400px",
            showCheckAll: true,
            styleActive: true,
            smartButtonMaxItems: 3,
            smartButtonTextConverter: function (itemText) {
                return itemText;
            }
        };

        this.statuses = noteConsts.statuses;

        this.handleChangeFromReact = ({
            notePredefinedAnswerId,
            plainText,
            richText,
            agencyMemberIds,
            commCenterTicketId,
            followUpDate,
            status,
            base64File,
        }) => {
            const agencyMembers = [...new Set([
                ...(agencyMemberIds ?? [])]),
                ...(richText?.mentions.map(x => x.id) ?? [])
            ];

            $scope.$ctrl.handleChanges({
              updatedNote: {
                agencyMembers: agencyMembers,
                followUp: followUpDate,
                newFile: base64File,
                notePredefinedAnswerId: notePredefinedAnswerId,
                noteRichText: richText?.output ?? plainText,
                commCenterTicketId: commCenterTicketId,
                status: status ?? "NONE",
              },
            });
        }

        this.initialize = () => {
            console.log($scope.$ctrl.noteTypes)
            // GET ENTITY NOTE SETTINGS
            const noteSettings = entityNoteService.getAgencyEntityNoteSettingByType($scope.$ctrl.noteTypes[0]);
            if (!noteSettings) {
                return toaster.pop('error', 'No setting for note entity: ' + $scope.$ctrl.noteType[0]);
            }
            this.noteSettings = noteSettings;
            this.updateFilteredAgencyMembers();
            this.initNoteSettings();
        };

        this.predefinedAnswerSelectionEvents = {
            onItemSelect: () => {
                this.onNoteChanges();
            }
        };

        this.agencyMembersSelectionEvents = {
            onSelectionChanged: () => {
                this.onNoteChanges();
            }
        };

        this.onNoteChanges = () => {
            let updatedNote = {};
            const predefinedId = this.noteModel.notePredefinedAnswer.id;
            if (this.showPredefinedField && predefinedId) {
                updatedNote.notePredefinedAnswerId = predefinedId;
            }

            if (this.showMessageField && this.noteModel.noteRichText) {
                updatedNote.noteRichText = this.noteModel.noteRichText;
            }

            if (this.noteModel.agencyMembers.length > 0) {
                updatedNote.agencyMembers = this.noteModel.agencyMembers.map(a => a.id);
            }

            if (this.noteModel.followUp) {
                updatedNote.followUp = this.noteModel.followUp;
            }

            if (this.noteModel.newFile) {
                updatedNote.newFile = this.noteModel.newFile;
            }

            updatedNote.status = this.noteModel.status;
            $scope.$ctrl.handleChanges({updatedNote: updatedNote});
        };

        //callbacks
        $scope.handleMessageChagnge = this.handleMessageChange;

        this.initNoteSettings = () => {
            this.noteModel = {
                notePredefinedAnswer: {},
                agencyMembers: [],
                status: noteConsts.DEFAULT_STATUS,
                noteRichText: undefined,
                followUp: undefined
            };

            if (this.note && this.note.id) {
                this.noteModel.id = this.note.id;
            }

            if (this.noteSettings.hasPredefinedField) {
                this.predefinedAnswers = this.noteSettings.predefinedAnswers.filter(a => a.active === true)
                    .map(a => ({
                        id: a.id,
                        label: a.text
                    })).sort((a, b) => a.label.localeCompare(b.label));;
                this.showPredefinedField = true;

                if (this.note && this.note.notePredefinedAnswerId) {
                    this.noteModel.notePredefinedAnswer = { id: this.note.notePredefinedAnswerId };
                }
            }

            if (this.noteSettings.hasMessageField) {
                this.messagePlaceholder = this.noteSettings.isMessageMandatory ? 'Enter a message...' : 'Optional...';
                this.showMessageField = true;
                if (this.note && this.note.noteRichText) {
                    this.noteModel.noteRichText = this.note.noteRichText;
                    this.isEditor = generalUtils.isHTMLString(this.noteModel.noteRichText);
                }
            }

            if (this.note && this.note.agencyMembers) {
                this.noteModel.agencyMembers = this.note.agencyMembers.map(agencyMmberId =>
                    agencyMembers.find(obj => obj.id === agencyMmberId)
                );
            }

            if (this.note && this.note.followUp) {
                this.noteModel.followUp = new Date(this.note.followUp);
            }

            if (this.note && this.note.status) {
                const status = this.statuses.find(obj => obj.id === this.note.status);
                if (status) {
                    this.noteModel.status = status.id;
                }
            }

            if (this.note && this.note.newFile) {
                this.noteModel.newFile = this.note.newFile;
            }
        };

        this.updateFilteredAgencyMembers = () => {
            if (this.patientId) {
                const patient = patientsMap[this.patientId];
                this.filteredAgencyMembers = agencyMembers.filter(agencyMember => {
                    return agencyMember.officeIds.includes(patient.currentOfficeId);
                }).sort(generalUtils.sortByDisplayName);
            } else {
                this.filteredAgencyMembers = agencyMembers.filter(agencyMember => {
                    return agencyMember.officeIds.findIndex(agencyMemberOfficeId => $rootScope.user.agencyMember.officeIds.includes(agencyMemberOfficeId)) !== -1;
                }).sort(generalUtils.sortByDisplayName);
            }
        };

        this.toggleExpandOptions = () => {
            this.isExpandedNote = !this.isExpandedNote;
            this.isEditor = this.isEditor === true && generalUtils.isHTMLString(this.noteModel.noteRichText) ?
                true : this.isExpandedNote;
        };

        this.$onInit = function () {
            this.initialize();
        };

        this.$onChanges = (changedData) => {
            if (
              changedData.shouldBeExpanded &&
              changedData.shouldBeExpanded.previousValue !==
                changedData.shouldBeExpanded.currentValue
            ) {
              this.isExpandedNote = this.shouldBeExpanded ?? false;
            }

            if (
              changedData.note &&
              changedData.note.previousValue !== changedData.note.currentValue
            ) {
                this.initialize();
            }
        };

        this.handleNewNoteFile = (newFile) => {
            this.noteModel.newFile = newFile;
            this.onNoteChanges();
        };

        this.onReady = function () {
            const self = this;
            if (CKEDITOR.instances["note-editor"]) {
                CKEDITOR.instances["note-editor"].destroy();
            }

            const noteEditor = CKEDITOR.replace("note-editor", {
                extraPlugins: "mentions,emoji,basicstyles,undo,link,toolbar",
                removePlugins: "elementspath, showborders",
                resize_enabled: false,
                language: "en",
                contentsCss: [
                    "/global-vendors/ckeditor4/ckeditor.css",
                    "https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700",
                ],
                font_names: "Open Sans",
                allowedContent: true,
                toolbar: [
                    {
                        name: "document",
                        items: ["Undo", "Redo"],
                    },
                    {
                        name: "basicstyles",
                        items: ["Bold", "Italic", "Strike"],
                    },
                    {
                        name: "links",
                        items: ["EmojiPanel", "Link", "Unlink"],
                    },
                ],
                height: 150
            });

            noteEditor.on("change", function () {
                self.noteModel.noteRichText = this.getData();
                self.onNoteChanges();
            });

            // When finished - add custom class
            $timeout(() => {
                CKEDITOR.instances["note-editor"].element.$.classList.add(
                    "active-note-editor"
                );
            });
        };
    }
};