import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import React from "react";
import { createDisclosureTriggerComponent } from "../../../shared/utils/create-disclosure-trigger-component";
import EntityNotes, { NoteEntity, WithName } from "./EntityNotes";

type Props = {
  entity: WithName<NoteEntity>;
  children: React.ReactElement;
};

const EntityNoteDrawerTrigger = createDisclosureTriggerComponent<Props>({
  displayName: "EntityNoteDrawerTrigger",
  render: ({ disclosure, entity }) => {
    return (
      <Drawer {...disclosure} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={0}>
            <EntityNotes entity={entity} onClose={disclosure.onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  },
});

export default EntityNoteDrawerTrigger;
