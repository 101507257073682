import { createIcon } from "@chakra-ui/react";

const DeleteForeverIcon = createIcon({
  displayName: "DeleteForeverIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m9.4 16.5l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6L16 9.9l-1.4-1.4l-2.6 2.6l-2.6-2.6L8 9.9l2.6 2.6L8 15.1l1.4 1.4ZM7 21q-.825 0-1.413-.588T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.588 1.413T17 21H7Z"
      fill="currentColor"
    />
  ),
});

export default DeleteForeverIcon;
