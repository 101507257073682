import Plivo from "plivo-browser-sdk";

const originalOnError = window.onerror; // https://github.com/plivo/Plivo-Browser-SDK-v2/issues/160
const { client: plivoClient } = new Plivo({
  debug: "INFO",
  permOnClick: true,
  enableTracking: true,
  closeProtection: false,
  usePlivoStunServer: true,
  enableNoiseReduction: true,
});
window.onerror = originalOnError;

export default plivoClient;
