import { LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment";

//! @ngInject
export function caregiverHoursOverrideModalCtrl(DatabaseApi, $rootScope, $scope, $uibModalInstance, toaster) {
    $scope.isEditMode = !!$scope.$resolve.override;
    $scope.caregiversMap = DatabaseApi.caregivers();
    $scope.preSelectedCaregiver = $scope.caregiversMap[$scope.$resolve.caregiverId];
    $scope.offices = $scope.$resolve.allOffices;
    $scope.override = $scope.$resolve.override;
    $scope.startTime = $scope.override ? moment($scope.override.startTime).toDate() : null;
    $scope.endTime = $scope.override ? moment($scope.override.endTime).toDate() : null;
    $scope.altInputFormats = ['MM/dd/yyyy'];
    console.log($scope.override);
    $scope.selectedOffice = $scope.override ?
        $scope.offices.filter((option) =>
            $scope.override.officeId === option.id
        )[0] : {};
    $scope.endDatePickerOptions = {
        minDate: $scope.override?.startTime,
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    $scope.saveOverride = () => {
        if (!$scope.selectedOffice) {
            toaster.error('Please select an office');
            return;
        }
        const override = {
            ...$scope.override,
            officeId: $scope.selectedOffice.id,
            startTime: LocalDate.from(nativeJs(moment($scope.startTime))),
            endTime: LocalDate.from(nativeJs(moment($scope.endTime)))
        }

        $uibModalInstance.close(override);
    }

    $scope.OfficeMultiselectSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '350px',
        enableSearch: true,
        selectionLimit: 1,
        showUncheckAll: false,
        showCheckAll: false
    };

    $scope.cancelModal = function () {
        $uibModalInstance.dismiss('dismiss');
    }

    $scope.updateFromOptions = function () {
        $scope.endDatePickerOptions = { minDate: $scope.override.startTime };
    }

};