import { nanoid } from "nanoid";
import invariant from "tiny-invariant";
import { Messages } from "../../../../core/api";
import {
  IntakeTrackStepFieldId,
  IntakeTrackStepFieldValueId,
  IntakeTrackStepId,
} from "../../../../shared/schema/schema";
import {
  IntakeStepForm,
  IntakeStepFormField,
  IntakeStepFormOptionsOption,
  WithKey,
} from "./flow-settings.types";

export const questionTypes: {
  [key in Messages["IntakeTrackStepField"]["type"]]: string;
} = {
  date: "Date",
  number: "Number",
  options: "Options",
  phonenumber: "Phone Number",
  string: "Text",
  datetime: "Date and Time",
  multiselect: "Multiselect",
  patient_availability: "Patient Availability",
  patient_staffing_preferences: "Patient Staffing Preferences",
  patient_special_requests: "Patient Special Requests",
  address_components: "Address Components",
  double_precision: "Decimal Number",
  ssn: "SSN",
  medicaid_id: "Medciaid Id",
  checkbox: "Checkbox",
};

export const createEmptyStep = (): WithKey<IntakeStepForm> => ({
  __key: nanoid(),
  id: IntakeTrackStepId.parse(0),
  action: null,
  fields: [],
  isStop: false,
  nextStepId: null,
  script: "",
  statusId: null,
  title: "Untitled step",
  trackId: null,
  code: null,
  isMainOnTrackAndStatus: false,
  flowType: "Regular",
  showAssistantButton: false,
  showTitle: true,
  externalFlowId: null,
});

export const createEmptyField = (): WithKey<IntakeStepFormField> => ({
  __key: nanoid(),
  code: null,
  id: null,
  type: "string",
  title: "Untitled field",
  orderInStep: 0,
  isOptional: false,
});

export const createEmptyOption = (): WithKey<IntakeStepFormOptionsOption> => ({
  __key: nanoid(),
  id: null,
  value: "Untitled option",
  nextIntakeTrackStepId: null,
  orderInField: null,
});

export function toIntakeStepForm(step: Messages["IntakeTrackStepForFullFlow"]): IntakeStepForm {
  return {
    action: step.action,
    fields: step.fields.map(toIntakeStepFormField),
    id: step.id,
    isStop: step.isStop,
    nextStepId: step.nextStepId,
    script: step.script,
    statusId: step.status.id,
    title: step.title,
    trackId: step.track.id,
    isMainOnTrackAndStatus: step.isMainInStatusAndTrack,
    code: step.code,
    flowType: step.flowType,
    showAssistantButton: step.showAssistantButton,
    showTitle: step.showTitle,
    externalFlowId: step.externalFlowId,
  };
}

export function toIntakeStepFormField(
  field: Messages["IntakeTrackStepForFullFlow"]["fields"][number]
): WithKey<IntakeStepFormField> {
  switch (field.type) {
    case "phonenumber":
    case "date":
    case "number":
    case "patient_availability":
    case "patient_staffing_preferences":
    case "patient_special_requests":
    case "string":
    case "datetime":
    case "address_components":
    case "double_precision":
    case "medicaid_id":
    case "ssn":
    case "checkbox":
      return {
        __key: nanoid(),
        code: field.code,
        id: field.id,
        orderInStep: field.orderInStep,
        title: field.title,
        type: field.type,
        isOptional: field.isOptional,
      };
    case "multiselect":
    case "options":
      return {
        __key: nanoid(),
        code: field.code,
        id: field.id,
        orderInStep: field.orderInStep,
        options: field.options.map((x) => ({ ...x, __key: nanoid() })),
        title: field.title,
        type: field.type,
        isOptional: field.isOptional,
      };
  }
}

export function toIntakeTrackStepField(
  field: IntakeStepFormField,
  step: Pick<IntakeStepForm, "id">
): Messages["IntakeTrackStepField"] {
  invariant(field.id !== null, "Field id is required");
  invariant(field.orderInStep !== null, "Field orderInStep is required");

  switch (field.type) {
    case "string":
    case "date":
    case "number":
    case "datetime":
    case "phonenumber":
    case "address_components":
    case "double_precision":
    case "medicaid_id":
    case "ssn":
    case "checkbox":
      return {
        code: emptyStringToNull(field.code),
        id: field.id,
        orderInStep: field.orderInStep,
        title: field.title,
        trackStepId: step.id,
        type: field.type,
        isOptional: field.isOptional,
        value: null,
      };
    case "patient_staffing_preferences":
    case "patient_availability":
    case "patient_special_requests":
      return {
        code: emptyStringToNull(field.code),
        id: field.id,
        orderInStep: field.orderInStep,
        title: field.title,
        trackStepId: step.id,
        type: field.type,
        isOptional: field.isOptional,
      };
    case "options":
    case "multiselect":
      return {
        code: field.code,
        id: field.id,
        orderInStep: field.orderInStep,
        isOptional: field.isOptional,
        options: field.options.map((option): Messages["IntakeTrackFieldValue"] => {
          invariant(option.id !== null, "Option id is required");
          invariant(field.id !== null, "Field id is required");
          invariant(option.orderInField !== null, "Option orderInField is required");
          invariant(option.value !== null, "Option value is required");

          return {
            id: option.id,
            fieldId: field.id,
            orderInField: option.orderInField,
            nextIntakeTrackStepId: option.nextIntakeTrackStepId,
            value: option.value,
            icon: option.icon,
          };
        }),
        title: field.title,
        trackStepId: step.id,
        type: field.type,
        value: null,
      };
  }
}

export function emptyStringToNull<T extends string>(value: T | null): T | null {
  return value === "" ? null : value;
}

export function getNewIdForStep(stepIds: Set<IntakeTrackStepId>) {
  return IntakeTrackStepId.parse(Math.max(...stepIds) + 1);
}

export function getNewIdForStepField(stepFieldIds: Set<IntakeTrackStepFieldId>) {
  return IntakeTrackStepFieldId.parse(Math.max(...stepFieldIds) + 1);
}

export function getNewIdForStepFieldValue(stepFieldValueIds: Set<IntakeTrackStepFieldValueId>) {
  return IntakeTrackStepFieldValueId.parse(Math.max(...stepFieldValueIds) + 1);
}
