import { PatientId, PatientSurplusInvoiceId } from "../messages/ids";
import {
  PatientAvailability,
  PatientAvailabilityTimeFrames,
  PatientExtendedData,
  PatientSurplusCheckCreateParams,
  PatientSurplusInvoiceCreateParams,
} from "../messages/patient";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";
import { TemporalAdjusters, DateTimeFormatter, nativeJs, LocalDate } from "@js-joda/core";

//! @ngInject
export class PatientService {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private endpoint: Endpoint,
    private api: Api
  ) {}

  /**
   * Get patient by id
   */
  async getById(patientId: PatientId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/patients/:patientId",
      params: {
        agencyId: this.$rootScope.agencyId,
        patientId: patientId,
      },
    });

    return this.api.get<PatientExtendedData>(url).then(({ data }) => data);
  }

  /**
   * Get PatientAvailabilityTimeframes list
   */
  async getPatientAvailabilityTimeframes() {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/availability_time_frames",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<PatientAvailabilityTimeFrames>(url).then(({ data }) => data);
  }

  /**
   * Get PatientAvailabilityTimeframe by patient
   */
  async getPatientAvailabilitySettings(patientId: PatientId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/availability_settings",
      params: {
        patientId: patientId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        agencyId: this.$rootScope.agencyId,
      },
    });

    return this.api.get<PatientAvailabilityTimeFrames>(url).then(({ data }) => data);
  }
  /**
   * Update PatientAvailabilityTimeframe by patient
   */
  async updatePatientAvailabilitySettings(patientId: PatientId, data: PatientAvailability) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/availability_settings",
      params: {
        patientId: patientId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        agencyId: this.$rootScope.agencyId,
      },
    });

    return this.api.put(url, data).then(({ data }) => data);
  }

  /**
   * Get patient surpluses
   */
  async getPatientSurpluses(patientId: PatientId, surplusDate?: LocalDate) {
    let url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_surpluses",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
      },
    });
    if (surplusDate !== undefined) {
      const queryParams = new URLSearchParams({
        ...surplusDate,
      });
      url = this.endpoint({
        path: `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_surpluses?${queryParams.toString()}`,
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          patientId: patientId,
        },
      });
    }
    return this.api.get(url).then(({ data }) => data);
  }
  async createPatientSurplus(patientId: PatientId, reqData: PatientSurplusCheckCreateParams) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_surpluses",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
      },
    });

    return this.api.post(url, reqData).then(({ data }) => data);
  }
  /**
   * Get patient surplus dashboard
   */
  async getPatientSurplusDashboard(filterBy: { fromDate: Date; toDate: Date | null }) {
    const format = DateTimeFormatter.ofPattern("yyyy-MM-dd");
    const queryParams = new URLSearchParams({
      from: LocalDate.from(nativeJs(filterBy.fromDate))
        .with(TemporalAdjusters.firstDayOfMonth())
        .format(format),
      to: LocalDate.from(nativeJs(filterBy.toDate))
        .with(TemporalAdjusters.lastDayOfMonth())
        .format(format),
    });
    const url = this.endpoint({
      path: `agencies/:agencyId/agency_members/:agencyMemberId/patients_surplus_dashboard?${queryParams.toString()}`,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });
    return this.api.get(url).then(({ data }) => data);
  }
  /**
   * Get patient surplus invoices
   */
  async getPatientSurplusInvoices(patientId: PatientId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_surplus_invoices",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
      },
    });
    
    return this.api.get(url).then(({ data }) => data);
  }
  async createPatientSurplusInvoice(
    patientId: PatientId,
    reqData: PatientSurplusInvoiceCreateParams
  ) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_surplus_invoices",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
      },
    });

    return this.api.post(url, reqData).then(({ data }) => data);
  }
  /**
   * Get patient surplus checks
   */
  async getPatientSurplusChecks(patientId: PatientId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_surplus_checks",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
      },
    });

    return this.api.get(url).then(({ data }) => data);
  }
  async createPatientSurplusCheck(patientId: PatientId, reqData: PatientSurplusCheckCreateParams) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_surplus_checks",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
      },
    });

    return this.api.post(url, reqData).then(({ data }) => data);
  }
  /**
   * Get patient surplus invoice pdf url
   */
  async getPatientSurplusInvoicePdfUrl(patientId: PatientId, invoiceId: PatientSurplusInvoiceId) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/:invoiceId/pdf",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
        patientId: patientId,
        invoiceId: invoiceId,
      },
    });

    return this.api.get(url).then(({ data }) => data);
  }
}
