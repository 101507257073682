import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React from "react";
import useApi from "../../../../../shared/hooks/useApi";
import { useMutation } from "@tanstack/react-query";
import { IntakeTrackNoteId } from "../../../../../shared/schema/schema";
import { IntakeFlowGraphId, parseIntakeFlowGraphId } from "../flow-settings-visualizer.utils";

interface Props {
  disclosure: UseDisclosureReturn;
  nodeId: IntakeFlowGraphId;
  refreshGraphNodes: () => void;
}

export function DeleteNoteAlertDialog(props: Props) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { api } = useApi();
  const deleteNoteMut = useMutation({
    mutationFn: async (params: { noteId: IntakeTrackNoteId }) =>
      api.delete("./intake_flow/notes/:noteId", {
        path: {
          noteId: params.noteId,
        },
      }),
  });

  const handleClickDeleteNote = () => {
    const nodeId = parseIntakeFlowGraphId(props.nodeId);
    if (nodeId.type !== "note") {
      return;
    }
    deleteNoteMut.mutate(
      { noteId: nodeId.id },
      {
        onSuccess: () => {
          props.disclosure.onClose();
          props.refreshGraphNodes();
        },
      }
    );
  };

  return (
    <AlertDialog {...props.disclosure} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Note
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={props.disclosure.onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" ml={3} onClick={handleClickDeleteNote}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
