import {
  Button,
  Center,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormLabel,
  Heading,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Messages, QueryParamsOf } from "../../../core/api";
import Select from "../../../shared/components/Select";
import RangeDatePicker from "../../../shared/components/DatePicker/RangeDatePicker";
import Page from "../../../shared/components/Page";
import { caregiverStatus } from "../../../shared/consts/caregiver-status";
import { createFilters } from "../../../shared/hooks/useFilters";
import { FilterProps } from "../../../shared/utils/filter-props";
import { EditShipmentRowRequest, ImportShipmentsTable } from "./CaregiverSuppliesShipmentsRoute";
import CaregiverSuppliesShipmentTable, {
  SuppliesTableRowEditableData,
} from "../components/CaregiverSuppliesShipmentTable";
import FreeTextSearch from "../../../shared/components/FreeTextSearch";
import UploadRoundedIcon from "../../../shared/icons/UploadRoundedIcon";
import { parseCSVFile } from "../utils/csvUtils";
import ExportOutlinedIcon from "../../../shared/icons/ExportOutlinedIcon";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { DateTimeFormatter, ZoneId } from "@js-joda/core";
import { downloadCsvFileData, jsonToCSV } from "../../../shared/utils/csv-utils";
import React from "react";
import UpdateTableRowForm from "../components/UpdateTableRowForm";
import { CaregiverSuppliesShipmentId } from "../../../shared/schema/schema";
import ErrorPage from "../../../shared/components/ErrorPage";
import { useCaregiversQuery } from "../../caregiver/hooks/useCaregiversQuery";

type CSVColumns =
  | "Caregiiver display ID"
  | "Caregiver Full Name"
  | "Caregiver Status"
  | "Caregiver Hire Date"
  | "Shipment Type"
  | "Shipment Date"
  | "Arrival Date"
  | "Caregiver Confirmed Delivery"
  | "Gloves Size"
  | "Scrubs Size";

type CSVColumnsData = {
  [K in CSVColumns]: string;
};

interface Props extends FilterProps<"./caregiver_supplies_shipments"> {
  shipments: Messages["CaregiverSuppliesShipmentRow"][];
  onImportTable: (newRows: ImportShipmentsTable) => void;
  onEditShipmentRowData: (req: EditShipmentRowRequest) => void;
}

const CaregiverSuppliesShipmentsPage = (props: Props) => {
  const toast = useToast();
  const caregivers = useCaregiversQuery();
  const [currentUpdatedRowDetails, setCurrentUpdatedRowDetails] = React.useState<{
    rowId: CaregiverSuppliesShipmentId;
    editableData: SuppliesTableRowEditableData;
  }>();
  const editRowDisclosure = useDisclosure();

  const {
    createMultiSelectFilter,
    createRangeDatePickerFilter,
    createFreeTextFilter,
    createSwitchFilter,
  } = createFilters<QueryParamsOf<"get", "./caregiver_supplies_shipments">>();

  const handleClickImportTable = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      console.error("no file selected");
    } else {
      const file = event.target.files[0];

      try {
        const csvData = await parseCSVFile(file);

        const newRows: Omit<
          Messages["CaregiverSuppliesShipmentRow"],
          | "id"
          | "caregiverFullName"
          | "caregiverStatus"
          | "caregiverHireDate"
          | "caregiverPhotoUrl"
          | "caregiverDisplayId"
          | "glovesSize"
          | "scrubsSize"
        >[] = csvData.map((data) => {
          const caregiver = caregivers.data?.find(
            (caregiver) => caregiver.email === data.caregiverEmail
          );

          if (caregiver === undefined) {
            throw new Error(
              `Caregiver does not exist. Cannot parse row for email .${data.caregiverEmail}`
            );
          }

          if (data.shipmentType === null) {
            throw new Error(
              `Shipment type is null. Cannot parse row for email .${data.caregiverEmail}`
            );
          }

          return {
            caregiverId: caregiver.id,
            shipmentType: data.shipmentType,
            arrivalDate:
              data.arrivalDate === null
                ? null
                : data.arrivalDate.atZone(ZoneId.of("America/New_York")).toInstant(),
            shipmentDate: data.shipmentDate.atZone(ZoneId.of("America/New_York")).toInstant(),
            caregiverConfirmation: false,
          };
        });

        props.onImportTable({ rows: newRows });
      } catch (error) {
        console.error(error);
        toast({
          title: "Error - Could not import the file",
          description: "could not parse the file for the given format",
          status: "error",
          position: "top-right",
        });
      }
    }

    // To allow importing the same file again
    (document.getElementById("import-shipments-table-input") as HTMLInputElement).value = "";
  };

  const handleClickExportTable = () => {
    const data = props.shipments;

    const rows: CSVColumnsData[] = [];
    for (const row of data) {
      rows.push({
        "Caregiiver display ID": row.caregiverDisplayId?.toString() ?? "",
        "Caregiver Full Name": row.caregiverFullName,
        "Caregiver Status": row.caregiverStatus,
        "Caregiver Hire Date": row.caregiverHireDate
          ? row.caregiverHireDate.format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))
          : "",
        "Shipment Type": row.shipmentType,
        "Shipment Date": row.shipmentDate
          ? dateFormatter.toDateTime(row.shipmentDate, { timezone: "America/New_York" })
          : "",
        "Arrival Date": row.arrivalDate
          ? dateFormatter.toDateTime(row.arrivalDate, { timezone: "America/New_York" })
          : "",
        "Caregiver Confirmed Delivery": row.caregiverConfirmation ? "Yes" : "No" ?? "",
        "Gloves Size": row.glovesSize ?? "",
        "Scrubs Size": row.scrubsSize ?? "",
      });
    }

    downloadCsvFileData("caregiver-supplies-shipment-data.csv", jsonToCSV(rows));
  };

  const shipmentDateFromToFilter = createRangeDatePickerFilter({
    label: "Shipment Date",
    placeholderText: "Shipment Date",
    startDate: {
      name: "shipmentDateFrom",
      value: props.filters.getValueOrNull("shipmentDateFrom"),
    },
    endDate: {
      name: "shipmentDateTo",
      value: props.filters.getValueOrNull("shipmentDateTo"),
    },
    onChange: props.onChangeFilter,
  });

  const arrivalDateFromToFilter = createRangeDatePickerFilter({
    label: "Arrival Date",
    placeholderText: "Arrival Date",
    startDate: {
      name: "arrivalDateFrom",
      value: props.filters.getValueOrNull("arrivalDateFrom"),
    },
    endDate: {
      name: "arrivalDateTo",
      value: props.filters.getValueOrNull("arrivalDateTo"),
    },
    onChange: props.onChangeFilter,
  });

  const caregiverStatusFilter = createMultiSelectFilter({
    name: "caregiverStatus",
    label: "Caregiver Status",
    options: caregiverStatus.dropdown,
    value: props.filters.getValueOrNull("caregiverStatus"),
    onChange: props.onChangeFilter,
  });

  const caregiverFullNameFilter = createFreeTextFilter({
    debounce: 300,
    name: "caregiverName",
    placeholder: "Caregiver Name..",
    value: props.filters.getValueOrNull("caregiverName"),
    onChange: props.onChangeFilter,
  });

  const caregiverConfirmationFilter = createSwitchFilter({
    name: "caregiverConfirmation",
    value: props.filters.getValueOrNull("caregiverConfirmation") ?? false,
    onChange: props.onChangeFilter,
  });

  const handleClickEditRow = (
    rowId: CaregiverSuppliesShipmentId,
    editableData: SuppliesTableRowEditableData
  ) => {
    editRowDisclosure.onOpen();

    setCurrentUpdatedRowDetails({
      rowId,
      editableData,
    });
  };

  const handleUpdateRowData = (req: EditShipmentRowRequest) => {
    editRowDisclosure.onClose();
    props.onEditShipmentRowData(req);
  };

  return (
    <Page>
      <Page.Header>
        <Heading size="lg">Supplies Shipments</Heading>
      </Page.Header>
      <Page.Filters>
        <Flex direction="column" gap={3}>
          <Heading size="sm">Filters</Heading>
          <Flex direction="row" justifyContent="space-between">
            <Flex gap={3} wrap="wrap">
              <FreeTextSearch {...caregiverFullNameFilter} />
              <RangeDatePicker {...shipmentDateFromToFilter} />
              <RangeDatePicker {...arrivalDateFromToFilter} />
              <Select {...caregiverStatusFilter} />
              <Center padding={2}>
                <FormLabel htmlFor={caregiverConfirmationFilter.name}>
                  {" "}
                  Caregiver Confirmed Delivery{" "}
                </FormLabel>
                <Checkbox
                  id={caregiverConfirmationFilter.name}
                  isChecked={caregiverConfirmationFilter.isChecked}
                  onChange={(e) => {
                    caregiverConfirmationFilter.onChange(e.target.checked);
                  }}
                />
              </Center>
            </Flex>
            <Flex direction="row" gap={3}>
              <Button colorScheme="blue" leftIcon={<UploadRoundedIcon boxSize={5} />} padding={5}>
                Import From CSV
                <Input
                  accept=".csv"
                  aria-hidden="true"
                  height="100%"
                  id="import-shipments-table-input"
                  left="0"
                  opacity="0"
                  position="absolute"
                  top="0"
                  type="file"
                  width="100%"
                  onChange={(e) => handleClickImportTable(e)}
                />
              </Button>
              <Button colorScheme="blue" onClick={handleClickExportTable}>
                <Flex alignItems="center" gap={5}>
                  <ExportOutlinedIcon boxSize={4} />
                  Export To CSV
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Page.Filters>

      <Divider />

      <Page.Content>
        <CaregiverSuppliesShipmentTable
          shipments={props.shipments}
          onClickEditRow={handleClickEditRow}
        />
      </Page.Content>

      <Drawer {...editRowDisclosure} size="md">
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Center>
              {currentUpdatedRowDetails === undefined ? (
                <ErrorPage
                  error="Something went wrong while trying to open edit shipment details screen"
                  resetErrorBoundary={() => editRowDisclosure.onClose()}
                ></ErrorPage>
              ) : (
                <UpdateTableRowForm
                  caregiver={{
                    name: currentUpdatedRowDetails.editableData.caregiverName,
                    id: currentUpdatedRowDetails.editableData.caregiverId,
                  }}
                  editableRowData={currentUpdatedRowDetails.editableData}
                  rowId={currentUpdatedRowDetails.rowId}
                  onUpdateRowData={handleUpdateRowData}
                ></UpdateTableRowForm>
              )}
            </Center>
          </DrawerBody>
        </DrawerContent>
        <DrawerOverlay />
      </Drawer>
    </Page>
  );
};

export default CaregiverSuppliesShipmentsPage;
