import { useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { CaregiverId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { useReactBridgeDispatchEvent } from "../../../shared/hooks/useReactBridgeEvent";
import { CustomAddress } from "../../../shared/components/AddressPicker/AddressPickerModal";
import {
  buildAddressComponentsFromCustomAddress,
  buildCustomAddressTextString,
  parseAddress,
} from "../../../shared/components/AddressPicker/address-picker-utils";
import AddressPickerModal from "../../../shared/components/AddressPicker/AddressPickerModal";

interface Props {
  caregiverId: CaregiverId;
  disclosure: ReturnType<typeof useDisclosure>;
}

function transformCustomAddressToCaregiverAddress(customAddress: CustomAddress) {
  return {
    address: buildCustomAddressTextString(customAddress),
    addressComponents: buildAddressComponentsFromCustomAddress(customAddress),
  };
}

const CaregiverAddressPicker = (props: Props) => {
  const { api, queries } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const caregiverAddresQuery = useQuery({
    ...queries.caregiver.get(props.caregiverId),
    select: (caregiver): CustomAddress => ({
      ...parseAddress(caregiver.address, caregiver.addressComponents),
      lat: caregiver.addressComponents?.location.lat ?? caregiver.addressGeoLocation.lat,
      lng: caregiver.addressComponents?.location.lng ?? caregiver.addressGeoLocation.lng,
    }),
  });

  const editCaregiverAddress = useMutation({
    mutationFn: (customAddress: CustomAddress) => {
      const { address, addressComponents } =
        transformCustomAddressToCaregiverAddress(customAddress);
      return api.patch("./caregiver/:caregiverId", {
        path: {
          caregiverId: props.caregiverId,
        },
        body: {
          address: address,
          addressComponents: addressComponents,
          isCustomAddress: true,
        },
      });
    },
    onSuccess: (_, customAddress) => {
      toast({
        title: "Caregiver address updated successfuly",
        status: "success",
        position: "top-right",
      });
      const { address, addressComponents } =
        transformCustomAddressToCaregiverAddress(customAddress);
      sendCaregiverCustomAddressUpdate(address, addressComponents, addressComponents.location);
      queryClient.invalidateQueries(queries.caregiver.get(props.caregiverId));
      props.disclosure.onClose();
    },
    onError: (error) => {
      toast({
        title: "Could not update caregiver address",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  useReactBridgeDispatchEvent({
    eventName: "CUSTOM_ADDRESS:ON_CHANGE",
    onEvent: ({ entity, id }) => {
      if (entity === "Caregiver" && id === props.caregiverId) {
        queryClient.invalidateQueries(queries.caregiver.get(props.caregiverId));
      }
    },
  });

  const sendCaregiverCustomAddressUpdate = (
    address: string,
    addressComponents: Messages["AddressComponents"],
    addressGeoLocation: Messages["Caregiver"]["addressGeoLocation"]
  ) => {
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "customAddressChange",
          payload: {
            entity: "Caregiver",
            id: props.caregiverId,
            address,
            addressComponents,
            addressGeoLocation,
          },
        },
      })
    );
  };

  return (
    <AddressPickerModal
      addressQuery={caregiverAddresQuery}
      disclosure={props.disclosure}
      updateAddressMutation={editCaregiverAddress}
    />
  );
};

export default CaregiverAddressPicker;
