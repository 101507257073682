import { Messages } from "../../core/api";

const PatientClinicalSeverityLevel = [
  "HIGHEST_ASSISTANCE",
  "MINIMAL_ASSISTANCE",
  "WALKING_ASSISTANCE",
] as const;
export type PatientClinicalSeverityLevel = typeof PatientClinicalSeverityLevel[number];

interface PatientPhoneNumber {
  contactName: string;
  contactPhoneNumber: string;
  contactRelationship: string;
  isMain: boolean;
  phoneNumberType: string;
}

export function getPatientPhoneNumbersToCall({
  patientName,
  mainPhoneNumber,
  phoneNumbers,
  contacts,
}: {
  patientName: string;
  mainPhoneNumber: string | null;
  phoneNumbers: Messages["PatientPhoneNumber"][];
  contacts: Messages["PatientContact"][];
}) {
  let retPhoneNumbers: PatientPhoneNumber[] = [];
  if (phoneNumbers.length > 0) {
    retPhoneNumbers.push({
      contactName: patientName,
      contactPhoneNumber: phoneNumbers[0].phonenumber,
      contactRelationship: "Patient",
      isMain: mainPhoneNumber === phoneNumbers[0].phonenumber,
      phoneNumberType: "MOBILE",
    });
  }
  if (contacts !== null) {
    retPhoneNumbers.push(
      ...contacts.flatMap((contact) => {
        const phoneNumbers: PatientPhoneNumber[] = [];
        retPhoneNumbers.push({
          contactName: `${contact.firstName} ${contact.lastName}`,
          contactPhoneNumber: contact.mobilePhoneNumber,
          contactRelationship: contact.relationship,
          isMain: mainPhoneNumber === contact.mobilePhoneNumber,
          phoneNumberType: "MOBILE",
        });

        if (contact.homePhoneNumber !== null && contact.homePhoneNumber !== "") {
          retPhoneNumbers.push({
            contactName: `${contact.firstName} ${contact.lastName}`,
            contactPhoneNumber: contact.homePhoneNumber,
            contactRelationship: contact.relationship,
            isMain: mainPhoneNumber === contact.homePhoneNumber,
            phoneNumberType: "TEL",
          });
        }
        return phoneNumbers;
      })
    );
  }

  retPhoneNumbers = retPhoneNumbers.sort((a, b) => {
    if (a.isMain) {
      return -1;
    }
    if (b.isMain) {
      return 1;
    }
    return 0;
  });
  return retPhoneNumbers;
}
