import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { useDidMount } from "rooks";
import { ResponseOf } from "../../../../core/api";
import LoadingPage from "../../../../shared/components/LoadingPage";
import useApi from "../../../../shared/hooks/useApi";
import useLastRouteState from "../../../../shared/hooks/useLastRouteState";
import { usePageFetchingObserver } from "../../../../shared/hooks/usePageIndication";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../../shared/query-keys";
import { PatientId } from "../../../../shared/schema/schema";
import IntakeDashboardTabView from "./IntakeDashboardTabView";
import useIntakeDashboard from "./hooks/useIntakeDashboard";
import useIntakeDashboardFilters from "./hooks/useIntakeDashboardFilters";

const IntakeDashboardRouteTab = () => {
  const { api } = useApi();
  const queryClient = useQueryClient();

  const orderedCallsQuery = useQuery({
    queryKey: queryKeys.patientIntake.intakeCallsOrder(),
    queryFn: () => api.get("./patient_intake_calls_order", {}),
  });

  useSocketEvent({
    key: "NewIntakePatientCreated",
    onEvent: () => queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.search.K }),
  });

  if (orderedCallsQuery.isPending || orderedCallsQuery.isPending) {
    return <LoadingPage />;
  }

  if (orderedCallsQuery.isError || orderedCallsQuery.isError) {
    return <div>Error</div>;
  }

  return <Inner orderedCalls={orderedCallsQuery.data} />;
};

function Inner(props: { orderedCalls: ResponseOf<"get", "./patient_intake_calls_order"> }) {
  const filters = useIntakeDashboardFilters();
  const { dashboardQueryOptions } = useIntakeDashboard({
    orderedCalls: props.orderedCalls,
    filters: filters,
  });
  const { stateService } = useRouter();

  const lastRouteState = useLastRouteState();
  const [areDefaultParamsResolved, setDefaultParamsResolved] = React.useState(false);

  useDidMount(() => {
    if (!["app.patients.intake", undefined].includes(lastRouteState?.name)) {
      filters.serverSide.setValue.call(null, "freeTextSearch", "");
    }

    setDefaultParamsResolved(true);
  });

  usePageFetchingObserver(dashboardQueryOptions);

  const goToPatientIntakeProfile = (
    event: React.MouseEvent<HTMLTableRowElement>,
    patientId: PatientId
  ) => {
    const isNewTab = event.ctrlKey || event.metaKey;

    isNewTab
      ? window.open(`/app/patients/patient-intake/${patientId}`)
      : stateService.go("app.patients.intake.dashboard.profile", {
          patientId,
        });
  };

  if (!areDefaultParamsResolved) {
    return <LoadingPage />;
  }

  return (
    <IntakeDashboardTabView
      filters={filters}
      orderedCalls={props.orderedCalls}
      onClickDashboardRow={goToPatientIntakeProfile}
    />
  );
}

export default IntakeDashboardRouteTab;
