export const patientCaregiverRelationships = {
  templateUrl: "admin/views/patient-caregiver-relationships.html",
  bindings: {
    patientId: "<",
    caregiverId: "<",
  },
  //! @ngInject
  controller: function ($scope, $rootScope, $uibModal, DatabaseApi, toaster, NgTableParams) {
    const vm = this;
    $scope.isLoading = false;
    $scope.relationships = [];
    $scope.gotTypes = false;

    $scope.caregivers = DatabaseApi.caregivers();
    $scope.patients = DatabaseApi.patients();

    if ($scope.showCaregiver) {
      $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregivers = DatabaseApi.caregivers();
        hydrateRelationships($scope.relationships);
        // Necessary?
        initTable($scope.relationships);
      });
    }
    if ($scope.showPatient) {
      $rootScope.$on("got_patients_data", function (event) {
        $scope.patients = DatabaseApi.patients();
        hydrateRelationships($scope.relationships);
        // Necessary?
        initTable($scope.relationships);
      });
    }

    vm.$onInit = function () {
      $scope.caregiverId = vm.caregiverId;
      $scope.patientId = vm.patientId;
      $scope.showPatient = !$scope.patientId;
      $scope.showCaregiver = !$scope.caregiverId;

      $scope.getPatientCaregiverRelationships();
    };

    vm.$onChanges = function (changes) {};

    $scope.getPatientCaregiverRelationships = async function () {
      if (
        !$rootScope.agencyId ||
        !$rootScope.user ||
        !$rootScope.user.agencyMember ||
        $scope.isLoading
      )
        return;
      $scope.isLoading = true;

      const url =
        "agencies/" +
        $rootScope.agencyId +
        "/agency_members/" +
        $rootScope.agencyMemberId +
        "/patient_caregiver_relationship";

      const queryParamsList = [];

      if ($scope.patientId) {
        queryParamsList.push("patientId=" + $scope.patientId);
      }
      if ($scope.caregiverId) {
        queryParamsList.push("caregiverId=" + $scope.caregiverId);
      }

      const urlWithQueryParams =
        queryParamsList.length > 0 ? url + "?" + queryParamsList.join("&") : url;

      DatabaseApi.get(urlWithQueryParams).then(
        function (res) {
          $scope.isLoading = false;
          $scope.relationships = res.data.relationships;
          hydrateRelationships($scope.relationships);
          initTable($scope.relationships);
        },
        function (err) {
          toaster.pop(
            "error",
            "Something went wrong",
            "Could not get patient-caregiver relationships"
          );
        }
      );
    };

    function hydrateRelationships(relationships) {
      if (!relationships || relationships.length === 0) {
        return;
      }

      relationships.forEach(function (relationship) {
        if (relationship.caregiverId) {
          relationship.caregiver = $scope.caregivers[relationship.caregiverId];
        }
        if (relationship.patientId) {
          relationship.patient = $scope.patients[relationship.patientId];
        }
      });
    }

    function initTable(relationships) {
      const options = {
        count: 10,
        sorting: { id: "desc" },
      };

      $scope.relationshipsTable = new NgTableParams(options, {
        dataset: relationships,
      });
    }

    $scope.handleAddOrEditPatientCaregiverRelationship = function (relationship) {
      DatabaseApi.getPatientCaregiverRelationshipTypes($scope.gotTypes).then((types) => {
        $scope.gotTypes = true;
        const modalInstance = $uibModal.open({
          templateUrl: "admin/views/patient-caregiver-relationship-modal.html",
          size: "md",
          windowClass: "center-center",
          resolve: {
            originalRelationship: relationship ?? null,
            relationshipTypes: {
              list: types,
            },
            selectedCaregiverId: {
              unwrap: $scope.caregiverId,
            },
            selectedPatientId: {
              unwrap: $scope.patientId,
            },
          },
          controller: "patientCaregiverRelationshipModalCtrl",
        });

        modalInstance.result.then(
          async () => {
            $scope.getPatientCaregiverRelationships();
          },
          () => {}
        );
      });
    };

    $scope.setRemoved = function (relationship) {
      const url =
        "agencies/" +
        $rootScope.agencyId +
        "/agency_members/" +
        $rootScope.agencyMemberId +
        "/patient_caregiver_relationship/" +
        relationship.id;

      DatabaseApi.delete(url).then(
        function (res) {
          toaster.pop("success", "Successfully removed patient-caregiver relationship");
          $scope.getPatientCaregiverRelationships();
        },
        function (err) {
          toaster.pop(
            "error",
            "Something went wrong",
            "Failed to remove patient-caregiver relationship"
          );
        }
      );
    };
  },
};
