import withCallCenterAuthorization from "../../../../shared/components/withCallCenterAuthorization";
import useImportantCalls from "../../hooks/useImportantCalls";
import ImportantCallsPopoverRoot from "./ImportantCallsPopoverRoot";
import ImportantCallsPopoverSwitch from "./ImportantCallsPopoverSwitch";

const ImportantCallsPopover = withCallCenterAuthorization(({ state }) => {
  const { ringingCallsQuery, missedCallsQuery } = useImportantCalls();

  if (state.currentCall !== null) {
    return null;
  }

  return (
    <ImportantCallsPopoverRoot
      missedCalls={missedCallsQuery.data?.calls ?? []}
      ringingCalls={ringingCallsQuery.data?.calls ?? []}
      onReset={ringingCallsQuery.refetch}
    >
      <ImportantCallsPopoverSwitch
        missedCallsQuery={missedCallsQuery}
        ringingCallsQuery={ringingCallsQuery}
      />
    </ImportantCallsPopoverRoot>
  );
});

export default ImportantCallsPopover;
