import { Center, Text } from "@chakra-ui/react";

export default function PatientIntakeFlowError(props: { error: unknown }) {
  return (
    <Center>
      <Text color="red" p={5}>
        <>Something went wrong ({String(props.error)})</>
      </Text>
    </Center>
  );
}
