import { SearchIcon } from "@chakra-ui/icons";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import React from "react";
import { patientStatuses } from "../../../../../../public/admin/scripts/messages/patient";
import { Messages, QueryParamsOf, ResponseOf } from "../../../../core/api";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import DebouncedInput from "../../../../shared/components/DebouncedInput";
import Page from "../../../../shared/components/Page";
import Select from "../../../../shared/components/Select";
import { createFilters } from "../../../../shared/hooks/useFilters";
import { PatientId } from "../../../../shared/schema/schema";
import { FilterProps } from "../../../../shared/utils/filter-props";
import IntakePlansDashboardTable, {
  IntakePlanDataRow,
} from "../../components/IntakePlansDashboardTable";

type IntakePlansDashboardQueryParams = QueryParamsOf<"get", "./patient_intake/patient_plans">;

interface Props extends FilterProps<"./patient_intake/patient_plans"> {
  intakePlans: Messages["IntegrationPayer"][];
  intakePatientsPlans: Messages["IntakePatientPlansDashboardDetails"][];
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  isFetching: boolean;
  onClickDashboardRow: (event: React.MouseEvent<HTMLTableRowElement>, patientId: PatientId) => void;
  onClickRefresh: () => void;
}

const IntakePlanReferralsTab = (props: Props) => {
  const tableRef = React.useRef<Table<IntakePlanDataRow>>(null);

  const handleChangeFilter: FilterProps<"./patient_intake/patient_plans">["onChangeFilter"] = (
    name,
    value
  ) => {
    tableRef.current?.setPageIndex(0);
    props.onChangeFilter(name, value);
  };

  const { createMultiSelectFilter, createFreeTextFilter, createRangeDatePickerFilter } =
    createFilters<IntakePlansDashboardQueryParams>();

  const intakePlansFilter = createMultiSelectFilter({
    name: "plan",
    label: "Plan",
    onChange: handleChangeFilter,
    options: props.intakePlans.map((plan) => {
      const label = plan.payerType
        ? `${plan.payerCode} - ${plan.payerName} - ${plan.payerType}`
        : `${plan.payerCode} - ${plan.payerName}`;
      return {
        value: plan.id,
        label,
      };
    }),
    value: props.filters.getValueOrNull("plan"),
  });

  const freeTextSearchFilter = createFreeTextFilter({
    placeholder: "Search by patient name, id, etc.",
    name: "freeTextSearch",
    value: props.filters.getValueOrNull("freeTextSearch"),
    onChange: handleChangeFilter,
    debounce: 250,
  });

  const fromToFilter = createRangeDatePickerFilter({
    label: "Filter By Referral date Range",
    startDate: {
      name: "referralDateFrom",
      value: props.filters.getValueOrNull("referralDateFrom"),
    },
    endDate: {
      name: "referralDateTo",
      value: props.filters.getValueOrNull("referralDateTo"),
    },
    onChange: handleChangeFilter,
  });

  const patientStatusFilter = createMultiSelectFilter({
    name: "patientStatus",
    label: "Patient status",
    options: patientStatuses.map((status) => ({
      label: status,
      value: status,
    })),
    value: props.filters.getValueOrNull("patientStatus"),
    onChange: props.onChangeFilter,
  });

  const intakeStatusFilter = createMultiSelectFilter({
    name: "intakeStatusId",
    label: "Intake Status",
    onChange: handleChangeFilter,
    options: props.intakeStatuses.map((status) => ({
      label: status.status,
      value: status.id,
    })),
    value: props.filters.getValueOrNull("intakeStatusId"),
  });

  const filterNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput {...freeTextSearchFilter} value={freeTextSearchFilter.value ?? ""} />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
      <RangeDatePicker {...fromToFilter} />
      <Select {...intakePlansFilter} width="fit-content" />
      <Select {...intakeStatusFilter} />
      <Select {...patientStatusFilter} />
    </>
  );

  return (
    <>
      <Page.Header>
        <Page.Title>{`Intake Plans (${props.intakePatientsPlans.length})`}</Page.Title>
      </Page.Header>

      <Page.Content p={0}>
        <IntakePlansDashboardTable
          filterNode={filterNode}
          filters={props.filters}
          plans={props.intakePatientsPlans}
          tableRef={tableRef}
          onClickDashboardRow={props.onClickDashboardRow}
        />
      </Page.Content>
    </>
  );
};

export default IntakePlanReferralsTab;
