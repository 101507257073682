import { createMutationGroup } from "../../core/api/mutations";

export default createMutationGroup(({ api, createMutation }) => ({
  lockFreeMsisdForParallelCall: () =>
    createMutation({
      mutationFn: (msisd: string | null) =>
        api.post("./parallel_calls/free_to_call_msisd", {
          body: {
            msisd,
          },
        }),
    }),
}));
