import { Box, Flex, Progress } from "@chakra-ui/react";
import { CommCenterTicketId } from "../../../../../shared/schema/schema";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { VisitAssignmentListedTicket } from "./VisitAssignmentListedTicket";

export function VisitAssignmentListedTickets(props: {
  title: string;
  tickets: CommCenterTicket[];
  selectedTicketId: CommCenterTicketId | null;
  isFetching: boolean;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
}) {
  return (
    <Flex direction="column">
      <Box position="sticky" top={0} width="full" zIndex={1}>
        <Flex direction="column" h={14}>
          <Flex
            align="center"
            bg="white"
            color="black"
            flex={1}
            fontWeight="semibold"
            px={4}
            width="full"
          >
            {props.title} ({props.tickets.length})
          </Flex>
          <Progress bg="gray.200" h="1px" isIndeterminate={props.isFetching} w="full" />
        </Flex>
      </Box>

      <Flex direction="column">
        {props.tickets.map((ticket) => (
          <VisitAssignmentListedTicket
            key={ticket.id}
            isSelected={props.selectedTicketId === ticket.id}
            ticket={ticket}
            onClick={() => props.onClickTicket(ticket.id)}
          />
        ))}
      </Flex>
    </Flex>
  );
}
