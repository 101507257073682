import {
  Button,
  ButtonGroup,
  Flex,
  FlexProps,
  HStack,
  Input,
  Select,
  Tag,
  TagCloseButton,
  Text,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { ResponseOf } from "../../core/api";
import { allLanguages } from "../consts";

type PatientStaffingPreferencesBody = ResponseOf<
  "get",
  "./patients/:patientId/staffing_preferences"
>;

const PatientStaffingPreferences = (props: {
  staffingPreferences: PatientStaffingPreferencesBody;
  onUpdateStaffingPreferences: (staffingPreferences: PatientStaffingPreferencesBody) => void;
}) => {
  const [staffingPreferences, setStaffingPreferences] = useState(props.staffingPreferences);

  const onChangePreference =
    <T extends keyof PatientStaffingPreferencesBody>(key: T) =>
    <V extends PatientStaffingPreferencesBody[T]>(value: V) => {
      setStaffingPreferences((prev) => {
        const newState = cloneDeep(prev);
        newState[key] = value;
        props.onUpdateStaffingPreferences(newState);
        return newState;
      });
    };

  return (
    <Flex direction="row" flexWrap="wrap" gap={2} justifyContent="space-between" w="full">
      <QuestionWrapper>
        <Text>Main language</Text>
        <LanguageQuestion
          label="Select"
          val={staffingPreferences.mainLanguage}
          onChange={onChangePreference("mainLanguage")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Secondary language</Text>
        <LanguageQuestion
          label="Select"
          val={staffingPreferences.secondaryLanguage}
          onChange={onChangePreference("secondaryLanguage")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Has kids in house</Text>
        <YesNoQuestion val={staffingPreferences.kids} onChange={onChangePreference("kids")} />
      </QuestionWrapper>
      <QuestionWrapper hidden={staffingPreferences.kids !== true}>
        <Text>Number of kids</Text>
        <NumberQuestion
          val={staffingPreferences.kidsCount}
          onChange={onChangePreference("kidsCount")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient smoking</Text>
        <YesNoQuestion val={staffingPreferences.smoking} onChange={onChangePreference("smoking")} />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Work with smoking caregiver</Text>
        <YesNoQuestion
          val={staffingPreferences.smokingCaregiver}
          onChange={onChangePreference("smokingCaregiver")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient bedbound</Text>
        <YesNoQuestion
          val={staffingPreferences.bedbound}
          onChange={onChangePreference("bedbound")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient with cane</Text>
        <YesNoQuestion val={staffingPreferences.cane} onChange={onChangePreference("cane")} />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient wheelchair</Text>
        <YesNoQuestion
          val={staffingPreferences.wheelchair}
          onChange={onChangePreference("wheelchair")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient has kosher house</Text>
        <YesNoQuestion val={staffingPreferences.kosher} onChange={onChangePreference("kosher")} />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient Halal observant</Text>
        <YesNoQuestion val={staffingPreferences.halal} onChange={onChangePreference("halal")} />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient Shabbat observant</Text>
        <YesNoQuestion val={staffingPreferences.shabat} onChange={onChangePreference("shabat")} />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Allow multiple caregivers</Text>
        <YesNoQuestion
          val={staffingPreferences.allowMultipleCaregivers}
          onChange={onChangePreference("allowMultipleCaregivers")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient using hoyer lift</Text>
        <YesNoQuestion
          val={staffingPreferences.hoyerLift}
          onChange={onChangePreference("hoyerLift")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Preffered caregiver gender</Text>
        <GenderQuestion val={staffingPreferences.gender} onChange={onChangePreference("gender")} />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text>Is patient has pets</Text>
        <YesNoQuestion val={staffingPreferences.pets} onChange={onChangePreference("pets")} />
      </QuestionWrapper>
      <QuestionWrapper hidden={staffingPreferences.pets !== true}>
        <Text>Pets details</Text>
        <StringArrayQuestion
          val={staffingPreferences.petsDetails}
          onChange={onChangePreference("petsDetails")}
        />
      </QuestionWrapper>
    </Flex>
  );
};

const QuestionWrapper = (props: FlexProps) => (
  <Flex alignItems="center" direction="row" gap={4} {...props} />
);

const YesNoQuestion = (props: {
  val: boolean | null;
  onChange: (value: boolean | null) => void;
}) => {
  const isValueEquals = (check: boolean | null) => props.val === check;
  const variantByValue = (check: boolean | null) => (isValueEquals(check) ? "solid" : "outline");
  return (
    <ButtonGroup isAttached colorScheme="blue">
      <Button variant={variantByValue(true)} onClick={() => props.onChange(true)}>
        Yes
      </Button>
      <Button variant={variantByValue(false)} onClick={() => props.onChange(false)}>
        No
      </Button>
      <Button variant={variantByValue(null)} onClick={() => props.onChange(null)}>
        Unknown
      </Button>
    </ButtonGroup>
  );
};

const GenderQuestion = (props: {
  val: string | null;
  onChange: (value: string | null) => void;
}) => {
  const isValueEquals = (check: string | null) => props.val === check;
  const variantByValue = (check: string | null) => (isValueEquals(check) ? "solid" : "outline");
  return (
    <ButtonGroup isAttached colorScheme="blue">
      <Button variant={variantByValue("Female")} onClick={() => props.onChange("Female")}>
        Female
      </Button>
      <Button variant={variantByValue("Male")} onClick={() => props.onChange("Male")}>
        Male
      </Button>
      <Button variant={variantByValue(null)} onClick={() => props.onChange(null)}>
        Unknown
      </Button>
    </ButtonGroup>
  );
};

const NumberQuestion = (props: {
  val: number | null;
  onChange: (value: number | null) => void;
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) {
      return props.onChange(null);
    }
    return props.onChange(value);
  };
  return (
    <Input
      min={0}
      step={1}
      type="number"
      value={props.val ?? undefined}
      onChange={handleOnChange}
    />
  );
};

const LanguageQuestion = (props: {
  label: string;
  val: string | null;
  onChange: (value: string | null) => void;
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value.trim() === "") {
      return props.onChange(null);
    }
    return props.onChange(e.target.value);
  };
  const options = useMemo(() => allLanguages, []);
  return (
    <Select placeholder={props.label} value={props.val ?? undefined} onChange={handleOnChange}>
      {options.map((lang) => (
        <option key={lang} value={lang}>
          {lang}
        </option>
      ))}
    </Select>
  );
};

const StringArrayQuestion = (props: {
  val: string[] | null;
  onChange: (value: string[] | null) => void;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [newPet, setNewPet] = useState("");
  const handleRemovePet = (index: number) => {
    const newVal = props.val ?? [];
    newVal.splice(index, 1);
    if (newVal.length === 0) {
      return props.onChange(null);
    }
    return props.onChange(newVal);
  };

  const handleAddPet = () => {
    if (newPet.trim() === "") {
      return;
    }
    const newVal = props.val ?? [];
    newVal.push(newPet);
    setNewPet("");
    return props.onChange(newVal);
  };
  return (
    <HStack
      borderColor={isFocused ? "blue.500" : "gray.200"}
      borderRadius="md"
      borderWidth={1}
      p={2}
      spacing={2}
    >
      {props.val?.map((val, index) => (
        <Tag key={val} alignItems="center" px={4} py={2}>
          {val}
          <TagCloseButton onClick={() => handleRemovePet(index)} />
        </Tag>
      ))}
      <Input
        _focusVisible={{ border: "none" }}
        border="none"
        type="text"
        value={newPet}
        w="100px"
        onBlur={() => setIsFocused(false)}
        onChange={(e) => setNewPet(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleAddPet();
          }
        }}
      />
    </HStack>
  );
};

export default PatientStaffingPreferences;
