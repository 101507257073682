import React from "react";
import PayrollVerificationTableNumberValue from "./PayrollVerificationTableNumberValue";
import { PayrollVerificationCellItemValues } from "../payroll.types";
import { Box, Td } from "@chakra-ui/react";

const DEFAULT_INSTANCES_NAME = "instances";

type Props = {
  item: PayrollVerificationCellItemValues;
  showComparison?: boolean;
  instancesName?: string;
  secondsFormatter?: (seconds: number) => React.ReactNode;
  amountFormatter?: (amount: number) => React.ReactNode;
};

function Identity<T>(x: T) {
  return x;
}

const PayrollVerificationTableStandardSectionCell = (props: Props) => {
  const amountFormatter = props.amountFormatter ?? Identity;
  const secondsFormatter = props.secondsFormatter ?? Identity;

  return (
    <Td>
      {props.item.count !== undefined ? (
        <Box>
          <PayrollVerificationTableNumberValue
            currentValue={props.item.count}
            description={props.instancesName ?? DEFAULT_INSTANCES_NAME}
            percentageChange={props.item.countPctChange}
            showComparison={props.showComparison}
          />
        </Box>
      ) : null}
      {props.item.amount !== undefined ? (
        <Box>
          <PayrollVerificationTableNumberValue
            currentValue={props.item.amount}
            formatter={amountFormatter}
            percentageChange={props.item.amountPctChange}
            showComparison={props.showComparison}
          />
        </Box>
      ) : null}
      {props.item.seconds !== undefined ? (
        <Box>
          <PayrollVerificationTableNumberValue
            currentValue={props.item.seconds}
            formatter={secondsFormatter}
            percentageChange={props.item.secondsPctChange}
            showComparison={props.showComparison}
          />
        </Box>
      ) : null}
    </Td>
  );
};

export default PayrollVerificationTableStandardSectionCell;
