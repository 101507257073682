import {
  Card,
  CardBody,
  CardHeader,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
} from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import { validateOneOf } from "../../../../shared/utils";
import TicketEntitySelectInput from "./TicketEntitySelectInput";
import TicketEntitySelectModalVisitsSelect from "./TicketEntitySelectModalVisitsSelect";
import { TicketEntityModalSchema } from "./utils/ticket-entity-select-modal-form-validation";

const PrimaryEntityOptions = ["Patient", "Caregiver", "PhonebookContact"] as const;
const SecondaryEntityOptions = ["Patient", "Caregiver", "PhonebookContact", null] as const;

function TicketEntitySelectModalBody() {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<TicketEntityModalSchema>();
  const primaryEntityType = useController({ name: "primaryEntityType", control });
  const secondaryEntityType = useController({ name: "secondaryEntityType", control });

  const handleChangePrimaryEntityType = (val: string | undefined) => {
    const value = validateOneOf(val, PrimaryEntityOptions);
    if (value === secondaryEntityType.field.value) {
      handleChangeSecondaryEntityType(undefined);
    }
    setValue("primaryEntityType", value);
    trigger("primaryEntityType");
  };

  const handleChangeSecondaryEntityType = (val: string | undefined) => {
    const newValue = val !== undefined ? validateOneOf(val, SecondaryEntityOptions) : null;
    setValue("secondaryEntityType", newValue);
    trigger("secondaryEntityType");
  };

  const hideSecondaryEntityPatientSelect = primaryEntityType.field.value === "Patient";
  const hideSecondaryEntityCaregiverSelect = primaryEntityType.field.value === "Caregiver";
  const hideSecondaryEntityPhonebookContactSelect =
    primaryEntityType.field.value === "PhonebookContact";

  return (
    <Flex direction="column" gap={5}>
      <FormControl isInvalid={errors["primaryEntityType"] !== undefined} isRequired={true}>
        <Card>
          <TicketEntitySelectModalBody.CardHeader>
            <Heading size="md">Primary Entity</Heading>
            <FormErrorMessage>{errors["primaryEntityType"]?.message}</FormErrorMessage>
          </TicketEntitySelectModalBody.CardHeader>
          <CardBody>
            <TicketEntitySelectInput
              entityKind="Primary"
              handleChangeEntityType={handleChangePrimaryEntityType}
            />
          </CardBody>
        </Card>
      </FormControl>
      <FormControl isInvalid={errors["secondaryEntityType"] !== undefined}>
        <Card>
          <TicketEntitySelectModalBody.CardHeader>
            <Heading size="md">Secondary Entity</Heading>
            <FormErrorMessage>{errors["secondaryEntityType"]?.message}</FormErrorMessage>
          </TicketEntitySelectModalBody.CardHeader>
          <CardBody>
            <TicketEntitySelectInput
              entityKind="Secondary"
              handleChangeEntityType={handleChangeSecondaryEntityType}
              hideCaregiverSelect={hideSecondaryEntityCaregiverSelect}
              hidePatientSelect={hideSecondaryEntityPatientSelect}
              hidePhonebookContactSelect={hideSecondaryEntityPhonebookContactSelect}
            />
          </CardBody>
        </Card>
      </FormControl>
      <FormControl isInvalid={errors["visitType"] !== undefined}>
        <Card>
          <TicketEntitySelectModalBody.CardHeader>
            <Heading size="md">Visit</Heading>
            <FormErrorMessage>{errors["visitType"]?.message}</FormErrorMessage>
          </TicketEntitySelectModalBody.CardHeader>
          <CardBody>
            <TicketEntitySelectModalVisitsSelect />
          </CardBody>
        </Card>
      </FormControl>
    </Flex>
  );
}

TicketEntitySelectModalBody.CardHeader = chakra(CardHeader, {
  baseStyle: {
    paddingBottom: 0,
  },
});

export default TicketEntitySelectModalBody;
