import { ExternalLinkIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
} from "@chakra-ui/react";
import { Row, createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { Messages } from "../../../../core/api";
import AgencyMemberSelect from "../../../../shared/components/AgencyMemberSelect";
import AgencyTeamSelect from "../../../../shared/components/AgencyTeamSelect";
import ContractTypeSelect from "../../../../shared/components/ContractTypeSelect";
import DataTable from "../../../../shared/components/DataTable/DataTable";
import useGraphQLDataTable from "../../../../shared/components/DataTable/useGraphQLDataTable";
import DebouncedInput from "../../../../shared/components/DebouncedInput";
import Link from "../../../../shared/components/Link";
import OfficesSelect from "../../../../shared/components/OfficeSelect";
import Page from "../../../../shared/components/Page";
import PatientStatusSelect from "../../../../shared/components/PatientStatusSelect";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import { useEntityLink } from "../../../../shared/hooks/useEntityLink";
import AddIcon from "../../../../shared/icons/AddIcon";
import ExportNotesOutlineIcon from "../../../../shared/icons/ExportNotesOutlineIcon";
import { GetPatientsQuery } from "../../../../shared/schema/gql/graphql";
import { fmap } from "../../../../shared/utils";
import { capitalize, fmapIn, toArrayOrNull } from "../../../../shared/utils/common";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { getEntityStatusColorScheme } from "../../../../shared/utils/get-entity-status-color-scheme";
import { getFullName } from "../../../../shared/utils/get-full-name";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import EntityNoteDrawerTrigger from "../../../note/components/EntityNoteDrawerTrigger";
import { GET_PATIENTS_QUERY } from "../../patient.graphql";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../../../shared/hooks/useApi";
import { formatCustomFieldValue } from "../../../custom-field/custom-field.utils";

export default function PatientsDashboardRoute() {
  const { agencyMember } = useAuthData();
  const { queries } = useApi();

  const customFieldsQuery = useQuery(queries.customField.list({ entity: "PATIENT" }));

  const columns = React.useMemo(() => {
    return createPatientsTableColumn({
      customFields: customFieldsQuery.data?.list.filter((x) => x.active && x.addToSettings) ?? [],
    });
  }, [customFieldsQuery.data?.list]);

  const { table, query, globalFilters, setFilter, metaButtonProps } = useGraphQLDataTable({
    document: GET_PATIENTS_QUERY,
    connection: "patients",
    columns: columns,
    columnVisiblity: {
      storage: { key: "patients/dashboard", version: 2 },
      forceVisibleColumns: React.useMemo(() => ["id"], []),
      initialSelected: [
        "firstName",
        "lastName",
        "displayId",
        "address",
        "currentOffice",
        "activeContracts",
        "@medicaidNumbers",
        "@memberIds",
        "status",
        "createdAt",
        "createdBy",
      ],
    },
    initialSorting: [{ id: "createdAt", desc: true }],
    globalFilters: {
      initialState: {
        offices: { in: agencyMember.officeIds },
        statuses: ["ACTIVE"],
        search: "",
      },
    },
  });

  const entityLink = useEntityLink();

  const handleClickRow = (event: React.MouseEvent<HTMLTableRowElement>, row: Row<Patient>) => {
    entityLink.open({ event, entity: { type: "Patient", id: row.original.id } });
  };

  const filterNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by name, admission, address, etc"
          value={globalFilters.search ?? ""}
          onChange={(x) => setFilter("search", x)}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>

      <PatientStatusSelect
        multiple={true}
        value={toArrayOrNull(globalFilters.statuses as Messages["PatientStatus"][])}
        onChange={(x) => setFilter("statuses", x ?? null)}
      />

      <OfficesSelect
        multiple={true}
        value={globalFilters.offices?.in ?? null}
        onChange={(x) => setFilter("offices", fmapIn(x))}
      />

      <ContractTypeSelect
        multiple={true}
        value={toArrayOrNull(globalFilters.contracts)}
        onChange={(x) => setFilter("contracts", x ?? null)}
      />

      <AgencyTeamSelect
        multiple={true}
        value={globalFilters.teams?.in ?? null}
        onChange={(x) => setFilter("teams", fmapIn(x))}
      />

      <AgencyMemberSelect
        filter={(x) =>
          ["Coordinator", "SeniorCoordinator", "HeadOfCoordination"].includes(x.jobTitle)
        }
        label="Coordinators"
        multiple={true}
        value={globalFilters.assigned?.in ?? null}
        onChange={(x) => setFilter("assigned", fmapIn(x))}
      />
    </>
  );

  const actionNode = (
    <Menu isLazy>
      <MenuButton aria-label="Add patient" as={Button} colorScheme="blue" leftIcon={<AddIcon />}>
        Add patients
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} params={{ view: "create-single" }} to="app.patients.dashboard_legacy">
          Add a single patient
        </MenuItem>
        <MenuItem as={Link} params={{ view: "create-multiple" }} to="app.patients.dashboard_legacy">
          Add multiple patients
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <Page isLoading={query.isPending || query.isFetching}>
      <Page.Header>
        <Page.Title>Patients ({query.data?.patients.totalCount ?? "..."})</Page.Title>
      </Page.Header>

      <Page.Content p={0}>
        <DataTable
          actionNode={actionNode}
          filterNode={filterNode}
          isLoading={query.isPending}
          metaButtonProps={metaButtonProps}
          spacing="tight"
          table={table}
          onClickRow={handleClickRow}
        />
      </Page.Content>
    </Page>
  );
}

type Patient = GetPatientsQuery["patients"]["nodes"][number];

function createPatientsTableColumn(params: { customFields: Messages["CustomField"][] }) {
  const { accessor, display } = createColumnHelper<Patient>();

  const columns = [
    accessor("lastName", {
      header: "Last Name",
      meta: { gqlFilterKey: "lastNameSearch", gqlSortKey: "lastName" },
    }),
    accessor("firstName", {
      header: "First Name",
      meta: { gqlFilterKey: "firstNameSearch", gqlSortKey: "firstName" },
    }),
    accessor("displayId", {
      header: "ID",
      meta: {
        gqlFilterKey: "displayIdSearch",
        gqlSortKey: "displayId",
      },
      cell: (props) => (
        <Button
          as="a"
          color="black"
          href={`${window.location.pathname}?patient=${props.row.original.id}`}
          rightIcon={<ExternalLinkIcon />}
          target="_blank"
          variant="link"
          onClick={(e) => e.stopPropagation()}
        >
          {props.getValue() ?? props.row.original.id}
        </Button>
      ),
    }),
    display({
      id: "_note-action",
      cell: (props) => (
        <EntityNoteDrawerTrigger
          entity={{
            type: "Patient",
            id: props.row.original.id,
            name: getFullName(props.row.original),
          }}
        >
          <Button
            _hover={{ bg: "blue.100" }}
            colorScheme="blue"
            leftIcon={<ExportNotesOutlineIcon h={5} w={5} />}
            variant="ghost"
            onClick={(e) => e.stopPropagation()}
          >
            Notes
          </Button>
        </EntityNoteDrawerTrigger>
      ),
    }),
    accessor("address", {
      header: "Address",
      meta: { gqlFilterKey: "addressSearch", gqlSortKey: "address" },
    }),
    accessor("homePhoneNumber", {
      header: "Home Phone",
      meta: { gqlFilterKey: "homePhoneSearch", gqlSortKey: "homePhoneNumber" },
      cell: (row) => fmap(row.getValue(), phoneFormatter.formatNationalIfValid),
    }),
    accessor("mobilePhoneNumber", {
      header: "Mobile Phone",
      meta: { gqlFilterKey: "mobilePhoneSearch", gqlSortKey: "mobilePhoneNumber" },
      cell: (row) => fmap(row.getValue(), phoneFormatter.formatNationalIfValid),
    }),
    accessor("dateOfBirth", {
      header: "Date of Birth",
      meta: { gqlSortKey: "dateOfBirth" },
      cell: (row) => fmap(row.getValue(), dateFormatter.toDate),
    }),
    accessor((x) => x.currentOffice.name, {
      id: "currentOffice",
      header: "Office",
      meta: { gqlSortKey: "currentOfficeId" },
    }),
    accessor("activeContracts", {
      header: "Contracts",
      meta: { csvFn: (x) => x.nodes.map((x) => x.contractType.name).join(", ") },
      cell: (row) =>
        row
          .getValue()
          .nodes.map((x) => x.contractType.name)
          .join(", "),
    }),
    accessor("activeContracts.nodes", {
      id: "medicaidNumbers",
      header: "Medicaid numbers",
      meta: {
        csvFn: (x) =>
          x
            .map((x) => x.patientContract.medicaidNumber)
            .filter(Boolean)
            .join(", "),
      },
      cell: (row) =>
        row
          .getValue()
          .map((x) => x.patientContract.medicaidNumber)
          .filter(Boolean)
          .join(", "),
    }),
    accessor("activeContracts.nodes", {
      id: "memberIds",
      header: "Member IDs",
      meta: { csvFn: (x) => x.map((x) => x.patientContract.memberId).join(", ") },
      cell: (row) =>
        row
          .getValue()
          .map((x) => x.patientContract.memberId)
          .join(", "),
    }),
    accessor("startOfCare", {
      header: "Start of Care",
      meta: { gqlSortKey: "startOfCare" },
      cell: (row) => fmap(row.getValue(), dateFormatter.toDate),
    }),
    accessor((x) => x.agencyTeam?.name, {
      id: "agencyTeam",
      header: "Team",
      meta: { gqlSortKey: "agencyTeamId" },
    }),
    accessor("assignedCoordinator", {
      header: "Coordinator",
      meta: { gqlSortKey: "assignedCoordinatorId" },
      cell: (row) => fmap(row.getValue(), getFullName),
    }),
    accessor("status", {
      header: "Status",
      meta: { gqlSortKey: "statusHidden" },
      cell: (row) => (
        <Tag colorScheme={getEntityStatusColorScheme(row.getValue())}>
          {capitalize(row.getValue())}
        </Tag>
      ),
    }),
    accessor("effectiveDate", {
      header: "Effective Date",
      meta: { gqlSortKey: "effectiveDate" },
      cell: (row) => fmap(row.getValue(), dateFormatter.toDate),
    }),
    accessor("createdAt", {
      header: "Created at",
      meta: { gqlSortKey: "createdAt" },
      cell: (row) => dateFormatter.toDateOrDateTime(row.getValue()),
    }),
    accessor("createdBy", {
      header: "Created by",
      meta: { gqlSortKey: "createdBy" },
      cell: (row) => getFullName(row.getValue()),
    }),
  ];

  for (const customField of params.customFields) {
    columns.push(
      display({
        id: `customField-${customField.id}`,
        header: customField.fieldName,
        meta: {
          csvFn: (_, x) => fmap(x.customFields[customField.fieldName], formatCustomFieldValue),
        },
        cell: ({ row }) => {
          return fmap(row.original.customFields[customField.fieldName], formatCustomFieldValue);
        },
      })
    );
  }

  return columns;
}
