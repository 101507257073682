import { CheckIcon } from "@chakra-ui/icons";
import { Flex, Skeleton, Text, ThemeTypings } from "@chakra-ui/react";
import { LocalDate } from "@js-joda/core";
import { UseQueryResult } from "@tanstack/react-query";
import { useCurrentStateAndParams } from "@uirouter/react";
import Link from "../../../../../shared/components/Link";
import QueryResolver from "../../../../../shared/components/QueryResolver";
import useApi from "../../../../../shared/hooks/useApi";
import CircleDotIcon from "../../../../../shared/icons/CircleDotIcon";
import { VisitAssignmentStatus } from "../../../../../shared/schema/gql/graphql";
import { PatientId, VisitAssignmentId } from "../../../../../shared/schema/schema";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";
import { AssignmentRecord, formatAssignmentReason } from "../assignments.utils";
import { VisitAssignmentSidebarSection } from "./VisitAssignmentSidebarSection";

export function VisitAssignmentRelatedAssignmentsCard(props: {
  query: UseQueryResult<AssignmentRecord>;
}) {
  return (
    <Flex data-group direction="column">
      <VisitAssignmentSidebarSection
        helpText="Assignments related to the patient within the last 7 days"
        panelProps={{ pt: 0 }}
        title="RELATED ASSIGNMENTS"
      >
        {(() => {
          switch (props.query.status) {
            case "pending":
              return <RelatedAssignmentsSkeleton />;
            case "error":
              return <Flex>Something went wrong</Flex>;
            case "success":
              return (
                <VisitAssignmentRelatedAssignmentsCardInner
                  assignmentId={props.query.data.id}
                  patientId={props.query.data.patient.id}
                />
              );
          }
        })()}
      </VisitAssignmentSidebarSection>
    </Flex>
  );
}

function VisitAssignmentRelatedAssignmentsCardInner(props: {
  assignmentId: VisitAssignmentId;
  patientId: PatientId;
}) {
  const { queries } = useApi();
  const { state } = useCurrentStateAndParams();

  return (
    <Flex direction="column">
      <QueryResolver
        options={queries.visit.assignments.related({
          id: props.assignmentId,
          patientId: props.patientId,
          createdAtFrom: LocalDate.now().minusWeeks(1),
        })}
        onError={(error) => <Flex>{formatErrorResponse(error)}</Flex>}
        onLoading={<RelatedAssignmentsSkeleton />}
        onSuccess={({ data: assignments }) => (
          <>
            {assignments.length === 0 && <Text>No related assignments found</Text>}
            {assignments.map((assignment) => (
              <Link
                key={assignment.id}
                params={{ visitAssignmentId: assignment.id }}
                to={state.name ?? "app.visitAssignments.assignment"}
              >
                <Flex
                  _hover={{ bg: "gray.100" }}
                  as="span"
                  direction="column"
                  gap={1}
                  mx={-5}
                  opacity={assignment.status === VisitAssignmentStatus.Resolved ? 0.5 : 1}
                  px={5}
                  py={3}
                >
                  <Text
                    as="span"
                    color="black"
                    fontWeight="semibold"
                    textDecoration={
                      assignment.status === VisitAssignmentStatus.Resolved ? "line-through" : "none"
                    }
                  >
                    {formatAssignmentReason(assignment.reason)}
                  </Text>
                  <Flex justify="space-between">
                    {(() => {
                      switch (assignment.status) {
                        case VisitAssignmentStatus.Resolved:
                          return (
                            <AssignmentStatus
                              color="green"
                              leftIcon={<CheckIcon h={3} w={3} />}
                              status="Resolved"
                            />
                          );
                        case VisitAssignmentStatus.Unresolved:
                          return (
                            <AssignmentStatus
                              color="gray"
                              leftIcon={<CircleDotIcon h={3} w={3} />}
                              status="Unresolved"
                            />
                          );
                      }
                    })()}
                    <Text as="span" color="gray.600" fontSize="sm">
                      {dateFormatter.toDateTime(assignment.createdAt)}
                    </Text>
                  </Flex>
                </Flex>
              </Link>
            ))}
          </>
        )}
      />
    </Flex>
  );
}

function AssignmentStatus(props: {
  leftIcon: React.ReactNode;
  status: string;
  color: ThemeTypings["colorSchemes"];
}) {
  return (
    <Flex align="center" as="span" color={props.color} gap={1.5}>
      {props.leftIcon}
      <Text as="span">{props.status}</Text>
    </Flex>
  );
}

function RelatedAssignmentsSkeleton() {
  return (
    <Flex direction="column" gap={3} py={2}>
      <Skeleton height={12} rounded="md" />
      <Skeleton height={12} rounded="md" />
    </Flex>
  );
}
