import { createIcon } from "@chakra-ui/react";

const ArrowRightOnRectangle20SolidIcon = createIcon({
  displayName: "ArrowRightOnRectangle20SolidIcon",
  viewBox: "0 0 20 20",
  path: (
    <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
      <path d="M3 4.25A2.25 2.25 0 0 1 5.25 2h5.5A2.25 2.25 0 0 1 13 4.25v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 0-.75-.75h-5.5a.75.75 0 0 0-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-2a.75.75 0 0 1 1.5 0v2A2.25 2.25 0 0 1 10.75 18h-5.5A2.25 2.25 0 0 1 3 15.75z" />
      <path d="M6 10a.75.75 0 0 1 .75-.75h9.546l-1.048-.943a.75.75 0 1 1 1.004-1.114l2.5 2.25a.75.75 0 0 1 0 1.114l-2.5 2.25a.75.75 0 1 1-1.004-1.114l1.048-.943H6.75A.75.75 0 0 1 6 10" />
    </g>
  ),
});

export default ArrowRightOnRectangle20SolidIcon;
