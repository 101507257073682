import { createIcon } from "@chakra-ui/react";

const HeadphonesIcon = createIcon({
  displayName: "HeadphonesIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M9 21H5q-.825 0-1.413-.587Q3 19.825 3 19v-7q0-1.875.712-3.513q.713-1.637 1.926-2.85q1.212-1.212 2.85-1.925Q10.125 3 12 3t3.513.712q1.637.713 2.85 1.925q1.212 1.213 1.925 2.85Q21 10.125 21 12v7q0 .825-.587 1.413Q19.825 21 19 21h-4v-8h4v-1q0-2.925-2.038-4.963Q14.925 5 12 5T7.038 7.037Q5 9.075 5 12v1h4Z"
      fill="currentColor"
    />
  ),
});

export default HeadphonesIcon;
