import { useRouter } from "@uirouter/react";
import { z } from "zod";
import usePathParams from "../../../../shared/hooks/usePathParams";
import { PatientId } from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import IntakePatientProfileRoute from "../../../patientIntake/components/IntakePatientProfileRoute";
import WorkflowTaskViewerPage from "./WorkflowTaskViewerPage";

const zStateParams = z.object({
  intakePatient: z.number().optional(),
});

export default function WorkflowTaskViewerRoute() {
  const { stateService } = useRouter();
  const { intakePatient } = usePathParams(zStateParams);

  const intakePatientId = fmap(intakePatient, PatientId.parse) ?? undefined;

  return (
    <>
      <WorkflowTaskViewerPage />
      <IntakePatientProfileRoute
        patientId={intakePatientId}
        onClose={() => stateService.go("app.workflow_task_viewer")}
      />
    </>
  );
}
