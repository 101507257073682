import { Button, Flex, Textarea, FormLabel, Switch, Badge } from "@chakra-ui/react";
import React from "react";
import { AgencyMemberId } from "../../shared/schema/schema";
import AgencyMemberSelect from "../../shared/components/AgencyMemberSelect";
import SaveIcon from "../../shared/icons/SaveIcon";
import { dateFormatter } from "../../shared/utils/date-formatter";
import { Messages } from "../../core/api";

interface VisitRecordForm {
  note: string | null;
  assignedAgencyMemberId: AgencyMemberId | null;
  attemptedToReach: boolean | null;
  successfullyReached: boolean | null;
  timesheetSent: boolean | null;
  multipleNotes?: boolean;
  multipleAssignedMembers?: boolean;
  multipleAttemptedToReach?: boolean;
  multipleSuccessfullyReached?: boolean;
  multipleTimesheetSent?: boolean;
}

interface Props {
  isLoading: boolean;
  selectedVisits: Messages["CicoIssuesVisit"][];
  canEditAssignee: boolean;
  initialForm?: VisitRecordForm;
  onSaveRecords: (form: VisitRecordForm) => void;
}

const VisitRecordForm = (props: Props) => {
  const [saveRecordsForm, setSaveRecordsForm] = React.useState<VisitRecordForm>({
    note: props.initialForm?.note ?? "",
    assignedAgencyMemberId: props.initialForm?.assignedAgencyMemberId ?? null,
    attemptedToReach: props.initialForm?.attemptedToReach ?? null,
    successfullyReached: props.initialForm?.successfullyReached ?? null,
    timesheetSent: props.initialForm?.timesheetSent ?? null,
  });

  const handleChangeFormDetails = (field: Partial<VisitRecordForm>) => {
    const newDetails = {
      ...saveRecordsForm,
      ...field,
    };

    setSaveRecordsForm(newDetails);
  };

  const handleSaveRecords = () => {
    props.onSaveRecords({
      note: saveRecordsForm.note,
      assignedAgencyMemberId: saveRecordsForm.assignedAgencyMemberId,
      attemptedToReach: saveRecordsForm.attemptedToReach,
      successfullyReached: saveRecordsForm.successfullyReached,
      timesheetSent: saveRecordsForm.timesheetSent,
    });
  };

  return (
    <Flex direction="column" height="100vh" justifyContent="space-around">
      <Flex direction="column" gap={8}>
        <Flex direction="column" gap={2}>
          {props.selectedVisits.length} Visits Selected
          {props.selectedVisits.slice(0, 5).map((visit, index) => (
            <Flex key={visit.visitId} direction="row" gap={2}>
              #{index + 1}: Visit {visit.visitId} at {dateFormatter.toDate(visit.visitDate)}
            </Flex>
          ))}
          {props.selectedVisits.length > 5 && (
            <Flex direction="row" gap={2}>
              + {props.selectedVisits.length - 5} more
            </Flex>
          )}
        </Flex>
        <Flex direction="column">
          <Flex alignItems="center" direction="row" justifyContent="space-between">
            <FormLabel>Assigned member:</FormLabel>
            {props.initialForm?.multipleAssignedMembers === true && (
              <Badge colorScheme="red" h={5}>
                Multiple Assigned Members
              </Badge>
            )}
          </Flex>
          <AgencyMemberSelect
            isDisabled={!props.canEditAssignee}
            multiple={false}
            value={saveRecordsForm.assignedAgencyMemberId}
            onChange={(value) => handleChangeFormDetails({ assignedAgencyMemberId: value ?? null })}
          />
        </Flex>

        <Flex direction="column">
          <Flex alignItems="center" direction="row" justifyContent="space-between">
            <FormLabel>Note:</FormLabel>
            {props.initialForm?.multipleNotes === true && (
              <Badge colorScheme="red" h={5}>
                Multiple Notes
              </Badge>
            )}
          </Flex>
          <Textarea
            rows={8}
            value={saveRecordsForm.note ?? ""}
            onChange={(event) => handleChangeFormDetails({ note: event.target.value })}
          />
        </Flex>
        <Flex direction="row" justifyContent="space-between">
          <Flex direction="column">
            <Switch
              isChecked={saveRecordsForm.attemptedToReach ?? false}
              onChange={(event) =>
                handleChangeFormDetails({
                  attemptedToReach: event.target.checked,
                  successfullyReached:
                    event.target.checked === true ? false : saveRecordsForm.successfullyReached,
                })
              }
            >
              Attempted To Reach
            </Switch>
            {props.initialForm?.multipleAttemptedToReach === true && (
              <Badge alignSelf="center" colorScheme="red" h={5}>
                Multiple Values
              </Badge>
            )}
          </Flex>
          <Flex direction="column">
            <Switch
              isChecked={saveRecordsForm.successfullyReached ?? false}
              onChange={(event) =>
                handleChangeFormDetails({
                  successfullyReached: event.target.checked,
                  attemptedToReach:
                    event.target.checked === true ? false : saveRecordsForm.attemptedToReach,
                })
              }
            >
              Successfully Reached
            </Switch>
            {props.initialForm?.multipleSuccessfullyReached === true && (
              <Badge alignSelf="center" colorScheme="red" h={5}>
                Multiple Values
              </Badge>
            )}
          </Flex>
          <Flex direction="column">
            <Switch
              isChecked={saveRecordsForm.timesheetSent ?? false}
              onChange={(event) => handleChangeFormDetails({ timesheetSent: event.target.checked })}
            >
              Timesheet Sent
            </Switch>
            {props.initialForm?.multipleTimesheetSent === true && (
              <Badge alignSelf="center" colorScheme="red" h={5}>
                Multiple Values
              </Badge>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex align="center" direction="row" gap={4} justifyContent="center">
        <Button
          colorScheme="green"
          isLoading={props.isLoading}
          leftIcon={<SaveIcon />}
          ms="auto"
          size="lg"
          onClick={handleSaveRecords}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default VisitRecordForm;
