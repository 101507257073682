import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  seconds: number;
  isProlonging: boolean;
  onClose: () => void;
  onProlong: () => void;
}

export default function WorkflowTaskViewerTimerModal(props: Props) {
  const handleProlong = () => {
    props.onProlong();
    props.onClose();
  };

  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={props.isOpen}
      motionPreset="slideInBottom"
      onClose={props.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Task is almost due!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            You have <b>{props.seconds}</b> seconds left to complete this task.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" isLoading={props.isProlonging} mr={3} onClick={handleProlong}>
            Prolong Task
          </Button>
          <Button isDisabled={props.isProlonging} variant="ghost" onClick={props.onClose}>
            Dismiss
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
