import { atom, useAtom } from "jotai";
import { Messages } from "../../../core/api";
import { AgencyMemberId, TelephonyCallId } from "../../../shared/schema/schema";
import useInviteToCallMutation from "./useInviteToCallMutation";

type State = { isOpen: false } | { isOpen: true; isCalling: boolean; callId: TelephonyCallId };

const inviteToCallModalStateAtom = atom<State>({ isOpen: false });

export default function useInviteToCallModal() {
  const [state, setState] = useAtom(inviteToCallModalStateAtom);
  const invite = useInviteToCallMutation({
    onInvite: () => {
      close();
    },
  });

  const open = ({ callId }: { callId: TelephonyCallId }) => {
    setState({ isOpen: true, isCalling: false, callId });
  };

  const close = () => {
    setState({ isOpen: false });
  };

  const dial = (
    number: string,
    entityType: Exclude<Messages["TelephonyInviteToCallInvitee"]["type"], "AgencyMember">
  ) => {
    if (!state.isOpen) return;

    setState({ ...state, isCalling: true });

    invite.mutate({
      callId: state.callId,
      invitee: { type: entityType, phonenumber: number },
    });
  };

  const addAgencyMember = (agencyMemberId: AgencyMemberId) => {
    if (!state.isOpen) return;

    setState({ ...state, isCalling: true });

    invite.mutate({
      callId: state.callId,
      invitee: { type: "AgencyMember", id: agencyMemberId },
    });
  };

  return { state, open, close, addAgencyMember, dial };
}
