import { useDisclosure } from "@chakra-ui/react";
import { LocalDate } from "@js-joda/core";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import React from "react";
import { Messages, QueryParamsOf } from "../../../../core/api";
import ErrorPage from "../../../../shared/components/ErrorPage";
import LoadingPage from "../../../../shared/components/LoadingPage";
import useApi from "../../../../shared/hooks/useApi";
import useMultipleSocketEvent from "../../../../shared/hooks/useMultipleSocketEvent";
import { useQueryParams } from "../../../../shared/hooks/useQueryParams";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../../shared/query-keys";
import useCallCenter from "../../../call-center/hooks/useCallCenter";
import useTicketSummaryDisclosure from "../../hooks/useTicketSummaryDisclosure";
import { transformCommCenterTickets } from "../../utils/communication-utils";
import OpenCommunicationsPage from "./OpenCommunicationsPage";
import useAuthData from "../../../../shared/hooks/useAuthInfo";

export function shouldEnableHideVisitAssignmentRelatedTicketsByDefault(
  agencyMember: Messages["AgencyMember"]
) {
  return (
    agencyMember.firstName.toLocaleLowerCase() === "noel" &&
    agencyMember.lastName.toLocaleLowerCase() === "terwilliger"
  );
}

const OpenCommunicationsRoute = () => {
  const { api } = useApi();
  const dialDisclosure = useDisclosure();
  const callCenter = useCallCenter();
  const { agencyMember } = useAuthData();

  React.useEffect(() => {
    if (callCenter.state.status === "Active") {
      dialDisclosure.onClose.call(null);
    }
  }, [callCenter.state.status, dialDisclosure.onClose]);

  const queryParams = useQueryParams<QueryParamsOf<"get", "./comm_center/tickets">>({
    storageKey: ["comm-center", "tickets-table", "query-filters"],
    defaultOptions: {
      lastMessageFrom: LocalDate.now().minusMonths(1),
      lastMessageTo: LocalDate.now(),
      ticketStatus: ["IN PROGRESS", "NEW"],
      hideVisitAssignmentRelatedTickets: shouldEnableHideVisitAssignmentRelatedTicketsByDefault(
        agencyMember
      )
        ? true
        : undefined,
    },
  });

  const tickets = useQuery({
    queryKey: queryKeys.commCenter.search(queryParams.params),
    placeholderData: keepPreviousData,
    queryFn: () => {
      return api.get("./comm_center/tickets", {
        query: queryParams.params.toJSON(),
      });
    },
    select: (response) => transformCommCenterTickets(response.tickets),
  });

  const stats = useQuery({
    queryKey: queryKeys.commCenter.stats(),
    queryFn: () => api.get("./comm_center/tickets/stats", {}),
  });

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => api.get("./comm_center/teams", {}),
  });

  const stages = useQuery({
    queryKey: ["onboarding_stages"],
    queryFn: () => api.get("./onboarding_stages", {}),
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => api.get("./comm_center/labels", {}),
    placeholderData: keepPreviousData,
    select: (response) => response.labels,
  });

  const ticketSources = useQuery({
    queryKey: ["ticket_sources"],
    queryFn: () => api.get("./comm_center/tickets/sources", {}),
    placeholderData: keepPreviousData,
    select: (response) => response.sources,
  });

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: ({ ticketId }) => {
      if (tickets.data?.some((ticket) => ticket.id === ticketId)) {
        tickets.refetch();
      }
    },
  });

  useMultipleSocketEvent({
    keys: [
      "TelephonyCallConnected",
      "TelephonyCallEnded",
      "TelephonyInboundCallRinging",
      "TelephonyOutboundCallStarted",
      "CallCenterCallerHangupBeforeCallStart",
      "TelephonyOutboundCallInitiated",
    ],
    onEvent: () => {
      tickets.refetch();
    },
  });

  const ticketSummaryDisclosure = useTicketSummaryDisclosure();

  if (
    tickets.isLoading ||
    stats.isLoading ||
    teams.isLoading ||
    stages.isLoading ||
    labels.isLoading ||
    ticketSources.isLoading
  ) {
    return <LoadingPage />;
  }

  if (tickets.isError) {
    return <ErrorPage error={tickets.error} resetErrorBoundary={tickets.refetch} />;
  }

  if (stats.isError) {
    return <ErrorPage error={stats.error} resetErrorBoundary={stats.refetch} />;
  }

  if (teams.isError) {
    return <ErrorPage error={teams.error} resetErrorBoundary={teams.refetch} />;
  }

  if (stages.isError) {
    return <ErrorPage error={stages.error} resetErrorBoundary={stages.refetch} />;
  }

  if (labels.isError) {
    return <ErrorPage error={labels.error} resetErrorBoundary={labels.refetch} />;
  }

  if (ticketSources.isError) {
    return <ErrorPage error={ticketSources.error} resetErrorBoundary={ticketSources.refetch} />;
  }

  if (
    !tickets.data ||
    !stats.data ||
    !teams.data ||
    !stages.data ||
    !labels.data ||
    !ticketSources.data
  ) {
    return <div>Something went wrong</div>;
  }

  return (
    <OpenCommunicationsPage
      filters={queryParams}
      initialLabelId={
        labels.data?.find((label) => label.parent === null && !label.active)?.id ?? null
      }
      isRefetching={tickets.isFetching}
      labels={labels.data.filter((label) => label.active)}
      stages={stages.data.stages}
      stats={stats.data}
      teams={teams.data.teams}
      tickets={tickets.data}
      ticketSources={ticketSources.data}
      ticketSummaryDisclosure={ticketSummaryDisclosure}
      onChangeFilter={queryParams.setValue}
      onChangeFilters={queryParams.setValues}
    />
  );
};

export default OpenCommunicationsRoute;
