import { atom, useAtom } from "jotai";
import { TicketPopupOptions } from "../components/CommCenterChatWrapper";

const activeTicketAtom = atom<TicketPopupOptions | null>(null);

export function useCommCenterTicketPopup() {
  const [state, setState] = useAtom(activeTicketAtom);

  const open = (params: TicketPopupOptions) => {
    // this is a hacky workaround. Since the the popup holds its state internally, we need
    // to first destroy it by setting it to null and then re-render it with the new state.
    // the correct approach would be to lift the state up for the call listener to manage it.
    if (state !== null) {
      setState(null);
      setTimeout(() => setState(params), 150);
      return;
    }

    setState(params);
  };

  const close = () => setState(null);

  return { open, close, state };
}
