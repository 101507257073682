import { SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  InputGroup,
  InputRightElement,
  ButtonGroup,
  Switch,
} from "@chakra-ui/react";
import AgencyMemberSelect from "../../shared/components/AgencyMemberSelect";
import ContractTypeSelect from "../../shared/components/ContractTypeSelect";
import RangeDatePicker from "../../shared/components/DatePicker/RangeDatePicker";
import DebouncedInput from "../../shared/components/DebouncedInput";
import OfficesSelect from "../../shared/components/OfficeSelect";
import Toggle from "../../shared/components/Toggle";
import { QueryParamsOf } from "../../core/api";
import { createFilters } from "../../shared/hooks/useFilters";
import { FilterProps } from "../../shared/utils/filter-props";
import Select from "../../shared/components/Select";
interface Props extends FilterProps<"./visits_with_cico_issues"> {
  groupedByPatients: boolean;
  groupedByCaregivers: boolean;
  onClickGroupByPatients: () => void;
  onClickGroupByCaregivers: () => void;
}
export default function CicoIssuesFilters(props: Props) {
  const {
    createMultiSelectFilter,
    createRangeDatePickerFilter,
    createSwitchFilter,
    createSelectFilter,
  } = createFilters<QueryParamsOf<"get", "./visits_with_cico_issues">>();

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date from to",
    startDate: {
      name: "from",
      value: props.filters.getValueOrNull("from"),
    },
    endDate: {
      name: "to",
      value: props.filters.getValueOrNull("to"),
    },
    onChange: props.onChangeFilter,
  });

  const showMissedClockIn = createSwitchFilter({
    name: "showMissedClockIn",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("showMissedClockIn") ?? false,
  });

  const showMissedClockOut = createSwitchFilter({
    name: "showMissedClockOut",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("showMissedClockOut") ?? false,
  });

  const showMissedAllClocks = createSwitchFilter({
    name: "showNoClocks",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("showNoClocks") ?? false,
  });

  const showConfirmedVisits = createSwitchFilter({
    name: "showVisitsWithRecords",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("showVisitsWithRecords") ?? false,
  });

  const showHasNotes = createSwitchFilter({
    name: "hasNotes",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("hasNotes") ?? false,
  });

  const showHasNoNotes = createSwitchFilter({
    name: "hasNoNotes",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("hasNoNotes") ?? false,
  });

  const assignedMember = createSwitchFilter({
    name: "isAssigned",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("isAssigned") ?? false,
  });

  const noAssignedMember = createSwitchFilter({
    name: "isNotAssigned",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("isNotAssigned") ?? false,
  });

  const attemptToReach = createSwitchFilter({
    name: "attemptedToReach",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("attemptedToReach") ?? false,
  });

  const noAttemptToReach = createSwitchFilter({
    name: "notAttemptedToReach",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("notAttemptedToReach") ?? false,
  });

  const successfullyReach = createSwitchFilter({
    name: "successfullyReached",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("successfullyReached") ?? false,
  });

  const noSuccessfullyReach = createSwitchFilter({
    name: "notSuccessfullyReached",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("notSuccessfullyReached") ?? false,
  });

  const timesheetSent = createSwitchFilter({
    name: "timesheetSent",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("timesheetSent") ?? false,
  });

  const noTimesheetSent = createSwitchFilter({
    name: "timesheetNotSent",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("timesheetNotSent") ?? false,
  });

  const visitIssues = createMultiSelectFilter({
    name: "visitIssues",
    label: "Visit Issues",
    options: [
      { label: "No Matching Authorization", value: "no_matching_authorization" },
      { label: "Authorization Over Allocation", value: "authorization_over_allocation" },
      { label: "Missing Authorization Hours", value: "missing_authorization_hours" },
      { label: "Schedule Auth Allocation Conflict", value: "schedule_auth_allocation_conflict" },
      { label: "Invalid Authorization Assignment", value: "invalid_authorization_assignment" },
      { label: "Missing Clock Times", value: "missing_clock_times" },
      { label: "No Caregiver", value: "no_caregiver" },
      { label: "Clockin Time Mismatch", value: "clockin_time_mismatch" },
      { label: "Clockout Time Mismatch", value: "clockout_time_mismatch" },
      { label: "No Billing Rate", value: "no_billing_rate" },
      { label: "No Billing Rate County", value: "no_billing_rate_county" },
      { label: "Same Day Visits With Issues", value: "same_day_visits_with_issues" },
      { label: "Has Patient Overlap Issue", value: "has_patient_overlap_issue" },
      { label: "Has Caregiver Overlap Issue", value: "has_caregiver_overlap_issue" },
      { label: "Missing Dx Codes", value: "missing_dx_codes" },
      { label: "Daily Minimum Hours Not Met", value: "daily_minimum_hours_not_met" },
      { label: "Overbilled Day", value: "overbilled_day" },
      { label: "Missed Visit No Travel Time", value: "missed_visit_no_travel_time" },
      { label: "Missing Matching Mutual Visit", value: "missing_matching_mutual_visit" },
      { label: "Zero Billing Units", value: "zero_billing_units" },
      { label: "Unapproved Manual Clock Time Edit", value: "unapproved_manual_clock_time_edit" },
      { label: "Caregiver Incompliant", value: "caregiver_incompliant" },
      { label: "Insufficient Visit Duties", value: "insufficient_visit_duties" },
      { label: "Missing Personal Care Duty", value: "missing_personal_care_duty" },
      { label: "Excessive Clock Distance", value: "excessive_clock_distance" },
      { label: "Manual Hold Invoicing", value: "manual_hold_invoicing" },
      { label: "Zero Dollar Pay Rate", value: "zero_dollar_pay_rate" },
      { label: "Missing Caregiver SSN", value: "missing_caregiver_ssn" },
      { label: "Missing Patient Member ID", value: "missing_patient_member_id" },
      { label: "Missing Address", value: "missing_address" },
    ],
    value: props.filters.getValueOrNull("visitIssues"),
    onChange: props.onChangeFilter,
  });

  const timelyFilingDaysLeft = createSelectFilter({
    name: "lessThanTimelyFilingDaysLeft",
    label: "Timely Filing Days Left",
    options: [
      { label: "7", value: 7 },
      { label: "14", value: 14 },
      { label: "30", value: 30 },
      { label: "60", value: 60 },
    ],
    value: props.filters.getValueOrNull("lessThanTimelyFilingDaysLeft"),
    onChange: props.onChangeFilter,
    disabled: false,
  });

  const noNegativeTimelyFiling = createSwitchFilter({
    name: "noNegativeTimelyFilingDaysLeft",
    onChange: props.onChangeFilter,
    value: props.filters.getValueOrNull("noNegativeTimelyFilingDaysLeft") ?? false,
  });
  return (
    <Flex direction="column" gap={3}>
      <Heading size="sm">Filters</Heading>
      <Flex gap={4} wrap="wrap">
        <InputGroup width="md">
          <DebouncedInput
            debounce={200}
            placeholder="Search by caregiver / patient name"
            value={props.filters.getValue("freeText") ?? ""}
            onChange={(x) => props.filters.setValue("freeText", x)}
          />
          <InputRightElement>
            <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
          </InputRightElement>
        </InputGroup>
        <RangeDatePicker {...fromToFilter} />
        <AgencyMemberSelect
          multiple={true}
          value={props.filters.getValueOrNull("assignedAgencyMemberIds")}
          onChange={(value) => props.onChangeFilter("assignedAgencyMemberIds", value)}
        />
        <OfficesSelect
          multiple={true}
          value={props.filters.getValueOrNull("officeIds")}
          onChange={(x) => props.filters.setValue("officeIds", x)}
        />
        <ContractTypeSelect
          multiple={true}
          value={props.filters.getValueOrNull("contractIds")}
          onChange={(x) => props.filters.setValue("contractIds", x)}
        />
        <Select {...visitIssues} />
        <Select {...timelyFilingDaysLeft} />
        <ButtonGroup isAttached>
          <Toggle
            borderColor="gray.400"
            value={showMissedClockIn.isChecked}
            onChange={showMissedClockIn.onChange}
          >
            Missed Clock In
          </Toggle>
          <Toggle
            borderColor="gray.400"
            value={showMissedClockOut.isChecked}
            onChange={showMissedClockOut.onChange}
          >
            Missed Clock Out
          </Toggle>
          <Toggle
            borderColor="gray.400"
            value={showMissedAllClocks.isChecked}
            onChange={showMissedAllClocks.onChange}
          >
            Missed Both Clocks
          </Toggle>
          <Toggle
            borderColor="gray.400"
            value={showConfirmedVisits.isChecked}
            onChange={showConfirmedVisits.onChange}
          >
            Confirmed
          </Toggle>
        </ButtonGroup>
        <ButtonGroup isAttached>
          <Toggle
            borderColor="gray.400"
            isDisabled={showHasNoNotes.isChecked}
            value={showHasNotes.isChecked}
            onChange={showHasNotes.onChange}
          >
            Has Notes
          </Toggle>
          <Toggle
            borderColor="gray.400"
            isDisabled={showHasNotes.isChecked}
            value={showHasNoNotes.isChecked}
            onChange={showHasNoNotes.onChange}
          >
            Has No Notes
          </Toggle>
        </ButtonGroup>
        <ButtonGroup isAttached>
          <Toggle
            borderColor="gray.400"
            isDisabled={noAssignedMember.isChecked}
            value={assignedMember.isChecked}
            onChange={assignedMember.onChange}
          >
            Member Assigned
          </Toggle>
          <Toggle
            borderColor="gray.400"
            isDisabled={assignedMember.isChecked}
            value={noAssignedMember.isChecked}
            onChange={noAssignedMember.onChange}
          >
            Member Not Assigned
          </Toggle>
        </ButtonGroup>
        <ButtonGroup isAttached>
          <Toggle
            borderColor="gray.400"
            isDisabled={noAttemptToReach.isChecked}
            value={attemptToReach.isChecked}
            onChange={attemptToReach.onChange}
          >
            Attempted To Reach
          </Toggle>
          <Toggle
            borderColor="gray.400"
            isDisabled={attemptToReach.isChecked}
            value={noAttemptToReach.isChecked}
            onChange={noAttemptToReach.onChange}
          >
            Not Attempted To Reach
          </Toggle>
        </ButtonGroup>
        <ButtonGroup isAttached>
          <Toggle
            borderColor="gray.400"
            isDisabled={noSuccessfullyReach.isChecked}
            value={successfullyReach.isChecked}
            onChange={successfullyReach.onChange}
          >
            Successfully Reached
          </Toggle>
          <Toggle
            borderColor="gray.400"
            isDisabled={successfullyReach.isChecked}
            value={noSuccessfullyReach.isChecked}
            onChange={noSuccessfullyReach.onChange}
          >
            Not Successfully Reached
          </Toggle>
        </ButtonGroup>
        <ButtonGroup isAttached>
          <Toggle
            borderColor="gray.400"
            isDisabled={noTimesheetSent.isChecked}
            value={timesheetSent.isChecked}
            onChange={timesheetSent.onChange}
          >
            Timesheet Sent
          </Toggle>
          <Toggle
            borderColor="gray.400"
            isDisabled={timesheetSent.isChecked}
            value={noTimesheetSent.isChecked}
            onChange={noTimesheetSent.onChange}
          >
            Timesheet Not Sent
          </Toggle>
        </ButtonGroup>
        <Toggle
          borderColor="gray.400"
          value={noNegativeTimelyFiling.isChecked}
          onChange={noNegativeTimelyFiling.onChange}
        >
          No Negative Timely Days
        </Toggle>
      </Flex>
      <Flex direction="row" gap={4}>
        <Switch
          isChecked={props.groupedByPatients}
          isDisabled={props.groupedByCaregivers}
          onChange={props.onClickGroupByPatients}
        >
          Group By Patients
        </Switch>
        <Switch
          isChecked={props.groupedByCaregivers}
          isDisabled={props.groupedByPatients}
          onChange={props.onClickGroupByCaregivers}
        >
          Group By Caregivers
        </Switch>
      </Flex>
    </Flex>
  );
}
