import { InputRightElement, Tag } from "@chakra-ui/react";

export default function IntakeLandlineRightAddon() {
  return (
    <InputRightElement p={2} width="fit-content">
      <Tag backgroundColor="blue.600" color="white">
        Landline
      </Tag>
    </InputRightElement>
  );
}
