import { Flex, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../../../../../shared/hooks/useApi";
import ImageOutlineRoundedIcon from "../../../../../../shared/icons/ImageOutlineRoundedIcon";
import OutlineInsertDriveFileIcon from "../../../../../../shared/icons/OutlineInsertDriveFileIcon";
import { queryKeys } from "../../../../../../shared/query-keys";
import { WorkflowTaskInstanceId } from "../../../../../../shared/schema/schema";
import { CardButton } from "./CardButton";
import { LoadingEntityCard } from "./LoadingEntityCard";

export function FilesLinkCard(props: { files: string[]; taskInstanceId: WorkflowTaskInstanceId }) {
  const { api } = useApi();

  const query = useQuery({
    queryKey: queryKeys.workflowTask.getSignedFiles(props.taskInstanceId),
    select: (data) => new Map(data.files),
    queryFn: () =>
      api.get("./workflow_tasks/:workflowTaskInstanceId/signed_files", {
        path: {
          workflowTaskInstanceId: props.taskInstanceId,
        },
      }),
  });

  if (query.isPending) {
    return <LoadingEntityCard />;
  }

  if (query.isError) {
    return null;
  }

  const handleClick = (url: string) => window.open(url, "_blank");

  return (
    <>
      {props.files.flatMap((file) => {
        const s3Key = file.split("/").slice(3).join("/");
        const url = query.data.get(s3Key);

        if (url === undefined) {
          return [];
        }

        const extension = file.split(".").pop()?.toLowerCase();
        const isImage = ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(extension ?? "");

        return [
          <CardButton key={file} onClick={() => handleClick(url)}>
            <Flex alignItems="center" gap="2">
              {isImage ? (
                <ImageOutlineRoundedIcon height="8" width="8" />
              ) : (
                <OutlineInsertDriveFileIcon height="8" width="8" />
              )}
              <Text fontSize="xl" fontWeight="medium">
                {extension?.toUpperCase()}
              </Text>
            </Flex>
          </CardButton>,
        ];
      })}
    </>
  );
}
