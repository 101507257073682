import { Messages } from "../../../core/api";
import { Entity, EntityWithStatus } from "../../../shared/components/EntityCard";
import { ViewFax } from "../components/ViewFaxButton";

export const getRelatedEntityFromFax = (
  fax: Messages["DashboardFax"]
): EntityWithStatus<Entity> => {
  switch (true) {
    case fax.relatedEntityType === "Caregiver" && fax.relatedCaregiver !== null:
      return {
        type: "Caregiver",
        displayId: fax.relatedCaregiver.displayId,
        fullName: fax.relatedCaregiver.name,
        id: fax.relatedCaregiver.id,
        status: fax.relatedCaregiver.status,
        photoUrl: fax.relatedCaregiver.photoUrl,
      };
    case fax.relatedEntityType === "Patient" && fax.relatedPatient !== null:
      return {
        type: "Patient",
        displayId: fax.relatedPatient.displayId,
        fullName: fax.relatedPatient.name,
        id: fax.relatedPatient.id,
        status: fax.relatedPatient.status,
        gender: fax.relatedPatient.gender,
        contactDetails: null,
      };
    default:
      return {
        type: "FaxNotAssignedEntity",
        status: "DRAFT",
      };
  }
};

export const getViewFaxButton = (fax: Messages["DashboardFax"]): ViewFax => {
  return {
    fax: fax,
    onSelectView: () => {
      window.dispatchEvent(
        new CustomEvent("from-webapp-react", {
          detail: { type: "navigate", payload: { id: fax.id, entity: "Document" } },
        })
      );
    },
  };
};
