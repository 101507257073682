import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from "@chakra-ui/react";
import { LocalDate } from "@js-joda/core";
import { keepPreviousData, useQuery, UseQueryResult } from "@tanstack/react-query";
import React from "react";
import { ResponseOf } from "../../../core/api";
import RangeDatePicker from "../../../shared/components/DatePicker/RangeDatePicker";
import Select from "../../../shared/components/Select";
import useApi from "../../../shared/hooks/useApi";
import { createFilters } from "../../../shared/hooks/useFilters";
import SortIcon from "../../../shared/icons/SortIcon";
import { queryKeys } from "../../../shared/query-keys";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { NY_ZONE_ID } from "../../../shared/utils/date-utils";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { CommCenterTicket } from "../communication.types";
import TicketItem from "./TicketItem";

interface Props {
  label: React.ReactNode;
  tickets: CommCenterTicket[];
  onClickTicket: (ticketId: CommCenterTicketId) => void;
  activeTicketId?: CommCenterTicketId;
}

const TicketsGroup = (props: Props) => {
  const { filteredTickets, filters, areFiltersSet, sourcesQuery, resetFilters } =
    useTicketsGroupFilter({
      tickets: props.tickets,
    });

  return (
    <Box data-group borderTop="1px solid var(--chakra-colors-gray-100)">
      <Flex
        _focusWithin={{ zIndex: 1 }}
        alignItems="center"
        bg="white"
        justifyContent="space-between"
        p={4}
        position="sticky"
        top={0}
      >
        <Heading size="sm">{props.label}</Heading>
        <TicketsGroupFilter
          areFiltersSet={areFiltersSet}
          filters={filters}
          query={sourcesQuery}
          onReset={resetFilters}
        />
      </Flex>

      {filteredTickets.map((ticket) => (
        <TicketItem
          key={ticket.id}
          isActive={props.activeTicketId === ticket.id}
          ticket={ticket}
          onClick={props.onClickTicket}
        />
      ))}
    </Box>
  );
};

type FilterState = {
  lastActivityFrom: LocalDate | undefined;
  lastActivityTo: LocalDate | undefined;
  sources: string[] | undefined;
};

const initialFilterState: FilterState = {
  lastActivityFrom: undefined,
  lastActivityTo: undefined,
  sources: undefined,
};

function useTicketsGroupFilter(params: { tickets: CommCenterTicket[] }) {
  const { api } = useApi();
  const [filters, setFilters] = React.useState<FilterState>(initialFilterState);

  const sourcesQuery = useQuery({
    queryKey: queryKeys.commCenter.sources(),
    queryFn: () => api.get("./comm_center/tickets/sources", {}),
    placeholderData: keepPreviousData,
    select: (response) => response.sources,
  });

  const { createRangeDatePickerFilter, createMultiSelectFilter } = createFilters<FilterState>();

  const lastActivityFilter = createRangeDatePickerFilter({
    label: "Last activity",
    startDate: {
      name: "lastActivityFrom",
      value: filters?.lastActivityFrom ?? null,
    },
    endDate: {
      name: "lastActivityTo",
      value: filters?.lastActivityTo ?? null,
    },
    onChange: (key, value) => setFilters((prev) => ({ ...prev, [key]: value })),
  });

  const sourcesFilter = createMultiSelectFilter({
    label: "Source",
    name: "sources",
    onChange: (key, value) => setFilters((prev) => ({ ...prev, [key]: value })),
    options:
      sourcesQuery.data?.map(({ label, source }) => ({
        label: label,
        value: source,
      })) ?? [],
    value: filters?.sources ?? null,
  });

  const filteredTickets = React.useMemo(() => {
    const lastActivityFromUTC = filters.lastActivityFrom
      ?.atStartOfDay()
      .atZone(NY_ZONE_ID)
      .toInstant();

    const lastActivityToUTC = filters.lastActivityTo
      ?.atStartOfDay()
      .plusDays(1)
      .minusNanos(1)
      .atZone(NY_ZONE_ID)
      .toInstant();

    return params.tickets.filter((ticket) => {
      const isLastActivityFromValid =
        fmap(lastActivityFromUTC, (x) => ticket.lastActivity.isAfter(x)) ?? true;

      const isLastActivityToValid =
        fmap(lastActivityToUTC, (x) => ticket.lastActivity.isBefore(x)) ?? true;

      const isSourceValid = fmap(filters.sources, (x) => x.includes(ticket.source)) ?? true;

      return isLastActivityFromValid && isLastActivityToValid && isSourceValid;
    });
  }, [filters.lastActivityFrom, filters.lastActivityTo, filters.sources, params.tickets]);

  const areFiltersSet = React.useMemo(() => {
    return (
      filters.lastActivityFrom !== undefined ||
      filters.lastActivityTo !== undefined ||
      filters.sources !== undefined
    );
  }, [filters.lastActivityFrom, filters.lastActivityTo, filters.sources]);

  return {
    sourcesQuery,
    filteredTickets,
    areFiltersSet,
    filters: {
      lastActivity: lastActivityFilter,
      sources: sourcesFilter,
    },
    resetFilters: () => setFilters(initialFilterState),
  };
}

function TicketsGroupFilter(props: {
  query: UseQueryResult<ResponseOf<"get", "./comm_center/tickets/sources">["sources"]>;
  filters: ReturnType<typeof useTicketsGroupFilter>["filters"];
  areFiltersSet: boolean;
  onReset: () => void;
}) {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button
          _groupHover={{ opacity: 1 }}
          colorScheme={props.areFiltersSet ? "blue" : undefined}
          leftIcon={<SortIcon />}
          opacity={props.areFiltersSet ? 1 : 0}
          size="sm"
          variant={props.areFiltersSet ? "outline" : "ghost"}
        >
          Filters
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          {(() => {
            switch (props.query.status) {
              case "pending":
                return (
                  <Center p={4}>
                    <Spinner />
                  </Center>
                );
              case "error":
                return <Center p={4}>{formatErrorResponse(props.query.error)}</Center>;
              case "success":
                return (
                  <Flex direction="column" gap={4}>
                    <FormControl>
                      <FormLabel>Last activity (select range)</FormLabel>
                      <RangeDatePicker {...props.filters.lastActivity} monthsShown={1} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Source</FormLabel>
                      <Select {...props.filters.sources} />
                    </FormControl>

                    <Flex justify="flex-end">
                      <Button variant="ghost" onClick={props.onReset}>
                        Reset
                      </Button>
                    </Flex>
                  </Flex>
                );
            }
          })()}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default TicketsGroup;
