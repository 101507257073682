import React, { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { CustomAddress } from "./AddressPickerModal";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Center, Spinner } from "@chakra-ui/react";
import MapMarkerIcon from "../../icons/MapMarkerIcon";
import { GOOGLE_MAPS_API_KEY } from "../../../shared/consts/index";

const defaultMapCenter = new google.maps.LatLng({ lat: 40.71, lng: -74 });

const AddressPickerMap = () => {
  const mapRef = React.useRef<google.maps.Map | null>(null);
  const { control, setValue } = useFormContext<CustomAddress>();
  const lat = useController({ name: "lat", control });
  const lng = useController({ name: "lng", control });

  const [mapCenter, setMapCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral>(
    lat.field.value !== 0 && lng.field.value !== 0
      ? new google.maps.LatLng({ lat: lat.field.value, lng: lng.field.value })
      : defaultMapCenter
  );

  React.useEffect(() => {
    if (lat.field.value !== 0 && lng.field.value !== 0) {
      setMapCenter({ lat: lat.field.value, lng: lng.field.value });
    }
  }, [lat.field.value, lng.field.value]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    id: "__react-googleMapsScriptId-address-picker-map",
  });

  const handleMapDragEnd = () => {
    if (mapRef.current !== undefined && mapRef.current !== null) {
      const newCenter = mapRef.current.getCenter();
      if (newCenter !== undefined) {
        setValue("lat", newCenter.lat());
        setValue("lng", newCenter.lng());
        setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
      }
    }
  };

  const handleLoad = async (map: google.maps.Map) => {
    mapRef.current = map;
  };

  const handleOnUnmount = () => {
    mapRef.current = null;
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return !isLoaded ? (
    <Center mb={20} mt={20}>
      <Spinner size="xl" />
    </Center>
  ) : (
    <GoogleMap
      center={mapCenter}
      id="react-address-picker-map"
      mapContainerStyle={{ width: "100%", height: 300 }}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      }}
      zoom={12}
      onDragEnd={handleMapDragEnd}
      onLoad={handleLoad}
      onUnmount={handleOnUnmount}
    >
      <MapMarkerIcon
        color="#4384f3"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -91%)", // The center of the map should be the needle of the pin
        }}
      />
      <Marker position={mapCenter} />
    </GoogleMap>
  );
};

export default AddressPickerMap;
