import { ChevronRightIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionPanelProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";

export function VisitAssignmentSidebarSection(props: {
  title: string;
  helpText?: string;
  children: React.ReactNode;
  panelProps?: AccordionPanelProps;
}) {
  return (
    <Accordion allowMultiple={true} defaultIndex={[0]}>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton color="gray.500" fontSize="sm" fontWeight="semibold" px={5} py={4}>
              <ChevronRightIcon
                fontSize="lg"
                transform={isExpanded ? "rotate(90deg)" : ""}
                transition="transform 200ms ease"
              />
              <Text as="span" flex={1} pl={2} textAlign="start">
                {props.title}
              </Text>
              {props.helpText && (
                <Tooltip label="Assignments related to the patient within the last 7 days">
                  <InfoOutlineIcon
                    _groupHover={{ opacity: 1 }}
                    color="gray.500"
                    opacity={0}
                    transition="ease"
                    transitionDuration="normal"
                  />
                </Tooltip>
              )}
            </AccordionButton>
            <AccordionPanel pb={5} pt={3} px={5} {...props.panelProps}>
              {props.children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}
