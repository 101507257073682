import { useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";
import React, { useEffect } from "react";
import invariant from "tiny-invariant";
import { Messages } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../../shared/query-keys";
import { IntakeTrackStepId, PatientId } from "../../../../../shared/schema/schema";
import { assertNever } from "../../../../../utils";
import { PatientIntakeFlowStepForm } from "../../../patient-intake.types";
import { Duration } from "@js-joda/core";
import { isMedicaidValid } from "../../../../../shared/consts";

function fieldToFieldWithAnswer(
  field: Messages["IntakeTrackStepField"]
): Messages["IntakeTrackStepFieldWithNewAnswer"] | null {
  switch (field.type) {
    case "date":
    case "datetime":
    case "number":
    case "options":
    case "phonenumber":
    case "string":
    case "multiselect":
    case "address_components":
    case "double_precision":
    case "medicaid_id":
    case "ssn":
    case "checkbox":
      return field.value;
    case "patient_availability":
    case "patient_staffing_preferences":
    case "patient_special_requests":
      return null;
    default:
      assertNever(field);
  }
}

function getFieldsErrorsForSubmission(
  stepFields: Messages["IntakeTrackStepField"][],
  stepAnswers: PatientIntakeFlowStepForm
): string[] {
  const errors = new Set<string>();
  const noAnswerFields: Messages["IntakeTrackStepField"]["type"][] = [
    "patient_availability",
    "patient_staffing_preferences",
    "patient_special_requests",
  ];

  const answerIds = Object.values(stepAnswers).map((x) => x.id);

  for (const field of stepFields) {
    if (field.type === "medicaid_id" && !field.isOptional) {
      const answer = stepAnswers[field.id];
      if (!answer || answer.type !== "medicaid_id" || !isMedicaidValid(answer.value)) {
        errors.add("Medicaid Id format is wrong");
      }
    }
    if (field.isOptional || noAnswerFields.includes(field.type) || answerIds.includes(field.id)) {
      continue;
    }
    errors.add("Please fill out all required fields before continuing");
  }

  return [...errors];
}

export default function usePatientIntakeFlow(params: { patientId: PatientId }) {
  const intakeProfileDisclosure = useDisclosure();
  const [$activeStepId, setActiveStepId] = React.useState<IntakeTrackStepId | null>(null);
  const [activeStepForm, setActiveStepForm] = React.useState<PatientIntakeFlowStepForm>({});
  const { api, queries } = useApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [didFinishFlow, setDidFinishFlow] = React.useState<boolean>(false);

  const patientQuery = useQuery({
    queryKey: queryKeys.patientIntake.flowPatient(params.patientId),
    queryFn: () => {
      return api.get("./patients/:patientId/intake", { path: { patientId: params.patientId } });
    },
  });

  const externalFlowsQuery = useQuery({
    queryKey: queryKeys.patientIntake.externalFlows(),
    queryFn: () => api.get("./intake_steps/external_flows", {}),
  });

  const stepsQuery = useQuery({
    queryKey: queryKeys.patientIntake.flowSteps({
      id: params.patientId,
    }),
    queryFn: () => {
      return api.get("./patients/:patientId/intake_steps", {
        path: {
          patientId: params.patientId,
        },
        query: {
          externalFlowId: undefined,
        },
      });
    },
    select: (response) => response.steps,
    staleTime: Infinity,
  });

  const activeStepId = $activeStepId ?? stepsQuery.data?.find((x) => x.isActive)?.id ?? null;

  const stepQuery = useQuery({
    enabled: activeStepId !== null,
    queryKey: queryKeys.patientIntake.flowStep({
      patientId: params.patientId,
      stepId: activeStepId!,
    }),
    staleTime: Duration.ofMinutes(1).toMillis(),
    queryFn: () => {
      invariant(activeStepId !== null, "activeStepId must be set");

      return api.get("./patients/:patientId/intake_steps/:intakeTrackStepId", {
        path: {
          intakeTrackStepId: activeStepId,
          patientId: params.patientId,
        },
      });
    },
    select: (response) => response.step,
  });

  useEffect(() => {
    if (stepQuery.data !== undefined) {
      const form: PatientIntakeFlowStepForm = {};
      for (const field of stepQuery.data.fields) {
        const value = fieldToFieldWithAnswer(field);
        if (field.type === "checkbox" && value === null) {
          form[field.id] = {
            type: "checkbox",
            id: field.id,
            value: false,
          };
        }
        if (value !== null) {
          form[field.id] = value;
        }
      }
      setActiveStepForm(form);
    }
  }, [activeStepId, stepQuery.data]);

  const submitStepMutation = useMutation({
    mutationFn: () => {
      invariant(activeStepId, "activeStepId must be set");

      return api.post("./patients/:patientId/intake_flow_step/:intakeTrackStepId", {
        path: {
          intakeTrackStepId: activeStepId,
          patientId: params.patientId,
        },
        body: {
          answers: Object.values(activeStepForm),
        },
      });
    },
    onSuccess: ({ steps, activeStep: step }) => {
      queryClient.setQueryData(
        queryKeys.patientIntake.flowSteps({
          id: params.patientId,
        }),
        () => ({
          steps,
        })
      );

      // This makes sure that if any update occures to the patient intake it will be refetched
      queryClient.removeQueries(queries.intake.profile(params.patientId));
      // This makes sure that if any update occures to the flow patient intake it will be refetched
      queryClient.removeQueries({
        queryKey: queryKeys.patientIntake.flowPatient(params.patientId),
      });
      // This makes sure that if we go back to a step that has already been completed we have the answers from the server
      queryClient.removeQueries({ queryKey: queryKeys.patientIntake.flowStep.K });
      // This is a fix for the application delay that sometimes happens that make the active step will show as future
      // so we set the query data manually
      queryClient.setQueryData(
        queryKeys.patientIntake.flowStep({ patientId: params.patientId, stepId: step.id }),
        () => ({ step })
      );
      const nextActiveStepId = steps.find((x) => x.isActive);

      if (nextActiveStepId !== undefined) {
        setActiveStepId(nextActiveStepId.id);
        setActiveStepForm({});
      }
    },
    onError: (error) => {
      toast({
        title: "Error submitting answers",
        status: "error",
        description: formatErrorResponse(error),
        position: "top-right",
      });
    },
  });

  const handleStepSubmit = () => {
    invariant(stepQuery.data !== undefined, "stepQuery.data must be set");
    const fieldsErrors = getFieldsErrorsForSubmission(stepQuery.data.fields, activeStepForm);
    if (fieldsErrors.length === 0) {
      return submitStepMutation.mutate();
    }
    for (const error of fieldsErrors) {
      toast({
        title: "Error",
        description: error,
        status: "error",
        position: "top-right",
      });
    }
    return;
  };

  const setField = (field: Messages["IntakeTrackStepFieldWithNewAnswer"]) => {
    setActiveStepForm((prev) => ({ ...prev, [field.id]: field }));
  };

  return {
    intakeProfileDisclosure: intakeProfileDisclosure,
    activeStepId: activeStepId,
    isStepSubmitting: submitStepMutation.isPending,
    activeStepForm: activeStepForm,
    patientQuery: patientQuery,
    stepsQuery: stepsQuery,
    stepQuery: stepQuery,
    externalFlowsQuery: externalFlowsQuery,
    didFinishFlow: didFinishFlow,
    onSubmitStep: handleStepSubmit,
    setActiveStepId: setActiveStepId,
    setField: setField,
    setDidFinishFlow: setDidFinishFlow,
  };
}
