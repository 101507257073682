import { createIcon } from "@chakra-ui/react";

const PhoneMissedIcon = createIcon({
  displayName: "PhoneMissedIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m3.4 20.4l-2.3-2.25q-.3-.3-.3-.7t.3-.7q2.2-2.375 5.075-3.562T12 12t5.813 1.188T22.9 16.75q.3.3.3.7t-.3.7l-2.3 2.25q-.275.275-.638.3t-.662-.2l-2.9-2.2q-.2-.15-.3-.35t-.1-.45v-2.85q-.95-.3-1.95-.475T12 14t-2.05.175T8 14.65v2.85q0 .25-.1.45t-.3.35l-2.9 2.2q-.3.225-.663.2t-.637-.3m8.55-9.05L7 6.4V9H5V3h6v2H8.4l3.525 3.525l5.65-5.65L19 4.3z"
      fill="currentColor"
    />
  ),
});

export default PhoneMissedIcon;
