import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Messages } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import EntityNoteFormModal from "../../../../note/components/EntityNoteFormModal";
import { EntityNoteFormData, mapToAgencyNoteCreateParams } from "../../../../note/utils/note-form";

export function CaregiverExclusionRowTerminateButton(props: {
  row: Messages["CaregiverExlusionDataNew"];
}) {
  const queryClient = useQueryClient();
  const disclosure = useDisclosure();
  const { api, queries } = useApi();

  const terminateReasonQuery = useQuery({
    ...queries.note.settings(),
    select: (x) =>
      x.agencyEntityNotes
        .find((x) => x.noteType === "caregiver_exclusion")
        ?.predefinedAnswers.find((x) => x.text === "Terminate")?.id,
  });

  const teminate = useMutation({
    mutationFn: (note: EntityNoteFormData) => {
      return api.put(
        "/agencies/:agencyId/exclusion_list/:exclusionId/caregivers/:caregiverId/terminate",
        {
          body: {
            note: mapToAgencyNoteCreateParams({
              noteType: "caregiver_exclusion",
              caregiverId: props.row.id,
              ...note,
            }),
          },
          path: { caregiverId: props.row.id, exclusionId: props.row.exclusionId },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queries.caregiver.exclusions.list({}));
      disclosure.onClose();
    },
  });

  return (
    <>
      <Button colorScheme="red" variant="outline" onClick={disclosure.onOpen}>
        Terminate
      </Button>

      <EntityNoteFormModal
        confirmButtonProps={{ children: "Terminate" }}
        disclosure={disclosure}
        hardcodedAnswerId={terminateReasonQuery.data}
        isLoading={teminate.isPending}
        noteType="caregiver_exclusion"
        titleProps={{ children: `Terminate ${props.row.fullName}?` }}
        onSuccess={teminate.mutate}
      >
        <Alert mb={4} rounded="md" status="warning">
          <AlertIcon />
          <AlertTitle>Warning</AlertTitle>
          <AlertDescription>
            This action will have system wide affect on the caregiver.
          </AlertDescription>
        </Alert>
      </EntityNoteFormModal>
    </>
  );
}
