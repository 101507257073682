import { ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { z } from "zod";
import { BodyOf, Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import useControlledForm from "../../../shared/hooks/useControlledForm";
import { NoteCommentId, NoteId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import NoteComment from "./NoteComment";

export const zCommentForm = z.object({
  text: z.string().nonempty(),
});

export default function EntityNoteComments(props: {
  note: Messages["EntityNote"];
  comments: Messages["NoteComment"][];
  onSuccessComment: () => void;
  onRemoveComment: (comment: { id: NoteCommentId; noteId: NoteId }) => void;
}) {
  const [isCommentsOpen, setCommentsOpen] = React.useState(false);
  const { api } = useApi();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: (body: BodyOf<"post", "./notes/:noteId/comment">) => {
      return api.post("./notes/:noteId/comment", { body, path: { noteId: props.note.id } });
    },
    onSuccess: () => {
      props.onSuccessComment();
      form.reset();

      toast({
        title: "Note created",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error creating note",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const form = useControlledForm({
    schema: zCommentForm,
    onSuccess: mutation.mutate,
  });

  if (!isCommentsOpen) {
    return (
      <Box>
        <Button
          colorScheme={props.comments.length > 0 ? "blue" : undefined}
          variant="link"
          onClick={() => setCommentsOpen(true)}
        >
          Comments ({props.comments.length})
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Button rightIcon={<ChevronUpIcon />} variant="link" onClick={() => setCommentsOpen(false)}>
        Hide comments
      </Button>

      <Flex direction="column" gap={4} my={4}>
        {props.comments.map((comment) => (
          <NoteComment key={comment.id} comment={comment} onRemoveComment={props.onRemoveComment} />
        ))}
        <form onSubmit={form.onSubmit}>
          <Flex align="flex-end" direction="column" gap={2} mt={2}>
            <FormControl isInvalid={form.isInvalid("text")}>
              <Textarea
                placeholder="Add a comment"
                value={form.state.text ?? ""}
                onChange={(e) => form.setValue("text", e.target.value)}
              />
              <FormErrorMessage>{form.getError("text")}</FormErrorMessage>
            </FormControl>
            <Button colorScheme="blue" isLoading={mutation.isPending} type="submit">
              Post comment
            </Button>
          </Flex>
        </form>
      </Flex>
    </Box>
  );
}
