import React from "react";
import { PermissionControlledField, PermissionControlledValue } from "../schema/gql/graphql";

export default function ProtectedField<T>(props: {
  field: PermissionControlledField<T>;
  fallback?: React.ReactNode;
  children: (value: PermissionControlledValue.Value<T>["value"]) => React.ReactNode;
}) {
  if (props.field.value.type === "PermissionDenied") {
    return <>{props.fallback}</> ?? <>Unauthorized</>;
  }

  return <>{props.children(props.field.value.value)}</>;
}
