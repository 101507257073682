import { useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import { BodyOf, Messages, ResponseOf } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../../shared/query-keys";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";
import { IntakeDataRow } from "../../../components/IntakeDashboardTable";
import { IntakeDashboardFilters } from "./useIntakeDashboardFilters";
import { AgencyMemberId } from "../../../../../shared/schema/schema";
import React from "react";
import assert from "../../../../../shared/utils/assert";
import { Instant, ZoneId } from "@js-joda/core";
import { NEW_YORK_TZ } from "../../../../../shared/utils/date-utils";

export const INTAKE_QUEUE_NAMES = {
  Q1High: "Q1 - High",
  Q1Low: "Q1 - Low",
  Q1Initial: "Q1 - Initial",
  Q2Regular: "Q2 - Regular",
  Q3CallToPlan: "Q3 - Call To Plan",
} as const;

const QUEUES_ORDER = [
  INTAKE_QUEUE_NAMES.Q1High,
  INTAKE_QUEUE_NAMES.Q1Initial,
  INTAKE_QUEUE_NAMES.Q1Low,
  INTAKE_QUEUE_NAMES.Q2Regular,
  INTAKE_QUEUE_NAMES.Q3CallToPlan,
];

export default function useIntakeDashboard(params: {
  orderedCalls: ResponseOf<"get", "./patient_intake_calls_order">;
  filters: IntakeDashboardFilters;
}) {
  const { api, queries } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { stateService } = useRouter();
  const createIntakePatientDisclosure = useDisclosure();
  const dashboardQueryOptions = queries.intake.dashboard(params.filters.serverSide.params.toJSON());

  const { mutate: handleCreateNewIntakePatient, reset } = useMutation({
    mutationKey: ["create-new-intake-patient"],
    mutationFn: (body: BodyOf<"post", "./patient_intake">) =>
      api.post("./patient_intake", { body }),
    onSuccess: (intakePatient) => {
      toast({
        title: "Intake Patient Created Successfully",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      createIntakePatientDisclosure.onClose();
      queryClient.invalidateQueries(dashboardQueryOptions);
      queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.intakeCallsOrder() });

      stateService.go("app.patients.intake.dashboard.profile", {
        patientId: intakePatient.patient.id,
      });
      reset();
    },
    onError: (error) => {
      toast({
        title: "Could not create new patient",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const initiatedPreselect =
    params.filters.serverSide.getValue("mainIntakeStatusId") !== undefined &&
    params.filters.serverSide.getValue("intakeStatusId") !== undefined;

  const dashboardQuery = useQuery({
    ...dashboardQueryOptions,
    enabled: initiatedPreselect,
    select: (data) => ({
      numberOfIncompletePatients: data.numberOfIncompletePatients,
    }),
  });

  const agencyMemberOrderedCalls = React.useMemo(() => {
    const now = Instant.now().atZone(ZoneId.of(NEW_YORK_TZ)).toLocalDate();
    const map = new Map<AgencyMemberId, Messages["IntakeCallOrder"][]>();
    for (const key of QUEUES_ORDER) {
      for (const call of params.orderedCalls[key]) {
        if (call.assignedAgencyMemberId === null) {
          continue;
        }
        const agencyMemberOrders = map.get(call.assignedAgencyMemberId) ?? [];
        agencyMemberOrders.push(call);
        map.set(call.assignedAgencyMemberId, agencyMemberOrders);
      }
    }
    for (const [, calls] of map) {
      calls.sort((a, b) => {
        const aDate = a.callDate?.toLocalDate() ?? a.manualLocalDateTime?.toLocalDate();
        const bDate = b.callDate?.toLocalDate() ?? b.manualLocalDateTime?.toLocalDate();
        assert(aDate !== undefined && bDate !== undefined, "Call dates must be defined");
        if (aDate.equals(now) && bDate.equals(aDate)) {
          return 0;
        }
        if (aDate.equals(now)) {
          return -1;
        }
        if (bDate.equals(now)) {
          return 1;
        }
        return 0;
      });
    }
    return map;
  }, [params.orderedCalls]);

  const tableQuery = useQuery({
    ...dashboardQueryOptions,
    select: (data) =>
      data.patients.map((p) => toDataRow(p, params.orderedCalls, agencyMemberOrderedCalls)),
  });

  const handleRefresh = () => {
    queryClient.invalidateQueries(dashboardQueryOptions);
    queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.intakeCallsOrder() });
  };

  return {
    dashboardQuery: dashboardQuery,
    tableQuery: tableQuery,
    createIntakePatient: handleCreateNewIntakePatient,
    createIntakePatientDisclosure: createIntakePatientDisclosure,
    dashboardQueryOptions: dashboardQueryOptions,
    refresh: handleRefresh,
  };
}

function toDataRow(
  row: Messages["IntakePatientDashboardDetails"],
  orderedCalls: ResponseOf<"get", "./patient_intake_calls_order">,
  agencyMemberOrderedCalls: Map<AgencyMemberId, Messages["IntakeCallOrder"][]>
): IntakeDataRow {
  const dataRow: IntakeDataRow = {
    ...row,
    entity: {
      displayId: row.displayId,
      fullName: row.firstName + " " + row.lastName,
      gender: row.gender,
      id: row.id,
      status: row.patientStatus,
      type: "Patient",
      contactDetails: null,
      selfServe: row.intakeFlowType === "Self Serve",
      wasSelfServe: row.wasSelfServe,
    },
    currentQueue: row.currentCallQueue,
    assigneeOrder: "No Order",
  };
  for (const key of Object.keys(orderedCalls)) {
    const calls = orderedCalls[key];
    const call = calls.find((call) => call.patientId === row.id);
    if (call !== undefined) {
      dataRow[`Team-${key}`] = call;
      if (call.assignedAgencyMemberId !== null) {
        const agencyMemberCallsOrderIndex = agencyMemberOrderedCalls
          .get(call.assignedAgencyMemberId)
          ?.findIndex((callOrder) => callOrder.id === call.id);
        if (agencyMemberCallsOrderIndex !== undefined) {
          dataRow.assigneeOrder = `${agencyMemberCallsOrderIndex + 1}`;
        }
      }
      break;
    }
  }
  return dataRow;
}
