export const GOOGLE_MAPS_API_KEY = "AIzaSyAcpv_xCANE3_P2EEqNmZL4LESMR_iAIik";
export const allLanguages = [
  "Albanian",
  "Arabic",
  "Armenian",
  "Bengali",
  "Bulgarian",
  "Cantonese",
  "Chinese",
  "Creole",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Farsi",
  "Filipino",
  "French",
  "Fukkianese",
  "Fula/Fulani",
  "Fuzhounese",
  "Georgian",
  "German",
  "Ghana",
  "Greek",
  "Hakka",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Italian",
  "Japanese",
  "Korean",
  "Krio",
  "Kyrgyz",
  "Mandarin",
  "Pashto",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Romanian",
  "Russian",
  "Shanghainese",
  "Sign",
  "Soninke",
  "Spanish",
  "Swahili",
  "Tagalog",
  "Taishanese",
  "Taiwanese",
  "Turkish",
  "Twi",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Yiddish",
];

export function isMedicaidValid(value: string) {
  return /[A-Z]{2}[0-9]{5}[A-Z]{1}/g.test(value);
}
