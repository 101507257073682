import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import useApi from "../../../../../shared/hooks/useApi";
import MagicButtonIcon from "../../../../../shared/icons/MagicButtonIcon";
import { VisitAssignmentId } from "../../../../../shared/schema/schema";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";

export function VisitAssignmentSummaryButton(props: { id: VisitAssignmentId }) {
  const { api } = useApi();

  const generate = useMutation({
    mutationFn: () => {
      return api.get("./assignment/:visitAssignmentId/summary", {
        path: { visitAssignmentId: props.id },
      });
    },
  });

  const handleOpen = () => {
    if (generate.status === "idle") {
      generate.mutate();
    }
  };

  return (
    <Popover onOpen={handleOpen}>
      <PopoverTrigger>
        <Box>
          <Tooltip label="Generate summary using AI">
            <Button leftIcon={<MagicButtonIcon />} variant="outline">
              Summary
            </Button>
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        {(() => {
          switch (generate.status) {
            case "success":
              return (
                <>
                  <PopoverBody flexDirection="column" p={4}>
                    <Text>{generate.data.summary}</Text>
                  </PopoverBody>
                  <PopoverFooter display="flex" justifyContent="flex-end">
                    <Button
                      leftIcon={<MagicButtonIcon />}
                      size="sm"
                      variant="ghost"
                      onClick={() => generate.mutate()}
                    >
                      Regenerate
                    </Button>
                  </PopoverFooter>
                </>
              );
            case "pending":
              return (
                <PopoverBody>
                  <Flex align="center" color="gray.500" gap={4} justify="center" p={4}>
                    <Spinner size="sm" thickness="1px" />
                    <Text>This should only take a moment</Text>
                  </Flex>
                </PopoverBody>
              );
            case "error":
              return (
                <PopoverBody>
                  <Flex align="center" color="red.500" justify="center" p={4}>
                    <Text>{formatErrorResponse(generate.error)}</Text>
                  </Flex>
                </PopoverBody>
              );
            case "idle":
              return <></>;
          }
        })()}
      </PopoverContent>
    </Popover>
  );
}
