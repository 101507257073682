import { HStack, Text, VStack } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { Messages } from "../../../../core/api";
import DurationTimer from "../../../../shared/components/DurationTimer";
import { getTelephonyCallParticipantTypeAndName } from "../../call-center.utils";

export default function RingingCallCard(props: {
  caller: Messages["TelephonyInboundRingingCallInfo"]["caller"];
  startedRingingAt: Instant;
}) {
  const { type, name } = getTelephonyCallParticipantTypeAndName(props.caller);
  return (
    <HStack gap={4} justifyContent="center">
      <VStack alignItems="flex-start" gap={0}>
        <Text fontSize="xl">{name}</Text>
        <Text color="gray.500" fontSize="sm">
          {type}
        </Text>
        <Text color="gray.500" fontSize="sm">
          {"Ringing for: "}
          <DurationTimer instant={props.startedRingingAt} />
        </Text>
      </VStack>
    </HStack>
  );
}
