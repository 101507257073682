import { LocalDateTime, ZoneId } from "@js-joda/core";

//! @ngInject
export function suggestVisitToRnModalCtrl($scope, $rootScope, toaster, DatabaseApi, $uibModalInstance, newPatient, patientAddress, $timeout, timezone){

    $scope.newPatient = angular.copy(newPatient);

    if(!$scope.newPatient || !$scope.newPatient.address) {
        console.log('hhhh', patientAddress);
        $scope.newPatient = {
            address: patientAddress
        };
    }

    $scope.options = {};
    $scope.map = {zoom: 13, patients: []};
    $scope.map.center = {
        latitude: $scope.newPatient.address.components.location.lat,
        longitude: $scope.newPatient.address.components.location.lng
    };
    $scope.map.home = {
        latitude: $scope.newPatient.address.components.location.lat,
        longitude: $scope.newPatient.address.components.location.lng
    };


    $scope.closeModal = function(){
        $uibModalInstance.close();
    };

    DatabaseApi.get('agencies/' + $rootScope.agencyId + '/staffing_suggestions_single_visit' ).then(function (res) {


        res.data.suggestions.forEach(function(c){


            // c.patientGeoLocation.lat += randTest();
            // c.patientGeoLocation.lng += randTest();

            $scope.map.patients.push({
                location: {
                    latitude: c.patientGeoLocation.lat,
                    longitude: c.patientGeoLocation.lng
                },
                data: c,
                events: {
                    'click':
                        function(event){
                            showCaregiver(event);
                        }
                }
            });
        });

        $timeout(function () {
            $scope.showMap = true;
        }, 100);
    }, function () {
        toaster.pop('error', "Something went wrong");
    });


    var rns = DatabaseApi.caregivers();
    function showCaregiver(event) {

        if(!$scope.map.patients || !event || event.key === undefined || !$scope.map.patients[event.key]) return;

        $scope.dayDisplay = null;

        var currentRNId = $scope.map.patients[event.key].data.caregiverId;
        var currentRNObject = rns[currentRNId];
        var day = {
            date: $scope.map.patients[event.key].data.startTime.split('T')[0],
            caregiver: currentRNObject ? currentRNObject.displayName : '',
            caregiverId: currentRNId,
            patients: []
        };

        $scope.map.patients.forEach(function (patient) {

            if(patient.data.caregiverId === day.caregiverId && patient.data.startTime.split('T')[0] === day.date){
                day.patients.push(patient.data);
            }
        });

        day.patients.sort(function (a, b) {
           if(b.startTime > a.startTime) return -1;
           if(b.startTime === a.startTime) return 0;
           return 1;
        });

        if(!day.patients.length) return;

        var destinations = [];
        day.patients.forEach(function (value) {
            destinations.push(new google.maps.LatLng(value.patientGeoLocation.lat, value.patientGeoLocation.lng));
        });

        new google.maps.DistanceMatrixService().getDistanceMatrix(
            {
                origins: [$scope.newPatient.address.text],
                destinations: destinations,
                travelMode: 'DRIVING'
            }, callbackDriving);

        function callbackDriving(response, status) {

            if (status == 'OK') {
                var origins = response.originAddresses;
                for (var i = 0; i < origins.length; i++) {
                    var results = response.rows[i].elements;
                    for (var j = 0; j < results.length; j++) {
                        day.patients[j].driving = results[j].duration.text;
                    }
                }
            }
            done();
        }

        var zonedTime = ZonedDateTime.of(LocalDateTime.parse(day.patients[0].startTime), ZoneId.of(timezone));
        var departureTime = new Date(zonedTime.toString().split('[')[0]);
        new google.maps.DistanceMatrixService().getDistanceMatrix(
            {
                origins: [$scope.newPatient.address.text],
                destinations: destinations,
                travelMode: 'TRANSIT',
                transitOptions: {departureTime: departureTime}
            }, callbackTransit);

        function callbackTransit(response, status) {

            if (status == 'OK') {
                var origins = response.originAddresses;
                for (var i = 0; i < origins.length; i++) {
                    var results = response.rows[i].elements;
                    for (var j = 0; j < results.length; j++) {
                        day.patients[j].transit = results[j].duration.text;
                    }
                }
            }
            done();
        }

        new google.maps.DistanceMatrixService().getDistanceMatrix(
            {
                origins: [$scope.newPatient.address.text],
                destinations: destinations,
                travelMode: 'WALKING'
            }, callbackWalking);

        function callbackWalking(response, status) {

            if (status == 'OK') {
                var origins = response.originAddresses;
                for (var i = 0; i < origins.length; i++) {
                    var results = response.rows[i].elements;
                    for (var j = 0; j < results.length; j++) {
                        day.patients[j].walking = results[j].duration.text;
                    }
                }
            }
            done();
        }

        var doneCounter = 0;
        function done(){
            doneCounter++;
            if(doneCounter === 3){
                $scope.dayDisplay = day;
            }

        }
    }

    /*function randTest() {
        var rand = Math.random() / 50;
        var randSignal = Math.random();
        if(randSignal < 0.5) rand *= -1;
        return rand;
    }*/
};
