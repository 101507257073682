import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { Transition, useRouter, useTransitionHook } from "@uirouter/react";
import React from "react";

export interface LeavePageDialogProps {
  enabled?: boolean;
  header?: string;
  body?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  actionsOrientation?: "row" | "column";
  confirmButtonProps?: React.ComponentProps<typeof Button>;
  cancelButtonProps?: React.ComponentProps<typeof Button>;
}

const LeavePageDialog = (props: LeavePageDialogProps) => {
  const cancelRef = React.useRef(null);
  const router = useRouter();
  const [transition, setTransition] = React.useState<Transition | null>(null);
  const disclosure = useDisclosure();

  const handleClickConfirm = () => {
    if (transition !== null) {
      router.stateService.go(transition.targetState().name(), transition.targetState().params());
    }
  };

  useTransitionHook("onExit", {}, (transition) => {
    setTransition(transition);

    if (!disclosure.isOpen && props.enabled) {
      disclosure.onOpen();
      transition.abort();
    }
  });

  return (
    <AlertDialog leastDestructiveRef={cancelRef} {...disclosure}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{props.header ?? "Are you sure?"}</AlertDialogHeader>
          <AlertDialogBody>
            {props.body ?? "If you'll leave this page now, your changes won't be saved."}
          </AlertDialogBody>
          <AlertDialogFooter
            alignItems="center"
            display="flex"
            flexDirection={props.actionsOrientation ?? "row"}
            gap={2}
          >
            <Button
              ref={cancelRef}
              colorScheme="blue"
              variant="solid"
              {...props.cancelButtonProps}
              onClick={disclosure.onClose}
            >
              {props.cancelButtonText ?? "Cancel"}
            </Button>
            <Button variant="link" {...props.confirmButtonProps} onClick={handleClickConfirm}>
              {props.confirmButtonText ?? "Leave page"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default LeavePageDialog;
