import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import { Duration, Instant } from "@js-joda/core";
import { Messages } from "../../../core/api";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../shared/utils/duration-formatter";
import TicketSourceCell from "./TicketSourceCell";

interface Props {
  ticket: Messages["CommCenterTicket"];
  isActive: boolean;
  onClick: (ticketId: CommCenterTicketId) => void;
}

const ChatTicketItem = (props: Props) => {
  const totalUnreadMessages = props.ticket.messages.filter(
    (message) =>
      (message.createdBy.type === "Caregiver" || message.createdBy.type === "Patient") &&
      message.readAt === null
  ).length;

  const lastMessage = props.ticket.messages.at(-1);
  const lastMessageSentTime = lastMessage?.createdAt ?? props.ticket.createdAt;
  const lastMessageText =
    lastMessage?.payload[0].type === "TEXT" ? lastMessage.payload[0].message : "";
  const assigneeName = props.ticket.assignedTo?.name ?? "Unassigned";
  const { formatted: timeAgoH, duration } = durationFormatter.relative(
    Duration.between(Instant.now(), lastMessageSentTime)
  );

  const sentDate = dateFormatter.toDateTime(lastMessageSentTime);

  const lastMessageSentDate = duration.abs().toDays() > 5 ? sentDate : timeAgoH;

  return (
    <Box
      _hover={{ backgroundColor: "var(--chakra-colors-gray-100)" }}
      backgroundColor={props.isActive ? "gray.300" : "inherit"}
      cursor="pointer"
      p={2}
      onClick={() => props.onClick(props.ticket.id)}
    >
      <Flex alignItems="center" direction="row" gap={2}>
        <Tag mb={1}>{props.ticket.label?.name}</Tag>
        <TicketSourceCell
          callInfo={props.ticket.callInfo}
          emailThread={props.ticket.emailThread}
          messages={props.ticket.messages}
          source={props.ticket.source}
          status={props.ticket.status}
        />
      </Flex>
      <Flex justifyContent="space-between">
        <Text>{assigneeName}</Text>
        <Text color="gray" fontSize="sm">
          {lastMessageSentDate}
        </Text>
      </Flex>
      <Flex ml={2} mt={2}>
        <Tag bg="blue.500" color="white" hidden={totalUnreadMessages === 0} mr={2} rounded="full">
          {totalUnreadMessages}
        </Tag>
        <Text color="gray" noOfLines={1}>
          {lastMessageText}
        </Text>
      </Flex>
    </Box>
  );
};

export default ChatTicketItem;
