import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { convert as convertHtmlToText } from "html-to-text";
import React from "react";
import {
  FieldError,
  FormProvider,
  SubmitHandler,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form";
import { z } from "zod";
import { Messages, ResponseOf } from "../../../../core/api";
import Editor from "../../../../shared/components/Lexical/LexicalEditor";
import Select from "../../../../shared/components/Select";
import { S3Object } from "../../../../shared/hooks/useUploadFiles";
import SendIcon from "../../../../shared/icons/SendIcon";
import { CaregiverId, CommCenterTeamId, PatientId } from "../../../../shared/schema/schema";
import CaregiverSelect from "../../../caregiver/components/CaregiverSelect";
import PatientSelect from "../../../patient/components/PatientSelect";
import useEmailTicketMessages from "../../hooks/useEmailTicketMessages";
import { buildAttachmentsPayloadForEmailMessage } from "../../utils/comm-center-email-utils";
import AttachmentTags from "./AttachmentTags";
import EmailAttachFilesButton from "./EmailAttachFilesButton";
import EmailInput from "./EmailInput/EmailInput";
import { fmapu } from "../../../../shared/utils";

const zEmailAddressObject: z.ZodType<Messages["CommCenterEmailAddressObject"]> = z.object({
  name: z.string(),
  email: z.string().email(),
});

const zNewEmailTicket = z
  .object({
    subject: z
      .string()
      .refine((x) => x.replace(/<[^>]*>/g, "").length > 0, { message: "Subject cannot be empty" }),
    assignedTeamId: z
      .number({ required_error: "Need an assigned comm center team" })
      .transform(CommCenterTeamId.parse),
    recipients: z.array(zEmailAddressObject).nonempty({ message: "Need at least one recipient" }),
    content: z
      .string({ invalid_type_error: "Content cannot be empty" })
      .refine((x) => x.replace(/<[^>]*>/g, "").length > 0, { message: "Content cannot be empty" }),
    cc: z.array(zEmailAddressObject),
    bcc: z.array(zEmailAddressObject),
  })
  .and(
    z.union([
      z.object({
        topic: z.literal("Caregiver", {
          errorMap: () => ({
            message: "Invalid value for topic Patient",
          }),
        }),
        caregiverId: z
          .number({ required_error: "Caregiver is missing" })
          .transform(CaregiverId.parse),
        patientId: z.number().transform(PatientId.parse).nullable(),
      }),
      z.object({
        topic: z.literal("Patient", {
          errorMap: () => ({
            message: "Invalid value for topic Caregiver",
          }),
        }),
        patientId: z.number({ required_error: "Patient is missing" }).transform(PatientId.parse),
        caregiverId: z.number().transform(CaregiverId.parse).nullable(),
      }),
    ])
  );
type NewEmailTicketSchema = z.infer<typeof zNewEmailTicket>;

interface Props {
  defaultValues: {
    caregiverId: CaregiverId | null;
    patientId: PatientId | null;
    topic: "Caregiver" | "Patient";
    recipients?: Messages["CommCenterEmailAddressObject"][];
    assignedTeamName?: string;
  };
  teams: Messages["CommCenterTeamWithMembers"][];
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
}

function NewEmailTicket(props: Props) {
  const modalContainerRef = React.useRef(null);
  const { attachments, onChangeAttachments, createEmailTicket } = useEmailTicketMessages({
    onSuccessCreateEmailTicket: props.onSuccessCreateEmailTicket,
  });

  const defaultTeam = props.teams.find(
    (team) => team.name === props.defaultValues.assignedTeamName
  );

  const form = useForm<NewEmailTicketSchema>({
    mode: "onSubmit",
    resolver: zodResolver(zNewEmailTicket),
    defaultValues: {
      ...(props.defaultValues as Partial<NewEmailTicketSchema>),
      recipients: props.defaultValues.recipients ?? [],
      cc: [],
      bcc: [],
      content: "",
      subject: "",
      assignedTeamId: fmapu(defaultTeam, (team) => team.id),
    },
  });

  const onSubmit: SubmitHandler<NewEmailTicketSchema> = (data: NewEmailTicketSchema) => {
    const entity =
      data.topic === "Caregiver"
        ? {
            type: data.topic,
            id: data.caregiverId,
          }
        : {
            type: data.topic,
            id: data.patientId,
          };

    createEmailTicket.mutate({
      entity,
      assignedTeamId: data.assignedTeamId,
      subject: data.subject,
      message: {
        recipients: data.recipients,
        cc: data.cc,
        bcc: data.bcc,
        content: data.content,
        contentPlainText: convertHtmlToText(data.content, {
          wordwrap: 130,
        }),
        attachments: buildAttachmentsPayloadForEmailMessage(attachments),
      },
    });
  };

  const error = Object.keys(form.formState.errors).reduce(
    (acc: FieldError | null, field: string) => {
      const errors = form.formState.errors as Record<string, FieldError>;
      return errors[field] ? errors[field] : acc;
    },
    null
  );

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Flex align="center" direction="column" gap={4}>
      <Heading color="blue.500">Send a new email</Heading>
      <FormProvider {...form}>
        <form onKeyDown={handleFormKeyDown} onSubmit={form.handleSubmit(onSubmit)}>
          <Flex align="center" direction="column" gap={3}>
            <TicketTopicSelect />
            <EmailTicketForm
              attachments={attachments}
              teams={props.teams}
              onChangeAttachments={onChangeAttachments}
            />

            {error !== null && (
              <Text color="red.500" fontSize="sm">
                {error.message}.
              </Text>
            )}

            <Flex gap={3} ml="auto" mt={4}>
              <Box ref={modalContainerRef} position="relative" zIndex="emailAttachFiles" />
              <EmailAttachFilesButton
                files={attachments}
                isDisabled={createEmailTicket.isPending}
                portalProps={{ containerRef: modalContainerRef }}
                onChange={onChangeAttachments}
              />
              <Button
                colorScheme="blue"
                gap={2}
                isDisabled={!form.formState.isDirty || !form.formState.isValid}
                isLoading={createEmailTicket.isPending}
                rounded="xl"
                size="md"
                type="submit"
              >
                Send
                <SendIcon />
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}

const TicketTopicSelect = () => {
  const { setValue, control, trigger } = useFormContext<NewEmailTicketSchema>();
  const topic = useController({ name: "topic", control });
  const patientId = useController({ name: "patientId", control });
  const caregiverId = useController({ name: "caregiverId", control });

  const handleCaregiverChange = (caregiverId: CaregiverId | null) => {
    setValue("caregiverId", caregiverId);
    trigger("topic");
  };

  const handlePatientChange = (patientId: PatientId | null) => {
    setValue("patientId", patientId);
    trigger("topic");
  };

  const handleTopicChange = (topic: "Caregiver" | "Patient") => {
    setValue("topic", topic);
    trigger("topic");
  };

  return (
    <Flex>
      <RadioGroup value={topic.field.value} onChange={handleTopicChange}>
        <Flex alignItems="flex-start" direction="column" gap={2}>
          <Flex gap={4}>
            <Radio checked={topic.field.value === "Caregiver"} value="Caregiver">
              Primary
            </Radio>
            <CaregiverSelect
              value={caregiverId.field.value}
              onChange={(value) => handleCaregiverChange(value?.id ?? null)}
            />
          </Flex>
          <Flex gap={4}>
            <Radio checked={topic.field.value === "Patient"} value="Patient">
              Primary
            </Radio>
            <PatientSelect
              value={patientId.field.value}
              onChange={(value) => handlePatientChange(value?.id ?? null)}
            />
          </Flex>
        </Flex>
      </RadioGroup>
    </Flex>
  );
};

const EmailTicketForm = (props: {
  attachments: S3Object[];
  onChangeAttachments: (newAttachments: S3Object[]) => void;
  teams: Messages["CommCenterTeamWithMembers"][];
}) => {
  const ref = React.useRef(null);
  const { control, trigger, setValue, register, getValues } =
    useFormContext<NewEmailTicketSchema>();
  const assignedTeamId = useController({ name: "assignedTeamId", control });

  const onChangeListField = (
    value: Messages["CommCenterEmailAddressObject"],
    field: "recipients" | "cc" | "bcc"
  ) => {
    const list = getValues(field);
    const index = list.map((x: Messages["CommCenterEmailAddressObject"]) => x).indexOf(value);
    if (index === -1) {
      setValue(field, [...list, value]);
    } else {
      setValue(field, [...list.slice(0, index), ...list.slice(index + 1)]);
    }
    trigger(field);
  };

  const handleRemoveAttachment = (file: S3Object) => {
    const newAttachments = props.attachments.filter((a) => a.key !== file.key);
    props.onChangeAttachments(newAttachments);
  };

  const handleChangeAssignedTeamId = (teamId: CommCenterTeamId | undefined) => {
    if (teamId !== undefined) {
      setValue("assignedTeamId", teamId);
      trigger("assignedTeamId");
    }
  };

  const teamsOptions = props.teams.map((team) => ({
    label: team.name,
    value: team.id,
  }));

  return (
    <Card maxW="3.5xl" minW="3.5xl">
      <CardBody>
        <Box ref={ref} position="relative" zIndex="emailInput" />
        <Flex flexDirection="column" gap={4}>
          <Select
            allowUnselect={false}
            buttonProps={{ w: "100%" }}
            label="Assigned team"
            multiple={false}
            options={teamsOptions}
            value={assignedTeamId.field.value ?? null}
            onChange={handleChangeAssignedTeamId}
          />
          <EmailInput
            containerRef={ref}
            label="To"
            value={getValues("recipients")}
            onSelect={(entity) => onChangeListField(entity, "recipients")}
          />
          <EmailInput
            containerRef={ref}
            label="cc"
            value={getValues("cc")}
            onSelect={(entity) => onChangeListField(entity, "cc")}
          />
          <EmailInput
            containerRef={ref}
            label="bcc"
            value={getValues("bcc")}
            onSelect={(entity) => onChangeListField(entity, "bcc")}
          />
          <FormControl alignItems="center" display="inline-flex" flexWrap="wrap">
            <FormLabel>Subject</FormLabel>
            <Input flex={1} minWidth={200} type="text" variant="ghost" {...register("subject")} />
          </FormControl>
          <Editor
            format="html"
            onChange={({ output }) => {
              setValue("content", output);
              trigger("content");
            }}
          />
          <Flex overflowX="auto">
            <AttachmentTags attachments={props.attachments} onRemove={handleRemoveAttachment} />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default NewEmailTicket;
