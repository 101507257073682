import { useQueryClient } from "@tanstack/react-query";
import useSocketEvent from "../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../shared/query-keys";

export default function CommCenterSocketsListener() {
  const queryClient = useQueryClient();

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: (event) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.get(event.ticketId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.search.K });
    },
  });

  return <></>;
}
