import {
  ButtonProps,
  chakra,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Heading,
  useDisclosure,
  Center,
  VStack,
  Text,
} from "@chakra-ui/react";
import { CaregiverId, PatientId } from "../../../../shared/schema/schema";
import React from "react";
import PatientSendSMSButton from "../../../patient/components/PatientSendSMSButton";
import CaregiverSendSMSButton from "../../../caregiver/components/CaregiverSendSMSButton";
import PatientSendEmailButton from "../../../patient/components/PatientSendEmailButton";
import CaregiverSendEmailButton from "../../../caregiver/components/CaregiverSendEmailButton";
import PatientCallButton from "../../../patient/components/PatientCallButton";
import CaregiverCallButton from "../../../caregiver/components/CaregiverCallButton";
import CaregiverChatButton from "../../../caregiver/components/CaregiverChatButton";
import PatientCommunicationLog from "../communication-log/PatientCommunicationLog";
import CaregiverCommunicationLog from "../communication-log/CaregiverCommunicationLog";
import EntityCommunicationsFilters from "./EntityCommunicationsFilters";
import useEntityCommunicationsFilters from "../../hooks/useEntityCommunicationsFilters";
import { EntityCommunicationsAdjustedFilters } from "./entity-communications.types";
import { matchEntityComminucationsTicketTypeToCommCenterTicketSource } from "./entity-communications.utils";

export type EntityCommunicationsProps =
  | {
      entity: "Patient";
      id: PatientId;
    }
  | {
      entity: "Caregiver";
      id: CaregiverId;
    };

type Props = EntityCommunicationsProps & {
  disclosure: ReturnType<typeof useDisclosure>;
};

function EntityCommunications(props: Props) {
  const { filters, setFilter, resetFilters } = useEntityCommunicationsFilters();

  const handleClose = () => {
    resetFilters();
    props.disclosure.onClose();
  };

  const adjustedFilters = React.useMemo(() => {
    return {
      ...filters,
      type: filters.type.flatMap((x) =>
        matchEntityComminucationsTicketTypeToCommCenterTicketSource(x)
      ),
    };
  }, [filters]);

  return (
    <Drawer
      {...props.disclosure}
      blockScrollOnMount={false}
      size="xl"
      trapFocus={false}
      onClose={handleClose}
    >
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <Flex direction="column" gap={5}>
            <Heading as="h1" size="lg">
              Communications
            </Heading>
            <EntityCommunicationsActions>
              {getCallButton(props)}
              {getSmsButton(props)}
              {getEmailButton(props)}
              {getChatButton(props)}
            </EntityCommunicationsActions>
            <EntityCommunicationsFilters filters={filters} setFilter={setFilter} />
            {getCommunicationLogComponent(props, adjustedFilters)}
          </Flex>
        </DrawerBody>
      </DrawerContent>
      <DrawerOverlay />
    </Drawer>
  );
}

function getSmsButton(props: Props): React.ReactNode {
  switch (props.entity) {
    case "Patient":
      return <PatientSendSMSButton buttonProps={defaultButtonProps} patientId={props.id} />;
    case "Caregiver":
      return <CaregiverSendSMSButton buttonProps={defaultButtonProps} caregiverId={props.id} />;
  }
}

function getEmailButton(props: Props): React.ReactNode {
  switch (props.entity) {
    case "Patient":
      return <PatientSendEmailButton buttonProps={defaultButtonProps} patientId={props.id} />;
    case "Caregiver":
      return <CaregiverSendEmailButton buttonProps={defaultButtonProps} caregiverId={props.id} />;
  }
}

function getCallButton(props: Props): React.ReactNode {
  switch (props.entity) {
    case "Patient":
      return <PatientCallButton buttonProps={defaultButtonProps} patientId={props.id} />;
    case "Caregiver":
      return <CaregiverCallButton buttonProps={defaultButtonProps} caregiverId={props.id} />;
  }
}

function getChatButton(props: Props): React.ReactNode {
  switch (props.entity) {
    case "Patient":
      return <></>;
    case "Caregiver":
      return <CaregiverChatButton buttonProps={defaultButtonProps} caregiverId={props.id} />;
  }
}

function getCommunicationLogComponent(
  props: Props,
  filters: EntityCommunicationsAdjustedFilters
): React.ReactNode {
  switch (props.entity) {
    case "Patient":
      return (
        <PatientCommunicationLog
          emptyCommunicationsComponent={<EmptyCommunications />}
          filters={filters}
          patientId={props.id}
        />
      );
    case "Caregiver":
      return (
        <CaregiverCommunicationLog
          caregiverId={props.id}
          emptyCommunicationsComponent={<EmptyCommunications />}
          filters={filters}
        />
      );
  }
}

const EntityCommunicationsActions = chakra(Flex, {
  baseStyle: {
    gap: 5,
    justifyContent: "flex-end",
  },
});

const defaultButtonProps: ButtonProps = {
  size: "md",
  fontSize: 14,
  variant: "solid",
  colorScheme: "blue",
  color: "white",
};

function EmptyCommunications() {
  return (
    <Center mt="10em">
      <VStack gap={2} justifyContent="center">
        <Image h={80} objectFit="cover" rounded="full" src="/admin/images/empty-chat.svg" w={80} />
        <Text fontSize="xl" textAlign="center">
          Looks like there are no communications matching these filters
        </Text>
      </VStack>
    </Center>
  );
}

export default EntityCommunications;
