import { chakra, Tag, TagProps } from "@chakra-ui/react";
import { PatientClinicalSeverityLevel } from "../patient-utils";

interface Props {
  clinicalSeverityLevel: PatientClinicalSeverityLevel | null;
}

export default function PatientClinicalSeverityLevelTag(props: TagProps & Props) {
  const { clinicalSeverityLevel, ...rest } = props;

  switch (clinicalSeverityLevel) {
    case "HIGHEST_ASSISTANCE":
      return (
        <BaseSeverityLevelTag {...rest} colorScheme="red">
          🚨 Patient requires the highest level of assistance 🚨
        </BaseSeverityLevelTag>
      );
    case "WALKING_ASSISTANCE":
      return (
        <BaseSeverityLevelTag {...rest} colorScheme="orange">
          Patient requires medium assistance
        </BaseSeverityLevelTag>
      );
    case "MINIMAL_ASSISTANCE":
    case null:
      return <></>;
  }
}

const BaseSeverityLevelTag = chakra(Tag, {
  baseStyle: {
    padding: 3,
    lineHeight: 1.5,
  },
});
