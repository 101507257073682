import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../../core/api";
import { useGlobalWorkflowRunner } from "../../../../shared/hooks/useGlobalWorkflowRunner";
import { WorkflowTaskInstanceId } from "../../../../shared/schema/schema";
import { mapWorkflowDataFieldRecordToInput } from "../../workflow.utils";
import { WorkflowInputForm } from "../WorkflowForm";
import { HumanTaskSubmitParams } from "./HumanTaskForm";

interface Props {
  workflowTaskInstanceId: WorkflowTaskInstanceId;
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  onSubmit: (params: HumanTaskSubmitParams) => void;
}

export default function HumanTaskDefaultForm(props: Props) {
  const [selectedOutput, setSelectedOutput] = React.useState<string | null>(null);
  const workflowRunner = useGlobalWorkflowRunner();

  const handleSubmit = (params: HumanTaskSubmitParams) => {
    props.onSubmit(params);
    setSelectedOutput(null);
  };

  return (
    <Flex direction="column" gap={3}>
      {Object.entries(props.output).map(([key, form]) => (
        <Box
          key={key}
          _hover={{
            bg: selectedOutput === key ? "" : "gray.50",
          }}
          border="2px"
          borderColor={selectedOutput === key ? "blue.400" : "gray.200"}
          role="button"
          rounded="lg"
          tabIndex={0}
          transition="var(--chakra-transition-duration-normal)"
          onClick={() => setSelectedOutput(key)}
        >
          <Text fontSize="lg" fontWeight="semibold" p={4}>
            {key}
          </Text>

          {selectedOutput === key && (
            <Box borderTop="1px" borderTopColor="gray.200" p={4}>
              <WorkflowInputForm
                fields={mapWorkflowDataFieldRecordToInput(form)}
                fileUploadDestination={{ by: "workflow-task", id: props.workflowTaskInstanceId }}
                hints={workflowRunner.hints}
                renderButton={() => (
                  <Button colorScheme="blue" ms="auto" type="submit" variant="solid">
                    Submit
                  </Button>
                )}
                onSubmit={(data) => handleSubmit({ outputKey: key, data: data })}
              />
            </Box>
          )}
        </Box>
      ))}
    </Flex>
  );
}
