import { PatientId, VisitInstanceId } from "../messages/ids";
import { AgencyNoteEditParams } from "../messages/note";
import { VisitInstancesSaveResponseWithAssignIncreaseOvertime } from "../messages/visit";
import { BulkEditVisitInstanceRequest, VisitInstanceEditParams } from "../messages/visit_instance";
import { MissedVisitService } from "../services/missedVisitService";

interface NoteValidations {
    isPredefinedValid: boolean | undefined,
    isMessageValid: boolean | undefined
}

interface MissedVisitsSideBarBindings {    
    changeState: (newState: string | undefined) => void;
}

interface MissedVisitsSideBarOptions extends angular.IComponentOptions {
    bindings: Record<keyof MissedVisitsSideBarBindings, string>;
}

interface NoteState {
    validations: NoteValidations;
    note: AgencyNoteEditParams;
    // note.noteType seems unreliable.
    noteType: AgencyNoteEditParams["noteType"];
    isNoteRequired: boolean;
}

interface ModalState {
    selectedOption: {id?: number};
    missedVisitNote: NoteState;
    adjustmentNote: NoteState;
}

function initializeNoteState(noteType: AgencyNoteEditParams["noteType"], required: boolean): NoteState {
    return {
        validations: {
            isPredefinedValid: undefined,
            isMessageValid: undefined
        },
        note: {
            status: "",
            noteType: noteType,
        },
        noteType: noteType,
        isNoteRequired: required
    };
}

//! @ngInject
class MissedVisitSideBarCtrl implements ng.IComponentController, MissedVisitsSideBarBindings {
    changeState!: (newState: string | undefined) => void;
    private selectedVisits :any[];
    private patientId :PatientId;
    private editShiftsParams :any[];
    private isLoading: boolean;
    private modalState: ModalState;
    private visitsPreview: string[] = [];
    private message;
    private isMissedVisitSideModalOpen :boolean;

    constructor(
        private missedVisitService: MissedVisitService,
        private toaster: toaster.IToasterService,        
        private $rootScope: ng.IRootScopeService,        
        private $filter: ng.IFilterService,
        private noteConsts: any,
        private entityNoteService: any,
        private entityNewVisitModalService: any,
        private VisitInstanceModalService: any,
        private generalUtils: any
    ) {
        this.isLoading = false;
        this.message = "";
        this.isMissedVisitSideModalOpen = true;
        this.selectedVisits = this.entityNewVisitModalService.missedVisitsData.selectedVisits;
        this.patientId = this.entityNewVisitModalService.missedVisitsData.patientId;
        this.editShiftsParams = this.entityNewVisitModalService.missedVisitsData.editShiftsParams;

        this.modalState = {
            selectedOption: { id: undefined },
            missedVisitNote: initializeNoteState(
                this.noteConsts.NoteTypes.MISSED_VISIT_INSTANCE, 
                this.entityNoteService.isEntityNoteRequired(this.noteConsts.NoteTypes.MISSED_VISIT_INSTANCE)
            ),
            adjustmentNote: initializeNoteState(
                this.noteConsts.NoteTypes.ADJUSTMENT_APPROVAL, 
                this.selectedVisits.some(visit => visit.isBilled || visit.isPaid || visit.isOnPayrollDraft)
                && this.entityNoteService.isEntityNoteRequired(this.noteConsts.NoteTypes.ADJUSTMENT_APPROVAL)
            )
        };

        this.entityNewVisitModalService.registerObserverCallback(
            "visits",
            "missedVisitSideBar",
            this.getSelectedVisits
        );

        this.updateVisitsPreview();
    }
    
    $onInit(): void {
        this.$rootScope.$on('patient_calendar_update_visit_selection_type', (_, type) => {
            this.editShiftsParams["type"] = type;
        });
    }

    loadItems = () => {
        this.isLoading = true;
        const body = this.getMissedVisitsBody(this.selectedVisits);
        this.missedVisitService.missVisits(body).then((res :VisitInstancesSaveResponseWithAssignIncreaseOvertime) => {
            const skippedVisitsData = res.skippedVisitsAndMissingPermissions;
            if (!skippedVisitsData) {
                this.toaster.pop("success", "Selected visits succesfully missed");
            } else {
                this.VisitInstanceModalService.showSkippedVisitsErrorModal({
                    skippedVisitsAndMissingPermissions: skippedVisitsData,
                    updatedVisitsAmount: res.visitInstances.length,
                    action: "update"
                });
            }
            this.$rootScope.$emit("refresh_visits");
            this.closeParentModal();
            this.generalUtils.scrollToElement('scroll-calendar');
        }, () => {
            this.toaster.pop("error", "Something went wrong", "Cannot set selected visits as missed");
        }).finally(() => {
            this.isLoading = false;
        });
    }

    getSelectedVisits = () => {
        this.selectedVisits = this.entityNewVisitModalService.selectedItems.visits;
        if (this.isMissedVisitSideModalOpen &&
            this.selectedVisits.length === 0) {
            this.closeParentModal();
        } else {
            this.updateVisitsPreview();
        }
    }

    updateVisitsPreview = () => {
        this.visitsPreview = this.selectedVisits.map((visit, index) => {
            const startDateAndTime = this.$filter("mfShortTime")(visit.startTime, ['withDate']);
            const endTime = this.$filter("mfShortTime")(visit.endTime);
            return `${index + 1}. ${startDateAndTime} - ${endTime}`;
        });

        this.modalState.adjustmentNote.isNoteRequired = 
            this.selectedVisits.some(visit => visit.isBilled || visit.isPaid || visit.isOnPayrollDraft)
            && this.entityNoteService.isEntityNoteRequired(this.noteConsts.NoteTypes.ADJUSTMENT_APPROVAL);

        // If more than one visit selected adjust the message accordingly
        this.message = this.selectedVisits.length === 1 ? `Are you sure you want to miss the selected visit?` :
            `Are you sure you want to miss the following ${this.selectedVisits.length} selected visits?`;
    }

    getMissedVisitsBody = (visits :any) => {
        const ids :VisitInstanceId[] = visits.map((visit :any) => visit.visitInstanceId);
        const changes :VisitInstanceEditParams = {
            "missedVisit": true,            
            "missedVisitNote": this.entityNoteService.buildEntityNoteRequest(
                this.modalState.missedVisitNote.note,
                this.modalState.missedVisitNote.noteType,
            ),
            "manualHoldInvoicingNotes": null,
            "travelTimeSeconds": 0
        }

        if (this.modalState.adjustmentNote.isNoteRequired) {
            changes.approvalNote = this.entityNoteService.buildEntityNoteRequest(
                this.modalState.adjustmentNote.note,
                this.modalState.adjustmentNote.noteType,
            );
        }

        const body :BulkEditVisitInstanceRequest = {
            ids,
            changes
        };

        return body;
    }

    closeParentModal = () => {
        this.changeState(undefined);
        this.isMissedVisitSideModalOpen = false;
        this.$rootScope.$emit("close_missed_visit_modal");
    };

    onClickMiss = () => {
        for (const noteState of [this.modalState.missedVisitNote, this.modalState.adjustmentNote]) {
            if (!noteState.isNoteRequired) {
                continue;
            }

            const { isNoteValid, isPredefinedValid, isMessageValid } = this.entityNoteService.validateEntityNote(
                noteState.note,
                noteState.noteType
            );
            if (!isNoteValid) {
                noteState.validations = { isPredefinedValid, isMessageValid };
                return;
            }
        }        

        this.loadItems();
    }

    handleNoteChanges = (updatedNote :any, nodeState: NoteState) => {
        nodeState.note = updatedNote;
    };

    $onDestroy = () => {
        this.entityNewVisitModalService.unregisterObserverCallback("visits", "MissedVisitSideBarCtrl");
    }
}

export const MissedVisitSideBar: MissedVisitsSideBarOptions = {
    controller: MissedVisitSideBarCtrl,
    controllerAs: "ctrl",
    templateUrl: "admin/views/missed-visit-side-bar.html",
    bindings: {        
        changeState: "&"
    },
};

