import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryParamsOf } from "../../core/api";
import useApi from "../../shared/hooks/useApi";
import { useQueryParams } from "../../shared/hooks/useQueryParams";
import CICOReportsPage from "./CicoIssuesVisitsPage";
import VisitRecordForm from "./VisitRecordForm";
import { VisitInstanceId } from "../../shared/schema/schema";
import { LocalDate } from "@js-joda/core";
import React from "react";

export default function CICOReportsRoute() {
  const queryClient = useQueryClient();
  const { queries, api } = useApi();
  const toast = useToast();
  const [enableQuery, setEnableQuery] = React.useState<boolean>(false);

  const queryParams = useQueryParams<QueryParamsOf<"get", "./visits_with_cico_issues">>({
    storageKey: ["reports", "cico-issues"],
    defaultOptions: {
      from: LocalDate.now().minusDays(1),
      to: LocalDate.now(),
    },
  });

  const saveRecordMutation = useMutation({
    mutationFn: (params: {
      visitInstanceIds: VisitInstanceId[];
      form: VisitRecordForm;
      onlyAssigneeChanged: boolean;
    }) => {
      return api.put("./visits_with_cico_issues", {
        body: {
          visitInstanceIds: params.visitInstanceIds,
          onlyAssigneeChanged: params.onlyAssigneeChanged,
          ...params.form,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        queries.cicoIssues.cicoIssuesVisits(queryParams.params.toJSON())
      );
      setEnableQuery(true);
      toast({
        title: "Record saved",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error: Error) => {
      toast({
        title: "Error saving record",
        description: error.message,
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <CICOReportsPage
      filters={queryParams}
      isLoading={saveRecordMutation.isPending}
      queryEnabled={enableQuery}
      setEnableQuery={setEnableQuery}
      visits={[]}
      onChangeFilter={queryParams.setValue}
      onChangeFilters={queryParams.setValues}
      onSaveRecords={saveRecordMutation.mutate}
    />
  );
}
