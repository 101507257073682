import { PhoneIcon } from "@chakra-ui/icons";
import { Button, ButtonProps, forwardRef, keyframes, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const RingingCallsButton = forwardRef(
  (
    props: ButtonProps & {
      numCalls: number;
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { numCalls, ...rest } = props;

    return (
      <Button
        ref={ref}
        _hover={{ bg: "red.600" }}
        alignItems="center"
        animation={`${pulseWithWave} 2s infinite`}
        bg="red.500"
        display="flex"
        gap={2}
        h={38}
        justifyContent="center"
        p={5}
        rounded="lg"
        size="xs"
        w={150}
        {...rest}
      >
        <motion.div
          animate={{
            rotate: [0, 20, -15, 0],
            transition: { duration: 1, repeat: Infinity, repeatDelay: 1 },
          }}
        >
          <PhoneIcon color="white" h={4} w={4} />
        </motion.div>
        <Text color="white" fontSize="lg">
          {getRingingCallsButtonText(numCalls)}
        </Text>
      </Button>
    );
  }
);

function getRingingCallsButtonText(numCalls: number) {
  return numCalls === 0
    ? "No ringing calls"
    : numCalls === 1
    ? "1 ringing call"
    : `${numCalls} ringing calls`;
}

const pulseWithWave = keyframes`
  from, 0%{  
    box-shadow: 0 0 0 0 rgba(255, 90, 90, 0.5);
  }
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1.05);
  }
  to {
    box-shadow: 0 0 20px 30px rgba(255, 90, 90, 0);
  }
  100% {
    transform: scale(0.95);
  }
`;

export default RingingCallsButton;
