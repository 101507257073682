import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import OptionButtonsGroup from "./OptionButtonsGroup";

type Props = {
  labels: Messages["CommCenterLabel"][];
  chosenLabels: Messages["CommCenterLabel"][];
  value?: Messages["CommCenterLabel"];
  onClickChosenLabel: (label: Messages["CommCenterLabel"]) => void;
  onChange: (label: Messages["CommCenterLabel"]) => void;
};

const ChooseLabelSection = (props: Props) => {
  return (
    <Flex direction="column" gap={2}>
      <Flex gap={2} minH={30} minW={500}>
        {props.chosenLabels.map((label) => (
          <Button
            key={`chosenLabel-${label.id}`}
            rightIcon={<ChevronRightIcon />}
            variant="link"
            onClick={() => props.onClickChosenLabel(label)}
          >
            {label.name}
          </Button>
        ))}
      </Flex>
      <Box>
        <OptionButtonsGroup
          options={props.labels.map((label) => ({ value: label, label: label.name }))}
          value={props.value}
          onClick={props.onChange}
        />
      </Box>
    </Flex>
  );
};

export default ChooseLabelSection;
