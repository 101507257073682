import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import Select from "../../../shared/components/Select";

type Option<$Value> = {
  label: string;
  value: $Value;
};

interface Props<$Value> {
  value: $Value | null;
  options: Option<$Value>[];
  inputLabel: string;
  label: string;
  isRequired: boolean;
  isEditable: boolean;
  styleProps?: any;
  inputPlaceholder?: string;
  onSelectValue: (value: $Value | null) => void;
}

function IntakePatientProfileSearchableDropdown<$Value>(props: Props<$Value>) {
  return (
    <FormControl isRequired={props.isEditable ? props.isRequired : false} {...props.styleProps}>
      <FormLabel>{props.inputLabel}</FormLabel>
      {props.isEditable ? (
        <Select
          label={props.label ?? props.inputLabel}
          multiple={false}
          options={props.options}
          value={props.value}
          width="fit-content"
          onChange={(value) => props.onSelectValue(value ?? null)}
        />
      ) : (
        <Input isDisabled={!props.isEditable} value={props.label} />
      )}
    </FormControl>
  );
}

export default IntakePatientProfileSearchableDropdown;
