class SelectAllCheckboxCtrl<T> implements ng.IComponentController, Bindings<T> {
  // --- Bindings --- //
  allItems!: T[];
  currentPageItems!: T[];
  checkedPropertyName!: keyof T;
  ngModel?: Set<T>;
  onChange?: (checkedItems: Set<T>) => void;
  // --- END Bindings --- //

  public checkboxModel = false;
  private selectAllType: "all" | "none" | "currentPage" = "none";

  $onInit = () => {
    if (this.ngModel === undefined) {
      this.ngModel = new Set();
    }
  };

  $doCheck = () => {
    if (this.ngModel === undefined) {
      return;
    }

    switch (this.selectAllType) {
      case "all":
        this.checkboxModel = this.allItems.length === this.ngModel.size;
        break;

      case "currentPage":
        this.checkboxModel =
          this.currentPageItems.length === this.ngModel.size &&
          this.currentPageItems.every((item) => this.ngModel!.has(item));
        break;

      case "none":
        this.checkboxModel = false;
        break;
    }
  };

  onCheckAll = () => {
    this.allItems.forEach((item) => {
      // @ts-expect-error #trustme
      item[this.checkedPropertyName] = true;
    });
    this.selectAllType = "all";
    this.setCheckedItems();
  };

  onCheckAllInPage = () => {
    this.onUncheckAll();
    this.currentPageItems.forEach((item) => {
      // @ts-expect-error #trustme
      item[this.checkedPropertyName] = true;
    });
    this.selectAllType = "currentPage";
    this.setCheckedItems();
  };

  onUncheckAll = () => {
    this.allItems.forEach((item) => {
      // @ts-expect-error #trustme
      item[this.checkedPropertyName] = false;
    });
    this.selectAllType = "none";
    this.setCheckedItems();
  };

  private setCheckedItems = () => {
    if (this.ngModel === undefined) {
      return;
    }

    this.ngModel = new Set(this.allItems.filter((item) => item[this.checkedPropertyName]));

    if (this.onChange !== undefined) {
      this.onChange(this.ngModel);
    }
  };
}

interface Bindings<T> {
  allItems: T[];
  currentPageItems: T[];
  checkedPropertyName: keyof T;
  ngModel?: Set<T>;
  onChange?: (checkedItems: Set<T>) => void;
}

export const selectAllCheckboxComponent = {
  $name: "selectAllCheckbox",
  templateUrl:
    "admin/modules/shared/components/select-all-checkbox/select-all-checkbox.component.html",
  controller: SelectAllCheckboxCtrl,
  controllerAs: "ctrl",
  bindings: {
    allItems: "<",
    currentPageItems: "<",
    checkedPropertyName: "<",
    ngModel: "=",
    onChange: "&",
  },
};
