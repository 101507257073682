import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import useApi from "../../../../../shared/hooks/useApi";
import { AgencyMemberId, WorkflowSkillId } from "../../../../../shared/schema/schema";

interface Props {
  skills: WorkflowSkillId[];
  agencyMemberId: AgencyMemberId;
  currentSkills: WorkflowSkillId[];
  isOpen: boolean;
  onClose: () => void;
}

export default function EditSkillsModal(props: Props) {
  const queryClient = useQueryClient();
  const { api, queries } = useApi();
  const toast = useToast();
  const [selectedSkills, setSelectedSkills] = React.useState<WorkflowSkillId[]>(
    props.currentSkills
  );
  const allChecked = selectedSkills.length === props.skills.length;
  const isIndeterminate = selectedSkills.some(Boolean) && !allChecked;

  const handleSelectAll = () => setSelectedSkills(allChecked ? [] : props.skills);

  const handleSelectSkill = (skillId: WorkflowSkillId) =>
    setSelectedSkills(
      selectedSkills.includes(skillId)
        ? selectedSkills.filter((x) => x !== skillId)
        : [...selectedSkills, skillId]
    );

  const save = useMutation({
    mutationFn: () =>
      api.put("./workflow_tasks/agency_member_skills", {
        body: {
          agencyMemberId: props.agencyMemberId,
          skills: selectedSkills,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(queries.workflow.memberSkills());
      queryClient.invalidateQueries(queries.workflow.allMembersWithSkills());

      props.onClose();

      toast({
        title: `Skills updated`,
        description: "Skills updated successfully.",
        status: "success",
        position: "top-right",
      });
    },
    onError: () => {
      toast({
        title: `Error in updating skills`,
        description: "Could not update skills. Please try again later.",
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Skills</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Stack mb={4}>
              <Checkbox
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                onChange={handleSelectAll}
              >
                Select All
              </Checkbox>
              <Divider />
            </Stack>
            <CheckboxGroup value={selectedSkills}>
              <Stack>
                {props.skills.map((skill) => (
                  <Checkbox key={skill} value={skill} onChange={() => handleSelectSkill(skill)}>
                    {skill}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            isLoading={save.isPending}
            mr={3}
            onClick={() => save.mutate()}
          >
            Save
          </Button>
          <Button isDisabled={save.isPending} onClick={props.onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
