import { Instant } from "@js-joda/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import { isInboundEmailMessage } from "../utils/comm-center-email-utils";

const useEmailTicketViewMutation = () => {
  const queryClient = useQueryClient();
  const { api } = useApi();

  return useMutation({
    mutationFn: (params: { ticketId: CommCenterTicketId }) =>
      api.post("./comm_center/tickets/:ticketId/view", {
        path: {
          ticketId: params.ticketId,
        },
        body: {},
      }),
    onMutate: ({ ticketId }) => {
      const ticket = optimisticUpdate<{ ticket: Messages["CommCenterTicket"] }>({
        queryClient: queryClient,
        queryKey: queryKeys.commCenter.get(ticketId),
        update: (data) => {
          data.ticket.emailThread?.messages.forEach((message) => {
            if (isInboundEmailMessage(message)) {
              message.readAt = Instant.now();
            }
          });
        },
      });

      const search = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient: queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets.forEach((ticket) =>
            ticket.emailThread?.messages.forEach((message) => {
              if (isInboundEmailMessage(message)) {
                message.readAt = Instant.now();
              }
            })
          );
        },
      });

      return { ticket, search };
    },
    onError: (_, variables, context) => {
      if (context !== undefined) {
        queryClient.setQueriesData(
          { queryKey: queryKeys.commCenter.get(variables.ticketId) },
          context.ticket.previousValue
        );
        queryClient.setQueriesData(
          { queryKey: queryKeys.commCenter.search.K },
          context.search.previousValue
        );
      }
    },
    onSuccess: (_, { ticketId }) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.search.K });
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.get(ticketId) });
    },
  });
};

export default useEmailTicketViewMutation;
