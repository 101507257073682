import { LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment";

//! @ngInject
export function addHrDocumentModalCtrl ($scope, SweetAlert, $rootScope, $uibModalInstance, DatabaseApi, toaster, generalUtils, wildcard, passedDocument, allDocumentTypes, agencyCertifications, followupTrees, mfModal, $timeout) {
    function init() {
        $scope.headerClickCount = 0;

        $scope.document = {
            isEditing: false,
            disableBaseEdits: false,
            title: "",
            expires: false,
            section: {},
            isMandatory: false,
            isShownByDefault: false,
            isForCompliance: false,
            complianceUploadFromMobile: false,
            requireReVerification: false,
            fields: [],
            followUpDocument: {},
            activeDateType: activeDateImmediatelyOption(),
            activeDate: {
                value: {},
                isOpen: false,
            },
            activeDateAfterAmount: "30",
            activeDateAfterUnit: { ...$scope.dateUnits[0] },
            certifications: [],
            defaultExpiryDate: {
                unit: {},
                amount: null
            },
            expireOnlyOnNoHireDate: false
        };

        if (passedDocument) { // Edit
            setNotAllowedFollowups();
            initEditDocument();
        }

        filterDocumentTypeOptions();
    }

    const notAllowedFollowupIds = new Set();

    const idToDoc = new Map();

    $scope.onClickHeader = () => {
        $scope.headerClickCount++;
    };

    $scope.documentTypeOptions = allDocumentTypes.map(doc => ({id: doc.documentTypeId, label: doc.name}));

    allDocumentTypes.forEach(docType => idToDoc.set(docType.documentTypeId, docType));

    $scope.fieldTypes = [
        { name: "date", title: "Date", description: "Add your custom date" },
        { name: "text", title: "Text", description: "Add your custom text" },
        { name: "dropdown", title: "Dropdown", description: "Create your dropdown menu" },
    ];

    $scope.fieldTypes.forEach(type => type.icon = `admin/images/icons/field-${type.name}.svg`);

    $scope.requireDateOptions = [
        { id: "SPECIFIC_DATE", label: "Specific date" },
        { id: "SET_TIME", label: "Set a time" },
        { id: "IMMEDIATELY", label: "Immediately" },
    ];

    $scope.defaultExpiryDateActionOptions = [
        { id: "SET_TIME", label: "Set a time" },
        { id: "NO_HIRE_DATE", label: "No hire date" },
        { id: "NOTHING", label: "Regular" },
    ];

    $scope.selectedDefaultExpiryDateAction = getInitialExpiryDateActionOption();

    const specificDateOption = () => angular.copy($scope.requireDateOptions[0]);
    const setTimeOption = () => angular.copy($scope.requireDateOptions[1]);
    const immediatelyOption = () => angular.copy($scope.requireDateOptions[2]);

    $scope.activeDateOptions = [
        { id: "IMMEDIATELY", label: "Immediately" },
        { id: "SPECIFIC_DATE", label: "Specific date" },
        { id: "SET_TIME", label: "Set a time from hire date" },
        { id: "GENERAL_START_DATE", label: "General start date" },
    ];

    const activeDateImmediatelyOption = () => angular.copy($scope.activeDateOptions[0]);
    const activeDateSpecificDateOption = () => angular.copy($scope.activeDateOptions[1]);
    const activeDateSetTimeOption = () => angular.copy($scope.activeDateOptions[2]);
    const activeDateGeneralStartDateOption = () => angular.copy($scope.activeDateOptions[3]);

    const getDoNothingActionOption = () => ({ id: null, label: "Do nothing" });

    $scope.indexToLetter = (index) => index + 1;

    const newEmptyChoice = (field) => {
        const choice = {
            value: "",
            action: getDoNothingActionOption(),
            ...buildRequireDateParams(),
            resolveParent: false,
        };

        choice.events = buildChoiceEvents(field, choice);
        choice.followupEvents = buildFollowupEvents(field, choice);

        return choice;
    };

    const newEmptyField = (fieldType) => {
        const field = {
            type: fieldType,
            name: "",
            isMandatory: false,
        };

        field.choices = [newEmptyChoice(field), newEmptyChoice(field)];

        return field;
    };

    $scope.addField = (fieldType) => {
        $scope.document.fields.push(newEmptyField(fieldType));
    }

    $scope.removeField = (key) => {
        const field = $scope.document.fields[key];

        if ($scope.document.isEditing && field.id !== undefined) {
            const modal = mfModal.create({
                subject: "Remove field",
                message: "Are you sure you want to remove this field?",
                cancelLabel: "Cancel",
                confirmLabel: "Remove",
                variant: "danger",
                onConfirm: () => {
                    modal.setLoading(true);
                    sendRemoveFieldRequest(field).then(() => {
                        toaster.pop('success', "Field", 'Removed Successfully');
                        $scope.document.fields.splice(key, 1);
                        modal.close();
                    }).catch(() => {
                        toaster.pop("error", "Field", "Was not removed");
                        modal.close();
                    });
                },
            });
        } else {
            $scope.document.fields.splice(key, 1);
        }
    };

    $scope.addChoice = (field) => {
        field.choices.push(newEmptyChoice(field));
    };

    $scope.removeChoice = (field, key) => {
        const choice = field.choices[key];

        if ($scope.document.isEditing && choice.id) {
            const modal = mfModal.create({
                subject: "Remove choice",
                message: "Are you sure you want to remove this choice?",
                cancelLabel: "Cancel",
                confirmLabel: "Remove",
                variant: "danger",
                onConfirm: () => {
                    modal.setLoading(true);
                    sendRemoveChoiceRequest(field, choice).then(() => {
                        toaster.pop('success', "Field choice", 'Removed Successfully');
                        field.choices.splice(key, 1);
                        modal.close();
                    }).catch(() => {
                        toaster.pop("error", "Field choice", "Was not removed");
                        modal.close();
                    });
                },
            });
        } else {
            field.choices.splice(key, 1);
        }
    };

    $scope.onFieldChange = (field) => {
        if (fieldHasFollowupItem(field) && !field.isMandatory) {
            field.isMandatory = true;
            toaster.pop("warning", "Must be mandatory", "This field has a follow-up item and must be mandatory.");
            return;
        }

        if ($scope.document.isEditing) {
            if (field.id) {
                sendUpdateFieldRequest(field);
            } else {
                sendAddFieldRequest(field);
            }
        }
    };

    $scope.onChoiceChange = (field, choice, choiceKey) => {
        choice.value = choice.value.trim();

        if (choice.value === "") {
            $scope.removeChoice(field.choices, choiceKey);
        } else {
            if (!field.isMandatory && choice.action.id !== null) {
                field.isMandatory = true;
                
                if (field.id) {
                    sendUpdateFieldRequest(field);
                }
            }
            
            if ($scope.document.isEditing) {
                if (choice.id) {
                    sendUpdateChoiceRequest(field, choice);
                } else {
                    sendAddChoiceRequest(field, choice);
                }
            }
        }
    };

    function fieldHasFollowupItem(field) {
        return field.type.title === 'Dropdown' &&
                field.choices.some(choice => choice.action.id !== null);
    }

    function sendRemoveChoiceRequest(field, choice) {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/compliance_fields/:complianceFieldId/possible_values/:possibleValueId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            field.id,
            choice.id
        );

        return DatabaseApi.delete(url);
    }

    function sendUpdateChoiceRequest(field, choice) {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/compliance_fields/:complianceFieldId/possible_values/:possibleValueId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            field.id,
            choice.id
        );

        DatabaseApi.put(url, transformChoiceToRequest(choice)).then((_) => {
            toaster.pop('success', "Field choice", 'Updated Successfully');
        }).catch(function (_) {
            toaster.pop("error", "Field choice", "Was not updated");
        });
    }

    function sendAddChoiceRequest(field, choice) {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/compliance_fields/:complianceFieldId/possible_values",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            field.id,
        );

        DatabaseApi.post(url, transformChoiceToRequest(choice)).then((res) => {
            choice.id = res.data.id;
            toaster.pop('success', "Field choice", 'Added Successfully');
        }).catch(function (_) {
            toaster.pop("error", "Field choice", "Was not added");
        });
    }

    function sendUpdateChoiceResolvesParentRequest(field, choice) {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/compliance_fields/:complianceFieldId/possible_values/:possibleValueId/resolve_parent",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            field.id,
            choice.id
        );

        return DatabaseApi.put(url, {
            resolveParent: choice.resolveParent,
        });
    }

    function sendRemoveFieldRequest(field) {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/compliance_fields/:complianceFieldId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            field.id
        );

        return DatabaseApi.delete(url);
    }

    function sendUpdateFieldRequest(field) {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/compliance_fields/:fieldId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            field.id
        );

        DatabaseApi.put(url, transformFieldToRequest(field)).then((_) => {
            toaster.pop('success', "Field", 'Updated Successfully');
        }).catch(function (_) {
            toaster.pop("error", "Field", "Was not updated");
        });
    }

    function sendAddFieldRequest(field) {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/compliance_items/:caregiverDocumentTypeId/compliance_fields",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.document.documentTypeId
        );

        DatabaseApi.post(url, transformFieldToRequest(field)).then((res) => {
            field.id = res.data.id;
            toaster.pop('success', "Field", 'Added Successfully');
        }).catch(function (_) {
            toaster.pop("error", "Field", "Was not added");
        });
    }

    $scope.followUpDocumentSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        showCheckAll: false,
        showUncheckAll: false,
        enableSearch: true,
    };

    $scope.dateTypeSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        showCheckAll: false,
        showUncheckAll: false,
        enableSearch: false,
    };

    $scope.dateUnits = [
        { id: "DAY", label: "Days" },
        { id: "WEEK", label: "Weeks" },
        { id: "MONTH", label: "Months" },
    ];

    const initAllSections = (documentTypes) => {
        return [...new Set(documentTypes.map(x => x.sectionLabel))].map((sectionLabel, index) => ({
            id: index+1,
            label: generalUtils.capitalize(sectionLabel.split("-")[0]),
            value: sectionLabel
        }));
    };

    $scope.certifications = agencyCertifications;

    $scope.allowedSections = initAllSections(allDocumentTypes);

    $scope.singleSelectionExtraSettings = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
    };

    $scope.selectSectionEvents = {
        onSelectionChanged: () => {
            filterDocumentTypeOptions();
            $scope.updateSetting('section');
        },
    };

    $scope.selectCertificationsEvents = {
        onSelectionChanged: () => {
            filterDocumentTypeOptions();
            $scope.updateSetting('certifications');
        },
    };

    $scope.selectActiveDateEvents = {
        onSelectionChanged: () => {
            $scope.updateActiveDateSetting();
        },
    };

    $scope.updateActiveDateSetting = () => {
        if ($scope.document.activeDateType.id === "SET_TIME") {
            if (!$scope.document.activeDateAfterUnit.id || !$scope.document.activeDateAfterAmount) {
                return;
            }
        }

        if ($scope.document.activeDateType.id === "SPECIFIC_DATE" || $scope.document.activeDateType.id === "GENERAL_START_DATE") {
            if (!$scope.document.activeDate.value || $scope.isEmptyObject($scope.document.activeDate.value)) {
                return;
            }
        }

        $scope.updateSetting('activeDate');
    };

    function getInitialExpiryDateActionOption(document) {
        const id = getInitialExpiryDateActionOptionId(document);

        const option = $scope.defaultExpiryDateActionOptions.find(o => o.id === id);

        return option ? angular.copy(option) : undefined;
    }

    function getInitialExpiryDateActionOptionId(document) {
        if (document) {
            const expiryUnit = $scope.dateUnits.find(unit => unit.id === document.expiryUnit);

            if (expiryUnit) {
                return "SET_TIME";
            }

            if (document.expireOnlyOnNoHireDate) {
                return "NO_HIRE_DATE";
            }
        }

        return "NOTHING";
    }

    function initEditDocument() {
        const sectionRef = $scope.allowedSections.find(section => section.value === passedDocument.sectionLabel);
        const expiryUnitRef = $scope.dateUnits.find(unit => unit.id === passedDocument.expiryUnit);
        
        $scope.document.isEditing = true;
        $scope.document.disableBaseEdits = passedDocument.isPassport;
        $scope.document.documentTypeId = passedDocument.documentTypeId;
        $scope.document.title = passedDocument.name;
        $scope.document.expires = passedDocument.expires;
        $scope.document.section = angular.copy(sectionRef);
        $scope.document.isMandatory = passedDocument.isMandatory;
        $scope.document.isShownByDefault = passedDocument.isShown;
        $scope.document.isForCompliance = passedDocument.isCompliance;
        $scope.document.complianceUploadFromMobile = passedDocument.complianceUploadFromMobile;
        $scope.document.requireReVerification = passedDocument.requireReVerification;
        $scope.document.defaultExpiryDate.unit = expiryUnitRef ? expiryUnitRef : {};
        $scope.document.defaultExpiryDate.amount = passedDocument.expiryAmount;
        $scope.document.expireOnlyOnNoHireDate = passedDocument.expireOnlyOnNoHireDate;
        $scope.selectedDefaultExpiryDateAction = getInitialExpiryDateActionOption(passedDocument);
        $scope.document.certifications = angular.copy(passedDocument.certifications);

        setComplianceDataFromPassedDocument();
    }

    function setComplianceDataFromPassedDocument() {
        if (passedDocument.complianceData) {
            const complianceData = passedDocument.complianceData;

            $scope.document.fields = buildFieldsFromResponse(complianceData.fields);

            $scope.document = {
                ...$scope.document,
                ...buildActiveDateParams(complianceData)
            };
        }
    }

    function stringToNativeDate(str) {
        const dateMoment = moment(str);

        return new Date(dateMoment.year(), dateMoment.month(), dateMoment.date());
    }

    function updatePassedDocument() {
        passedDocument.documentTypeId = $scope.document.documentTypeId;
        passedDocument.name = $scope.document.title;
        passedDocument.expires = $scope.document.expires;
        passedDocument.sectionLabel = $scope.document.section.value;
        passedDocument.isMandatory = $scope.document.isMandatory;
        passedDocument.isShown = $scope.document.isShownByDefault;
        passedDocument.isCompliance = $scope.document.isForCompliance;
        passedDocument.complianceUploadFromMobile = $scope.document.complianceUploadFromMobile;
        passedDocument.requireReVerification = $scope.document.requireReVerification;
        passedDocument.certifications = angular.copy($scope.document.certifications);
    }

    $scope.isSubmitting = false;

    $scope.isDocumentValidToSubmit = () => {
        return (
            $scope.document.title.trim() !== '' &&
            $scope.allowedSections.find(section => section.id === $scope.document.section.id) &&
            $scope.document.isShownByDefault !== null &&
            $scope.document.isMandatory !== null &&
            $scope.document.expires !== null &&
            $scope.document.isForCompliance !== null
        );
    }

    $scope.addCustomSectionLabel = () => {
        return SweetAlert.swal({
            title: "Add new section",
            text: "Section label:",
            type: "input",
            showCancelButton: true,
            closeOnConfirm: true,
            animation: "slide-from-top",
            inputPlaceholder: "Write something",
            html: true,
            allowOutsideClick: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Add",
        }, function (sectionName) {
            if (sectionName) {
                const biggestId = $scope.allowedSections[$scope.allowedSections.length-1].id;
                const newSection = {
                    id: biggestId+1,
                    label: generalUtils.capitalize(sectionName),
                    value: `${sectionName.toLowerCase()}-doc`
                };
                $scope.allowedSections.push(newSection);
                $scope.document.section = newSection;
                $scope.updateSetting('section');
            }
        });
    }

    $scope.submitDocument = () => {
        if (!$scope.isDocumentValidToSubmit()) {
            return toaster.pop('error', 'The form is missing details please try again!');
        }
        const similarDocuments = $scope.levenshteinDocuments();
        if (Array.isArray(similarDocuments) && similarDocuments.length > 0) {
            return SweetAlert.swal({
                title: `There are similar document${similarDocuments.length > 1 ? "s" : ""}`,
                text: similarDocuments.map((doc, index) => `${index+1}. ${doc}<br>`).join("") + "Are you sure you want to add this one also?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Yes, add",
                closeOnConfirm: true,
                closeOnCancel: true,
                html: true,
                allowOutsideClick: true
            }, function (isConfirm) {
                if (isConfirm) {
                    return $scope.createNewDocumentType()
                }
            });
        }
        return $scope.createNewDocumentType()
    }

    function buildFollowupDocumentRequestData(choice) {
        const requireDate = buildRequireDateRequest(choice);

        return choice.action.id ? {
            followupDocumentTypeId: choice.action.id,
            followupDocumentRequireDate: requireDate.static,
            followupDocumentRequireDateDynamic: requireDate.dynamic
        } : null
    }

    function buildRequireDateRequest(choice) {
        const result = {
            static: null,
            dynamic: null
        };

        switch (choice.requireDate.id) {
            case "SET_TIME":
                const unit = choice.followUpDocumentRequireDateAfterUnit.id;
                const amount = parseInt(choice.followUpDocumentRequireDateAfterAmount);

                result.dynamic = {
                    followupDocumentRequireAmount: amount,
                    followupDocumentRequireUnit: unit
                };
                break;

            case "SPECIFIC_DATE":
                if (hasFollowUpDocumentRequireDate(choice)) {
                    result.static = dateToLocalDate(choice.followUpDocumentRequireDate.value);
                }
                break;
        }

        return result;
    }

    $scope.isEmptyObject = (obj) => JSON.stringify(obj) === "{}";

    const buildActiveDateRequest = () => {
        switch ($scope.document.activeDateType.id) {
            case "SET_TIME":
                const unit = $scope.document.activeDateAfterUnit.id;
                const amount = parseInt($scope.document.activeDateAfterAmount);
                if (unit && amount) {
                    return {
                        type: "dynamic",
                        value: { amount, unit },
                    };
                }
                break;

            case "SPECIFIC_DATE":
            case "GENERAL_START_DATE":
                if (hasStaticActiveDate()) {
                    return {
                        type: $scope.document.activeDateType.id === "SPECIFIC_DATE"
                            ? "static"
                            : "general",
                        value: dateToLocalDate($scope.document.activeDate.value),
                    };
                }
                break;
        }

        return null;
    };

    const transformFieldToRequest = (field) => ({
        id: field.id || undefined,
        type: field.type.name.charAt(0).toUpperCase() + field.type.name.slice(1),
        name: field.name,
        possibleValues: field.type.name === "dropdown"
            ? field.choices.filter(c => c.value.trim() !== "").map(transformChoiceToRequest)
            : undefined,
        isMandatory: field.isMandatory
    });

    const transformChoiceToRequest = (choice) => ({
        id: choice.id || undefined,
        followupDocumentRequireData: buildFollowupDocumentRequestData(choice),
        text: choice.value,
        followupResolvesParent: choice.resolveParent
    });

    const hasFollowUpDocumentRequireDate = (choice) => !$scope.isEmptyObject(choice.followUpDocumentRequireDate.value);
    
    const hasStaticActiveDate = () => !$scope.isEmptyObject($scope.document.activeDate.value);

    function buildFieldsFromResponse(responseFields) {
        return responseFields.map(responseField => {
            const field = {
                id: responseField.id,
                type: $scope.fieldTypes.find(type => type.name === responseField.type.toLowerCase()),
                name: responseField.name,
                isMandatory: responseField.isMandatory
            };
    
            if (responseField.type.toLowerCase() === "dropdown") {
                field.choices = responseField.possibleValues.map(choice => ({
                    id: choice.id,
                    action: choice.followupDocumentRequireData?.followupDocumentTypeId ? {
                        id: choice.followupDocumentRequireData.followupDocumentTypeId,
                        label: idToDoc.get(choice.followupDocumentRequireData.followupDocumentTypeId).title
                    } : getDoNothingActionOption(),
                    text: choice.text,
                    value: choice.text,
                    ...buildRequireDateParams(choice.followupDocumentRequireData),
                    resolveParent: choice.followupResolvesParent,
                }));
    
                field.choices.forEach(choice => {
                    choice.events = buildChoiceEvents(field, choice);
                    choice.followupEvents = buildFollowupEvents(field, choice);
                });
            }
    
            return field;
        });
    }

    function buildRequireDateParams(data) {
        const params = {
            requireDate: immediatelyOption(),
            followUpDocumentRequireDate: {
                value: {},
                isOpen: false,
            },
            followUpDocumentRequireDateAfterAmount: data ? null : "30",
            followUpDocumentRequireDateAfterUnit: { ...$scope.dateUnits[0] },
        };

        if (data) {
            if (data.followupDocumentRequireDate) {
                // specific date
                params.requireDate = specificDateOption();
                params.followUpDocumentRequireDate.value = stringToNativeDate(data.followupDocumentRequireDate);
            } else if (data.followupDocumentRequireDateDynamic) {
                // dynamic date
                params.requireDate = setTimeOption();

                params.followUpDocumentRequireDateAfterUnit = angular.copy(
                    $scope.dateUnits.find(unit => unit.id === data.followupDocumentRequireDateDynamic.followupDocumentRequireUnit)
                );

                params.followUpDocumentRequireDateAfterAmount = data.followupDocumentRequireDateDynamic.followupDocumentRequireAmount;
            } else {
                // immediately
                params.requireDate = immediatelyOption();
            }
        }

        return params;
    }

    function buildActiveDateParams(data) {
        const params = {
            activeDateType: activeDateImmediatelyOption(),
            activeDate: {
                value: {},
                isOpen: false,
            },
            activeDateAfterAmount: data ? null : "30",
            activeDateAfterUnit: { ...$scope.dateUnits[0] },
        };

        if (!data?.activeDate) {
            return {
                ...params,
                activeDateType: activeDateImmediatelyOption(),
            };
        }

        switch (data.activeDate.type) {
            case "dynamic":
                return {
                    ...params,
                    activeDateType: activeDateSetTimeOption(),
                    activeDateAfterUnit: angular.copy(
                        $scope.dateUnits.find(unit => unit.id === data?.activeDateDynamic?.unit)
                    ),
                    activeDateAfterAmount: data?.activeDateDynamic?.amount,
                };

            case "static":
            case "general":
                return {
                    ...params,
                    activeDateType: data.activeDate.type === "static"
                        ? activeDateSpecificDateOption()
                        : activeDateGeneralStartDateOption(),
                    activeDate: {
                        ...params.activeDate,
                        value: stringToNativeDate(data.activeDate.value),
                    },
                };

            default:
                console.error(`Unhandled active date type: "${data.activeDate.type}"`);
        }
    }

    // We do this because you can't pass more parameters to ng-dropdown-multiselect's
    // events. the only parameter that is being passed is "item" with property "id" only,
    // so we can't know the field.
    function buildChoiceEvents(field, choice) {
        return {
            onSelectionChanged: () => $scope.onChoiceChange(field, choice),
        };
    }

    function buildFollowupEvents(field, choice) {
        return {
            onSelectionChanged: () => $scope.onFollowupDocumentChange(field, choice),
        };
    }

    $scope.onFollowupDocumentChange = (field, choice) => {
        if ($scope.document.isEditing) {
            if (choice.action.id && choice.requireDate.id) {
                if (
                    (choice.requireDate.id === "SPECIFIC_DATE" && hasFollowUpDocumentRequireDate(choice)) ||
                    (choice.requireDate.id === "SET_TIME" && choice.followUpDocumentRequireDateAfterAmount && choice.followUpDocumentRequireDateAfterUnit) ||
                    (choice.requireDate.id === "IMMEDIATELY")
                ) {
                    sendUpdateChoiceRequest(field, choice);
                }
            } else {
                if (!choice.action.id) {
                    sendUpdateChoiceRequest(field, choice);
                }
            }
        }
    };

    $scope.updateSetting = (type, successCallback, errorCallback) => {
        if (!$scope.document.isEditing) return;

        const expiryUnit = $scope.document.defaultExpiryDate.unit.id ?? null;
        const expiryAmount = $scope.document.defaultExpiryDate.amount;
        const activeDateParams = buildActiveDateRequest();

        const namesMapping = {
            title: () => ({val: $scope.document.title, paramName: "displayName"}),
            section: () => ({val: $scope.allowedSections.find(section => section.id === $scope.document.section.id).value, paramName: "sectionLabel"}),
            expires: () => ({val: $scope.document.expires, paramName: "expires"}),
            isMandatory: () => ({val: $scope.document.isMandatory, paramName: "isMandatory"}),
            isForCompliance: () => ({val: $scope.document.isForCompliance, paramName: "isForCompliance"}),
            isShownByDefault: () => ({val: $scope.document.isShownByDefault, paramName: "isForHr"}),
            complianceUploadFromMobile: () => ({val: $scope.document.complianceUploadFromMobile, paramName: "complianceUploadFromMobile"}),
            requireReVerification: () => ({val: $scope.document.requireReVerification, paramName: "requireReVerification"}),
            certifications: () => ({val: $scope.document.certifications.map(cert => $scope.certifications[cert.id].label), paramName: "certifications"}),
            activeDate: () => ({val: activeDateParams, paramName: "activeDate"}),
            expiryUnit: () => ({val: expiryUnit, paramName: "expiryUnit"}),
            expiryAmount: () => ({val: expiryAmount, paramName: "expiryAmount"}),
            expireOnlyOnNoHireDate: () => ({val: $scope.document.expireOnlyOnNoHireDate, paramName: "expireOnlyOnNoHireDate"}),
        };

        if (!namesMapping[type]) {
            console.error(`Unhandled type: "${type}"`);
        } else {
            const params = Object.fromEntries(
                Object.values(namesMapping).map((e) => [e().paramName, e().val])
            );

            const url = wildcard(
                "agencies/:agencyId/agency_members/:agencyMemberId/caregiver_document_type_agency_settings/:caregiverDocumentTypeId",
                $rootScope.agencyId,
                $rootScope.agencyMemberId,
                $scope.document.documentTypeId
            );

            const humanReadableField = camelCaseToSentenceCase(type);

            DatabaseApi.put(url, params).then(() => {
                updatePassedDocument();
                toaster.pop('success', humanReadableField, 'Updated Successfully');
                successCallback && successCallback();
            }).catch(() => {
                toaster.pop("error", humanReadableField, "Was not updated");
                errorCallback && errorCallback();
            });
        }
    };

    function camelCaseToSentenceCase(text) {
        const result = text.replace( /([A-Z])/g, " $1" );
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const dateToLocalDate = (date) => {
        if (date) {
            return LocalDate.from(nativeJs(moment(date)));
        }
        return undefined;
    }

    function refreshSwitchery() {
        $scope.refreshSwitcheryWorkaround = true;
        $timeout(() => $scope.refreshSwitcheryWorkaround = false, 5);
    }

    $scope.changeComplianceSetting = (settingName) => {
        if (!$scope.document.isEditing) return;
        
        const modal = mfModal.create({
            subject: "Are you sure?",
            message: "Changing this setting will affect the compliance status of existing caregiver items associated with this document type.",
            cancelLabel: "Cancel",
            confirmLabel: "Confirm",
            preventBackdropClose: true,
            onConfirm: () => {
                modal.setLoading(true);

                $scope.updateSetting(
                    settingName,
                    () => modal.close(),
                    () => {
                        $scope.document[settingName] = !$scope.document[settingName];
                        refreshSwitchery();
                    }
                );
            },
            onCancel: () => {
                $scope.document[settingName] = !$scope.document[settingName];
                refreshSwitchery();
            },
        });
    };

    $scope.createNewDocumentType = () => {
        $scope.isSubmitting = true;

        const complianceData = $scope.document.isForCompliance ? {
            activeDate: buildActiveDateRequest(),
            fields: $scope.document.fields.map(transformFieldToRequest),
        } : null;

        const expiryUnit = $scope.document.defaultExpiryDate.unit.id ?? null;
        const expiryAmount = $scope.document.defaultExpiryDate.amount;
        if (expiryUnit !== null && expiryAmount === null) {
            return toaster.pop("warning", "Can't set expiry unit without setting the amount");
        }

        const documentToSend = {
            expires: $scope.document.expires,
            isForCompliance: $scope.document.isForCompliance,
            mandatoryByDefault: $scope.document.isMandatory,
            shownByDefault: $scope.document.isShownByDefault,
            complianceUploadFromMobile: $scope.document.complianceUploadFromMobile,
            requireReVerification: $scope.document.requireReVerification,
            name: $scope.document.title,
            section: $scope.allowedSections.find(section => section.id === $scope.document.section.id).value,
            complianceData,
            certifications: $scope.document.certifications.map(cert => $scope.certifications[cert.id].label),
            expiryUnit: expiryUnit,
            expiryAmount: expiryAmount,
            groupType: null,
            isChildOnly: false,
            expireOnlyOnNoHireDate: $scope.document.expireOnlyOnNoHireDate,
        };

        const url = wildcard(
            "hr/agencies/:agencyId/agency_member/:agencyMemberId/document_types/custom",
            $rootScope.agencyId,
            $rootScope.agencyMemberId
        );

        DatabaseApi.post(url, documentToSend).then((_res) => {
            toaster.pop("success", "Document created successfully");
            $uibModalInstance.close("success");
        }, (_err) => {
            toaster.pop("error", "Something went wrong");
            $scope.isSubmitting = false;
        });
    }

    $scope.closeModal = function () {
        $uibModalInstance.close("ok");
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };

    $scope.levenshteinDocuments = () => {
        const similarDocumentsSet = new Set();
        allDocumentTypes.forEach(documentType => {
            if (generalUtils.levenshtein($scope.document.title.toLowerCase(), documentType.name.toLowerCase()) < 65 * documentType.name.length / 100) {
                similarDocumentsSet.add(documentType.name);
            }
        });
        return Array.from(similarDocumentsSet);
    }

    function setNotAllowedFollowups() {
        followupTrees.forEach(doc => {
            const branchIds = getBranchIds(doc);
            if (branchIds.has(passedDocument.documentTypeId)) {
                for (const id of branchIds) {
                    if (!passedDocument?.followupDocumentsIds.includes(id)) {
                        notAllowedFollowupIds.add(id);
                    }

                    if (id === doc.documentTypeId) {
                        // children are allowed
                        break;
                    }
                }
            }
        });
    }

    function getBranchIds(doc) {
        const result = new Set();

        result.add(doc.documentTypeId);

        doc.followupDocuments.forEach((followupDocument) => {
            const ids = getBranchIds(followupDocument);
            ids.forEach(id => result.add(id));
        });

        return result;
    }

    function filterDocumentTypeOptions() {
        if (!$scope.document.section || $scope.document.certifications.length === 0) {
            $scope.documentTypeFollowupOptions = [getDoNothingActionOption()];
        } else {
            const followups = filterFollowupSelectionByAllowed();
            filterFollowupSelectionBySectionAndCertifications(followups);
        }
    }

    // This filters the follow-up document selection
    // and shows only the documents in the same section,
    // and containing the same certifications.
    function filterFollowupSelectionBySectionAndCertifications(documentTypeOptions) {
        const documentTypeOptionIdsSet = new Set();

        $scope.documentTypeFollowupOptions = [
            getDoNothingActionOption(),
            ...documentTypeOptions
                .filter(option => {
                    const exists = !documentTypeOptionIdsSet.has(option.id);

                    documentTypeOptionIdsSet.add(option.id);

                    return exists;
                })
                .filter((option) => {
                const docType = idToDoc.get(option.id);

                const sectionLabel = $scope.allowedSections.find(
                    (section) => section.id === $scope.document.section.id
                ).value;

                return docType.sectionLabel === sectionLabel &&
                    docType.certifications.some((certification) =>
                    $scope.document.certifications.map(c => c.id).includes(certification.id)
                );
            })
        ];
    }

    function filterFollowupSelectionByAllowed() {
        return $scope.documentTypeOptions.filter(doc => {
            return !notAllowedFollowupIds.has(doc.id)
        });
    }

    function clearDefaultExpirySettings() {
        $scope.document.defaultExpiryDate.unit = {};
        $scope.document.defaultExpiryDate.amount = null;
        $scope.document.expireOnlyOnNoHireDate = false;
    }

    $scope.onIsForComplianceChange = () => {
        if (!$scope.document.isForCompliance) {
            clearDefaultExpirySettings();
        }

        $scope.updateSetting('isForCompliance');
    }

    $scope.defaultExpiryDateActionEvents = {
        onItemSelect: function (selectedOption) {
            clearDefaultExpirySettings();

            switch (selectedOption.id) {
                case "SET_TIME": {
                    $scope.document.defaultExpiryDate.unit = $scope.dateUnits[0];
                }
                break;

                case "NOTHING": {
                    $scope.updateSetting('expiryUnit');
                }
                break;

                case "NO_HIRE_DATE": {
                    $scope.document.expireOnlyOnNoHireDate = true;
                    $scope.updateSetting('expireOnlyOnNoHireDate');
                }
                break;

                default:
                    console.error(`Unhandled default expiry date action: "${selectedOption.id}"`);
            }
        }
    };

    $scope.documentExpiryUnitEvents = {
        onItemSelect: function () {
            if (!$scope.document.isEditing) return;
            const amount = $scope.document.defaultExpiryDate.amount;
            
            if (amount === null) return;
            $scope.updateSetting('expiryUnit');
        }
    };

    $scope.onDocumentExpiryAmountChange = (oldValue) => {
        if (!$scope.document.isEditing) return;
        const unit = $scope.document.defaultExpiryDate.unit;
        const amount = $scope.document.defaultExpiryDate.amount;

        if (amount === null && unit.id !== null) {
            $scope.document.defaultExpiryDate.amount = oldValue;
            return toaster.pop("warning", "Can't set an empty value with a time unit");
        }
        $scope.updateSetting('expiryAmount');
    };

    $scope.openSetParentResolvedModal = (field, choice) => {
        if (!$scope.document.isEditing) {
            return;
        }

        const modal = mfModal.create({
            subject: choice.resolveParent ? "Resolve item when follow-up is compliant?" : "Disable item resolve when follow-up is compliant?",
            message: choice.resolveParent
                ? "This will resolve the parent item when the follow-up is compliant, even when the parent is missing or not compliant."
                : "The follow-up item will not affect the parent item's compliance status.",
            cancelLabel: "Cancel",
            confirmLabel: choice.resolveParent ? "Turn on" : "Turn off",
            preventBackdropClose: true,
            onConfirm: () => {
                modal.setLoading(true);

                sendUpdateChoiceResolvesParentRequest(field, choice).then((_) => {
                    toaster.pop('success', "Resolve parent", 'Updated Successfully');
                    modal.close();
                }).catch(function (_) {
                    toaster.pop("error", "Resolve parent", "Was not updated");
                    choice.resolveParent = !choice.resolveParent;
                    modal.close();
                });
            },
            onCancel: () => choice.resolveParent = !choice.resolveParent,
        });
    };

    init();
};

export function filterValidDropdownFields() {
    return (fields) => fields.filter(field => field.type.name === "dropdown" && field.choices[0].value !== "");
};