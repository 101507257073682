import { Box, Button, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import AccountSupervisorCircleIcon from "../../../shared/icons/AccountSupervisorCircleIcon";
import InboundCallModalConferenceParticipant from "./InboundCallConferenceParticipant";

export default function InboundCallConferenceButton(props: {
  participants: Messages["TelephonyCallParticipantInfo"][];
}) {
  const activeParticipants = props.participants.filter((participant) => {
    switch (participant.status) {
      case "Answered":
      case "BargedIn":
      case "Joined":
      case "Initiated":
      case "Invited":
        return true;
      case "Declined":
      case "EavesDropped":
      case "Hungup":
      case "Left":
      case "Removed":
        return false;
    }
  });

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Button
          leftIcon={<AccountSupervisorCircleIcon fontSize="lg" />}
          rounded="full"
          size="sm"
          variant="outline"
        >
          Conference ({activeParticipants.length})
        </Button>
      </PopoverTrigger>
      <PopoverContent width="fit-content">
        <Box
          sx={{
            "> * + *": {
              borderTopWidth: 1,
              borderTopColor: "gray.50",
            },
          }}
        >
          {activeParticipants.map((participant) => (
            <InboundCallModalConferenceParticipant key={participant.id} participant={participant} />
          ))}
        </Box>
      </PopoverContent>
    </Popover>
  );
}
