import { Instant, LocalDate, LocalDateTime } from "@js-joda/core";
import { Row, SortingFn } from "@tanstack/react-table";

export type CustomSortingFns = typeof sortingFns;

export const defaultPaginationPageSizes = [10, 20, 30, 40, 50];
export const defaultPagination = { pageIndex: 0, pageSize: 10 };

export const sortingFns = {
  instant: withNullishChecks<Instant>((a, b) => a.compareTo(b)),
  localDateTime: withNullishChecks<LocalDateTime>((a, b) => a.compareTo(b)),
  localDate: withNullishChecks<LocalDate>((a, b) => a.compareTo(b)),
};

function withNullishChecks<T>(
  fn: (a: NonNullable<T>, b: NonNullable<T>) => number
): SortingFn<unknown> {
  return <TData>(rowA: Row<TData>, rowB: Row<TData>, columnId: string): number => {
    const a = rowA.getValue(columnId);
    const b = rowB.getValue(columnId);

    if (a === null || a === undefined) {
      return b === null || b === undefined ? 0 : -1;
    }

    if (b === null || b === undefined) {
      return 1;
    }

    return fn(a as NonNullable<T>, b as NonNullable<T>);
  };
}
