import { z } from "zod";
import { withPathParams } from "../../../../shared/consts/hoc";
import { PayrollBatchId } from "../../../../shared/schema/schema";
import PayrollVerificationPage from "./PayrollVerificationPage";

const pathParamsSchema = z.object({
  id: z.string().transform((x) => PayrollBatchId.parse(parseInt(x))),
});

type PayrollVerificationRouteProps = {
  pathParams: z.infer<typeof pathParamsSchema>;
};

const PayrollVerificationRoute = (props: PayrollVerificationRouteProps) => {
  return <PayrollVerificationPage payrollBatchId={props.pathParams.id} />;
};

export default withPathParams(PayrollVerificationRoute, pathParamsSchema);
