import { reactBridge } from "@medflyt/webapp-react/src/core";
import { GlobalWorkflowHint } from "@medflyt/webapp-react/src/shared/hooks/useGlobalWorkflowRunner";
import { OpenSmsSenderData } from "webapp-react/src/shared/components/ReactHost/SmsSenderListener";
import { AngularJSPayload } from "@medflyt/webapp-react/src/core/react-bridge";
import { NoteId } from "@medflyt/webapp-react/src/shared/schema/schema";
import { TicketPopupOptions } from "@medflyt/webapp-react/src/modules/communication/components/CommCenterChatWrapper";
import { NewTicketPopupProps } from "webapp-react/src/modules/communication/components/NewTicketPopup";
import { EntityCommunicationsProps } from "@medflyt/webapp-react/src/modules/communication/components/entity-communications/EntityCommunications";

const createAngularToReactEvents = () => {
  return {
    workflows: {
      openRunner: () => {
        return reactBridge.dispatch("GLOBAL_WORKFLOW_RUNNER:OPEN", undefined);
      },
      setHints: (hints: GlobalWorkflowHint[]) => {
        return reactBridge.dispatch("GLOBAL_WORKFLOW_RUNNER:SET_HINTS", { hints });
      },
    },
    sms: {
      openSender: (data: OpenSmsSenderData) => {
        return reactBridge.dispatch("OPEN_SMS_SENDER", data);
      },
    },
    note: {
      openEditNoteModal: (noteId: NoteId) => {
        return reactBridge.dispatch("EDIT_NOTE_MODAL:OPEN", { id: noteId });
      },
    },
    commCenter: {
      openTicketPopup: (params: TicketPopupOptions) => {
        return reactBridge.dispatch("COMM_CENTER:OPEN_TICKET_POPUP", params);
      },
      openNewTicketPopup: (params: NewTicketPopupProps) => {
        return reactBridge.dispatch("COMM_CENTER:OPEN_NEW_TICKET_POPUP", params);
      },
      openEntityCommunications: (params: EntityCommunicationsProps) => {
        return reactBridge.dispatch("COMM_CENTER:OPEN_ENTITY_COMMUNICATIONS", params);
      },
    },
    callCenter: {
      openDialer: (params: { predefinedPhoneNumber?: string } = {}) => {
        return reactBridge.dispatch("CALL_CENTER:OPEN_DIALER_POPUP", params);
      },
      closeDialer: () => {
        return reactBridge.dispatch("CALL_CENTER:CLOSE_DIALER_POPUP", undefined);
      },
      openRingingCalls: () => {
        return reactBridge.dispatch("CALL_CENTER:OPEN_RINGING_CALLS", undefined);
      },
    },
    customAddress: {
      openModal: (props: AngularJSPayload["OPEN_CUSTOMIZE_ADDRESS_MODAL"]) => {
        return reactBridge.dispatch("CUSTOM_ADDRESS:OPEN_MODAL", props);
      },
      onChange: (props: AngularJSPayload["CUSTOM_ADDRESS_CHANGE"]) => {
        return reactBridge.dispatch("CUSTOM_ADDRESS:ON_CHANGE", props);
      },
    },
  };
};

declare module "angular" {
  interface IRootScopeService {
    react: ReturnType<typeof createAngularToReactEvents>;
  }
}

//! @ngInject
export function angularToReact($rootScope: angular.IRootScopeService) {
  $rootScope.react = createAngularToReactEvents();
}
