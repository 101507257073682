import React from "react";

const ringtone = new Audio("/admin/sounds/ringtone.mp3");

export default function useIncomingCallRingtone(params: { enabled: boolean }) {
  React.useEffect(() => {
    if (!params.enabled) {
      return;
    }

    ringtone.loop = true;
    ringtone.controls = false;
    ringtone.currentTime = 0;
    ringtone
      .play()
      .catch(() => console.info("Ringtone won't play until user interacts with the page"));

    return () => {
      ringtone.pause();
    };
  }, [params.enabled]);
}
