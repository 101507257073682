import { useQuery } from "@tanstack/react-query";
import EntityCard from "../../../../../../shared/components/EntityCard";
import useApi from "../../../../../../shared/hooks/useApi";
import { useEntityLink } from "../../../../../../shared/hooks/useEntityLink";
import { queryKeys } from "../../../../../../shared/query-keys";
import { PatientId } from "../../../../../../shared/schema/schema";
import { getFullName } from "../../../../../../shared/utils/get-full-name";
import { CardButton } from "./CardButton";
import { LoadingEntityCard } from "./LoadingEntityCard";

export function PatientLinkCard(props: {
  patientId: PatientId;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}) {
  const { api } = useApi();
  const patientQuery = useQuery({
    queryKey: queryKeys.patient.get(props.patientId),
    queryFn: () => {
      return api.get("/agencies/:agencyId/patients/:patientId", {
        path: { patientId: props.patientId },
      });
    },
  });
  const { open } = useEntityLink({
    onOpen: props.onOpenAngularModal,
    onClose: props.onCloseAngularModal,
    redirectModal: false,
  });

  if (patientQuery.isPending) {
    return <LoadingEntityCard />;
  }

  if (patientQuery.isError) {
    return null;
  }

  const patient = patientQuery.data;

  if (!patient) {
    return null;
  }

  const entity = {
    type: "Patient" as const,
    id: patient.id,
    displayId: patient.displayId,
    gender: patient.gender,
    fullName: getFullName(patient),
    status: patient.status,
    contactDetails: null,
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => open({ entity, event });

  return (
    <CardButton onClick={handleClick}>
      <EntityCard entity={entity} />
    </CardButton>
  );
}
