import { createIcon } from "@chakra-ui/react";

const MobileVoiceIcon = createIcon({
  displayName: "MobileVoiceIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m8 17.01l.01-.011M8 5H3.6a.6.6 0 0 0-.6.6v14.8a.6.6 0 0 0 .6.6h8.8a.6.6 0 0 0 .6-.6V16m3-13v10m-3-8v6m9-4v2M10 7v2m9-5v8"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  ),
});

export default MobileVoiceIcon;
