import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputProps,
  SystemStyleObject,
  Text,
} from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import { MaskGenerator, MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";
import { Messages } from "../../../../../core/api";
import SingleDatePicker from "../../../../../shared/components/DatePicker/SingleDatePicker";
import SingleDateTimePicker from "../../../../../shared/components/DatePicker/SingleDateTimePicker";
import PhoneNumberInput from "../../../../../shared/components/PhoneNumberInput";
import { IntakeTrackStepFieldValueId } from "../../../../../shared/schema/schema";
import PatientIntakeFlowStepFieldAddress from "./PatientIntakeFlowStepFieldAddress";
import { isMedicaidValid } from "../../../../../shared/consts";

type AllowedFields =
  | Messages["IntakeTrackFieldNS.PhoneNumber"]
  | Messages["IntakeTrackFieldNS.Date"]
  | Messages["IntakeTrackFieldNS.String"]
  | Messages["IntakeTrackFieldNS.Number"]
  | Messages["IntakeTrackFieldNS.Options"]
  | Messages["IntakeTrackFieldNS.DateTime"]
  | Messages["IntakeTrackFieldNS.Multiselect"]
  | Messages["IntakeTrackFieldNS.AddressComponents"]
  | Messages["IntakeTrackFieldNS.DoublePrecision"]
  | Messages["IntakeTrackFieldNS.MedicaidId"]
  | Messages["IntakeTrackFieldNS.SSN"]
  | Messages["IntakeTrackFieldNS.Checkbox"];

export default function PatientIntakeFlowStepField<TField extends AllowedFields>(props: {
  field: TField;
  value: Messages["IntakeTrackStepFieldWithNewAnswer"] | undefined;
  onChange: (field: Messages["IntakeTrackStepFieldWithNewAnswer"]) => void;
}) {
  const valueWithFallback = String(props.value?.value ?? props.field.value?.value ?? "");

  const handleChange = (value: any) => {
    props.onChange({ id: props.field.id, type: props.field.type, value });
  };

  const handleChangeMultiselect = (value: IntakeTrackStepFieldValueId) => {
    const values = (props.value?.value ??
      props.field.value?.value ??
      []) as IntakeTrackStepFieldValueId[];
    if (values.includes(value)) {
      return handleChange(values.filter((x) => x !== value));
    }
    return handleChange([...values, value]);
  };

  switch (props.field.type) {
    case "date":
      return (
        <SingleDatePicker
          inputProps={{ sx: baseInputSx }}
          value={valueWithFallback}
          onChange={handleChange}
        />
      );
    case "number":
      return (
        <NumberInput
          value={valueWithFallback}
          onChange={(e) => {
            const num = parseInt(e.target.value);
            if (isNaN(num)) {
              return handleChange(0);
            }
            return handleChange(num);
          }}
        />
      );
    case "string":
      return <TextInput value={valueWithFallback} onChange={(e) => handleChange(e.target.value)} />;
    case "medicaid_id":
      return (
        <>
          <MedicaidIdInput
            value={valueWithFallback}
            onChange={(e) => {
              handleChange(e as any as string);
            }}
          />
          {valueWithFallback !== "" && !isMedicaidValid(valueWithFallback) && (
            <Text color="red.500">Invalid Medicaid ID</Text>
          )}
        </>
      );
    case "ssn":
      return (
        <SSNInput
          value={valueWithFallback}
          onChange={(e) => {
            handleChange(e as any as string);
          }}
        />
      );
    case "options":
      return (
        <Flex direction="column" gap={2}>
          {props.field.options?.map((option) => (
            <Button
              key={option.id}
              data-active={option.id === (props.value?.value ?? props.field.value?.value)}
              sx={baseInputSx}
              variant="unstyled"
              onClick={() => handleChange(option.id)}
            >
              {option.value}
            </Button>
          ))}
        </Flex>
      );
    case "multiselect": {
      const values = (props.value?.value ??
        props.field.value?.value ??
        []) as IntakeTrackStepFieldValueId[];
      return (
        <Flex direction="column" gap={2}>
          {props.field.options?.map((option) => (
            <Button
              key={option.id}
              data-active={values?.includes(option.id)}
              sx={baseInputSx}
              variant="unstyled"
              onClick={() => handleChangeMultiselect(option.id)}
            >
              {option.value}
            </Button>
          ))}
        </Flex>
      );
    }
    case "address_components":
      return (
        <PatientIntakeFlowStepFieldAddress
          value={props.field.value?.value ?? null}
          onChange={handleChange}
        />
      );
    case "phonenumber":
      return (
        <PhoneNumberInput sx={baseInputSx} value={valueWithFallback} onChange={handleChange} />
      );
    case "datetime": {
      const value = (props.value?.value ??
        props.field.value?.value ??
        null) as LocalDateTime | null;
      return (
        <SingleDateTimePicker
          inputProps={{ sx: baseInputSx }}
          selected={value}
          onChange={handleChange}
        />
      );
    }
    case "double_precision": {
      return (
        <NumberInput
          value={valueWithFallback}
          onChange={(e) => {
            const num = parseFloat(e.target.value);
            if (isNaN(num)) {
              return handleChange(0);
            }
            return handleChange(num);
          }}
        />
      );
    }
    case "checkbox": {
      return (
        <Checkbox
          isChecked={valueWithFallback === "true"}
          isIndeterminate={valueWithFallback === ""}
          onChange={(e) => handleChange(e.target.checked)}
        >
          {props.field.title}
        </Checkbox>
      );
    }
  }
}

const baseInputSx: SystemStyleObject = {
  borderWidth: 2,
  borderColor: "gray.200",
  padding: 4,
  fontSize: "xl",
  color: "black",
  width: "full",
  height: "auto",
  fontWeight: "500",
  transition: "none",
  _hover: {
    borderColor: "gray.300",
  },
  _focus: {
    borderColor: "blue.500",
  },
  "&[data-active='true']": {
    borderColor: "blue.500",
    bg: "blue.50",
  },
};

const NumberInput = (props: InputProps) => <Input sx={baseInputSx} type="number" {...props} />;

const TextInput = (props: InputProps) => (
  <Input placeholder="Free text..." sx={baseInputSx} {...props} />
);

const medicaidIdMaskGenerator: MaskGenerator = {
  rules: new Map([["C", /[A-Za-z\d]/]]),
  generateMask: () => "CCCCCCCC",
  transform: (val) => val.toUpperCase(),
};

const MedicaidIdInput = (props: InputProps) => (
  <Input
    as={MaskedInput}
    maskGenerator={medicaidIdMaskGenerator}
    placeholder="AB12345C"
    sx={baseInputSx}
    {...props}
  />
);

const ssnMaskGenerator: MaskGenerator = createDefaultMaskGenerator("999-99-9999");

const SSNInput = (props: InputProps) => (
  <Input
    as={MaskedInput}
    maskGenerator={ssnMaskGenerator}
    placeholder="111-22-3333"
    sx={baseInputSx}
    {...props}
  />
);
