export abstract class PhoneProviderError extends Error {}

export class PhoneProviderNotInitializedError extends PhoneProviderError {
  constructor() {
    super("Phone provider not initialized");
    this.name = "PhoneProviderNotInitializedError";
  }
}

export class PhoneProviderNotRegisteredError extends PhoneProviderError {
  constructor() {
    super("Phone provider not registered");
    this.name = "PhoneProviderNotRegisteredError";
  }
}

export class AlreadyInACallOnAnotherTabError extends PhoneProviderError {
  constructor(tabId: string) {
    super(`Already in a call on another tab: ${tabId}`);
    this.name = "AlreadyInACallOnAnotherTabError";
  }
}

export class AlreadyInACallError extends PhoneProviderError {
  constructor() {
    super(`Already in a call`);
    this.name = "AlreadyInACallError";
  }
}

export class PhoneProviderLoginTimeoutError extends PhoneProviderError {
  constructor() {
    super("Phone provider login timeout");
    this.name = "PhoneProviderLoginTimeoutError";
  }
}
