import { convert, Instant, LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";
import { AvailableTimeZones, isXDaysAfter, toLocalDateTime } from "./date-utils";

function createFormatter() {
  const dateTimeFormatter = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  const dateOnlyFormatter = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const timeOnlyForatter = Intl.DateTimeFormat("en-US", { timeStyle: "short" });

  function toDateTime(date: LocalDateTime | Instant, options?: { timezone?: AvailableTimeZones }) {
    const localDate = toLocalDateTime(date, options);
    return dateTimeFormatter.format(convert(localDate).toDate());
  }

  /**
   * e.g:
   * 05/30/2023, 09:00 AM - 10:00 AM
   * 05/30/2023, 11:00 AM - 13:00 PM
   * 05/30/2023, 11:00 PM - 05/30/2023 01:00 AM (Overnight)
   */
  function toDateTimeRange(
    lower: LocalDateTime,
    greater: LocalDateTime,
    options?: { overnight?: boolean; timezone?: AvailableTimeZones }
  ) {
    const date1Str = toDateTime(lower, { timezone: options?.timezone });
    const date2Str = toDateTime(greater, { timezone: options?.timezone });
    const isOvernight = isXDaysAfter(lower, greater, 1) && options?.overnight === true;
    const isSameDay = lower.toLocalDate().isEqual(greater.toLocalDate());
    const date2StrWithoutDate = date2Str.split(", ")[1];

    return isOvernight
      ? `${date1Str} - ${date2StrWithoutDate} (Overnight)`
      : isSameDay
      ? `${date1Str} - ${date2StrWithoutDate}`
      : `${date1Str} - ${date2Str}`;
  }

  function toDate(
    date: LocalDateTime | Instant | LocalDate,
    options?: { timezone?: AvailableTimeZones }
  ) {
    const localDate = toLocalDateTime(date, options);
    return dateOnlyFormatter.format(convert(localDate).toDate());
  }

  function toTime(date: LocalDateTime | Instant | LocalTime) {
    const localDate = toLocalDateTime(
      date instanceof LocalTime ? LocalDateTime.of(LocalDate.now(), date) : date
    );
    return timeOnlyForatter.format(convert(localDate).toDate());
  }

  function toDateOrDateTime(date: LocalDateTime | Instant) {
    const localDate = toLocalDateTime(date);
    const now = LocalDateTime.now();
    if (
      localDate.year() === now.year() &&
      localDate.month() === now.month() &&
      localDate.dayOfMonth() === now.dayOfMonth()
    ) {
      return toTime(localDate);
    }

    return toDateTime(localDate);
  }

  return {
    toDateTime,
    toDate,
    toTime,
    toDateOrDateTime,
    toDateTimeRange,
  };
}

export const dateFormatter = createFormatter();
