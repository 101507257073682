import { atom, useAtom } from "jotai";
import { EntityCommunicationsProps } from "../../modules/communication/components/entity-communications/EntityCommunications";

const entityCommunicationsAtom = atom<EntityCommunicationsProps | null>(null);

export function useEntityCommunications() {
  const [state, setState] = useAtom(entityCommunicationsAtom);

  const onOpen = (params: EntityCommunicationsProps) => setState(params);
  const onClose = () => setState(null);

  return { state, onOpen, onClose };
}
