import { createIcon } from "@chakra-ui/react";

const TableExportIcon = createIcon({
  displayName: "TableExportIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12.5 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v7.5M3 10h18M10 3v18m6-2h6m-3-3l3 3l-3 3"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  ),
});

export default TableExportIcon;
