import React from "react";
import { NodeChange, XYPosition, applyNodeChanges, useReactFlow } from "reactflow";
import { IntakeTrackNoteId, IntakeTrackStepId } from "../../../../../shared/schema/schema";
import { ElkNode, ElkNodeData, parseIntakeFlowGraphId } from "../flow-settings-visualizer.utils";

interface Props {
  nodes: ElkNode[];
  onChangePositions: (params: {
    stepsMap: Map<IntakeTrackStepId, XYPosition>;
    notesMap: Map<IntakeTrackNoteId, XYPosition>;
  }) => void;
  onSelectNode: (nodeId: string) => void;
  onUnselectNode: (nodeId: string) => void;
  onChangeNodes: (nodes: ElkNode[]) => void;
}

export default function useNodeChange(props: Props) {
  const { getNode } = useReactFlow<ElkNodeData>();
  const handleChangePosition = React.useCallback(
    (changes: NodeChange[]) => {
      const stepsPositions = new Map<IntakeTrackStepId, XYPosition>();
      const notesPositions = new Map<IntakeTrackNoteId, XYPosition>();

      for (const node of changes) {
        if (node.type === "position" && node.position !== undefined) {
          const elkNode = getNode(node.id);
          if (elkNode === undefined) {
            continue;
          }
          const nodeId = parseIntakeFlowGraphId(elkNode.data.id);
          switch (nodeId.type) {
            case "step": {
              stepsPositions.set(nodeId.id, node.position);
              break;
            }
            case "note": {
              notesPositions.set(nodeId.id, node.position);
              break;
            }
          }
        }
      }

      props.onChangePositions.call(null, {
        notesMap: notesPositions,
        stepsMap: stepsPositions,
      });
    },
    [getNode, props.onChangePositions]
  );

  const handleChangeSelection = React.useCallback(
    (changes: NodeChange[]) => {
      const selectEvents = changes.filter(
        (change): change is NodeChange & { type: "select" } =>
          change.type === "select" && change.selected
      );
      const unSelectEvents = changes.filter(
        (change): change is NodeChange & { type: "select" } =>
          change.type === "select" && !change.selected
      );

      if (selectEvents.length > 0) {
        props.onSelectNode.call(null, selectEvents[0].id);
      } else if (unSelectEvents.length > 0) {
        props.onUnselectNode.call(null, unSelectEvents[0].id);
      }
    },
    [props.onSelectNode, props.onUnselectNode]
  );

  return React.useCallback(
    (changes: NodeChange[]) => {
      handleChangePosition(changes);
      handleChangeSelection(changes);

      const nodes: ElkNode[] = applyNodeChanges(changes, props.nodes).map((n) => ({
        ...n,
        type: "elk",
      }));

      props.onChangeNodes.call(null, nodes);
    },
    [handleChangePosition, handleChangeSelection, props.nodes, props.onChangeNodes]
  );
}
