import { createIcon } from "@chakra-ui/react";

const VolumeUpIcon = createIcon({
  displayName: "VolumeUpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M14 20.725v-2.05q2.25-.65 3.625-2.5t1.375-4.2q0-2.35-1.375-4.2T14 5.275v-2.05q3.1.7 5.05 3.138T21 11.975q0 3.175-1.95 5.613T14 20.725ZM3 15V9h4l5-5v16l-5-5H3Zm11 1V7.95q1.175.55 1.838 1.65T16.5 12q0 1.275-.663 2.363T14 16Z"
      fill="currentColor"
    />
  ),
});

export default VolumeUpIcon;
