import { Client as PlivoClient } from "plivo-browser-sdk/client";

export function getPlivoClientUtils(client: PlivoClient) {
  let isLoggingIn: boolean = false;

  const waitForLogin = (credentials: { username: string; password: string }) =>
    new Promise<void>((resolve, reject) => {
      const onLogin = () => {
        isLoggingIn = false;
        clearTimeout(timeout);
        resolve();
      };

      const onLoginFailed = (error: unknown) => {
        isLoggingIn = false;
        clearTimeout(timeout);
        reject(error);
      };

      const onTimeout = () => {
        isLoggingIn = false;
        client.off("onLogin", onLogin);
        client.off("onLoginFailed", onLoginFailed);
        reject(new Error("Login timeout"));
      };

      const timeout = setTimeout(onTimeout, 5000);

      client.once("onLogin", onLogin);
      client.once("onLoginFailed", onLoginFailed);

      client.login(credentials.username, credentials.password);
    });

  return {
    waitForLogin: waitForLogin,
    isLoggingIn: isLoggingIn,
    login: (credentials: { username: string; password: string }) => {
      const isConnected = client.isConnected();
      const isConnecting = client.isConnecting();
      const isRegistered = client.isRegistered();

      if (isLoggingIn || isConnected || isConnecting) {
        console.warn("[plivo client] avoided login", {
          isLoggingIn,
          isConnected,
          isConnecting,
          isRegistered,
        });

        return Promise.resolve();
      }

      isLoggingIn = true;

      return waitForLogin(credentials);
    },
  };
}
