import { Button, Center, Flex, Text } from "@chakra-ui/react";

export default function RingingCallsContentError(props: { error: unknown; onRefresh: () => void }) {
  return (
    <Center h="full">
      <Flex align="center" flexDir="column" justify="center">
        <Text>Error while loading ringing calls list</Text>
        <Text>{`${props.error}`}</Text>
        <Button mt={10} onClick={props.onRefresh}>
          Refresh
        </Button>
      </Flex>
    </Center>
  );
}
