import { LocalDate, nativeJs } from "@js-joda/core";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { getBase64 } from "../../../../scripts/utils/getBase64"
import download from "downloadjs";

export const caregiverCompliance = {
    templateUrl: "admin/modules/compliance/components/caregiver-compliance/caregiver-compliance.component.html",
    bindings: {
        caregiverId: '<',
        caregiverFullName: '<'
    },
    //! @ngInject
    controller: function ($scope, $rootScope, DatabaseApi, NgTableParams, complianceConsts, toaster, $uibModal, base64ImageConsts, $q, combinePdf, generalUtils, wildcard, $timeout, complianceService, mfModal) {
        const fieldTypesMap = complianceConsts.fieldTypesMap;

        $scope.caregiverComplianceItems = [];

        $scope.docBNotNeeded = complianceConsts.docBNotNeeded;
        const customComplianceItemsMap = {};
        complianceConsts.customComplianceItems.forEach(function (item) {
            customComplianceItemsMap[item.name] = item.options;
        });

        $scope.filter = {
            $: "",
            statuses: [],
        };

        $scope.statuses = [
            "Compliant",
            "Not Compliant",
            "Resolved",
            "Not due yet",
            "Missing",
            "Pending Uploads",
        ].map(status => ({id: status, label: status}));

        $scope.sectionItemsCount = {};

        $scope.baseCols = [
            {title: "Effective Date"},
            {title: "Expiration Date"},
            {title: "View Upload"},
            {title: "Compliant"},
        ];

        $scope.baseColsAppending = [
            {title: "Uploaded At"},
            {title: "Uploaded By"},
            {title: "Actions"},
        ];

        const itemFields = {};

        const inputDateTypes = ['sentOutDate', 'receivedDate',
            'completionDate',
            'expiryDate',
            'startDate',
            'endDate',
            'certificationDate',
            'verificationDate'
        ];

        $scope.caregiverUploadTabs = {
          records: {
            id: 0,
            name: "Records",
            src: "admin/views/caregiver-compliance-upload-records-tab.html"
          },
          pending: {
            id: 1,
            name: "Pending Review",
            src: "admin/views/caregiver-compliance-upload-pending-tab.html"
          },
          history: {
            id: 2,
            name: "History",
            src: "admin/views/caregiver-compliance-upload-history-tab.html"
          }
        };

        $scope.defaultDateOptions = {
            startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
        }

        const calculateDayToExpireAsText = (expiryDate) => {
            const daysDiff = complianceService.calculateDaysDiffFromToday(expiryDate);
            return complianceService.calculateDayToExpireAsText(daysDiff);
        }

        $scope.dateToLocalDate = (date) => {
            if (date) {
                return LocalDate.from(nativeJs(moment(date)));
            }
            return undefined;
        }

        function transformFieldBeforeRequest(field) {
            let value = null;

            switch (field.type) {
                case "Text":
                    value = field.model || null;
                    break;

                case "Date":
                    value = field.model.value ? $scope.dateToLocalDate(field.model.value) : null;
                    break;

                case "Dropdown":
                    value = field.possibleValues.find(pv => pv.id === parseInt(field.model));
                    break;

                default:
                    console.error(`Unhandled type: "${field.type}"`);
            }

            return {
                ...field,
                value
            };
        }

        $scope.updateComplianceItemAnswer = (item, field) => {
            const body = {};

            switch (field) {
                case "expiryDate":
                    body.expiryDate = LocalDate.from(nativeJs(moment(item[field])));
                    break;

                case "isCompliant":
                    body.isCompliant = Boolean(item[field]);
                    break;

                default:
                    body.fields = [transformFieldBeforeRequest(field)];
                    break;
            }

            const url = wildcard(
                "hr/agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/compliance_items/:complianceItemId",
                $rootScope.agencyId,
                $rootScope.agencyMemberId,
                $scope.$ctrl.caregiverId,
                item.id,
            );

            DatabaseApi.put(url, body)
                .then(function (_) {
                    toaster.pop('success', "Compliance item answer Updated");
                }, function (_) {
                    toaster.pop('error', 'Something went wrong', 'Could not update instance');
                });
        };

        $scope.updateComplianceItemStatus = (item) => {
            const instance = item.instances.length > 0 ? item.instances[0] : null;
            if (instance === null) {
                item.isCompliant = !item.isCompliant;
                toaster.pop('warning', 'Something went wrong', 'Compliance item has no records');
                return;
            }

            instance.isCompliant = !instance.isCompliant;
            $scope.updateComplianceItemAnswer(instance, 'isCompliant');
        };

        $scope.setComplianceSections = (items) => {
            $scope.complianceSections = [...new Set(items.map(i => i.sectionLabel))];
            $scope.complianceSections.sort();
        };

        $scope.sectionLabelToDisplayText = (sectionLabel) => {
            if (sectionLabel.indexOf('-') === -1) {
                return "";
            }            
            return generalUtils.capitalize(sectionLabel.split('-')[0]) + ` (${$scope.sectionItemsCount[sectionLabel]})`;
        }

        $scope.setSectionLabelTab = (sectionLabelTab) => {
            if ($scope.sectionLabelTab !== sectionLabelTab) {
                $scope.sectionLabelTab = sectionLabelTab;
                $scope.resetPage = true;
                initComplianceTable($scope.caregiverComplianceItems);
            }
        }

        $scope.getDropdownTextValue = (field) => field.possibleValues.find(v => v.id === parseInt(field.model, 10))?.text;

        const getComplianceItems = async () => {
            const deferred = $q.defer();
            $scope.isLoading = true;

            DatabaseApi.get('hr/agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.$ctrl.caregiverId + '/compliance_items').then(function (res) {
                $scope.setComplianceSections(res.data.caregiverComplianceItems);
                deferred.resolve(res.data.caregiverComplianceItems);
            }).catch(function (_) {
                toaster.pop("error", "Failed to load compliance data");
                deferred.reject(null);
            })
            .finally(function () {
                $timeout(() => $scope.isLoading = false);
            });

            return deferred.promise;
        }

        $scope.applyCaregiverComplianceGlobalSearch = () => {
          if ($scope.caregiverComplianceTable) {
            angular.extend(
                $scope.caregiverComplianceTable.filter(),
                $scope.filter
            );
          } else {
            console.log("no table");
          }
        }

        function stringToDate(str) {
            if (str) {
                const d = LocalDate.parse(str);
                return new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
            }

            return null;
        }

        const initComplianceTable = (items) => {
            $scope.sectionItemsCount = complianceService.getCountGroupBy(items, "sectionLabel");
            
            const oldTotal = $scope.caregiverComplianceTable?.total?.() || 0;

            if (!$scope.sectionLabelTab) {
                $scope.sectionLabelTab = $scope.complianceSections[0];
            }

            items.forEach(function (item) {
                complianceService.setItemFieldsModels(item.fields);
                itemFields[item.caregiverDocumentTypeId] = item.fields;
                const latestInstance = item.instances.length === 0 ? {} : item.instances[0];

                // a record with fictive type is not a real record.
                // fictiveType=due_date: used for setting due date.
                item.instances = item.instances.filter(i => !i.fictiveType);

                item.hasPreviewRecord = Boolean(latestInstance.caregiverDocumentUploadId);

                if (item.instances.length > 0) {
                    latestInstance.expirationStatus = latestInstance.expiryDate && moment().isAfter(moment(latestInstance.expiryDate));

                    $scope.updateLatestInstance(item, latestInstance);

                    item.cols = [
                        ...$scope.baseCols,
                        ...item.fields.map(field => ({title: field.name})),
                        ...$scope.baseColsAppending,
                    ];
                }

                item.isCompliant = item.groupType ? item.isCompliant : Boolean(item.latestInstance?.isCompliant);

                item.options = customComplianceItemsMap[item.name];

                const requiredFields = Object.keys(item).filter(field => {
                    if (fieldTypesMap[field] && item[field] === true) {
                        return field;
                    }
                });
                item.listOfFields = requiredFields.map(field => fieldTypesMap[field]).join(', ');
                item.modalFields = requiredFields.map(field => {
                    const fieldName = field.replace("requires", "");
                    return fieldName[0].toLowerCase() + fieldName.substr(1);
                });

                item.instances.forEach(instance => {
                    complianceService.setItemFieldsModels(instance.fields);
                });

                item.expiryData = complianceService.getExpiryOrDueDateParams(
                    item.status,
                    item.dueDate,
                    latestInstance?.expiryDate,
                    item.requireReVerification
                ).data;
            });

            const options = {
                count: 100,
                sorting: { name: "asc" }
            };

            let page = false;
            if (!$scope.resetPage) {
                if ($scope.caregiverComplianceTable) {
                    page = $scope.caregiverComplianceTable.page();
                }
            } else {
                $scope.resetPage = undefined;
            }

            $scope.caregiverComplianceTable = new NgTableParams(options, {
                counts: [],
                dataset: items,
                getData: (params) => complianceService.getCaregiverComplianceData(
                    params,
                    items,
                    "caregiverDocumentTypeId",
                    "name",
                    $scope.sectionLabelTab,
                    $scope.sectionItemsCount
                ),
            });

            $scope.applyCaregiverComplianceGlobalSearch();

            if (page && oldTotal === $scope.caregiverComplianceTable.total()) $scope.caregiverComplianceTable.page(page);
        }

        function transformHistoryStatusChanges(records) {
            const result = [];
            let lastDate;

            for (let i = records.length - 1; i >= 0; i --) {
                const record = records[i];

                result.push({
                    compliant: record.compliant,
                    fromDate: record.effectiveDate,
                    toDate: lastDate ? lastDate.subtract(1, 'days').format('YYYY-MM-DD') : null,
                });

                lastDate = moment(record.effectiveDate);
            }

            return result;
        }

        async function loadRecords(activeCaregiverDocumentTypeId, targetTab) {
            const complianceItems = await getComplianceItems();
            if (complianceItems !== null) {
                if (activeCaregiverDocumentTypeId) {
                    // This sets active document type
                    // in the UI, so it will load opened.
                    const complianceItem = complianceItems.find(complianceItem => complianceItem.caregiverDocumentTypeId === activeCaregiverDocumentTypeId);
                    if (complianceItem) {
                        complianceItem.activeUploadTab = $scope.caregiverUploadTabs[targetTab].id;
                    }
                }
                $scope.caregiverComplianceItems = complianceItems;
                initComplianceTable(complianceItems);
            }
        }

        $scope.loadRecords = loadRecords;

        $scope.toggleUploadRow = (row, tabIndex) => {
            if (!row.groupType) {
                row.activeUploadTab = row.activeUploadTab === null
                    ? tabIndex
                    : null;
            }
            // row.activeUploadTab = row.activeUploadTab === null
            // ? tabIndex
            // : null;
        };

        function openGroupItemModal(item) {
            const modalInstance = $uibModal.open({
                templateUrl: 'admin/modules/compliance/views/caregiver-compliance-group-modal.html',
                controller: 'caregiverComplianceGroupModalCtrl',
                size: 'lg',
                windowClass: 'top-top',
                resolve: {
                    parentItem: () => item,
                    caregiverId: () => $scope.$ctrl.caregiverId
                }
              });

              modalInstance.result.then(async (res) => {
                await loadRecords(null, "records");
              }, () => {});
        }

        $scope.addNewComplianceItem = (item, type, caregiverDocumentUploadId) => {
            if (item.groupType) {
                openGroupItemModal(item);
                return;
            }
            
            const caregiverDocumentTypeId = item.caregiverDocumentTypeId;
            const newScope = $scope.$new();

            newScope.item = item;
            newScope.caregiverId = $scope.$ctrl.caregiverId;
            newScope.itemId = caregiverDocumentTypeId;
            newScope.type = type;
            newScope.documentName = item.name;
            if (caregiverDocumentUploadId) {
                newScope.caregiverDocumentUploadId = caregiverDocumentUploadId;
            }

            if (item.defaultExpirySettings) {
                newScope.defaultExpirySettings = item.defaultExpirySettings;
            }

            newScope.fields = itemFields[caregiverDocumentTypeId];

            const modalInstance = $uibModal.open({
                templateUrl: 'admin/modules/compliance/views/caregiver-compliance-instance-modal.html',
                controller: 'caregiverComplianceInstanceModalCtrl',
                size: 'lg',
                scope: newScope,
                windowClass: "compliance-instance-modal"
            });

            modalInstance.result.then(async () => {
                await loadRecords(caregiverDocumentTypeId, "records");
            }, () => {});
        }

        $scope.viewComplianceInstanceDocument = function (document) {
            let newScope = $scope.$new();
            newScope.document = document;
            newScope.url = 'agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.$ctrl.caregiverId +
                '/compliance_instances/' + document.id + '/preview';

            $uibModal.open({
                templateUrl: 'admin/views/image-viewer-modal.html',
                size: 'lg',
                controller: 'imageViewerModalCtrl',
                scope: newScope
            });
        }

        $scope.uploadComplianceFileToInstance = (complianceInstance) => {
            const complianceInstanceId = complianceInstance.id;
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                keyboard: false,
                templateUrl: 'admin/modules/compliance/views/upload-compliance-instance-multiple-doc-modal.html',
                size: 'md',
                controller: 'uploadComplianceInstanceMultipleDocModalCtrl',
                resolve: {
                    complianceInstanceId: function () { return complianceInstanceId; },
                    proId: function () { return $scope.$ctrl.caregiverId; }
                }
            });
            modalInstance.result.then(async () => {
                await loadRecords(complianceInstance.caregiverDocumentTypeId, "records");
            }, () => {});
        }

        $scope.viewCaregiverPendingUpload = function (document) {
            let newScope = $scope.$new();
            newScope.document = document;
            newScope.url = 'agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.$ctrl.caregiverId +
                '/document_uploads/' + document.caregiverDocumentUploadId + '/generate_url';

            $uibModal.open({
                templateUrl: 'admin/views/image-viewer-modal.html',
                size: 'lg',
                controller: 'imageViewerModalCtrl',
                scope: newScope
            });
        }

        const getJSPDFCenterOffset = (doc, text) => {
            const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            return (doc.internal.pageSize.width - textWidth) / 2;
        }

        const getComplianceItemEncodedPdf = async function (caregiverComplianceItem) {
            let doc = new jsPDF();
            const medflytLogoBase64 = base64ImageConsts.medflytLogo;
            const latestRecord = caregiverComplianceItem.instances[0];

            let cols = [{
                name: 'Name',
                effectiveDate: 'Effective Date'
            }];
            Object.keys(caregiverComplianceItem).filter(field => {
                if (fieldTypesMap[field] && caregiverComplianceItem[field] === true) {
                    cols[0][field] = fieldTypesMap[field];
                }
            });

            let rows = [];
            let row = [];
            row.push(caregiverComplianceItem.name);
            row.push(latestRecord.effectiveDate);
            caregiverComplianceItem.modalFields.forEach(field => {
                let value = "";
                if (inputDateTypes.includes(field)) {
                    value = latestRecord[field] !== null ? LocalDate.from(nativeJs(moment(latestRecord[field]))).toString() : "";
                } else {
                    value = latestRecord[field] !== null ? latestRecord[field] : "";
                }
                row.push(value);
            })
            rows.push(row);

            doc.setFontSize(25);
            doc.text($scope.$ctrl.caregiverFullName, getJSPDFCenterOffset(doc, $scope.$ctrl.caregiverFullName), 40);

            doc.setFontSize(15);
            doc.setTextColor(100);

            const titleText = "Compliance Item - " + caregiverComplianceItem.name;
            doc.text(titleText, getJSPDFCenterOffset(doc, titleText), 47);

            autoTable(doc, {
                head: cols,
                body: rows,
                startY: 55,
                didDrawPage: function (data) {
                    doc.setFontSize(20);
                    doc.setTextColor(40);
                    doc.setFontStyle('normal');
                    doc.addImage(medflytLogoBase64, "JPEG", (doc.internal.pageSize.width / 2) - 30, 15, 60, 15);
                },
                margin: { top: 30 }
            });

            if (caregiverComplianceItem.latestInstance?.id) {
                doc = await $scope.getCombinedDocWithLatestComplianceInstance(caregiverComplianceItem.latestInstance?.id, doc);
                if (doc === null) {
                    toaster.pop('error', 'Something went wrong', 'could not download compliance item pdf');
                    return null;
                }
            } else {
                doc = doc.output('arraybuffer');
            }

            const fileDate = LocalDate.from(nativeJs(moment(new Date()))).toString();
            return {
                doc: doc,
                fileName: $scope.$ctrl.caregiverFullName + ", " + titleText +  " - " + fileDate
            };
        }

        $scope.getCombinedDocWithLatestComplianceInstance = async (complianceInstanceId, doc) => {
            const deferred = $q.defer();
            const baseFileFetch = await getBase64(doc.output('blob'));

            const getFileUrl = `agencies/${$rootScope.agencyId}/caregivers/${$scope.$ctrl.caregiverId}/compliance_instances/${complianceInstanceId}/preview`;
            const fileUrl = await DatabaseApi.get(getFileUrl).then((res) => {
                return res.data.fileUrl;
            }, (err) => {
                deferred.reject(null);
            });
            if (fileUrl) {
                const fetchPromisesWithTypes = [{ fetchPromise: baseFileFetch, type: 'pdf' }];
                const fetchUrlPromise = fetch(fileUrl).then(res => res.arrayBuffer());
                if (fileUrl.includes(".pdf")) {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchUrlPromise, type: 'pdf' });
                } else if (fileUrl.includes(".png")) {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchUrlPromise, type: 'png' });
                } else if (fileUrl.includes(".jpg")) {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchUrlPromise, type: 'jpg' });
                } else if (fileUrl.includes(".jpeg")) {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchUrlPromise, type: 'jpeg' });
                }

                const bytes = await combinePdf(fetchPromisesWithTypes);
                deferred.resolve(bytes);
            }
            return deferred.promise;
        }

        $scope.sendComplianceItemPdfToEmailOrFax = async (caregiverComplianceItem, type) => {
            let pdf = await getComplianceItemEncodedPdf(caregiverComplianceItem);
            if (pdf === null) {
                return;
            }

            const newScope = $scope.$new();
            newScope.modalTitle = "Send Compliance Item";

            newScope.attachmentName = "Compliance Item";
            newScope.type = type;
            const blob = new Blob([pdf.doc], { type: "application/pdf" });
            const file = new File([blob], pdf.fileName + '.pdf');
            newScope.encodedPdf = await getBase64(file);
            newScope.relatedEntity = {
                type: "Caregiver",
                caregiverId: parseInt($scope.$ctrl.caregiverId, 10)
            } 
            newScope.fileName = file.name;

            $uibModal.open({
                templateUrl: 'admin/views/send-encoded-pdf-modal.html',
                size: 'md',
                scope: newScope,
                controller: 'sendEncodedPdfModalCtrl'
            });
        }

        $scope.downloadComplianceItemPdf = async function (caregiverComplianceItem) {
            const pdf = await getComplianceItemEncodedPdf(caregiverComplianceItem);
            if (pdf) {
                download(pdf.doc, pdf.fileName + '.pdf', "application/pdf");
            }
        }

        $scope.updateLatestInstance = (item, latestInstance) => {
            item.latestInstance = latestInstance;
        }

        $scope.openComplianceItemEditModal = (complianceItem) => {
            let newScope = $scope.$new();
            newScope.title = complianceItem.name;
            newScope.caregiverId = $scope.$ctrl.caregiverId;
            newScope.complianceItemRecord = complianceItem.instances[0];
            newScope.complianceItemRecord.name = complianceItem.name;
            newScope.complianceItemRecord.daysToExpire = complianceItem.daysToExpire;
            newScope.complianceItemRecord.expirationStatus = complianceItem.expirationStatus;
            newScope.options = complianceItem.options;
            newScope.docBNotNeeded = complianceConsts.docBNotNeeded;
            newScope.fields = complianceItem.modalFields;
            newScope.updateComplianceItemAnswer = $scope.updateComplianceItemAnswer;
            newScope.calculateDayToExpireAsText = calculateDayToExpireAsText;
            newScope.updateLatestInstance = () => {
                $scope.updateLatestInstance(complianceItem, complianceItem.instances[0]);
            }

            $uibModal.open({
                templateUrl: 'admin/modules/compliance/views/compliance-item-edit-modal.html',
                size: 'lg',
                controller: 'complianceItemEditModalCtrl',
                scope: newScope,
                windowClass: "compliance-item-edit-modal"
            });
        }
        this.$onInit = async () => {
            await loadRecords();
        }
    }
};