import { Flex } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import ErrorPage from "../../../../../shared/components/ErrorPage";
import LoadingPage from "../../../../../shared/components/LoadingPage";
import { PatientId } from "../../../../../shared/schema/schema";
import usePatientIntakeFlow from "../hooks/usePatientIntakeFlow";
import PatientIntakeFlowStepFields from "./PatientIntakeFlowStepFields";
import PatientIntakeFlowStepSection from "./PatientIntakeFlowStepSection";

export default function PatientIntakeFlowStepContent(props: {
  query: ReturnType<typeof usePatientIntakeFlow>["stepQuery"];
  activeStepForm: ReturnType<typeof usePatientIntakeFlow>["activeStepForm"];
  patientId: PatientId;
  isStepSubmitting: boolean;
  externalFlows: Messages["IntakeExternalFlow"][];
  onFinishFlow: () => void;
  onSubmitStep: ReturnType<typeof usePatientIntakeFlow>["onSubmitStep"];
  onChangeField: ReturnType<typeof usePatientIntakeFlow>["setField"];
}) {
  return (
    <Flex direction="column" gap={16} h="full" overflow="auto" p={8} pb={24} w="full">
      {(() => {
        switch (props.query.status) {
          case "pending":
            return <LoadingPage />;
          case "error":
            return (
              <ErrorPage
                error={new Error(String(props.query.error))}
                resetErrorBoundary={props.query.refetch}
              />
            );
          case "success": {
            const { data } = props.query;

            return (
              <>
                <PatientIntakeFlowStepSection
                  externalFlowName={
                    props.externalFlows.find((f) => f.id === data.externalFlowId)?.title ?? null
                  }
                  script={data.script}
                  title={data.title}
                />
                <PatientIntakeFlowStepFields
                  fields={data.fields}
                  form={props.activeStepForm}
                  isFuture={data.isFuture}
                  isStop={data.isStop}
                  isSubmitting={props.isStepSubmitting}
                  patientId={props.patientId}
                  onChange={props.onChangeField}
                  onFinishFlow={props.onFinishFlow}
                  onSubmit={props.onSubmitStep}
                />
              </>
            );
          }
        }
      })()}
    </Flex>
  );
}
