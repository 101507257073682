import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  ModalBody,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import Base64FileSelectionButton from "../../../shared/components/Base64FileSelectionButton";
import SingleDatePicker from "../../../shared/components/DatePicker/SingleDatePicker";
import Editor from "../../../shared/components/Lexical/LexicalEditor";
import NoteSubjectSelect from "./NoteSubjectSelect";
import Select from "../../../shared/components/Select";
import { ControlledForm } from "../../../shared/hooks/useControlledForm";
import BaselineImageSearchIcon from "../../../shared/icons/BaselineImageSearchIcon";
import { NoteFileId } from "../../../shared/schema/schema";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import EntityFormControl from "../../workflow/components/EntityFormControl";
import { zNoteForm } from "../utils/note-form";
import NoteFileViewerModal from "./NoteFileViewerModal";

export type Props = {
  form: ControlledForm<typeof zNoteForm>;
  uploadedFiles?: Messages["NoteFile"][];
};

export default function NoteModalBody({ form, uploadedFiles }: Props) {
  const fileViewerDisclosure = useDisclosure();
  const [selectedUploadedFileId, setSelectedUploadedFileId] = React.useState<NoteFileId | null>(
    null
  );

  return (
    <ModalBody>
      <VStack align="stretch" spacing={4}>
        <Card>
          <CardHeader>
            <Heading color="black" mb={1} size="sm" textTransform="uppercase">
              Entities
            </Heading>
            <Text color="gray.500" fontSize="md">
              Please choose a caregiver or a patient (both can be chosen).
            </Text>
          </CardHeader>
          <CardBody>
            <Flex gap={4}>
              <EntityFormControl
                errors={form.errors.caregiverId ?? []}
                input={{ type: "entity", entity: "Caregiver" }}
                isRequired={false}
                label="Caregiver"
                multiple={false}
                value={form.state.caregiverId ?? null}
                onChange={(x) => form.setValue("caregiverId", x ?? undefined)}
              />

              <EntityFormControl
                errors={form.errors.patientId ?? []}
                input={{ type: "entity", entity: "Patient" }}
                isRequired={false}
                label="Patient"
                multiple={false}
                value={form.state.patientId ?? null}
                onChange={(x) => form.setValue("patientId", x ?? undefined)}
              />
            </Flex>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Heading color="black" mb={1} size="sm" textTransform="uppercase">
              Assignee
            </Heading>
            <Text color="gray.500" fontSize="md">
              Choose agency member/s to assign this note to (optional).
            </Text>
          </CardHeader>
          <CardBody>
            <EntityFormControl
              errors={form.errors.agencyMemberIds ?? []}
              input={{ type: "entity", entity: "Agency Member" }}
              isRequired={false}
              label="Agency Member/s"
              multiple={true}
              value={form.state.agencyMemberIds ?? null}
              onChange={(x) => form.setValue("agencyMemberIds", x ?? undefined)}
            />
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <VStack spacing={4}>
              <FormControl isInvalid={form.isInvalid("subject")}>
                <FormLabel>Subject</FormLabel>
                <NoteSubjectSelect
                  value={form.state.subject ?? null}
                  onChange={(x) => form.setValue("subject", x ?? null)}
                />
                <FormErrorMessage>{form.getError("subject")}</FormErrorMessage>
              </FormControl>

              <EntityFormControl
                errors={form.errors.commCenterTicketId ?? []}
                input={{ type: "entity", entity: "Comm Center Ticket" }}
                isRequired={false}
                label="Ticket"
                multiple={false}
                value={form.state.commCenterTicketId ?? null}
                onChange={(x) => form.setValue("commCenterTicketId", x ?? undefined)}
              />

              <FormControl isInvalid={form.isInvalid("content")}>
                <FormLabel>Content</FormLabel>
                <Editor
                  format="html"
                  initialValue={form.state.content}
                  onChange={({ mentions, output }) => {
                    form.setValue("content", output);
                    form.setValue(
                      "mentionedAgencyMemberIds",
                      mentions.map((x) => x.id)
                    );
                  }}
                />
                <FormErrorMessage>{form.getError("content")}</FormErrorMessage>
              </FormControl>

              <Flex gap={4} w="full">
                <FormControl isInvalid={form.isInvalid("followUpDate")}>
                  <FormLabel>Follow-up</FormLabel>
                  <SingleDatePicker
                    selected={form.state.followUpDate}
                    onChange={(x) => form.setValue("followUpDate", x)}
                  />
                  <FormErrorMessage>{form.getError("followUpDate")}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={form.isInvalid("status")}>
                  <FormLabel>Status</FormLabel>
                  <Select
                    buttonProps={{ w: "full" }}
                    label="Choose a status"
                    multiple={false}
                    options={
                      [
                        { value: "NONE", label: "None" },
                        { value: "VIEWED", label: "Viewed" },
                        { value: "IN_PROGRESS", label: "In progress" },
                        { value: "DONE", label: "Done" },
                      ] as const
                    }
                    value={form.state.status ?? null}
                    onChange={(x) => form.setValue("status", x ?? null)}
                  />
                  <FormErrorMessage>{form.getError("status")}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Box w="full">
                <Base64FileSelectionButton onChange={(x) => form.setValue("base64File", x)} />
              </Box>
            </VStack>
          </CardBody>
        </Card>

        {uploadedFiles !== undefined && uploadedFiles.length > 0 && (
          <Card>
            <CardBody>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Created at</Th>
                    <Th>Created by</Th>
                    <Th w="48">View</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {uploadedFiles?.map((file) => (
                    <Tr key={file.id}>
                      <Td>{dateFormatter.toDateOrDateTime(file.createdAt)}</Td>
                      <Td>{file.createdByName}</Td>
                      <Td>
                        <IconButton
                          aria-label="View uploaded file"
                          icon={<BaselineImageSearchIcon fontSize="2xl" />}
                          variant="ghost"
                          onClick={() => {
                            fileViewerDisclosure.onOpen();
                            setSelectedUploadedFileId(file.id);
                          }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </VStack>

      {selectedUploadedFileId !== null && (
        <NoteFileViewerModal
          disclosure={fileViewerDisclosure}
          noteFileId={selectedUploadedFileId}
        />
      )}
    </ModalBody>
  );
}
