export default async function createBrowserNotification(params: {
  title: string;
  body?: string;
  onClick?: (e: Event) => void;
}) {
  if (window.Notification === undefined || (await Notification.requestPermission()) !== "granted") {
    return;
  }

  const notification = new Notification(params.title, {
    body: params.body,
    icon: "admin/images/favicon-96x96.png",
    tag: params.title + (params.body ?? ""),
  });

  if (params.onClick !== undefined) {
    notification.onclick = params.onClick;
  }

  return notification;
}
