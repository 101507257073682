import { Box, Divider, Skeleton } from "@chakra-ui/react";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { loadable, Loadable } from "../../../shared/utils/loadable";
import { CommCenterTicket } from "../communication.types";
import TicketsGroup from "./TicketsGroup";

interface Props {
  tickets: Loadable<CommCenterTicket[]>;
  selectedTicketId?: CommCenterTicketId;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
}

const TicketsList = (props: Props) => {
  if (!loadable.isResolved(props.tickets)) {
    return <TicketsList.Loading />;
  }

  const { value: tickets } = props.tickets;

  const openTickets = tickets.filter((ticket) => ticket.status !== "RESOLVED");
  const resolvedTickets = tickets.filter((ticket) => ticket.status === "RESOLVED");

  return (
    <Box>
      <TicketsGroup
        activeTicketId={props.selectedTicketId}
        label={<>Open Tickets ({openTickets.length})</>}
        tickets={openTickets}
        onClickTicket={props.onClickTicket}
      />

      <Divider />

      <TicketsGroup
        activeTicketId={props.selectedTicketId}
        label={<>Resolved Tickets ({resolvedTickets.length})</>}
        tickets={resolvedTickets}
        onClickTicket={props.onClickTicket}
      />
    </Box>
  );
};

const Loading = () => {
  const headline = (
    <Box m={2}>
      <Skeleton height={12} width="40%" />
    </Box>
  );

  const box = (
    <Box my={2}>
      <Skeleton height={20} />
    </Box>
  );

  return (
    <Box>
      {headline}
      {box}
      {box}
      {box}
      {headline}
      {box}
      {box}
      {box}
    </Box>
  );
};
TicketsList.Loading = Loading;

export default TicketsList;
