import { LocalDate, DateTimeFormatter } from "@js-joda/core";

//! @ngInject
export function callsMissedOutCtrl($scope, NgTableParams, itemSearchPageManager, toaster) {

    $scope.tableParams = null;

    function initialize() {
        initPageManager();
        $scope.loadItems();
    }

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/missed_clocks");

        $scope.pageManager.initSearchParam("type", "OUT");
        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.updateSearchParamValue("from", new Date(LocalDate.now().minusMonths(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
        $scope.pageManager.updateSearchParamValue("to", new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
    };

    var initTable = function (items) {
        var options = {
            count: 25,
            sorting: { id: "desc" }
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    $scope.loadItems = function () {
        $scope.pageManager.executeSearch().then(function (response) {

            var calls = response.data.visitInstances;
            initTable(calls);
        }, function (error) {
            toaster.pop("error", "Failed to load visits for calls with missed clock-out");
        });
    };

    initialize();
};