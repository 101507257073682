import { useQueries } from "@tanstack/react-query";
import { commCenterKeys } from "../../modules/communication/communication.query-keys";
import useApi from "./useApi";

const usePreSignedUrl = (attachments: File[]) => {
  const { api } = useApi();
  const preSignedFiles = useQueries({
    queries: attachments.map((attachment) => {
      return {
        queryKey: commCenterKeys.attachment(attachment.name),
        queryFn: async () => {
          const preSign = await api.get("./get_pre_signed_url", {
            query: { fileExtension: attachment.type },
          });
          return { ...preSign, file: attachment, name: attachment.name };
        },
      };
    }),
  });
  return preSignedFiles;
};

export default usePreSignedUrl;
