import { useCurrentStateAndParams } from "@uirouter/react";
import { z } from "zod";

const usePathParams = <$PathParamSchema extends z.AnyZodObject>(
  schema: $PathParamSchema
): z.infer<$PathParamSchema> => {
  const { params } = useCurrentStateAndParams();

  return schema.parse(params);
};

export default usePathParams;
