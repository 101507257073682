import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";

export function useSearchPatientsQuery(params: { term: string }) {
  const { api } = useApi();

  return useQuery({
    queryKey: queryKeys.patient.search(params.term),
    enabled: params.term !== "",
    placeholderData: params.term !== "" ? keepPreviousData : undefined,
    select: (data) => data.patients.slice(0, 10),
    queryFn: () =>
      api.get("/agencies/:agencyId/search", {
        query: {
          patient_id: true,
          patient_first_name: true,
          patient_last_name: true,
          text: params.term,
        },
      }),
  });
}
