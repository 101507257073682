import { Messages } from "../../../core/api";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import useCommCenterCallRecording from "../hooks/useCommCenterCallRecording";
import LiveBadge from "./LiveBadge";
import PhoneCallStatus from "./PhoneCallStatus";
import RingingBadge from "./RingingBadge";

interface Props {
  callInfo: Messages["CommCenterTicket"]["callInfo"];
  ticketId: CommCenterTicketId;
  isLiveCall: boolean;
}

const PhoneCallPreviewCell = (props: Props) => {
  if (props.callInfo === null) {
    return <span>No Status</span>;
  }

  if (props.callInfo.status === "Ringing") {
    return <RingingBadge />;
  }

  if (props.callInfo.status === "InProgress") {
    return <LiveBadge />;
  }

  return <PhoneCallStatusWrapper callInfo={props.callInfo} />;
};

const PhoneCallStatusWrapper = (props: {
  callInfo: NonNullable<Messages["CommCenterTicket"]["callInfo"]>;
}) => {
  const recordingState = useCommCenterCallRecording({
    callId: props.callInfo.id,
    hasRecording: props.callInfo.hasRecording ?? false,
  });

  return (
    <PhoneCallStatus
      callStatus={props.callInfo.status}
      failDescription={props.callInfo.failDescription}
      recordingState={recordingState}
    />
  );
};

export default PhoneCallPreviewCell;
