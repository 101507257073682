import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Divider,
  Flex,
  IconButton,
  Portal,
  Spinner,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { noop } from "@chakra-ui/utils";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Messages } from "../../../core/api";
import EntityCard, {
  CaregiverEntity,
  Entity,
  EntityWithStatus,
  NotIdentifiedEmailEntity,
  NotIdentifiedPhoneNumberEntity,
  PatientEntity,
  PhonebookContactEntity,
} from "../../../shared/components/EntityCard";
import useApi from "../../../shared/hooks/useApi";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import useMultipleSocketEvent from "../../../shared/hooks/useMultipleSocketEvent";
import useSocketEvent from "../../../shared/hooks/useSocketEvent";
import MaximizeIcon from "../../../shared/icons/MaximizeIcon";
import MinimizeIcon from "../../../shared/icons/MinimizeIcon";
import RefreshRoundedIcon from "../../../shared/icons/RefreshRoundedIcon";
import { queryKeys } from "../../../shared/query-keys";
import {
  AgencyPhonebookContactId,
  CaregiverId,
  CommCenterTeamId,
  CommCenterTeamMemberId,
  CommCenterTicketId,
  PatientId,
  VisitBroadcastId,
} from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { getFullName } from "../../../shared/utils/get-full-name";
import { loadable } from "../../../shared/utils/loadable";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import { usePhoneNumberDialerPopup } from "../../call-center/hooks/usePhoneNumberDialerPopup";
import useCommCenterTicket from "../hooks/useTicket";
import useTicketMessages from "../hooks/useTicketMessages";
import useTicketSummaryDisclosure from "../hooks/useTicketSummaryDisclosure";
import {
  NewTicketRequestBody,
  getCommCenterTeamMemberIdByAgencyMemberId,
  transformCommCenterTickets,
} from "../utils/communication-utils";
import TicketSummaryModal from "./TicketSummaryModal";
import TicketsBox from "./TicketsBox";

export type TicketPopupPrimaryEntityOptions =
  | { primaryEntity: "Caregiver"; caregiverId: CaregiverId }
  | {
      primaryEntity: "Patient";
      patientId: PatientId;
      contactDetails: Messages["PatientContactDetails"] | null;
    }
  | { primaryEntity: "NotIdentifiedPhoneNumber"; notIdentifiedPhoneNumber: string }
  | { primaryEntity: "NotIdentifiedEmailEntity"; notIdentifiedEmailEntity: string }
  | { primaryEntity: "PhonebookContact"; phonebookContactId: AgencyPhonebookContactId };

export type TicketPopupOptions = {
  caregiverId?: CaregiverId;
  patientId?: PatientId;
  phonebookContactId?: AgencyPhonebookContactId;
  notIdentifiedPhoneNumber?: string;
  notIdentifiedEmailEntity?: string;
  contactDetails?: Messages["PatientContactDetails"] | null;
  defaultTicketId?: CommCenterTicketId;
  defaultMessage?: string;
  defaultLabel?: Messages["CommCenterLabel"];
  defaultMinimized?: boolean;
  defaultVisitBroadcastId?: VisitBroadcastId;
} & TicketPopupPrimaryEntityOptions &
  (
    | { secondaryEntity: "Patient"; patientId: PatientId }
    | { secondaryEntity: "Caregiver"; caregiverId: CaregiverId }
    | { secondaryEntity?: undefined }
  );

type Props = TicketPopupOptions & {
  onClose: () => void;
};

const CommCenterChatWrapper = (props: Props) => {
  const { api, queries } = useApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { agencyMember } = useAuthData();
  const dialerPopup = usePhoneNumberDialerPopup();

  const [isMinimized, setIsMinimized] = useState(() => props.defaultMinimized ?? false);

  const [activeTicketId, setActiveTicketId] = useState<CommCenterTicketId | null>(
    props.defaultTicketId ?? null
  );

  const isEntityQueryEnabled = {
    patient: props.patientId !== undefined,
    caregiver: props.caregiverId !== undefined,
    phonebookContact: props.phonebookContactId !== undefined,
  };

  const { attachments, submitMessage } = useTicketMessages({
    onSubmitMessage: () => {
      relatedTickets.refetch();
      activeTicketQuery.refetch();
    },
  });
  const ticketSummaryDisclosure = useTicketSummaryDisclosure();

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => api.get("./comm_center/teams", {}),
    placeholderData: keepPreviousData,
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => api.get("./comm_center/labels", {}),
    select: (response) => response.labels,
    placeholderData: keepPreviousData,
  });

  const caregiverEntity = useQuery({
    ...queries.caregiver.get(props.caregiverId!),
    enabled: isEntityQueryEnabled.caregiver,
    select: (caregiver): EntityWithStatus<CaregiverEntity> => ({
      type: "Caregiver",
      id: caregiver.id,
      displayId: caregiver.displayId,
      status: caregiver.status,
      photoUrl: caregiver.photoUrl,
      fullName: getFullName(caregiver),
    }),
  });

  const patientEntity = useQuery({
    queryKey: queryKeys.patient.get(props.patientId!),
    enabled: isEntityQueryEnabled.patient,
    staleTime: 0,
    queryFn: () => {
      return api.get("/agencies/:agencyId/patients/:patientId", {
        path: { patientId: props.patientId! },
      });
    },
    select: (patient): EntityWithStatus<PatientEntity> => ({
      type: "Patient",
      id: patient.id,
      displayId: patient.displayId,
      status: patient.status,
      fullName: getFullName(patient),
      gender: patient.gender,
      contactDetails: props.contactDetails ?? null,
    }),
  });

  const phonebookContactEntity = useQuery({
    enabled: isEntityQueryEnabled.phonebookContact,
    queryKey: queryKeys.phonebook.list(),
    queryFn: () => api.get("./phonebook", {}),
    staleTime: Infinity,
    select: (data): EntityWithStatus<PhonebookContactEntity> | undefined => {
      const phonebookContact = data.contacts.find(
        (phonebookContact) => phonebookContact.id === props.phonebookContactId
      );
      return phonebookContact !== undefined
        ? {
            status: "Phonebook Contact",
            type: "PhonebookContact",
            id: phonebookContact.id,
            name: phonebookContact.name,
          }
        : undefined;
    },
  });

  const notIdentifiedPhoneNumberEntity:
    | EntityWithStatus<NotIdentifiedPhoneNumberEntity>
    | undefined =
    props.notIdentifiedPhoneNumber === undefined
      ? undefined
      : {
          type: "NotIdentifiedPhoneNumber",
          phoneNumber: props.notIdentifiedPhoneNumber,
          status: null,
        };

  const notIdentifiedEmailEntity: EntityWithStatus<NotIdentifiedEmailEntity> | undefined =
    props.notIdentifiedEmailEntity === undefined
      ? undefined
      : {
          type: "NotIdentifiedEmailEntity",
          email: props.notIdentifiedEmailEntity,
          status: null,
        };

  const getPrimaryEntityFromQueriesData = (
    caregiverEntitiyData: Entity | undefined,
    patientEntityData: Entity | undefined,
    notIdentifiedPhoneNumberEntity: Entity | undefined,
    phonebookContactEntity: Entity | undefined,
    notIdentifiedEmailEntity: Entity | undefined
  ) => {
    const primaryEntity = (() => {
      switch (props.primaryEntity) {
        case "Patient":
          return patientEntityData;
        case "NotIdentifiedPhoneNumber":
          return notIdentifiedPhoneNumberEntity;
        case "Caregiver":
          return caregiverEntitiyData;
        case "PhonebookContact":
          return phonebookContactEntity;
        case "NotIdentifiedEmailEntity":
          return notIdentifiedEmailEntity;
        default:
          return undefined;
      }
    })();

    if (primaryEntity === undefined) {
      throw new Error(`No related primary entity found! (${props.primaryEntity})`);
    }

    return primaryEntity;
  };

  const getSecondaryEntityFromQueriesData = (
    caregiverEntityData: Entity | undefined,
    patientEntityData: Entity | undefined
  ) => {
    if (!props.primaryEntity) return;

    switch (props.secondaryEntity) {
      case "Caregiver":
        return caregiverEntityData;
      case "Patient":
        return patientEntityData;
      case undefined:
        return undefined;
    }
  };

  const relatedTicketsQueryParams = (() => {
    switch (props.primaryEntity) {
      case "Caregiver":
        return { caregiverId: [props.caregiverId] };
      case "Patient":
        return { patientId: [props.patientId] };
      case "NotIdentifiedPhoneNumber":
        return { relatedNotIdentifiedPhoneNumber: [props.notIdentifiedPhoneNumber] };
      case "NotIdentifiedEmailEntity":
        return { relatedNotIdentifiedEmailEntity: [props.notIdentifiedEmailEntity] };
      case "PhonebookContact":
        return { phonebookContactId: [props.phonebookContactId] };
    }
  })();

  const relatedTickets = useQuery({
    queryKey: queryKeys.commCenter.search(relatedTicketsQueryParams),
    enabled:
      (props.caregiverId ??
        props.patientId ??
        props.notIdentifiedPhoneNumber ??
        props.phonebookContactId) !== undefined,
    queryFn: () => {
      return api.get("./comm_center/tickets", {
        query: relatedTicketsQueryParams,
      });
    },
    placeholderData: keepPreviousData,
    select: (response) => transformCommCenterTickets(response.tickets),
  });

  const activeTicketQuery = useCommCenterTicket(activeTicketId!, {
    markAsRead: true,
    queryOptions: {
      enabled: activeTicketId !== null,
    },
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: NewTicketRequestBody) => {
      return api.post(
        "/agencies/:agencyId/comm_center_team_members/:commCenterTeamMemberId/comm_center/tickets",
        {
          path: {
            commCenterTeamMemberId:
              getCommCenterTeamMemberIdByAgencyMemberId(teams.data?.teams ?? [], agencyMember.id) ??
              CommCenterTeamMemberId.parse(-1),
          },
          body: newTicketRequest,
        }
      );
    },
    onSuccess: (response) => {
      toast({
        title: "Ticket created successfuly",
        status: "success",
        position: "top-right",
      });

      setActiveTicketId(response.ticketId);
      relatedTickets.refetch();
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const markAllAsUnread = useMutation({
    mutationFn: (ticketId: CommCenterTicketId) => {
      return api.post("./comm_center/tickets/:ticketId/unread", {
        path: { ticketId },
      });
    },
    onMutate: () => {
      return optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets
            .find((ticket) => ticket.id === activeTicketId)
            ?.messages.forEach((message) => (message.readAt = null));
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "All messages marked unread.",
        status: "success",
        position: "top-right",
      });

      if (activeTicketId !== null) {
        setTimeout(
          () =>
            queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.get(activeTicketId) }),
          500
        );
      }

      setActiveTicketId(null);
    },
    onError: (error) => {
      toast({
        title: "Error marking ticket as unread",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  useSocketEvent({
    key: "TelephonyInboundCallRinging",
    onEvent: (event) => {
      if (event.call.ticket.id === activeTicketId) {
        activeTicketQuery.refetch();
      }
    },
  });

  useMultipleSocketEvent({
    keys: [
      "CallCenterCallEnd",
      "CallCenterCallParticipantEvent",
      "CallCenterCallStart",
      "CallCenterCallerHangupBeforeCallStart",
    ],
    onEvent: (event) => {
      if (event.ticketId === activeTicketId) {
        activeTicketQuery.refetch();
      }
    },
  });

  useSocketEvent({
    key: "TelephonyOutboundCallInitiated",
    onEvent: (event) => {
      if (event.call.ticket.id === activeTicketId) {
        activeTicketQuery.refetch();
      }
    },
  });

  useSocketEvent({
    key: "TelephonyOutboundCallStarted",
    onEvent: (event) => {
      if (event.call.ticket.id === activeTicketId) {
        activeTicketQuery.refetch();
      }
    },
  });

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: (event) => {
      relatedTickets.refetch();

      if (event.ticketId === activeTicketId) {
        activeTicketQuery.refetch();
      }
    },
  });

  const handleClickTicket = (newTicketId: CommCenterTicketId) => {
    setActiveTicketId(newTicketId);
  };

  const handleMarkAsUnread = (ticketId: CommCenterTicketId) => {
    markAllAsUnread.mutate(ticketId);
  };

  const handleCreateNewTicket = (newTicketRequest: NewTicketRequestBody) => {
    createTicket.mutate(newTicketRequest);
  };

  const handleClickDial = (phoneNumber: string) => {
    dialerPopup.open({
      predefinedPhoneNumber: phoneNumber,
    });
  };

  const handleSuccessCreateEmailTicket = () => {
    toast({
      title: "Email ticket created successfully",
      status: "success",
      position: "top-right",
    });

    setActiveTicketId(null);
  };

  const areActionsDisabled =
    activeTicketQuery.data?.ticket?.status === "RESOLVED" || activeTicketId === null;

  const allEntityQueriesLoaded =
    (isEntityQueryEnabled.caregiver ? !caregiverEntity.isLoading : true) &&
    (isEntityQueryEnabled.patient ? !patientEntity.isLoading : true) &&
    (isEntityQueryEnabled.phonebookContact ? !phonebookContactEntity.isLoading : true);

  return (
    <Portal>
      <Box
        bg="white"
        borderTopRadius="2xl"
        bottom={39}
        boxShadow="0 0 16px -2px rgba(0,0,0,0.25)"
        id="comm-center-chat-window"
        position="fixed"
        right={55}
        sx={{
          "--max-chat-height": "60vh",
        }}
        transform={isMinimized ? `translateY(calc(100% - 49px)) ` : undefined}
        transition="all 250ms ease"
        w={isMinimized ? 300 : 1200}
        zIndex="ticketPopup"
      >
        <Flex justify="space-between">
          <Flex p={2}>
            <IconButton
              aria-label="close"
              borderRadius="xl"
              icon={<CloseIcon fontSize={12} />}
              variant="ghost"
              onClick={props.onClose}
            />
            <IconButton
              aria-label="close"
              borderRadius="xl"
              hidden={isMinimized}
              icon={<MinimizeIcon fontSize={18} />}
              variant="ghost"
              onClick={() => setIsMinimized(true)}
            />
            <IconButton
              aria-label="close"
              borderRadius="xl"
              hidden={!isMinimized}
              icon={<MaximizeIcon fontSize={18} />}
              variant="ghost"
              onClick={() => setIsMinimized(false)}
            />
          </Flex>
          <Flex p={2}>
            <Tooltip label="Open in new tab">
              <IconButton
                aria-label="open external"
                as="a"
                borderRadius="xl"
                href={`/app/commcenter/${activeTicketId}`}
                icon={<ExternalLinkIcon fontSize={18} />}
                target="blank"
                variant="ghost"
                onClick={() => setIsMinimized(false)}
              />
            </Tooltip>
            <Tooltip label="Refresh">
              <IconButton
                aria-label="refresh"
                borderRadius="xl"
                icon={<RefreshRoundedIcon fontSize={18} />}
                isLoading={activeTicketQuery.isFetching}
                variant="ghost"
                onClick={() => {
                  activeTicketQuery.refetch();

                  if (activeTicketQuery.data?.ticket.callInfo?.id !== undefined) {
                    queryClient.invalidateQueries(
                      queries.telephony.call(activeTicketQuery.data?.ticket.callInfo.id)
                    );
                  }
                }}
              />
            </Tooltip>
          </Flex>
        </Flex>

        <Divider />

        {relatedTickets.isLoading || !allEntityQueriesLoaded ? (
          <Center maxH="65vh">
            <Center color="gray.500" flex={1} flexDirection="column" h="6xl">
              <Spinner size="xl" />
            </Center>
          </Center>
        ) : (
          <TicketsBox
            activeTicket={activeTicketQuery.data?.ticket ?? null}
            areActionsDisabled={areActionsDisabled}
            attachments={attachments.state}
            defaultLabel={props.defaultLabel}
            defaultMessage={props.defaultMessage}
            defaultVisitBroadcastId={props.defaultVisitBroadcastId}
            entityCardAs={EntityCard}
            initialLabelId={
              labels.data?.find((label) => label.parent === null && !label.active)?.id ?? null
            }
            isNewTicketOpen={activeTicketId === null}
            label={activeTicketQuery.data?.ticket?.label?.name ?? null}
            labels={labels.data?.filter((label) => label.active) ?? []}
            onboardingStageName={
              activeTicketQuery.data?.ticket?.relatedCaregiver?.onboardingStageDetails?.name ?? null
            }
            primaryEntity={loadable.resolve(
              getPrimaryEntityFromQueriesData(
                caregiverEntity.data,
                patientEntity.data,
                notIdentifiedPhoneNumberEntity,
                phonebookContactEntity.data,
                notIdentifiedEmailEntity
              )
            )}
            secondaryEntity={loadable.resolve(
              getSecondaryEntityFromQueriesData(caregiverEntity.data, patientEntity.data)
            )}
            settings={{
              assignedToId: activeTicketQuery.data?.ticket?.assignedTo?.id ?? null,
              status: activeTicketQuery.data?.ticket?.status ?? "NEW",
              teamId: activeTicketQuery.data?.ticket?.relatedTeam.id ?? CommCenterTeamId.parse(0),
            }}
            teams={teams.data?.teams ?? []}
            tickets={loadable.fromUndefined(relatedTickets.data)}
            onClickDial={handleClickDial}
            onClickMarkAsUnread={handleMarkAsUnread}
            onClickNewTicket={() => setActiveTicketId(null)}
            onClickRemoveAttachment={attachments.remove}
            onClickShowSummary={ticketSummaryDisclosure.disclosureProps.onOpen}
            onClickTicket={handleClickTicket}
            onCreateNewTicket={handleCreateNewTicket}
            onRequestCloseNewTicket={noop}
            onSelectFile={attachments.add}
            onSubmitNewMessage={submitMessage}
            onSuccessCreateEmailTicket={handleSuccessCreateEmailTicket}
          />
        )}
      </Box>
      <TicketSummaryModal {...ticketSummaryDisclosure} />
    </Portal>
  );
};

export default CommCenterChatWrapper;
