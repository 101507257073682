import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import useApi from "../../../../../shared/hooks/useApi";
import { WorkflowSkillId } from "../../../../../shared/schema/schema";

interface Props {
  isOpen: boolean;
  onAdd: () => void;
  onClose: () => void;
}

export default function AddWorkflowSkillModal(props: Props) {
  const { api } = useApi();
  const toast = useToast();
  const [skill, setSkill] = React.useState("");

  const addSkill = useMutation({
    mutationFn: () =>
      api.post("./workflow_tasks/skills", { body: { skill: WorkflowSkillId.parse(skill) } }),
    onSuccess: () => {
      setSkill("");
      props.onAdd();
      props.onClose();
      toast({
        title: `Skill added`,
        description: "Skill added successfully.",
        status: "success",
        position: "top-right",
      });
    },
    onError: () => {
      toast({
        title: `Error in adding skill`,
        description: "Could not add skill. Please try again later.",
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add skill</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Skill</FormLabel>
            <Input value={skill} onChange={(e) => setSkill(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            isLoading={addSkill.isPending}
            mr={3}
            onClick={() => addSkill.mutate()}
          >
            Add
          </Button>
          <Button variant="ghost" onClick={props.onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
