import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BodyOf } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../../shared/query-keys";
import { IntakeExternalFlowId } from "../../../../../shared/schema/schema";
import { getReactFlowElementsByTrack } from "../flow-settings-visualizer.utils";
import React from "react";

export default function usePatientIntakeFlowSettings() {
  const { api } = useApi();
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: queryKeys.patientIntake.flowSettings(),
    queryFn: async () => await api.get("./get_full_flow", {}),
    select: (response) => ({
      ...response,
      steps: response.steps.filter((x) => x.removedAt === null),
      actions: [...response.actions].sort((a, b) => a.localeCompare(b)),
      statuses: [...response.statuses].sort((a, b) => a.label.localeCompare(b.label)),
      tracks: [...response.tracks].sort((a, b) => a.label.localeCompare(b.label)),
      sortedSteps: [...response.steps].sort((a, b) => a.title.localeCompare(b.title)),
    }),
    staleTime: Infinity,
  });

  const notesQuery = useQuery({
    queryKey: queryKeys.patientIntake.intakeNotes(),
    queryFn: async () => await api.get("./intake_flow/notes", {}),
  });

  const removeExternalFlowMutation = useMutation({
    mutationFn: (id: IntakeExternalFlowId) =>
      api.delete("./intake_flow_step/external_flow/:externalFlowId", {
        path: {
          externalFlowId: id,
        },
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.flowSettings() }),
  });

  const createExternalFlowMutation = useMutation({
    mutationFn: (params: BodyOf<"post", "./intake_flow_step/external_flow">) =>
      api.post("./intake_flow_step/external_flow", {
        body: params,
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.flowSettings() }),
  });

  const reactFlowElementsMap = React.useMemo(
    () =>
      getReactFlowElementsByTrack(
        query.data?.steps.filter((x) => x.removedAt === null) ?? [],
        notesQuery.data?.notes ?? []
      ),
    [notesQuery.data?.notes, query.data?.steps]
  );

  return {
    query,
    removeExternalFlowMutation,
    createExternalFlowMutation,
    reactFlowElementsMap,
    notesQuery,
  };
}
