import { useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";
import { PatientId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { useReactBridgeDispatchEvent } from "../../../shared/hooks/useReactBridgeEvent";
import { CustomAddress } from "../../../shared/components/AddressPicker/AddressPickerModal";
import {
  buildAddressComponentsFromCustomAddress,
  buildCustomAddressTextString,
  getDefaultCustomAddress,
  parseAddress,
} from "../../../shared/components/AddressPicker/address-picker-utils";
import AddressPickerModal from "../../../shared/components/AddressPicker/AddressPickerModal";

interface Props {
  patientId: PatientId;
  disclosure: ReturnType<typeof useDisclosure>;
}

function transformCustomAddressToPatientAddress(
  customAddress: CustomAddress
): Messages["PatientAddressComponents"] {
  return {
    text: buildCustomAddressTextString(customAddress),
    timezone: customAddress.timezone ?? "America/New_York",
    components: buildAddressComponentsFromCustomAddress(customAddress),
  };
}

const PatientAddressPicker = (props: Props) => {
  const { api } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const patientAddressQuery = useQuery({
    queryKey: queryKeys.patient.get(props.patientId),
    queryFn: () => {
      return api.get("/agencies/:agencyId/patients/:patientId", {
        path: { patientId: props.patientId },
      });
    },
    select: (patient): CustomAddress => {
      return patient.address !== null
        ? {
            ...parseAddress(patient.address.text, patient.address.components),
            timezone: patient.address.timezone,
          }
        : getDefaultCustomAddress();
    },
  });

  const editPatientAddress = useMutation({
    mutationFn: (customAddress: CustomAddress) => {
      const patientAddress = transformCustomAddressToPatientAddress(customAddress);
      return api.patch("/agencies/:agencyId/patients/:patientId", {
        path: {
          patientId: props.patientId,
        },
        body: {
          id: props.patientId,
          address: patientAddress,
          isCustomAddress: true,
        },
      });
    },
    onSuccess: (_, customAddress) => {
      toast({
        title: "Patient address updated successfuly",
        status: "success",
        position: "top-right",
      });
      const { text, timezone, components } = transformCustomAddressToPatientAddress(customAddress);
      sendPatientCustomAddressUpdate(text, timezone, components);
      queryClient.invalidateQueries({ queryKey: queryKeys.patient.get(props.patientId) });
      props.disclosure.onClose();
    },
    onError: (error) => {
      toast({
        title: "Could not update patient address",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  useReactBridgeDispatchEvent({
    eventName: "CUSTOM_ADDRESS:ON_CHANGE",
    onEvent: ({ entity, id }) => {
      if (entity === "Patient" && id === props.patientId) {
        queryClient.invalidateQueries({ queryKey: queryKeys.patient.get(props.patientId) });
      }
    },
  });

  const sendPatientCustomAddressUpdate = (
    address: string,
    timezone: string,
    addressComponents: Messages["AddressComponents"]
  ) => {
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "customAddressChange",
          payload: {
            entity: "Patient",
            id: props.patientId,
            address,
            timezone,
            addressComponents,
          },
        },
      })
    );
  };

  return (
    <AddressPickerModal
      addressQuery={patientAddressQuery}
      disclosure={props.disclosure}
      updateAddressMutation={editPatientAddress}
    />
  );
};

export default PatientAddressPicker;
