import { useCurrentStateAndParams, useRouter } from "@uirouter/react";
import { assertNever } from "../../utils";
import {
  CaregiverEntity,
  CommCenterTicketEntity,
  IntakePatientEntity,
  PatientEntity,
  VisitBroadcastEntity,
  VisitInstanceEntity,
} from "../components/EntityCard";

export function useEntityLink(options?: {
  redirectModal?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}) {
  const route = useCurrentStateAndParams();
  const { stateService } = useRouter();

  const open = (params: {
    event: React.MouseEvent<HTMLElement>;
    entity: Pick<
      | CaregiverEntity
      | PatientEntity
      | IntakePatientEntity
      | VisitInstanceEntity
      | VisitBroadcastEntity
      | CommCenterTicketEntity,
      "id" | "type"
    >;
  }) => {
    options?.onOpen?.();

    if (params.entity.type === "CommCenterTicket") {
      params.event.metaKey || params.event.ctrlKey
        ? window.open(
            stateService.href("app.commcenter_ticket", { ticketId: params.entity.id }),
            "_blank"
          )
        : stateService.go("app.commcenter_ticket", { ticketId: params.entity.id });
      return;
    }

    if (params.entity.type === "IntakePatient") {
      stateService.go(".intake", { ...route.params, intakePatient: params.entity.id });
      return;
    }

    if (params.event.metaKey || params.event.ctrlKey) {
      const currentUrl = Object.entries(route.params).reduce(
        (acc, [key, value]) => acc.replace(`:${key}`, value),
        route.state.url ?? ""
      );

      const queryParam = (() => {
        switch (params.entity.type) {
          case "Caregiver":
            return "caregiver";

          case "Patient":
            return "patient";

          case "VisitInstance":
            return "visitInstance";

          case "VisitBroadcast":
            return "broadcast";

          default:
            assertNever(params.entity.type);
        }
      })();

      // this should be handled better. Maybe wrap inside a `<Link />` component instead.
      const entityUrl = currentUrl.includes("?")
        ? `app${currentUrl}&${queryParam}=${params.entity.id}`
        : `app${currentUrl}?${queryParam}=${params.entity.id}`;
      window.open(entityUrl, "_blank");
      return;
    }

    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "navigate",
          payload: { id: params.entity.id, entity: params.entity.type },
          onClose: options?.onClose,
          redirect: options?.redirectModal ?? true,
        },
      })
    );
  };

  return { open };
}
