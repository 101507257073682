import { useCallbackRef } from "@chakra-ui/react";
import { Duration } from "@js-joda/core";
import React from "react";
import { Messages } from "../../../core/api";
import useIncomingCallRingtone from "../hooks/useIncomingCallRingtone";
import InboundCallBanner from "./InboundCallBanner";
import InboundCallModal from "./InboundCallModal";
import { getPortalCallPurposeText } from "../call-center.utils";

type Props = {
  isLoading: boolean;
  onClose: () => void;
  onDecline: () => void;
  onTimeout: () => void;
  call: Messages["TelephonyPortalRequestCallInfo"] | Messages["TelephonyInboundCallInfo"];
  onAccept: (
    call: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
  ) => void;
};

const getTitle = (
  call: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
) => {
  switch (call.direction) {
    case "Inbound":
      return "Incoming call";
    case "PortalCallRequest":
      return "Portal call request";
  }
};

const getSubtitle = (
  call: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
) => {
  switch (call.direction) {
    case "Inbound":
      return `Team - ${call.ticket.teamName}`;
    case "PortalCallRequest":
      return getPortalCallPurposeText(call);
  }
};

export default function InboundCallOverlay(props: Props) {
  const [isRingtoneMuted, setRingtoneMuted] = React.useState(false);
  const [isMinimized, setMinimized] = React.useState(false);

  useIncomingCallRingtone({ enabled: !isRingtoneMuted });

  const onTimeoutRef = useCallbackRef(props.onTimeout);

  React.useEffect(() => {
    const timeout = setTimeout(onTimeoutRef, Duration.ofMinutes(2).toMillis());

    return () => clearTimeout(timeout);
  }, [onTimeoutRef, props.call.id]);

  const subtitle = getSubtitle(props.call);
  return (
    <>
      <InboundCallModal
        call={props.call}
        isLoading={props.isLoading}
        isOpen={!isMinimized}
        isRingtoneMuted={isRingtoneMuted}
        subtitle={subtitle}
        onAccept={() => {
          console.debug("[call-center] InboundCallOverlay: onAccept called.", {
            timestamp: Date.now(),
            rand: Math.random(),
          });
          props.onAccept(props.call);
        }}
        onClose={props.onClose}
        onDecline={props.onDecline}
        onToggleMinimize={() => setMinimized((prev) => !prev)}
        onToggleMuteRingtone={() => setRingtoneMuted((prev) => !prev)}
      />

      <InboundCallBanner
        isLoading={props.isLoading}
        isOpen={isMinimized}
        isRingtoneMuted={isRingtoneMuted}
        subtitle={subtitle}
        title={getTitle(props.call)}
        onAccept={() => props.onAccept(props.call)}
        onClose={props.onClose}
        onDecline={props.onDecline}
        onToggleMinimize={() => setMinimized((prev) => !prev)}
        onToggleMuteRingtone={() => setRingtoneMuted((prev) => !prev)}
      />
    </>
  );
}
