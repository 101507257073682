import { Box, Flex, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import useSocketEvent from "../../../../../shared/hooks/useSocketEvent";
import { CommCenterTicketId, WorkflowTaskInstanceId } from "../../../../../shared/schema/schema";
import { CommCenterTicket } from "../../../../communication/communication.types";
import ActiveTicket from "../../../../communication/components/ActiveTicket";
import useCommCenterTicket from "../../../../communication/hooks/useTicket";
import useTicketMessages from "../../../../communication/hooks/useTicketMessages";
import HumanTaskDefaultForm from "../HumanTaskDefaultForm";
import { HumanTaskSubmitParams } from "../HumanTaskForm";
import { HumanTaskLayoutWrapper } from "../components/HumanTaskLayoutWrapper";
import { CaregiverLinkCard } from "./components/CaregiverLinkCard";
import { IntakePatientLinkCard } from "./components/IntakePatientLinkCard";
import { LoadingEntityCard } from "./components/LoadingEntityCard";
import { PatientLinkCard } from "./components/PatientLinkCard";
import { TicketLinkCard } from "./components/TicketLinkCard";
import { VisitBroadcastLinkCard } from "./components/VisitBroadcastLinkCard";
import { VisitInstanceLinkCard } from "./components/VisitInstanceLinkCard";

interface Props {
  taskInstanceId: WorkflowTaskInstanceId;
  instructions: string;
  ticketId: CommCenterTicketId;
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  showTicketLink?: boolean;
  onSubmit: (params: HumanTaskSubmitParams) => void;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}

export default function HumanTaskChatLayout(props: Props) {
  const ticket = useCommCenterTicket(props.ticketId);

  return (
    <HumanTaskLayoutWrapper>
      <Flex direction="row" gap={4} height="full">
        <Flex direction="column" flex={1} gap={3}>
          <Box bg="gray.50" p={8} rounded="lg">
            <Text fontSize="lg" fontWeight="semibold">
              Instructions:
            </Text>
            <Text fontSize="lg" whiteSpace="pre-wrap">
              {props.instructions}
            </Text>
          </Box>
          {ticket.isPending ? (
            <Chat type="loading" />
          ) : ticket.isError ? (
            <Chat type="error" />
          ) : (
            <Chat ticket={ticket.data.ticket} type="loaded" onRefetch={ticket.refetch} />
          )}
        </Flex>
        <Flex direction="column" gap={2} w="md">
          {ticket.isPending ? (
            <Sidebar type="loading" />
          ) : ticket.isError ? (
            <Sidebar type="error" />
          ) : (
            <Sidebar
              ticket={ticket.data.ticket}
              type="loaded"
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
          {props.showTicketLink && <TicketLinkCard ticketId={props.ticketId} />}
          <HumanTaskDefaultForm
            output={props.output}
            workflowTaskInstanceId={props.taskInstanceId}
            onSubmit={props.onSubmit}
          />
        </Flex>
      </Flex>
    </HumanTaskLayoutWrapper>
  );
}

function Chat(
  props:
    | {
        type: "loading";
      }
    | {
        type: "error";
      }
    | {
        type: "loaded";
        ticket: CommCenterTicket;
        onRefetch: () => void;
      }
) {
  switch (props.type) {
    case "error":
      return (
        <Box bg="gray.50" p={8} rounded="lg">
          <Text fontSize="lg" fontWeight="semibold">
            Error loading ticket
          </Text>
        </Box>
      );

    case "loading":
      return (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      );

    case "loaded":
      return <ChatActivity ticket={props.ticket} onRefresh={props.onRefetch} />;
  }
}

function ChatActivity(props: { ticket: CommCenterTicket; onRefresh: () => void }) {
  const { attachments, submitMessage } = useTicketMessages({});
  const toast = useToast();

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: (event) => {
      if (event.ticketId !== props.ticket.id) {
        return;
      }

      props.onRefresh();
    },
  });

  const handleEmailCreated = () => {
    toast({
      title: "Email ticket created successfully",
      status: "success",
      position: "top-right",
    });
  };

  return (
    <ActiveTicket
      attachments={attachments.state}
      ticket={props.ticket}
      onClickRemoveAttachment={attachments.remove}
      onSelectFile={attachments.add}
      onSubmitNewMessage={(message) => submitMessage(props.ticket.id, message)}
      onSuccessCreateEmailTicket={handleEmailCreated}
    />
  );
}

function Sidebar(
  props:
    | {
        type: "loading";
      }
    | {
        type: "error";
      }
    | {
        type: "loaded";
        ticket: CommCenterTicket;
        onOpenAngularModal?: () => void;
        onCloseAngularModal?: () => void;
      }
) {
  switch (props.type) {
    case "error":
      return (
        <Box bg="gray.50" p={8} rounded="lg">
          <Text fontSize="lg" fontWeight="semibold">
            Error loading ticket
          </Text>
        </Box>
      );

    case "loading":
      return (
        <>
          <LoadingEntityCard />
          <LoadingEntityCard />
          <LoadingEntityCard />
        </>
      );

    case "loaded":
      return (
        <>
          {props.ticket.relatedCaregiver && (
            <CaregiverLinkCard
              caregiverId={props.ticket.relatedCaregiver.id}
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
          {props.ticket.relatedPatient && (
            <PatientLinkCard
              patientId={props.ticket.relatedPatient.id}
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
          {props.ticket.relatedPatient && (
            <IntakePatientLinkCard patientId={props.ticket.relatedPatient.id} />
          )}
          {props.ticket.relatedVisitInstance && (
            <VisitInstanceLinkCard
              visitInstanceId={props.ticket.relatedVisitInstance.id}
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
          {props.ticket.relatedVisitBroadcast && (
            <VisitBroadcastLinkCard
              visitBroadcastId={props.ticket.relatedVisitBroadcast.id}
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
        </>
      );
  }
}
