import { LocalDate, LocalDateTime } from "@js-joda/core";

export function parseFromStorage<T = Record<string, unknown>>(params: {
  storage: Storage;
  key: string | string[];
}): T | null {
  const fromStorage = params.storage.getItem(
    Array.isArray(params.key) ? JSON.stringify(params.key) : params.key
  );

  if (fromStorage === null) {
    return null;
  }

  try {
    const parsed = JSON.parse(fromStorage);

    for (const [key, value] of Object.entries(parsed)) {
      if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
        parsed[key] = LocalDate.parse(value);
      }

      if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+$/.test(value)) {
        parsed[key] = LocalDateTime.parse(value);
      }
    }

    return parsed;
  } catch (e) {
    console.error(`Failed to parse ${JSON.stringify(params.key)} from storage`, e);
    return null;
  }
}
