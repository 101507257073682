import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { atom, useAtom } from "jotai";
import invariant from "tiny-invariant";
import { BodyOf } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../../shared/query-keys";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";
import { shouldShowOptionsForQuestion } from "../../../shared/utils/intakeQuestionUtils";
import {
  IntakeStepForm,
  IntakeStepFormField,
  IntakeStepFormOptionsField,
  IntakeStepFormOptionsOption,
  WithKey,
} from "../flow-settings.types";
import {
  createEmptyField,
  createEmptyOption,
  createEmptyStep,
  emptyStringToNull,
  toIntakeTrackStepField,
} from "../flow-settings.utils";

const formStepAtom = atom<IntakeStepForm>(createEmptyStep());

export default function usePatientIntakeStepForm() {
  const { api } = useApi();

  const [state, setState] = useAtom(formStepAtom);
  const toast = useToast();
  const queryClient = useQueryClient();

  const submit = useMutation({
    mutationFn: () => {
      invariant(state.statusId !== null, "Status is required");
      invariant(state.title !== null, "Title is required");
      invariant(state.trackId !== null, "Track is required");

      const body: BodyOf<"post", "./intake_flow_step/:intakeTrackStepId"> = {
        action: emptyStringToNull(state.action),
        fields: state.fields.map((field) => toIntakeTrackStepField(field, { id: state.id })),
        id: state.id,
        isStop: state.isStop,
        nextStepId: state.nextStepId,
        script: state.script,
        statusId: state.statusId,
        trackId: state.trackId,
        title: state.title,
        isMainInStatusAndTrack: state.isMainOnTrackAndStatus,
        flowType: state.flowType ?? "Regular",
        code: state.code as any,
        showAssistantButton: state.showAssistantButton,
        showTitle: state.showTitle,
        externalFlowId: state.externalFlowId,
        graphPoint: null,
      };

      return api.post("./intake_flow_step/:intakeTrackStepId", {
        body: body,
        path: {
          intakeTrackStepId: state.id,
        },
      });
    },
    onError: (error) => {
      toast({
        status: "error",
        position: "top-right",
        title: "Error",
        description: formatErrorResponse(error),
      });
    },
    onSuccess: () => {
      toast({
        status: "success",
        position: "top-right",
        title: "Success",
        description: "Intake step saved",
      });

      queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.flowSettings() });
    },
  });

  const changeStepProperty = <K extends keyof IntakeStepForm>(
    field: K,
    value: IntakeStepForm[K]
  ) => {
    setState((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const changeStepField = (fieldIdx: number, value: WithKey<IntakeStepFormField>) => {
    setState((prev) => {
      return {
        ...prev,
        fields: prev.fields.map((optionField, i): WithKey<IntakeStepFormField> => {
          return i === fieldIdx ? value : optionField;
        }),
      };
    });
  };

  const changeStepFieldOptions = (
    fieldIdx: number,
    options: WithKey<IntakeStepFormOptionsOption>[]
  ) => {
    setState((prev) => {
      return {
        ...prev,
        fields: prev.fields.map((field, $fieldIdx): WithKey<IntakeStepFormField> => {
          if (
            (field.type !== "options" && field.type !== "multiselect") ||
            $fieldIdx !== fieldIdx
          ) {
            return field;
          }

          return { ...field, options };
        }),
      };
    });
  };

  const changeStepFieldOption = <K extends keyof WithKey<IntakeStepFormOptionsOption>>(
    fieldIdx: number,
    optionIdx: number,
    key: K,
    value: WithKey<IntakeStepFormOptionsOption>[K]
  ) => {
    setState((prev) => {
      return {
        ...prev,
        fields: prev.fields.map((field, $fieldIdx): WithKey<IntakeStepFormField> => {
          if (
            (field.type !== "options" && field.type !== "multiselect") ||
            fieldIdx !== $fieldIdx
          ) {
            return field;
          }

          return {
            ...field,
            options: field.options.map((option, $optionIdx) => {
              return $optionIdx === optionIdx ? { ...option, [key]: value } : option;
            }),
          };
        }),
      };
    });
  };

  const changeStepFieldOptionProperty = <K extends keyof WithKey<IntakeStepFormOptionsOption>>(
    fieldIdx: number,
    optionIdx: number,
    key: K,
    value: WithKey<IntakeStepFormOptionsOption>[K]
  ) => {
    setState((prev) => {
      return {
        ...prev,
        fields: prev.fields.map((field, $fieldIdx): WithKey<IntakeStepFormField> => {
          if (
            (field.type !== "options" && field.type !== "multiselect") ||
            fieldIdx !== $fieldIdx
          ) {
            return field;
          }

          return {
            ...field,
            options: field.options.map((option, $optionIdx) => {
              return $optionIdx === optionIdx ? { ...option, [key]: value } : option;
            }),
          };
        }),
      };
    });
  };

  const createStepField = () => {
    setState((prev) => {
      const newField: WithKey<IntakeStepFormField> = {
        ...createEmptyField(),
        orderInStep: prev.fields.length,
      };
      return {
        ...prev,
        fields: [...prev.fields, newField],
      };
    });
  };

  const removeStepField = (fieldIdx: number) => {
    setState((prev) => {
      return {
        ...prev,
        fields: prev.fields.filter((_, i) => i !== fieldIdx),
      };
    });
  };

  const createStepFieldOption = (fieldIdx: number) => {
    setState((prev) => {
      return {
        ...prev,
        fields: prev.fields.map((field, $fieldIdx): WithKey<IntakeStepFormField> => {
          if (!shouldShowOptionsForQuestion(field) || fieldIdx !== $fieldIdx) {
            return field;
          }

          return {
            ...field,
            options: [...field.options, createEmptyOption()],
          };
        }),
      };
    });
  };

  const changeStepFieldProperty = <K extends keyof WithKey<IntakeStepFormField>>(
    fieldIdx: number,
    key: K,
    value: WithKey<IntakeStepFormField>[K]
  ) => {
    setState((prev) => {
      return {
        ...prev,
        fields: prev.fields.map((field, $fieldIdx): WithKey<IntakeStepFormField> => {
          if ($fieldIdx !== fieldIdx) {
            return field;
          }

          if (key === "type" && (value === "options" || value === "multiselect")) {
            return {
              ...field,
              type: value,
              options: [createEmptyOption()],
            };
          }

          return { ...field, [key]: value };
        }),
      };
    });
  };

  const changeStepFieldOfOptionsProperty = <K extends keyof WithKey<IntakeStepFormOptionsField>>(
    fieldIdx: number,
    key: K,
    value: WithKey<IntakeStepFormOptionsField>[K]
  ) => {
    setState((prev) => {
      console.log("changeStepField", prev);
      return {
        ...prev,
        fields: prev.fields.map((field, $fieldIdx): WithKey<IntakeStepFormField> => {
          if (
            (field.type !== "options" && field.type !== "multiselect") ||
            fieldIdx !== $fieldIdx
          ) {
            return field;
          }

          return { ...field, [key]: value };
        }),
      };
    });
  };

  const removeStepFieldOption = (fieldIdx: number, optionIdx: number) => {
    setState((prev) => {
      console.log("removeStepField", prev);
      return {
        ...prev,
        fields: prev.fields.map((field, $fieldIdx): WithKey<IntakeStepFormField> => {
          if (
            (field.type !== "options" && field.type !== "multiselect") ||
            fieldIdx !== $fieldIdx
          ) {
            return field;
          }

          return {
            ...field,
            options: field.options.filter((_, $optionIdx) => $optionIdx !== optionIdx),
          };
        }),
      };
    });
  };

  return {
    submit: submit,

    state: state,
    setState: setState,
    changeStepProperty: changeStepProperty,

    createStepField: createStepField,
    changeStepField: changeStepField,
    changeStepFieldProperty: changeStepFieldProperty,
    changeStepFieldOfOptionsProperty: changeStepFieldOfOptionsProperty,
    removeStepField: removeStepField,

    createStepFieldOption: createStepFieldOption,
    changeStepFieldOption: changeStepFieldOption,
    changeStepFieldOptionProperty: changeStepFieldOptionProperty,
    removeStepFieldOption: removeStepFieldOption,
    //
    changeStepFieldOptions: changeStepFieldOptions,
  };
}
