import { Instant } from "@js-joda/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import useMultipleSocketEvent from "../../../shared/hooks/useMultipleSocketEvent";
import useSocketEvent from "../../../shared/hooks/useSocketEvent";

const MISSED_CALLS_PARAMS = { inLastMinutes: 30 };

export default function useImportantCalls() {
  const queryClient = useQueryClient();
  const { queries } = useApi();

  const ringingCallsQuery = useQuery(queries.telephony.ringingCalls());
  const missedCallsQuery = useQuery(queries.telephony.missedCalls(MISSED_CALLS_PARAMS));

  useSocketEvent({
    key: "TelephonyInboundCallRinging",
    validate: true,
    onEvent: ({ call }) => {
      if (ringingCallsQuery.data === undefined) {
        ringingCallsQuery.refetch();
        return;
      }

      queryClient.setQueryData(queries.telephony.ringingCalls().queryKey, {
        calls: [
          ...(ringingCallsQuery.data.calls.filter((c) => c.id !== call.id) ?? []),
          {
            ...call,
            rangAt: call.rangAt ?? Instant.now(),
          },
        ],
      });
    },
  });

  useMultipleSocketEvent({
    keys: ["TelephonyInboundCallAnswered", "TelephonyCallEnded"],
    onEvent: ({ call }) => {
      if (ringingCallsQuery.data === undefined) {
        ringingCallsQuery.refetch();
        return;
      }

      queryClient.setQueryData(queries.telephony.ringingCalls().queryKey, {
        calls: ringingCallsQuery.data.calls.filter((c) => c.id !== call.id) ?? [],
      });
    },
  });

  useSocketEvent({
    key: "TelephonyCallEnded",
    validate: true,
    onEvent: ({ call }) => {
      if (call.status !== "Missed" || call.direction !== "Inbound") return;

      if (missedCallsQuery.data !== undefined) {
        queryClient.setQueryData(queries.telephony.missedCalls(MISSED_CALLS_PARAMS).queryKey, {
          calls: [...missedCallsQuery.data.calls, call],
        });
      }

      missedCallsQuery.refetch();
    },
  });

  useSocketEvent({
    key: "TelephonyCallConnected",
    validate: true,
    onEvent: ({ call }) => {
      const newCallPatient = call.participants.find((p) => p.entity.type === "Patient");
      const newCallPatientContact = call.participants.find(
        (p) => p.entity.type === "PatientContact"
      );

      if (newCallPatient === undefined && newCallPatientContact === undefined) {
        return;
      }

      if (missedCallsQuery.data !== undefined) {
        queryClient.setQueryData(queries.telephony.missedCalls(MISSED_CALLS_PARAMS).queryKey, {
          calls: missedCallsQuery.data.calls.filter((missedCall) => {
            const missedCallPatient = missedCall.participants.find(
              (p) => p.entity.type === "Patient"
            );
            const missedCallPatientContact = missedCall.participants.find(
              (p) => p.entity.type === "PatientContact"
            );

            const isSamePatientOfMissedCall =
              (newCallPatient !== undefined &&
                newCallPatient.entity.id === missedCallPatient?.entity.id) ||
              (newCallPatientContact !== undefined &&
                newCallPatientContact.entity.id === missedCallPatientContact?.entity.id);

            return !isSamePatientOfMissedCall;
          }),
        });
      }

      missedCallsQuery.refetch();
    },
  });

  return { ringingCallsQuery, missedCallsQuery };
}
