import { Messages } from "../../../core/api";
import { CaregiverId, PatientId } from "../../schema/schema";

export interface PhoneNumber {
  label: string;
  value: string;
}

export interface PortalLink {
  label: string;
  value: string;
  redirectUrl: Messages["PatientPortalRedirectUrl"];
}

export interface RegularMessage {
  label: string;
  value: string;
}

export enum DefaultSmsMessageFields {
  FIRST_NAME = "{FIRST_NAME}",
  AGENCY_MEMBER = "{AGENCY_MEMBER}",
}

export interface SendSmsBody {
  phoneNumber: string;
  message: string;
}

export interface SendPortalLinkSmsBody {
  redirectUrl: Messages["PatientPortalRedirectUrl"];
  phoneNumber: string;
  message: string;
}

export type MessageTemplate = PortalLink | RegularMessage;

export type QueriedEntity = {
  firstName: string;
  phoneNumbers: PhoneNumber[];
};

export type SmsSenderProps = PatientSmsSenderProps | CaregiverSmsSenderProps;

interface DefaultSmsSenderProps {
  predefinedPhoneNumber?: string;
  predefinedMessageTemplate?: MessageTemplate;
}

export type PatientSmsSenderProps = DefaultSmsSenderProps & {
  entity: "Patient";
  patientId: PatientId;
};

export type CaregiverSmsSenderProps = DefaultSmsSenderProps & {
  entity: "Caregiver";
  caregiverId: CaregiverId;
};
