import { LocalDate } from "@js-joda/core";
import { CaregiverId, CaregiverCertification, HRSectionStageId } from "./ids";

export interface HROnboardingStatisticsResponse {
  caregivers: HROnboardingStatistics[];
}

export type WorkReadinessItems =
  | "Medicals"
  | "Orientation"
  | "Criminal Background"
  | "I-9"
  | "Caregiver Certificate"
  | "SSN Card";

export const ScheduleMedicalsType = [
  "No need to schedule",
  "Waiting for appoitment",
  "N/A",
  "Scheduled",
] as const;

type ScheduleMedicalsType =
  | {
      type: typeof ScheduleMedicalsType[0];
    }
  | {
      type: typeof ScheduleMedicalsType[1];
    }
  | {
      type: typeof ScheduleMedicalsType[2];
      date: LocalDate;
    };

type OrientationStatusType =
  | {
      type: "Not Started";
    }
  | {
      type: "Started";
      completion: number;
    };

export const onboardingMedicalsStatuses = ["Missing", "In Progress", "Done"] as const;
export type OnboardingMedicalsStatus = typeof onboardingMedicalsStatuses[number];

export const CriminalBackgroundStatus = ["N/A", "Pending", "Sent", "Rejected", "Done"] as const;
type CriminalBackgroundStatus = typeof CriminalBackgroundStatus[number];
export interface HROnboardingStatistics {
  caregiverId: CaregiverId;
  certification: CaregiverCertification;
  startedAt: string;
  currentStage: {
    id: HRSectionStageId;
    name: string;
    completion: number;
  };
  workReadiness: {
    completion: number;
    missingItems: WorkReadinessItems[];
  };
  scheduledInterview: string | null;
  scheduleMedicals: ScheduleMedicalsType;
  medicalsStatus: OnboardingMedicalsStatus;
  pendingDocs: number;
  orientation: OrientationStatusType;
  criminalBackground: CriminalBackgroundStatus;
}
