import {
  Center,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from "@chakra-ui/react";
import EmojiPicker from "emoji-picker-react";
import React from "react";
import SmileyIcon from "../icons/SmileyIcon";

export function EmojiPickerPopover(props: React.ComponentProps<typeof EmojiPicker>) {
  const Loading = (
    <Center bg="white" border="1px" borderColor="gray.200" borderRadius="md" h="450px" w="448px">
      <Spinner />
    </Center>
  );

  return (
    <Popover placement="top-start">
      <PopoverTrigger>
        <IconButton
          aria-label="emoji"
          icon={<SmileyIcon fontSize="2xl" />}
          rounded="2xl"
          size="lg"
        />
      </PopoverTrigger>
      <PopoverContent backgroundColor="transparent" border={0} minW="lg">
        <React.Suspense fallback={Loading}>
          <EmojiPicker width="auto" {...props} />
        </React.Suspense>
      </PopoverContent>
    </Popover>
  );
}
