import ng from "angular";
import { Caregiver } from "@medflyt/messages/caregiver";
import { CaregiverId } from "@medflyt/messages/ids";
import { FlyToCaregiver } from "../visit-table-common.types";

interface Bindings extends FlyToCaregiver{
    caregivers: Caregiver[];
    overtimeMap: Map<CaregiverId, number>;
    fields: {
        zip: boolean;
        distance: boolean;
        patientDistance: boolean;
        phoneNumebr: boolean;
        overtime: boolean;
        reason: boolean;
    }
    onClickChat: (caregiverId: CaregiverId, context: "BROADCASTED_TO_OR_OPENED") => void;
}

//! @ngInject
class visitModalCaregiversTableCtrl implements ng.IComponentController, Bindings
{
    caregivers!: Caregiver[];
    overtimeMap!: Map<CaregiverId, number>;
    fields!: {
        zip: boolean;
        distance: boolean;
        patientDistance: boolean;
        phoneNumebr: boolean;
        overtime: boolean;
        reason: boolean;
    }
    showAll!: boolean;
    onClickChat!: (caregiverId: CaregiverId, context: "BROADCASTED_TO_OR_OPENED") => void;
    startFlyToCaregiver!: (caregiverId: CaregiverId) => void;
    stopFlyToCaregiver!: () => void;

    constructor(){
        this.showAll = false
    }

    $onInit(){
        this.showAll = false
    }
}

export const visitModalCaregiversTable = {
    templateUrl:
      "admin/modules/visit-broadcast/components/visit-modal-caregivers-table/visit-modal-caregivers-table.component.html",
    controller: visitModalCaregiversTableCtrl,
    controllerAs: "ctrl",
    bindings: {
        caregivers: "<",
        fields: "<",
        onClickChat: "=",
        startFlyToCaregiver: "<",
        stopFlyToCaregiver: "<"
    },
  };