import React from "react";
import {
  EditPatientIntake,
  PatientIntakeContact,
  PatientIntakeProfileDraft,
} from "../patient-intake.types";
import {
  formatPhonenumberE164,
  parsePatientAdditionalContact,
  validateContactsPhonenumbers,
  validatePatientAdditionalContact,
} from "../shared/utils/intakePatientContacts.utils";

function useIntakePatientAdditionalContacts(props: {
  contacts: PatientIntakeProfileDraft["contacts"];
  onEditPatientIntakeContacts: (value: EditPatientIntake["contacts"]) => void;
}) {
  const [isUnsavedNewContact, setIsUnsavedNewContact] = React.useState(false);

  const editPatientContact = (key: string, newContactInfo: PatientIntakeContact) => {
    const parsedContact = parsePatientAdditionalContact(newContactInfo);

    if (props.contacts === null) {
      throw new Error("Patient contacts are not defined");
    } else {
      const contactIndex = props.contacts.findIndex((contact) => contact.key === key);

      if (contactIndex !== -1) {
        const newContact = {
          ...props.contacts[contactIndex],
          ...parsedContact,
        };

        props.contacts[contactIndex] = newContact;
        props.onEditPatientIntakeContacts(props.contacts);
      }
    }
  };

  const handleAddNewPatientContact = (newContactInfo: PatientIntakeContact) => {
    if (!validatePatientAdditionalContact(newContactInfo)) {
      throw new Error("Invalid patient contact, please fill the required fields");
    }

    if (!validateContactsPhonenumbers(props.contacts)) {
      throw new Error("Contact with this phone number already exists");
    }

    const parsedContact = parsePatientAdditionalContact(newContactInfo);

    const newContactsArray =
      props.contacts === null ? [parsedContact] : [...props.contacts, parsedContact];
    props.onEditPatientIntakeContacts(newContactsArray);
  };

  const handleRemovePatientContact = (keyToRemove: string) => {
    if (props.contacts !== null && props.contacts.length > 0) {
      const indexToRemove = props.contacts.findIndex((contact) => contact.key === keyToRemove);
      props.contacts.splice(indexToRemove, 1);
      props.onEditPatientIntakeContacts(props.contacts);
    }
  };

  const validateNewContactPhonenumber = (contact: PatientIntakeContact) => {
    const parsedPhonenumber = formatPhonenumberE164(contact.mobilePhoneNumber);

    // If it exists return false since it's invalid.
    const doesPhoneNumberExist = props.contacts?.some(
      (c) => c.mobilePhoneNumber === parsedPhonenumber
    );
    return !doesPhoneNumberExist;
  };

  return {
    contacts: props.contacts,
    isUnsavedNewContact,
    setIsUnsavedNewContact,
    editPatientContact,
    validateNewConactPhoneNumber: validateNewContactPhonenumber,
    createPatientContact: handleAddNewPatientContact,
    removePatientContact: handleRemovePatientContact,
  };
}

export default useIntakePatientAdditionalContacts;
