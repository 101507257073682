import { LocalDateTime } from "@js-joda/core";
import { Messages } from "../../../core/api";
import { QueryParams } from "../../../shared/hooks/useQueryParams";
import { VisitInstanceId } from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { EntityNoteFilters } from "../components/EntityNotes";

export function getNoteTotalInstances(note: Messages["EntityNote"]) {
  if ("instances" in note) {
    return note.instances.length;
  }

  return 1;
}

export function getNoteHeadlines(note: Messages["EntityNote"]) {
  const occurrences = getNoteTotalInstances(note);
  const isMultiple = occurrences > 1;

  switch (note.noteType) {
    case "adjustment_approval": {
      return {
        name: "Adjustment approval",
        title: isMultiple
          ? `${occurrences} Billed/Paid visits were adjusted`
          : "A billed/paid visit was adjusted",
      };
    }
    case "bulk": {
      return {
        name: "Bulk note",
        title: isMultiple ? `${occurrences} Bulk Notes were added` : "A bulk note was created",
      };
    }
    case "calendar": {
      return {
        name: "Calendar",
        title: isMultiple
          ? `${occurrences} Calendar Notes were added`
          : "A calendar note was created",
      };
    }
    case "caregiver_absence": {
      return {
        name: "Caregiver absence",
        title: isMultiple
          ? `${occurrences} Visits marked as absent caregiver`
          : "A visit marked as absent caregiver",
      };
    }
    case "caregiver_exclusion": {
      return {
        name: "Caregiver exclusion",
        title: isMultiple
          ? `${occurrences} Caregivers were added to exclusion list`
          : "A caregiver was added to exclusion list",
      };
    }
    case "caregiver_pto": {
      return {
        name: "Caregiver PTO",
        title: isMultiple
          ? `${occurrences} Visits marked as caregiver PTO`
          : "A visit marked as caregiver PTO",
      };
    }
    case "caregiver_status_change": {
      return {
        name: "Caregiver status change",
        title: isMultiple
          ? `${occurrences} Status changes were made to a caregiver`
          : "A status change was made to caregiver",
      };
    }
    case "missed_visit_instance": {
      return {
        name: "Missed visit instance",
        title: isMultiple
          ? `${occurrences} Visits were marked as missed`
          : "A visit was marked as missed",
        secondary: getFormattedLocalDateTimesRange(note.instances),
        instances: note.instances.map(getFormattedVisitInstance),
      };
    }
    case "patient_authorization": {
      return {
        name: "Patient authorization",
        title: isMultiple
          ? `${occurrences} Authorizations were added`
          : "An authorization was created",
      };
    }
    case "patient_status": {
      return {
        name: "Patient status",
        title: isMultiple
          ? `${occurrences} Patient statuses were changed`
          : "A patient status was changed",
      };
    }
    case "phone_call": {
      return {
        name: "Phone call",
        title: isMultiple ? `${occurrences} Phone calls were made` : "A phone call was made",
      };
    }
    case "profile_note": {
      return {
        name: "Profile note",
        title: isMultiple ? `${occurrences} Profile notes were added` : "A profile note was added",
      };
    }
    case "visit_distance_approval": {
      return {
        name: "Visit distance approval",
        title: isMultiple
          ? `${occurrences} Distance approvals were made`
          : "A distance approval was made",
      };
    }
    case "visit_instance": {
      return {
        name: "Visit instance",
        title: isMultiple ? `${occurrences} Visit notes were added` : "A Visit note was added",
      };
    }
    case "visit_manual_clock_time": {
      return {
        name: "Visit manual clock time",
        title: isMultiple
          ? `${occurrences} Manual clock times made`
          : "A manual clock time was made to a visit",
      };
    }
    case "visit_manual_remove": {
      return {
        name: "Visit manual remove",
        title: isMultiple
          ? `${occurrences} Visits were manually removed`
          : "A Visit was manually removed",
        secondary: getFormattedLocalDateTimesRange(note.instances),
        instances: note.instances.map(getFormattedVisitInstance),
      };
    }
    case "visit_instance_edit": {
      return {
        name: "Visit edit",
        title: isMultiple ? `${occurrences} Visits were edited` : "A Visit was edited",
        secondary: getFormattedLocalDateTimesRange(note.instances),
        instances: note.instances.map(getFormattedVisitInstance),
      };
    }
    case "visit_instance_caregiver_unassign": {
      return {
        name: "Visit unassign caregiver",
        title: isMultiple
          ? `Caregiver was unassigned from ${occurrences} visits`
          : "Caregiver was unassigned from a visit",
      };
    }
    case "visit_assignment_note": {
      return {
        name: "Visit assignment note",
        title: isMultiple
          ? `${occurrences} Visit assignment notes were added`
          : "A Visit assignment note was added",
      };
    }
  }
}

export function getFormattedVisitInstance(visitInstance: {
  id: VisitInstanceId;
  startTime: LocalDateTime;
  endTime: LocalDateTime;
}) {
  const startTime = dateFormatter.toDate(visitInstance.startTime);
  const endTime = dateFormatter.toDate(visitInstance.endTime);

  return {
    name: `Visit instance #${visitInstance.id} (${startTime} - ${endTime})`,
  };
}

export function getFormattedLocalDateTimesRange(
  dates: { startTime: LocalDateTime; endTime: LocalDateTime }[]
) {
  const startTime = dates[0]?.startTime;
  const endTime = dates[dates.length - 1]?.endTime;

  if (startTime && endTime) {
    return `${dateFormatter.toDate(startTime)} - ${dateFormatter.toDate(endTime)}`;
  }

  return "N/A";
}

export function filterEntityNotes(params: {
  items: Messages["EntityNote"][];
  filters: QueryParams<EntityNoteFilters>;
  maxNotes: number;
}) {
  const { items, filters, maxNotes } = params;

  return items
    .filter((note) => {
      return (
        (fmap(filters.getValue("subject"), (x) => x === note.subject?.id) ?? true) &&
        (fmap(filters.getValue("type"), (x) => x === note.noteType) ?? true)
      );
    })
    .slice(0, maxNotes);
}
