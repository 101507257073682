import { WrapItem, Flex, Avatar, Badge, Text } from "@chakra-ui/react";

interface Props {
  isMainPhoneNumber: boolean;
  contactName: string;
  contactRelationship: string;
  contactPhoneNumber: string;
  phoneNumberType: string;
  onClickCall: (phoneNumber: string) => void;
}

export default function PatientCallOption(props: Props) {
  return (
    <WrapItem
      _hover={{ bg: "gray.100" }}
      alignItems="center"
      cursor="pointer"
      gap={1}
      p={2}
      onClick={() => props.onClickCall(props.contactPhoneNumber)}
    >
      <Avatar name={props.contactName} />
      <Flex direction="column" gap={1} ml={2}>
        <Flex gap={2}>
          <Badge colorScheme={props.contactRelationship === "Patient" ? "green" : "blue"}>
            {props.contactRelationship}
          </Badge>
          <Badge>{props.phoneNumberType}</Badge>
          {props.isMainPhoneNumber && <Badge colorScheme="red">Main number</Badge>}
        </Flex>
        <Text fontSize={14} fontWeight="bold">
          {props.contactName}
        </Text>
      </Flex>
    </WrapItem>
  );
}
