import { ButtonProps, Wrap } from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { usePhoneNumberDialerPopup } from "../../call-center/hooks/usePhoneNumberDialerPopup";
import { getFullName } from "../../../shared/utils/get-full-name";
import CallButton from "../../../shared/components/CallButton";
import PatientCallOption from "./PatientCallOption";
import { getPatientPhoneNumbersToCall } from "../patient-utils";

interface Props {
  patientId: Messages["PatientId"];
  buttonProps?: ButtonProps;
  text?: string;
}

export default function PatientCallButton(props: Props) {
  const { queries } = useApi();
  const dialerPopup = usePhoneNumberDialerPopup();

  const patientQuery = useQuery({
    ...queries.patient.get(props.patientId),
    placeholderData: keepPreviousData,
    select: (data) => ({
      ...data,
      contacts: orderBy(data.contacts, (contact) => contact.id, "asc"),
    }),
  });

  const handleClickDial = (phoneNumber?: string) => {
    dialerPopup.open({ predefinedPhoneNumber: phoneNumber });
  };

  if (patientQuery.isError) {
    return (
      <CallButton
        isDisabled={true}
        isLoading={false}
        text="Error while loading call button"
        {...props.buttonProps}
      />
    );
  }

  if (patientQuery.isLoading || patientQuery.data === undefined) {
    return (
      <CallButton isDisabled={true} isLoading={true} {...props.buttonProps}>
        <></>
      </CallButton>
    );
  }

  const patient = patientQuery.data;
  const contactsArray = getPatientPhoneNumbersToCall({
    patientName: getFullName(patient),
    phoneNumbers: patient.phoneNumbers,
    contacts: patient.contacts,
    mainPhoneNumber: patient.mainPhoneNumber,
  });

  return (
    <CallButton
      isDisabled={patient.contacts.length === 0 && patient.phoneNumbers.length === 0}
      isLoading={patientQuery.isLoading || patientQuery.data === undefined}
      text={props.text}
      {...props.buttonProps}
    >
      <Wrap direction="column">
        {contactsArray.map((contact, index) => (
          <PatientCallOption
            key={`contact-${index}`}
            contactName={contact.contactName}
            contactPhoneNumber={contact.contactPhoneNumber}
            contactRelationship={contact.contactRelationship}
            isMainPhoneNumber={contact.isMain}
            phoneNumberType={contact.phoneNumberType}
            onClickCall={handleClickDial}
          />
        ))}
      </Wrap>
    </CallButton>
  );
}
