import { z } from "zod";
import { Messages } from "../../../core/api";

type ReferralSource = NonNullable<Messages["IntakePatientProfile"]["referralSource"]>;

export const zReferralSource = z.enum([
  "FC",
  "Online lead - Google",
  "Online lead - Bing",
  "Online lead - Facebook",
  "Online lead - General",
  "Insurance plan",
  "Facility lead",
  "A Place for Mom",
]) satisfies z.ZodEnum<[ReferralSource, ...ReferralSource[]]>;

export const referralSourceOptions = zReferralSource.options.map((value) => ({
  value,
  label: String(value),
}));
