import { createIcon } from "@chakra-ui/react";

const CalendarIcon = createIcon({
  displayName: "CalendarIcon",
  viewBox: "0 0 17 17",
  path: (
    <path
      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
      fill="currentColor"
    />
  ),
});

export default CalendarIcon;
