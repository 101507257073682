import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Messages } from "../../../../core/api";
import useApi from "../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../shared/query-keys";
import { CommCenterTicketId } from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import { getFullName } from "../../../../shared/utils/get-full-name";
import { optimisticUpdate } from "../../../../shared/utils/optimistic-update";

export default function useEditTicket() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { api, queries } = useApi();
  const { data: teams = [] } = useQuery(queries.communication.teams());

  return useMutation({
    mutationFn: (params: {
      id: CommCenterTicketId;
      body: Messages["Partial<EditCommCenterTicketParams>"];
    }) => {
      return api.patch("./comm_center/tickets/:ticketId/edit", {
        path: {
          ticketId: params.id,
        },
        body: { params: params.body },
      });
    },
    onMutate: async (params) => {
      const { previousValue: previousTickets } = optimisticUpdate<{
        tickets: Messages["CommCenterTicket"][];
      }>({
        queryClient: queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          const ticket = draft.tickets.find((ticket) => ticket.id === params.id);

          if (ticket !== undefined) {
            mutateEditTicket({ ticket, teams, body: params.body });
          }
        },
      });

      const { previousValue: previousTicket } = optimisticUpdate<{
        ticket: Messages["CommCenterTicket"];
      }>({
        queryClient: queryClient,
        queryKey: queryKeys.commCenter.get(params.id),
        update: (draft) => mutateEditTicket({ ticket: draft.ticket, teams, body: params.body }),
      });

      return { previousTicket, previousTickets };
    },
    onSuccess: () => {
      toast({ title: "Ticket updated", status: "success", position: "top-right", duration: 2000 });
    },
    onError: (error, { id }, context) => {
      queryClient.setQueryData(queryKeys.commCenter.get(id), context?.previousTicket);
      queryClient.setQueriesData(
        { queryKey: queryKeys.commCenter.search.K },
        context?.previousTickets
      );

      toast({
        title: "Error updating ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSettled: (_, __, { id }) => {
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.get(id) });
      queryClient.invalidateQueries({ queryKey: queryKeys.commCenter.search.K });
    },
  });
}
export function mutateEditTicket(params: {
  ticket: Messages["CommCenterTicket"];
  teams: Messages["CommCenterTeamWithMembers"][];
  body: Messages["Partial<EditCommCenterTicketParams>"];
}) {
  const { body, ticket } = params;

  if (body.teamId !== undefined) {
    ticket.relatedTeam.id = body.teamId;
    ticket.assignedTo = null;
  }

  if (body.assignedToId !== undefined) {
    ticket.assignedTo = {
      id: body.assignedToId,
      name: (() => {
        const assignee = params.teams
          .flatMap((team) => team.members)
          .find((member) => member.id === body.assignedToId);

        return fmap(assignee, getFullName) ?? "Loading...";
      })(),
    };
  }

  if (body.status !== undefined) {
    ticket.status = body.status;
  }

  if (body.patientId !== undefined) {
    ticket.relatedPatient =
      body.patientId === null
        ? null
        : {
            id: body.patientId,
            displayId: null,
            gender: null,
            name: "Loading...",
            status: "ACTIVE",
            clinicalSeverityLevel: null,
          };
  }

  if (body.caregiverId !== undefined) {
    ticket.relatedCaregiver =
      body.caregiverId === null
        ? null
        : {
            id: body.caregiverId,
            displayId: null,
            onboardingStageDetails: null,
            photoUrl: null,
            name: "Loading...",
            status: "ACTIVE",
          };
  }
}
