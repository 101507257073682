import React from "react";
import useCallCenter from "./useCallCenter";

const handleRefreshWithActiveCall = (hangupCall: () => void) => (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = "";
  hangupCall();
};

export default function useCallCenterBeforeUnloadListener() {
  const callCenter = useCallCenter();

  React.useEffect(() => {
    const listener = handleRefreshWithActiveCall(callCenter.hangup);
    if (callCenter.state.status !== "Active" && callCenter.state.status !== "Calling") {
      return;
    }

    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [callCenter.hangup, callCenter.state.status]);
}
