import { useQuery } from "@tanstack/react-query";
import EntityCard from "../../../../../../shared/components/EntityCard";
import useApi from "../../../../../../shared/hooks/useApi";
import { useEntityLink } from "../../../../../../shared/hooks/useEntityLink";
import { CaregiverId } from "../../../../../../shared/schema/schema";
import { getFullName } from "../../../../../../shared/utils/get-full-name";
import { CardButton } from "./CardButton";
import { LoadingEntityCard } from "./LoadingEntityCard";

export function CaregiverLinkCard(props: {
  caregiverId: CaregiverId;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}) {
  const { queries } = useApi();
  const caregiverQuery = useQuery(queries.caregiver.get(props.caregiverId));
  const { open } = useEntityLink({
    onOpen: props.onOpenAngularModal,
    onClose: props.onCloseAngularModal,
    redirectModal: false,
  });

  if (caregiverQuery.isPending) {
    return <LoadingEntityCard />;
  }

  if (caregiverQuery.isError) {
    return null;
  }

  const caregiver = caregiverQuery.data;

  const entity = {
    type: "Caregiver" as const,
    id: caregiver.id,
    displayId: caregiver.displayId,
    photoUrl: caregiver.photoUrl,
    fullName: getFullName(caregiver),
    status: caregiver.status,
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => open({ entity, event });

  return (
    <CardButton onClick={handleClick}>
      <EntityCard entity={entity} />
    </CardButton>
  );
}
