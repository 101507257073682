import { Badge, keyframes } from "@chakra-ui/react";
import CircleDotIcon from "../../../shared/icons/CircleDotIcon";

const flicker = keyframes`
  50% {
    opacity: 0;
  }
`;

const LiveBadge = () => {
  return (
    <Badge
      alignItems="center"
      as="div"
      bg="red"
      color="white"
      display="flex"
      gap={1}
      paddingX={2}
      rounded="md"
      w="50px"
    >
      live
      <CircleDotIcon animation={`${flicker} 1.5s ease-in-out infinite`} />
    </Badge>
  );
};

export default LiveBadge;
