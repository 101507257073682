import { LocalDate } from "@js-joda/core";

//! @ngInject
export function FormService($rootScope, DatabaseApi) {
    this.getFields = function(fieldsTemplate, data, viewOnlyMode) {
        if(!viewOnlyMode) {
            propMap = {
                "Payer": DatabaseApi.activePayers,
                "ServiceCode": DatabaseApi.activeServiceCodes,
                "Contract": DatabaseApi.activeContractTypes,
                "Office": DatabaseApi.activeOffices,
                "PayrollCode": DatabaseApi.activePayrollCodes,
                "Certifications": DatabaseApi.agencyCertifications
            }
        }
        var fields = angular.copy(fieldsTemplate);
        var that = this;
        

        fields.groups.forEach(function(group) {
            group.fields.forEach(function(field) {
                if ("type" in field) {
                    if (field.type.type === "selectMany" && field.type.displayType === "Checkbox") {
                        field.value = that.convertCheckBoxesFromServer(field.type.options, data[field.name]);
                    } else {
                        field = that.fillValue(field, data);
                    }
                    
                    if (field.type.displayType === "Dropdown" && typeof field.type.options === "string") {
                        field.type.options = that.getOptionsData(field.type.options);
                    }

                    if (field.type.type === "SelectMulti" && field.type.displayType === "Dropdown") {
                        field.type.options = field.type.options.map(x => ({id: x.name,name: x.name,label: x.label}));
                        if (field.value === undefined) {
                            field.value = [];
                        }
                    }

                    if (field.type.type === 'Boolean' && field.type.displayType === 'Toggle') {
                        if (field.value === undefined) {
                            field.value = true;
                        }
                    }
                }
            });
        });

        return fields;
    }

    $rootScope.getMultiSelectDisplayOption = (fields, label, valueId) => {
        for (const group of fields.groups) {
            for (const field of group.fields) {
                if (field.label === label && field.type.type === "SelectMulti" && field.type.displayType === "Dropdown") {
                    for (const option of field.type.options) {
                        if (option.id === valueId) {
                            return option.label;
                        }
                    }
                }
            }
        }
    }

    this.convertCheckBoxesFromServer = function(options, data) {
        const checkBoxesValue = {};

        for (var option in options) {
            checkBoxesValue[option.name] = data ? data.indexOf(option.name) > -1 : false;
        }

        return checkBoxesValue;
    }

    this.convertCheckBoxesToServer = function(data) {
        const checkBoxesValue = [];

        for (var optionName in data) {
            if (data[optionName]) {
                checkBoxesValue.push(optionName);
            }
        }

        return checkBoxesValue;
    }

    this.fillValue = function(field, data) {
        if (field.name in data) {
            field.value = data[field.name];

            if (field.type.type === "LocalDate" && field.value !== null) {
                var d = LocalDate.parse(field.value);
                var year = d.year();
                var month = d.month().value() - 1;
                var day = d.dayOfMonth();
                field.value = new Date(year, month, day);
            }
        }
        
        return field;
    }

    var propMap = {
        "Payer": DatabaseApi.payers,
        "ServiceCode": DatabaseApi.serviceCodes,
        "Contract": DatabaseApi.contractTypes,
        "Office": DatabaseApi.offices,
        "PayrollCode": DatabaseApi.payrollCodes,
        "Certifications": DatabaseApi.agencyCertifications
    }
    
    this.getOptionsData = function(property) {
        var serverData = propMap[property]();
        var data;

        switch(property) {
            case "Payer":
                data = serverData.reduce(function(acc, obj) {
                    acc.push({ name: obj.id, label: obj.name});
                    return acc;
                }, []);
                break;
            case "ServiceCode":
                data = serverData.reduce(function(acc, obj) {
                    acc.push({ name: obj.id, label: obj.code});
                    return acc;
                }, []);
                break;
            case "Contract":
                data = serverData.reduce(function(acc, obj) {
                    acc.push({ name: obj.id, label: obj.name});
                    return acc;
                }, []);
                break;
            case "Office":
                data = serverData.reduce(function(acc, obj) {
                    acc.push({ name: obj.id, label: obj.name});
                    return acc;
                }, []);
                break;
            case "PayrollCode":
                data = serverData.reduce(function (acc, obj) {
                    acc.push({ name: obj.id, label: obj.displayId });
                    return acc;
                }, []);
                break;
            case "Certifications":
                serverData = serverData.filter(cert => cert.isActive);
                data = serverData.reduce(function (acc, obj) {
                    acc.push({ name: obj.certification, label: obj.certification });
                    return acc;
                }, []);
                break;
            default:
                break;
        }

        return data;
    }

    this.prepareFormData = function(fieldsTemplate) {
        var fields = angular.copy(fieldsTemplate);
        var formData = {};
        var field, value;

        for (var groupIdx in fields.groups) {
            for (var idx in fields.groups[groupIdx].fields) {
                field = fields.groups[groupIdx].fields[idx];

                if (field.value !== undefined && field.value !== null) {
                    value = field.value !== true && Number(field.value) > 0 ? Number(field.value) : field.value;

                    if (field.type.type === "LocalDate") {
                        var d = new Date(value);
                        value = LocalDate.of(d.getFullYear(), d.getMonth() + 1, d.getDate()).toString();
                    }

                    // This requires so much testing now...
                    if (field.type.type === "Text") {
                        value = field.value;
                    }

                    formData[field.name] = value;
                } else if (field.nullable) {
                    formData[field.name] = null;
                } else if (!field.nullable) {
                    if (field.type.type === "Boolean") {
                        formData[field.name] = false;
                    }
                }
            }
        }

        return formData;
    }
};
