import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BodyOf, Messages } from "../../../core/api";
import ErrorBox from "../../../shared/components/ErrorBox";
import useApi from "../../../shared/hooks/useApi";
import useControlledForm from "../../../shared/hooks/useControlledForm";
import { NoteId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { NoteFormData, zNoteForm } from "../utils/note-form";
import NoteModalBody from "./NoteModalBody";
import NoteModalSkeleton from "./NoteModalSkeleton";

type Props = {
  noteId: NoteId;
  disclosure: ReturnType<typeof useDisclosure>;
  onSuccess?: () => void;
};

export default function EditNoteModal(props: Props) {
  const { api, queries } = useApi();
  const toast = useToast();

  const query = useQuery({
    ...queries.note.get(props.noteId),
    enabled: props.disclosure.isOpen,
  });

  const mutation = useMutation({
    mutationFn: (body: BodyOf<"put", "./notes/:noteId/v2">) => {
      return api.put("./notes/:noteId/v2", { body, path: { noteId: props.noteId } });
    },
    onSuccess: () => {
      props.disclosure.onClose();
      query.refetch();

      toast({
        title: "Note edited",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error editing note",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Modal {...props.disclosure} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit note</ModalHeader>
        <ModalCloseButton />
        {(() => {
          switch (query.status) {
            case "pending":
              return <NoteModalSkeleton />;
            case "error":
              return (
                <ErrorBox error={query.error} resetErrorBoundary={query.refetch} width="2xl" />
              );
            case "success":
              return (
                <EditNoteModalForm
                  initialValues={{
                    caregiverId: query.data.caregiverId ?? undefined,
                    patientId: query.data.patientId ?? undefined,
                    agencyMemberIds: query.data.agencyMembers,
                    commCenterTicketId: query.data.commCenterTicketId ?? undefined,
                    content: query.data.noteRichText ?? undefined,
                    followUpDate: query.data.followUp ?? undefined,
                    status: (query.data.status as any) ?? undefined,
                    subject: query.data.subject?.id ?? undefined,
                  }}
                  isLoading={mutation.isPending}
                  uploadedFiles={query.data.files}
                  onClose={props.disclosure.onClose}
                  onSubmit={mutation.mutate}
                />
              );
          }
        })()}
      </ModalContent>
    </Modal>
  );
}

function EditNoteModalForm(props: {
  initialValues: Partial<NoteFormData>;
  uploadedFiles: Messages["NoteFile"][];
  isLoading: boolean;
  onSubmit: (data: BodyOf<"put", "./notes/:noteId/v2">) => void;
  onClose: () => void;
}) {
  const form = useControlledForm({
    schema: zNoteForm,
    initialValues: props.initialValues,
    onSuccess: (data) => {
      props.onSubmit({
        caregiverId: data.caregiverId,
        patientId: data.patientId,
        commCenterTicketId: data.commCenterTicketId,
        noteRichText: data.content,
        noteType: "profile_note",
        status: data.status,
        base64File: data.base64File,
        followUp: data.followUpDate,
        subject: data.subject,
        agencyMemberIds: [
          ...(data.mentionedAgencyMemberIds ?? []),
          ...(data.agencyMemberIds ?? []),
        ],
      });
    },
  });

  return (
    <form onSubmit={form.onSubmit}>
      <NoteModalBody form={form} uploadedFiles={props.uploadedFiles} />

      <ModalFooter>
        <ButtonGroup>
          <Button variant="ghost" onClick={props.onClose}>
            Close
          </Button>
          <Button colorScheme="blue" isLoading={props.isLoading} type="submit">
            Edit Note
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
}
