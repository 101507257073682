import { Button } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../core/api";
import LoadingButton from "./LoadingButton";
import Select, { CustomSelectProps } from "./Select";
import SelectNoResults from "./SelectNoResults";
import useApi from "../hooks/useApi";
import { AgencyMemberId } from "../schema/schema";
import { fmap } from "../utils";
import { getFullName } from "../utils/get-full-name";

export default function AgencyMemberSelect(
  props: CustomSelectProps<AgencyMemberId> & { filter?: (row: Messages["AgencyMember"]) => boolean }
) {
  const { queries } = useApi();

  const query = useQuery({
    ...queries.agencyMember.list(),
    select: ({ agencyMembers }) =>
      fmap(props.filter, (filter) => agencyMembers.filter(filter)) ?? agencyMembers,
  });

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          label={props.multiple ? "Agency Members" : "Agency Member"}
          options={query.data.map((x) => ({ value: x.id, label: getFullName(x) }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          {...props}
        />
      );
  }
}
