import { Messages } from "../../core/api";

export function mapWorkflowDataFieldRecordToInput(
  v: Record<string, Messages["WorkflowDataFieldType"]>
): Map<string, Messages["WorkflowInput"]> {
  const map = new Map();

  for (const [key, value] of Object.entries(v)) {
    map.set(key, { name: key, ...value });
  }

  return map;
}

export const WorkflowDefinitionSerializer = {
  serialize: (value: unknown) => {
    return JSON.stringify(value, (_, value) => {
      if (value instanceof Map) {
        return {
          __type: "Map",
          value: Array.from(value.entries()),
        };
      }

      return value;
    });
  },
  parse: (value: string) => {
    return JSON.parse(value, (_, value) => {
      if (value && value.__type === "Map") {
        return new Map(value.value);
      }

      return value;
    });
  },
  toJSON: <K, V>(value: Map<K, V>) => ({
    __type: "Map" as const,
    value: Array.from(value.entries()),
  }),
  fromJSON: <K, V>(value: { __type: "Map"; value: [K, V][] }): Map<K, V> =>
    WorkflowDefinitionSerializer.parse(JSON.stringify(value)),
};
