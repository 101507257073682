import {
  Box,
  BoxProps,
  Tab as ChakraTab,
  TabIndicator as ChakraTabIndicator,
  TabList as ChakraTabList,
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
  Flex,
  Heading,
  Progress,
  TabPanel,
  TabPanels,
  chakra,
  forwardRef,
} from "@chakra-ui/react";
import { useCurrentStateAndParams, useRouter } from "@uirouter/react";
import React from "react";
import invariant from "tiny-invariant";
import { useIsPageLoading } from "../hooks/usePageIndication";

interface Props {
  isLoading?: boolean;
  children: React.ReactNode;
  as?: React.ElementType;
  rootProps?: BoxProps;
}

const Page = (props: Props) => {
  return (
    <Root as={props.as} {...props.rootProps} position="relative">
      <Box left={0} opacity={props.isLoading === true ? 1 : 0} position="sticky" right={0} top={0}>
        <Progress isIndeterminate size="xs" />
      </Box>
      <Flex bg="white" boxShadow="sm" direction="column" flex={1} height="full" m={3} rounded={8}>
        {props.children}
      </Flex>
    </Root>
  );
};

const Root = chakra(Flex, {
  baseStyle: {
    flexDirection: "column",
    height: "full",
    minH: "calc(100vh - 170px)",
  },
});

const Header = chakra(Flex, {
  baseStyle: {
    gap: 8,
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: 8,
  },
});

const Title = chakra(Heading, {
  baseStyle: {
    size: "lg",
  },
});

const Filters = chakra(Flex, {
  baseStyle: {
    padding: 8,
  },
});

const Content = chakra(Box, {
  baseStyle: {
    height: "full",
    padding: 6,
  },
});

const Tabs = forwardRef<ChakraTabsProps, "div">((props, ref) => {
  return (
    <ChakraTabs ref={ref} colorScheme="blue" variant="unstyled" {...props}>
      {props.children}
    </ChakraTabs>
  );
});

const TabList = chakra(ChakraTabList, {
  baseStyle: {
    px: 8,
    borderBottomColor: "blue.50",
    borderBottomWidth: "2px",
  },
});

const Tab = chakra(ChakraTab, {
  baseStyle: {
    _hover: {
      bg: "blue.50",
    },
    fontWeight: "semibold",
    minW: 24,
    px: 5,
    py: 5,
  },
});

const TabIndicator = chakra(ChakraTabIndicator, {
  baseStyle: {
    bg: "blue.500",
    borderRadius: "1px",
    height: "2px",
    mt: "-2px",
  },
});

export type PageTab = { header: string; route: string; component: React.ReactNode };

function Tabbed(props: { tabs: { header: string; route: string; component: React.ReactNode }[] }) {
  const { state } = useCurrentStateAndParams();
  const { stateService } = useRouter();
  const { isLoading } = useIsPageLoading();

  const currentTab = [...props.tabs].reverse().find((x) => state.name?.startsWith(x.route));

  invariant(currentTab, `No tab found for state ${state.name}`);

  const tabIndex = props.tabs.indexOf(currentTab);

  const handleTabChange = (index: number) => {
    stateService.go(props.tabs[index].route);
  };

  return (
    <Page isLoading={isLoading}>
      <Tabs index={tabIndex} isLazy={true} onChange={handleTabChange}>
        <TabList>
          {props.tabs.map((x) => (
            <Tab key={x.route}>{x.header}</Tab>
          ))}
        </TabList>

        <TabIndicator />

        <TabPanels>
          {props.tabs.map((x) => (
            <TabPanel key={x.route} p={0}>
              {x.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Page>
  );
}

export default Object.assign(Page, {
  Header,
  Title,
  Filters,
  Content,
  Tabbed: React.memo(Tabbed),
});
