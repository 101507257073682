import { Box, Spinner } from "@chakra-ui/react";
import PatientAvailability from "../../../../../shared/components/PatientAvailability";
import usePatientAvailability from "../../../../../shared/hooks/usePatientAvilability";
import { PatientId } from "../../../../../shared/schema/schema";
import { loadable } from "../../../../../shared/utils/loadable";

const PatientIntakeFlowPatientAvilability = (props: { patientId: PatientId }) => {
  const { availabilityTimeFrames, patientAvailaibility, onUpdateAvailability } =
    usePatientAvailability(props.patientId);

  if (loadable.isLoading(availabilityTimeFrames) || loadable.isLoading(patientAvailaibility)) {
    return <Spinner />;
  }

  if (loadable.isRejected(availabilityTimeFrames) || loadable.isRejected(patientAvailaibility)) {
    return <Box>Failed to load data</Box>;
  }

  if (loadable.isResolved(availabilityTimeFrames) && loadable.isResolved(patientAvailaibility)) {
    return (
      <PatientAvailability
        availabilityTimeFrames={availabilityTimeFrames.value}
        patientAvilaibility={patientAvailaibility.value}
        onUpdateAvilability={onUpdateAvailability}
      />
    );
  }

  return <>Impossible</>;
};

export default PatientIntakeFlowPatientAvilability;
