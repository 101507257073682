import { Box, Flex, Skeleton, Text, chakra } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import { AssignmentRecord, formatAssignmentReason } from "../assignments.utils";
import { VisitAssignmentButton } from "./VisitAssignmentButton";
import { VisitAssignmentSummaryButton } from "./VisitAssignmentSummaryButton";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";

export function VisitAssignmentHeader(props: { query: UseQueryResult<AssignmentRecord> }) {
  return (
    <Flex
      align="center"
      borderBottomWidth={1}
      borderColor="gray.200"
      flexShrink={0}
      justify="space-between"
      p={7}
    >
      <Flex direction="column" flex={1}>
        <VisitAssignmentHeaderHeadlines query={props.query} />
      </Flex>
      {props.query.isSuccess && (
        <Flex gap={2}>
          <VisitAssignmentSummaryButton id={props.query.data.id} />
          <VisitAssignmentButton id={props.query.data.id} status={props.query.data.status} />
        </Flex>
      )}
    </Flex>
  );
}

function VisitAssignmentHeaderHeadlines(props: { query: UseQueryResult<AssignmentRecord> }) {
  switch (props.query.status) {
    case "pending":
      return (
        <>
          <VisitAssignmentSubheading>Assignment</VisitAssignmentSubheading>
          <Box py={1}>
            <Skeleton height={9} rounded="full" width="512px" />
          </Box>
        </>
      );
    case "error":
      return (
        <>
          <VisitAssignmentSubheading>{String(props.query.error)}</VisitAssignmentSubheading>
          <VisitAssignmentHeading>Something went wrong</VisitAssignmentHeading>
        </>
      );
    case "success":
      return (
        <>
          <VisitAssignmentSubheading>
            {dateFormatter.toDateTime(props.query.data.createdAt)}
          </VisitAssignmentSubheading>
          <VisitAssignmentHeading>
            {formatAssignmentReason(props.query.data.reason)}
          </VisitAssignmentHeading>
        </>
      );
  }
}

const VisitAssignmentHeading = chakra(Text, {
  baseStyle: { fontSize: "3xl", fontWeight: "bold", letterSpacing: "-0.02em" },
});

const VisitAssignmentSubheading = chakra(Text, {
  baseStyle: { color: "gray.600", fontSize: "lg", fontWeight: "semibold" },
});
