import { Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useSearchPatientsQuery } from "../../modules/patient/hooks/useSearchPatientsQuery";
import { useDebounce } from "../hooks/useDebounce";
import EntityPreview from "./DialerEntityPreview";
import { EntitySelectItemShimmer } from "./EntitySelect";

interface Props {
  onClickDial: (E164number: string) => void;
}

const DialerPatientsSearch = (props: Props) => {
  const [patientSearchText, setPatientSearchText] = useState("");
  const debouncedPatientSearchText = useDebounce(patientSearchText, 200);
  const patients = useSearchPatientsQuery({ term: debouncedPatientSearchText });
  return (
    <>
      <Input
        placeholder="Search patients..."
        type="text"
        value={patientSearchText}
        onChange={(e) => setPatientSearchText(e.currentTarget.value)}
      />
      {(() => {
        if (debouncedPatientSearchText.trim() === "") {
          return <></>;
        }

        switch (patients.status) {
          case "pending":
            return <EntitySelectItemShimmer />;
          case "error":
            return <Text>Error loading</Text>;
          case "success": {
            if (patients.data.length === 0) {
              return <Text>Not Found</Text>;
            }

            return patients.data.map((patient) => (
              <EntityPreview
                key={patient.id}
                entity={{
                  type: "Patient",
                  ...patient,
                }}
                onClickDial={props.onClickDial}
              />
            ));
          }
        }
      })()}
    </>
  );
};

export default DialerPatientsSearch;
