import { Flex, Tag } from "@chakra-ui/react";
import { Messages } from "../../../../core/api";
import { isInboundEmailMessage } from "../../utils/comm-center-email-utils";
import { sortByInstant } from "../../../../shared/utils/date-utils";

interface Props {
  emailThread: Messages["CommCenterTicket"]["emailThread"];
  chatSummaryDetails: Messages["CommCenterTicket"]["chatSummaryDetails"];
}

const EmailPreviewCell = (props: Props) => {
  if (props.emailThread === null) {
    return <span>Unavailable</span>;
  }

  const unreadCount = props.emailThread.messages.filter(
    (x) => isInboundEmailMessage(x) && x.readAt === null
  ).length;

  let previewMessage = "Unavailable";
  if (props.chatSummaryDetails !== null) {
    previewMessage = props.chatSummaryDetails.summary;
  } else {
    const orderedMessages = sortByInstant(props.emailThread.messages, "createdAt", "desc");
    const lastMessage = orderedMessages[0];
    if (lastMessage !== undefined) {
      previewMessage = lastMessage.contentPlainText;
    }
  }
  return (
    <Flex gap={1}>
      <Tag bg="blue.500" color="white" hidden={unreadCount === 0} mr={2} rounded="full">
        {unreadCount}
      </Tag>
      {previewMessage}
    </Flex>
  );
};

export default EmailPreviewCell;
