import { convert, LocalDate } from "@js-joda/core";
import { dateUtils } from "../../../../scripts/consts/dateUtils";

//! @ngInject
class DatePickerCtrl implements ng.IComponentController {
  // ---- bindings ----- //
  ngModel?: LocalDate;
  onChange?: (date: LocalDate) => void;
  // ------------------- //

  model = new Date();

  constructor(private $scope: ng.IScope) {}

  $onInit() {
    this.ngModel = this.ngModel ?? LocalDate.now();

    this.model = convert(this.ngModel).toDate();

    this.$scope.$watch(
      "ctrl.ngModel",
      (newDate: LocalDate) => {
        this.model = convert(newDate).toDate();
      },
      true
    );
  }

  selectDate = () => {
    if (this.model === undefined) {
      return;
    }

    this.ngModel = dateUtils.dateToLocalDate(this.model);
    this.onChange?.(
      LocalDate.of(this.model.getFullYear(), this.model.getMonth() + 1, this.model.getDate())
    );
  };
}

export const datePickerComponent = {
  $name: "datePicker",
  templateUrl: "admin/modules/shared/components/date-picker/date-picker.component.html",
  controller: DatePickerCtrl,
  controllerAs: "ctrl",
  bindings: {
    ngModel: "=?",
    onChange: "<?",
  },
};
