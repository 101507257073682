import React from "react";
import useApi from "../../../shared/hooks/useApi";
import { PayrollBatchId } from "../../../shared/schema/schema";
import { useQuery } from "@tanstack/react-query";
import { TableContainer, Table, Thead, Th, Tbody, Td, Tr, Text, Box } from "@chakra-ui/react";
import PayrollVerificationTableStandardSectionCell from "./PayrollVerificationTableStandardSectionCell";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import PayrollVerificationTableNumberValue from "./PayrollVerificationTableNumberValue";
import { Messages } from "../../../core/api";
import PayrollVerificationTableDoubleCell from "./PayrollVerificationTableDoubleCell";

type Props = {
  payrollBatchId: PayrollBatchId;
  batchCount: number;
  showComparison: boolean;
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const secondsFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

function formatCurrency(amount: number) {
  return currencyFormatter.format(amount / 100);
}

function formatSecondsToHours(seconds: number) {
  return secondsFormatter.format(seconds / 3600) + " hrs";
}

export default function PayrollVerificationTable(props: Props) {
  const { queries } = useApi();

  const query = useQuery({
    ...queries.payroll.payrollVerification(props.payrollBatchId, props.batchCount),
    select: (payrollVerification) => {
      payrollVerification.batches.forEach((batch) => {
        batch.breakdowns.rates.sort(([a], [b]) => a - b);
        batch.breakdowns.seconds.sort(([a], [b]) => a - b);
        batch.breakdowns.amounts.sort(([a], [b]) => a - b);
      });
      return payrollVerification.batches.sort(
        (a, b) => b.precedingPayrollOrderNumber - a.precedingPayrollOrderNumber
      );
    },
  });

  const data = query.data ?? [];

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th key="empty-col"></Th>
            {data.map((batch) => (
              <Th
                key={batch.batchInfo.id}
                borderTopRadius="md"
                textAlign="center"
                transition="100ms ease-in-out"
              >
                <Box>{batch.batchInfo.id}</Box>
                <Box>{dateFormatter.toDate(batch.batchInfo.date)}</Box>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rowDefinitions.map((rowDef) => (
            <Tr key={rowDef.header}>
              <Td>{rowDef.header}</Td>
              {data.map((row) => (
                <React.Fragment key={row.batchInfo.id}>
                  {rowDef.rowRenderer(row, props.showComparison)}
                </React.Fragment>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

const rowDefinitions: {
  header: string;
  rowRenderer: (
    row: Messages["PayrollVerificationBatch"],
    showComparison: boolean
  ) => React.ReactNode;
}[] = [
  {
    header: "Total Amount",
    rowRenderer: (row, showComparison) => (
      <Td>
        <Text>
          <PayrollVerificationTableNumberValue
            currentValue={row.totals.overall.amount}
            formatter={formatCurrency}
            percentageChange={row.totals.overall.amountPctChange}
            showComparison={showComparison}
          />
          {" ( "}
          <PayrollVerificationTableNumberValue
            currentValue={row.totals.reversal.amount}
            formatter={formatCurrency}
            percentageChange={row.totals.reversal.amountPctChange}
            showComparison={showComparison}
          />
          {" ) "}
        </Text>
      </Td>
    ),
  },
  {
    header: "Total Hours",
    rowRenderer: (row, showComparison) => (
      <Td>
        <Text>
          <PayrollVerificationTableNumberValue
            currentValue={row.totals.overall.seconds}
            formatter={formatSecondsToHours}
            percentageChange={row.totals.overall.secondsPctChange}
            showComparison={showComparison}
          />
          {" ( "}
          <PayrollVerificationTableNumberValue
            currentValue={row.totals.reversal.seconds}
            formatter={formatSecondsToHours}
            percentageChange={row.totals.reversal.secondsPctChange}
            showComparison={showComparison}
          />
          {" ) "}
        </Text>
      </Td>
    ),
  },
  {
    header: "Old / New",
    rowRenderer: (row, showComparison) => (
      <Td>
        <Text>
          <PayrollVerificationTableNumberValue
            currentValue={row.oldNew.old.count}
            description="old items"
            percentageChange={row.oldNew.old.countPctChange}
            showComparison={showComparison}
          />
          {" / "}
          <PayrollVerificationTableNumberValue
            currentValue={row.oldNew.new.count}
            description="new items"
            percentageChange={row.oldNew.new.countPctChange}
            showComparison={showComparison}
          />
        </Text>
      </Td>
    ),
  },
  {
    header: "Caregivers",
    rowRenderer: (row, showComparison) => (
      <Td>
        <Box>
          <PayrollVerificationTableNumberValue
            currentValue={row.caregivers.all.count}
            description="caregivers"
            percentageChange={row.caregivers.all.countPctChange}
            showComparison={showComparison}
          />
        </Box>
        <Box>
          <PayrollVerificationTableNumberValue
            currentValue={row.caregivers.new.count}
            description="new"
            percentageChange={row.caregivers.new.countPctChange}
            showComparison={showComparison}
          />
        </Box>
        <Box>
          <PayrollVerificationTableNumberValue
            currentValue={row.caregivers.dropped.count}
            description="dropped"
            percentageChange={row.caregivers.dropped.countPctChange}
            showComparison={showComparison}
          />
        </Box>
      </Td>
    ),
  },
  {
    header: "New Payment ( Visits / All )",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableDoubleCell
        amountFormatter={formatCurrency}
        instanceAName="visits"
        instanceBname="items"
        itemA={row.visits.newPayment}
        itemB={row.totals.newPayment}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Adjustment ( Visits / All )",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableDoubleCell
        amountFormatter={formatCurrency}
        instanceAName="visits"
        instanceBname="items"
        itemA={row.visits.adjustment}
        itemB={row.totals.adjustment}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Reversal ( Visits / All )",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableDoubleCell
        amountFormatter={formatCurrency}
        instanceAName="visits"
        instanceBname="items"
        itemA={row.visits.reversal}
        itemB={row.totals.reversal}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Overtime",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableStandardSectionCell
        amountFormatter={formatCurrency}
        item={row.overtime}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Holiday",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableStandardSectionCell
        amountFormatter={formatCurrency}
        item={row.holiday}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Spread of Hours",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableStandardSectionCell
        amountFormatter={formatCurrency}
        item={row.soh}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Visits",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableStandardSectionCell
        amountFormatter={formatCurrency}
        instancesName="visits"
        item={row.visits.overall}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "PTO",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableStandardSectionCell
        amountFormatter={formatCurrency}
        instancesName="caregiver PTOs"
        item={row.pto}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Travel Time",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableStandardSectionCell
        amountFormatter={formatCurrency}
        instancesName="visit travels"
        item={row.travelTime}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Inservice",
    rowRenderer: (row, showComparison) => (
      <PayrollVerificationTableStandardSectionCell
        amountFormatter={formatCurrency}
        instancesName="inservice certificates"
        item={row.inservice}
        secondsFormatter={formatSecondsToHours}
        showComparison={showComparison}
      />
    ),
  },
  {
    header: "Amount Counts",
    rowRenderer: (row) => (
      <Td verticalAlign="top">
        {row.breakdowns.amounts.map(([amount, count]) => (
          <Box key={"amount" + amount}>
            <span>
              {formatCurrency(amount)}: {count}
            </span>
          </Box>
        ))}
      </Td>
    ),
  },
  {
    header: "Rate Counts",
    rowRenderer: (row) => (
      <Td verticalAlign="top">
        {row.breakdowns.rates.map(([rate, count]) => (
          <Box key={"rate" + rate}>
            <span>
              {formatCurrency(rate)}: {count}
            </span>
          </Box>
        ))}
      </Td>
    ),
  },
  {
    header: "Time Counts",
    rowRenderer: (row) => (
      <Td verticalAlign="top">
        {row.breakdowns.seconds.map(([seconds, count]) => (
          <Box key={"time" + seconds}>
            <span>
              {formatSecondsToHours(seconds)}: {count}
            </span>
          </Box>
        ))}
      </Td>
    ),
  },
];
