import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { VisitAssignmentTicketRelationLayout } from "./VisitAssignmentTicketRelationLayout";
import { VisitAssignmentTicketRelationButton } from "./VisitAssignmentTicketRelationButton";

export function VisitAssignmentTicketVisitBroadcastRelation(props: { ticket: CommCenterTicket }) {
  if (props.ticket.relatedVisitBroadcast === null) return null;

  const { id, startTime, endTime } = props.ticket.relatedVisitBroadcast;

  return (
    <VisitAssignmentTicketRelationLayout colorScheme="pink">
      This ticket is related to a broadcast{" "}
      <VisitAssignmentTicketRelationButton
        colorScheme="pink"
        params={{ broadcast: id }}
        to="app.visitAssignments.assignment"
      >
        {dateFormatter.toDateTimeRange(startTime, endTime)}
      </VisitAssignmentTicketRelationButton>
    </VisitAssignmentTicketRelationLayout>
  );
}
