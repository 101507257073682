import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Switch,
  Textarea,
} from "@chakra-ui/react";
import { noop } from "@chakra-ui/utils";
import { LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";
import React from "react";
import { z } from "zod";
import { Messages } from "../../../core/api";
import FileInput, { InputFileUploadDestination } from "../../../shared/components/FileInput";
import Select from "../../../shared/components/Select";
import {
  GlobalWorkflowHint,
  getHintOfGlobalWorkflowField,
} from "../../../shared/hooks/useGlobalWorkflowRunner";
import { zj } from "../../../shared/utils/zj";
import { isDefined } from "../../../utils";
import { AddButton } from "./AddButton";
import { MinusButton } from "./MinusButton";
import { RequiredAst } from "./RequiredAst";
import WorkflowEntityFormControl from "./WorkflowEntityFormControl";

const WorkflowBooleanFormControl = (props: {
  input: Messages["WorkflowBooleanField"] & { name: string };
  value: (boolean | null)[];
  errors: string[];
  hint: { value: boolean; display: string } | null;
  onChange: (value: (boolean | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    props.onChange(props.value.map((value, i) => (i === index ? e.target.checked : value)));
  };

  return (
    <BaseWorkflowFormControl
      errors={props.errors}
      hint={props.hint}
      input={props.input}
      label={props.input.name}
      renderField={(value, index) => (
        <Switch isChecked={value === true} size="lg" onChange={(e) => handleChange(e, index)} />
      )}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

export function BaseWorkflowFormControl<$Value>(props: {
  label: React.ReactNode;
  value: ($Value | null)[];
  errors: string[];
  input: Messages["WorkflowInput"];
  hint: { value: $Value; display: string } | null;
  renderField: (value: $Value | null, index: number) => React.ReactNode;
  onChange: (value: ($Value | null)[]) => void;
}) {
  if (props.input.array === true && props.input.type !== "file" && props.input.type !== "option") {
    return <ArrayWorkflowFormControl {...props} />;
  }

  return (
    <SingleWorkflowFormControl
      errors={props.errors}
      hint={props.hint}
      input={props.input}
      label={props.label}
      renderField={(value) => props.renderField(value ?? null, 0)}
      value={props.value[0]}
      onChange={(v) => props.onChange(v === undefined ? [] : [v])}
    />
  );
}

export const WorkflowFormControlHint = <$Value,>(props: {
  isSelected: boolean;
  hint: { value: $Value; display: string } | null;
  onChange: (value: $Value | null) => void;
}) => {
  if (props.hint === null || props.isSelected) {
    return <></>;
  }

  const { hint } = props;

  return (
    <FormHelperText>
      <Button size="sm" variant="link" onClick={() => props.onChange(hint.value)}>
        🪄 Autofill &quot;{hint.display}&quot;
      </Button>
    </FormHelperText>
  );
};

const SingleWorkflowFormControl = <$Value,>(props: {
  label: React.ReactNode;
  value: $Value | null;
  input: Messages["WorkflowInput"];
  errors: string[];
  hint: { value: $Value; display: string } | null;
  renderField: (value: $Value | null) => React.ReactNode;
  onChange: (value: $Value | null) => void;
}) => {
  const isRequired = props.input.optional !== true && props.input.nullable !== true;

  return (
    <FormControl isInvalid={props.errors.length > 0}>
      <FormLabel>
        {props.label}
        {isRequired && <RequiredAst />}:
      </FormLabel>
      {props.renderField(props.value)}
      <WorkflowFormControlHint
        hint={props.hint}
        isSelected={props.value !== null}
        onChange={props.onChange}
      />
      {props.errors.length > 0 && <FormErrorMessage>{props.errors[0]}</FormErrorMessage>}
    </FormControl>
  );
};

const ArrayWorkflowFormControl = <$Value,>(props: {
  label: React.ReactNode;
  value: ($Value | null)[];
  input: Messages["WorkflowInput"];
  errors: string[];
  renderField: (value: $Value | null, index: number) => React.ReactNode;
  onChange: (value: ($Value | null)[]) => void;
}) => {
  const isRequired = props.input.optional !== true && props.input.nullable !== true;

  return (
    <FormControl isInvalid={props.errors.length > 0}>
      <FormLabel>
        {props.label}
        {isRequired && <RequiredAst />}:
      </FormLabel>
      <Flex direction="column" gap={2}>
        {props.value.map((value, index) => (
          <Flex key={index} align="center" gap={2} justify="space-between">
            {props.renderField(value, index)}
            {index === 0 ? (
              <AddButton onClick={() => props.onChange([...props.value, null])} />
            ) : (
              <MinusButton
                onClick={() => props.onChange(props.value.filter((_, i) => i !== index))}
              />
            )}
          </Flex>
        ))}
      </Flex>
      {props.errors.length > 0 && <FormErrorMessage>{props.errors[0]}</FormErrorMessage>}
    </FormControl>
  );
};

const WorkflowDateFormControl = (props: {
  input: Messages["WorkflowDateField"] & { name: string };
  value: (LocalDate | null)[];
  hint: { value: LocalDate; display: string } | null;
  errors: string[];
  onChange: (value: (LocalDate | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : LocalDate.parse(e.target.value);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <BaseWorkflowFormControl
      errors={props.errors}
      hint={props.hint}
      input={props.input}
      label={props.input.name}
      renderField={(value, index) => (
        <Input
          type="date"
          value={value?.toString() ?? ""}
          onChange={(e) => handleChange(e, index)}
        />
      )}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const WorkflowDateTimeFormControl = (props: {
  input: Messages["WorkflowDateTimeField"] & { name: string };
  value: (LocalDateTime | null)[];
  hint: { value: LocalDateTime; display: string } | null;
  errors: string[];
  onChange: (value: (LocalDateTime | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : LocalDateTime.parse(e.target.value);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <BaseWorkflowFormControl
      errors={props.errors}
      hint={props.hint}
      input={props.input}
      label={props.input.name}
      renderField={(value, index) => (
        <Input
          type="datetime-local"
          value={value?.toString() ?? ""}
          onChange={(e) => handleChange(e, index)}
        />
      )}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const WorkflowTimeFormControl = (props: {
  input: Messages["WorkflowTimeField"] & { name: string };
  value: (LocalTime | null)[];
  hint: { value: LocalTime; display: string } | null;
  errors: string[];
  onChange: (value: (LocalTime | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : LocalTime.parse(e.target.value);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <BaseWorkflowFormControl
      errors={props.errors}
      hint={props.hint}
      input={props.input}
      label={props.input.name}
      renderField={(value, index) => (
        <Input
          type="time"
          value={value?.toString() ?? ""}
          onChange={(e) => handleChange(e, index)}
        />
      )}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const WorkflowEntityFormControlWrapper = (props: {
  input: Messages["WorkflowEntityField"] & { name: string };
  value: (unknown | null)[];
  hint: { value: unknown; display: string } | null;
  errors: string[];
  onChange: (value: (unknown | null)[]) => void;
}) => {
  const isRequired = props.input.optional !== true && props.input.nullable !== true;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : parseInt(e.target.value, 10);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <WorkflowEntityFormControl
      errors={props.errors}
      hint={props.hint}
      input={props.input}
      isRequired={isRequired}
      value={props.value}
      onChange={props.onChange}
      onChangeField={handleChange}
    />
  );
};

const WorkflowNumberFormControl = (props: {
  input: Messages["WorkflowNumberField"] & { name: string };
  hint: { value: number; display: string } | null;
  value: (number | null)[];
  errors: string[];
  onChange: (value: (number | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : parseInt(e.target.value, 10);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <BaseWorkflowFormControl
      errors={props.errors}
      hint={props.hint}
      input={props.input}
      label={props.input.name}
      renderField={(value, index) => (
        <Input
          type="number"
          value={value?.toString() ?? ""}
          onChange={(e) => handleChange(e, index)}
        />
      )}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const WorkflowTextareaFormControl = (props: {
  input: Messages["WorkflowTextField"] & { name: string };
  hint: { value: string; display: string } | null;
  value: (string | null)[];
  errors: string[];
  onChange: (value: (string | null)[]) => void;
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const v = e.target.value === "" ? null : e.target.value;
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <BaseWorkflowFormControl
      errors={props.errors}
      hint={props.hint}
      input={props.input}
      label={props.input.name}
      renderField={(value, index) => {
        return props.input.type === "textarea" ? (
          <Textarea value={value?.toString() ?? ""} onChange={(e) => handleChange(e, index)} />
        ) : (
          <Input
            type="text"
            value={value?.toString() ?? ""}
            onChange={(e) => handleChange(e, index)}
          />
        );
      }}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const zFileData = z.object({
  key: z.string(),
  file: z.instanceof(File),
});

type FileData = z.infer<typeof zFileData>;

const WorkflowFileFormControl = (props: {
  input: Messages["WorkflowFileField"] & { name: string };
  value: (FileData | null)[];
  errors: string[];
  fileUploadDestination: InputFileUploadDestination;
  onChange: (value: (FileData | null)[]) => void;
}) => {
  const files = React.useMemo(
    () => props.value.filter(isDefined).map((f) => ({ ...f, type: "s3-object" as const })),
    [props.value]
  );

  return (
    <BaseWorkflowFormControl
      errors={props.errors}
      hint={null}
      input={props.input}
      label={props.input.name}
      renderField={() => (
        <FileInput
          files={files}
          fileUploadDestination={props.fileUploadDestination}
          multiple={props.input.array === true}
          onChange={props.onChange}
        />
      )}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const WorkflowOptionFormControl = <T,>(props: {
  input: Messages["WorkflowOptionField"] & { name: string };
  value: (T | null)[];
  errors: string[];
  onChange: (value: (T | null)[]) => void;
}) => {
  const values = props.value.filter(isDefined);

  return (
    <BaseWorkflowFormControl
      errors={props.errors}
      hint={null}
      input={props.input}
      label={props.input.name}
      renderField={(value) => {
        if (props.input.array === true) {
          return (
            <Select
              label={props.input.name}
              multiple={true}
              options={props.input.options.map((o) => ({
                label: `${o.value}`,
                value: o.key as T,
              }))}
              value={values}
              onChange={(selected) => props.onChange(selected ?? [])}
            />
          );
        }

        return (
          <Select
            label={props.input.name}
            multiple={false}
            options={props.input.options.map((o) => ({
              label: `${o.value}`,
              value: o.key as T,
            }))}
            value={value ?? null}
            onChange={(selected) => props.onChange([selected ?? null])}
          />
        );
      }}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const WorkflowUnionFormControl = (
  props: Omit<BaseWorkflowFormControlProps, "onChange" | "input"> & {
    input: Messages["WorkflowUnionField"] & { name: string };
    errors: string[];
    hints: GlobalWorkflowHint[];
    fileUploadDestination: InputFileUploadDestination;
    onChange: (field: Messages["WorkflowDataFieldType"], value: unknown[]) => void;
  }
) => {
  const [selected, setSelected] = React.useState<Messages["WorkflowDataFieldType"] | null>(null);

  return (
    <Flex direction="column" gap={6}>
      <BaseWorkflowFormControl
        errors={props.errors}
        hint={null}
        input={props.input}
        label={props.input.name}
        renderField={() => {
          return (
            <Select
              label="Type"
              multiple={false}
              options={props.input.union.map((o) => ({
                label: o.type === "entity" ? o.entity : o.type,
                value: o,
              }))}
              searchable={false}
              value={selected}
              onChange={(v) => {
                props.onChange(v ?? props.input.union[0], [null]);
                setSelected(v ?? null);
              }}
            />
          );
        }}
        value={props.value}
        onChange={noop}
      />
      {selected && (
        <WorkflowFormControl
          errors={props.errors}
          fileUploadDestination={props.fileUploadDestination}
          hints={props.hints}
          input={{
            ...selected,
            name: selected.type === "entity" ? selected.entity : selected.type,
          }}
          value={props.value}
          onChange={(value) => props.onChange(selected, value?.value ?? [null])}
        />
      )}
    </Flex>
  );
};

type WorkflowFormControlPropsOfType<$Input, $Type = unknown> = {
  input: $Input;
  value: ($Type | null)[];
  onChange: (value: ($Type | null)[]) => void;
};

type BaseWorkflowFormControlProps = {
  input: Messages["WorkflowInput"];
  value: unknown[];
  onChange: unknown;
};

type InputMapper = {
  entity: {
    input: Messages["WorkflowEntityField"] & { name: string };
    type: number;
  };
  text: {
    input: Messages["WorkflowTextField"] & { name: string };
    type: string;
  };
  textarea: {
    input: Messages["WorkflowTextField"] & { name: string };
    type: string;
  };
  file: {
    input: Messages["WorkflowFileField"] & { name: string };
    type: FileData;
  };
  number: {
    input: Messages["WorkflowNumberField"] & { name: string };
    type: number;
  };
  boolean: {
    input: Messages["WorkflowBooleanField"] & { name: string };
    type: boolean;
  };
  date: {
    input: Messages["WorkflowDateField"] & { name: string };
    type: LocalDate;
  };
  datetime: {
    input: Messages["WorkflowDateTimeField"] & { name: string };
    type: LocalDateTime;
  };
  time: {
    input: Messages["WorkflowTimeField"] & { name: string };
    type: LocalTime;
  };
  option: {
    input: Messages["WorkflowOptionField"] & { name: string };
    type: Messages["WorkflowOptionField"]["optionType"]["type"];
  };
  union: {
    input: Messages["WorkflowUnionField"] & { name: string };
    type: Messages["WorkflowUnionField"]["union"][number]["type"];
  };
};

function isInputTypeOf<T extends keyof InputMapper>(
  p: BaseWorkflowFormControlProps,
  v: T
): p is WorkflowFormControlPropsOfType<InputMapper[T]["input"], InputMapper[T]["type"]> {
  return p.input.type === v;
}

export const WorkflowFormControl = (
  props: Omit<BaseWorkflowFormControlProps, "onChange"> & {
    errors: string[];
    hints: GlobalWorkflowHint[];
    fileUploadDestination: InputFileUploadDestination;
    onChange: (value: NullableResolvedField) => void;
  }
) => {
  const handleChange = (field: Messages["WorkflowDataFieldType"], value: unknown[]) => {
    if (field.type === "option" || field.type === "union") {
      throw new Error("Final value must be a primitive type");
    }

    props.onChange({ field, value });
  };

  if (isInputTypeOf(props, "boolean")) {
    const hint = getHintOfGlobalWorkflowField(props.input, props.hints);
    return (
      <WorkflowBooleanFormControl
        hint={hint}
        {...props}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "date")) {
    const hint = getHintOfGlobalWorkflowField(props.input, props.hints);
    return (
      <WorkflowDateFormControl
        hint={hint}
        {...props}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "datetime")) {
    const hint = getHintOfGlobalWorkflowField(props.input, props.hints);
    return (
      <WorkflowDateTimeFormControl
        hint={hint}
        {...props}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "time")) {
    const hint = getHintOfGlobalWorkflowField(props.input, props.hints);
    return (
      <WorkflowTimeFormControl
        hint={hint}
        {...props}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "entity")) {
    const hint = getHintOfGlobalWorkflowField(props.input, props.hints);
    return (
      <WorkflowEntityFormControlWrapper
        hint={hint}
        {...props}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "number")) {
    const hint = getHintOfGlobalWorkflowField(props.input, props.hints);
    return (
      <WorkflowNumberFormControl
        hint={hint}
        {...props}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "text")) {
    const hint = getHintOfGlobalWorkflowField(props.input, props.hints);
    return (
      <WorkflowTextareaFormControl
        hint={hint}
        {...props}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "textarea")) {
    const hint = getHintOfGlobalWorkflowField(props.input, props.hints);
    return (
      <WorkflowTextareaFormControl
        hint={hint}
        {...props}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "file")) {
    return (
      <WorkflowFileFormControl
        {...props}
        value={props.value}
        onChange={(value) => handleChange(props.input, value)}
      />
    );
  }

  if (isInputTypeOf(props, "option")) {
    return (
      <WorkflowOptionFormControl
        {...props}
        onChange={(value) => handleChange(props.input.optionType, value)}
      />
    );
  }

  if (isInputTypeOf(props, "union")) {
    return <WorkflowUnionFormControl {...props} onChange={handleChange} />;
  }

  return (
    <p>
      <code>DataTypeFormControl</code> not implemented for type <code>{props.input.type}</code>
    </p>
  );
};

function createZodValidatorForBasicWorkflowFieldType(
  property: Exclude<Messages["WorkflowDataFieldType"], { type: "union" | "option" }>
): z.ZodTypeAny {
  let zPropertyValidator = ((): z.ZodTypeAny => {
    switch (property.type) {
      case "number":
        return z.number();
      case "boolean":
        return z.boolean();
      case "entity":
        return z.union([z.string(), z.number()]);
      case "text":
        return z.string();
      case "textarea":
        return z.string();
      case "date":
        return zj.localDate();
      case "datetime":
        return zj.localDateTime();
      case "time":
        return zj.localTime();
      case "file":
        return zFileData.transform((v) => v.key);
      case "unknown":
        return z.unknown();
    }
  })();

  if (property.array === true) {
    zPropertyValidator = zPropertyValidator.array();
  }

  if (property.optional === true) {
    zPropertyValidator = zPropertyValidator.optional();
  }

  if (property.nullable === true) {
    zPropertyValidator = zPropertyValidator.nullable();
  }

  return zPropertyValidator;
}

function createZodValidatorForWorkflowFieldType(
  property: Messages["WorkflowDataFieldType"]
): z.ZodTypeAny {
  if (property.type === "option") {
    return createZodValidatorForWorkflowFieldType(property.optionType);
  }

  if (property.type === "union") {
    const [a, b, ...rest] = property.union.map(createZodValidatorForWorkflowFieldType);

    if (a === undefined || b === undefined) {
      throw new Error("Union must have at least two elements");
    }

    return z.union([a, b, ...rest]);
  }

  return createZodValidatorForBasicWorkflowFieldType(property);
}

function createWorkflowDataFieldsRecordParser<
  $Schema extends Map<string, Messages["WorkflowDataFieldType"]>
>(schema: $Schema) {
  const zObjectProperyValidators = {};

  for (const [key, property] of schema.entries()) {
    const zPropertyValidator = createZodValidatorForWorkflowFieldType(property);

    Object.assign(zObjectProperyValidators, { [key]: zPropertyValidator });
  }

  return z.object(zObjectProperyValidators);
}

export type NullableResolvedField = {
  field: Messages["WorkflowResolvedDataFieldType"];
  value: unknown[];
} | null;

export type UnresolvedField = {
  field: Messages["WorkflowInput"];
  value: unknown[];
};

export const WorkflowInputForm = (props: {
  fields: Map<string, Messages["WorkflowInput"]>;
  fileUploadDestination: InputFileUploadDestination;
  hints: GlobalWorkflowHint[];
  renderButton: () => React.ReactNode;
  onSubmit: (values: Record<string, unknown>) => void;
}) => {
  const [values, setValues] = React.useState<Record<string, UnresolvedField>>(() => {
    const initialValues: Record<string, UnresolvedField> = {};

    for (const [key, value] of props.fields.entries()) {
      initialValues[key] = { field: value, value: [null] };
    }

    return initialValues;
  });

  const [errors, setErrors] = React.useState<Record<string, string[]>>({});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const parsedFields = Object.entries(values).reduce((acc, [key, value]) => {
      const input = props.fields.get(key);
      const newValue = (input?.array === true ? value?.value : value?.value[0]) ?? undefined;
      acc[key] = newValue;
      return acc;
    }, {} as Record<string, unknown>);

    const result = createWorkflowDataFieldsRecordParser(props.fields).safeParse(parsedFields);

    if (!result.success) {
      return setErrors(result.error.flatten().fieldErrors);
    }

    const formValues = Object.fromEntries(
      Object.entries(result.data).map(([key, value]) => {
        const resolvedField = values[key]?.field;
        const field = props.fields.get(key);

        if (resolvedField === undefined || field === undefined) {
          throw new Error(`Field for key ${key} not found`);
        }

        // union field value is: {
        //   type: WorkflowResolvedDataFieldType; --> the selected union field
        //   value: unknown[]
        // }
        // all other field values are: unknown

        return [
          key,
          field.type === "union"
            ? {
                type: resolvedField,
                value,
              }
            : value,
        ];
      })
    );

    return props.onSubmit(formValues);
  };

  const handleChange = (key: string, resolved: NullableResolvedField) => {
    const originalField = props.fields.get(key);

    if (originalField === undefined) {
      throw new Error(`Field for key ${key} not found`);
    }

    const newValue: UnresolvedField = {
      field: resolved === null ? originalField : { ...resolved.field, name: key },
      value: resolved === null ? [null] : resolved.value,
    };

    setValues((prev) => ({ ...prev, [key]: newValue }));
    setErrors((prev) => ({ ...prev, [key]: [] }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap={6}>
        {[...props.fields.entries()].map(([key, value]) => (
          <WorkflowFormControl
            key={key}
            errors={errors[key] ?? []}
            fileUploadDestination={props.fileUploadDestination}
            hints={props.hints}
            input={value}
            value={values[key]?.value ?? [null]}
            onChange={(v) => handleChange(key, v)}
          />
        ))}
        {props.renderButton()}
      </Flex>
    </form>
  );
};
