import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { VisitAssignmentId } from "../../../../../shared/schema/schema";
import { WEBAPP_FOOTER_HEIGHT } from "../../../../../shared/utils";
import { VisitAssignmentDrawerBody } from "./VisitAssignmentDrawerBody";

export function VisitAssignmentDrawer(props: {
  isOpen: boolean;
  assignmentId: VisitAssignmentId | null;
  onClose: () => void;
}) {
  return (
    <Drawer
      blockScrollOnMount={false}
      isOpen={props.isOpen}
      size="85%"
      trapFocus={false}
      onClose={props.onClose}
    >
      <DrawerOverlay />
      <DrawerContent mb={WEBAPP_FOOTER_HEIGHT}>
        {props.assignmentId && <VisitAssignmentDrawerBody id={props.assignmentId} />}
      </DrawerContent>
    </Drawer>
  );
}
