import { chakra, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { fromAddressText } from "mf-google-geocoder";
import { useController, useFormContext } from "react-hook-form";
import { GOOGLE_MAPS_API_KEY } from "../../consts";
import Select from "../Select";
import { STATES } from "./address-picker-consts";
import { CustomAddress } from "./AddressPickerModal";

const mfGoogleGeocoderApiOptions = { apiKey: GOOGLE_MAPS_API_KEY, mfAutoFix: true };
function AddressPickerFields() {
  const { control, setValue, register } = useFormContext<CustomAddress>();

  const state = useController({ name: "state", control });
  const zip = useController({ name: "zip", control });

  const handleChangeState = (val: string | undefined) => {
    if (val === undefined) return;
    setValue("state", val);
    const stateDetails = STATES.find((state) => state.value === val);
    if (stateDetails !== undefined) {
      setValue("lat", stateDetails.location.lat);
      setValue("lng", stateDetails.location.lng);
      setValue("timezone", stateDetails.timezone);
    }
  };

  const handleChangeZipCode = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const zip = event?.target.value;
    setValue("zip", zip);

    const changeAddressFieldsAccordingToZipCode = async (zip: string) => {
      const address = await fromAddressText(zip, mfGoogleGeocoderApiOptions);
      setValue("lat", address.location.lat);
      setValue("lng", address.location.lng);
      address.state && setValue("state", address.state);
      address.city && setValue("city", address.city);
      address.county && setValue("county", address.county);
    };
    await changeAddressFieldsAccordingToZipCode(zip);
  };

  return (
    <Flex direction="column" gap={4}>
      <Flex direction="row" gap={2}>
        <FormControl isRequired>
          <FormLabel>Zip code (auto-fills state, city & county)</FormLabel>
          <Input
            autoComplete="new-password"
            name="zip"
            placeholder="Zip code"
            value={zip.field.value ?? null}
            onChange={(e) => handleChangeZipCode(e)}
          />
        </FormControl>
        <Flex direction="column" w="100%">
          <Flex>
            <FormLabel mr={0}>State</FormLabel>
            <AddressPickerFields.StateLabelAsterik>*</AddressPickerFields.StateLabelAsterik>
          </Flex>
          <Select
            allowUnselect={false}
            buttonProps={{ w: "100%", justifyContent: "space-between" }}
            label="State"
            multiple={false}
            options={STATES}
            searchable={true}
            value={state.field.value ?? null}
            onChange={handleChangeState}
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap={2}>
        <FormControl isRequired>
          <FormLabel>City</FormLabel>
          <Input autoComplete="new-password" placeholder="City" {...register("city")} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>County</FormLabel>
          <Input autoComplete="new-password" placeholder="County" {...register("county")} />
        </FormControl>
      </Flex>
      <Flex direction="row" gap={2}>
        <FormControl isRequired>
          <FormLabel>Street name</FormLabel>
          <Input autoComplete="new-password" placeholder="Street name" {...register("street")} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Street number</FormLabel>
          <Input
            autoComplete="new-password"
            placeholder="Street number"
            {...register("streetNumber")}
          />
        </FormControl>
      </Flex>
    </Flex>
  );
}

const StateLabelAsterik = chakra(FormLabel, {
  baseStyle: {
    color: "red",
    marginInlineStart: "var(--chakra-space-1)",
  },
});
AddressPickerFields.StateLabelAsterik = StateLabelAsterik;

export default AddressPickerFields;
