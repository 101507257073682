import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import EntityCard from "../../../shared/components/EntityCard";
import { AgencyMemberId } from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { PatientIntakeProfileDraft } from "../patient-intake.types";
import useIntakeAgencyMembers from "../../../shared/hooks/useIntakeAgencyMembers";
import IntakeAgencyMemberSelect from "../../../shared/components/IntakeAgencyMemberSelect";
import { getFullName } from "../../../shared/utils/get-full-name";

export function AssignedAgencyMember(props: {
  isEdit: boolean;
  assignedAgencyMember: PatientIntakeProfileDraft["assignedAgencyMember"];
  onChange: (value: Messages["IntakePatientProfile"]["assignedAgencyMember"]) => void;
}) {
  const { data: agencyMembers } = useIntakeAgencyMembers();

  const handleChange = (agencyMemberId: AgencyMemberId | undefined) => {
    if (agencyMemberId === undefined) {
      props.onChange(null);
    } else {
      const agencyMember = agencyMembers?.find((x) => x.id === agencyMemberId);
      if (agencyMember !== undefined) {
        props.onChange({
          id: agencyMember.id,
          name: getFullName(agencyMember),
        });
      }
    }
  };

  return (
    <FormControl>
      <FormLabel>Assigned Agency Member</FormLabel>
      {props.isEdit ? (
        <IntakeAgencyMemberSelect
          allowUnselect={true}
          multiple={false}
          value={props.assignedAgencyMember?.id ?? null}
          onChange={handleChange}
        />
      ) : (
        fmap(props.assignedAgencyMember, (agencyMember) => (
          <EntityCard
            entity={{
              type: "Agency Member",
              id: agencyMember.id,
              fullName: agencyMember.name,
              photoUrl: null,
            }}
          />
        )) ?? <Input isDisabled value="Not Assigned" />
      )}
    </FormControl>
  );
}
