import { Image, ImageProps } from "@chakra-ui/react";
import React from "react";
import { Shimmer } from "react-shimmer";
import useCacheSignedUrlQuery from "../hooks/useCacheSignedUrlQuery";

type Props = ImageProps & { src: string; fallback?: React.ReactNode };

const CachedSignedImage = (props: Props) => {
  const { width, height, src, fallback, ...rest } = props;
  const query = useCacheSignedUrlQuery(src);

  if (query.isPending) {
    return <>{fallback}</>;
  }

  if (query.data !== undefined) {
    return (
      <Image borderRadius="lg" h={height} objectFit="cover" src={query.data} w={width} {...rest} />
    );
  }

  return null;
};

export const CachedSignedImageWithShimmer = (
  props: Omit<Props, "w" | "h"> & { width: number; height: number }
) => {
  return (
    <CachedSignedImage
      {...props}
      fallback={<Shimmer height={props.height} width={props.width} />}
    />
  );
};

export default CachedSignedImage;
