import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import useApi from "../../../../../shared/hooks/useApi";
import { WorkflowSkillId } from "../../../../../shared/schema/schema";
import { createDisclosureTriggerComponent } from "../../../../../shared/utils/create-disclosure-trigger-component";

interface Props {
  skillId: WorkflowSkillId;
  isOpen: boolean;
  onClose: () => void;
}

export default function DeleteSkillAlertDialog(props: Props) {
  const leastDestructiveRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertContent leastDestructiveRef={leastDestructiveRef} {...props} />
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

function AlertContent(props: Props & { leastDestructiveRef: React.RefObject<HTMLButtonElement> }) {
  const queryClient = useQueryClient();
  const { api, queries } = useApi();
  const toast = useToast();

  const removeSkill = useMutation({
    mutationFn: () =>
      api.delete("./workflow_tasks/skills/:workflowSkillId", {
        path: { workflowSkillId: props.skillId },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(queries.workflow.skills());
      props.onClose();
      toast({
        title: `Skill removed`,
        description: "Skill removed successfully.",
        status: "success",
        position: "top-right",
      });
    },
    onError: () => {
      toast({
        title: `Error in removing skill`,
        description: "Could not remove skill. Please try again later.",
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Delete Skill
      </AlertDialogHeader>

      <AlertDialogBody>
        Are you sure you want to remove <b>{props.skillId}</b>? You can&rsquo;t undo this action
        afterwards.
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button ref={props.leastDestructiveRef} onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          colorScheme="red"
          isLoading={removeSkill.isPending}
          ml={3}
          onClick={() => removeSkill.mutate()}
        >
          Delete
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}

export const DeleteSkillAlertDialogTrigger = createDisclosureTriggerComponent<{
  id: WorkflowSkillId;
}>({
  displayName: "DeleteSkillAlertDialogTrigger",
  render: (props) => (
    <DeleteSkillAlertDialog
      isOpen={props.disclosure.isOpen}
      skillId={props.id}
      onClose={props.disclosure.onClose}
    />
  ),
});
