import { Box, Flex, Text } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import { VisitAssignmentId, WorkflowTaskInstanceId } from "../../../../../shared/schema/schema";
import { VisitAssignmentDrawerBody } from "../../../../visit/pages/assignments/components/VisitAssignmentDrawerBody";
import HumanTaskDefaultForm from "../HumanTaskDefaultForm";
import { HumanTaskSubmitParams } from "../HumanTaskForm";

interface Props {
  workflowTaskInstanceId: WorkflowTaskInstanceId;
  visitAssignmentId: VisitAssignmentId;
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  onSubmit: (params: HumanTaskSubmitParams) => void;
}

export default function HumanTaskVisitAssignmentLayout(props: Props) {
  return (
    <Flex direction="column" gap={3} height="full">
      <Box my={4} px={8} rounded="lg">
        <Text fontSize="lg" fontWeight="semibold">
          Instructions:
        </Text>
        <Text fontSize="lg" whiteSpace="pre-wrap">
          Resolve the visit assignment.
        </Text>

        <Box mt={4}>
          <HumanTaskDefaultForm
            output={props.output}
            workflowTaskInstanceId={props.workflowTaskInstanceId}
            onSubmit={props.onSubmit}
          />
        </Box>
      </Box>

      <Box borderColor="gray.200" borderTopWidth={1} flex={1}>
        <VisitAssignmentDrawerBody id={props.visitAssignmentId} />
      </Box>
    </Flex>
  );
}
