import { Messages } from "../../../core/api";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { isEmailTicket } from "../utils/comm-center-email-utils";
import EmailTicketItem from "./Emails/EmailTicketItem";
import ChatTicketItem from "./ChatTicketItem";

interface Props {
  ticket: Messages["CommCenterTicket"];
  isActive: boolean;
  onClick: (ticketId: CommCenterTicketId) => void;
}

const TicketItem = (props: Props) => {
  return isEmailTicket(props.ticket) ? (
    <EmailTicketItem {...props} ticket={props.ticket} />
  ) : (
    <ChatTicketItem {...props} />
  );
};

export default TicketItem;
