import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: '"open sans", sans-serif',
    body: '"open sans", sans-serif',
  },
  colors: {
    asd: {
      "50": "hsla(214, 100%, 97%, 1)",
      "100": "hsla(214, 95%, 93%, 1)",
      "200": "hsla(213, 97%, 87%, 1)",
      "300": "hsla(212, 96%, 78%, 1)",
      "400": "hsla(213, 94%, 68%, 1)",
      "500": "hsla(217, 91%, 60%, 1)",
      "600": "hsla(221, 83%, 53%, 1)",
      "700": "hsla(224, 76%, 48%, 1)",
      "800": "hsla(226, 71%, 40%, 1)",
      "900": "hsla(224, 64%, 33%, 1)",
    },
    blue: {
      "50": "hsla(214, 100%, 97%, 1)",
      "100": "hsla(214, 95%, 93%, 1)",
      "200": "hsla(213, 97%, 87%, 1)",
      "300": "hsla(212, 96%, 78%, 1)",
      "400": "hsla(213, 94%, 68%, 1)",
      "500": "hsla(217, 91%, 60%, 1)",
      "600": "hsla(221, 83%, 53%, 1)",
      "700": "hsla(224, 76%, 48%, 1)",
      "800": "hsla(226, 71%, 40%, 1)",
      "900": "hsla(224, 64%, 33%, 1)",
    },
  },
  zIndices: {
    ticketPopup: 1400,
    phoneNumberDialerPopup: 1402,
    afterCallModal: 1403,
    workflowRunnerModal: 1501,
    activeCallBanner: 1501,
  },
  sizes: {
    "3.5xl": "52rem",
  },
  components: {
    Drawer: {
      sizes: {
        "2xl": { dialog: { maxW: "8xl" } },
        "80%": { dialog: { maxW: "80%" } },
        "85%": { dialog: { maxW: "85%" } },
      },
    },
  },
});
