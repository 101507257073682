import { CommCenterTicketId, CaregiverId } from "../../../../shared/schema/schema";
import useApi from "../../../../shared/hooks/useApi";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import CommunicationLogWrapper from "./CommunicationLogWrapper";
import { useCommCenterTicketPopup } from "../../hooks/useCommCenterTicketPopup";
import { EntityCommunicationsAdjustedFilters } from "../entity-communications/entity-communications.types";
import { filterCommunicationsLog } from "../entity-communications/entity-communications.utils";

interface Props {
  caregiverId: CaregiverId;
  filters?: EntityCommunicationsAdjustedFilters;
  emptyCommunicationsComponent?: React.ReactNode;
}

function CaregiverCommunicationLog(props: Props) {
  const { queries } = useApi();
  const ticketPopup = useCommCenterTicketPopup();

  const query = useQuery({
    ...queries.caregiver.communicationLog(props.caregiverId),
    placeholderData: keepPreviousData,
    select: (data) => filterCommunicationsLog(data, props.filters),
  });

  const handleClickTicket = (ticketId: CommCenterTicketId) => {
    ticketPopup.open({
      primaryEntity: "Caregiver",
      caregiverId: props.caregiverId,
      defaultTicketId: ticketId,
      contactDetails: null,
    });
  };

  return (
    <CommunicationLogWrapper
      emptyCommunicationsComponent={props.emptyCommunicationsComponent}
      query={query}
      onClickTicket={handleClickTicket}
    />
  );
}

export default CaregiverCommunicationLog;
