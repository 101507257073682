//! @ngInject
export function billingInvoicesToExportCtrl($scope, $rootScope, NgTableParams, DatabaseApi, itemSearchPageManager, selectionLogic, toaster, $filter, FilterUtils, billingInvoicesProcessor) {

    $scope.tableParams = null;
    $scope.isLoadingItems = false;
    $scope.globalFilter = { val: "" };

    function initialize() {
        initPageManager();
        initLogics();
        $scope.pageManager.resetSearchParamsByUrl();

        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingInvoicesToExportCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }
    }

    $scope.$on("$destroy", function () {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingInvoicesToExportCtrl = angular.copy(filters);
    });

    // ?
    $scope.$on("refresh_invoices", function () {
        $scope.loadItems();
    })

    const initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/invoices_to_export");

        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();

        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initContractTypesParam();


        $scope.pageManager.initSearchParam("status", "NEW");

        $scope.pageManager.initSearchParam("includeAwaitingResponse", false);
        $scope.pageManager.initSearchParam("includeReadyToExport", false);

        $scope.pageManager.initSearchParam("includeFullyPaid", false);

        $scope.pageManager.initSearchParam("dateByInvoice", true, {
            placeholderValue: true,
            queryName: "date-by-invoice",
        });
    };

    $scope.isRowDisabled = (row) => !row.isReadyToExport;

    $scope.changeSearchStatus = (val) => {
        if ((val === "ADJUSTMENT" || val === "NEGATIVE ADJUSTMENT") && $scope.pageManager.searchParams.status.value === "NEW") {
            $scope.pageManager.updateSearchParamValue("includeAwaitingResponse", false);
            $scope.pageManager.updateSearchParamValue("includeReadyToExport", false);
        }

        $scope.pageManager.searchParams.status.value = val;
    }

    $scope.areAllItemsSelected = false;
    function toggleAllItems () {
        var areAllItemsSelected = $scope.selectionLogic.data.areAllItemsSelected;
        $scope.selectionLogic.data.items.forEach(function (item) {
            if (!$scope.isRowDisabled(item)) {
                $scope.selectionLogic.toggleItem(item, areAllItemsSelected);
            }
        });
    }

    function initLogics(itemIds) {
        initSelection(itemIds || []);
    };

    const initSelection = function (items) {
        $scope.areAllItemsSelected = false;
        $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogic.addItemToCollection(item);
        }, undefined, $scope.isRowDisabled);
        if (items) {
            items.forEach((item) => {
                $scope.selectionLogic.initItem(item);
            });
        }
    };
    $scope.loadItems = function () {
        $scope.isLoadingItems = true;
        $scope.globalFilter.val = '';

        $scope.pageManager.executeSearch().then(function ({ data }) {
            $scope.invoices = data.invoices;
            initLogics($scope.invoices);
            renderPage($scope.invoices);
        }).catch(function (error) {
            toaster.pop("error", "Failed to load invoices");
        }).finally(function () {
            $scope.isLoadingItems = false;
        });
    };

    const mapPageItems = function (pageItems) {
        if (!$scope.patientsMap) {
            $scope.patientsMap = DatabaseApi.patients() || {};
        }
        pageItems.forEach(function (item) {
            if (item.patientId) {
                item.patient = $scope.patientsMap[item.patientId];
            }
        });
    };

    const renderPage = function (pageItems) {
        const hasPageItems = pageItems && pageItems.length;
        if (hasPageItems) {
            mapPageItems(pageItems);
        }

        $scope.tableParams = new NgTableParams(
            { count: 25 },
            {
                counts: [],
                dataset: pageItems,
            }
        );
    };

    $scope.applyGlobalSearch = (term) => {
        let filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
            const invoices = $filter('filter')($scope.invoices, $scope.globalFilter.val);
            initSelection(invoices);
        }
    };

    $scope.clickTableRow = function (row) {
        if (!row) return;
        $rootScope.openInvoiceModal({ invoiceId: row.id });
    };

    $scope.openModalToExportInvoices = function () {
        const selectedItems = $scope.selectionLogic.getSelectedItems();
        billingInvoicesProcessor.openInvoicesExporterModalByInvoices(selectedItems.map(item => item.id), $scope.loadItems);
    };

    $scope.exportTable = function (selected) {
        $scope.loadingCSV = true;
        const rows = [];
        const titles = [
            'Exports',
            'Payment',
            'ID',
            'Date Range',
            'Total Billed Hours',
            'Total Billed',
            'Total Paid',
            'Total Units',
            'Patient',
            'Contract',
            'Invoice Batch ID',
            'Number of Visits',
            'Created at',
            'Patient Medflyt ID',
            'Patient HHA Exchange ID',
            'Patient Admission ID',
            'Patient SSN',
            'Patient Medicare Number',
            'Patient Medicaid Number',
            'Patient Address',
            'Patient Gender'
        ];
        rows.push(titles);

        const items = selected ? $scope.selectionLogic.getSelectedItems() : $scope.selectionLogic.data.items;

        // Filter table data by global filter
        const allInvoices = $filter('filter')(items, $scope.globalFilter.val);

        allInvoices.forEach(dataRow => {
            const csvRow = [];
            titles.forEach(title => {
                let toPush = "";
                switch (title) {
                    case "Exports":
                        toPush = dataRow.exportedStatus || '';
                        break;
                    case "Payment":
                        toPush = dataRow.paymentStatus || '';
                        break;
                    case "ID":
                        toPush = dataRow.displayId.replace('#', '') || '';
                        break;
                    case "Date Range":
                        toPush = `${$filter("mfShortDate")(dataRow.startDate)} - ${$filter("mfShortDate")(dataRow.endDate)}`;
                        break;
                    case "Total Billed Hours":
                        toPush = $filter("secondsToLabel")(dataRow.totalBilledSeconds);
                        break;
                    case "Total Billed":
                        toPush = $filter("centsToDollars")(dataRow.totalBilled, true);
                        break;
                    case "Total Paid":
                        toPush = $filter("centsToDollars")(dataRow.totalPaid, true);
                        break;
                    case "Total Units":
                        toPush = dataRow.totalUnitsThousandths / 1000;
                        break;
                    case "Patient":
                        toPush = dataRow.patientName || '';
                        break;
                    case "Contract":
                        toPush = dataRow.contractTypeName || '';
                        break;
                    case "Invoice Batch ID":
                        toPush = dataRow.invoiceBatchDisplayId.replace('#', '') || '';
                        break;
                    case "Number of Visits":
                        toPush = dataRow.visitCount || '';
                        break;
                    case "Created at":
                        toPush = $filter("mfShortTime")(dataRow.createdAt, ['withDate']) || '';
                        break;
                    case "Patient Medflyt ID":
                        toPush = dataRow.patientId || '';
                        break;
                    case "Patient HHA Exchange ID":
                        toPush = dataRow.patientExternalId || '';
                        break;
                    case "Patient Admission ID":
                        toPush = dataRow.patientAdmissionId || '';
                        break;
                    case "Patient SSN":
                        toPush = dataRow.patientSSN || '';
                        break;
                    case "Patient Medicare Number":
                        toPush = dataRow.patientMedicareNumber || '';
                        break;
                    case "Patient Medicaid Number":
                        toPush = dataRow.patientMedicaidNumber || '';
                        break;
                    case "Patient Address":
                        toPush = dataRow.patientAddress ? dataRow.patientAddress.replace('#', '') : '';
                        break;
                    case "Patient Gender":
                        toPush = dataRow.patientGender || '';
                        break;
                    default:
                        break;
                }

                csvRow.push("\"" + toPush + "\"");
            });
            rows.push(csvRow);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };


    const getExportedFileName = function () {
        const filename = "medflyt-invoices-to-export"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    initialize();

};