import { Flex, Text } from "@chakra-ui/react";
import { ResponseOf } from "../../../../core/api";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { typedObjectEntries } from "../../../../shared/utils/ts-utils";

const LatestValidEligibilityCheckInfo = (props: {
  validCheck: ResponseOf<"get", "./patients/:patientId/intake_eligibility_checks">["records"][0];
}) => {
  const description = `Showing latest successful results from ${dateFormatter.toDateTime(
    props.validCheck.createdAt
  )} ${props.validCheck.createdBy !== null ? `ran by ${props.validCheck.createdBy}` : ""}`;

  return (
    <Flex direction="column" gap={4}>
      <Text>{description}</Text>
      <Flex direction="column" gap={3} maxHeight="280px" overflowY="scroll">
        {typedObjectEntries(props.validCheck.vendorResponse as Record<string, string>).map(
          ([key, value]) => (
            <Flex key={key} direction="row" gap={4}>
              <Text>
                <u>{key}:</u>
              </Text>
              <Text>{value}</Text>
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  );
};

export default LatestValidEligibilityCheckInfo;
