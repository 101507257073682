import { useToast } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { BodyOf, Messages, ResponseOf } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { S3Object } from "../../../shared/hooks/useUploadFiles";
import { queryKeys } from "../../../shared/query-keys";
import {
  CommCenterEmailMessageAttachmentId,
  CommCenterEmailMessageId,
} from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import {
  CreateEmailMessageRequest,
  SubmitEmailMessage,
} from "../pages/CommunicationCenterTicket/CommunicationCenterTicketRoute";
import { buildAttachmentsPayloadForEmailMessage } from "../utils/comm-center-email-utils";

interface Params {
  onSubmitEmailMessage?: () => void;
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
}

export default function useEmailTicketMessages(params: Params) {
  const toast = useToast();
  const { api } = useApi();
  const queryClient = useQueryClient();
  const [attachments, setAttachments] = React.useState<S3Object[]>([]);

  const submitEmailMessage = useMutation({
    mutationFn: async (createParams: CreateEmailMessageRequest) => {
      return api.post("./comm_center/email/threads/:threadId/messages", {
        path: {
          threadId: createParams.threadId,
        },
        body: {
          attachments: createParams.attachments,
          content: createParams.content,
          contentPlainText: createParams.contentPlainText,
          recipients: createParams.recipients,
          sender: createParams.sender,
          cc: createParams.cc,
          bcc: createParams.bcc,
        },
      });
    },
    onMutate: async (createParams) => {
      const tickets = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          const ticket = draft.tickets.find((ticket) => ticket.id === createParams.ticketId);

          if (ticket !== undefined) {
            mutateSubmitEmailMessage({ ticket, body: createParams });
          }
        },
      });

      const ticket = optimisticUpdate<{ ticket: Messages["CommCenterTicket"] }>({
        queryClient,
        queryKey: queryKeys.commCenter.get(createParams.ticketId),
        update: (draft) => mutateSubmitEmailMessage({ ticket: draft.ticket, body: createParams }),
      });

      return { tickets, ticket };
    },
    onError: (error, _newChatMessage, context) => {
      queryClient.setQueryData(
        queryKeys.commCenter.get(_newChatMessage.ticketId),
        context?.ticket.previousValue
      );

      toast({
        title: "Could not send message.",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: () => {
      toast({
        title: "Message sent successfully",
        status: "success",
        position: "top-right",
      });

      params.onSubmitEmailMessage?.();
    },
  });

  function mutateSubmitEmailMessage(params: {
    ticket: Messages["CommCenterTicket"];
    body: CreateEmailMessageRequest;
  }) {
    const { ticket, body } = params;
    const messageId = CommCenterEmailMessageId.parse(Instant.now().toEpochMilli() * -1);
    ticket.emailThread?.messages.push({
      id: messageId,
      sender: body.sender,
      cc: body.cc,
      bcc: body.bcc,
      recipients: body.recipients,
      content: body.content,
      contentPlainText: body.contentPlainText,
      direction: "OUTBOUND",
      threadId: body.threadId,
      replyTo: null,
      s3Url: null,
      attachments: attachments.map((attachment) => ({
        id: CommCenterEmailMessageAttachmentId.parse(Instant.now().toEpochMilli() * -1),
        type: attachment.file.type,
        s3Url: URL.createObjectURL(attachment.file),
        name: attachment.file.name,
        messageId,
        createdAt: Instant.now(),
      })),
      createdAt: Instant.now(),
      readAt: null,
    });
  }

  const handleSubmitNewEmailMessage = async (body: SubmitEmailMessage) => {
    const attachmentsPayload = buildAttachmentsPayloadForEmailMessage(attachments);
    await submitEmailMessage.mutateAsync({ ...body, attachments: attachmentsPayload });
    setAttachments([]);
  };

  const createEmailTicket = useMutation({
    mutationFn: (newEmailTicketRequest: BodyOf<"post", "./comm_center/email/threads">) => {
      return api.post("./comm_center/email/threads", {
        body: newEmailTicketRequest,
      });
    },
    onSuccess: (response) => {
      params.onSuccessCreateEmailTicket(response);
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return {
    attachments,
    onChangeAttachments: setAttachments,
    submitEmailMessage: handleSubmitNewEmailMessage,
    createEmailTicket,
  };
}
