import React from "react";
import { Box, FormControl, FormLabel, Input, List, ListItem } from "@chakra-ui/react";
import { fromPlaceId, toMfAddressComponents } from "mf-google-geocoder";
import { Messages } from "../../../core/api";
import { GOOGLE_MAPS_API_KEY } from "../../../shared/consts";
import useGoogleMapsAddress from "../../../shared/hooks/useGoogleMapsAddress";

interface Props {
  label: string;
  isEdit: boolean;
  value: string | null;
  onChange: (
    address: string | null,
    addressComponents:
      | Messages["PatientAddressComponents"]
      | Messages["PatientContactAddressComponents"]
      | null
  ) => void;
}

const IntakeProfileAddressInput = (props: Props) => {
  const {
    handleChange: handleChangeAddress,
    placePredictions,
    getDetails: getAddressDetails,
    showPredictions,
    setShowPredictions,
  } = useGoogleMapsAddress();

  const handleClickAddressPrediction = async (
    prediction: google.maps.places.AutocompletePrediction
  ): Promise<{
    address: string | null;
    addressComponents: Messages["IntakePatientProfile"]["addressComponents"] | null;
  }> => {
    setShowPredictions(false);
    let address: string | null = null;
    let addressComponents: Messages["IntakePatientProfile"]["addressComponents"] | null = null;

    try {
      const result = await getAddressDetails(prediction);
      const placeId = result?.place_id;

      if (placeId === undefined) {
        return {
          address: null,
          addressComponents: null,
        };
      }

      const addressDetails = await fromPlaceId(placeId, {
        apiKey: GOOGLE_MAPS_API_KEY,
        mfAutoFix: true,
      });
      addressComponents = {
        components: toMfAddressComponents(addressDetails),
        text: addressDetails.fullAddress,
        timezone: "America/New_York",
      };
      address = prediction.description;
      return { address, addressComponents };
    } catch (error) {
      console.error("Error getting address details", error);
      return { address: null, addressComponents: null };
    }
  };

  const handleClickAddress = (prediction: google.maps.places.AutocompletePrediction) => {
    handleClickAddressPrediction(prediction).then(({ address, addressComponents }) => {
      props.onChange(address, addressComponents);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeAddress(event);
    props.onChange(event.target.value, null);
  };

  return (
    <FormControl>
      <FormLabel>{props.label}</FormLabel>
      <Input
        autoComplete="new-password"
        isDisabled={!props.isEdit}
        value={props.value ?? ""}
        onChange={handleChange}
      />
      {showPredictions ? (
        <StylePredisctionList
          placePredictions={placePredictions}
          onClickAddressPrediction={handleClickAddress}
        />
      ) : null}
    </FormControl>
  );
};

export default IntakeProfileAddressInput;

const StylePredisctionList = (props: {
  placePredictions: google.maps.places.AutocompletePrediction[];
  onClickAddressPrediction: (prediction: google.maps.places.AutocompletePrediction) => void;
}) => (
  <Box
    backgroundColor="white"
    border="1px"
    borderColor="chakra-border-color"
    padding={2}
    position="absolute"
    width="xl"
    zIndex={1}
  >
    <List
      display="flex"
      flexDirection="column"
      gap={4}
      maxHeight="200px"
      overflowY="auto"
      top="100%"
      width="100%"
    >
      {props.placePredictions.map((prediction) => (
        <ListItem
          key={prediction.place_id}
          borderBottom="1px"
          borderColor="chakra-border-color"
          cursor="pointer"
          onClick={() => props.onClickAddressPrediction(prediction)}
        >
          {prediction.description}
        </ListItem>
      ))}
    </List>
  </Box>
);
