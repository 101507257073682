import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import useGraphQLQuery from "../../shared/hooks/useGraphQLQuery";
import { CaregiverId } from "../../shared/schema/schema";
import { GET_CAREGIVERS_FULL_NAMES_QUERY } from "../caregiver/caregiver.graphql";
import { WarningIcon } from "@chakra-ui/icons";

export default function HoursRestrictionErrorModal(props: {
  conflicts: {
    caregiverId: CaregiverId;
    exceededHours: number;
  }[];
  onClose: () => void;
}) {
  const caregiversQuery = useGraphQLQuery({
    document: GET_CAREGIVERS_FULL_NAMES_QUERY,
    variables: {
      ids: {
        in: props.conflicts.map((c) => c.caregiverId),
      },
    },
  });
  return (
    <Modal isOpen={true} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center" gap={3}>
            <WarningIcon color="red.500" />
            <Text>Office Hours Restriction Error</Text>
          </Flex>
        </ModalHeader>
        <Divider />
        <ModalCloseButton onClick={props.onClose} />
        <ModalBody>
          {caregiversQuery.isLoading ? (
            <Text>Loading...</Text>
          ) : caregiversQuery.isError || caregiversQuery.data === undefined ? (
            <Text>Error</Text>
          ) : (
            <Flex direction="column" gap={5}>
              <Text fontSize="16" fontWeight="450">
                This action can&apos;t be completed because one or more caregivers exceed the
                allowed hours:
              </Text>
              <Flex direction="column" fontSize="14" gap={2}>
                {props.conflicts.map((conflict) => {
                  const caregiver = caregiversQuery.data.caregivers.nodes.find(
                    (c) => c.id === conflict.caregiverId
                  );

                  return (
                    <Flex key={conflict.caregiverId} gap={1}>
                      <Text>{`${caregiver?.firstName} ${caregiver?.lastName}`}</Text>
                      <Text>exceeds by {conflict.exceededHours} hours.</Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          )}
        </ModalBody>

        <ModalFooter gap={2}>
          <Button colorScheme="blue" onClick={props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
