import useAuth from "./useAuth";

export default function useAuthPermissions() {
  const { hasPermissions, authData } = useAuth();

  if (authData.type !== "authenticated") {
    throw new Error("Cannot get auth data when not logged in");
  }

  return {
    hasPermissions,
    can: hasPermissions,
  };
}
