import { Messages } from "../../../../../../core/api";
import { getFullName } from "../../../../../../shared/utils/get-full-name";
import { phoneFormatter } from "../../../../../../shared/utils/phone-formatter";

export function getParticipantDescription(
  participant: Messages["TelephonyCallParticipantInfo"]
): string {
  switch (participant.entity.type) {
    case "AgencyMember":
      return getFullName(participant.entity);
    case "Patient":
    case "PatientContact":
      return `${participant.entity.type}: ${getFullName(participant.entity)}`;
    case "Caregiver":
      return `${participant.entity.type}: ${getFullName(participant.entity)}`;
    case "NotIdentified":
      return phoneFormatter.formatNationalIfValid(participant.entity.source);
    case "PhonebookContact":
      return `Phonebook Contact: ${participant.entity.name}`;
  }
}
