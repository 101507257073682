import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Messages, ResponseOf } from "../../../../core/api";
import { ChatAssigneeChangeEvent, ChatStatusChangeEvent } from "../ChatMessages";
import EmailMessage from "./EmailMessage";
import ClosedEmailThreadMessage from "./ClosedEmailThreadMessage";
import { EmailTicket as EmailTicketProps } from "../../utils/comm-center-email-utils";
import EmailActions from "./EmailActions";

type EmailMessage =
  | ({
      type: "Message";
      previousMessage: Messages["CommCenterEmailMessage"] | undefined;
    } & Messages["CommCenterEmailMessage"])
  | ({
      type: "AssigneeChange";
    } & Messages["CommCenterTicketAssigneeChange"])
  | ({
      type: "StatusChange";
    } & Messages["CommCenterTicketStatusChange"]);

export type SubmitReply = {
  recipients: Messages["CommCenterEmailAddressObject"][];
  cc: Messages["CommCenterEmailAddressObject"][];
  bcc: Messages["CommCenterEmailAddressObject"][];
  content: string;
  contentPlainText: string;
};

interface Props {
  activeTicket: EmailTicketProps;
  onSubmitNewEmailMessage?: () => void;
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
}

const EmailTicket = (props: Props) => {
  const { emailThread } = props.activeTicket;
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const messages: EmailMessage[] = [
    ...(emailThread !== null
      ? emailThread.messages.map((message, i, messages) => {
          return { type: "Message" as const, previousMessage: messages[i - 1], ...message };
        })
      : []),
    ...props.activeTicket.statusChanges.map((message) => {
      return { type: "StatusChange" as const, ...message };
    }),
    ...props.activeTicket.assigneeChanges.map((message) => {
      return { type: "AssigneeChange" as const, ...message };
    }),
  ].sort((a, b) => a.createdAt.compareTo(b.createdAt));

  React.useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, [props.activeTicket.emailThread?.messages]);

  return (
    <Flex direction="column" height="100%">
      <Text backgroundColor="gray.50" p={3} size="sm">
        {emailThread?.subject}
      </Text>
      <Flex
        ref={wrapperRef}
        direction="column"
        gap={5}
        height="100%"
        justifyContent="space-between"
        overflowY="auto"
        p={4}
      >
        <Flex direction="column" gap={2} justifyContent="space-between">
          {messages.map((message) => {
            switch (message.type) {
              case "Message":
                return <EmailMessage key={`message-${message.id}`} message={message} />;
              case "AssigneeChange":
                return <ChatAssigneeChangeEvent key={`assignee-${message.id}`} event={message} />;
              case "StatusChange":
                return <ChatStatusChangeEvent key={`status-${message.id}`} event={message} />;
            }
          })}
        </Flex>
        {props.activeTicket.emailThread !== null &&
          (props.activeTicket.status !== "RESOLVED" ? (
            <EmailActions
              ticket={props.activeTicket}
              onSubmitNewEmailMessage={props.onSubmitNewEmailMessage}
              onSuccessCreateEmailTicket={props.onSuccessCreateEmailTicket}
            />
          ) : (
            <ClosedEmailThreadMessage isOpen={props.activeTicket.status === "RESOLVED"} />
          ))}
      </Flex>
    </Flex>
  );
};

export default EmailTicket;
