import { chakra, keyframes } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { motion } from "framer-motion";
import React from "react";
import { Messages } from "../../../core/api";
import PhoneHangupIcon from "../../../shared/icons/PhoneHangupIcon";
import { getTelephonyCallPrimaryTypeAndName } from "../call-center.utils";
import DurationTimer from "../../../shared/components/DurationTimer";

type Props = {
  call: Messages["TelephonyCallInfo"];
  startedAt: Instant;
  onClick: (call: Messages["TelephonyCallInfo"]) => void;
  onClickHangup: () => void;
};

export default function ActiveCallBanner(props: Props) {
  const callee = getTelephonyCallPrimaryTypeAndName(props.call);
  const bodyEl = React.useRef(document.querySelector("body"));
  const [isDragging, setIsDragging] = React.useState(false);

  const handleClickHangup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onClickHangup();
  };

  const handleClick = () => {
    if (!isDragging) {
      props.onClick(props.call);
    }
  };

  return (
    <Root
      drag={true}
      dragConstraints={bodyEl}
      dragElastic={1}
      dragTransition={{ power: 0.1, timeConstant: 100 }}
      whileDrag={{ scale: 0.8 }}
      onDragEnd={() => setIsDragging(false)}
      onDragStart={() => setIsDragging(true)}
      onPointerUp={handleClick}
    >
      <RecordIcon />
      <Header>
        <PrefixText>
          {callee.type} (<DurationTimer instant={props.startedAt} />)
        </PrefixText>
        <CalleeText>{callee.name}</CalleeText>
      </Header>
      <HangupButton onPointerUp={handleClickHangup}>
        <PhoneHangupIcon />
      </HangupButton>
    </Root>
  );
}

const HangupButton = chakra("button", {
  baseStyle: {
    backgroundColor: "red.500",
    borderRadius: "100%",
    height: "32px",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: 0,
    color: "white",
    transition: "background-color 0.2s ease-in-out",
    _hover: {
      backgroundColor: "red.600",
    },
    _active: {
      backgroundColor: "red.700",
    },
  },
});

const activeCallPulse = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
  }
`;

const Root = chakra(motion.div, {
  baseStyle: {
    backgroundColor: "gray.800",
    position: "fixed",
    zIndex: "activeCallBanner",
    bottom: 12,
    left: 4,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingX: 4,
    paddingY: 3,
    gap: "16px",
    borderRadius: "2xl",
    minWidth: 270,

    _hover: {
      backgroundColor: "gray.900",
    },
  },
});

const RecordIcon = chakra("div", {
  baseStyle: {
    backgroundColor: "red.500",
    borderRadius: "100%",
    height: "10px",
    width: "10px",
    position: "relative",
    mx: 3,

    _before: {
      backgroundColor: "red.500",
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      borderRadius: "100%",
      height: "100%",
      width: "100%",
      animation: `${activeCallPulse} 2s infinite`,
    },
  },
});

const Header = chakra("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    flex: 1,
  },
});

const PrefixText = chakra("span", {
  baseStyle: {
    color: "gray.400",
    fontSize: 12,
  },
});

const CalleeText = chakra("span", {
  baseStyle: {
    fontSize: 15,
    fontWeight: 500,
  },
});
