import { graphql } from "../../shared/schema/gql";

export const GET_AVAILABLE_WORKFLOW_TASKS = graphql(`
  query GetAvailableWorkflowTasks(
    $order: [AgencyMemberAvailableWorkflowTaskInstance__Order]!
    $limit: Int!
    $offset: Int!
    $id: WorkflowTaskInstanceId_Filter
    $ownerId: AgencyMemberId_Filter
    $assigneeId: AgencyMemberId_Filter
    $assigneeSearch: String
    $taskName: String_Filter
    $nameSearch: String
    $correlationKey: WorkflowDefinitionCorrelationKey_Filter
    $skillIds: [WorkflowSkillId!]
    $caregiverIds: [CaregiverId!]
    $patientIds: [PatientId!]
    $clusterId: WorkflowTaskClusterId_Filter
    $createdAtFrom: LocalDate
    $createdAtTo: LocalDate
  ) {
    workflowAvailableTaskInstances(
      order: $order
      limit: $limit
      offset: $offset
      id: $id
      ownerId: $ownerId
      assigneeId: $assigneeId
      assigneeSearch: $assigneeSearch
      name: $taskName
      nameSearch: $nameSearch
      correlationKey: $correlationKey
      skillIds: $skillIds
      caregiverIds: $caregiverIds
      patientIds: $patientIds
      clusterId: $clusterId
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
    ) {
      totalCount
      nodes {
        id
        name
        description
        mandatory
        priority
        severity
        assignmentTimeoutSeconds
        createdAt
        clusterId
        clusterValue
        workflowDefinition {
          id
          name
        }
        skills {
          nodes {
            id
          }
        }
        instanceEntities {
          nodes {
            key
            entity
            value
          }
        }
        assignee {
          id
          firstName
          lastName
        }
      }
    }
  }
`);
