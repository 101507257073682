import CaregiverSmsSender from "../../../modules/caregiver/components/CaregiverSmsSender";
import PatientSmsSender from "../../../modules/patient/components/PatientSmsSender";
import { useReactBridgeDispatchEvent } from "../../hooks/useReactBridgeEvent";
import { CaregiverId, PatientId } from "../../schema/schema";
import { useSmsSender } from "../../hooks/useSmsSender";

export type OpenSmsSenderData = { phoneNumber: string } & (
  | {
      type: "Patient";
      patientId: PatientId;
    }
  | {
      type: "Caregiver";
      caregiverId: CaregiverId;
    }
);

export default function SmsSenderListener() {
  const { state, onOpen, onClose } = useSmsSender();

  useReactBridgeDispatchEvent({
    eventName: "OPEN_SMS_SENDER",
    onEvent: (data) => {
      if (data.type === "Patient") {
        onOpen({
          entity: "Patient",
          patientId: data.patientId,
          predefinedPhoneNumber: data.phoneNumber,
        });
      } else if (data.type === "Caregiver") {
        onOpen({
          entity: "Caregiver",
          caregiverId: data.caregiverId,
          predefinedPhoneNumber: data.phoneNumber,
        });
      }
    },
  });

  if (state === null) {
    return <></>;
  }

  switch (state.entity) {
    case "Patient":
      return (
        <PatientSmsSender
          patientId={state.patientId}
          predefinedMessageTemplate={state.predefinedMessageTemplate}
          predefinedPhoneNumber={state.predefinedPhoneNumber}
          onClose={onClose}
        />
      );
    case "Caregiver":
      return (
        <CaregiverSmsSender
          caregiverId={state.caregiverId}
          predefinedMessageTemplate={state.predefinedMessageTemplate}
          predefinedPhoneNumber={state.predefinedPhoneNumber}
          onClose={onClose}
        />
      );
  }
}
