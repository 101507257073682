import { Button, Flex, FormControl, FormErrorMessage, Text, Textarea } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import Select from "../../../../shared/components/Select";
import { CommCenterTicketId } from "../../../../shared/schema/schema";
import useCallCenterNoteCache from "../../hooks/useCallCenterNoteCache";
import useCallTicketNotes from "./hooks/useCallTicketNotes";

type Props = ReturnType<typeof useCallTicketNotes> & { ticketId: CommCenterTicketId };

const CallNotesSection = ({
  formState,
  control,
  subjectIdSelectFilter,
  ticketId,
  onSubmit,
  clearForm,
}: Props) => {
  const { setTicketNoteCache } = useCallCenterNoteCache();
  return (
    <Flex alignItems="center" direction="column" fontSize="md" gap={4} w="full">
      <FormControl isInvalid={formState.errors.text !== undefined}>
        <Controller
          control={control}
          name="text"
          render={({ field }) => (
            <>
              <Textarea
                placeholder="Write your note..."
                w="100%"
                {...field}
                onChange={(e) => {
                  setTicketNoteCache(ticketId, { text: e.currentTarget.value });
                  field.onChange(e.currentTarget.value);
                }}
              ></Textarea>
              <FormErrorMessage>{formState.errors.text?.message}</FormErrorMessage>
            </>
          )}
        />
      </FormControl>
      <FormControl>
        <Select
          allowUnselect={false}
          buttonProps={{ w: "full" }}
          label={subjectIdSelectFilter.label}
          multiple={subjectIdSelectFilter.multiple}
          options={subjectIdSelectFilter.options}
          value={subjectIdSelectFilter.value}
          onChange={(e) => {
            setTicketNoteCache(ticketId, {
              noteSubjectId: e ?? null,
            });
            subjectIdSelectFilter.onChange(e);
          }}
        />
        <Text color="red">{formState.errors.subjectId?.message}</Text>
      </FormControl>
      <FormControl as={Flex} gap={4}>
        <Button colorScheme="blue" flex={1} type="submit" onClick={onSubmit}>
          Add Note
        </Button>
        <Button flex={1} onClick={clearForm}>
          Clear
        </Button>
      </FormControl>
    </Flex>
  );
};

export default CallNotesSection;
