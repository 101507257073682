import { Box, Flex, Skeleton, Text, Tag } from "@chakra-ui/react";
import Dot02SIcon from "../../../../../shared/icons/Dot02SIcon";
import TwotoneCheckIcon from "../../../../../shared/icons/TwotoneCheckIcon";

type Props = {
  question: string;
  answer: string | null;
  status: "FUTURE" | "ACTIVE" | "COMPLETED";
  isCompleted: boolean;
  isFuture: boolean;
  externalFlowName: string | null;
  onClick: () => void;
};

export default function PatientIntakeFlowSidebarItem(props: Props) {
  const StatusIcon = props.isCompleted ? TwotoneCheckIcon : Dot02SIcon;

  return (
    <Flex
      bg={props.status === "ACTIVE" ? "blue.500" : undefined}
      color={props.status === "ACTIVE" ? "white" : undefined}
      cursor="pointer"
      gap={2}
      px={2}
      py={3}
      rounded="lg"
      sx={{
        _hover: { bg: props.status === "ACTIVE" ? undefined : "gray.200" },
        transition: "all 0.1s ease-out",
      }}
      onClick={props.onClick}
    >
      <Box textAlign="center" w={8}>
        {props.status === "FUTURE" && <StatusIcon color="gray.900" fontSize="20" />}
        {props.status === "ACTIVE" && <StatusIcon color="white" fontSize="20" />}
        {props.status === "COMPLETED" && <StatusIcon color="green.500" fontSize="20" />}
      </Box>
      <Flex gap={5}>
        <Flex direction="column" gap={1}>
          <Text fontSize="14">{props.question}</Text>
          <Text fontSize="12">{props.answer}</Text>
          {props.externalFlowName !== null && (
            <Flex>
              <Tag colorScheme={props.status === "ACTIVE" ? "green" : "blue"}>
                <Text fontSize="10">External - {props.externalFlowName}</Text>
              </Tag>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

const PatientIntakeFlowSidebarItemShimmer = () => {
  return (
    <Flex gap={2} px={2} py={3}>
      <Flex justify="center" w={8}>
        <Skeleton height={5} rounded="full" width={5} />
      </Flex>
      <Flex direction="column" gap={2}>
        <Skeleton height="21px" width={48} />
        <Skeleton height="18px" width={24} />
      </Flex>
    </Flex>
  );
};

PatientIntakeFlowSidebarItem.Shimmer = PatientIntakeFlowSidebarItemShimmer;
