import { groupBy } from "lodash";
import { Messages } from "../../../../core/api";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import { PatientIntakeContact } from "../../patient-intake.types";
import { PatientContactForm } from "../../components/IntakePatientContact";

const familyMemberContactRepationships = [
  "Father",
  "Mother",
  "Son",
  "Daughter",
  "Brother",
  "Sister",
  "Wife",
  "Husband",
  "Grandchild",
  "Mother-in-law",
  "Father-in-law",
  "Legal guardian",
];

const additionalContactRelationships = [
  "Unknown",
  "Case Manager",
  "Plan",
  "Physician",
  "Other",
  "Social Worker",
];
export type PatientContactRelationship = typeof relationshipsOptions[number];
export type PatientContactRelationshipType = "Family Member" | "Additional Contact" | "Unknown";

export const relationshipsOptions = [
  ...familyMemberContactRepationships,
  ...additionalContactRelationships,
];

export function getIntakeContactRelationshipType(
  relationship: PatientContactRelationship
): PatientContactRelationshipType {
  if (familyMemberContactRepationships.includes(relationship)) {
    return "Family Member";
  }

  if (additionalContactRelationships.includes(relationship)) {
    return "Additional Contact";
  }

  return "Unknown";
}

export const validateContactsPhonenumbers = (
  contacts: (PatientContactForm | Messages["PatientContact"])[]
) => {
  const groupByPhonenumbers = groupBy(contacts, (contact) => contact.mobilePhoneNumber);
  let isValid = true;

  for (const phoneNumber in groupByPhonenumbers) {
    if (groupByPhonenumbers[phoneNumber].length > 1) {
      isValid = false;
      break;
    }
  }

  return isValid;
};

export const validatePatientAdditionalContact = (
  contact: PatientContactForm | Messages["PatientContact"]
) => {
  if (
    contact.mobilePhoneNumber === null ||
    contact.mobilePhoneNumber === "" ||
    formatPhonenumberE164(contact.mobilePhoneNumber) === null ||
    contact.firstName === null ||
    contact.firstName === "" ||
    contact.relationship === null ||
    contact.relationship === ""
  ) {
    return false;
  }

  return true;
};

export function formatPhonenumberE164(value: string | null) {
  if (value === null || value === "" || !phoneFormatter.isValidNumber(value)) {
    return null;
  }

  return phoneFormatter.formatE164(value);
}

export function parsePatientAdditionalContact(contact: PatientIntakeContact) {
  const newMobile = formatPhonenumberE164(contact.mobilePhoneNumber);
  const newHomePhone = formatPhonenumberE164(contact.homePhoneNumber);

  if (newMobile !== null) {
    contact.mobilePhoneNumber = newMobile;
  } else {
    console.error(
      `Could not parse mobile number for patient contact with first name: ${contact.firstName}`
    );
    contact.mobilePhoneNumber = "";
  }

  if (newHomePhone !== null) {
    contact.homePhoneNumber = newHomePhone;
  } else {
    console.error(
      `Could not parse home number for patient contact with first name: ${contact.firstName}`
    );
    contact.homePhoneNumber = "";
  }

  // To make the mail dissapear from the "Send Email" dropdown on the header.
  // If the agency member changes it to "", it's going to show as empty: contact_name <> instead of not showing.
  if (contact.email === "") {
    contact.email = null;
  }

  return contact;
}
