import { createFilters } from "../../../../shared/hooks/useFilters";
import { type EntityCommunicationsFilters } from "./entity-communications.types";
import {
  entityCommunicationsTicketStatusOptions,
  entityCommunicationsTicketTypeOptions,
} from "./entity-communications.types";
import Select from "../../../../shared/components/Select";
import { HStack } from "@chakra-ui/react";

interface Props {
  filters: { [key in keyof EntityCommunicationsFilters]: EntityCommunicationsFilters[key] };
  setFilter: (
    key: keyof EntityCommunicationsFilters,
    value: EntityCommunicationsFilters[keyof EntityCommunicationsFilters]
  ) => void;
}

export default function EntityCommunicationsFilters(props: Props) {
  const { filters, setFilter } = props;
  const { createMultiSelectFilter: createLocalMultiSelectFilter } =
    createFilters<EntityCommunicationsFilters>();

  const typeFilter = createLocalMultiSelectFilter({
    label: "Type",
    name: "type",
    options: entityCommunicationsTicketTypeOptions,
    value: filters.type.length === 0 ? null : filters.type,
    onChange: (e, o) => setFilter(e, o ?? []),
  });

  const statusFilter = createLocalMultiSelectFilter({
    label: "Status",
    name: "status",
    options: entityCommunicationsTicketStatusOptions,
    value: filters.status.length === 0 ? null : filters.status,
    onChange: (e, o) => setFilter(e, o ?? []),
  });

  return (
    <HStack gap={5}>
      <Select {...typeFilter} />
      <Select {...statusFilter} />
    </HStack>
  );
}
