import { Box, Circle, Image, Skeleton, SkeletonProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../query-keys";

export default function ImageCircleSkeleton(props: { size: SkeletonProps["width"]; src: string }) {
  const { isLoading, data } = useQuery({
    queryKey: queryKeys.shared.image(props.src),
    staleTime: Infinity,
    queryFn: async () => {
      try {
        const response = await fetch(props.src);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } catch {
        console.log(`failed to fetch image: ${props.src}.`);
        return props.src;
      }
    },
  });

  const showShimmer = isLoading || data === undefined;
  const isSVG = props.src.split(".").pop()?.toLowerCase() === "svg";

  return (
    <Box borderRadius="50%" h={props.size} w={props.size}>
      {showShimmer ? (
        <Skeleton height={props.size} rounded="full" width={props.size} />
      ) : isSVG ? (
        <Circle bgColor="gray.100">
          <Image
            h={props.size}
            objectFit="cover"
            objectPosition="center 20%"
            p={3}
            src={data}
            w={props.size}
          />
        </Circle>
      ) : (
        <Image
          borderRadius="50%"
          h={props.size}
          objectFit="cover"
          objectPosition="center 20%"
          src={data}
          w={props.size}
        />
      )}
    </Box>
  );
}
