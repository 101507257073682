import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { XYPosition, useReactFlow } from "reactflow";
import { Messages } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { useStorageState } from "../../../../../shared/hooks/useStorageState";
import { queryKeys } from "../../../../../shared/query-keys";
import {
  IntakeTrackId,
  IntakeTrackNoteId,
  IntakeTrackStepId,
} from "../../../../../shared/schema/schema";
import { fmap } from "../../../../../shared/utils";
import {
  ElkNodeData,
  ReactFlowElementsMap,
  parseIntakeFlowGraphId,
} from "../flow-settings-visualizer.utils";
import { createEmptyStep, toIntakeStepForm } from "../flow-settings.utils";
import useNodeChange from "./useNodeChange";
import useNodeClipboard from "./useNodeClipboard";
import usePatientIntakeFlowSettings from "./usePatientIntakeFlowSettings";
import usePatientIntakeStepForm from "./usePatientIntakeStepForm";
import useSelectedNode from "./useSelectedNode";

export default function usePatientIntakeFlowVisualizer() {
  const { query, notesQuery, reactFlowElementsMap } = usePatientIntakeFlowSettings();
  const toast = useToast();
  const form = usePatientIntakeStepForm();
  const [data, setData] = React.useState<ReactFlowElementsMap>(reactFlowElementsMap);
  const queryClient = useQueryClient();
  const trackId = form.state.trackId;
  const trackData = fmap(trackId, (x) => data.get(x)) ?? null;
  const { fitView, setViewport } = useReactFlow<ElkNodeData>();
  const [newStepPositions, setNewStepPositions] = React.useState<
    Map<IntakeTrackStepId, { x: number; y: number }>
  >(new Map());
  const [newNotesPositions, setNewNotesPositions] = React.useState<
    Map<IntakeTrackNoteId, { x: number; y: number }>
  >(new Map());
  const [initialViewport, setInitialViewport, { initialState: initialValueOfInitialViewport }] =
    useStorageState<
      Record<
        IntakeTrackId,
        {
          zoom: number;
          position: XYPosition;
        }
      >
    >({
      key: ["intakeFlow", "viewport"],
      initialState: () => ({}),
      debounce: 0,
      storage: localStorage,
    });

  const { api } = useApi();
  const savePoints = useMutation({
    mutationFn: async (params: {
      stepsPositions: Map<IntakeTrackStepId, XYPosition>;
      notesPositions: Map<IntakeTrackNoteId, XYPosition>;
    }) =>
      api.patch("./intake_flow/graph_points", {
        body: {
          notes: Array.from(params.notesPositions.entries()).map(([id, position]) => ({
            id,
            graphPoint: position,
          })),
          steps: Array.from(params.stepsPositions.entries()).map(([id, position]) => ({
            id,
            graphPoint: position,
          })),
        },
      }),
    onSuccess: () => {
      toast({ position: "top", description: "Graph points saved", status: "success" });
    },
    onError: (error) => toast({ position: "top", description: `${error}`, status: "error" }),
  });

  const selectedNode = useSelectedNode();
  useNodeClipboard({ selectedNodeId: selectedNode.selectedNodeId });

  const handleInitialViewport = React.useCallback(
    (params: { zoomLevel: number | null; position: XYPosition | null }) => {
      const trackPosition = params.position;
      const trackZoomLevel = params.zoomLevel;

      if (trackZoomLevel === null || trackPosition === null) {
        setTimeout(() => fitView(), 0);
        return;
      }

      setTimeout(
        () =>
          setViewport({
            zoom: trackZoomLevel,
            x: trackPosition.x,
            y: trackPosition.y,
          }),
        0
      );
    },
    [fitView, setViewport]
  );

  React.useEffect(() => {
    const viewport = fmap(trackId, (x) => initialValueOfInitialViewport?.value[x]) ?? null;

    handleInitialViewport({
      zoomLevel: viewport?.zoom ?? null,
      position: viewport?.position ?? null,
    });

    setData(reactFlowElementsMap);
  }, [handleInitialViewport, initialValueOfInitialViewport?.value, reactFlowElementsMap, trackId]);

  const handleSavePoints = () => {
    // TODO: when changing x/y db columns to be not nullable,
    // we can just:
    // savePoints.mutate(newPositions);

    const stepsPositions = new Map<IntakeTrackStepId, XYPosition>();
    const notesPositions = new Map<IntakeTrackNoteId, XYPosition>();

    for (const node of trackData?.nodes ?? []) {
      const nodeId = parseIntakeFlowGraphId(node.data.id);
      switch (nodeId.type) {
        case "step": {
          stepsPositions.set(nodeId.id, node.position);
          break;
        }
        case "note": {
          notesPositions.set(nodeId.id, node.position);
          break;
        }
      }
    }

    for (const [id, position] of newStepPositions) {
      stepsPositions.set(id, position);
    }

    for (const [id, position] of newNotesPositions) {
      notesPositions.set(id, position);
    }

    savePoints.mutate({
      notesPositions: notesPositions,
      stepsPositions: stepsPositions,
    });
  };

  const onChangeNodes = useNodeChange({
    nodes: trackData?.nodes ?? [],
    onChangePositions: (positions) => {
      setNewStepPositions((prev) => {
        const newMap = new Map(prev);

        for (const [id, position] of positions.stepsMap) {
          newMap.set(id, position);
        }

        return newMap;
      });

      setNewNotesPositions((prev) => {
        const newMap = new Map(prev);

        for (const [id, position] of positions.notesMap) {
          newMap.set(id, position);
        }

        return newMap;
      });
    },
    onSelectNode: selectedNode.select,
    onUnselectNode: selectedNode.unselect,
    onChangeNodes: (nodes) => {
      setData((data) => {
        if (trackId === null || trackData === null) {
          return data;
        }

        const newData = new Map(data);

        newData.set(trackId, {
          ...trackData,
          nodes: nodes,
        });

        return newData;
      });
    },
  });

  const handleClickStep = (step: Messages["IntakeTrackStepForFullFlow"]) => {
    form.setState(toIntakeStepForm(step));
  };

  const handleChangeTrack = (trackId: IntakeTrackId | undefined) => {
    if (trackId === undefined) {
      form.setState(createEmptyStep());
      return;
    }

    const firstStep = query.data?.sortedSteps.find((step) => step.track.id === trackId);

    if (firstStep === undefined) {
      return;
    }

    handleClickStep(firstStep);
  };

  const edges = React.useMemo(() => {
    if (trackId === null) {
      return [];
    }

    return data.get(trackId)?.edges ?? [];
  }, [data, trackId]);

  const nodes = React.useMemo(() => {
    if (trackId === null) {
      return [];
    }

    return data.get(trackId)?.nodes ?? [];
  }, [data, trackId]);

  const reloadGraph = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.flowSettings() });
    queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.intakeNotes() });
  };

  return {
    trackId: trackId,
    nodes: nodes,
    edges: edges,
    hasPositionChanges: newStepPositions.size > 0 || newNotesPositions.size > 0,
    selectedNode: selectedNode,
    isLoading: query.isPending || query.isFetching || notesQuery.isPending || notesQuery.isFetching,
    onChangeNodes: onChangeNodes,
    setStep: handleClickStep,
    changeTrack: handleChangeTrack,
    savePoints: handleSavePoints,
    reloadGraph: reloadGraph,
    initialViewport: initialViewport,
    setInitialViewport: setInitialViewport,
  };
}
