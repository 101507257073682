import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";

export default createQueryGroup(({ api }) => {
  return {
    list: () =>
      queryOptions({
        queryKey: ["agency_team", "list"],
        queryFn: () => api.get("./teams", {}),
      }),
  };
});
