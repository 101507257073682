import { Center } from "@chakra-ui/react";
import { formatErrorResponse } from "../../../../../shared/utils/format-response-error";

export function VisitAssignmentErrorBox(props: { error: unknown }) {
  return (
    <Center bg="red.50" color="red" fontWeight="medium" h="full" w="full">
      Failed to fetch tickets ({formatErrorResponse(props.error)})
    </Center>
  );
}
