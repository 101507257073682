import { Avatar, AvatarBadge, Box, Flex, Spacer, Spinner, keyframes } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import Dot02SIcon from "../../../shared/icons/Dot02SIcon";
import { getFullName } from "../../../shared/utils/get-full-name";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import CallTicketText from "./CallTicket/helpers/CallTicketText";

export interface CallTicketPartiesActions {
  labe: string;
  onClick: () => void;
}

function getPaticipantNameByRole(role: Messages["TelephonyCallParticipantInfo"]["entity"]): string {
  switch (role.type) {
    case "AgencyMember":
      return `${role.firstName} ${role.lastName}`;
    case "Caregiver":
      return `${role.firstName} ${role.lastName}`;
    case "Patient":
    case "PatientContact":
      return getFullName(role);
    case "NotIdentified":
      return phoneFormatter.formatNationalIfValid(role.source);
    case "PhonebookContact":
      return role.name;
  }
}

function getFormattedRoleType(role: Messages["TelephonyCallParticipantInfo"]["entity"]): string {
  switch (role.type) {
    case "AgencyMember":
      return "Agency Member";
    case "Caregiver":
      return "Caregiver";
    case "Patient":
      return "Patient";
    case "PatientContact":
      return `Patient - ${role.relationship}`;
    case "NotIdentified":
      return "Unknown Number";
    case "PhonebookContact":
      return "Phonebook Contact";
  }
}

const CallTicketParticipantCard = (props: {
  participant: Messages["TelephonyCallParticipantInfo"];
  index: number;
  actions?: React.ReactNode;
}) => {
  switch (props.participant.entity.type) {
    case "AgencyMember":
    case "Caregiver":
    case "Patient":
    case "PatientContact":
    case "PhonebookContact":
      return (
        <ParticipantItem
          actions={props.actions}
          description={
            <>
              <CallTicketText>{getFormattedRoleType(props.participant.entity)}</CallTicketText>
              <Dot02SIcon />
              <CallTicketText>{props.participant.role}</CallTicketText>
            </>
          }
          index={props.index}
          participant={props.participant}
        />
      );
    case "NotIdentified":
      return (
        <ParticipantItem
          actions={props.actions}
          description={
            <>
              <CallTicketText>{getFormattedRoleType(props.participant.entity)}</CallTicketText>
              <Dot02SIcon />
              <CallTicketText>{props.participant.role}</CallTicketText>
              <Dot02SIcon />
              <CallTicketText>
                {phoneFormatter.formatNationalIfValid(props.participant.entity.source)}
              </CallTicketText>
            </>
          }
          index={props.index}
          participant={props.participant}
        />
      );
  }
};

const invitedBadgeKeframes = keyframes`
    0% {
        background-color: var(--chakra-colors-blue-400);
    }
    50% {
        background-color: var(--chakra-colors-blue-200);
    }
    100% {
        background-color: var(--chakra-colors-blue-400);
    }
`;

function ParticipantItemBadge(props: {
  status: Messages["TelephonyCallParticipantInfo"]["status"];
}) {
  switch (props.status) {
    case "Answered":
    case "BargedIn":
      return <AvatarLoadingSpinner />;
    case "Initiated":
    case "Joined":
      return <AvatarBadge bg="green.500" boxSize={{ base: "1em", "2xl": "1.25em" }} />;
    case "Invited":
      return (
        <AvatarBadge
          animation={`${invitedBadgeKeframes} 1.5s infinite`}
          bg="blue.400"
          boxSize={{ base: "1em", "2xl": "1.25em" }}
        />
      );
    case "Declined":
    case "EavesDropped":
    case "Hungup":
    case "Left":
    case "Removed":
      return <AvatarBadge bg="gray.500" boxSize={{ base: "1em", "2xl": "1.25em" }} />;
  }
}

function ParticipantItem(props: {
  index: number;
  participant: Messages["TelephonyCallParticipantInfo"];
  description: React.ReactNode;
  actions?: React.ReactNode;
}) {
  return (
    <Flex alignItems="center" direction="row" gap={3} w="full">
      <Avatar
        opacity={props.participant.isActiveOnCall ? 1 : 0.5}
        showBorder={true}
        size={{ base: "sm", "2xl": "md" }}
      >
        <ParticipantItemBadge status={props.participant.status} />
      </Avatar>
      <Flex direction="column" opacity={props.participant.isActiveOnCall ? 1 : 0.5}>
        <CallTicketText color="gray.900" fontWeight="semibold">
          {getPaticipantNameByRole(props.participant.entity)}
        </CallTicketText>
        <Flex alignItems="center" color="gray.600" direction="row">
          {props.description}
        </Flex>
      </Flex>
      <Spacer />
      {props.actions}
    </Flex>
  );
}

function AvatarLoadingSpinner() {
  return (
    <Box
      alignItems="center"
      bg="blue.500"
      bottom={0}
      boxSize={{ base: 3, "2xl": 5 }}
      display="flex"
      justifyContent="center"
      m={-1}
      p="2px"
      position="absolute"
      right={0}
      rounded="full"
    >
      <Spinner h={2} thickness="1px" w={2} />
    </Box>
  );
}

export default CallTicketParticipantCard;
