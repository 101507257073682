import { LocalDate, DateTimeFormatter, nativeJs } from "@js-joda/core";

//! @ngInject
export function engagementKPICtrl($scope, DatabaseApi, toaster, NgTableParams) {
    $scope.from = new Date(LocalDate.now().minusMonths(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy")));
    $scope.to = new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy")));

    $scope.engagementKPIFilters = {
        isTriggered: true
    };

    const engagementKPIFilterByMethods = {
        kpiIsTriggered: (kpiTrigger, isTriggered) => kpiTrigger.isTriggered === isTriggered,
    };

    function filterKPITriggersTable() {
        if (!$scope.triggers) return;
        let filters = [];

        const isTriggered = $scope.engagementKPIFilters.isTriggered;
        if (isTriggered === true) {
            filters.push(function (trigger) { return engagementKPIFilterByMethods.kpiIsTriggered(trigger, isTriggered); });
        }

        let filteredTriggers = $scope.triggers;
        if (filters.length > 0) {
            filteredTriggers = filteredTriggers.filter(function (kpiTrigger) {
                let isFiltered = true;
                for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](kpiTrigger);
                }
                return isFiltered;
            });
        }

        initKPITriggersTable(filteredTriggers);
    }

    function initKPITriggersTable(items) {
        const options = {
            count: 25
        };
        $scope.engagementKPITable = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    $scope.getKPITriggers = () => {
        let url = 'admin/customer-engagement/kpi';
        const fromParam = LocalDate.from(nativeJs($scope.from));
        const toParam = LocalDate.from(nativeJs($scope.to));
        url = `${url}?from=${fromParam}&to=${toParam}`;

        DatabaseApi.get(url).then(function (res) {
            $scope.triggers = res.data.triggers;
            filterKPITriggersTable();
        }, function (err) {
            toaster.pop('error', 'Something went wrong', 'could not get KPI triggers');
        });
    }

    $scope.updateKPITrigger = (kpiTrigger, fieldName) => {
        let url = `admin/customer-engagement/kpi/${kpiTrigger.id}`;

        let body = {};
        body[fieldName] = kpiTrigger[fieldName];

        DatabaseApi.patch(url, body).then(function (res) {
            toaster.pop('success', "KPI Trigger updated successfully");
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not update KPI Trigger");
        });
    }

    $scope.$watch('engagementKPIFilters', function () {
        filterKPITriggersTable();
    }, true);

    $scope.getKPITriggers();
};