import Page from "../../shared/components/Page";
import AuthorizedPatientsWithoutVisitsTable from "./AuthorizedPatientsWithoutVisitsTable";

export default function AuthorizedPatientsWithoutVisitsPage() {
  return (
    <Page>
      <Page.Header>
        <Page.Title>Authorized Patients Without Visits</Page.Title>
      </Page.Header>
      <AuthorizedPatientsWithoutVisitsTable />
    </Page>
  );
}
