import { Instant } from "@js-joda/core";
import useCountdown from "../../../../../shared/hooks/useCountdown";
import { formatSeconds } from "../../../../../shared/utils/date-utils";

export default function Countdown(props: { end: Instant }) {
  const seconds = useCountdown({
    endsAt: props.end,
    onTick: () => {},
  });

  return <>{formatSeconds(seconds ?? 0)}</>;
}
