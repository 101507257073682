import { Badge } from "@chakra-ui/react";
import { Props } from "../IntakeDashboardTabView";
import useIntakeDashboard from "../hooks/useIntakeDashboard";

export function IncompletePatientFlowBadge(props: Props) {
  const { dashboardQuery } = useIntakeDashboard({
    orderedCalls: props.orderedCalls,
    filters: props.filters,
  });

  if (dashboardQuery.isPending || dashboardQuery.isError) {
    return null;
  }

  return (
    <Badge
      alignItems="center"
      background="red"
      borderRadius="md"
      color="white"
      display="flex"
      h="22px"
      justifyContent="center"
      position="absolute"
      right="-10px"
      top="-5px"
      w="22px"
    >
      {dashboardQuery.data.numberOfIncompletePatients}
    </Badge>
  );
}
