import {
  Flex,
  useDisclosure,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { Messages } from "../../core/api";
import Page from "../../shared/components/Page";
import { FilterProps } from "../../shared/utils/filter-props";
import CicoIssuesVisitsTable from "./CicoIssuesTable";
import AddRoundedIcon from "../../shared/icons/AddRoundedIcon";

import { useEntityLink } from "../../shared/hooks/useEntityLink";
import { VisitInstanceId } from "../../shared/schema/schema";
import VisitRecordForm from "./VisitRecordForm";
import { useMapSelection } from "../../shared/hooks/useMapSelection";
import {
  getInitialForm,
  groupVisitsByCaregivers,
  groupVisitsByPatients,
} from "./utils/cicoIssuesUtils";
import useAuth from "../../shared/hooks/useAuth";
import React from "react";
import CicoIssuesFilters from "./CicoIssuesFilters";

interface Props extends FilterProps<"./visits_with_cico_issues"> {
  visits: Messages["CicoIssuesVisit"][];
  isLoading: boolean;
  queryEnabled: boolean;
  setEnableQuery: (enabled: boolean) => void;
  onSaveRecords: (params: {
    visitInstanceIds: VisitInstanceId[];
    form: VisitRecordForm;
    onlyAssigneeChanged: boolean;
  }) => void;
}

export default function CICOReportsPage(props: Props) {
  const saveRecordsDisclosure = useDisclosure();
  const modalDisclosure = useDisclosure();
  const entityLink = useEntityLink();
  const auth = useAuth();

  const [groupedByPatients, setGroupedByPatients] = React.useState<boolean>(false);
  const [groupedByCaregivers, setGroupedByCaregivers] = React.useState<boolean>(false);

  const selection = useMapSelection<VisitInstanceId, Messages["CicoIssuesVisit"]>();

  const handleClickGroupByPatients = () => {
    setGroupedByPatients(!groupedByPatients);
  };

  const handleClickGroupByCaregivers = () => {
    setGroupedByCaregivers(!groupedByCaregivers);
  };

  const groupedVisits = React.useMemo(() => {
    if (groupedByPatients) {
      return groupVisitsByPatients(props.visits);
    }
    if (groupedByCaregivers) {
      return groupVisitsByCaregivers(props.visits);
    }
    return props.visits;
  }, [props.visits, groupedByPatients, groupedByCaregivers]);

  const openGroupedVisitsModal = (visit: Messages["CicoIssuesVisit"]) => {
    if (groupedByPatients) {
      props.onChangeFilter("patientId", visit.patientId);
    }
    if (groupedByCaregivers) {
      props.onChangeFilter("caregiverId", visit.caregiverId);
    }
    modalDisclosure.onOpen();
  };

  const closeGroupedVisitsModal = () => {
    if (groupedByPatients) {
      props.onChangeFilter("patientId", undefined);
    }
    if (groupedByCaregivers) {
      props.onChangeFilter("caregiverId", undefined);
    }
    modalDisclosure.onClose();
  };

  const handleClickRow = (
    event: React.MouseEvent<HTMLElement>,
    visit: Messages["CicoIssuesVisit"]
  ) => {
    if (event.target instanceof HTMLElement || event.target instanceof SVGElement) {
      const isClickableTarget = ["P", "SPAN", "INPUT", "svg"].includes(event.target.tagName);
      const isInCheckbox = event.target.closest(".chakra-checkbox__checkbox") !== null;

      if (isClickableTarget || isInCheckbox) {
        return;
      }
    }

    if (groupedByCaregivers || groupedByPatients) {
      openGroupedVisitsModal(visit);
      return;
    }

    entityLink.open({ event, entity: { type: "VisitInstance", id: visit.visitId } });
  };

  const handleClickEdit = (visit: Messages["CicoIssuesVisit"]) => {
    selection.clear();
    if (groupedByCaregivers) {
      props.visits.forEach((v) => {
        if (v.caregiverId === visit.caregiverId) {
          selection.toggle(v.visitId, v);
        }
      });
    } else if (groupedByPatients) {
      props.visits.forEach((v) => {
        if (v.patientId === visit.patientId) {
          selection.toggle(v.visitId, v);
        }
      });
    } else {
      selection.add(visit.visitId, visit);
    }
    saveRecordsDisclosure.onOpen();
  };

  const handleClickSelectAll = (visits: Messages["CicoIssuesVisit"][]) => {
    if (selection.state.length > 0) {
      selection.clear();
    } else {
      visits.forEach((v) => {
        selection.toggle(v.visitId, v);
      });
    }
  };

  const handleClickCheckbox = (visit: Messages["CicoIssuesVisit"]) => {
    if (groupedByCaregivers) {
      props.visits.forEach((v) => {
        if (v.caregiverId === visit.caregiverId) {
          selection.toggle(v.visitId, v);
        }
      });
    } else if (groupedByPatients) {
      props.visits.forEach((v) => {
        if (v.patientId === visit.patientId) {
          selection.toggle(v.visitId, v);
        }
      });
    } else {
      selection.toggle(visit.visitId, visit);
    }
  };

  const handleSaveRecords = (form: VisitRecordForm) => {
    const initialForm = getInitialForm(selection.state);

    const onlyAssigneeChanged =
      initialForm?.assignedAgencyMemberId !== form.assignedAgencyMemberId &&
      ((initialForm?.note === null && form.note === "") || initialForm?.note === form.note) &&
      initialForm?.attemptedToReach === form.attemptedToReach &&
      initialForm?.successfullyReached === form.successfullyReached &&
      initialForm?.timesheetSent === form.timesheetSent;

    props.onSaveRecords({
      visitInstanceIds: selection.state.map((x) => x.visitId),
      form,
      onlyAssigneeChanged,
    });
    saveRecordsDisclosure.onClose();
    selection.clear();
  };

  const handleClickSearch = () => {
    props.setEnableQuery(true);
  };

  const actionNode = (
    <Flex gap={4}>
      <Button
        colorScheme="blue"
        isDisabled={selection.state.length === 0}
        isLoading={props.isLoading}
        leftIcon={<AddRoundedIcon />}
        ms="auto"
        onClick={saveRecordsDisclosure.onOpen}
      >
        Add / Edit
      </Button>
    </Flex>
  );

  const filterNode = (
    <Flex alignItems="flex-start" direction="column" gap={4}>
      <CicoIssuesFilters
        filters={props.filters}
        groupedByCaregivers={groupedByCaregivers}
        groupedByPatients={groupedByPatients}
        onChangeFilter={props.onChangeFilter}
        onChangeFilters={props.onChangeFilters}
        onClickGroupByCaregivers={handleClickGroupByCaregivers}
        onClickGroupByPatients={handleClickGroupByPatients}
      />
      <Button colorScheme="blue" size="lg" width="md" onClick={handleClickSearch}>
        Search
      </Button>
      <Button colorScheme="blue" variant="ghost">
        {selection.state.length > 1 && (
          <p>
            {selection.state.length} Record{selection.state.length > 1 ? "s" : ""} Selected
          </p>
        )}
      </Button>
    </Flex>
  );

  return (
    <Page>
      <Page.Header>
        <Page.Title>Clock In/Out Reports</Page.Title>
      </Page.Header>
      <Page.Content>
        <CicoIssuesVisitsTable
          actionNode={actionNode}
          filterNode={filterNode}
          filters={props.filters}
          groupByCaregivers={groupedByCaregivers}
          groupByPatients={groupedByPatients}
          queryEnabled={props.queryEnabled}
          selectedVisits={selection.keys}
          setEnabled={props.setEnableQuery}
          visits={groupedVisits}
          onClickCheckbox={handleClickCheckbox}
          onClickEdit={handleClickEdit}
          onClickRow={handleClickRow}
          onSelectAllVisits={handleClickSelectAll}
        />
        <Drawer {...saveRecordsDisclosure} size="xl">
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <VisitRecordForm
                canEditAssignee={auth.can("edit_cico_issues_visits")}
                initialForm={getInitialForm(selection.state)}
                isLoading={props.isLoading}
                selectedVisits={selection.state}
                onSaveRecords={handleSaveRecords}
              ></VisitRecordForm>
            </DrawerBody>
          </DrawerContent>
          <DrawerOverlay />
        </Drawer>
        <Modal isCentered {...modalDisclosure} size="6xl" onClose={closeGroupedVisitsModal}>
          <ModalOverlay />
          <ModalContent height="fit-content" minH={700}>
            <ModalCloseButton />
            <ModalBody height="fit-content" minH={700}>
              <CicoIssuesVisitsTable
                actionNode={actionNode}
                filters={props.filters}
                groupByCaregivers={false}
                groupByPatients={false}
                paginationSize={5}
                queryEnabled={true}
                selectedVisits={selection.keys}
                setEnabled={() => {}}
                visits={groupedVisits}
                onClickCheckbox={(visit) => selection.toggle(visit.visitId, visit)}
                onClickEdit={handleClickEdit}
                onClickRow={handleClickRow}
                onSelectAllVisits={handleClickSelectAll}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Page.Content>
    </Page>
  );
}
