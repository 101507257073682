import { Button, ButtonProps } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import MdRefreshIcon from "../../../shared/icons/MdRefreshIcon";
import { queryKeys } from "../../../shared/query-keys";
import { PatientId } from "../../../shared/schema/schema";

export default function PatientIntakeRescheduleNextCallButton(
  props: ButtonProps & { patientId: PatientId }
) {
  const { patientId, ...rest } = props;
  const { api, queries } = useApi();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (patientId: PatientId) => {
      return api.post("./patients/:patientId/reschedule_next_call", {
        path: {
          patientId,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queries.intake.profile(patientId));
      queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.intakeCallsOrder() });
    },
  });

  return (
    <Button
      colorScheme="blue"
      isLoading={mutation.isPending}
      leftIcon={<MdRefreshIcon boxSize={6} />}
      variant="outline"
      onClick={() => mutation.mutate(props.patientId)}
      {...rest}
    >
      Reschedule Next Call
    </Button>
  );
}
