import { Box, Button, chakra, Flex } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import React from "react";
import BaseDatePicker, { BaseDatePickerProps } from "./BaseDatePicker";

const CalendarBox = chakra(Box, {
  baseStyle: {
    display: "flex",
    width: "fit-content",
    gap: 3,
  },
});

const ActionsBox = chakra(Box, {
  baseStyle: {
    borderColor: "gray.200",
    borderTopWidth: "1px",
    paddingTop: 2,
    marginTop: 2,
  },
});

export interface SingleDateTimePickerProps
  extends Omit<BaseDatePickerProps, "monthsShown" | "onChange"> {
  onChange: (
    date: LocalDateTime | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => void;
}

const SingleDateTimePicker = (props: SingleDateTimePickerProps) => {
  const handleSetClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return props.onChange(null, e);
  };

  const handleSetToday = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return props.onChange(LocalDateTime.now(), e);
  };

  return (
    <BaseDatePicker
      {...props}
      showTimeSelect
      calendarContainer={({ children }) => {
        return (
          <>
            <CalendarBox>{children}</CalendarBox>
            <ActionsBox>
              <Flex alignItems="center" gap={8} justifyContent="space-between">
                <Button color="negative" size="sm" variant="ghost" onClick={handleSetClear}>
                  Clear
                </Button>
                <Button size="sm" variant="ghost" onClick={handleSetToday}>
                  Today
                </Button>
              </Flex>
            </ActionsBox>
          </>
        );
      }}
      dateFormat="MM/dd/yyyy HH:mm"
      monthsShown={1}
      shouldCloseOnSelect={false}
      timeFormat="HH:mm"
      timeIntervals={15}
    />
  );
};

export default SingleDateTimePicker;
