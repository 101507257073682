import { Center, Spinner } from "@chakra-ui/react";
import Page from "./Page";

const LoadingPage = () => {
  return (
    <Page>
      <Center color="gray.500" flex={1} flexDirection="column" gap={8}>
        <Spinner size="xl" />
      </Center>
    </Page>
  );
};

export default LoadingPage;
