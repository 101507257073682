import { atom, useAtom } from "jotai";
import { fmap } from "../../../shared/utils";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";

type State = {
  isOpen: boolean;
  predefinedPhoneNumber?: string;
};

const dialerAtom = atom<State>({ isOpen: false });

export function usePhoneNumberDialerPopup() {
  const [state, setState] = useAtom(dialerAtom);

  const open = (params?: { predefinedPhoneNumber?: string }) => {
    const formatted =
      fmap(params?.predefinedPhoneNumber, phoneFormatter.formatNumberWithoutCountryPrefix) ??
      undefined;

    if (state.isOpen && formatted !== state.predefinedPhoneNumber) {
      setState({ isOpen: false });
      setTimeout(() => {
        setState({ isOpen: true, predefinedPhoneNumber: formatted });
      }, 150);
      return;
    }

    setState({ isOpen: true, predefinedPhoneNumber: formatted });
  };

  const close = () => {
    setState({ isOpen: false });
  };

  return {
    state,
    open,
    close,
  };
}
