import { Tag, Flex, Text } from "@chakra-ui/react";
import SmsTicketStatus from "../SmsTicketStatus";
import { getMessagePreview, ChatTicket } from "../../utils/communication-utils";
import { CommunicationLogVerticalDivider } from "./CommunicationLog";
import { Messages } from "../../../../core/api";
import { sortByInstant } from "../../../../shared/utils/date-utils";
import { fmap } from "../../../../shared/utils";

interface Props {
  ticket: ChatTicket;
}

const getLastMessage = (messages: Messages["CommCenterTicket"]["messages"]) => {
  const orderedMessages = sortByInstant(messages, "createdAt", "asc");
  return orderedMessages.at(-1);
};

const CommunicationLogMobileChatDetails = (props: Props) => {
  const { assignedTo, chatSummaryDetails, relatedCaregiver, messages } = props.ticket;

  const assignedToName = assignedTo?.name ?? "Unkown agency member";
  const caregiverName = relatedCaregiver?.name ?? "Unkown caregiver";
  const description = `Chat between ${assignedToName} and ${caregiverName}`;

  const summaryText =
    chatSummaryDetails !== null && chatSummaryDetails.summary !== null
      ? chatSummaryDetails.summary
      : null;

  const lastMessage = getLastMessage(messages);

  return (
    <>
      <Flex alignItems="center" gap={2}>
        <SmsTicketStatus messages={props.ticket.messages} status={props.ticket.status} />
        <CommunicationLogVerticalDivider />
        <Text>{description}</Text>
        <CommunicationLogVerticalDivider />
        {props.ticket.label !== null && <Tag>{props.ticket.label.name}</Tag>}
      </Flex>
      <Text>{summaryText ?? fmap(lastMessage, getMessagePreview) ?? "No summary available"}</Text>
    </>
  );
};

export default CommunicationLogMobileChatDetails;
