import Immutable from "immutable";
import React from "react";
import { parseFromStorage } from "../utils/storage";

export type QueryParams<$QueryParams> = {
  params: Immutable.Map<keyof $QueryParams, unknown>;
  getIsDirty<T extends keyof $QueryParams>(key: T): boolean;
  getValue: <K extends keyof $QueryParams>(key: K) => $QueryParams[K] | undefined;
  getValueOrNull: <K extends keyof $QueryParams>(key: K) => NonNullable<$QueryParams[K]> | null;
  setValue: <K extends keyof $QueryParams>(key: K, value: $QueryParams[K]) => void;
  setValues: (values: Partial<$QueryParams>) => void;
  resetToDefault: () => void;
};

export function useQueryParams<$QueryParams extends { [key: string]: unknown }>(
  params?: Partial<{ storageKey: string[]; defaultOptions: $QueryParams }>
): QueryParams<$QueryParams> {
  const defaultOptionsRef = React.useRef(params?.defaultOptions);
  const [queryParams, setQueryParams] = React.useState<
    Immutable.Map<keyof $QueryParams, $QueryParams[number]>
  >(() => {
    if (params?.storageKey !== undefined) {
      const parsed = parseFromStorage<object>({ key: params.storageKey, storage: sessionStorage });

      if (parsed !== null) {
        return Immutable.Map(parsed);
      }
    }
    if (defaultOptionsRef.current !== undefined) {
      return Immutable.Map(defaultOptionsRef.current as any);
    }

    return Immutable.Map();
  });
  const [isDirty, setIsDirty] = React.useState<Record<keyof $QueryParams, boolean>>(
    Object.fromEntries(Array.from(queryParams.keys()).map((key) => [key, false])) as Record<
      keyof $QueryParams,
      boolean
    >
  );

  const setValue = <T extends keyof $QueryParams>(key: T, value: $QueryParams[T] | undefined) => {
    setIsDirty((prev) => ({ ...prev, [key]: true }));
    setQueryParams((prev) => {
      const newParams = value === undefined ? prev.delete(key) : prev.set(key, value as any);

      if (params?.storageKey !== undefined) {
        sessionStorage.setItem(
          JSON.stringify(params.storageKey),
          JSON.stringify(newParams.toObject())
        );
      }

      return newParams;
    });
  };

  const setValues = (values: Partial<$QueryParams>) => {
    const newParams = Immutable.Map({
      ...queryParams.toJS(),
      ...values,
    }) as Immutable.Map<keyof $QueryParams, $QueryParams[number]>;

    if (params?.storageKey !== undefined) {
      sessionStorage.setItem(
        JSON.stringify(params.storageKey),
        JSON.stringify(newParams.toObject())
      );
    }

    setQueryParams(newParams);
  };

  const getValue = <T extends keyof $QueryParams>(key: T) => {
    return queryParams.get(key) as $QueryParams[T] | undefined;
  };

  const getValueOrNull = <T extends keyof $QueryParams>(key: T) => {
    return (queryParams.get(key) ?? null) as NonNullable<$QueryParams[T]> | null;
  };

  const resetToDefault = React.useCallback(() => {
    sessionStorage.removeItem(JSON.stringify(params?.storageKey));

    if (defaultOptionsRef.current !== undefined) {
      setQueryParams(Immutable.Map(defaultOptionsRef.current as any));
    }

    setQueryParams(Immutable.Map());
  }, [params?.storageKey]);

  return {
    params: queryParams,
    setValue,
    setValues,
    getValue,
    getValueOrNull,
    resetToDefault,
    getIsDirty: (key) => isDirty[key] ?? false,
  };
}
