import { AttachmentIcon } from "@chakra-ui/icons";
import { Button, IconButton, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import React from "react";
import BaselinePlayCircleIcon from "../icons/BaselinePlayCircleIcon";
import DocumentIcon from "../icons/DocumentIcon";
import PhotoFillIcon from "../icons/PhotoFillIcon";

interface Props {
  onSelectFile: (newFile: File) => void;
}

const FileAttachmentButton = (props: Props) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (acceptedFiles: string) => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.accept = acceptedFiles;
      hiddenFileInput.current.click();
    }
  };

  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;

    if (file === null || file.length === 0) {
      return;
    }

    props.onSelectFile(file[0]);
  };

  return (
    <Popover closeOnBlur={true} placement="top-end">
      <PopoverTrigger>
        <IconButton aria-label="attachment" icon={<AttachmentIcon />} rounded="2xl" size="lg" />
      </PopoverTrigger>
      <PopoverContent backgroundColor="transparent" border={0} w="36">
        <Button
          colorScheme="gray"
          leftIcon={<PhotoFillIcon />}
          marginBottom="1.5"
          size="md"
          onClick={() => handleClick("image/*")}
        >
          Photo
        </Button>
        <Button
          colorScheme="gray"
          leftIcon={<BaselinePlayCircleIcon />}
          marginBottom="1.5"
          size="md"
          onClick={() => handleClick("video/*")}
        >
          Video
        </Button>
        <Button
          colorScheme="gray"
          leftIcon={<DocumentIcon />}
          size="md"
          onClick={() => handleClick(".pdf")}
        >
          Document
        </Button>
        <input
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          onChange={handleFileSelection}
        />
      </PopoverContent>
    </Popover>
  );
};
export default FileAttachmentButton;
