import { Portal } from "@chakra-ui/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CustomizeAddressModalListener from "./CustomizeAddressModalListener";
import { Global } from "@emotion/react";
import { enableMapSet } from "immer";

enableMapSet();

const GlobalStyle = () => <Global styles={{ "react-ui-view-adapter": { fontSize: 14 } }} />;

/**
 * This component is global in the webapp and is being imported from content.html
 */
export default function ReactGlobalHost() {
  return (
    <>
      <GlobalStyle />
      <Portal>
        <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
      </Portal>
      <CustomizeAddressModalListener />
    </>
  );
}
