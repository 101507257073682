import React from "react";
import { createRoot } from "react-dom/client";

export function renderReactComponent<P>(params: {
  $element: [HTMLElement];
  component: React.ComponentType<P>;
  props: P & JSX.IntrinsicAttributes;
}) {
  const host = params.$element[0];
  const container = host.appendChild(document.createElement("div"));
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <params.component {...params.props} />
    </React.StrictMode>
  );

  return {
    unmount: () => {
      root.unmount();
      host.removeChild(container);
    },
  };
}
