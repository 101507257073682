import useApi from "../../../shared/hooks/useApi";
import { CaregiverId } from "../../../shared/schema/schema";
import { useQuery } from "@tanstack/react-query";
import SendSMSButton from "../../../shared/components/SendSMSButton";
import { ResponseOf } from "../../../core/api";
import { MessageTemplate } from "../../../shared/components/SmsSender/sms-sender.types";
import { ButtonProps } from "@chakra-ui/react";

function isCaregiverSmsable(
  caregiver: ResponseOf<"get", "./caregivers/:caregiverId">["caregiver"]
) {
  return caregiver.phoneNumbers.some((phoneNumber) => phoneNumber.type === "mobile");
}

export default function CaregiverSendSMSButton(props: {
  caregiverId: CaregiverId;
  predefinedPhoneNumber?: string;
  predefinedSmsMessageTemplate?: MessageTemplate;
  buttonProps?: ButtonProps;
  text?: string;
}) {
  const { queries } = useApi();

  const caregiver = useQuery(queries.caregiver.get(props.caregiverId));

  if (caregiver.isError) {
    return (
      <SendSMSButton
        buttonProps={props.buttonProps}
        caregiverId={props.caregiverId}
        entity="Caregiver"
        isDisabled={true}
        isLoading={false}
        predefinedMessageTemplate={props.predefinedSmsMessageTemplate}
        predefinedPhoneNumber={props.predefinedPhoneNumber}
        text="Error while loading SMS button"
      />
    );
  }

  const isSmsButtonDisabled = !caregiver.isSuccess || !isCaregiverSmsable(caregiver.data);

  return (
    <SendSMSButton
      buttonProps={props.buttonProps}
      caregiverId={props.caregiverId}
      entity="Caregiver"
      isDisabled={isSmsButtonDisabled}
      isLoading={caregiver.isLoading}
      predefinedMessageTemplate={props.predefinedSmsMessageTemplate}
      predefinedPhoneNumber={props.predefinedPhoneNumber}
      text={props.text}
    />
  );
}
