import {
  EditPatientIntake,
  PatientIntakePlan,
  PatientIntakeProfileDraft,
} from "../patient-intake.types";

function useIntakePatientPlans(props: {
  plans: PatientIntakeProfileDraft["plans"];
  onEditPatientIntakePlans: (value: EditPatientIntake["plans"]) => void;
}) {
  const editPatientPlan = (key: string, newPlanInfo: PatientIntakePlan) => {
    if (props.plans !== null) {
      const planIndex = props.plans.findIndex((plan) => plan.key === key);

      const newPlan = {
        ...props.plans[planIndex],
        ...(newPlanInfo.plan !== null ? { plan: newPlanInfo.plan } : {}),
        ...newPlanInfo,
      };
      props.plans[planIndex] = newPlan;
      props.onEditPatientIntakePlans(props.plans);
    }
  };

  const handleAddNewPatientPlan = (newPlanInfo: PatientIntakePlan) => {
    if ((props.plans === null || props.plans.length === 0) && newPlanInfo.current !== true) {
      newPlanInfo.main = true;
    }

    const newPlansArray = props.plans === null ? [newPlanInfo] : [...props.plans, newPlanInfo];
    props.onEditPatientIntakePlans(newPlansArray);
  };

  const handleRemovePatientPlan = (keyToRemove: string) => {
    if (props.plans !== null && props.plans.length > 0) {
      const indexToRemove = props.plans.findIndex((plan) => plan.key === keyToRemove);
      props.plans.splice(indexToRemove, 1);
      props.onEditPatientIntakePlans(props.plans);
    }
  };

  return {
    plans: props.plans,
    editPatientPlan,
    createPatientPlan: handleAddNewPatientPlan,
    removePatientPlan: handleRemovePatientPlan,
  };
}

export default useIntakePatientPlans;
