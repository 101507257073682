import { EditIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  chakra,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../core/api";
import { editorTheme } from "../../../shared/components/Lexical/editor-utils";
import SafeHTML from "../../../shared/components/SafeHTML";
import useApi from "../../../shared/hooks/useApi";
import DotsVerticalIcon from "../../../shared/icons/DotsVerticalIcon";
import ExportNotesIcon from "../../../shared/icons/ExportNotesIcon";
import FaxIcon from "../../../shared/icons/FaxIcon";
import { NoteCommentId, NoteFileId, NoteId } from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { getFullName } from "../../../shared/utils/get-full-name";
import { getNoteHeadlines } from "../utils/entity-note.utils";
import EditNoteModal from "./EditNoteModal";
import EntityNoteComments from "./EntityNoteComments";
import NoteFileViewerModal from "./NoteFileViewerModal";
import NoteStatusTag from "./NoteStatusTag";
import RemoveNoteModal from "./RemoveNoteModal";

type Props = {
  note: Messages["EntityNote"]; // should be changed to BasicNote
  isActionLoading: boolean;
  isSelected: boolean;
  onToggleSelect: (noteId: Messages["EntityNote"]) => void;
  onSuccessEdit: () => void; // TODO should we lift onClickEdit instead?
  onSuccessRemove: () => void; // TODO should we lift onClickRemove instead?
  onSuccessComment: () => void;
  onRemoveComment: (comment: { id: NoteCommentId; noteId: NoteId }) => void;
  onClickExport: (noteId: NoteId) => void;
  onClickEmail: (noteId: Messages["EntityNote"]) => void;
  onClickFax: (noteId: Messages["EntityNote"]) => void;
};

export default function EntityNoteBox(props: Props) {
  const { title, secondary, instances } = getNoteHeadlines(props.note);
  const editNoteModalDisclosure = useDisclosure();
  const removeNoteModalDisclosure = useDisclosure();
  const noteFileViewerModalDisclosure = useDisclosure();
  const [isShowAllInstances, setShowAllInstances] = React.useState(false);
  const [selectedFileId, setSelectedFileId] = React.useState<NoteFileId | null>(null);
  const { api } = useApi();
  const toast = useToast();

  const removeMutation = useMutation({
    mutationFn: () => api.delete("./notes/:noteId", { path: { noteId: props.note.id } }),
    onSuccess: () => {
      removeNoteModalDisclosure.onClose();
      props.onSuccessRemove();

      toast({
        title: "Note removed",
        status: "info",
        position: "top-right",
      });
    },
  });

  const handleClickFile = (file: Pick<Messages["NoteFile"], "id">) => {
    setSelectedFileId(file.id);
    noteFileViewerModalDisclosure.onOpen();
  };

  return (
    <CardRoot>
      <CardHeaderFlex>
        <Flex align="center" gap={2} wrap="wrap">
          <Heading fontWeight="semibold" size="sm">
            {title}
          </Heading>
          {secondary !== undefined && (
            <Text color="gray.600" size="xs">
              ({secondary})
            </Text>
          )}
        </Flex>
        <Flex align="center" gap={2} h="fit-content">
          <Checkbox
            isChecked={props.isSelected}
            m={0}
            onChange={() => props.onToggleSelect(props.note)}
          />
          <Menu>
            <MenuButton
              aria-label="Options"
              as={IconButton}
              icon={
                props.isActionLoading ? (
                  <Spinner h={4} thickness="1px" w={4} />
                ) : (
                  <DotsVerticalIcon h={4} w={4} />
                )
              }
              size="sm"
              variant="ghost"
            />
            <MenuList>
              <MenuItem icon={<EditIcon fontSize="lg" />} onClick={editNoteModalDisclosure.onOpen}>
                Edit
              </MenuItem>
              <MenuItem
                icon={<EmailIcon fontSize="lg" />}
                onClick={() => props.onClickEmail(props.note)}
              >
                Send to Email
              </MenuItem>
              <MenuItem
                icon={<FaxIcon fontSize="lg" />}
                onClick={() => props.onClickFax(props.note)}
              >
                Send to Fax
              </MenuItem>
              <MenuItem
                icon={<ExportNotesIcon fontSize="lg" />}
                onClick={() => props.onClickExport(props.note.id)}
              >
                Export as PDF
              </MenuItem>

              <MenuDivider />

              <MenuItem color="red" onClick={removeNoteModalDisclosure.onOpen}>
                Remove note
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </CardHeaderFlex>

      <CardBodyFlex>
        {props.note.status !== "NONE" && (
          <Box mb={1}>
            <NoteStatusTag status={props.note.status} />
          </Box>
        )}
        <Flex gap={1}>
          <Text fontWeight="semibold">Subject:</Text>
          <Text>{props.note.subject?.text ?? "N/A"}</Text>
        </Flex>

        <Flex gap={1}>
          <Text fontWeight="semibold">Reason:</Text>
          <Text>{props.note.predefinedAnswerText ?? "N/A"}</Text>
        </Flex>

        <Flex gap={1}>
          <Text fontWeight="semibold">Follow-up:</Text>
          <Text>{fmap(props.note.followUp, dateFormatter.toDate) ?? "N/A"}</Text>
        </Flex>

        {instances !== undefined && (
          <Flex gap={1}>
            <Text fontWeight="semibold">{instances.length === 1 ? "Record:" : "Records:"}</Text>
            <Flex direction="column" gap={1} ms={1}>
              {instances.slice(0, isShowAllInstances ? instances.length : 2).map((instance) => (
                <Box key={instance.name} color="gray.600" p={0}>
                  {instance.name}
                </Box>
              ))}

              {instances.length > 2 && (
                <Button
                  colorScheme="blue"
                  variant="link"
                  w="fit-content"
                  onClick={() => setShowAllInstances((x) => !x)}
                >
                  {isShowAllInstances ? "Show less" : `Show all ${instances.length} records`}
                </Button>
              )}
            </Flex>
          </Flex>
        )}

        {props.note.files.length > 0 && (
          <Flex gap={1}>
            <Text fontWeight="semibold">Files:</Text>
            <Flex direction="column" gap={1} ms={1}>
              {props.note.files.map((file) => (
                <Button
                  key={file.id}
                  color="black"
                  lineHeight={1.5}
                  variant="link"
                  onClick={() => handleClickFile(file)}
                >
                  By {file.createdByName} at {dateFormatter.toDateOrDateTime(file.createdAt)}
                </Button>
              ))}
            </Flex>
          </Flex>
        )}

        {props.note.noteRichText !== null && (
          <Flex direction="column" gap={2}>
            <Text fontWeight="semibold">Content:</Text>
            <Box
              bg="gray.50"
              p={5}
              rounded="md"
              sx={{
                ...editorTheme,
              }}
            >
              <SafeHTML html={props.note.noteRichText} />
            </Box>
          </Flex>
        )}

        <EntityNoteComments
          comments={props.note.comments}
          note={props.note}
          onRemoveComment={props.onRemoveComment}
          onSuccessComment={props.onSuccessComment}
        />
      </CardBodyFlex>

      <CardFooterFlex>
        <Flex isTruncated gap={2}>
          <Avatar
            name={getFullName(props.note.author)}
            size="xs"
            src={props.note.author.photoUrl ?? undefined}
          />
          <Text isTruncated>{getFullName(props.note.author)}</Text>
        </Flex>
        <Flex isTruncated gap={2}>
          <Text isTruncated>{dateFormatter.toDateOrDateTime(props.note.createdAt)}</Text>
        </Flex>
      </CardFooterFlex>

      <EditNoteModal
        disclosure={editNoteModalDisclosure}
        noteId={props.note.id}
        onSuccess={props.onSuccessEdit}
      />

      <RemoveNoteModal
        disclosure={removeNoteModalDisclosure}
        isLoading={removeMutation.isPending}
        onClickRemove={removeMutation.mutate}
      />

      {selectedFileId !== null && (
        <NoteFileViewerModal
          disclosure={noteFileViewerModalDisclosure}
          noteFileId={selectedFileId}
        />
      )}
    </CardRoot>
  );
}

const CardRoot = chakra(Flex, {
  baseStyle: {
    borderColor: "gray.200",
    borderWidth: "1px",
    flexDirection: "column",
    fontSize: "md",
    rounded: "md",
  },
});

const CardHeaderFlex = chakra(Flex, {
  baseStyle: {
    bg: "gray.50",
    borderBottomWidth: "1px",
    borderColor: "gray.200",
    gap: 2,
    justifyContent: "space-between",
    alignItems: "center",
    p: 3,
    roundedTop: "md",
  },
});

const CardBodyFlex = chakra(Flex, {
  baseStyle: {
    flexDirection: "column",
    gap: 2,
    p: 5,
  },
});

const CardFooterFlex = chakra(Flex, {
  baseStyle: {
    bg: "gray.50",
    borderColor: "gray.200",
    borderTopWidth: "1px",
    color: "gray.600",
    justifyContent: "space-between",
    alignItems: "center",
    px: 5,
    py: 3,
    roundedBottom: "md",
  },
});

EntityNoteBox.CardRoot = CardRoot;
EntityNoteBox.CardHeaderFlex = CardHeaderFlex;
EntityNoteBox.CardBodyFlex = CardBodyFlex;
EntityNoteBox.CardFooterFlex = CardFooterFlex;
