import WorkflowTaskDrawer from "./WorkflowTaskDrawer";
import WorkflowTasksPage from "./WorkflowTasksPage";

export default function WorkflowTasksRoute() {
  return (
    <>
      <WorkflowTasksPage />
      <WorkflowTaskDrawer />
    </>
  );
}
