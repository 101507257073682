import {
  QueryKey,
  QueryObserverSuccessResult,
  UndefinedInitialDataOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { ReactNode } from "react";

queryOptions;
export default function QueryResolver<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(props: {
  options: UndefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey>;
  onLoading: ReactNode | (() => ReactNode);
  onError: ReactNode | ((error: unknown) => ReactNode);
  onSuccess: ReactNode | ((result: QueryObserverSuccessResult<TData, TError>) => ReactNode);
}) {
  const { options, onLoading, onError, onSuccess } = props;

  const query = useQuery(options);

  if (query.isPending) {
    return <>{typeof onLoading === "function" ? onLoading() : onLoading}</>;
  }

  if (query.isError) {
    return <>{typeof onError === "function" ? onError(query.error) : onError}</>;
  }

  return <>{typeof onSuccess === "function" ? onSuccess(query) : onSuccess}</>;
}
