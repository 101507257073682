import { Instant, LocalDate, LocalTime } from "@js-joda/core";
import { AddressComponents } from "./address_component";
import { AgencyId, AgencyMemberId, AgencyTeamId, CaregiverId, OfficeId, PatientId } from "./ids";
import { NotImported } from "./_not_imported";

export interface PatientAddressComponents {
  text: string;
  timezone: string;
  components: AddressComponents;
}

export interface PatientFamilyInformation {
  firstName: string;
  lastName: string;
  relationship: string;
  mobilePhoneNumber: string;
  homePhoneNumber: string;
}

export interface PatientEmergencyContact {
  firstName: string;
  lastName: string;
  relationship: string;
  livesWithPatient: boolean;
  emergencyPhoneNumber1: string;
  emergencyPhoneNumber2: string;
  address: string;
}

export interface LinkedPatient {
  id: PatientId;
  mutualLink: boolean | null;
  linkedWith: PatientId | null;
  linkedPatientName: string | null;
}

interface MiniCaregiver {
  caregiverId: CaregiverId;
  fullName: string;
  photoUrl: string | null;
}

export interface PatientExtendedData {
  id: PatientId;
  status: PatientStatus;
  createdAt: Instant;
  currentOfficeId: OfficeId;
  contracts: unknown[];
  clinicalInfo: unknown[];
  socialWorker: unknown | null;
  source: unknown | null;
  ssn: string | null;
  firstName: string;
  middleName: string | null;
  lastName: string;
  gender: string | null;
  dateOfBirth: LocalDate | null;
  address: PatientAddressComponents | null;
  address2: string | null;
  generalNotes: string | null;
  medicalNotes: string | null;
  medicareNumber: string | null;
  medicaidNumber: string | null;
  wageParityNumber: number | null;
  mainLanguage: string | null;
  secondaryLanguage: string | null;
  preferredHHAGender: string | null;
  smoking: boolean | null;
  pets: string | null;
  kosherHome: boolean | null;
  kidsInHouse: number | null;
  familyInformation: PatientFamilyInformation[] | null;
  emergencyContact: PatientEmergencyContact[] | null;
  priorityCode: number | null;
  evacuationZone: string | null;
  mobilityStatus: string | null;
  evacuationLocation: string | null;
  electricEquipmentDependency: string | null;
  allergies: NotImported<"Allergy">[];
  patientHIClaim: string | null;
  email: string | null;
  assignedSalesRep: AgencyMemberId | null;
  assignedIntake: AgencyMemberId | null;
  assignedCoordinator: AgencyMemberId | null;
  agencyTeamId: NotImported<"AgencyTeamId"> | null;
  assignedDPS: AgencyMemberId | null;
  updatedAt: Instant;
  updatedBy: AgencyMemberId;
  linkedPatient: LinkedPatient;
  phoneNumbers: NotImported<"PatientPhoneNumber">[];
  addresses: NotImported<"PatientAddress">[];
  nurseOnCase: MiniCaregiver | null;
  caregiverOnCase: MiniCaregiver | null;
  missingAddressFields?: string[];
  displayId: number | null;
  agencyBranchIds: NotImported<"AgencyBranchId">[];
  customFields: NotImported<"EntityCustomField">;
  externalId: string | null;
  locationGroupName: string | null;
  blacklisted: CaregiverId[] | null;
  wageParity: boolean | null;
  vacations: NotImported<"PatientVacation">[];
  startOfCare: LocalDate | null;
}

export interface Patients {
  patients: Patient[];
}
export interface Patient {
  id: PatientId;
  ssn: string | null;
  firstName: string;
  middleName: string | null;
  lastName: string;
  homePhoneNumber: string | null;
  mobilePhoneNumber: string | null;
  phone3: string | null;
  phone4: string | null;
  address: PatientAddressComponents | null;
  address2: string | null;
  status: PatientStatus;
  createdAt: Instant;
  agency: AgencyId;
  gender: string | null;
  mutualLink: boolean | null;
  linkedWith: PatientId | null;
  updatedAt: Instant;
  updatedBy: AgencyMemberId;
  currentOfficeId: OfficeId;
  agencyTeamId: AgencyTeamId | null;
  assignedCoordinator: AgencyMemberId | null;
  timezone: string;
  dateOfBirth: LocalDate | null;
  displayId: number | null;
  admissionId: string | null;
  startOfCare: LocalDate | null;
}

export type PatientStatus = typeof patientStatuses[number];
export const patientStatuses = [
    "REFERRAL",
    "PENDING_FILES",
    "ELIGIBLE",
    "ACCEPTED",
    "ACTIVE",
    "DISCHARGED",
    "ON_HOLD",
    "HOSPITALIZED",
    "VACATION",
    "DECEASED",
    "DRAFT",
    "LOST",
] as const;
export interface PatientAvailabilityTimeFrame {
  id: number;
  name: string;
  startTime: LocalTime;
  endTime: LocalTime;
}
export interface PatientAvailabilityTimeFrames {
  timeframes: PatientAvailabilityTimeFrame[];
}
export interface PatientAvailability {
  id?: number;
  day: string;
  timeframesIds: PatientAvailabilityTimeFrame[];
}
export interface PatientSurplusCreateParams {
  amountInCents: number;

  fromDate: LocalDate;
  toDate: LocalDate;

  patientContractId: number;
}
export interface PatientSurplusCheckCreateParams {
  totalAmountInCents: number;
  receivedAt: LocalDate;
  patientId: number;
}
export interface PatientSurplusInvoiceCreateParams {
  datesArr: LocalDate | Date[];
  patientId: number;
}
