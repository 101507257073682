import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";
import { PatientId } from "../../shared/schema/schema";

export default createQueryGroup(({ api }) => {
  return {
    get: (id: PatientId) =>
      queryOptions({
        queryKey: ["patient", "get", id],
        queryFn: () =>
          api.get("/agencies/:agencyId/patients/:patientId", { path: { patientId: id } }),
      }),
    search: (term: string) =>
      queryOptions({
        queryKey: ["patient", "search", term],
        queryFn: () => {
          return term.length > 0
            ? api.get("./patients/search", { query: { term } })
            : { patients: [] };
        },
      }),
    communicationLog: (id: PatientId) =>
      queryOptions({
        queryKey: ["patient", id, "communicationLog"],
        queryFn: () =>
          api.get("./comm_center/tickets/patients/:patientId", {
            path: { patientId: id },
          }),
      }),
  };
});
