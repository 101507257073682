import { Divider, Flex } from "@chakra-ui/react";
import { PatientId } from "../../../../../shared/schema/schema";
import { WEBAPP_FOOTER_HEIGHT } from "../../../../../shared/utils";
import { usePhoneNumberDialerPopup } from "../../../../call-center/hooks/usePhoneNumberDialerPopup";
import IntakePatientProfileRoute from "../../../components/IntakePatientProfileRoute";
import usePatientIntakeFlow from "../hooks/usePatientIntakeFlow";
import PatientIntakeFlowActions from "./PatientIntakeFlowActions";
import PatientIntakeFlowHeader from "./PatientIntakeFlowHeader";
import PatientIntakeFlowProgress from "./PatientIntakeFlowProgress";
import PatientIntakeFlowSidebar from "./PatientIntakeFlowSidebar";
import PatientIntakeFlowStepContent from "./PatientIntakeFlowStepContent";

type Props = {
  patientId: PatientId;
  patientQuery: ReturnType<typeof usePatientIntakeFlow>["patientQuery"];
  stepsQuery: ReturnType<typeof usePatientIntakeFlow>["stepsQuery"];
  stepQuery: ReturnType<typeof usePatientIntakeFlow>["stepQuery"];
  activeStepId: ReturnType<typeof usePatientIntakeFlow>["activeStepId"];
  activeStepForm: ReturnType<typeof usePatientIntakeFlow>["activeStepForm"];
  isStepSubmitting: boolean;
  phoneDialerPopup: ReturnType<typeof usePhoneNumberDialerPopup>;
  onFinishFlow: () => void;
  onChangeStep: ReturnType<typeof usePatientIntakeFlow>["setActiveStepId"];
  onChangeField: ReturnType<typeof usePatientIntakeFlow>["setField"];
  onSubmitStep: ReturnType<typeof usePatientIntakeFlow>["onSubmitStep"];
  intakeProfileDisclosure: ReturnType<typeof usePatientIntakeFlow>["intakeProfileDisclosure"];
  externalFlowsQuery: ReturnType<typeof usePatientIntakeFlow>["externalFlowsQuery"];
};

export default function PatientIntakeFlowContent(props: Props) {
  return (
    <>
      <Flex direction="column" h="full" pb={WEBAPP_FOOTER_HEIGHT}>
        <PatientIntakeFlowHeader
          phoneDialerPopup={props.phoneDialerPopup}
          query={props.patientQuery}
          onClickEntity={props.intakeProfileDisclosure.onOpen}
        />
        <PatientIntakeFlowProgress isFetching={props.stepQuery.isFetching} />

        <Flex color="black" flex={1} h="full" overflow="auto">
          <PatientIntakeFlowSidebar
            activeStepId={props.activeStepId}
            externalFlows={props.externalFlowsQuery.data?.externalFlows ?? []}
            query={props.stepsQuery}
            onClickStep={props.onChangeStep}
          />

          <Flex direction="column" w="full">
            <PatientIntakeFlowActions
              patientQuery={props.patientQuery}
              onClickIntakeProfile={props.intakeProfileDisclosure.onOpen}
            />

            <Divider />

            <PatientIntakeFlowStepContent
              activeStepForm={props.activeStepForm}
              externalFlows={props.externalFlowsQuery.data?.externalFlows ?? []}
              isStepSubmitting={props.isStepSubmitting}
              patientId={props.patientId}
              query={props.stepQuery}
              onChangeField={props.onChangeField}
              onFinishFlow={props.onFinishFlow}
              onSubmitStep={props.onSubmitStep}
            />
          </Flex>
        </Flex>
      </Flex>

      <IntakePatientProfileRoute
        patientId={props.intakeProfileDisclosure.isOpen ? props.patientId : undefined}
        onClose={props.intakeProfileDisclosure.onClose}
      />
    </>
  );
}
