import React from "react";
import { Messages } from "../../core/api";
import UnauthorizedPage from "./UnauthorizedPage";
import useAuth from "../hooks/useAuth";

export function AuthPermissionGuard(props: {
  children: React.ReactNode;
  permission: Messages["AgencyMemberPermission"];
  fallback?: React.ReactNode;
}) {
  const auth = useAuth();

  if (!auth.can(props.permission)) {
    return <>{props.fallback ?? <UnauthorizedPage />}</>;
  }

  return <>{props.children}</>;
}
