import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import { PatientId } from "../../../shared/schema/schema";
import IntakePatientProfileInput from "./IntakePatientProfileInput";
import { z } from "zod";
import EligibilityCheckResults from "./eligibility_check/EligibilityCheckResults";
import CheckEligibilityButton from "./eligibility_check/CheckEligibilityButton";

interface Props {
  isEditMode: boolean;
  patientId: PatientId;
  medicareId: string | null;
  medicaidId: string | null;
  medicaidStatus: Messages["IntakePatientProfile"]["medicaidStatus"];
  onChangeIds: (field: "medicareId" | "medicaidId", value: string | null) => void;
}

const medicaidIdRegExp = /^[A-Z]{2}\d{5}[A-Z]{1}$/;

export const medicaidNumberSchema = z.union([
  z.string().length(0, {
    message: "Please enter a valid medicaid id",
  }),
  z
    .string()
    .refine((value) => medicaidIdRegExp.test(value), "Please enter a valid medicaid number"),
]);

const IntakeProfileMedInfoSection = (props: Props) => {
  return (
    <Flex direction="column" gap={4}>
      <Flex alignItems="baseline" direction="row" justifyContent="space-between">
        <Heading as="h2" size="md">
          Medicare & Medicaid Information{" "}
        </Heading>
      </Flex>
      <Flex direction="row" justifyContent="space-between">
        <Flex direction="row" gap={4}>
          <Flex direction="column" gap={4}>
            <Flex alignItems="center" direction="row" gap={2} width="150px">
              {props.medicaidId !== null && medicaidIdRegExp.test(props.medicaidId) ? (
                <CheckIcon color="green.300" fontSize="xl" />
              ) : (
                <CloseIcon color="red.300" fontSize="xl" />
              )}
              <Text size="xl">Medicaid ID</Text>
            </Flex>
            <IntakePatientProfileInput
              isEditable={props.isEditMode}
              isRequired={false}
              label=""
              styleProps={{ width: "200px" }}
              validateSchema={medicaidNumberSchema}
              value={props.medicaidId ?? ""}
              onChange={(value) => props.onChangeIds("medicaidId", value)}
            />
          </Flex>
          <Flex direction="column" gap={4}>
            <Flex alignItems="center" direction="row" gap={2}>
              {props.medicareId !== null ? (
                <CheckIcon color="green.300" fontSize="xl" />
              ) : (
                <CloseIcon color="red.300" fontSize="xl" />
              )}
              <Text size="xl"> Medicare ID </Text>
            </Flex>
            <IntakePatientProfileInput
              isEditable={props.isEditMode}
              isRequired={false}
              label=""
              styleProps={{ width: "200px" }}
              value={props.medicareId ?? ""}
              onChange={(value) => props.onChangeIds("medicareId", value)}
            />
          </Flex>
        </Flex>
        <CheckEligibilityButton patientId={props.patientId} />
      </Flex>
      <EligibilityCheckResults patientId={props.patientId} />
    </Flex>
  );
};

export default IntakeProfileMedInfoSection;
