import React from "react";
import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading } from "@chakra-ui/react";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { optionalPhoneNumberSchema } from "../shared/utils/intake.utils";
import { DisplayPatientContacts, EditablePatientContacts } from "./IntakePatientAdditionalContacts";
import { PatientIntakeContact, PatientIntakeProfileDraft } from "../patient-intake.types";
import { PatientId } from "../../../shared/schema/schema";
import IntakePatientProfileInput from "./IntakePatientProfileInput";
import IntakeMobileRightAddon from "./IntakeMobileRightAddon";
import IntakeLandlineRightAddon from "./IntakeLandlineRightAddon";
import IntakePatientProfileSwitch from "./IntakePatientProfileSwitch";

type Props = {
  patientId: PatientId;
  isEditMode: boolean;
  patientPhoneNumber: string;
  phonenumberSmsable: boolean;
  patientEmail: string | null;
  patientContacts: PatientIntakeProfileDraft["contacts"];
  mainPhoneNumber: string | null;
  onChangeNewContactDetails: (isEdited: boolean) => void;
  onToggleMainNumber: (value: string) => void;
  onChangePatientContact: (key: string, newContact: PatientIntakeContact) => void;
  onClickSaveChanges: (newContact: PatientIntakeContact) => void;
  onClickRemovePatientContact: (key: string) => void;
  onChangePatientPhoneNumber: (value: string) => void;
  onChangePatientEmail: (value: string) => void;
  onValidateNewContactPhoneNumber: (value: PatientIntakeContact) => boolean;
};

const IntakeProfileContactInfoSection = (props: Props) => {
  const [isNewContactOpen, setIsNewContactOpen] = React.useState(false);

  // Handles backwards compatibility since the patient contacts "relationship" field wasn't mandatory before
  const additionalContacts =
    props.patientContacts?.map((contact) => {
      if (contact.relationship === null) {
        return {
          ...contact,
          relationship: "Other",
        };
      }

      return contact;
    }) ?? null;

  return (
    <Flex direction="column" gap={4}>
      <Heading as="h1" size="md">
        Contact Information
      </Heading>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          label="Patient phone number"
          maxLength={10}
          rightElement={
            props.phonenumberSmsable ? (
              <IntakeMobileRightAddon
                patientId={props.patientId}
                phoneNumber={props.patientPhoneNumber}
              />
            ) : (
              <IntakeLandlineRightAddon />
            )
          }
          validateSchema={optionalPhoneNumberSchema}
          value={phoneFormatter.formatNational(props.patientPhoneNumber)}
          onChange={(value) => {
            props.onChangePatientPhoneNumber(value);
          }}
        />
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          isRequired={false}
          label="Email"
          value={props.patientEmail ?? ""}
          onChange={(value) => {
            props.onChangePatientEmail(value);
          }}
        />
        <IntakePatientProfileSwitch
          isEditable={
            props.isEditMode && props.patientPhoneNumber !== null && props.patientPhoneNumber !== ""
          }
          isRequired={false}
          label="Main number"
          value={
            props.patientPhoneNumber === props.mainPhoneNumber && props.mainPhoneNumber !== null
          }
          onChange={() => props.onToggleMainNumber(props.patientPhoneNumber)}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading as="h1" size="md">
          Additional Contact Info
        </Heading>
        {!isNewContactOpen && props.isEditMode ? (
          <Button
            color="blue.500"
            justifyContent="flex-start"
            leftIcon={<AddIcon />}
            variant="link"
            onClick={() => setIsNewContactOpen(true)}
          >
            Add new contact
          </Button>
        ) : null}
      </Flex>
      {props.isEditMode ? (
        <EditablePatientContacts
          contacts={additionalContacts}
          isNewContactOpen={isNewContactOpen}
          mainPhoneNumber={props.mainPhoneNumber}
          patientId={props.patientId}
          onChangeNewContactDetails={props.onChangeNewContactDetails}
          onClickCloseNewContactForm={() => setIsNewContactOpen(false)}
          onClickRemoveContact={props.onClickRemovePatientContact}
          onClickSaveChanges={props.onClickSaveChanges}
          onEditPatientContact={props.onChangePatientContact}
          onToggleMainNumber={props.onToggleMainNumber}
          onValidateNewContactPhoneNumber={props.onValidateNewContactPhoneNumber}
        />
      ) : (
        <DisplayPatientContacts
          contacts={additionalContacts}
          mainPhoneNumber={props.mainPhoneNumber}
          patientId={props.patientId}
          onClickRemoveContact={props.onClickRemovePatientContact}
          onEditPatientContact={props.onChangePatientContact}
          onToggleMainNumber={props.onToggleMainNumber}
        />
      )}
    </Flex>
  );
};

export default IntakeProfileContactInfoSection;
