import { Messages } from "../../../../../core/api";

export type CommCenterCallTicket = Messages["CommCenterTicket"] & {
  callInfo: Messages["CommCenterTicketTelephonyCallInfo"];
};

export function hasNYIAParticipant(participantRoles: Messages["TelephonyCallParticipantInfo"][]) {
  return participantRoles.some((participant) => {
    return participant.entity.type === "PhonebookContact" && participant.entity.name === "NYIA";
  });
}

export function isPatientTicket(
  ticket: Messages["CommCenterTicket"]
): ticket is Messages["CommCenterTicket"] & {
  topic: "Patient";
  relatedPatient: NonNullable<Messages["CommCenterTicket"]["relatedPatient"]>;
} {
  return ticket.topic === "Patient" && ticket.relatedPatient !== null;
}

/**
 * convert a letter to a digit base on the layout of a phone keypad
 * e.g A,B,C -> 2, D,E,F -> 3, etc
 */
export function letterToDigit(letter: string): string {
  if (letter.length > 1) {
    throw new Error("letterToDigit only accepts a single character");
  }
  const generalDigits = "0123456789*#";
  if (generalDigits.includes(letter)) {
    return letter;
  }
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const digits = "22233344455566677778889999";
  const index = letters.indexOf(letter.toUpperCase());
  if (index > -1) {
    return digits[index];
  }
  throw new Error(`letterToDigit: invalid letter ${letter}`);
}
