import { Messages } from "./api";

const storageKey = "user";

interface AuthInfo {
  authToken: string;
  refreshToken: string;
  permissions: Messages["AgencyMemberPermission"][];
}

export const auth = {
  get: () => {
    const user = localStorage.getItem(storageKey);
    return user ? (JSON.parse(user) as AuthInfo) : null;
  },
  getTokens: () => {
    const user = auth.get();
    return user ? { authToken: user.authToken, refreshToken: user.refreshToken } : null;
  },
  getPermissions: () => {
    const user = auth.get();
    return user ? user.permissions : [];
  },
  set: (info: AuthInfo) => {
    const user = auth.get();

    if (user === null) {
      throw new Error("Can't set user without existing user");
    }

    localStorage.setItem(
      storageKey,
      JSON.stringify({
        ...user,
        ...info,
      })
    );
  },
  isAllowedTo(permission: Messages["AgencyMemberPermission"]) {
    const user = auth.get();
    return user !== null && user.permissions.includes(permission);
  },
};
