import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { BodyOf } from "../../../core/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { assertObjectPropsNonNulls, validateOneOf } from "../../../shared/utils";
import useGoogleMapsAddress from "../../../shared/hooks/useGoogleMapsAddress";
import { fromPlaceId, toMfAddressComponents } from "mf-google-geocoder";
import { GOOGLE_MAPS_API_KEY } from "../../../shared/consts";
import SingleDatePicker from "../../../shared/components/DatePicker/SingleDatePicker";
import PhoneNumberInput from "../../../shared/components/PhoneNumberInput";
import { isValidNumber } from "../../../shared/utils/phone-number";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import { isEmpty } from "lodash";
import SingleDateTimePicker from "../../../shared/components/DatePicker/SingleDateTimePicker";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { zj } from "../../../shared/utils/zj";
import { referralSourceOptions, zReferralSource } from "../shared/intake.consts";

const Location = z.object({
  lat: z.number(),
  lng: z.number(),
});

const FormatedAddressDetails = z.object({
  location: Location,
  address1: z.string(),
  address2: z.string().nullish(),
  country: z.string(),
  state: z.string(),
  county: z.string(),
  fullAddress: z.string(),
  city: z.string().nullish(),
  zip5: z.string().nullish(),
  zip4: z.string().nullish(),
  street: z.string().nullish(),
  streetNumber: z.string().nullish(),
  crossStreet: z.string().nullish(),
});

const AddressComponent = z.object({
  location: Location,
  country: z.string(),
  administrativeAreaLevel1: z.string(),
  administrativeAreaLevel2: z.string(),
  sublocalityLevel1: z.string().nullish(),
  neighborhood: z.string().nullish(),
  locality: z.string().nullish(),
  formatedAddressDetails: FormatedAddressDetails.optional(),
});

const PatientAddressComponentsSchema = z.object({
  text: z.string().min(1),
  timezone: z.string().min(1),
  components: AddressComponent,
});

const emptyStringTransform = <T extends string>(val: T | null) => (val?.trim() === "" ? null : val);

const otherDetailsFields = {
  phoneNumber: z.string().refine((a) => isEmpty(a) || isValidNumber(a)),
  address2: z.string().nullable().transform(emptyStringTransform),
  address_instructions: z.string().nullable().transform(emptyStringTransform),
  middleName: z.string().nullable().transform(emptyStringTransform),
  contactFirstName: z.string().nullable().transform(emptyStringTransform),
  contactLastName: z.string().nullable().transform(emptyStringTransform),
  contactRelationship: z.string().nullable().transform(emptyStringTransform),
  contactPhoneNumber: z
    .string()
    .nullable()
    .transform(emptyStringTransform)
    .refine((a) => a === null || isValidNumber(a)),
  dateOfBirth: zj.localDate().nullable(),
  gender: z.enum(["M", "F"]).nullable().transform(emptyStringTransform),
  addressComponents: PatientAddressComponentsSchema.nullable(),
  address: z.string().nullable(),
  email: z.string().nullable().transform(emptyStringTransform),
  nextCallDate: zj.localDateTime().nullable(),
  lastCallDate: zj.localDateTime().nullable(),
  referralSource: zReferralSource,
};

const schema = z.union([
  z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    medicaidId: z.string().nullable().transform(emptyStringTransform),
    ...otherDetailsFields,
  }),
  z.object({
    firstName: z.string().nullable().transform(emptyStringTransform),
    lastName: z.string().nullable().transform(emptyStringTransform),
    medicaidId: z.string().min(1),
    ...otherDetailsFields,
  }),
]);

type FormSchemaData = z.infer<typeof schema>;
type SubmitDataType = BodyOf<"post", "./patient_intake">;

interface Props {
  onCreateNewIntakePatient: (data: SubmitDataType) => void;
}

const NewIntakePatient = (props: Props) => {
  const toast = useToast();
  const [createBy, setCreateBy] = useState<"Patient Name" | "Medicaid ID">("Patient Name");
  const { register, control, handleSubmit, setValue, formState } = useForm<FormSchemaData>({
    resolver: zodResolver(schema),
    defaultValues: {
      address: null,
      address2: null,
      address_instructions: null,
      addressComponents: null,
      dateOfBirth: null,
      gender: null,
      medicaidId: null,
      middleName: null,
      phoneNumber: "",
      contactPhoneNumber: null,
      contactFirstName: null,
      contactLastName: null,
      contactRelationship: null,
      firstName: "",
      lastName: "",
      nextCallDate: null,
      email: "",
      lastCallDate: null,
      referralSource: undefined,
    },
  });

  const {
    handleChange: handleChangeAddress,
    placePredictions,
    getDetails: getAddressDetails,
    showPredictions,
    setShowPredictions,
  } = useGoogleMapsAddress();

  const onSubmit: SubmitHandler<FormSchemaData> = (data) => {
    const formattedData: SubmitDataType = (() => {
      const firstName = createBy === "Patient Name" ? data.firstName ?? "" : "Unknown";
      const lastName =
        createBy === "Patient Name"
          ? data.lastName ?? ""
          : dateFormatter.toDateTime(LocalDateTime.now());

      const contactPhoneNumber =
        data.contactPhoneNumber !== null
          ? phoneFormatter.formatE164(data.contactPhoneNumber)
          : null;

      const contact = assertObjectPropsNonNulls({
        firstName: data.contactFirstName,
        lastName: data.contactLastName,
        relationship: data.contactRelationship,
        mobilePhoneNumber: contactPhoneNumber,
      });

      return {
        ...data,
        firstName,
        lastName,
        intakeFlowType: "Regular",
        contact:
          contact !== null
            ? {
                ...contact,
                homePhoneNumber: contactPhoneNumber,
                email: data.email,
              }
            : null,
      };
    })();

    if (
      formattedData.contact === null &&
      (data.contactFirstName !== null ||
        data.contactLastName !== null ||
        data.contactRelationship !== null ||
        data.contactPhoneNumber !== null)
    ) {
      toast({
        title: "Contact info is invalid",
        description:
          "Please fill first and last name, relatioship and phone number for the additional contact.",
        status: "error",
        position: "top-right",
      });
    } else {
      props.onCreateNewIntakePatient(formattedData);
    }
  };

  const handleClickAddressPrediction = async (
    prediction: google.maps.places.AutocompletePrediction
  ) => {
    setShowPredictions(false);
    setValue("address", null);
    setValue("addressComponents", null);
    try {
      const result = await getAddressDetails(prediction);
      const placeId = result?.place_id;

      if (placeId === undefined) {
        return;
      }

      const addressDetails = await fromPlaceId(placeId, {
        apiKey: GOOGLE_MAPS_API_KEY,
        mfAutoFix: true,
      });
      setValue("addressComponents", {
        components: toMfAddressComponents(addressDetails),
        text: addressDetails.fullAddress,
        timezone: "America/New_York",
      });
      setValue("address", prediction.description);
    } catch (error) {
      console.error("Error getting address details", error);
      setValue("address", null);
      setValue("addressComponents", null);
    }
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeAddress(event);
    setValue("addressComponents", null);
  };

  return (
    <Flex bg="white" direction="column" gap={2} w="full">
      <Heading fontSize="md">General Information</Heading>
      <form autoComplete="do-not-autofill" onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup
          my={4}
          size="md"
          value={createBy}
          w="auto"
          onChange={(val) => setCreateBy(validateOneOf(val, ["Patient Name", "Medicaid ID"]))}
        >
          <VStack alignItems="start">
            <Radio value="Patient Name">I have patient name</Radio>
            <Radio value="Medicaid ID">I have Medicaid ID</Radio>
          </VStack>
        </RadioGroup>
        <Flex direction="column" gap={5} justifyContent="space-around">
          <FormPair>
            <FormControl isRequired={createBy === "Patient Name"}>
              <FormLabel>First Name</FormLabel>
              <Input
                autoComplete="new-password"
                {...register("firstName")}
                isRequired={createBy === "Patient Name"}
              />
              <Text color="red">{formState.errors.firstName?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Middle Name</FormLabel>
              <Input autoComplete="new-password" {...register("middleName")} />
              <Text color="red">{formState.errors.middleName?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl isRequired={createBy === "Patient Name"}>
              <FormLabel>Last Name</FormLabel>
              <Input
                autoComplete="new-password"
                {...register("lastName")}
                isRequired={createBy === "Patient Name"}
              />
              <Text color="red">{formState.errors.lastName?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <Select {...register("gender")} defaultValue="">
                <option disabled value="">
                  Select
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </Select>
              <Text color="red">{formState.errors.gender?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <InputGroup>
                    <InputLeftAddon>+1</InputLeftAddon>
                    <PhoneNumberInput
                      autoComplete="new-password"
                      borderLeftRadius="0px"
                      {...field}
                    />
                  </InputGroup>
                )}
              />
              <Text color="red">{formState.errors.phoneNumber?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Date Of Birth</FormLabel>
              <Controller
                control={control}
                name="dateOfBirth"
                render={(field) => (
                  <SingleDatePicker
                    {...field}
                    autoComplete="new-password"
                    pickerYears={[1910, LocalDate.now().year()]}
                    selected={field.field.value}
                    onChange={(date) => {
                      setValue("dateOfBirth", date);
                    }}
                  />
                )}
              />
              <Text color="red">{formState.errors.dateOfBirth?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl>
              <FormLabel>Address</FormLabel>
              <Input
                autoComplete="new-password"
                {...register("address", {
                  onChange: handleAddressChange,
                })}
              />
              {showPredictions ? (
                <StylePredisctionList
                  placePredictions={placePredictions}
                  onClickAddressPrediction={handleClickAddressPrediction}
                />
              ) : null}
              <Text color="red">{formState.errors.addressComponents?.message ?? null}</Text>
            </FormControl>
            <FormControl isRequired>
              <Controller
                control={control}
                name="referralSource"
                render={() => (
                  <>
                    <FormLabel>Referral Source</FormLabel>
                    <Select {...register("referralSource")}>
                      {referralSourceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              />
              <Text color="red">{formState.errors.referralSource?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl>
              <FormLabel>Address 2</FormLabel>
              <Input autoComplete="new-password" {...register("address2")} />
            </FormControl>
            <FormControl isRequired={createBy === "Medicaid ID"}>
              <FormLabel>Medicaid ID</FormLabel>
              <Input
                autoComplete="new-password"
                {...register("medicaidId")}
                required={createBy === "Medicaid ID"}
              />
            </FormControl>
          </FormPair>
          <Heading fontSize="md">Additional Contact Information</Heading>
          <FormPair>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input autoComplete="new-password" {...register("contactFirstName")} />
              <Text color="red">{formState.errors.contactFirstName?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input autoComplete="new-password" {...register("contactLastName")} />
              <Text color="red">{formState.errors.contactLastName?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Controller
                control={control}
                name="contactPhoneNumber"
                render={({ field }) => (
                  <InputGroup>
                    <InputLeftAddon>+1</InputLeftAddon>
                    <PhoneNumberInput
                      autoComplete="new-password"
                      borderLeftRadius="0px"
                      {...field}
                      value={field.value ?? ""}
                    />
                  </InputGroup>
                )}
              />
              <Text color="red">{formState.errors.contactPhoneNumber?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Relationship</FormLabel>
              <Select {...register("contactRelationship")}>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Wife">Wife</option>
                <option value="Husband">Husband</option>
                <option value="Grandchild">Grandchild</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Mother-in-law">Mother-in-law</option>
                <option value="Father-in-law">Father-in-law</option>
                <option value="Legal guardian">Legal guardian</option>
                <option value="Other">Other</option>
              </Select>
            </FormControl>
          </FormPair>
          <FormPair>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input autoComplete="new-password" {...register("email")} />
              <Text color="red">{formState.errors.email?.message ?? null}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Next Call Date</FormLabel>
              <Controller
                control={control}
                name="nextCallDate"
                render={(field) => (
                  <SingleDateTimePicker
                    autoComplete="new-password"
                    disabled={false}
                    pickerYears={[LocalDateTime.now().year(), 2100]}
                    selected={field.field.value}
                    onChange={(date) => {
                      setValue("nextCallDate", date);
                    }}
                  />
                )}
              />

              <Text color="red">{formState.errors.nextCallDate?.message ?? null}</Text>
            </FormControl>
          </FormPair>
          <FormControl mb={20}>
            <Button colorScheme="blue" isDisabled={formState.isSubmitting} type="submit">
              {formState.isSubmitting ? <Spinner size="sm" /> : "Save"}
            </Button>
          </FormControl>
        </Flex>
      </form>
    </Flex>
  );
};

const StylePredisctionList = (props: {
  placePredictions: google.maps.places.AutocompletePrediction[];
  onClickAddressPrediction: (
    prediction: google.maps.places.AutocompletePrediction
  ) => Promise<void>;
}) => (
  <Box
    bg="white"
    border="1px"
    borderColor="chakra-border-color"
    padding={3}
    position="fixed"
    width="xl"
    zIndex={1}
  >
    <List display="flex" flexDirection="column" gap={4}>
      {props.placePredictions.map((prediction) => (
        <ListItem
          key={prediction.place_id}
          borderBottom="1px"
          borderColor="chakra-border-color"
          cursor="pointer"
          onClick={() => props.onClickAddressPrediction(prediction)}
        >
          {prediction.description}
        </ListItem>
      ))}
    </List>
  </Box>
);

const FormPair = (props: { children: ReactNode }) => {
  return (
    <Flex direction="row" gap={20} justifyContent="space-between">
      {props.children}
    </Flex>
  );
};
export default NewIntakePatient;
