import { Flex } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import React from "react";
import { CommCenterTicketId } from "../../../../../shared/schema/schema";
import { CommCenterTicket } from "../../../../communication/communication.types";
import { VisitAssignmentErrorBox } from "./VisitAssignmentErrorBox";
import { VisitAssignmentListedTickets } from "./VisitAssignmentListedTickets";
import { VisitAssignmentListedTicketsSkeleton } from "./VisitAssignmentListedTicketsSkeleton";

export function VisitAssignmentTicketsSidebar(props: {
  query: UseQueryResult<CommCenterTicket[]>;
  selectedTicketId: CommCenterTicketId | null;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
}) {
  const { resolved, unresolved } = React.useMemo(() => {
    const resolved: CommCenterTicket[] = [];
    const unresolved: CommCenterTicket[] = [];

    for (const ticket of props.query.data ?? []) {
      ticket.status === "RESOLVED" ? resolved.push(ticket) : unresolved.push(ticket);
    }

    return { resolved, unresolved };
  }, [props.query.data]);

  return (
    <Flex
      borderBottomColor="gray.200"
      borderBottomWidth={{ base: 1, xl: 0 }}
      borderColor="gray.200"
      borderRightWidth={1}
      direction="column"
      h={{ base: "md", xl: "full" }}
      overflow="auto"
      shrink={0}
      w={{ base: "full", xl: "96" }}
    >
      {(() => {
        switch (props.query.status) {
          case "pending":
            return <VisitAssignmentListedTicketsSkeleton />;
          case "error":
            return <VisitAssignmentErrorBox error={props.query.error} />;
          case "success":
            return (
              <>
                <VisitAssignmentListedTickets
                  isFetching={props.query.isFetching}
                  selectedTicketId={props.selectedTicketId}
                  tickets={unresolved}
                  title="Open Tickets"
                  onClickTicket={props.onClickTicket}
                />

                <VisitAssignmentListedTickets
                  isFetching={props.query.isFetching}
                  selectedTicketId={props.selectedTicketId}
                  tickets={resolved}
                  title="Resolved Tickets"
                  onClickTicket={props.onClickTicket}
                />
              </>
            );
        }
      })()}
    </Flex>
  );
}
