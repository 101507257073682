import { createIcon } from "@chakra-ui/react";

const BaselinePersonIcon = createIcon({
  displayName: "BaselinePersonIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
      fill="currentColor"
    />
  ),
});

export default BaselinePersonIcon;
