import { FlexProps, useToast } from "@chakra-ui/react";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import { caregiverRegularMessageTemplateOptions } from "../../../shared/components/SmsSender/sms-sender.consts";
import {
  CaregiverSmsSenderProps,
  PhoneNumber,
  RegularMessage,
} from "../../../shared/components/SmsSender/sms-sender.types";
import {
  reduceCommCenterLabelsByTeamForSms,
  ReduceLabelsForSmsResultType,
} from "../../../shared/components/SmsSender/sms-sender.utils";
import SmsSender from "../../../shared/components/SmsSender/SmsSender";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { NewTicketRequestBody } from "../../communication/utils/communication-utils";

const caregiverMessageTemplateOptions: RegularMessage[] = [
  ...Object.values(caregiverRegularMessageTemplateOptions),
].sort((a, b) => a.label.localeCompare(b.label));

const getSmsLabelByCaregiverStatus = (
  caregiverStatus: Messages["CaregiverStatus"],
  smsLabels: ReduceLabelsForSmsResultType
) => {
  switch (caregiverStatus) {
    case "ACTIVE":
    case "ON_LEAVE":
      return smsLabels.Staffing;
    default:
      return smsLabels.HR;
  }
};

type Props = Omit<CaregiverSmsSenderProps, "entity"> & {
  flexProps?: FlexProps;
  onClose: () => void;
};

function CaregiverSmsSender(props: Props) {
  const { api, queries } = useApi();
  const toast = useToast();

  const caregiverQuery = useQuery({
    ...queries.caregiver.get(props.caregiverId),
    select: (caregiver) => {
      const phoneNumbers: PhoneNumber[] = caregiver.phoneNumbers.map((x) => ({
        label: `${caregiver.firstName} ${
          caregiver.lastName
        } [ ${phoneFormatter.formatNationalIfValid(x.phoneNumber)} ]`,
        value: x.phoneNumber,
      }));
      return {
        phoneNumbers,
        firstName: caregiver.firstName,
        status: caregiver.status,
      };
    },
  });

  const smsTicketLabels = useQuery({
    queryKey: [queryKeys.commCenter.labels(), "SMS"],
    queryFn: () => api.get("./comm_center/labels", {}),
    placeholderData: keepPreviousData,
    select: (response) => reduceCommCenterLabelsByTeamForSms(response.labels),
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: Pick<NewTicketRequestBody, "phoneNumber" | "message">) => {
      if (smsTicketLabels.data === undefined || caregiverQuery.data === undefined) {
        toast({
          title: "Could not create new ticket",
          description: "SMS labels not found OR patient not found",
          status: "error",
          position: "top-right",
        });
        throw new Error("SMS label not found");
      }
      const relevantSmsLabel = getSmsLabelByCaregiverStatus(
        caregiverQuery.data.status,
        smsTicketLabels.data
      );
      if (relevantSmsLabel === undefined) {
        toast({
          title: "Could not create new ticket",
          description: "SMS labels not found",
          status: "error",
          position: "top-right",
        });
        throw new Error("SMS label not found");
      }

      return api.post("./comm_center/tickets", {
        body: {
          ...newTicketRequest,
          message: newTicketRequest.message,
          phoneNumber: newTicketRequest.phoneNumber,
          caregiverId: props.caregiverId,
          patientId: null,
          labelId: relevantSmsLabel.id,
          topic: "Caregiver",
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "SMS sent successfully",
        status: "success",
        position: "top-right",
      });
      props.onClose();
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <SmsSender
      entityQuery={caregiverQuery}
      flexProps={props.flexProps}
      isSending={createTicket.isPending}
      messageTemplates={caregiverMessageTemplateOptions}
      predefinedMessageTemplate={props.predefinedMessageTemplate}
      predefinedPhoneNumber={props.predefinedPhoneNumber}
      onClose={props.onClose}
      onSend={createTicket.mutate}
    />
  );
}

export default CaregiverSmsSender;
