import { PhoneIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import { Messages } from "../../../../../../../core/api";
import { dateFormatter } from "../../../../../../../shared/utils/date-formatter";
import CallTicketIndexBadge from "../../../../CallTicketIndexBadge";
import CallTicketText from "../../CallTicketText";

interface Props {
  event: Messages["TelephonyCallParticipantEvent"] & { data: { name: "RangTo" } };
}

const CallTicketEventCardRingingAt = ({ event }: Props) => {
  return (
    <Flex alignItems="center" gap={2}>
      <CallTicketIndexBadge>
        <PhoneIcon />
      </CallTicketIndexBadge>
      <Flex direction="column">
        <CallTicketText color="gray.400">
          {dateFormatter.toDateTime(event.timestamp)}
        </CallTicketText>
        <Flex gap={1}>
          <CallTicketText fontWeight="bold">Call started ringing to</CallTicketText>
          <CallTicketText>
            {event.data.agencyMember.firstName} {event.data.agencyMember.lastName}.
          </CallTicketText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CallTicketEventCardRingingAt;
