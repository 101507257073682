import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import useApi from "../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../shared/query-keys";
import { PatientId } from "../../../../shared/schema/schema";
import LatestErrorInEligibilityCheck from "./LatestErrorInEligibilityCheck";
import LatestValidEligibilityCheckInfo from "./LatestValidEligibilityCheckResult";

const EligibilityCheckResults = (props: { patientId: PatientId }) => {
  const { api } = useApi();

  const eligibilityCheck = useQuery({
    queryKey: queryKeys.patientIntake.eligibilityCheck(props.patientId),
    placeholderData: keepPreviousData,
    queryFn: () =>
      api.get("./patients/:patientId/intake_eligibility_checks", {
        path: {
          patientId: props.patientId,
        },
      }),
    select: (data) => {
      const sortedChecks = orderBy(data.records, (check) => check.createdAt.toEpochMilli(), "desc");
      const latestValidCheck = sortedChecks.find((check) => check.error === null);
      const latestErrorCheck = sortedChecks.find((check) => check.error !== null);

      return {
        latestValidCheck: latestValidCheck ?? null,
        latestErrorCheck: latestErrorCheck ?? null,
      };
    },
  });

  const displayError =
    eligibilityCheck.data?.latestErrorCheck !== null &&
    ((eligibilityCheck.data?.latestValidCheck !== null &&
      eligibilityCheck.data?.latestErrorCheck.createdAt.isAfter(
        eligibilityCheck.data?.latestValidCheck.createdAt
      )) ||
      eligibilityCheck.data?.latestValidCheck === null);

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton justifyContent="space-between" w="100%">
          <Heading as="h1" size="sm">
            Check Results
          </Heading>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Flex direction="column" gap={6}>
            {eligibilityCheck.data?.latestErrorCheck === null &&
            eligibilityCheck.data?.latestValidCheck === null ? (
              <Text> No eligibility check was made for this patient. Run one now! </Text>
            ) : (
              <Flex direction="column" gap={4}>
                {eligibilityCheck.data?.latestErrorCheck !== undefined &&
                eligibilityCheck.data.latestErrorCheck !== null &&
                displayError ? (
                  <LatestErrorInEligibilityCheck
                    errorCheck={eligibilityCheck.data.latestErrorCheck}
                  />
                ) : null}
                {eligibilityCheck.data?.latestValidCheck && (
                  <LatestValidEligibilityCheckInfo
                    validCheck={eligibilityCheck.data.latestValidCheck}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default EligibilityCheckResults;
