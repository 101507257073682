import { useToast } from "@chakra-ui/react";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Messages, QueryParamsOf } from "../../../core/api";
import ErrorPage from "../../../shared/components/ErrorPage";
import LoadingPage from "../../../shared/components/LoadingPage";
import useApi from "../../../shared/hooks/useApi";
import { useQueryParams } from "../../../shared/hooks/useQueryParams";
import { queryKeys } from "../../../shared/query-keys";
import { CaregiverId, CaregiverSuppliesShipmentId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import CaregiverSuppliesShipmentsPage from "./CaregiverSuppliesShipmentsPage";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import { useCaregiversQuery } from "../../caregiver/hooks/useCaregiversQuery";
import { LocalDate } from "@js-joda/core";

export interface EditShipmentRowRequest {
  rowId: CaregiverSuppliesShipmentId;
  caregiverId: CaregiverId;
  changes: Partial<Messages["CaregiverSuppliesShipmentRow"]>;
}

export const caregievrGlovesSizes = ["S", "M", "L", "XL"] as const;
export const caregiverScrubsSizes = ["S", "M", "L", "XL", "XXL"] as const;

export interface ImportShipmentsTable {
  rows: Omit<
    Messages["CaregiverSuppliesShipmentRow"],
    | "id"
    | "caregiverFullName"
    | "caregiverStatus"
    | "caregiverHireDate"
    | "caregiverPhotoUrl"
    | "caregiverDisplayId"
    | "glovesSize"
    | "scrubsSize"
  >[];
}

const CaregiverSuppliesShipmentsRoute = () => {
  const { api } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();
  const caregivers = useCaregiversQuery();

  const queryParams = useQueryParams<QueryParamsOf<"get", "./caregiver_supplies_shipments">>({
    storageKey: ["caregiver", "supplies-shipment-table"],
  });

  const shipments = useQuery({
    queryKey: queryKeys.caregiverSuppliesShipments.search(queryParams.params),
    placeholderData: keepPreviousData,
    queryFn: () => {
      return api.get("./caregiver_supplies_shipments", {
        query: queryParams.params.toJSON(),
      });
    },
  });

  const editShipmentRowData = useMutation({
    mutationFn: (requestBody: EditShipmentRowRequest) => {
      return api.put("./caregiver_supplies_shipments/:caregiverSuppliesShipmentId", {
        path: {
          caregiverSuppliesShipmentId: requestBody.rowId,
        },
        body: {
          changes: requestBody.changes,
          caregiverId: requestBody.caregiverId,
        },
      });
    },
    onMutate: (requestBody) => {
      return optimisticUpdate<{ shipments: Messages["CaregiverSuppliesShipmentRow"][] }>({
        queryClient,
        queryKey: queryKeys.caregiverSuppliesShipments.search(queryParams.params),
        update: (draft) => {
          const shipment = draft.shipments.find((shipment) => shipment.id === requestBody.rowId);

          if (shipment) {
            shipment.arrivalDate = requestBody.changes.arrivalDate ?? shipment.arrivalDate;
            shipment.shipmentDate = requestBody.changes.shipmentDate ?? shipment.shipmentDate;
            shipment.glovesSize = requestBody.changes.glovesSize ?? shipment.glovesSize;
            shipment.scrubsSize = requestBody.changes.scrubsSize ?? shipment.scrubsSize;
          }
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.caregiverSuppliesShipments.search(queryParams.params),
      });
    },
    onError: (error) => {
      toast({
        title: "Could not edit shipment data",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const importShipmentsTable = useMutation({
    mutationFn: (requestBody: ImportShipmentsTable) => {
      return api.post("./caregiver_supplies_shipments", {
        body: {
          newRows: requestBody.rows,
        },
      });
    },
    onMutate: (requestBody) => {
      return optimisticUpdate<{ shipments: Messages["CaregiverSuppliesShipmentRow"][] }>({
        queryClient,
        queryKey: queryKeys.caregiverSuppliesShipments.search(queryParams.params),
        update: (draft) => {
          if (caregivers.data !== undefined) {
            const relevantCaregiversInfo = caregivers.data.filter((caregiver) =>
              requestBody.rows.find((row) => row.caregiverId === caregiver.id)
            );

            let draftLastID = draft.shipments.length > 0 ? draft.shipments.length : 0;

            draft.shipments = requestBody.rows.map((row) => {
              const currCaregiver = relevantCaregiversInfo.find(
                (caregiver) => caregiver.id === row.caregiverId
              );

              return {
                id: ++draftLastID as CaregiverSuppliesShipmentId,
                caregiverId: row.caregiverId,
                caregiverFullName:
                  currCaregiver === undefined
                    ? ""
                    : `${currCaregiver.firstName} ${currCaregiver.lastName}`,
                arrivalDate: row.arrivalDate,
                shipmentDate: row.shipmentDate,
                shipmentType: row.shipmentType,
                caregiverStatus: currCaregiver?.status ?? "ACTIVE",
                caregiverHireDate: currCaregiver?.hireDate ?? LocalDate.of(2000, 1, 1),
                caregiverPhotoUrl: currCaregiver?.photoUrl ?? "",
                caregiverDisplayId: currCaregiver?.displayId ?? 0,
                caregiverConfirmation: false,
                glovesSize: currCaregiver?.glovesSize ?? null,
                scrubsSize: currCaregiver?.scrubsSize ?? null,
              };
            });
          }
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.caregiverSuppliesShipments.search(queryParams.params),
      });
    },
    onError: (error) => {
      toast({
        title: "Could not import shipment data from spreadsheet",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  if (shipments.status === "error") {
    return <ErrorPage error={shipments.error} resetErrorBoundary={shipments.refetch} />;
  }

  if (shipments.status === "pending") {
    return <LoadingPage />;
  }

  return (
    <CaregiverSuppliesShipmentsPage
      filters={queryParams}
      shipments={shipments.data?.shipments ?? []}
      onChangeFilter={queryParams.setValue}
      onChangeFilters={queryParams.setValues}
      onEditShipmentRowData={editShipmentRowData.mutate}
      onImportTable={importShipmentsTable.mutate}
    />
  );
};

export default CaregiverSuppliesShipmentsRoute;
