import { Button, Flex, Heading, FormLabel, Spacer } from "@chakra-ui/react";
import { ZoneId } from "@js-joda/core";
import React from "react";
import SingleDateTimePicker from "../../../shared/components/DatePicker/SingleDateTimePicker";
import Select from "../../../shared/components/Select";
import SendIcon from "../../../shared/icons/SendIcon";
import { CaregiverId, CaregiverSuppliesShipmentId } from "../../../shared/schema/schema";
import { validateOneOf } from "../../../shared/utils";
import {
  caregievrGlovesSizes,
  caregiverScrubsSizes,
  EditShipmentRowRequest,
} from "../pages/CaregiverSuppliesShipmentsRoute";
import { SuppliesTableRowEditableData } from "./CaregiverSuppliesShipmentTable";

interface Props {
  rowId: CaregiverSuppliesShipmentId;
  caregiver: {
    name: string;
    id: CaregiverId;
  };
  editableRowData: SuppliesTableRowEditableData;
  onUpdateRowData: (request: EditShipmentRowRequest) => void;
}

const UpdateTableRowForm = (props: Props) => {
  const [updateForm, setUpdateForm] = React.useState<SuppliesTableRowEditableData>(
    props.editableRowData
  );

  const handleChangeFormDetails = (field: Partial<SuppliesTableRowEditableData>) => {
    const newDetails = {
      ...updateForm,
      ...field,
    };

    setUpdateForm(newDetails);
  };

  const handleSubmitChanges = () => {
    props.onUpdateRowData({
      rowId: props.rowId,
      caregiverId: props.caregiver.id,
      changes: {
        arrivalDate:
          updateForm.arrivalDate === null
            ? null
            : updateForm.arrivalDate.atZone(ZoneId.of("America/New_York")).toInstant(),
        shipmentDate:
          updateForm.shipmentDate === null
            ? null
            : updateForm.shipmentDate.atZone(ZoneId.of("America/New_York")).toInstant(),
        glovesSize: updateForm.glovesSize,
        scrubsSize: updateForm.scrubsSize,
      },
    });
  };

  return (
    <Flex direction="column" gap={10}>
      <Heading alignSelf="flex-start" as="h2" margin={30} size="md">
        {" "}
        Shipment Details For {props.caregiver.name}
      </Heading>
      <Spacer />
      <Flex direction="row" gap={10}>
        <Flex alignItems="center" direction="row" gap={4}>
          <FormLabel>Gloves Size</FormLabel>
          <Select
            label="Gloves Size"
            multiple={false}
            options={caregievrGlovesSizes.map((size) => ({ label: size, value: size }))}
            value={updateForm.glovesSize}
            onChange={(value) =>
              handleChangeFormDetails({
                glovesSize: validateOneOf(value, caregievrGlovesSizes) ?? null,
              })
            }
          />
        </Flex>
        <Flex alignItems="center" direction="row" gap={4}>
          <FormLabel>Scrubs Size</FormLabel>
          <Select
            label="Scrubs Size"
            multiple={false}
            options={caregiverScrubsSizes.map((size) => ({ label: size, value: size }))}
            value={updateForm.scrubsSize}
            onChange={(value) =>
              handleChangeFormDetails({
                scrubsSize: validateOneOf(value, caregiverScrubsSizes) ?? null,
              })
            }
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap={10}>
        <Flex direction="column">
          <FormLabel>Shipment Date</FormLabel>
          <SingleDateTimePicker
            autoComplete="new-password"
            selected={updateForm.shipmentDate}
            onChange={(date) => handleChangeFormDetails({ shipmentDate: date })}
          ></SingleDateTimePicker>
        </Flex>
        <Flex direction="column">
          <FormLabel>Arrival Date</FormLabel>
          <SingleDateTimePicker
            autoComplete="new-password"
            selected={updateForm.arrivalDate}
            onChange={(date) => handleChangeFormDetails({ arrivalDate: date })}
          ></SingleDateTimePicker>
        </Flex>
      </Flex>
      <Button
        alignSelf="center"
        colorScheme="blue"
        leftIcon={<SendIcon />}
        width="100px"
        onClick={handleSubmitChanges}
      >
        Send
      </Button>
    </Flex>
  );
};

export default UpdateTableRowForm;
