import NewTicketPopup from "../../../modules/communication/components/NewTicketPopup";
import { useNewTicket } from "../../hooks/useNewTicket";
import { useReactBridgeDispatchEvent } from "../../hooks/useReactBridgeEvent";

function NewTicketPopupListener() {
  const { state, onOpen } = useNewTicket();

  useReactBridgeDispatchEvent({
    eventName: "COMM_CENTER:OPEN_NEW_TICKET_POPUP",
    onEvent: (data) => {
      onOpen(data);
    },
  });

  if (state === null) {
    return <></>;
  }

  return <NewTicketPopup {...state} />;
}

export default NewTicketPopupListener;
