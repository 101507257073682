import { useDisclosure } from "@chakra-ui/react";
import Page from "../../../../../shared/components/Page";
import useApi from "../../../../../shared/hooks/useApi";
import AddWorkflowSkillModal from "./AddWorkflowSkillModal";
import WorkflowSkillsDataTable from "./WorkflowSkillsDataTable";
import { useQueryClient } from "@tanstack/react-query";

export default function WorkflowSkillsListTab() {
  const queryClient = useQueryClient();
  const { queries } = useApi();
  const addSkillModal = useDisclosure();

  return (
    <>
      <Page.Header>
        <Page.Title>Skills</Page.Title>
      </Page.Header>

      <Page.Content p={0}>
        <WorkflowSkillsDataTable onClickAdd={addSkillModal.onOpen} />
      </Page.Content>

      <AddWorkflowSkillModal
        isOpen={addSkillModal.isOpen}
        onAdd={() => queryClient.invalidateQueries(queries.workflow.skills())}
        onClose={addSkillModal.onClose}
      />
    </>
  );
}
