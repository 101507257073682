//! @ngInject
export function sendPatientDocumentAsFaxModalCtrl($scope, DatabaseApi, $rootScope, toaster, $uibModalInstance) {
    $scope.result = {};
  
    $scope.isLoading = false;
  
    $scope.documents = $scope.$resolve.documents;

    $scope.errorMessage = "";
    $scope.showErrorMessage = false;

    function isFormFieldProvided(field){
      return field !== undefined && field !== "";
    }

    function validateForm(form) {
      if(isFormFieldProvided(form.subject) && 
         isFormFieldProvided(form.name) && 
         isFormFieldProvided(form.faxNumber)){
        return true;
      }

      return false;
    }
    
    function buildRequestBody(form, document, type){
      switch(type){
        case "ScheduledDocument":{
          // In case we got here from the multiple documents selection, but the user selected only one document
          if(document.length !== undefined){
            document = document[0];
          }

          return {
            type: "ScheduledDocument",
              documentId: document.id,
              faxNumber: form.faxNumber,
              relatedEntity: {
                type: "Patient",
                patientId: $scope.$resolve.patientId
              },
              recipient: form.name,
              subject: form.subject,
              comment: form.comment ?? ""
          }
        }
        case "TaskDocument":{
          return {
              type: "TaskDocument",
              fileUrl: document.fileUrl,
              faxNumber: form.faxNumber,
              relatedEntity: {
                type: "Patient",
                patientId: $scope.$resolve.patientId
              },
              recipient: form.name,
              subject: form.subject,
              comment: form.comment ?? ""
          }
        } 
        case "MultipleScheduledDocuments":{
          return {
              type: "MultipleScheduledDocuments",
              documentIds: document.map(x => x.id),
              faxNumber: form.faxNumber,
              relatedEntity: {
                type: "Patient",
                patientId: $scope.$resolve.patientId
              },
              recipient: form.name,
              subject: form.subject,
              comment: form.comment ?? ""
          }
        }
        default:{
          console.error("Invalid patient document fax type");
        }
      }
    }

    $scope.sendFax = function () {
      const url = "agencies/:agencyId/agency_members/:agencyMemberId/faxes/send_patient_document"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":patientId", $scope.$resolve.patientId);

      const form = $scope.req;
      const type = $scope.$resolve.type;

      if(!validateForm(form)){
        $scope.errorMessage = "Please fill all required fields.";
        $scope.showErrorMessage = true;
      }
      else{
        $scope.showErrorMessage = false;
        $scope.errorMessage = "";

        let reqObj = buildRequestBody(form, $scope.$resolve.document, $scope.$resolve.type);
  
        DatabaseApi.post(url, reqObj).then(function () {
          toaster.pop('success', "Success", "Pdf sent successfully");
  
          if($scope.$resolve.onSuccess !== undefined){
            $scope.$resolve.onSuccess();
          }
          
          $uibModalInstance.close();
        }, function () {
            toaster.pop('error', 'Failed, please try again');
            $scope.isLoading = false;
        });
      }
    };
  };
  