import { SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import React from "react";
import { Messages, QueryParamsOf, ResponseOf } from "../../../../core/api";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import DebouncedInput from "../../../../shared/components/DebouncedInput";
import NullableMultiSelect from "../../../../shared/components/NullableMultiSelect";
import Page from "../../../../shared/components/Page";
import Select from "../../../../shared/components/Select";
import Toggle from "../../../../shared/components/Toggle";
import { createFilters } from "../../../../shared/hooks/useFilters";
import useIntakeAgencyMembers from "../../../../shared/hooks/useIntakeAgencyMembers";
import AddRoundedIcon from "../../../../shared/icons/AddRoundedIcon";
import { IntakeStatusId, PatientId } from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import { FilterProps } from "../../../../shared/utils/filter-props";
import { getFullName } from "../../../../shared/utils/get-full-name";
import IntakeDashboardTable, { IntakeDataRow } from "../../components/IntakeDashboardTable";
import NewIntakePatient from "../../components/NewIntakePatient";
import { IncompletePatientFlowBadge } from "./components/IncompletePatientFlowBadge";
import { IntakeDashboardTitle } from "./components/IntakeDashboardTitle";
import { IntakePlanSelect } from "./components/IntakePlanSelect";
import IntakeStatusSelect from "./components/IntakeStatusSelect";
import { IntakeTracksSelect } from "./components/IntakeTracksSelect";
import { MedicaidStatusSelect } from "./components/MedicaidStatusSelect";
import useIntakeDashboard from "./hooks/useIntakeDashboard";
import { IntakeDashboardFilters } from "./hooks/useIntakeDashboardFilters";

export type IntakeDashboardQueryParams = QueryParamsOf<"get", "./patient_intake/dashboard">;

export interface Props {
  orderedCalls: ResponseOf<"get", "./patient_intake_calls_order">;
  filters: IntakeDashboardFilters;
  onClickDashboardRow: (event: React.MouseEvent<HTMLTableRowElement>, patientId: PatientId) => void;
}

const IntakeDashboardTabView = (props: Props) => {
  const { createIntakePatient, createIntakePatientDisclosure } = useIntakeDashboard({
    orderedCalls: props.orderedCalls,
    filters: props.filters,
  });
  const tableRef = React.useRef<Table<IntakeDataRow>>(null);

  const handleChangeServerSideFilter: FilterProps<"./patient_intake/dashboard">["onChangeFilter"] =
    (name, value) => {
      tableRef.current?.setPageIndex(0);
      props.filters.serverSide.setValue(name, value ?? undefined);
    };

  const { data: agencyMembers } = useIntakeAgencyMembers();

  const {
    createMultiSelectFilter,
    createRangeDatePickerFilter,
    createFreeTextFilter,
    createSwitchFilter,
    createNullableMultiSelectFilter,
  } = createFilters<IntakeDashboardQueryParams>();

  const lastCallFilter = createRangeDatePickerFilter({
    label: "Filter By Last Call Range",
    placeholderText: "Filter last call",
    startDate: {
      name: "lastCallFrom",
      value: props.filters.serverSide.getValueOrNull("lastCallFrom"),
    },
    endDate: {
      name: "lastCallTo",
      value: props.filters.serverSide.getValueOrNull("lastCallTo"),
    },
    onChange: handleChangeServerSideFilter,
  });

  const createdAtFilter = createRangeDatePickerFilter({
    label: "Created At Range",
    placeholderText: "Filter created at",
    startDate: {
      name: "createdAtFrom",
      value: props.filters.serverSide.getValueOrNull("createdAtFrom"),
    },
    endDate: {
      name: "createdAtTo",
      value: props.filters.serverSide.getValueOrNull("createdAtTo"),
    },
    onChange: handleChangeServerSideFilter,
  });

  const freeTextSearchFilter = createFreeTextFilter({
    placeholder: "Search by patient name, id, etc.",
    name: "freeTextSearch",
    value: props.filters.serverSide.getValueOrNull("freeTextSearch"),
    onChange: handleChangeServerSideFilter,
    debounce: 250,
  });

  const intakeFlowTypeFilter = createMultiSelectFilter({
    label: "Intake Flow Type",
    name: "intakeFlowType",
    onChange: handleChangeServerSideFilter,
    options: [
      {
        label: "Regular",
        value: "Regular",
      },
      {
        label: "Self Serve",
        value: "Self Serve",
      },
    ],
    value: props.filters.serverSide.getValueOrNull("intakeFlowType"),
  });

  const agencyMemberFilter = createNullableMultiSelectFilter({
    label: "Assigned Agency Member",
    name: "assignedAgencyMemberIds",
    options: (agencyMembers ?? []).map((x) => ({
      label: getFullName(x),
      value: x.id,
    })),
    onChange: handleChangeServerSideFilter,
    value: props.filters.serverSide.getValue("assignedAgencyMemberIds"),
  });

  const intakeQueueNameFilter = createMultiSelectFilter({
    label: "Call Order Type",
    name: "intakeQueueName",
    onChange: handleChangeServerSideFilter,
    options: [
      {
        label: "Q1 - High",
        value: "Q1 - High",
      },
      {
        label: "Q1 - Low",
        value: "Q1 - Low",
      },
      {
        label: "Q1 - Initial",
        value: "Q1 - Initial",
      },
      {
        label: "Q2 - Regular",
        value: "Q2 - Regular",
      },
      {
        label: "Q3 - Call To Plan",
        value: "Q3 - Call To Plan",
      },
    ],
    value: props.filters.serverSide.getValueOrNull("intakeQueueName"),
  });

  const incompleteIntakeFlowFilter = createSwitchFilter({
    name: "incompletePatientIntake",
    onChange: handleChangeServerSideFilter,
    value: props.filters.serverSide.getValue("incompletePatientIntake") ?? false,
  });

  const handleChangeMainStatus = (
    options: IntakeStatusId[] | undefined,
    intakeStatuses: Messages["IntakeStatus"][]
  ) => {
    if (options === null || options === undefined) {
      handleChangeServerSideFilter("intakeStatusId", options);
      handleChangeServerSideFilter("mainIntakeStatusId", options);
      return;
    }
    const currentMainStatuses = props.filters.serverSide.getValueOrNull("mainIntakeStatusId");
    const newCheckStatusIds = options.filter((x) => !currentMainStatuses?.some((y) => y === x));
    const removedCheckStatusIds =
      currentMainStatuses?.filter((x) => !options?.some((y) => y === x)) ?? [];

    const removedCheckMainStatus = intakeStatuses.filter((status) =>
      removedCheckStatusIds.includes(status.id)
    );
    const newCheckMainStatus = intakeStatuses.filter((status) =>
      newCheckStatusIds.includes(status.id)
    );

    const currentSubStatusIds = props.filters.serverSide.getValue("intakeStatusId") ?? [];
    const currentSubStatus = intakeStatuses.filter((status) =>
      currentSubStatusIds.includes(status.id)
    );
    const newSubStatusIds = currentSubStatus
      .filter(
        (subStatus) =>
          !removedCheckMainStatus.some((mainStatus) => mainStatus.id === subStatus.parentId)
      )
      .concat(
        intakeStatuses.filter((subStatus) =>
          newCheckMainStatus.some((mainStatus) => mainStatus.id === subStatus.parentId)
        )
      )
      .map((x) => x.id);

    handleChangeServerSideFilter("intakeStatusId", newSubStatusIds);
    handleChangeServerSideFilter("mainIntakeStatusId", options);
  };

  const filterNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput {...freeTextSearchFilter} value={freeTextSearchFilter.value ?? ""} />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
      <RangeDatePicker {...lastCallFilter} />
      <RangeDatePicker {...createdAtFilter} />
      <IntakePlanSelect
        multiple
        value={props.filters.serverSide.getValueOrNull("plan")}
        onChange={(value) => handleChangeServerSideFilter("plan", value)}
      />
      <MedicaidStatusSelect
        multiple
        value={props.filters.serverSide.getValueOrNull("medicaidStatus")}
        onChange={(value) => handleChangeServerSideFilter("medicaidStatus", value)}
      />
      <IntakeStatusSelect
        multiple
        defaultValue={(intakeStatuses) => {
          if (props.filters.serverSide.getIsDirty("mainIntakeStatusId")) {
            return null;
          }

          const defaultMainStatus = intakeStatuses
            .filter((x) => ["LEAD", "ACTIVE_INTAKE"].includes(x.code))
            .map((x) => x.id);

          handleChangeMainStatus(defaultMainStatus, intakeStatuses);

          return defaultMainStatus;
        }}
        filter={(x) => x.parentId === null}
        label="Main Status"
        value={props.filters.serverSide.getValueOrNull("mainIntakeStatusId")}
        onChange={handleChangeMainStatus}
      />
      <IntakeStatusSelect
        multiple
        filter={(x) =>
          fmap(x.parentId, (parentId) =>
            props.filters.serverSide.getValue("mainIntakeStatusId")?.includes(parentId)
          ) ?? false
        }
        value={props.filters.serverSide.getValueOrNull("intakeStatusId")}
        onChange={(value) => {
          handleChangeServerSideFilter("intakeStatusId", value);
        }}
      />
      <Select {...intakeFlowTypeFilter} />
      <Select {...intakeQueueNameFilter} />
      <NullableMultiSelect {...agencyMemberFilter} selectNoneString="Not Assigned" />
      <IntakeTracksSelect
        multiple
        value={props.filters.serverSide.getValueOrNull("intakeTracks")}
        onChange={(value) => handleChangeServerSideFilter("intakeTracks", value)}
      />
      <Toggle
        value={incompleteIntakeFlowFilter.isChecked}
        onChange={(value) => incompleteIntakeFlowFilter.onChange(value)}
      >
        Incomplete Patient Flow
        <IncompletePatientFlowBadge {...props} />
      </Toggle>
    </>
  );

  const actionNode = (
    <>
      <Button
        colorScheme="blue"
        leftIcon={<AddRoundedIcon />}
        onClick={createIntakePatientDisclosure.onOpen}
      >
        New Intake patient
      </Button>
    </>
  );

  return (
    <>
      <Page.Header>
        <IntakeDashboardTitle {...props} />
      </Page.Header>

      <Page.Content p={0}>
        <IntakeDashboardTable
          actionNode={actionNode}
          filterNode={filterNode}
          filters={props.filters}
          orderedCalls={props.orderedCalls}
          tableRef={tableRef}
          onClickDashboardRow={props.onClickDashboardRow}
        />

        {/** Start New Intake Patient Modal */}
        <Drawer {...createIntakePatientDisclosure} size="xl">
          <DrawerContent>
            <DrawerCloseButton mr={2} size="lg" />
            <DrawerHeader>Add New Intake Patient</DrawerHeader>
            <DrawerBody>
              <NewIntakePatient onCreateNewIntakePatient={createIntakePatient} />
            </DrawerBody>
          </DrawerContent>
          <DrawerOverlay />
        </Drawer>
        {/** End New Intake Patient Modal */}
      </Page.Content>
    </>
  );
};

export default IntakeDashboardTabView;
