import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import AccountArrowRightIcon from "../../../shared/icons/AccountArrowRightIcon";
import TransferFromIntakePatientModal from "./TransferFromIntakePatientModal";
import { PatientId } from "../../../shared/schema/schema";

type Props = ButtonProps & {
  patientId: PatientId;
  track: Messages["PatientIntakeTrack"];
};

export default function TransferFromIntakePatientButton(props: Props) {
  const disclosure = useDisclosure();
  const { patientId, track, ...buttonProps } = props;

  return (
    <>
      <Button
        leftIcon={<AccountArrowRightIcon />}
        variant="outline"
        onClick={disclosure.onOpen}
        {...buttonProps}
      >
        Transfer from patient
      </Button>
      <TransferFromIntakePatientModal disclosure={disclosure} patientId={patientId} track={track} />
    </>
  );
}
