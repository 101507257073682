import { createIcon } from "@chakra-ui/react";

const XMark20SolidIcon = createIcon({
  displayName: "XMark20SolidIcon",
  viewBox: "0 0 20 20",
  path: (
    <path
      d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94L6.28 5.22Z"
      fill="currentColor"
    />
  ),
});

export default XMark20SolidIcon;
