import React from "react";

import { Badge } from "@chakra-ui/react";
import { createColumnHelper, Table as ReactTable, Row } from "@tanstack/react-table";
import { Messages } from "../../../core/api";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import useQueryDataTable from "../../../shared/components/DataTable/useQueryDataTable";
import useApi from "../../../shared/hooks/useApi";
import DataTable from "../../../shared/components/DataTable/DataTable";
import { useQuery } from "@tanstack/react-query";
import { FilterProps } from "../../../shared/utils/filter-props";
import { CheckIcon } from "@chakra-ui/icons";
import { PatientId } from "../../../shared/schema/schema";
import EntityCard, { PatientEntity } from "../../../shared/components/EntityCard";

export type IntakePlanDataRow = Messages["IntakePatientPlansDashboardDetails"] & {
  entity: PatientEntity;
};

function toDataRow(row: Messages["IntakePatientPlansDashboardDetails"]): IntakePlanDataRow {
  return {
    ...row,
    entity: {
      displayId: row.patientDisplayId,
      fullName: row.patientFirstName + " " + row.patientLastName,
      gender: row.patientGender,
      id: row.patientId,
      status: row.patientStatus,
      type: "Patient",
      contactDetails: null,
    },
  };
}

interface Props {
  plans: Messages["IntakePatientPlansDashboardDetails"][];
  tableRef?: React.MutableRefObject<ReactTable<IntakePlanDataRow> | null>;
  filterNode: React.ReactNode;
  filters: FilterProps<"./patient_intake/patient_plans">["filters"];
  onClickDashboardRow: (event: React.MouseEvent<HTMLTableRowElement>, patientId: PatientId) => void;
}

export default function IntakeDashboardTable(props: Props) {
  const { queries } = useApi();

  const columns = React.useMemo(() => {
    return createIntakePlansDashboardColumns();
  }, []);

  const queryOptions = queries.intake.plans(props.filters.params.toJSON());
  const query = useQuery({
    ...queryOptions,
    select: (data) => data.plans.map((p) => toDataRow(p)),
  });

  const { dataTableProps } = useQueryDataTable({
    query,
    columns,
    columnVisiblity: {
      storage: { key: "patient-plans-dashboard", version: 2 },
      forceVisibleColumns: React.useMemo(() => ["entity"], []),
      initialSelected: [
        "patientCurrentPlan",
        "patientReferredPlan",
        "referralDate",
        "patientIntakeStatus",
        "main",
        "selected",
      ],
    },
    initialSorting: [
      {
        id: "patientCurrentPlan",
        desc: true,
      },
    ],
  });

  const handleClickDashboardRow = (
    event: React.MouseEvent<HTMLTableRowElement>,
    row: Row<IntakePlanDataRow>
  ) => {
    props.onClickDashboardRow(event, row.original.patientId);
  };

  return (
    <DataTable
      {...dataTableProps}
      filterNode={props.filterNode}
      onClickRow={handleClickDashboardRow}
    />
  );
}

function createIntakePlansDashboardColumns() {
  const columnHelper = createColumnHelper<IntakePlanDataRow>();
  const columns = [
    columnHelper.accessor("entity", {
      cell: (info) => <EntityCard entity={info.getValue()} />,
      header: "Patient",
      meta: {
        csvFn: (x) => `${x.fullName} (${x.displayId})`,
      },
    }),
    columnHelper.accessor("patientCurrentPlan", {
      header: "Current Plan",
      cell: (info) => info.getValue() ?? "Unknown",
      meta: {
        csvFn: (x) => x ?? "Unknown",
      },
    }),
    columnHelper.accessor("patientReferredPlan", {
      header: "Referred Plan",
      cell: (info) => info.getValue() ?? "Unknown",
      meta: {
        csvFn: (x) => x ?? "Unknown",
      },
    }),
    columnHelper.accessor("referralDate", {
      header: "Referral Date",
      cell: (info) => fmap(info.getValue(), (v) => dateFormatter.toDate(v)) ?? "Unknown",
      meta: {
        csvFn: (x) => fmap(x, (v) => dateFormatter.toDate(v)) ?? "Unknown",
      },
    }),
    columnHelper.accessor("patientIntakeStatus", {
      header: "Intake Status",
      cell: (info) => info.getValue() ?? "Unknown",
      meta: {
        csvFn: (x) => x ?? "Unknown",
      },
    }),
    columnHelper.accessor("main", {
      header: "Main Plan",
      cell: (info) =>
        info.getValue() ? (
          <Badge colorScheme="green">
            <CheckIcon />
          </Badge>
        ) : null,
      meta: {
        csvFn: (x) => (x ? "Yes" : "No"),
      },
    }),
    columnHelper.accessor("selected", {
      header: "Selected Plan",
      cell: (info) =>
        info.getValue() ? (
          <Badge colorScheme="blue">
            <CheckIcon />
          </Badge>
        ) : null,
      meta: {
        csvFn: (x) => (x ? "Yes" : "No"),
      },
    }),
  ];

  return columns;
}
