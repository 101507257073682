import { Box, Text, Flex, Button, IconButton, Tooltip } from "@chakra-ui/react";
import { z } from "zod";
import SendIcon from "../../../../shared/icons/SendIcon";
import EmailInput from "./EmailInput/EmailInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Messages, ResponseOf } from "../../../../core/api";
import EmailAttachFilesButton from "./EmailAttachFilesButton";
import { S3Object } from "../../../../shared/hooks/useUploadFiles";
import { convert as convertHtmlToText } from "html-to-text";
import {
  DEFAULT_SYSTEM_EMAIL_ADDRESS,
  EmailQuotedReplyParams,
} from "../../utils/comm-center-email-utils";
import AttachmentTags from "./AttachmentTags";
import React from "react";
import useEmailTicketMessages from "../../hooks/useEmailTicketMessages";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../shared/query-keys";
import useApi from "../../../../shared/hooks/useApi";
import ReplyRoundedIcon from "../../../../shared/icons/ReplyRoundedIcon";
import {
  CommCenterEmailThreadId,
  CommCenterTeamId,
  CommCenterTicketId,
} from "../../../../shared/schema/schema";
import ReplyAllRoundedIcon from "../../../../shared/icons/ReplyAllRoundedIcon";
import { DeleteIcon } from "@chakra-ui/icons";
import EmailEditor from "./EmailEditor";

export type EmailReplyType = "reply" | "reply-all";
export type EmailReplyDefaultValues = {
  recipients: Messages["CommCenterEmailAddressObject"][];
  cc: Messages["CommCenterEmailAddressObject"][];
  bcc: Messages["CommCenterEmailAddressObject"][];
  quotedReplyParams?: EmailQuotedReplyParams;
};

const zRecipient = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  name: z.string(),
});

const zEmailReply = z.object({
  to: zRecipient
    .array()
    .refine((x) => x.length > 0, { message: "Please specify at least one recipient" }),
  cc: zRecipient.array(),
  bcc: zRecipient.array(),
  content: z.string().refine((x) => x.replace(/<[^>]*>/g, "").length > 0, {
    message: "Email content cannot be empty",
  }),
});
type EmailReply = z.infer<typeof zEmailReply>;

function getIconByReplyType(type: EmailReplyType) {
  switch (type) {
    case "reply":
      return <ReplyRoundedIcon h={6} w={6} />;
    case "reply-all":
      return <ReplyAllRoundedIcon h={6} w={6} />;
  }
}

function getTextByReplyType(type: EmailReplyType) {
  switch (type) {
    case "reply":
      return "Reply";
    case "reply-all":
      return "Reply All";
  }
}

function EmailTicketReply(props: {
  ticketId: CommCenterTicketId;
  threadId: CommCenterEmailThreadId;
  ticketRelatedTeamId: CommCenterTeamId;
  defaultValues: Partial<EmailReplyDefaultValues>;
  type: EmailReplyType;
  onDiscardDraftReply: () => void;
  onSubmitNewEmailMessage?: () => void;
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
}) {
  const { api } = useApi();
  const { attachments, onChangeAttachments, submitEmailMessage } = useEmailTicketMessages({
    onSubmitEmailMessage: props.onSubmitNewEmailMessage,
    onSuccessCreateEmailTicket: props.onSuccessCreateEmailTicket,
  });

  const modalContainerRef = React.useRef(null);

  const systemEmailAddressesQuery = useQuery({
    queryKey: queryKeys.commCenter.emailAddresses(),
    queryFn: () => api.get("./comm_center/email/system_addresses", {}),
    placeholderData: keepPreviousData,
  });

  const form = useForm<EmailReply>({
    resolver: zodResolver(zEmailReply),
    defaultValues: {
      to: props.defaultValues.recipients ?? [],
      cc: props.defaultValues.cc ?? [],
      bcc: props.defaultValues.bcc ?? [],
      content: "",
    },
  });
  const { handleSubmit, setValue, getValues, formState, trigger } = form;

  const onSubmit: SubmitHandler<EmailReply> = async (data: EmailReply) => {
    const getSenderEmailAddress = () => {
      const retEmailAddress = { name: "Medflyt", email: DEFAULT_SYSTEM_EMAIL_ADDRESS };
      const emailAddress = systemEmailAddressesQuery.data?.emailAddresses?.find(
        (x) => x.teamId === props.ticketRelatedTeamId
      )?.email;
      if (emailAddress !== undefined) {
        retEmailAddress.email = emailAddress;
      }
      return retEmailAddress;
    };
    await submitEmailMessage({
      sender: getSenderEmailAddress(),
      threadId: props.threadId,
      ticketId: props.ticketId,
      recipients: data.to,
      cc: data.cc,
      bcc: data.bcc,
      content: data.content,
      contentPlainText: convertHtmlToText(data.content, {
        wordwrap: 130,
      }),
    });
    form.reset();
  };

  const onChangeListField = (
    value: Messages["CommCenterEmailAddressObject"],
    field: "to" | "cc" | "bcc"
  ) => {
    const list = getValues(field);
    const index = list.map((x: Messages["CommCenterEmailAddressObject"]) => x).indexOf(value);
    if (index === -1) {
      setValue(field, [...list, value]);
    } else {
      setValue(field, [...list.slice(0, index), ...list.slice(index + 1)]);
    }
    trigger(field);
  };

  const handleRemoveAttachment = (file: S3Object) => {
    const newAttachments = attachments.filter((a) => a.key !== file.key);
    onChangeAttachments(newAttachments);
  };

  const errors = [formState.errors.content?.message, formState.errors.to?.message].filter((x) => x);

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Box
      borderColor="gray.200"
      borderRadius="lg"
      borderStyle="solid"
      borderWidth={1}
      boxShadow="xl"
      p={4}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap={2} mb={3} textAlign="left">
          {getIconByReplyType(props.type)}
          <Text fontSize="xl">{getTextByReplyType(props.type)}</Text>
        </Flex>
        <Tooltip label="Discard draft">
          <IconButton
            aria-label="discard"
            icon={<DeleteIcon />}
            variant="ghost"
            onClick={props.onDiscardDraftReply}
          />
        </Tooltip>
      </Flex>
      <form onKeyDown={handleFormKeyDown} onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap={4}>
          <EmailInput
            label="To"
            value={getValues("to")}
            onSelect={(entity) => onChangeListField(entity, "to")}
          />
          <EmailInput
            label="cc"
            value={getValues("cc")}
            onSelect={(entity) => onChangeListField(entity, "cc")}
          />
          <EmailInput
            label="bcc"
            value={getValues("bcc")}
            onSelect={(entity) => onChangeListField(entity, "bcc")}
          />
          <EmailEditor
            key={`email-reply-editor-${formState.isSubmitting}`}
            emailQuotedReplyParams={props.defaultValues.quotedReplyParams}
            onChange={({ output }) => {
              setValue("content", output);
              trigger("content");
            }}
          />
          <div>
            <AttachmentTags attachments={attachments} onRemove={handleRemoveAttachment} />
          </div>
        </Flex>

        <Flex gap={2} mt={5}>
          {errors.map((error, i) => (
            <Text key={`email-reply-error-${i}`} color="red.500" fontSize="sm">
              {error}.
            </Text>
          ))}
        </Flex>

        <Flex gap={3} justifyContent="flex-end" mt={4}>
          <Box ref={modalContainerRef} position="relative" zIndex="emailAttachFiles" />
          <EmailAttachFilesButton
            files={attachments}
            isDisabled={formState.isSubmitting}
            portalProps={{ containerRef: modalContainerRef }}
            onChange={onChangeAttachments}
          />
          <Button
            colorScheme="blue"
            gap={2}
            isDisabled={errors.length > 0}
            isLoading={formState.isSubmitting}
            rounded="xl"
            size="md"
            type="submit"
          >
            Send
            <SendIcon />
          </Button>
        </Flex>
      </form>
    </Box>
  );
}

export default EmailTicketReply;
