import { useDisclosure } from "@chakra-ui/react";
import DialerModal from "../../../shared/components/DialerModal";
import { useReactBridgeDispatchEvent } from "../../../shared/hooks/useReactBridgeEvent";
import { usePhoneNumberDialerPopup } from "../hooks/usePhoneNumberDialerPopup";
import useCallCenter from "../hooks/useCallCenter";
import useInviteToCallModal from "../hooks/useInviteToCallModal";

export default function PhoneNumberDialerPopupListener() {
  const dialerPopup = usePhoneNumberDialerPopup();
  const callCenter = useCallCenter();
  const inviteToCallModal = useInviteToCallModal();
  const disclosure = useDisclosure({
    isOpen: dialerPopup.state.isOpen,
    onClose: dialerPopup.close,
  });

  useReactBridgeDispatchEvent({
    eventName: "CALL_CENTER:OPEN_DIALER_POPUP",
    onEvent: (payload) => {
      if (callCenter.state.currentCall === null) {
        dialerPopup.open({ predefinedPhoneNumber: payload.predefinedPhoneNumber });
      } else {
        inviteToCallModal.open({ callId: callCenter.state.currentCall.id });
      }
    },
  });

  return (
    <DialerModal
      isOpen={disclosure.isOpen}
      predefinedPhoneNumber={dialerPopup.state.predefinedPhoneNumber}
      onClose={disclosure.onClose}
    />
  );
}
