import PhoneNumber from "awesome-phonenumber";

function createFormatter() {
  function isValidNumber(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.isValid();
  }
  function isE164(number: string) {
    return /^\+?[1-9]\d{10,14}$/.test(number);
  }
  function formatE164(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.getNumber("e164");
  }
  function formatNational(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.getNumber("national");
  }

  function formatNationalIfValid(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.isValid() ? phoneNumber.getNumber("national") : number;
  }

  function formatNumberWithoutCountryPrefix(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.getNumber("significant");
  }

  return {
    isValidNumber,
    isE164,
    formatE164,
    formatNational,
    formatNationalIfValid,
    formatNumberWithoutCountryPrefix,
  };
}

export const phoneFormatter = createFormatter();
