import { Tag, TagLabel } from "@chakra-ui/react";
import AssigneeIcon from "../../../shared/icons/AssigneeIcon";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import { AgencyMemberId } from "../../../shared/schema/schema";

type Props = {
  agencyMember: {
    id: AgencyMemberId;
    name: string;
  };
};

function IntakePatientAgencyMemberAssignmentTag(props: Props) {
  const { agencyMember } = useAuthData();

  return (
    <Tag
      colorScheme={agencyMember.id === props.agencyMember.id ? "blue" : "gray"}
      gap={1}
      size="lg"
      variant="subtle"
    >
      <AssigneeIcon boxSize={6} />
      <TagLabel>{props.agencyMember.name}</TagLabel>
    </Tag>
  );
}

export default IntakePatientAgencyMemberAssignmentTag;
