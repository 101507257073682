import React from "react";

import { Button } from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Messages } from "../../../../../core/api";
import LoadingButton from "../../../../../shared/components/LoadingButton";
import Select, { CustomSelectProps } from "../../../../../shared/components/Select";
import SelectNoResults from "../../../../../shared/components/SelectNoResults";
import useApi from "../../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../../shared/query-keys";
import { IntakeStatusId } from "../../../../../shared/schema/schema";
import { fmap } from "../../../../../shared/utils";

export default function IntakeStatusSelect(
  props: Omit<
    CustomSelectProps<IntakeStatusId>,
    "onChange" | "multiple" | "value" | "selectedLabel"
  > & {
    filter?: (row: Messages["IntakeStatus"]) => boolean;
    label?: string;
  } & (
      | {
          multiple: true;
          value: IntakeStatusId[] | null;
          selectedLabel?: (value: IntakeStatusId[]) => string;
          defaultValue?: (intakeStatuses: Messages["IntakeStatus"][]) => IntakeStatusId[] | null;
          onChange: (
            value: IntakeStatusId[] | undefined,
            intakeStatuses: Messages["IntakeStatus"][]
          ) => void;
        }
      | {
          multiple: false;
          value: IntakeStatusId | null;
          selectedLabel?: (value: IntakeStatusId) => string;
          defaultValue?: (intakeStatuses: Messages["IntakeStatus"][]) => IntakeStatusId | null;
          onChange: (
            value: IntakeStatusId | undefined,
            intakeStatuses: Messages["IntakeStatus"][]
          ) => void;
        }
    )
) {
  const { api } = useApi();

  const query = useQuery({
    queryKey: queryKeys.patientIntake.intakeStatus(),
    queryFn: async () => api.get("./patient_intake/intake_status", {}),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });

  const filteredData = React.useMemo(() => {
    if (query.status !== "success") {
      return [];
    }

    const { intakeStatuses } = query.data;
    return fmap(props.filter, (filter) => intakeStatuses.filter(filter)) ?? intakeStatuses;
  }, [query.status, query.data, props.filter]);

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success": {
      return props.multiple ? (
        <Select
          label={props.label ?? "Intake Statuses"}
          options={filteredData.map((x) => ({ value: x.id, label: x.status }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          {...props}
          multiple={true}
          value={props.value ?? props.defaultValue?.(query.data.intakeStatuses) ?? null}
          onChange={(value) => props.onChange(value, query.data.intakeStatuses)}
        />
      ) : (
        <Select
          label={props.label ?? "Intake Status"}
          options={filteredData.map((x) => ({ value: x.id, label: x.status }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          {...props}
          multiple={false}
          value={props.value ?? props.defaultValue?.(query.data.intakeStatuses) ?? null}
          onChange={(value) => props.onChange(value, filteredData)}
        />
      );
    }
  }
}
