import { Flex, Text } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import { fmap } from "../../../../../shared/utils";
import { CallState, useCallStateValue } from "../../../../call-center/hooks/useCallState";

export default function CurrentlyTalkingToIndicator() {
  const call = useCallStateValue();

  const participantEntity = getParticipantEntityWeAreTalkingTo(call);

  const text =
    participantEntity === undefined
      ? null
      : getEntityNameAndRelationToThePatient(participantEntity);

  return fmap(text, (text) => (
    <Flex alignItems="center" gap={2}>
      <Text as="span">Talking to: {text}</Text>
    </Flex>
  ));
}

function getParticipantEntityWeAreTalkingTo(call: CallState) {
  if (
    call.status === "Active" ||
    call.status === "Ringing" ||
    call.status === "Dialing" ||
    call.status === "Calling"
  ) {
    switch (call.currentCall?.direction) {
      case "Inbound":
        return call.currentCall.caller.entity;
      case "Outbound":
        return call.currentCall.callee.entity;
      case "PortalCallRequest":
        return call.currentCall.requester.entity;
      case undefined:
        return undefined;
    }
  }

  return undefined;
}

function getEntityNameAndRelationToThePatient(
  entity: Messages["TelephonyCallParticipantEntityInfo"]
) {
  switch (entity.type) {
    case "AgencyMember":
    case "Caregiver":
      return null;

    case "NotIdentified":
      return "Unknown contact";

    case "Patient":
      return `${entity.firstName} ${entity.lastName}, The patient`;

    case "PatientContact":
      return `${entity.firstName} ${entity.lastName}, Patient's ${entity.relationship}`;

    case "PhonebookContact":
      return `${entity.name}`;
  }
}
