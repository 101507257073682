import { z } from "zod";
import LeavePageDialog from "../../../../shared/components/LeavePageDialog";
import Page from "../../../../shared/components/Page";
import usePathParams from "../../../../shared/hooks/usePathParams";
import { PatientId } from "../../../../shared/schema/schema";
import { zPathParamId } from "../../../../shared/utils/zod";
import { usePhoneNumberDialerPopup } from "../../../call-center/hooks/usePhoneNumberDialerPopup";
import { isLostPatientIntakeStatus } from "../../shared/utils/intake.utils";
import PatientIntakeFlowContent from "./components/PatientIntakeFlowContent";
import usePatientIntakeFlow from "./hooks/usePatientIntakeFlow";

const zPathParams = z.object({
  patientId: zPathParamId(PatientId),
});

export default function PatientIntakeFlowPage() {
  const pathParams = usePathParams(zPathParams);
  const {
    intakeProfileDisclosure,
    activeStepId,
    activeStepForm,
    setActiveStepId,
    setField,
    onSubmitStep,
    isStepSubmitting,
    patientQuery,
    stepsQuery,
    stepQuery,
    externalFlowsQuery,
    didFinishFlow,
    setDidFinishFlow,
  } = usePatientIntakeFlow({ patientId: pathParams.patientId });

  const showLeavePageDialog =
    !didFinishFlow && !isLostPatientIntakeStatus(patientQuery.data?.patient.intakeStatus ?? null);
  const dialerPopup = usePhoneNumberDialerPopup();

  const leavePageDialogBody = (
    <>
      Not completing the questions will cause potential issues with this patient.
      <br />
      <br />
      You need to complete all the steps for this call until the “End call” step is completed.
    </>
  );

  return (
    <Page>
      <Page.Content>
        <LeavePageDialog
          actionsOrientation="column"
          body={leavePageDialogBody}
          cancelButtonProps={{
            w: 200,
          }}
          cancelButtonText="Complete steps"
          confirmButtonProps={{
            w: 200,
          }}
          confirmButtonText="I need to stop in the middle"
          enabled={showLeavePageDialog}
          header="Are you sure?"
        />
        <PatientIntakeFlowContent
          activeStepForm={activeStepForm}
          activeStepId={activeStepId}
          externalFlowsQuery={externalFlowsQuery}
          intakeProfileDisclosure={intakeProfileDisclosure}
          isStepSubmitting={isStepSubmitting}
          patientId={pathParams.patientId}
          patientQuery={patientQuery}
          phoneDialerPopup={dialerPopup}
          stepQuery={stepQuery}
          stepsQuery={stepsQuery}
          onChangeField={setField}
          onChangeStep={setActiveStepId}
          onFinishFlow={() => setDidFinishFlow(true)}
          onSubmitStep={onSubmitStep}
        />
      </Page.Content>
    </Page>
  );
}
