import { useMutation } from "@tanstack/react-query";
import { BodyOf } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { NoteEntity } from "../components/EntityNotes";

export function useExportEntityNotesMutation(props: { entity: NoteEntity }) {
  const { api } = useApi();

  return useMutation({
    mutationFn: (
      body:
        | BodyOf<"post", "./patient/:patientId/export_notes">
        | BodyOf<"post", "./caregiver/:caregiverId/export_notes">
    ) => {
      switch (props.entity.type) {
        case "Caregiver":
        case "CaregiverCalendar":
          return api.post("./caregiver/:caregiverId/export_notes", {
            body,
            path: { caregiverId: props.entity.id },
          });
        case "Patient":
        case "PatientCalendar":
          return api.post("./patient/:patientId/export_notes", {
            body,
            path: { patientId: props.entity.id },
          });
      }
    },
    onSuccess: ({ fileUrl }) => {
      window.open(fileUrl);
    },
  });
}
