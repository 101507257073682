import {
  Button,
  ButtonProps,
  ChakraComponent,
  ComponentWithAs,
  useDisclosure,
} from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import { WorkflowHumanTaskAssignment } from "../../../workflow.types";
import Countdown from "./Countdown";
import StartTaskDialog from "./StartTaskDialog";

type Props = {
  task: WorkflowHumanTaskAssignment;
  isActiveTask: boolean;
  isLoading: boolean;
  onChangeActiveTask: (task: WorkflowHumanTaskAssignment) => void;
  onRequestAssign: (instance: Messages["WorkflowHumanTaskInstance"]) => void;
};

export default function ClusteredTaskButton(props: Props) {
  const disclosure = useDisclosure();

  const handleClick = () => {
    if (props.task.endsAt !== null) {
      return props.onChangeActiveTask(props.task);
    }

    disclosure.onOpen();
  };

  return (
    <>
      <ClusteredTaskButtonUI
        colorScheme={props.isActiveTask ? "blue" : undefined}
        flexShrink={0}
        gap={1}
        isLoading={props.isLoading}
        rounded="full"
        variant="outline"
        onClick={handleClick}
      >
        <span>{props.task.instance.meta.name}</span>
        {props.task.endsAt && <Countdown end={props.task.endsAt} />}
      </ClusteredTaskButtonUI>

      <StartTaskDialog
        disclosure={disclosure}
        task={props.task}
        onConfirm={props.onRequestAssign}
      />
    </>
  );
}

const ClusteredTaskButtonUI: ChakraComponent<ComponentWithAs<"button", ButtonProps>> = (props) => {
  return <Button flexShrink={0} gap={1} rounded="full" variant="outline" {...props} />;
};
ClusteredTaskButton.UI = ClusteredTaskButtonUI;
