import EntityCard from "../../../../../../shared/components/EntityCard";
import { useCommCenterTicketPopup } from "../../../../../communication/hooks/useCommCenterTicketPopup";
import { CommCenterTicketId } from "../../../../../../shared/schema/schema";
import useCommCenterTicket from "../../../../../communication/hooks/useTicket";
import { CardButton } from "./CardButton";
import { LoadingEntityCard } from "./LoadingEntityCard";

export function TicketLinkCard(props: { ticketId: CommCenterTicketId }) {
  const ticketQuery = useCommCenterTicket(props.ticketId);

  const commCenterTicketPopup = useCommCenterTicketPopup();

  if (ticketQuery.isPending) {
    return <LoadingEntityCard />;
  }

  if (ticketQuery.isError) {
    return null;
  }

  const { ticket } = ticketQuery.data;

  const entity = {
    type: "CommCenterTicket" as const,
    id: props.ticketId,
    status: ticket.status,
    label: ticket.label,
  };

  const handleClick = () => {
    if (ticket.relatedCaregiver?.id) {
      return commCenterTicketPopup.open({
        primaryEntity: "Caregiver",
        defaultTicketId: props.ticketId,
        caregiverId: ticket.relatedCaregiver.id,
      });
    }

    if (ticket.relatedPatient?.id) {
      return commCenterTicketPopup.open({
        primaryEntity: "Patient",
        defaultTicketId: props.ticketId,
        patientId: ticket.relatedPatient.id,
        contactDetails: null,
      });
    }

    if (ticket.relatedNotIdentifiedEmailEntity !== null) {
      return commCenterTicketPopup.open({
        primaryEntity: "NotIdentifiedEmailEntity",
        defaultTicketId: props.ticketId,
        notIdentifiedEmailEntity: ticket.relatedNotIdentifiedEmailEntity,
      });
    }

    if (ticket.relatedNotIdentifiedPhoneNumber !== null) {
      return commCenterTicketPopup.open({
        primaryEntity: "NotIdentifiedPhoneNumber",
        defaultTicketId: props.ticketId,
        notIdentifiedPhoneNumber: ticket.relatedNotIdentifiedPhoneNumber,
      });
    }

    if (ticket.relatedPhonebookContact !== null) {
      return commCenterTicketPopup.open({
        primaryEntity: "PhonebookContact",
        defaultTicketId: props.ticketId,
        phonebookContactId: ticket.relatedPhonebookContact.id,
      });
    }

    console.error("Unhandled callback for ticket", ticket);
  };

  return (
    <CardButton onClick={handleClick}>
      <EntityCard entity={entity} />
    </CardButton>
  );
}
