import { SortingState } from "@tanstack/react-table";
import React from "react";
import { parseFromStorage } from "../../utils/storage";
import { fmap } from "../../utils";

export type ColumnVisibilityParams = {
  storage?: { key: string; version: number };
  initialSelected?: SortingState;
};

export default function useSortingStorage(params: ColumnVisibilityParams) {
  const actualStorageKey = React.useMemo(() => {
    return fmap(params.storage, (x) => JSON.stringify(["table-sorting", x]));
  }, [params.storage]);

  const actualInitialSelected = React.useMemo(() => {
    return params.initialSelected !== undefined ? params.initialSelected : [];
  }, [params.initialSelected]);

  const [sorting, setSorting] = React.useState<SortingState>(() => {
    if (actualStorageKey === null) {
      return actualInitialSelected;
    }

    return (
      parseFromStorage({ key: actualStorageKey, storage: localStorage }) ?? actualInitialSelected
    );
  });

  React.useEffect(() => {
    if (actualStorageKey === null) {
      return;
    }

    sorting === undefined
      ? localStorage.removeItem(actualStorageKey)
      : localStorage.setItem(actualStorageKey, JSON.stringify(sorting));
  }, [actualStorageKey, sorting]);

  return {
    sorting,
    setSorting,
  };
}
