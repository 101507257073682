import { createIcon } from "@chakra-ui/react";

const MagicButtonIcon = createIcon({
  displayName: "MagicButtonIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m10 19l-2.5-5.5L2 11l5.5-2.5L10 3l2.5 5.5L18 11l-5.5 2.5L10 19Zm8 2l-1.25-2.75L14 17l2.75-1.25L18 13l1.25 2.75L22 17l-2.75 1.25L18 21Z"
      fill="currentColor"
    />
  ),
});

export default MagicButtonIcon;
