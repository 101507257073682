import { TableRowProps } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { downloadCSVContent, mapDataTableToCSV } from "../../utils/data-table-csv-utils";
import { sortingFns } from "../../utils/tanstack-table";
import { DATA_TABLE_DEFAULT_PAGE_SIZE } from "./data-table.utils";
import { DataTableMetaButtonsProps } from "./DataTableMetaButtons";
import useColumnVisibility, { ColumnVisibilityParams } from "./useColumnVisibility";
import useSortingStorage from "./useSortingStorage";

export default function useQueryDataTable<TData extends Record<string, unknown>, TValue>(params: {
  query: UseQueryResult<TData[], unknown>;
  columnVisiblity: Omit<ColumnVisibilityParams<TData, TValue>, "columns">;
  initialSorting?: SortingState;
  columns: ColumnDef<TData, any>[];
  disableLoader?: boolean;
  paginationSize?: number;
  trProps?: (row: TData) => TableRowProps;
}) {
  const { columnOptions, columnVisibility, visibleColumns, setVisibleColumns } =
    useColumnVisibility({ ...params.columnVisiblity, columns: params.columns });

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: params.paginationSize ? params.paginationSize : DATA_TABLE_DEFAULT_PAGE_SIZE,
  });

  const { sorting, setSorting } = useSortingStorage({
    storage: params.columnVisiblity.storage,
    initialSelected: params.initialSorting,
  });

  const nodes = params.query.data;
  const totalCount = nodes?.length ?? 0;

  const pageCount = React.useMemo(() => {
    return Math.ceil(totalCount / pagination.pageSize);
  }, [pagination.pageSize, totalCount]);

  const exportCsv = () => {
    const headers = table
      .getHeaderGroups()
      .flatMap((group) => group.headers)
      .filter((x) => !x.id.startsWith("_"));

    const csv = mapDataTableToCSV({ rows: nodes ?? [], headers });

    downloadCSVContent(csv);
  };

  const table = useReactTable({
    data: React.useMemo(() => nodes ?? [], [nodes]),
    columns: params.columns,
    pageCount: pageCount,
    state: {
      columnVisibility,
      pagination,
      sorting,
    },
    sortingFns: sortingFns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const metaButtonProps: DataTableMetaButtonsProps = {
    columnOptions: columnOptions,
    onChangeVisibleColumns: setVisibleColumns,
    visibleColumns: visibleColumns,
    isExporting: false,
    isRefreshing: params.query.isFetching,
    onClickRefresh: params.query.refetch,
    onClickExport: exportCsv,
  };

  return {
    table,
    query: params.query,
    dataTableProps: {
      metaButtonProps,
      isLoading: params.query.isPending && params.disableLoader !== true,
      table: table,
      trProps: params.trProps,
      error: params.query.error,
    },
  };
}
