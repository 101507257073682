import { createIcon } from "@chakra-ui/react";

const Forward15SecondsIcon = createIcon({
  displayName: "Forward15SecondsIcon",
  viewBox: "0 0 24 24",
  path: (
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path d="M21 13a9 9 0 1 1-9-9m0 0h7.5m0 0l-2-2m2 2l-2 2M9 9v7" />
      <path d="M15 9h-2a1 1 0 0 0-1 1v1.5a1 1 0 0 0 1 1h1a1 1 0 0 1 1 1V15a1 1 0 0 1-1 1h-2" />
    </g>
  ),
});

export default Forward15SecondsIcon;
