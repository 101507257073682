import { createIcon } from "@chakra-ui/react";

const TextDocumentOutlineIcon = createIcon({
  displayName: "TextDocumentOutlineIcon",
  viewBox: "0 0 15 15",
  path: (
    <path
      d="M4.5 6.995H4v1h.5v-1Zm6 1h.5v-1h-.5v1Zm-6 1.998H4v1h.5v-1Zm6 1.007h.5v-1h-.5v1Zm-6-7.003H4v1h.5v-1ZM8.5 5H9V4h-.5v1Zm2-4.5l.354-.354L10.707 0H10.5v.5Zm3 3h.5v-.207l-.146-.147l-.354.354Zm-9 4.495h6v-1h-6v1Zm0 2.998l6 .007v-1l-6-.007v1Zm0-5.996L8.5 5V4l-4-.003v1Zm8 9.003h-10v1h10v-1ZM2 13.5v-12H1v12h1ZM2.5 1h8V0h-8v1ZM13 3.5v10h1v-10h-1ZM10.146.854l3 3l.708-.708l-3-3l-.708.708ZM2.5 14a.5.5 0 0 1-.5-.5H1A1.5 1.5 0 0 0 2.5 15v-1Zm10 1a1.5 1.5 0 0 0 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM2 1.5a.5.5 0 0 1 .5-.5V0A1.5 1.5 0 0 0 1 1.5h1Z"
      fill="currentColor"
    />
  ),
});

export default TextDocumentOutlineIcon;
