import { SearchIcon } from "@chakra-ui/icons";
import { Flex, InputGroup, InputLeftElement, Input, Button } from "@chakra-ui/react";
import Select from "../../../../../shared/components/Select";
import AddRoundedIcon from "../../../../../shared/icons/AddRoundedIcon";
import {
  IntakeExternalFlowId,
  IntakeStatusId,
  IntakeTrackId,
} from "../../../../../shared/schema/schema";

interface IntakeTrackOption {
  value: IntakeTrackId;
  label: string;
}

interface IntakeStatusOption {
  value: IntakeStatusId;
  label: string;
}

interface ExternalFlowOption {
  value: IntakeExternalFlowId;
  label: string;
}

export default function SidebarHeader(props: {
  freeTextValue: string;
  intakeStatuses: IntakeStatusOption[];
  selectedIntakeStatuses: IntakeStatusId[] | null;
  intakeTracks: IntakeTrackOption[];
  selectedIntakeTracks: IntakeTrackId[] | null;
  externalFlows: ExternalFlowOption[];
  selectedExternalFlows: IntakeExternalFlowId[] | null;
  onChangeTrack: (value: IntakeTrackId[]) => void;
  onChangeIntakeStatus: (value: IntakeStatusId[]) => void;
  onChangeFreeText: (value: string) => void;
  onClickNew: () => void;
  onChangeExternalFlows: (value: IntakeExternalFlowId[]) => void;
}) {
  return (
    <Flex direction="column" gap={2}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.500" />
        </InputLeftElement>
        <Input
          placeholder="Search step..."
          value={props.freeTextValue}
          onChange={(e) => props.onChangeFreeText(e.target.value)}
        />
      </InputGroup>

      <Flex gap={2}>
        <Select
          buttonProps={{ w: "full" }}
          label="Track Filter"
          multiple={true}
          options={props.intakeTracks}
          value={props.selectedIntakeTracks}
          onChange={(value) => props.onChangeTrack(value ?? [])}
        />
        <Select
          buttonProps={{ w: "full" }}
          label="Status Filter"
          multiple={true}
          options={props.intakeStatuses}
          value={props.selectedIntakeStatuses}
          onChange={(value) => props.onChangeIntakeStatus(value ?? [])}
        />
        <Select
          buttonProps={{ w: "full" }}
          label="External Flow"
          multiple={true}
          options={props.externalFlows}
          value={props.selectedExternalFlows}
          onChange={(value) => props.onChangeExternalFlows(value ?? [])}
        />
      </Flex>
      <Button colorScheme="blue" leftIcon={<AddRoundedIcon />} onClick={props.onClickNew}>
        New
      </Button>
    </Flex>
  );
}
