import { queryOptions } from "@tanstack/react-query";
import { QueryParamsOf } from "../../core/api";
import { createQueryGroup } from "../../core/api/queries";
import { PatientId } from "../../shared/schema/schema";

export default createQueryGroup(({ api }) => ({
  profile: (patientId: PatientId) =>
    queryOptions({
      queryKey: ["intake", "patients", patientId],
      queryFn: () => api.get("./patients/:patientId/intake", { path: { patientId } }),
    }),
  checklist: (patientId: PatientId) =>
    queryOptions({
      queryKey: ["checklist", "patients", patientId],
      queryFn: () => api.get("./patient_intake/:patientId/checklist", { path: { patientId } }),
    }),
  dashboard: (query: QueryParamsOf<"get", "./patient_intake/dashboard">) =>
    queryOptions({
      queryKey: ["intake", "dashboard", query],
      queryFn: () => api.get("./patient_intake/dashboard", { query }),
    }),
  plans: (query?: QueryParamsOf<"get", "./patient_intake/patient_plans">) =>
    queryOptions({
      queryKey: ["intake", "plans", "list", query].filter(Boolean),
      queryFn: () => api.get("./patient_intake/patient_plans", { query: query ?? {} }),
    }),
  intakeTeams: () =>
    queryOptions({
      queryKey: ["intake", "teams"],
      queryFn: () => api.get("./patient_intake/intake_teams", {}),
    }),
  parallelPatients: () =>
    queryOptions({
      queryKey: ["intake", "parallel"],
      queryFn: () => api.get("./patient_intake/parallel_calls_patients", {}),
    }),
}));
