import { PatientId } from "../schema/schema";

export const usePatientProfileLink = () => {
  const open = (params: { id: PatientId; onClose?: () => void; redirectModal?: boolean }) => {
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "navigate",
          payload: { id: params.id, entity: "Patient" },
          onClose: params?.onClose,
          redirect: params?.redirectModal ?? true,
        },
      })
    );
  };

  return open;
};
