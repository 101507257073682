import { MutationOptions, UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";
import callCenterMutations from "../../modules/call-center/call-center.mutations";
import { NewTicketRequestBody } from "../../modules/communication/utils/communication-utils";
import { InputFileUploadDestination } from "../../shared/components/FileInput";
import { FileUpload } from "../../shared/hooks/useUploadFiles";
import { CommCenterTeamMemberId, WorkflowDefinitionId } from "../../shared/schema/schema";
import { API, createApi } from "./api-utils";

type PartialMutationOptions<TMutationFnData, TError, TVariables, TContext> = Pick<
  MutationOptions<TMutationFnData, TError, TVariables, TContext>,
  "mutationFn"
>;

function createMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
) {
  return options;
}

export function createMutationGroup<GroupRecord extends Record<PropertyKey, unknown>>(
  factory: (p: {
    api: API;
    createMutation: <TData = unknown, TError = unknown, TVariables = void>(
      options: PartialMutationOptions<TData, TError, TVariables, TData>
    ) => PartialMutationOptions<TData, TError, TVariables, TData>;
  }) => GroupRecord
) {
  return factory;
}

export function createMutations(params: { api: ReturnType<typeof createApi> }) {
  const { api } = params;
  const groupParams = { api, createMutation };

  return {
    runWorkflow: (workflowDefinitionId: WorkflowDefinitionId) =>
      createMutation({
        mutationKey: ["workflows"],
        mutationFn: (input: Record<string, unknown>) => {
          return api.post("./workflow_definitions/:workflowDefinitionId/run", {
            body: { input },
            path: { workflowDefinitionId },
          });
        },
      }),
    generatePresignedUrls: (fileUploadDestination: InputFileUploadDestination) =>
      createMutation({
        mutationKey: ["generate_presigned_urls"],
        mutationFn: (uploads: FileUpload[]) => {
          switch (fileUploadDestination.by) {
            case "workflow-definition":
              return api.post("./workflow_definitions/:workflowDefinitionId/presign_urls", {
                path: { workflowDefinitionId: fileUploadDestination.id },
                body: { fileNames: uploads.map((upload) => upload.file.name) },
              });
            case "email-attachment":
              return api.post("./comm_center/email/presign_urls", {
                body: { fileNames: uploads.map((upload) => upload.file.name) },
              });
            case "workflow-task":
              return api.post("./workflow_tasks/:workflowTaskInstanceId/presign_urls", {
                path: { workflowTaskInstanceId: fileUploadDestination.id },
                body: { fileNames: uploads.map((upload) => upload.file.name) },
              });
          }
        },
      }),
    uploadFile: () =>
      createMutation({
        mutationKey: ["upload_file"],
        mutationFn: async (params: {
          url: string;
          file: File;
          onUploadProgress: (percentage: number) => void;
        }) => {
          const { url, file, onUploadProgress } = params;
          const { data } = await axios.put(url, file, {
            headers: { "Content-Type": file.type },
            onUploadProgress: (e) => {
              const percentCompleted =
                e.total === undefined ? 0 : Math.floor((e.loaded * 100) / e.total);

              onUploadProgress(percentCompleted);
            },
          });
          return data;
        },
      }),
    createTicket: (commCenterTeamMemberId: CommCenterTeamMemberId | null) =>
      createMutation({
        mutationFn: (newTicketRequest: NewTicketRequestBody) => {
          return api.post(
            "/agencies/:agencyId/comm_center_team_members/:commCenterTeamMemberId/comm_center/tickets",
            {
              path: {
                commCenterTeamMemberId: commCenterTeamMemberId ?? CommCenterTeamMemberId.parse(0),
              },
              body: newTicketRequest,
            }
          );
        },
      }),
    callCenter: callCenterMutations(groupParams),
  };
}
