import { Button, Center, chakra, Flex, IconButton } from "@chakra-ui/react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  MiniMap,
  Node,
  NodeChange,
  OnMove,
  ReactFlowProvider,
  Viewport,
} from "reactflow";
import "reactflow/dist/style.css";
import Page from "../../../../shared/components/Page";
import Select from "../../../../shared/components/Select";
import RefreshRoundedIcon from "../../../../shared/icons/RefreshRoundedIcon";
import { CreateNewNoteButton } from "./Components/CreateNewNoteButton";
import ElkNode from "./ElkNode";
import usePatientIntakeFlowSettings from "./hooks/usePatientIntakeFlowSettings";
import usePatientIntakeFlowVisualizer from "./hooks/usePatientIntakeFlowVisualizer";

const nodeTypes = {
  elk: ElkNode,
};

export default function PatientIntakeFlowSettingsVisualizerPage() {
  const { query } = usePatientIntakeFlowSettings();

  if (query.isPending || query.isError) {
    return null;
  }

  return (
    <ReactFlowProvider>
      <InnerPage {...query.data} />
    </ReactFlowProvider>
  );
}

function InnerPage(
  props: NonNullable<ReturnType<typeof usePatientIntakeFlowSettings>["query"]["data"]>
) {
  const {
    reloadGraph,
    savePoints,
    hasPositionChanges,
    trackId,
    changeTrack,
    edges,
    nodes,
    isLoading,
    initialViewport,
    setInitialViewport,
    onChangeNodes,
  } = usePatientIntakeFlowVisualizer();

  const handleMoveEnd = (_: MouseEvent | TouchEvent, viewport: Viewport) => {
    if (trackId === null) {
      return;
    }

    setInitialViewport({
      ...initialViewport,
      [trackId]: {
        zoom: viewport.zoom,
        position: { x: viewport.x, y: viewport.y },
      },
    });
  };

  return (
    <Page>
      <Page.Header>
        <Flex justifyContent="space-between">
          <Page.Title>Flow Settings Visualizer</Page.Title>
        </Flex>
        <Flex alignItems="center" gap={4}>
          <Button isDisabled={!hasPositionChanges} onClick={savePoints}>
            Save
          </Button>
          <CreateNewNoteButton trackId={trackId} />
          <IconButton
            aria-label="Reload Graph"
            icon={<RefreshRoundedIcon h={5} w={5} />}
            isDisabled={isLoading}
            onClick={reloadGraph}
          />
          <Select
            label="Select track"
            multiple={false}
            options={props.tracks.map((track) => ({ value: track.id, label: track.label }))}
            value={trackId}
            onChange={changeTrack}
          />
        </Flex>
      </Page.Header>
      <Page.Content flex={1}>
        {trackId === null ? (
          <Center>No track selected</Center>
        ) : (
          <Visualizer
            edges={edges}
            nodes={nodes}
            onChangeNodes={onChangeNodes}
            onMoveEnd={handleMoveEnd}
          />
        )}
      </Page.Content>
    </Page>
  );
}

const VisualizerRoot = chakra("div", {
  baseStyle: {
    h: "600px",
    w: "full",
  },
});

function Visualizer(props: {
  edges: Edge[];
  nodes: Node[];
  onMoveEnd: OnMove;
  onChangeNodes: (changes: NodeChange[]) => void;
}) {
  return (
    <VisualizerRoot>
      <ReactFlow
        edges={props.edges}
        minZoom={0.1}
        nodes={props.nodes}
        nodeTypes={nodeTypes}
        onMoveEnd={props.onMoveEnd}
        onNodesChange={props.onChangeNodes}
      >
        <Controls />
        <MiniMap />
        <Background gap={12} size={1} variant={BackgroundVariant.Dots} />
      </ReactFlow>
    </VisualizerRoot>
  );
}
