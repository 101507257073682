import { createIcon } from "@chakra-ui/react";

const AccountSupervisorCircleIcon = createIcon({
  displayName: "AccountSupervisorCircleIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12 2C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m3.6 6.34c1.07 0 1.93.86 1.93 1.93c0 1.07-.86 1.93-1.93 1.93a1.93 1.93 0 0 1-1.93-1.93c-.01-1.07.86-1.93 1.93-1.93m-6-1.58c1.3 0 2.36 1.06 2.36 2.36c0 1.3-1.06 2.38-2.36 2.38c-1.3 0-2.36-1.08-2.36-2.38c0-1.31 1.05-2.36 2.36-2.36m0 9.13v3.75c-2.4-.75-4.3-2.6-5.14-4.96C5.5 13.56 8.13 13 9.6 13c.53 0 1.2.07 1.9.21c-1.64.87-1.9 2.02-1.9 2.68M12 20c-.28 0-.54 0-.8-.04v-4.07c0-1.42 2.94-2.13 4.4-2.13c1.07 0 2.9.39 3.84 1.15C18.27 17.88 15.38 20 12 20"
      fill="currentColor"
    />
  ),
});

export default AccountSupervisorCircleIcon;
