import { Flex, Skeleton } from "@chakra-ui/react";
import { CommunicationLogVerticalDivider } from "./CommunicationLog";

export function CommunicationLogSkeleton() {
  return (
    <Flex direction="column" gap={2}>
      <Flex direction="row" gap={2}>
        <Skeleton h={6} rounded="md" w={28} />
        <Skeleton h={6} rounded="md" w={60} />
      </Flex>
      <Flex direction="row" gap={2}>
        <Skeleton h={6} rounded="md" w={5} />
        <Skeleton h={6} rounded="md" w={28} />
        <CommunicationLogVerticalDivider />
        <Skeleton h={6} rounded="md" w={60} />
      </Flex>
      <Skeleton h={6} rounded="md" w={600} />
    </Flex>
  );
}
