import { InputRightElement, Tag } from "@chakra-ui/react";
import { PatientId } from "../../../shared/schema/schema";
import { useSmsSender } from "../../../shared/hooks/useSmsSender";

export default function IntakeMobileRightAddon(props: {
  patientId: PatientId;
  phoneNumber: string;
}) {
  const { state, onOpen, onClose } = useSmsSender();

  const handleClick = () => {
    if (state === null) {
      onOpen({
        entity: "Patient",
        patientId: props.patientId,
        predefinedPhoneNumber: props.phoneNumber,
      });
    } else {
      onClose();
    }
  };

  return (
    <InputRightElement cursor="pointer" p={2} width="fit-content" onClick={handleClick}>
      <Tag backgroundColor="blue.600" color="white">
        Mobile
      </Tag>
    </InputRightElement>
  );
}
