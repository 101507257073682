import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import useUploadFiles, { FileEntry, S3Object } from "../hooks/useUploadFiles";
import { humanFileSize } from "../utils/common";
import Dropzone from "./DropZone";
import { InputFileUploadDestination } from "./FileInput";

type Props = DropzoneOptions & {
  isOpen: boolean;
  files: S3Object[];
  fileUploadDestination: InputFileUploadDestination;
  portalProps?: ModalProps["portalProps"];
  onClose: () => void;
  onChange: (files: S3Object[]) => void;
};

export default function UploadModal(props: Props) {
  const { isOpen, onClose, ...options } = props;

  const [initialUploads] = React.useState(props.files);

  const { uploads, attachFiles, unAttach, isUploading, upload, setUploads } = useUploadFiles({
    multiple: props.multiple,
    maxFiles: props.maxFiles,
    maxSize: props.maxSize,
    fileUploadDestination: props.fileUploadDestination,
    uploads: initialUploads,
  });
  const state = useDropzone({
    ...options,
    onDrop: React.useCallback(
      (acceptedFiles: File[]) => {
        const uploads = attachFiles(acceptedFiles);

        if (uploads === undefined) {
          return;
        }

        upload(uploads);
      },
      [attachFiles, upload]
    ),
  });

  React.useEffect(() => {
    if (isOpen) {
      setUploads(initialUploads);
    }
  }, [initialUploads, isOpen, setUploads]);

  const handleSelectFiles = () => {
    const files = uploads.flatMap((upload) => (upload.type === "file" ? [] : [upload]));
    props.onChange(files);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} portalProps={props.portalProps} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select file{props.multiple ? "s" : ""}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={4}>
            <Dropzone options={options} state={state} />
            {uploads.length > 0 && (
              <Flex direction="column" gap={2}>
                {uploads.map((upload) => {
                  switch (upload.type) {
                    case "file":
                      return <FileRow key={upload.id} upload={upload} onRemove={unAttach} />;
                    case "s3-object":
                      return <FileRow key={upload.key} upload={upload} onRemove={unAttach} />;
                  }
                })}
              </Flex>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" isDisabled={isUploading} onClick={handleSelectFiles}>
              Select file{props.multiple ? "s" : ""}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function FileRow(props: { upload: FileEntry; onRemove: (file: S3Object) => void }) {
  const { upload } = props;

  const handleRemove = () => {
    if (upload.type === "file") {
      return;
    }
    props.onRemove(upload);
  };

  return (
    <Flex
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      direction="column"
      gap={2}
      justifyContent="space-between"
      p={2}
    >
      <Flex justifyContent="space-between">
        <Flex gap={1}>
          <Text>{upload.file.name}</Text>
          <Text color="gray.400">{humanFileSize(upload.file.size)}</Text>
        </Flex>
        {upload.type === "file" ? (
          <CircularProgress size="24px" value={upload.percent} />
        ) : (
          <IconButton
            aria-label="Remove file"
            icon={<CloseIcon />}
            size="xs"
            onClick={handleRemove}
          />
        )}
      </Flex>
    </Flex>
  );
}
