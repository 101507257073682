import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../../../shared/hooks/useApi";
import { PatientId } from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { letterToDigit } from "./helpers/call-ticket-utils";

interface Props {
  patientId: PatientId;
  onClickMultipleDigits: (digit: string, delayMs?: number) => void;
}

const DialerPatientNYIAActions = (props: Props) => {
  const { queries } = useApi();
  const patientDetails = useQuery(queries.patient.get(props.patientId));

  const disclosure = useDisclosure();

  const handleClickEnterDetails = (data: string) => {
    const toDial = data
      .split("")
      .map((letter) => letterToDigit(letter))
      .join("");
    props.onClickMultipleDigits(toDial, 100);
  };

  const zip = patientDetails.data?.address?.components.formatedAddressDetails?.zip5;
  const medicaidNumber = patientDetails.data?.medicaidNumber;
  const dateOfBirth = patientDetails.data?.dateOfBirth;
  const dateOfBirthFormatted = fmap(dateOfBirth, (x) => dateFormatter.toDate(x));

  return (
    <Popover {...disclosure}>
      <PopoverTrigger>
        <Button
          colorScheme="blue"
          isDisabled={
            patientDetails.isError ||
            (!patientDetails.isLoading && patientDetails.data === undefined)
          }
          isLoading={patientDetails.isLoading}
          onClick={disclosure.onToggle}
        >
          Enter NIYA Details
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody as={Flex} flexDirection="column" gap={2}>
          <Button
            isDisabled={!dateOfBirth}
            onClick={() => handleClickEnterDetails(dateOfBirthFormatted?.replaceAll("/", "") ?? "")}
          >
            Enter DOB: {dateOfBirthFormatted ?? "None"}
          </Button>
          <Button
            isDisabled={!medicaidNumber}
            onClick={() => handleClickEnterDetails(medicaidNumber ?? "")}
          >
            Enter MedicaidId: {medicaidNumber ?? "None"}
          </Button>
          <Button isDisabled={!zip} onClick={() => handleClickEnterDetails(zip ?? "")}>
            Enter zipcode: {zip ?? "None"}
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
export default DialerPatientNYIAActions;
