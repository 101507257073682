import { queryOptions } from "@tanstack/react-query";
import { QueryParamsOf } from "../../../core/api";
import { createQueryGroup } from "../../../core/api/queries";

export default createQueryGroup(({ api }) => ({
  cicoIssuesVisits: (query: QueryParamsOf<"get", "./visits_with_cico_issues">) =>
    queryOptions({
      queryKey: ["reports", "cico-issues", query],
      queryFn: () => api.get("./visits_with_cico_issues", { query }),
    }),
}));
