import { FlexProps, Flex } from "@chakra-ui/react";

export default function SidebarSteps(props: FlexProps) {
  return (
    <Flex
      className="thin-scrollbar"
      direction="column"
      gap={2}
      overflow="auto"
      px={2}
      py={1}
      {...props}
    />
  );
}
