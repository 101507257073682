import { LocalDate, nativeJs } from "@js-joda/core";

//! @ngInject
export function newPatientSurplusInvoiceModalCtrl(
  $scope,
  DatabaseApi,
  patientService,
  PatientModalService,
  $filter,
  $uibModalInstance,
  toaster,
  patientId
) {
  $scope.patientId = patientId;
  $scope.isLoading=false;
  // $scope.patientPhysicians = patientPhysicians;

  function initialize(patientId) {
    $scope.form = {};
    $scope.surplusInvoiceCreateParams = {
      datesArr: [],
      patientId: 1*patientId
    };
  }
  $scope.onDateRangeChanged = function(startDate, endDate){
    const datesArr=[]
    const fromDate = LocalDate.from(nativeJs(startDate)).withDayOfMonth(1);
    datesArr.push(fromDate)
    const toDate = LocalDate.from(nativeJs(endDate)).withDayOfMonth(1);
    let currentDate = LocalDate.from(nativeJs(startDate));

    while(currentDate < toDate){
      currentDate= currentDate.plusMonths(1)
      datesArr.push(currentDate)

    }
    $scope.surplusInvoiceCreateParams.datesArr = datesArr;
  }

  $scope.save = function () {
    if ($scope.form.$invalid) {
      return;
    }
    $scope.isLoading = true;
    console.log($scope.surplusInvoiceCreateParams);
    // $scope.surplus.fromDate = LocalDate.from(nativeJs($scope.surplus.fromDate));
    // if($scope.surplus.toDate !== undefined && $scope.surplus.toDate !== null){
    //   $scope.surplus.toDate = LocalDate.from(nativeJs($scope.surplus.toDate));
    // }
    patientService
      .createPatientSurplusInvoice(1 * $scope.patientId, $scope.surplusInvoiceCreateParams)
      .then((res) => {
        toaster.pop("success", "surplus invoice created");
        
        // toaster.pop("success",res);
        console.log(res);
        $uibModalInstance.close(JSON.stringify(res));
      })
      .catch((err) => {
        console.error(err);
        toaster.pop("error", "Something went wrong");
        toaster.pop("error",err)
      })
      .finally(() => ($scope.isLoading = false));
  };

  $scope.closeModal = function () {
    $uibModalInstance.close("ok");
  };

  initialize(patientId);
}
