import { createIcon } from "@chakra-ui/react";

const WorkflowsPlayIcon = createIcon({
  displayName: "WorkflowsPlayIcon",
  viewBox: "0 0 32 32",
  path: (
    <path
      d="M0 3.5C0 1.568 1.568 0 3.5 0H10.5C12.432 0 14 1.568 14 3.5V10.5C14 11.4283 13.6313 12.3185 12.9749 12.9749C12.3185 13.6313 11.4283 14 10.5 14H8V22C8 22.5304 8.21071 23.0391 8.58579 23.4142C8.96086 23.7893 9.46957 24 10 24H15.0145C15.0049 23.8346 15 23.6678 15 23.5C15 18.8056 18.8056 15 23.5 15C28.1944 15 32 18.8056 32 23.5C32 28.1944 28.1944 32 23.5 32C20.0533 32 17.0857 29.9485 15.7517 27H10C8.67392 27 7.40215 26.4732 6.46447 25.5355C5.52678 24.5979 5 23.3261 5 22V14H3.5C2.57174 14 1.6815 13.6313 1.02513 12.9749C0.368749 12.3185 0 11.4283 0 10.5V3.5ZM3.5 3C3.36739 3 3.24021 3.05268 3.14645 3.14645C3.05268 3.24021 3 3.36739 3 3.5V10.5C3 10.776 3.224 11 3.5 11H10.5C10.6326 11 10.7598 10.9473 10.8536 10.8536C10.9473 10.7598 11 10.6326 11 10.5V3.5C11 3.36739 10.9473 3.24021 10.8536 3.14645C10.7598 3.05268 10.6326 3 10.5 3H3.5ZM23.5 29C26.5376 29 29 26.5376 29 23.5C29 20.4624 26.5376 18 23.5 18C20.4624 18 18 20.4624 18 23.5C18 26.5376 20.4624 29 23.5 29ZM22.402 26.3952L26.2255 23.9824C26.4085 23.8666 26.5 23.7057 26.5 23.4998C26.5 23.2939 26.4085 23.1331 26.2255 23.0172L22.402 20.6045C22.2059 20.4758 22.0064 20.466 21.8035 20.5751C21.6007 20.6842 21.4995 20.8549 21.5 21.087V25.9126C21.5 26.1442 21.6014 26.3149 21.8043 26.4245C22.0072 26.5341 22.2064 26.5244 22.402 26.3952Z"
      fill="currentColor"
    />
  ),
});

export default WorkflowsPlayIcon;
