import { createIcon } from "@chakra-ui/react";

const AccountArrowRightIcon = createIcon({
  displayName: "AccountArrowRightIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M18 16h-4v2h4v2l3-3l-3-3v2M11 4C8.8 4 7 5.8 7 8s1.8 4 4 4s4-1.8 4-4s-1.8-4-4-4m0 10c-4.4 0-8 1.8-8 4v2h9.5c-.3-.8-.5-1.6-.5-2.5c0-1.2.3-2.3.9-3.4c-.6 0-1.2-.1-1.9-.1"
      fill="currentColor"
    />
  ),
});

export default AccountArrowRightIcon;
