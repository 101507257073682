//! @ngInject
export function approveVisitInstanceUnassignModalCtrl(
    $scope,
    $uibModalInstance,
    noteConsts,
    entityNoteService,
) {
    $scope.visitInstanceId = $scope.$resolve.visitInstanceId;
    $scope.caregiverId = $scope.$resolve.caregiverId;
    $scope.patientId = $scope.$resolve.patientId;
    $scope.approvalNote = {};
    $scope.unassignNote = {};
    $scope.isManualRemoveVisitNoteRequired = true;
    // FOR THE APPROVAL NOTE, WE HAVE requestNoteText, indicates 'billing/paid/payroll draft';

    $scope.approveForm = () => {

        const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
            $scope.unassignNote, noteConsts.NoteTypes.VISIT_INSTANCE_CAREGIVER_UNASSIGN
        );
        if (!isNoteValid) {
            $scope.removedVisitNoteValidations = { isPredefinedValid, isMessageValid };
            return;
        }

        if ($scope.$resolve.requestNoteText) {
            const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote(
                $scope.approvalNote, 
                noteConsts.NoteTypes.ADJUSTMENT_APPROVAL
            );
            if (!isNoteValid) {
                $scope.approvalNoteValidations = { isPredefinedValid, isMessageValid };
                return;
            }
        }

        $scope.unassignNote = entityNoteService.buildEntityNoteRequest(
            $scope.unassignNote,noteConsts.NoteTypes.VISIT_INSTANCE_CAREGIVER_UNASSIGN,
        );

        $scope.unassignNote.caregiverId = $scope.caregiverId;
        $scope.unassignNote.patientId = $scope.patientId;

        if ($scope.visitInstanceId) {
            $scope.unassignNote.visitInstanceId = $scope.visitInstanceId;
        }

        if ($scope.$resolve.requestNoteText) {
            $scope.approvalNote = entityNoteService.buildEntityNoteRequest(
                $scope.approvalNote,
                noteConsts.NoteTypes.ADJUSTMENT_APPROVAL,
            );
        }
        $uibModalInstance.close($scope.unassignNote);
    }

    $scope.handleNoteChanges = (noteModel, updatedNote) => {
        $scope[noteModel] = updatedNote;
    };

    $scope.closeModal = function (data) {
        $uibModalInstance.close(data);
    };
};