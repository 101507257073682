import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Skeleton,
} from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import SingleDatePicker from "../../../shared/components/DatePicker/SingleDatePicker";
import FormControlSkeleton from "../../../shared/components/FormControlSkeleton";
import Editor from "../../../shared/components/Lexical/LexicalEditor";
import { getTextContentOfRichText } from "../../../shared/components/Lexical/editor-utils";
import Select from "../../../shared/components/Select";
import { ControlledForm } from "../../../shared/hooks/useControlledForm";
import { fmap } from "../../../shared/utils";
import EntityFormControl from "../../workflow/components/EntityFormControl";
import { zEntityNoteForm } from "../utils/note-form";
import NotePredefinedAnswerSelect from "./NotePredefinedAnswerSelect";

type Props = {
  noteTypes: Messages["NoteTypes"][];
  settings: Messages["EntityNoteSetting"];
  form: ControlledForm<ReturnType<typeof zEntityNoteForm>>;
  isReasonDisabled: boolean;
};

export default function EntityNoteFormControls({
  noteTypes,
  settings,
  form,
  isReasonDisabled,
}: Props) {
  const [isExpanded, setExpanded] = React.useState(false);

  const shouldShowReasonSelect = settings.hasPredefinedField && (isExpanded || !isReasonDisabled);

  return (
    <Flex direction="column" gap={4}>
      {shouldShowReasonSelect && (
        <FormControl
          isInvalid={form.isInvalid("notePredefinedAnswerId")}
          isRequired={settings.isPredefinedMandatory}
        >
          <FormLabel>Reason</FormLabel>
          <NotePredefinedAnswerSelect
            buttonProps={{ isDisabled: isReasonDisabled && isExpanded }}
            noteTypes={noteTypes}
            value={form.state.notePredefinedAnswerId ?? null}
            onChange={(x) => form.setValue("notePredefinedAnswerId", x ?? null)}
          />
          <FormErrorMessage>{form.getError("notePredefinedAnswerId")}</FormErrorMessage>
        </FormControl>
      )}

      {isExpanded ? (
        <>
          <FormControl
            isInvalid={form.isInvalid("richText")}
            isRequired={settings.isMessageMandatory}
          >
            <FormLabel>Message</FormLabel>
            <Editor
              format="html"
              initialValue={form.state.richText?.output}
              onChange={(x) => form.setValue("richText", x)}
            />
            <FormErrorMessage>{form.getError("richText")}</FormErrorMessage>
          </FormControl>

          <EntityFormControl
            errors={form.errors.agencyMemberIds ?? []}
            input={{ type: "entity", entity: "Agency Member" }}
            isRequired={false}
            label="Agency Member/s"
            multiple={true}
            value={form.state.agencyMemberIds ?? null}
            onChange={(x) => form.setValue("agencyMemberIds", x ?? undefined)}
          />

          <EntityFormControl
            errors={form.errors.commCenterTicketId ?? []}
            input={{ type: "entity", entity: "Comm Center Ticket" }}
            isRequired={false}
            label="Comm Center Ticket"
            multiple={false}
            value={form.state.commCenterTicketId ?? null}
            onChange={(x) => form.setValue("commCenterTicketId", x ?? undefined)}
          />

          <Flex gap={4} w="full">
            <FormControl isInvalid={form.isInvalid("followUpDate")}>
              <FormLabel>Follow-up</FormLabel>
              <SingleDatePicker
                selected={form.state.followUpDate}
                onChange={(x) => form.setValue("followUpDate", x)}
              />
              <FormErrorMessage>{form.getError("followUpDate")}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={form.isInvalid("status")}>
              <FormLabel>Status</FormLabel>
              <Select
                buttonProps={{ w: "full" }}
                label="Choose a status"
                multiple={false}
                options={
                  [
                    { value: "NONE", label: "None" },
                    { value: "VIEWED", label: "Viewed" },
                    { value: "IN_PROGRESS", label: "In progress" },
                    { value: "DONE", label: "Done" },
                  ] as const
                }
                value={form.state.status ?? null}
                onChange={(x) => form.setValue("status", x ?? null)}
              />
              <FormErrorMessage>{form.getError("status")}</FormErrorMessage>
            </FormControl>
          </Flex>

          <Box>
            <Button
              rightIcon={<ChevronDownIcon />}
              variant="link"
              onClick={() => {
                setExpanded(false);
                const plainOutput = fmap(form.state.richText, getTextContentOfRichText);
                form.setValue("richText", { mentions: [], output: plainOutput ?? "" });
              }}
            >
              Collapse Note
            </Button>
          </Box>
        </>
      ) : (
        <>
          <FormControl
            isInvalid={form.isInvalid("richText")}
            isRequired={settings.isMessageMandatory}
          >
            <FormLabel>Message</FormLabel>
            <Input
              placeholder={settings.isMessageMandatory ? "Message" : "Optional..."}
              value={form.state.richText?.output ?? ""}
              onChange={(e) => form.setValue("richText", { mentions: [], output: e.target.value })}
            />
            <FormErrorMessage>{form.getError("richText")}</FormErrorMessage>
          </FormControl>
          <Box>
            <Button
              colorScheme="blue"
              rightIcon={<ChevronUpIcon />}
              variant="link"
              onClick={() => setExpanded(true)}
            >
              Expand Note
            </Button>
          </Box>
        </>
      )}
    </Flex>
  );
}

function EntityNoteFormControlsSkeleton() {
  return (
    <Flex direction="column" gap={4}>
      <FormControlSkeleton />
      <Skeleton h={6} rounded="full" w={28} />
    </Flex>
  );
}

EntityNoteFormControls.Skeleton = EntityNoteFormControlsSkeleton;
