import { EditIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Skeleton,
  Tag,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Messages, ResponseOf } from "../../../core/api";
import { Entity, EntityCardLink } from "../../../shared/components/EntityCard";
import Select from "../../../shared/components/Select";
import { createFilters } from "../../../shared/hooks/useFilters";
import AddIcon from "../../../shared/icons/AddIcon";
import AddRoundedIcon from "../../../shared/icons/AddRoundedIcon";
import MarkUnreadIcon from "../../../shared/icons/MarkUnreadIcon";
import {
  CaregiverId,
  CommCenterLabelId,
  CommCenterTeamId,
  CommCenterTeamMemberId,
  CommCenterTicketId,
  PatientId,
  VisitBroadcastId,
} from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { isOneOfUnion } from "../../../shared/utils/common";
import { Loadable } from "../../../shared/utils/loadable";
import { isDefined } from "../../../utils";
import useCallCenter from "../../call-center/hooks/useCallCenter";
import CaregiverSelect from "../../caregiver/components/CaregiverSelect";
import NewNoteModal from "../../note/components/NewNoteModal";
import PatientClinicalSeverityLevelTag from "../../patient/components/PatientClinicalSeverityLevelTag";
import PatientSelect from "../../patient/components/PatientSelect";
import { CommCenterTicket } from "../communication.types";
import { TicketSummaryDisclosureState } from "../hooks/useTicketSummaryDisclosure";
import {
  CommCenterRelatedVisit,
  CommCenterTicketStatus,
  NewTicketRequestBody,
  getRelatedVisitFromTicket,
  getTextFormatForRelatedVisit,
} from "../utils/communication-utils";
import ActiveTicket from "./ActiveTicket";
import NewCommCenterTicket from "./NewCommCenterTicket";
import { TicketEntitySelectModalTrigger } from "./TicketEntitySelectModal/TicketEntitySelectModal";
import TicketsList from "./TicketsList";
import useEditTicket from "../pages/CommunicationCenterTicket/mutateEditTicket";

interface Props {
  label: string | null;
  primaryEntity: Loadable<Entity | null>;
  secondaryEntity?: Loadable<Entity | undefined>;
  defaultMessage?: string;
  defaultLabel?: Messages["CommCenterLabel"];
  defaultVisitBroadcastId?: VisitBroadcastId;
  settings: {
    assignedToId: CommCenterTeamMemberId | null;
    teamId: CommCenterTeamId;
    status: CommCenterTicketStatus;
  };
  tickets: Loadable<CommCenterTicket[]>;
  teams: Messages["CommCenterTeamWithMembers"][];
  activeTicket: CommCenterTicket | null;
  onboardingStageName: string | null;
  labels: Messages["CommCenterLabel"][];
  initialLabelId: CommCenterLabelId | null;
  isNewTicketOpen: boolean;
  areActionsDisabled: boolean;
  attachments: File[];
  entityCardAs?: React.ElementType<{ entity: Entity }>;
  goBackButton?: React.ReactElement;
  onCreateNewTicket: (newTicket: NewTicketRequestBody) => void;
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
  onClickMarkAsUnread: (ticketId: CommCenterTicketId) => void;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
  onSubmitNewMessage: (ticketId: CommCenterTicketId, message: string) => void;
  onClickNewTicket: () => void;
  onRequestCloseNewTicket: () => void;
  onSelectFile: (newFile: File) => void;
  onClickRemoveAttachment: (attachment: File) => void;
  onClickShowSummary: (params: TicketSummaryDisclosureState) => void;
  onClickDial: (phoneNumber: string) => void;
}

function shouldShowCaregiverSelect(
  ticket: Messages["CommCenterTicket"] | null,
  caregiverId: CaregiverId | null,
  patientId: PatientId | null
) {
  if (ticket === null) {
    return false;
  }

  if (ticket.topic === "Patient") {
    return true;
  }

  if (["PhonebookContact", "NotIdentifiedEmailEntity"].includes(ticket.topic)) {
    return (patientId === null && caregiverId === null) || caregiverId !== null;
  }

  return false;
}

function shouldShowPatientSelect(
  ticket: Messages["CommCenterTicket"] | null,
  caregiverId: CaregiverId | null,
  patientId: PatientId | null
) {
  if (ticket === null) {
    return false;
  }

  if (ticket.topic === "Caregiver") {
    return true;
  }

  if (["PhonebookContact", "NotIdentifiedEmailEntity"].includes(ticket.topic)) {
    return (patientId === null && caregiverId === null) || patientId !== null;
  }

  return false;
}

function shouldShowEditTicketEntitiesSelect(
  ticket: Messages["CommCenterTicket"] | null
): ticket is Messages["CommCenterTicket"] {
  if (ticket === null) {
    return false;
  }
  return ["PHONE_CALL", "SMS_CONVERSATION"].includes(ticket.source);
}

const TicketsBox = (props: Props) => {
  const callCenter = useCallCenter();
  const { createSelectFilter } = createFilters<Messages["Partial<EditCommCenterTicketParams>"]>();
  const newNoteDisclosure = useDisclosure();
  const editTicket = useEditTicket();

  const handleChange = <
    $Name extends keyof Messages["Partial<EditCommCenterTicketParams>"],
    $Option extends NonNullable<Messages["Partial<EditCommCenterTicketParams>"][$Name]>
  >(
    name: $Name,
    option: $Option | undefined
  ) => {
    if (props.activeTicket !== null) {
      editTicket.mutate({ id: props.activeTicket.id, body: { [name]: option } });
    }
  };

  const activeTeam =
    props.teams.find((team) => team.id === props.settings.teamId) ?? props.teams.at(0);

  const assigneeFilter = createSelectFilter({
    label: "Assignee",
    name: "assignedToId",
    options:
      activeTeam === undefined
        ? []
        : activeTeam.members.map((member) => ({
            label: [member.firstName, member.lastName].join(" "),
            value: member.id,
          })),
    value: props.settings.assignedToId,
    disabled: props.areActionsDisabled,
    onChange: handleChange,
  });

  const teamFilter = createSelectFilter({
    label: "Team",
    name: "teamId",
    options: props.teams.map((team) => ({ label: team.name, value: team.id })),
    value: props.settings.teamId,
    disabled: props.areActionsDisabled,
    onChange: handleChange,
  });

  const statusFilter = createSelectFilter({
    name: "status",
    label: "Status",
    options: [
      { label: "New", value: "NEW" },
      { label: "In progress", value: "IN PROGRESS" },
      { label: "Resolved", value: "RESOLVED" },
    ],
    value: props.settings.status,
    disabled: props.areActionsDisabled,
    onChange: handleChange,
  });

  const handleClickMarkAsUnread = () => {
    if (props.activeTicket !== null) {
      props.onClickMarkAsUnread(props.activeTicket.id);
    }
  };

  const handleCreateNewTicket = (newTicket: NewTicketRequestBody) => {
    props.onCreateNewTicket(newTicket);
    props.onRequestCloseNewTicket();
  };

  const handleClickTicket = (ticketId: CommCenterTicketId) => {
    props.onClickTicket(ticketId);
    props.onRequestCloseNewTicket();
  };

  const toast = useToast();

  const handleClickCallBack = () => {
    if (props.activeTicket === null || props.activeTicket.callInfo === null) {
      toast({
        description: "This ticket does not have an associated call",
        status: "error",
        position: "top-right",
      });
      return;
    }

    const { direction, participants } = props.activeTicket.callInfo;

    let source: string | undefined = undefined;

    switch (direction) {
      case "Inbound":
        source = participants.find((x) => x.role === "Caller")?.source;
        break;
      case "Outbound":
        source = participants.find((x) => x.role === "Callee")?.source;
        break;
    }

    if (source === undefined) {
      toast({
        description: "This ticket does not have a phone number",
        status: "error",
        position: "top-right",
      });
      return;
    }

    props.onClickDial(source);
  };

  const handleEntityChange = (
    entityChange: Partial<{ caregiverId: CaregiverId | null; patientId: PatientId | null }>
  ) => {
    if (props.activeTicket !== null) {
      editTicket.mutate({ id: props.activeTicket.id, body: entityChange });
    }
  };

  const entityMatch = (() => {
    switch (props.primaryEntity?.type) {
      case "Loading":
        return <Skeleton height={10} width={72} />;
      case "Resolved":
        return props.primaryEntity.value === null ? null : (
          <TicketsBox.Entity as={props.entityCardAs} entity={props.primaryEntity.value} />
        );
      case "Rejected":
        return <>Failed to load entity</>;
      default:
        throw new Error("Invalid primaryEntity type");
    }
  })();

  const defaultCaregiverId =
    props.primaryEntity?.type === "Resolved" && props.primaryEntity.value?.type === "Caregiver"
      ? props.primaryEntity.value.id
      : props.secondaryEntity?.type === "Resolved" &&
        props.secondaryEntity.value?.type === "Caregiver"
      ? props.secondaryEntity?.value.id
      : null;

  const defaultPatientId =
    props.primaryEntity?.type === "Resolved" && props.primaryEntity.value?.type === "Patient"
      ? props.primaryEntity.value.id
      : props.secondaryEntity?.type === "Resolved" &&
        props.secondaryEntity.value?.type === "Patient"
      ? props.secondaryEntity.value.id
      : null;

  const defaultTopic =
    props.primaryEntity?.type === "Resolved" &&
    (props.primaryEntity.value?.type === "Caregiver" ||
      props.primaryEntity.value?.type === "Patient")
      ? props.primaryEntity.value.type
      : "Caregiver";

  const showCaregiverSelect = shouldShowCaregiverSelect(
    props.activeTicket,
    defaultCaregiverId,
    defaultPatientId
  );
  const showPatientSelect = shouldShowPatientSelect(
    props.activeTicket,
    defaultCaregiverId,
    defaultPatientId
  );

  const caregiverSelectProps = {
    value: showCaregiverSelect ? defaultCaregiverId : null,
    isDisabled: props.areActionsDisabled || props.activeTicket?.topic === "Caregiver",
  };

  const patientSelectProps = {
    value: showPatientSelect ? defaultPatientId : null,
    isDisabled: props.areActionsDisabled || props.activeTicket?.topic === "Patient",
  };

  const noteInitialValues = React.useMemo(() => {
    return {
      ...(defaultCaregiverId && { caregiverId: defaultCaregiverId }),
      ...(defaultPatientId && { patientId: defaultPatientId }),
      ...(props.activeTicket?.id && { commCenterTicketId: props.activeTicket.id }),
    };
  }, [props.activeTicket, defaultCaregiverId, defaultPatientId]);

  const relatedVisit = getRelatedVisitFromTicket(props.activeTicket);

  const shouldHideCallButton = (): boolean => {
    if (props.activeTicket === null || props.activeTicket.callInfo === null) {
      return true;
    }

    return isOneOfUnion(callCenter.state.status, ["Active", "Ringing", "Calling"]);
  };

  return (
    <TicketsBox.Root>
      <TicketsBox.Header>
        {props.goBackButton}
        {entityMatch}
        {props.label && <TicketsBox.LabelName>{props.label}</TicketsBox.LabelName>}
        {props.onboardingStageName !== null && (
          <TicketsBox.StageName colorScheme="purple">
            {props.onboardingStageName}
          </TicketsBox.StageName>
        )}
        {defaultPatientId !== null &&
          isDefined(props.activeTicket) &&
          isDefined(props.activeTicket.relatedPatient) && (
            <PatientClinicalSeverityLevelTag
              clinicalSeverityLevel={props.activeTicket.relatedPatient.clinicalSeverityLevel}
            />
          )}
        <TicketsBox.Settings>
          <TicketsBox.Actions>
            <Button
              colorScheme="blue"
              disabled={props.areActionsDisabled}
              hidden={shouldHideCallButton()}
              leftIcon={<PhoneIcon />}
              variant="outline"
              onClick={handleClickCallBack}
            >
              Call Back
            </Button>
            <Button
              disabled={props.areActionsDisabled}
              leftIcon={<AddIcon />}
              variant="outline"
              onClick={newNoteDisclosure.onOpen}
            >
              Add note
            </Button>
            <Button
              disabled={props.areActionsDisabled}
              leftIcon={<MarkUnreadIcon />}
              variant="outline"
              onClick={handleClickMarkAsUnread}
            >
              Mark as unread
            </Button>
          </TicketsBox.Actions>
          {showCaregiverSelect && (
            <Box>
              <CaregiverSelect
                isDisabled={caregiverSelectProps.isDisabled}
                value={caregiverSelectProps.value}
                onChange={(value) =>
                  handleEntityChange({ caregiverId: value === null ? null : value.id })
                }
              />
            </Box>
          )}
          {showPatientSelect && (
            <Box>
              <PatientSelect
                isDisabled={patientSelectProps.isDisabled}
                value={patientSelectProps.value}
                onChange={(value) =>
                  handleEntityChange({ patientId: value === null ? null : value.id })
                }
              />
            </Box>
          )}
          <Select {...assigneeFilter} />
          <Select {...teamFilter} />
          <Select {...statusFilter} />
          <Button
            colorScheme="blue"
            disabled={props.isNewTicketOpen}
            leftIcon={<AddRoundedIcon />}
            onClick={props.onClickNewTicket}
          >
            New
          </Button>
          {shouldShowEditTicketEntitiesSelect(props.activeTicket) && (
            <TicketEntitySelectModalTrigger ticketId={props.activeTicket.id}>
              <IconButton
                aria-label="edit"
                colorScheme="blue"
                icon={<EditIcon />}
                variant="solid"
              />
            </TicketEntitySelectModalTrigger>
          )}
        </TicketsBox.Settings>
        {fmap(relatedVisit, (visit) => (
          <TicketsBox.Settings>
            <TicketsBox.RelatedVisit {...visit} />
          </TicketsBox.Settings>
        ))}
      </TicketsBox.Header>

      <Divider />

      <TicketsBox.Body flex={1}>
        <Box flexShrink={0} height="var(--max-chat-height)" overflow="auto" w={375}>
          <TicketsList
            selectedTicketId={props.activeTicket?.id}
            tickets={props.tickets}
            onClickTicket={handleClickTicket}
          />
        </Box>

        {props.isNewTicketOpen ? (
          <Flex justifyContent="center" overflowY="auto" w="100%">
            <NewCommCenterTicket
              defaultTicketType="Chat"
              defaultValues={{
                caregiverId: defaultCaregiverId,
                patientId: defaultPatientId,
                topic: defaultTopic,
                message: props.defaultMessage,
                label: props.defaultLabel,
                visitBroadcastId: props.defaultVisitBroadcastId,
              }}
              initialLabelId={props.initialLabelId}
              labels={props.labels}
              teams={props.teams}
              onCreateTicket={handleCreateNewTicket}
              onSuccessCreateEmailTicket={props.onSuccessCreateEmailTicket}
            />
          </Flex>
        ) : (
          <Box flex={1}>
            {fmap(props.activeTicket, (ticket) => (
              <ActiveTicket
                attachments={props.attachments}
                ticket={ticket}
                onClickRemoveAttachment={props.onClickRemoveAttachment}
                onSelectFile={props.onSelectFile}
                onSubmitNewMessage={(message) => props.onSubmitNewMessage(ticket.id, message)}
                onSuccessCreateEmailTicket={props.onSuccessCreateEmailTicket}
              />
            ))}
          </Box>
        )}
      </TicketsBox.Body>
      <NewNoteModal disclosure={newNoteDisclosure} initialValues={noteInitialValues} />
    </TicketsBox.Root>
  );
};

TicketsBox.Root = chakra(Flex, {
  baseStyle: {
    flexDirection: "column",
    flex: 1,
  },
});

TicketsBox.Header = chakra(Flex, {
  baseStyle: {
    alignItems: "center",
    gap: 4,
    p: 4,
    flexWrap: "wrap",
  },
});

// eslint-disable-next-line react/display-name
TicketsBox.Entity = (props: { entity: Entity; as?: React.ElementType<{ entity: Entity }> }) => {
  const EntityComponent = props.as ?? EntityCardLink;

  return (
    <Heading as="h3" size="md">
      <EntityComponent {...props} />
    </Heading>
  );
};

const RelatedVisit = (props: CommCenterRelatedVisit) => {
  const handleClick = () => {
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: { type: "navigate", payload: { id: props.id, entity: props.type } },
      })
    );
  };

  return (
    <TicketsBox.ExternalEntityLabel colorScheme="blue" onClick={handleClick}>
      {getTextFormatForRelatedVisit(props)}
    </TicketsBox.ExternalEntityLabel>
  );
};
TicketsBox.RelatedVisit = RelatedVisit;

TicketsBox.LabelName = Tag;
TicketsBox.StageName = Tag;
TicketsBox.ExternalEntityLabel = chakra(Tag, { baseStyle: { cursor: "pointer", padding: "5px" } });
TicketsBox.Settings = chakra(Flex, { baseStyle: { flex: 1, gap: 2, justifyContent: "flex-end" } });
TicketsBox.Actions = chakra(Box, { baseStyle: { display: "flex", gap: 2, flexDirection: "row" } });
TicketsBox.Body = chakra(Flex, {
  baseStyle: {
    maxH: "var(--max-chat-height)",
    "> * + *": {
      borderLeft: "1px solid var(--chakra-colors-gray-100)",
    },
  },
});

export default TicketsBox;
