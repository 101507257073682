import { Card, CardBody } from "@chakra-ui/react";

export function CardButton(props: {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}) {
  if (props.onClick === undefined) {
    return (
      <Card variant="outline">
        <CardBody>{props.children}</CardBody>
      </Card>
    );
  }

  return (
    <Card
      _hover={{
        bgColor: "gray.50",
      }}
      cursor="pointer"
      role="button"
      variant="outline"
      onClick={props.onClick}
    >
      <CardBody>{props.children}</CardBody>
    </Card>
  );
}
