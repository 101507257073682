import { TaskTemplateContext } from "../messages/patient_task";


//! @ngInject
export function patientDocumentSettingsCtrl($scope, $rootScope, DatabaseApi, toaster, NgTableParams, $state, SweetAlert, $window, $uibModal) {
  $scope.planOfCares = [];
  $scope.patientDocumentTypesTemplates = [];
  $scope.patientDocumentTypesTemplatesToCopy = [];
  $scope.selectedDocumentToCopy = { val: "" };
  $scope.caregiversMap = DatabaseApi.caregivers();
  $scope.offices = DatabaseApi.offices();
  $scope.loadedCaregiver;
  $scope.selectedCaregiver = { id: undefined };
  $scope.planOfCares = DatabaseApi.plansOfCare();
  $scope.defaultPocSignaturePerson = DatabaseApi.defaultPocSignaturePerson;

  const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
  $scope.certifications = activeAgencyCertifications
      .map((certificationItem, index) => ({
          id: index,
          label: certificationItem.certification
      }));

  function initialize() {
    initPlanOfCareSettings(true);
    initPlanOfCareTable(DatabaseApi.plansOfCare());
    $scope.initDocumentTypesTable(DatabaseApi.patientDocumentTypes());
    $scope.questionTypes = mapQuestionTypes(DatabaseApi.patientQuestionTypes());
    $scope.initDatabaseQuestionsTable($scope.questionTypes);
    initTaskContextsMultiSelect();
    initPatientTaskTemplates();
    initPatientTaskTemplatesTable();
    initDefaultCaregiver();
  }

  const initPatientTaskTemplates = () => {
    $scope.patientTaskTemplates = [];
    Object.assign($scope.patientTaskTemplates, DatabaseApi.patientTaskTemplates());
    if ($scope.patientTaskTemplates) {
      initIsExistsContexts();
    }
  };

  const initIsExistsContexts = () => {
    const isExistsTemplatesContexts = {
      startOfCare: false,
      reassessment: false,
    };
    $scope.patientTaskTemplates.forEach(template => {
      if (template.context === TaskTemplateContext.StartOfCare) {
        isExistsTemplatesContexts.startOfCare = true;
      } else if (template.context === TaskTemplateContext.Reassessment) {
        isExistsTemplatesContexts.reassessment = true;
      }
    });
    $scope.taskContextOptions.forEach(option => {
      if (option.value === TaskTemplateContext.StartOfCare) {
        option.disabled = isExistsTemplatesContexts.startOfCare;
      } else if (option.value === TaskTemplateContext.Reassessment) {
        option.disabled = isExistsTemplatesContexts.reassessment;
      }
    });
  }

  $scope.handleCaregiverSelection = (id) => {
    $scope.selectedCaregiver.id = id;
  }

  $scope.saveDefaultCaregiver = () => {
    const url = "agencies/" + $rootScope.agencyId + "/coordinator/" + $rootScope.agencyMemberId + "/plan_of_care_settings";
    const body = {
      defaultPocSignaturePerson: $scope.selectedCaregiver.id
    };

    DatabaseApi.put(url, body).then((_) => {
      DatabaseApi.setDefaultPocSignaturePerson($scope.selectedCaregiver.id);
      toaster.pop('success', 'Default caregiver updated successfully');
    }, (_) => {
      toaster.pop('error', 'Something went wrong');
    });
  }

  function setLoadedCaregiver() {
    if ($scope.selectedCaregiver.id !== undefined && Object.keys($scope.caregiversMap).length > 0) {
      const id = $scope.selectedCaregiver.id;

      const caregiver = $scope.caregiversMap[id];

      if (caregiver) {
        caregiver.photo = caregiver.photoUrl;
      }

      $scope.loadedCaregiver = caregiver ? caregiver : null;
    }
  }

  $scope.initDocumentTypesTable = (data) => {
    setDocumentsMultiSelectOptions();
    var sorting = { createdAt: "desc" };
    if ($scope.documentTypesTable) {
      sorting = $scope.documentTypesTable.sorting();
    }
    var options = {
      count: 25,
      sorting: sorting
    };

    $scope.documents = data;
    if (!$scope.documentTypesTable && $scope.documents.length > 0) {
      $scope.documentTypesTable = new NgTableParams(options, {
        counts: [],
        dataset: $scope.documents
      });
    }
  }

    $scope.openPlanOfCareModal = function (plan) {
        $uibModal.open({
            templateUrl: 'admin/views/plan-of-care-template-modal.html',
            size: 'md',
            controller: 'planOfCareTemplateModalCtrl',
            resolve: {
                plan: () => plan,
                onPlanOfCarePatched: () => (planOfCareType) => {
                    $scope.planOfCares = $scope.planOfCares.map(poc => {
                        if (poc.id === planOfCareType.id) {
                            return planOfCareType;
                        }
                        return poc;
                    })
                }
            }
        });
    }

  function initPlanOfCareSettings(initDocumentTypesTemplates) {
    $scope.handleCaregiverSelection($scope.defaultPocSignaturePerson);
    setLoadedCaregiver();
    $scope.planOfCareTable = new NgTableParams({
      count: 25,
    }, {
      counts: [],
      dataset: $scope.planOfCares
    });

    if (initDocumentTypesTemplates) {
      getDocumentTypesTemplates();
    }

    if ($scope.planOfCares.length > 0) {
      $scope.planOfCare = $scope.planOfCares[0];
    }
    setDocumentsMultiSelectOptions();
    setOfficesWithoutPOCType();
  }

  function setOfficesWithoutPOCType() {
    if ($scope.planOfCares.length === 0) {
      $scope.officesWithoutPOCType = $scope.offices;
      return;
    }
    const pocTypesOfficeIds = $scope.planOfCares.map(p => p.officeId);
    $scope.officesWithoutPOCType = $scope.offices.filter(office => !pocTypesOfficeIds.includes(office.id));
  }

  function initPlanOfCareTable (data) {
      $scope.planOfCareTable = new NgTableParams({
          count: 25,
      }, {
          counts: [],
          dataset: data
      });
  }

  $scope.newDocument = function () {
    DatabaseApi.post("agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/patient_document_types",
                     {
                       title: "",
                       type: "GENERAL",
                       planOfCareId: null,
                       detailsSettings: {
                        showPatientName: true,
                        showPatientGender: true,
                        showPatientAddress: true,
                        showPatientPhoneNumber: true,
                        showPatientDateOfBirth: true,
                        showCaregiverName: true,
                        showPatientContract: true,
                        showPatientInitials: true,
                        showGeneralDetailsOnEveryPage: false
                       }
                      }).then(function (res) {
                       $scope.documents.push(res.data);
                       $state.go("app.patientDocumentBuilder", {documentId: res.data.id});
                     });
  }

  $scope.example = function (documentRow) {
    var lastVersion = documentRow.versions.sort(function(ver1, ver2) { return ver2.id - ver1.id})[0];

    $scope.isLoadingExample = documentRow.id;
    DatabaseApi.post("agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId + "/patient_document_types/example",
                     { title: documentRow.title ,content: lastVersion.content}).then(function(res) {
                       $scope.isLoadingExample = false;
                       $window.open(res.data.url);
                     }, function(){
                         toaster.pop('error',"Something went wrong");
    });
  };

  $scope.removeDocument = function (document) {
    SweetAlert.swal({
      title: "Remove?",
      text: "Are you sure you want to remove this document type?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3077EB",
      confirmButtonText: "Yes, remove",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        DatabaseApi.patch("agencies/" + $rootScope.agencyId + "/" + $rootScope.agencyMemberId
          + "/patient_document_types/" + document.id, { title: document.title, isRemoved: true }).then(function (res) {
            let docIndex = $scope.documents.findIndex(doc => doc.id === document.id);
            $scope.documents.splice(docIndex, 1);
            $scope.initDocumentTypesTable($scope.documents);
            initSelectedDocumentTypesTemplates();
            toaster.pop('success', "Document deleted successfully");
          }, function () {
            toaster.pop('error', "Something went wrong");
          })
      }
    });
  }

  $rootScope.$on('got_data', function (event) {
    $scope.initDocumentTypesTable(DatabaseApi.patientDocumentTypes());
    $scope.questionTypes = mapQuestionTypes(DatabaseApi.patientQuestionTypes());
    $scope.initDatabaseQuestionsTable($scope.questionTypes);
  });

  $scope.getTypeText = function (type) {
    switch(type) {
      case "GENERAL":
        return "General";
      case "PLAN_OF_CARE":
        return "Plan Of Care"
      default:
        return type;
    }
  }

  $scope.copyDocumentTypeTemplate = () => {
    if (!$scope.selectedDocumentToCopy.val) {
      return;
    }
    const docTemplateToCopy = $scope.patientDocumentTypesTemplatesToCopy.find(doc => doc.id === parseInt($scope.selectedDocumentToCopy.val));
    if (!docTemplateToCopy) {
      toaster.pop('error', "Something went wrong", "Could not copy document type template");
      return;
    }

    DatabaseApi.post('agencies/' + $rootScope.agencyId + "/patient_documents/" + docTemplateToCopy.id + '/copy').then(function (res) {
      let documents = DatabaseApi.patientDocumentTypes();
      documents.push(res.data.document);
      $scope.initDocumentTypesTable(documents);
      initSelectedDocumentTypesTemplates();
      toaster.pop('success', "Document added successfully");
    }, function (err) {
      toaster.pop('error', "Something went wrong", "Could not get document types templates");
    });
  }

  $scope.createPlanOfCareForOffice = (item) => {
    const url = "/agencies/:agencyId/agency_members/:agencyMemberId/offices/:officeId/plan_of_care/copy_from_template"
        .replace(":agencyId", $rootScope.agencyId)
        .replace(":agencyMemberId", $rootScope.agencyMemberId)
        .replace(":officeId", item.officeId);

    DatabaseApi.post(url).then(function (res) {
        DatabaseApi.getPlansOfCare();
        toaster.pop('success', "Plan of care for office created successfully");
      }, function (err) {
        toaster.pop('error', "Something went wrong", "Could not copy plan of care document");
      });
  }

  const initSelectedDocumentTypesTemplates = () => {
    let templates = [];

    $scope.patientDocumentTypesTemplatesToCopy = angular.copy($scope.patientDocumentTypesTemplates);
    const documentTemplatesNames = $scope.documents.map(doc => doc.title);
    $scope.patientDocumentTypesTemplates.forEach(template => {
      if(!documentTemplatesNames.includes(template.title)) {
        templates.push(template);
      }
    })
    $scope.patientDocumentTypesTemplatesToCopy = templates;
  }

  function getDocumentTypesTemplates() {
    if($scope.patientDocumentTypesTemplates.length === 0) {
      DatabaseApi.get('agencies/' + $rootScope.agencyId + "/" + $rootScope.agencyMemberId +  '/patient_document_types_templates').then(function (res) {
        $scope.patientDocumentTypesTemplates = res.data.patientDocumentTypesTemplates;
        initSelectedDocumentTypesTemplates();
      }, function (err) {
        toaster.pop('error', "Something went wrong", "could not get document types templates");
      });
    } else {
      initSelectedDocumentTypesTemplates();
    }
  }

  $scope.answerTypesMap = {
    radio: "Radio",
    check: "Multiple Select (check)",
    dropDown: "Drop Down",
    yesNo: "Yes/No",
    textShort: "Short Text",
    textLong: "Long Text",
    number: "Number",
    time: "Time",
    date: "Date",
    bloodPressure: "Blood Pressure"
  };

  $scope.questionsGlobalFilter = { val: "" };
  $scope.filterQuestionsBy = {
    questionType: [],
    isForVBP: {},
    isVital: {},
    showOnPatient: {},
    showOnAgency: {}
  };

  $scope.questionTypesOptions = Object.keys($scope.answerTypesMap).map((key, index) => {
    return { id: index, label: $scope.answerTypesMap[key], value: key };
  });
  $scope.yesNoFilterOptions = [
    { id: 1, label: "Yes" },
    { id: 2, label: "No" }
  ];
  $scope.singleSelectionExtraSettings = {
      styleActive: true,
      selectionLimit: 1,
      smartButtonMaxItems: 1,
      closeOnSelect: true
  };

  $scope.selectPatientTaskTemplateCertificationsExtraSettings = {
    styleActive: true,
    scrollable: true,
    enableSearch: true,
    smartButtonMaxItems: 3
  };

  $scope.selectPatientTaskTemplateDocumentsExtraSettings = {
    styleActive: true,
    scrollable: true,
    enableSearch: true,
    scrollableHeight: '400px'
  };

  $scope.allowedAnswerTypesToEditShowOnAgency = ['radio', 'check', 'dropDown'];
  $scope.multipleAnswersQuestionTypes = ['radio', 'check', 'dropDown'];
  $scope.allowedVBPQuestionTypes = ["radio", "dropDown", "yesNo"];

  function mapQuestionTypes (questionTypes) {
    return questionTypes.map(questionType => {
      questionType.questionTypeText = $scope.answerTypesMap[questionType.answerType];
      if (questionType.possibleAnswers && questionType.possibleAnswers.length > 0) {
        questionType.possibleAnswersLimit = Math.min(questionType.possibleAnswers.length, 3);
      } else {
        questionType.possibleAnswersLimit = 0;
      }
      return questionType;
    });
  }

  $scope.initDatabaseQuestionsTable = (data) => {
    let sorting = { createdAt: "desc" };
    if ($scope.questionTypesTable) {
      sorting = $scope.questionTypesTable.sorting();
    }
    var options = {
      count: 25,
      sorting: sorting
    };

    $scope.questionTypesTable = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: data
    });

    if ($scope.questionsGlobalFilter.val) {
      $scope.applyQuestionsGlobalSearch($scope.questionsGlobalFilter.val);
    }
  }

  $scope.applyQuestionsGlobalSearch = (term) => {
    const filter = { $: term };
    if ($scope.questionTypesTable) {
      angular.extend($scope.questionTypesTable.filter(), filter);
    } else {
      console.log("no table");
    }
  };

  $scope.openNewQuestionModal = (nursingQuestion) => {
    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/patient-new-question-type-modal.html',
      size: 'md',
      controller: 'patientNewQuestionTypeModalCtrl',
      resolve: {
        questionType: function () {
          return undefined;
        },
        allowedVBPQuestionTypes: function () {
          return $scope.allowedVBPQuestionTypes;
        },
        modalStatus: function () {
          return nursingQuestion ? "EDITABLE_ALERTS" : "NEW_QUESTION";
        },
        nursingQuestion: function () {
          return nursingQuestion ? nursingQuestion : undefined;
        },
        updateQuestionType: function () {
          return $scope.updateQuestionType
        }
      }
    });
    modalInstance.result.then(function (res) {
      if (res === true) {
        DatabaseApi.setPatientQuestionTypes();
      }
    });
  }

  $scope.updateQuestionType = (row, field, oldValue) => {
    if (field === 'showOnAgency' && !$scope.allowedAnswerTypesToEditShowOnAgency.includes(row.answerType)) {
      return;
    }
    const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/nursing_questions_settings/${row.id}`;
    const body = {};
    body[field] = $scope.questionTypes.find(question => question.id === row.id)[field];
    DatabaseApi.put(url, body).then((res) => {
      toaster.pop('success', 'Question type updated successfully');
      $scope.initDatabaseQuestionsTable($scope.questionTypes);
    }, (err) => {
      toaster.pop('error', 'Failed updating question type');
      row[field] = oldValue;
    });
  }

  $scope.deleteQuestionType = (row) => {
    SweetAlert.swal({
      title: "Delete?",
      text: "Are you sure you want to delete this question?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3077EB",
      confirmButtonText: "Yes, delete",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/nursing_questions_settings/${row.id}`;
        DatabaseApi.delete(url).then((res) => {
          toaster.pop('success', 'Question type deleted successfully');
          $scope.questionTypes = $scope.questionTypes.filter(questionType => questionType.id !== row.id);
          $scope.initDatabaseQuestionsTable($scope.questionTypes);
        }, (err) => {
          toaster.pop('error', 'Failed deleting question type');
        });
      }
    });
  }

  $scope.setQuestionsFilters = () => { 
    if (!$scope.questionTypesTable) return;

    const filters = [];

    const selectedQuestionTypes = $scope.filterQuestionsBy.questionType.map((obj) => {
      return $scope.questionTypesOptions.find(lang => lang.id === obj.id).value;
    });
    if (selectedQuestionTypes.length > 0) {
      filters.push((question) => selectedQuestionTypes.indexOf(question.answerType) > -1);
    }

    if ($scope.filterQuestionsBy.isForVBP.id !== undefined) {
      const selectedIsForVBP = $scope.filterQuestionsBy.isForVBP.id === 1;
      filters.push((question) => question.isVBP === selectedIsForVBP);
    }

    if ($scope.filterQuestionsBy.isVital.id !== undefined) {
      const selectedIsVital = $scope.filterQuestionsBy.isVital.id === 1;
      filters.push((question) => question.isVital === selectedIsVital);
    }

    if ($scope.filterQuestionsBy.showOnPatient.id !== undefined) {
      const selectedShowOnPatient = $scope.filterQuestionsBy.showOnPatient.id === 1;
      filters.push((question) => question.showOnPatient === selectedShowOnPatient);
    }

    if ($scope.filterQuestionsBy.showOnAgency.id !== undefined) {
      const selectedShowOnAgency = $scope.filterQuestionsBy.showOnAgency.id === 1;
      filters.push((question) => question.showOnAgency === selectedShowOnAgency);
    }

    let filteredQuestions = angular.copy($scope.questionTypes);
    if (filters.length > 0) {
      filteredQuestions = filteredQuestions.filter((question) => {
        let isFilterd = true;
        for (let idx = 0; isFilterd && idx < filters.length; idx++) {
          isFilterd = isFilterd && filters[idx](question);
        }
        return isFilterd;
      });
    }

    $scope.initDatabaseQuestionsTable(filteredQuestions);
  }

  $scope.$watch('filterQuestionsBy', function () {
    $scope.setQuestionsFilters();
  });

  $scope.questionsFilterEvents = {
    onSelectionChanged: () => {
      $scope.setQuestionsFilters();
    }
  }

  $scope.onClickPatientTaskTemplateDropdown = (item) => {
    $scope.edittingPatientTaskTemplateItem = item;
  }

  $scope.selectPatientTaskTemplateCertificationsEvents = {
    onSelectionChanged: function () {
      $scope.updatePatientTaskTemplate($scope.edittingPatientTaskTemplateItem);
    }
  };

  $scope.selectPatientTaskTemplateDocumentsEvents = {
    onItemSelect: (item) => {
      // check whether it is plan of care, and set planOfCaretypeId
      const selectedDocument = $scope.documentsMultiSelectOptions.find(d => d.id === item.id);
      if (selectedDocument && selectedDocument.isPlanOfCare) {
        $scope.edittingPatientTaskTemplateItem.planOfCareTypeId = $scope.planOfCare.planOfCareTypeId;
      }
    },

    onItemDeselect: (item) => {
      // check whether it is plan of care, and reset planOfCaretypeId
      const selectedDocument = $scope.documentsMultiSelectOptions.find(d => d.id === item.id);
      if (selectedDocument && selectedDocument.isPlanOfCare) {
        $scope.edittingPatientTaskTemplateItem.planOfCareTypeId = null;
      }
    },
    onSelectionChanged: function () {
      $scope.updatePatientTaskTemplate($scope.edittingPatientTaskTemplateItem);
    }
  };

  $scope.updatePatientTaskTemplate = (patientTaskTemplate) => {
    const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_task_templates/:patientTaskTemplateId"
      .replace(":agencyId", $rootScope.agencyId)
      .replace(":agencyMemberId", $rootScope.agencyMemberId)
      .replace(":patientTaskTemplateId", patientTaskTemplate.id);

    const durationMinutes = patientTaskTemplate.durationMinutes && patientTaskTemplate.durationMinutes > 0
      ? patientTaskTemplate.durationMinutes : null;

    const hasPlanOfCare = patientTaskTemplate.documents.find(d => d.id === 0) !== undefined;

    const context = $scope.taskContextOptions.find(option =>
      option.id === patientTaskTemplate.taskContextModel.id
    ).value;

    const body = {
      allowedCertifications: patientTaskTemplate.allowedCertifications
        .map(cert => $scope.certifications.find(c => c.id === cert.id).label),
      documents: patientTaskTemplate.documents.map(doc => doc.id).filter(docId => docId !== 0),
      hasPlanOfCare: hasPlanOfCare,
      title: patientTaskTemplate.title,
      durationMinutes: durationMinutes,
      context: context,
    };

    DatabaseApi.put(url, body).then(() => {
      toaster.pop('success', 'Successfully updated patient task template');
      $scope.patientTaskTemplates.forEach(template => {
        if (template.id === patientTaskTemplate.id) {
          template.context = context;
        }
      });
      initIsExistsContexts();
    }, () => {
      toaster.pop('error', 'Failed updating patient task template');
    });
  }

  $scope.deletePatientTaskTemplate = (patientTaskTemplateId) => {
    SweetAlert.swal({
      title: "Delete?",
      text: "Are you sure you want to delete this patient task template?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3077EB",
      confirmButtonText: "Yes, delete",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_task_templates/${patientTaskTemplateId}`;
        DatabaseApi.delete(url).then((res) => {
          toaster.pop('success', 'Patient Task Template deleted successfully');
          $scope.patientTaskTemplates = $scope.patientTaskTemplates.filter(item => item.id !== patientTaskTemplateId);
          initIsExistsContexts();
          initPatientTaskTemplatesTable();
        }, (err) => {
          toaster.pop('error', 'Failed deleting patient task template');
        });
      }
    });
  }

  $scope.openNewPatientTaskTemplateModal = () => {
    let newScope = $scope.$new();
    newScope.documentsMultiSelectOptions = $scope.documentsMultiSelectOptions;
    newScope.selectPatientTaskTemplateDocumentsExtraSettings = $scope.selectPatientTaskTemplateDocumentsExtraSettings;

    newScope.certifications = $scope.certifications;
    newScope.selectPatientTaskTemplateCertificationsExtraSettings = $scope.selectPatientTaskTemplateCertificationsExtraSettings;

    newScope.taskContextOptions = $scope.taskContextOptions;
    newScope.selectPatientTaskTemplateTaskContextEvents = $scope.selectPatientTaskTemplateTaskContextEvents;

    const modalInstance = $uibModal.open({
      templateUrl: 'admin/views/new-patient-task-template-modal.html',
      size: 'md',
      controller: 'newPatientTaskTemplateModalCtrl',
      scope: newScope
    });

    modalInstance.result.then(function (res) {
      if (res.id) {
        $scope.patientTaskTemplates.push(res);
        initIsExistsContexts();
        initPatientTaskTemplatesTable($scope.patientTaskTemplates);
        DatabaseApi.setPatientTaskTemplates();
      }

    }, function () {
    });
  }

  $scope.handleClickAddNewPocConfiguration = () => {
    const offices = $scope.officesWithoutPOCType.map(o => ({
      id: o.id,
      label: o.name
    }));

    $scope.customPOCTypeModal = $uibModal.open({
      size: 'md',
      component: 'customPlanOfCareType',
      resolve: {
        offices: () => offices,
        closeModal: () => closeModal,
        handleSuccess: () => () => {
          DatabaseApi.getPlansOfCare();
        }
      }
    });

    function closeModal() {
      $scope.customPOCTypeModal.close();
    }
  };

  function initPatientTaskTemplatesTable() {
    const items = angular.copy($scope.patientTaskTemplates);
  
    items.forEach(taskTemplate => {
      taskTemplate.allowedCertifications = taskTemplate.allowedCertifications
        .map(cert => $scope.certifications.find(c => c.label === cert)).filter(c => c !== undefined);

      taskTemplate.documents = taskTemplate.documents
        .map(documentId => $scope.documentsMultiSelectOptions.find(d => d.id === documentId));

      // check if template have plan of care upon task
      if (taskTemplate.hasPlanOfCare) {
        taskTemplate.documents.push({ id: 0 });
      }

      const findTaskContext = $scope.taskContextOptions.find(option =>
        option.value === taskTemplate.context
      );
      taskTemplate.taskContextModel = { id: 0 };
      if (findTaskContext !== undefined) {
        taskTemplate.taskContextModel.id = findTaskContext.id;
      }
    });

    const options = {
      count: 25
    };

    $scope.patientTaskTemplatesTable = new NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: items
    });
  }

  function initDefaultCaregiver() {
    $scope.selectedCaregiver.id = $rootScope.user.agencyMember.defaultPocSignturePerson;
  }

  function isPublishedDocument(document) {
    return document
        && document.versions
        && document.versions.find(v => v.isPublished) !== undefined
  }

  $scope.isPublishedDocument = isPublishedDocument;

  function setDocumentsMultiSelectOptions() {
    const documents = DatabaseApi.patientDocumentTypes();
    const relevantDocuments = documents
        .filter(document => document.title !== "" && isPublishedDocument(document))
        .map(doc => ({id: doc.id, label: doc.title}));

    $scope.documentsMultiSelectOptions = angular.copy(relevantDocuments);

    // add plan of care to array with unique id '0'
    if ($scope.planOfCare) {
      $scope.documentsMultiSelectOptions.push({
        id: 0,
        label: $scope.planOfCare.name,
        isPlanOfCare: true
      })
    }
  }

  const initTaskContextsMultiSelect = () => {
    $scope.taskContextOptions = [
      { id: 0, label: "Regular", value: TaskTemplateContext.Regular },
      { id: 1, label: "Start of Care", value: TaskTemplateContext.StartOfCare },
      { id: 2, label: "Reassessment", value: TaskTemplateContext.Reassessment },
    ];
    
    $scope.selectPatientTaskTemplateTaskContextsExtraSettings = {
      styleActive: true,
      singleSelection: true,
      selectionLimit: 1,
      smartButtonMaxItems: 1,
      closeOnSelect: true,
      showCheckAll: false,
      showUncheckAll: false,
    };

    $scope.selectPatientTaskTemplateTaskContextEvents = {
      onSelectionChanged: () => {
        $scope.updatePatientTaskTemplate($scope.edittingPatientTaskTemplateItem);
      }
    };
  };

  $rootScope.$on("got_patient_document_types", function (event) {
    $scope.initDocumentTypesTable(DatabaseApi.patientDocumentTypes());
  });

  $rootScope.$on("got_agency_certifications", function (event) {
    const agencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = agencyCertifications
      .map((certificationItem, index) => ({
        id: index,
        label: certificationItem.certification
      }));
  });

  $rootScope.$on("got_patient_task_templates", function (event) {
    initPatientTaskTemplates();
    initPatientTaskTemplatesTable();
  });

  $rootScope.$on("got_caregivers_data", function (event) {
    $scope.caregiversMap = DatabaseApi.caregivers();

    setLoadedCaregiver();
  });

  $rootScope.$on("got_offices", () => {
    $scope.offices = DatabaseApi.offices();
    setOfficesWithoutPOCType();
  });

  $rootScope.$on("got_plan_of_care_type", () => {
    $scope.planOfCares = DatabaseApi.plansOfCare();
    $scope.defaultPocSignaturePerson = DatabaseApi.defaultPocSignaturePerson();
    if ($scope.planOfCares.length > 0) {
      initPlanOfCareSettings(true);
    }
  });

  initialize();
};
