import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";
import useReactBroadcastChannel from "../../../shared/hooks/useReactBroadcastChannel";
import usePhoneProvider from "../hooks/usePhoneProvider";

export function AlreadyInACallOnAnotherTabDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { broadcastEvent } = useReactBroadcastChannel();
  const provider = usePhoneProvider();

  const handleUseThisTabInstead = () => {
    if (provider.activeTabId === null) return;

    broadcastEvent("PHONE_CLIENT:LOGOUT", { tabId: provider.activeTabId });

    onClose();
  };

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} size="lg" onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Hangup active call?
          </AlertDialogHeader>

          <AlertDialogBody>
            You are already in a call on another tab.
            <br />
            Do you want to hang up the active call and use this tab instead?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" ml={3} onClick={handleUseThisTabInstead}>
              Hangup and use this tab
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
