import { Box, Button, ButtonGroup, ButtonProps, Flex, Spacer, Text } from "@chakra-ui/react";
import { DayOfWeek } from "@js-joda/core";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { ResponseOf } from "../../core/api";

const daysOfWeek = DayOfWeek.values();

type PatientAvailabilityBody = ResponseOf<"get", "./patients/:patientId/availability_settings">;
type TimeFrames = ResponseOf<"get", "./patients/availability_time_frames">;

const PatientAvailability = (props: {
  patientAvilaibility: PatientAvailabilityBody;
  availabilityTimeFrames: TimeFrames;
  onUpdateAvilability: (availability: PatientAvailabilityBody["availability"]) => void;
}) => {
  const [patientAvailability, setPatientAvailability] = useState(props.patientAvilaibility);

  const getDayTimeFrameVariant = (
    day: DayOfWeek,
    timeframe: TimeFrames["timeframes"][number]
  ): NonNullable<ButtonProps["variant"]> => {
    const availabilityForDay = patientAvailability.availability.find(
      (availability) => availability.day === day.name()
    );
    const selected = availabilityForDay?.timeframesIds.includes(timeframe.id) ?? false;
    return selected ? "solid" : "outline";
  };

  const onClickTimeFrame = (day: DayOfWeek, timeframe: TimeFrames["timeframes"][number]) => {
    setPatientAvailability((previousState) => {
      const clonedState = cloneDeep(previousState);
      const currentDay = clonedState.availability.find(
        (availability) => availability.day === day.name()
      );
      if (!currentDay) {
        return clonedState;
      }
      const doesTimeframeExist = currentDay.timeframesIds.includes(timeframe.id);
      if (doesTimeframeExist) {
        currentDay.timeframesIds = currentDay.timeframesIds.filter((id) => id !== timeframe.id);
      } else {
        currentDay.timeframesIds.push(timeframe.id);
      }

      props.onUpdateAvilability(clonedState.availability);

      return clonedState;
    });
  };

  return (
    <Flex direction="column" gap={2} w="full">
      {daysOfWeek.map((day) => {
        return (
          <Flex key={day.value()} direction="row" justifyContent="space-between">
            <Box flex={1}>
              <Text>{day.name()}</Text>
            </Box>
            <ButtonGroup flex={1} isAttached={true}>
              {props.availabilityTimeFrames.timeframes.map((timeframe) => {
                return (
                  <Button
                    key={`${day.name()}-${timeframe.name}`}
                    colorScheme="blue"
                    variant={getDayTimeFrameVariant(day, timeframe)}
                    onClick={() => onClickTimeFrame(day, timeframe)}
                  >
                    {timeframe.name} {timeframe.startTime}-{timeframe.endTime}
                  </Button>
                );
              })}
            </ButtonGroup>
            <Spacer />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default PatientAvailability;
