import { Avatar, Flex, Tag, Text } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import { getLabelForNotIdentifiedPhoneNumber } from "../../../shared/utils/call-center";
import { getFullName } from "../../../shared/utils/get-full-name";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";

export default function InboundCallModalConferenceParticipant(props: {
  participant: Messages["TelephonyCallParticipantInfo"];
}) {
  const { name, description, photoUrl, role, tag } = getParticipantInfo(props.participant);

  return (
    <Flex align="center" gap={3} justify="space-between" p={3}>
      <Avatar name={name} src={photoUrl} />
      <Flex direction="column" flex={1} gap={1}>
        <Text>
          <Text as="b" fontWeight="semibold">
            {name}
          </Text>{" "}
          {description !== undefined && description}
        </Text>
        <Flex gap={1}>
          <Tag>{role}</Tag>
          <Tag colorScheme="blue">{tag}</Tag>
        </Flex>
      </Flex>
    </Flex>
  );
}

function getParticipantInfo(participant: Messages["TelephonyCallParticipantInfo"]) {
  switch (participant.entity.type) {
    case "AgencyMember":
      return {
        name: getFullName(participant.entity),
        role: participant.role,
        description: undefined,
        photoUrl: participant.entity.photoUrl ?? undefined,
        tag: "Agency Member",
      };
    case "Patient":
      return {
        name: getFullName(participant.entity),
        role: participant.role,
        description: phoneFormatter.formatNationalIfValid(participant.source),
        photoUrl: undefined,
        tag: "Partient",
      };
    case "PatientContact":
      return {
        name: getFullName(participant.entity),
        role: participant.role,
        description: phoneFormatter.formatNationalIfValid(participant.source),
        photoUrl: undefined,
        tag: `Patient Contact (${participant.entity.relationship} • ${getFullName(
          participant.entity.patient
        )})`,
      };
    case "PhonebookContact":
      return {
        name: participant.entity.name,
        role: participant.role,
        description: phoneFormatter.formatNationalIfValid(participant.source),
        photoUrl: undefined,
        tag: "Phonebook Contact",
      };
    case "NotIdentified":
      return {
        name: phoneFormatter.formatNationalIfValid(participant.source),
        role: participant.role,
        description: undefined,
        photoUrl: undefined,
        tag: getLabelForNotIdentifiedPhoneNumber(participant.entity.kind),
      };
    case "Caregiver":
      return {
        name: getFullName(participant.entity),
        role: participant.role,
        description: undefined,
        photoUrl: undefined,
        tag: "Caregiver",
      };
  }
}
