import React from "react";
import { Messages } from "../../../core/api";
import { getEntityPhotoUrl } from "../../../shared/components/EntityCard";
import EntitySelect from "../../../shared/components/EntitySelect";
import { useDebounce } from "../../../shared/hooks/useDebounce";
import PatientIcon from "../../../shared/icons/PatientIcon";
import { PatientId } from "../../../shared/schema/schema";
import { usePatients } from "../hooks/usePatients";
import { useSearchPatientsQuery } from "../hooks/useSearchPatientsQuery";

type PatientResult = Pick<
  Messages["GlobalSearchPatientResult"],
  "id" | "firstName" | "middleName" | "lastName" | "displayId" | "gender"
>;

type Props = {
  value: PatientId | null;
  isDisabled?: boolean;
  onChange: (value: PatientResult | null) => void;
};

const PatientSelect = (props: Props) => {
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchText = useDebounce(searchText, 200);
  const searchQuery = useSearchPatientsQuery({ term: debouncedSearchText });
  const patientsQuery = usePatients();

  function getFullName(
    entity: Pick<PatientResult, "id" | "displayId" | "firstName" | "middleName" | "lastName">
  ): string {
    let name = entity.firstName;

    if (entity.middleName ?? null !== null) {
      name += ` ${entity.middleName?.charAt(0)}.`;
    }

    name += ` ${entity.lastName}`;

    return name;
  }

  return (
    <EntitySelect
      buttonPlaceholder="Select patient"
      entitiesQuery={patientsQuery}
      getEntityDisplayText={getFullName}
      inputPlaceholder="Search patient..."
      isDisabled={props.isDisabled}
      leftIcon={<PatientIcon fontSize={18} />}
      notFoundPlaceholder="No results"
      photoUrlPredicate={getEntityPhotoUrl}
      searchByPlaceholder="Search by name or ID"
      searchQuery={searchQuery}
      searchText={searchText}
      value={props.value}
      onChange={props.onChange}
      onChangeSearchText={setSearchText}
    />
  );
};

export default PatientSelect;
