import DOMPurify from "dompurify";
import parse from "html-react-parser";
const htmlParser = parse;

const parseHTML = (html: string) => {
  try {
    const sanitizedHTML = DOMPurify.sanitize(html);
    return htmlParser(sanitizedHTML);
  } catch (e) {
    console.error(e);
    return html;
  }
};

function SafeHTML(props: { html: string }) {
  return <>{parseHTML(props.html)}</>;
}

export default SafeHTML;
