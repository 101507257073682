import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Highlight,
  HighlightProps,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import React from "react";
import FocusLock from "react-focus-lock";
import { FaRegTimesCircle } from "react-icons/fa";
import { formatErrorResponse } from "../utils/format-response-error";
import { Loadable, loadable } from "../utils/loadable";

type EntitySelectBaseEntity = {
  id: unknown;
  displayId?: unknown;
};

type Props<
  $Entity extends EntitySelectBaseEntity,
  $SearchResultData extends EntitySelectBaseEntity[],
  $EntitiesQueryData extends $Entity[]
> = {
  value: $Entity["id"] | null;
  entitiesQuery: UseQueryResult<$EntitiesQueryData, unknown>;
  searchQuery: UseQueryResult<$SearchResultData>;
  buttonPlaceholder: string;
  inputPlaceholder: string;
  notFoundPlaceholder: string;
  searchByPlaceholder: string;
  searchText: string;
  leftIcon: React.ReactElement;
  isDisabled?: boolean;
  onChangeSearchText: (value: string) => void;
  photoUrlPredicate: (entity: $SearchResultData[number]) => string | undefined;
  onChange: (value: $Entity | null) => void;
  getEntityDisplayText: (entity: $Entity) => string;
};

const EntitySelect = <
  $Entity extends EntitySelectBaseEntity,
  $SearchResultData extends $Entity[],
  $EntitiesQueryData extends $Entity[]
>(
  props: Props<$Entity, $SearchResultData, $EntitiesQueryData>
) => {
  const disclosure = useDisclosure({ onClose: () => props.onChangeSearchText("") });

  const selectedEntity = ((): Loadable<$Entity | null> => {
    if (props.entitiesQuery.isPaused || props.value === null) {
      return loadable.resolve(null);
    }

    if (props.entitiesQuery.isPending) {
      return loadable.loading();
    }

    if (props.entitiesQuery.data !== undefined) {
      return loadable.resolve(
        props.entitiesQuery.data.find((entity) => entity.id === props.value) ?? null
      );
    }

    return loadable.reject(props.entitiesQuery.error);
  })();

  const entityName = (() => {
    switch (selectedEntity.type) {
      case "Loading":
        return "Loading...";
      case "Rejected":
        return "Error";
      case "Resolved":
        return selectedEntity.value === null
          ? props.buttonPlaceholder
          : props.getEntityDisplayText(selectedEntity.value);
    }
  })();

  return (
    <Popover isLazy={true} placement="bottom-start" {...disclosure}>
      <ButtonGroup
        color={props.value !== null ? "blue.500" : "gray.600"}
        isAttached={true}
        variant="outline"
      >
        <PopoverTrigger>
          <Button
            bg={props.value !== null ? "blue.50" : "transparent"}
            borderColor={props.value !== null ? "blue.200" : "gray.300"}
            isDisabled={props.isDisabled ?? false}
            leftIcon={props.leftIcon}
            rightIcon={props.value === null ? <ChevronDownIcon /> : undefined}
          >
            {entityName}
          </Button>
        </PopoverTrigger>
        {props.value !== null && (
          <IconButton
            aria-label="close"
            bg={props.value !== null ? "blue.50" : "transparent"}
            borderColor={props.value !== null ? "blue.200" : "gray.300"}
            fontSize={8}
            icon={<CloseIcon />}
            isDisabled={props.isDisabled ?? false}
            onClick={() => props.onChange(null)}
          />
        )}
      </ButtonGroup>
      <PopoverContent w="100%">
        <PopoverBody p={0}>
          <FocusLock>
            <EntitySelectInput
              placeholder={props.inputPlaceholder}
              value={props.searchText}
              onChange={props.onChangeSearchText}
            />
          </FocusLock>
          <Box opacity={!props.searchQuery.isPending && props.searchQuery.isFetching ? 1 : 0}>
            <Progress isIndeterminate bg="gray.200" h="1px" marginTop="-1px" size="xs" />
          </Box>

          <Box maxH="40vh" overflow="auto">
            {(() => {
              switch (props.searchQuery.status) {
                case "pending": {
                  if (props.searchQuery.isFetching) {
                    return <EntitySelectItemsShimmer />;
                  }

                  return (
                    <Center color="gray.400" fontSize={16} py={10}>
                      <Text>{props.searchByPlaceholder}</Text>
                    </Center>
                  );
                }

                case "error":
                  return <Text>{formatErrorResponse(props.searchQuery.error)}</Text>;

                case "success":
                  return (
                    <Box>
                      {props.searchQuery.data.length === 0 && (
                        <EntitySelectNotFound placeholder={props.notFoundPlaceholder} />
                      )}
                      <Box>
                        {props.searchQuery.data.map((result) => {
                          const entity = props.entitiesQuery.data?.find((x) => x.id === result.id);
                          return (
                            <EntitySelectItem
                              key={`${result.id}`}
                              entity={result}
                              getEntityDisplayText={props.getEntityDisplayText}
                              highlight={props.searchText}
                              photoUrl={props.photoUrlPredicate(result)}
                              onClick={() => {
                                disclosure.onClose();
                                props.onChange(entity ?? null);
                              }}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  );
              }
            })()}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

function FixHighlight(props: HighlightProps): JSX.Element {
  if (props.query.length === 0) {
    return <>{props.children}</>;
  }

  return <Highlight {...props} />;
}

function EntitySelectNotFound(props: { placeholder: string }): JSX.Element {
  return (
    <Center color="gray.400" flexDirection="column" fontSize={16} gap={2} py={8}>
      <FaRegTimesCircle />
      <Text>{props.placeholder}</Text>
    </Center>
  );
}

function EntitySelectItem<$Entity extends EntitySelectBaseEntity>(props: {
  highlight: string;
  entity: $Entity;
  photoUrl: string | undefined;
  onClick: (entity: $Entity) => void;
  getEntityDisplayText: (entity: $Entity) => string;
}) {
  const handleClick = () => {
    return props.onClick(props.entity);
  };

  return (
    <Flex
      _hover={{ bg: "gray.50" }}
      align="center"
      cursor="pointer"
      gap={2}
      p={4}
      onClick={handleClick}
    >
      {props.photoUrl !== undefined && (
        <Image h={10} objectFit="cover" rounded="full" src={props.photoUrl} w={10} />
      )}
      <Flex direction="column">
        <Text fontWeight={600}>
          <FixHighlight
            query={props.highlight}
            styles={{ px: 0, py: 0, bg: "blue.100", borderRadius: 4 }}
          >
            {props.getEntityDisplayText(props.entity)}
          </FixHighlight>
        </Text>
        <Text color="gray.500" fontSize="sm" fontWeight={500}>
          <FixHighlight
            query={props.highlight}
            styles={{ px: "1", py: "1", bg: "blue.100", borderRadius: 4 }}
          >
            {`#${props.entity.displayId ?? props.entity.id}`}
          </FixHighlight>
        </Text>
      </Flex>
    </Flex>
  );
}

function EntitySelectItemsShimmer() {
  return (
    <Box>
      <EntitySelectItemShimmer />
      <EntitySelectItemShimmer />
      <EntitySelectItemShimmer />
    </Box>
  );
}

export function EntitySelectItemShimmer() {
  return (
    <Flex align="center" gap={2} p={4}>
      <Box flexShrink={0}>
        <Skeleton height={10} rounded="full" width={10} />
      </Box>
      <Skeleton height={2.5} width={72} />
    </Flex>
  );
}

function EntitySelectInput(props: {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}) {
  return (
    <Input
      autoFocus={true}
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      borderRadius={0}
      placeholder={props.placeholder}
      px={4}
      py={3}
      value={props.value}
      variant="unstyled"
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
}

export default EntitySelect;
