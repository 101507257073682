import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";
import { GetRelatedVisitAssignmentQueryVariables } from "../../shared/schema/gql/graphql";
import { VisitAssignmentId } from "../../shared/schema/schema";
import { transformCommCenterTickets } from "../communication/utils/communication-utils";
import {
  GET_RELATED_VISIT_ASSIGNMENTS_QUERY,
  GET_VISIT_ASSIGNMENT_QUERY,
} from "./pages/assignments/visit-assignment.graphql";

export default createQueryGroup(({ api }) => ({
  assignments: {
    get: (id: VisitAssignmentId) =>
      queryOptions({
        queryKey: ["visit", "assignment", "get", id],
        queryFn: async () => {
          const results = await api.graphql(GET_VISIT_ASSIGNMENT_QUERY, { variables: { id: id } });

          if (results.visitAssignments.nodes.length === 0) {
            throw new Error("Visit assignment not found");
          }

          return results.visitAssignments.nodes[0];
        },
      }),
    tickets: (id: VisitAssignmentId) =>
      queryOptions({
        queryKey: ["visit", "assignment", "get", id, "tickets"],
        queryFn: async () => {
          const { tickets } = await api.get("./assignment/:visitAssignmentId/tickets", {
            path: { visitAssignmentId: id },
          });

          return transformCommCenterTickets(tickets);
        },
      }),
    related: (variables: GetRelatedVisitAssignmentQueryVariables) =>
      queryOptions({
        queryKey: ["visit", "assignment", "get", variables.id, "related"],
        queryFn: async () => {
          const results = await api.graphql(GET_RELATED_VISIT_ASSIGNMENTS_QUERY, { variables });
          return [...results.unresolved.nodes, ...results.resolved.nodes];
        },
      }),
  },
}));
