import { CheckIcon, ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Flex,
  FlexProps,
  Input,
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Progress,
  Text,
  useDisclosure,
  useFormControlProps,
} from "@chakra-ui/react";
import React from "react";

type SelectOptions<TValue> =
  | ReadonlyArray<{ value: TValue; label: string; description?: string }>
  | { value: TValue; label: string; description?: string }[];

type BaseProps<TValue> = {
  multiple: boolean;
  buttonProps?: ButtonProps;
  width?: PopoverContentProps["width"] | undefined;
  maxH?: PopoverContentProps["maxH"] | undefined;
  size?: ButtonProps["size"] | undefined;
  allowUnselect?: boolean;
  closeOnUnselect?: boolean;
  closeOnSelectAll?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isTruncated?: boolean;
  searchable?: boolean;
  "aria-invalid"?: boolean;
  children?: React.ReactNode | ((p: { isOpen: boolean }) => React.ReactNode);
  popoverProps?: PopoverProps;
  controlledSearchTerm?: [string, React.Dispatch<React.SetStateAction<string>>];
  renderAfter?: (p: {
    searchTerm: string;
    filteredOptions: SelectOptions<TValue>;
  }) => React.ReactNode;
} & (
  | {
      multiple: true;
      value: TValue[] | null;
      selectedLabel?: (value: TValue[]) => string;
      onChange: (value: TValue[] | undefined) => void;
    }
  | {
      multiple: false;
      value: TValue | null;
      selectedLabel?: (value: TValue) => string;
      onChange: (value: TValue | undefined) => void;
    }
);

export type CustomSelectProps<TValue> = BaseProps<TValue> & {
  label?: string;
};

export type SelectProps<TValue> = BaseProps<TValue> & {
  label: string;
  options: SelectOptions<TValue>;
};

export default function Select<TValue>(props: SelectProps<TValue>) {
  const formControlProps = useFormControlProps(props);

  const searchTermState = React.useState("");

  const [searchTerm, setSearchTerm] = props.controlledSearchTerm ?? searchTermState;

  const disclosure = useDisclosure({
    onClose: () => setTimeout(() => setSearchTerm(""), 200),
  });

  const isSearchable = props.searchable ?? props.options.length > 5;
  const isMultiple = props.multiple ?? false;
  const canUnselect = props.allowUnselect ?? true;

  const isChecked = (value: TValue) => {
    if (props.multiple) {
      return ((props.value ?? []) as TValue[]).includes(value);
    }

    return props.value === value;
  };

  const filteredOptions = props.options.filter((option) => {
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSelect = (value: TValue) => {
    if (props.multiple) {
      const selected = props.value ?? [];
      const newValue = selected.includes(value)
        ? selected.filter((v) => v !== value)
        : [...selected, value];

      return props.onChange(newValue.length > 0 ? newValue : undefined);
    }

    props.value === value ? props.onChange(undefined) : props.onChange(value);
    disclosure.onClose();
  };

  const handleSelectAll = () => {
    if (props.closeOnSelectAll) {
      disclosure.onClose();
    }
    if (props.multiple) {
      return props.onChange(props.options.map((option) => option.value));
    }
  };

  const handleUnselectAll = () => {
    if (props.closeOnUnselect) {
      disclosure.onClose();
    }
    return props.onChange(undefined);
  };

  const getButtonLabel = () => {
    if (props.value !== null) {
      return props.multiple
        ? props.selectedLabel?.(props.value) ?? `${props.label} (${props.value.length})`
        : props.selectedLabel?.(props.value) ??
            String(props.options.find((option) => option.value === props.value)?.label);
    }

    return String(props.label);
  };

  return (
    <Popover placement="bottom-start" {...disclosure} isLazy {...props.popoverProps}>
      <PopoverTrigger>
        {props.children !== undefined ? (
          typeof props.children === "function" ? (
            props.children({ isOpen: disclosure.isOpen })
          ) : (
            props.children
          )
        ) : (
          <Button
            _hover={{
              bg: disclosure.isOpen ? "transparent" : "gray.50",
            }}
            _invalid={
              disclosure.isOpen
                ? undefined
                : {
                    borderColor: "red.500",
                    boxShadow: "0 0 0 1px var(--chakra-colors-red-500)",
                  }
            }
            aria-invalid={props["aria-invalid"] ?? formControlProps.isInvalid}
            bg={props.value !== null ? "blue.50" : undefined}
            borderColor={disclosure.isOpen ? "blue.500" : undefined}
            boxShadow={disclosure.isOpen ? "0 0 0 1px var(--chakra-colors-blue-500)" : undefined}
            colorScheme={props.value !== null ? "blue" : undefined}
            isDisabled={props.isDisabled}
            rightIcon={<ChevronDownIcon />}
            size={props.size}
            textOverflow="ellipsis"
            type="button"
            variant="outline"
            whiteSpace="nowrap"
            {...props.buttonProps}
          >
            <Text isTruncated={props.isTruncated ?? true} textAlign="start" w="full">
              {getButtonLabel()}
            </Text>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent fontSize={props.size} maxH={props.maxH} width={props.width}>
        {isMultiple ? (
          <>
            <MenuGroup>
              <MenuItem onClick={handleSelectAll}>
                <Center h={5} w={4}>
                  <CheckIcon h={3} />
                </Center>

                <Text>Check all</Text>
              </MenuItem>
              <MenuItem onClick={handleUnselectAll}>
                <Center h={5} w={4}>
                  <CloseIcon h={2.5} />
                </Center>
                <Text>Uncheck all</Text>
              </MenuItem>
            </MenuGroup>
            <Divider />
          </>
        ) : (
          props.value !== null &&
          canUnselect && (
            <>
              <MenuGroup>
                <MenuItem onClick={handleUnselectAll}>
                  <Center h={5} w={4}>
                    <CloseIcon h={2.5} />
                  </Center>
                  <Text>Clear selection</Text>
                </MenuItem>
              </MenuGroup>
              <Divider />
            </>
          )
        )}
        {isSearchable && (
          <>
            <Input
              p={props.size === "sm" ? 3 : 4}
              placeholder="Search..."
              size={props.size}
              value={searchTerm}
              variant="unstyled"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Divider />
          </>
        )}

        {props.isLoading === true && <Progress isIndeterminate size="xs" />}
        {props.isLoading === false && <Box h="3.5px" />}

        <MenuGroup>
          {filteredOptions.map((option) => (
            <MenuItem
              key={JSON.stringify(option.value)}
              alignItems={option.description !== undefined ? "flex-start" : "center"}
              onClick={() => handleSelect(option.value)}
            >
              <Center h={5} w={4}>
                <CheckIcon h={3} opacity={isChecked(option.value) ? 1 : 0} />
              </Center>
              <Flex direction="column">
                <Text>{option.label}</Text>
                {option.description && <Text color="gray.500">{option.description}</Text>}
              </Flex>
            </MenuItem>
          ))}
          {props.renderAfter?.({ searchTerm, filteredOptions })}
        </MenuGroup>
      </PopoverContent>
    </Popover>
  );
}

function MenuGroup(props: FlexProps) {
  return <Flex direction="column" maxH="40vh" overflow="auto" py={2} {...props} />;
}

function MenuItem(props: FlexProps) {
  return (
    <Flex
      _hover={{ bg: "gray.100" }}
      alignItems="center"
      as="button"
      cursor="pointer"
      gap={2}
      px={4}
      py={2}
      textAlign="start"
      type="button"
      {...props}
    />
  );
}

Select.MenuGroup = MenuGroup;
Select.MenuItem = MenuItem;
