import { Instant } from "@js-joda/core";

/*! @ngInject */
export function mfShortTime($filter, $rootScope) {
  const getTimezoneByProps = (props) => {
    let timezone = props.find((prop) => prop.startsWith("timezone-"));
    if (timezone) timezone = timezone.slice("timezone-".length, timezone.length);
    return timezone;
  };

  const getFormatByProps = (props) => {
    let timeFormatKey =
      props.includes("military") || $rootScope.visitSettings.isMilitaryTime
        ? "militaryTime"
        : "amPm";
    timeFormatKey += "-" + (props.includes("withDate") ? "withDate" : "withoutDate");
    timeFormatKey += "-" + (props.includes("withSeconds") ? "withSeconds" : "withoutSeconds");

    switch (timeFormatKey) {
      case "militaryTime-withDate-withSeconds":
        return "MM/dd/yyyy HH:mm:ss";
      case "militaryTime-withDate-withoutSeconds":
        return "MM/dd/yyyy HH:mm";
      case "amPm-withDate-withSeconds":
        return "MM/dd/yyyy h:mm:ss a";
      case "amPm-withDate-withoutSeconds":
        return "MM/dd/yyyy h:mm a";
      case "militaryTime-withoutDate-withSeconds":
        return "HH:mm:ss";
      case "militaryTime-withoutDate-withoutSeconds":
        return "HH:mm";
      case "amPm-withoutDate-withSeconds":
        return "h:mm:ss a";
      case "amPm-withoutDate-withoutSeconds":
        return "h:mm a";
      default:
        console.error("No mfShortTime case defined to format key " + timeFormatKey);
        return false;
    }
  };

  const getDate = (date, props) => {
    if (typeof date === "string" && date.length === 5) {
      return new Date("2000-01-01T" + date);
    } else if (typeof date === "number" && !props.includes("withDate")) {
      return new Date(Math.abs(date));
    }
    return new Date(date);
  };

  // Example props: ['military', 'withDate', 'withSeoncds', 'timezone-UTC']
  return function mfShortTime(date, props = []) {
    if (!date) {
      return date;
    }

    const dt = getDate(date, props);
    const format = getFormatByProps(props);
    const timezone = getTimezoneByProps(props);

    if (!format || isNaN(dt.valueOf())) {
      return "";
    }

    const epochMilli = date instanceof Instant ? date.toEpochMilli() : date;

    return (epochMilli < 0 ? "-" : "") + $filter("date")(dt, format, timezone);
  };
}
