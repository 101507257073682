import { useQuery } from "@tanstack/react-query";
import useApi from "./useApi";

export default function useIntakeAgencyMembers() {
  const { api } = useApi();

  return useQuery({
    queryKey: ["agency_members", "intake", "list"],
    staleTime: Infinity,
    queryFn: () => api.get("./intake/agency_members", {}),
    select: (data) => {
      const agencyMembers = data.agencyMembers.sort((x) => (x.dev ? 1 : -1));
      return agencyMembers;
    },
  });
}
