import React from "react";
import { InitialTableState } from "@tanstack/react-table";
import { useSessionstorageState } from "rooks";
import { RouteState } from "../../modules/communication/components/TicketsTable";
import { defaultPagination } from "../utils/tanstack-table";

export function useTableSessionState(params: { key: string; initialState?: InitialTableState }) {
  const initialStateRef = React.useRef(params.initialState);

  const defaultState: RouteState = {
    sorting: initialStateRef.current?.sorting,
    pagination: defaultPagination,
  };

  const [pagination, setPagination] = useSessionstorageState<RouteState["pagination"] | null>(
    `table-pagination-${params.key}`,
    null
  );

  const [sorting, setSorting] = useSessionstorageState<RouteState["sorting"] | null>(
    `table-sorting-${params.key}`,
    null
  );

  const canReset = pagination !== null || sorting !== null;

  const reset = () => {
    setPagination(null);
    setSorting(null);
  };

  const state: RouteState = {
    pagination: pagination ?? defaultState.pagination,
    sorting: sorting ?? defaultState.sorting,
  };

  return { state, setPagination, setSorting, canReset, reset };
}
