import { Messages } from "../../../../core/api";
import { toTitleCase } from "../../../../utils";

export type EntityCommunicationsFilters = {
  type: EntityCommunicationsTicketType[];
  status: EntityCommunicationsTicketStatus[];
};

export type EntityCommunicationsAdjustedFilters = {
  type: EntityCommunicationsTicketSource[];
  status: EntityCommunicationsTicketStatus[];
};

export type EntityCommunicationsTicketSource = Messages["CommCenterTicketSource"] | "ROBOCALL";

const entityCommunicationsTicketTypes = ["Call", "Chat", "SMS", "Email", "Robocall"] as const;
export type EntityCommunicationsTicketType = typeof entityCommunicationsTicketTypes[number];
export const entityCommunicationsTicketTypeOptions = entityCommunicationsTicketTypes.map((x) => ({
  value: x,
  label: x,
}));

const entityCommunicationsTicketStatuses = ["NEW", "IN PROGRESS", "RESOLVED"] as const;
export type EntityCommunicationsTicketStatus = typeof entityCommunicationsTicketStatuses[number];
export const entityCommunicationsTicketStatusOptions = entityCommunicationsTicketStatuses.map(
  (status) => ({
    value: status,
    label: toTitleCase(status),
  })
);
