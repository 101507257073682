import { LocalDate, nativeJs, LocalDateTime, DateTimeFormatter, convert, DayOfWeek, TemporalAdjusters, ChronoUnit } from "@js-joda/core";

export const dateUtils = {
  dateToLocalDate: (date: Date) => LocalDate.from(nativeJs(date)),
  dateToLocalDateTime: (date: Date) => LocalDateTime.from(nativeJs(date)),
  ymdStringToLocalDate: (date: string) => LocalDate.parse(date),
  ymdStringToDate: (date: string) => convert(LocalDate.parse(date)).toDate(),
  localDateToDate: (localDate: LocalDate) => convert(localDate).toDate(),
  localDateToMDYString: (localDate: LocalDate) =>
    localDate.format(DateTimeFormatter.ofPattern("M/d/yyyy")),
  localDateTimeToMDYHMString: (localDateTime: LocalDateTime) =>
    localDateTime.format(DateTimeFormatter.ofPattern("M/d/yyyy HH:mm")),
  startOfWeek: (localDate: LocalDate, firstDayOfWeek: DayOfWeek) => {
    const offset = {
      [DayOfWeek.SUNDAY.ordinal()]: -1,
      [DayOfWeek.MONDAY.ordinal()]: 0,
      [DayOfWeek.TUESDAY.ordinal()]: 1,
      [DayOfWeek.WEDNESDAY.ordinal()]: 2,
      [DayOfWeek.THURSDAY.ordinal()]: 3,
      [DayOfWeek.FRIDAY.ordinal()]: 4,
      [DayOfWeek.SATURDAY.ordinal()]: 5,
    };

    return localDate
      .with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY))
      .plusDays(offset[firstDayOfWeek.ordinal()]);
  },
  getLocalDatesInRange: (params: { from: LocalDate; to: LocalDate }) => {
    const days: LocalDate[] = [];

    for (let i = 0; i <= params.from.until(params.to, ChronoUnit.DAYS); i++) {
      days.push(params.from.plusDays(i));
    }

    return days;
  },
};

export type DateUtils = typeof dateUtils;
