import { Box } from "@chakra-ui/react";
import React from "react";
import Viewer from "react-viewer";

interface Props {
  src: string;
  onClose?: () => void;
}

function DocumentViewer(props: Props) {
  const [ref, setRef] = React.useState<HTMLElement | null>(null);

  if ((props.src as string | undefined) === undefined) {
    // angularjs hotfix
    return null;
  }

  const isPdf = props.src.split("?")[0].toLowerCase().endsWith(".pdf");

  if (isPdf) {
    return (
      <Box h="full" w="full">
        <object data={props.src} height="100%" type="application/pdf" width="100%" />
      </Box>
    );
  }

  return (
    <>
      <Box ref={(newRef) => setRef(newRef)} h="full" w="full"></Box>
      {ref && (
        <Viewer
          changeable={false}
          container={ref}
          images={[{ src: props.src, alt: "" }]}
          noNavbar={true}
          visible={true}
          onClose={props.onClose}
        />
      )}
    </>
  );
}

export default DocumentViewer;
