import { Box, ButtonProps } from "@chakra-ui/react";

export function VisitAssignmentTicketRelationLayout(props: {
  colorScheme: ButtonProps["colorScheme"];
  children: React.ReactNode;
}) {
  return (
    <Box
      bg={`${props.colorScheme}.50`}
      borderBottomWidth={1}
      borderColor={`${props.colorScheme}.100`}
      borderTopWidth={1}
      color={`${props.colorScheme}.600`}
      fontWeight="medium"
      mt="-1px"
      p={4}
    >
      {props.children}
    </Box>
  );
}
