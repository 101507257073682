import { ChronoUnit, LocalDate, LocalDateTime } from "@js-joda/core";
import { Button } from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";

interface Props {
  nextCallDate: LocalDateTime | null;
}

const IntakeNextCallBadge = (props: Props) => {
  const getNextCallStyleProps = () => {
    if (props.nextCallDate === null) {
      return {
        text: "No call was scheduled",
        badgeColor: "gray" as const,
      };
    }
    if (props.nextCallDate.isBefore(LocalDateTime.now())) {
      return {
        text: "Due date has passed",
        badgeColor: "red" as const,
      };
    }

    const nextCallFromNowDays = LocalDate.now().until(
      props.nextCallDate.toLocalDate(),
      ChronoUnit.DAYS
    );
    const daysText = nextCallFromNowDays === 1 ? "day" : "days";
    const text =
      nextCallFromNowDays === 0 ? `Due Today` : `Due in ${nextCallFromNowDays} ${daysText}`;

    return {
      text,
      badgeColor: nextCallFromNowDays === 0 ? ("yellow" as const) : ("whatsapp" as const),
    };
  };

  const nextCallBadgeProps = getNextCallStyleProps();

  return (
    <Button
      _active={{ colorScheme: nextCallBadgeProps.badgeColor }}
      _hover={{ colorScheme: nextCallBadgeProps.badgeColor }}
      boxShadow="none"
      colorScheme={nextCallBadgeProps.badgeColor}
      cursor="default"
      fontSize="12px"
      height="25px"
      leftIcon={<PhoneIcon />}
      marginTop="3px"
      padding="10px"
      onClick={(event) => event.preventDefault()}
    >
      {nextCallBadgeProps.text}
    </Button>
  );
};

export default IntakeNextCallBadge;
