import { LocalDate, LocalDateTime, LocalTime, convert, nativeJs } from "@js-joda/core";
import moment from "moment";

export function MasterWeekService($http, Consts, wildcard) { return {
        /**
         * Get all master weeks of a patient
         */
        getMasterWeeks: ({ agencyId, agencyMemberId, patientId }) => {
            const url =
                Consts.api +
                "agencies/:agencyId/agency_members/:agencyMemberId/" +
                "patients/:patientId/master_weeks";
            return $http
                .get(wildcard(url, agencyId, agencyMemberId, patientId))
                .then((res) => res.data.masterWeeks)
                .then((weeks) =>
                    weeks.map((masterWeek) => {
                        masterWeek.week.periodDisplay = parsePeriodStringToJsDate(masterWeek.week.period);
                        parseNullToNone(masterWeek.week, masterWeek.days);
                        preparePotentialPayrollCodes(masterWeek.days);
                        parseDaysJsJodaToJsDate(masterWeek.days);

                        return masterWeek;
                    })
                );
        },

        /**
         * Insert a new master week and generate
         */
        insertMasterWeek: ({ agencyId, agencyMemberId, patientId, masterWeek }) => {
            const url =
                Consts.api +
                "agencies/:agencyId/agency_members/:agencyMemberId/" +
                "patients/:patientId/master_weeks";

            masterWeek.week.period = parsePeriodJsDateToLocalDate(masterWeek.week.periodDisplay);
            parseDaysLocalTime(masterWeek.days);
            parseNoneToNull(masterWeek.week, masterWeek.days);
            const payload = prepareMasterWeekForSave(masterWeek.week, masterWeek.days);

            return $http.post(
                //wildcard(url, agencyId, agencyMemberId, patientId),
                Consts.api + "agencies/" + agencyId + "/agency_members/" + agencyMemberId +
                "/patients/" + patientId + "/master_weeks",
                payload
            );
        },

        /**
         * Update a new master week and generate
         */
        updateMasterWeek: (
            masterWeekId,
            { agencyId, agencyMemberId, patientId, masterWeek }
        ) => {
            const url =
                Consts.api +
                "agencies/:agencyId/agency_members/:agencyMemberId/" +
                "patients/:patientId/master_weeks/:masterWeekId";

            masterWeek.week.period = parsePeriodJsDateToLocalDate(masterWeek.week.periodDisplay);
            parseDaysLocalTime(masterWeek.days);
            parseNoneToNull(masterWeek.week, masterWeek.days);
            const payload = prepareMasterWeekForSave(masterWeek.week, masterWeek.days);

            return $http.put(
                wildcard(url, agencyId, agencyMemberId, patientId, masterWeekId),
                payload
            ).then(res => res.data.list);
        },

        /**
         * Delete a new master week and generate
         */
        deleteMasterWeek: (
            masterWeekId,
            { agencyId, agencyMemberId, patientId }
        ) => {
            const url =
                Consts.api +
                "agencies/:agencyId/agency_members/:agencyMemberId/" +
                "patients/:patientId/master_weeks/:masterWeekId";
            return $http.delete(
                wildcard(url, agencyId, agencyMemberId, patientId, masterWeekId)
            );
        },
    } };

const prepareMasterWeekForSave = (week, days) => {
    const ids = getMappedIds(week);

    return {
        ...week,
        ...ids,
        days: days.map(day => ({
            ...day,
            ...getMappedCaregiverIds(day)
        }))
    };
}

const getMappedCaregiverIds = (day) => {
    return {
        caregiverId: day.caregiver && day.caregiver.id ? day.caregiver.id : null
    };
}

const getMappedIds = (week) => {
    const ids = {};

    ids.patientId = week.patient && week.patient.id ? week.patient.id : null;
    ids.serviceCodeId = week.serviceCode && week.serviceCode.id ? week.serviceCode.id : null;
    ids.payrollCodeId = week.payrollCode && week.payrollCode.id ? week.payrollCode.id : null;
    ids.patientContractId = week.contract && week.contract.id ? week.contract.id : null;

    return ids;
}

/**
 * Gets a display period object, parses into LocalDate and returns the result.
 * @param period
 */
const parsePeriodJsDateToLocalDate = (period) => {
    // Importing JSJoda here, since import at the start of file leads to AngularJS provider failure
    

    const { startDate, endDate } = period;

    return {
        startDate: LocalDate.from(nativeJs(startDate)),
        endDate: LocalDate.from(nativeJs(endDate)),
    };
};

/**
 * Gets a display days array and parses the hour dates to LocalTime.
 * @param days
 */
const parseDaysLocalTime = (days) => {
    // Importing JSJoda here, since import at the start of file leads to AngularJS provider failure
    

    for (const day of days) {
        const { startHour } = day.periodDisplay;

        if (startHour !== undefined) {
            day.period.startHour = LocalTime.from(nativeJs(startHour));
        }
    }
};

/**
 * Gets a days array from server and parses the LocalTime to jsDate into periodDisplay for html inputs.
 * @param days
 */
const parseDaysJsJodaToJsDate = (days) => {
    // Importing JSJoda here, since import at the start of file leads to AngularJS provider failure
    

    for (const day of days) {
        const { startHour, endHour, durationInMinutes } = day.period;
        day.periodDisplay = {};

        if (startHour !== undefined) {
            day.periodDisplay.startHour = new Date("01/01/1970 " + startHour);
        }
        if (endHour !== undefined) {
            day.periodDisplay.endHour = new Date("01/01/1970 " + endHour);
        } else {
            day.periodDisplay.endHour = convert(
                LocalDateTime.from(nativeJs(day.periodDisplay.startHour)).plusMinutes(
                    durationInMinutes
                )
            ).toDate();
        }
    }
};

/**
 * Gets a period object from the server then parses it to jsDate for the html inputs.
 * @param period
 */
const parsePeriodStringToJsDate = (period) => {
    const { startDate, endDate } = period;

    return {
        startDate: moment(startDate),
        endDate: moment(endDate),
    };
};

/**
 * Gets array of days and parses empty objects(id == null) to null for the server.
 * @param days
 */
const parseNoneToNull = (week, days) => {
    const { contract, serviceCode, payrollCode } = week;

    if (contract && !contract.id) {
        week.contract = null;
    }

    if (serviceCode && !serviceCode.id) {
        week.serviceCode = null;
    }

    if (payrollCode && !payrollCode.id) {
        week.payrollCode = null;
    }

    for (const day of days) {
        const { caregiver } = day;

        if (caregiver && !caregiver.id) {
            day.caregiver = null;
        }
    }
};

function noneSelectedObj() {
    return {
        id: null,
        name: "None",
    };
}

/**
 * Gets array of days and parses null from the server to display empty objects(id == null).
 * @param days
 */
const parseNullToNone = (week, days) => {
    if (week.contract === null) {
        week.contract = noneSelectedObj();
    }

    if (week.serviceCode === null) {
        week.serviceCode = noneSelectedObj();
    }

    if (week.payrollCode === null) {
        week.payrollCode = noneSelectedObj();
    }

    for (const day of days) {
        if (day.caregiver === null) {
            day.caregiver = noneSelectedObj();
        }
    }
};

/**
 * Gets array of days and adds an array with empty object for the display inputs.
 * @param days
 */
const preparePotentialPayrollCodes = (week) => {
    week.potentialPayrollCodes = [
        {
            id: null,
            name: "None",
        },
    ];
};