import { AddIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import { dateFormatter } from "../../../../../../../shared/utils/date-formatter";
import CallTicketIndexBadge from "../../../../CallTicketIndexBadge";
import CallTicketText from "../../CallTicketText";
import { getParticipantDescription } from "../call-ticket-event-utils";
import { Messages } from "../../../../../../../core/api";

interface Props {
  event: Messages["TelephonyCallParticipantEvent"] & { data: { name: "BargedIn" } };
}

const CallTicketEventCardBarge = ({ event }: Props) => {
  return (
    <Flex alignItems="center" gap={2}>
      <CallTicketIndexBadge>
        <AddIcon />
      </CallTicketIndexBadge>
      <Flex direction="column">
        <CallTicketText color="gray.400">
          {dateFormatter.toDateTime(event.timestamp)}
        </CallTicketText>
        <Flex gap={1}>
          <CallTicketText fontWeight="bold">
            {getParticipantDescription(event.data.participant)}
          </CallTicketText>
          <CallTicketText>has barged in.</CallTicketText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CallTicketEventCardBarge;
