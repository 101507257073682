import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../query-keys";
import useApi from "./useApi";

const useCacheSignedUrlQuery = (url: string) => {
  const { api } = useApi();
  const actualUrl = url.split("?")[0];

  const query = useQuery({
    queryKey: queryKeys.shared.image(actualUrl),
    queryFn: async () => {
      if (url.startsWith("blob:")) {
        return url;
      }

      if (url.startsWith("s3://")) {
        const signed = await api.get("./get_signed_photo_url", { query: { url } });
        const response = await fetch(signed.url);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      }

      console.log("Fetching signed URL", url);
      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },
    staleTime: Infinity,
  });
  return query;
};

export default useCacheSignedUrlQuery;
