import { Avatar, Box, Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { Duration, Instant } from "@js-joda/core";
import { useMutation } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import SafeHTML from "../../../shared/components/SafeHTML";
import useApi from "../../../shared/hooks/useApi";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import { NoteCommentId, NoteId } from "../../../shared/schema/schema";
import { durationFormatter } from "../../../shared/utils/duration-formatter";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { getFullName } from "../../../shared/utils/get-full-name";
import RemoveNoteCommentModal from "./RemoveNoteCommentModal";

type Props = {
  comment: Messages["NoteComment"];
  onRemoveComment: (comment: { id: NoteCommentId; noteId: NoteId }) => void;
};

export default function NoteComment(props: Props) {
  const { agencyMember } = useAuthData();
  const removeCommentModalDisclosure = useDisclosure();
  const { api } = useApi();
  const toast = useToast();

  const canRemoveComment = props.comment.author.id === agencyMember.id || agencyMember.agencyAdmin;

  const removeCommentMutation = useMutation({
    mutationFn: () => {
      return api.delete("./notes/:noteId/comment/:commentId", {
        path: { commentId: props.comment.id, noteId: props.comment.noteId },
      });
    },
    onSuccess: () => {
      removeCommentModalDisclosure.onClose();
      props.onRemoveComment({ id: props.comment.id, noteId: props.comment.noteId });

      toast({
        title: "Comment removed",
        status: "info",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error removing comment",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Box key={props.comment.id} data-group>
      <Flex align="flex-start" gap={3}>
        <Avatar
          name={getFullName(props.comment.author)}
          size="xs"
          src={props.comment.author.photoUrl ?? undefined}
        />

        <Flex direction="column">
          <Flex align="flex-end" gap={2} w="full">
            <Text fontWeight="semibold">{getFullName(props.comment.author)}</Text>

            <Text color="gray.500" fontSize="sm" mt={1}>
              {
                durationFormatter.relative(Duration.between(Instant.now(), props.comment.createdAt))
                  .formatted
              }
            </Text>

            {canRemoveComment && (
              <Button
                _groupHover={{ display: "block" }}
                display="none"
                size="sm"
                variant="link"
                onClick={removeCommentModalDisclosure.onOpen}
              >
                Remove
              </Button>
            )}
          </Flex>
          <Box color="gray.600" w="full">
            {props.comment.commentRichText !== null && (
              <SafeHTML html={props.comment.commentRichText} />
            )}
          </Box>
        </Flex>
      </Flex>

      <RemoveNoteCommentModal
        disclosure={removeCommentModalDisclosure}
        isLoading={removeCommentMutation.isPending}
        onClickRemove={removeCommentMutation.mutate}
      />
    </Box>
  );
}
