import {
  Button,
  ButtonGroup,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { IntakeChecklistItemId } from "../../../../shared/schema/schema";
import { ChecklistWithBlocking } from "../../shared/utils/intakeChecklist.utils";

interface Props {
  disclosure: ReturnType<typeof useDisclosure>;
  item: ChecklistWithBlocking[number] | undefined;
  onConfirm: (ids: IntakeChecklistItemId[]) => Promise<void>;
}

function ConfirmBulkUncheckModal(props: Props) {
  return (
    <Modal {...props.disclosure} size="2xl">
      <ModalOverlay />
      {props.item === undefined ? (
        <LoadingModalContent onClose={props.disclosure.onClose} />
      ) : (
        <ConfirmModalContent
          item={props.item}
          onClose={props.disclosure.onClose}
          onConfirm={props.onConfirm}
        />
      )}
    </Modal>
  );
}

const LoadingModalContent = (props: { onClose: () => void }) => {
  return (
    <ModalContent>
      <ModalHeader>Loading...</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Spinner size="xl" />
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={props.onClose}>
          Close
        </Button>
        <Button colorScheme="blue" isLoading={true}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const ConfirmModalContent = (props: {
  onClose: () => void;
  item: ChecklistWithBlocking[number];
  onConfirm: (ids: IntakeChecklistItemId[]) => Promise<void>;
}) => {
  const onConfirmItems = [...props.item.blocking.map((b) => b.id), props.item.id];
  const itemBlockingLength = props.item.blocking.length;
  const itemBlockingLengthItemsPlural = itemBlockingLength > 1 ? "s" : "";
  const itemBlockingLengthPlural = itemBlockingLength > 1 ? "are" : "is";

  return (
    <ModalContent>
      <ModalHeader>{props.item.name}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text fontSize={14}>
          {`There ${itemBlockingLengthPlural} ${itemBlockingLength} dependent item${itemBlockingLengthItemsPlural} that ${itemBlockingLengthPlural} currently checked and will be unchecked:`}
        </Text>

        <UnorderedList px={3} py={3}>
          {props.item.blocking.map((blockedItem) => (
            <ListItem key={blockedItem.id}>
              <Text fontSize={14}>{blockedItem.name}</Text>
            </ListItem>
          ))}
        </UnorderedList>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="ghost" onClick={props.onClose}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={() => props.onConfirm(onConfirmItems)}>
            Confirm
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  );
};

export default ConfirmBulkUncheckModal;
