import { createIcon } from "@chakra-ui/react";

const CheckDoubleFillIcon = createIcon({
  displayName: "CheckDoubleFillIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m11.602 13.76l1.412 1.412l8.466-8.466l1.414 1.414l-9.88 9.88l-6.364-6.364l1.414-1.414l2.125 2.125l1.413 1.412zm.002-2.828l4.952-4.953l1.41 1.41l-4.952 4.953l-1.41-1.41zm-2.827 5.655L7.364 18L1 11.636l1.414-1.414l1.413 1.413l-.001.001l4.951 4.951z"
      fill="currentColor"
    />
  ),
});

export default CheckDoubleFillIcon;
