import {
  Box,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ControlledForm } from "../../../../../../shared/hooks/useControlledForm";
import { zVisitAssignment } from "./visit-assignment-form";
import EntityFormControl from "../../../../../workflow/components/EntityFormControl";
import { VisitAssignmentReason } from "../../../../../../shared/schema/gql/graphql";
import Select from "../../../../../../shared/components/Select";
import SelectNoResults from "../../../../../../shared/components/SelectNoResults";
import { RequiredAst } from "../../../../../workflow/components/RequiredAst";
import { PatientId } from "../../../../../../shared/schema/schema";

type Props = {
  form: ControlledForm<typeof zVisitAssignment>;
};

function NewVisitAssignmentModalBody(props: Props) {
  const { form } = props;
  const hasReasonError = form.errors.reason !== undefined ? form.errors.reason.length > 0 : false;
  const isVisitSelectionDisabled =
    form.state.patientId === undefined || form.state.patientId === null;

  const setPatient = (patientId: PatientId | null) => {
    form.setValue("patientId", patientId);
    form.setValue("visitBroadcasts", []);
    form.setValue("visitInstances", []);
  };

  return (
    <ModalBody>
      <VStack align="strech" spacing={6}>
        <FormControl isInvalid={hasReasonError}>
          <FormLabel>
            Reason
            <RequiredAst />:
          </FormLabel>

          <Select
            buttonProps={{ w: "full" }}
            label="Select reason"
            multiple={false}
            options={Object.values(VisitAssignmentReason).map((x) => ({
              value: x,
              label: x,
            }))}
            renderAfter={({ searchTerm, filteredOptions }) =>
              filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
            }
            value={form.state.reason ?? null}
            onChange={(x) => form.setValue("reason", x ?? null)}
          />

          {hasReasonError && <FormErrorMessage>Required</FormErrorMessage>}
        </FormControl>

        <Card>
          <CardBody>
            <VStack alignItems="flex-start" spacing={5}>
              <VStack spacing={2}>
                <EntityFormControl
                  errors={form.errors.patientId ?? []}
                  input={{ type: "entity", entity: "Patient" }}
                  isRequired={true}
                  label="Patient"
                  multiple={false}
                  showLabel={true}
                  value={form.state.patientId ?? null}
                  onChange={setPatient}
                />

                <Text color="gray.500" fontSize="md">
                  Please select a patient to enable visit selection
                </Text>
              </VStack>

              <EntityFormControl
                errors={form.errors.caregiverId ?? []}
                input={{ type: "entity", entity: "Caregiver" }}
                isRequired={false}
                label="Caregiver"
                multiple={false}
                showLabel={true}
                value={form.state.caregiverId ?? null}
                onChange={(x) => form.setValue("caregiverId", x)}
              />
            </VStack>
          </CardBody>
        </Card>

        <Box
          opacity={isVisitSelectionDisabled ? 0.5 : 1}
          pointerEvents={isVisitSelectionDisabled ? "none" : "auto"}
        >
          <Card bg={isVisitSelectionDisabled ? "gray.100" : "auto"}>
            <CardBody>
              <Flex gap={10}>
                <EntityFormControl
                  errors={form.errors.visitInstances ?? []}
                  hardFilters={{
                    patientId: form.state.patientId,
                  }}
                  input={{ type: "entity", entity: "Visit Instance" }}
                  isRequired={true}
                  label="Visit instance/s"
                  multiple={true}
                  showLabel={true}
                  value={form.state.visitInstances ?? []}
                  onChange={(x) => form.setValue("visitInstances", x ?? [])}
                />

                <EntityFormControl
                  errors={form.errors.visitBroadcasts ?? []}
                  hardFilters={{
                    patientId: form.state.patientId,
                  }}
                  input={{ type: "entity", entity: "Visit Broadcast" }}
                  isRequired={false}
                  label="Visit broadcast/s"
                  multiple={true}
                  showLabel={true}
                  value={form.state.visitBroadcasts ?? []}
                  onChange={(x) => form.setValue("visitBroadcasts", x ?? [])}
                />
              </Flex>
            </CardBody>
          </Card>
        </Box>
      </VStack>
    </ModalBody>
  );
}

export default NewVisitAssignmentModalBody;
