import React from "react";
import usePreSignUpload from "../../../shared/hooks/usePreSignUpload";

export default function useAttachments() {
  const [attachments, setAttachments] = React.useState<File[]>([]);

  const uploadAttachments = usePreSignUpload();

  const handleRemoveAttachment = (attachment: File) => {
    setAttachments(attachments.filter((i) => i !== attachment));
  };

  const handleRemoveAllAttachments = () => {
    setAttachments([]);
  };

  const handleAddFile = (newFile: File) => {
    setAttachments([...attachments, newFile]);
  };

  return {
    upload: uploadAttachments,
    clear: handleRemoveAllAttachments,
    add: handleAddFile,
    remove: handleRemoveAttachment,
    state: attachments,
  };
}
