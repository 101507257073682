import { createIcon } from "@chakra-ui/react";

const ResetIcon = createIcon({
  displayName: "ResetIcon",
  viewBox: "0 0 15 15",
  path: (
    <path
      clipRule="evenodd"
      d="M4.854 2.146a.5.5 0 0 1 0 .708L3.707 4H9a4.5 4.5 0 1 1 0 9H5a.5.5 0 0 1 0-1h4a3.5 3.5 0 1 0 0-7H3.707l1.147 1.146a.5.5 0 1 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708 0Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export default ResetIcon;
