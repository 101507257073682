import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
} from "@chakra-ui/react";

export type ButtonMenuItem<TButtonId> = {
  id: TButtonId;
  label: string;
  type: ButtonProps["type"];
  leftIcon?: React.ReactElement;
} & (
  | {
      type: "submit";
      onClick?: never;
    }
  | {
      type: "button";
      onClick: () => void;
    }
);

type Props<TButtonId, TButtonMenuItem extends ButtonMenuItem<TButtonId>> = {
  activeButtonId: TButtonId;
  onChangeActiveButton: (item: TButtonMenuItem) => void;
  menuItems: TButtonMenuItem[];
  buttonGroupProps?: ButtonGroupProps;
  menuProps?: MenuProps;
};

function ButtonWithMenu<TButtonId, TButtonMenuItem extends ButtonMenuItem<TButtonId>>(
  props: Props<TButtonId, TButtonMenuItem>
) {
  const { menuProps, buttonGroupProps } = props;
  const activeButton = props.menuItems.find((button) => button.id === props.activeButtonId);
  if (activeButton === undefined) {
    throw new Error(`No button found with id ${props.activeButtonId}`);
  }
  return (
    <ButtonGroup isAttached {...buttonGroupProps}>
      <Button
        leftIcon={activeButton.leftIcon}
        type={activeButton.type}
        onClick={() => activeButton.onClick?.()}
      >
        {activeButton.label}
      </Button>
      <Menu placement={`${menuProps?.placement ?? "bottom-end"}`} {...menuProps}>
        <MenuButton
          as={IconButton}
          borderLeftColor={`${buttonGroupProps?.colorScheme}.400`}
          borderLeftWidth={2}
          icon={<ChevronDownIcon />}
        />
        <MenuList>
          {props.menuItems.map((button) => (
            <MenuItem key={button.label} onClick={() => props.onChangeActiveButton(button)}>
              {button.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </ButtonGroup>
  );
}

export default ButtonWithMenu;
