import { LocalDate } from "@js-joda/core";
import moment from "moment";

export const contractAndPayment = {
    templateUrl: 'admin/views/new-visit/new-visit-contract-and-payment.html',
    bindings: {
        patient: '<',
        form: '=',
        isEditMode: '<',
        onClickToggleEditMode: '&',
        handleCertificationsChanged: '&',
        shouldFilterByAuthorization: '<',
        isParentEditMode: '<',
        mixedContent: '<',
        editModeToggle: '<',
    },
    //! @ngInject
    controller: function ($scope, $rootScope, DatabaseApi, wildcard, toaster, $filter) {

		const initialize = () => {
			$scope.initialized = true;
			initCertifications();
			initContracts();
		};

		const initCertifications = () => {
			const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
			$scope.certifications = activeAgencyCertifications
				.map(certificationItem => certificationItem.certification);
		}

        const initContracts = () => {
			$scope.$ctrl.patient.contracts = $filter("onlyActivePatientContracts")(
				$scope.$ctrl.patient.contracts,
				false
			).map(contract => ({
				...contract,
				label: $filter("formatPatientContract")(contract)
			}));

			$scope.serviceCodes = DatabaseApi.activeServiceCodes().map(sc => ({
				...sc,
				label: `${sc.code} (${sc.certification})`
			}));
			if (!Array.isArray($scope.serviceCodes) || $scope.serviceCodes.length === 0) {
				$scope.serviceCodes = [{id: null, label: "Empty (no code)", certification: "HHA"}];
			}

			$scope.payrollCodes = DatabaseApi.payrollCodes();

			if ($scope.$ctrl.shouldFilterByAuthorization) {
				initAuthorizations();
			}

			if (!$rootScope.showBillingFeature) {
				if ($scope.$ctrl.patient.contracts.length === 0) {
					toaster.pop("error", "Oops... Something went wrong", "Please contact Medflyt");
				} else {
					const defaultContract = angular.copy($scope.$ctrl.patient.contracts[0]);
					const defaultContractTypeId = defaultContract.contractTypeId;
					const defaultServiceCodeId = $scope.serviceCodes[0].id;
					
					$scope.contractServiceCodesMap = {};
					$scope.contractServiceCodesMap[defaultContractTypeId] = [defaultServiceCodeId];
					$scope.contractsOptions = [defaultContract];
					$scope.$ctrl.form.contract = angular.copy(defaultContract);
					handleChangeContract();

					initServiceCodes();
					initEditModeToggleTarget();
				}
			} else {
				if ($scope.$ctrl.patient.contracts.length === 0) {
					toaster.pop("error", "Error", "No available contracts for visit");
				} else {
					const url = wildcard(
							"agencies/:agencyId/contracts/billing_rates",
							$rootScope.agencyId
						) + "?ids=" + $scope.$ctrl.patient.contracts.map(contract =>
							contract.contractTypeId
						).join(",");
	
					DatabaseApi.get(url).then((res) => {
						const arrContractTypes = res.data.contractTypes;
						$scope.contractServiceCodesMap = {};
						arrContractTypes.forEach(contractType => {
							const serviceCodesIds = contractType.billingRates
								.filter(billRate =>
									billRate.endDate === null || !moment().isAfter(billRate.endDate)
								)
								.map(billRate => billRate.serviceCode);
							if (serviceCodesIds.length > 0) {
								$scope.contractServiceCodesMap[contractType.contractTypeId] = serviceCodesIds;
							}
						});
						$scope.allContractsOptions = $scope.$ctrl.patient.contracts.filter(contract =>
							$scope.contractServiceCodesMap[contract.contractTypeId] !== undefined
						);
						filterContractOptionsByAuthorizations();
						initServiceCodes();
						initEditModeToggleTarget();
					}, (err) => {
						toaster.pop("error", "Oops..", "Something went wrong");
					});
				}
			}
	
			$scope.contractsExtraSettings = {
				styleActive: true,
				selectionLimit: 1,
				singleSelection: true,
				closeOnSelect: true,
				smartButtonMaxItems: 1,
				showCheckAll: false,
				showUncheckAll: false
			};
	
			$scope.contractsEvents = {
				onSelectionChanged: function () {
					if ($scope.$ctrl.form.contract.id) {
						handleChangeContract();
					}
				}
			};
		};
	
		const initServiceCodes = () => {
			initServiceCodesOptions();

			$scope.serviceCodesExtraSettings = {
				styleActive: true,
				selectionLimit: 1,
				singleSelection: true,
				closeOnSelect: true,
				smartButtonMaxItems: 1,
				showCheckAll: false,
				showUncheckAll: false
			};
	
			$scope.serviceCodesEvents = {
				onSelectionChanged: function () {
					handleChangeServiceCode();
				}
			}

			initPayrollCodes();
		};
	
		const initServiceCodesOptions = () => {
			if ($scope.$ctrl.form.contract.id && $scope.contractServiceCodesMap !== undefined) {
				if (!$rootScope.showBillingFeature) {
					$scope.serviceCodesOptions = [$scope.serviceCodes[0]];
				} else {
					$scope.serviceCodesOptions = $scope.serviceCodes.filter(sc => 
						$scope.contractServiceCodesMap[$scope.$ctrl.form.contract.contractTypeId + '']
							.find(contractSc => contractSc === sc.id) !== undefined
					);
					$scope.serviceCodesOptions.forEach(scOption => {
						if ($scope.hasServiceCodeOptionCurrent === undefined) {
							scOption.serviceCodeName = angular.copy(scOption.label);
							if (scOption.id === $scope.originalServiceCodeId && $scope.$ctrl.isParentEditMode) {
								scOption.label += " (current)";
								$scope.hasServiceCodeOptionCurrent = true;
							}
						}
					});
					if (
						$scope.authorizations !== undefined &&
						$scope.authorizations.isFiltering &&
						$scope.authorizations.contractIds.includes($scope.$ctrl.form.contract.id)
					) {
						$scope.serviceCodesOptions = $scope.serviceCodesOptions.filter(scOption =>
							$scope.authorizations.contractServiceCodesMap[$scope.$ctrl.form.contract.id + '']
								.includes(scOption.id)
							|| scOption.id === $scope.originalServiceCodeId
						);
					}
				}

				const serviceCode = $scope.serviceCodesOptions.find(sc =>
					sc.id === $scope.$ctrl.form.serviceCode.id
				);

				if (!$rootScope.showBillingFeature && !serviceCode || $scope.serviceCodesOptions.length === 1) {
					$scope.$ctrl.form.certifications = [$scope.serviceCodesOptions[0].certification];

					if ($scope.serviceCodesOptions.length === 1) {
						$scope.$ctrl.form.serviceCode = angular.copy($scope.serviceCodesOptions[0]);
					} else {
						$scope.$ctrl.form.serviceCode = {};
					}

					$scope.$ctrl.handleCertificationsChanged();
				} else if (!serviceCode) {
					$scope.$ctrl.form.serviceCode = {};
				}
			} else {
				$scope.serviceCodesOptions = [];
				$scope.$ctrl.form.serviceCode = {};
			}
			handleChangeServiceCode();
		};
	
		const initPayrollCodes = () => {
			initPayrollCodesOptions();
	
			$scope.payrollCodesExtraSettings = {
				styleActive: true,
				selectionLimit: 1,
				singleSelection: true,
				closeOnSelect: true,
				smartButtonMaxItems: 1,
				displayProp: "displayId",
				showCheckAll: false,
				showUncheckAll: false
			};
	
			$scope.payrollCodesEvents = {
				onSelectionChanged: function () {
					if ($scope.$ctrl.form.payrollCode.id) {
						handleChangePayrollCode();
					}
				}
			};
		};
	
		const initPayrollCodesOptions = () => {
			const prevPayrollCodeId = $scope.$ctrl.form.payrollCode.id;
			$scope.$ctrl.form.payrollCode = {};
			if (
				$scope.$ctrl.form.serviceCode.id === undefined ||
				$scope.$ctrl.form.serviceCode.id === null ||
				$scope.payrollCodes === undefined
			) {
				$scope.payrollCodesOptions = [];
			} else {
				const serviceCode = $scope.serviceCodesOptions.find(sc =>
					sc.id === $scope.$ctrl.form.serviceCode.id
				);
				$scope.payrollCodesOptions = angular.copy($scope.payrollCodes);
				if (serviceCode) {
					$scope.payrollCodesOptions = $scope.payrollCodesOptions.filter(payrollCode =>
						serviceCode.payrollCodes.some(pcId => payrollCode.id === pcId)
							&& payrollCode.isActive
					);
				}

				let previousPayrollCode = undefined;
				if (prevPayrollCodeId) {
					previousPayrollCode = $scope.payrollCodesOptions.find(
						pc => pc.id === prevPayrollCodeId
					);
				}

				// assign if paycode is found
				if (previousPayrollCode) {
					$scope.$ctrl.form.payrollCode = angular.copy(previousPayrollCode);
				// assign if there is only one paycode option
				} else if ($scope.payrollCodesOptions.length === 1) {
					$scope.$ctrl.form.payrollCode = angular.copy($scope.payrollCodesOptions[0]);
				// assign empty if no paycode options at all
				} else if ($scope.payrollCodesOptions.length === 0) {
					$scope.payrollCodesOptions.push({ id: -1, displayId: "Empty (no code)" });
					$scope.$ctrl.form.payrollCode = angular.copy($scope.payrollCodesOptions[0]);
				}
			}
		};
	
		const handleChangeContract = () => {
			const contract = $scope.contractsOptions.find(c => c.id === $scope.$ctrl.form.contract.id);
			if (contract !== undefined) {
				$scope.$ctrl.form.contract = angular.copy(contract);
			} else {
				$scope.$ctrl.form.contract = {};
			}
			if ($scope.initialValues) {
				const hasChangedToInitialValue = ($scope.$ctrl.form.contract.id === $scope.initialValues.contractId);
				if ($scope.$ctrl.form.contract.id !== undefined && !hasChangedToInitialValue) {
					$scope.$ctrl.editModeToggle["serviceCode"] = true;
				} else {
					if ($scope.editModeToggleTarget.section !== "payrollCode") {
						$scope.$ctrl.editModeToggle["serviceCode"] = false;
					}
				}
				if ($scope.editModeToggleTarget.section !== "contract") {
					$scope.editModeToggleTarget.forceEdit = !hasChangedToInitialValue;
				}
			}
			initServiceCodesOptions();
		};

		const handleChangeServiceCode = () => {
			if ($scope.$ctrl.form.serviceCode.id || $scope.$ctrl.form.serviceCode.id === null) {
				$scope.$ctrl.form.serviceCode = angular.copy($scope.serviceCodesOptions.find(sc =>
					sc.id === $scope.$ctrl.form.serviceCode.id
				));
				const hasFormCertifications = ($scope.$ctrl.form.certifications && $scope.$ctrl.form.certifications.length > 0);
				if (
					!hasFormCertifications &&
					$scope.$ctrl.form.serviceCode.certification &&
					(!$scope.$ctrl.mixedContent || !$scope.$ctrl.mixedContent.certifications)
				) {
					$scope.$ctrl.form.certifications = [$scope.$ctrl.form.serviceCode.certification];

					if (
						$scope.$ctrl.form.serviceCode.certification === 'PCA'
						&& $scope.certifications.includes('HHA')
						&& !$scope.$ctrl.form.certifications.includes('HHA')
					) {
						$scope.$ctrl.form.certifications.push('HHA');
					}
					$scope.$ctrl.handleCertificationsChanged();
				}
			} else {
				$scope.$ctrl.form.serviceCode = {};
			}
			if ($scope.initialValues) {
				const hasChangedToInitialValue = ($scope.$ctrl.form.serviceCode.id === $scope.initialValues.serviceCodeId);
				if ($scope.$ctrl.form.serviceCode.id !== undefined && !hasChangedToInitialValue) {
					$scope.$ctrl.editModeToggle["payrollCode"] = true;
				} else {
					$scope.$ctrl.editModeToggle["payrollCode"] = false;
				}
				if ($scope.editModeToggleTarget.section === "payrollCode") {
					$scope.editModeToggleTarget.forceEdit = !hasChangedToInitialValue;
				}
			}
			initPayrollCodesOptions();
		};

		const handleChangePayrollCode = () => {
			if ($scope.$ctrl.form.payrollCode.id === -1) {
				return;
			}
			$scope.$ctrl.form.payrollCode = angular.copy($scope.payrollCodes.find(pc =>
				pc.id === $scope.$ctrl.form.payrollCode.id
			));
			if ($scope.$ctrl.form.payrollCode === undefined) {
				$scope.$ctrl.form.payrollCode = {};
			}
		};

		const initAuthorizations = () => {
			if (!$rootScope.showBillingFeature) {
				return;
			}

			const filteredAuthorizations = $scope.$ctrl.patient.authorizations.filter(auth =>
				auth.endDate === null ||
				auth.endDate === undefined ||
				LocalDate.parse(auth.endDate).isAfter(LocalDate.now())
			);

			const contractServiceCodesMap = {};
			filteredAuthorizations.forEach(auth => {
				if (contractServiceCodesMap[auth.patientContractId] === undefined) {
					contractServiceCodesMap[auth.patientContractId] = [auth.serviceCode];
				} else if (!contractServiceCodesMap[auth.patientContractId].includes(auth.serviceCode)) {
					contractServiceCodesMap[auth.patientContractId].push(auth.serviceCode);
				}
			});

			const contractIds = Object.keys(contractServiceCodesMap).map(x => Number(x));

			$scope.authorizations = {
				contractServiceCodesMap: contractServiceCodesMap,
				contractIds: contractIds,
				isFiltering:  $scope.authorizations === undefined ?
					$scope.$ctrl.patient.authorizations.length > 0 :
					$scope.authorizations.isFiltering,
			};

			$scope.toggleAuthorizationsFilter = () => {
				if ($scope.authorizations === undefined) {
					return;
				}
				$scope.authorizations.isFiltering = !$scope.authorizations.isFiltering;
				filterContractOptionsByAuthorizations();
			};
		}

		const filterContractOptionsByAuthorizations = () => {
			$scope.contractsOptions = angular.copy($scope.allContractsOptions);
			if ($scope.$ctrl.isParentEditMode) {
				$scope.contractsOptions.forEach(contractOption => {
					if (contractOption.id === $scope.originalContractId) {
						contractOption.label += " (current)";
					}
				});
			}
			if (
				$scope.authorizations !== undefined &&
				$scope.authorizations.isFiltering &&
				$scope.authorizations.contractIds.length > 0
			) {
				$scope.contractsOptions = $scope.contractsOptions.filter(contractOption =>
					$scope.authorizations.contractIds.includes(contractOption.id)
					|| (contractOption.id === $scope.originalContractId && $scope.$ctrl.isParentEditMode)
				);
			}
			if ($scope.contractsOptions.length === 1) {
				$scope.$ctrl.form.contract = angular.copy($scope.contractsOptions[0]);
			}
			handleChangeContract();
		};

		const initEditModeToggleTarget = () => {
			if ($scope.initialValues !== undefined) {
				return;
			}

			$scope.initialValues = {
				contractId: $scope.$ctrl.form.contract.id,
				serviceCodeId: $scope.$ctrl.form.serviceCode.id,
				payrollCodeId: $scope.$ctrl.form.payrollCode.id
			};

			$scope.editModeToggleTarget = {
				section: "",
				forceEdit: false
			};
			if ($scope.$ctrl.mixedContent) {
				if ($scope.$ctrl.mixedContent.contracts) {
					$scope.editModeToggleTarget.section = "contract";
				} else if ($scope.$ctrl.mixedContent.serviceCodes) {
					$scope.editModeToggleTarget.section = "serviceCode";
					$scope.$ctrl.editModeToggle["contract"] = true;
				} else if ($scope.$ctrl.mixedContent.payrollCodes) {
					$scope.editModeToggleTarget.section = "payrollCode";
					$scope.$ctrl.editModeToggle["contract"] = true;
					$scope.$ctrl.editModeToggle["serviceCode"] = true;
				}
			}
		};

		$scope.$watch("$ctrl.form.contract", () => {
			if ($scope.$ctrl.form.contract.id && $scope.originalContractId === undefined) {
				$scope.originalContractId = $scope.$ctrl.form.contract.id;
				$scope.originalServiceCodeId = $scope.$ctrl.form.serviceCode.id;
				initialize();
			}
		});

        this.$onInit = () => {
			initialize();
        };

		this.$onChanges = (changes) => {
            if ('isEditMode' in changes) {
				if ($scope.initialized === true) {
					initServiceCodes();
				}
			}
        };
    }
};