import { Button, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../../core/api";
import ErrorPage from "../../../../shared/components/ErrorPage";
import LoadingPage from "../../../../shared/components/LoadingPage";
import Page from "../../../../shared/components/Page";
import {
  IntakeExternalFlowId,
  IntakeStatusId,
  IntakeTrackId,
  IntakeTrackStepFieldId,
  IntakeTrackStepFieldValueId,
  IntakeTrackStepId,
} from "../../../../shared/schema/schema";
import AskForConfirmation from "./Components/AskForConfirmation";
import ExternalFlowModal from "./Components/ExternalFlowModal";
import IntakeFlowStepForm from "./Components/IntakeFlowStepForm";
import Sidebar from "./Components/Sidebar";
import SidebarHeader from "./Components/SidebarHeader";
import SidebarSteps from "./Components/SidebarSteps";
import StepCard from "./Components/StepCard";
import { createEmptyStep, toIntakeStepForm } from "./flow-settings.utils";
import useAskForConfirmation from "./hooks/useAskForConfirmation";
import usePatientIntakeFlowSettings from "./hooks/usePatientIntakeFlowSettings";
import usePatientIntakeStepForm from "./hooks/usePatientIntakeStepForm";
import CopyStepModal from "./Components/CopyStepModal";
import useCopyStep from "./hooks/useCopyStep";

export default function PatientIntakeFlowSettingsPage() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [trackFilter, setTrackFilter] = React.useState<IntakeTrackId[] | null>(null);
  const [intakeStatusFilter, setIntakeStatusFilter] = React.useState<IntakeStatusId[] | null>(null);
  const [externalFlowFilter, setExternalFlowFilter] = React.useState<IntakeExternalFlowId[] | null>(
    null
  );
  const { query, removeExternalFlowMutation, createExternalFlowMutation } =
    usePatientIntakeFlowSettings();
  const form = usePatientIntakeStepForm();
  const confirmDisclosure = useDisclosure();
  const confirm = useAskForConfirmation(confirmDisclosure);
  const externalFlowDisclosure = useDisclosure();

  const handleClickNew = () => {
    form.setState(createEmptyStep());
  };

  const handleClickStep = (step: Messages["IntakeTrackStepForFullFlow"]) => {
    form.setState(toIntakeStepForm(step));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.submit.mutate();
  };

  const toggleMainStepOnTrackAndStatus = (checked: boolean) => {
    if (checked) {
      const alreadyExists = query.data?.steps.find(
        (x) =>
          x.track.id === form.state.trackId &&
          x.status.id === form.state.statusId &&
          x.isMainInStatusAndTrack &&
          form.state.id !== x.id
      );
      if (alreadyExists !== undefined) {
        confirm.onOpen(
          "There is already main step",
          `step "${alreadyExists.title} (${alreadyExists.id})" is main step for track "${alreadyExists.track.label} (${alreadyExists.track.id})" and status "${alreadyExists.status.label} (${alreadyExists.status.id})". Do you want to replace it?`,
          (confirmed) => {
            console.log("confirmed", confirmed);
            if (confirmed) {
              form.changeStepProperty("isMainOnTrackAndStatus", true);
            }
          }
        );
      } else {
        form.changeStepProperty("isMainOnTrackAndStatus", true);
      }
    } else {
      form.changeStepProperty("isMainOnTrackAndStatus", false);
    }
  };

  const filteredSteps = React.useMemo(() => {
    return (query.data?.steps ?? []).filter((step) => {
      return (
        (step.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          step.id.toString().includes(searchTerm)) &&
        (trackFilter === null || trackFilter.length === 0 || trackFilter.includes(step.track.id)) &&
        (intakeStatusFilter === null ||
          intakeStatusFilter.length === 0 ||
          intakeStatusFilter.includes(step.status.id)) &&
        (externalFlowFilter === null ||
          externalFlowFilter.length === 0 ||
          (step.externalFlowId !== null && externalFlowFilter.includes(step.externalFlowId)))
      );
    });
  }, [externalFlowFilter, intakeStatusFilter, query.data?.steps, searchTerm, trackFilter]);

  const allStepsIds = React.useMemo(() => {
    if (query.status === "success") {
      return new Set(query.data.steps.map((x) => x.id));
    }
    return new Set<IntakeTrackStepId>();
  }, [query.data?.steps, query.status]);

  const allFieldsIds = React.useMemo(() => {
    if (query.status === "success") {
      return new Set(query.data.steps.flatMap((step) => step.fields.flatMap((field) => field.id)));
    }
    return new Set<IntakeTrackStepFieldId>();
  }, [query.data?.steps, query.status]);

  const allFieldValuesIds = React.useMemo(() => {
    const ids = new Set<IntakeTrackStepFieldValueId>();
    if (query.status === "success") {
      for (const step of query.data.steps) {
        for (const field of step.fields) {
          if (field.type === "options" || field.type === "multiselect") {
            for (const option of field.options) {
              ids.add(option.id);
            }
          }
        }
      }
    }
    return ids;
  }, [query.data?.steps, query.status]);

  const {
    disclosure: copyStepDisclosure,
    onClickCopyStep,
    onClickConfirmCopyStep,
    onClickCancelStepCopy,
  } = useCopyStep({
    form,
    allStepsIds,
    allFieldsIds,
    allFieldValuesIds,
  });

  if (query.isPending) {
    return <LoadingPage />;
  }

  if (query.isError) {
    return <ErrorPage error={query.error} resetErrorBoundary={query.refetch} />;
  }

  const { statuses, steps, sortedSteps, tracks, externalFlows } = query.data;

  return (
    <Page isLoading={query.isFetching}>
      <Page.Header>
        <Page.Title>
          Flow Settings <Button onClick={externalFlowDisclosure.onOpen}>External Flows</Button>
        </Page.Title>
      </Page.Header>
      <Page.Content display="flex" flex={1} gap={4}>
        <Sidebar>
          <SidebarHeader
            externalFlows={externalFlows.map((x) => ({
              label: x.title,
              value: x.id,
            }))}
            freeTextValue={searchTerm}
            intakeStatuses={query.data.statuses.map((x) => ({ label: x.label, value: x.id }))}
            intakeTracks={query.data.tracks.map((x) => ({ label: x.label, value: x.id }))}
            selectedExternalFlows={externalFlowFilter}
            selectedIntakeStatuses={intakeStatusFilter}
            selectedIntakeTracks={trackFilter}
            onChangeExternalFlows={setExternalFlowFilter}
            onChangeFreeText={setSearchTerm}
            onChangeIntakeStatus={setIntakeStatusFilter}
            onChangeTrack={setTrackFilter}
            onClickNew={handleClickNew}
          />
          <SidebarSteps>
            {filteredSteps.map((step) => (
              <StepCard
                key={step.id}
                isActive={form.state.id === step.id}
                step={step}
                onClick={() => handleClickStep(step)}
              />
            ))}
          </SidebarSteps>
        </Sidebar>

        <IntakeFlowStepForm
          allFieldsIds={allFieldsIds}
          allStepActions={query.data.actions}
          allStepCodes={query.data.stepCodes}
          allStepFieldValuesIds={allFieldValuesIds}
          allStepsIds={allStepsIds}
          externalFlows={externalFlows}
          sortedSteps={sortedSteps}
          statuses={statuses}
          steps={steps}
          tracks={tracks}
          value={form.state}
          onChangeStepField={form.changeStepField}
          onChangeStepFieldOfOptionsProperty={form.changeStepFieldOfOptionsProperty}
          onChangeStepFieldOption={form.changeStepFieldOption}
          onChangeStepFieldOptionProperty={form.changeStepFieldOptionProperty}
          onChangeStepFieldProperty={form.changeStepFieldProperty}
          onChangeStepProperty={form.changeStepProperty}
          onClickCopyStep={onClickCopyStep}
          onClickNewField={form.createStepField}
          onClickNewFieldOption={form.createStepFieldOption}
          onClickRemoveField={form.removeStepField}
          onClickRemoveFieldOption={form.removeStepFieldOption}
          onSubmit={handleSubmit}
          onSwitchMainStepOnTrackAndStatus={toggleMainStepOnTrackAndStatus}
        />
        <AskForConfirmation {...confirm} disclosure={confirmDisclosure} />
        <ExternalFlowModal
          disclosure={externalFlowDisclosure}
          externalFlows={externalFlows}
          onClickCreate={createExternalFlowMutation.mutate}
          onClickRemove={removeExternalFlowMutation.mutate}
        />
        <CopyStepModal
          disclosure={copyStepDisclosure}
          tracks={query.data.tracks}
          onClickCancel={onClickCancelStepCopy}
          onClickConfirm={onClickConfirmCopyStep}
        />
      </Page.Content>
    </Page>
  );
}
