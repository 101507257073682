import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";
import { Badge, Box, Button, Flex, Heading, IconButton, Text } from "@chakra-ui/react";
import { Instant, LocalDate, LocalDateTime, LocalTime, ZoneId } from "@js-joda/core";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { z } from "zod";
import { Messages } from "../../../core/api";
import DeleteForeverIcon from "../../../shared/icons/DeleteForeverIcon";
import SaveIcon from "../../../shared/icons/SaveIcon";
import { IntegrationPayerId } from "../../../shared/schema/schema";
import { toLocalDateTime } from "../../../shared/utils/date-utils";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import { PatientIntakePlan } from "../patient-intake.types";
import { PATIENT_INTAKE_PLAN_KEY_PREFIX, phoneNumberSchema } from "../shared/utils/intake.utils";
import {
  getPlanOptions,
  getPlanSearchableDropdownLabel,
} from "../shared/utils/intakePatientPlans.utils";
import IntakePatientProfileDatepicker from "./IntakePatientProfileDatepicker";
import IntakePatientProfileInput from "./IntakePatientProfileInput";
import IntakePatientProfileSearchableDropdown from "./IntakePatientProfileSearchableDropdown";
import IntakePatientProfileSwitch from "./IntakePatientProfileSwitch";

const PatientPlanForm = (props: {
  plan: PatientIntakePlan;
  isEditable: boolean;
  availablePlans: Messages["IntegrationPayer"][];
  onChangeField: (
    field: keyof PatientIntakePlan,
    value:
      | string
      | boolean
      | LocalDate
      | Instant
      | Messages["IntegrationPayer"]
      | LocalDateTime
      | number
      | null
  ) => void;
}) => {
  const NEW_YORK_TZ = "America/New_York";

  const uasDate =
    props.plan.uasAssessmentDate !== null
      ? toLocalDateTime(props.plan.uasAssessmentDate, { timezone: NEW_YORK_TZ })
      : null;

  const handleUASDateChange = (value: LocalDate | LocalDateTime | null) => {
    let uasAssessmentDate: Instant | null = null;
    if (value !== null) {
      const newValue = value instanceof LocalDate ? LocalDateTime.of(value, LocalTime.MIN) : value;
      uasAssessmentDate = newValue.atZone(ZoneId.of(NEW_YORK_TZ)).toInstant();
    }
    props.onChangeField("uasAssessmentDate", uasAssessmentDate);
  };

  return (
    <Flex direction="column" gap={4}>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileSearchableDropdown
          inputLabel="Plan"
          inputPlaceholder="Search for a plan..."
          isEditable={props.isEditable}
          isRequired={false}
          label={getPlanSearchableDropdownLabel(props.plan.plan)}
          options={getPlanOptions(props.availablePlans)}
          value={emptishToNull(props.plan.plan?.id)}
          onSelectValue={(value) => {
            props.onChangeField(
              "plan",
              value === null ? null : props.availablePlans.find((plan) => value === plan.id) ?? null
            );
          }}
        />
        <IntakePatientProfileDatepicker
          isEditable={props.isEditable}
          isRequired={false}
          label="Referral Date"
          value={props.plan.referralDate}
          onChange={(value) => props.onChangeField("referralDate", value)}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditable}
          isRequired={false}
          label="UAS date"
          value={uasDate}
          withTime={true}
          onChange={(value) => handleUASDateChange(value)}
        />

        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={false}
          label="UAS hours amount"
          type="number"
          validateSchema={z
            .string()
            .regex(new RegExp(/^\d*\.?\d*$/), { message: "Must be a number" })}
          value={props.plan.uasHoursAmount?.toString() ?? ""}
          onChange={(value) => props.onChangeField("uasHoursAmount", parseFloat(value))}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={false}
          label="Case Manager Name"
          value={props.plan.caseManagerName ?? ""}
          onChange={(value) => props.onChangeField("caseManagerName", value)}
        />
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={false}
          label="Case Manager Phone Number"
          validateSchema={phoneNumberSchema}
          value={phoneFormatter.formatNational(props.plan.caseManagerPhoneNumber ?? "")}
          onChange={(value) => props.onChangeField("caseManagerPhoneNumber", value)}
        />
        <IntakePatientProfileInput
          isEditable={props.isEditable}
          isRequired={false}
          label="Case Manager Routing"
          value={props.plan.caseManagerRouting ?? ""}
          onChange={(value) => props.onChangeField("caseManagerRouting", value)}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileSwitch
          isEditable={props.isEditable}
          isRequired={false}
          label="Selected Plan"
          value={props.plan.selected}
          onChange={(value) => props.onChangeField("selected", value)}
        />
        {props.plan.selected && (
          <IntakePatientProfileDatepicker
            isEditable={props.isEditable}
            isRequired={false}
            label="Plan Start Date"
            value={props.plan.planStartDate}
            onChange={(value) => props.onChangeField("planStartDate", value)}
          />
        )}
      </Flex>
    </Flex>
  );
};

export const NewPatientPlan = (props: {
  index: number;
  isEditable: boolean;
  isCurrentPlan: boolean;
  availablePlans: Messages["IntegrationPayer"][];
  onClickCloseForm: () => void;
  onClickSavePlan: (plan: PatientIntakePlan) => void;
}) => {
  const [plan, setPlan] = React.useState<PatientIntakePlan>({
    key: "",
    plan: {
      id: -1 as IntegrationPayerId,
      payerName: "",
      state: "",
      description: null,
      payerCode: "",
      vendorName: "",
      payerType: null,
    },
    planStartDate: null,
    referralDate: null,
    uasAssessmentDate: null,
    uasHoursAmount: null,
    caseManagerName: null,
    caseManagerPhoneNumber: null,
    caseManagerRouting: null,
    main: false,
    selected: false,
    current: props.isCurrentPlan,
  });

  const [errorMessage, setErrorMessage] = React.useState("");

  const handleChangePlan = (
    field: keyof PatientIntakePlan,
    value:
      | string
      | boolean
      | LocalDate
      | Instant
      | Messages["IntegrationPayer"]
      | LocalDateTime
      | number
      | null
  ) => {
    setPlan((prev) => ({
      ...prev,
      [field]: value,
      key: `${PATIENT_INTAKE_PLAN_KEY_PREFIX}-new-${
        field === "plan" && value !== null
          ? (value as Messages["IntegrationPayer"])?.id ?? null
          : prev.plan.id
      }`,
    }));
  };

  const handleClickSaveChanges = () => {
    if (plan.plan === null) {
      setErrorMessage("Please fill out all required fields");
      return;
    }

    props.onClickSavePlan(plan);
  };

  return (
    <Flex
      border="1px solid black"
      borderColor="gray.300"
      borderRadius={16}
      direction="column"
      gap={4}
      padding={5}
    >
      <Flex alignItems="center" direction="row" justifyContent="space-between">
        <Heading as="h4" size="sm">
          {props.isCurrentPlan ? "Current Plan " : `Referred plan #${props.index + 1}`}
        </Heading>
        <CloseIcon aria-label="close" cursor="pointer" onClick={props.onClickCloseForm} />
      </Flex>
      <PatientPlanForm
        availablePlans={props.availablePlans}
        isEditable={props.isEditable}
        plan={plan}
        onChangeField={(field, value) => handleChangePlan(field, value)}
      />
      <Flex justifyContent="flex-start">
        <Text color="red.400">{errorMessage}</Text>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button
          aria-label="save changes"
          colorScheme="blue"
          leftIcon={<SaveIcon />}
          onClick={handleClickSaveChanges}
        >
          {" "}
          Save Changes{" "}
        </Button>
      </Flex>
    </Flex>
  );
};

interface IntakePatientPlanProps {
  index: number;
  openIndex: number;
  plan: PatientIntakePlan;
  availablePlans: Messages["IntegrationPayer"][];
  isEditable: boolean;
  isCurrentPlan: boolean;
  onClickRemovePlan: (key: string) => void;
  onEditPatientPlan: (key: string, newPlanInfo: PatientIntakePlan) => void;
}

export const IntakePatientPlan = (props: IntakePatientPlanProps) => {
  const [isOpen, setIsOpen] = React.useState(props.openIndex === props.index);

  const handleEditPlan = (
    field: keyof PatientIntakePlan,
    value:
      | string
      | boolean
      | LocalDate
      | Instant
      | Messages["IntegrationPayer"]
      | LocalDateTime
      | number
      | null
  ) => {
    props.onEditPatientPlan(props.plan.key, {
      ...props.plan,
      [field]: value,
      key: `${PATIENT_INTAKE_PLAN_KEY_PREFIX}-${
        field === "plan" && value !== null
          ? (value as Messages["IntegrationPayer"]).id
          : props.plan.plan.id
      }`,
    });
  };

  return (
    <Flex direction="column" gap={5}>
      <Flex alignItems="center" direction="row" justifyContent="space-between">
        <Flex alignItems="center" direction="row" gap={5}>
          <Heading as="h3" size="sm" w={350}>
            {props.isCurrentPlan ? "Current Plan - " : `Referred plan #${props.index + 1} - `}
            {props.plan.plan?.id !== -1 && props.plan.plan !== null
              ? `${props.plan.plan?.payerCode ?? ""} - ${props.plan.plan?.payerName ?? ""}`
              : "Unknown"}
          </Heading>
          {props.isEditable ? (
            <Button
              leftIcon={<DeleteForeverIcon height="32px" width="20px" />}
              variant="link"
              onClick={() => props.onClickRemovePlan(props.plan.key)}
            ></Button>
          ) : null}
          <Box w={120}>
            {props.plan.main && !props.isCurrentPlan && <Badge colorScheme="blue">Main</Badge>}
          </Box>
        </Flex>
        {isOpen ? (
          <IconButton
            aria-label="collapse up"
            color="black"
            icon={<ChevronUpIcon boxSize={6} />}
            size="md"
            variant="link"
            onClick={() => setIsOpen(!isOpen)}
          />
        ) : (
          <IconButton
            aria-label="collapse down"
            color="black"
            icon={<ChevronDownIcon boxSize={6} />}
            variant="link"
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </Flex>
      <AnimatePresence>
        {isOpen && (
          <motion.section
            animate="open"
            exit="collapsed"
            initial="collapsed"
            transition={{ duration: 0.5, ease: "easeInOut" }}
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
          >
            <PatientPlanForm
              availablePlans={props.availablePlans}
              isEditable={props.isEditable}
              plan={props.plan}
              onChangeField={handleEditPlan}
            />
          </motion.section>
        )}
      </AnimatePresence>
    </Flex>
  );
};

// current code is broken, refactor at your own will
function emptishToNull(id: IntegrationPayerId | null | undefined) {
  return id === -1 || id === undefined ? null : id;
}
