import { LocalDate } from "@js-joda/core";

export function onlyActivePatientContracts() {
  return function (patientContracts, allowInactive) {
    if (!allowInactive && Array.isArray(patientContracts)) {
      return patientContracts.filter((c) => {
        return (
          (c.endDate === null ||
            c.endDate === undefined ||
            LocalDate.parse(c.endDate).isAfter(LocalDate.now())) &&
          !c.isOnHold
        );
      });
    } else {
      return patientContracts;
    }
  };
}
