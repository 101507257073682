import { Instant, LocalDate, LocalDateTime } from "@js-joda/core";
import { ColumnDef, Header } from "@tanstack/react-table";
import { fmap } from "./common";
import { dateFormatter } from "./date-formatter";
import { getFullName } from "./get-full-name";

export function mapDataTableToCSV<TData, TValue>(params: {
  rows: Record<string, unknown>[];
  headers: Header<TData, TValue>[];
}): string {
  const { rows, headers } = params;

  const csvColumns: string[][] = [headers.map((x) => `"${x.column.columnDef.header}"`)];

  const newline = "\r\n";

  for (const [i, row] of rows.entries()) {
    const csvRow: string[] = [];

    for (const header of headers) {
      const value = formatCSVCell({
        column: header.column.columnDef,
        value: fmap(header.column.accessorFn, (accessorFn) => accessorFn(row as TData, i)),
        row,
      });

      csvRow.push(`"${value.replace(/"/g, '""')}"`);
    }

    csvColumns.push(csvRow);
  }

  const csv = csvColumns.map((rowFields) => rowFields.join(",")).join(newline);

  return csv;
}

function formatCSVCell<TData, TValue>(params: {
  value: unknown;
  row: Record<string, unknown>;
  column: ColumnDef<TData, TValue>;
}): string {
  const { value, row, column } = params;

  if (column?.meta?.csvFn !== undefined) {
    return column.meta.csvFn(value as TValue, row as TData) ?? "";
  }

  if (value === undefined || value === null) {
    return "";
  }

  if (value instanceof LocalDateTime || value instanceof Instant) {
    return dateFormatter.toDateTime(value);
  }

  if (value instanceof LocalDate) {
    return dateFormatter.toDate(value);
  }

  if (Array.isArray(value)) {
    return value.join(", ");
  }

  if (typeof value === "object") {
    if (isCamelFullName(value)) {
      return getFullName(value);
    }

    return JSON.stringify(value);
  }

  return String(value);
}

function isCamelFullName(
  value: unknown
): value is { firstName: string; middleName?: string | null; lastName: string } {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  return "firstName" in value && "lastName" in value;
}

export function downloadCSVContent(csv: string) {
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", "data.csv");
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
