import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BodyOf } from "../../core/api";
import { queryKeys } from "../query-keys";
import { PatientId } from "../schema/schema";
import { loadable } from "../utils/loadable";
import useApi from "./useApi";

const usePatientAvailability = (patientId: PatientId) => {
  const { api } = useApi();
  const queryClient = useQueryClient();
  const patientAvilaibilityQuery = useQuery({
    queryKey: queryKeys.patient.patientAvailability(patientId),
    queryFn: () =>
      api.get("./patients/:patientId/availability_settings", {
        path: {
          patientId: patientId,
        },
      }),
  });

  const availabilityTimeFrames = useQuery({
    queryKey: queryKeys.patient.availabilityTimeFrames(),
    queryFn: () => api.get("./patients/availability_time_frames", {}),
  });

  const updateAvailabilityMutation = useMutation({
    mutationFn: (params: BodyOf<"put", "./patients/:patientId/availability_settings">) =>
      api.put("./patients/:patientId/availability_settings", {
        path: {
          patientId: patientId,
        },
        body: params,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.patient.patientAvailability(patientId) });
    },
  });

  const handleUpdateAvailability = (
    availability: BodyOf<"put", "./patients/:patientId/availability_settings">["newSettings"]
  ) =>
    updateAvailabilityMutation.mutate({
      newSettings: availability,
    });
  return {
    patientAvailaibility: loadable.fromQuery(patientAvilaibilityQuery),
    availabilityTimeFrames: loadable.fromQuery(availabilityTimeFrames),
    onUpdateAvailability: handleUpdateAvailability,
  };
};

export default usePatientAvailability;
