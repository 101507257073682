import { LocalDate, nativeJs } from "@js-joda/core";
//! @ngInject
export function newPatientSurplusCheckModalCtrl(
    $scope,
    DatabaseApi,
    patientService,
    
    $uibModalInstance,
    toaster,
    patientId
) {
    $scope.patientId = this.patientId = patientId;
    
    // $scope.patientPhysicians = patientPhysicians;

    function initialize() {
        $scope.form = {};
        $scope.surplusCheck = {
            patientId: 1*$scope.patientId,
            totalAmountInCents: 0,
            receivedAt: new Date()
        }
    }


   
    $scope.save = function () {
        if ($scope.form.$invalid) {
            return;
        }
        console.log($scope.surplusCheck)
        $scope.surplusCheck.receivedAt = LocalDate.from(nativeJs($scope.surplusCheck.receivedAt))
      patientService.createPatientSurplusCheck(1*$scope.patientId,$scope.surplusCheck)   .then((res) => {
        toaster.pop("success", "surplus check created");
        console.log(res)
        $scope.closeModal("")
    })
    .catch((err) => {
        console.error(err);
        toaster.pop("error", "Something went wrong");
    })
    .finally(() => $scope.isLoading = false);
    };

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    initialize();
};
