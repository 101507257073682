import { createIcon } from "@chakra-ui/react";

const CheckLineIcon = createIcon({
  displayName: "CheckLineIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m10 15.172l9.192-9.193l1.415 1.414L10 18l-6.364-6.364l1.414-1.414z"
      fill="currentColor"
    />
  ),
});

export default CheckLineIcon;
