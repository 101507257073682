import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { CaregiverId } from "../../../shared/schema/schema";
import { getFullName } from "../../../shared/utils/get-full-name";
import { isDefined } from "../../../utils";
import SendEmailButton from "../../../shared/components/SendEmailButton";
import { ButtonProps } from "@chakra-ui/react";
import React from "react";

interface Props {
  caregiverId: CaregiverId;
  buttonProps?: ButtonProps;
  text?: string;
}

export default function CaregiverSendEmailButton(props: Props) {
  const { queries } = useApi();

  const emailsQuery = useQuery({
    ...queries.caregiver.get(props.caregiverId),
    select: (caregiver) => {
      if (isDefined(caregiver.email) && caregiver.email !== "") {
        return {
          name: getFullName(caregiver),
          email: caregiver.email,
        };
      }

      return null;
    },
  });

  const emails = React.useMemo(() => {
    if (emailsQuery.data) {
      return [emailsQuery.data];
    }

    return [];
  }, [emailsQuery.data]);

  if (emailsQuery.isError) {
    return (
      <SendEmailButton
        buttonProps={props.buttonProps}
        emails={[]}
        isDisabled={true}
        isLoading={false}
        newTicketDefaultValues={{
          topic: "Caregiver",
          caregiverId: props.caregiverId,
          patientId: null,
        }}
        text="Error while loading email button"
      />
    );
  }

  return (
    <SendEmailButton
      buttonProps={props.buttonProps}
      defaultAssignedTeam="Staffing"
      emails={emails}
      isLoading={emailsQuery.isLoading}
      newTicketDefaultValues={{
        topic: "Caregiver",
        caregiverId: props.caregiverId,
        patientId: null,
      }}
      text={props.text}
    />
  );
}
