import { Messages } from "../../../core/api";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { IntakePatientPlan, NewPatientPlan } from "./IntakePatientPlan";
import { PatientIntakePlan } from "../patient-intake.types";

export const EditablePatientPlans = (props: {
  isNewPlanOpen: boolean;
  plans: PatientIntakePlan[];
  availablePlans: Messages["IntegrationPayer"][];
  isCurrentPlan: boolean;
  onClickCloseNewPlanForm: () => void;
  onClickSaveChanges: (plan: PatientIntakePlan) => void;
  onClickRemovePlan: (key: string) => void;
  onEditPatientPlan: (key: string, newPlanInfo: PatientIntakePlan) => void;
}) => {
  return props.plans === null || props.plans.length === 0 ? (
    <NewPatientPlan
      availablePlans={props.availablePlans}
      index={0}
      isCurrentPlan={props.isCurrentPlan}
      isEditable={true}
      onClickCloseForm={props.onClickCloseNewPlanForm}
      onClickSavePlan={props.onClickSaveChanges}
    />
  ) : (
    <Flex direction="column" gap={6}>
      {props.plans.map((plan, index) => (
        <IntakePatientPlan
          key={plan.key}
          availablePlans={props.availablePlans}
          index={index}
          isCurrentPlan={props.isCurrentPlan}
          isEditable={true}
          openIndex={props.plans === null ? -1 : props.plans.length - 1}
          plan={plan}
          onClickRemovePlan={props.onClickRemovePlan}
          onEditPatientPlan={props.onEditPatientPlan}
        />
      ))}
      {props.isNewPlanOpen && !props.isCurrentPlan ? (
        <NewPatientPlan
          availablePlans={props.availablePlans}
          index={props.plans?.length ?? 0}
          isCurrentPlan={props.isCurrentPlan}
          isEditable={true}
          onClickCloseForm={props.onClickCloseNewPlanForm}
          onClickSavePlan={(newPlan) => {
            props.onClickCloseNewPlanForm();
            props.onClickSaveChanges(newPlan);
          }}
        />
      ) : null}
    </Flex>
  );
};

export const DisplayPatientPlans = (props: {
  plans: PatientIntakePlan[];
  availablePlans: Messages["IntegrationPayer"][];
  isCurrentPlan: boolean;
  onClickRemovePlan: (key: string) => void;
  onEditPatientPlan: (key: string, newPlanInfo: PatientIntakePlan) => void;
}) => {
  return props.plans === null || props.plans.length === 0 ? (
    <>
      {!props.isCurrentPlan ? (
        <Text>
          Looks like there are no plans yet.
          <br />
          Click the edit button to add one!
        </Text>
      ) : (
        <Heading as="h4" size="sm">
          Current plan - Unknown
        </Heading>
      )}
    </>
  ) : (
    <Flex direction="column" gap={4}>
      {props.plans.map((plan, index) => (
        <IntakePatientPlan
          key={plan.key}
          availablePlans={props.availablePlans}
          index={index}
          isCurrentPlan={props.isCurrentPlan}
          isEditable={false}
          openIndex={props.plans === null ? -1 : props.plans.length - 1}
          plan={plan}
          onClickRemovePlan={props.onClickRemovePlan}
          onEditPatientPlan={props.onEditPatientPlan}
        />
      ))}
    </Flex>
  );
};
