import { createIcon } from "@chakra-ui/react";

const ImageOutlineRoundedIcon = createIcon({
  displayName: "ImageOutlineRoundedIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M5 21q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.588 1.413T19 21H5Zm0-2h14V5H5v14Zm0 0V5v14Zm2-2h10q.3 0 .45-.275t-.05-.525l-2.75-3.675q-.15-.2-.4-.2t-.4.2L11.25 16L9.4 13.525q-.15-.2-.4-.2t-.4.2l-2 2.675q-.2.25-.05.525T7 17Z"
      fill="currentColor"
    />
  ),
});

export default ImageOutlineRoundedIcon;
