import { LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment";

//! @ngInject
export function exportCaregiverProfileModal($scope, $rootScope, exportProfileService, toaster, wildcard, agencyId, agencyMemberId, caregiverId) {
    $scope.title = "Export Caregiver Profile";

    $scope.sections = {
        personal: {
            selected: true,
            title: "Personal information"
        },
        professional: {
            selected: true,
            title: "Professional information"
        },
        calendar: {
            selected: true,
            title: "Calendar"
        },
        compliance: {
            selected: true,
            title: "Compliance"
        },
        inservices: {
            selected: true,
            title: "Completed inservices"
        }
    };

    $scope.isCalendarSelected = () => {
        return $scope.sections.calendar.selected;
    };

    $scope.setCalendarRange = (range) => {
        const { fromDate, toDate } = exportProfileService.getCalendarDatesRange(range);
        $scope.fromDate = fromDate;
        $scope.toDate = toDate;
    };

    $scope.setCalendarRange("last7Days");

    $scope.exportPDF = () => {
        $scope.isExportingProfile = true;

        const sectionsIncluded = Object
            .keys($scope.sections)
            .filter(key => $scope.sections[key].selected);

        const calendarPeriod = $scope.isCalendarSelected() ? {
            startDate: LocalDate.from(nativeJs(moment($scope.fromDate))),
            endDate: LocalDate.from(nativeJs(moment($scope.toDate)))
        } : null;

        const payload = { sectionsIncluded, calendarPeriod };

        return exportProfileService
            .exportCaregiverProfilePDF(agencyId, agencyMemberId, caregiverId, payload)
            .finally(() => $scope.isExportingProfile = false);
    };
};
