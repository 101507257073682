import {
  Box,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  Flex,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { useTransitionHook } from "@uirouter/react";
import { PropsWithChildren } from "react";
import { Messages, ResponseOf } from "../../../core/api";
import { NoteId, PatientId } from "../../../shared/schema/schema";
import IntakeChecklist from "./checklist/IntakeChecklist";
import IntakePatientProfileBody from "./IntakePatientProfileBody";
import IntakeProfileHeader from "./IntakeProfileHeader";
import useIntakePatientAdditionalContacts from "../hooks/useIntakePatientAdditionalContacts";
import {
  validateContactsPhonenumbers,
  validatePatientAdditionalContact,
} from "../shared/utils/intakePatientContacts.utils";
import useIntakePatientPlans from "../hooks/useIntakePatientPlans";
import { validatePlans } from "../shared/utils/intakePatientPlans.utils";
import { EditPatientIntake, PatientIntakeProfileDraft } from "../patient-intake.types";

interface Props {
  intakeProfile: PatientIntakeProfileDraft;
  intakePlans: Messages["IntegrationPayer"][];
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  communicationLog: ResponseOf<"get", "./comm_center/tickets/patients/:patientId">;
  portalDocuments: ResponseOf<"get", "./patients/:patientId/documents">["documents"];
  intakeTracks: ResponseOf<"get", "./patient_intake/intake_tracks">["tracks"];
  isEditMode: boolean;
  isFetching: boolean;
  onClickEdit: () => void;
  onClickSave: () => void;
  onClickAddNewNote: (patientId: PatientId) => void;
  onMarkNoteAsDone: (noteId: NoteId) => void;
  onCloseIntakeProfile: () => void;
  setPatientIntakeProfile: (field: keyof EditPatientIntake, value: any) => void;
}

const IntakePatientProfileDrawerContent = (props: PropsWithChildren<Props>) => {
  const toast = useToast();

  useTransitionHook("onExit", {}, (transition) => {
    if (props.isEditMode) {
      if (!window.confirm("Are you sure you want to leave this page?")) {
        transition.abort();
      }
    }
  });

  const {
    isUnsavedNewContact,
    setIsUnsavedNewContact,
    editPatientContact,
    createPatientContact,
    removePatientContact,
    validateNewConactPhoneNumber,
  } = useIntakePatientAdditionalContacts({
    contacts: props.intakeProfile.contacts ?? [],
    onEditPatientIntakeContacts: (newContacts) =>
      props.setPatientIntakeProfile("contacts", newContacts),
  });

  const { editPatientPlan, createPatientPlan, removePatientPlan } = useIntakePatientPlans({
    plans: props.intakeProfile.plans ?? [],
    onEditPatientIntakePlans: (newPlans) => props.setPatientIntakeProfile("plans", newPlans),
  });

  function handleClickSave() {
    if (props.intakeProfile.contacts !== null) {
      if (isUnsavedNewContact) {
        toast({
          status: "error",
          title: "Unsaved New Patient Contact",
          description: "Please click 'Save Changes' or discard them.",
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      const invalidContacts = props.intakeProfile.contacts.filter(
        (contact) => !validatePatientAdditionalContact(contact)
      );
      if (invalidContacts.length > 0) {
        toast({
          status: "error",
          title: "Invalid Patient Contact",
          description: "Please fill relationship, first name and phone number for all contacts",
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      if (
        props.intakeProfile.phoneNumbers.length + props.intakeProfile.contacts.length > 1 &&
        props.intakeProfile.mainPhoneNumber === null
      ) {
        toast({
          status: "error",
          title: "Missing main number",
          description: "Please select a main phone number",
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      const isDuplicatePhonenumber = validateContactsPhonenumbers(props.intakeProfile.contacts);
      if (!isDuplicatePhonenumber) {
        toast({
          status: "error",
          title: "Invalid Patient Contact",
          description: "There are contacts with the same phone number",
          isClosable: true,
          position: "top-right",
        });
        return;
      }
    }

    try {
      validatePlans(props.intakeProfile.plans);
    } catch (error: any) {
      toast({
        status: "error",
        title: "Invalid Patient Plan",
        description: error.message,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    props.onClickSave();
  }

  function handleEditPatientIntakeAddress(
    address: string | null,
    addressComponents: Messages["PatientAddressComponents"] | null
  ) {
    props.setPatientIntakeProfile("address", address);
    props.setPatientIntakeProfile("addressComponents", addressComponents);
  }

  return (
    <>
      <div style={{ opacity: props.isFetching === true ? 1 : 0 }}>
        <Progress isIndeterminate size="xs" />
      </div>
      <Flex overflowY="auto">
        <IntakeChecklist
          intakeTrack={props.intakeProfile.track}
          patientId={props.intakeProfile.id}
        />
        <Divider orientation="vertical" variant="solid"></Divider>
        <Flex flexDir="column" flexGrow={1} overflowY="auto">
          <DrawerHeader>
            <Box>
              <IntakeProfileHeader
                isEditMode={props.isEditMode}
                patientInfo={props.intakeProfile}
                onClickEdit={props.onClickEdit}
                onClickSave={handleClickSave}
                onCloseIntakeProfile={props.onCloseIntakeProfile}
              />
            </Box>
            <Divider marginTop="5px" variant="solid"></Divider>
          </DrawerHeader>
          <DrawerBody>
            <IntakePatientProfileBody
              communicationLog={props.communicationLog}
              intakePlans={props.intakePlans}
              intakeStatuses={props.intakeStatuses}
              intakeTracks={props.intakeTracks}
              isEditMode={props.isEditMode}
              patientIntakeProfile={props.intakeProfile}
              portalDocuments={props.portalDocuments}
              onChangeNewContactDetails={(isEdited: boolean) => setIsUnsavedNewContact(isEdited)}
              onClickAddNewNote={props.onClickAddNewNote}
              onClickAddPatientContact={(newContact) => createPatientContact(newContact)}
              onClickMarkNoteAsDone={props.onMarkNoteAsDone}
              onClickRemovePatientContact={(key) => removePatientContact(key)}
              onClickRemovePlan={(key) => removePatientPlan(key)}
              onEditPatientContact={(key, contact) => editPatientContact(key, contact)}
              onEditPatientIntakeAddress={handleEditPatientIntakeAddress}
              onEditPatientIntakeProfileField={props.setPatientIntakeProfile}
              onEditPatientPlan={editPatientPlan}
              onSaveNewPlan={createPatientPlan}
              onValidateNewContactPhoneNumber={validateNewConactPhoneNumber}
            />
            {props.children}
          </DrawerBody>
          <DrawerCloseButton size="lg" />
        </Flex>
      </Flex>
    </>
  );
};

export default IntakePatientProfileDrawerContent;
