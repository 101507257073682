import { LocalDate, DateTimeFormatter } from "@js-joda/core";

/* @ngInject */
export function entityNoteService(DatabaseApi, $rootScope, $http, Consts, toaster) {
        /* Decalare factory return object */
        const service = {};

        service.createAgencyEntityNoteSetting = (noteSettingsCreateParams) => {
            const url = Consts.api + "agencies/:agencyId/agency_members/:agencyMemberId/entity_notes/settings"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId);

            return $http.post(url, noteSettingsCreateParams);
        };

        service.getAgencyEntityNoteSettingByType = (entityNoteSettingType) => {
            return DatabaseApi.agencyEntityNoteSettings().find(setting => setting.noteType === entityNoteSettingType);
        };

        service.getEntityNoteSettings = (noteType) => {
            const noteSettings = service.getAgencyEntityNoteSettingByType(noteType);
            if (!noteSettings) {
                return toaster.pop('error', 'No setting for note entity: ' + noteType);
            }
            return noteSettings;
        };

        service.isEntityNoteRequired = (noteType) => {
            const noteSettings = service.getEntityNoteSettings(noteType);
            if (!noteSettings) {
                toaster.pop('error', 'No setting for note entity: ' + noteType);
                return false;
            }
            return noteSettings.hasPredefinedField || noteSettings.hasMessageField;
        };

        service.validateEntityNote = (note, noteType) => {
            const validations = {
                isNoteValid: true,
                isPredefinedValid: true,
                isMessageValid: true,
            };

            const isNoteRequired = service.isEntityNoteRequired(noteType);
            if (!isNoteRequired) {
                return validations;
            }

            const noteSettings = service.getEntityNoteSettings(noteType);
            if (!noteSettings) {
                return toaster.pop('error', 'No setting for note entity: ' + noteType);
            }

            const isPredefinedMandatory = noteSettings.hasPredefinedField && noteSettings.isPredefinedMandatory;
            if (isPredefinedMandatory && (!note || !note.notePredefinedAnswerId)) {
                validations.isNoteValid = false;
                validations.isPredefinedValid = false;
            }

            const isMessageMandatory = noteSettings.hasMessageField && noteSettings.isMessageMandatory;
            if (isMessageMandatory && (!note || !note.noteRichText)) {
                validations.isNoteValid = false;
                validations.isMessageValid = false;
            }

            return validations;
        };

        service.buildEntityNoteRequest = (note, noteType, relatedActors) => {
            const isNoteRequired = service.isEntityNoteRequired(noteType);
            if (!isNoteRequired || !note) return undefined;

            if (!note.notePredefinedAnswerId && !note.noteRichText) return undefined;

            let followUp = undefined;
            if (note.followUp !== undefined) {
                if (note.followUp === null) {
                    followUp = null;
                } else if (typeof note.followUp !== 'string') {
                    const localDateObj = LocalDate.of(
                        note.followUp.getFullYear(),
                        note.followUp.getMonth() + 1,
                        note.followUp.getDate()
                    );
                    followUp = localDateObj.format(
                        DateTimeFormatter.ofPattern("yyyy-MM-dd")
                    );
                }
            }

            const body = {
                notePredefinedAnswerId: note && note.notePredefinedAnswerId !== null ? note.notePredefinedAnswerId : null,
                noteRichText: note && note.noteRichText ? note.noteRichText : null,
                status: note.status ? note.status : 'NONE',
                agencyMemberIds: note.agencyMembers,
                followUp: followUp,
                calendarDate: note.calendarDate ? note.calendarDate : undefined,
                visitInstanceId: note.visitInstanceId ? note.visitInstanceId : undefined,
                noteType: noteType,
                base64File: note.newFile ? note.newFile : undefined,
                ...relatedActors
            };

            return body;
        };

        // ============ Utils ========
        service.setRichNoteText = (text) => {
            if (text.includes("<p>")) return text.replace("<p>", "message: ");
            return "message: ".concat(text);
        };

        service.getPredefinedReasonsGroupedByEntity = () => {
            const reasonsGroups = [];
            const allEntityNotes = DatabaseApi.agencyEntityNoteSettings();
            allEntityNotes.forEach(entityNote => {
                const reasons = entityNote.predefinedAnswers.map(a => {
                    return {
                        id: a.id,
                        label: a.text,
                        entity: entityNote.text
                    }
                })
                reasonsGroups.push(...reasons);
            });
            return reasonsGroups;
        };

        return service;
    };