import { Box, Button, Flex, Skeleton, Text } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import { UseQueryResult } from "@tanstack/react-query";
import { useCurrentStateAndParams } from "@uirouter/react";
import Link from "../../../../../shared/components/Link";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { AssignmentRecord } from "../assignments.utils";
import { VisitAssignmentSidebarSection } from "./VisitAssignmentSidebarSection";

export function VisitAssignmentRelatedBroadcastsCard(props: {
  query: UseQueryResult<AssignmentRecord>;
}) {
  const { state } = useCurrentStateAndParams();

  return (
    <VisitAssignmentSidebarSection title="RELATED BROADCASTS">
      <Box>
        {(() => {
          switch (props.query.status) {
            case "pending":
              return (
                <Flex direction="column" gap={2}>
                  <Skeleton height={5} rounded="full" width="66%" />
                </Flex>
              );
            case "error":
              return <Flex>Something went wrong</Flex>;
            case "success":
              return (
                <Flex direction="column" gap={2}>
                  {props.query.data.visitBroadcasts.nodes.length === 0 && (
                    <Text>There are no related broadcasts</Text>
                  )}
                  {props.query.data.visitBroadcasts.nodes.map(({ visitBroadcast }) => (
                    <Button
                      key={visitBroadcast.id}
                      as={Link}
                      color="black"
                      display="block"
                      isTruncated={false}
                      params={{ broadcast: visitBroadcast.id }}
                      textAlign="start"
                      textDecoration={
                        visitBroadcast.canceledAt !== null ? "line-through" : undefined
                      }
                      to={state.name ?? "app.visitAssignments.assignment"}
                      variant="link"
                      whiteSpace="normal"
                    >
                      {formatVisitBroadcastTimeRange(
                        visitBroadcast.startTimeLocal,
                        visitBroadcast.endTimeLocal
                      )}
                    </Button>
                  ))}
                </Flex>
              );
          }
        })()}
      </Box>
    </VisitAssignmentSidebarSection>
  );
}

function formatVisitBroadcastTimeRange(startTimeLocal: LocalDateTime, endTimeLocal: LocalDateTime) {
  return startTimeLocal.toLocalDate().isEqual(endTimeLocal.toLocalDate())
    ? `${dateFormatter.toDateTime(startTimeLocal)} - ${dateFormatter.toTime(endTimeLocal)}`
    : `${dateFormatter.toDateTime(startTimeLocal)} - ${dateFormatter.toDateTime(endTimeLocal)}`;
}
