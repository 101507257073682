import { QueryClient } from "@tanstack/react-query";
import agencyTeamQueries from "../../modules/agency-team/agency-team.queries";
import agencyMembersQueries from "../../modules/agencyMembers/agency-members.queries";
import callCenterQueries from "../../modules/call-center/call-center.queries";
import caregiverQueries from "../../modules/caregiver/caregiver.queries";
import cicoIssuesQueries from "../../modules/cico-reports/utils/cicoIssues.queries";
import communicationQueries from "../../modules/communication/communication.queries";
import contractQueries from "../../modules/contract/contract.queries";
import customFieldQueries from "../../modules/custom-field/custom-field.queries";
import noteQueries from "../../modules/note/note.queries";
import officeQueries from "../../modules/office/office.queries";
import patientQueries from "../../modules/patient/patient.queries";
import patientIntakeQueries from "../../modules/patientIntake/patient-intake.queries";
import payrollQueries from "../../modules/payroll/payroll.queries";
import telephonyQueries from "../../modules/telephony/telephony.queries";
import visitQueries from "../../modules/visit/visit.queries";
import workflowQueries from "../../modules/workflow/workflow.queries";
import { API, createApi } from "../api";

export function createQueryGroup<GroupRecord extends Record<PropertyKey, unknown>>(
  factory: (p: { api: API }) => GroupRecord
) {
  return factory;
}

export function createQueries(params: {
  api: ReturnType<typeof createApi>;
  queryClient: QueryClient;
}) {
  const { api } = params;

  const groupParams = { api };

  return {
    note: noteQueries(groupParams),
    agencyTeam: agencyTeamQueries(groupParams),
    agencyMember: agencyMembersQueries(groupParams),
    communication: communicationQueries(groupParams),
    visit: visitQueries(groupParams),
    office: officeQueries(groupParams),
    contract: contractQueries(groupParams),
    workflow: workflowQueries(groupParams),
    intake: patientIntakeQueries(groupParams),
    callCenter: callCenterQueries(groupParams),
    telephony: telephonyQueries(groupParams),
    customField: customFieldQueries(groupParams),
    patient: patientQueries(groupParams),
    caregiver: caregiverQueries(groupParams),
    payroll: payrollQueries(groupParams),
    cicoIssues: cicoIssuesQueries(groupParams),
  };
}
