import { Flex, Text, TextProps } from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { Messages } from "../../../../core/api";
import {
  EmailTicket,
  isInboundEmailMessage,
  isOutboundEmailMessage,
} from "../../utils/comm-center-email-utils";
import { sortByInstant } from "../../../../shared/utils/date-utils";

type EmailMessages = EmailTicket["emailThread"]["messages"];

interface Props {
  messages: EmailMessages;
  status: Messages["CommCenterTicket"]["status"];
  direction: Messages["CommCenterEmailDirection"];
}

const getLastMessage = (messages: EmailMessages) => {
  const orderedMessages = sortByInstant(messages, "createdAt", "asc");
  return orderedMessages.at(-1);
};

const isNoAnswer = (messages: EmailMessages) => {
  return messages.every(isOutboundEmailMessage);
};

const isPendingAgencyResponse = (messages: EmailMessages) => {
  const lastMessage = getLastMessage(messages);
  return lastMessage !== undefined && isInboundEmailMessage(lastMessage);
};

const isPendingReply = (messages: EmailMessages) => {
  const lastMessage = getLastMessage(messages);
  return lastMessage !== undefined && isOutboundEmailMessage(lastMessage);
};

const renderStatus = ({ messages, status }: Props) => {
  if (isNoAnswer(messages)) {
    return <EmailStatusText color="red">No answer</EmailStatusText>;
  }

  if (isPendingAgencyResponse(messages)) {
    return status === "RESOLVED" ? (
      <EmailStatusText color="green">Resolved</EmailStatusText>
    ) : (
      <EmailStatusText color="red">Pending agency response</EmailStatusText>
    );
  }

  if (isPendingReply(messages)) {
    return <EmailStatusText color="orange">Pending reply</EmailStatusText>;
  }

  return undefined;
};

function formatCommCenterEmailDirection(direction: Messages["CommCenterEmailDirection"]) {
  switch (direction) {
    case "INBOUND":
      return "In";
    case "OUTBOUND":
      return "Out";
  }
}

function EmailStatusText(props: TextProps) {
  return <Text fontSize={{ base: "xs", "2xl": "md" }} {...props} />;
}

function EmailTicketStatus(props: Props) {
  return (
    <Flex alignItems="center" gap={2}>
      <EmailIcon />
      <Text fontSize="x-small">{formatCommCenterEmailDirection(props.direction)}</Text>
      {renderStatus(props)}
    </Flex>
  );
}

export default EmailTicketStatus;
