import Editor, { LexicalEditorProps } from "../../../../shared/components/Lexical/LexicalEditor";
import { EmailQuotedReplyParams } from "../../utils/comm-center-email-utils";

interface Props extends Omit<LexicalEditorProps, "format"> {
  emailQuotedReplyParams?: EmailQuotedReplyParams;
}

const composeCitation = (params: EmailQuotedReplyParams) =>
  `On ${params.date} at ${params.time}, ${params.sender} wrote:`;

const constructEmailNodeString = (params: EmailQuotedReplyParams) => {
  return `
    <div data-lexical-email-reply-chain>
      <p>${composeCitation(params)}</p>
      <blockquote>${params.content}</blockquote>
    </div>
  `;
};

function EmailEditor(props: Props) {
  let initialValue = "";

  if (props.initialValue !== undefined) {
    initialValue += props.initialValue;
  }

  if (props.emailQuotedReplyParams !== undefined) {
    initialValue += constructEmailNodeString(props.emailQuotedReplyParams);
  }

  return <Editor {...props} format="html" initialValue={initialValue} />;
}

export default EmailEditor;
