import { createIcon } from "@chakra-ui/react";

const VolumeOffIcon = createIcon({
  displayName: "VolumeOffIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m19.8 22.6l-3.025-3.025q-.625.4-1.325.688t-1.45.462v-2.05q.35-.125.688-.25t.637-.3L12 14.8V20l-5-5H3V9h3.2L1.4 4.2l1.4-1.4l18.4 18.4l-1.4 1.4Zm-.2-5.8l-1.45-1.45q.425-.775.638-1.625t.212-1.75q0-2.35-1.375-4.2T14 5.275v-2.05q3.1.7 5.05 3.138T21 11.975q0 1.325-.363 2.55T19.6 16.8Zm-3.35-3.35L14 11.2V7.95q1.175.55 1.838 1.65T16.5 12q0 .375-.063.738t-.187.712ZM12 9.2L9.4 6.6L12 4v5.2Z"
      fill="currentColor"
    />
  ),
});

export default VolumeOffIcon;
