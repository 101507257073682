import { useRouter } from "@uirouter/react";
import { z } from "zod";
import { AuthPermissionGuard } from "../../../../shared/components/AuthPermissionGuard";
import usePathParams from "../../../../shared/hooks/usePathParams";
import { PatientId } from "../../../../shared/schema/schema";
import { zPathParamId } from "../../../../shared/utils/zod";
import IntakePatientProfileRoute from "../../components/IntakePatientProfileRoute";
import IntakeDashboardRouteTab from "./IntakeDashboardRouteTab";

const zPathParams = z.object({
  patientId: zPathParamId(PatientId).optional(),
});

const IntakeDashboardRoute = () => {
  const pathParams = usePathParams(zPathParams);
  const { stateService } = useRouter();
  return (
    <>
      <AuthPermissionGuard permission="view_patient_page_intake">
        <IntakeDashboardRouteTab />
      </AuthPermissionGuard>

      <IntakePatientProfileRoute
        patientId={pathParams.patientId}
        onClose={() => stateService.go("app.patients.intake.dashboard")}
      />
    </>
  );
};

export default IntakeDashboardRoute;
