import React from "react";

import {
  Badge,
  Button,
  Checkbox,
  IconButton,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Row, createColumnHelper } from "@tanstack/react-table";
import { VisitInstanceId } from "../../shared/schema/schema";
import {
  AgencyMemberEntity,
  CaregiverEntity,
  EntityCardLink,
  PatientEntity,
} from "../../shared/components/EntityCard";
import { Messages } from "../../core/api";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import { FilterProps } from "../../shared/utils/filter-props";
import useApi from "../../shared/hooks/useApi";
import useQueryDataTable from "../../shared/components/DataTable/useQueryDataTable";
import DataTable from "../../shared/components/DataTable/DataTable";
import { dateFormatter } from "../../shared/utils/date-formatter";
import { fromCamelCaseToTitle } from "../../utils";
import { EditIcon } from "@chakra-ui/icons";
import {
  CicoVisitToDataRow,
  groupVisitsByCaregivers,
  groupVisitsByPatients,
} from "./utils/cicoIssuesUtils";

export type CicoIssuesVisitDataRow = {
  visit: Messages["CicoIssuesVisit"];
  visitId: Messages["VisitInstanceId"];
  visitDate: LocalDate;
  visitStartTime: LocalDateTime;
  visitEndTime: LocalDateTime;
  patientEntity: PatientEntity;
  caregiverEntity: CaregiverEntity;
  agencyMemberEntity: AgencyMemberEntity | null;
  contractName: string;
  clockinTime: LocalDateTime | null;
  clockoutTime: LocalDateTime | null;
  assignedAgencyMemberId: Messages["AgencyMemberId"] | null;
  note: null | string;
  attemptedToReachAt: LocalDateTime | null;
  successfullyReachedAt: LocalDateTime | null;
  timesheetSentAt: LocalDateTime | null;
  filingLimitDaysLeft: null | number;
  visitIssues: Messages["CicoIssuesVisit"]["visitIssues"];
};

interface Props {
  visits: Messages["CicoIssuesVisit"][];
  filterNode?: React.ReactNode;
  actionNode: React.ReactNode;
  filters: FilterProps<"./visits_with_cico_issues">["filters"];
  selectedVisits: Set<VisitInstanceId>;
  groupByPatients: boolean;
  groupByCaregivers: boolean;
  paginationSize?: number;
  queryEnabled: boolean;
  setEnabled: (enabled: boolean) => void;
  onClickCheckbox: (visit: Messages["CicoIssuesVisit"]) => void;
  onClickEdit: (visit: Messages["CicoIssuesVisit"]) => void;
  onClickRow: (event: React.MouseEvent<HTMLElement>, visit: Messages["CicoIssuesVisit"]) => void;
  onSelectAllVisits: (visits: Messages["CicoIssuesVisit"][]) => void;
}

export default function CicoIssuesTable(props: Props) {
  const { queries } = useApi();

  const columns = createCicoIssuesVisitsColumns({
    selectedVisits: props.selectedVisits,
    onClickCheckbox: props.onClickCheckbox,
    onClickEdit: props.onClickEdit,
  });

  const queryOptions = queries.cicoIssues.cicoIssuesVisits(props.filters.params.toJSON());
  const query = useQuery({
    ...queryOptions,
    enabled: props.queryEnabled,
    select: (data) => {
      props.setEnabled(false);
      if (props.groupByPatients) {
        return groupVisitsByPatients(data.visits).map(CicoVisitToDataRow);
      }
      if (props.groupByCaregivers) {
        return groupVisitsByCaregivers(data.visits).map(CicoVisitToDataRow);
      }
      return data.visits.map(CicoVisitToDataRow);
    },
  });

  const { dataTableProps } = useQueryDataTable({
    query,
    columns,
    paginationSize: props.paginationSize,
    disableLoader: !props.queryEnabled,
    columnVisiblity: {
      storage: { key: "cico-issues", version: 1 },
      forceVisibleColumns: React.useMemo(() => ["visitId"], []),
      initialSelected: [
        "visit",
        "visitId",
        "visitDate",
        "visitStartTime",
        "visitEndTime",
        "patientEntity",
        "caregiverEntity",
        "contractName",
        "filingLimitDaysLeft",
        "clockinTime",
        "clockoutTime",
        "agencyMemberEntity",
        "note",
        "attemptedToReachAt",
        "successfullyReachedAt",
        "timesheetSentAt",
        "visitIssues",
      ],
    },
    initialSorting: [
      {
        id: "visitDate",
        desc: true,
      },
    ],
  });

  const handleClickRow = (
    event: React.MouseEvent<HTMLElement>,
    row: Row<CicoIssuesVisitDataRow>
  ) => {
    props.onClickRow(event, row.original.visit);
  };

  return (
    <DataTable
      {...dataTableProps}
      actionNode={
        <>
          {props.actionNode}
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={() =>
              props.onSelectAllVisits(
                dataTableProps.table.getRowModel().rows.map((row) => row.original.visit)
              )
            }
          >
            Select All
          </Button>
        </>
      }
      filterNode={props.filterNode}
      onClickRow={handleClickRow}
    />
  );
}

function createCicoIssuesVisitsColumns(params: {
  selectedVisits: Set<VisitInstanceId>;
  onClickCheckbox: (visit: Messages["CicoIssuesVisit"]) => void;
  onClickEdit: (visit: Messages["CicoIssuesVisit"]) => void;
}) {
  const columnHelper = createColumnHelper<CicoIssuesVisitDataRow>();
  const columns = [
    columnHelper.display({
      header: "Add / Edit",
      cell: (info) => (
        <IconButton
          aria-label="Edit Visit"
          colorScheme="blue"
          icon={<EditIcon />}
          onClick={(e) => {
            e.stopPropagation();
            params.onClickEdit(info.row.original.visit);
          }}
        />
      ),
    }),
    columnHelper.accessor("visit", {
      cell: (info) => (
        <Checkbox
          alignItems="center"
          colorScheme="blue"
          fontWeight="normal"
          isChecked={params.selectedVisits.has(info.row.original.visitId)}
          justifyContent="center"
          size="lg"
          onChange={(e) => {
            e.stopPropagation();
            params.onClickCheckbox(info.getValue());
          }}
        ></Checkbox>
      ),
      header: "Action",
      meta: {
        csvFn() {
          return "";
        },
      },
    }),
    columnHelper.accessor("visitId", {
      sortingFn: "alphanumeric",
      cell: (info) => info.getValue(),
      header: "Visit ID",
    }),
    columnHelper.accessor("visitDate", {
      sortingFn: "localDate",
      cell: (info) => <Text>{dateFormatter.toDate(info.getValue())}</Text>,
      header: "Visit Date",
    }),
    columnHelper.accessor("visitStartTime", {
      sortingFn: "localDate",
      cell: (info) => <Text>{dateFormatter.toTime(info.getValue())}</Text>,
      header: "Visit Start Time",
    }),
    columnHelper.accessor("visitEndTime", {
      sortingFn: "localDate",
      cell: (info) => <Text>{dateFormatter.toTime(info.getValue())}</Text>,
      header: "Visit End Time",
    }),
    columnHelper.accessor("visitIssues", {
      cell: (info) => {
        const allIssues: { [key: string]: boolean } | null = info.getValue();
        const currentIssues =
          allIssues !== null
            ? Object.keys(allIssues).filter((key) => allIssues[key] === true)
            : null;
        const issuesNumber = currentIssues?.length ?? 0;
        return (
          <>
            <Popover placement="top" trigger="hover">
              <PopoverTrigger>
                <Badge colorScheme="blue">{issuesNumber} Issues</Badge>
              </PopoverTrigger>
              {issuesNumber > 0 && (
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <UnorderedList>
                      {currentIssues?.map((issue) => (
                        <ListItem key={issue}>{fromCamelCaseToTitle(issue)}</ListItem>
                      ))}
                    </UnorderedList>
                  </PopoverBody>
                </PopoverContent>
              )}
            </Popover>
          </>
        );
      },
      header: "Visit Issues",
      meta: {
        csvFn(x) {
          const allIssues: { [key: string]: boolean } | null = x;
          const currentIssues =
            allIssues !== null
              ? Object.keys(allIssues).filter((key) => allIssues[key] === true)
              : null;
          return currentIssues?.join(", ") ?? "";
        },
      },
    }),
    columnHelper.accessor("patientEntity", {
      cell: (info) => <EntityCardLink entity={info.getValue()} />,
      header: "Patient",
      meta: {
        csvFn(x) {
          return `${x.fullName} (${x.displayId})`;
        },
      },
    }),
    columnHelper.accessor("caregiverEntity", {
      cell: (info) => <EntityCardLink entity={info.getValue()} />,
      header: "Caregiver",
      meta: {
        csvFn(x) {
          return `${x.fullName} (${x.displayId})`;
        },
      },
    }),
    columnHelper.accessor("contractName", {
      sortingFn: "text",
      cell: (info) => info.getValue(),
      header: "Contract",
    }),
    columnHelper.accessor("filingLimitDaysLeft", {
      sortingFn: "alphanumeric",
      cell: (info) => info.getValue(),
      header: "Timely Filing Days left",
    }),
    columnHelper.accessor("clockinTime", {
      sortingFn: "localDateTime",
      cell: (info) => {
        const clockinTime = info.getValue();
        return (
          <Text>{clockinTime !== null ? dateFormatter.toDateOrDateTime(clockinTime) : ""}</Text>
        );
      },
      header: "Clock In Time",
    }),
    columnHelper.accessor("clockoutTime", {
      sortingFn: "localDateTime",
      cell: (info) => {
        const clockoutTime = info.getValue();
        return (
          <Text>{clockoutTime !== null ? dateFormatter.toDateOrDateTime(clockoutTime) : ""}</Text>
        );
      },
      header: "Clock Out Time",
    }),
    columnHelper.accessor("agencyMemberEntity", {
      cell: (info) => {
        const agencyMember = info.getValue();
        return agencyMember !== null ? <EntityCardLink entity={agencyMember} /> : null;
      },
      header: "Assigned Agency Member",
      meta: {
        csvFn(x) {
          return x?.fullName ?? "";
        },
      },
    }),
    columnHelper.accessor("note", {
      sortingFn: "text",
      cell: (info) => info.getValue(),
      header: "Note",
    }),
    columnHelper.accessor("attemptedToReachAt", {
      sortingFn: "localDateTime",
      cell: (info) => {
        const attemptedToReachAt = info.getValue();
        return (
          <Text>
            {attemptedToReachAt !== null ? dateFormatter.toDateOrDateTime(attemptedToReachAt) : ""}
          </Text>
        );
      },
      header: "Attempted to reach at",
    }),
    columnHelper.accessor("successfullyReachedAt", {
      sortingFn: "localDateTime",
      cell: (info) => {
        const successfullyReachedAt = info.getValue();
        return (
          <Text>
            {successfullyReachedAt !== null
              ? dateFormatter.toDateOrDateTime(successfullyReachedAt)
              : ""}
          </Text>
        );
      },
      header: "Successfully reached at",
    }),
    columnHelper.accessor("timesheetSentAt", {
      sortingFn: "localDateTime",
      cell: (info) => {
        const timesheetSentAt = info.getValue();
        return (
          <Text>
            {timesheetSentAt !== null ? dateFormatter.toDateOrDateTime(timesheetSentAt) : ""}
          </Text>
        );
      },
      header: "Timesheet sent at",
    }),
  ];

  return columns;
}
