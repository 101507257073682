import { AttachmentIcon } from "@chakra-ui/icons";
import { Button, ModalProps, useDisclosure } from "@chakra-ui/react";
import { DropzoneOptions } from "react-dropzone";
import UploadModal from "../../../../shared/components/UploadModal";
import { S3Object } from "../../../../shared/hooks/useUploadFiles";
import { MAX_EMAIL_ATTACHMENTS_SIZE } from "../../utils/comm-center-email-utils";

type Props = DropzoneOptions & {
  isDisabled: boolean;
  files: S3Object[];
  portalProps?: ModalProps["portalProps"];
  onChange: (files: S3Object[]) => void;
};

function EmailAttachFilesButton(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button gap={2} isDisabled={props.isDisabled} rounded="xl" size="md" onClick={onOpen}>
        <AttachmentIcon />
        Attach files
      </Button>
      <UploadModal
        {...props}
        fileUploadDestination={{ by: "email-attachment" }}
        isOpen={isOpen}
        maxSize={MAX_EMAIL_ATTACHMENTS_SIZE}
        multiple={true}
        portalProps={props.portalProps}
        onClose={onClose}
      />
    </>
  );
}

export default EmailAttachFilesButton;
