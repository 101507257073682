import { atom, useAtom } from "jotai";
import { SmsSenderProps } from "../components/SmsSender/sms-sender.types";

const smsSenderAtom = atom<SmsSenderProps | null>(null);

export function useSmsSender() {
  const [state, setState] = useAtom(smsSenderAtom);

  const onOpen = (params: SmsSenderProps) => setState(params);
  const onClose = () => setState(null);

  return { state, onOpen, onClose };
}
