import { z } from "zod";
import { VisitAssignmentReason } from "../../../../../../shared/schema/gql/graphql";
import {
  PatientId,
  CaregiverId,
  VisitInstanceId,
  VisitBroadcastId,
} from "../../../../../../shared/schema/schema";

export const zVisitAssignment = z.object({
  patientId: z.number({ invalid_type_error: "Required" }).transform(PatientId.parse),
  caregiverId: CaregiverId.nullable().optional(),
  visitInstances: VisitInstanceId.array().min(1, "Must contain at least one visit instance"),
  visitBroadcasts: VisitBroadcastId.array().optional(),
  reason: z.nativeEnum(VisitAssignmentReason),
});
