import { atom, useAtom } from "jotai";

const alreadyInACallAtom = atom(false);

export default function useAlreadyInACallDialog() {
  const [isOpen, setOpen] = useAtom(alreadyInACallAtom);

  const open = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  return { isOpen, open, close };
}
