import { Messages } from "../../../../core/api";
import { PatientIntakeProfileDraft } from "../../patient-intake.types";

export const getPlanSearchableDropdownLabel = (
  selectedPlan: Messages["IntegrationPayer"] | null
) => {
  if (selectedPlan !== null) {
    return selectedPlan.payerType
      ? `${selectedPlan.payerCode} - ${selectedPlan.payerName} - ${selectedPlan.payerType}`
      : `${selectedPlan.payerCode} - ${selectedPlan.payerName}`;
  }
  return "Unknown";
};

export const getPlanOptions = (plans: Messages["IntegrationPayer"][]) => {
  return reorderPlans(plans).map((plan) => {
    const label = plan.payerType
      ? `${plan.payerCode} - ${plan.payerName} - ${plan.payerType}`
      : `${plan.payerCode} - ${plan.payerName}`;
    return {
      value: plan.id,
      label,
    };
  });
};

export const reorderPlans = (plans: Messages["IntegrationPayer"][]) => {
  const substringsToBumpToStart = ["Centers", "Healthfirst", "Integra"];
  const matchingStrings: Messages["IntegrationPayer"][] = [];
  const nonMatchingStrings: Messages["IntegrationPayer"][] = [];

  plans.forEach((plan) => {
    const hasMatchingSubstring = substringsToBumpToStart.some((substring) =>
      plan.payerName.includes(substring)
    );
    if (hasMatchingSubstring) {
      matchingStrings.push(plan);
    } else {
      nonMatchingStrings.push(plan);
    }
  });

  return [...matchingStrings, ...nonMatchingStrings];
};

export const validatePlans = (plans: PatientIntakeProfileDraft["plans"]) => {
  const idSet = new Set();
  let selectedCount = 0;

  if (plans === null) {
    throw new Error("Plans cannot be null");
  }

  for (const plan of plans) {
    // Check for duplicate planss
    if (idSet.has(plan.plan.id)) {
      throw new Error(`Duplicate plans found: ${plan.plan.payerCode}`);
    } else {
      idSet.add(plan.plan.id);
    }

    // Check for multiple selections
    if (plan.selected) {
      selectedCount++;

      if (selectedCount > 1) {
        throw new Error("Only one plan can be selected");
      }
    }

    if (plan.plan.id === null || plan.plan.id === -1) {
      throw new Error("Plan not selected");
    }
  }
};
