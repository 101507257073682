import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { ErrorBoundary } from "react-error-boundary";
import { Messages } from "../../../../core/api";
import MissedCallsButton from "./MissedCallsButton";
import RingingCallsButton from "./RingingCallsButton";
import RingingCallsErrorBoundary from "./RingingCallsErrorBoundary";

export default function ImportantCallsPopoverRoot(props: {
  ringingCalls: Messages["TelephonyInboundRingingCallInfo"][];
  missedCalls: Messages["TelephonyInboundCallInfo"][];
  children: React.ReactNode;
  onReset: () => void;
}) {
  const hasCalls = props.ringingCalls.length > 0 || props.missedCalls.length > 0;

  return (
    <ErrorBoundary FallbackComponent={RingingCallsErrorBoundary} onReset={props.onReset}>
      <Popover>
        {hasCalls && (
          <PopoverTrigger>
            {props.ringingCalls.length > 0 ? (
              <RingingCallsButton numCalls={props.ringingCalls.length} />
            ) : (
              <MissedCallsButton numCalls={props.missedCalls.length} />
            )}
          </PopoverTrigger>
        )}
        <PopoverContent h="500px" maxH="500px" w="350px">
          <PopoverArrow />
          {props.children}
        </PopoverContent>
      </Popover>
    </ErrorBoundary>
  );
}
