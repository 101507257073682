import { Edge, MarkerType, Node } from "reactflow";
import {
  IntakeTrackId,
  IntakeTrackNoteId,
  IntakeTrackStepId,
} from "../../../../shared/schema/schema";
import { groupByFn } from "../../../../shared/utils/common";
import { Messages } from "../../../../core/api";

export type IntakeFlowGraphId = `${"step"}-${IntakeTrackStepId}` | `${"note"}-${IntakeTrackNoteId}`;
export type IntakeFlowGraphIdPair =
  | { type: "step"; id: IntakeTrackStepId }
  | { type: "note"; id: IntakeTrackNoteId };

export function buildIntakeFlowGraphId(params: IntakeFlowGraphIdPair): IntakeFlowGraphId {
  switch (params.type) {
    case "step":
      return `step-${params.id}`;
    case "note":
      return `note-${params.id}`;
  }
}

export function parseIntakeFlowGraphId(id: IntakeFlowGraphId): IntakeFlowGraphIdPair {
  const [type, idStr] = id.split("-");
  if (type === "step") {
    return { type: "step", id: parseInt(idStr) as IntakeTrackStepId };
  }
  return { type: "note", id: parseInt(idStr) as IntakeTrackNoteId };
}

export type ReacFlowElements = {
  nodes: ElkNode[];
  edges: Edge[];
};

export type ReactFlowElementsMap = Map<IntakeTrackId, ReacFlowElements>;

export type ElkNodeData =
  | {
      id: IntakeFlowGraphId;
      nodeType: "IntakeStep";
      step: Messages["IntakeTrackStepForFullFlow"];
      isRoot: boolean;
      sourceHandles: { id: string }[];
      targetHandles: { id: string }[];
    }
  | {
      id: IntakeFlowGraphId;
      nodeType: "IntakeNote";
      content: string;
    };

export type ElkNode = Node<ElkNodeData, "elk">;

export function getReactFlowElementsByTrack(
  steps: Messages["IntakeTrackStepForFullFlow"][],
  notes: Messages["IntakeTrackNote"][]
): ReactFlowElementsMap {
  const stepsMap = groupByFn(steps, (step) => step.track.id);
  const notesMap = groupByFn(notes, (note) => note.trackId);
  const trackIds = new Set([...stepsMap.keys(), ...notesMap.keys()]);
  const map: ReactFlowElementsMap = new Map();
  for (const trackId of trackIds) {
    map.set(trackId, buildReactFlowData(stepsMap.get(trackId) ?? [], notesMap.get(trackId) ?? []));
  }

  return map;
}

function buildReactFlowData(
  steps: Messages["IntakeTrackStepForFullFlow"][],
  notes: Messages["IntakeTrackNote"][]
) {
  const parentsMap = new Map<IntakeFlowGraphId, { id: IntakeFlowGraphId; label: string }[]>();
  const nextStepsMap = new Map<IntakeFlowGraphId, { id: IntakeFlowGraphId; label: string }[]>();

  for (const step of steps) {
    const nextSteps = (
      step.nextStepId === null
        ? []
        : [
            {
              id: buildIntakeFlowGraphId({
                type: "step",
                id: step.nextStepId,
              }),
              label: "Next",
            },
          ]
    ).concat(
      step.fields.flatMap((field) =>
        "options" in field
          ? field.options.flatMap((option) =>
              option.nextIntakeTrackStepId === null
                ? []
                : [
                    {
                      id: buildIntakeFlowGraphId({
                        type: "step",
                        id: option.nextIntakeTrackStepId,
                      }),
                      label: option.value,
                    },
                  ]
            )
          : []
      )
    );

    for (const nextStep of nextSteps) {
      const parents = parentsMap.get(nextStep.id) ?? [];
      parentsMap.set(
        nextStep.id,
        parents.concat({
          id: buildIntakeFlowGraphId({ type: "step", id: step.id }),
          label: nextStep.label,
        })
      );
      nextStepsMap.set(buildIntakeFlowGraphId({ type: "step", id: step.id }), nextSteps);
    }
  }

  const nodes: ElkNode[] = steps.map((step) => {
    const nodeId = buildIntakeFlowGraphId({ type: "step", id: step.id });
    const parents = parentsMap.get(nodeId) ?? [];
    const nextSteps = nextStepsMap.get(nodeId) ?? [];

    return {
      id: nodeId,
      position:
        step.graphPoint === null ? { x: 0, y: 0 } : { x: step.graphPoint.x, y: step.graphPoint.y },
      data: {
        id: nodeId,
        nodeType: "IntakeStep",
        step: step,
        isRoot: parents.length === 0,
        sourceHandles: [
          ...new Set(nextSteps.map((nextStep) => `${step.id}-s-${nextStep.label}`)),
        ].map((id) => ({ id })),
        targetHandles: [...new Set(parents.map((parent) => `${step.id}-t-${parent.label}`))].map(
          (id) => ({ id })
        ),
      },
      type: "elk",
    };
  });

  for (const note of notes) {
    const nodeId = buildIntakeFlowGraphId({ type: "note", id: note.id });
    nodes.push({
      id: nodeId,
      position: note.graphPoint,
      data: {
        id: nodeId,
        nodeType: "IntakeNote",
        content: note.content,
      },
      type: "elk",
    });
  }

  const edges: Edge[] = steps.flatMap((step) => {
    const stepNodeId = buildIntakeFlowGraphId({ type: "step", id: step.id });
    const parents = parentsMap.get(stepNodeId) ?? [];
    return parents.map((parent): Edge => {
      const parentNodeId = parseIntakeFlowGraphId(parent.id);
      if (parentNodeId.type === "step") {
        return {
          id: `${parentNodeId.id}-${step.id}`,
          source: parent.id,
          sourceHandle: `${parentNodeId.id}-s-${parent.label}`,
          target: stepNodeId,
          targetHandle: `${step.id}-t-${parent.label}`,
          label: parent.label,
          markerEnd: { type: MarkerType.ArrowClosed },
        };
      }
      throw new Error("Not step edge");
    });
  });

  return { nodes, edges: [...new Map(edges.map((edge) => [edge.id, edge])).values()] };
}
