import { Messages } from "../../../core/api";
import { CicoIssuesVisitDataRow } from "../CicoIssuesTable";
import VisitRecordForm from "../VisitRecordForm";

export function getInitialForm(
  selectedVisits: Messages["CicoIssuesVisit"][]
): VisitRecordForm | undefined {
  if (selectedVisits.length === 0) {
    return;
  }
  const firstVisit = selectedVisits[0];
  const multipleNotes = selectedVisits.some((visit) => visit.note !== firstVisit.note);
  const multipleAssignedMembers = selectedVisits.some(
    (visit) => visit.assignedAgencyMemberId !== firstVisit.assignedAgencyMemberId
  );
  const multipleAttemptedToReach = selectedVisits.some(
    (visit) => visit.attemptedToReachAt !== firstVisit.attemptedToReachAt
  );
  const multipleSuccessfullyReached = selectedVisits.some(
    (visit) => visit.successfullyReachedAt !== firstVisit.successfullyReachedAt
  );
  const multipleTimesheetSent = selectedVisits.some(
    (visit) => visit.timesheetSentAt !== firstVisit.timesheetSentAt
  );

  return {
    note: multipleNotes ? null : selectedVisits[0].note,
    assignedAgencyMemberId: multipleAssignedMembers
      ? null
      : selectedVisits[0].assignedAgencyMemberId,
    attemptedToReach: multipleAttemptedToReach
      ? null
      : selectedVisits[0].attemptedToReachAt !== null,
    successfullyReached: multipleSuccessfullyReached
      ? null
      : selectedVisits[0].successfullyReachedAt !== null,
    timesheetSent: multipleTimesheetSent ? null : selectedVisits[0].timesheetSentAt !== null,
    multipleNotes: multipleNotes,
    multipleAssignedMembers: multipleAssignedMembers,
    multipleAttemptedToReach: multipleAttemptedToReach,
    multipleSuccessfullyReached: multipleSuccessfullyReached,
    multipleTimesheetSent: multipleTimesheetSent,
  };
}

export function groupVisitsByPatients(
  visits: Messages["CicoIssuesVisit"][]
): Messages["CicoIssuesVisit"][] {
  const seen = new Set();
  return visits.filter((visit) => {
    const isDuplicate = seen.has(visit.patientId);
    seen.add(visit.patientId);
    return !isDuplicate;
  });
}

export function groupVisitsByCaregivers(
  visits: Messages["CicoIssuesVisit"][]
): Messages["CicoIssuesVisit"][] {
  const seen = new Set();
  return visits.filter((visit) => {
    const isDuplicate = seen.has(visit.caregiverId);
    seen.add(visit.caregiverId);
    return !isDuplicate;
  });
}

export function CicoVisitToDataRow(visit: Messages["CicoIssuesVisit"]): CicoIssuesVisitDataRow {
  return {
    visit: visit,
    visitId: visit.visitId,
    visitDate: visit.visitDate,
    visitStartTime: visit.visitStartTime,
    visitEndTime: visit.visitEndTime,
    patientEntity: {
      type: "Patient",
      id: visit.patientId,
      displayId: visit.patientDisplayId,
      fullName: visit.patientName,
      status: visit.patientStatus,
      gender: visit.patientGender,
      contactDetails: null,
    },
    caregiverEntity: {
      type: "Caregiver",
      id: visit.caregiverId,
      displayId: visit.caregiverDisplayId,
      fullName: visit.caregiverName,
      status: visit.caregiverStatus,
      photoUrl: visit.caregiverPhotoUrl,
    },
    agencyMemberEntity:
      visit.assignedAgencyMemberId === null || visit.agencyMemberName === null
        ? null
        : {
            type: "Agency Member",
            id: visit.assignedAgencyMemberId,
            fullName: visit.agencyMemberName,
            photoUrl: visit.agencyMemberPhotoUrl,
          },
    contractName: visit.contractName,
    clockinTime: visit.clockinTime,
    clockoutTime: visit.clockoutTime,
    assignedAgencyMemberId: visit.assignedAgencyMemberId,
    note: visit.note,
    attemptedToReachAt: visit.attemptedToReachAt,
    successfullyReachedAt: visit.successfullyReachedAt,
    timesheetSentAt: visit.timesheetSentAt,
    filingLimitDaysLeft: visit.filingLimitDaysLeft,
    visitIssues: visit.visitIssues,
  };
}
