import { atom } from "jotai";
import { useReducerAtom } from "jotai/utils";
import { Messages } from "../../core/api";
import {
  InferWorkflowFieldTypeWithEffects,
  WorkflowDataFieldType,
} from "../../modules/workflow/workflow.types";
import useStableCallback from "./useStableCallback";

export type GlobalWorkflowHint = {
  type: "prefill-field";
  fieldType: Messages["WorkflowDataFieldType"];
  display: string;
  value: unknown;
};

type GlobalWorkflowState = {
  isOpen: boolean;
  hints: GlobalWorkflowHint[];
};

type GlobalWorkflowAction =
  | { type: "open" }
  | { type: "close" }
  | { type: "set-hints"; hints: GlobalWorkflowHint[] };

const globalWorkflowReducer = (
  prev: GlobalWorkflowState,
  action: GlobalWorkflowAction
): GlobalWorkflowState => {
  switch (action.type) {
    case "open":
      return { ...prev, isOpen: true };
    case "close":
      return { ...prev, isOpen: false };
    case "set-hints":
      return { ...prev, hints: action.hints };
  }
};

const globalWorkflowAtom = atom<GlobalWorkflowState>({
  isOpen: false,
  hints: [],
});

export function useGlobalWorkflowRunner() {
  const [state, dispatch] = useReducerAtom(globalWorkflowAtom, globalWorkflowReducer);

  function getHintOfField<TField extends WorkflowDataFieldType>(
    field: TField
  ): {
    value: InferWorkflowFieldTypeWithEffects<TField>;
    display: string;
  } | null {
    return getHintOfGlobalWorkflowField(field, state.hints);
  }

  return {
    isOpen: state.isOpen,
    hints: state.hints,
    getHintOfField: getHintOfField,
    open: useStableCallback(() => dispatch({ type: "open" })),
    close: useStableCallback(() => dispatch({ type: "close" })),
    setHints: useStableCallback((hints: GlobalWorkflowHint[]) =>
      dispatch({ type: "set-hints", hints })
    ),
    removeHintsWhere: useStableCallback((predicate: (hint: GlobalWorkflowHint) => boolean) => {
      dispatch({
        type: "set-hints",
        hints: state.hints.filter((hint) => !predicate(hint)),
      });
    }),
  };
}

export function getHintOfGlobalWorkflowField<TField extends WorkflowDataFieldType>(
  field: TField,
  hints: GlobalWorkflowHint[]
): {
  value: InferWorkflowFieldTypeWithEffects<TField>;
  display: string;
} | null {
  const hint = hints.find((hint) => {
    if ((hint.fieldType.array ?? false) !== (field.array ?? false)) {
      return false;
    }

    switch (field.type) {
      case "entity":
        return hint.fieldType.type === "entity" && hint.fieldType.entity === field.entity;
      case "date":
      case "datetime":
      case "time":
      case "boolean":
      case "file":
      case "number":
      case "option":
      case "text":
      case "textarea":
        return hint.fieldType.type === field.type;
    }
  });

  if (hint === undefined) {
    return null;
  }

  return {
    display: hint.display,
    value: hint.value as InferWorkflowFieldTypeWithEffects<TField>,
  };
}
