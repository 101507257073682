import React from "react";

import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Duration, Instant } from "@js-joda/core";
import {
  Header,
  PaginationState,
  Table as ReactTable,
  Row,
  SortingState,
  Updater,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useRouter } from "@uirouter/react";
import { useDidMount } from "rooks";
import { z } from "zod";
import { Messages } from "../../../core/api";
import EntityCard, { Entity, EntityWithStatus } from "../../../shared/components/EntityCard";
import { useTableSessionState } from "../../../shared/hooks/useTableSessionState";
import ClockIcon from "../../../shared/icons/ClockIcon";
import ResetIcon from "../../../shared/icons/ResetIcon";
import TranscriptIcon from "../../../shared/icons/TranscriptIcon";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../shared/utils/duration-formatter";
import { defaultPaginationPageSizes, sortingFns } from "../../../shared/utils/tanstack-table";
import { TicketSummaryDisclosureState } from "../hooks/useTicketSummaryDisclosure";
import { isEmailTicket } from "../utils/comm-center-email-utils";
import {
  getTicketLastUpdatedAt,
  getTicketUnreadCount,
  getTopicEntityFromTicket,
  isCallTicket,
} from "../utils/communication-utils";
import ChatPreviewCell from "./ChatPreviewCell";
import EmailPreviewCell from "./Emails/EmailPreviewCell";
import PhoneCallPreviewCell from "./PhoneCallPreviewCell";
import { TableCellBadge } from "./TableCellBadge";
import TicketSourceCell from "./TicketSourceCell";
import { useCommCenterTicketPopup } from "../hooks/useCommCenterTicketPopup";

export interface TicketDataRow {
  id: CommCenterTicketId;
  name: string;
  source: Messages["CommCenterTicket"]["source"];
  entity: Entity | null;
  label: string;
  isLiveCall: boolean;
  lastMessage: Messages["CommCenterMessage"] | null;
  priority: "Low" | "Medium" | "High";
  estimatedResolveTime: number;
  waitingTime: { duration: Duration; formatted: string } | null;
  assignee: string | null;
  ticketStatus: "New" | "In Progress" | "Resolved";
  onboardingStage?: string; // enum
  unreadCount: number;
  team: string;
  callInfo: Messages["CommCenterTicket"]["callInfo"];
  chatSummaryDetails: Messages["CommCenterTicket"]["chatSummaryDetails"];
  summary: {
    summaryText: string;
    satisfactionRank: number;
    satisfactionText: string;
  } | null;
  updatedAt: Instant;
  emailThread: Messages["CommCenterTicket"]["emailThread"];
  messages: Messages["CommCenterTicket"]["messages"];
  status: Messages["CommCenterTicket"]["status"];
}

const zRouteState = z.object({
  pagination: z.object({
    pageIndex: z.number().default(0),
    pageSize: z.number().default(10),
  }),
  sorting: z
    .object({
      id: z.string(),
      desc: z.boolean(),
    })
    .array()
    .optional(),
});
export type RouteState = z.infer<typeof zRouteState>;

function getEntityName(entity: EntityWithStatus<Entity> | null) {
  if (entity === null) {
    return "Unknown";
  }

  switch (entity.type) {
    case "Caregiver":
    case "Patient":
    case "IntakePatient":
    case "Agency Member":
      return entity.fullName;
    case "NotIdentifiedPhoneNumber":
      return entity.phoneNumber;
    case "FaxNotAssignedEntity":
      return "Not Assigned";
    case "PhonebookContact":
    case "VisitInstance":
    case "VisitBroadcast":
    case "CommCenterTicket":
      return entity.id.toString();
    case "NotIdentifiedEmailEntity":
      return entity.email;
  }
}

function toDataRow(ticket: Messages["CommCenterTicket"]): TicketDataRow {
  const entity = getTopicEntityFromTicket(ticket);
  const unreadCount = getTicketUnreadCount(ticket);
  const summary = getTicketSummary(ticket);
  const name = getEntityName(entity);

  const lastEmailMessage = isEmailTicket(ticket) ? ticket.emailThread.messages.at(-1) : null;
  const lastChatMessage = !isEmailTicket(ticket) ? ticket.messages.at(-1) : null;

  const lastEmailMessageTime =
    lastEmailMessage?.direction === "INBOUND" ? lastEmailMessage.createdAt : null;
  const lastChatMessageTime =
    lastChatMessage?.createdBy.type === "Caregiver" ? lastChatMessage.createdAt : null;
  const lastMessageTime =
    lastEmailMessageTime ??
    lastChatMessageTime ??
    ticket.agencyReponseMeasureStartTime ??
    ticket.createdAt;

  const updatedAt = getTicketLastUpdatedAt(ticket);

  return {
    id: ticket.id,
    name,
    assignee: ticket.assignedTo?.name ?? null,
    source: ticket.source,
    entity: entity,
    label: ticket.label?.name ?? "",
    lastMessage: lastChatMessage ?? null,
    priority: formatPriortiy(ticket.priority),
    ticketStatus: formatStatus(ticket.status),
    estimatedResolveTime: ticket.label?.timeToResolve ?? 0,
    waitingTime: durationFormatter.relative(Duration.between(Instant.now(), lastMessageTime)),
    onboardingStage: ticket.relatedCaregiver?.onboardingStageDetails?.name ?? "",
    unreadCount: unreadCount,
    team: ticket.relatedTeam.name,
    callInfo: ticket.callInfo,
    chatSummaryDetails: ticket.chatSummaryDetails,
    isLiveCall: ticket.callInfo?.isLive ?? false,
    updatedAt,
    summary,
    emailThread: ticket.emailThread,
    messages: ticket.messages,
    status: ticket.status,
  };
}

function formatPriortiy(
  priority: Messages["CommCenterTicket"]["priority"]
): TicketDataRow["priority"] {
  switch (priority) {
    case "LOW":
      return "Low";
    case "MEDIUM":
      return "Medium";
    case "HIGH":
      return "High";
  }
}

function formatStatus(
  status: Messages["CommCenterTicket"]["status"]
): TicketDataRow["ticketStatus"] {
  switch (status) {
    case "NEW":
      return "New";
    case "IN PROGRESS":
      return "In Progress";
    case "RESOLVED":
      return "Resolved";
  }
}

const ticketStatusTextToColor = {
  New: "blue" as const,
  "In Progress": "yellow" as const,
  Resolved: "green" as const,
};

const priorityStatusTextToColor = {
  Low: "blue" as const,
  Medium: "yellow" as const,
  High: "red" as const,
};

interface Props {
  tickets: Messages["CommCenterTicket"][];
  tableRef?: React.MutableRefObject<ReactTable<TicketDataRow> | null>;
  onClickShowSummary: (state: TicketSummaryDisclosureState) => void;
  isGroupedByPatient: boolean;
}

export function TicketsTable(props: Props) {
  const { stateService } = useRouter();
  const tableSession = useTableSessionState({
    key: "commcenter-tickets",
    initialState: { sorting: [{ id: "updatedAt", desc: true }] },
  });
  const ticketPopup = useCommCenterTicketPopup();

  const data = React.useMemo(() => props.tickets.map(toDataRow), [props.tickets]);

  const columnHelper = createColumnHelper<TicketDataRow>();

  const columns = [
    columnHelper.accessor("entity", {
      cell: (info) => {
        const entity = info.getValue();

        if (entity === null) {
          return <Text>Unknown</Text>;
        }

        return <EntityCard entity={entity} />;
      },
      header: () => <span>Entity</span>,
    }),
    columnHelper.accessor("team", {
      cell: (info) => info.getValue(),
      header: () => <span>Team</span>,
    }),
    columnHelper.accessor("source", {
      cell: (info) => (
        <TicketSourceCell
          callInfo={info.row.original.callInfo}
          emailThread={info.row.original.emailThread}
          messages={info.row.original.messages}
          source={info.getValue()}
          status={info.row.original.status}
        />
      ),
      header: () => <span>Source</span>,
    }),
    columnHelper.accessor("assignee", {
      cell: (info) => info.getValue(),
      header: () => <span>Assignee</span>,
    }),
    columnHelper.accessor("ticketStatus", {
      cell: (info) => {
        const summary = info.row.original.summary;
        return (
          <Flex direction="row" gap={4} justifyContent="space-between">
            <TableCellBadge
              color={ticketStatusTextToColor[info.getValue()]}
              text={info.getValue()}
            />
            {summary && (
              <Tooltip hasArrow label="Show summary" placement="top">
                <IconButton
                  aria-label="summary"
                  colorScheme="blue"
                  icon={<TranscriptIcon />}
                  size="xs"
                  variant="solid"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onClickShowSummary(summary);
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        );
      },
      header: () => <span>Ticket Status</span>,
    }),
    columnHelper.accessor("updatedAt", {
      id: "updatedAt",
      sortingFn: "instant",
      cell: (info) => <Text>{dateFormatter.toDateOrDateTime(info.getValue())}</Text>,
      header: () => <span>Updated at</span>,
    }),
    columnHelper.accessor("label", {
      cell: (info) => info.getValue(),
      header: () => <span>Label</span>,
    }),
    columnHelper.accessor("lastMessage", {
      cell: (info) => {
        switch (info.row.original.source) {
          case "EMAIL":
            return (
              <EmailPreviewCell
                chatSummaryDetails={info.row.original.chatSummaryDetails}
                emailThread={info.row.original.emailThread}
              />
            );
          case "MANUAL":
          case "MOBILE_CHAT":
          case "SMS_CONVERSATION":
          case "SYSTEM_TRIGGER":
            return (
              <ChatPreviewCell
                chatSummaryDetails={info.row.original.chatSummaryDetails}
                lastMessage={info.getValue()}
                unreadCount={info.row.original.unreadCount}
              />
            );
          case "PHONE_CALL":
            return (
              <PhoneCallPreviewCell
                callInfo={info.row.original.callInfo}
                isLiveCall={info.row.original.isLiveCall}
                ticketId={info.row.original.id}
              />
            );
        }
      },
      header: () => <span>Preview</span>,
    }),
    columnHelper.accessor("onboardingStage", {
      cell: (info) => info.getValue(),
      header: () => <span>Onboarding Stage</span>,
    }),
    columnHelper.accessor("priority", {
      cell: (info) => (
        <TableCellBadge color={priorityStatusTextToColor[info.getValue()]} text={info.getValue()} />
      ),
      header: () => <span>Priority</span>,
    }),
    columnHelper.accessor("estimatedResolveTime", {
      cell: (info) => (
        <Flex align="center" gap={1}>
          <ClockIcon color="gray.400" />
          <Text>{info.getValue()} hours</Text>
        </Flex>
      ),
      header: () => <span>EST. resolve time</span>,
    }),
    columnHelper.accessor("waitingTime", {
      cell: (info) => (
        <Flex align="center" gap={1}>
          <ClockIcon color="gray.400" />
          <Text>{info.getValue()?.formatted}</Text>
        </Flex>
      ),
      header: () => <span>Waiting time</span>,
      sortingFn: (a, b, col) => {
        const aVal = a.getValue(col) as { duration: Duration } | null;
        const bVal = b.getValue(col) as { duration: Duration } | null;

        if (aVal === null && bVal === null) {
          return 0;
        }

        if (aVal === null) {
          return 1;
        }

        if (bVal === null) {
          return -1;
        }

        return aVal.duration.compareTo(bVal.duration);
      },
    }),
  ];

  const setSorting = (updater: Updater<SortingState>) => {
    if (typeof updater !== "function") {
      return;
    }

    const sorting = updater(tableSession.state.sorting ?? []);
    tableSession.setSorting(sorting);
  };

  const setPagination = (updater: Updater<PaginationState>) => {
    if (typeof updater !== "function") {
      return;
    }

    const pagination = updater(tableSession.state.pagination);
    tableSession.setPagination(pagination);
  };

  const table = useReactTable({
    data,
    columns,
    sortingFns: sortingFns,
    autoResetPageIndex: false,
    state: tableSession.state,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useDidMount(() => {
    if (props.tableRef !== undefined) {
      props.tableRef.current = table;
    }
  });

  const createHeaderFromColumn = (header: Header<TicketDataRow, any>) => {
    return (
      <Th
        key={header.id}
        _hover={{ bg: "gray.100" }}
        borderTopRadius="md"
        colSpan={header.colSpan}
        cursor="pointer"
        transition="100ms ease-in-out"
        onClick={header.column.getToggleSortingHandler()}
      >
        {header.isPlaceholder ? null : (
          <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
            <Flex alignItems="center" justifyContent="space-between">
              {flexRender(header.column.columnDef.header, header.getContext())}
              {{
                asc: <ArrowUpIcon />,
                desc: <ArrowDownIcon />,
              }[header.column.getIsSorted() as string] ?? null}
            </Flex>
          </div>
        )}
      </Th>
    );
  };

  const createTableRow = (row: Row<TicketDataRow>) => {
    const onClickRow = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (props.isGroupedByPatient) {
        if (row.original.entity === null || row.original.entity.type !== "Patient") {
          throw new Error("Expected entity to be a Patient");
        }

        ticketPopup.open({
          defaultTicketId: row.original.id,
          patientId: row.original.entity.id,
          primaryEntity: "Patient",
          contactDetails: null,
        });
      } else {
        e.ctrlKey || e.metaKey
          ? window.open(
              stateService.href("app.commcenter_ticket", { ticketId: row.original.id }),
              "_blank"
            )
          : stateService.go("app.commcenter_ticket", { ticketId: row.original.id });
      }
    };

    return (
      <Tr
        key={row.id}
        _hover={{ bg: "gray.50" }}
        bg={row.original.unreadCount > 0 ? "blue.50" : undefined}
        borderBottomColor={row.original.unreadCount > 0 ? "blue.100" : undefined}
        cursor="pointer"
        fontWeight={row.original.unreadCount > 0 ? 600 : undefined}
        onClick={onClickRow}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <Td key={cell.id} maxWidth={1380}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
      </Tr>
    );
  };

  return (
    <Stack spacing={1}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return createHeaderFromColumn(header);
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>{table.getRowModel().rows.map((row) => createTableRow(row))}</Tbody>
        </Table>
      </TableContainer>
      <Flex gap={4} justifyContent="space-between" pt={2}>
        <Flex gap={8}>
          <ButtonGroup>
            <Button isDisabled={!table.getCanPreviousPage()} onClick={() => table.setPageIndex(0)}>
              «
            </Button>
            <Button isDisabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}>
              ‹
            </Button>
            <Button isDisabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>
              ›
            </Button>
            <Button
              isDisabled={!table.getCanNextPage()}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            >
              »
            </Button>
          </ButtonGroup>

          <Stack alignItems="center" direction="row">
            <Text fontSize="md">Page</Text>
            <Stack alignItems="center" direction="row" w="150px">
              <Text as="b" fontSize="md">
                {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </Text>
              <Text>({table.getRowModel().rows.length} Rows)</Text>
            </Stack>
          </Stack>
        </Flex>
        <Flex gap={2}>
          {tableSession.canReset && (
            <Tooltip label="Reset adjustments" placement="top">
              <IconButton
                aria-label="Reset adjustments"
                icon={<ResetIcon />}
                variant="ghost"
                onClick={tableSession.reset}
              />
            </Tooltip>
          )}
          <Menu>
            <MenuButton as={Button}>Show {table.getState().pagination.pageSize}</MenuButton>
            <MenuList>
              {defaultPaginationPageSizes.map((pageSize) => (
                <MenuItem
                  key={pageSize}
                  value={pageSize}
                  onClick={() => table.setPageSize(pageSize)}
                >
                  Show {pageSize}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Stack alignItems="center" direction="row">
            <Text fontSize="md" textAlign="right" w="100px">
              Go to page:
            </Text>
            <Input
              defaultValue={table.getState().pagination.pageIndex + 1}
              type="number"
              w="60px"
              onChange={(e) => {
                table.setPageIndex(e.target.value ? Number(e.target.value) - 1 : 0);
              }}
            />
          </Stack>
        </Flex>
      </Flex>
    </Stack>
  );
}

function getTicketSummary(ticket: Messages["CommCenterTicket"]) {
  switch (true) {
    case isCallTicket(ticket) && ticket.callInfo.summary !== null: {
      return {
        summaryText: ticket.callInfo?.summary?.text ?? "",
        satisfactionRank: ticket.callInfo?.summary?.satisfaction.rankNumber ?? 0,
        satisfactionText: ticket.callInfo?.summary?.satisfaction.rankDescription ?? "",
      };
    }

    case ticket.chatSummaryDetails !== null: {
      return {
        summaryText: ticket.chatSummaryDetails?.summary ?? "",
        satisfactionRank: ticket.chatSummaryDetails?.satisfactionRankNumber ?? 0,
        satisfactionText: ticket.chatSummaryDetails?.satisfactionRankDescription ?? "",
      };
    }

    default:
      return null;
  }
}

export default TicketsTable;
