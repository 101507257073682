import { MenuItem } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import { capitalize, fmap } from "../../../../../shared/utils";
import { getFullName } from "../../../../../shared/utils/get-full-name";
import { phoneFormatter } from "../../../../../shared/utils/phone-formatter";
import { usePhoneNumberDialerPopup } from "../../../../call-center/hooks/usePhoneNumberDialerPopup";
import { useCommCenterTicketPopup } from "../../../../communication/hooks/useCommCenterTicketPopup";
import { AssignmentRecord } from "../assignments.utils";
import { VisitAssigmentEntityCardError } from "./VisitAssigmentEntityCardError";
import { VisitAssignmentEntityCard } from "./VisitAssignmentEntityCard";
import { VisitAssignmentEntityCardSkeleton } from "./VisitAssignmentEntityCardSkeleton";
import { getGenderPhotoURL } from "../../../../../shared/utils/get-gender-photo-url";

export function VisitAssignmentPatientCard(props: { query: UseQueryResult<AssignmentRecord> }) {
  const ticketPopup = useCommCenterTicketPopup();
  const dialerPopup = usePhoneNumberDialerPopup();
  const { stateService } = useRouter();

  switch (props.query.status) {
    case "pending":
      return <VisitAssignmentEntityCardSkeleton />;
    case "error":
      return <VisitAssigmentEntityCardError />;
    case "success": {
      const { displayId, id, phoneNumbers } = props.query.data.patient;
      return (
        <VisitAssignmentEntityCard
          description={`PATIENT #${displayId ?? id}`}
          name={getFullName(props.query.data.patient)}
          src={getGenderPhotoURL(props.query.data.patient.gender)}
          onClick={() => stateService.go("app.visitAssignments.assignment", { patient: id })}
          onClickChat={() => {
            ticketPopup.open({
              primaryEntity: "Patient",
              patientId: id,
              contactDetails: null,
            });
          }}
        >
          <>
            {phoneNumbers.nodes.map(({ phonenumber, label, type }) => (
              <MenuItem
                key={phonenumber}
                onClick={() => dialerPopup.open({ predefinedPhoneNumber: phonenumber })}
              >
                {phoneFormatter.formatNationalIfValid(phonenumber)} ({capitalize(type)}){" "}
                {fmap(label, (x) => `- ${x}`)}
              </MenuItem>
            ))}
          </>
        </VisitAssignmentEntityCard>
      );
    }
  }
}
