import { StateDeclaration, useCurrentStateAndParams, useTransitionHook } from "@uirouter/react";
import { atom, useAtomValue, useSetAtom } from "jotai";

const lastTransitionAtom = atom<StateDeclaration | null>(null);

export default function useLastRouteState() {
  return useAtomValue(lastTransitionAtom);
}

export function useLastRouteStateDispatcher() {
  const setLastTransition = useSetAtom(lastTransitionAtom);
  const { state } = useCurrentStateAndParams();
  useTransitionHook("onBefore", {}, () => {
    setLastTransition(state);
  });
}
