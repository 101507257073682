import VisitAssignmentRoute from "./VisitAssignmentRoute";
import VisitAssignmentsPage from "./VisitAssignmentsPage";

export default function VisitAssignmentsRoute() {
  return (
    <>
      <VisitAssignmentsPage />
      <VisitAssignmentRoute />
    </>
  );
}
