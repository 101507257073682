import { z } from "zod";
import { AgencyId } from "./shared/schema/schema";

const zEnv = z.object({
  API_URL: z.string(),
  API_TELEPHONY_URL: z.string().default(import.meta.env.API_URL),
  MEDFLYT_AGENCY_ID: z
    .string()
    .default("10001")
    .transform((v) => AgencyId.parse(parseInt(v, 10))),
  CLOUDONIX_DOMAIN: z
    .string()
    .default(import.meta.env.DEV ? "medflyt.cloudonix.net" : "cs.medflyt.com"),
  WORKFLOWS_API_URL: z
    .string()
    .default(import.meta.env.DEV ? "http://localhost:5173" : "https://workflows.medflyt.com"),
});

export const env = zEnv.parse(import.meta.env);
