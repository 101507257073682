import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";
import { PayrollBatchId } from "../../shared/schema/schema";

export default createQueryGroup(({ api }) => {
  return {
    payrollVerification: (id: PayrollBatchId, count?: number) =>
      queryOptions({
        queryKey: ["payroll-verification", id],
        queryFn: () => {
          return api.get("./payroll_verification/:payrollBatchId", {
            path: { payrollBatchId: id },
            query: { count },
          });
        },
      }),
  };
});
