import { FlexProps, Flex } from "@chakra-ui/react";

export default function Sidebar(props: FlexProps) {
  return (
    <Flex
      borderColor="gray.100"
      borderRight="1px"
      direction="column"
      flexShrink={0}
      gap={2}
      h="full"
      maxH="1360px"
      pr={6}
      w="400px"
      {...props}
    />
  );
}
