import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import { Button, Divider, Flex, Heading, LayoutProps, Text, ThemeTypings } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import { NoteId } from "../../../shared/schema/schema";
import { dateFormatter } from "../../../shared/utils/date-formatter";

interface Props {
  notes: Messages["IntakePatientProfile"]["notes"];
  width?: LayoutProps["width"];
  onClickNewNote: () => void;
  onClickMarkAsDone: (noteId: NoteId) => void;
}

const IntakePatientProfileNotesSection = (props: Props) => {
  return (
    <Flex
      background="#f2f5f3"
      border="1px"
      borderColor="lightgray"
      borderRadius="10px"
      direction="column"
      gap={4}
      width={props.width}
    >
      <Heading as="h1" marginLeft="15px" marginTop="15px" size="md">
        Patient Notes
      </Heading>
      <Flex direction="column" gap={5} marginLeft="15px" maxHeight="150px" overflowY="scroll">
        {props.notes.length > 0 ? (
          props.notes.map((note) => (
            <PatientIntakeNote
              key={`note-${note.id}`}
              note={note}
              onClickMarkAsDone={props.onClickMarkAsDone}
            />
          ))
        ) : (
          <NoNotesContainer />
        )}
        <Divider colorScheme="blackAlpha" variant="solid" />
      </Flex>
      <Button
        aria-label="Add new note"
        colorScheme="blue"
        leftIcon={<AddIcon />}
        marginBottom="15px"
        variant="link"
        width="100px"
        onClick={props.onClickNewNote}
      >
        {" "}
        Add New{" "}
      </Button>
    </Flex>
  );
};

export default IntakePatientProfileNotesSection;

interface PatientIntakeNoteProps {
  note: Messages["EntityNote"];
  onClickMarkAsDone: (noteId: NoteId) => void;
}

const PatientIntakeNote = (props: PatientIntakeNoteProps) => {
  const getStatusTextDisplay = (
    status: string
  ): { text: string; color: ThemeTypings["colorSchemes"] } => {
    switch (status) {
      case "IN_PROGRESS": {
        return {
          text: "In Progress",
          color: "orange",
        };
      }
      case "VIEWED": {
        return {
          text: "Viewed",
          color: "blue",
        };
      }
      case "DONE": {
        return {
          text: "Done",
          color: "green",
        };
      }
      default: {
        return {
          text: "No Status",
          color: "gray",
        };
      }
    }
  };

  return (
    <Flex alignItems="center" direction="row" justifyContent="space-between">
      <Text maxWidth="250px" width="150px">
        {dateFormatter.toDateTime(props.note.createdAt, {
          timezone: "America/New_York",
        })}
      </Text>
      <Text maxWidth="300px" width="300px">
        {parseNoteText(props.note.noteRichText)}
      </Text>
      <Text alignSelf="center" colorScheme={getStatusTextDisplay(props.note.status).color}>
        {" "}
        {getStatusTextDisplay(props.note.status).text}{" "}
      </Text>
      <Button
        aria-label="Mark as done"
        colorScheme="green"
        leftIcon={<CheckIcon />}
        marginRight="15px"
        variant="link"
        onClick={() => props.onClickMarkAsDone(props.note.id)}
      >
        Mark as done
      </Button>
    </Flex>
  );
};

const parseNoteText = (noteWithHtml: string | null) => {
  return noteWithHtml?.replace(/<[^>]+>/g, "");
};

const NoNotesContainer = () => {
  return (
    <Flex direction="column">
      <Text> Looks like there are no notes yet.</Text>
      <Text> Add a new one!</Text>
    </Flex>
  );
};
