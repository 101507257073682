import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";

export default createQueryGroup(({ api }) => {
  return {
    list: () =>
      queryOptions({
        queryKey: ["agency_member", "list"],
        queryFn: () => api.get("./agency_members", {}),
      }),
    intake: () =>
      queryOptions({
        queryKey: ["agency_member", "intake", "list"],
        queryFn: () => api.get("./intake/agency_members", {}),
      }),
  };
});
