import { Button, ButtonProps, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { useNewTicket } from "../hooks/useNewTicket";
import { NewTicketPopupProps } from "../../modules/communication/components/NewTicketPopup";

type AssignedCommCenterTeam =
  | "Intake"
  | "Staffing"
  | "Payroll"
  | "HR"
  | "Default Team"
  | "RN"
  | "Technical";

interface SendEmailButtonProps {
  buttonProps?: ButtonProps;
  text?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}

type Props = SendEmailButtonProps & {
  emails: { name: string; email: string }[];
  newTicketDefaultValues: NewTicketPopupProps["defaultValues"];
  defaultAssignedTeam?: AssignedCommCenterTeam;
};

export default function SendEmailButton(props: Props) {
  const { onOpen } = useNewTicket();

  const handleClickEmail = (email?: { name: string; email: string }) => {
    onOpen({
      initialLabelId: null,
      defaultTicketType: "Email",
      defaultEmailValues: {
        recipients: email && [email],
        assignedTeamName: props.defaultAssignedTeam,
      },
      defaultValues: props.newTicketDefaultValues,
    });
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        color="blue.500"
        fontSize="12px"
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        leftIcon={<EmailIcon />}
        variant="ghost"
        {...props.buttonProps}
      >
        {props.text ?? "Send Email"}
      </MenuButton>
      <MenuList>
        {props.emails.map((email) => (
          <MenuItem
            key={email.email}
            fontSize={12}
            onClick={() => handleClickEmail(email)}
          >{`${email.name} <${email.email}>`}</MenuItem>
        ))}
        <MenuItem fontSize={12} onClick={() => handleClickEmail()}>
          + New Empty Email
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
