import {
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { Messages, QueryParamsOf } from "../../../core/api";
import RangeDatePicker from "../../../shared/components/DatePicker/RangeDatePicker";
import Page from "../../../shared/components/Page";
import Select from "../../../shared/components/Select";
import { createFilters } from "../../../shared/hooks/useFilters";
import AddRoundedIcon from "../../../shared/icons/AddRoundedIcon";
import { FaxDetailsId } from "../../../shared/schema/schema";
import { FilterProps } from "../../../shared/utils/filter-props";
import CaregiverSelect from "../../caregiver/components/CaregiverSelect";
import PatientSelect from "../../patient/components/PatientSelect";
import { SelectEntityParams } from "../components/EditFaxEntitySection";
import FaxesTable from "../components/FaxesTable";
import SendFaxForm, { SendFaxRequest } from "../components/SendFaxForm";

interface Props extends FilterProps<"./faxes"> {
  faxes: Messages["DashboardFax"][];
  onSendFax: (request: SendFaxRequest) => void;
  onUpdateStatus: (faxId: FaxDetailsId) => void;
  onSelectEntity: (faxId: FaxDetailsId, entity: SelectEntityParams | null) => void;
}

const OpenFaxDashboardPage = (props: Props) => {
  const createFaxDisclosure = useDisclosure();

  const { createMultiSelectFilter, createRangeDatePickerFilter } =
    createFilters<QueryParamsOf<"get", "./faxes">>();

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date from to",
    startDate: {
      name: "createdAtFrom",
      value: props.filters.getValueOrNull("createdAtFrom"),
    },
    endDate: {
      name: "createdAtTo",
      value: props.filters.getValueOrNull("createdAtTo"),
    },
    onChange: props.onChangeFilter,
  });

  const faxStatusFilter = createMultiSelectFilter({
    name: "faxStatus",
    label: "Fax status",
    options: [
      { label: "SUCCESS", value: "success" },
      { label: "PROCESSING", value: "processing" },
      { label: "TRANSMITTING", value: "transmitting" },
      { label: "FAILED", value: "failed" },
    ],
    value: props.filters.getValueOrNull("faxStatus"),
    onChange: props.onChangeFilter,
  });

  const handleSendFax = (sendFaxRequest: SendFaxRequest) => {
    props.onSendFax(sendFaxRequest);
    createFaxDisclosure.onClose();
  };

  return (
    <Page>
      <Page.Header>
        <Flex gap={8} justifyContent="space-between" wrap="wrap">
          <Heading size="lg">Fax Dashboard</Heading>
        </Flex>
      </Page.Header>
      <Page.Filters>
        <Flex direction="column" gap={3}>
          <Heading size="sm">Filters</Heading>
          <Flex gap={3} wrap="wrap">
            <RangeDatePicker {...fromToFilter} />
            <Select {...faxStatusFilter} />
            <CaregiverSelect
              value={props.filters.getValueOrNull("caregiver")}
              onChange={(value) => props.onChangeFilter("caregiver", value?.id)}
            />
            <PatientSelect
              value={props.filters.getValueOrNull("patient")}
              onChange={(value) => props.onChangeFilter("patient", value?.id)}
            />
            <Button
              colorScheme="blue"
              leftIcon={<AddRoundedIcon />}
              ms="auto"
              onClick={createFaxDisclosure.onOpen}
            >
              New Fax
            </Button>
          </Flex>
        </Flex>
      </Page.Filters>

      <Divider />

      <Page.Content>
        <FaxesTable
          faxes={props.faxes}
          onSelectEntity={props.onSelectEntity}
          onUpdateStatus={props.onUpdateStatus}
        />
        <Drawer {...createFaxDisclosure} size="xl">
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <Center h="full">
                <SendFaxForm onSendFax={handleSendFax}></SendFaxForm>
              </Center>
            </DrawerBody>
          </DrawerContent>
          <DrawerOverlay />
        </Drawer>
      </Page.Content>
    </Page>
  );
};

export default OpenFaxDashboardPage;
