import { Button } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import LoadingButton from "../../../shared/components/LoadingButton";
import Select, { CustomSelectProps } from "../../../shared/components/Select";
import SelectNoResults from "../../../shared/components/SelectNoResults";
import useApi from "../../../shared/hooks/useApi";
import { WorkflowDefinitionCorrelationKey } from "../../../shared/schema/schema";

export default function WorkflowLatestDefinitionSelect(
  props: CustomSelectProps<WorkflowDefinitionCorrelationKey>
) {
  const { queries } = useApi();

  const query = useQuery(queries.workflow.latestDefinitions());

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          label={props.multiple ? "Workflows" : "Workflow"}
          options={query.data.workflows.map((x) => ({ value: x.correlationKey, label: x.name }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          width="fit-content"
          {...props}
        />
      );
  }
}
