import { Box, Input, List, ListItem } from "@chakra-ui/react";
import { fromPlaceId, toMfAddressComponents } from "mf-google-geocoder";
import React from "react";
import { Messages } from "../../../../../core/api";
import { GOOGLE_MAPS_API_KEY } from "../../../../../shared/consts";
import useGoogleMapsAddress from "../../../../../shared/hooks/useGoogleMapsAddress";

interface Props {
  onChange: (addressComponents: Messages["AddressComponents"] | null) => void;
  value: Messages["AddressComponents"] | null;
}

const PatientIntakeFlowStepFieldAddress = (props: Props) => {
  const {
    handleChange: handleChangeAddress,
    placePredictions,
    getDetails: getAddressDetails,
    showPredictions,
    input,
    setShowPredictions,
  } = useGoogleMapsAddress(props.value ?? undefined);

  const handleClickAddressPrediction = async (
    prediction: google.maps.places.AutocompletePrediction
  ): Promise<Messages["AddressComponents"] | null> => {
    setShowPredictions(false);

    try {
      const result = await getAddressDetails(prediction);
      const placeId = result?.place_id;

      if (placeId === undefined) {
        return null;
      }

      const addressDetails = await fromPlaceId(placeId, {
        apiKey: GOOGLE_MAPS_API_KEY,
        mfAutoFix: true,
      });
      return toMfAddressComponents(addressDetails);
    } catch (error) {
      console.error("Error getting address details", error);
      return null;
    }
  };

  const handleClickAddress = async (prediction: google.maps.places.AutocompletePrediction) => {
    const addressComponents = await handleClickAddressPrediction(prediction);
    props.onChange(addressComponents);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(null);
    handleChangeAddress(event);
  };

  return (
    <>
      <Input
        autoFocus
        autoComplete="new-password"
        sx={{
          borderWidth: 2,
          borderColor: "gray.200",
          padding: 4,
          fontSize: "xl",
          color: "black",
          width: "full",
          height: "auto",
          fontWeight: "500",
          transition: "none",
          _hover: {
            borderColor: "gray.300",
          },
          _focus: {
            borderColor: "blue.500",
          },
          "&[data-active='true']": {
            borderColor: "blue.500",
            bg: "blue.50",
          },
        }}
        value={input}
        onChange={handleChange}
      />
      {showPredictions ? (
        <StylePredisctionList
          placePredictions={placePredictions}
          onClickAddressPrediction={handleClickAddress}
        />
      ) : null}
    </>
  );
};

export default PatientIntakeFlowStepFieldAddress;

const StylePredisctionList = (props: {
  placePredictions: google.maps.places.AutocompletePrediction[];
  onClickAddressPrediction: (prediction: google.maps.places.AutocompletePrediction) => void;
}) => (
  <Box
    backgroundColor="white"
    border="1px"
    borderColor="chakra-border-color"
    padding={2}
    position="absolute"
    width="xl"
    zIndex={1}
  >
    <List
      display="flex"
      flexDirection="column"
      gap={4}
      maxHeight="200px"
      overflowY="auto"
      top="100%"
      width="100%"
    >
      {props.placePredictions.map((prediction) => (
        <ListItem
          key={prediction.place_id}
          borderBottom="1px"
          borderColor="chakra-border-color"
          cursor="pointer"
          onClick={() => props.onClickAddressPrediction(prediction)}
        >
          {prediction.description}
        </ListItem>
      ))}
    </List>
  </Box>
);
