import { SocketEvent, SocketEventPayloadOf } from "../../types/socket";
import { withSocketEventValidator } from "../schema/validate-message";
import { useReactBridgeDispatchEvent } from "./useReactBridgeEvent";

const useMultipleSocketEvent = <$SocketEventKey extends SocketEvent["type"]>(params: {
  keys: $SocketEventKey[];
  validate?: boolean;
  onEvent: (event: SocketEventPayloadOf<$SocketEventKey>, type: $SocketEventKey) => void;
}) => {
  useReactBridgeDispatchEvent({
    eventName: "SOCKET_EVENT_RECEIVED",
    onEvent: async (value) => {
      if (params.keys.includes(value.type as $SocketEventKey)) {
        const payload = await withSocketEventValidator({
          validate: params.validate === true,
          data: value.payload,
          socketName: value.type,
        });

        params.onEvent(
          payload as SocketEventPayloadOf<$SocketEventKey>,
          value.type as $SocketEventKey
        );
      }
    },
  });
};

export default useMultipleSocketEvent;
