import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { S3Object } from "../../../../shared/hooks/useUploadFiles";

function AttachmentTags(props: {
  attachments: S3Object[];
  onRemove: (attachment: S3Object) => void;
}) {
  return (
    <>
      {props.attachments.map((attachment) => (
        <Tag key={attachment.key} m={2} maxInlineSize="max-content" minInlineSize="fit-content">
          <TagLabel>{attachment.file.name}</TagLabel>
          <TagCloseButton onClick={() => props.onRemove(attachment)} />
        </Tag>
      ))}
    </>
  );
}

export default AttachmentTags;
