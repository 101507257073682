import {
  Box,
  Button,
  Flex,
  Heading,
  keyframes,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
// eslint-disable-next-line no-restricted-imports
import styled from "@emotion/styled";
import { Messages } from "../../../core/api";
import { CachedSignedDocumentWithShimmer } from "../../../shared/components/CachedSignedDocument";
import { CachedSignedFileWithShimmer } from "../../../shared/components/CachedSignedFile";
import CachedSignedImageModal from "../../../shared/components/CachedSignedImageModal";
import { CachedSignedVideoWithShimmer } from "../../../shared/components/CachedSignedVideo";
import DocumentChatMessage from "../../../shared/components/DocumentChatMessage";
import CheckDoubleFillIcon from "../../../shared/icons/CheckDoubleFillIcon";
import CheckLineIcon from "../../../shared/icons/CheckLineIcon";
import ClockIcon from "../../../shared/icons/ClockIcon";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { getCommCenterMessageCreatorName } from "../utils/communication-utils";

interface Props {
  message: Messages["CommCenterMessage"];
  displayEntityName: boolean;
}

const ChatMessage = (props: Props) => {
  const sentOrReceived = ["Caregiver", "Patient"].includes(props.message.createdBy.type)
    ? "received"
    : "sent";
  const entityName = getCommCenterMessageCreatorName(props.message);
  const [firstMessage, ...otherMessages] = props.message.payload;

  const relevantIcon = (() => {
    if (props.message.id < 0) {
      return (
        <Tooltip label="Sending...">
          <ClockIcon color="gray.500" />
        </Tooltip>
      );
    }

    if (props.message.readAt !== null) {
      return (
        <Tooltip label="Read">
          <CheckDoubleFillIcon color="blue.500" />
        </Tooltip>
      );
    }

    return (
      <Tooltip label="Sent">
        <CheckLineIcon color="gray.500" />
      </Tooltip>
    );
  })();

  return (
    <ChatMessage.Root data-is-new={props.message.id < 0} type={sentOrReceived}>
      <ChatMessage.Bubble type={sentOrReceived}>
        <Flex alignItems="center" gap={1} justifyContent="space-between" mb={1}>
          {props.displayEntityName && <Heading size="xs">{entityName}</Heading>}
          <Text color="gray.700" fontSize="xs">
            {dateFormatter.toDateOrDateTime(props.message.createdAt)}
          </Text>
          {sentOrReceived === "sent" && <Box px={1}>{relevantIcon}</Box>}
        </Flex>
        <ChatMessageContent message={firstMessage} />
        {otherMessages.map((payload, i) => (
          <ChatMessage.ContentBubble key={i} type={sentOrReceived}>
            <ChatMessageContent message={payload} />
          </ChatMessage.ContentBubble>
        ))}
      </ChatMessage.Bubble>
    </ChatMessage.Root>
  );
};

function ChatMessageContent(props: { message: Messages["CommCenterMessage"]["payload"][number] }) {
  switch (props.message.type) {
    case "TEXT":
      return <Text whiteSpace="break-spaces">{props.message.message}</Text>;
    case "IMAGE":
      return <CachedSignedImageModal height={200} src={props.message.url} width={200} />;
    case "ACTION":
      return <ChatMessageContentAction action={props.message} />;
    case "DOCUMENT":
      return <CachedSignedDocumentWithShimmer height={200} src={props.message.url} width={200} />;
    case "VIDEO":
      return <CachedSignedVideoWithShimmer height={200} src={props.message.url} width={200} />;
    case "ONBOARDING_MESSAGE":
      return <></>;
  }
}

function ChatMessageContentAction(props: { action: Messages["CommCenterMessagePayloadAction"] }) {
  switch (props.action.payload.payloadType) {
    case "TEXT":
      return <Text whiteSpace="break-spaces">{props.action.payload.text}</Text>;
    case "IMAGE":
      return <CachedSignedImageModal height={200} src={props.action.payload.url} width={200} />;
    case "DOCUMENT":
      return (
        <DocumentChatMessage
          data={props.action.payload.url}
          element={CachedSignedFileWithShimmer}
          height={200}
          width={200}
        />
      );
    case "VIDEO":
      return (
        <CachedSignedVideoWithShimmer height={200} src={props.action.payload.url} width={200} />
      );
    case "LIST":
      return <ChatMessage.ActionsPopover list={props.action.payload} />;
  }
}

const ActionsPopover = (props: { list: Messages["CommCenterMessagePayloadActionList"] }) => {
  return (
    <Popover placement="auto-end">
      <PopoverTrigger>
        <Button colorScheme="blue" size="sm" variant="outline" w="full">
          Show options
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <List spacing={5}>
            {props.list.items.map((item) => (
              <ListItem key={item.id}>{item.text}</ListItem>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
ChatMessage.ActionsPopover = ActionsPopover;

ChatMessage.Bubble = styled(Box)<{ type: "sent" | "received" }>`
  padding: 12px;
  border-radius: 12px;
  min-width: 120px;
  transition: all 250ms ease;

  ${({ type }) => {
    if (type === "received") {
      return css`
        background-color: var(--chakra-colors-gray-100);
      `;
    }

    return css`
      background-color: var(--chakra-colors-blue-100);
    `;
  }}
`;

const chatMessageIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.95);
    }
`;

ChatMessage.Root = styled(Flex)<{ type: "sent" | "received" }>`
  max-width: 80%;
  flex-direction: column;
  gap: 4px;

  &[data-is-new="true"] {
    animation: ${chatMessageIn} 0.3s ease-in-out backwards;
    opacity: 0.75;
  }

  ${({ type }) => {
    if (type === "received") {
      return css`
        margin-inline-end: auto;
        align-items: flex-start;
      `;
    }

    return css`
      margin-inline-start: auto;
      align-items: flex-end;
    `;
  }}
`;

ChatMessage.ContentBubble = styled(ChatMessage.Bubble)`
  border: 1px solid var(--chakra-colors-gray-300);
  margin: 5px;
  display: inline-block;
`;

export default ChatMessage;
