import { Flex, FlexProps, Spinner } from "@chakra-ui/react";
import AudioPlayer from "../../../../../../shared/components/AudioPlayer/AudioPlayer";
import BaselinePlayCircleIcon from "../../../../../../shared/icons/BaselinePlayCircleIcon";
import useCommCenterCallRecording from "../../../../hooks/useCommCenterCallRecording";
import CallTicketText from "../CallTicketText";

interface Props extends FlexProps {
  state: ReturnType<typeof useCommCenterCallRecording>;
}

const CallTicketRecording = ({ state, ...props }: Props) => {
  switch (state.type) {
    case "NoRecording":
      return <></>;
    case "HasRecording":
      return (
        <DefaultFlex
          color="blue.500"
          cursor="pointer"
          onClick={state.onRequestRecording}
          {...props}
        >
          <BaselinePlayCircleIcon />
          <CallTicketText>Play Recording</CallTicketText>
        </DefaultFlex>
      );
    case "LoadingRecording":
      return <Spinner />;
    case "RecodringReady":
      return (
        <DefaultFlex {...props}>
          <AudioPlayer ratesOptions={[1, 1.25, 1.5, 2]} src={state.recordingUrl} />
        </DefaultFlex>
      );
    case "ErrorLoading":
      return (
        <DefaultFlex color="red" cursor="auto" {...props}>
          Error loading recording
        </DefaultFlex>
      );
  }
};

const DefaultFlex = (props: FlexProps) => {
  return <Flex alignItems="inherit" gap="inherit" {...props}></Flex>;
};

export default CallTicketRecording;
