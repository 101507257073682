import { DateTimeFormatter, LocalDateTime } from "@js-joda/core";

export interface SuppliesShipmentsInformationFromCSV {
  shipmentType: "GLOVES" | "SCRUBS" | "BOTH" | null;
  caregiverEmail: string;
  caregiverName: string;
  shipmentDate: LocalDateTime;
  arrivalDate: LocalDateTime | null;
}

export const parseCSVFile = async (file: File): Promise<SuppliesShipmentsInformationFromCSV[]> => {
  const fileUrl = URL.createObjectURL(file);
  const response = await fetch(fileUrl);
  const text = await response.text();
  const lines = text.split("\n");

  const header = lines[0].split(",");
  const shipmentTypeIndex = header.indexOf("Line Item Name");
  const caregiverEmailIndex = header.indexOf("Customer Email");
  const caregiverNameIndex = header.indexOf("Customer Name");
  const shipmentDateIndex = header.indexOf("Ship Date");
  const arrivalDateIndex = header.indexOf("Delivered Date");

  const dataLines = lines.slice(1);
  const data = dataLines.map((line) => line.split(","));

  const formatter = DateTimeFormatter.ofPattern("M/d/yy H:mm");

  return data.map((line) => ({
    shipmentType: extractShipemntType(line, shipmentTypeIndex),
    caregiverEmail: line[caregiverEmailIndex],
    caregiverName: line[caregiverNameIndex],
    shipmentDate: LocalDateTime.parse(line[shipmentDateIndex], formatter),
    arrivalDate:
      line[arrivalDateIndex] === "" ? null : LocalDateTime.parse(line[arrivalDateIndex], formatter),
  }));
};

const extractShipemntType = (
  line: string[],
  index: number
): "GLOVES" | "SCRUBS" | "BOTH" | null => {
  const isScrubs = line[index].includes("Scrubs");
  const isGloves = line[index].includes("Gloves");

  if (isScrubs && isGloves) {
    return "BOTH";
  }

  if (isScrubs) {
    return "SCRUBS";
  }

  if (isGloves) {
    return "GLOVES";
  }

  return null;
};
