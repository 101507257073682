import { Center, Skeleton } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../../shared/components/Select";
import useApi from "../../../../../shared/hooks/useApi";
import { getFullName } from "../../../../../shared/utils/get-full-name";
import { CommCenterTicket } from "../../../../communication/communication.types";
import useEditTicket from "../../../../communication/pages/CommunicationCenterTicket/mutateEditTicket";

export function VisitAssignmentTicketAssigneeSelect(props: { ticket: CommCenterTicket }) {
  const { queries } = useApi();

  const editTicket = useEditTicket();

  const query = useQuery({
    ...queries.communication.teams(),
    select: (data) => {
      return data.flatMap((team) => {
        return team.members.flatMap((member) => {
          if (props.ticket.relatedTeam.id === team.id) {
            return [{ label: getFullName(member), value: member.id }];
          }

          if (props.ticket.assignedTo?.id === member.id) {
            return [
              {
                label: getFullName(member),
                value: member.id,
                description: `Member is not in team ${props.ticket.relatedTeam.name}`,
              },
            ];
          }

          return [];
        });
      });
    },
  });

  switch (query.status) {
    case "pending":
      return <Skeleton h={8} rounded="md" w={32} />;
    case "error":
      return <Center h={8}>Failed to load members</Center>;
    case "success":
      return (
        <Select
          label="Assignee"
          multiple={false}
          options={query.data}
          size="sm"
          value={props.ticket.assignedTo?.id ?? null}
          width="fit-content"
          onChange={(x) => editTicket.mutate({ id: props.ticket.id, body: { assignedToId: x } })}
        />
      );
  }
}
