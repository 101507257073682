import { createIcon } from "@chakra-ui/react";

const DialpadIcon = createIcon({
  displayName: "DialpadIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M10 3h4v3h-4zm0 5h4v3h-4zm0 5h4v3h-4zm6-10h4v3h-4zm0 5h4v3h-4zm0 5h4v3h-4zM4 3h4v3H4zm0 5h4v3H4zm0 5h4v3H4zm6 5h4v3h-4z"
      fill="currentColor"
    />
  ),
});

export default DialpadIcon;
