import { LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment";

//! @ngInject
export function _8850ModalCtrl($scope, $rootScope, NgTableParams, $state, $uibModalInstance, modalData, subsection, DatabaseApi, $timeout, $window, $stateParams, toaster) {

    $scope.question = {};
    $scope.subsection = subsection;

    for (let qg in $scope.subsection.questionGroups) {
        let questionGroup = $scope.subsection.questionGroups[qg];
        for (let question in questionGroup.questions) {
            if (questionGroup.questions[question].textId === 'F8850JobStartedDate') {
                $scope.question = questionGroup.questions[question];
                if ($scope.question.answer) {
                    $scope.question.answer = new Date(moment($scope.question.answer).format('MM/DD/YYYY'));
                }
                break;
            }
        }
    }

    $scope.updateQuestion = function () {
        const questionUrl = `hr/agencies/${$rootScope.agencyId}/caregivers/${modalData.caregiverId}/caregiver_application_answer_webapp`;

        const body = angular.copy($scope.question);
        body.answer = LocalDate.from(nativeJs(moment(body.answer))).toString();

        DatabaseApi.put(questionUrl, body).then((res) => {
            if (res.data.isSuccessful) {
                toaster.pop('success', 'Question was updated successfully');
            } else {
                toaster.pop('warning', 'something went wrong', '');
            }
        }, (err) => {
            toaster.pop('warning', 'something went wrong', '');
        });
    }

    $scope.submit8850Doc = function () {
        $scope.sending8850 = true;
        const postUrl = `hr/agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${modalData.caregiverId}/send_8850_data`;

        const questionBody = angular.copy($scope.question);
        questionBody.answer = LocalDate.from(nativeJs(moment(questionBody.answer))).toString();
        
        const body = {
            question: questionBody,
            subsectionId: $scope.subsection.data.id
        };

        DatabaseApi.post(postUrl, body).then((res) => {
            $window.open(res.data.url);
            $scope.sending8850 = false;
            $scope.closeModal('Completed');
        }, (err) => {
            toaster.pop('warning', 'something went wrong', '');
            $scope.sending8850 = false;
        });
    };

    $scope.closeModal = (res) => {
        if (res === undefined) {
            res = 'cancel';
        }
        $uibModalInstance.close(res);
    };
};