import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { Messages } from "../../../../../core/api";
import { IntakeExternalFlowId } from "../../../../../shared/schema/schema";
import usePatientIntakeFlowSettings from "../hooks/usePatientIntakeFlowSettings";
import { sortingFns } from "../../../../../shared/utils/tanstack-table";

const schema = z.object({
  id: z.string().min(2).transform(IntakeExternalFlowId.parse),
  title: z.string().min(2),
  description: z.string().min(2),
});

type Schema = z.infer<typeof schema>;

interface Props {
  disclosure: UseDisclosureReturn;
  externalFlows: Messages["IntakeExternalFlow"][];
  onClickRemove: ReturnType<
    typeof usePatientIntakeFlowSettings
  >["removeExternalFlowMutation"]["mutate"];
  onClickCreate: ReturnType<
    typeof usePatientIntakeFlowSettings
  >["createExternalFlowMutation"]["mutate"];
}

const defaultValues: Partial<Schema> = {
  id: undefined,
  title: undefined,
  description: undefined,
};

export default function ExternalFlowModal(props: Props) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Schema>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<Schema> = (data: Schema) => {
    props.onClickCreate(data, {
      onSuccess: () => reset(),
    });
  };

  return (
    <Modal {...props.disclosure} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>External Flows</ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap={2} w="full">
              <Heading size="sm">Create new external flow</Heading>
              <Flex gap={2} justifyContent="space-between" w="full">
                <Box w="full">
                  <Input placeholder="ID" type="text" {...register("id")} />
                  {errors.id && (
                    <Text color="red.500" fontSize="sm">
                      {errors.id.message}
                    </Text>
                  )}
                </Box>
                <Box w="full">
                  <Input placeholder="Title" type="text" {...register("title")} />
                  {errors.title && (
                    <Text color="red.500" fontSize="sm">
                      {errors.title.message}
                    </Text>
                  )}
                </Box>
                <Box w="full">
                  <Input placeholder="Description" type="text" {...register("description")} />
                  {errors.description && (
                    <Text color="red.500" fontSize="sm">
                      {errors.description.message}
                    </Text>
                  )}
                </Box>
                <Button
                  colorScheme="blue"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                  w="full"
                >
                  Create
                </Button>
              </Flex>
            </Flex>
          </form>
          <ExternalFlowsTable
            externalFlows={props.externalFlows}
            onClickRemove={props.onClickRemove}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface ExternalFlows {
  id: IntakeExternalFlowId;
  title: string;
  description: string;
}

function ExternalFlowsTable(props: {
  externalFlows: Messages["IntakeExternalFlow"][];
  onClickRemove: (id: IntakeExternalFlowId) => void;
}) {
  const columnHelper = createColumnHelper<ExternalFlows>();

  const data: ExternalFlows[] = React.useMemo(() => {
    return props.externalFlows
      .filter((x) => x.removedAt === null)
      .map((x) => ({
        id: x.id,
        title: x.title,
        description: x.description,
      }));
  }, [props.externalFlows]);

  const columns: ColumnDef<ExternalFlows, any>[] = [
    columnHelper.accessor("id", {
      header: () => <span>ID</span>,
      cell: (info) => <Text>{info.getValue()}</Text>,
    }),
    columnHelper.accessor("title", {
      header: () => <span>Title</span>,
      cell: (info) => <Text>{info.getValue()}</Text>,
    }),
    columnHelper.accessor("description", {
      header: () => <span>Description</span>,
      cell: (info) => <Text>{info.getValue()}</Text>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (info) => (
        <Box cursor="pointer">
          <CloseIcon color="red" onClick={() => props.onClickRemove(info.row.original.id)} />
        </Box>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    sortingFns: sortingFns,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const createHeaderFromColumn = (header: Header<ExternalFlows, any>) => {
    return (
      <Th
        key={header.id}
        _hover={{ bg: "gray.100" }}
        borderTopRadius="md"
        colSpan={header.colSpan}
        transition="100ms ease-in-out"
      >
        {header.isPlaceholder ? null : (
          <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
            <Flex alignItems="center" justifyContent="space-between">
              {flexRender(header.column.columnDef.header, header.getContext())}
            </Flex>
          </div>
        )}
      </Th>
    );
  };

  const createTableRow = (row: Row<ExternalFlows>) => {
    return (
      <Tr key={row.id} _hover={{ bg: "gray.50" }}>
        {row.getVisibleCells().map((cell) => {
          return (
            <Td key={cell.id} maxWidth={1380}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
      </Tr>
    );
  };

  return (
    <Flex direction="column">
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return createHeaderFromColumn(header);
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>{table.getRowModel().rows.map((row) => createTableRow(row))}</Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
