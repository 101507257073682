import { useEntityCommunications } from "../../hooks/useEntityCommunications";
import { useReactBridgeDispatchEvent } from "../../hooks/useReactBridgeEvent";
import EntityCommunications from "../../../modules/communication/components/entity-communications/EntityCommunications";
import { useDisclosure } from "@chakra-ui/react";

function EntityCommunicationsListener() {
  const { state, onOpen, onClose } = useEntityCommunications();
  const disclosure = useDisclosure({
    isOpen: state !== null,
    onClose: onClose,
  });

  useReactBridgeDispatchEvent({
    eventName: "COMM_CENTER:OPEN_ENTITY_COMMUNICATIONS",
    onEvent: (data) => {
      switch (data.entity) {
        case "Patient":
          onOpen({
            entity: "Patient",
            id: data.id,
          });
          break;
        case "Caregiver":
          onOpen({
            entity: "Caregiver",
            id: data.id,
          });
          break;
      }
    },
  });

  if (state === null) {
    return <></>;
  }

  return <EntityCommunications disclosure={disclosure} {...state} />;
}

export default EntityCommunicationsListener;
