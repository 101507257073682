import { PatientIntakeContact, PatientIntakeProfileDraft } from "../patient-intake.types";
import { Flex, Text } from "@chakra-ui/react";
import { PatientId } from "../../../shared/schema/schema";
import { IntakePatientContact, NewPatientContact } from "./IntakePatientContact";

export const EditablePatientContacts = (props: {
  isNewContactOpen: boolean;
  patientId: PatientId;
  contacts: PatientIntakeProfileDraft["contacts"];
  mainPhoneNumber: string | null;
  onToggleMainNumber: (value: string) => void;
  onChangeNewContactDetails: (isEdited: boolean) => void;
  onClickCloseNewContactForm: () => void;
  onClickSaveChanges: (contact: PatientIntakeContact) => void;
  onClickRemoveContact: (key: string) => void;
  onEditPatientContact: (key: string, contact: PatientIntakeContact) => void;
  onValidateNewContactPhoneNumber: (value: PatientIntakeContact) => boolean;
}) => {
  return props.contacts === null || props.contacts.length === 0 ? (
    <NewPatientContact
      index={0}
      isEditable={true}
      patientId={props.patientId}
      onChangeForm={props.onChangeNewContactDetails}
      onClickCloseForm={props.onClickCloseNewContactForm}
      onClickSaveContact={props.onClickSaveChanges}
      onToggleMainNumber={props.onToggleMainNumber}
      onValidateNewContactPhoneNumber={props.onValidateNewContactPhoneNumber}
    />
  ) : (
    <Flex direction="column" gap={6}>
      {props.contacts.map((contact, index) => (
        <IntakePatientContact
          key={contact.key}
          contact={contact}
          index={index}
          isEditable={true}
          mainPhoneNumber={props.mainPhoneNumber}
          openIndex={props.contacts === null ? -1 : props.contacts.length - 1}
          patientId={props.patientId}
          onClickRemoveContact={props.onClickRemoveContact}
          onEditPatientContact={props.onEditPatientContact}
          onToggleMainNumber={props.onToggleMainNumber}
        />
      ))}
      {props.isNewContactOpen ? (
        <NewPatientContact
          index={props.contacts?.length ?? 0}
          isEditable={true}
          patientId={props.patientId}
          onChangeForm={props.onChangeNewContactDetails}
          onClickCloseForm={props.onClickCloseNewContactForm}
          onClickSaveContact={(newContact) => {
            props.onClickCloseNewContactForm();
            props.onClickSaveChanges(newContact);
          }}
          onToggleMainNumber={props.onToggleMainNumber}
          onValidateNewContactPhoneNumber={props.onValidateNewContactPhoneNumber}
        />
      ) : null}
    </Flex>
  );
};

export const DisplayPatientContacts = (props: {
  patientId: PatientId;
  contacts: PatientIntakeProfileDraft["contacts"];
  mainPhoneNumber: string | null;
  onToggleMainNumber: (value: string) => void;
  onClickRemoveContact: (key: string) => void;
  onEditPatientContact: (key: string, contact: PatientIntakeContact) => void;
}) => {
  return props.contacts === null || props.contacts.length === 0 ? (
    <Text>
      Looks like there are no additional contacts yet.
      <br />
      Click the edit button to add one!
    </Text>
  ) : (
    <Flex direction="column" gap={4}>
      {props.contacts.map((contact, index) => (
        <IntakePatientContact
          key={contact.key}
          contact={contact}
          index={index}
          isEditable={false}
          mainPhoneNumber={props.mainPhoneNumber}
          openIndex={props.contacts === null ? -1 : props.contacts.length - 1}
          patientId={props.patientId}
          onClickRemoveContact={props.onClickRemoveContact}
          onEditPatientContact={props.onEditPatientContact}
          onToggleMainNumber={props.onToggleMainNumber}
        />
      ))}
    </Flex>
  );
};
