import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Highlight,
  HStack,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  PortalProps,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { useDebounce } from "../../../../../shared/hooks/useDebounce";
import CheckCircleOutlineIcon from "../../../../../shared/icons/CheckCircleOutlineIcon";
import { emailValidator } from "../../../../../shared/utils/email-utils";

type EmailInputEntity = {
  email: string;
  name: string;
};

type Props = {
  onSelect: (entity: EmailInputEntity) => void;
  label: string;
  value: EmailInputEntity[];
  containerRef?: PortalProps["containerRef"];
};

function EmailInput(props: Props) {
  const { api } = useApi();
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchText = useDebounce(searchText, 200);
  const popoverDisclosure = useDisclosure();

  const entitySearchQuery = useQuery({
    queryKey: ["entitySearch", { query: "email", term: debouncedSearchText }],
    enabled: debouncedSearchText !== "",
    queryFn: () => {
      return api.get("./comm_center/email/search/:term", { path: { term: debouncedSearchText } });
    },
    select: (res) => res.entities.slice(0, 5),
  });

  const selectedSet = React.useMemo(
    () => new Set(props.value.map((entity) => entity.email)),
    [props.value]
  );

  const handleChangeEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    popoverDisclosure.onOpen();
    setSearchText(event.target.value);
  };

  const selectSearchedEntity = () => {
    if (emailValidator(searchText)) {
      props.onSelect({ email: searchText, name: "" });
    }
    popoverDisclosure.onClose();
    setSearchText("");
  };

  const handleBlurEmailInput = () => {
    selectSearchedEntity();
  };

  const handleEmailInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab") {
      selectSearchedEntity();
    }
  };

  const isPopoverOpen =
    popoverDisclosure.isOpen &&
    entitySearchQuery.data !== undefined &&
    entitySearchQuery.data.length > 0;

  return (
    <Popover autoFocus={false} isOpen={isPopoverOpen} placement="bottom-start">
      <PopoverTrigger>
        <Flex alignItems="center" wrap="wrap">
          <FormControl alignItems="center" display="inline-flex" flexWrap="wrap">
            <FormLabel>{props.label}</FormLabel>
            <EmailInputSelectedTags selected={props.value} onRemove={props.onSelect} />
            <Input
              flex={1}
              minWidth={200}
              type="text"
              value={searchText}
              variant="ghost"
              onBlur={handleBlurEmailInput}
              onChange={handleChangeEmailInput}
              onKeyPress={handleEmailInputKeyPress}
            />
          </FormControl>
        </Flex>
      </PopoverTrigger>
      <Portal appendToParentPortal={true} containerRef={props.containerRef}>
        <PopoverContent>
          <Box as="ul" py={1}>
            {entitySearchQuery.data?.map((entity, i) => (
              <EmailInputEntityItem
                key={`${props.label}-${entity.email}-${i}`}
                entity={entity}
                isSelected={selectedSet.has(entity.email)}
                query={searchText ?? ""}
                onClick={() => {
                  setSearchText("");
                  props.onSelect(entity);
                }}
              />
            ))}
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

function EmailInputEntityItem({
  query,
  isSelected,
  onClick,
  entity,
}: {
  query: string;
  isSelected: boolean;
  onClick: () => void;
  entity: Messages["SearchEmailEntity"];
}) {
  const entityType = getEntityTypeDisplayText(entity.type);
  const entityDisplayText = entityType !== "" ? `(${entityType})` : "";
  const mainDisplayText = `${entity.name !== "" ? entity.name : entity.email} ` + entityDisplayText;
  const secondaryDisplayText = entity.name !== "" ? `<${entity.email}>` : "";
  return (
    <HStack
      key={mainDisplayText}
      _hover={{ bg: "gray.50" }}
      _selected={{ bg: "gray.200" }}
      aria-selected={isSelected}
      as="li"
      cursor="pointer"
      justifyContent="space-between"
      p={2}
      pointerEvents={isSelected ? "none" : undefined}
      onClick={onClick}
    >
      <Flex alignItems="center" gap={2}>
        <Avatar name={entity.name} size="sm" />
        <Flex direction="column" gap={0}>
          <Text>
            <Highlight query={query} styles={{ px: 0, py: 0, bg: "blue.100", borderRadius: 4 }}>
              {mainDisplayText}
            </Highlight>
          </Text>
          <Text>
            <Highlight query={query} styles={{ px: 0, py: 0, bg: "blue.100", borderRadius: 4 }}>
              {secondaryDisplayText}
            </Highlight>
          </Text>
        </Flex>
      </Flex>
      {isSelected && <CheckCircleOutlineIcon color="gray.500" h={7} w={7} />}
    </HStack>
  );
}

function getEntityTypeDisplayText(type: Messages["SearchEmailEntity"]["type"]) {
  switch (type) {
    case "Caregiver":
      return "Caregiver";
    case "NotIdentifiedEmailEntity":
      return "";
    case "Patient":
      return "Patient";
    case "PatientContact":
      return "Patient Contact";
  }
}

function EmailInputSelectedTags(props: {
  selected: EmailInputEntity[];
  onRemove: (entity: EmailInputEntity) => void;
}) {
  return (
    <>
      {props.selected.map((entity, i) => (
        <Tag
          key={`email-input-tag-${i}`}
          m={2}
          maxInlineSize="max-content"
          minInlineSize="fit-content"
        >
          <TagLabel>
            {entity.name !== "" ? `${entity.name} <${entity.email}>` : entity.email}
          </TagLabel>
          <TagCloseButton onClick={() => props.onRemove(entity)} />
        </Tag>
      ))}
    </>
  );
}

export default EmailInput;
