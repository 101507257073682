import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { ResponseOf } from "../../../../core/api";

const LatestErrorInEligibilityCheck = (props: {
  errorCheck: ResponseOf<"get", "./patients/:patientId/intake_eligibility_checks">["records"][0];
}) => {
  const description = `On latest check from ${dateFormatter.toDateTime(
    props.errorCheck.createdAt
  )} ${props.errorCheck.createdBy !== null ? `ran by ${props.errorCheck.createdBy}` : ""}`;

  return (
    <Alert status="error" variant="subtle">
      <AlertIcon />
      <AlertTitle>{props.errorCheck.error?.message}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
};

export default LatestErrorInEligibilityCheck;
