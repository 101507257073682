import { Box, Flex, Text } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import {
  CaregiverId,
  CommCenterTicketId,
  PatientId,
  VisitBroadcastId,
  VisitInstanceId,
  WorkflowTaskInstanceId,
} from "../../../../../shared/schema/schema";
import HumanTaskDefaultForm from "../HumanTaskDefaultForm";
import { HumanTaskSubmitParams } from "../HumanTaskForm";
import { HumanTaskLayoutWrapper } from "../components/HumanTaskLayoutWrapper";
import { CaregiverLinkCard } from "./components/CaregiverLinkCard";
import { FilesLinkCard } from "./components/FilesLinkCard";
import { IntakePatientLinkCard } from "./components/IntakePatientLinkCard";
import { PatientLinkCard } from "./components/PatientLinkCard";
import { TicketLinkCard } from "./components/TicketLinkCard";
import { VisitBroadcastLinkCard } from "./components/VisitBroadcastLinkCard";
import { VisitInstanceLinkCard } from "./components/VisitInstanceLinkCard";

interface Props {
  taskInstanceId: WorkflowTaskInstanceId;
  instructions: string;
  caregiver: CaregiverId | undefined;
  patient: PatientId | undefined;
  intakePatient: PatientId | undefined;
  visit: VisitInstanceId | undefined;
  visitBroadcast: VisitBroadcastId | undefined;
  ticket: CommCenterTicketId | undefined;
  files: string[] | undefined;
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  onSubmit: (params: HumanTaskSubmitParams) => void;
  onOpenAngularModal?: () => void;
  onCloseAngularModal?: () => void;
}

export default function HumanTaskEntityLayout(props: Props) {
  return (
    <HumanTaskLayoutWrapper>
      <Flex direction="row" gap={4}>
        <Flex direction="column" flex={1} gap={3}>
          <Box bg="gray.50" p={8} rounded="lg">
            <Text fontSize="lg" fontWeight="semibold">
              Instructions:
            </Text>
            <Text fontSize="lg" whiteSpace="pre-wrap">
              {props.instructions}
            </Text>
          </Box>
          <HumanTaskDefaultForm
            output={props.output}
            workflowTaskInstanceId={props.taskInstanceId}
            onSubmit={props.onSubmit}
          />
        </Flex>
        <Flex direction="column" gap={2} w="md">
          {props.caregiver && (
            <CaregiverLinkCard
              caregiverId={props.caregiver}
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
          {props.patient && (
            <PatientLinkCard
              patientId={props.patient}
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
          {props.intakePatient && <IntakePatientLinkCard patientId={props.intakePatient} />}
          {props.visit && (
            <VisitInstanceLinkCard
              visitInstanceId={props.visit}
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
          {props.visitBroadcast && (
            <VisitBroadcastLinkCard
              visitBroadcastId={props.visitBroadcast}
              onCloseAngularModal={props.onCloseAngularModal}
              onOpenAngularModal={props.onOpenAngularModal}
            />
          )}
          {props.ticket && <TicketLinkCard ticketId={props.ticket} />}
          {props.files && (
            <FilesLinkCard files={props.files} taskInstanceId={props.taskInstanceId} />
          )}
        </Flex>
      </Flex>
    </HumanTaskLayoutWrapper>
  );
}
