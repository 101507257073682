import { Box, Flex, Heading, Skeleton, Tooltip, chakra } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";
import { WorkflowTaskInstanceId } from "../../../../../shared/schema/schema";
import { WorkflowHumanTaskAssignment } from "../../../workflow.types";
import ClusteredTaskButton from "./ClusteredTaskButton";

type Props = {
  cluster: {
    id: string;
    value: string;
    name: string;
  };
  tasks: WorkflowHumanTaskAssignment[];
  activeTaskInstanceId: WorkflowTaskInstanceId | null;
  isButtonLoading: (task: WorkflowHumanTaskAssignment) => boolean;
  onChangeActiveTask: (task: WorkflowHumanTaskAssignment) => void;
  onRequestAssign: (instance: Messages["WorkflowHumanTaskInstance"]) => void;
};

export default function ClusteredTasks(props: Props) {
  return (
    <ClusteredTasks.Root>
      <ClusteredTasks.Heading cluster={props.cluster} />
      {props.tasks.map((task) => (
        <ClusteredTaskButton
          key={task.instance.id}
          isActiveTask={props.activeTaskInstanceId === task.instance.id}
          isLoading={props.isButtonLoading(task)}
          task={task}
          onChangeActiveTask={props.onChangeActiveTask}
          onRequestAssign={props.onRequestAssign}
        />
      ))}
    </ClusteredTasks.Root>
  );
}

ClusteredTasks.Root = chakra(Flex, {
  baseStyle: {
    alignItems: "center",
    gap: 4,
    overflow: "auto",
    px: 6,
    py: 4,
  },
});

function ClusteredTaskHeading(props: {
  cluster: {
    id: string;
    value: string;
    name: string;
  };
}) {
  return (
    <Box bg="white" left={0} position="sticky" px={4} zIndex={1}>
      <Tooltip label={`${props.cluster.id} (${props.cluster.value})`}>
        <Heading size="sm" whiteSpace="nowrap">
          {props.cluster.name}
        </Heading>
      </Tooltip>
    </Box>
  );
}
ClusteredTasks.Heading = ClusteredTaskHeading;

function ClusteredTasksSkeleton() {
  return (
    <Flex align="center" gap={4} overflow="auto" px={6} py={4}>
      <Skeleton h={5} mr={6} rounded="full" w={32} />
      <Skeleton h={10} rounded="full" w={48} />
      <Skeleton h={10} rounded="full" w={40} />
      <Skeleton h={10} rounded="full" w={60} />
      <Skeleton h={10} rounded="full" w={32} />
      <Skeleton h={10} rounded="full" w={24} />
      <Skeleton h={10} rounded="full" w={40} />
    </Flex>
  );
}
ClusteredTasks.Skeleton = ClusteredTasksSkeleton;
