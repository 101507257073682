import { createIcon } from "@chakra-ui/react";

const DocumentIcon = createIcon({
  displayName: "DocumentIcon",
  viewBox: "0 0 16 18",
  path: (
    <path
      clipRule="evenodd"
      d="M3 17.3337L13 17.3337C14.3807 17.3337 15.5 16.2144 15.5 14.8337V9.00033L15.5 5.98315C15.5 5.37208 15.2762 4.78217 14.8709 4.32488L13.4974 2.77533L12.4286 1.535C11.9536 0.983892 11.2622 0.666992 10.5347 0.666992H8H3C1.61929 0.666992 0.5 1.78628 0.5 3.16699V14.8337C0.500002 16.2144 1.61929 17.3337 3 17.3337ZM11.3333 2.81718L12.2424 3.87212L13.0947 4.83366H11.3333V2.81718ZM9.66667 2.33366V5.66699C9.66667 6.12723 10.0398 6.50033 10.5 6.50033H13.8333V9.00033L13.8333 14.8337C13.8333 15.2939 13.4602 15.667 13 15.667L3 15.667C2.53976 15.667 2.16667 15.2939 2.16667 14.8337L2.16667 3.16699C2.16667 2.70676 2.53976 2.33366 3 2.33366L8 2.33366L9.66667 2.33366ZM5.08333 10.2503C5.08333 9.79009 5.45643 9.41699 5.91667 9.41699H7.16667V8.16699C7.16667 7.70676 7.53976 7.33366 8 7.33366C8.46024 7.33366 8.83333 7.70676 8.83333 8.16699V9.41699H10.0833C10.5436 9.41699 10.9167 9.79009 10.9167 10.2503C10.9167 10.7106 10.5436 11.0837 10.0833 11.0837H8.83333V12.3337C8.83333 12.7939 8.46024 13.167 8 13.167C7.53976 13.167 7.16667 12.7939 7.16667 12.3337V11.0837H5.91667C5.45643 11.0837 5.08333 10.7106 5.08333 10.2503Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export default DocumentIcon;
