import { Leaves } from "../@ts-utils/leaves";

interface DropdownOption {
  id: unknown;
  label?: unknown;
}

export interface DropdownFilterSettings {
  enableSearch?: boolean;
  scrollable?: boolean;
  scrollableHeight?: number;
}

export interface DropdownEntity<T> {
  title: string;
  entityPath: Leaves<T>;
  options: DropdownOption[];
  values: DropdownOption[];
  settings?: DropdownFilterSettings;
}

export type CreateDropdownFilter = typeof createDropdownFilter;

export interface DropdownFilter<Item> {
  getValue: (entity: DropdownEntity<Item>, item: Item) => unknown | null,
  setOptionsFromLocalData: (items: Item[]) => void,
  filter: (item: Item) => boolean,
}

export function createDropdownFilter<Item extends object>(params: {
  dropdowns: DropdownEntity<Item>[];
}): DropdownFilter<Item> {
  const dropdowns: DropdownEntity<Item>[] = [...params.dropdowns];

  const getValue = (entity: DropdownEntity<Item>, item: Item): unknown | null => {
    let value = Object.assign(item, {}) as any;

    for (const key of (entity as any).entityPath) {
      value = value[key];
    }

    return value ?? null;
  };

  const filter = (item: Item) => {
    for (const filter of dropdowns) {
      const value = getValue(filter, item);
      if (filter.values.length > 0 && (
          (Array.isArray(value) && filter.values.map((val) => val.id).filter(val => value.includes(val)).length === 0)
          || (value === null || (!Array.isArray(value) && !filter.values.map((value) => value.id).includes(value))))){
            return false;
        }
    }

    return true;
  }

  const setOptionsFromLocalData = (items: Item[]) => {
    const sets: Set<unknown>[] = dropdowns.map(() => new Set());

    for (const item of items) {
      for (let i = 0; i < dropdowns.length; i++) {
        const entity = dropdowns[i];
        const value = getValue(entity, item);
        if (value !== null) {
          sets[i].add(value);
        }
      }
    }

    for (let i = 0; i < dropdowns.length; i++) {
      const entity = dropdowns[i];
      entity.options = [...sets[i]].map((val) => ({ id: val, label: val }));
    }
  };


  return {
    getValue,
    setOptionsFromLocalData,
    filter,
  };
}
