import ImportantCallsPopover from "@medflyt/webapp-react/src/modules/call-center/components/ImportantCallsPopover/index";
import DocumentViewer from "@medflyt/webapp-react/src/modules/compliance/components/DocumentViewer";
import EntityNotes from "@medflyt/webapp-react/src/modules/note/components/EntityNotes";
import UncontrolledEntityNoteFormControls from "@medflyt/webapp-react/src/modules/note/components/UncontrolledEntityNoteFormControls";
import UncontrolledEntityNotesFormControls from "@medflyt/webapp-react/src/modules/note/components/UncontrolledEntityNotesFormControls";
import PatientClinicalSeverityLevelTag from "@medflyt/webapp-react/src/modules/patient/components/PatientClinicalSeverityLevelTag";
import PatientSelect from "@medflyt/webapp-react/src/modules/patient/components/PatientSelect";
import HoursRestrictionErrorModal from "@medflyt/webapp-react/src/modules/OfficeHoursRestrictions/HoursRestrictionErrorModal";
import { withAppOnly, withBasicApp, withRouter } from "@medflyt/webapp-react/src/utils";
import angular from "angular";
import { angularize } from "react-in-angularjs";

export function angularizeReactComponents() {
  angularize(withBasicApp(DocumentViewer), "canvasViewer", angular.module("dashboard"), {
    src: "<",
  });

  angularize(
    withAppOnly(PatientClinicalSeverityLevelTag),
    "patientClinicalSeverityLevelBanner",
    angular.module("dashboard"),
    { clinicalSeverityLevel: "<" }
  );

  angularize(withAppOnly(EntityNotes), "reactEntityNotes", angular.module("dashboard"), {
    entity: "<",
    onClose: "&",
  });

  angularize(
    withAppOnly(UncontrolledEntityNoteFormControls),
    "reactEntityNoteFormControls",
    angular.module("dashboard"),
    {
      isAnswerInvalid: "<",
      isMessageInvalid: "<",
      onChange: "<",
      noteType: "<",
    }
  );

  angularize(
    withAppOnly(UncontrolledEntityNotesFormControls),
    "reactEntityNotesFormControls",
    angular.module("dashboard"),
    {
      isAnswerInvalid: "<",
      isMessageInvalid: "<",
      onChange: "<",
      noteTypes: "=",
      onSuccess: "&",
    }
  );

  angularize(withAppOnly(PatientSelect), "reactPatientSelect", angular.module("dashboard"), {
    onChange: "<",
    value: "<",
  });

  angularize(
    withAppOnly(withRouter(ImportantCallsPopover)),
    "reactImportantCalls",
    angular.module("dashboard"),
    {}
  );

  angularize(
    withAppOnly(withRouter(HoursRestrictionErrorModal)),
    "reactHoursRestrictionErrorModal",
    angular.module("dashboard"),
    {
      conflicts: "<",
      onClose: "&",
    }
  );
}
