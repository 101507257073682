import { Flex, FormControl, FormLabel, Heading, Input } from "@chakra-ui/react";
import { Instant, LocalDate, LocalDateTime, LocalTime, ZoneId } from "@js-joda/core";
import { toLocalDateTime } from "../../../shared/utils/date-utils";
import IntakePatientProfileDatepicker from "./IntakePatientProfileDatepicker";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";

const NEW_YORK_TZ = "America/New_York";
enum NYIAResults {
  Approved = "Approved",
  Denied = "Denied",
}

enum NYIALocation {
  FaceToFace = "Face-to-face",
  Zoom = "via Zoom",
}

interface Props {
  isEditMode: boolean;
  NYIA1Date: Instant | null;
  NYIA1Status: boolean;
  NYIA2Date: Instant | null;
  NYIA2Status: boolean;
  NYIA1Location: ("Face-to-face" | "via Zoom") | null;
  NYIA2Location: ("Face-to-face" | "via Zoom") | null;
  NYIA1Clinician: string | null;
  NYIA2Clinician: string | null;
  NYIAResults: ("Approved" | "Denied") | null;
  onChangeField: (
    field:
      | "NYIA1AppoitmentDate"
      | "NYIA2AppoitmentDate"
      | "NYIA1Completed"
      | "NYIA2Completed"
      | "NYIAResults"
      | "NYIA1Location"
      | "NYIA2Location"
      | "NYIA1Clinician"
      | "NYIA2Clinician",
    value: boolean | null | NYIAResults | NYIALocation | string | Instant
  ) => void;
}

const NYIAStatusOptions = [
  {
    value: "Completed",
    label: "Completed",
  },
  {
    value: "Not completed",
    label: "Not completed",
  },
];

const NYIAResultsOptions = [
  {
    value: NYIAResults.Approved,
    label: NYIAResults.Approved,
  },
  {
    value: NYIAResults.Denied,
    label: NYIAResults.Denied,
  },
  {
    value: null,
    label: "Unknown",
  },
];

const NYIALocationOptions = [
  {
    value: NYIALocation.FaceToFace,
    label: NYIALocation.FaceToFace,
  },
  {
    value: NYIALocation.Zoom,
    label: NYIALocation.Zoom,
  },
];
const validateStringInNYIAResults = (value: string) => {
  if (!(value in NYIAResults)) {
    throw new Error(
      `NYIAResults validation error. Expected "${value}" to be one of "${NYIAResults}`
    );
  }
  return NYIAResults[value as keyof typeof NYIAResults];
};

const IntakeProfileNYIAInfo = (props: Props) => {
  const handleDateChange = (
    field: "NYIA1AppoitmentDate" | "NYIA2AppoitmentDate",
    value: LocalDate | LocalDateTime | null
  ) => {
    if (value === null) {
      props.onChangeField(field, null);
    } else {
      const newValue = value instanceof LocalDate ? LocalDateTime.of(value, LocalTime.MIN) : value;
      const instant = newValue.atZone(ZoneId.of(NEW_YORK_TZ)).toInstant();
      props.onChangeField(field, instant);
    }
  };

  const nyia1Date =
    props.NYIA1Date !== null ? toLocalDateTime(props.NYIA1Date, { timezone: NEW_YORK_TZ }) : null;
  const nyia2Date =
    props.NYIA2Date !== null ? toLocalDateTime(props.NYIA2Date, { timezone: NEW_YORK_TZ }) : null;

  return (
    <Flex direction="column" gap={4}>
      <Heading as="h1" size="md">
        {" "}
        NYIA Information
      </Heading>
      <Heading as="h2" size="sm">
        {" "}
        NYIA #1
      </Heading>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          label="Date"
          placement="right"
          value={nyia1Date}
          withTime={true}
          onChange={(value) => handleDateChange("NYIA1AppoitmentDate", value)}
        />
        <IntakePatientProfileDropdown
          currValue={{
            value: props.NYIA1Location,
            label: props.NYIA1Location?.toString() ?? "Not chosen",
          }}
          isEditable={props.isEditMode}
          isRequired={false}
          label="Location"
          options={NYIALocationOptions}
          onChange={(value) => props.onChangeField("NYIA1Location", value as NYIALocation | null)}
        />
        <FormControl>
          <FormLabel>Clinician</FormLabel>
          <Input
            autoComplete="new-password"
            isDisabled={!props.isEditMode}
            placeholder="Clinician name"
            value={props.NYIA1Clinician ?? ""}
            onChange={(val) => props.onChangeField("NYIA1Clinician", val.target.value)}
          />
        </FormControl>
        <IntakePatientProfileDropdown
          currValue={{
            value: props.NYIA1Status ? "Completed" : "Not Completed",
            label: props.NYIA1Status ? "Completed" : "Not Completed",
          }}
          isEditable={props.isEditMode}
          isRequired={false}
          label="Status"
          options={NYIAStatusOptions}
          onChange={(value) =>
            props.onChangeField("NYIA1Completed", value === "Completed" ? true : false)
          }
        />
      </Flex>
      <Heading as="h2" size="sm">
        {" "}
        NYIA #2
      </Heading>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          label="Date"
          placement="right"
          value={nyia2Date}
          withTime={true}
          onChange={(value) => handleDateChange("NYIA2AppoitmentDate", value)}
        />
        <IntakePatientProfileDropdown
          currValue={{
            value: props.NYIA2Location,
            label: props.NYIA2Location?.toString() ?? "Not chosen",
          }}
          isEditable={props.isEditMode}
          isRequired={false}
          label="Location"
          options={NYIALocationOptions}
          onChange={(value) => {
            props.onChangeField("NYIA2Location", value as NYIALocation | null);
          }}
        />
        <FormControl>
          <FormLabel>Clinician</FormLabel>
          <Input
            autoComplete="new-password"
            isDisabled={!props.isEditMode}
            placeholder="Clinician name"
            value={props.NYIA2Clinician ?? ""}
            onChange={(val) => props.onChangeField("NYIA2Clinician", val.target.value)}
          />
        </FormControl>
        <IntakePatientProfileDropdown
          currValue={{
            value: props.NYIA2Status ? "Completed" : "Not Completed",
            label: props.NYIA2Status ? "Completed" : "Not Completed",
          }}
          isEditable={props.isEditMode}
          isRequired={false}
          label="Status"
          options={NYIAStatusOptions}
          onChange={(value) =>
            props.onChangeField("NYIA2Completed", value === "Completed" ? true : false)
          }
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDropdown
          currValue={{
            value: props.NYIAResults,
            label: props.NYIAResults !== null ? props.NYIAResults : "Unknown",
          }}
          isEditable={props.isEditMode}
          isRequired={false}
          label="NYIA Results"
          options={NYIAResultsOptions}
          onChange={(value) =>
            props.onChangeField(
              "NYIAResults",
              value === null ? value : validateStringInNYIAResults(value)
            )
          }
        />
      </Flex>
    </Flex>
  );
};

export default IntakeProfileNYIAInfo;
