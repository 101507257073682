import { Box, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useSearchCaregiversQuery } from "../../modules/caregiver/hooks/useSearchCaregiversQuery";
import { useDebounce } from "../hooks/useDebounce";
import EntityPreview from "./DialerEntityPreview";
import { EntitySelectItemShimmer } from "./EntitySelect";

interface Props {
  onClickDial: (E164number: string) => void;
}

const DialerCaregiversSearch = (props: Props) => {
  const [caregiverSearchText, setCaregiverSearchText] = useState("");
  const debouncedCaregiverSearchText = useDebounce(caregiverSearchText, 200);
  const caregivers = useSearchCaregiversQuery({ term: debouncedCaregiverSearchText });
  return (
    <>
      <Input
        placeholder="Search caregivers..."
        type="text"
        value={caregiverSearchText}
        onChange={(e) => setCaregiverSearchText(e.currentTarget.value)}
      />
      <Box>
        {(() => {
          if (debouncedCaregiverSearchText.trim() === "") {
            return <></>;
          }

          switch (caregivers.status) {
            case "pending":
              return <EntitySelectItemShimmer />;
            case "error":
              return <Text>Error loading</Text>;
            case "success": {
              if (caregivers.data.length === 0) {
                return <Text>Not Found</Text>;
              }

              return caregivers.data.map((caregiver) => (
                <EntityPreview
                  key={caregiver.id}
                  entity={{
                    type: "Caregiver",
                    ...caregiver,
                  }}
                  onClickDial={props.onClickDial}
                />
              ));
            }
          }
        })()}
      </Box>
    </>
  );
};

export default DialerCaregiversSearch;
