import moment from "moment";

//! @ngInject
export function standardReportViewCtrl(
    $scope,
    $rootScope,
    $state,
    $filter,
    $uibModal,
    reportService,
    uiGridExporterConstants,
    DatabaseApi,
    toaster
  ) {
    $scope.goToReportState = (reportState) => {
        $state.go(reportState);
    }


$scope.handleBack = () =>{
  $state.go('app.reports.standard');
}
    const parseFilters = (filters) => {
      for (let i = 0; i < filters.length; i++) {
        let item = filters[i];

      
        if (item["aggregate"] === undefined) {
          filters[i].aggregate = null;
        }
        if (!filters[i].filterSelected) {
          filters.splice(i);
        }
        if (item["$selectedModel"]) {
          item.value = [];
          for (let j = 0; j < item["$selectedModel"].length; j++) {
            let selectedArrayItem = item["$selectedModel"][j];
            item.value.push(selectedArrayItem.id);
          }
          if (item.value.length === 0) {
          item.filterSelected  = false;
          }
        }
      }

      return filters.filter((val)=>{
        return val.filterSelected == true ? true:  false;
      }).map((filter) => ({
        ...filter,
        tableColumn: filter.name,
        // aggregate: null,
        isLocked: filter.isLocked,
        value: parseFilterValueToObject(filter),
      }));
    };

    const parseFilterValueToObject = (filter) => {
      let valueString = parseFilterValueString(filter);
      
      let valueObj = {};
      if (filter.type === "MULTISELECT") {
        return {
          type: "multiselect",
          value: filter.value,
        };
      }
      switch (filter.operator) {
        case "LAST X":
        case "THIS X":
        case "NEXT X": {
          valueObj = {
            type: "timePeriod",
            timePeriod: valueString,
          };
          break;
        }
        case "LAST N X":
        case "NEXT N X": {
          valueObj = {
            type: "timePeriodInterval",
            value: {
              interval: filter.timePeriodValue.interval,
              timePeriod: filter.timePeriodValue.timePeriod,
            },
          };
          break;
        }
        case ("LAST REL N X"):
        case ("NEXT REL N X"):  {
          valueObj = {
            type: "timePeriodNowRelative",
            value: {
              negate: false,
              interval: filter.timePeriodValue.interval,
              timePeriod: filter.timePeriodValue.timePeriod,
            },
          };
          break;
        }
        case ("NOW REL TIME COMPARE"):
          valueObj = {
            type: "timeComparisonNowRelative",
            value: {
              comparison: filter.timePeriodValue.comparison,
              interval: filter.timePeriodValue.interval,
              timePeriod: filter.timePeriodValue.timePeriod
            }
          }
        break;
        default:
          valueObj = {
            type: "string",
            value: valueString,
          };
          break;
      }

        return valueObj;
    }

    const parseFilterValueString = (filter) => {
        switch (filter.type) {
            case "TIMESTAMPTZ":
            case "TIMESTAMP":
            case "DATE":
                return $filter("mfShortTime")(filter.value, ['withDate', 'withSeconds'])
            case "TIME":
                return $filter("mfShortTime")(filter.value, ['withSeconds'])
            case "MULTISELECT":
                return filter.value;
            default:
                return "" + filter.value;
        }
    }
    $scope.export = (format) => {
      if (format == 'csv') {
        var myElement = angular.element(document.querySelectorAll(".custom-csv-link-location"));
        var rowTypes = uiGridExporterConstants.ALL;
        var colTypes = uiGridExporterConstants.ALL;
        // this.gridOptions.exporterCsvFilename = getFileName(this.reportName);
        $rootScope.gridApi.exporter.csvExport(rowTypes,colTypes,myElement );
      } else if (format == 'xlsx') {

        var rowTypes = uiGridExporterConstants.ALL;
        var colTypes = uiGridExporterConstants.ALL;
        $rootScope.gridApi.exporter.excelExport(rowTypes,colTypes );
      };
    };

    const parseSavedFilterValueToValue = (filter) => {
        if (filter.type === "NUMBER") {
            return (Number.parseInt(filter.value.value, 10));
        }

        if (filter.type === "BOOLEAN") {
            return (filter.value.value === "true");
        }

        let newValue;

        switch (filter.operator) {
            case ("LAST X"):
            case ("THIS X"):
            case ("NEXT X"): {
                newValue = filter.value.timePeriod;
                break;
            }
            case ("LAST N X"):
            case ("NEXT N X"): 
            case ("LAST REL N X"):
            case ("NEXT REL N X"): {
                newValue = {
                    interval: filter.value.interval,
                    timePeriod: filter.value.timePeriod
                }
                break;
            }
            case ("NOW REL TIME COMPARE"):
              newValue = {
                comparison: filter.value.comparison,
                interval: filter.value.interval,
                timePeriod: filter.value.timePeriod
              }
              break;
            default:
                newValue = filter.value.value;
                break;
        }

        return newValue;
    }
    $scope.loadingReport = false;
    $scope.gridOptions = {};
    const isValueEmpty = (filterValue, filterOperator) => {
        return ((filterValue === undefined || filterValue === "") && !["IS NULL", "NOT IS NULL"].includes(filterOperator));
    }
    const handleLoadStandardReport = (reportId) => {

        if (!reportId) {
            return;
        }
        reportService.getStandardReport(reportId).then((loadedSavedReport) => {

            $scope.gridOptions = {
                ...loadedSavedReport.report_table_def,
                exporterMenuCsv: true,
                exporterMenuPdf: false,
                enableGridMenu: true,
                enableGroupHeaderSelection: true,
                treeRowHeaderAlwaysVisible: false,
                paginationPageSizes: [25, 50, 100],
                paginationPageSize: 50,
                showColumnFooter:false,
                onRegisterApi: function(gridApi){
                    $rootScope.gridApi = gridApi;
                },
                enableFiltering: true

            };
            $scope.report = {
                ...loadedSavedReport.report_template,
                reportType:loadedSavedReport.report_type,
                id: loadedSavedReport.id,
                name: loadedSavedReport.name,
                description: loadedSavedReport.description,
                primaryTable: loadedSavedReport.report_type === 'CUSTOM' ? null : loadedSavedReport.report_template.primaryTable.tableName,
            };
            for (const filter of loadedSavedReport.report_template.filters) {
                filter.value = parseSavedFilterValueToValue(filter);
            }

        }).catch(err => toaster.pop("error", "Something went wrong", "Failed to load saved report"));
    }

    $scope.handleRemoveFilter = (index, tableName, columnName) => {
        $scope.reportsSchema.tables.find(table => table.tableName === tableName).columns.find(column => column.name === columnName).filterSelected = false;
        $scope.report.filters.splice(index, 1);
    }

    $scope.handleRunReport = () => {
      $scope.loadingReport = true;
      const currentReport = angular.copy($scope.report);
      
      const reportToSave = {
        ...currentReport,
        format: "json",
        primaryTable:
          currentReport.report_type === "REPORTING_TOOL"
            ? { tableName: currentReport.primaryTable, columns: [] }
            : null,

        // tables: getTablesArrayByColumns(currentReport.columns),
        // columns: parseColumns(currentReport.columns),
        filters: parseFilters(currentReport.filters),
        timezone: moment.tz.guess(true),
      };
      if (reportToSave.reportType === "CUSTOM") {
        
        reportService
          .executeCustomReport(reportToSave)
          .then((data) => {
            let lines = data.lines;
            $scope.gridOptions.data = lines;

                toaster.pop("success", "Success", "Successfully created report");
            }).catch((error) => {
                // console.log(error);
                let err = error.data;
                toaster.pop("error", err.error, err.details.join("\n"));                
                // toaster.pop("error", err.error, err.details);
            }).finally(() => {
                $scope.loadingReport = false;
            });

        }else {
            reportService.executeReport(reportToSave).then((data) => {
                toaster.pop("success", "Success", "Successfully created report");

                $scope.gridOptions.data = data.lines;

            }).catch((error) => {
                
                let err = error.data;
                
                toaster.pop("error", err.error, err.details.join("\n"));
                // toaster.pop("error", "Something went wrong", "Failed to create report");
            }).finally(() => {
                $scope.loadingReport = false;
            });
        }
    }
    function initialize() {
        $scope.savedReportId = $state.params.standardReportId;
        handleLoadStandardReport($scope.savedReportId);
        $scope.isAdmin = $rootScope.user.admin;
    }

    initialize();
};
