import { SocketEvent, SocketEventPayloadOf } from "../../types/socket";
import { withSocketEventValidator } from "../schema/validate-message";
import { Jsonify } from "../utils/jsonify";
import { useReactBridgeDispatchEvent } from "./useReactBridgeEvent";

const useSocketEvent = <
  $SocketEventKey extends SocketEvent["type"],
  TValidate extends boolean
>(params: {
  key: $SocketEventKey;
  validate?: TValidate;
  onEvent: (
    event: TValidate extends true
      ? SocketEventPayloadOf<$SocketEventKey>
      : Jsonify<SocketEventPayloadOf<$SocketEventKey>>
  ) => void;
}) => {
  useReactBridgeDispatchEvent({
    eventName: "SOCKET_EVENT_RECEIVED",
    onEvent: async (value) => {
      if (value.type === params.key) {
        const payload = await withSocketEventValidator({
          validate: params.validate === true,
          data: value.payload,
          socketName: params.key,
        });

        params.onEvent(payload);
      }
    },
  });
};

export default useSocketEvent;
