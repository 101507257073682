import { Duration, Instant } from "@js-joda/core";

function createDurationFormatter() {
  const rtf = new Intl.RelativeTimeFormat("en", { style: "long" });

  const formatter = {
    relative: (duration: Duration) => {
      const formatted = (() => {
        if (Math.abs(duration.toDays()) >= 1) {
          return rtf.format(duration.toDays(), "days");
        }

        if (Math.abs(duration.toHours()) >= 1) {
          return rtf.format(duration.toHours(), "hours");
        }

        if (Math.abs(duration.toMinutes()) >= 1) {
          return rtf.format(duration.toMinutes(), "minutes");
        }

        if (Math.abs(duration.toMillis()) / 1000 > 10) {
          return rtf.format(Math.floor(duration.toMillis() / 1000), "seconds");
        }

        return "Just now";
      })();

      return { duration, formatted };
    },

    relativeFrom: (from: Instant) => formatter.relative(Duration.between(Instant.now(), from)),

    hours: (duration: Duration, style: "short" | "long" = "long") => {
      const durationSeconds = Math.floor(duration.toMillis() / 1000);
      const numberWrapper = style === "long" ? withTrailingZero : (val: string) => val;
      const hours = Math.floor(durationSeconds / 60 / 60);
      const minutes = Math.floor((durationSeconds % 3600) / 60);
      const seconds = Math.floor(durationSeconds % 60);

      if (hours === 0) {
        return `${numberWrapper(minutes.toString())}:${numberWrapper(seconds.toString())}`;
      }
      return `${numberWrapper(hours.toString())}:${numberWrapper(
        minutes.toString()
      )}:${numberWrapper(seconds.toString())}`;
    },
  };

  return formatter;
}

export const durationFormatter = createDurationFormatter();

const withTrailingZero = (val: string) => `${val.length === 1 ? "0" : ""}${val}`;
