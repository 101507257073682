import { Box, Button, chakra, Flex } from "@chakra-ui/react";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import React from "react";
import BaseDatePicker, { BaseDatePickerProps } from "./BaseDatePicker";

const CalendarBox = chakra(Box, {
  baseStyle: {
    display: "flex",
    width: "fit-content",
    gap: 3,
  },
});

const ActionsBox = chakra(Box, {
  baseStyle: {
    borderColor: "gray.200",
    borderTopWidth: "1px",
    paddingTop: 2,
    marginTop: 2,
  },
});

export interface SingleDatePickerProps
  extends Omit<BaseDatePickerProps, "monthsShown" | "onChange"> {
  onChange: (
    date: LocalDate | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => void;
}

const SingleDatePicker = (props: SingleDatePickerProps) => {
  const handleSetClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return props.onChange(null, e);
  };

  const handleSetToday = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return props.onChange(LocalDate.now(), e);
  };

  // DatePickerJsJodaAdapter converts js Date to js-joda. Since a Date object is always DateTime, it is converted to LocalDateTime.
  const handleChange = (
    date: LocalDateTime | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => {
    let localDate: LocalDate | null = null;
    if (date instanceof LocalDateTime) {
      localDate = LocalDate.of(date.year(), date.month(), date.dayOfMonth());
    }
    return props.onChange(localDate, event);
  };

  return (
    <BaseDatePicker
      {...props}
      calendarContainer={({ children }) => {
        return (
          <>
            <CalendarBox>{children}</CalendarBox>
            <ActionsBox>
              <Flex alignItems="center" gap={8} justifyContent="space-between">
                <Button color="negative" size="sm" variant="ghost" onClick={handleSetClear}>
                  Clear
                </Button>
                <Button size="sm" variant="ghost" onClick={handleSetToday}>
                  Today
                </Button>
              </Flex>
            </ActionsBox>
          </>
        );
      }}
      dateFormat="MM/dd/yyyy"
      monthsShown={1}
      pickerYears={[1900, LocalDate.now().year() + 1]}
      onChange={handleChange}
    />
  );
};

export default SingleDatePicker;
