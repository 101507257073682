import { Input, InputProps } from "@chakra-ui/react";
import React from "react";

export default function DebouncedInput<T extends string | number>(
  props: {
    value: T;
    onChange: (value: T) => void;
    debounce?: number;
  } & Omit<InputProps, "onChange">
) {
  const [value, setValue] = React.useState<T>(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      props.onChange(value);
    }, props.debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <Input {...props} value={value} onChange={(e) => setValue(e.target.value as T)} />;
}
