import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { useRouter } from "@uirouter/react";
import { Messages, ResponseOf } from "../../../../core/api";
import { Entity, EntityWithStatus } from "../../../../shared/components/EntityCard";
import Page from "../../../../shared/components/Page";
import {
  CommCenterLabelId,
  CommCenterTeamId,
  CommCenterTicketId,
} from "../../../../shared/schema/schema";
import { Loadable } from "../../../../shared/utils/loadable";
import { usePhoneNumberDialerPopup } from "../../../call-center/hooks/usePhoneNumberDialerPopup";
import { CommCenterTicket } from "../../communication.types";
import TicketsBox from "../../components/TicketsBox";
import TicketSummaryModal from "../../components/TicketSummaryModal";
import useTicketSummaryDisclosure from "../../hooks/useTicketSummaryDisclosure";
import { NewTicketRequestBody } from "../../utils/communication-utils";

type Props = {
  ticket: CommCenterTicket | null;
  teams: Messages["CommCenterTeamWithMembers"][];
  primaryEntity: Loadable<EntityWithStatus<Entity> | null>;
  secondaryEntity?: Loadable<EntityWithStatus<Entity> | undefined>;
  relatedTickets: Loadable<CommCenterTicket[]>;
  labels: Messages["CommCenterLabel"][];
  initialLabelId: CommCenterLabelId | null;
  attachments: File[];
  onClickTicket: (ticketId: CommCenterTicketId) => void;
  onClickMarkAsUnread: (ticketId: CommCenterTicketId) => void;
  onSubmitNewMessage: (ticketId: CommCenterTicketId, message: string) => void;
  onCreateNewTicket: (newTicket: NewTicketRequestBody) => void;
  onSuccessCreateEmailTicket: (response: ResponseOf<"post", "./comm_center/email/threads">) => void;
  onSelectFile: (newFile: File) => void;
  onClickRemoveAttachment: (attachment: File) => void;
};

const CommunicationCenterTicketPage = (props: Props) => {
  const { stateService } = useRouter();
  const newTicketDisclosure = useDisclosure();
  const ticketSummaryDisclosure = useTicketSummaryDisclosure();
  const dialerPopup = usePhoneNumberDialerPopup();

  // TODO @Newbie012 - this is wrong. We should ditch loadable.
  const onboardingStageName = (() => {
    switch (props.primaryEntity.type) {
      case "Loading":
        return null;
      case "Resolved":
        return getOnboardingNameIfExist(props.ticket, props.primaryEntity.value);
      case "Rejected":
        return null;
    }
  })();

  const settings = {
    // TODO: Make the settings nullable in tickets box. on null it will present default values or blanks.
    assignedToId: props.ticket?.assignedTo?.id ?? null,
    status: props.ticket?.status ?? "NEW",
    teamId: props.ticket?.relatedTeam.id ?? CommCenterTeamId.parse(0),
  };

  const areActionsDisabled = props.ticket?.status === "RESOLVED" || newTicketDisclosure.isOpen;

  const handleClickDial = (phoneNumber: string) => {
    dialerPopup.open({ predefinedPhoneNumber: phoneNumber });
  };

  return (
    <Page>
      <Box sx={{ "--max-chat-height": "calc(100vh - 295px)" }}>
        <TicketsBox
          activeTicket={props.ticket ?? null}
          areActionsDisabled={areActionsDisabled}
          attachments={props.attachments}
          goBackButton={
            <Button
              leftIcon={<ChevronLeftIcon />}
              variant="outline"
              onClick={() => stateService.go("app.commcenter")}
            >
              Go back
            </Button>
          }
          initialLabelId={props.initialLabelId}
          isNewTicketOpen={newTicketDisclosure.isOpen}
          label={props.ticket?.label?.name ?? null}
          labels={props.labels}
          onboardingStageName={onboardingStageName}
          primaryEntity={props.primaryEntity}
          secondaryEntity={props.secondaryEntity}
          settings={settings}
          teams={props.teams}
          tickets={props.relatedTickets}
          onClickDial={handleClickDial}
          onClickMarkAsUnread={props.onClickMarkAsUnread}
          onClickNewTicket={newTicketDisclosure.onOpen}
          onClickRemoveAttachment={props.onClickRemoveAttachment}
          onClickShowSummary={ticketSummaryDisclosure.disclosureProps.onOpen}
          onClickTicket={props.onClickTicket}
          onCreateNewTicket={props.onCreateNewTicket}
          onRequestCloseNewTicket={newTicketDisclosure.onClose}
          onSelectFile={props.onSelectFile}
          onSubmitNewMessage={props.onSubmitNewMessage}
          onSuccessCreateEmailTicket={props.onSuccessCreateEmailTicket}
        />
      </Box>
      <TicketSummaryModal {...ticketSummaryDisclosure} />
    </Page>
  );
};

function getOnboardingNameIfExist(
  ticket: Messages["CommCenterTicket"] | null,
  entityToDisplay: EntityWithStatus<Entity> | null
): string | null {
  if (ticket !== null) {
    return ticket.relatedCaregiver?.onboardingStageDetails?.name ?? null;
  }

  if (entityToDisplay?.type === "Caregiver") {
    return entityToDisplay.status; // TODO: Add onboarding stage to the entity object.
  }

  return null;
}

export default CommunicationCenterTicketPage;
