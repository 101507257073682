import { PatientId } from "../../shared/schema/schema";
import { createQueryKeys } from "../../shared/utils/create-query-keys";

export const patientKeys = createQueryKeys("patient", {
  search: (term: string) => ({ term }),
  get: (id: PatientId) => id,
  list: null,
  patientAvailability: (id: PatientId) => id,
  patientStaffingPreferences: (id: PatientId) => id,
  availabilityTimeFrames: null,
});
