import ng from "angular";
import { DayOfWeek } from "@js-joda/core";
import { CaregiverId, PatientId, VisitBroadcastId } from "../../../../scripts/messages/ids";
import { CaregiverRequest } from "../../visitBroadcast.types";
import {
    FlexibleVisitAssignmentRequest,
    FlexibleVisitAssignmentRequestsContainerBindings
} from "./flexible-visit-assignment-requests-container.types";
import {
    MappedCaregiverEngagement
} from "../flexible-visit-requests-container/flexible-visit-requests-container.types";
import { VisitBroadcastService } from "../../visitBroadcast.service";
import { isDefined } from "../../../../scripts/utils/generalUtils";
import { getErrorFromAssignResponse } from "../../visitBroadcast.utils";

//! @ngInject
class flexibleVisitAssignmentRequestsContainerCtrl implements ng.IComponentController, FlexibleVisitAssignmentRequestsContainerBindings {
    visitAssignmentRequests!: FlexibleVisitAssignmentRequest[];
    visitBroadcastId!: VisitBroadcastId;
    patientId!: PatientId;

    startFlyToCaregiver!: (caregiverId: CaregiverId) => void;
    stopFlyToCaregiver!: () => void;
    updateEngagements!: () => void;
    closeModal!: () => void;

    mappedCaregiverEngagements!: MappedCaregiverEngagement[];
    visitHoursRestrictionError: {
        isOpen: boolean;
        conflicts: {
          exceededHours: number;
          caregiverId: CaregiverId;
        }[];
      };

    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
        private toaster: toaster.IToasterService,
        private mfModal: any,
        private visitBroadcastService: VisitBroadcastService
    ) {
        this.visitHoursRestrictionError = {
            isOpen: false,
            conflicts: [],
        };
    }

    $onChanges(changesObj) {
        if ("visitAssignmentRequests" in changesObj) {
            const isFirstChange = changesObj.visitAssignmentRequests.isFirstChange();
            const prevValue = changesObj.visitAssignmentRequests.previousValue;
            const currValue = changesObj.visitAssignmentRequests.currentValue;

            if (isFirstChange || prevValue.length !== currValue.length) {
                this.mapRequestsOnChange();
            }
        }
    }

    mapRequestsOnChange() {
        this.mappedCaregiverEngagements = this.visitAssignmentRequests.map((engagement) => {
            const mappedRequests: CaregiverRequest[] = DayOfWeek.values().map((day) => {
                const requestInDay = engagement.pendingConfirmationShifts.find((request) => request.day.toString() === day.name());

                if (requestInDay === undefined) {
                    return {
                        type: "DISABLED",
                        day,
                        isDisabled: true,
                        isSelected: false,
                        caregiverId: engagement.caregiver.id
                    };
                }

                return {
                    type: "REAL_REQUEST",
                    day,
                    startTime: requestInDay.startTime,
                    endTime: requestInDay.endTime,
                    isDisabled: requestInDay === undefined,
                    isSelected: false,
                    caregiverId: engagement.caregiver.id
                };
            });

            return {
                caregiver: engagement.caregiver,
                requests: mappedRequests,
                pendingConfirmationUntil: engagement.pendingConfirmationUntil,
                pendingConfirmationConfirmedAt: engagement.pendingConfirmationConfirmedAt
            };
        });
    }

    getCaregiverShifts(caregiverId) {
        for (const { caregiver, requests } of this.mappedCaregiverEngagements) {
            if (caregiverId === caregiver.id) {
                return requests
                    .map((request) => {
                        if (request.type === "REAL_REQUEST") {
                            return {
                                day: request.day,
                                startTime: request.startTime,
                                endTime: request.endTime
                            };
                        }
                    })
                    .filter(isDefined);
            }
        }
        return [];
    }

    openVisitHoursRestrictionsErrorModal(
        conflicts: { exceededHours: number; caregiverId: CaregiverId }[]
      ) {
        this.visitHoursRestrictionError = {
          isOpen: true,
          conflicts,
        };
      }
    
      onCloseHoursRestrictionErrorModal() {
        this.visitHoursRestrictionError = {
          isOpen: false,
          conflicts: [],
        };
    
        // digest
        this.$scope.$apply();
      }

    assignToCaregiver() {
        this.visitBroadcastService
            .confirmAssignmentRequestForFlexibleVisit(this.visitBroadcastId)
            .then(
                (res) => {
                    if (res.data.assignedWithOvertime) {
                        this.toaster.pop({
                            type: "warning",
                            title: "Warning",
                            body: `Successfully assigned caregiver with increased caregiver overtime`
                        });
                    } else {
                        this.toaster.pop("success", "Successfully assigned caregiver");
                    }
                    this.$rootScope.$emit("refresh_visits");
                    this.$rootScope.$emit("visit_changed", { visitId: this.visitBroadcastId });
                    this.closeModal();
                },
                (err) => {
                    if(err.data && err.data.type === "OfficeHoursRestrictionsConflict") {
                        this.openVisitHoursRestrictionsErrorModal(err.data.officeHoursRestrictionsConflicts);
                    } else {
                        const errorMessage = getErrorFromAssignResponse(err);
                        this.toaster.pop("error", "Oops...", errorMessage);
                    }
                }
            );
    }

    openShouldAssignModal() {
        const modal = this.mfModal.create({
            subject: "Are You Sure?",
            variant: "warning",
            message: "Are you sure you want to confirm manually?",
            cancelLabel: "I changed my mind",
            confirmLabel: "Confirm assignment",
            showInput: false,
            layoutOrder: ["message"],
            hideCancelButton: false,
            preventBackdropClose: true,
            onConfirm: async () => {
                await this.assignToCaregiver();
                modal.close();
            }
        });
    }

    rejectAssignmentToCaregiver() {
        this.visitBroadcastService
            .rejectAssignmentRequestForFlexibleVisit(this.visitBroadcastId)
            .then(
                () => {
                    this.toaster.pop("success", "Successfully rejected caregiver's request.");
                    this.$rootScope.$emit("refresh_visits");
                    this.$rootScope.$emit("visit_changed", { visitId: this.visitBroadcastId });
                },
                (err) => {
                    this.toaster.pop("error", "Oops...", "Failed to reject the caregiver's request.", err);
                }
            );
    }

    openShouldRejectModal() {
        const modal = this.mfModal.create({
            subject: "Are You Sure?",
            variant: "warning",
            message: "Are you sure you want to reject assignment?",
            cancelLabel: "I changed my mind",
            confirmLabel: "Confirm",
            showInput: false,
            layoutOrder: ["message"],
            hideCancelButton: false,
            preventBackdropClose: true,
            onConfirm: async () => {
                await this.rejectAssignmentToCaregiver();
                modal.close();
            }
        });
    }
}

export const flexibleVisitAssignmentRequestsContainer = {
    templateUrl:
        "admin/modules/visit-broadcast/components/flexible-visit-assignment-requests-container/flexible-visit-assignment-requests-container.component.html",
    controller: flexibleVisitAssignmentRequestsContainerCtrl,
    controllerAs: "ctrl",
    bindings: {
        visitAssignmentRequests: "<",
        visitBroadcastId: "<",
        patientId: "<",
        updateEngagements: "&",
        startFlyToCaregiver: "<",
        stopFlyToCaregiver: "<",
        closeModal: "&"
    }
};