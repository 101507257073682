import { Flex, FormControl, FormErrorMessage, FormLabel, StyleProps } from "@chakra-ui/react";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import React from "react";
import SingleDatePicker from "../../../shared/components/DatePicker/SingleDatePicker";
import SingleDateTimePicker from "../../../shared/components/DatePicker/SingleDateTimePicker";

interface IntakeProfileSingleDatepickerProps {
  isEditable: boolean;
  isRequired: boolean;
  label: string;
  value: LocalDate | LocalDateTime | null;
  withTime?: boolean;
  validateSchema?: Zod.Schema;
  styleProps?: StyleProps;
  placement?:
    | "top"
    | "top-end"
    | "top-start"
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-end"
    | "left-start";
  pickerYearsRange?: [number, number];
  headerInfoTooltip?: React.ReactNode;
  inputRightIndication?: React.ReactNode;
  onChange: (value: LocalDate | LocalDateTime | null) => void;
}

const IntakePatientProfileDatepicker = (props: IntakeProfileSingleDatepickerProps) => {
  const [error, setError] = React.useState<string | null>(null);

  const validate = (value: LocalDate | LocalDateTime | null) => {
    if (props.validateSchema === undefined || value === null) {
      setError(null);
    } else {
      const result = props.validateSchema.safeParse(value);
      setError(!result.success ? result.error.flatten().formErrors.at(0) ?? null : null);
    }
  };

  return (
    <FormControl
      isDisabled={!props.isEditable}
      isRequired={props.isEditable ? props.isRequired : false}
    >
      <FormLabel _disabled={{ color: "black" }} alignItems="center" display="flex" gap={3}>
        {props.label}
        {props.headerInfoTooltip}
      </FormLabel>
      <Flex alignItems="center">
        {!props.withTime ? (
          <SingleDatePicker
            autoComplete="new-password"
            disabled={!props.isEditable}
            pickerYears={props.pickerYearsRange ?? [LocalDate.now().year(), 2100]}
            popperPlacement={props.placement}
            selected={props.value}
            onBlur={() => validate(props.value)}
            onChange={props.onChange}
          />
        ) : (
          <SingleDateTimePicker
            autoComplete="new-password"
            disabled={!props.isEditable}
            pickerYears={props.pickerYearsRange ?? [LocalDate.now().year(), 2100]}
            popperPlacement={props.placement}
            selected={props.value}
            onBlur={() => validate(props.value)}
            onChange={props.onChange}
          />
        )}
        {!props.isEditable ? props.inputRightIndication : null}
      </Flex>
      <FormErrorMessage>{error ?? ""}</FormErrorMessage>
    </FormControl>
  );
};

export default IntakePatientProfileDatepicker;
