import { createIcon } from "@chakra-ui/react";

const ConfirmIcon = createIcon({
  displayName: "ConfirmIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M5 11L11 17L21 7"
      fill="none"
      stroke="currentColor"
      strokeDasharray="24"
      strokeDashoffset="24"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <animate attributeName="strokeDashoffset" dur="0.4s" fill="freeze" values="24;0" />
    </path>
  ),
});

export default ConfirmIcon;
