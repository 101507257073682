import { Button } from "@chakra-ui/react";

export default function RingingCallsErrorBoundary(props: {
  error: Error | unknown;
  resetErrorBoundary: () => void;
}) {
  console.error(props.error);
  return (
    <Button size="sm" onClick={props.resetErrorBoundary}>
      Failed to load ringing calls. Click to retry.
    </Button>
  );
}
