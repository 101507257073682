import { atom, useAtom } from "jotai";
import { NewTicketPopupProps } from "../../modules/communication/components/NewTicketPopup";

const defaultProps: NewTicketPopupProps = {
  initialLabelId: null,
  defaultValues: {
    caregiverId: null,
    patientId: null,
    topic: "Caregiver",
  },
  defaultTicketType: "Chat",
};

type NewTicketProps = NewTicketPopupProps | null;

const newTicketAtom = atom<NewTicketProps>(null);

export function useNewTicket() {
  const [state, setState] = useAtom(newTicketAtom);

  const onOpen = (params?: NewTicketPopupProps) => setState({ ...(params ?? defaultProps) });
  const onClose = () => setState(null);

  return { state, onOpen, onClose };
}
