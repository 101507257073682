import { PhoneIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { FaPhoneSlash, FaVoicemail } from "react-icons/fa";
import { Messages } from "../../../../core/api";
import RoundPermPhoneMsgIcon from "../../../../shared/icons/RoundPermPhoneMsgIcon";
import { CallTicket, formatDirection } from "../../utils/communication-utils";
import LiveBadge from "../LiveBadge";
import RingingBadge from "../RingingBadge";
import { getFullName } from "../../../../shared/utils/get-full-name";
import { CommunicationLogVerticalDivider } from "./CommunicationLog";

interface Props {
  ticket: CallTicket;
}

function shouldShowCallSummary(callStatus: Messages["TelephonyCallInfo"]["status"]) {
  return ["Completed", "VoiceMail"].includes(callStatus);
}

const getCallParticipantName = (participant: Messages["TelephonyCallParticipantInfo"]) => {
  switch (participant.entity.type) {
    case "AgencyMember":
    case "Patient":
    case "PatientContact":
    case "Caregiver":
      return getFullName(participant.entity);
    case "PhonebookContact":
      return participant.entity.name;
    case "NotIdentified":
      return "Not Identified";
  }
};

const getParticipantsString = (participants: Messages["TelephonyCallParticipantInfo"][]) => {
  if (participants.length === 0) {
    return undefined;
  }
  if (participants.length === 1) {
    return getCallParticipantName(participants[0]);
  }
  const participantsNames = participants.map((p) => getCallParticipantName(p));
  const last = participantsNames.pop();
  return `${participantsNames.join(", ")} and ${last}`;
};

function getCallStatus(callInfo: Messages["CommCenterTicketTelephonyCallInfo"]) {
  switch (callInfo.status) {
    case "Canceled":
      return (
        <Flex alignItems="center" color="red" gap={4}>
          <FaPhoneSlash />
          <Text>Canceled</Text>
        </Flex>
      );
    case "Completed":
      return (
        <Flex alignItems="center" gap={4}>
          <PhoneIcon color="green.400" />
          <Text>Completed Call</Text>
        </Flex>
      );
    case "Initiated":
    case "Ringing":
    case "Connecting":
      return <RingingBadge />;
    case "Failed":
      return (
        <Tooltip label={callInfo.failDescription}>
          <Flex alignItems="center" color="red" gap={4}>
            <WarningTwoIcon />
            <Text>Failed Call</Text>
          </Flex>
        </Tooltip>
      );
    case "InProgress":
      return <LiveBadge />;
    case "Missed":
      return (
        <Flex alignItems="center" color="red" gap={4}>
          <FaPhoneSlash />
          <Text>Missed Call</Text>
        </Flex>
      );
    case "MovedToSMS":
      return (
        <Flex alignItems="center" color="orange.500" gap={4}>
          <RoundPermPhoneMsgIcon />
          <Text>Moved to SMS</Text>
        </Flex>
      );
    case "NoAnswer":
      return (
        <Flex alignItems="center" color="red" gap={4}>
          <FaPhoneSlash />
          <Text>No Answer</Text>
        </Flex>
      );
    case "VoiceMail":
      return (
        <Flex alignItems="center" color="yellow.400" gap={4}>
          <FaVoicemail size="16px" />
          <Text>Voice Mail</Text>
        </Flex>
      );
  }
}

const CommunicationLogCallDetails = (props: Props) => {
  const { callInfo } = props.ticket;
  const { direction, participants, summary } = callInfo;
  const caller = participants.find(
    (p: Messages["TelephonyCallParticipantInfo"]) => p.role === "Caller"
  );
  const recipient = participants.find(
    (p: Messages["TelephonyCallParticipantInfo"]) => p.role === "Callee"
  );
  const otherParticipants = participants.filter(
    (p: Messages["TelephonyCallParticipantInfo"]) => p.role !== "Caller" && p.role !== "Callee"
  );

  const callerName = caller !== undefined ? getCallParticipantName(caller) : null;
  const recipientName =
    recipient !== undefined ? getCallParticipantName(recipient) : ", missed call";
  const participantsNames = getParticipantsString(otherParticipants);

  const summaryText = summary !== null ? summary.text : "No summary available";

  const participantsText = participantsNames !== undefined ? `, with ${participantsNames}` : "";

  return (
    <>
      <Flex alignItems="center" gap={2}>
        {getCallStatus(callInfo)}
        <CommunicationLogVerticalDivider />
        <Text>{formatDirection(direction)}</Text>
        {callerName !== null && (
          <>
            <CommunicationLogVerticalDivider />
            <Text>{`${callerName} called ${recipientName}${participantsText}`}</Text>
          </>
        )}
      </Flex>
      {shouldShowCallSummary(callInfo.status) && <Text>{summaryText}</Text>}
    </>
  );
};

export default CommunicationLogCallDetails;
