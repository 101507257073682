import { tabId } from "../../../shared/consts/tab-id";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";

const STORAGE_KEY = "current-phone-client-tab";
const ACTIVE_CALL_STORAGE_KEY = "active-call-uuid";

export default function useActivePhoneProviderClientTab() {
  const [activeTabId, setActiveTabId] = useLocalStorage<string | null>(STORAGE_KEY, null);
  const [activeCallId, setActiveCallId] = useLocalStorage<string | null>(
    ACTIVE_CALL_STORAGE_KEY,
    null
  );
  const isTabActive = activeTabId === tabId;

  return {
    id: activeTabId,
    isTabActive: isTabActive,
    activeCallId: activeCallId,
    setActiveTabId: setActiveTabId,
    setActiveCallId: setActiveCallId,
  };
}
