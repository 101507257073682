import { Button } from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import LoadingButton from "../../../../../shared/components/LoadingButton";
import Select, { CustomSelectProps } from "../../../../../shared/components/Select";
import SelectNoResults from "../../../../../shared/components/SelectNoResults";
import useApi from "../../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../../shared/query-keys";
import { MedicaidStatusId } from "../../../../../shared/schema/schema";

export function MedicaidStatusSelect(props: CustomSelectProps<MedicaidStatusId>) {
  const { api } = useApi();
  const query = useQuery({
    queryKey: queryKeys.patientIntake.medicaidStatus(),
    placeholderData: keepPreviousData,
    queryFn: () => api.get("./patient_intake/medicaid_status", {}),
  });

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          label={props.label ?? (props.multiple ? "Medicaid statuses" : "Medicaid status")}
          options={query.data.medicaidStatuses.map((x) => ({
            label: x.name,
            value: x.id,
          }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          {...props}
        />
      );
  }
}
