import { Messages } from "../../../../core/api";
import {
  EntityCommunicationsTicketType,
  EntityCommunicationsTicketSource,
  EntityCommunicationsTicketStatus,
} from "./entity-communications.types";

export function matchEntityComminucationsTicketTypeToCommCenterTicketSource(
  type: EntityCommunicationsTicketType
): EntityCommunicationsTicketSource[] {
  switch (type) {
    case "Call":
      return ["PHONE_CALL"];
    case "Chat":
      return ["MOBILE_CHAT", "SYSTEM_TRIGGER", "MANUAL"];
    case "SMS":
      return ["SMS_CONVERSATION"];
    case "Email":
      return ["EMAIL"];
    case "Robocall":
      return ["ROBOCALL"];
  }
}

type CommunicationLogQueryData = {
  tickets: Messages["CommCenterTicket"][];
  roboCalls: Messages["RobocallLogData"][];
};

export function filterCommunicationsLog(
  data: CommunicationLogQueryData,
  filters?: { type: EntityCommunicationsTicketSource[]; status: EntityCommunicationsTicketStatus[] }
): CommunicationLogQueryData {
  if (filters === undefined) {
    return data;
  }

  const filteredBySource = filterCommunicationLogByTicketSource(data, filters.type);
  const filteredByStatus = filterCommunicationLogByTicketStatus(filteredBySource, filters.status);
  return filteredByStatus;
}

function filterCommunicationLogByTicketStatus(
  data: CommunicationLogQueryData,
  statuses: EntityCommunicationsTicketStatus[]
): CommunicationLogQueryData {
  if (statuses.length === 0) {
    return data;
  }

  const tickets = data.tickets.filter((x) => statuses.includes(x.status));
  const roboCalls = statuses.includes("RESOLVED") ? data.roboCalls : [];
  return { tickets, roboCalls };
}

function filterCommunicationLogByTicketSource(
  data: CommunicationLogQueryData,
  sources: EntityCommunicationsTicketSource[]
): CommunicationLogQueryData {
  if (sources.length === 0) {
    return data;
  }

  const tickets = data.tickets.filter((x) => sources.includes(x.source));
  const roboCalls = sources.includes("ROBOCALL") ? data.roboCalls : [];
  return { tickets, roboCalls };
}
