import { useToast } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import usePreSignedUrl from "../../../shared/hooks/usePreSignedUrl";
import { queryKeys } from "../../../shared/query-keys";
import { CommCenterMessageId, CommCenterTicketId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { getFullName } from "../../../shared/utils/get-full-name";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import { CreateMessageRequest } from "../pages/CommunicationCenterTicket/CommunicationCenterTicketRoute";
import {
  AttachmentMimeType,
  buildAttachmentsPayload,
  getPayloadTypeByMimeType,
} from "../utils/communication-utils";
import useAttachments from "./useAttachments";

interface Params {
  onSubmitMessage?: () => void;
}

export default function useTicketMessages(params: Params) {
  const toast = useToast();
  const { api } = useApi();
  const queryClient = useQueryClient();
  const { agencyMember } = useAuthData();
  const attachments = useAttachments();
  const preSignedFiles = usePreSignedUrl(attachments.state);

  const submitMessage = useMutation({
    mutationFn: async (createParams: CreateMessageRequest) => {
      return api.post("./comm_center/tickets/:commCenterTicketId/message", {
        path: {
          commCenterTicketId: createParams.ticketId,
        },
        body: {
          message: [
            {
              type: "TEXT",
              message: createParams.message,
            },
            ...createParams.attachments,
          ],
        },
      });
    },
    onMutate: async (createParams) => {
      const tickets = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          (draft.tickets ?? [])
            .find((ticket) => ticket.id === createParams.ticketId)
            ?.messages.push({
              id: CommCenterMessageId.parse(Instant.now().toEpochMilli() * -1),
              createdBy: {
                type: "Agency Member",
                id: agencyMember.id,
                name: getFullName(agencyMember),
                photoUrl: agencyMember.photoUrl,
              },
              payload: [
                {
                  type: "TEXT",
                  message: createParams.message,
                },
                ...attachments.state.map((attachment) => ({
                  type: getPayloadTypeByMimeType(attachment.type as AttachmentMimeType),
                  url: URL.createObjectURL(attachment),
                })),
              ],
              labelId: null,
              readAt: null,
              messageActionId: null,
              ticketId: createParams.ticketId,
              createdAt: Instant.now(),
            });
        },
      });
      await attachments.upload.mutateAsync(preSignedFiles.flatMap((a) => (a.data ? a.data : [])));

      return tickets;
    },
    onError: (error, _newChatMessage, context) => {
      queryClient.setQueryData(queryKeys.commCenter.search.K, context?.previousValue);

      toast({
        title: "Could not send message.",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: () => {
      toast({
        title: "Message sent successfully",
        status: "success",
        position: "top-right",
      });

      params.onSubmitMessage?.();
    },
  });

  const handleSubmitNewMessage = async (ticketId: CommCenterTicketId, message: string) => {
    attachments.clear();
    const payload = buildAttachmentsPayload(preSignedFiles);
    await attachments.upload.mutateAsync(preSignedFiles.flatMap((a) => (a.data ? a.data : [])));
    submitMessage.mutate({ ticketId, message, attachments: payload });
  };

  return {
    attachments,
    submitMessage: handleSubmitNewMessage,
  };
}
