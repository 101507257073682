import { CaregiverId, OfficeCaregiverHoursRestrictionOverrideId, OfficeId } from "@medflyt/messages/ids";
import angular from "angular";
import ng from "angular";
import { Moment } from "moment";
import { MultiselectCallbackParameter } from "public/admin/modules/shared/components/multiselect/multiselect.component";
import { OfficesService } from "public/admin/scripts/services/officesService";
import { CaregiverHoursOverrideService } from "../caregiver-hours-override.service";
import { CreateOfficeCaregiverHoursOverrideRequest, OfficeCaregiverHoursOverride } from "../caregiver-hours-override.types";

interface caregiverHoursOverrideBindings {
  caregiverId: CaregiverId;
}

interface caregiverHoursOverrideFilters {
  dropdowns: {
    offices: {
      options: Array<{
        id: OfficeId;
        label: string;
      }>;
      selected: Array<{
        id: OfficeId;
        label: string;
      }>;
    };
  };
  from: Moment | undefined;
  to: Moment | undefined;
}

//! @ngInject
class caregiverHoursOverrideCtrl implements ng.IComponentController, caregiverHoursOverrideBindings {
  caregiverId!: CaregiverId;
  overridesTableParams!: NgTableParams<OfficeCaregiverHoursOverride>;

  constructor(
    private $uibModal: angular.ui.bootstrap.IModalService,
    private caregiverHoursOverrideService: CaregiverHoursOverrideService,
    private officesService: OfficesService,
    private toaster: toaster.IToasterService,
    private NgTableParams: NgTable.ITableParamsConstructor<OfficeCaregiverHoursOverride>
  ) { }


  public filters: caregiverHoursOverrideFilters = {
    dropdowns: {
      offices: {
        options: [],
        selected: [],
      },
    },
    from: undefined,
    to: undefined,
  };

  async $onInit() {
    const offices = await this.officesService.getOffices();

    const allOffices = offices.map((office) => ({
      id: office.id,
      label: office.name,
    }));

    this.filters.dropdowns.offices.options = allOffices;

    this.initTable();
  }

  initTable = () => {
    this.caregiverHoursOverrideService
      .getOverrides(this.caregiverId, this.filters.dropdowns.offices.selected.map((selected) => selected.id))
      .then((res) => {
        const options = {
          count: 10,
          sorting: { category: "asc" },
        };

        this.overridesTableParams = new this.NgTableParams(options, {
          counts: [10, 25, 50, 100],
          dataset: res.data.overrides,
        });
      })
      .catch((err) => {
        console.log(err);
        this.toaster.pop("error", "Failed to load overrides");
      });
  };

  $onChanges(onChangesObj: angular.IOnChangesObject): void {
    if ("caregiverHoursOverrideFilters" in onChangesObj) {
      this.initTable();
    }
  }

  handleCreatedAtFilterChange = (startDate, endDate) => {
    this.filters = {
      ...this.filters,
      from: startDate,
      to: endDate,
    };

    this.initTable();
  };

  handleOfficesDropdownFilterChange = (selected: MultiselectCallbackParameter<OfficeId>) => {
    if (selected.length === 0) {
      this.filters.dropdowns.offices.selected = [];
    } else {
      const selectedIds = selected.map((selected) => selected.id);
      const selectedOptions = this.filters.dropdowns.offices.options.filter((option) =>
        selectedIds.includes(option.id)
      );
      this.filters.dropdowns.offices.selected = selectedOptions;
    }

    this.initTable();
  };

  removeOverride = (overrideId: OfficeCaregiverHoursRestrictionOverrideId) => {
    this.caregiverHoursOverrideService
      .removeOverride(this.caregiverId, overrideId)
      .then((res) => {
        if (res.data.type === "Conflict") {
          this.toaster.warning("This caregiver has visits that surpass the restriction and override hours");
        } else {
          this.toaster.success("Successfuly removed");
        }
        this.initTable();
      })
      .catch((err) => {
        console.log(err);
        this.toaster.pop("error", err?.data?.details ?? "Failed to remove override");
      });
  };

  onClickCreateOverride = () => {
    this.$uibModal.open({
      templateUrl: "admin/views/caregiver-hours-override-modal.html",
      size: "lg",
      controller: "caregiverHoursOverrideModalCtrl",
      resolve: {
        caregiverId: () => this.caregiverId,
        allOffices: () => this.filters.dropdowns.offices.options,
        override: () => { }
      },
    }).result.then((override: CreateOfficeCaregiverHoursOverrideRequest) => {
      this.caregiverHoursOverrideService.createOverride(this.caregiverId, override).then((res) => {
        if (res.data.type === "Conflict") {
          this.toaster.warning("This caregiver has visits that surpass the restriction and override hours");
        } else {
          this.toaster.success("Successfuly created override");
        }
        this.initTable();
      }).catch((err) => {
        console.log(err);
        this.toaster.pop("error", err?.data?.details ?? "Failed to create override");
      });
    });
  };

  onClickEditOverride = (override: OfficeCaregiverHoursOverride) => {
    this.$uibModal.open({
      templateUrl: "admin/views/caregiver-hours-override-modal.html",
      size: "lg",
      controller: "caregiverHoursOverrideModalCtrl",
      resolve: {
        caregiverId: () => this.caregiverId,
        allOffices: () => this.filters.dropdowns.offices.options,
        override: () => override,
      },
    }).result.then((override) => {
      this.caregiverHoursOverrideService.editOverride(this.caregiverId, override.id, override).then((res) => {
        if (res.data.type === "Conflict") {
          this.toaster.warning("This caregiver has visits that surpass the restriction and override hours");
        } else {
          this.toaster.success("Successfuly edited override");
        }
        this.initTable();
      }).catch((err) => {
        console.log(err);
        this.toaster.pop("error", err?.data?.details ?? "Failed to edit override");
      });
    });
  };
}

export const caregiverHoursOverride = {
  templateUrl:
    "admin/modules/cargiver-hours-override/components/caregiver-hours-override.component.html",
  controller: caregiverHoursOverrideCtrl,
  controllerAs: "ctrl",
  bindings: {
    caregiverId: "<",
  },
};

