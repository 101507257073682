import { DownloadIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Switch,
  Tag,
  Text,
} from "@chakra-ui/react";
import { LocalDate } from "@js-joda/core";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { Messages, QueryParamsOf } from "../../../../core/api";
import DataTable from "../../../../shared/components/DataTable/DataTable";
import useQueryDataTable from "../../../../shared/components/DataTable/useQueryDataTable";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import DebouncedInput from "../../../../shared/components/DebouncedInput";
import EntityLink from "../../../../shared/components/EntityLink";
import OfficesSelect from "../../../../shared/components/OfficeSelect";
import Page from "../../../../shared/components/Page";
import Select from "../../../../shared/components/Select";
import useApi from "../../../../shared/hooks/useApi";
import { createFilters } from "../../../../shared/hooks/useFilters";
import { useQueryParams } from "../../../../shared/hooks/useQueryParams";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { CaregiverExclusionRowSwitchFlagButton } from "./components/CaregiverExclusionRowSwitchFlagButton";
import { CaregiverExclusionRowTerminateButton } from "./components/CaregiverExclusionRowTerminateButton";

export default function CaregiverExclusionsPage() {
  const { queries } = useApi();

  const { createRangeDatePickerFilter, createMultiSelectFilter } =
    createFilters<QueryParamsOf<"get", "./exclusion_list">>();

  const filters = useQueryParams<QueryParamsOf<"get", "./exclusion_list">>({
    defaultOptions: {
      from: atStartOfMonth(LocalDate.now()),
      to: atEndOfMonth(LocalDate.now()),
      includeNonMatching: false,
    },
  });

  const fromToFilter = createRangeDatePickerFilter({
    label: "Pick range",
    startDate: { name: "from", value: filters.getValueOrNull("from") },
    endDate: { name: "to", value: filters.getValueOrNull("to") },
    onChange: filters.setValue,
  });

  const statusFilter = createMultiSelectFilter({
    label: "Status",
    name: "statuses",
    options: [
      { label: "Flagged", value: "Flagged" },
      { label: "Un-Flagged", value: "Un-Flagged" },
      { label: "No Match", value: "NoMatch" },
    ],
    onChange: filters.setValue,
    value: filters.getValueOrNull("statuses"),
  });

  const query = useQuery({
    ...queries.caregiver.exclusions.list(filters.params.toJSON()),
    placeholderData: keepPreviousData,
    select: (data) => data.caregivers,
  });

  const { dataTableProps } = useQueryDataTable({
    query: query,
    columns: columns,
    columnVisiblity: {},
  });

  const filterNode = (
    <>
      <InputGroup width="md">
        <DebouncedInput
          debounce={200}
          placeholder="Search by name, address, etc"
          value={filters.getValue("searchTerm") ?? ""}
          onChange={(x) => filters.setValue("searchTerm", x)}
        />
        <InputRightElement>
          <SearchIcon _groupFocusWithin={{ color: "blue" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
      <RangeDatePicker {...fromToFilter} />
      <OfficesSelect
        multiple={true}
        value={filters.getValueOrNull("offices")}
        onChange={(x) => filters.setValue("offices", x)}
      />
      <Select {...statusFilter} />
      <Switch
        isChecked={filters.getValue("includeNonMatching")}
        onChange={(e) => filters.setValue("includeNonMatching", e.target.checked)}
      >
        <Text as="span" fontWeight="medium" verticalAlign="3px">
          Include non-matching
        </Text>
      </Switch>
    </>
  );

  return (
    <Page isLoading={query.status === "pending"}>
      <Page.Header>
        <Page.Title>Exclusion caregiver list</Page.Title>
      </Page.Header>

      <DataTable {...dataTableProps} filterNode={filterNode} />
    </Page>
  );
}

const { accessor, display } = createColumnHelper<Messages["CaregiverExlusionDataNew"]>();

const columns = [
  accessor("fullName", {
    header: "Name",
    cell: ({ row }) => {
      return <EntityLink fullName={row.original.fullName} id={row.original.id} type="Caregiver" />;
    },
  }),
  accessor("displayId", {
    header: "ID",
  }),
  accessor("createdAt", {
    header: "Date",
    sortingFn: "instant",
    cell: ({ cell }) => dateFormatter.toDate(cell.getValue()),
  }),
  accessor("nameOfList", {
    header: "Name of list",
  }),
  accessor("nameInList", {
    header: "Name in list",
  }),
  display({
    header: "Row in file",
    id: "rowInFile",
    cell: ({ cell }) => {
      const { rowNum, rowJson } = cell.row.original;

      if (!isValidRowJson(rowJson)) {
        return null;
      }

      return (
        <Box>
          <Popover isLazy={true} trigger="hover">
            <PopoverTrigger>
              <Button variant="ghost">Found in row number {rowNum}</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverBody>
                  {Object.entries(rowJson.v).map(([key, value]) => (
                    <Box key={key} p={1}>
                      <strong>{key}</strong>: {String(value)}
                    </Box>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Box>
      );
    },
  }),
  display({
    id: "status",
    header: "Status",
    cell: ({ row }) => {
      switch (getRowStatus(row.original)) {
        case "No Match":
          return (
            <Tag colorScheme="blue" size="lg">
              No match
            </Tag>
          );
        case "Flagged":
          return (
            <Tag colorScheme="red" size="lg">
              Flagged
            </Tag>
          );
        case "Un-Flagged":
          return (
            <Tag colorScheme="green" size="lg">
              Un-Flagged
            </Tag>
          );
      }
    },
    meta: {
      csvFn: (_, row) => getRowStatus(row),
    },
  }),
  display({
    id: "_actions",
    header: "Actions",
    cell: ({ row }) => (
      <ButtonGroup>
        <CaregiverExclusionRowSwitchFlagButton row={row.original} />

        <Button
          as="a"
          href={row.original.dataSourceSignedUrl}
          leftIcon={<DownloadIcon />}
          target="_blank"
          variant="outline"
        >
          Download file
        </Button>

        <CaregiverExclusionRowTerminateButton row={row.original} />
      </ButtonGroup>
    ),
  }),
];

function atStartOfMonth(localDate: LocalDate) {
  return localDate.withDayOfMonth(1);
}

function atEndOfMonth(localDate: LocalDate) {
  return localDate.withDayOfMonth(localDate.lengthOfMonth());
}

function isValidRowJson(rowJson: unknown): rowJson is { v: Record<string, unknown> } {
  return typeof rowJson === "object" && rowJson !== null && "v" in rowJson;
}

function getRowStatus(row: Messages["CaregiverExlusionDataNew"]) {
  if (row.noMatch) {
    return "No Match" as const;
  }

  if (row.flagged) {
    return "Flagged" as const;
  }

  return "Un-Flagged" as const;
}
