import { Button } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import LoadingButton from "../../../shared/components/LoadingButton";
import Select, { CustomSelectProps } from "../../../shared/components/Select";
import SelectNoResults from "../../../shared/components/SelectNoResults";
import useApi from "../../../shared/hooks/useApi";
import { WorkflowTaskClusterId } from "../../../shared/schema/schema";

export default function WorkflowTaskClusterSelect(props: CustomSelectProps<WorkflowTaskClusterId>) {
  const { queries } = useApi();

  const query = useQuery(queries.workflow.clusters());

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          isLoading={query.isFetching}
          label={props.multiple ? "Clusters" : "Cluster"}
          options={query.data.clusters.map((x) => ({ value: x.id, label: x.name }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          {...props}
        />
      );
  }
}
