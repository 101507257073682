import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Avatar, Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { getFullName } from "../../../shared/utils/get-full-name";
import { noteTypes } from "../utils/note.utils";

export default function AttachedNotes(props: { notes: Messages["EntityNote"][] }) {
  const [seeAttached, setSeeAttached] = React.useState(false);

  return (
    <Flex direction="column" gap={2}>
      <Box>
        {seeAttached ? (
          <Button
            rightIcon={<ChevronUpIcon />}
            variant="link"
            onClick={() => setSeeAttached(false)}
          >
            Hide attached notes
          </Button>
        ) : (
          <Button
            rightIcon={<ChevronDownIcon />}
            variant="link"
            onClick={() => setSeeAttached(true)}
          >
            See attached notes
          </Button>
        )}
      </Box>

      {seeAttached && (
        <Flex direction="column" gap={3}>
          {props.notes.map((note) => (
            <AttachedNoteBox key={note.id} note={note}></AttachedNoteBox>
          ))}
        </Flex>
      )}
    </Flex>
  );
}

function AttachedNoteBox(props: { note: Messages["EntityNote"] }) {
  return (
    <Flex borderColor="gray.200" borderWidth="1px" direction="column" gap={2} p={4} rounded="md">
      <Flex fontSize="md" gap={2}>
        <Text fontWeight="semibold">{noteTypes[props.note.noteType]}</Text>
        {props.note.subject !== null && <Text color="gray.600">({props.note.subject.text})</Text>}
      </Flex>

      <Flex gap={2}>
        <Flex gap={2}>
          <Avatar name={getFullName(props.note.author)} size="xs" />
          <Text>by {getFullName(props.note.author)}</Text>
        </Flex>

        <Text>&bull;</Text>

        <Text>{dateFormatter.toDateOrDateTime(props.note.createdAt)}</Text>
      </Flex>
    </Flex>
  );
}
