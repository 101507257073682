import { isEmpty } from "lodash";
import { Messages } from "../../../core/api";
import { S3Object } from "../../../shared/hooks/useUploadFiles";
import assert from "../../../shared/utils/assert";
import { TicketPopupPrimaryEntityOptions } from "../components/CommCenterChatWrapper";
import { dateFormatter } from "../../../shared/utils/date-formatter";

export const DEFAULT_SYSTEM_EMAIL_ADDRESS = "info@mail.medflyt.com";

export const getMailboxStrings = (
  emailAddressObjects: Messages["CommCenterEmailAddressObject"][]
) => {
  return emailAddressObjects.map(getMailboxString).join(", ");
};

export const getMailboxString = (emailAddressObject: Messages["CommCenterEmailAddressObject"]) => {
  return isEmpty(emailAddressObject.name)
    ? emailAddressObject.email
    : `${emailAddressObject.name} <${emailAddressObject.email}>`;
};

export function buildAttachmentsPayloadForEmailMessage(attachments: S3Object[]) {
  const payload: {
    s3Url: string;
    name: string;
    type: string;
  }[] = [];
  for (const attachment of attachments) {
    payload.push({ name: attachment.file.name, type: attachment.file.type, s3Url: attachment.key });
  }

  return payload;
}

export const isInboundEmailMessage = (message: Messages["CommCenterEmailMessage"]) => {
  return message.direction === "INBOUND";
};
export const isOutboundEmailMessage = (message: Messages["CommCenterEmailMessage"]) => {
  return message.direction === "OUTBOUND";
};

export function getLatestCommCenterEmailMessageByRecipient(
  messages: Messages["CommCenterEmailMessage"][]
) {
  return messages.filter((message) => isInboundEmailMessage(message)).at(-1);
}

export type EmailTicket = Messages["CommCenterTicket"] & {
  emailThread: NonNullable<Messages["CommCenterTicket"]["emailThread"]>;
};
export function isEmailTicket(ticket: Messages["CommCenterTicket"]): ticket is EmailTicket {
  return ticket.source === "EMAIL" && ticket.emailThread !== null;
}

export type EmailQuotedReplyParams = {
  date: string;
  time: string;
  sender: string;
  content: string;
};

export function getQuotedReplyParams(
  message: Messages["CommCenterEmailMessage"]
): EmailQuotedReplyParams {
  const date = dateFormatter.toDate(message.createdAt);
  const time = dateFormatter.toTime(message.createdAt);
  const sender = getMailboxString(message.sender);

  return { date, time, sender, content: message.content };
}

export const MAX_EMAIL_ATTACHMENTS_SIZE = 21 * 1024 * 1024; // 21 MB

export function getTicketPopupEntityParamsFromTicketCreation(
  ticket: Messages["CommCenterTicket"]
): TicketPopupPrimaryEntityOptions {
  switch (ticket.topic) {
    case "Patient":
      assert(ticket.relatedPatient?.id, "Expected related patient in patient ticket");
      return {
        primaryEntity: "Patient",
        patientId: ticket.relatedPatient.id,
        contactDetails: null,
      };

    case "Caregiver":
      assert(ticket.relatedCaregiver?.id, "Expected related caregiver in caregiver ticket");
      return {
        primaryEntity: "Caregiver",
        caregiverId: ticket.relatedCaregiver.id,
      };

    case "NotIdentifiedEmailEntity":
      assert(
        ticket.relatedNotIdentifiedEmailEntity,
        "Expected related not identified email entity in not identified email entity ticket"
      );
      return {
        primaryEntity: "NotIdentifiedEmailEntity",
        notIdentifiedEmailEntity: ticket.relatedNotIdentifiedEmailEntity,
      };

    default:
      throw new Error("Unexpected ticket topic for email ticket");
  }
}
