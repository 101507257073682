import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";

export default function useNotesEntitySettingsQuery(params: {
  noteTypes: Messages["NoteTypes"][];
}) {
  const { queries } = useApi();

  return useQuery({
    ...queries.note.settings(),
    select: ({ agencyEntityNotes }) => {
      const settings = agencyEntityNotes.find((x) => params.noteTypes.includes(x.noteType));

      if (!settings) {
        throw new Error(`No settings found for note types ${params.noteTypes.join(",")}`);
      }

      return settings;
    },
  });
}
