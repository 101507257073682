import {
  Button,
  ButtonGroup,
  ButtonProps,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  TextProps,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { z } from "zod";
import { Messages } from "../../../core/api";
import ErrorBox from "../../../shared/components/ErrorBox";
import useControlledForm from "../../../shared/hooks/useControlledForm";
import { NotePredefinedAnswerId } from "../../../shared/schema/schema";
import useNoteEntitySettingsQuery from "../hooks/useNoteEntitySettingsQuery";
import { zEntityNoteForm } from "../utils/note-form";
import EntityNoteFormControls from "./EntityNoteFormControls";

type Props = {
  titleProps?: TextProps;
  confirmButtonProps?: ButtonProps;
  children?: React.ReactNode;
  hardcodedAnswerId?: NotePredefinedAnswerId;
  noteType: Messages["NoteTypes"];
  disclosure: ReturnType<typeof useDisclosure>;
  isLoading: boolean;
  onSuccess: (form: z.infer<ReturnType<typeof zEntityNoteForm>>) => void;
};

export default function EntityNoteFormModal(props: Props) {
  const query = useNoteEntitySettingsQuery({ noteType: props.noteType });

  return (
    <Modal isCentered={true} size="2xl" {...props.disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text {...props.titleProps}>{props.titleProps?.children ?? "Add note"}</Text>
        </ModalHeader>
        {(() => {
          switch (query.status) {
            case "pending":
              return (
                <>
                  <ModalBody>
                    <EntityNoteFormControls.Skeleton />
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Skeleton h={9} rounded="md" w={20} />
                      <Skeleton h={9} rounded="md" w={16} />
                    </ButtonGroup>
                  </ModalFooter>
                </>
              );
            case "error":
              return (
                <ModalBody>
                  <ErrorBox error={query.error} resetErrorBoundary={query.refetch} />
                </ModalBody>
              );
            case "success":
              return <EntityNoteFormModalBody {...props} settings={query.data} />;
          }
        })()}
      </ModalContent>
    </Modal>
  );
}
function EntityNoteFormModalBody(props: Props & { settings: Messages["EntityNoteSetting"] }) {
  const form = useControlledForm({
    schema: zEntityNoteForm(props.settings),
    onSuccess: props.onSuccess,
    initialValues: {
      notePredefinedAnswerId: props.hardcodedAnswerId,
    },
  });

  return (
    <chakra.form onSubmit={form.onSubmit}>
      <ModalBody>
        {props.children}
        <EntityNoteFormControls
          form={form}
          isReasonDisabled={props.hardcodedAnswerId !== undefined}
          noteTypes={[props.noteType]}
          settings={props.settings}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={props.disclosure.onClose}>Cancel</Button>
          <Button
            colorScheme="blue"
            isLoading={props.isLoading}
            type="submit"
            {...props.confirmButtonProps}
          >
            Post
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </chakra.form>
  );
}
