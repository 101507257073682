import { LocalDate } from "@js-joda/core";

//! @ngInject
export function hhaIntegrationMergePatientModal(
            $rootScope,
            $scope,
            $filter,
            $state,
            $uibModalInstance,
            generalUtils,
            DatabaseApi,
            PatientModalService,
            hhaxIntegrationService,
            GoogleAddressService,
            toaster
        ) {
            $scope.state = {};

            const getInitialMergeForm = () => ({
                dateOfBirth: false,
                address: false,
                address2: false,
                phoneNumbers: false,
                firstName: false,
                middleName: false,
                lastName: false,
                memberId: false,
                gender: false,
                medicaidNumber: false,
                status: false,
                contractStartDate: false,
                contractEndDate: false
            });

            function fmap(target, predicate) {
                if (target === null || target === undefined) {
                    return target;
                }

                return predicate(target);
            }

            const getProperties = () => {
                const hhaxRecord = $scope.$resolve.record.content;
                const { phoneNumbers, contracts } = $scope.state.asyncPatientData;
                const contract = contracts.find((x) => x.id === $scope.state.selectedContract.id);
                const patient = $scope.state.selectedPatient;

                if (patient === undefined) {
                    throw new Error("Failed to find the patient");
                }

                if (contract === undefined) {
                    throw new Error("Failed to find the contract of the patient");
                }

                const hhaAddress = `${hhaxRecord.street1 ?? ""}, ${hhaxRecord.state ?? ""} ${
                    hhaxRecord.zip ?? ""
                }, ${hhaxRecord.city ?? ""}`

                return [
                    {
                        model: "dateOfBirth",
                        label: "Date of Birth",
                        after: fmap(hhaxRecord.dob, $filter("dateStringToLocaleDate")),
                        before: fmap(patient.dateOfBirth, $filter("dateStringToLocaleDate"))
                    },
                    {
                        model: "address",
                        label: "Address",
                        after: hhaAddress,
                        before: patient.address.text
                    },
                    {
                        model: "address2",
                        label: "Address 2",
                        after: hhaxRecord.street2,
                        before: patient.address2
                    },
                    {
                        model: "phoneNumbers",
                        label: "Phone numbers",
                        after: [hhaxRecord.homePhone, hhaxRecord.phone2, hhaxRecord.phone3]
                            .filter((x) => x !== undefined)
                            .join(", "),
                        before: phoneNumbers
                            .map((x) => $filter("phonenumber")(x.phonenumber.replace(/\+1/, "")))
                            .join(", ")
                    },
                    {
                        model: "firstName",
                        label: "First name",
                        after: hhaxRecord.firstName,
                        before: patient.firstName
                    },
                    {
                        model: "middleName",
                        label: "Middle name",
                        after: hhaxRecord.middleName,
                        before: patient.middleName
                    },
                    {
                        model: "lastName",
                        label: "Last name",
                        after: hhaxRecord.lastName,
                        before: patient.lastName
                    },
                    {
                        model: "memberId",
                        label: "Member Id",
                        after: hhaxRecord.mrNumber,
                        before: contract.memberId
                    },
                    {
                        model: "gender",
                        label: "Gender",
                        after: hhaxRecord.gender,
                        before: patient.gender
                    },
                    {
                        model: "medicaidNumber",
                        label: "Medicaid Number",
                        after: hhaxRecord.medicaidNumber,
                        before: contract.medicaidNumber,
                        validate: x => {
                            if (!x) {
                                return false;
                            }

                            return generalUtils.isMedicaidNumberValid(x.toString(), hhaxRecord.state);
                        }
                    },
                    {
                        model: "status",
                        label: "Status",
                        after: hhaxRecord.status,
                        before: $filter("capitalize")(patient.status)
                    },
                    {
                        model: "contractStartDate",
                        label: "Contract Start Date",
                        after: fmap(hhaxRecord.startOfCareDate, $filter("dateStringToLocaleDate")),
                        before: fmap(contract.startDate, $filter("dateStringToLocaleDate"))
                    },
                    {
                        model: "contractEndDate",
                        label: "Contract End Date",
                        after: fmap(hhaxRecord.dischargeDate, $filter("dateStringToLocaleDate")),
                        before: fmap(contract.endDate, $filter("dateStringToLocaleDate"))
                    }
                ];
            };

            const getAsyncData = (patientId) => {
                const { hhaxPayerCode } = $scope.$resolve.record;
                return Promise.all([
                    PatientModalService.getPatientPhonenumbers(patientId),
                    PatientModalService.getPatientContractExtended(patientId).then(contracts => contracts.filter(x => x.hhaPayer?.hhaPayerCode === hhaxPayerCode.toString()))
                ]);
            }

            const setViewToMerge = async ({ patientId, contractId }) => {
                if (!$rootScope.isPermittedByKey("edit_patient_page_summary_details")) {
                    toaster.pop("warning", "Permission Denied", "Could not merge patients");
                    return;
                }
                $scope.state.isLoading = true;

                try {
                    const [phoneNumbers, contracts] = await getAsyncData(patientId)

                    $scope.state = {
                        view: "Merge",
                        selectedPatient: $filter("toPatient")(patientId),
                        selectedContract: contracts.find((x) => x.id === contractId),
                        asyncPatientData: { phoneNumbers, contracts },
                        mergeForm: getInitialMergeForm(),
                        isLoading: false
                    };

                    $scope.$digest();
                } catch (e) {
                    console.error(e);
                    $scope.state.error = "Failed to fetch patient data";
                }

            };

            const setViewToSearch = (payload) => {
                $scope.state = {
                    view: "Search",
                    ...payload
                };
            };

            const getPropertyByModel = (model) => {
                return getProperties().find((x) => x.model === model);
            };

            const toggleProperty = (property) => {
                if (property.validate && !property.validate(property.after)) {
                    return toaster.pop(
                        "warning",
                        "cannot select to merge this property since it's an invalid value"
                    );
                }

                $scope.state.mergeForm[property.model] = !$scope.state.mergeForm[property.model];
            };

            const setViewToMatchesList = () => {
                $scope.state = {
                    ...$scope.state,
                    view: "MatchesList",
                    asyncPatientData: undefined,
                    canViewMatchesList: false
                };
            };

            const hhaDateToLocalDate = (value) => {
                const regex = /(?<month>\d+)\/(?<day>\d+)\/(?<year>\d+)/.exec(value);

                if (regex === null) {
                    throw new Error(
                        `Failed to parse the date from HHAX ("${JSON.stringify(value)}")`
                    );
                }

                const { year, month, day } = regex.groups;

                return LocalDate.of(year, month, day);
            };

            const hhaPhoneNumberToInternationalPhoneNumber = (value) => {
                return value === null ? null : `+1${value.replace(/-/g, "")}`;
            };

            const mergePatient = async () => {
                const models = Object.entries($scope.state.mergeForm)
                    .filter(([, selected]) => selected)
                    .map(([model]) => model);

                const buildPatientPatchPayloadByModels = async () => {
                    const payloadNew = {
                        recordId: $scope.$resolve.record.id,
                        patientContractId: $scope.state.selectedContract.id
                    };

                    for (const model of models) {
                        const property = getPropertyByModel(model);

                        if (property === undefined || property.after === undefined) {
                            continue;
                        }

                        const { after: value, validate } = getPropertyByModel(model);

                        if (validate !== undefined && !validate(value)) {
                            continue;
                        }

                        switch (model) {
                            case "dateOfBirth":
                                payloadNew.dateOfBirth = fmap(value, hhaDateToLocalDate);
                                break;
                            case "address":
                                const address =
                                    await GoogleAddressService.getAddressComponentsFromText(value);

                                payloadNew.address = {
                                    components: address,
                                    timezone: "America/New_York",
                                    text: address.formatedAddressDetails.fullAddress
                                };
                                break;
                            case "address2":
                                payloadNew.address2 = value;
                                break;
                            case "addressInstructions":
                               payloadNew.addressInstructions = value;
                               break;
                            case "phoneNumbers":
                                const hhaPhoneNumbers = value
                                    .split(", ")
                                    .map(hhaPhoneNumberToInternationalPhoneNumber);
                                const mfPhoneNumbers = new Set(
                                    $scope.state.asyncPatientData.phoneNumbers.map(
                                        (x) => x.phonenumber
                                    )
                                );
                                const newPhonenumbers = hhaPhoneNumbers.filter(
                                    (x) => !mfPhoneNumbers.has(x)
                                );

                                payloadNew.phoneNumbers = newPhonenumbers.map((x) => ({
                                    patientId: $scope.state.selectedPatient.id,
                                    type: "OTHER",
                                    label: "HHA",
                                    description: "Imported via HHA integration",
                                    phonenumber: x
                                }));
                                break;
                            case "firstName":
                                payloadNew.firstName = value;
                                break;
                            case "middleName":
                                payloadNew.middleName = value;
                                break;
                            case "lastName":
                                payloadNew.lastName = value;
                                break;
                            case "memberId":
                                payloadNew.memberId = value;
                                break;
                            case "gender":
                                payloadNew.gender = value;
                                break;
                            case "medicaidNumber":
                                payloadNew.medicaidNumber = value;
                                break;
                            case "status":
                                payloadNew.status = fmap(value, (x) => x.toUpperCase());
                                break;
                            case "contractStartDate":
                                payloadNew.contractStartDate = fmap(value, hhaDateToLocalDate);
                                break;
                            case "contractEndDate":
                                payloadNew.contractEndDate = fmap(value, hhaDateToLocalDate);
                                break;
                            default:
                                throw new Error(
                                    `Unexpected ${model} in buildPatientPatchPayloadByModels`
                                );
                        }
                    }

                    return payloadNew;
                };

                const patchPatientBody = await buildPatientPatchPayloadByModels();
                const patchPatientUrl = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient/${$scope.state.selectedPatient.id}/hhax_edit_patient`;

                $scope.state.isLoading = true;

                return DatabaseApi.patch(patchPatientUrl, patchPatientBody)
                    .then(() => {
                        $scope.$resolve.onMergeRecord();
                        toaster.pop("success", "The patient has been successfully merged");
                        $scope.state.isLoading = false;
                        $uibModalInstance.close();
                    }).catch((e) => {
                        console.error(e);

                        const message = e.status === 403
                            ? "Insufficient permissions"
                            : "Failed to update patient / process record";
                        
                        toaster.pop("error", message);
                        $scope.state.isLoading = false;
                        $uibModalInstance.close();
                    })
            };

            const OnInit = () => {
                const { possibleMfPatientMatches } = $scope.$resolve.record;

                if (possibleMfPatientMatches.length === 0) {
                    setViewToSearch({
                        selectedPatient: null,
                        selectedContract: null
                    });
                    return;
                }

                if (possibleMfPatientMatches.length === 1) {
                    setViewToMerge({
                        patientId: possibleMfPatientMatches[0].patientId,
                        contractId: possibleMfPatientMatches[0].contractId
                    });
                    return;
                }

                if (possibleMfPatientMatches.length > 1) {
                    setViewToMatchesList();
                    return;
                }

            };

            $scope.onSelectPatient = async (patient) => {
                $scope.state.isLoading = true;

                const [phoneNumbers, contracts] = await getAsyncData(patient.id);

                setViewToSearch({
                    selectedPatient: patient,
                    selectedContract: null,
                    asyncPatientData: { phoneNumbers, contracts }
                });

                $scope.$digest();
            };

            $scope.onSelectPatientContract = (contract) => {
                setViewToSearch({
                    selectedPatient: $scope.state.selectedPatient,
                    selectedContract: contract,
                    asyncPatientData: $scope.state.asyncPatientData
                });
            };

            $scope.onClickContinueFromSearch = () => {
                setViewToMerge({
                    contractId: $scope.state.selectedContract.id,
                    patientId: $scope.state.selectedPatient.id
                });
            };
            
            $scope.onRequestSearchPatient = () => {
                setViewToSearch({
                    selectedPatient: null,
                    selectedContract: null
                });
            }

            $scope.onRequestCreatePatient = async () => {

                if (!$rootScope.isPermittedByKey("add_patient")) {
                    toaster.pop("warning", "Permission Denied", "Could not create new patient");
                    return;
                }

                const hhaxRecord = $scope.$resolve.record.content;
                $state.go("app.patients.dashboard", {
                    createPatientParams: {
                        form: {
                            dateOfBirth: fmap(fmap(hhaxRecord.dob, $filter("dateStringToLocaleDate")), hhaDateToLocalDate),
                            addressObject: `${hhaxRecord.street1 ?? ""}, ${hhaxRecord.state ?? ""} ${hhaxRecord.zip ?? ""}, ${hhaxRecord.city ?? ""}`,
                            address2: hhaxRecord.street2,
                            addressInstructions: null,
                            firstName: hhaxRecord.firstName ?? null,
                            middleName: hhaxRecord.middleName ?? null,
                            lastName: hhaxRecord.lastName ?? null,
                            gender: hhaxRecord.gender ?? null,
                            status: hhaxRecord.status.toUpperCase(),
                            homePhoneNumberForm: hhaxRecord.homePhone ?? null,
                            mobilePhoneNumberForm: hhaxRecord.phone2 ?? null,
                            phone3Form: hhaxRecord.phone3,
                        },
                        contract: {
                            payerId: $scope.$resolve.record.payerId,
                            medicaidNumber: hhaxRecord.medicaidNumber,
                            memberId: hhaxRecord.mrNumber,
                            contractStartDate: fmap(fmap(hhaxRecord.startOfCareDate, $filter("dateStringToLocaleDate")), hhaDateToLocalDate),
                            contractEndDate: fmap(fmap(hhaxRecord.dischargeDate, $filter("dateStringToLocaleDate")), hhaDateToLocalDate),
                            isPrimary: true
                        }
                    }
                });

                $uibModalInstance.close();
            }

            $scope.hasDifferentNames = (patient) => {
                const normalize = ({ firstName, lastName }) => {
                    return [firstName, lastName]
                        .filter(x => typeof x === "string")
                        .map(x => x.toLowerCase())
                        .sort((a, b) => a.localeCompare(b))
                        .join(" ")
                };

                const hhaName = normalize({
                    firstName: $scope.$resolve.record.content["firstName"],
                    lastName: $scope.$resolve.record.content["lastName"]
                });

                const mfName = normalize(patient);

                return hhaName !== mfName;
            };

            $scope.canViewMatchesList = $scope.$resolve.record.possibleMfPatientMatches.length > 0,
            $scope.getProperties = getProperties;
            $scope.onRequestViewMatchesList = setViewToMatchesList;
            $scope.onRequestMergePatient = setViewToMerge;
            $scope.onClickProperty = toggleProperty;
            $scope.mergePatient = mergePatient;
            $scope.patientsMap = DatabaseApi.patients();

            OnInit();
        }
