import { FlexProps, Flex, HeadingProps, Heading } from "@chakra-ui/react";

const CallTicketRow = (props: FlexProps) => {
  return (
    <Flex
      borderRadius={10}
      borderWidth={1}
      direction="column"
      gap={4}
      overflowY="scroll"
      padding={4}
      {...props}
    >
      {props.children}
    </Flex>
  );
};

const Header = (props: HeadingProps) => {
  return (
    <Heading fontSize={{ "2xl": "lg", base: "sm" }} {...props}>
      {props.children}
    </Heading>
  );
};
CallTicketRow.Header = Header;

const Content = (props: FlexProps) => {
  return (
    <Flex direction="column" gap={4} paddingTop={2} {...props}>
      {props.children}
    </Flex>
  );
};
CallTicketRow.Content = Content;

const ContentRow = (props: FlexProps) => {
  return (
    <Flex alignItems="center" direction="row" fontSize="md" gap={{ base: 2, "2xl": 4 }} {...props}>
      {props.children}
    </Flex>
  );
};
CallTicketRow.ContentRow = ContentRow;

export default CallTicketRow;
