import { ChatIcon, EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { capitalize } from "../../../../../shared/utils";
import { CommCenterTicket } from "../../../../communication/communication.types";

export function VisitAssignmentListedTicketLabel(props: { ticket: CommCenterTicket }) {
  if (props.ticket.topic === "Caregiver" && props.ticket.label !== null) {
    return (
      <VisitAssignmentListedTicketLabelLayout>
        <Text isTruncated>{props.ticket.label.name}</Text>
      </VisitAssignmentListedTicketLabelLayout>
    );
  }
  switch (props.ticket.source) {
    case "MANUAL":
    case "MOBILE_CHAT":
    case "SYSTEM_TRIGGER":
      return (
        <VisitAssignmentListedTicketLabelLayout icon={<ChatIcon color="gray.600" fontSize="xs" />}>
          Chat
        </VisitAssignmentListedTicketLabelLayout>
      );
    case "EMAIL":
      return (
        <Flex align="center" color="blackAlpha.700" gap={1}>
          <EmailIcon color="blackAlpha.600" fontSize="xs" />
          <Text fontSize="sm">{capitalize(props.ticket.emailThread?.direction ?? "INBOUND")}</Text>
        </Flex>
      );
    case "PHONE_CALL":
      return (
        <VisitAssignmentListedTicketLabelLayout icon={<PhoneIcon color="gray.600" fontSize="xs" />}>
          {props.ticket.callInfo?.direction ?? "Inbound"} ({props.ticket.callInfo?.status})
        </VisitAssignmentListedTicketLabelLayout>
      );
    case "SMS_CONVERSATION":
      return (
        <VisitAssignmentListedTicketLabelLayout icon={<ChatIcon color="gray.600" fontSize="xs" />}>
          SMS Conversation
        </VisitAssignmentListedTicketLabelLayout>
      );
  }
}

function VisitAssignmentListedTicketLabelLayout(props: {
  icon?: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <Flex isTruncated align="center" color="gray.500" fontSize="sm" gap={1} w="full">
      {props.icon}
      {props.children}
    </Flex>
  );
}
