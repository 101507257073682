import { Flex, Text } from "@chakra-ui/react";
import { CommCenterTicket } from "../../../../communication/communication.types";

export function VisitAssignmentListedTicketTopic(props: { ticket: CommCenterTicket }) {
  switch (true) {
    case props.ticket.topic === "Caregiver" && props.ticket.relatedCaregiver !== null:
      return (
        <VisitAssignmentListedTicketTopicText
          id={props.ticket.relatedCaregiver.displayId ?? props.ticket.relatedCaregiver.id}
          name={props.ticket.relatedCaregiver.name}
          type="CAREGIVER"
        />
      );
    case props.ticket.topic === "Patient" && props.ticket.relatedPatient !== null:
      return (
        <VisitAssignmentListedTicketTopicText
          id={props.ticket.relatedPatient.displayId ?? props.ticket.relatedPatient.id}
          name={props.ticket.relatedPatient.name}
          type="PATIENT"
        />
      );
    case props.ticket.topic === "PhonebookContact" && props.ticket.relatedPhonebookContact !== null:
      return (
        <VisitAssignmentListedTicketTopicText
          id={props.ticket.relatedPhonebookContact.id}
          name={props.ticket.relatedPhonebookContact.name}
          type="PHONEBOOK CONTACT"
        />
      );
    default:
      return <Text>Unknown</Text>;
  }
}

function VisitAssignmentListedTicketTopicText(props: { name: string; type: string; id: number }) {
  return (
    <Flex fontWeight="semibold" gap={1} width="full">
      <Text isTruncated color="black">
        {props.name}
      </Text>
      <Text
        _groupActive={{ color: "blue.500" }}
        _groupHover={{ color: "blue.500" }}
        color="gray.500"
        whiteSpace="nowrap"
      >
        {props.type} #{props.id}
      </Text>
    </Flex>
  );
}
