import { CloseIcon } from "@chakra-ui/icons";
import { Flex, useDisclosure } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Handle, NodeProps, Position } from "reactflow";
import { queryKeys } from "../../../../shared/query-keys";
import { DeleteNoteAlertDialog } from "./Components/DeleteNoteAlertDialog";
import { EditableNoteDisplayInput } from "./Components/EditableNoteDisplayInput";
import "./elknode.css";
import { ElkNodeData } from "./flow-settings-visualizer.utils";
import { Messages } from "../../../../core/api";

type ElkNodeStep = Extract<ElkNodeData, { nodeType: "IntakeStep" }>;
type ElkNodeNote = Extract<ElkNodeData, { nodeType: "IntakeNote" }>;

// Not using a Chakra UI component here to benefit better performance
export default function ElkNode(props: NodeProps<ElkNodeData>) {
  switch (props.data.nodeType) {
    case "IntakeStep": {
      const data: NodeProps<ElkNodeStep> = {
        ...props,
        data: { ...props.data },
      };
      return <StepNode {...data} />;
    }
    case "IntakeNote": {
      const data: NodeProps<ElkNodeNote> = {
        ...props,
        data: { ...props.data },
      };
      return <NoteNode {...data} />;
    }
  }
}

function NoteNode({ data }: NodeProps<ElkNodeNote>) {
  const deleteDisclosure = useDisclosure();

  const queryClient = useQueryClient();
  const refreshGraphNodes = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.flowSettings() });
    queryClient.invalidateQueries({ queryKey: queryKeys.patientIntake.intakeNotes() });
  };

  return (
    <>
      <Flex
        align="center"
        background="yellow.100"
        borderColor="gray.200"
        borderRadius="base"
        borderWidth={1}
        flexDir="column"
        justify="center"
        padding={4}
        sx={{ wordWrap: "break-word" }}
        w="md"
      >
        <CloseIcon
          cursor="pointer"
          position="absolute"
          right={4}
          top={4}
          onClick={() => deleteDisclosure.onOpen()}
        />
        <EditableNoteDisplayInput
          content={data.content}
          nodeId={data.id}
          refreshGraphNodes={refreshGraphNodes}
        />
        <DeleteNoteAlertDialog
          disclosure={deleteDisclosure}
          nodeId={data.id}
          refreshGraphNodes={refreshGraphNodes}
        />
      </Flex>
    </>
  );
}

function StepNode({ data, selected }: NodeProps<ElkNodeStep>) {
  const classNames = buildClassNames(data, selected);
  return (
    <div className={classNames}>
      <div className="handles targets">
        {data.targetHandles.map((handle) => (
          <Handle key={handle.id} id={handle.id} position={Position.Left} type="target" />
        ))}
      </div>
      <div className="my-label">
        <NodeContent step={data.step} />
      </div>
      <div className="handles sources">
        {data.sourceHandles.map((handle) => (
          <Handle key={handle.id} id={handle.id} position={Position.Right} type="source" />
        ))}
      </div>
    </div>
  );
}

function buildClassNames(data: ElkNodeStep, selected: boolean) {
  const classNames = ["elk-node"];

  if (data.isRoot) {
    classNames.push("root-node");
  }

  if (selected) {
    classNames.push("selected");
  }

  return classNames.join(" ");
}

function NodeContent({ step }: { step: Messages["IntakeTrackStepForFullFlow"] }) {
  const { id, title, action, code } = step;

  const status = step.status.label;

  const label = step.isStop
    ? ("STOP" as const)
    : step.isMainInStatusAndTrack
    ? ("MAIN" as const)
    : null;

  return (
    <div className="elk-node-content">
      <div className="elk-node-row elk-node-id">#{id}</div>
      <div className="elk-node-row elk-node-title">{title}</div>
      {label && (
        <div className={`elk-node-row elk-node-label elk-node-label-${label.toLowerCase()}`}>
          {label}
        </div>
      )}
      {action && <div className="elk-node-row elk-node-action">Action: {action}</div>}
      {code && <div className="elk-node-row elk-node-code">Code: {code}</div>}
      <div className="elk-node-row">
        <div className="elk-node-status">{status}</div>
      </div>
    </div>
  );
}
