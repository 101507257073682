import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { atom, useAtomValue, useSetAtom } from "jotai";
import React from "react";

const pageFetchingQueryKeysAtom = atom<QueryKey[]>([]);

export function usePageFetchingObserver(query: { queryKey: QueryKey }) {
  const setPageFetchingQueryKeys = useSetAtom(pageFetchingQueryKeysAtom);

  React.useEffect(() => {
    setPageFetchingQueryKeys((prev) => {
      return prev.includes(query.queryKey) ? prev : [...prev, query.queryKey];
    });

    return () => {
      setPageFetchingQueryKeys((prev) => {
        return prev.filter((key) => key !== query.queryKey);
      });
    };
  }, [query.queryKey, setPageFetchingQueryKeys]);
}

export function useIsPageLoading() {
  const pageFetchingQueryKeys = useAtomValue(pageFetchingQueryKeysAtom);
  const queryClient = useQueryClient();

  const isLoading = React.useMemo(
    () => pageFetchingQueryKeys.some((queryKey) => queryClient.isFetching({ queryKey })),
    [pageFetchingQueryKeys, queryClient]
  );

  return { isLoading };
}
