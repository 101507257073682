import { Messages } from "../../core/api";
import { isOneOf } from "../../shared/utils";
import { getLabelForNotIdentifiedPhoneNumber } from "../../shared/utils/call-center";
import { getFullName } from "../../shared/utils/get-full-name";
import { phoneFormatter } from "../../shared/utils/phone-formatter";
import { CallState } from "./hooks/useCallState";

export function shouldRing(callState: Pick<CallState, "status">) {
  switch (callState.status) {
    case "Idle":
      return true;
    case "Dialing":
    case "Calling":
    case "Active":
    case "Ringing":
    case "Loading":
    case "Unauthorized":
      return false;
  }
}

export function isInboundIntakeCall(
  call: Messages["TelephonyCallInfo"] | null
): call is Messages["TelephonyInboundCallInfo"] & {
  callerInfo:
    | Messages["TelephonyCallPatientParticipantEntityInfo"]
    | Messages["TelephonyCallPatientContactParticipantEntityInfo"];
} {
  return (
    call?.direction === "Inbound" &&
    call.ticket.teamName === "Intake" &&
    ["Patient", "PatientContact"].includes(call.caller.entity.type)
  );
}

export function isOutboundIntakeCall(
  call: Messages["TelephonyCallInfo"] | null
): call is Messages["TelephonyOutboundCallInfo"] & {
  callee: {
    role: "Callee";
    entity:
      | Messages["TelephonyCallPatientParticipantEntityInfo"]
      | Messages["TelephonyCallPatientContactParticipantEntityInfo"];
  };
} {
  return (
    call?.direction === "Outbound" &&
    call.ticket.teamName === "Intake" &&
    ["Patient", "PatientContact"].includes(call.callee.entity.type)
  );
}

export function isIntakeTeam(teamname: Messages["CallCenterTeamName"]) {
  return teamname === "Intake";
}

export function isIntakeCall(call: Messages["TelephonyCallInfo"] | null) {
  return isInboundIntakeCall(call) || isOutboundIntakeCall(call);
}

export function getTelephonyCallPrimaryTypeAndName(call: Messages["TelephonyCallInfo"]) {
  const participant = call.direction === "Inbound" ? call.caller : call.callee;

  return getTelephonyCallParticipantTypeAndName(participant);
}

export function getIsTelephonyInboundCallInvitation(call: Messages["TelephonyInboundCallInfo"]) {
  return call.caller.entity.type === "AgencyMember";
}

export function getTelephonyInboundCallType(
  call: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
): "Invitation" | "Incoming" | "PortalCallRequest" {
  switch (call.direction) {
    case "Inbound":
      return getIsTelephonyInboundCallInvitation(call) ? "Invitation" : "Incoming";
    case "PortalCallRequest":
      return "PortalCallRequest";
  }
}

export function getTelephonyCallParticipantTypeAndName(
  participant: Messages["TelephonyCallParticipantInfo"]
) {
  switch (participant.entity.type) {
    case "AgencyMember":
      return {
        type: "Agency Member",
        name: getFullName(participant.entity),
      };
    case "Patient":
      return {
        type: "Patient",
        name: participant.entity.isUnknown
          ? `${phoneFormatter.formatNationalIfValid(participant.source)}`
          : `${getFullName(participant.entity)} #${participant.entity.id}`,
      };
    case "PatientContact":
      return {
        type: `${getFullName(participant.entity.patient)} ${participant.entity.relationship}`,
        name: getFullName(participant.entity),
      };
    case "PhonebookContact":
      return {
        type: "Phonebook Contact",
        name: participant.entity.name,
      };
    case "NotIdentified":
      return {
        type: getLabelForNotIdentifiedPhoneNumber(participant.entity.kind),
        name: phoneFormatter.formatNationalIfValid(participant.source),
      };
    case "Caregiver":
      return {
        type: "Caregiver",
        name: getFullName(participant.entity),
      };
  }
}

export function isParticipantRemoveable(participant: {
  status: Messages["TelephonyCallParticipantStatus"];
}) {
  return isOneOf(participant.status, ["Answered", "Invited", "Joined"]);
}

export function getPortalCallPurposeText(call: Messages["TelephonyPortalRequestCallInfo"]) {
  switch (call.purpose) {
    case "RequestAssistance":
      return "Portal user requests assistance";
    case "SwitchAgencies":
      return "Portal user requests to switch agencies";
    case "NYIAFamilyMember":
      return "Portal user (family member) requests to call NYIA (need to add patient to the call)";
    case "NYIAPatient":
      return "Portal user (patient) requests to call NYIA";
    case "MedicaidChoicePatient":
      return "Portal user (patient) request to call Medicaid Choice";
  }
}

export function isPortalCall(
  call: Messages["TelephonyCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
): call is Messages["TelephonyPortalRequestCallInfo"] {
  return call.direction === "PortalCallRequest";
}

export function isConferenceCall(
  call: Messages["TelephonyPortalRequestCallInfo"] | Messages["TelephonyInboundCallInfo"]
): call is Messages["TelephonyInboundCallInfo"] {
  return call.direction === "Inbound" && call.participants.length > 1;
}

export async function validateMicrophone() {
  if (!window.navigator.mediaDevices || !window.navigator.mediaDevices.getUserMedia) {
    throw new Error("Your browser does not support microphone access.");
  }

  try {
    const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });

    stream.getTracks().forEach((track) => track.stop());
  } catch (error) {
    throw new Error(`Access to microphone was denied or it is not available: ${error}`);
  }
}
