"use strict";

export const complianceItemRecords = {
    templateUrl: "admin/modules/compliance/components/compliance-item-records/compliance-item-records.component.html",
    bindings: {
        caregiverId: '<',
        item: "<",
        onClickApproveItem: '&',
        loadRecords: '&'
    },
    //! @ngInject
    controller: function (
        $scope,
        $rootScope,
        DatabaseApi,
        NgTableParams,
        complianceService,
        $uibModal,
        mfModal,
        toaster,
    ) {

        this.caregiverUploadTabs = {
            records: {
                id: 0,
                name: "Records",
                src: "admin/views/caregiver-compliance-upload-records-tab.html"
            },
            pending: {
                id: 1,
                name: "Pending Review",
                src: "admin/views/caregiver-compliance-upload-pending-tab.html"
            },
            history: {
                id: 2,
                name: "History",
                src: "admin/views/caregiver-compliance-upload-history-tab.html"
            }
        };

        this.baseCols = [
            { title: "Effective Date" },
            { title: "Expiration Date" },
            { title: "View Upload" },
            { title: "Compliant" },
        ];

        const initialize = () => {
            $scope.$ctrl.item.activeRecord = null;
            $scope.$ctrl.item.previousRecords = [];
            $scope.$ctrl.item.tables = [];

            const recordsOptions = {
                count: 10,
                sorting: { effectiveDate: "desc" }
            };

            if ($scope.$ctrl.item.instances.length > 0) {
                $scope.$ctrl.item.instances = $scope.$ctrl.item.instances.map(instance => {
                    instance.daysToExpireNum = complianceService.calculateDaysDiffFromToday(instance.expiryDate);
                    instance.daysToExpire = complianceService.calculateDayToExpireAsText(instance.daysToExpireNum);
                    instance.isValidExpiry = instance.daysToExpire && instance.daysToExpire.indexOf("ago") === -1;
                    return instance;
                });
                $scope.$ctrl.item.activeRecord = $scope.$ctrl.item.instances.length > 0 ? $scope.$ctrl.item.instances[0] : null;
                $scope.$ctrl.item.previousRecords = $scope.$ctrl.item.instances.slice(1);
                $scope.$ctrl.item.tables = [
                    new NgTableParams(recordsOptions, {
                        counts: [],
                        dataset: $scope.$ctrl.item.activeRecord ? [$scope.$ctrl.item.activeRecord] : []
                    }),
                    new NgTableParams(recordsOptions, {
                        counts: [],
                        dataset: $scope.$ctrl.item.previousRecords
                    })
                ];
            }

            const pendingOptions = {
                count: 25,
            };

            // set pending uploads table
            if ($scope.$ctrl.item.caregiverPendingUploads.length > 0) {
                $scope.$ctrl.item.caregiverPendingUploads = $scope.$ctrl.item.caregiverPendingUploads.map(pending => {
                    pending.daysToExpireNum = complianceService.calculateDaysDiffFromToday(pending.expirationDate);
                    pending.daysToExpire = complianceService.calculateDayToExpireAsText(pending.daysToExpireNum);
                    pending.isValidExpiry = pending.daysToExpire && pending.daysToExpire.indexOf("ago") === -1;
                    return pending;
                })
                $scope.$ctrl.item.pendingUploadsTable = new NgTableParams(pendingOptions, {
                    counts: [],
                    dataset: $scope.$ctrl.item.caregiverPendingUploads
                });
            }

            const historyOptions = {
                count: 20,
                sorting: { fromDate: "desc" }
            };

            $scope.$ctrl.item.historyTable = new NgTableParams(historyOptions, {
                counts: [10, 20, 50, 100],
                dataset: complianceService.transformHistoryStatusChanges($scope.$ctrl.item.historyStatusChanges)
            });
            
            const isPending = ({ complianceStatus })=> ['Rejected', 'Dismissed'].indexOf(complianceStatus) === -1;

            $scope.$ctrl.item.pendingUploadsAmount = $scope.$ctrl.item.caregiverPendingUploads.filter(isPending).length;
            if ($scope.$ctrl.item.activeUploadTab === undefined) {
                $scope.$ctrl.item.activeUploadTab = null;
            }
        };

        this.getDropdownTextValue = (field) => field.possibleValues.find(v => v.id === parseInt(field.model, 10))?.text;

        this.viewComplianceInstanceDocument = (document) => {
            let newScope = $scope.$new();
            newScope.document = document;
            newScope.url = 'agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.$ctrl.caregiverId +
                '/compliance_instances/' + document.id + '/preview';

            $uibModal.open({
                templateUrl: 'admin/views/image-viewer-modal.html',
                size: 'lg',
                controller: 'imageViewerModalCtrl',
                scope: newScope
            });
        };

        this.viewCaregiverPendingUpload = function (document) {
            const newScope = $scope.$new();
            newScope.document = document;
            newScope.url = 'agencies/' + $rootScope.agencyId + '/caregivers/' + $scope.$ctrl.caregiverId +
                '/document_uploads/' + document.caregiverDocumentUploadId + '/generate_url';

            $uibModal.open({
                templateUrl: 'admin/views/image-viewer-modal.html',
                size: 'lg',
                controller: 'imageViewerModalCtrl',
                scope: newScope
            });
        };

        this.editComplianceItem = (complianceItemInstance, item) => {
            const newScope = $scope.$new();

            newScope.item = item;
            newScope.complianceItemInstance = { ...complianceItemInstance, caregiverDocumentTypeName: item.name };
            newScope.caregiverId = complianceItemInstance.caregiverId;
            newScope.itemId = complianceItemInstance.caregiverDocumentTypeId;
            newScope.type = "UPDATE_RECORD";
            newScope.caregiverDocumentUploadId = complianceItemInstance.caregiverDocumentUploadId;
            newScope.fields = complianceItemInstance.fields;

            if (item.defaultExpirySettings) {
                newScope.defaultExpirySettings = item.defaultExpirySettings;
            }

            const modalInstance = $uibModal.open({
                templateUrl: 'admin/modules/compliance/views/caregiver-compliance-instance-modal.html',
                controller: 'caregiverComplianceInstanceModalCtrl',
                size: 'lg',
                scope: newScope,
                windowClass: "compliance-instance-modal"
            });

            modalInstance.result.then(async () => {
                await $scope.$ctrl.loadRecords({
                    activeCaregiverDocumentTypeId: complianceItemInstance.caregiverDocumentTypeId,
                    targetTab: "records"
                });
            }, () => { });
        };

        this.deleteComplianceItem = (complianceItemInstance, itemName) => {
            const modal = mfModal.create({
                subject: "Delete compliance item",
                message: `Are you sure you want to delete this record for :itemName?`.replace(":itemName", itemName),
                layoutOrder: ["message", "options"],
                cancelLabel: "Cancel",
                confirmLabel: "Delete",
                variant: "danger",
                onConfirm: () => {
                    modal.setLoading(true);

                    const url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/compliance_items/:complianceInstanceId"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":caregiverId", $scope.$ctrl.caregiverId)
                        .replace(":complianceInstanceId", complianceItemInstance.id);

                    DatabaseApi.delete(url).then(async () => {
                        await $scope.$ctrl.loadRecords({
                            activeCaregiverDocumentTypeId: complianceItemInstance.caregiverDocumentTypeId,
                            targetTab: "records"
                        });
                        toaster.pop("success", "Compliance record deleted");
                        modal.close();
                    }, () => {
                        toaster.pop("error", "Something went wrong", "Can't delete compliance item");
                    }).finally(() => {
                        modal.setLoading(false);
                    });
                },
            });
        };

        this.rejectComplianceItemPendingUpload = (pendingUpload, complianceItem) => {
            const newScope = $scope.$new();
            newScope.pendingUpload = pendingUpload;
            newScope.complianceItemName = complianceItem.name;
            newScope.caregiverDocumentTypeId = complianceItem.caregiverDocumentTypeId;

            const modalInstance = $uibModal.open({
                templateUrl: 'admin/modules/compliance/views/reject-compliance-item-pending-upload-modal.html',
                size: 'lg',
                controller: 'rejectComplianceItemPendingUploadModalCtrl',
                scope: newScope
            });

            modalInstance.result.then(async (res) => {
                if (res === 'success') {
                    await $scope.$ctrl.loadRecords({
                        activeCaregiverDocumentTypeId: complianceItem.caregiverDocumentTypeId,
                        targetTab: "pending"
                    });
                }
            }, () => { });
        };


        this.dismissComplianceItemPendingUpload = async (pendingUpload, complianceItem) => {
            const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${pendingUpload.caregiverId}/uploaded_documents/${pendingUpload.caregiverDocumentUploadId}/dismiss`;

            return DatabaseApi.post(url)
                .then((res) => {
                        toaster.pop("success", "Successfully dismissed upload");
                        pendingUpload.complianceStatus = "Dismissed";

                        return $scope.$ctrl.loadRecords({
                            activeCaregiverDocumentTypeId: complianceItem.caregiverDocumentTypeId,
                            targetTab: "pending"
                        });
                    },
                    (err) => toaster.pop("error", "Oops! Something went wrong", "could not dismiss upload")
                );

        };

        this.handleClickApproveItem = (item, type, caregiverDocumentUploadId) => {
            $scope.$ctrl.onClickApproveItem({
                item: item,
                type: type,
                caregiverDocumentUploadId: caregiverDocumentUploadId
            });
        };
        
        this.$onInit = () => {
            initialize();
        };
    },
};
