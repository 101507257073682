import { Messages, ResponseOf } from "../../../../core/api";
import { IntakeChecklistItemId } from "../../../../shared/schema/schema";

type BlockingMapValue = { id: IntakeChecklistItemId; name: string };
type BlockingMap = Map<IntakeChecklistItemId, BlockingMapValue[]>;
type Checklist = ResponseOf<"get", "./patient_intake/:patientId/checklist">["items"];
type ChecklistItem = Checklist[number];
export type ChecklistWithBlocking = (ChecklistItem & { blocking: BlockingMapValue[] })[];
export type ChecklistItemWithBlocking = ChecklistWithBlocking[number];

function isCustomBlocker(
  blocker: ChecklistItem["blockers"][number]
): blocker is Messages["IntakeChecklistItemBlockerTypes.CustomItemBlocker"] {
  return blocker.isCustomBlocker;
}

export function createBlockingDependenciesForChecklistItems(
  items: ResponseOf<"get", "./patient_intake/:patientId/checklist">["items"]
): ChecklistWithBlocking {
  // get initial map of items and the items they block directly
  const blockersMap: BlockingMap = new Map();
  items.forEach((item) => {
    item.blockers.forEach((blocker) => {
      if (isCustomBlocker(blocker)) return;
      if (!blockersMap.has(blocker.id)) {
        blockersMap.set(blocker.id, [{ id: item.id, name: item.name }]);
      } else {
        blockersMap.get(blocker.id)?.push({ id: item.id, name: item.name });
      }
    });
  });

  // recursion to return decendent blocked items
  const getBlockingItemsRec = (
    blocking: BlockingMapValue,
    visited = new Set<IntakeChecklistItemId>()
  ) => {
    visited.add(blocking.id);
    return (blockersMap.get(blocking.id) || [])
      .reduce((acc, blocker) => {
        if (!visited.has(blocker.id)) {
          acc.push(blocker, ...getBlockingItemsRec(blocker, visited));
        }
        return acc;
      }, [] as BlockingMapValue[])
      .filter((blocked) => {
        const blockedItem = items.find((item) => item.id === blocked.id);
        return blockedItem && blockedItem.isCompleted;
      });
  };

  // get final map of items and the items they block directly and indirectly
  const finalMap: BlockingMap = new Map();
  items.forEach((item) => {
    if (item.isCompleted) {
      finalMap.set(item.id, [...new Set(getBlockingItemsRec({ id: item.id, name: item.name }))]);
    } else {
      finalMap.set(item.id, []);
    }
  });

  return items.map((item) => ({
    ...item,
    blocking: finalMap.get(item.id) || [],
  }));
}
