import { Box, chakra, Text, useFormControlProps } from "@chakra-ui/react";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from "@lexical/markdown";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TreeView } from "@lexical/react/LexicalTreeView";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import {
  $createLineBreakNode,
  $createParagraphNode,
  $getRoot,
  $isDecoratorNode,
  $isElementNode,
  EditorState,
  LexicalEditor,
} from "lexical";
import { editorTheme, RichText } from "./editor-utils";
import { $isEmailReplyChainNode, EmailReplyChainNode } from "./nodes/EmailReplyChainNode";
import { $getMentionNodes, MentionNode } from "./nodes/MentionNode";
import { AutoLinkWithMatchersPlugin } from "./plugins/AutoLinkPlugin";
import NewMentionsPlugin from "./plugins/MentionsPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";

const EDITOR_PADDING = 4;

function Placeholder() {
  return (
    <Text isTruncated fontSize="md" left={EDITOR_PADDING} position="absolute" top={EDITOR_PADDING}>
      Enter some text...
    </Text>
  );
}

const ChakraContentEditable = chakra(ContentEditable, {
  baseStyle: {
    minH: 150,
    p: EDITOR_PADDING,
    fontSize: "md",
    outline: "none",
    ...editorTheme,
  },
});

export type LexicalEditorProps = {
  "aria-invalid"?: boolean;
  isDisabled?: boolean;
  viewTree?: boolean;
  format: "html" | "markdown";
  initialValue?: string;
  onChange: (p: RichText) => void;
};

function prepopulatedRichText(params: {
  value: string;
  format: LexicalEditorProps["format"];
  editor: LexicalEditor;
}) {
  const root = $getRoot();

  switch (params.format) {
    case "html": {
      const document = new DOMParser().parseFromString(params.value, "text/html");
      const nodes = $generateNodesFromDOM(params.editor, document).map((node) => {
        if ($isEmailReplyChainNode(node)) {
          const p = $createParagraphNode();
          p.append($createLineBreakNode(), node);
          return p;
        }

        if (!$isElementNode(node) && !$isDecoratorNode(node)) {
          const p = $createParagraphNode();
          p.append(node);
          return p;
        }

        return node;
      });
      root.append(...nodes);
      break;
    }
    case "markdown":
      return $convertFromMarkdownString(params.value, TRANSFORMERS, root);
  }
}

export default function Editor(props: LexicalEditorProps) {
  const initialConfig: InitialConfigType = {
    namespace: "MyEditor",
    editorState:
      props.initialValue !== undefined
        ? (editor) =>
            prepopulatedRichText({ value: props.initialValue!, format: props.format, editor })
        : undefined,
    onError: (error) => {
      console.error(error);
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      AutoLinkNode,
      LinkNode,
      MentionNode,
      EmailReplyChainNode,
    ],
  };

  const formControlProps = useFormControlProps(props);

  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      const mentions = $getMentionNodes(editorState).map((x) => x.__mention);

      switch (props.format) {
        case "html":
          return props.onChange({ output: $generateHtmlFromNodes(editor), mentions });
        case "markdown":
          return props.onChange({ output: $convertToMarkdownString(TRANSFORMERS), mentions });
      }
    });
  };

  return (
    <Box
      _invalid={{
        borderColor: "red.500",
        boxShadow: "0 0 0 1px var(--chakra-colors-red-500)",
      }}
      aria-invalid={props["aria-invalid"] ?? formControlProps.isInvalid}
      borderWidth="1px"
      rounded="md"
    >
      <LexicalComposer initialConfig={initialConfig}>
        <ToolbarPlugin />
        <Box position="relative">
          <RichTextPlugin
            contentEditable={<ChakraContentEditable />}
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={<Placeholder />}
          />
        </Box>
        <LinkPlugin />
        <ListPlugin />
        <HistoryPlugin />
        <NewMentionsPlugin />
        <AutoLinkWithMatchersPlugin />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <OnChangePlugin onChange={handleChange} />

        {props.viewTree === true ? <TreeViewPlugin /> : <></>}
      </LexicalComposer>
    </Box>
  );
}

function TreeViewPlugin() {
  const [editor] = useLexicalComposerContext();
  return (
    <TreeView
      editor={editor}
      timeTravelButtonClassName="debug-timetravel-button"
      timeTravelPanelButtonClassName="debug-timetravel-panel-button"
      timeTravelPanelClassName="debug-timetravel-panel"
      timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
      treeTypeButtonClassName="debug-treetype-button"
      viewClassName="tree-view-output"
    />
  );
}
