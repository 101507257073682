import { queryOptions } from "@tanstack/react-query";
import { QueryParamsOf } from "../../core/api";
import { createQueryGroup } from "../../core/api/queries";

export default createQueryGroup(({ api }) => {
  return {
    list: (query: QueryParamsOf<"get", "./custom_fields">) =>
      queryOptions({
        queryKey: ["custom_field", "list"],
        queryFn: () => api.get("./custom_fields", { query }),
      }),
  };
});
