import { Messages } from "../../core/api";
import { dateFormatter } from "../../shared/utils/date-formatter";

export function formatCustomFieldValue(field: Messages["CustomFieldValue"]): string {
  switch (field.fieldType) {
    case "TEXT":
    case "NUMBER":
    case "ADDRESS":
    case "PHONE_NUMBER":
    case "CAREGIVER":
    case "PATIENT":
    case "ENUM":
      return String(field.value);
    case "BOOLEAN":
      return field.value ? "Yes" : "No";
    case "DATE":
      return dateFormatter.toDate(field.value);
    case "DATE_RANGE": {
      const form = dateFormatter.toDate(field.from);
      const to = dateFormatter.toDate(field.to);

      return `${form} - ${to}`;
    }
  }
}
