// @ts-check
/* @ngInject */
export function switchEnv($scope, $rootScope, Consts) {
  const activeEnv = (() => {
    return { apiBaseUrl: Consts.api, socketIOServerUrl: Consts.socketUrl };
  })();

  const setActiveEnv = ({ apiBaseUrl, socketIOServerUrl }) => {
    if (apiBaseUrl && socketIOServerUrl) {
      activeEnv.apiBaseUrl = apiBaseUrl;
      activeEnv.socketIOServerUrl = socketIOServerUrl;
      localStorage.removeItem("user");
      localStorage.removeItem("userEmail");
      return localStorage.setItem(
        "apiOverride",
        JSON.stringify({
          overrideApiUrl: apiBaseUrl,
          overrideSocketUrl: socketIOServerUrl,
        })
      );
    }
  };

  const predefinedEnvs = {
    production: {
      apiBaseUrl: "https://api.medflyt.com/",
      socketIOServerUrl: "https://ws.medflyt.com/",
    },
    staging: {
      apiBaseUrl: "https://medflyt-api-staging.herokuapp.com/",
      socketIOServerUrl: "https://ws-stg.medflyt.com/",
    },
    local: {
      apiBaseUrl: "http://localhost:3000/",
      socketIOServerUrl: "http://localhost:3005/",
    },
    p: {
      apiBaseUrl: "https://api-p.medflyt.com/",
      socketIOServerUrl: "https://ws-p.medflyt.com/",
    },
  };

  const onSelectPredefinedEnv = (env) => {
    setActiveEnv(env);
    $scope.shouldReload = true;
  };

  const onSubmitCustomEnv = () => {
    setActiveEnv(activeEnv);
    $scope.shouldReload = true;
  };

  const onResetEnv = () => {
    $rootScope.resetApiOverride();
  };

  $scope.reloadPage = () => window.location.reload();
  $scope.onResetEnv = onResetEnv;
  $scope.activeEnv = activeEnv;
  $scope.predefinedEnvs = predefinedEnvs;
  $scope.onSelectPredefinedEnv = onSelectPredefinedEnv;
  $scope.onSubmitCustomEnv = onSubmitCustomEnv;
}
