import { Instant, Duration } from "@js-joda/core";
import React from "react";
import { durationFormatter } from "../utils/duration-formatter";

function DurationTimer({ instant }: { instant: Instant }) {
  const [now, setNow] = React.useState(Instant.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(Instant.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <>{durationFormatter.hours(Duration.between(instant, now))}</>;
}

export default DurationTimer;
