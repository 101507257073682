import { Button } from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import LoadingButton from "../../../../../shared/components/LoadingButton";
import Select, { CustomSelectProps } from "../../../../../shared/components/Select";
import SelectNoResults from "../../../../../shared/components/SelectNoResults";
import useApi from "../../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../../shared/query-keys";
import { IntegrationPayerId } from "../../../../../shared/schema/schema";

export function IntakePlanSelect(props: CustomSelectProps<IntegrationPayerId>) {
  const { api } = useApi();
  const query = useQuery({
    queryKey: queryKeys.patientIntake.intakePlan(),
    placeholderData: keepPreviousData,
    queryFn: () => api.get("./patient_intake/intake_plan", {}),
  });

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          label={props.label ?? (props.multiple ? "Plans" : "Plan")}
          options={query.data.intakePlans.map((plan) => {
            const label = plan.payerType
              ? `${plan.payerCode} - ${plan.payerName} - ${plan.payerType}`
              : `${plan.payerCode} - ${plan.payerName}`;
            return {
              value: plan.id,
              label,
            };
          })}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          {...props}
        />
      );
  }
}
