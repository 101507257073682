//! @ngInject
export function hhaxMergeAuthModal(
      $rootScope,
      $scope,
      DatabaseApi,
      NgTableParams,
      record,
      toaster,
      PatientModalService,
      $uibModal,
      $uibModalInstance
    ) {
      $scope.periodTypesOptions = {
        DAILY: "Daily",
        WEEKLY: "Weekly",
        MONTHLY: "Monthly",
        ENTIRE_PERIOD: "Entire period",
      };
      $scope.firstDayOfWeekOptions = {
        SUNDAY: "Sunday",
        MONDAY: "Monday",
        TUESDAY: "Tuesday",
        WEDNESDAY: "Wednesday",
        THURSDAY: "Thursday",
        FRIDAY: "Friday",
        SATURDAY: "Saturday",
      };
      $scope.isLoading = false;
      $scope.serviceCodesMap = DatabaseApi.serviceCodes();
      $scope.record = record;
      $scope.record.mergeableAuthorizations = $scope.record.mergeableAuthorizations.map(item => ({...item, serviceCodeName: $scope.serviceCodesMap.find(x => x.id === item.serviceCode).code}));
      
      $scope.tableParams = new NgTableParams(
        {},
        {
          counts: [],
          dataset: record.mergeableAuthorizations,
        }
      );

      $scope.createNewAuthorization = () => {
        $scope.isLoading = true;
        $scope.$resolve.onCreateAuth();
        $scope.isLoading = false;
      };

      $scope.ignore = () => {
        $scope.isLoading = true;
        $scope.$resolve.onIgnoreAuth();
        $scope.isLoading = false;
        $uibModalInstance.close();
      };

      $scope.finish = () => {
        $scope.isLoading = true;
        $scope.$resolve.onFinishAuth();
        $scope.isLoading = false;
        $uibModalInstance.close();
      };

      $scope.openViewRecordDetailsModal = () => {
        $scope.$resolve.onViewRecordDetails();
      }

      async function getAuthorizations() {
        const res = await DatabaseApi.get(
          "agencies/" +
            $rootScope.agencyId +
            "/agency_members/" +
            $rootScope.agencyMemberId +
            "/authorizations/" +
            record.mergeablePatients[0].patientId
        );
        try {
          $scope.tableParams.data[$scope.selectedRow] = res.data.authorizations.find(
            (auth) => auth.id === $scope.tableParams.data[$scope.selectedRow].id
          );
        } catch (e) {
          toaster.pop("error", "Something went wrong", "Could not get authorizations");
        } finally {
          $scope.isLoading = false;
        }
      }

      $scope.editAuthorization = async function (data, index) {
        if (!$rootScope.isPermittedByKey("edit_patient_page_authorizations")) return;
        $scope.isLoading = true;
        $scope.selectedRow = index;
        var newScope = $scope.$new();
        newScope.patientContracts = await PatientModalService.getPatientContractExtended(
          record.mergeablePatients[0].patientId
        );
        newScope.periodTypesOptions = $scope.periodTypesOptions;
        newScope.firstDayOfWeekOptions = $scope.firstDayOfWeekOptions;
        newScope.getAuthorizations = getAuthorizations;

        $uibModal.open({
          templateUrl: "admin/views/authorization-setup-modal.html",
          controller: "authorizationSetupModalCtrl",
          scope: newScope,
          size: "lg",
          resolve: {
            fields: function () {
              return DatabaseApi.formFields("Authorization");
            },
            data: function () {
              if (data) return data;
              return undefined;
            },
            serviceCodes: function () {
              return DatabaseApi.activeServiceCodes();
            },
            patientId: function () {
              return record.mergeablePatients[0].patientId;
            },
          },
        });
        $scope.isLoading = false;
      };

      $scope.parseFileHours = (fileHours) => {
        if (fileHours === null || fileHours === 0) {
          return fileHours;
        }

        const fileHoursString = fileHours.toString();
        
        let hours;

        if (fileHoursString.length === 3) {
          hours = fileHoursString.slice(0, 1);
        } else if (fileHoursString.length === 4) {
          hours = fileHoursString.slice(0, 2);
        }

        const minutes = fileHoursString.slice(-2);
        let result;

        if (minutes === '00') {
          result = hours;
        } else {
          if (minutes.slice(1, 2) !== '0') {
            result = `${hours}.${minutes}`;
          } else {
            result = `${hours}.${minutes.slice(0, 1)}`;
          }
        }
        
        return result;
      }
    }
  ;
