import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import Select from "../../../../shared/components/Select";
import useApi from "../../../../shared/hooks/useApi";
import { PatientId, TelephonyCallId } from "../../../../shared/schema/schema";
import { typedObjectEntries } from "../../../../shared/utils/ts-utils";

interface Props {
  disclosure: UseDisclosureReturn;
  callId: TelephonyCallId;
  patientId: PatientId;
}

const options = {
  Completed: "Patient Answered",
  NoAnswer: "Reached Voice Mail",
  CallGotDisconnected: "Call Got Disconnected",
} as const;

type Option = keyof typeof options;

const AfterCallCallTypeModal = (props: Props) => {
  const [value, setValue] = React.useState<Option>("Completed");

  const { api } = useApi();
  const toast = useToast();
  const submitUpdatedCallResult = useMutation({
    mutationFn: () => {
      if (value === "Completed") {
        return Promise.resolve();
      }

      return api.patch("./telephony/calls/:callId/result", {
        body: { result: value },
        path: { callId: props.callId },
      });
    },
    onSuccess: () => {
      props.disclosure.onClose();
      toast({
        status: "success",
        title: "Call Result Updated",
        description: "Thank you for your feedback!",
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const handleSubmit = () => {
    submitUpdatedCallResult.mutate();
  };

  return (
    <Modal {...props.disclosure} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent zIndex="afterCallModal">
        <ModalHeader>Please Select Result of The Call</ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={4}>
            To help us understand the status of the patient right now we need you to select the
            result:
            <Select
              allowUnselect={false}
              label="Call output"
              multiple={false}
              options={typedObjectEntries(options).map(([value, label]) => ({
                value,
                label,
              }))}
              value={value}
              onChange={(val) => setValue(val ?? "Completed")}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            isLoading={submitUpdatedCallResult.isPending}
            variant="solid"
            w="full"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AfterCallCallTypeModal;
