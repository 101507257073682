import { Instant } from "@js-joda/core";
import React from "react";
import { fmap } from "../utils";

export default function useCountdown(props: {
  endsAt: Instant | null;
  onTick: (seconds: number) => void;
}) {
  const [secondsState, setSecondsState] = React.useState<number | null>(
    calculateSecondsLeft(props.endsAt)
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      const seconds = calculateSecondsLeft(props.endsAt);

      setSecondsState(seconds);

      if (seconds !== null) {
        props.onTick.call(null, seconds);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [props.onTick, props.endsAt]);

  return secondsState;
}

function calculateSecondsLeft(endsAt: Instant | null) {
  return fmap(endsAt, (x) => Math.max(x.epochSecond() - Instant.now().epochSecond(), 0));
}
