import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";
import useApi from "./useApi";
import { Exact } from "../schema/gql/graphql";

type NoVariables = {
  [key: string]: never;
};

export default function useGraphQLQuery<
  TQueryFnData extends Record<string, unknown> = Record<string, unknown>,
  TVariables extends Record<string, unknown> = Record<string, unknown>,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: {
    document: TypedDocumentNode<TQueryFnData, Exact<TVariables>>;
    select?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>["select"];
  } & (TVariables extends NoVariables ? { variables?: undefined } : { variables: TVariables })
): UseQueryResult<TData, TError> {
  const { api } = useApi();
  const definition = options.document.definitions.at(0);

  if (definition?.kind !== "OperationDefinition") {
    throw new Error("Expected an operation definition");
  }

  if (definition.name === undefined) {
    throw new Error("Expected an operation definition with a name");
  }

  return useQuery<TQueryFnData, TError, TData>({
    queryKey: ["graphql", definition.name.value, options.variables],
    queryFn: async () => {
      return api.graphql(options.document, {
        variables: options.variables as Exact<TVariables> | undefined,
      });
    },
    select: options.select,
  });
}
