import { createIcon } from "@chakra-ui/react";

const RaiseHandIcon = createIcon({
  displayName: "RaiseHandIcon",
  viewBox: "0 0 20 20",
  path: (
    <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
      <path d="M11.5 6a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5M7 8.5a4.5 4.5 0 1 1 9 0a4.5 4.5 0 0 1-9 0" />
      <path d="M2 4a2.5 2.5 0 0 1 5 0v3.272c0 .883.31 1.737.874 2.415l1.394 1.673a1 1 0 1 1-1.536 1.28l-1.394-1.673A5.772 5.772 0 0 1 5 7.272V4a.5.5 0 0 0-1 0v3.75a4 4 0 0 0 .47 1.882l3.412 6.397a1 1 0 0 1-1.764.942l-3.412-6.398A6 6 0 0 1 2 7.75z" />
      <path d="M7.5 12a1 1 0 0 1 1-1H11a6 6 0 0 1 6 6v2a1 1 0 1 1-2 0v-2a4 4 0 0 0-4-4H8.5a1 1 0 0 1-1-1M7 15.5a1 1 0 0 1 1 1V19a1 1 0 1 1-2 0v-2.5a1 1 0 0 1 1-1" />
    </g>
  ),
});

export default RaiseHandIcon;
