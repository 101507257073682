import { createIcon } from "@chakra-ui/react";

const MarkEmailUnreadOutlineIcon = createIcon({
  displayName: "MarkEmailUnreadOutlineIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h10.1q-.1.5-.1 1t.1 1H4l8 5l3.65-2.275q.35.325.763.563t.862.412L12 13L4 8v10h16V9.9q.575-.125 1.075-.35T22 9v9q0 .825-.587 1.413T20 20zM4 6v12zm15 2q-1.25 0-2.125-.875T16 5q0-1.25.875-2.125T19 2q1.25 0 2.125.875T22 5q0 1.25-.875 2.125T19 8"
      fill="currentColor"
    />
  ),
});

export default MarkEmailUnreadOutlineIcon;
