import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { DropzoneOptions } from "react-dropzone";
import { S3Object } from "../hooks/useUploadFiles";
import { WorkflowDefinitionId, WorkflowTaskInstanceId } from "../schema/schema";
import UploadModal from "./UploadModal";

export type InputFileUploadDestination =
  | { by: "workflow-definition"; id: WorkflowDefinitionId }
  | { by: "workflow-task"; id: WorkflowTaskInstanceId }
  | { by: "email-attachment" };

type Props = DropzoneOptions & {
  files: S3Object[];
  fileUploadDestination: InputFileUploadDestination;
  onChange: (files: S3Object[]) => void;
};

export default function FileInput(props: Props) {
  const isMultiple = props.multiple ?? false;
  const maxFiles = isMultiple ? props.maxFiles : 1;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        alignItems="center"
        border="1px"
        borderColor="gray.200"
        borderRadius="md"
        gap={2}
        onClick={onOpen}
      >
        <Button p={3} variant="link">
          Choose File
        </Button>
        {props.files.length > 0 ? (
          <Text>
            {props.files.length} file{props.files.length > 1 ? "s" : ""} selected
          </Text>
        ) : (
          <Text>No file{isMultiple ? "s" : ""} selected</Text>
        )}
      </Flex>
      <UploadModal {...props} isOpen={isOpen} maxFiles={maxFiles} onClose={onClose} />
    </>
  );
}
