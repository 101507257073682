import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import ErrorBox from "../../../shared/components/ErrorBox";
import useApi from "../../../shared/hooks/useApi";
import { NoteFileId } from "../../../shared/schema/schema";
import DocumentViewer from "../../compliance/components/DocumentViewer";

type Props = {
  noteFileId: NoteFileId;
  disclosure: ReturnType<typeof useDisclosure>;
};

export default function NoteFileViewerModal({ noteFileId, disclosure }: Props) {
  const { queries } = useApi();
  const query = useQuery(queries.note.file(noteFileId));

  return (
    <Modal {...disclosure} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={4}>
          {(() => {
            switch (query.status) {
              case "pending":
                return (
                  <Center h="80vh">
                    <Spinner size="xl" />
                  </Center>
                );
              case "error":
                return <ErrorBox error={query.error} resetErrorBoundary={query.refetch} />;
              case "success":
                return (
                  <Box h="80vh">
                    <DocumentViewer src={query.data.fileUrl} onClose={disclosure.onClose} />
                  </Box>
                );
            }
          })()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
