//! @ngInject
export function billingVisitsWithIssuesCtrl($scope, $rootScope, NgTableParams, DatabaseApi, itemSearchPageManager, toaster, FilterUtils, $filter, Storage) {

    $scope.tableParams = null;
    $scope.globalFilter = { val: '' };
    $scope.offices = [];
    $scope.visitsInProgress = [];

    $scope.otherOptions = [{
        id: 1,
        name: "Include Billed Visits",
        searchParamName: "includeBilledVisits"
    }, {
        id: 2,
        name: "Exclude Visits With No Clock Times",
        searchParamName: "excludeNoClockTimes"
    }, {
        id: 3,
        name: "Exclude Unpaid Visits",
        searchParamName: "excludeUnPaidVisits"
    }, {
        id: 4,
        name: "Exclude Missed Visits",
        searchParamName: "excludeMissedVisits"
    }];

    $scope.otherOptionsSettings = {
        displayProp: "name",
        styleActive: true,
        scrollable: true,
        scrollableHeight: '300px',
        enableSearch: true
    };

    $scope.billingIssuesTemplatesExtraSettings = {
        styleActive: true,
        scrollable: true,
        displayProp: "name",
        scrollableHeight: '300px',
        enableSearch: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        showCheckAll: false,
        showUncheckAll: false,
    };

    $scope.otherOptionsSelected = [];
    $scope.showNonFilteredIssues = false;
    $scope.issuesCounters = {};

    function initialize() {
        initPageManager();
        getOffices();
        getAgencyIssuesSettings();
        $scope.pageManager.resetSearchParamsByUrl();
        getBillingIssuesTemplates();
        initTableColumns();
    }

    const loadFilters = () => {
        const storageFilters = FilterUtils.rootFilters.billingVisitsWithIssuesCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
            initValidatorsFilterFromStorage(storageFilters);
        } else if ($scope.selectedBillingIssuesTemplate.id) {
            handleChangeBillingIssuesTemplateSelection($scope.selectedBillingIssuesTemplate.id);
        }
    };

    const handleChangeBillingIssuesTemplateSelection = (billingIssuesTemplateId) => {
        const templateSettings = $scope.billingIssuesTemplates.find(billingIssuesTemplateSettings =>
            billingIssuesTemplateSettings.id === billingIssuesTemplateId
        );
        if (templateSettings !== undefined) {
            initValidatorsFromTemplate(templateSettings);
        }
    }

    $scope.$on("$destroy", function () {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingVisitsWithIssuesCtrl = angular.copy(filters);
    });

    // $scope.$on("refresh_visits", function () {
    //     $scope.searchVisitsWithIssues();
    // });

    $scope.billingPreventingIssue = function (contractTypeId, officeId, issue) {
        return $scope.issuesSettings[officeId][contractTypeId]['billing_' + issue];
    }

    $scope.payrollPreventingIssue = function (contractTypeId, officeId, issue) {
        return $scope.issuesSettings[officeId][contractTypeId]['payroll_' + issue];
    }

    const getAgencyIssuesSettings = async function () {
        $scope.issuesSettings = await DatabaseApi.agencyBillingIssues();
    }
    const getOffices = function () {
        $scope.offices = DatabaseApi.offices();
        if (!$scope.offices || $scope.offices.length === 0) {
            DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/offices`).then(function (res) {
                $scope.offices = res.data.offices;
                DatabaseApi.storeOffices(res.data.offices);
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not get offices");
            });
        }
    }

    var initPageManager = function () {
        $scope.selectedIssuesCategory = { billing: false, payroll: false };
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/visits_with_issues");
        $scope.validatorsDataManager = $scope.pageManager.getValidatorsDataManager();
        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
        $scope.teamDataManager = $scope.pageManager.getTeamDataManager();
        $scope.coordinatorDataManager = $scope.pageManager.getCoordinatorDataManager({onlyActive: true});
        $scope.certificationDataManager = $scope.pageManager.getCertificationDataManager();
        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initContractTypesParam();
        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initTeamParam();
        $scope.pageManager.initCoordinatorParam();
        $scope.pageManager.initCertificationParam();
        $scope.pageManager.initSearchParam("officeId", "");
        initOtherOptionSelections();
        $scope.pageManager.initValidatorsParam();
    };

    const initValidatorsFilterFromStorage = (storageFilters) => {
        const validatorsToDeselectMap = {};
        storageFilters.validators.value.forEach(validator => {
            if (validator.isSelected === false) {
                validatorsToDeselectMap[validator.id] = true;
            }
        });
        const validators = $scope.validatorsDataManager.getAllValidators();
        validators.forEach((validator) => {
            if (validator.isSelected && validatorsToDeselectMap[validator.id]) {
                $scope.validatorsDataManager.toggleSelection(validator);
            }
        });
        $scope.pageManager.updateSearchParamValue("validators", validators);
    }

    const getBillingIssuesTemplates = () => {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/billing_issues_templates"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId);
        DatabaseApi.get(url).then((res) => {
            $scope.billingIssuesTemplates = res.data.billingIssuesTemplates;
            $scope.selectedBillingIssuesTemplate = {};
            if ($scope.billingIssuesTemplates.length > 0) {
                $scope.selectedBillingIssuesTemplate = {
                    id: $scope.billingIssuesTemplates[0].id
                };
            }
        }).catch((err) => {
                $scope.billingIssuesTemplates = [];
                $scope.selectedBillingIssuesTemplate = {};
        }).finally (() => {
            loadFilters()
        })
    };

    const initValidatorsFromTemplate = (billingIssuesTemplate) => {
        const validators = $scope.validatorsDataManager.getAllValidators();
        validators.forEach((validator) => {
            const shouldBeSelected = billingIssuesTemplate.issues.includes(validator.id);
            if (validator.isSelected !== shouldBeSelected) {
                $scope.validatorsDataManager.toggleSelection(validator);
            }
        });
        $scope.pageManager.updateSearchParamValue("validators", validators);
    };

    $scope.billingIssuesTemplatesEvents = {
        onSelectionChanged: () => {
            handleChangeBillingIssuesTemplateSelection($scope.selectedBillingIssuesTemplate.id);
        },
    };

    $scope.filterByRelevantCategories = function (category) {
        var filter = {};
        if (category) {
            $scope.selectedIssuesCategory[category] = !$scope.selectedIssuesCategory[category];
        }

        if ($scope.selectedIssuesCategory['payroll'] && !$scope.selectedIssuesCategory['billing']) {
            if ($scope.showNonFilteredIssues) {
                Object.assign(filter, { hasNonFilteredPayrollIssues: true });
            } else {
                Object.assign(filter, { hasFilteredPayrollIssues: true });
            }
        }
        else if (!$scope.selectedIssuesCategory['payroll'] && $scope.selectedIssuesCategory['billing']) {
            if ($scope.showNonFilteredIssues) {
                Object.assign(filter, { hasNonFilteredBillingIssues: true });
            } else {
                Object.assign(filter, { hasFilteredBillingIssues: true });
            }
        }
        else if ($scope.selectedIssuesCategory['payroll'] && $scope.selectedIssuesCategory['billing']) {
            if ($scope.showNonFilteredIssues) {
                Object.assign(filter, { hasNonFilteredBillingOrPayrollIssues: true });
            } else {
                Object.assign(filter, { hasFilteredBillingOrPayrollIssues: true });
            }
        }

        if ($scope.tableParams) {
            $scope.tableParams.filter({});
            angular.extend($scope.tableParams.filter(), filter);
        }
    }

    $scope.areAllValidatorsSelected = function () {
        var allValidators = $scope.validatorsDataManager.getAllValidators();
        var selectedValidators = allValidators.filter(v => v.isSelected);
        return selectedValidators.length === allValidators.length;
    }

    $scope.toggleSelectAllValidators = function () {
        var selection = !$scope.areAllValidatorsSelected();
        $scope.validatorsDataManager.getAllValidators().forEach(function (validator) {
            if (validator.isSelected !== selection) {
                $scope.validatorsDataManager.toggleSelection(validator)
            }
        });
    }

    var mapItems = function (items) {
        if (!$scope.caregiversMap) {
            $scope.caregiversMap = DatabaseApi.caregivers() || {};
        }
        if (!$scope.patientsMap) {
            $scope.patientsMap = DatabaseApi.patients() || {};
        }
        const validatorsMap = {};
        $scope.validatorsDataManager.getAllValidators().forEach(validator => {
            validatorsMap["issue_" + validator.id] = { isSelected: validator.isSelected };
        })

        items.forEach(function (item) {
            var caregiver = $scope.caregiversMap[item.visit.caregiverId];
            item.visit.caregiver = caregiver;
            var patient = $scope.patientsMap[item.visit.patientId];
            item.visit.patient = patient;

            if (!item.contractTypeName && item.contractId) {
                var contractType = $scope.contractTypesDataManager.getContractTypeById(item.contractTypeId);
                item.contractTypeName = (contractType && contractType.name) || item.contractId;
            }

            item.displaySameDayProblemVisits = false;

            item.hasFilteredBillingIssues = Object.keys(item.issues).findIndex(issue =>
                $scope.billingPreventingIssue(item.visit.contractId, item.visit.officeId, issue)
                && (validatorsMap[issue].isSelected)
            ) !== -1;
            item.hasNonFilteredBillingIssues = Object.keys(item.issues).findIndex(issue =>
                $scope.billingPreventingIssue(item.visit.contractId, item.visit.officeId, issue)
            ) !== -1;

            item.hasFilteredPayrollIssues = Object.keys(item.issues).findIndex(issue =>
                $scope.payrollPreventingIssue(item.visit.contractId, item.visit.officeId, issue) &&
                (validatorsMap[issue].isSelected)
            ) !== -1;
            item.hasNonFilteredPayrollIssues = Object.keys(item.issues).findIndex(issue =>
                $scope.payrollPreventingIssue(item.visit.contractId, item.visit.officeId, issue)
            ) !== -1;

            item.hasFilteredBillingOrPayrollIssues = item.hasFilteredBillingIssues || item.hasFilteredPayrollIssues;
            item.hasNonFilteredBillingOrPayrollIssues = item.hasNonFilteredBillingIssues || item.hasNonFilteredPayrollIssues;

            Object.keys(item.issues).forEach(issue => {
                if ($scope.issuesCounters[issue]) {
                    $scope.issuesCounters[issue]++;
                } else {
                    $scope.issuesCounters[issue] = 1;
                }
            });
        });
    }

    $scope.showRowIssue = (row, issue) => {
        if (!$scope.showNonFilteredIssues && !$scope.isIssueSelected(issue)) {
            return false;
        }

        const isBillingIssue = $scope.billingPreventingIssue(row.visit.contractId, row.visit.officeId, issue);
        const isPayrollIssue = $scope.payrollPreventingIssue(row.visit.contractId, row.visit.officeId, issue);
        const filteredBilling = $scope.selectedIssuesCategory.billing;
        const filteredPayroll = $scope.selectedIssuesCategory.payroll;

        if (filteredBilling && filteredPayroll) {
            return isBillingIssue || isPayrollIssue;
        } else if (filteredBilling) {
            return isBillingIssue;
        } else if (filteredPayroll) {
            return isPayrollIssue;
        }

        return true;
    };

    var initTable = function (items) {
        var hasItems = items && items.length;
        if (hasItems) {
            mapItems(items);
            // toaster.pop("success", items.length + " Visits with issues were loaded successfully");
        }
        $scope.visitsWithIssues = items;

        var options = {
            count: 25
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    const initOtherOptionSelections = () => {
        $scope.otherOptions.forEach(option => {
            $scope.pageManager.initSearchParam(option.searchParamName, "false");
        });
    }

    const updateOtherOptionSelections = () => {
        $scope.otherOptions.forEach(option => {
            $scope.pageManager.updateSearchParamValue(option.searchParamName, "false");
        });
        $scope.otherOptionsSelected.forEach(({ id }) => {
            const option = $scope.otherOptions.find(x => x.id === id);
            if (option) {
                $scope.pageManager.updateSearchParamValue(option.searchParamName, "true");
            }
        })
    }

    $scope.searchVisitsWithIssues = function () {
        $scope.globalFilter.val = '';
        $scope.issuesCounters = {};
        
        updateOtherOptionSelections();
        $scope.pageManager.executeSearch().then(function (response) {
            initTable(response.data.visits);

            $scope.filterByRelevantCategories();
        }, function (error) {
            toaster.pop("error", "Failed to load visits with issues");
        });
    }

    $scope.hasIssues = function (visit) {
        return Object.keys(visit.issues).length > 0;
    }

    $scope.openSameDayVisitInstanceModal = function (sameDayVisitId, fatherVisitId) {
        if (!sameDayVisitId || !fatherVisitId) return;
        $scope.visitsInProgress.push(fatherVisitId);
        const visitModalInstance = $rootScope.openVisitInstanceModal(sameDayVisitId);
        visitModalInstance.result
            .then((result) => {
                if (result && result.type == "PROMISES_IN_PROGRESS") {
                    Promise.all(result.promises).finally(() => {
                        fetchRowFromServer(fatherVisitId);
                    });
                } else {
                    fetchRowFromServer(fatherVisitId);
                }
            })
            .catch((err) => {
                console.error(err);
                fetchRowFromServer(fatherVisitId);
            });
    }

    $scope.clickTableRow = function (row) {
        if (!row) return;
        if ($scope.checkVisitInProgress(row)) return;

        const rowId = row.visit.id;
        $scope.visitsInProgress.push(rowId);
        const visitModalInstance = $rootScope.openVisitInstanceModal(rowId);
        visitModalInstance.result
            .then((result) => {
                if (result && result.type == "PROMISES_IN_PROGRESS") {
                    Promise.all(result.promises).finally(() => {
                        fetchRowFromServer(rowId);
                    });
                } else {
                    fetchRowFromServer(rowId);
                }
            })
            .catch((err) => {
                console.error(err);
                fetchRowFromServer(rowId);
            });
    };

    const fetchRowFromServer = function (rowId) {
        DatabaseApi.get(
            `${$scope.pageManager.getFullUrl()}/${rowId}${$scope.pageManager.generateQueryStringParams()}`
        )
            .then((res) => {
                if (res.data.visits && res.data.visits.length > 0) {
                    const rowIndex = $scope.tableParams.data.findIndex(
                        ({ visit }) => visit.id === rowId
                    );
                    $scope.tableParams.data[rowIndex] = {
                        ...res.data.visits[0],
                    };
                    $scope.tableParams.data[rowIndex].visit.caregiver =
                        $scope.caregiversMap[res.data.visits[0].visit.caregiverId];

                    return;
                }

                const rowIndex = $scope.tableParams.data.findIndex(
                    ({ visit }) => visit.id === rowId
                );
                if (rowIndex === -1) return;

                $scope.tableParams.data.splice(rowIndex, 1);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                removeVisitInProgress(rowId);
            });
    }
    $scope.toggleProblemVisitsDisplay = function (row) {
        row.displaySameDayProblemVisits = !row.displaySameDayProblemVisits;
    }

    $scope.getRowDataById = function (id) {
        return $scope.visitsWithIssues.find(function (row) {
            return row.visit.id === id;
        });
    }

    $scope.applyGlobalSearch = function (term) {
        var filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
        }
    };

    $scope.getBillingIssueSettingTooltipMessage = function (contractId, officeId, issue) {
        return $scope.billingPreventingIssue(contractId, officeId, issue) ? "This issue prevents visit from being billed" : "This issue allows visit to be billed";
    };

    $scope.getPayrollIssueSettingTooltipMessage = function (contractId, officeId, issue) {
        return $scope.payrollPreventingIssue(contractId, officeId, issue) ? "This issue prevents visit from being processed for payroll" : "This issue allows visit to appear in payroll";
    };

    $scope.exportTable = function () {
        $scope.loadingCSV = true;
        const rows = [];
        const titles = [];
        for (const key in $scope.tableColumns) {
            if ($scope.tableColumns[key]) {
                titles.push(key);
            }
        }
        rows.push(titles);

        // Filter table data by global filter
        const tableData = $filter('filter')($scope.visitsWithIssues, $scope.globalFilter.val);
        tableData.forEach(dataRow => {
            const row = [];
            titles.forEach(function (title) {
                let toPush = "";
                if (title === "Visit ID") toPush = dataRow.visit.id || '';
                else if (title === "Patient") toPush = dataRow.visit.patientName || '';
                else if (title === "Patient SSN") toPush = dataRow.visit.patientSSN || '';
                else if (title === "Patient Medicare Number") toPush = dataRow.visit.patientMedicareNumber || '';
                else if (title === "Patient Medicaid Number") toPush = dataRow.visit.patientMedicaidNumber || '';
                else if (title === "Patient Address") toPush = dataRow.visit.patientAddress ? '"' + dataRow.visit.patientAddress.replace('#', '') + '"' : '" "';
                else if (title === "Patient Gender") toPush = dataRow.visit.patientGender || '';
                else if (title === "Patient Office") toPush = dataRow.visit.officeName || '';
                else if (title === "Caregiver") toPush = dataRow.visit.caregiver ? dataRow.visit.caregiver.displayName : '';
                else if (title === "Certification") toPush = dataRow.serviceCodeCertification || '';
                else if (title === "Caregiver Code") toPush = dataRow.visit.caregiver ? dataRow.visit.caregiver.caregiverCode : '';
                else if (title === "Assigned Coordinator") toPush = dataRow.visit.patientAssignedCoordinator || '';
                else if (title === "Patient Agency Team") toPush = dataRow.visit.patientAgencyTeam || '';
                else if (title === "Date") toPush = $filter("dateStringToLocaleDate")(dataRow.visit.startTime) || '';
                else if (title === "Schedule time") toPush = $filter("hourMinutesRange")(dataRow.visit, 'startTime', 'endTime');
                else if (title === "Recorded time") toPush = $filter("hourMinutesRange")(dataRow.visit, 'clockinTime', 'clockoutTime');
                else if (title === "Contract") toPush = dataRow.visit.contractName || '';
                else if (title === "Current Total") toPush = $filter("centsToDollars")(dataRow.billingTotal) || 0;
                else if (title === "Potential Total") toPush = $filter("centsToDollars")(dataRow.potentialBillingTotal) || 0;
                else if (title === "Days For Timely Filing") toPush = dataRow.visit.timelyFilingLimitDaysLeft || '';
                else if (title === "Patient Medflyt ID") toPush = dataRow.visit.patientId || '';
                else if (title === "Patient HHA Exchange ID") toPush = dataRow.visit.patientExternalId || '';
                else if (title === "Patient Admission ID") toPush = dataRow.visit.patientAdmissionId || '';
                else if (title === "Issues") toPush = "\"" + Object.values(dataRow.issues).join(', \n') + "\"";
                row.push(toPush);
            });
            rows.push(row);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    var getExportedFileName = function () {
        var filename = "medflyt-visits-with-issues"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    $scope.checkVisitInProgress = function ({ visit }) {
        if (!visit || !visit.id) return false;

        return $scope.visitsInProgress.includes(visit.id);
    }

    function removeVisitInProgress(visitId) {
        if (!visitId) return;

        $scope.visitsInProgress = $scope.visitsInProgress.filter(id => id !== visitId);
    }

    $scope.isIssueSelected = (issue) => {
        return $scope.validatorsDataManager.getSelectedValidators()
            .find(x => "issue_" + x.id === issue && x.isSelected) !== undefined;
    };

    $scope.toggleShowNonFilteredIssues = () => {
        $scope.showNonFilteredIssues = !$scope.showNonFilteredIssues;
        $scope.filterByRelevantCategories();
    };

    $scope.getIssueCounterSuffix = (issue) => {
        const issueKey = "issue_" + issue;
        if (!$scope.issuesCounters[issueKey]) {
            return "";
        }
        return ` (${$scope.issuesCounters[issueKey]})`;
    };

    function initTableColumns() {
        const localStorageColumns = Storage.getObject("visitsWithIssuesTableSettings");

        $scope.tableColumns = {
            "Visit ID": false,
            "Patient": true,
            "Patient SSN": false,
            "Patient Medicare Number": false,
            "Patient Medicaid Number": false,
            "Patient Address": false,
            "Patient Gender": false,
            "Patient Office": true,
            "Caregiver": true,
            "Caregiver Code": false,
            "Certification": false,
            "Assigned Coordinator": true,
            "Patient Agency Team": true,
            "Date": true,
            "Schedule time": true,
            "Recorded time": true,
            "Contract": true,
            "Current Total": true,
            "Potential Total": true,
            "Days For Timely Filing": true,
            "Issues": true,
            "Patient Medflyt ID": false,
            "Patient HHA Exchange ID": false,
            "Patient Admission ID": false,
        };

        if (localStorageColumns && Object.keys(localStorageColumns).length > 0) {
            Object.keys(localStorageColumns).forEach((col) => {
                $scope.tableColumns[col] = localStorageColumns[col];
            })

        }

        $scope.$watch(
            "tableColumns",
            function () {
                if ($scope.tableColumns) {
                    Storage.setObject("visitsWithIssuesTableSettings", $scope.tableColumns);
                }
            },
            true
        );
    }

    initialize();
};