import { LocalDate, nativeJs } from "@js-joda/core";
import moment from "moment";

//! @ngInject
export function passportVerifierCtrl($scope, $rootScope, itemSearchPageManager, NgTableParams, DatabaseApi, toaster, $filter, $uibModal) {
    $scope.tableParams = null;
    $scope.reviewedAtCounter = 0;

    $scope.docStatuses = [
        { id: 1, value: "APPROVED", label: "Approved" },
        { id: 2, value: "DECLINED", label: "Declined" },
        { id: 3, value: "PENDING", label: "Pending" }
    ];

    $scope.documentTypes = [];

    function initialize() {
        initPageManager();
        $scope.loadItems();
    }

    function getDocStatusesByName(items) {
        const statusIds = items.map(item => item.id);
        const statuses = $scope.docStatuses.filter(item => statusIds.includes(item.id));
        return $filter("pipeItems")(statuses, "value");
    }

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/passport_documents_verification");
        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initSearchParamDeprecated("status", [], "status", getDocStatusesByName);
    };

    $scope.globalFilter = { val: "" };
    $scope.applyGlobalSearch = function (term) {
        var filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
        } else {
            console.log('no table');
        }
    };

    var initTable = function (items) {
        items.forEach(function (item) {
            if (item.expiryDate !== null) {
                var d = LocalDate.parse(item.expiryDate);
                item.expiryDate = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
            }
        });

        var options = {
            count: 25,
            sorting: { createdAt: "desc" }
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });

        if ($scope.globalFilter.val) {
            $scope.applyGlobalSearch($scope.globalFilter.val);
        }
    };

    $scope.onExpiryDatePickerChange = function (item) {
        var body = {
            expiryDate: LocalDate.from(nativeJs(moment(item.expiryDate))).toString()
        }

        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
        + '/caregivers/' + item.caregiver + '/passport_documents/' + item.id, body).then(function (res) {
            toaster.pop('success', "Expiry date updated successfully");
        }, function (err) {
            toaster.pop('error', "Failed to update document expiry date");
        });
    }

    $scope.verifyDocument = function (row, status) {
        var body = {
            status: status
        }

        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
            + '/passport_documents/' + row.id + '/update_status', body).then(function (res) {
                row.status = status;
                toaster.pop('success', "Document status updated successfully");
            }, function (err) {
                toaster.pop('error', "Failed to update document status");
            });
    }

    $scope.viewPassportDocument = function (document) {
        var newScope = $scope.$new();
        newScope.document = document;
        newScope.url = 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
        + '/passport_documents/' + document.id + '/file_url';

        $uibModal.open({
            templateUrl: 'admin/views/image-viewer-modal.html',
            size: 'lg',
            controller: 'imageViewerModalCtrl',
            scope: newScope
        });
    }

    $scope.loadItems = function () {
        $scope.pageManager.executeSearch().then(function (response) {
            initTable(response.data.documents);
        }, function (error) {
            toaster.pop("error", "Failed to fetch passport documents");
        });

        const getDocumentTypesUrl = 'agencies/' + $rootScope.agencyId +
            '/agency_members/' + $rootScope.agencyMemberId +
            '/passport_documents/types';
        DatabaseApi.get(getDocumentTypesUrl).then(res => {
            $scope.documentTypes = res.data.documentTypes;
        });
    };

    $scope.incrementReviewedAt = function() {
        $scope.reviewedAtCounter ++;
    }

    $scope.getDocumentTypeById = (documentTypeId) => {
        return $scope.documentTypes.find((documentType) => documentType.id === documentTypeId);
    }

    $scope.updateDocumentTypeId = (row) => {
        const updateDocTypeIdUrl = 'agencies/' + $rootScope.agencyId +
            '/agency_members/' + $rootScope.agencyMemberId +
            '/passport_documents/' + row.id +
            '/update_doc_type';
        const body = {
            documentTypeId: row.docType.id
        };
        DatabaseApi.put(updateDocTypeIdUrl, body).then((res) => {
            row.docType.name = $scope.getDocumentTypeById(row.docType.id).documentName;
            toaster.pop('success', 'Document type updated successfully');
        }, (err) => {
            toaster.pop('error', 'Oops! Something went wrong', 'Failed updating document type');
        });
    }

    initialize();
};
