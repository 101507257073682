import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import { EligibilityCheck, EligibilityChecks, PatientEligibiltyStatus, PatientEligibiltyStatusResponse } from "../messages/eligibility_check";
import { OfficeId, PatientId } from "../messages/ids";
import { PatientStatus } from "../messages/patient";
import { Api } from "./Api";
import { ContractTypeService } from "./contractTypeService";
import { DatabaseApiService } from "./db";
import { Endpoint } from "./endpoint.service";
import { OfficesService } from "./officesService";
export interface EnrichedEligibiltyCheck extends EligibilityCheck{
    patientOfficeId: OfficeId | null; // nullable because of weird voodoo
    patientStatus: PatientStatus;
    patientId: PatientId;
    patientDisplayId: number | null;
    patientDisplayName: string;
    patientActiveContractsNames: string[] | null;
    patientOfficeName: string;
    createdByName:string | null;
    responsePayerName: string | null;
    responseMessage: string | null;
}

//! @ngInject
export class EligibilityChecksService{
    constructor(
        private $rootScope: ng.IRootScopeService,
        private api: Api,
        private endpoint: Endpoint,
        private DatabaseApi: DatabaseApiService,
        private officesService: OfficesService,
        private contractTypeService: ContractTypeService) {
        }

    getEligibilityStatusText = (isEligible: boolean | null): string => {
      return  isEligible === null ? 'ERROR' : (isEligible ? 'ELIGIBLE' : 'NOT ELIGIBLE');
    }

    getEligibilityStatusColor = (isEligible: boolean | null): string => {
      return  isEligible ? 'lightblue' : 'red';
    };

    async runEligibilityCheck (patientId: PatientId): Promise<PatientEligibiltyStatus | null> {
      const url = this.endpoint({
        path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/check_eligibility",
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          patientId: patientId
        },
      });

      const result = await this.api.post<PatientEligibiltyStatusResponse>(url);
      return result.data.status;
    }

    async getEligibiltyChecks (runDate?: LocalDate) : Promise<EnrichedEligibiltyCheck[]> {
        const format = DateTimeFormatter.ofPattern("yyyy-MM-dd");
        const queryParams = new URLSearchParams(
            runDate === undefined ? {} : {runDate: runDate.format(format)}
        );

        const queryParamsString = queryParams.toString();
        const url = this.endpoint({
          path: `agencies/:agencyId/agency_members/:agencyMemberId/eligibility_checks?${queryParamsString}`,
          params: {
            agencyId: this.$rootScope.agencyId,
            agencyMemberId: this.$rootScope.agencyMemberId,
          },
        });
        const agencyMembers = this.DatabaseApi.getAgencyMembers();
        const patientsMap = this.DatabaseApi.patients();
        const contractTypes = await this.contractTypeService.getContractTypes({onlyActives: false, refetch: false});
        const offices = await this.officesService.getOffices({onlyActives:false, refetch: false});
        const res = await this.api.get<EligibilityChecks>(url);

        // This is a temp hack, need to create patients service instead of using db.js
        if ((patientsMap as any)["$$state"] === undefined){
        return res.data.records.map(record => {
          const patientActiveContractsNames = contractTypes
          .filter(contract => record.activeContractTypes !== null && record.activeContractTypes.includes(contract.id))
          .map(contract => contract.name);
          const patientOfficeId = patientsMap[record.patientId as unknown as string].currentOfficeId;
          const createdByName = record.createdBy === null ? 'System' : agencyMembers[record.createdBy.toString()];
          const patientOffice = offices.find(office => office.id === patientOfficeId);
          const patientTeamId = patientsMap[record.patientId as unknown as string].agencyTeamId;
          const patientId = patientsMap[record.patientId as unknown as string].id;
          const patientDisplayName = patientsMap[record.patientId as unknown as string].displayName;
          const patientDisplayId = patientsMap[record.patientId as unknown as string].displayId;
          const patientStatus = patientsMap[record.patientId as unknown as string].status;
          const patientAssignedCoordinator = patientsMap[record.patientId as unknown as string].assignedCoordinator;
          const responseMessage = record.vendorResponse === null ? null : JSON.stringify(Object.fromEntries(Object.entries(record.vendorResponse as any[]).filter(([, v]) => v != null && v !== '')), null, "\n").replace(/[[\]{}"]+/g, '')
          record.message = record.message !== null ? record.message : (record.isEligibleOnDifferentPayer ?  'Patient is eligibile, but not for any of the active contracts' : null);
            return {...record,
              patientTeamId,
              patientId,
              patientDisplayName,
              patientDisplayId,
              patientOfficeId,
              patientStatus,
              patientAssignedCoordinator,
              patientActiveContractsNames,
              responsePayerName: record.responsePayerName,
              createdByName,
              responseMessage,
              patientOfficeName: patientOffice === undefined ? '' : patientOffice.name
          }
        });
      }
      return [];
    }
}