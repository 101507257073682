import { Flex, Tag, UnorderedList, ListItem, Text } from "@chakra-ui/react";
import MobileVoiceIcon from "../../../../shared/icons/MobileVoiceIcon";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { toTitleCase } from "../../../../utils";
import { Messages } from "../../../../core/api";
import AudioPlayer from "../../../../shared/components/AudioPlayer/AudioPlayer";
import { CommunicationLogVerticalDivider } from "./CommunicationLog";

interface Props {
  robocall: Messages["RobocallLogData"];
}

const CommunicationLogRobocallRow = (props: Props) => {
  return (
    <Flex direction="column" gap={2}>
      <Flex alignItems="center" gap={2}>
        <Tag colorScheme="gray">Robocall</Tag>
        <Tag>{dateFormatter.toDateTime(props.robocall.createdAt)}</Tag>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <MobileVoiceIcon />
        <Text>{toTitleCase(props.robocall.status)}</Text>
        <CommunicationLogVerticalDivider />
        <Text>
          {props.robocall.customCallName
            ? props.robocall.customCallName
            : toTitleCase(props.robocall.type)}
        </Text>
      </Flex>
      <Flex direction="column" gap={2}>
        {props.robocall.data.map((d) => {
          return (
            <UnorderedList key={d.createdAt.toString()}>
              <ListItem>
                <Flex alignItems="center" gap={2}>
                  <Text>{toTitleCase(d.eventType)}</Text>
                  {d.digitPressed && <Tag colorScheme="blue">{d.digitPressed}</Tag>}
                  <Text colorScheme="gray">{dateFormatter.toDateTime(d.createdAt)}</Text>
                </Flex>
              </ListItem>
            </UnorderedList>
          );
        })}
      </Flex>
      {props.robocall.callSignedUrl && (
        <AudioPlayer ratesOptions={[1, 1.25, 1.5, 2]} src={props.robocall.callSignedUrl} />
      )}
    </Flex>
  );
};

export default CommunicationLogRobocallRow;
