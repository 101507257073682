import React from "react";

import { ArrowDownIcon, ArrowUpIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Messages } from "../../../core/api";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { sortingFns } from "../../../shared/utils/tanstack-table";
import { Instant, LocalDate, LocalDateTime, ZoneId } from "@js-joda/core";
import { CaregiverId, CaregiverSuppliesShipmentId } from "../../../shared/schema/schema";
import { CaregiverEntity, EntityCardLink } from "../../../shared/components/EntityCard";
import ConfirmIcon from "../../../shared/icons/ConfirmIcon";
import XMark20SolidIcon from "../../../shared/icons/XMark20SolidIcon";

interface CaregiverSuppliesShipmentRow {
  rowId: CaregiverSuppliesShipmentId;
  type: Messages["CaregiverSuppliesShipmentType"];
  shipmentDate: Instant | null;
  arrivalDate: Instant | null;
  caregiver: CaregiverEntity;
  scrubsSize: Messages["CaregiverSuppliesShipmentRow"]["scrubsSize"];
  glovesSize: Messages["CaregiverSuppliesShipmentRow"]["glovesSize"];
  caregiverConfirmation: boolean;
  caregiverHireDate: LocalDate | null;
  editRow: boolean;
}

export type SuppliesTableRowEditableData = {
  arrivalDate: LocalDateTime | null;
  shipmentDate: LocalDateTime | null;
  caregiverName: string;
  caregiverId: CaregiverId;
  glovesSize: Messages["CaregiverSuppliesShipmentRow"]["glovesSize"] | null;
  scrubsSize: Messages["CaregiverSuppliesShipmentRow"]["scrubsSize"] | null;
};

function toDataRow(
  shipmentRow: Messages["CaregiverSuppliesShipmentRow"]
): CaregiverSuppliesShipmentRow {
  return {
    rowId: shipmentRow.id,
    type: shipmentRow.shipmentType,
    shipmentDate: shipmentRow.shipmentDate,
    arrivalDate: shipmentRow.arrivalDate,
    caregiverConfirmation: shipmentRow.caregiverConfirmation,
    caregiverHireDate: shipmentRow.caregiverHireDate,
    glovesSize: shipmentRow.glovesSize,
    scrubsSize: shipmentRow.scrubsSize,
    caregiver: {
      type: "Caregiver",
      id: shipmentRow.caregiverId,
      displayId: shipmentRow.caregiverDisplayId,
      fullName: shipmentRow.caregiverFullName,
      photoUrl: shipmentRow.caregiverPhotoUrl,
      status: shipmentRow.caregiverStatus,
    },
    editRow: true,
  };
}

const paginationPageSizes = [10, 20, 30, 40, 50];

export function CaregiverSuppliesShipmentsTable(props: {
  shipments: Messages["CaregiverSuppliesShipmentRow"][];
  onClickEditRow: (
    rowId: CaregiverSuppliesShipmentId,
    rowData: SuppliesTableRowEditableData
  ) => void;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([{ id: "shipmentDate", desc: false }]);

  const data = React.useMemo(() => props.shipments.map(toDataRow), [props.shipments]);

  const columnHelper = createColumnHelper<CaregiverSuppliesShipmentRow>();
  const columns = [
    columnHelper.accessor("caregiver", {
      cell: (info) => <EntityCardLink entity={info.getValue()} />,
      header: () => <span>Entity</span>,
    }),
    columnHelper.accessor("type", {
      cell: (info) => <Center>{info.getValue()}</Center>,
      header: () => <span>Type</span>,
    }),
    columnHelper.accessor("shipmentDate", {
      sortingFn: "instant",
      cell: (info) => {
        const date = info.getValue();
        return (
          <Center>
            <Text>
              {" "}
              {date ? dateFormatter.toDateTime(date, { timezone: "America/New_York" }) : "Not Set"}
            </Text>
          </Center>
        );
      },
      header: () => <span>Shipment Date</span>,
    }),
    columnHelper.accessor("arrivalDate", {
      sortingFn: "instant",
      cell: (info) => {
        const date = info.getValue();
        return (
          <Center>
            <Text>
              {" "}
              {date ? dateFormatter.toDateTime(date, { timezone: "America/New_York" }) : "Not Set"}
            </Text>
          </Center>
        );
      },
      header: () => <span>Arrival Date</span>,
    }),
    columnHelper.accessor("caregiverHireDate", {
      sortingFn: "instant",
      cell: (info) => {
        const date = info.getValue();
        return (
          <Center>
            <Text> {date ? dateFormatter.toDate(date) : "Not Set"}</Text>
          </Center>
        );
      },
      header: () => <span>Hire Date</span>,
    }),
    columnHelper.accessor("scrubsSize", {
      cell: (info) => <Center>{info.getValue() ?? ""}</Center>,
      header: () => <span>Scrubs Size</span>,
    }),
    columnHelper.accessor("glovesSize", {
      cell: (info) => <Center>{info.getValue() ?? ""}</Center>,
      header: () => <span>Gloves Size</span>,
    }),
    columnHelper.accessor("caregiverConfirmation", {
      cell: (info) => (
        <Center>
          {info.getValue() ? (
            <ConfirmIcon boxSize={5} color="var(--chakra-colors-green-400)" />
          ) : (
            <XMark20SolidIcon boxSize={5} color="var(--chakra-colors-red-500)" />
          )}
        </Center>
      ),
      header: () => <span>Caregievr Confirmed Delivery </span>,
    }),
    columnHelper.accessor("editRow", {
      cell: (info) => (
        <Center>
          <Button
            colorScheme="blue"
            onClick={() =>
              props.onClickEditRow(info.row.original.rowId, {
                caregiverName: info.row.original.caregiver.fullName,
                caregiverId: info.row.original.caregiver.id,
                arrivalDate: info.row.original.arrivalDate
                  ? LocalDateTime.ofInstant(
                      info.row.original.arrivalDate,
                      ZoneId.of("America/New_York")
                    )
                  : null,
                shipmentDate: info.row.original.shipmentDate
                  ? LocalDateTime.ofInstant(
                      info.row.original.shipmentDate,
                      ZoneId.of("America/New_York")
                    )
                  : null,
                glovesSize: info.row.original.glovesSize,
                scrubsSize: info.row.original.scrubsSize,
              })
            }
          >
            <EditIcon />
          </Button>
        </Center>
      ),
      header: () => <span>Edit Row</span>,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    sortingFns: sortingFns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const createHeaderFromColumn = (header: Header<CaregiverSuppliesShipmentRow, any>) => {
    return (
      <Th
        key={header.id}
        _hover={{ bg: "gray.100" }}
        borderTopRadius="md"
        colSpan={header.colSpan}
        cursor="pointer"
        transition="100ms ease-in-out"
        onClick={header.column.getToggleSortingHandler()}
      >
        {header.isPlaceholder ? null : (
          <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
            <Flex alignItems="center" justifyContent="center">
              {flexRender(header.column.columnDef.header, header.getContext())}
              {{
                asc: <ArrowUpIcon />,
                desc: <ArrowDownIcon />,
              }[header.column.getIsSorted() as string] ?? null}
            </Flex>
          </div>
        )}
      </Th>
    );
  };

  const createTableRow = (row: Row<CaregiverSuppliesShipmentRow>) => {
    return (
      <Tr key={row.id} _hover={{ bg: "gray.50" }} borderBottomColor="blue.100" fontWeight={600}>
        {row.getVisibleCells().map((cell) => {
          return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
        })}
      </Tr>
    );
  };

  return (
    <Stack spacing={1}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return createHeaderFromColumn(header);
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>{table.getRowModel().rows.map((row) => createTableRow(row))}</Tbody>
        </Table>
      </TableContainer>
      <Flex alignItems="center" dir="ltr" direction="row" gap={8}>
        <Stack alignItems="center" dir="ltr" direction="row" spacing={1}>
          <Button disabled={!table.getCanPreviousPage()} onClick={() => table.setPageIndex(0)}>
            «
          </Button>
          <Button disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}>
            ‹
          </Button>
          <Button disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>
            ›
          </Button>
          <Button
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          >
            »
          </Button>
        </Stack>
        <Stack alignItems="center" direction="row">
          <Text fontSize="md">Page</Text>
          <Stack alignItems="center" direction="row" w="150px">
            <Text as="b" fontSize="md">
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </Text>
            <Text>({table.getRowModel().rows.length} Rows)</Text>
          </Stack>
        </Stack>
        <Stack alignItems="center" direction="row">
          <Text fontSize="md" w="100px">
            Go to page:
          </Text>
          <Input
            defaultValue={table.getState().pagination.pageIndex + 1}
            type="number"
            w="60px"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Stack>
        <Menu>
          <MenuButton as={Button}>Show {table.getState().pagination.pageSize}</MenuButton>
          <MenuList>
            {paginationPageSizes.map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize} onClick={() => table.setPageSize(pageSize)}>
                Show {pageSize}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  );
}

export default CaregiverSuppliesShipmentsTable;
