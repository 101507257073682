import { useDisclosure } from "@chakra-ui/react";
import Page from "../../../../shared/components/Page";
import VisitAssignmentsTable from "./components/VisitAssignmentsTable";
import { useQueryClient } from "@tanstack/react-query";
import { GET_VISIT_ASSIGNMENTS_QUERY } from "./visit-assignment.graphql";
import NewVisitAssignmentModal from "./components/NewVisitAssignmentModal/NewVisitAssignmentModal";
import { useRouter } from "@uirouter/react";

export default function VisitAssignmentsPage() {
  const newVisitAssignmentModal = useDisclosure();
  const queryClient = useQueryClient();
  const { stateService } = useRouter();

  return (
    <Page>
      <Page.Header>
        <Page.Title>Visit Assignments</Page.Title>
      </Page.Header>

      <VisitAssignmentsTable openNewVisitAssignmentModal={newVisitAssignmentModal.onOpen} />

      <NewVisitAssignmentModal
        isOpen={newVisitAssignmentModal.isOpen}
        onAdd={(id) => {
          queryClient.invalidateQueries({ queryKey: [GET_VISIT_ASSIGNMENTS_QUERY] });

          stateService.go("app.visitAssignments.assignment", {
            visitAssignmentId: id,
          });
        }}
        onClose={newVisitAssignmentModal.onClose}
      />
    </Page>
  );
}
