import React from "react";

export function useSelection<T>(initialState?: T[] | (() => T[])) {
  const [state, setStates] = React.useState<Set<T>>(
    typeof initialState === "function" ? new Set(initialState()) : new Set(initialState)
  );

  const add = React.useCallback((item: T) => {
    setStates((prev) => new Set(prev).add(item));
  }, []);

  const remove = React.useCallback((item: T) => {
    setStates((prev) => {
      const newSet = new Set(prev);
      newSet.delete(item);
      return newSet;
    });
  }, []);

  const toggle = React.useCallback((item: T) => {
    setStates((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(item)) {
        newSet.delete(item);
      } else {
        newSet.add(item);
      }
      return newSet;
    });
  }, []);

  const clear = React.useCallback(() => {
    setStates(new Set());
  }, []);

  const isSelected = React.useCallback((item: T) => state.has(item), [state]);

  const selection = React.useMemo(() => Array.from(state), [state]);

  return { state: selection, add, remove, toggle, clear, isSelected };
}
