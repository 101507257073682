import { z } from "zod";
import { Messages } from "../../../../core/api";
import {
  IntakeStatusId,
  PatientContactId,
  PatientId,
  PatientIntakePlanId,
  components,
} from "../../../../shared/schema/schema";
import { EditPatientIntake, PatientIntakeProfileDraft } from "../../patient-intake.types";
import { isDefined } from "../../../../utils";
import { PhoneNumberInfo } from "../../../workflow/components/HumanTaskForm/layouts/HumanTaskCallIntakeLayout";
import { IntakeDataRow } from "../../components/IntakeDashboardTable";
import { fmap } from "../../../../shared/utils";

export const PATIENT_INTAKE_CONTACT_KEY_PREFIX = "intake-contact";
export const PATIENT_INTAKE_PLAN_KEY_PREFIX = "intake-plan";

export const phoneNumberSchema = z.union([
  z.string().length(0, { message: "Patient phone number must be empty or of length 10" }),
  z.string().min(10, { message: "Patient phone number must be empty or of length 10" }),
]);

export const optionalPhoneNumberSchema = z
  .union([
    z.string().length(0, { message: "Patient phone number must be empty or of length 10" }),
    z.string().min(10, { message: "Patient phone number must be empty or of length 10" }),
  ])
  .optional();

export function isIntakePatientSmsable<
  T extends {
    phoneNumbers: Messages["IntakePatientProfile"]["phoneNumbers"];
    contacts: Messages["IntakePatientProfile"]["contacts"] | null;
  }
>(patient: T): boolean {
  return (
    patient.phoneNumbers?.some((x) => x.isSmsable) === true ||
    patient.contacts?.some((x) => x.mobilePhoneNumberSmsable) === true
  );
}

export function isLostPatientIntakeStatus(intakeStatus: Messages["IntakeStatus"] | null) {
  if (intakeStatus === null) {
    return false;
  }

  return intakeStatus.parentId === IntakeStatusId.parse(3);
}

export function getOrderedCallForPatient(
  orderedCalls: { [intakeTeamName: string]: Messages["IntakeCallOrder"][] },
  intakePatient: IntakeDataRow
) {
  for (const key of Object.keys(orderedCalls)) {
    const keyName = `Team-${key}` as const;
    const patientCallByIntakeName = intakePatient[keyName];
    if (patientCallByIntakeName) {
      return patientCallByIntakeName;
    }
  }
  return null;
}

export function getCallButtonText(
  callData: components["schemas"]["IntakeCallWithDisplayData"] | null
) {
  switch (callData?.boost?.type) {
    case "Email":
      return "Replay Email";
    case "SMS":
      return "Replay SMS";
    default:
      return "Call";
  }
}

export function getPatientPhoneNumbers(patient: Messages["IntakePatientParallelCall"]) {
  const map = new Map<string, PhoneNumberInfo>();

  for (const phoneNumber of patient.phoneNumbers) {
    map.set(phoneNumber.phonenumber, {
      type: phoneNumber.type,
      contact: {
        type: "patient",
        fullName: `${patient.patientFirstName} ${patient.patientLastName}`,
        firstName: patient.patientFirstName,
        lastName: patient.patientLastName,
      },
    });
  }

  for (const contact of patient.patientContacts ?? []) {
    const data = [
      {
        type: "MOBILE" as const,
        number: contact.mobilePhoneNumber,
        contact,
      },
      {
        type: "TEL" as const,
        number: contact.homePhoneNumber,
        contact,
      },
    ].filter(isDefined);

    for (const { contact, type, number } of data) {
      if (number === null || number === "") continue;

      map.set(number, {
        type,
        contact: {
          type: "relative",
          fullName: `${contact.firstName} ${contact.lastName}`,
          relationship: contact.relationship,
          firstName: contact.firstName,
          lastName: contact.lastName,
        },
      });
    }
  }

  return map;
}

function mergePhoneNumbers(
  originalPhoneNumbers: Messages["IntakePatientProfile"]["phoneNumbers"],
  draftPhoneNumbers: EditPatientIntake["phoneNumbers"],
  didPhoneNumberChange: boolean
): PatientIntakeProfileDraft["phoneNumbers"] {
  const updatedPhoneNumber = draftPhoneNumbers?.at(0);
  const originalPhoneNumber = originalPhoneNumbers?.at(0);

  if (updatedPhoneNumber === undefined && originalPhoneNumber !== undefined) {
    // This means the user has deleted the phone number
    if (didPhoneNumberChange) {
      return [];
    }
    return [
      {
        ...originalPhoneNumber,
      },
    ];
  }

  if (updatedPhoneNumber !== undefined && originalPhoneNumber === undefined) {
    return [
      {
        ...updatedPhoneNumber,
        isSmsable: false,
        isForPortalLogin: false,
      },
    ];
  }

  if (updatedPhoneNumber !== undefined && originalPhoneNumber !== undefined) {
    return [
      {
        ...originalPhoneNumber,
        ...updatedPhoneNumber,
        phonenumber:
          originalPhoneNumber.phonenumber !== updatedPhoneNumber.phonenumber
            ? updatedPhoneNumber.phonenumber
            : originalPhoneNumber.phonenumber,
      },
    ];
  }

  return [];
}

function mergeContacts(
  patientId: PatientId,
  originalContacts: Messages["IntakePatientProfile"]["contacts"],
  draftContacts: EditPatientIntake["contacts"] | null,
  didContractsChange: boolean
): PatientIntakeProfileDraft["contacts"] {
  if (!didContractsChange) {
    return (
      originalContacts?.map((c) => ({
        ...c,
        key: `${PATIENT_INTAKE_CONTACT_KEY_PREFIX}-${c.mobilePhoneNumber}`,
      })) ?? []
    );
  }

  const mappedDraftContacts =
    draftContacts?.map((c) => ({
      ...c,
      key: `${PATIENT_INTAKE_CONTACT_KEY_PREFIX}-new-${c.mobilePhoneNumber}`,
      address: c.address ?? null,
      address2: c.address2 ?? null,
      addressComponents: c.addressComponents ?? null,
      email: c.email ?? null,
      homePhoneNumber: c.homePhoneNumber ?? null,
      mobilePhoneNumber: c.mobilePhoneNumber,
      relationship: c.relationship ?? null,
      firstName: c.firstName ?? null,
      lastName: c.lastName ?? null,
      middleName: c.middleName ?? null,
      patientId: patientId,
      mobilePhoneNumberSmsable: false,
      isForPortalLogin: false,
    })) ?? [];

  if (originalContacts !== null) {
    if (draftContacts === null) {
      return (
        originalContacts?.map((c) => ({
          ...c,
          key: `${PATIENT_INTAKE_CONTACT_KEY_PREFIX}-${c.mobilePhoneNumber}`,
        })) ?? []
      );
    } else if (draftContacts?.length === 0) {
      return [];
    }

    const toDelete: PatientContactId[] = [];
    const toUpdate: PatientIntakeProfileDraft["contacts"] = [];
    const toCreate: PatientIntakeProfileDraft["contacts"] = mappedDraftContacts?.filter(
      (c) => c.id === undefined
    );

    for (const contact of originalContacts) {
      const draftContact = draftContacts?.find((c) => c.id === contact.id);
      if (draftContact === undefined) {
        toDelete.push(contact.id);
      } else {
        toUpdate.push({
          ...contact,
          ...draftContact,
          key: `${PATIENT_INTAKE_CONTACT_KEY_PREFIX}-${
            contact.id !== undefined
              ? contact.mobilePhoneNumber
              : `new-${contact.mobilePhoneNumber}`
          }`,
        });
      }
    }
    return [...toUpdate, ...toCreate];
  }

  if (draftContacts !== null) {
    return mappedDraftContacts;
  }

  return [];
}

function mergePlans(
  originalPlans: Messages["PatientIntakePlan"][],
  draftPlans: EditPatientIntake["plans"],
  didPlansChange: boolean
): PatientIntakeProfileDraft["plans"] {
  if (!didPlansChange) {
    return (
      originalPlans?.map((p) => ({
        ...p,
        key: `${PATIENT_INTAKE_PLAN_KEY_PREFIX}-${p.plan.id}`,
      })) ?? []
    );
  }

  const mappedDraftPlans =
    draftPlans?.map((p) => ({
      ...p,
      key: `${PATIENT_INTAKE_PLAN_KEY_PREFIX}-new-${p.plan?.id}`,
      caseManagerName: p.caseManagerName ?? null,
      caseManagerPhoneNumber: p.caseManagerPhoneNumber ?? null,
      caseManagerRouting: p.caseManagerRouting ?? null,
      current: p.current ?? false,
      main: p.main ?? false,
      plan: p.plan ?? null,
      planStartDate: p.planStartDate ?? null,
      referralDate: p.referralDate ?? null,
      selected: p.selected ?? false,
      uasAssessmentDate: p.uasAssessmentDate ?? null,
      uasHoursAmount: p.uasHoursAmount ?? null,
    })) ?? [];

  if (originalPlans !== null) {
    if (draftPlans === null) {
      return (
        originalPlans?.map((p) => ({
          ...p,
          key: `${PATIENT_INTAKE_PLAN_KEY_PREFIX}-${p.plan.id}`,
        })) ?? []
      );
    } else if (draftPlans?.length === 0 && didPlansChange) {
      return [];
    }

    const toDelete: PatientIntakePlanId[] = [];
    const toUpdate: PatientIntakeProfileDraft["plans"] = [];
    const toCreate: PatientIntakeProfileDraft["plans"] =
      draftPlans
        ?.filter((p) => p.id === undefined)
        .map((p) => ({
          key: `${PATIENT_INTAKE_PLAN_KEY_PREFIX}-new-${p.plan?.id}`,
          caseManagerName: p.caseManagerName ?? null,
          caseManagerPhoneNumber: p.caseManagerPhoneNumber ?? null,
          caseManagerRouting: p.caseManagerRouting ?? null,
          current: p.current ?? false,
          main: p.main ?? false,
          plan: p.plan ?? null,
          planStartDate: p.planStartDate ?? null,
          referralDate: p.referralDate ?? null,
          selected: p.selected ?? false,
          uasAssessmentDate: p.uasAssessmentDate ?? null,
          uasHoursAmount: p.uasHoursAmount ?? null,
        })) ?? [];

    for (const plan of originalPlans) {
      const draftPlan = draftPlans?.find((c) => c.id === plan.id);
      if (draftPlan === undefined) {
        toDelete.push(plan.id);
      } else {
        toUpdate.push({
          ...plan,
          ...draftPlan,
          key: `${PATIENT_INTAKE_PLAN_KEY_PREFIX}-${
            plan.id !== undefined ? plan.id : `new-${plan.plan.id}`
          }`,
        });
      }
    }

    return [...toUpdate, ...toCreate];
  }

  if (draftPlans !== null) {
    return mappedDraftPlans;
  }

  return [];
}

export function mergeIntakePatientProfileWithDraft(
  intakeProfile: Messages["IntakePatientProfile"],
  draft: EditPatientIntake | null,
  didContractsChange: boolean,
  didPlansChange: boolean,
  didPhoneNumberChange: boolean
): PatientIntakeProfileDraft {
  return {
    ...intakeProfile,
    ...draft,
    contacts: mergeContacts(
      intakeProfile.id,
      intakeProfile.contacts ?? [],
      draft === null ? null : draft.contacts ?? [],
      didContractsChange
    ),
    plans: mergePlans(
      intakeProfile.plans ?? [],
      draft === null ? null : draft.plans ?? [],
      didPlansChange
    ),
    phoneNumbers: mergePhoneNumbers(
      intakeProfile.phoneNumbers ?? [],
      draft?.phoneNumbers ?? [],
      didPhoneNumberChange
    ),
  };
}

export function getIsRemovingPhoneNumberAssociatedWithPortal(params: {
  original: {
    contacts: Messages["IntakePatientProfile"]["contacts"];
    patientPhone: {
      number: string;
      isForPortalLogin: boolean;
    } | null;
  };
  changes: {
    contacts: Messages["EditPatientContact"][] | null | undefined;
    patientPhoneNumber: string | null;
  };
}) {
  const portalPhone =
    (params.original.patientPhone?.isForPortalLogin === true
      ? {
          source: "patient" as const,
          number: removeLeadingPhoneNumberCountryCode(params.original.patientPhone.number),
        }
      : undefined) ??
    fmap(params.original.contacts?.find((c) => c.isForPortalLogin)?.mobilePhoneNumber, (x) => ({
      source: "contact" as const,
      number: removeLeadingPhoneNumberCountryCode(x),
    }));

  if (portalPhone === null) {
    return false;
  }

  if (portalPhone.source === "patient" && params.changes.patientPhoneNumber === null) {
    return false;
  }

  if (
    portalPhone.source === "contact" &&
    (params.changes.contacts === null || params.changes.contacts === undefined)
  ) {
    return false;
  }

  return (
    params.changes.patientPhoneNumber !== portalPhone.number &&
    (params.changes.contacts?.every(
      (c) => removeLeadingPhoneNumberCountryCode(c.mobilePhoneNumber) !== portalPhone.number
    ) ??
      true)
  );
}

function removeLeadingPhoneNumberCountryCode(phoneNumber: string) {
  // I don't like this, but currently phone numbers
  // from different sources have different formats
  return phoneNumber.startsWith("+1") ? phoneNumber.slice(2) : phoneNumber;
}
