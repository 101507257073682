import { createIcon } from "@chakra-ui/react";

const PlayCircleIcon = createIcon({
  displayName: "PlayCircleIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m9.5 16.5l7-4.5l-7-4.5ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
      fill="currentColor"
    />
  ),
});

export default PlayCircleIcon;
