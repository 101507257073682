import { graphql } from "../../../../shared/schema/gql";

export const GET_VISIT_ASSIGNMENTS_QUERY = graphql(`
  query GetVisitAssignments(
    $order: [VisitAssignmentDistinct__Order]!
    $limit: Int!
    $offset: Int!
    $reasons: [VisitAssignmentReason!]
    $statuses: [VisitAssignmentStatus!]
    $patientId: PatientId_Filter
    $from: LocalDate
    $to: LocalDate
  ) {
    visitAssignmentsDistinct(
      createdAtFrom: $from
      createdAtTo: $to
      order: $order
      limit: $limit
      offset: $offset
      reasons: $reasons
      statuses: $statuses
      patientId: $patientId
    ) {
      totalCount
      nodes {
        id
        reason
        status
        createdAt
        unresolvedCount
        patient {
          id
          displayId
          firstName
          middleName
          lastName
          status
          gender
        }
        caregiver {
          id
          displayId
          firstName
          middleName
          lastName
          status
          avatarUrl
        }
        visitInstances {
          nodes {
            visitInstance {
              id
              startTimeLocal
              endTimeLocal
            }
          }
        }
        visitBroadcasts(visitBroadcast: { canceledAt: { eq: null } }) {
          nodes {
            visitBroadcast {
              id
              engagements(isRequestActive: { eq: true }) {
                nodes {
                  id
                  isRequestActive
                }
              }
            }
          }
        }
        tickets {
          nodes {
            ticket {
              id
              unreadCount
            }
          }
        }
      }
    }
  }
`);

export const GET_VISIT_ASSIGNMENT_QUERY = graphql(`
  query GetVisitAssignment($id: VisitAssignmentId!) {
    visitAssignments(id: { eq: $id }) {
      nodes {
        id
        reason
        status
        createdAt
        patient {
          id
          firstName
          lastName
          displayId
          gender
          phoneNumbers {
            nodes {
              type
              label
              phonenumber
              description
            }
          }
        }
        caregiver {
          id
          firstName
          lastName
          displayId
          avatarUrl
          phoneNumbers {
            nodes {
              phoneNumber
            }
          }
        }
        visitInstances {
          nodes {
            visitInstance {
              id
              startTimeLocal
              endTimeLocal
            }
          }
        }
        visitBroadcasts {
          nodes {
            visitBroadcast {
              id
              startTimeLocal
              endTimeLocal
              canceledAt
              engagements(isRequestActive: { eq: true }) {
                nodes {
                  id
                  isRequestActive
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_RELATED_VISIT_ASSIGNMENTS_QUERY = graphql(`
  query GetRelatedVisitAssignment(
    $id: VisitAssignmentId!
    $patientId: PatientId!
    $createdAtFrom: LocalDate!
  ) {
    unresolved: visitAssignments(
      id: { ne: $id }
      patientId: { eq: $patientId }
      order: [{ field: createdAt, sort: desc }]
      statuses: [Unresolved]
    ) {
      nodes {
        id
        reason
        status
        createdAt
      }
    }

    resolved: visitAssignments(
      id: { ne: $id }
      patientId: { eq: $patientId }
      createdAtFrom: $createdAtFrom
      order: [{ field: createdAt, sort: desc }]
      statuses: [Resolved]
    ) {
      nodes {
        id
        reason
        status
        createdAt
      }
    }
  }
`);
