import { Badge, ButtonProps, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { usePhoneNumberDialerPopup } from "../../call-center/hooks/usePhoneNumberDialerPopup";
import CallButton from "../../../shared/components/CallButton";
import { CaregiverId } from "../../../shared/schema/schema";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";

interface Props {
  caregiverId: CaregiverId;
  buttonProps?: ButtonProps;
  text?: string;
}

export default function CaregiverCallButton(props: Props) {
  const { queries } = useApi();
  const dialerPopup = usePhoneNumberDialerPopup();

  const caregiverQuery = useQuery({
    ...queries.caregiver.get(props.caregiverId),
    placeholderData: keepPreviousData,
  });

  const handleClickDial = (phoneNumber?: string) => {
    dialerPopup.open({ predefinedPhoneNumber: phoneNumber });
  };

  if (caregiverQuery.isError) {
    return (
      <CallButton isDisabled={true} isLoading={false} text="Error while loading call button" />
    );
  }

  if (caregiverQuery.isLoading || caregiverQuery.data === undefined) {
    return <CallButton isDisabled={true} isLoading={true} />;
  }

  return (
    <CallButton
      isDisabled={caregiverQuery.data.phoneNumbers.length === 0}
      isLoading={caregiverQuery.isLoading || caregiverQuery.data === undefined}
      text={props.text}
      {...props.buttonProps}
    >
      <Wrap direction="column">
        {caregiverQuery.data.phoneNumbers.map((pn, index) => (
          <WrapItem
            key={`phone-number-${index}`}
            _hover={{ bg: "gray.100" }}
            alignItems="center"
            cursor="pointer"
            gap={2}
            p={2}
            onClick={() => handleClickDial(pn.phoneNumber)}
          >
            <Badge>{pn.type}</Badge>
            <Text fontSize={14} fontWeight="bold">
              {phoneFormatter.formatNationalIfValid(pn.phoneNumber)}
            </Text>
          </WrapItem>
        ))}
      </Wrap>
    </CallButton>
  );
}
