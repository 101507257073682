import { createIcon } from "@chakra-ui/react";

const CompletedSolidIcon = createIcon({
  displayName: "CompletedSolidIcon",
  viewBox: "0 0 2048 2048",
  path: (
    <path
      d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37zm603 685l-136-136l-659 659l-275-275l-136 136l411 411l795-795z"
      fill="currentColor"
    />
  ),
});

export default CompletedSolidIcon;
