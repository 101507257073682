import { Badge, Flex, Heading, Text } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";

export default function StepCard(props: {
  step: Messages["IntakeTrackStepForFullFlow"];
  onClick: () => void;
  isActive: boolean;
}) {
  return (
    <Flex
      _active={{
        outlineColor: "blue.300",
        outlineOffset: "2px",
      }}
      _focusVisible={{
        outlineColor: "blue.300",
        outlineOffset: "2px",
      }}
      _hover={{
        outlineColor: "gray.100",
        bg: "gray.50",
      }}
      background={props.isActive ? "blue.100" : "white"}
      border="1px"
      borderColor={props.isActive ? "blue.500" : "gray.200"}
      cursor="pointer"
      direction="column"
      gap={2}
      outline="2px solid transparent"
      p={4}
      rounded="md"
      tabIndex={0}
      onClick={props.onClick}
    >
      <Text color="gray.500" fontSize="sm" fontWeight="semibold" textTransform="uppercase">
        {props.step.track.label} / GOTO{" "}
        {[
          ...new Set(
            props.step.fields
              .flatMap((x) =>
                x.type === "options" ? x.options.map((y) => y.nextIntakeTrackStepId) : []
              )
              .concat([props.step.nextStepId])
              .filter((x) => x !== null)
          ),
        ].join(",")}
      </Text>
      <Flex gap={2}>
        {props.step.isMainInStatusAndTrack && (
          <Badge colorScheme="blue" fontSize="xs">
            Main
          </Badge>
        )}
        {props.step.isStop && (
          <Badge colorScheme="green" fontSize="xs">
            Is Stop
          </Badge>
        )}
      </Flex>
      <Heading as="h3" size="sm">
        ({props.step.id}) {props.step.title}
      </Heading>
      <Text color="gray.500" fontSize="sm">
        {props.step.fields.length} Fields • {props.step.status.label}
      </Text>
    </Flex>
  );
}
