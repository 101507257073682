import { Box, Flex, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../../../../core/api";
import useApi from "../../../../../shared/hooks/useApi";
import { queryKeys } from "../../../../../shared/query-keys";
import { WorkflowTaskInstanceId } from "../../../../../shared/schema/schema";
import DocumentViewer from "../../../../compliance/components/DocumentViewer";
import HumanTaskDefaultForm from "../HumanTaskDefaultForm";
import { HumanTaskSubmitParams } from "../HumanTaskForm";
import { HumanTaskLayoutWrapper } from "../components/HumanTaskLayoutWrapper";

interface Props {
  id: WorkflowTaskInstanceId;
  instructions: string;
  files: string[];
  output: Record<string, Record<string, Messages["WorkflowDataFieldType"]>>;
  onSubmit: (params: HumanTaskSubmitParams) => void;
}

export default function HumanTaskDocumentViewerLayout(props: Props) {
  const { api } = useApi();

  const query = useQuery({
    queryKey: queryKeys.workflowTask.getSignedFiles(props.id),
    select: (data) => new Map(data.files),
    queryFn: () =>
      api.get("./workflow_tasks/:workflowTaskInstanceId/signed_files", {
        path: {
          workflowTaskInstanceId: props.id,
        },
      }),
  });

  return (
    <HumanTaskLayoutWrapper>
      <Flex direction="column" gap={3}>
        <Box bg="gray.50" mt={4} p={8} rounded="lg">
          <Text fontSize="lg" fontWeight="semibold">
            Instructions:
          </Text>
          <Text fontSize="lg" whiteSpace="pre-wrap">
            {props.instructions}
          </Text>
        </Box>
        <Box bg="gray.50" p={8} rounded="lg">
          {query.isPending && <Text>Loading...</Text>}
          {query.isError && <Text>Error loading files</Text>}
          {query.isSuccess &&
            props.files.flatMap((file) => {
              const s3Key = file.split("/").slice(3).join("/");
              const url = query.data.get(s3Key);

              if (url === undefined) {
                return [];
              }

              return [<DocumentViewer key={url} src={url} />];
            })}
        </Box>

        <HumanTaskDefaultForm
          output={props.output}
          workflowTaskInstanceId={props.id}
          onSubmit={props.onSubmit}
        />
      </Flex>
    </HumanTaskLayoutWrapper>
  );
}
