import { useCurrentStateAndParams, useRouter } from "@uirouter/react";

import { z } from "zod";
import {
  CaregiverId,
  PatientId,
  VisitAssignmentId,
  VisitBroadcastId,
  VisitInstanceId,
} from "../../../../shared/schema/schema";
import { zPathParamId } from "../../../../shared/utils/zod";
import { isDefined } from "../../../../utils";
import { VisitAssignmentDrawer } from "./components/VisitAssignmentDrawer";

const zPathParams = z.object({
  visitAssignmentId: zPathParamId(VisitAssignmentId).optional(),
  visitInstance: zPathParamId(VisitInstanceId).optional(),
  broadcast: zPathParamId(VisitBroadcastId).optional(),
  patient: zPathParamId(PatientId).optional(),
  caregiver: zPathParamId(CaregiverId).optional(),
});

export default function VisitAssignmentRoute() {
  const { params } = useCurrentStateAndParams();
  const { stateService } = useRouter();
  const { caregiver, visitAssignmentId, patient, visitInstance, broadcast } =
    zPathParams.parse(params);

  const isOpen =
    isDefined(visitAssignmentId) &&
    !isDefined(visitInstance) &&
    !isDefined(broadcast) &&
    !isDefined(patient) &&
    !isDefined(caregiver);

  return (
    <VisitAssignmentDrawer
      assignmentId={visitAssignmentId ?? null}
      isOpen={isOpen}
      onClose={() => stateService.go("^")}
    />
  );
}
