import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from "@tanstack/react-query";
import { UIRouterContextComponent } from "@uirouter/react-hybrid";
import axios from "axios";
import React, { StrictMode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ApiProvider } from "./core/api/api";
import ErrorPage from "./shared/components/ErrorPage";
import LoadingPage from "./shared/components/LoadingPage";
import useAuth from "./shared/hooks/useAuth";
import { theme } from "./shared/theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: (e) => !axios.isAxiosError(e),
      staleTime: 1000 * 30,
      retry: (failureCount, error) => {
        return axios.isAxiosError(error) && error.response?.status === 404 && failureCount < 2;
      },
    },
  },
});

export function WithAppInRouter<P>(Component: React.ComponentType<P>) {
  const displayName = Component.displayName || Component.name || "Component";

  const ComponentWithAppInRouter = (props: P & JSX.IntrinsicAttributes) => {
    return withAppOnly(Component)(props);
  };

  ComponentWithAppInRouter.displayName = `WithAppInRouter(${displayName})`;

  return ComponentWithAppInRouter;
}

export function withRouter<P>(Component: React.ComponentType<P>) {
  const displayName = Component.displayName || Component.name || "Component";

  const ComponentWithRouter = (props: P & JSX.IntrinsicAttributes) => {
    return (
      <UIRouterContextComponent inherited={true} parentContextLevel="3">
        <Component {...props} />
      </UIRouterContextComponent>
    );
  };

  ComponentWithRouter.displayName = `withRouter(${displayName})`;

  return ComponentWithRouter;
}

export function withAppOnly<P>(Component: React.ComponentType<P>) {
  const displayName = Component.displayName || Component.name || "Component";

  const ComponentWithApp = (props: P & JSX.IntrinsicAttributes) => {
    const { isInitialized, isUnauthorized } = useAuth();

    if (!isInitialized) {
      return <LoadingPage />;
    }

    if (isUnauthorized) {
      return <>Unauthorized</>;
    }

    return (
      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <ApiProvider>
            <ChakraProvider resetCSS={false} theme={theme}>
              <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary FallbackComponent={ErrorPage} onReset={reset}>
                    <Component {...props} />
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
            </ChakraProvider>
          </ApiProvider>
        </QueryClientProvider>
      </StrictMode>
    );
  };

  ComponentWithApp.displayName = `withAppOnly(${displayName})`;

  return ComponentWithApp;
}

export function withBasicApp<P>(Component: React.ComponentType<P>) {
  const displayName = Component.displayName || Component.name || "Component";

  const ComponentWithBasicApp = (props: P & JSX.IntrinsicAttributes) => {
    return (
      <StrictMode>
        <ChakraProvider resetCSS={false} theme={theme}>
          <Component {...props} />
        </ChakraProvider>
      </StrictMode>
    );
  };

  ComponentWithBasicApp.displayName = `withBasicApp(${displayName})`;

  return ComponentWithBasicApp;
}

export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow) {
    return value;
  }

  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

export type AssertNever = typeof assertNever;

export function isDefined<T>(value: T | undefined | null): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export function toTitleCase(str: string) {
  const text = str.toLowerCase();
  const result = text.replace(/[_]/g, " ");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function fromCamelCaseToTitle(str: string) {
  const result = str.replace(/([A-Z])/g, " $1").trim();
  return result.charAt(0).toUpperCase().concat(result.slice(1));
}
