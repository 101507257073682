import { Button, ButtonProps } from "@chakra-ui/react";

interface Props {
  onChange: (value: boolean) => void;
  value: boolean;
  borderColor?: ButtonProps["borderColor"];
  buttonProps?: ButtonProps;
  size?: ButtonProps["size"] | undefined;
  isDisabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
}

export default function Toggle(props: Props) {
  return (
    <Button
      background={props.value ? "blue.50" : undefined}
      borderColor={props.value ? "blue.600" : props.borderColor}
      color={props.value ? "blue.600" : undefined}
      isDisabled={props.isDisabled}
      variant="outline"
      onClick={() => props.onChange(!props.value)}
      {...props.buttonProps}
    >
      {props.children}
    </Button>
  );
}
