import { Button } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import useApi from "../hooks/useApi";
import { OfficeId } from "../schema/schema";
import LoadingButton from "./LoadingButton";
import Select, { CustomSelectProps } from "./Select";
import SelectNoResults from "./SelectNoResults";

export default function OfficesSelect(props: CustomSelectProps<OfficeId>) {
  const { queries } = useApi();

  const query = useQuery(queries.office.list());

  switch (query.status) {
    case "pending":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          label={props.multiple ? "Offices" : "Office"}
          options={query.data.offices.map((x) => ({ value: x.id, label: x.name }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          {...props}
        />
      );
  }
}
