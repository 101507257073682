import { Button, Flex, FlexProps, Input, Text, TextProps } from "@chakra-ui/react";
import React from "react";
import BackspaceIcon from "../icons/BackspaceIcon";
import { phoneFormatter } from "../utils/phone-formatter";
import PhoneNumberInput from "./PhoneNumberInput";
import Select from "./Select";

export interface PhoneNumberDialPadState {
  type: "PhoneNumberDialer";
  dialButtonConent: React.ReactNode;
  isCalling: boolean;
  dialButtonLeftIcon?: React.ReactElement;
  predefinedPhoneNumber?: string;
  onDialNumber: (E164number: string) => void;
}

export interface SingleDigitDialPadState {
  type: "SingleDigitDialer";
  currentState?: string;
  onClickDigit: (digit: string) => void;
  onClickMultipleDigits: (digits: string, delayMs?: number) => void;
}

type DialPadState = PhoneNumberDialPadState | SingleDigitDialPadState;

interface Props {
  state: DialPadState;
  flexProps?: FlexProps;
}

const Dialpad = (props: Props) => {
  switch (props.state.type) {
    case "PhoneNumberDialer":
      return <PhoneNumberDialPad flexProps={props.flexProps} state={props.state} />;
    case "SingleDigitDialer":
      return <SingleDigitDialPad flexProps={props.flexProps} state={props.state} />;
    default:
      return null;
  }
};

const SingleDigitDialPad = ({
  state,
  flexProps,
}: {
  flexProps?: FlexProps;
  state: SingleDigitDialPadState;
}) => {
  const handlePressDigit = (val: number | string) => {
    state.onClickDigit(val.toString());
  };
  return (
    <Flex direction="column" gap={4} w="60%" {...flexProps}>
      <Input readOnly placeholder="Press Digit..." type="text" value={state.currentState ?? ""} />
      <DialPadNumbers onClick={handlePressDigit} />
    </Flex>
  );
};

const PhoneNumberDialPad = ({
  flexProps,
  state,
}: {
  flexProps?: FlexProps;
  state: PhoneNumberDialPadState;
}) => {
  const [phonenumber, setPhoneNumber] = React.useState(state.predefinedPhoneNumber ?? "");
  const [prefix, setPrefix] = React.useState("+1");
  const addToNumber = (value: number | string) => {
    if (phonenumber.length >= 10) {
      return;
    }
    setPhoneNumber((curr) => `${curr}${value}`);
  };
  const removeFromNumber = () => {
    setPhoneNumber((curr) => curr.slice(0, curr.length - 1));
  };

  const phonenumberWithPrefix = `${prefix}${phonenumber}`;

  const shouldShowInvalidPhoneNumber =
    phoneFormatter.isE164(phonenumberWithPrefix) &&
    !phoneFormatter.isValidNumber(phonenumberWithPrefix);

  return (
    <Flex direction="column" gap={4} {...flexProps}>
      <Flex gap={2}>
        <Select
          allowUnselect={false}
          isTruncated={false}
          label="Prefix"
          multiple={false}
          options={[
            { label: "+1", value: "+1" },
            { label: "+972", value: "+972" },
          ]}
          value={prefix}
          width="fit-content"
          onChange={(e) => {
            if (e !== undefined) {
              setPrefix(e);
            }
          }}
        />
        <PhoneNumberInput
          autoFocus={true}
          borderWidth={0}
          placeholder="Enter number or paste..."
          textAlign="left"
          value={phonenumber}
          onChange={(e) => setPhoneNumber(e as string)}
        />
      </Flex>
      <DialPadNumbers onClick={addToNumber} />
      <DialPadNumber value="" onClick={removeFromNumber}>
        <BackspaceIcon boxSize={6} />
      </DialPadNumber>

      {shouldShowInvalidPhoneNumber ? (
        <Button colorScheme="red" isDisabled={true} variant="outline">
          Phone number is invalid
        </Button>
      ) : (
        <Button
          colorScheme="blue"
          isDisabled={state.isCalling}
          isLoading={state.isCalling}
          leftIcon={state.dialButtonLeftIcon}
          onClick={() => state.onDialNumber(phonenumberWithPrefix)}
        >
          {state.dialButtonConent}
        </Button>
      )}
    </Flex>
  );
};

const DialPadNumbers = (props: { onClick: (val: string | number) => void }) => {
  return (
    <>
      <Flex direction="row" justifyContent="space-around">
        <DialPadNumber value={1} onClick={props.onClick} />
        <DialPadNumber value={2} onClick={props.onClick}>
          <DialPadNumberLetters>ABC</DialPadNumberLetters>
        </DialPadNumber>
        <DialPadNumber value={3} onClick={props.onClick}>
          <DialPadNumberLetters>DEF</DialPadNumberLetters>
        </DialPadNumber>
      </Flex>
      <Flex direction="row" justifyContent="space-around">
        <DialPadNumber value={4} onClick={props.onClick}>
          <DialPadNumberLetters>GHI</DialPadNumberLetters>
        </DialPadNumber>
        <DialPadNumber value={5} onClick={props.onClick}>
          <DialPadNumberLetters>JKL</DialPadNumberLetters>
        </DialPadNumber>
        <DialPadNumber value={6} onClick={props.onClick}>
          <DialPadNumberLetters>MNO</DialPadNumberLetters>
        </DialPadNumber>
      </Flex>
      <Flex direction="row" justifyContent="space-around">
        <DialPadNumber value={7} onClick={props.onClick}>
          <DialPadNumberLetters>PQRS</DialPadNumberLetters>
        </DialPadNumber>
        <DialPadNumber value={8} onClick={props.onClick}>
          <DialPadNumberLetters>TUV</DialPadNumberLetters>
        </DialPadNumber>
        <DialPadNumber value={9} onClick={props.onClick}>
          <DialPadNumberLetters>WXYZ</DialPadNumberLetters>
        </DialPadNumber>
      </Flex>
      <Flex direction="row" justifyContent="space-around">
        <DialPadNumber value="*" onClick={props.onClick} />
        <DialPadNumber value={0} onClick={props.onClick} />
        <DialPadNumber value="#" onClick={props.onClick} />
      </Flex>
    </>
  );
};

const DialPadNumberLetters = (props: TextProps) => (
  <Text color="gray.400" {...props}>
    {props.children}
  </Text>
);

const DialPadNumber = (props: {
  onClick: (value: number | string) => void;
  value: number | string;
  children?: React.ReactNode;
}) => {
  return (
    <Button
      as={Flex}
      background="white"
      cursor="pointer"
      flexDirection="column"
      flexGrow={1}
      height="50px"
      w="full"
      onClick={() => props.onClick(props.value)}
    >
      <Text>{props.value}</Text>
      {props.children}
    </Button>
  );
};

export default Dialpad;
