import { Flex, Skeleton } from "@chakra-ui/react";

export function VisitAssignmentEntityCardSkeleton() {
  return (
    <Flex align="center" gap={4} p={4}>
      <Skeleton height={9} rounded="full" width={9} />
      <Flex direction="column" flex={1} gap={1}>
        <Skeleton height={5} rounded="full" width="80%" />
        <Skeleton height={4} rounded="full" width="60%" />
      </Flex>
      <Flex gap={2}>
        <Skeleton height={10} rounded="full" width={10} />
        <Skeleton height={10} rounded="full" width={10} />
      </Flex>
    </Flex>
  );
}
