import { Flex, FormLabel, Input } from "@chakra-ui/react";

interface Props {
  label?: string;
  onSelectFile: (value: FileList) => void;
}

const SelectFile = (props: Props) => {
  return (
    <Flex direction="column">
      <FormLabel> {props.label ?? "Upload File"} </FormLabel>
      <Input
        accept=".pdf"
        border="0px"
        type="file"
        onChange={(event) => props.onSelectFile(event.currentTarget.files ?? new FileList())}
      ></Input>
    </Flex>
  );
};

export default SelectFile;
