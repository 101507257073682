import { Flex, Skeleton, Stack } from "@chakra-ui/react";
import { CardButton } from "./CardButton";

export function LoadingEntityCard() {
  return (
    <CardButton>
      <Flex direction="row" gap={2}>
        <Skeleton h={10} rounded="full" w={10} />
        <Stack>
          <Skeleton h={4} rounded="md" w={200} />
          <Skeleton h={4} rounded="md" w={40} />
        </Stack>
      </Flex>
    </CardButton>
  );
}
