import { Tag, ThemeTypings } from "@chakra-ui/react";

interface TableCellBadgeProps {
  color: ThemeTypings["colorSchemes"];
  text: string;
}

export function TableCellBadge(props: TableCellBadgeProps) {
  return (
    <Tag colorScheme={props.color} size="sm" variant="subtle">
      {props.text}
    </Tag>
  );
}
