import { LocalDate, DateTimeFormatter } from "@js-joda/core";

//! @ngInject
export function visitInstancesCtrl($scope, $uibModal, NgTableParams, itemSearchPageManager, toaster, DatabaseApi, $rootScope, SweetAlert) {
    $scope.caregivers = DatabaseApi.caregivers();
    $scope.patients = DatabaseApi.patients();

    $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregivers = DatabaseApi.caregivers();
    });

    $rootScope.$on("got_patients_data", function (event) {
        $scope.patients = DatabaseApi.patients();
    });

    const selectedVisitInstances = new Set();
    $scope.selectedVisitInstances = selectedVisitInstances;

    const selectedVisitInstancesModels = {};
    selectedVisitInstancesModels["allInPage"] = false;
    selectedVisitInstancesModels["allInTable"] = false;
    $scope.selectedVisitInstancesModels = selectedVisitInstancesModels;

    let items;

    $scope.tableParams = null;
    $scope.tableColumns = {
        VisitId: true,
        VisitDate: true,
        VisitTime: true,
        Patient: true,
        Caregiver: true,
        Location: true,
        Contract: true,
        ServiceCode: false,
        InvoiceDisplayId: false,
        ClockIn: false,
        ClockOut: false
    };

    async function initialize() {
        initPageManager();
        $scope.loadItems();
    }

    $scope.$on("refresh_visits", function () {
        $scope.loadItems();
    })

    var initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/visit_instances");
        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();

        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.updateSearchParamValue("from", new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
        $scope.pageManager.updateSearchParamValue("to", new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
        $scope.pageManager.initContractTypesParam();
        $scope.pageManager.initSearchParam("patient", "");
        $scope.pageManager.initSearchParam("caregiver", "");
        $scope.pageManager.initSearchParam("patientDisplayId", "");
        $scope.pageManager.initSearchParam("caregiverAgencyDisplayId", "");
        $scope.pageManager.initSearchParam("visitId", "");
        $scope.pageManager.initSearchParam("invoiceDisplay", "");
    };

    var initTable = function (items) {
        var options = {
            count: 25,
            sorting: { id: "desc" }
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });
    };

    $scope.loadItems = function () {
        $scope.pageManager.executeSearch().then(function (response) {

            items = response.data.visitInstances;
            initTable(items);
        }, function (error) {
            toaster.pop("error", "Failed to load visit instances");
        });
    };

    const deselectAllInTable = () => {
        for (const key of Object.keys(selectedVisitInstancesModels)) {
            selectedVisitInstancesModels[key] = false;    
        }
        selectedVisitInstances.clear();
    }

    $scope.handleClickCheckbox = (row) => {
        selectedVisitInstancesModels["allInPage"] = false;

        selectedVisitInstances.has(row.id)
            ? selectedVisitInstances.delete(row.id)
            : selectedVisitInstances.add(row.id);
    }

    $scope.handleClickEdit = () => {
        if (selectedVisitInstances.size === 1) {
            $rootScope.openVisitInstanceModal([...selectedVisitInstances][0]);
        } else {
            $scope.handleClickBulkEdit();
        }
    }

    $scope.handleClickBulkEdit = () => {
        let selectedIds = [...selectedVisitInstances];

        if (selectedVisitInstancesModels["allInTable"]) {
            selectedIds = [...items.map(x => x.id)];
        }

        $rootScope.openBulkVisitInstancesModal(selectedIds);
    }

    $scope.handleClickDelete = () => {
        const visitInstanceIdsArr = [...selectedVisitInstances];
        const modalInstance = $uibModal.open({
            templateUrl: "admin/views/approve-visit-instance-delete-modal.html",
            size: "md",
            controller: "approveVisitInstanceDeleteModalCtrl",
            resolve: {
                visitInstanceIds: () => visitInstanceIdsArr
            }
        });

        modalInstance.result.then(function (res) {
            if (res.success === true) {
                $rootScope.$broadcast('refresh_visits');
                initTable(items.filter(x => !visitInstanceIdsArr.includes(x.id)));
            }
        });
    }

    $scope.handleClickSelectAllInPage = () => {
        const isSelectedAll = !selectedVisitInstancesModels["allInPage"];
        deselectAllInTable();

        $scope.tableParams.data.forEach((row) => {
            if (isSelectedAll) {
                selectedVisitInstances.add(row.id);
                selectedVisitInstancesModels[row.id] = true;
            } else {
                selectedVisitInstances.delete(row.id);
                selectedVisitInstancesModels[row.id] = false;
            }
        });
    }

    $scope.handleClickSelectAllInTable = () => {
        for (const item of items) {
            selectedVisitInstancesModels[item.id] = true;
            selectedVisitInstances.add(item.id);
        }
    }

    initialize();
};