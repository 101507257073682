import { Button, ButtonProps, forwardRef, Text } from "@chakra-ui/react";
import PhoneMissedIcon from "../../../../shared/icons/PhoneMissedIcon";

const MissedCallsButton = forwardRef(
  (
    props: ButtonProps & {
      numCalls: number;
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { numCalls, ...rest } = props;

    return (
      <Button
        ref={ref}
        _hover={{ bg: "red.600" }}
        alignItems="center"
        bg="red.500"
        display="flex"
        gap={2}
        h={38}
        justifyContent="center"
        p={5}
        rounded="lg"
        size="xs"
        w={150}
        {...rest}
      >
        <PhoneMissedIcon color="white" h={6} w={6} />
        <Text color="white" fontSize="lg">
          {getRingingCallsButtonText(numCalls)}
        </Text>
      </Button>
    );
  }
);

function getRingingCallsButtonText(numCalls: number) {
  return numCalls === 0
    ? "No missed calls"
    : numCalls === 1
    ? "1 missed call"
    : `${numCalls} missed calls`;
}

export default MissedCallsButton;
