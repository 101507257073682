import type { EditorState, Spread } from "lexical";

import {
  $applyNodeReplacement,
  TextNode,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
} from "lexical";
import { AgencyMemberId } from "../../../schema/schema";
import { fmap } from "../../../utils";
import { MentionItem } from "../editor-utils";

export type SerializedMentionNode = Spread<{ mention: MentionItem }, SerializedTextNode>;

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  const mentionId = fmap(domNode.dataset["mention"], parseInt);

  if (textContent !== null && mentionId !== undefined) {
    const node = $createMentionNode({
      id: AgencyMemberId.parse(mentionId),
      name: textContent,
      picture: null,
    });
    return {
      node,
    };
  }

  return null;
}

export class MentionNode extends TextNode {
  __mention: MentionItem;

  static getType(): string {
    return "mention";
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mention, node.__text, node.__key);
  }
  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(serializedNode.mention);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(member: MentionItem, text?: string, key?: NodeKey) {
    super(text ?? member.name, key);
    this.__mention = member;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mention: this.__mention,
      type: "mention",
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = "mention";
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-mention", "true");
    element.setAttribute("data-mention", JSON.stringify(this.__mention.id));
    element.classList.add("mention");
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-mention")) {
          return null;
        }
        return {
          conversion: convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createMentionNode(mention: MentionItem): MentionNode {
  const mentionNode = new MentionNode(mention);
  mentionNode.setMode("segmented").toggleDirectionless();
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode;
}

export function $getMentionNodes(editorState: EditorState): MentionNode[] {
  return [...editorState._nodeMap.values()].filter($isMentionNode);
}
