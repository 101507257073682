import { Flex, Text } from "@chakra-ui/react";
import SmsTicketStatus from "../SmsTicketStatus";
import { SmsTicket } from "../../utils/communication-utils";
import { CommunicationLogVerticalDivider } from "./CommunicationLog";

interface Props {
  ticket: SmsTicket;
}

const CommunicationLogSmsDetails = (props: Props) => {
  const { assignedTo, chatSummaryDetails, relatedPatient } = props.ticket;

  const assignedToName = assignedTo?.name ?? "Unkown agency member";
  const patientName = relatedPatient?.name ?? "Unkown patient";
  const description = `SMS conversation between ${assignedToName} and ${patientName}`;
  const summaryText =
    chatSummaryDetails !== null && chatSummaryDetails.summary !== null
      ? chatSummaryDetails.summary
      : "No summary available";

  return (
    <>
      <Flex alignItems="center" gap={2}>
        <SmsTicketStatus messages={props.ticket.messages} status={props.ticket.status} />
        <CommunicationLogVerticalDivider />
        <Text>{description}</Text>
      </Flex>
      <Text>{summaryText}</Text>
    </>
  );
};

export default CommunicationLogSmsDetails;
